import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, ActionBtn, ImageUploader, Form } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';

import StepForm from './StepForm';

import { Icons } from '../../../../common/Icons';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddStepDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onAddStep } = props;

  const [step, setStep] = React.useState();

  if (!open) {
    return <div />;
  }
  const _onAdd = () => {
    console.log(step);
    onAddStep(step);
  };
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <span
          style={{
            position: 'relative',
            top: '4px',
          }}>
          <Icons.ProcessStep size={25} />
        </span>{' '}
        Add Process Step
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <StepForm
            step={step}
            onChange={(step) => {
              console.log(step);
              setStep({ ...step });
            }}
          />
        </div>
        <div
          style={{
            marginTop: '20px',
            textAlign: 'center',
            marginBottom: '30px',
          }}>
          <ActionBtn label={'Add Step'} onClick={_onAdd} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddStepDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddStep: PropTypes.func,
};

AddStepDialog.defaultProps = {
  onChange: (v) => console.log(v),
};
export default AddStepDialog;
