import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { Loading, NoData, QRCodeDialog } from '@meronex/components';

import SitesAvatar from '../../common/SitesAvatar';
import WorkOrderDrafts from '../../factories/components/workorders/draftWorkOrder/WorkOrderDrafts';
import DraftDialog from '../../factories/components/workorders/draftWorkOrder/DraftDialog';

import { equipmentsMng } from '../api/equipmentsMng';

import QRCodeLabelDialog from '../components/QRCodeLabelDialog';

function EquipmentHomePage(props) {
  const { equipment, onWorkOrderAdded } = props;

  const [drafts, setDrafts] = React.useState();
  const [selectedDraft, setSelectedDraft] = React.useState();
  const [openDraft, setOpenDraft] = React.useState();

  const [openQrCodeDialog, setOpenQrCodeDialog] = React.useState();

  const getEquipmentDrafts = async () => {
    const drafts = await equipmentsMng.getEquipmentDrafts({
      _id: equipment._id,
    });
    console.log(drafts);
    setDrafts(drafts);
  };

  React.useEffect(() => {
    if (equipment) {
      getEquipmentDrafts();
    }
  }, [equipment]);

  const renderDrafts = () => {
    console.log(drafts);
    if (!Array.isArray(drafts)) {
      return <Loading />;
    } else if (drafts.length === 0) {
      return <NoData type={'warn'} message={'Equipment not being used'} />;
    }
    return (
      <WorkOrderDrafts
        showCreateWorkOrder={false}
        containerStyle={{ margin: '0px' }}
        drafts={drafts}
        onDraftResume={(d) => {
          console.log(d);
          setOpenDraft(true);
        }}
        onDraftClick={(d) => {
          console.log(d);
          setSelectedDraft(d);
        }}
        onDraftDeleted={() => {
          console.log('draft deleted');
          getEquipmentDrafts();
        }}
      />
    );
  };

  console.log(selectedDraft);
  console.log(openDraft);
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
        }}>
        <div
          style={{
            marginTop: '20px',
          }}>
          <SitesAvatar
            name={equipment.name}
            image={equipment.image ? equipment.image.thumbUrl : ''}
            size={220}
          />
        </div>
        <div>
          <h1>{equipment.name}</h1>
        </div>
      </div>
      {/*<div*/}
      {/*  style={{*/}
      {/*    textAlign: 'center',*/}
      {/*    marginTop: '-10x',*/}
      {/*    marginBottom: '10px',*/}
      {/*  }}>*/}
      {/*  <Button*/}
      {/*    onClick={() => {*/}
      {/*      setOpenQrCodeDialog(true);*/}
      {/*    }}>*/}
      {/*    <Icons.QrCode />{' '}*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        position: 'relative',*/}
      {/*        left: '10px',*/}
      {/*      }}>*/}
      {/*      QR Code Label*/}
      {/*    </span>*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div></div>
      <Divider />
      <QRCodeLabelDialog
        open={openQrCodeDialog}
        url={''}
        label={'1A'}
        onClose={() => {
          setOpenQrCodeDialog(false);
        }}
      />

      <div>{renderDrafts()}</div>
      {selectedDraft && (
        <DraftDialog
          draft={selectedDraft}
          open={openDraft}
          onWorkOrderAdded={onWorkOrderAdded}
          onClose={() => {
            getEquipmentDrafts();
            setOpenDraft(false);
          }}
        />
      )}
    </div>
  );
}
EquipmentHomePage.propTypes = {
  equipment: PropTypes.object,
  onWorkOrderAdded: PropTypes.func,
};

EquipmentHomePage.defaultProps = {
  equipment: () => {},
  onWorkOrderAdded: () => {},
};
export default EquipmentHomePage;
