import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

export default function ImageTile(props) {
  return (
    <Paper
      style={{
        cursor: 'pointer',
        marginRight: '10px',
        textAlign: 'center',
        width: '260px',
        height: '170px',
        backgroundImage: `url(${props.imgUrl})`,
        backgroundSize: 'cover'
      }}>
      <div
        style={{
          position: 'relative',
          top: '140px',
          width: '100%',
          fontWeight: '700',
          fontSize: '18px',
          color: 'white',
          backgroundColor: '#0000009e',
          height: '30px',
          padding: '4px',
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px'
        }}>
        {props.title}
      </div>
    </Paper>
  );
}

ImageTile.propTypes = {
  imgUrl: PropTypes.string,
  title: PropTypes.string
};
