import { App } from '@meronex/app';

const { server } = App;

export const productsMng = {
  updateProduct: async (product) => {
    const result = await server.call('updateProduct', product);
    return result;
  },

  addProduct: async (product) => {
    const result = await server.call('addProduct', product);
    return result;
  },
  getProducts: async (factoryId) => {
    const result = await server.call('getProducts', {
      factoryId,
    });
    return result;
  },
  getProductsWithFactories: async () => {
    const result = await server.call('getProductsWithFactories', {});
    return result;
  },
  deleteProduct: async (productId) => {
    const result = await server.call('deleteProduct', { _id: productId });
    return result;
  },

  addProductToStore: async ({ productId, storeId }) => {
    const result = await server.call('addProductToStore', {
      productId,
      storeId,
    });
    return result;
  },
  getProductDraftsCount: async ({ productId }) => {
    const result = await server.call('getProductDraftsCount', {
      productId,
    });
    return result;
  },
};
