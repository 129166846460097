import React, { Suspense } from 'react';

import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MdMenu from '@meronex/icons/md/MdMenu';

import ILocation from '@meronex/icons/en/EnLocation';
import IArchive from '@meronex/icons/bs/BsArchive';
import IEdit from '@meronex/icons/ai/AiTwotoneEdit';
import IQRCode from '@meronex/icons/mdc/MdcQrcodeScan';

import { Icons } from '../../../../common/Icons';

import Fade from '@material-ui/core/Fade';

import { toast } from '@meronex/components';

import { storesMng } from '../../../api/storesMng';
import ModifyDialog from './ModifyDialog';
import ManageRulesDialog from '../rules/ManageRulesDialog';

import LocationDialog from '../actions/LocationDialog';
import AdjustUnitRateDialog from './AdjustUnitRateDialog/AdjustUnitRateDialog';
const LabelDialog = React.lazy(() => import('../labels/LabelDialog'));

function AuditDrawer(props) {
  const {
    onOpen,
    onClose,
    item,
    products,
    isScrolling,
    onModifyStoreItem,
    onArchiveStoreItem,
    onAdjustUnitRate,
    permissions,
  } = props;

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openModifyDialog, setOpenModifyDialog] = React.useState(false);
  const [openManageRulesDialog, setOpenManageRulesDialog] = React.useState(
    false
  );
  const [openLocationDialog, setOpenLocationDialog] = React.useState(false);
  const [openGenerateLabel, setOpenGenerateLabel] = React.useState();
  const [openAdjustUnitRate, setOpenAdjustUnitRate] = React.useState();

  const _onClose = () => {
    setOpenDrawer(false);
    onClose();
  };

  const modifyAmount = async () => {
    setOpenModifyDialog(true);
    _onClose();
  };

  const setLocation = async () => {
    setOpenLocationDialog(true);
    _onClose();
  };

  const generateLabel = async () => {
    setOpenGenerateLabel(true);
    _onClose();
  };
  const archiveItem = async () => {
    console.log('archive item');
    const result = await storesMng.archiveStoreItem(item._id);
    toast.success('Store item archived', { autoClose: 900 });
    onArchiveStoreItem();
    _onClose();
  };

  const disableArchiving = Array.isArray(products) && products.length > 0;
  return (
    <div>
      <Fade in={!isScrolling}>
        <Fab
          onClick={() => {
            setOpenDrawer(true);
            onOpen();
          }}
          aria-label="add"
          style={{
            position: 'fixed',
            right: '25px',
            bottom: '30px',
          }}>
          <MdMenu size={25} />
        </Fab>
      </Fade>
      <div>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            _onClose();
          }}>
          <List>
            {/*<ListItem*/}
            {/*  button*/}
            {/*  key="manageRules"*/}
            {/*  onClick={() => {*/}
            {/*    setOpenManageRulesDialog(true);*/}
            {/*  }}>*/}
            {/*  <ListItemIcon>*/}
            {/*    <IRules*/}
            {/*      size={25}*/}
            {/*      style={{*/}
            {/*        color: 'black',*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary={'Manage Rules'} />*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}
            <ListItem button key="label" onClick={generateLabel}>
              <ListItemIcon>
                <IQRCode
                  size={25}
                  style={{
                    color: 'black',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={'Generate Label'} />
            </ListItem>
            <Divider />
            {/*<ListItem*/}
            {/*  button*/}
            {/*  key="exportToCSV"*/}
            {/*  onClick={() => {*/}
            {/*    console.log('export to CSV');*/}
            {/*    _onClose();*/}
            {/*  }}>*/}
            {/*  <ListItemIcon>*/}
            {/*    <span style={{}}>*/}
            {/*      <ICsv size={20} />*/}
            {/*    </span>*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary={'Export items to CSV'} />*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}
            {item.store.enableSections && (
              <>
                <ListItem button key="location" onClick={setLocation}>
                  <ListItemIcon>
                    <ILocation
                      size={25}
                      style={{
                        color: 'black',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={'Set Location'} />
                </ListItem>
                <Divider />
              </>
            )}
            <ListItem
              button
              key="adjustUnitRate"
              onClick={() => {
                console.log('Adjust unit rate');
                setOpenDrawer(false);
                setOpenAdjustUnitRate(true);
              }}
              disabled={!permissions.modify}>
              <ListItemIcon>
                <Icons.UnitRate
                  size={25}
                  style={{
                    color: 'black',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={'Adjust Unit Rate'} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="modifyAmount"
              onClick={modifyAmount}
              disabled={!permissions.modify}>
              <ListItemIcon>
                <IEdit
                  size={25}
                  style={{
                    color: 'black',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={'Modify Quantity'} />
            </ListItem>
            <Divider />
            <ListItem
              disabled={disableArchiving || !permissions.archive}
              button
              key="archiveItem"
              onClick={archiveItem}>
              <ListItemIcon>
                <IArchive
                  size={20}
                  style={{
                    color: 'black',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={'Archive Store Item'}
                secondary={
                  disableArchiving
                    ? 'Please remove associated products before archiving'
                    : ''
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="close"
              onClick={() => {
                _onClose();
              }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '18px',
                }}>
                Close{' '}
              </div>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
      </div>
      <ManageRulesDialog
        open={openManageRulesDialog}
        onClose={() => {
          setOpenManageRulesDialog(false);
        }}
      />
      <ModifyDialog
        onClose={() => {
          setOpenModifyDialog(false);
        }}
        onModifyStoreItem={() => {
          if (onModifyStoreItem) {
            onModifyStoreItem();
          }
        }}
        item={item}
        open={openModifyDialog}
      />
      <LocationDialog
        open={openLocationDialog}
        onUpdate={() => {
          if (onModifyStoreItem) {
            onModifyStoreItem();
          }
        }}
        item={item}
        onClose={() => {
          setOpenLocationDialog();
        }}
      />
      {openGenerateLabel && (
        <Suspense fallback={<div>Loading...</div>}>
          <LabelDialog
            item={item}
            open={openGenerateLabel}
            onClose={() => {
              setOpenGenerateLabel(false);
            }}
          />
        </Suspense>
      )}

      {openAdjustUnitRate && (
        <AdjustUnitRateDialog
          item={item}
          open={openAdjustUnitRate}
          onAdjustUnitRate={onAdjustUnitRate}
          onClose={() => {
            setOpenAdjustUnitRate(false);
          }}
        />
      )}
    </div>
  );
}
AuditDrawer.propTypes = {
  selectedStore: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isScrolling: PropTypes.bool,
  item: PropTypes.object,
  products: PropTypes.array,
  onModifyStoreItem: PropTypes.func,
  onAdjustUnitRate: PropTypes.func,
  onArchiveStoreItem: PropTypes.func,
};

AuditDrawer.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  onAdjustUnitRate: () => {},
};
export default AuditDrawer;
