import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { v4 as generateRandomID } from 'uuid';

import { App } from '@meronex/app';

const utils = App.utils;
const optimizeImage = utils.image.optimizeImage;
const generateRandomFilename = () => generateRandomID();

function extractExtension(filename) {
  let ext = /(?:\.([^.]+))?$/.exec(filename);
  if (ext != null && ext[0] != null) {
    return ext[0];
  } else {
    return '';
  }
}

export class FilesUpload {
  constructor({
    metadata,
    randomizeFilename,
    filename,
    folder,
    optimize,
    onTaskSizeChange,
  }) {
    this.metadata = metadata;
    this.randomizeFilename = randomizeFilename;
    this.filename = filename;
    this.folder = folder;
    this.optimize = optimize;
    this.uploadTasks = [];
    this.onTaskSizeChange = onTaskSizeChange;
  }
  cancelRunningUploads() {
    while (this.uploadTasks.length > 0) {
      const task = this.uploadTasks.pop();
      if (task.snapshot.state === 'running') {
        task.cancel();
      }
    }
  }
  removeTask(task) {
    for (let i = 0; i < this.uploadTasks.length; i++) {
      if (this.uploadTasks[i] === task) {
        this.uploadTasks.splice(i, 1);
        if (this.onTaskSizeChange) {
          this.onTaskSizeChange(this.uploadTasks);
        }
        return;
      }
    }
  }
  startUpload({
    file,
    onUploadStart,
    onProgress,
    onUploadError,
    onUploadSuccess,
  }) {
    console.log('start upload');
    console.log(file);
    const { metadata, randomizeFilename, filename, folder } = this;

    let filenameToUse;
    if (filename) {
      filenameToUse =
        typeof filename === 'function' ? filename(file) : filename;
    } else {
      filenameToUse = randomizeFilename ? generateRandomFilename() : file.name;
    }

    // Ensure there is an extension in the filename
    if (!extractExtension(filenameToUse)) {
      filenameToUse += extractExtension(file.name);
    }

    Promise.resolve()
      .then(() => {
        return optimizeImage(file);
      })
      .then((file) => {
        const storage = getStorage();

        const fullPath = `${folder}/${filenameToUse}`;
        console.log(fullPath);

        const storageRef = ref(storage, fullPath);

        const task = uploadBytesResumable(storageRef, file, metadata);

        if (onUploadStart) {
          onUploadStart(file, task);
        }

        task.on(
          'state_changed',
          (snapshot) =>
            onProgress &&
            onProgress(
              Math.round(
                (100 * snapshot.bytesTransferred) / snapshot.totalBytes
              ),
              task
            ),
          (error) => onUploadError && onUploadError(error, task),
          () => {
            this.removeTask(task);
            getDownloadURL(task.snapshot.ref).then((downloadUrl) => {
              console.log('File available at', downloadUrl);
              if (onUploadSuccess) {
                return (
                  onUploadSuccess &&
                  onUploadSuccess({
                    fileName: task.snapshot.metadata.name,
                    file,
                    task,
                    downloadUrl,
                  })
                );
              }
            });
          }
        );
        this.uploadTasks.push(task);
        if (this.onTaskSizeChange) {
          this.onTaskSizeChange(this.uploadTasks);
        }
      });
  }
}
