import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineSharp';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IPeople from '@meronex/icons/md/MdPeopleOutline';

import { membersMng } from '../api/membersMng';

export default function MembersSelect(props) {
  const { onChange, defaultValue } = props;

  const [members, setMembers] = React.useState();
  const [options, setOptions] = React.useState();
  const [val, setVal] = useState(defaultValue || []);

  const fetchMembers = async () => {
    const result = await membersMng.getMembers();
    setMembers(result);
  };

  React.useEffect(() => {
    if (Array.isArray(members)) {
      const memberOptions = members.map((m) => {
        return {
          _id: m._id,
          name: `${m.profile.firstName} ${m.profile.lastName}`,
        };
      });
      setOptions(memberOptions);
    }
  }, [members]);

  React.useEffect(() => {
    fetchMembers();
  }, []);

  React.useEffect(() => {
    if (onChange) {
      onChange(val);
    }
  }, [val]);
  const valHtml = val.map((option, index) => {
    // This is to handle new options added by the user (allowed by freeSolo prop).
    const name = option.name || option;
    return (
      <Chip
        style={{
          margin: '5px',
        }}
        key={name}
        label={
          <span>
            <span
              style={{
                position: 'relative',
                top: '4px',
              }}>
              <IPeople size={20} />
            </span>
            <span
              style={{
                marginLeft: '5px',
              }}>
              {name}
            </span>
          </span>
        }
        deleteIcon={<RemoveIcon />}
        onDelete={() => {
          setVal(val.filter((entry) => entry !== option));
        }}
      />
    );
  });

  const getOptions = () => {
    if (!Array.isArray(options)) {
      return [
        {
          _id: 'loading',
          name: 'Loading',
        },
      ];
    }

    const valIds = val.map((v) => v._id);

    const filteredOptions = options.filter((o) => !valIds.includes(o._id));

    return filteredOptions;
  };
  return (
    <div>
      <Autocomplete
        multiple
        id="assigned-to"
        filterSelectedOptions
        disabled={!members}
        options={getOptions()}
        onChange={(e, newValue) => setVal(newValue)}
        getOptionLabel={(option) => option.name}
        renderOption={(v) => {
          return (
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '4px',
                }}>
                <IPeople size={20} />
              </span>
              <span
                style={{
                  marginLeft: '5px',
                }}>
                {v.name}
              </span>
            </div>
          );
        }}
        value={val}
        renderTags={() => {}}
        InputLabelProps={{
          shrink: true,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={'Members'}
            margin="normal"
            placeholder={!members ? 'loading..' : 'search members'}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        )}
      />
      <div className="selectedTags">{valHtml}</div>
    </div>
  );
}

MembersSelect.propTypes = {
  onChange: PropTypes.func,
};

MembersSelect.defaultProps = {
  onChange: (v) => console.log(v),
};
