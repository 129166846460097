import React from 'react';
import PropTypes from 'prop-types';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';

import List from '@material-ui/core/List';

import TeamItem from './TeamItem';
import { teamsMng } from '../api/teamsMng';

function TeamsList(props) {
  const { teams, onClick, onEdit, onDelete } = props;

  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const _onDelete = (team) => {
    setOpenDeleteAlert(true);
    setSelectedItem(team);
  };

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    await teamsMng.deleteTeam(selectedItem);
    toast.success('Team successfully deleted');
    setDeleting(false);
    onDelete();
  };

  const renderTeams = () => {
    if (!Array.isArray(teams)) {
      return <Loading />;
    } else if (teams.length === 0) {
      return <NoData type={'warn'} />;
    }

    return teams.map((team) => {
      return (
        <TeamItem
          team={team}
          onEdit={onEdit}
          onDelete={_onDelete}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <>
      <List>{renderTeams()}</List>
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
TeamsList.propTypes = {
  teams: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

TeamsList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default TeamsList;
