import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import IWarn from '@meronex/icons/ti/TiWarningOutline';
import IData from '@meronex/icons/bs/BsClipboardData';
import IVerify from '@meronex/icons/mdc/MdcMicroscope';

import FlaggedDialog from './FlaggedDialog';

function WorkOrderState(props) {
  const { workOrder, onChange } = props;

  const [woState, setWoState] = React.useState(
    workOrder.state || {
      dataEntry: null,
      qualityVerified: null,
      flagged: null,
    }
  );

  const [openFlaggedDialog, setOpenFlaggedDialog] = React.useState();

  console.log(woState);

  const user = App.getUser();

  if (!workOrder) {
    return null;
  }
  return (
    <MenuList
      style={{
        padding: '0px',
        border: '1px solid #e8e7e8',
        borderRadius: '10px',
      }}>
      <div>
        <MenuItem
          key={'qualityVerified'}
          onClick={() => {
            const _woState = { ...woState };
            if (!_woState.qualityVerified) {
              _woState.qualityVerified = {
                checked: true,
                user: {
                  profile: user.profile,
                  _id: user._id,
                },
                date: new Date(),
              };
            } else {
              _woState.qualityVerified = null;
            }
            setWoState(_woState);
            onChange(_woState);
          }}
          style={{
            padding: '10px',
            borderBottom: '1px solid #e1e1e1',
          }}>
          <Checkbox checked={woState.qualityVerified} />
          <span>&nbsp;Quality verified</span>
          <div
            style={{
              position: 'absolute',
              right: '25px',
            }}>
            <IVerify size={25} />
          </div>
        </MenuItem>
        <MenuItem
          key={'dataEntry'}
          onClick={() => {
            const _woState = { ...woState };
            if (!_woState.dataEntry) {
              _woState.dataEntry = {
                checked: true,
                user: {
                  profile: user.profile,
                  _id: user._id,
                },
                date: new Date(),
              };
            } else {
              _woState.dataEntry = null;
            }
            setWoState(_woState);
            onChange(_woState);
          }}
          style={{
            padding: '10px',

            borderBottom: '1px solid #e1e1e1',
          }}>
          <Checkbox checked={woState.dataEntry} />
          &nbsp;Data verified and processed
          <div
            style={{
              position: 'absolute',
              right: '25px',
            }}>
            <IData size={25} />
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            const _woState = { ...woState };
            if (!_woState.flagged) {
              setOpenFlaggedDialog(true);
            } else {
              _woState.flagged = null;
            }
            setWoState(_woState);
          }}
          key={'flagged'}
          style={{
            padding: '10px',
          }}>
          <Checkbox checked={woState.flagged} />
          <span
            style={{
              color: 'red',
            }}>
            &nbsp;Work order flagged
          </span>
          <div
            style={{
              position: 'absolute',
              right: '25px',
              color: 'red',
            }}>
            <IWarn size={25} />
          </div>
        </MenuItem>
      </div>
      <FlaggedDialog
        open={openFlaggedDialog}
        onSubmit={(reason) => {
          const _woState = { ...woState };

          _woState.flagged = {
            checked: true,
            reason,
            user: {
              profile: user.profile,
              _id: user._id,
            },
            date: new Date(),
          };
          setWoState(_woState);
          onChange(_woState);
        }}
        onClose={() => {
          setOpenFlaggedDialog(false);
        }}
      />
    </MenuList>
  );
}
WorkOrderState.propTypes = {
  workOrder: PropTypes.object,
  onChange: PropTypes.func,
};

WorkOrderState.defaultProps = {};
export default WorkOrderState;
