import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, Loading } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IQRCode from '@meronex/icons/mdc/MdcQrcodeScan';
import Scanner from 'react-webcam-qr-scanner';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { App } from '@meronex/app';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function QrCodeScanDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onScan } = props;

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState();

  const _onClose = () => {
    setData(null);
    onClose();
  };

  React.useEffect(() => {
    if (data) {
      onScan(data);
      _onClose();
    }
  }, [data]);
  return (
    <Dialog fullWidth open={open} onClose={_onClose}>
      <DialogTitle>
        <IQRCode /> QR Code Scanner
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <Scanner
          className={'qr-code-scanner'}
          onDecode={(result) => {
            if (!data) {
              setData(result.data);
            }
          }}
          onScannerLoad={() => {
            console.log('loaded');
            setLoading(false);
          }}
          constraints={{
            audio: false,
            video: {
              facingMode: 'environment',
            },
          }}
          captureSize={{ width: 1280, height: 720 }}
        />
      </DialogContent>
    </Dialog>
  );
}

QrCodeScanDialog.defaultProps = {
  onScan: (data) => console.log(data),
  open: false,
};
QrCodeScanDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onScan: PropTypes.func,
};

export default QrCodeScanDialog;
