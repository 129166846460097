import React from 'react';
import PropTypes from 'prop-types';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';
import { List, ListItem } from '@material-ui/core';

import Product from './Product';
import { productsMng } from '../../api/ProductsMng';
import EditProductDialog from './EditProductDialog';
import CreateWorkOrderDialog from '../workorders/createWorkOrder/CreateWorkOrderDialog';
import loadingGif from '../../factories.gif';

function ProductsList(props) {
  const {
    products,
    onDelete,
    onEdit,
    onWorkOrderAdded,
    onProductClick,
    onAdd,
    factory,
    onStart,
    showOnlyProductName,
    readOnly,
  } = props;

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [openEditProduct, setOpenEditProduct] = React.useState(false);
  const [openWorkOrder, setOpenWorkOrder] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState();

  const _onDelete = async () => {
    if (selectedProduct) {
      const result = await productsMng.deleteProduct(selectedProduct._id);
      toast.success('Product successfully deleted.', {
        autoClose: 800,
      });
      setOpenDeleteAlert(false);
      setOpenEditProduct(false);

      onDelete();
    }
  };

  const renderProduct = (product, index) => {
    return (
      <Product
        key={product._id}
        product={product}
        onEdit={(p) => {
          setSelectedProduct(p);
          setOpenEditProduct(true);
        }}
        readOnly={readOnly}
        showOnlyProductName={showOnlyProductName}
        onClick={(p) => {
          setSelectedProduct(p);
          setOpenWorkOrder(true);
          onProductClick();
        }}
        last={index === products.length - 1}
      />
    );
  };
  const renderContent = () => {
    if (!Array.isArray(products)) {
      return <Loading type={'img'} text={'Loading..'} url={loadingGif} />;
    }
    if (products.length === 0) {
      return (
        <NoData
          message={readOnly ? 'No Products Defined' : 'Add a Product'}
          onClick={() => {
            onAdd();
          }}
        />
      );
    }
    return products.map((p, index) => {
      return <ListItem>{renderProduct(p, index)}</ListItem>;
    });
  };
  return (
    <>
      <div>{renderContent()}</div>
      <div>
        {openWorkOrder && (
          <CreateWorkOrderDialog
            open={openWorkOrder}
            products={products}
            factory={factory}
            product={selectedProduct}
            onStart={onStart}
            onWorkOrderAdded={() => {
              setOpenWorkOrder(false);
              onWorkOrderAdded();
            }}
            onClose={() => {
              setOpenWorkOrder(false);
            }}
          />
        )}
        {openEditProduct && (
          <EditProductDialog
            product={selectedProduct}
            open={openEditProduct}
            onEdit={(_updateProduct) => {
              setSelectedProduct(_updateProduct);
              setOpenEditProduct(false);
              onEdit();
            }}
            onDelete={() => {
              setOpenDeleteAlert(true);
            }}
            onClose={() => {
              setOpenEditProduct(false);
            }}
          />
        )}
        <AlertDialog
          onConfirm={() => _onDelete()}
          open={openDeleteAlert}
          onClose={() => {
            setOpenDeleteAlert(false);
          }}
        />
      </div>
    </>
  );
}

ProductsList.propTypes = {
  onDelete: PropTypes.func,
  products: PropTypes.array,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onStart: PropTypes.func,
  onWorkOrderCreate: PropTypes.func,
  onProductClick: PropTypes.func,
};
ProductsList.defaultProps = {
  onProductClick: () => {},
  onStart: () => {},
};

export default ProductsList;
