import IProducts from '@meronex/icons/go/GoPackage';
import IItems from '@meronex/icons/fi/FiTriangle';
import IItemsFill from '@meronex/icons/bs/BsFillTriangleFill';

import IFactories from '@meronex/icons/mdc/MdcFactory';
import IStores from '@meronex/icons/mdc/MdcWarehouse';
import INews from '@meronex/icons/bs/BsNewspaper';
import ISettings from '@meronex/icons/fi/FiSettings';
import ICalculator from '@meronex/icons/ios/MdCalculator';
import IConvert from '@meronex/icons/mdc/MdcTapeMeasure';
import IDraw from '@meronex/icons/mdc/MdcDraw';
import IRefresh from '@meronex/icons/ios/MdRefresh';
import IHome from '@meronex/icons/ai/AiOutlineHome';
import IConfigure from '@meronex/icons/gi/GiSettingsKnobs';
import ICode from '@meronex/icons/fa/FaBarcode';
import IBatch from '@meronex/icons/mdc/MdcStickerOutline';
import IWorkOrder from '@meronex/icons/fa/FaUsersCog';
import ITransfer from '@meronex/icons/bi/BiTransferAlt';
import IRemove from '@meronex/icons/ri/RiDeleteBinLine';
import IResume from '@meronex/icons/vsc/VscDebugContinue';
import IiD from '@meronex/icons/mdc/MdcIdentifier';
import IMembers from '@meronex/icons/mdc/MdcAccountGroupOutline';
import IData from '@meronex/icons/su/SuDatabase';
import IDraft from '@meronex/icons/su/SuPaperFolded';
import IPartner from '@meronex/icons/fa/FaRegHandshake';
import IAdd from '@meronex/icons/cg/CgAdd';
import IEdit from '@meronex/icons/bi/BiEdit';
import IProcessStep from '@meronex/icons/mdc/MdcAccountCogOutline';
import IEquipment from '@meronex/icons/mdc/MdcForklift';
import ITime from '@meronex/icons/zo/ZoTimer';
import IPrice from '@meronex/icons/bi/BiDollar';
import IObservations from '@meronex/icons/ios/MdSearch';
import IApps from '@meronex/icons/md/MdApps';
import IMenu from '@meronex/icons/md/MdMenu';
import IQRCode from '@meronex/icons/mdc/MdcQrcodeScan';
import ICategory from '@meronex/icons/vsc/VscTag';
import IFeed from '@meronex/icons/md/MdRssFeed';
import ITask from '@meronex/icons/ios/MdCheckboxOutline';
import IComments from '@meronex/icons/mdc/MdcCommentMultipleOutline';
import IUnitRate from '@meronex/icons/fa/FaBalanceScaleLeft';
import IIssues from '@meronex/icons/bs/BsFiles';
export const Icons = {};

Icons.Items = IItems;
Icons.ItemsFill = IItemsFill;
Icons.Remove = IRemove;
Icons.Stores = IStores;
Icons.Products = IProducts;
Icons.Factories = IFactories;
Icons.Settings = ISettings;
Icons.Calculator = ICalculator;
Icons.Refresh = IRefresh;
Icons.News = INews;
Icons.Convert = IConvert;
Icons.Draw = IDraw;
Icons.Home = IHome;
Icons.Configure = IConfigure;
Icons.Code = ICode;
Icons.Observations = IObservations;
Icons.Batch = IBatch;
Icons.WorkOrder = IWorkOrder;
Icons.Transfer = ITransfer;
Icons.Resume = IResume;
Icons.Id = IiD;
Icons.Members = IMembers;
Icons.Data = IData;
Icons.Drafts = IDraft;
Icons.Partners = IPartner;
Icons.Add = IAdd;
Icons.Edit = IEdit;
Icons.ProcessStep = IProcessStep;
Icons.Equipments = IEquipment;
Icons.Time = ITime;
Icons.Price = IPrice;
Icons.Apps = IApps;
Icons.Menu = IMenu;
Icons.QrCode = IQRCode;
Icons.Category = ICategory;
Icons.Feed = IFeed;
Icons.Tasks = ITask;
Icons.Comments = IComments;
Icons.UnitRate = IUnitRate;
Icons.Issues = IIssues;
