import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { rolesMng } from '../../roles/api/rolesMng';
import MenuItem from '@material-ui/core/MenuItem';

function RolesSelect(props) {
  const { defaultValue, onChange } = props;
  const [roles, setRoles] = React.useState();

  const getRoles = async () => {
    const roles = await rolesMng.getRoles();
    roles.push({
      _id: 'admin',
      name: 'Admin',
    });
    roles.unshift({
      _id: 'any',
      name: 'Any',
    });
    setRoles(roles);
  };

  React.useEffect(() => {
    getRoles();
  }, []);

  const renderRoles = () => {
    if (!Array.isArray(roles)) {
      return <MenuItem> Loading...</MenuItem>;
    } else if (roles.length === 0) {
      return <MenuItem>No roles defined</MenuItem>;
    }
    return roles.map((r) => {
      return <MenuItem value={r._id}>{r.name}</MenuItem>;
    });
  };

  return (
    <TextField
      placeholder={'select role'}
      variant="outlined"
      style={{ marginTop: '10px' }}
      label="Role"
      name={'roleId'}
      defaultValue={defaultValue}
      onChange={onChange}
      fullWidth
      validate={(v) => {
        if (!v || v === '') return 'Role is required';
        return true;
      }}
      select>
      {renderRoles()}
    </TextField>
  );
}
RolesSelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

RolesSelect.defaultProps = {};

export default RolesSelect;
