import { App } from '@meronex/app';

const { server } = App;

export const factoriesMng = {
  updateFactory: async (factory) => {
    const result = await server.call('updateFactory', factory);
    return result;
  },

  addFactory: async ({ name }) => {
    const result = await server.call('addFactory', { name });
    return result;
  },
  getFactories: async () => {
    const result = await server.call('getFactories', {});
    return result;
  },
  deleteFactory: async (factoryId) => {
    const result = await server.call('deleteFactory', { _id: factoryId });
    return result;
  },

  getFactoryDrafts: async ({ factoryId, filters }) => {
    const result = await server.call('getFactoryDrafts', {
      factoryId,
      filters,
    });
    return result;
  },

  getUserSelectedFactory: async (factories) => {
    const siteSettings = await App.userSettings.app.get('sites');
    let selectedFactory;
    if (Array.isArray(factories) && factories.length > 0) {
      let selectedFactoryId = siteSettings.selectedFactoryId;
      if (selectedFactoryId) {
        selectedFactory = factories.find((f) => f._id === selectedFactoryId);
        // this could happen when the admin switches domain
      }
      if (!selectedFactory) {
        selectedFactory = factories[0];
      }
    }
    return selectedFactory;
  },

  setUserSelectedFactory: async (factoryId) => {
    return await App.userSettings.app.set('sites', {
      selectedFactoryId: factoryId,
    });
  },

  getInProgressDrafts: async (factoryId) => {
    const result = await server.call('getInProgressDrafts', {
      factoryId,
    });
    return result;
  },

  getRecentlyCompletedWO: async (factoryId) => {
    const result = await server.call('getWorkOrders', {
      factoryId,
      limit: 5,
    });
    return result;
  },
};
