import React, { Component } from 'react';
import { InfiniteLoader, List, WindowScroller } from 'react-virtualized';

import CircularProgress from '@material-ui/core/CircularProgress';
import SpeedDial from '@material-ui/lab/SpeedDial/SpeedDial';
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';

import { AppContext, App } from '@meronex/app';
import { noDataImage2 } from '@meronex/assets';
import BsChevronDoubleUp from '@meronex/icons/bs/BsChevronDoubleUp';
import GiBackwardTime from '@meronex/icons/gi/GiBackwardTime';

import Filters from './components/Filters';
import LogCard from './components/LogCard';
import logsUtil, { timeFullFormat } from './components/common/logsUtils';
import LogDialog from './components/LogDialog';
import { logMng } from './api';

const { eventEmitter } = App;

class Logs extends Component {
  state = {
    remoteRowCount: 10,
    logs: undefined,
    hasMore: true,
    filterOptions: {},
    selectedLogIndex: undefined,
    selectedLog: undefined,
    openCardDialog: false,
    sites: undefined,
    renderData: undefined,
    fastRender: true,
  };
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }
  componentDidMount() {
    this.init();
    this.updateLastLogsVisit();
    eventEmitter.on('event-user-loaded', () => {
      this.init();
    });
    eventEmitter.on('event-refresh-feed', () => {
      this.refreshLogs();
    });
  }
  init = () => {
    const { logs } = this.state;
    window.lastVisitIndexStamp = undefined;
    if (!logs) {
      this.loadMoreRows({ startIndex: 0, stopIndex: 9 });
    } else {
      this.refreshLogs();
    }
  };

  updateLastLogsVisit = () => {
    const { App } = this.context;

    if (App.server) {
      App.server.call('updateLastLogsVisit', {});
    }
    App.fetchUser();
  };

  isEmpty = () => {
    const { logs, fetching } = this.state;
    return Array.isArray(logs) && logs.length === 0 && !fetching;
  };

  /**
   * Reset the logs list (back to the top) while applying
   * the current filters.
   */
  refreshLogs = () => {
    window.lastVisitIndexStamp = undefined;
    this.loadMoreRows({ startIndex: 0, stopIndex: 10, refresh: true });
    this.scrollToTop();
  };

  /**
   * Update the current range index of the logs without resetting the list
   * or scrolling.
   */
  updateLogs = () => {
    const { renderData } = this.state;
    if (renderData) {
      this.loadMoreRows({
        startIndex: renderData.startIndex,
        stopIndex: renderData.stopIndex + 1,
      });
    }
  };

  scrollToTop = () => {
    const list = (this.listRef || {}).current;
    if (list) {
      list.scrollToRow(0);
    }
  };

  setFastRender = (fastRender) => {
    this.setState({
      fastRender,
    });
  };

  isLastVisitIndexStamp = (index) => {
    const { logs } = this.state;
    if (logs) {
      if (typeof window.lastVisitIndexStamp === 'undefined') {
        const isSeen = logsUtil.isSeen(this.state.logs[index]);

        if (isSeen) {
          window.lastVisitIndexStamp = index;
        }
      }
      return window.lastVisitIndexStamp === index;
    }
    return false;
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {
    let _style = { ...style };
    const { logs } = this.state;
    let data;
    if (logs && logs[index]) {
      data = logs[index];
    }

    return (
      <div key={key} style={_style}>
        {data && this.renderLog(data, isScrolling, index)}
      </div>
    );
  };

  renderLog = (log, isScrolling, index) => {
    if (!log) {
      return <div />;
    }

    const { theme } = this.props;

    const styleLastVisit = {
      borderBottom: '1px solid #b84142',
      marginLeft: '20px',
      marginRight: '20px',
      marginBottom: '20px',
      textAlign: 'center',
      color: '#908e8e',
      fontSize: '13px',
    };

    const styleLastVisitText = {
      backgroundColor: theme.palette.background.paper,
      width: '230px',
      borderRadius: '10px',
      position: 'absolute',
      left: '0px',
      right: '0px',
      top: '0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      color: theme.palette.text.primary,
    };

    const isLastVisitMark = this.isLastVisitIndexStamp(index);
    let lastVisitDate = new Date();
    if (isLastVisitMark) {
      lastVisitDate = logsUtil.getLastVisitDate();
    }
    return (
      <div
        key={log._id}
        id={log._id}
        style={{
          marginTop: isLastVisitMark ? '10px' : 'inherit',
          marginBottom: '20px',
          width: '100%',
        }}>
        {isLastVisitMark && (
          <div style={styleLastVisit}>
            <div style={styleLastVisitText}>
              Last Visit ({logsUtil.timeAgo(lastVisitDate)})
            </div>
          </div>
        )}
        <LogCard
          isScrolling={isScrolling}
          fastRender={this.state.fastRender}
          log={log}
          onClick={() => {
            this.setState({
              selectedLog: log,
              selectedLogIndex: index,
              openCardDialog: true,
            });
          }}
        />
      </div>
    );
  };

  isRowLoaded = ({ index }) => {
    const { logs } = this.state;
    return !!logs[index];
  };

  getLoader = () => {
    const { logs } = this.state;
    const firstLoad = !logs || logs.length === 0;

    return (
      <div
        key={'loader'}
        style={{
          marginRight: '-30px',
          marginTop: firstLoad ? '30px' : '10px',
          marginBottom: '125px',
          textAlign: 'center',
        }}>
        {' '}
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={24}
          thickness={4}
        />
        {firstLoad && <div style={{ color: 'gray' }}>Loading...</div>}
      </div>
    );
  };

  loadMoreRows = async ({ startIndex, stopIndex, refresh = false }) => {
    let { logs, hasMore, filterOptions } = this.state;

    const { App } = this.context;
    if (!App.server.connected) {
      console.warn('Waiting for server to connect,');
    } else {
      let result;
      console.log(`load more ${startIndex} ${stopIndex}`);
      if (hasMore) {
        const fetchedLogs = await logMng.getLogs(
          stopIndex - startIndex,
          startIndex,
          filterOptions
        );
        let _logs = logs;
        if (!_logs || refresh) {
          _logs = [];
        }
        if (fetchedLogs.length > 0) {
          // Update the underlying logs list based on the fetched ranged
          for (let i = startIndex; i < stopIndex; i += 1) {
            // Ignore undefined storeItems
            let item = fetchedLogs[i - startIndex];

            if (item) {
              _logs[i] = item;
            }
          }
        }

        let selectedLog = this.state.selectedLog;
        if (selectedLog) {
          console.log(`updating selected log  ${selectedLog}`);
          selectedLog = _logs[this.state.selectedLogIndex];
        }
        if (_logs.length > 0) {
          this.setState({
            logs: _logs,
            remoteRowCount: _logs.length + 10,
            selectedLog,
          });
        } else {
          this.setState({
            hasMore: false,
            logs: _logs,
            selectedLog,
          });
        }
        return Promise.resolve(_logs);
      } else {
        console.info('has more is false');
      }
    }
  };

  setFilterOptions = (filterOptions) => {
    this.setState(
      {
        logs: undefined,
        hasMore: true,
        filterOptions,
      },
      () => {
        this.refreshLogs();
      }
    );
  };
  render() {
    const {
      openCardDialog,
      selectedLog,
      logs,
      remoteRowCount,
      renderData,
    } = this.state;

    const { App } = this.context;
    const { size, server } = App;
    const { theme } = this.props;

    const loaded = logs && server.connected && size;

    return (
      <div>
        <Filters
          setFilterOptions={this.setFilterOptions}
          setFastRender={this.setFastRender}
          fastRenderDefault={this.state.fastRender}
        />
        <div
          id={'feed-container'}
          style={{
            marginTop: '10px',
          }}>
          {!loaded && this.getLoader()}
          {loaded && (
            <InfiniteLoader
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={this.loadMoreRows}
              rowCount={remoteRowCount}>
              {({ onRowsRendered, registerChild }) => (
                <div ref={registerChild}>
                  <WindowScroller>
                    {({ height, isScrolling, onChildScroll, scrollTop }) => (
                      <>
                        <List
                          ref={this.listRef}
                          autoHeight
                          style={{
                            width: '100%',
                          }}
                          height={height}
                          isScrolling={isScrolling}
                          scrollTop={scrollTop}
                          onScroll={onChildScroll}
                          onRowsRendered={(data) => {
                            if (data) {
                              this.setState({
                                renderData: data,
                              });
                              onRowsRendered(data);
                            }
                          }}
                          noRowsRenderer={() => {
                            return (
                              <div
                                style={{
                                  marginTop: '50px',
                                  textAlign: 'center',
                                  padding: '22px',
                                  margin: '15px',
                                }}>
                                <img
                                  src={noDataImage2}
                                  style={{ width: '150px' }}
                                />
                                <div
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    color: theme.palette.text.primary,
                                    marginTop: '10px',
                                  }}>
                                  NO LOGS AVAILABLE
                                </div>
                              </div>
                            );
                          }}
                          scrollToAlignment={'center'}
                          rowCount={logs.length}
                          rowHeight={({ index }) => {
                            const lastCard = index === logs.length - 1;
                            const isLastVisitMark = this.isLastVisitIndexStamp(
                              index
                            );
                            if (lastCard) {
                              return 320;
                            } else if (isLastVisitMark) {
                              return 240;
                            }
                            return 220;
                          }}
                          rowRenderer={this.rowRenderer}
                          width={size.width}
                        />
                        {logs && renderData && isScrolling && (
                          <div
                            style={{
                              position: 'fixed',
                              top: '85px',
                              width: '100%',
                              textAlign: 'center',
                            }}>
                            <div
                              style={{
                                width: '240px',
                                margin: 'auto',
                                backgroundColor: 'rgba(25, 25, 25, 0.74)',
                                color: 'white',
                                borderRadius: '10px',
                                padding: '2px',
                                fontSize: '15px',
                              }}>
                              <div
                                style={{
                                  display: 'inline-block',
                                  position: 'relative',
                                  left: '-8px',
                                  top: '2px',
                                }}>
                                <GiBackwardTime size={22} />
                              </div>
                              <div
                                style={{
                                  display: 'inline-block',
                                  position: 'relative',
                                  top: '-5px',
                                }}>
                                {timeFullFormat(
                                  (logs[renderData.startIndex] || {}).createdAt
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {renderData && renderData.startIndex > 1 && (
                          <div
                            style={{
                              position: 'fixed',
                              top: '40px',
                              width: '100%',
                              textAlign: 'center',
                            }}>
                            <SpeedDial
                              className={'logs-scroll-top'}
                              style={{
                                transition: 'none',
                                position: 'relative',
                                top: '-40px',
                              }}
                              ariaLabel="SpeedDial openIcon example"
                              onClick={() => {
                                window.scrollTo({
                                  top: 0,
                                });
                              }}
                              icon={<BsChevronDoubleUp size={30} />}
                              onClose={() => {}}
                              onOpen={() => {}}
                              open={false}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </WindowScroller>
                </div>
              )}
            </InfiniteLoader>
          )}
          {selectedLog && (
            <LogDialog
              open={openCardDialog}
              card={selectedLog}
              updateLogs={this.updateLogs}
              onClose={() => {
                this.updateLogs();
                this.setState({
                  selectedLog: null,
                  openCardDialog: false,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTheme(Logs);

Logs.contextType = AppContext;
