import React from 'react';
import PropTypes from 'prop-types';

import { storesMng } from '../../../stores/api/storesMng';
import Avatar from 'react-avatar';
import { App } from '@meronex/app';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const StoreItemSelects = React.forwardRef((props, ref) => {
  const { label, onChange, excludedIds = [], storeId } = props;

  const [storeItems, setStoreItems] = React.useState();
  const [value, setValue] = React.useState();

  const fetchStoreItems = async () => {
    const result = await storesMng.getStoreItems(storeId);
    setValue(null);
    setStoreItems(result);
  };

  React.useEffect(() => {
    fetchStoreItems();
    setValue({});
  }, [storeId]);

  const _onChange = (e, value) => {
    setValue(value);
    onChange(value.item);
  };

  const filterStoreItem = (storeItems) => {
    let _filteredItems = storeItems;

    if (Array.isArray(_filteredItems)) {
      if (_filteredItems) {
        _filteredItems = storeItems.filter(
          (i) => !excludedIds.includes(i.type._id)
        );
      }
    }
    return _filteredItems;
  };

  const getSelectOptions = () => {
    let _itemDoc;
    let imgUrl;
    return (_filteredItems || []).map((item) => {
      _itemDoc = item;
      if (typeof _itemDoc === 'string') {
        _itemDoc = storeItems.find((i) => i._id === _itemDoc);
      }
      return {
        _id: _itemDoc._id,
        item: _itemDoc,
      };
    });
  };

  const getLabel = (items) => {
    let _label = label;
    if (!Array.isArray(items)) {
      _label = 'Loading..';
    } else if (items.length === 0) {
      _label = 'No items available.';
    }
    return _label;
  };

  const _filteredItems = filterStoreItem(storeItems);
  const _label = getLabel(_filteredItems);
  const _selectOptions = getSelectOptions();

  return (
    <>
      <Autocomplete
        key={storeId} // a hack to force re-rendering
        // from https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
        options={_selectOptions}
        fullWidth
        getOptionLabel={(o) => {
          if (!o) {
            return '';
          }
          let _item = o;
          if (o.item) {
            _item = o.item;
          }

          return (_item.type || {}).name;
        }}
        disabled={
          !storeId ||
          !Array.isArray(_filteredItems) ||
          _filteredItems.length === 0
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={<span>{_label}</span>}
            variant="outlined"
          />
        )}
        value={value}
        onChange={(e, v) => {
          if (v) {
            _onChange(e, v);
          }
        }}
        renderOption={(o) => {
          console.log(o);
          const item = o.item;
          const index = o.index;

          const getImgUrl = (item) => {
            console.log(item);
            if (!item || !item.type || !item.type.image) {
              return null;
            }
            return item.type.image.thumbUrl || item.type.image.imgUrl;
          };
          const imgUrl = getImgUrl(item);
          console.log(imgUrl);
          if (!item) {
            return <div />;
          }
          return (
            <div
              value={item._id}
              key={item._id}
              style={{
                width: '100%',
              }}>
              {imgUrl && (
                <img
                  style={{
                    width: '40px',
                    marginRight: '10px',
                  }}
                  src={imgUrl}
                />
              )}
              {!imgUrl && (
                <Avatar
                  style={{
                    marginRight: '10px',
                  }}
                  name={App.utils.sanitize(item.type.name)}
                  size={40}
                  round={'15px'}
                />
              )}
              {item.type.name}
            </div>
          );
        }}
      />
    </>
  );
});

StoreItemSelects.propTypes = {
  storeId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  excludedIds: PropTypes.array,
};

StoreItemSelects.defaultProps = {
  label: 'Store Items',
  onChange: () => {},
  excludedIds: [],
};
export default StoreItemSelects;
