import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

import CommentDialog from '../CommentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  backdrop: {
    zIndex: 0,
  },
}));

export default function CommentBox(props) {
  const { card, onAddComment } = props;
  const classes = useStyles();
  const [openCommentDialog, setOpenCommentDialog] = React.useState(undefined);
  const theme = useTheme();
  if (!card) {
    return null;
  }

  return (
    <Paper
      component="form"
      className={classes.root}
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        backgroundColor: theme.palette.background.default,
      }}>
      <InputBase
        className={classes.root}
        style={{
          width: '80%',
          minHeight: '50px',
        }}
        id="filled-textarea"
        label="Comment"
        placeholder="Add a new comment"
        multiline
        variant="filled"
        readOnly
        onClick={() => {
          setOpenCommentDialog(true);
        }}
      />
      <CommentDialog
        authorId={card.authorId}
        cardId={card._id}
        open={openCommentDialog}
        onAdd={() => onAddComment()}
        onClose={() => {
          setOpenCommentDialog(false);
        }}
      />
    </Paper>
  );
}

CommentBox.propTypes = {
  card: PropTypes.object,
  onAddComment: PropTypes.func,
};
