import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/core/SvgIcon/SvgIcon';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import { withTheme } from '@material-ui/core/styles';

import { App } from '@meronex/app';
import MdFilterList from '@meronex/icons/md/MdFilterList';
import FaRegBuilding from '@meronex/icons/fa/FaRegBuilding';
import MdPersonOutline from '@meronex/icons/md/MdPersonOutline';
import MdWarning from '@meronex/icons/md/MdWarning';
import AiOutlineOrderedList from '@meronex/icons/ai/AiOutlineOrderedList';
import TiTag from '@meronex/icons/ti/TiTag';
import MdSearch from '@meronex/icons/ios/MdSearch';
import FaCheckSquare from '@meronex/icons/fa/FaCheckSquare';
import FaRegCommentDots from '@meronex/icons/fa/FaRegCommentDots';

import { sitesMng } from '../../sites/api';

const { utils } = App;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const initFormData = {
  site: 'all',
  type: 'all',
  types: [],
  author: 'all',
  priority: 'all',
};

class Filters extends Component {
  state = {
    expanded: false,
    formData: initFormData,
    sites: [],
    authors: [],
    fastRender: this.props.fastRenderDefault,
  };

  static propTypes = {
    setFilterOptions: PropTypes.func,
    fastRenderDefault: PropTypes.bool,
  };
  static defaultProps = {};

  componentDidMount() {
    // code to run on component mount
    this.fetchSites();
    // ensure that we call fetch sites when reconnect, this happen
    // on refresh the browser or reconnection while the page is on.
    App.eventsManager.on('event-connected', Filters.constructor.name, () => {
      this.fetchSites();
    });

    // code to run on component mount
    this.fetchAuthors();
    // ensure that we call fetch authors when reconnect, this happen
    // on refresh the browser or reconnection while the page is on.
    App.eventsManager.on('event-connected', Filters.constructor.name, () => {
      this.fetchAuthors();
    });
  }

  fetchSites = async () => {
    try {
      const sites = await sitesMng.getSites();
      this.setState({
        sites,
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchAuthors = async () => {
    try {
      const authors = await sitesMng.getAuthors();
      this.setState({
        authors,
      });
    } catch (e) {
      console.log(e);
    }
  };

  generateAuthorOptions = () => {
    const { authors } = this.state;

    let authorsDisplay = [];

    authorsDisplay = authors.map((author) => {
      return (
        <MenuItem key={author._id} value={author._id}>
          {author.profile && (
            <>
              <Avatar
                style={{
                  marginRight: '10px',
                  display: 'inline-block',
                  height: '25px',
                  width: '25px',
                  textAlign: 'center',
                }}
                alt={`${author.profile.firstName} ${author.profile.lastName}`}
                src={`${author.profile.avatar ? author.profile.avatar : ''}`}
              />{' '}
              {author.profile.firstName} {author.profile.lastName}
            </>
          )}
        </MenuItem>
      );
    });

    authorsDisplay.unshift(
      <MenuItem key="all" value={'all'}>
        All
      </MenuItem>
    );

    return authorsDisplay;
  };

  generateSiteOptions = () => {
    const { sites } = this.state;

    let sitesDisplay = [];

    sitesDisplay = sites.map((site) => {
      return (
        <MenuItem key={site._id} value={site._id}>
          {site.name}
        </MenuItem>
      );
    });

    sitesDisplay.unshift(
      <MenuItem key="all" value={'all'}>
        All
      </MenuItem>
    );

    return sitesDisplay;
  };

  render() {
    const { formData, expanded } = this.state;
    const { setFilterOptions, setFastRender, theme } = this.props;
    const types = [...formData.types];

    if (types.length === 0) {
      types.push('All');
    }
    const dropdownStyle = {
      marginBottom: '16px',
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const filterIconStyle = {
      display: 'inline-block',
      color: '#b7b7b7',
      width: '20px',
      height: '20px',
    };

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    return (
      <div
        style={{
          marginTop: '15px',
          marginLeft: '15px',
          marginRight: '15px',
        }}>
        <ExpansionPanel
          className={'log-filter'}
          expanded={expanded}
          onChange={() => {
            this.setState({ expanded: !expanded });
          }}>
          <ExpansionPanelSummary
            style={{
              width: '100%',
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <div
              style={{
                fontSize: '16px',
                textTransform: 'uppercase',
                width: '100%',
                color: theme.palette.text.primary,
              }}>
              <MdFilterList /> FLTERS{' '}
              <div
                style={{
                  display: 'inline-block',
                  float: 'right',
                  marginRight: '-20px',
                }}>
                {formData.site !== 'all' && (
                  <div style={filterIconStyle}>
                    <FaRegBuilding size={12} />
                  </div>
                )}
                {formData.author !== 'all' && (
                  <div style={filterIconStyle}>
                    <MdPersonOutline size={12} />
                  </div>
                )}
                {formData.type !== 'all' && (
                  <div style={filterIconStyle}>
                    <TiTag size={12} />
                  </div>
                )}
                {formData.priority !== 'all' && (
                  <div style={filterIconStyle}>
                    <AiOutlineOrderedList size={12} />
                  </div>
                )}
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <FormControl
                fullWidth
                className={useStyles.formControl}
                style={dropdownStyle}>
                <InputLabel id="demo-simple-select-label">
                  {' '}
                  <FaRegBuilding size={15} /> Site
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.site}
                  fullWidth
                  onChange={(e) => {
                    const updatedData = { ...formData };
                    updatedData.site = e.target.value;
                    this.setState(
                      { formData: updatedData, expanded: false },
                      () => {
                        setFilterOptions(updatedData);
                      }
                    );
                  }}>
                  {this.generateSiteOptions()}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                className={useStyles.formControl}
                style={dropdownStyle}>
                <InputLabel id="demo-simple-select-label">
                  <MdPersonOutline size={20} /> Author
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.author}
                  fullWidth
                  onChange={(e) => {
                    const updatedData = { ...formData };
                    updatedData.author = e.target.value;
                    this.setState(
                      { formData: updatedData, expanded: false },
                      () => {
                        setFilterOptions(updatedData);
                      }
                    );
                  }}>
                  {this.generateAuthorOptions()}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                className={useStyles.formControl}
                style={{
                  marginBottom: '16px',
                }}>
                <InputLabel id="demo-simple-select-label">
                  <TiTag /> Type
                </InputLabel>
                <Select
                  id="type-multi-select"
                  multiple
                  value={types}
                  displayEmpty
                  onChange={(event) => {
                    const updatedData = { ...formData };
                    updatedData.types = utils.removeFromArray(
                      event.target.value,
                      'All'
                    );
                    this.setState(
                      {
                        formData: updatedData,
                      },
                      () => {
                        setFilterOptions(updatedData);
                      }
                    );
                  }}
                  input={<Input />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <span>All</span>;
                    }
                    if (selected.length > 1) {
                      selected = utils.removeFromArray(selected, 'All');
                    }
                    return utils.capitalize(selected).join(', ');
                  }}
                  MenuProps={MenuProps}>
                  <MenuItem key={'observation'} value={'observation'}>
                    <Checkbox checked={types.indexOf('observation') > -1} />
                    <MdSearch />
                    &nbsp;Observations
                  </MenuItem>
                  <MenuItem key={'incident'} value={'incident'}>
                    <Checkbox checked={types.indexOf('incident') > -1} />
                    <MdWarning />
                    &nbsp;Incident
                  </MenuItem>
                  <MenuItem key={'feedback'} value={'feedback'}>
                    <Checkbox checked={types.indexOf('feedback') > -1} />
                    <FaRegCommentDots />
                    &nbsp;Feedback
                  </MenuItem>
                  <MenuItem key={'survey'} value={'survey'}>
                    <Checkbox checked={types.indexOf('survey') > -1} />
                    <FaCheckSquare />
                    &nbsp;Surveys
                  </MenuItem>
                </Select>
              </FormControl>
              {(formData.type === 'observation' ||
                formData.type === 'incident') && (
                <FormControl
                  fullWidth
                  className={useStyles.formControl}
                  style={dropdownStyle}>
                  <InputLabel id="demo-simple-select-label">
                    <AiOutlineOrderedList size={14} /> Priority
                  </InputLabel>
                  <Select
                    value={formData.priority}
                    fullWidth
                    onChange={(e) => {
                      const updatedData = { ...formData };
                      updatedData.priority = e.target.value;
                      this.setState(
                        { formData: updatedData, expanded: false },
                        () => {
                          setFilterOptions(updatedData);
                        }
                      );
                    }}>
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'urgent'}>Urgent</MenuItem>
                    <MenuItem value={'high'}>High</MenuItem>
                    <MenuItem value={'medium'}>Medium</MenuItem>
                    <MenuItem value={'low'}>Low</MenuItem>
                  </Select>
                </FormControl>
              )}

              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Fast render feed</Grid>
                <Grid item>
                  <Switch
                    checked={this.state.fastRender}
                    onChange={() => {
                      this.setState(
                        {
                          fastRender: !this.state.fastRender,
                        },
                        () => {
                          setFastRender(this.state.fastRender);
                        }
                      );
                    }}
                    name="fastRender"
                    color="primary"
                  />
                </Grid>
              </Grid>

              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.setState(
                      { formData: initFormData, expanded: false },
                      () => {
                        setFilterOptions(initFormData);
                      }
                    );
                  }}>
                  Reset
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withTheme(Filters);
