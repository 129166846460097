import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, ActionBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddFieldDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Add Field
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginBottom: '20px',
          }}>
          <TextField
            select
            value={'range'}
            style={{
              width: '100%',
            }}>
            <MenuItem value={'range'}>Range</MenuItem>
            <MenuItem value={'checkbox'}>Checkbox</MenuItem>
            <MenuItem value={'text'}>Text</MenuItem>
          </TextField>
        </div>
        <div
          style={{
            marginBottom: '20px',
          }}>
          <TextField
            label={'Text'}
            style={{
              width: '100%',
            }}
          />
        </div>
        <div
          style={{
            marginBottom: '20px',
          }}>
          <TextField
            type={'number'}
            label={'Points'}
            style={{
              width: '100%',
            }}
          />
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: '20px',
            marginTop: '20px',
          }}>
          <ActionBtn label={'Add Field'} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddFieldDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddFieldDialog;
