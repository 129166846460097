import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, Form, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { factoriesMng } from '../../api/FactoriesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditFactoryDialog(props) {
  const { open, onClose, onEdit, factory } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(factory);

  const editFactory = async () => {
    setLoading(true);
    const _updatedFactory = { ...factory };
    _updatedFactory.name = formData.values.name;

    try {
      const result = await factoriesMng.updateFactory(_updatedFactory);
      onEdit();
      onClose();
      toast.success('Production site updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (!factory) {
    return null;
  }
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Edit Production Site
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: '-15px',
        }}>
        <Form
          onUpdate={(_formData) => {
            setFormData(_formData);
          }}>
          <TextField
            name={'name'}
            label="Name"
            autoFocus={true}
            defaultValue={factory.name}
            variant="outlined"
            validate={(v) => {
              if (!v || v === '') return 'Name is required';
              return true;
            }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Form>
        <div
          style={{
            marginBottom: '20px',
            marginTop: '10px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (formData && !formData.isValid)}
            label={<span>Save</span>}
            onClick={() => {
              editFactory();
            }}
            onEnter={() => {
              editFactory();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditFactoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default EditFactoryDialog;
