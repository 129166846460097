import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//TODO: replace with <Icons.X/>
import IList from '@meronex/icons/ios/MdList';

import AddItemCategoryDialog from './AddItemCategoryDialog';
import EditItemCategoryDialog from './EditItemCategoryDialog';
import ItemCategoriesList from './ItemCategoriesList';

import { itemCategoriesMng } from '../api/itemCategoriesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ItemCategoriesDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;

  const [openAddItemCategory, setOpenAddItemCategory] = React.useState();
  const [openEditItemCategory, setOpenEditItemCategory] = React.useState();
  const [selectedItemCategory, setSelectedItemCategory] = React.useState();
  const [itemCategories, setItemCategories] = React.useState();

  const getItemCategories = async () => {
    console.log('getItemCategories');
    const itemCategories = await itemCategoriesMng.getItemCategories();
    setItemCategories(itemCategories);
  };

  React.useEffect(() => {
    getItemCategories();
  }, []);

  React.useEffect(() => {
    if (selectedItemCategory) {
      setOpenEditItemCategory(true);
    }
  }, [selectedItemCategory]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IList /> Item Categories
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <ItemCategoriesList
            itemCategories={itemCategories}
            onClick={(itemCategory) => {
              console.log(itemCategory);
            }}
            onEdit={(itemCategory) => {
              console.log(itemCategory);
              setSelectedItemCategory(itemCategory);
              setOpenEditItemCategory(true);
            }}
            onDelete={getItemCategories}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            marginTop: '20px',
          }}>
          <ActionBtn
            onClick={() => {
              setSelectedItemCategory(false);
              setOpenAddItemCategory(true);
            }}
            label={<div>Add Item Category</div>}
          />
        </div>
        {openAddItemCategory && (
          <AddItemCategoryDialog
            open={openAddItemCategory}
            onAdd={getItemCategories}
            onClose={() => {
              setOpenAddItemCategory(false);
              setSelectedItemCategory(null);
            }}
          />
        )}
        {openEditItemCategory && (
          <EditItemCategoryDialog
            open={openEditItemCategory}
            itemCategory={selectedItemCategory}
            onEdit={getItemCategories}
            onClose={() => {
              setOpenEditItemCategory(false);
              setSelectedItemCategory(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

ItemCategoriesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ItemCategoriesDialog;
