import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import { Weather, Section, toast } from '@meronex/components';
import { noImage2 } from '@meronex/assets';
import INews from '@meronex/icons/bs/BsNewspaper';
import IConfigure from '@meronex/icons/go/GoSettings';
import IDrag from '@meronex/icons/ri/RiDragMove2Fill';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import IRotate from '@meronex/icons/bi/BiRefresh';
import IFullScreen from '@meronex/icons/md/MdFullscreen';
import IFullScreenExit from '@meronex/icons/mdc/MdcFullscreenExit';
import LinearProgress from '@material-ui/core/LinearProgress';
import IClose from '@meronex/icons/mdc/MdcClose';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { useTheme } from '@material-ui/core';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import moment from 'moment';

import AddNewsSectionDialog from './AddNewsSectionDialog';
import EditNewsSectionDialog from './EditNewsSectionDialog';

import NewsSection from './NewsSection';
import { newsMng } from './api';

const { server } = App;

const NEWS_REFRESH_INTERVAL = Math.floor(1.9 * 60 * 60 * 1000);
const REFRESH_TIME_AGO_INTERVAL = 1000 * 60;

const DragHandle = SortableHandle(() => (
  <div
    style={{
      display: 'inline-block',
      position: 'relative',
      color: 'white',
      height: '40px',
      width: '40px',
    }}>
    <span style={{ position: 'relative', top: '12px', left: '-6px' }}>
      <IDrag />
    </span>
  </div>
));

const SortableItem = SortableElement(
  ({ value, onItemClick, refresh, fullscreen }) => (
    <div style={{ position: 'relative' }}>
      <NewsSection
        fullscreen={fullscreen}
        refresh={refresh}
        newsSection={value}
        onItemClick={onItemClick}
        dragHandle={<DragHandle />}
      />
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, refresh, onItemClick, fullscreen }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            fullscreen={fullscreen}
            onItemClick={onItemClick}
            key={`item-${value._id}`}
            index={index}
            value={value}
            refresh={refresh}
          />
        ))}
      </div>
    );
  }
);

export default function NewsTile(props) {
  const { alwaysExpand } = props;

  const handle = useFullScreenHandle();

  const [expanded, setExpanded] = React.useState(alwaysExpand);
  const [openAddNewsSection, setOpenAddNewsSection] = React.useState();
  const [openEditNewsSection, setOpenEditNewsSection] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();

  const [userNews, setUserNews] = React.useState();

  const [refresh, setRefresh] = React.useState();
  const [refreshTimeAgo, setRefreshTimeAgo] = React.useState();

  const theme = useTheme();

  React.useEffect(() => {
    window.clearInterval(window.refreshTimeAgoInterval);
    window.clearInterval(window.refreshTimeout);

    return () => {
      window.clearInterval(window.refreshTimeAgoInterval);
      window.clearInterval(window.refreshTimeout);

      setRefresh(false);
    };
  }, []);

  React.useEffect(() => {
    if (expanded) {
      // playSound('netflix', 0.5);
      if (!userNews) {
        onRefresh();
      }
    }

    if (!expanded && handle.active) {
      handle.exit();
    }
  }, [expanded]);

  const fetchData = async () => {
    let userNewsSection = await server.call('getUserNewsSections', {});
    setUserNews(userNewsSection);
  };

  const savedUpdatedIndex = (updatedList) => {
    newsMng.saveUpdatedNewsSort(updatedList);
  };

  const monitorUpdateTimeAgo = () => {
    if (expanded) {
      window.refreshTimeAgoInterval = window.setInterval(function () {
        if (window.lastRefreshDate) {
          setRefreshTimeAgo(App.utils.time.timeAgo(window.lastRefreshDate));
        }
      }, REFRESH_TIME_AGO_INTERVAL);
    }
  };

  const onSortEnd = (data) => {
    let updatedList = arrayMoveImmutable(
      userNews,
      data.oldIndex,
      data.newIndex
    );

    updatedList = updatedList.map((u, index) => {
      u.index = index;
      return u;
    });
    setUserNews(updatedList);
    savedUpdatedIndex(updatedList);
  };

  const onRefresh = async () => {
    const date = new Date();
    window.lastRefreshDate = date;

    if (!refresh) {
      clearTimeout(window.refreshTimeout);
      window.clearInterval(window.refreshTimeAgoInterval);

      setRefresh(true);
      try {
        await fetchData();
      } catch (e) {
        console.error(e);
      } finally {
        setRefresh(false);
      }

      // setup next refresh
      window.refreshTimeout = setTimeout(async () => {
        await onRefresh();
      }, NEWS_REFRESH_INTERVAL);

      monitorUpdateTimeAgo();

      setRefreshTimeAgo(App.utils.time.timeAgo(date));
      toast.success('🗞️ News updated successfully', {
        position: 'bottom-right',
        autoClose: 1300,
      });
    }
  };

  const renderSections = () => {
    if (Array.isArray(userNews)) {
      return (
        <SortableList
          key={userNews._id}
          refresh={refresh}
          items={userNews}
          onItemClick={(i) => {
            if (handle.active) {
              handle.exit();
            }
            setSelectedItem(i);
            setOpenEditNewsSection(true);
          }}
          useDragHandle
          lockAxis={'y'}
          onSortEnd={onSortEnd}
        />
      );
    }
  };
  return (
    <Paper
      className={'noselect news-view'}
      onClick={() => {}}
      style={{
        textAlign: 'center',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: 'rgba(14, 14, 14, 0.9)',
        color: theme.appTheme.tilesFont,
        fontSize: '14px',
        minHeight: '55px',
        marginBottom: '8px',
      }}>
      <div>
        <FullScreen handle={handle} className={'fullscreen'}>
          <div
            onClick={() => {
              setExpanded(!expanded);
            }}
            style={{
              fontSize: '18px',
              fontWeight: '400',
              position: 'relative',
              backgroundColor: '#ef000a',
              height: '55px',
            }}>
            <span
              style={{
                position: 'relative',
                top: '18px',
              }}>
              <span
                style={{
                  position: 'relative',
                  top: '2px',
                }}>
                <INews size={18} />
              </span>
              <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                News
              </span>
            </span>{' '}
          </div>
          <Collapse
            in={expanded}
            collapsedHeight={0}
            style={{
              overflow: 'auto',
            }}>
            <div
              style={{
                padding: '5px',
                backgroundColor: 'rgba(14, 14, 14, 0.9)',
              }}>
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    marginBottom: '12px',
                    minHeight: '45px',
                    marginTop: '-8px',
                    fontSize: '12px',
                  }}>
                  <div
                    style={{
                      color: 'white',
                    }}>
                    <div
                      onClick={handle.active ? handle.exit : handle.enter}
                      style={{
                        display: 'inline-block',
                        position: 'relative',
                        top: '7px',
                        left: '-22px',
                      }}>
                      {handle.active ? (
                        <IFullScreenExit size={20} />
                      ) : (
                        <IFullScreen size={20} />
                      )}
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        position: 'relative',
                      }}>
                      <div style={{ display: 'inline-block' }}>
                        {moment().format('MMM Do YY')} |
                      </div>
                      <Weather
                        appid={'5d9141caf6014f9fbbd2aff1f06a3b6b'}
                        city={'Abu Dhabi, AE'}
                      />
                    </div>
                    <div
                      onClick={() => {
                        onRefresh();
                      }}
                      style={{
                        top: '10px',
                        right: '-28px',
                        display: 'inline',
                        position: 'relative',
                        color: 'lightgray',
                      }}>
                      <IRotate size={24} className={refresh ? 'rotate' : ''} />
                    </div>
                  </div>
                </div>
              </div>

              {!refreshTimeAgo && (
                <div>
                  loading your news, please wait.
                  <div
                    style={{
                      marginTop: '10px',
                      marginBottom: '40px',
                    }}>
                    <LinearProgress />
                  </div>
                </div>
              )}
              {refreshTimeAgo && <div>updated {refreshTimeAgo}</div>}
              {renderSections()}
              {refreshTimeAgo && !handle.active && (
                <div>
                  <Button
                    onClick={() => {
                      if (handle.active) {
                        handle.exit();
                      }
                      setOpenAddNewsSection(true);
                    }}
                    style={{
                      borderRadius: '10px',
                      padding: '5px',
                      marginTop: '10px',
                      marginBottom: '15px',
                      color: 'white',
                      fontSize: '12px',
                    }}>
                    ADD SECTION
                  </Button>
                </div>
              )}
            </div>
          </Collapse>
        </FullScreen>
      </div>
      <AddNewsSectionDialog
        refresh={fetchData}
        open={openAddNewsSection}
        onClose={() => {
          setOpenAddNewsSection(false);
        }}
      />
      {selectedItem && (
        <EditNewsSectionDialog
          refresh={fetchData}
          open={openEditNewsSection}
          newsSection={selectedItem}
          onClose={() => {
            setOpenEditNewsSection(false);
          }}
        />
      )}
    </Paper>
  );
}

NewsTile.defaultProps = {
  bgColor: '#008000ab',
  onClose: () => {
    console.log('on close click');
  },
};

NewsTile.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
  alwaysExpand: PropTypes.bool,
  onClose: PropTypes.func,
};
