import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { App } from '@meronex/app';
import { SaveBtn, CloseBtn, ImageUploader } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const { server } = App;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  uploadBtnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  uploadBtn: {
    borderRadius: '20px',
    width: '100%',
    height: '45px',
    marginTop: '5px',
    marginBottom: '25px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function ObservationDialog(props) {
  const classes = useStyles();

  const initFormData = {
    priority: 'low',
  };
  const { open, onClose, sites } = props;

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);

  const [formData, setFormData] = React.useState(initFormData);

  const [descError, setDescError] = React.useState(false);

  const previewContainerRef = React.useRef();

  const onSave = async () => {
    const noImg = !imgUrl || imgUrl.length < 1;
    const noTitle = !formData || !formData.title;

    if (noImg && noTitle) {
      if (noTitle) {
        setDescError(true);
      }
      toast.error('Either an image or title is required!', {
        autoClose: 2000,
      });
      return;
    }

    formData.imgUrl = imgUrl;
    formData.thumbUrl = thumbUrl;

    try {
      const id = await server.call('addLogCard', {
        logDetails: formData,
        logType: 'observation',
      });
      formData._id = id;
      toast.success('Observation was successfully added!');
    } catch (e) {
      console.log(e);
      toast.error('Ops, something went wrong');
    }
    if (props.onSave) {
      props.onSave();
    }
    if (props.onClose) {
      props.onClose();
    }
    reset();
  };

  const reset = () => {
    setDescError(false);
    setImgUrl(null);
    setThumbUrl(null);
    setFormData({
      priority: 'low',
      description: '',
      siteId: undefined,
    });
  };

  return (
    <div>
      <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
        <DialogTitle>
          Add Observation{' '}
          <CloseBtn
            onClick={() => {
              reset();
              onClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <ImageUploader
              previewContainer={previewContainerRef}
              onUploadStart={() => {
                setUploading(true);
              }}
              onUploadSuccess={(imageData) => {
                console.log(imageData);
                setImgUrl(imageData.imgUrl);
                setThumbUrl(imageData.thumbUrl);
                setUploading(false);
              }}
            />
            <TextField
              label="Title"
              style={{ margin: 8 }}
              value={formData.title}
              onChange={(e) => {
                const updatedData = { ...formData };
                updatedData.title = e.target.value;
                setFormData(updatedData);
              }}
              variant={'outlined'}
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={null}
            />

            <TextField
              label="Location"
              style={{ margin: 8 }}
              variant={'outlined'}
              value={formData.location}
              onChange={(e) => {
                const updatedData = { ...formData };
                updatedData.location = e.target.value;
                setFormData(updatedData);
              }}
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              // error={descError}
            />

            <TextField
              label="Related Team for Action"
              style={{ margin: 8 }}
              variant={'outlined'}
              value={formData.targetGroup}
              onChange={(e) => {
                const updatedData = { ...formData };
                updatedData.targetGroup = e.target.value;
                setFormData(updatedData);
              }}
              fullWidth
              select
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              // error={descError}
            >
              <MenuItem value={'hk'}>HM</MenuItem>
              <MenuItem value={'dm'}>DM</MenuItem>
              <MenuItem value={'fmSUp'}>FM Sup</MenuItem>
              <MenuItem value={'mng'}>Management</MenuItem>
              <MenuItem value={'security'}>Security</MenuItem>
            </TextField>
            <TextField
              label="Note"
              style={{ margin: 8 }}
              variant={'outlined'}
              value={formData.note}
              onChange={(e) => {
                const updatedData = { ...formData };
                updatedData.note = e.target.value;
                setFormData(updatedData);
              }}
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              // error={descError}
            />

            {/*<FormControl fullWidth className={classes.formControl}>*/}
            {/*  <InputLabel id="demo-simple-select-label">Priority</InputLabel>*/}
            {/*  <Select*/}
            {/*    labelId="demo-simple-select-label"*/}
            {/*    id="demo-simple-select"*/}
            {/*    value={formData.priority}*/}
            {/*    fullWidth*/}
            {/*    onChange={(e) => {*/}
            {/*      const updatedData = { ...formData };*/}
            {/*      updatedData.priority = e.target.value;*/}
            {/*      setFormData(updatedData);*/}
            {/*    }}>*/}
            {/*    <MenuItem value={'urgent'}>Urgent</MenuItem>*/}
            {/*    <MenuItem value={'high'}>High</MenuItem>*/}
            {/*    <MenuItem value={'medium'}>Medium</MenuItem>*/}
            {/*    <MenuItem value={'low'}>Low</MenuItem>*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}
          </div>
          <SaveBtn
            disabled={uploading}
            onClick={onSave}
            label={'Add Observation'}
          />
          <div ref={previewContainerRef} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

ObservationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ObservationDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
};
