import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';
import IosPeople from '@meronex/icons/ios/IosPeople';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import RolesSelect from './RolesSelect';
//TODO: replace with <Icons.X/>
import IList from '@meronex/icons/ios/MdList';

import MembersList from './MembersList';
import { Icons } from '../../../common/Icons';
import TopHeader from '../../../views/components/TopHeader';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function MembersDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;

  const [filterValue, setFilterValue] = React.useState();
  const [filters, setFilters] = React.useState({});

  React.useEffect(() => {
    setFilters({
      textFilter: filterValue,
      roleFilter: filters.roleFilter,
    });
  }, [filterValue]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <TopHeader
          title={'Members'}
          onClose={onClose}
          icon={<IosPeople />}
          filterProps={{
            placeholder: 'Filter by name or email',
            expandable: true,
            showApply: false,
            height: 140,
            onFilter: (v) => {
              setFilterValue(v);
            },
            onReset: () => {
              setFilters({
                textFilter: '',
                roleFilter: null,
              });
            },
            onApply: () => {
              console.log('onApply');
            },
            filterValue: (v) => {},
            extraFields: (
              <div>
                <RolesSelect
                  defaultValue={'any'}
                  onChange={(v) => {
                    console.log(v.target);
                    setFilters({
                      textFilter: filterValue,
                      roleFilter: v.target.value,
                    });
                  }}
                />
              </div>
            ),
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <MembersList filters={filters} />
      </DialogContent>
    </Dialog>
  );
}
MembersDialog.defaultProps = {
  open: false,
  onClose: () => {},
};
MembersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MembersDialog;
