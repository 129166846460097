import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, Loading } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import ICSV from '@meronex/icons/gr/GrDocumentCsv';

import ICheck from '@meronex/icons/ai/AiOutlineCheckCircle';
import { storesMng } from '../../api/storesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ExportStoreItemsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [exportState, setExportState] = React.useState({
    value: 'inProgress',
    message: 'Fetching store items',
  });

  const { open, onClose, store } = props;

  React.useEffect(() => {
    exportStoreItems();
  }, [open]);

  const _onClose = () => {
    setExportState({
      value: 'inProgress',
      message: 'Fetching store items',
    });
    onClose();
  };

  const createStoreItemsCSV = (storeItems) => {
    console.log('export to csv');
    console.log(storeItems);
    let csv;
    if (!Array.isArray(storeItems)) {
      throw Error('store items is null');
    }
    csv = Papa.unparse(
      storeItems.map((i) => {
        return {
          name: i.type.name,
          code: i.type.code,
          quantity: i.quantity,
          unit: i.type.unit,
          location: i.location
            ? `${i.location.rack}-${i.location.shelf}-${i.location.level}`
            : 'N/A',
        };
      })
    );
    return csv;
  };
  const exportStoreItems = async () => {
    let storeItemsToExport;

    if (store) {
      storeItemsToExport = await storesMng.getStoreItems(store._id);
      setExportState({
        value: 'inProgress',
        message: 'Exporting store items',
      });
      console.log(storeItemsToExport);
      if (Array.isArray(storeItemsToExport)) {
        const csv = createStoreItemsCSV(storeItemsToExport);
        let csvContent = `data:text/csv;charset=utf-8,${csv}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${store.name}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click(); //
        setExportState({
          value: 'completed',
          message: `${storeItemsToExport.length} items exported`,
        });
      } else {
        toast.error('No stores items', {
          autoClose: 1200,
        });
      }
    } else {
      toast.error('No selected store', {
        autoClose: 1200,
      });
    }
  };

  return (
    <Dialog open={open} onClose={_onClose}>
      <DialogTitle>
        <ICSV /> <span>CSV Export</span>
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          textAlign: 'center',
          minWidth: '300px',
        }}>
        <div>
          {exportState.value === 'inProgress' && (
            <div>
              <Loading text={exportState.message} />
            </div>
          )}
          {exportState.value === 'completed' && (
            <div
              style={{
                marginTop: '20px',
              }}>
              <ICheck size={50} color={'green'} />
              <div>{exportState.message}</div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

ExportStoreItemsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  store: PropTypes.object,
};

export default ExportStoreItemsDialog;
