import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { CloseBtn, ActionBtn, Loading } from '@meronex/components';
import IChart from '@meronex/icons/hi/HiOutlineChartPie';
import ICode from '@meronex/icons/fa/FaBarcode';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LocalizationProvider from '@material-ui/pickers/LocalizationProvider';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { MobileDateRangePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IPrint from '@meronex/icons/fd/FdPrint';
import moment from 'moment';
import Stats from './Stats';
import statsLoading from './statsLoading.gif';

import { useReactToPrint } from 'react-to-print';

// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';

import { analyticsMng } from '../../../api/analyticsMng';
import SitesAvatar from '../../../../common/SitesAvatar';
import IconButton from '@material-ui/core/IconButton/IconButton';

const utils = App.utils;

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function AnalyticsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, item } = props;

  const [isMonthChart, setIsMonthChart] = React.useState(true);

  const [data, setData] = React.useState({
    dateType: 'lastThreeMonths',
    range: [null, null],
    out: true,
    transferOut: true,
    in: true,
    transferIn: true,
  });

  const [transactions, setTransactions] = React.useState();

  const [analyticsMap, setAnalyticsMap] = React.useState();
  const [fetching, setFetching] = React.useState();

  React.useEffect(() => {
    fetchItemTransaction();
  }, [data]);
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const generateSumMaps = (transactions) => {
    console.log(transactions);
    const sums = {
      daysMap: new Map(),
      monthsMap: new Map(),
    };
    let totalIn = 0;
    let totalOut = 0;
    let totalTransferIn = 0;
    let totalTransferOut = 0;

    transactions.forEach((tx) => {
      const date = moment(tx.createdAt).format('DD/MM/YYYY');
      console.log(date);
      const dateArray = date.split('/'); //mon/dd/year
      console.log(dateArray);
      // day sum

      const processData = (data, tx, updateTotals) => {
        let _data = data;
        if (!data) {
          _data = {
            in: 0,
            out: 0,
            netAmount: 0,
          };
        }

        const amount = parseFloat(tx.amount) || 0;
        if (tx.transactionType === 'in') {
          _data.in = amount + _data.in;
          if (updateTotals) {
            totalIn = totalIn + amount;
          }
        } else if (tx.transactionType === 'out') {
          _data.out = amount + _data.out;
          if (updateTotals) {
            totalOut = amount + totalOut;
          }
        } else if (tx.transactionType === 'transferOut') {
          _data.out = amount + _data.out;
          if (updateTotals) {
            totalTransferOut = amount + totalTransferOut;
          }
        } else if (tx.transactionType === 'transferIn') {
          _data.in = amount + _data.in;
          if (updateTotals) {
            totalTransferIn = totalTransferIn + amount;
          }
        }
        _data.netAmount = _data.netAmount + amount;
        return _data;
      };

      let currentDayData = sums.daysMap.get(date);

      currentDayData = processData(currentDayData, tx, true);

      sums.daysMap.set(date, currentDayData);
      console.log(`totalIn: ${totalIn}`);

      sums.totals = {
        totalIn,
        totalOut,
        totalTransferIn,
        totalTransferOut,
      };

      // months sum
      const currentMonth = `${dateArray[1]}/${dateArray[2]}`;
      let currentMonthData = sums.monthsMap.get(currentMonth); //mm/yyyy
      currentMonthData = processData(currentMonthData, tx);
      sums.monthsMap.set(currentMonth, currentMonthData);
    });
    console.log(sums);
    setAnalyticsMap(sums);
    return sums;
  };
  const chartData = () => {
    let result = [];

    console.log(analyticsMap);
    console.log(`is month chart: ${isMonthChart}`);
    const map = isMonthChart ? analyticsMap.monthsMap : analyticsMap.daysMap;

    console.log(map);
    for (let [key, value] of map) {
      let obj = {
        name: key,
        in: utils.number.round(value.in) || 0,
        out: utils.number.round(value.out) || 0,
        amount: utils.number.round(value.netAmount) || 0,
      };
      // console.log(obj);
      result.push(obj);
    }
    return result;
  };
  const fetchItemTransaction = async () => {
    console.log('fetch transactions');
    setFetching(true);
    const transactions = await analyticsMng.getAnalyticsTransactions({
      itemId: item._id,
      filters: data,
    });
    generateSumMaps(transactions);
    console.log(transactions);
    setTransactions(transactions);
    setFetching(false);
  };

  React.useEffect(() => {
    if (item) {
      console.log(item);
      fetchItemTransaction();
    }
  }, [item]);

  const renderFilter = () => {
    return (
      <div>
        <div
          style={{
            marginTop: '10px',
          }}>
          <TextField
            id="select"
            label="Date"
            value={data.dateType}
            InputProps={{
              style: {
                borderRadius: '4px',
                height: '50px',
              },
            }}
            onChange={(e) => {
              const _data = { ...data };
              _data.dateType = e.target.value;
              setData(_data);
            }}
            select
            fullWidth
            variant={'outlined'}>
            <MenuItem value="thisMonth">Last month</MenuItem>
            <MenuItem value="lastThreeMonths">Last three months</MenuItem>
            <MenuItem value="lastSixMonths">Last six month</MenuItem>
            <MenuItem value="range">Date range</MenuItem>
          </TextField>
        </div>
        {data.dateType === 'range' && (
          <div
            style={{
              marginTop: '20px',
            }}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <div
                style={{
                  width: '100%',
                }}>
                <MuiThemeProvider>
                  <MobileDateRangePicker
                    renderInput={(startProps, endProps) => {
                      startProps.helperText = null;
                      endProps.helperText = null;
                      return (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                style={{ color: 'white' }}
                                variant="outlined"
                                {...startProps}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                style={{ color: 'white' }}
                                variant="outlined"
                                {...endProps}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    }}
                    // InputProps={{ className: classes.input }}
                    startText="Start Date"
                    endText="End Date"
                    helperText={null}
                    value={data.range}
                    onChange={(range) => {
                      const _data = { ...data };
                      _data.range = range;
                      setData(_data);
                    }}
                  />
                </MuiThemeProvider>
              </div>
            </LocalizationProvider>
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '10px',
            marginBottom: '10px',
          }}>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.out}
                    onClick={() => {
                      const _data = { ...data };
                      _data.out = !_data.out;
                      setData(_data);
                    }}
                  />
                }
                label="Out"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.in}
                    onClick={() => {
                      const _data = { ...data };
                      _data.in = !_data.in;
                      setData(_data);
                    }}
                  />
                }
                label="In"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.transferOut}
                    onClick={() => {
                      const _data = { ...data };
                      _data.transferOut = !_data.transferOut;
                      setData(_data);
                    }}
                  />
                }
                label="Transfer Out"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.transferIn}
                    onClick={() => {
                      const _data = { ...data };
                      _data.transferIn = !_data.transferIn;
                      setData(_data);
                    }}
                  />
                }
                label="Transfer In"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IChart /> Item Analysis
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div ref={printRef} className={'print-wrapper'}>
          <div
            style={{
              textAlign: 'center',
            }}>
            <SitesAvatar
              size={120}
              image={item.type.image}
              name={item.type.name}
            />

            <div
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                marginTop: '20px',
                marginBottom: '20px',
              }}>
              {item.type.name}
            </div>
            <div>
              <ICode />{' '}
              <span style={{ marginLeft: '5px' }}>{item.type.code}</span>
            </div>
            <div
              style={{
                marginTop: '10px',
              }}>
              {item.store.name}
            </div>
          </div>

          <div className={'remove-print'}>
            <div
              className={'label'}
              style={{
                marginTop: '30px',
                marginBottom: '20px',
              }}>
              FILTERS
            </div>
            {renderFilter()}
          </div>
          {fetching && <Loading url={statsLoading} type={'img'} />}
          {!fetching && (
            <div>
              <div
                className={'label'}
                style={{
                  marginTop: '30px',
                  marginBottom: '10px',
                }}>
                MOVEMENT CHART
                <div
                  className={'hide-print'}
                  style={{
                    display: 'inline-block',
                    float: 'right',
                  }}>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}>
                    <Grid item xs={4}>
                      Day
                    </Grid>
                    <Grid item xs={4}>
                      <AntSwitch
                        checked={isMonthChart}
                        onChange={() => {
                          setIsMonthChart(!isMonthChart);
                        }}
                        name="checkedC"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Month
                    </Grid>
                  </Grid>
                </div>
              </div>
              {analyticsMap && (
                <>
                  <div
                    style={{
                      marginLeft: '-28px',
                    }}>
                    <ResponsiveContainer height={300}>
                      <ComposedChart
                        width={500}
                        height={400}
                        data={chartData()}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 20,
                          left: 20,
                        }}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="band" />
                        <YAxis unit={` ${item.type.unit}`} />
                        <Tooltip />
                        <Bar
                          unit={` ${item.type.unit}`}
                          name={'Net In'}
                          dataKey="in"
                          fill="#82ca9d"
                        />
                        <Bar
                          name={'Net Out'}
                          dataKey="out"
                          fill="red"
                          unit={` ${item.type.unit}`}
                        />
                        <Line
                          name={'Net Movement'}
                          type="amount"
                          unit={` ${item.type.unit}`}
                          dataKey="amount"
                          stroke="gray"
                        />
                        <Legend
                          wrapperStyle={{
                            position: 'relative',
                            top: '5px',
                            marginTop: '-35px',
                          }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <Stats data={analyticsMap} item={item} filters={data} />
                </>
              )}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handlePrint()}>PRINT</Button>
      </DialogActions>
    </Dialog>
  );
}

AnalyticsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  item: PropTypes.object,
};

export default AnalyticsDialog;
