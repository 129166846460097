import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';

import { useTheme } from '@material-ui/core';

import FiCheck from '@meronex/icons/fi/FiCheck';
import MdWarning from '@meronex/icons/md/MdWarning';
import MdSearch from '@meronex/icons/ios/MdSearch';
import FaCheckSquare from '@meronex/icons/fa/FaCheckSquare';
import FaRegCommentDots from '@meronex/icons/fa/FaRegCommentDots';

import logsUtil from './common/logsUtils';
import LogDetails from './logDetails/LogDetails';

export default function LogCard(props) {
  const log = props.log;
  const isScrolling = props.isScrolling;
  const fastRender = props.fastRender;
  const card = logsUtil.getCard(log);
  const theme = useTheme();
  const ellipsisClass = 'ellipsis';

  const renderStatus = () => {
    // The card can be seen (meaning scrolled through it), opened or new.
    const isOpenedBool = card.isOpened;

    if (isOpenedBool) {
      return (
        <FiCheck
          style={{ color: '#4da532', marginLeft: '2px', marginRight: '2px' }}
        />
      );
    }
    return <span />;
  };

  const renderType = () => {
    const styleType = {
      fontSize: '12px',
    };
    let type;
    if (log.logType === 'observation') {
      type = (
        <>
          <MdSearch /> Observation
        </>
      );
    } else if (log.logType === 'incident') {
      type = (
        <>
          <MdWarning /> Incident
        </>
      );
    } else if (log.logType === 'feedback') {
      type = (
        <>
          <FaCheckSquare /> Feedback
        </>
      );
    } else if (log.logType === 'survey') {
      type = (
        <>
          <FaRegCommentDots /> Survey
        </>
      );
    }

    if (type) {
      return <span style={styleType}>{type}</span>;
    }
    return <div />;
  };
  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick(log);
        }
      }}>
      <div
        style={{
          overflow: 'hidden',
          margin: '5px',
          marginLeft: '15px',
          marginRight: '15px',
          height: '200px',
          padding: '10px',
        }}>
        {isScrolling && !fastRender && (
          <div>
            <div>
              <Skeleton variant="text" />
              <Skeleton animation="wave" width="80%" />
              <Skeleton animation="wave" width="60%" />
              <Skeleton animation="wave" width="20%" />
              <Skeleton animation="wave" width="20%" />
              <Skeleton animation="wave" width="20%" />
              <Skeleton animation="wave" width="60%" />
            </div>
          </div>
        )}
        {(fastRender || !isScrolling) && (
          <div
            style={{
              fontSize: '12px',
              marginBottom: '-5px',
              minHeight: '90px',
            }}>
            <div
              className={ellipsisClass}
              style={{
                color: theme.palette.text.primary,
                fontWeight: '500',
                fontSize: '20px',
                textAlign: !card.isRTL ? 'auto' : 'right',
                marginBottom: '5px',
                paddingRight: '5px',
              }}>
              {renderStatus()}
              {card.title}
            </div>
            <div style={{ marginBottom: '5px' }}>{renderType()}</div>
            <Grid container>
              <Grid item xs={7} sm={7}>
                <LogDetails log={log} />
              </Grid>
              <Grid item xs={5} sm={5}>
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  {card.detailsColumn}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <Divider />
    </div>
  );
}

LogCard.propTypes = {
  fastRender: PropTypes.bool,
  author: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
  location: PropTypes.string,
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  width: PropTypes.number,
  tag: PropTypes.shape({
    bgColor: PropTypes.string,
    label: PropTypes.string,
  }),
};

LogCard.defaultProps = {
  width: 260,
};
