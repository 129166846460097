import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { App } from '@meronex/app';
import Url from 'url-parse';
import base64url from 'base64-url';
import { toast, ToastContainer } from 'react-toastify';

PublicLayout.getQuery = () => {
  const url = new Url(window.location.href, true);
  if (url.query) {
    return url.query;
  }
};
PublicLayout.getUrlData = () => {
  const url = new Url(window.location.href, true);

  let data;
  if (url.query && url.query.d) {
    data = url.query.d;
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }
  return data;
};
PublicLayout.parseData = () => {
  const url = new Url(window.location.href, true);

  let data;
  if (url.query && url.query.d) {
    data = base64url.decode(url.query.d);
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }
  return data;
};

export default function PublicLayout(props) {
  const {
    classes,
    fullPage,
    emptyPage,
    pageTop,
    showLogo = true,
    logoWidth,
  } = props;

  const history = useHistory();

  // reuse the same login config
  const config = App.config.login;

  const stylePaper = {
    margin: 'auto',
    position: 'relative',
    width: '100%',
    maxWidth: '500px',
    overflow: 'auto',
    backgroundColor: config.backgroundColor || 'white',
    padding: '10px',
    height: '100vh',
  };

  if (!fullPage) {
    stylePaper.transform = 'translateY(-50%)';
    stylePaper.paddingTop = '20px';
    stylePaper.top = pageTop || '50%';
    stylePaper.height = 'auto';
  }

  const logoStyle = Object.assign({}, config.logo.style);
  if (logoWidth) {
    logoStyle.width = logoWidth;
  }
  if (emptyPage) {
    return (
      <div className={'public-layout'}>
        <div>{props.children}</div>
        <ToastContainer
          position={toast.POSITION.BOTTOM_CENTER}
          duration={1000}
        />
      </div>
    );
  }
  return (
    <div
      className={'public-layout'}
      style={{
        height: '100vh',
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundImage: `url(${config.backgroundImg.src})`,
        backgroundSize: 'cover',
        backgroundPosition:
          config.backgroundImg.backgroundPosition || 'center top',
        textAlign: 'left',
      }}>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          height: '100vh',
        }}>
        <div style={stylePaper}>
          {showLogo && (
            <div
              style={{
                textAlign: 'center',
                paddingBottom: '15px',
                fontSize: '35px',
              }}>
              <img src={config.logo.src} style={logoStyle} />
              {config.title}
            </div>
          )}
          <div style={{ color: 'white' }}>{props.children}</div>
        </div>
      </div>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} duration={1000} />
    </div>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fullPage: PropTypes.bool,
  showLogo: PropTypes.bool,
  logoWidth: PropTypes.string,
  pageTop: PropTypes.string,
  emptyPage: PropTypes.bool,
};

PublicLayout.defaultProps = {
  emptyPage: false,
};
