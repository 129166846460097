import React from 'react';
import { utils } from '../../base/utils';

export const useOnEnterPress = (
  onEnter = () => {
    console.log('on enter');
  }
) => {
  React.useEffect(() => {
    if (typeof onEnter === 'function' && !document.onkeydown) {
      document.onkeydown = utils.debounce(function (e) {
        if (e.key === 'Enter') {
          onEnter();
        }
      });
    }
    return () => {
      if (typeof onEnter === 'function') {
        document.onkeydown = null;
      }
    };
  });
};
