import React from 'react';
import PropTypes from 'prop-types';

export default function Title(props) {
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'inline-block',
          fontWeight: '400',
          fontSize: '12px',
          marginTop: '22px',
          marginBottom: props.subtitle ? '-15px' : '-5px',
          color: '#777777',
          textTransform: 'uppercase',
          letterSpacing: '3px',
        }}>
        {props.title}
      </div>
      {props.subtitle && (
        <div
          style={{
            color: 'gray',
            marginTop: '15px',
            marginLeft: '10px',
          }}>
          {props.subtitle}
        </div>
      )}
    </>
  );
}

Title.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  tileStyle: PropTypes.object,
};
