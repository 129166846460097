import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { useTheme } from '@material-ui/core';

import { App } from '@meronex/app';
import { ReadMore } from '@meronex/components';

import CommentDialog from './CommentDialog';
import RemoveCommentDialog from './RemoveCommentDialog';

const { utils } = App;

export default function Comment(props) {
  const { comment, onEdit, onRemove } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);

  const open = Boolean(anchorEl);
  const theme = useTheme();

  if (!comment) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCommentText = (authorName, comment) => {
    if (comment.removed) {
      return (
        <Fragment>
          <strong>{authorName}</strong>
          <div>
            <i>Removed {moment(comment.removedOn).fromNow()}</i>
          </div>
        </Fragment>
      );
    }

    const commentContent = (
      <Fragment>
        <strong>{authorName}</strong>
        <div
          dangerouslySetInnerHTML={{
            __html: comment.text.sanitizedHtml,
          }}
        />
      </Fragment>
    );

    const textLength =
      comment &&
      comment.text &&
      comment.text.sanitizedHtml &&
      comment.text.sanitizedHtml.length;

    return (
      <ReadMore
        textContent={commentContent || <div />}
        textLength={textLength || 0}
      />
    );
  };
  const renderCommentOptions = (comment) => {
    const userId = (App.getUser() || {})._id;

    const showRemove = comment.userId === userId;
    const showEdit = comment.userId === userId;

    const styleMenuLabel = {
      margin: '5px',
    };

    const menuItems = [];

    if (showEdit) {
      menuItems.push(
        <MenuItem
          eventKey={`edit-${comment._id}`}
          key={`edit-${comment._id}`}
          onClick={() => {
            handleClose();
            setOpenCommentDialog(true);
          }}>
          <IconButton edge="end" aria-label="edit">
            <EditIcon />
          </IconButton>
          <span style={styleMenuLabel}>Edit</span>
        </MenuItem>
      );
    }

    if (showRemove) {
      menuItems.push(
        <MenuItem
          eventKey={`remove-${comment._id}`}
          key={`remove-${comment._id}`}
          onClick={() => {
            handleClose();
            setOpenRemoveDialog(true);
          }}>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon />
          </IconButton>
          <span style={styleMenuLabel}>Remove</span>
        </MenuItem>
      );
    }

    return (
      <ListItemSecondaryAction style={{ top: '30px' }}>
        <IconButton
          edge="end"
          aria-label="more"
          aria-controls={`long-menu-${comment._id}`}
          aria-haspopup="true"
          onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`long-menu-${comment._id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '20ch',
            },
          }}>
          {menuItems}
        </Menu>
      </ListItemSecondaryAction>
    );
  };

  const author = comment.user && comment.user[0];

  // Need to have a placeholder in case user/actor was deleted
  let authorName = author
    ? `${App.utils.getUserName(author)}: `
    : 'Unknown User';

  const commentText = getCommentText(authorName, comment);

  const commentToEdit = {
    commentId: comment._id,
    commentText: ((comment || {}).text || {}).sanitizedHtml,
  };

  return (
    <Fragment key={comment._id}>
      <CommentDialog
        existingComment={commentToEdit}
        open={openCommentDialog}
        onClose={() => {
          setOpenCommentDialog(false);
        }}
        onEdit={() => onEdit()}
        isEditMode={true}
      />
      <RemoveCommentDialog
        commentId={comment._id}
        open={openRemoveDialog}
        onClose={() => {
          setOpenRemoveDialog(false);
        }}
        onRemove={() => onRemove()}
      />
      <div component="form" style={{ width: '100%', marginTop: '10px' }}>
        <ListItem
          style={{
            backgroundColor: theme.palette.background.default,
            borderRadius: '5px',
            borderBottom: '1px solid rgb(232 231 231 / 43%)',
          }}
          alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={utils.getUserName(author)}
              src={utils.getUserAvatar(author)}
            />
          </ListItemAvatar>
          <ListItemText
            primary={commentText}
            secondary={moment(comment.createdAt).fromNow()}
          />
          {!comment.removed && renderCommentOptions(comment)}
        </ListItem>
      </div>
    </Fragment>
  );
}

Comment.propTypes = {
  comments: PropTypes.shape({
    _id: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};
