import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fader from 'react-fader';
import PWAPrompt from 'react-ios-pwa-prompt';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import SpeedDial from '@material-ui/lab/SpeedDial/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SpeedDialIcon';

import { App, AppContext } from '../base/AppContext';
import SideMenu from './sideMenu/SideMenu';

import HeaderBar from './header/HeaderBar';
import Footer from './footer/Footer';
import LazyLoader from '../components/LazyLoader';

export default function MainLayout(props) {
  console.log(App);
  const [index, setIndex] = React.useState(
    (((props.history || {}).location || {}).state || {}).index || 0
  );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const views = App.config.views;

  React.useEffect(() => () => {
    // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  });

  const renderView = (index) => {
    if (typeof views.singlePage !== 'undefined') {
      return views.singlePage.component;
    } else if (Array.isArray(views.multiPage)) {
      return views.multiPage[index].component;
    }
  };

  const renderInjections = () => {
    if (!views.layoutInjections) {
      return null;
    }
    return views.layoutInjections.map((component) => component);
  };

  const renderBody = (index) => {
    if (App.opts.disableServer || App.opts.disableUserAccount) {
      return (
        <>
          {renderView(index)}
          {renderInjections()}
        </>
      );
    }
    return (
      <LazyLoader>
        {renderView(index)}
        {renderInjections()}
      </LazyLoader>
    );
  };
  const drawer = App.config.views.drawer || {};
  const DrawerMenu = drawer.component;
  const OpenIcon = drawer.icon;
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <div>
      <div
        style={{
          marginBottom: Boolean(App.utils.isMobile()) ? '56px' : '0px',
        }}>
        <HeaderBar
          config={App.config.header}
          icon={props.icon}
          title={props.title}
          backUrl={props.backUrl}
        />
        <Grid container>
          {!views.singlePage && (
            <Hidden mdDown>
              <Grid item xs={3}>
                <SideMenu onClick={(index) => setIndex(index)} value={index} />
              </Grid>
            </Hidden>
          )}
          <Grid item xs={12} lg={9}>
            <Fader
              fadeOutTransitionDuration={100}
              fadeInTransitionDuration={100}>
              <div
                style={{
                  marginTop: matches ? '20px' : '',
                  marginRight: matches ? '20px' : '0px',
                }}>
                {renderBody(index)}
              </div>
            </Fader>
          </Grid>
        </Grid>
      </div>
      <Footer
        value={index}
        onDrawerOpen={() => {
          setDrawerOpen(true);
        }}
        onClick={(index) => {
          setIndex(index);
        }}
      />
      {DrawerMenu && (
        <>
          <Hidden mdDown>
            <SpeedDial
              style={{
                transition: 'none',
                position: 'relative',
                top: '-100px',
                right: '50px',
                float: 'right',
              }}
              ariaLabel="SpeedDial openIcon example"
              onClick={() => {
                setDrawerOpen(true);
              }}
              icon={<SpeedDialIcon openIcon={OpenIcon} />}
              onClose={() => {}}
              onOpen={() => {}}
              open={false}
            />
          </Hidden>
          <DrawerMenu
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          />
        </>
      )}
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={7}
        delay={5000}
        permanentlyHideOnDismiss={false}
      />
      <ToastContainer
        position={App.getToastPosition() || toast.POSITION.BOTTOM_CENTER}
      />
    </div>
  );
}

MainLayout.propTypes = {
  title: PropTypes.object,
  backUrl: PropTypes.string,
};
