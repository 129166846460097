import React from 'react';
import PropTypes from 'prop-types';

import Link from '@material-ui/core/Link/Link';
import { Icons } from '../../../common/Icons';

import StoreDialog from './StoreDialog';
import { App } from '@meronex/app';

function StoreLink(props) {
  const { linkStyle, store } = props;

  const [openStoreDialog, setOpenStoreDialog] = React.useState(false);

  const mergedStyle = Object.assign(
    {
      fontWeight: 'bold',
    },
    linkStyle
  );
  if (!store) {
    return null;
  }

  const onLinkClick = (e) => {
    setOpenStoreDialog(true);
  };
  return (
    <>
      <Link onClick={onLinkClick}>
        <Icons.Stores />{' '}
        <span style={mergedStyle}>
          {App.utils.string.truncate(store.name, 24)}
        </span>
      </Link>
      {openStoreDialog && (
        <StoreDialog
          store={store}
          open={openStoreDialog}
          onClose={() => {
            setOpenStoreDialog(false);
          }}
        />
      )}
    </>
  );
}
StoreLink.propTypes = {
  store: PropTypes.object,
};

StoreLink.defaultProps = {};
export default StoreLink;
