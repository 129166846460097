/**
 * FirebaseFileUploader for React
 * Forked from https://github.com/fris-fruitig/react-firebase-file-uploader
 * to add client side image compression
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { v4 as generateRandomID } from 'uuid';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { utils } from '../../base/utils';

const optimizeImage = utils.image.optimizeImage;
const generateRandomFilename = () => generateRandomID();

function extractExtension(filename) {
  let ext = /(?:\.([^.]+))?$/.exec(filename);
  if (ext != null && ext[0] != null) {
    return ext[0];
  } else {
    return '';
  }
}

export default class FirebaseFileUploader extends Component {
  uploadTasks = [];

  // Cancel all running uploads before unmount
  componentDidMount() {
    // this is added to prevent Server checker component
    // from refresh when browsing

    window.DISABLE_RELOAD_ON_VISIBILTY_CHANE = true;
  }

  componentWillUnmount() {
    window.DISABLE_RELOAD_ON_VISIBILTY_CHANE = false;
    this.cancelRunningUploads();
  }

  cancelRunningUploads() {
    while (this.uploadTasks.length > 0) {
      const task = this.uploadTasks.pop();
      if (task.snapshot.state === 'running') {
        task.cancel();
      }
    }
  }

  // Remove a specific task from the uploadTasks
  removeTask(task) {
    for (let i = 0; i < this.uploadTasks.length; i++) {
      if (this.uploadTasks[i] === task) {
        this.uploadTasks.splice(i, 1);
        return;
      }
    }
  }

  startUpload(file) {
    const {
      onUploadStart,
      onProgress,
      onUploadError,
      onUploadSuccess,
      metadata,
      randomizeFilename,
      filename,
      folder,
      optimize,
    } = this.props;

    let filenameToUse;
    if (filename) {
      filenameToUse =
        typeof filename === 'function' ? filename(file) : filename;
    } else {
      filenameToUse = randomizeFilename ? generateRandomFilename() : file.name;
    }

    // Ensure there is an extension in the filename
    if (!extractExtension(filenameToUse)) {
      filenameToUse += extractExtension(file.name);
    }

    Promise.resolve()
      .then(() => {
        return optimizeImage(file);
      })
      .then((file) => {
        const storage = getStorage();

        const fullPath = `${folder}/${filenameToUse}`;
        console.log(fullPath);

        const storageRef = ref(storage, fullPath);

        const task = uploadBytesResumable(storageRef, file, metadata);

        if (onUploadStart) {
          onUploadStart(file, task);
        }

        task.on(
          'state_changed',
          (snapshot) =>
            onProgress &&
            onProgress(
              Math.round(
                (100 * snapshot.bytesTransferred) / snapshot.totalBytes
              ),
              task
            ),
          (error) => onUploadError && onUploadError(error, task),
          () => {
            this.removeTask(task);
            getDownloadURL(task.snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);
              if (onUploadSuccess) {
                return (
                  onUploadSuccess &&
                  onUploadSuccess(
                    task.snapshot.metadata.name,
                    file,
                    task,
                    downloadURL
                  )
                );
              }
            });
          }
        );
        this.uploadTasks.push(task);
      });
  }

  handleFileSelection = (event) => {
    const {
      target: { files },
    } = event;
    for (let i = 0; i < files.length; i++) {
      this.startUpload(files[i]);
    }
  };

  render() {
    const {
      onUploadStart,
      onProgress,
      onUploadSuccess,
      onUploadError,
      randomizeFilename,
      metadata,
      filename,
      maxWidth,
      maxHeight,
      hidden,

      as: Input = 'input',
      ...props
    } = this.props;

    const inputStyle = hidden
      ? Object.assign({}, props.style, {
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        })
      : props.style;

    return (
      <Input
        type="file"
        onChange={this.handleFileSelection}
        {...props}
        style={inputStyle}
      />
    );
  }
}

FirebaseFileUploader.propTypes = {
  onUploadStart: PropTypes.func,
  onProgress: PropTypes.func,
  onUploadError: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  metadata: PropTypes.object,
  randomizeFilename: PropTypes.bool,
  filename: PropTypes.string,
  folder: PropTypes.string,
  optimize: PropTypes.bool,
};

FirebaseFileUploader.defaultProps = {
  folder: 'images',
};
