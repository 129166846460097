import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, Form } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IRules from '@meronex/icons/ios/IosNotificationsOutline';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ManageRulesDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <IRules
          size={22}
          style={{
            position: 'relative',
            top: '4px',
          }}
        />{' '}
        Manage Rules
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}>
          <Form onUpdate={(data) => console.log(data)}>
            <TextField
              variant="outlined"
              name={'name'}
              label="Name"
              validate={(v) => {
                if (!v || v === '') return 'Name is required';
                return true;
              }}
              style={{ margin: 8 }}
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Condition</InputLabel>
              <Select
                formElement
                error={'hello'}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={'Condition'}>
                <MenuItem value={'equal'}>= Equal</MenuItem>
                <MenuItem value={'greaterThan'}> > Greater than</MenuItem>
                <MenuItem value={'lessThan'}> &lt; Less than</MenuItem>
                <MenuItem value={'lessThanOrEqualTo'}>
                  &lt;= Less than or equal to
                </MenuItem>
                <MenuItem value={'greaterThanOrEqualTo'}>
                  >= Greater than or equal to
                </MenuItem>
              </Select>
            </FormControl>
          </Form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

ManageRulesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ManageRulesDialog;
