import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import { toast } from '@meronex/components';
import { App } from '@meronex/app';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';

import { Icons } from '../../common/Icons';
import { can, p } from '../../common/roles';

export default function SideMenu(props) {
  const { activeView } = props;
  const theme = useTheme();

  const user = App.getUser();

  const [userLoaded, setUserLoaded] = React.useState(false);

  const onUserLoaded = () => {
    setUserLoaded(true);
  };

  React.useEffect(() => {
    App.eventsManager.on('event-user-loaded', 'SideMenu', onUserLoaded);
    return () => {
      App.eventsManager.removeListener(
        'event-user-loaded',
        'SideMenu',
        onUserLoaded
      );
    };
  });

  const _onClick = (item) => {
    if (item._id) {
      if (item._id === 'refresh') {
        App.eventsManager.emit('event-refresh-views', SideMenu.name, {
          activeView,
        });
      } else {
        App.eventsManager.emit('event-active-view', SideMenu.name, {
          activeView: item._id,
        });
      }
    }
  };

  const getTextColor = (id) => {
    let color = 'inherit';
    if (id === activeView) {
      color = 'rgb(59, 151, 186)';
    }
    return color;
  };

  const menuItemsArray = [];
  menuItemsArray.push({
    _id: 'home',
    type: 'item',
    label: 'Home',
    onClick: () => {},
    icon: (
      <span>
        <Icons.Home size={22} color={getTextColor('home')} />
      </span>
    ),
  });
  menuItemsArray.push({ type: 'divider' });

  if (can(p.globals.accessStores)) {
    menuItemsArray.push({
      _id: 'stores',
      type: 'item',
      label: 'Stores',
      icon: (
        <span>
          <Icons.Stores size={22} color={getTextColor('stores')} />
        </span>
      ),
    });
  }
  if (can(p.globals.accessFactories)) {
    menuItemsArray.push({
      _id: 'factories',
      type: 'item',
      label: 'Production',
      icon: (
        <span>
          <Icons.Factories size={22} color={getTextColor('factories')} />
        </span>
      ),
    });
  }
  if (can(p.globals.accessEquipments)) {
    menuItemsArray.push({
      _id: 'equipments',
      type: 'item',
      label: 'Equipments',
      icon: (
        <span>
          <Icons.Equipments size={22} color={getTextColor('equipments')} />
        </span>
      ),
    });
  }
  menuItemsArray.push({ type: 'divider' });
  menuItemsArray.push({
    _id: 'refresh',
    type: 'item',
    label: 'Refresh',
    icon: (
      <span>
        <Icons.Refresh size={22} color={''} />
      </span>
    ),
  });
  if (can(p.globals.viewConfigurations)) {
    menuItemsArray.push({
      _id: 'configurations',
      type: 'item',
      label: 'Configurations',
      icon: (
        <span>
          <Icons.Settings size={22} color={getTextColor('settings')} />
        </span>
      ),
    });
  }

  const renderMenuList = () => {
    if (Array.isArray(menuItemsArray)) {
      return menuItemsArray.map((item, index) => {
        const isActive = activeView === item._id;
        if (item.type === 'item') {
          return (
            <ListItem
              button
              key={index}
              onClick={() => {
                App.utils.debounce(_onClick(item), 1000);
              }}>
              <span style={{ marginRight: '10px' }}>{item.icon}</span>
              <ListItemText
                style={{ color: isActive ? 'rgb(59, 151, 186)' : '' }}
                primary={item.label}
              />
            </ListItem>
          );
        } else {
          return <Divider key={index} />;
        }
      });
      return <div />;
    }
  };

  if (!user && !userLoaded) {
    return <div />;
  }
  return (
    <div
      style={{
        paddingRight: '15px',
        position: 'sticky',
        top: '0',
      }}>
      <div
        className={'side-menu'}
        style={{
          borderRadius: '10px',
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}>
        {renderMenuList()}
      </div>
    </div>
  );
}

SideMenu.defaultProps = {
  onClick: () => console.log('on click'),
};
SideMenu.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.number,
};
