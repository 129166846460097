import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { App } from '@meronex/app';
import { ConstructionDialog } from '@meronex/components';
import MdSearch from '@meronex/icons/ios/MdSearch';
import TiThLargeOutline from '@meronex/icons/ti/TiThLargeOutline';
import MdWarning from '@meronex/icons/md/MdWarning';
import MdPlaylistAdd from '@meronex/icons/md/MdPlaylistAdd';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import ObservationDialog from '../logs/components/types/ObservationDialog';
import IncidentsDialog from '../incidents/IncidentsDialog';
import AllLogsDialog from '../logs/components/allLogs/AllLogsDialog';

const iconSize = 20;

export default function DrawerMenu(props) {
  const { open, onClose } = props;

  const [sites, setSites] = React.useState();
  const [observationOpen, setObservationOpen] = React.useState();
  const [incidentOpen, setIncidentOpen] = React.useState();

  const [openComplaints, setOpenComplaints] = React.useState(false);
  const [allLogsOpen, setAllLogsOpen] = React.useState(false);

  React.useEffect(() => {
    App.eventsManager.on('event-sites-loaded', DrawerMenu, (sites) => {
      setSites(sites);
    });
  }, []);

  const openFormFunc = (openForm) => {
    if (!sites || sites.length < 1) {
      toast.error('No sites available, please ask admin to add one.');
    } else {
      openForm(true);
    }
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          onClose();
        }}>
        <div
          role="Feedback"
          onClick={() => false}
          onKeyDown={() => onclose(false)}>
          <List>
            <ListItem
              button
              key={'all'}
              onClick={() => {
                setAllLogsOpen(true);
              }}>
              <ListItemIcon>
                <TiThLargeOutline size={iconSize} />
              </ListItemIcon>
              <ListItemText primary={'All Logs'} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key={'events'}
              onClick={() => {
                // setIncidentOpen(true);
              }}>
              <ListItemIcon>
                <MdPlaylistAdd size={iconSize} />
              </ListItemIcon>
              <ListItemText primary={'Add Events'} />
            </ListItem>
            <ListItem
              button
              key={'incidents'}
              onClick={() => {
                openFormFunc(setIncidentOpen);
              }}>
              <ListItemIcon>
                <MdWarning size={iconSize} />
              </ListItemIcon>
              <ListItemText primary={'Add Incident'} />
            </ListItem>
            <List>
              <ListItem
                button
                key={'observation'}
                onClick={() => {
                  openFormFunc(setObservationOpen);
                }}>
                <ListItemIcon>
                  <MdSearch size={iconSize} />
                </ListItemIcon>
                <ListItemText primary={'Add Observation'} />
              </ListItem>
            </List>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key={'close'}
              onClick={() => {
                onClose();
              }}>
              <div style={{ textAlign: 'center', width: '100%' }}>
                <ListItemText
                  primary={'Close'}
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </div>
            </ListItem>
          </List>
        </div>
      </Drawer>

      <ObservationDialog
        open={observationOpen}
        sites={sites}
        onSave={() => {
          App.eventsManager.emit('event-refresh-feed', DrawerMenu.name);
        }}
        onClose={() => {
          setObservationOpen(false);
          onClose();
        }}
      />
      <IncidentsDialog
        open={incidentOpen}
        sites={sites}
        onSave={() => {
          App.eventsManager.emit('event-refresh-feed', DrawerMenu.name);
        }}
        onClose={() => {
          setIncidentOpen(false);
          onClose();
        }}
      />
      {/*<FeedbackDialog*/}
      {/*  open={this.state.complaintsOpen}*/}
      {/*  sites={sites}*/}
      {/*  onSave={() => {*/}
      {/*    this.refreshFeed();*/}
      {/*  }}*/}
      {/*  onClose={() => {*/}
      {/*    this.setState({*/}
      {/*      complaintsOpen: false*/}
      {/*    });*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<SurveyDialog*/}
      {/*  open={this.state.surveysOpen}*/}
      {/*  onClose={() => {*/}
      {/*    this.setState({*/}
      {/*      surveysOpen: false*/}
      {/*    });*/}
      {/*  }}*/}
      {/*  onSave={() => {*/}
      {/*    // this.refreshFeed();*/}
      {/*  }}*/}
      {/*  sites={sites}*/}
      {/*/>*/}
      {/*<AuditReport*/}
      {/*  open={this.state.auditsOpen}*/}
      {/*  onClose={() => this.setState({ auditsOpen: false })}*/}
      {/*/>*/}
      <AllLogsDialog
        open={allLogsOpen}
        onClose={() => {
          setAllLogsOpen(false);
          onClose();
        }}
        onClick={() => {}}
        sites={sites}
      />
      <ConstructionDialog
        open={false}
        onClose={() => {
          // this.setState({
          //   constructionOpen: false
          // })
        }}
      />
    </>
  );
}

DrawerMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
