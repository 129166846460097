import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MdSystemUpdateAlt from '@meronex/icons/md/MdSystemUpdateAlt';
import { useTheme } from '@material-ui/core/styles';
import CgNotes from '@meronex/icons/cg/CgNotes';

import LoadingBtn from '../buttons/LoadingBtn';
import { AppContext } from '../../base/AppContext';
import Changelog from './Changelog';
import Fade from '@material-ui/core/Fade';
import Confetti from 'react-confetti';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VersionChecker(props) {
  const { App } = React.useContext(AppContext);
  const { showHelperText, changelog } = props;
  const { server, metadata } = App;
  const theme = useTheme();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showChangelog, setShowChangelog] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [buildMatch, setBuildMatch] = React.useState(false);
  const [versionMatch, setVersionMatch] = React.useState(false);
  let [data, setData] = React.useState(false);

  const subscribe = async () => {
    let metadataSub;
    console.log('[App/versionChecker]: Subscribing to metadata');
    try {
      metadataSub = server.subscribe('metadata', {
        sort: { createdAt: -1 },
        limit: 1,
      });
    } catch (e) {}
    if (metadataSub) {
      await metadataSub.ready();
    }
  };

  const buildCheck = (serverMD, clientMD) => {
    if (App.isLocalHost()) {
      console.warn('[App/versionChecker] Skipping build check on localhost');
    } else if (serverMD && clientMD) {
      console.log('[App/versionChecker] Checking build...');

      console.log(
        `[App/versionChecker] Client - name:${clientMD.name}, version:${clientMD.version},build:${clientMD.build}`
      );
      console.log(
        `[App/versionChecker] Server - name:${serverMD.name}, version:${serverMD.version},build:${serverMD.build}`
      );

      const nameMatch = clientMD.name === serverMD.name;
      const buildMatch = serverMD.build === clientMD.build;
      const versionMatch = serverMD.version === clientMD.version;

      console.log(
        `[App/versionChecker] NameMatch: ${nameMatch}, buildMatch: ${buildMatch}, versionMatch: ${versionMatch}`
      );
      setData({
        serverMD,
        clientMD,
      });
      if (nameMatch && (!buildMatch || !versionMatch)) {
        setBuildMatch(buildMatch);
        setVersionMatch(versionMatch);
        setShowDialog(true);
      }
    }
  };
  React.useEffect(() => {
    // skip version check on localhost

    if (!App.isLocalHost()) {
      // if we don't have server
      // then try fetching a fresh version
      // of the metadata
      if (App.opts.disableServer) {
        console.log(
          '[App/versionChecker] client only mode, fallback to using window focus'
        );
        window.onfocus = function () {
          console.log('[App/versionChecker] focused, fetching fresh metadata');
          try {
            window
              .fetch(`${process.env.PUBLIC_URL}/metadata.json`, {
                cache: 'no-store',
              })
              .then((response) => {
                response.json().then((freshMetadata) => {
                  console.log(
                    '[App/versionChecker] fresh metadata.json retrieved, checking versions'
                  );
                  buildCheck(freshMetadata, metadata);
                });
              });
          } catch (e) {
            console.warn(
              '[App/versionChecker] failed to fetch fresh metadata.json'
            );
          }
        };
      } else {
        App.eventsManager.on('event-connected', VersionChecker.name, () => {
          subscribe().then(() => {
            let serverMetadata = server.collection('metadata').reactive();
            serverMetadata.onChange((newData) => {
              const serverMetadataDoc = serverMetadata.data()[0];
              buildCheck(serverMetadataDoc, metadata);
            });
            if (serverMetadata) {
              const serverMetadataDoc = serverMetadata.data()[0];
              buildCheck(serverMetadataDoc, metadata);
            }
          });
        });
      }
    }
  }, []);

  const handleClose = () => {
    setShowDialog(false);
  };
  if (!data || !data.serverMD || !data.clientMD) {
    data = {
      clientMD: {
        version: 1,
      },
      serverMD: {
        version: 2,
      },
    };
  }
  const getMessage = () => {
    let message;
    if (!versionMatch) {
      message = {
        title: <span>🎉&nbsp;&nbsp;&nbsp;New Version Available</span>,
        content: (
          <>
            The app has been updated from version{' '}
            <span style={{ fontWeight: 'bold' }}>{data.clientMD.version}</span>{' '}
            to version{' '}
            <span style={{ fontWeight: 'bold' }}>{data.serverMD.version}</span>{' '}
            on {data.serverMD.date}
          </>
        ),
        helperText: undefined,
        changelog: true,
      };
    } else if (!buildMatch) {
      message = {
        title: 'New Version Available',
        content: (
          <>
            The app has been updated from version{' '}
            <span style={{ fontWeight: 'bold' }}>{data.clientMD.build}</span> to
            version{' '}
            <span style={{ fontWeight: 'bold' }}>{data.serverMD.build}</span> on{' '}
            {data.serverMD.date}
          </>
        ),
        helperText: (
          <p style={{ fontSize: '14px' }}>
            Note that a version change is usually an internal code enhancement
            without functionality change. Stay tuned for the next version
            release!
          </p>
        ),
        changelog: true,
      };
    }
    return message;
  };

  const message = getMessage();
  return (
    <div>
      <Dialog
        className={'version-checker-dialog'}
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick={false}
        onClose={handleClose}>
        <DialogTitle id="alert-dialog-slide-title">{message.title}</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-slide-description">
            {message.content}
            {showHelperText && message.helperText}
            {changelog && message.changelog && (
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '15px',
                }}>
                <div
                  style={{
                    marginTop: '18px',
                    marginBottom: '15px',
                    pointer: 'cursor',
                    color: '#6a72c1',
                    fontSize: '16px',
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}>
                  <div
                    onClick={() => {
                      setShowChangelog(true);
                    }}>
                    <div style={{ marginBottom: '10px' }}>
                      Click to see what's new!
                    </div>
                    <div>
                      <CgNotes size={25} />
                    </div>
                  </div>
                </div>
                <div style={{ fontSize: '12px' }}>
                  You can always see the change log from the settings
                </div>
                <Changelog
                  onClose={() => {
                    setShowChangelog(false);
                    console.log('hello');
                  }}
                  open={showChangelog}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: updating ? 'inline' : 'none',
            }}>
            <Fade in={updating}>
              <Confetti
                numberOfPieces={50}
                gravity={0.1}
                tweenDuration={1200}
              />
            </Fade>
          </div>
          <LoadingBtn
            style={{
              width: '100%',
              borderRadius: '20px',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            loading={updating}
            onClick={() => {
              setUpdating(true);
              setTimeout(() => {
                App.forceReload();
              }, 500);
            }}>
            <span style={{ marginRight: '5px' }}>Update</span>
            <MdSystemUpdateAlt />
          </LoadingBtn>
        </DialogContent>
      </Dialog>
    </div>
  );
}

VersionChecker.propTypes = {
  showHelperText: PropTypes.bool,
  changelog: PropTypes.bool,
};
VersionChecker.defaultProps = {
  showHelperText: true,
  changelog: true,
};
export default VersionChecker;
