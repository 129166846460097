import React from 'react';
import PropTypes from 'prop-types';

import {
  CloseBtn,
  toast,
  NoData,
  AlertDialog,
  Loading,
} from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import IFactory from '@meronex/icons/mdc/MdcFactory';
import IAdd from '@meronex/icons/md/MdAdd';
import ISelect from '@meronex/icons/bs/BsCheckCircle';

import AddFactoriesDialog from './AddFactoryDialog';
import EditFactoriesDialog from './EditFactoryDialog';

import { factoriesMng } from '../../api/FactoriesMng';
import SitesListItem from '../../../common/SitesListItem';
import TopBar from '../../../views/components/TopHeader';
import { can, p } from '../../../common/roles';

const useStyles = makeStyles((theme) => ({
  container: {},
  checkStyle: { marginLeft: '10px', color: 'green' },
  productsCount: {
    position: 'absolute',
    right: '10px',
    color: 'gray',
  },
}));

function FactoriesDialog(props) {
  const {
    open,
    onClose,
    factories,
    onFactorySelect,
    refreshFactories,
    selectedFactory,
    factorySelectLoading,
    onEdit,
    onDelete,
    onSelectClose,
  } = props;

  const topHeaderRef = React.useRef();

  const [factoryToDelete, setFactoryToDelete] = React.useState();

  const [openAddFactoryDialog, setOpenAddFactoryDialog] = React.useState(false);
  const [openEditFactoryDialog, setOpenEditFactoryDialog] = React.useState(
    false
  );
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState();
  const [switchingFactory, setSwitchingFactory] = React.useState();

  const _onDelete = async (factory) => {
    const result = await factoriesMng.deleteFactory(factory._id);
    refreshFactories();

    toast.success('Production site successfully deleted', { autoClose: 800 });
    console.log(selectedFactory);
    onDelete();
    setOpenDeleteAlert(false);
  };

  const handleFactoryClick = (factory) => {
    setSwitchingFactory(true);
    onFactorySelect(factory, () => {
      setSwitchingFactory(false);
      _onClose();
    });
  };

  const handleFactoryEdit = (factory) => {
    console.log('on factory edit');
    onFactorySelect(factory);
    setOpenEditFactoryDialog(true);
  };

  const renderFactories = () => {
    if (!factories) {
      return <div />;
    }

    let _factories = factories;

    if (filterValue) {
      _factories = factories.filter((f) =>
        f.name.includes(filterValue.toLowerCase())
      );
    }
    if (_factories.length === 0) {
      return (
        <NoData
          message={'Add Production Site'}
          onClick={() => {
            setOpenAddFactoryDialog(true);
          }}
        />
      );
    }

    return _factories.map((factory) => {
      const selected = selectedFactory && selectedFactory._id === factory._id;
      return (
        <SitesListItem
          loading={factorySelectLoading}
          onSelect={() => handleFactoryClick(factory)}
          onEdit={() => handleFactoryEdit(factory)}
          updatedAtLabel={'Updated: '}
          onClose={onSelectClose}
          onDelete={(factory) => {
            setFactoryToDelete(factory);
            setOpenDeleteAlert(true);
          }}
          disableDelete={!can(p.factories.CRUDFactories)}
          disableEdit={!can(p.factories.CRUDFactories)}
          selected={selected}
          item={{
            _id: factory._id,
            name: factory.name,
            icon: <IFactory />,
            unit: 'product',
            updatedAt: factory.updatedAt,
            count: factory.productsCount || 0,
            doc: factory,
          }}
        />
      );
    });
  };

  if (!Array.isArray(factories)) {
    return null;
  }

  const _onClose = () => {
    onClose();
    setTimeout(() => {
      setFilterValue('');
    }, 500);
  };

  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle style={{ paddingBottom: '0px' }}>
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
        <TopBar
          ref={topHeaderRef}
          title={
            <span
              style={{
                fontSize: '20px',
              }}>
              Select Production Site
            </span>
          }
          subtitle={`${factories.length} production site`}
          icon={
            <span>
              <span
                style={{
                  position: 'relative',
                }}>
                <IFactory />
              </span>
              <span
                style={{
                  position: 'relative',
                  left: '2px',
                }}>
                <ISelect size={18} />
              </span>
            </span>
          }
          filterProps={{
            placeholder: 'search production site',
            onFilter: (v) => {
              setFilterValue(v);
            },
          }}
        />
      </DialogTitle>
      <DialogContent>
        <List>{renderFactories()}</List>
        {can(p.factories.CRUDFactories) && (
          <Fab
            onClick={() => {
              setOpenAddFactoryDialog(true);
            }}
            style={{
              position: 'fixed',
              right: '25px',
              bottom: '30px',
            }}>
            <IAdd size={25} />
          </Fab>
        )}
      </DialogContent>
      {openAddFactoryDialog && (
        <AddFactoriesDialog
          open={openAddFactoryDialog}
          onAdd={refreshFactories}
          onClose={() => {
            setOpenAddFactoryDialog(false);
          }}
        />
      )}
      {openEditFactoryDialog && (
        <EditFactoriesDialog
          open={openEditFactoryDialog}
          onEdit={() => {
            refreshFactories();
            onEdit();
          }}
          factory={selectedFactory}
          onClose={() => {
            setOpenEditFactoryDialog(false);
          }}
        />
      )}
      {selectedFactory && (
        <AlertDialog
          open={openDeleteAlert}
          onConfirm={() => {
            _onDelete(factoryToDelete);
          }}
          onClose={() => {
            setOpenDeleteAlert(false);
          }}
          description={`Do you want to delete ${selectedFactory.name}?`}
        />
      )}
      {switchingFactory && <Loading type={'serverCall'} delay={1 * 3000} />}
    </Dialog>
  );
}

FactoriesDialog.defaultProps = {
  selectedFactory: (factory) => {
    console.log(factory);
  },
  refreshFactories: () => {
    console.log('refresh factory');
  },
  stores: [],
};
FactoriesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  factories: PropTypes.array,
  onFactorySelect: PropTypes.func,
  refreshFactories: PropTypes.func,
  selectedFactory: PropTypes.object,
};

FactoriesDialog.defaultProps = {
  selectedFactory: null,
};

export default FactoriesDialog;
