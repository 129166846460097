import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { App } from '@meronex/app';
import Url from 'url-parse';
import base64url from 'base64-url';
import { toast, ToastContainer } from 'react-toastify';
import { LazyLoader } from '@meronex/components';

PrivateLayout.getUrlData = () => {
  const url = new Url(window.location.href, true);

  let data;
  if (url.query && url.query.d) {
    data = url.query.d;
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }
  return data;
};
PrivateLayout.parseData = () => {
  const url = new Url(window.location.href, true);

  let data;
  if (url.query && url.query.d) {
    data = base64url.decode(url.query.d);
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }
  return data;
};

export default function PrivateLayout(props) {
  const { onLoad = () => {} } = props;

  const history = useHistory();

  React.useEffect(() => {
    console.log('loaded');
    console.log(App.loaded);
    if (!App.loaded)
      App.eventsManager.on('event-user-loaded', PrivateLayout.name, () => {
        onLoad();
      });
    else {
      onLoad();
    }
  }, []);

  return (
    <div style={{ padding: '15px' }}>
      <LazyLoader>{props.children}</LazyLoader>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} duration={1000} />
    </div>
  );
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  fullPage: PropTypes.bool,
  showLogo: PropTypes.bool,
  logoWidth: PropTypes.string,
  pageTop: PropTypes.string,
  onLoad: PropTypes.func,
};
