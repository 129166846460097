import React from 'react';
import PropTypes from 'prop-types';
import { App } from '@meronex/app';
import { Icons } from '../../../common/Icons';

import IWarehouse from '@meronex/icons/mdc/MdcWarehouse';
import ICode from '@meronex/icons/fa/FaBarcode';
import ITXCount from '@meronex/icons/fa/FaExchangeAlt';
import IArchive from '@meronex/icons/en/EnArchive';
import IFactories from '@meronex/icons/mdc/MdcFactory';
import IiD from '@meronex/icons/mdc/MdcIdentifier';
import ITime from '@meronex/icons/bi/BiTimeFive';
import IValue from '@meronex/icons/fa/FaBalanceScaleLeft';
import ILocation from '@meronex/icons/en/EnLocation';

import { toast } from 'react-toastify';

function StoreItemDetailsFields(props) {
  const {
    item,
    showStore,
    showTransaction,
    showDetails,
    permissions,
    showFactory,
    showUpdated,
    showId,
  } = props;

  const code = item.type.code;
  const storeName = item.store.name;

  const copyId = () => {
    try {
      navigator.clipboard.writeText(item._id);
      console.log(`${item._id} copied!`);
      toast.success('Item id copied successfully to clipboard!');
    } catch (e) {
      console.log(e);
      toast.error('Failed to copy item id to clipboard.');
    }
  };

  const getTotalPrice = () => {
    const totalCost = item.quantity * item.type.price;
    if (isNaN(totalCost)) {
      return null;
    }

    return (
      <span>
        {totalCost.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}
      </span>
    );
  };

  const renderItemPriceDetails = () => {
    if (permissions && permissions.viewPrices) {
      let unitRate, totalValue;

      if (!item.store.enableDynamicPrice && !isNaN(item.type.price)) {
        unitRate = item.type.price;
        totalValue = getTotalPrice();
      } else if (item.store.enableDynamicPrice && item.adjustedUnitRate) {
        unitRate = item.adjustedUnitRate.value;
      }
      if (unitRate) {
        totalValue = item.quantity * unitRate;
      }

      if (!isNaN(unitRate) && !isNaN(totalValue) && showDetails) {
        return (
          <>
            <div>
              <span
                style={{
                  position: 'relative',
                  left: '-2px',
                }}>
                <Icons.Price size={16} />
              </span>
              <span
                style={{
                  position: 'relative',
                  top: '-4px',
                  left: '4px',
                }}>
                Rate:{' '}
                {(unitRate || 0).toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                })}{' '}
                AED/
                {item.type.unit}
              </span>
            </div>
            <div>
              <span
                style={{
                  position: 'relative',
                  left: '-2px',
                }}>
                <IValue size={16} />
              </span>
              <span
                style={{
                  position: 'relative',
                  top: '-4px',
                  left: '4px',
                }}>
                Value:{' '}
                {(totalValue || 0).toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                })}{' '}
                AED
              </span>
            </div>
          </>
        );
      }
    }
    return <span />;
  };
  return (
    <div
      style={{
        marginTop: '2px',
        fontSize: '12px',
        color: 'gray',
      }}>
      {showStore && (
        <div>
          <IWarehouse />{' '}
          <span style={{ marginLeft: '5px' }}>
            {App.utils.string.truncate(storeName, 25)}
          </span>
        </div>
      )}
      <div>
        <ICode /> <span style={{ marginLeft: '5px' }}>{code}</span>
      </div>
      {item.type.category && (
        <div>
          <Icons.Category />{' '}
          <span style={{ marginLeft: '5px' }}>
            {App.utils.string.truncate(item.type.category.name, 25)}
          </span>
        </div>
      )}
      {showTransaction && (
        <div>
          <ITXCount color={'#808080ba'} />
          <span style={{ marginLeft: '10px' }}>
            <b>{item.transactionsCount || 0}</b> transactions
          </span>
        </div>
      )}
      {showFactory && item.productionInfo && (
        <div>
          <IFactories />
          <span style={{ marginLeft: '10px' }}>
            {App.utils.string.truncate(
              `${item.productionInfo.factory.name}`,
              25
            )}
          </span>
        </div>
      )}
      {showUpdated && item.updatedAt && (
        <div>
          <ITime />
          <span style={{ marginLeft: '8px' }}>
            {App.utils.time.timeAgo(item.updatedAt.date)}
          </span>
        </div>
      )}
      {item.location && (
        <div>
          <ILocation />
          <span
            style={{
              marginLeft: '8px',
            }}>
            {item.location.rack}.{item.location.shelf}.{item.location.level}
          </span>
        </div>
      )}
      {renderItemPriceDetails()}
      {item.archived && (
        <>
          <div>
            <IArchive />
            <span style={{ marginLeft: '8px' }}>
              {App.utils.time.fullFormat(item.archived.date)}
            </span>
          </div>
        </>
      )}
      {showId && (
        <>
          <div>
            <IiD size={15} />
            <span style={{ marginLeft: '10px' }}>
              <span
                style={{
                  cursor: 'pointer',
                  position: 'relative',
                  top: '-2px',
                  left: '-2px',
                }}>
                {item._id}{' '}
                <span className="link" onClick={copyId}>
                  (copy)
                </span>
              </span>
            </span>
          </div>
        </>
      )}
    </div>
  );
}
StoreItemDetailsFields.propTypes = {
  item: PropTypes.object,
  showStore: PropTypes.bool,
  showTransaction: PropTypes.bool,
  showDetails: PropTypes.bool,
  permissions: PropTypes.object,
  showFactory: PropTypes.bool,
  showUpdated: PropTypes.bool,
  showId: PropTypes.bool,
};

StoreItemDetailsFields.defaultProps = {
  showId: false,
};
export default StoreItemDetailsFields;
