import React from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';
import Avatar from 'react-avatar';
import { App } from '@meronex/app';
import { ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import ICode from '@meronex/icons/fa/FaBarcode';
import IUnit from '@meronex/icons/bi/BiRuler';
import IAdd from '@meronex/icons/cg/CgAdd';
import IEdit from '@meronex/icons/bi/BiEdit';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';
import IAdded from '@meronex/icons/fi/FiCheckCircle';
import { Icons } from '../../../common/Icons';

import { helpers } from '../../../common/helpers';

const useStyles = makeStyles((theme) => ({
  storeItemImg: {
    borderRadius: '10px',
    width: '75px',
    maxHeight: '75px',

    objectFit: 'cover',
    position: 'relative',
    marginRight: '10px',
  },
  storeItemName: { fontWeight: '400', fontSize: '14px' },
  storeItemLineItem: {
    marginTop: '10px',
    fontSize: '12px',
    color: 'gray',
    lineHeight: 1.5,
  },
  addBtn: {
    position: 'relative',
    top: '40px',
    color: 'green',
  },
}));

function ItemType(props) {
  const {
    data,
    onAdd,
    onEdit,
    onDelete,
    onClick,
    expanded,
    enableAddToStore,
    store,
    permissions,
  } = props;
  const classes = useStyles();

  const itemType = data;

  if (!itemType) {
    return null;
  }
  let imgSrc;

  if (itemType.image) {
    imgSrc = itemType.image.thumbUrl;
  }

  let added = false;
  if (store && Array.isArray(itemType.addedToStores)) {
    added = itemType.addedToStores.includes(store._id);
  }

  const renderStateInfo = () => {
    if (itemType.archived) {
      return <span style={{ fontSize: '12px' }}>(archived)</span>;
    } else if (added) {
      return <span style={{ fontSize: '12px' }}>(added)</span>;
    }
  };

  let gridSize = 12 / (enableAddToStore ? 3 : 2);

  return (
    <React.Fragment key={itemType._id}>
      <ListItem
        onClick={() => {
          onClick(itemType);
        }}
        style={{
          display: 'inline',
        }}>
        <div
          style={{
            marginTop: '-10px',
            marginBottom: '-10px',
          }}>
          <Grid container>
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: 'center',
                }}>
                {imgSrc && (
                  <img className={classes.storeItemImg} src={imgSrc} />
                )}
                {!imgSrc && (
                  <Avatar
                    name={App.utils.sanitize(itemType.name)}
                    size={70}
                    round={'10px'}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                className={classes.storeItemName}
                style={{
                  color: itemType.added ? 'rgb(2 2 2 / 43%)' : 'inherit',
                }}>
                <Icons.Items /> {itemType.name} {renderStateInfo()}
              </div>
              <div className={classes.storeItemLineItem}>
                {/*<div>*/}
                {/*  <ITag /> Category: {itemType.category}*/}
                {/*</div>*/}
                <div>
                  <ICode /> Code: {itemType.code}
                </div>
                <div>
                  <Icons.Category /> Category:{' '}
                  {itemType.category ? itemType.category.name : 'n/a'}
                </div>
                <div>
                  <IUnit /> Unit: {itemType.unit}
                </div>
                {permissions.viewPrices && (
                  <div>
                    <span
                      style={{
                        position: 'relative',
                        left: '-2px',
                      }}>
                      <Icons.Price size={16} />
                    </span>
                    <span
                      style={{
                        position: 'relative',
                        top: '-4px',
                        left: '-1px',
                      }}>
                      Price:{' '}
                      {(itemType.price || 0).toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                      })}{' '}
                      AED/
                      {itemType.unit}
                    </span>
                  </div>
                )}
              </div>
              {Boolean(added) && (
                <div
                  style={{
                    position: 'relative',
                  }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: '20px',
                      top: '-80px',
                    }}>
                    <IAdded color={'green'} size={20} />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>

        {permissions.CRUDItemTypes && (
          <div>
            <AnimateHeight
              duration={200}
              height={expanded ? 80 : 0} // see props documentation below
            >
              <Grid
                container
                style={{
                  marginTop: '12px',
                  textAlign: 'center',
                  borderRadius: '15px',
                }}>
                {enableAddToStore && (
                  <Grid
                    style={{
                      color: !itemType.added ? 'green' : 'gray',
                      cursor: 'pointer',
                    }}
                    item
                    xs={gridSize}
                    onClick={(e) => {
                      if (!itemType.added) {
                        onAdd(itemType);
                      }
                    }}>
                    <IconButton>
                      <IAdd color={!itemType.added ? 'green' : 'gray'} />
                    </IconButton>
                    <div>Add</div>
                  </Grid>
                )}
                <Grid
                  item
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                  }}
                  xs={gridSize}
                  onClick={() => {
                    onEdit(itemType);
                  }}>
                  <IconButton>
                    <IEdit style={{ color: 'rgb(85, 108, 214)' }} />
                  </IconButton>
                  <div style={{ color: 'rgb(85, 108, 214)' }}>Edit</div>
                </Grid>
                <Grid
                  style={{
                    color: 'green',
                    cursor: 'pointer',
                  }}
                  item
                  xs={gridSize}
                  onClick={() => {
                    console.log('on delete');
                    onDelete(itemType);
                  }}>
                  <IconButton>
                    <IDelete style={{ color: 'red' }} />
                  </IconButton>
                  <div style={{ color: 'red' }}>Delete</div>
                </Grid>
              </Grid>
            </AnimateHeight>
          </div>
        )}
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}
ItemType.propTypes = {
  data: PropTypes.object,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  enableAddToStore: PropTypes.bool,
};

ItemType.defaultProps = {
  enableAddToStore: true,
};
export default ItemType;
