import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from 'react-share';
import { toast } from 'react-toastify';

import QRCodeIcon from '../assets/QRCodeIcon.png';

import QRCodeDialog from './QRCodeDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ShareDialog(props) {
  const {
    open,
    onClose,
    data,
    showUrl = true,
    showCopy = false,
    useWebShare = true,
  } = props;

  const [openQRCodeDialog, setOpenQRCodeDialog] = React.useState(false);

  const [openShare, setOpenShare] = React.useState(open);

  React.useEffect(() => {
    if (data && data.url) {
      if (open && useWebShare) {
        _share();
      } else {
        setOpenShare(open);
      }
    }
  }, [open]);

  if (!data || !data.url) {
    return null;
  }

  const copyLink = () => {
    try {
      navigator.clipboard.writeText(data.url);
    } catch (e) {
      toast.error('Failed to copy the link to clipboard.');
    }
    console.log(`${data.url} copied!`);
    toast.success('Link copied successfully to clipboard!');
  };

  const _share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: data.title,
          text: data.summary,
          url: data.url,
        })
        .then(() => {
          onClose();
          console.log('Successful share');
        })
        .catch((error) => {
          onClose();
          console.log('Error sharing', error);
        });
    } else {
      setOpenShare(true);
      console.log('Web Share API is not supported in your browser.');
    }
  };

  return (
    <Dialog
      open={openShare}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent style={{ padding: '10px' }}>
        <div style={{ marginTop: '15px', textAlign: 'center' }}>
          <div
            onClick={() => {
              setOpenQRCodeDialog(true);
            }}
            style={{
              display: 'inline-block',
            }}>
            <img
              style={{
                marginRight: '4px',
                width: '40px',
              }}
              src={QRCodeIcon}
            />
          </div>
          <WhatsappShareButton title={data.title} url={data.url}>
            <WhatsappIcon style={{ marginRight: '5px' }} size={40} round />
          </WhatsappShareButton>
          <EmailShareButton url={data.url}>
            <EmailIcon style={{ marginRight: '5px' }} size={40} round />
          </EmailShareButton>

          <FacebookShareButton url={data.url}>
            <FacebookIcon style={{ marginRight: '5px' }} size={40} round />
          </FacebookShareButton>
          <TwitterShareButton url={data.url}>
            <TwitterIcon style={{ marginRight: '5px' }} size={40} round />
          </TwitterShareButton>
          <LinkedinShareButton
            title={data.title}
            summary={data.summary}
            url={data.url}>
            <LinkedinIcon style={{ marginRight: '5px' }} size={40} round />
          </LinkedinShareButton>
        </div>
        {showUrl && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px',
              cursor: 'pointer',
            }}>
            <a
              href={data.url}
              target="_blank"
              style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {data.url}
              </div>
            </a>
            {showCopy && (
              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  copyLink();
                }}>
                (Copy Link)
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginTop: '-15px' }}>
        <Button onClick={() => onClose()}>Close</Button>
      </DialogActions>
      <div>
        <QRCodeDialog
          url={data.url}
          open={openQRCodeDialog}
          onClose={() => {
            setOpenQRCodeDialog(false);
          }}
        />
      </div>
    </Dialog>
  );
}
ShareDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  showUrl: PropTypes.bool,
  showCopy: PropTypes.bool,
  useWebShare: PropTypes.bool,
  data: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
  }),
};
export default ShareDialog;
