import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
const LabelsStepper = React.lazy(() => import('../labels/LabelsStepper'));

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function StoreLabelsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, store } = props;

  if (!store) {
    return null;
  }

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Generate Labels
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '5px',
        }}>
        <Suspense fallback={<div>Loading...</div>}>
          <LabelsStepper store={store} />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
}

StoreLabelsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StoreLabelsDialog;
