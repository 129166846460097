import React from 'react';
import PropTypes from 'prop-types';
import { Form, AlertDialog } from '@meronex/components';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import IInfo from '@meronex/icons/fi/FiInfo';

import CountrySelect from './CountrySelect';

function NewsSectionForm(props) {
  const { onChange } = props;
  const [newsSection, setNewsSection] = React.useState(props.newsSection || {});
  const [openInfo, setOpenInfo] = React.useState(false);

  React.useEffect(() => {
    onChange(newsSection);
  }, [newsSection]);

  const formatQuery = (q) => {
    let _q = q;

    _q = q.replaceAll('or', 'OR').replaceAll('and', 'AND');

    return _q;
  };

  return (
    <Form
      onUpdate={(_formData) => {
        const _ns = { ...newsSection };
        Object.assign(_ns, _formData.values);
        setNewsSection(_ns);
      }}>
      <FormControl
        component="fieldset"
        style={{ marginTop: '15px', marginBottom: '15px' }}>
        <FormLabel component="legend">News Source</FormLabel>
        <RadioGroup
          aria-label="newsSource"
          name="newsSource"
          value={newsSection.source}
          onChange={(v) => {
            const _ns = { ...newsSection };
            _ns.source = v.target.value;
            setNewsSection(_ns);
          }}>
          <FormControlLabel
            value="topHeadlines"
            control={<Radio />}
            label="Top Headlines"
          />
          <FormControlLabel
            value="everything"
            control={<Radio />}
            label="Everything"
          />
        </RadioGroup>
      </FormControl>

      {'topHeadlines' === newsSection.source && (
        <>
          <div style={{ marginTop: '12px' }}>
            <CountrySelect
              defaultValue={newsSection.country}
              onChange={(v) => {
                const _ns = { ...newsSection };
                _ns.country = v;
                setNewsSection(_ns);
              }}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select
                label={'Category'}
                value={newsSection.category}
                fullWidth
                onChange={(v) => {
                  const _ns = { ...newsSection };
                  _ns.category = v.target.value;
                  setNewsSection(_ns);
                }}>
                <MenuItem key="general" value={'general'}>
                  General
                </MenuItem>
                <MenuItem key="business" value={'business'}>
                  Business
                </MenuItem>
                <MenuItem key="entertainment" value={'entertainment'}>
                  Entertainment
                </MenuItem>
                <MenuItem key="health" value={'health'}>
                  Health
                </MenuItem>
                <MenuItem key="science" value={'science'}>
                  Science
                </MenuItem>
                <MenuItem key="sports" value={'sports'}>
                  Sports
                </MenuItem>
                <MenuItem key="technology" value={'technology'}>
                  Technology
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
      )}
      {newsSection.source === 'everything' && (
        <>
          <div style={{ marginTop: '-6px', marginBottom: '-5px' }}>
            <TextField
              name={'query'}
              label="Query"
              value={newsSection.query}
              variant="outlined"
              fullWidth
              onChange={(v) => {
                const _ns = { ...newsSection };
                _ns.query = formatQuery(v.target.value);
                setNewsSection(_ns);
              }}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setOpenInfo(true)}>
                      <IInfo />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              name={'queryInTitle'}
              label="Query in Title"
              value={newsSection.queryInTitle}
              variant="outlined"
              onChange={(v) => {
                const _ns = { ...newsSection };
                _ns.queryInTitle = formatQuery(v.target.value);
                setNewsSection(_ns);
              }}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setOpenInfo(true)}>
                      <IInfo />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ marginBottom: '10px', marginTop: '5px' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Sort By</InputLabel>
              <Select
                label={'Sort By'}
                value={newsSection.sortBy}
                fullWidth
                onChange={(v) => {
                  const _ns = { ...newsSection };
                  _ns.sortBy = v.target.value;
                  setNewsSection(_ns);
                }}>
                <MenuItem key="publishedAt" value={'publishedAt'}>
                  Published At
                </MenuItem>
                <MenuItem key="relevancy" value={'relevancy'}>
                  Relevancy
                </MenuItem>
                <MenuItem key="popularity" value={'popularity'}>
                  Popularity
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
      )}
      <div style={{ marginTop: '15px', marginBottom: '5px' }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Language</InputLabel>
          <Select
            label={'Language'}
            value={newsSection.language}
            fullWidth
            onChange={(v) => {
              const _ns = { ...newsSection };
              _ns.language = v.target.value;
              setNewsSection(_ns);
            }}>
            <MenuItem key="any" value={'any'}>
              Any
            </MenuItem>
            <MenuItem key="en" value={'en'}>
              English
            </MenuItem>
            <MenuItem key="ar" value={'ar'}>
              Arabic
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <TextField
        name={'name'}
        label="Section Name"
        value={newsSection.name}
        defaultValue={newsSection.name}
        variant="outlined"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <AlertDialog
        title={'Query Info'}
        type={'info'}
        open={openInfo}
        description={
          <div>
            <div>Advanced search is supported here:</div>
            <ul>
              <li>Surround phrases with quotes (") for exact match.</li>
              <li>
                Prepend words or phrases that must appear with a + symbol. Eg:
                +bitcoin
              </li>
              <li>
                Prepend words that must not appear with a - symbol. Eg: -bitcoin
              </li>
              <li>
                Alternatively you can use the AND / OR / NOT keywords, and
                optionally group these with parenthesis. Eg: crypto AND
                (ethereum OR litecoin) NOT bitcoin.
              </li>
            </ul>
          </div>
        }
        confirmBtn={''}
        onClose={() => {
          setOpenInfo(false);
        }}
      />
    </Form>
  );
}
NewsSectionForm.propTypes = {
  onChange: PropTypes.func,
  newsSection: PropTypes.object,
};

NewsSectionForm.defaultProps = {};
export default NewsSectionForm;
