import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IOut from '@meronex/icons/bi/BiExport';
import IStores from '@meronex/icons/mdc/MdcWarehouse';
import IWarn from '@meronex/icons/ti/TiWarning';
import { toast } from 'react-toastify';

import { storesMng } from '../../../api/storesMng';
import { playSound } from '../../../../common/helpers';
import ActionInfo from './ActionInfo';
import TopBar from '../../../../views/components/TopHeader';

import { helpers } from '../../../../common/helpers';
import Button from '@material-ui/core/Button';

function ItemOutDialog(props) {
  const { open, onClose, item, onItemOut } = props;

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [onNoteFocus, setOnNoteFocus] = React.useState();
  const [error, setError] = React.useState();
  const [amountBlurred, setAmountBlurred] = React.useState(false);
  const [isValidBool, setIsValidBool] = React.useState(false);

  React.useEffect(() => {
    {
      open && setIsValidBool(isValid());
    }
  }, [data, amountBlurred]);

  React.useEffect(() => {
    window.loading = false;
  }, [open]);

  const _onClose = () => {
    onClose();
    window.loading = false;

    setTimeout(() => {
      setError('');
      setAmountBlurred(false);
      setIsValidBool(false);
      setData({});
    }, 500);
  };

  const transferStoreItem = async (data) => {
    setLoading(true);

    const storeItemId = data.storeItemId;
    const amount = data.amount;
    const note = data.note;
    const groupId = data.group ? data.group._id : null;

    const result = await storesMng.storeItem.actions.out({
      storeItemId,
      amount,
      note,
      groupId,
    });

    onItemOut(() => {
      toast.success('Amount successfully transferred out', {
        autoClose: 900,
      });
      playSound('out');
      _onClose();
    });
  };

  const onSubmit = async () => {
    if (!window.loading && data.amount) {
      window.loading = true;
      try {
        const result = await storesMng.storeItem.actions.outStoreItem({
          storeItemId: item._id,
          amount: Number.parseFloat(data.amount || 0),
          note: data.note,
          groupId: data.group ? data.group._id : null,
        });
        toast.success('Amount successfully removed!', {
          autoClose: 900,
        });
        onItemOut();
        playSound('out');
      } catch (e) {
        console.error(e);
      } finally {
      }
    }
    _onClose();
  };

  const isValid = () => {
    console.log('is valid');
    console.log(data);
    console.log(error);

    const _amountFloat = Number.parseFloat(data.amount);

    // validate blurring on amount
    if (isNaN(_amountFloat)) {
      if (amountBlurred) {
        setError('Amount is required');
      }
      return false;
    }
    // validate negative amount
    if (_amountFloat < 0) {
      setError('Negative amount value is not allowed');
      return false;
    }

    // validate batch info
    if (helpers.batch.isBatchedItem(item)) {
      // check if batch is selected
      if (!data.group) {
        setError('Batch group is required');
        return false;
      }
      if (!item.store.allowNegatives) {
        // check if the batch is negative
        if (data.group.quantity - _amountFloat < 0) {
          setError('Negative store batch quantity is not allowed');
          return false;
        }
        // check if the sum is negative
        const sum = helpers.batch.getSum(item);
        if (sum < 0) {
          setError('Negative store quantity is not allowed');
          return false;
        }
      }
    } else {
      // validate negative store quantity
      const _quantity = item.quantity - (_amountFloat || 0);
      if (!item.store.allowNegatives && _quantity < 0) {
        setError('Negative store quantity is not allowed');
        return false;
      }
    }

    // clear error and set valid
    setError('');
    return true;
  };

  if (!item) {
    return null;
  }
  return (
    <Dialog open={open} className={'fullScreenMobile'} scoll={'body'}>
      <DialogTitle>
        <TopBar
          title={`${item.store.name}`}
          icon={
            <span>
              <IStores /> <IOut size={22} color={'red'} />
            </span>
          }
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
        }}>
        <ActionInfo
          autoFocusQuantity
          hideImage
          onChange={(data) => {
            setData(data);
          }}
          onAmountBlur={() => {
            setAmountBlurred(true);
          }}
          onNoteFocusUpdate={(v) => {
            setOnNoteFocus(v);
          }}
          type={helpers.TRANSACTION_TYPES.out}
          item={item}
        />
        {error && (
          <div
            style={{
              marginTop: '10px',
            }}>
            <span style={{ color: 'red' }}>
              <IWarn /> {error}
            </span>
          </div>
        )}

        <div
          style={{
            position: 'relative',
            textAlign: 'center',
            width: '100%',
            marginTop: '20px',
            marginBottom: '30px',
          }}>
          <ActionBtn
            style={{
              marginBottom: '40px',
              marginTop: '18px',
            }}
            loading={window.loading}
            disabled={!isValidBool || window.loading || error}
            onClick={() => {
              if (!window.loading) {
                onSubmit();
              }
            }}
            onEnter={() => {
              if (!onNoteFocus && !window.loading) {
                onSubmit();
              }
            }}
            backgroundColor={'red'}
            label={
              <span>
                <span
                  style={{
                    marginRight: '10px',
                    top: '4px',
                    position: 'relative',
                  }}>
                  <IOut size={22} />
                </span>
                OUTPUT
              </span>
            }
          />
          <div>
            <Button
              onClick={() => {
                _onClose();
              }}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ItemOutDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onItemOut: PropTypes.func,
};

export default ItemOutDialog;
