import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Quantity from './Quantity';
import { storesMng } from '../stores/api/storesMng';

const { utils } = App;

const StoresSelect = React.forwardRef((props, ref) => {
  const {
    label,
    onChange,
    exclude,
    variant,
    placeholder,
    storeId,
    typeId,
    charCount,
    includeProductsStore,
    disabled,
  } = props;

  const [stores, setStores] = React.useState(props.stores);

  const fetchStores = async () => {
    console.log('fetch');
    console.log(includeProductsStore);
    const storesWithItemQuantities = await storesMng.getStoresWithTypeQuantities(
      {
        typeId,
        includeProductsStore,
      }
    );
    setStores(storesWithItemQuantities);
    console.log(storesWithItemQuantities);
  };

  React.useEffect(() => {
    if (!Array.isArray(stores)) {
      fetchStores();
    }
  }, []);

  const getStore = (storeId) => {
    if (!stores || !storeId) {
      return null;
    }
    return stores.find((s) => s._id === storeId);
  };
  const getItemValue = (s) => {
    const value = {};
    let quantity;
    let quantityString;
    let unit;

    if (!s) {
      return null;
    }

    if (s.storeItem) {
      quantity = Number.isNaN(s.storeItem.quantity) ? 0 : s.storeItem.quantity;
      unit = s.storeItem.type.unit;
    } else if (s.storeItemType) {
      quantity = 0;
      unit = s.storeItemType.unit;
    } else {
      quantity = '';
    }

    value.quantity = quantity;
    value.unit = unit;
    value.store = s;
    return value;
  };

  const getSelectItems = () => {
    const filteredStores = (stores || []).filter(
      (s) => !exclude.includes(s._id)
    );

    return filteredStores.map((store, index) => {
      return {
        itemValue: getItemValue(store),
        store,
        index,
      };
    });
  };

  const _onChange = (e, v) => {
    if (v && v.store) {
      onChange(v.store);
    } else {
      onChange();
    }
  };

  const getName = (name) => {
    if (charCount) {
      return utils.string.truncate(name, charCount);
    }
    return name;
  };

  return (
    <>
      <Autocomplete
        options={getSelectItems()}
        fullWidth
        disabled={disabled}
        placeholder={placeholder}
        getOptionLabel={(option) => (option.store || {}).name}
        value={getItemValue(getStore(storeId))}
        renderInput={(params) => {
          const itemValue = getItemValue(getStore(storeId));
          let quantity;
          if (itemValue && itemValue.quantity) {
            quantity = (
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '65px',
                  float: 'right',
                }}>
                <Quantity
                  quantity={itemValue.quantity}
                  style={{
                    float: 'right',
                  }}
                  unitLabel={itemValue.unit}
                  quantitySize={'18px'}
                  unitLabelStyle={{
                    position: 'relative',
                    float: 'right',
                    marginLeft: '5px',
                    top: '2px',
                  }}
                  enableChangeDirAnimation={false}
                />
              </div>
            );
          }
          const endAdornment = (
            <>
              {quantity}
              {params.InputProps.endAdornment}
            </>
          );
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment,
              }}
              label={<span>{label}</span>}
              variant={variant}
            />
          );
        }}
        onChange={_onChange}
        renderOption={(o) => {
          const store = o.store;
          const index = o.index;
          const itemValue = o.itemValue;
          console.log(itemValue);
          return (
            <div
              value={store._id}
              key={store._id}
              style={{
                width: '100%',
                position: 'relative',
              }}>
              <div
                style={{
                  position: 'absolute',
                  right: '5px',
                  fontSize: '12px',
                  color: 'gray',
                  width: '80px',
                  whiteSpace: 'pre-line',
                  textAlign: 'right',
                }}>
                <div>
                  {itemValue && Boolean(itemValue.quantity) && (
                    <Quantity
                      quantity={itemValue.quantity}
                      unitLabel={itemValue.unit}
                      style={{
                        position: 'relative',
                        top: '-2px',
                      }}
                      unitLabelStyle={{
                        position: 'relative',
                        float: 'right',
                      }}
                      quantitySize={'14px'}
                      enableChangeDirAnimation={false}
                    />
                  )}
                  {itemValue && !Boolean(itemValue.quantity) && (
                    <span
                      style={{
                        position: 'relative',
                        fontSize: '14px',
                        top: '2px',
                      }}>
                      n/a
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  fontSize: '14px',
                  width: '246px',
                }}>
                <div>{getName(store.name)}</div>
                {store.productsStore && (
                  <div
                    style={{
                      fontSize: '10px',
                      color: 'gray',
                    }}>
                    Products Only Store
                  </div>
                )}
                <div style={{ fontSize: '12px', marginTop: '2px' }}></div>
              </div>
            </div>
          );
        }}
      />
    </>
  );
});
StoresSelect.propTypes = {
  stores: PropTypes.array,
  typeId: PropTypes.string,
  storeId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  exclude: PropTypes.array,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.bool,
  charCount: PropTypes.number,
  includeProductsStore: PropTypes.bool,
  disabled: PropTypes.bool,
};

StoresSelect.defaultProps = {
  label: 'Store',
  variant: 'outlined',
  onChange: (store) => {
    console.log(store);
  },
  exclude: [],
  defaultValue: 'empty',
  placeholder: 'Select store',
  includeProductsStore: true,
  disabled: false,
};
export default StoresSelect;
