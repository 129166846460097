import React from 'react';
import PropTypes from 'prop-types';
import IStores from '@meronex/icons/mdc/MdcWarehouse';
import IItem from '@meronex/icons/bi/BiSquareRounded';
import { App } from '@meronex/app';

import { Icons } from '../common/Icons';
import Block from '../common/Block';

import Stores from './Stores';
import View from '../views/components/View';
import StoresDialog from './components/stores/StoresDialog';
import { storesMng } from './api/storesMng';
import { can, p } from '../common/roles';

function StoresView(props) {
  const { activeView } = props;

  const permissions = {
    in: can(p.stores.itemIn),
    out: can(p.stores.itemOut),
    transfer: can(p.stores.transferItem),
    auditItem: can(p.stores.auditStore),
    restore: can(p.stores.itemRestore),
    delete: can(p.stores.itemDelete),
    modify: can(p.stores.modifyItemAmount),
    archive: can(p.stores.itemArchive),
    auditStore: can(p.stores.auditItem),
    CRUDItemTypes: can(p.stores.CRUDItemTypes),
    viewPrices: can(p.stores.viewStoresPrices),
  };
  // we fetch the store header items here
  const [openStoreDialog, setOpenStoreDialog] = React.useState(false);
  const [stores, setStores] = React.useState(undefined);
  const [selectedStore, setSelectedStore] = React.useState();
  const [filterValue, setFilterValue] = React.useState('');

  const updateUserSelectStore = async () => {
    const selectedStore = await storesMng.getUserSelectedStore(stores);
    setSelectedStore(selectedStore);
  };

  React.useEffect(() => {
    App.eventsManager.on('event-product-updated', StoresView.name, () => {
      init();
    });
    App.eventsManager.on('event-work-order-added', StoresView.name, () => {
      init();
    });
    App.eventsManager.on('event-work-order-reversed', StoresView.name, () => {
      init();
    });
  }, []);

  React.useEffect(() => {
    updateUserSelectStore();
  }, [stores]);

  React.useEffect(() => {
    if (activeView !== 'stores') {
      setFilterValue(null);
    } else {
      fetchStores();
      // if (!selectedStore) {
      //   console.log('init store from active view');
      //   init();
      // }
    }
  }, [activeView]);

  const init = async () => {
    console.log('init');
    if (!stores) {
      await fetchStores();
    }
  };

  const refresh = async () => {
    console.log('refresh stores');
    await fetchStores();
    // await fetchStoreItems();
  };

  const fetchStores = async (cb) => {
    console.log('fetching stores');
    const _stores = await storesMng.getStores();
    setStores(_stores);
    if (typeof cb === 'function') {
      cb(_stores);
    }
  };

  const onStoreSelect = async (store, cb) => {
    storesMng.setUserSelectedStore(store._id);
    setSelectedStore(store);
    setFilterValue(null);
    App.eventsManager.emit('event-reset-filter', StoresView.name);

    /*fetchStoreItems();*/
    if (cb) {
      cb();
    }
  };

  return (
    <View
      icon={
        <span>
          <IStores size={22} />
        </span>
      }
      title={selectedStore ? selectedStore.name : ''}
      subtitle={
        selectedStore && selectedStore.productsStore ? (
          <div
            style={{
              position: 'relative',
              fontSize: '12px',
            }}>
            <Icons.Products /> Products Only Store
          </div>
        ) : (
          ''
        )
      }
      onTitleDropdownClick={() => {
        setOpenStoreDialog(true);
      }}
      filterProps={{
        onFilter: (v) => {
          setFilterValue(v);
        },
        enableQrCode: true,
        placeholder: `search ${
          selectedStore ? selectedStore.itemCount : 0
        } items`,
      }}
      onRefresh={async (cb) => {
        await refresh();
        cb();
      }}
      viewId={'stores'}
      showMenu
      activeView={activeView}>
      <Block>
        <Stores
          stores={stores}
          filters={{
            filterValue,
          }}
          initStores={init}
          fetchStores={fetchStores}
          permissions={permissions}
          selectedStore={selectedStore}
        />
      </Block>
      {openStoreDialog && (
        <StoresDialog
          permissions={permissions}
          open={openStoreDialog}
          stores={stores}
          refreshStores={fetchStores}
          selectedStore={selectedStore}
          onStoreSelect={onStoreSelect}
          onClose={() => {
            setOpenStoreDialog(false);
          }}
        />
      )}
    </View>
  );
}
StoresView.propTypes = {
  activeView: PropTypes.string,
};

StoresView.defaultProps = {};

export default StoresView;
