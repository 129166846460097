import React from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import IEdit from '@meronex/icons/bi/BiEdit';

import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';

import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IAddFAB from '@meronex/icons/ai/AiOutlinePlus';
import IAdd from '@meronex/icons/cg/CgAdd';

import { App } from '@meronex/app';

import SitesUserName from '../../../common/SitesUserName';
import SitesAvatar from '../../../common/SitesAvatar';

const MemberItem = (props) => {
  const { onClick, onEditClick, onDeleteClick, member } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const _onClick = () => {
    setExpanded(!expanded);
    onClick(member);
  };
  const _onEdit = () => {
    onEditClick(member);
  };
  const _onDelete = () => {
    onDeleteClick(member);
  };

  const renderRole = () => {
    let roleName = '';
    if (
      Array.isArray(member.roles) &&
      member.roles.some((r) => ['admin', 'root', 'manager'].includes(r))
    ) {
      roleName = 'Admin';
    } else if (
      member.settings &&
      member.settings.sites &&
      member.settings.sites.role
    ) {
      roleName = member.settings.sites.role.name;
    }
    return <div>{roleName}</div>;
  };
  return (
    <>
      <ListItem onClick={_onClick}>
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <div>
            <Grid container>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <span
                  style={{
                    marginRight: '5px',
                    top: '10px',
                  }}>
                  <SitesAvatar
                    image={member.profile.avatar}
                    name={`${member.profile.firstName} ${member.profile.lastName}`}
                    size={55}
                    round={50}
                  />
                </span>
              </Grid>
              <Grid item xs={8}>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    marginBottom: '6px',
                  }}>
                  <SitesUserName profile={member.profile} />{' '}
                </div>
                <div
                  style={{
                    color: 'gray',
                  }}>
                  <div>{member.emails[0].address}</div>
                  <div>
                    {App.utils.time.timeAgo(member.profile.lastLoggedIn)}
                  </div>
                  {renderRole()}
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            <AnimateHeight
              duration={200}
              height={expanded ? 90 : 0} // see props documentation below
            >
              <div>
                <Grid
                  container
                  style={{
                    marginTop: '12px',
                    paddingBottom: '14px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}>
                  <Grid
                    item
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    xs={6}
                    onClick={_onEdit}>
                    <IconButton>
                      <IEdit style={{ color: 'rgb(85, 108, 214)' }} />
                    </IconButton>
                    <div style={{ color: 'rgb(85, 108, 214)' }}>Edit</div>
                  </Grid>
                  <Grid item xs={6} onClick={_onDelete}>
                    <IconButton>
                      <IDelete style={{ color: 'red' }} />
                    </IconButton>
                    <div style={{ color: 'red' }}>Delete</div>
                  </Grid>
                </Grid>
              </div>
            </AnimateHeight>
          </div>
        </div>
      </ListItem>
      <Divider />
    </>
  );
};

MemberItem.propTypes = {
  onClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  member: PropTypes.object,
};

MemberItem.defaultProps = {
  onClick: () => {
    console.log('on click');
  },
  onEditCLick: () => {
    console.log('on edit');
  },
  onDeleteClick: () => {
    console.log('on delete');
  },
  onChange: (data) => console.log(data),
};
export default MemberItem;
