import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { CloseBtn, Section, TextTile } from '@meronex/components';

export default function AllLogsDialog(props) {
  const { open, onClose, onClick } = props;

  const qualitySection = '#f1f0ff';
  const tenantExperience = 'rgba(91, 175, 81, 0.13)';
  const marketingSection = 'rgba(228, 189, 0, 0.13)';

  const handleClick = (eventKey) => {
    if (onClick) {
      onClick(eventKey);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        All Logs
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent style={{ padding: '0px' }}>
        <div style={{ marginTop: '-20px' }}>
          <Section title={'Quality & Maintenance'}>
            <Section.SectionItem>
              <TextTile
                id="observations"
                onClick={handleClick}
                bgColor={qualitySection}>
                Observations
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile
                id="incidents"
                onClick={handleClick}
                bgColor={qualitySection}>
                Incidents
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile
                id="audits"
                onClick={handleClick}
                bgColor={qualitySection}>
                Audits
              </TextTile>
            </Section.SectionItem>
          </Section>
          <Section title={'Tenants Experience'}>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Suggestions
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Complaints
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Package Delivery
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Surveys
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Visitors
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Key Log
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile bgColor={tenantExperience} onClick={handleClick}>
                Parking
              </TextTile>
            </Section.SectionItem>
          </Section>
          <Section title={'Health & Safety'}>
            <Section.SectionItem>
              <TextTile onClick={handleClick}>Theft & Crime</TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile onClick={handleClick}>Suspicious Behaviour</TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile onClick={handleClick}>Cleaning Supplies</TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile onClick={handleClick}>First Aid Supplies</TextTile>
            </Section.SectionItem>
          </Section>
          <Section title={'Marketing & Ads'}>
            <Section.SectionItem>
              <TextTile onClick={handleClick} bgColor={marketingSection}>
                Viewing Request
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile onClick={handleClick} bgColor={marketingSection}>
                Agents Check-in
              </TextTile>
            </Section.SectionItem>
            <Section.SectionItem>
              <TextTile onClick={handleClick} bgColor={marketingSection}>
                Ads Listing Request
              </TextTile>
            </Section.SectionItem>
          </Section>
        </div>
      </DialogContent>
    </Dialog>
  );
}

AllLogsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};
