import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IDelete from '@material-ui/icons/Delete';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle((props) => {
  const { text } = props;
  return (
    <Grid item xs={2}>
      <div
        style={{
          padding: '0px',
          float: 'right',
          color: 'gray',
          position: 'relative',
          top: '12px',
        }}>
        <DragHandleIcon />{' '}
      </div>
    </Grid>
  );
});

function Field(props) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div
      style={{
        border: '1px solid lightGray',
        borderRadius: '10px',
        marginBottom: '15px',
        position: 'relative',
        padding: '10px',
      }}
      onClick={() => {
        console.log('on click');
        setExpanded(!expanded);
      }}>
      <Grid conatiner style={{ display: 'flex', height: '105px' }}>
        <Grid item xs={10} style={{ textAlign: 'left' }}>
          <div
            style={{
              fontSize: '18px',
              marginTop: '8px',
            }}>
            Name here
          </div>
          <div
            style={{
              marginTop: '20px',
            }}>
            Test2
          </div>
        </Grid>
        <DragHandle text={'test'} />
      </Grid>
      <div className={'action-container'} style={{ textAlign: 'center' }}>
        <AnimateHeight
          duration={200}
          height={expanded ? 80 : 0} // see props documentation below
        >
          <div
            onClick={() => {
              console.log('on remove');
            }}>
            <IconButton style={{ color: 'red' }}>
              <IDelete color={'red'} />
            </IconButton>
            <div style={{ color: 'red' }}>Remove</div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}
Field.propTypes = {};

Field.defaultProps = {};
export default Field;
