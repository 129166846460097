import React from 'react';
import PropTypes from 'prop-types';

import { Form } from '@meronex/components';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import { rolesMng } from '../../roles/api/rolesMng';

function MemberForm(props) {
  const { onChange = () => {}, data = {}, validateOnInit, isEditForm } = props;

  const { values = {} } = data;
  const [roles, setRoles] = React.useState();

  React.useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const roles = await rolesMng.getRoles();
    roles.push({
      _id: 'admin',
      name: 'Admin',
    });
    setRoles(roles);
  };

  const renderRoles = () => {
    if (!Array.isArray(roles)) {
      return <MenuItem> Loading...</MenuItem>;
    } else if (roles.length === 0) {
      return <MenuItem>No roles defined</MenuItem>;
    }
    return roles.map((r) => {
      return <MenuItem value={r._id}>{r.name}</MenuItem>;
    });
  };

  return (
    <div>
      <Form validateOnInit={validateOnInit} blurDelay={500} onUpdate={onChange}>
        <TextField
          name={'firstName'}
          label="First Name"
          autoFocus={true}
          defaultValue={(values || {}).firstName}
          variant="outlined"
          fullWidth
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
        />
        <TextField
          name={'lastName'}
          label="Last Name"
          required
          defaultValue={(values || {}).lastName}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
        />
        <TextField
          name={'email'}
          label="Email"
          disabled={isEditForm}
          defaultValue={(values || {}).email}
          variant="outlined"
          fullWidth
          required
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Email is required';
            if (!Form.validators.isValidEmail(v)) {
              return 'Email is not valid';
            } else return true;
          }}
        />
        <TextField
          placeholder={'select role'}
          variant="outlined"
          style={{ marginTop: '10px' }}
          label="Role"
          name={'roleId'}
          defaultValue={(values || {}).roleId}
          required
          fullWidth
          validate={(v) => {
            if (!v || v === '') return 'Role is required';
            return true;
          }}
          select>
          {renderRoles()}
        </TextField>
        <FormHelperText>
          You can manage roles at the settings page.
        </FormHelperText>
        <TextField
          name={'tempPass'}
          label="Temporary Password"
          defaultValue={(values || {}).tempPass}
          variant="outlined"
          fullWidth
          required={!isEditForm}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            console.log(values);
            if (isEditForm) {
              return true;
            }
            if (!v || v === '') return 'Temporary Password is required';
            return true;
          }}
        />
        {isEditForm && (
          <FormHelperText>Change the user current password</FormHelperText>
        )}
      </Form>
    </div>
  );
}
MemberForm.propTypes = {
  values: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
  isEditForm: PropTypes.bool,
};

MemberForm.defaultProps = {
  values: {},
  isEditForm: false,
  validateOnInit: false,
  onChange: (values) => console.log(values),
};
export default MemberForm;
