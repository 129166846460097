import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { App } from '@meronex/app';

import { ActionBtn, Loading, NoData } from '@meronex/components';
import Grid from '@material-ui/core/Grid';

import FactoriesDrawer from './components/factories/FactoriesDrawer';
import WorkOrdersDialog from './components/workorders/WorkOrdersDialog';
import AllDraftsDialog from './components/workorders/draftWorkOrder/AllDraftsDialog';

import EquipmentsDialog from '../equipments/components/EquipmentsDialog';
import { factoriesMng } from './api/FactoriesMng';
import SitesAvatar from '../common/SitesAvatar';
import loadingGif from './factories.gif';
import Quantity from '../common/Quantity';
import CircularProgress from '../common/CircularProgress';
import SitesUserName from '../common/SitesUserName';
import ProductsDialog from './components/products/ProductsDialog';
import ActiveStepInfo from '../factories/components/products/steps/ActiveStepInfo';
import { can, p } from '../common/roles';

import IconButton from '@material-ui/core/IconButton';
import IRefresh from '@meronex/icons/bi/BiRefresh';
import IWarn from '@meronex/icons/ti/TiWarningOutline';
import IVerify from '@meronex/icons/mdc/MdcMicroscope';
import IData from '@meronex/icons/bs/BsClipboardData';
import { workOrderHelpers } from './components/workorders/helpers';

function FactoriesHomePage(props) {
  const { selectedFactory, loading, onWorkOrderAdded, onRefresh } = props;

  const [openAddProduct, setOpenAddProduct] = React.useState();
  const [openWorkOrderDialog, setOpenWorkOrdersDialog] = React.useState();
  const [openDraftsDialog, setOpenDraftsDialog] = React.useState();
  const [openManageEquipments, setOpenManageEquipments] = React.useState();
  const [openProductsDialog, setOpenProductsDialog] = React.useState();
  const [
    openReadOnlyProductsDialog,
    setOpenReadOnlyProductsDialog,
  ] = React.useState();
  const [fetching, setFetching] = React.useState();

  const [drafts, setDrafts] = React.useState();
  const [workOrders, setWorkOrders] = React.useState();

  React.useEffect(() => {
    console.log(selectedFactory);
    if (selectedFactory) {
      // getCompletedWO();
      refresh();
    }
  }, [selectedFactory]);

  React.useEffect(() => {
    return () => {
      window.clearTimeout(window.summaryPageRefreshTimeout);
    };
  }, []);
  const refresh = async () => {
    window.clearTimeout(window.summaryPageRefreshTimeout);
    setFetching(true);
    await getRecentlyCompleted();
    await getInProgressDrafts();
    setFetching(false);
    window.summaryPageRefreshTimeout = setTimeout(() => {
      refresh();
    }, 4 * 60 * 1000);
  };
  // const getCompletedWO = async () => {};
  const getInProgressDrafts = async () => {
    console.log(selectedFactory);
    const drafts = await factoriesMng.getFactoryDrafts({
      factoryId: selectedFactory._id,
      filters: {},
    });
    setDrafts(drafts);
  };

  const getRecentlyCompleted = async () => {
    const workOrders = await factoriesMng.getRecentlyCompletedWO(
      selectedFactory._id
    );
    setWorkOrders(workOrders);
  };

  const renderWarn = (item) => {
    let warn = false;
    if (item.state && item.state.flagged) {
      warn = true;
    } else if (item.status === 'flagged') {
      warn = true;
    }

    if (warn) {
      return (
        <span
          style={{
            position: 'relative',
            top: '5px',
          }}>
          <IWarn
            size={22}
            color={'red'}
            style={{
              marginRight: '5px',
            }}
          />
        </span>
      );
    }
    return null;
  };

  const renderItem = (item, isDraft, index) => {
    return (
      <div
        onClick={() => {
          if (isDraft) {
            setOpenDraftsDialog(true);
          } else {
            setOpenWorkOrdersDialog(true);
          }
        }}
        style={{
          minHeight: '110px',
          marginTop: '15px',
          backgroundColor: index % 2 ? '#f7f7f7' : 'none',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}>
        <Grid container>
          <Grid xs={3}>
            <div
              style={{
                position: 'relative',
                paddingLeft: '5px',
                top: '6px',
                textAlign: 'center',
              }}>
              <SitesAvatar
                name={item.product.name}
                image={item.product.image}
                size={65}
                round={5}
              />
            </div>
          </Grid>
          <Grid xs={6}>
            <div
              style={{
                position: 'relative',
                marginLeft: '10px',
              }}>
              <div
                style={{
                  fontSize: '15px',
                  marginTop: '2px',
                  marginBottom: '5px',
                  position: 'relative',
                }}>
                {renderWarn(item)}
                {item.reversed ? <span>&nbsp;(R)</span> : ''}{' '}
                {item.product.name}
              </div>
              <div
                style={{
                  color: 'gray',
                  fontSize: '12px',
                }}>
                {isDraft && (
                  <div>
                    <div>
                      {App.utils.time.fullFormat(item.draft.createdAt, true)}
                    </div>
                    <div>
                      <SitesUserName profile={item.draft.createdBy} />
                    </div>
                  </div>
                )}
                {!isDraft && (
                  <div>
                    <div>{App.utils.time.fullFormat(item.createdAt, true)}</div>
                    <div>
                      <SitesUserName profile={item.draft.createdBy} />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                  }}>
                  <span>#{item.seqNum} </span>
                  {item.batchNum ? <span>- {item.batchNum} </span> : ''}
                  {can(p.factories.viewFactoriesPrices) && item.cost && (
                    <span
                      style={{
                        color: 'gray',
                      }}>
                      {' '}
                      -{' '}
                      {item.cost.total.toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                      })}{' '}
                      AED
                    </span>
                  )}
                </div>
              </div>
              {!isDraft && item.state && (
                <>
                  <div>
                    {item.state.qualityVerified && <IVerify />}{' '}
                    {item.state.dataEntry && <IData />}
                  </div>
                  {item.state.flagged && item.state.flagged.reason && (
                    <div
                      style={{
                        color: 'red',
                      }}>
                      {item.state.flagged.reason}
                    </div>
                  )}
                </>
              )}

              {isDraft && (
                <div
                  style={{
                    position: 'relative',
                    marginTop: '-8px',
                  }}>
                  <ActiveStepInfo workOrder={item} />
                </div>
              )}
            </div>
          </Grid>

          <Grid
            xs={3}
            style={{
              textAlign: 'center',
            }}>
            <span
              style={{
                right: '8px',
                position: 'relative',
                color: 'gray',
                fontSize: '12px',
              }}>
              {isDraft && (
                <span>
                  {App.utils.time.timeAgo(item.draft.createdAt, true)}
                </span>
              )}

              {!isDraft && (
                <span>{App.utils.time.timeAgo(item.createdAt, true)}</span>
              )}
            </span>
            {isDraft && (
              <div
                style={{
                  color: 'gray',
                  position: 'relative',
                  top: '10px',
                }}>
                <CircularProgress value={item.draft.progress} />
              </div>
            )}
            {!isDraft && (
              <div
                style={{
                  marginTop: '5px',
                }}>
                <Quantity
                  enableChangeDirAnimation={false}
                  quantity={item.producedQuantity}
                  showUnitLabel
                  enableAutoSizeShrink
                  unitLabel={item.product.unit}
                />
                <div
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                    position: 'relative',
                    top: '12px',
                  }}>
                  {workOrderHelpers.getPackagedQuantity(item)}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };
  const renderCompleted = () => {
    if (!Array.isArray(workOrders)) {
      return (
        <div
          style={{
            marginTop: '10px',
          }}>
          <div
            style={{
              marginBottom: '-48px',
            }}>
            <Loading />
          </div>
        </div>
      );
    } else if (workOrders.length === 0) {
      return (
        <div>
          <NoData
            message={
              <span
                style={{
                  fontSize: '15px',
                }}>
                No Recently Completed Work Orders
              </span>
            }
            type={'warn'}
          />
        </div>
      );
    } else {
      return workOrders.map((m, index) => renderItem(m, false, index));
    }
  };

  const renderInProgress = () => {
    if (!Array.isArray(drafts)) {
      return (
        <div
          style={{
            marginBottom: '-48px',
          }}>
          <Loading />
        </div>
      );
    }
    if (drafts.length === 0) {
      return (
        <div>
          <NoData
            onClick={() => {
              setOpenProductsDialog(true);
            }}
            message={
              <div
                style={{
                  marginTop: '-10px',
                  fontSize: '15px',
                }}>
                Start a new work order
              </div>
            }
            type={'warn'}
          />
        </div>
      );
    }

    return drafts.map((d, index) => {
      return renderItem(d, true, index);
    });
  };

  const renderBody = () => {
    if (loading) {
      return <Loading type="img" text={'Loading..'} url={loadingGif} />;
    }
    return (
      <div>
        {can(p.factories.CRUDWorkOrders) && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '30px',
            }}>
            <ActionBtn
              label={'Start work order'}
              onClick={() => {
                setOpenReadOnlyProductsDialog(true);
              }}
              style={{
                width: '100%',
                borderRadius: '0px',
              }}
            />
          </div>
        )}
        <div
          style={{
            overflow: 'auto',
            height: 'auto',
            maxHeight: '90vh',
            paddingBottom: '200px',
            position: 'relative',
          }}>
          <div
            style={{
              position: 'absolute',
              right: '18px',
              top: '-12px',
            }}>
            <IconButton
              onClick={async () => {
                await refresh();
              }}>
              <IRefresh color={'black'} className={fetching ? 'spin' : ''} />
            </IconButton>
          </div>
          <div
            style={{
              minHeight: '80px',
            }}>
            <div
              style={{
                color: 'gray',
                marginLeft: '10px',
                marginBottom: '20px',
                fontSize: '18px',
              }}>
              RECENTLY COMPLETED{' '}
            </div>

            <div>
              <div>{renderCompleted()}</div>
            </div>
          </div>
          <div>
            <div
              style={{
                color: 'gray',
                marginLeft: '10px',
                marginTop: '60px',
                marginBottom: '20px',
                fontSize: '18px',
              }}>
              IN PROGRESS DRAFTS{' '}
              {Array.isArray(drafts) ? `(${drafts.length})` : ''}
            </div>
            <div>{renderInProgress()}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderBody()}
      {!loading && selectedFactory && (
        <div>
          <FactoriesDrawer
            onOpenProducts={() => setOpenProductsDialog(true)}
            onViewDrafts={() => {
              setOpenDraftsDialog(true);
            }}
            onManageEquipments={() => {
              setOpenManageEquipments(true);
            }}
            onViewWorkOrders={() => {
              setOpenWorkOrdersDialog(true);
            }}
          />
        </div>
      )}
      <div>
        {openWorkOrderDialog && (
          <WorkOrdersDialog
            open={openWorkOrderDialog}
            onClose={() => {
              setOpenWorkOrdersDialog(false);
            }}
            factory={selectedFactory}
          />
        )}
        {openManageEquipments && (
          <EquipmentsDialog
            factory={selectedFactory}
            open={openManageEquipments}
            onClose={() => {
              setOpenManageEquipments(false);
            }}
          />
        )}
        {openDraftsDialog && (
          <AllDraftsDialog
            factory={selectedFactory}
            open={openDraftsDialog}
            onDraftDeleted={() => {
              refresh();
            }}
            onWorkOrderAdded={() => {
              refresh();
              setOpenDraftsDialog(false);
              onWorkOrderAdded();
            }}
            onClose={() => {
              setOpenDraftsDialog(false);
            }}
          />
        )}
        {workOrders && (
          <ProductsDialog
            open={openProductsDialog || openReadOnlyProductsDialog}
            readOnly={openReadOnlyProductsDialog}
            factory={selectedFactory}
            onDraftCreate={() => {
              refresh();
            }}
            onWorkOrderAdded={() => {
              refresh();
              setOpenProductsDialog(false);
              onWorkOrderAdded();
            }}
            onAddProduct={() => {
              setOpenAddProduct(true);
            }}
            onClose={() => {
              setOpenProductsDialog(false);
              setOpenReadOnlyProductsDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

FactoriesHomePage.propTypes = {
  fetchProducts: PropTypes.func,
  onProductUpdate: PropTypes.func,
  openFactoriesDialog: PropTypes.func,
  onWorkOrderAdded: PropTypes.func,
  onProductEdit: PropTypes.func,
  onRefresh: PropTypes.func,
};
FactoriesHomePage.defaultProps = {
  openFactoriesDialog: () => {},
  onWorkOrderAdded: () => {},
  onProductEdit: () => {},
  onRefresh: () => {},
};

export default FactoriesHomePage;
