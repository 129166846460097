import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { QRCode } from 'react-qr-svg';
import LinearProgress from '@material-ui/core/LinearProgress';

import { App } from '@meronex/app';
const useStyles = makeStyles((theme) => ({
  container: {},
}));

const shortenUrl = async ({ url, cb }) => {
  const isLocalHost = App.isLocalHost();

  if (isLocalHost) {
    console.log('localhost skip url shortening');
    if (cb) {
      cb(url);
      return url;
    }
  } else {
    console.log('shorten url');
    const bitlyToken = 'c15c1295038311a8b04f30042fe0b79cfd9de553';

    const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
      method: 'post',
      headers: new Headers({
        Authorization: 'Bearer ' + bitlyToken,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        long_url: url,
      }),
    });
    const jsonData = await response.json();
    if (cb) {
      cb(jsonData.link);
    }
    return jsonData.link;
  }
};

function QRCodeDialog(props) {
  const { open, onClose, url, enableShortenUrl = true } = props;

  const [shortUrl, setShortUrl] = React.useState(false);
  React.useEffect(() => {
    if (enableShortenUrl) {
      shortenUrl({
        url,
        cb: (_shortUrl) => {
          console.log(_shortUrl);
          setShortUrl(_shortUrl);
        },
      });
    }
  }, [url, open]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            textAlign: 'center',
            marginTop: '5px',
            marginBottom: '20px',
          }}>
          {enableShortenUrl && (
            <>
              {!shortUrl && (
                <div
                  style={{
                    padding: '15px',
                  }}>
                  <div
                    style={{
                      marginBottom: '15px',
                    }}>
                    Generating QR Code
                  </div>
                  <LinearProgress />
                </div>
              )}
              {shortUrl && (
                <QRCode
                  className={'qr-code-svg'}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  style={{ width: 256 }}
                  value={shortUrl}
                />
              )}
            </>
          )}
          {!enableShortenUrl && url && (
            <QRCode
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ width: 256 }}
              value={url}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

QRCodeDialog.propTypes = {
  open: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
  enableShortenUrl: PropTypes.bool,
};

export default QRCodeDialog;
