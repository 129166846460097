import React from 'react';
import PropTypes from 'prop-types';

import Fade from '@material-ui/core/Fade';

import SitesAvatar from '../../../../common/SitesAvatar';
import { Icons } from '../../../../common/Icons';

import ActiveStepInfo from '../../products/steps/ActiveStepInfo';
import StoreLink from '../../../../stores/components/stores/StoreLink';
import SitesUserName from '../../../../common/SitesUserName';

function WorkOrderInfo(props) {
  const { product, workOrder } = props;

  const getStatusInfo = () => {
    if (!workOrder) {
      return null;
    }

    console.log(workOrder);
    let activeStep;
    let activeStepIndex;
    if (workOrder && workOrder.product && workOrder.product.items) {
      activeStepIndex = workOrder.product.items.findIndex(
        (i) => (i.timer || {}).active
      );
      activeStep = workOrder.product.items[activeStepIndex];
    }
    console.log(activeStep);

    return (
      <div>
        <div
          style={{
            marginTop: '15px',
            fontSize: '18px',
            minHeight: workOrder.draft ? '60px' : 'inherit',
          }}>
          {workOrder.seqNum && <span> #{workOrder.seqNum}</span>}
          {workOrder.draft && <span> - {workOrder.draft.progress}% </span>}
          {workOrder.batchNum && <span>- {workOrder.batchNum}</span>}
          <Fade in={activeStep}>
            <ActiveStepInfo workOrder={workOrder} />
          </Fade>
        </div>
        {workOrder.destinationStore && (
          <div style={{ marginTop: '10px', fontSize: '12px', color: 'gray' }}>
            Will be stored at <StoreLink store={workOrder.destinationStore} />
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        marginBottom: '20px',
      }}>
      <div style={{ marginTop: '20px', paddingBottom: '10px' }}>
        <SitesAvatar image={product.image} name={product.name} size={80} />
      </div>
      <div
        style={{
          marginTop: '5px',
          fontWeight: 500,
        }}>
        <span
          style={{
            position: 'relative',
            left: '-8px',
            top: '2px',
          }}>
          <Icons.Products />
        </span>
        {product.name}{' '}
        {workOrder.draft && (
          <span>
            by <SitesUserName profile={workOrder.draft.createdBy} />
          </span>
        )}
      </div>{' '}
      {getStatusInfo()}
    </div>
  );
}
WorkOrderInfo.propTypes = {
  product: PropTypes.object,
  workOrder: PropTypes.object,
};

WorkOrderInfo.defaultProps = {};
export default WorkOrderInfo;
