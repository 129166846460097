import React from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import IEdit from '@meronex/icons/bi/BiEdit';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';
import IOpen from '@meronex/icons/md/MdOpenInNew';

import EquipmentInfo from './EquipmentInfo';

const EquipmentItem = (props) => {
  const { onClick, onEdit, onView, onDelete, equipment, last } = props;
  const [expanded, setExpanded] = React.useState(false);

  const _onClick = () => {
    console.log('onClick');
    setExpanded(!expanded);
    onClick(equipment);
  };

  const _onView = () => {
    onView(equipment);
  };
  const _onEdit = () => {
    onEdit(equipment);
  };
  const _onDelete = () => {
    onDelete(equipment);
  };

  return (
    <>
      <ListItem onClick={_onClick}>
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <EquipmentInfo equipment={equipment} />
          <div>
            <AnimateHeight
              duration={200}
              height={expanded ? 90 : 0} // see props documentation below
            >
              <div>
                <Grid
                  container
                  style={{
                    marginTop: '12px',
                    paddingBottom: '14px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}>
                  <Grid
                    item
                    style={{
                      cursor: 'pointer',
                    }}
                    xs={4}
                    onClick={_onView}>
                    <IconButton>
                      <IOpen style={{ color: 'green' }} />
                    </IconButton>
                    <div style={{ color: 'green' }}>View</div>
                  </Grid>
                  <Grid
                    item
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    xs={4}
                    onClick={_onEdit}>
                    <IconButton>
                      <IEdit style={{ color: 'rgb(85, 108, 214)' }} />
                    </IconButton>
                    <div style={{ color: 'rgb(85, 108, 214)' }}>Edit</div>
                  </Grid>
                  <Grid
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    item
                    xs={4}
                    onClick={_onDelete}>
                    <IconButton>
                      <IDelete style={{ color: 'red' }} />
                    </IconButton>
                    <div style={{ color: 'red' }}>Delete</div>
                  </Grid>
                </Grid>
              </div>
            </AnimateHeight>
          </div>
        </div>
      </ListItem>
      {!last && (
        <Divider
          style={{
            marginBottom: '5px',
          }}
        />
      )}
    </>
  );
};

EquipmentItem.propTypes = {
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
  equipment: PropTypes.object,
  last: PropTypes.bool,
};

EquipmentItem.defaultProps = {
  onClick: () => {
    console.log('on click');
  },
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onChange: (data) => console.log(data),
  onView: () => {
    console.log('on view');
  },
};
export default EquipmentItem;
