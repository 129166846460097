import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, AlertDialog, toast } from '@meronex/components';

import WorkOrderDraft from './WorkOrderDraft';
import { workOrdersMng } from '../../../api/WorkOrdersMng';

import { can, p } from '../../../../common/roles';

function WorkOrderDrafts(props) {
  const {
    drafts,
    onDraftClick,
    onDraftResume,
    onDraftDeleted,
    onCreateNewWorkOrder,
    containerStyle,
    showCreateWorkOrder,
  } = props;

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);

  const [deleting, setDeleting] = React.useState(false);
  const [selectedDraft, setSelectedDraft] = React.useState();

  if (!Array.isArray(drafts)) {
    return null;
  }

  const deleteDraft = async () => {
    console.log('delete draft');
    setDeleting(true);
    setOpenDeleteAlert(false);
    const result = await workOrdersMng.deleteWorkOrderDraft({
      workOrderDraft: selectedDraft,
    });
    console.log(result);
    setDeleting(false);
    if (onDraftDeleted) {
      onDraftDeleted();
    }
  };
  const renderDrafts = () => {
    console.log(drafts);
    return drafts.map((d, index) => {
      return (
        <WorkOrderDraft
          onClick={onDraftClick}
          onDelete={() => {
            setSelectedDraft(d);
            setOpenDeleteAlert(true);
          }}
          onResume={() => {
            onDraftResume(d);
          }}
          workOrder={d}
          index={index}
        />
      );
    });
  };
  return (
    <div style={containerStyle}>
      {can(p.factories.CRUDWorkOrders) && showCreateWorkOrder && (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: '40px',
            marginTop: '10px ',
          }}>
          <ActionBtn
            onClick={() => {
              onCreateNewWorkOrder();
            }}
            label={'Create new Work Order'}
            style={{
              width: '300px',
            }}
          />
          {drafts.length > 0 && (
            <div
              style={{
                marginTop: '25px',
                color: '#9f9f9f',
              }}>
              or resume an existing draft
            </div>
          )}
        </div>
      )}

      <div className={'mobile-padding'}>{renderDrafts()}</div>
      {openDeleteAlert && (
        <AlertDialog
          open={openDeleteAlert}
          loading={deleting}
          description={'Are you sure you want to delete draft?'}
          onConfirm={() => {
            deleteDraft();
          }}
          onClose={() => {
            setOpenDeleteAlert(false);
          }}
        />
      )}
    </div>
  );
}
WorkOrderDrafts.propTypes = {
  drafts: PropTypes.array,
  onDraftResume: PropTypes.func,
  onDraftClick: PropTypes.func,
  onDraftDeleted: PropTypes.func,
  onCreateNewWorkOrder: PropTypes.func,
  containerStyle: PropTypes.object,
  showCreateWorkOrder: PropTypes.bool,
};

WorkOrderDrafts.defaultProps = {
  containerStyle: {},
  showCreateWorkOrder: true,
};
export default WorkOrderDrafts;
