import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import MdArrowBack from '@meronex/icons/md/MdArrowBack';

import { App, AppContext } from '../../base/AppContext';
import LazyLoader from '../../components/LazyLoader';

const Settings = React.lazy(() => import('./Settings'));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(14, 14, 14)',
    height: '60px',
    color: theme.appBar.color,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsDialog(props) {
  const classes = useStyles();

  const { open, onClose } = props;

  const renderBody = () => {
    return (
      <LazyLoader>
        <Settings open={open} />
      </LazyLoader>
    );
  };

  return (
    <Dialog
      fullScreen={!!App.utils.isMobile()}
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close">
            <MdArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Settings
          </Typography>
        </Toolbar>
      </AppBar>
      {renderBody()}
    </Dialog>
  );
}

SettingsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
