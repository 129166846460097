import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { utils } from '../../base/utils';

import { useOnEnterPress } from '../hooks/useOnEnterPress';

function ActionBtn(props) {
  const { onEnter, disabled } = props;

  React.useEffect(() => {
    return () => {
      window.wait = false;
    };
  }, []);
  const _onAction = (type) => {
    console.log('on action');
    if (!window.wait) {
      window.wait = true;
      if (type === 'enter') {
        onEnter();
      } else if (type === 'click') {
        props.onClick();
      }
      setTimeout(() => {
        window.wait = false;
      }, 400);
    }
  };
  useOnEnterPress(() => {
    if (!disabled && onEnter) {
      _onAction('enter');
    }
  });

  const getBackgroundColor = () => {
    if (props.disabled || props.loading) {
      return 'rgb(158, 158, 158)';
    } else if (props.backgroundColor) {
      return props.backgroundColor;
    }

    return '#43c16a';
  };
  const mergedStyle = Object.assign(
    {
      color: 'white',
      width: '200px',
      height: '40px',
      borderRadius: '20px',
      backgroundColor: getBackgroundColor(),
    },
    props.style
  );

  return (
    <Button
      onClick={() => {
        if (!props.disabled && props.onClick) {
          _onAction('click');
        }
      }}
      disabled={props.disabled || props.loading}
      variant="contained"
      style={mergedStyle}>
      {props.loading && (
        <CircularProgress style={{ marginRight: '10px' }} size={20} />
      )}
      <span style={props.labelStyle}>{props.label || 'Save'}</span>
    </Button>
  );
}
ActionBtn.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onEnter: PropTypes.func,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  backgroundColor: PropTypes.string,
};

export default ActionBtn;
