import React from 'react';
import PropTypes from 'prop-types';

import Fab from '@material-ui/core/Fab';
import IAdd from '@meronex/icons/ios/MdAdd';

import IssuesList from './components/IssuesList';
import AddIssueDialog from './components/AddIssueDialog';

function Issues(props) {
  const [openIssueDialog, setOpenIssueDialog] = React.useState();

  return (
    <div>
      <IssuesList />
      <AddIssueDialog
        open={openIssueDialog}
        onClose={() => {
          setOpenIssueDialog(false);
        }}
      />
      <Fab
        onClick={() => {
          setOpenIssueDialog(true);
        }}
        aria-label="add"
        style={{
          position: 'fixed',
          right: '25px',
          bottom: '30px',
        }}>
        <IAdd size={25} />
      </Fab>
    </div>
  );
}
Issues.propTypes = {};

Issues.defaultProps = {};
export default Issues;
