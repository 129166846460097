import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MdMenu from '@meronex/icons/md/MdMenu';
import IProduct from '@meronex/icons/go/GoPackage';

import { Icons } from '../../../common/Icons';
import { can, p } from '../../../common/roles';
import { useHistory } from 'react-router-dom';

function FactoriesDrawer(props) {
  const history = useHistory();

  const {
    onViewWorkOrders,
    onManageEquipments,
    onViewDrafts,
    onOpenProducts,
    onClose,
  } = props;

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const _onClose = () => {
    setOpenDrawer(false);
    onClose();
  };

  return (
    <div>
      <Fab
        onClick={() => {
          setOpenDrawer(true);
        }}
        aria-label="add"
        style={{
          position: 'fixed',
          right: '25px',
          bottom: '30px',
        }}>
        <MdMenu size={25} />
      </Fab>
      <div>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            _onClose();
          }}>
          <List>
            <ListItem
              button
              key="equipments"
              onClick={() => {
                setOpenDrawer(false);
                onManageEquipments();
              }}>
              <ListItemIcon>
                <Icons.Equipments size={30} color={'rgba(0, 0, 0, 0.54)'} />
              </ListItemIcon>
              <ListItemText primary={'Equipments'} />
            </ListItem>
            <Divider />
            {can(p.factories.CRUDProducts) && (
              <>
                <ListItem
                  button
                  key="products"
                  onClick={() => {
                    setOpenDrawer(false);
                    onOpenProducts();
                  }}>
                  <ListItemIcon>
                    <IProduct size={25} />
                  </ListItemIcon>
                  <ListItemText primary={'Products'} />
                </ListItem>
                <Divider />
              </>
            )}
            <ListItem
              button
              key="viewWorkDrafts"
              onClick={() => {
                onViewDrafts();
                _onClose();
              }}>
              <ListItemIcon>
                <Icons.Drafts size={30} />
              </ListItemIcon>
              <ListItemText primary={'View Drafts'} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="viewWorkOrders"
              onClick={() => {
                onViewWorkOrders();
                _onClose();
              }}>
              <ListItemIcon>
                <Icons.WorkOrder size={30} />
              </ListItemIcon>
              <ListItemText primary={'View Work Orders'} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="close"
              onClick={() => {
                _onClose();
              }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '18px',
                }}>
                Close{' '}
              </div>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </div>
  );
}
FactoriesDrawer.propTypes = {
  onViewWorkOrders: PropTypes.func,
  onViewDrafts: PropTypes.func,
  onOpenProducts: PropTypes.func,
  onClose: PropTypes.func,
};

FactoriesDrawer.defaultProps = {
  onViewWorkOrders: () => {},
  onOpenProducts: () => {},
  onManageEquipments: () => {},
  onClose: () => {},
};
export default FactoriesDrawer;
