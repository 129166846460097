import React from 'react';
import PropTypes from 'prop-types';
import { NoData } from '@meronex/components';

import AddFieldDialog from './AddFieldDialog';

import { render } from 'react-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import Field from './Field';

const SortableItem = SortableElement(({ value, index }) => {
  return <Field value={value} key={index} />;
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${value}`} index={index} value={value} />
      ))}
    </div>
  );
});

class SortableComponent extends React.Component {
  state = {
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMoveImmutable(items, oldIndex, newIndex),
    }));
  };
  render() {
    return (
      <SortableList
        helperClass={'draggable-item'}
        useDragHandle={true}
        items={this.state.items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

function QualityForm(props) {
  const [openAddField, setOpenAddField] = React.useState();

  const [fields, setFields] = React.useState([
    { type: 'range', text: 'test one', points: 2 },
  ]);
  return (
    <div>
      <div
        style={{
          position: 'relative',
        }}>
        <div
          style={{
            marginLeft: '20px',
            marginRight: '20px',
          }}>
          <SortableComponent />
        </div>
        <NoData
          style={{
            padding: '5px',
          }}
          message={'Add new field'}
          onClick={() => {
            setOpenAddField(true);
          }}
        />
      </div>

      {openAddField && (
        <AddFieldDialog
          open={openAddField}
          onClose={() => {
            setOpenAddField(false);
          }}
        />
      )}
    </div>
  );
}
QualityForm.propTypes = {};

QualityForm.defaultProps = {};
export default QualityForm;
