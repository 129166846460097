import React, { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';

import { App } from '@meronex/app';

import NoData from '../NoData';
import Loading from '../Loading';
import EndlessScrollFooter from './EndlessScrollFooter';
import DefaultScrollRenderer from './DefaultScrollRenderer';
import { _reloadRange, _loadMore, _reloadItem } from './methods';
import { useWindowSize } from '@react-hook/window-size';

const EndlessScroll = forwardRef((props, ref) => {
  const { size, server } = App;

  const {
    height,
    limit,
    noRowRenderer,
    onScroll,
    remoteRowCount,
    scrollRenderer,
    getListItems,
    getListItem,
    selectedItemIndex,
    mobileBottomMargin,
    desktopBottomMargin,
  } = props;

  const listRef = React.useRef();
  const [width, winHeight] = useWindowSize();

  const [state, setState] = React.useState({
    hasMore: true,
    selectedItemIndex: undefined,
    selectedItem: undefined,
    scrollToIndex: 0,
  });
  const [items, setItems] = React.useState(null);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const [rangeIndices, setRangeIndices] = React.useState({
    start: 0,
    end: 8,
  });
  const [dataKey, setDataKey] = React.useState(Symbol());

  React.useEffect(() => {
    loadMore(0);
  }, []);

  useImperativeHandle(ref, () => ({
    refreshList,
    refreshItems,
    refreshSelectedItem,
  }));

  const loaded = Boolean(items && server.connected && size);

  const loadMore = async (skip = 0) => {
    const { hasMore } = state;
    if (skip === 0 || hasMore) {
      const { hasMore, _items } = await _loadMore({
        items,
        skip,
        limit,
        getListItems,
      });
      setItems(_items);
      setState({
        hasMore,
      });
    }
  };
  /**
   * Reset the storeItems list (back to the top) while applying
   * the current filters.
   *
   * clearItems: will clear the items and add them again this will show a loader
   *
   *
   * If the order is changed, then better clear and scroll to top, because this
   * ensure the subarray before the window is also updated.
   */
  const refreshList = async (scrollToTop) => {
    loadMore(0);
  };

  /**
   * Refresh the current range index of the storeItems without resetting the list
   * or scrolling.
   */
  const refreshItems = async () => {
    const refreshedItems = await _reloadRange({
      getListItems,
      startIndex: rangeIndices?.startIndex || 0,
      endIndex: rangeIndices?.endIndex,
      items,
    });
    setItems(refreshedItems);
  };

  const refreshSelectedItem = async () => {
    const _items = await _reloadItem({ selectedItemIndex, items, getListItem });
    console.log(_items);
    setItems(_items);
    setDataKey(Symbol());
  };

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    style, // Style object to be applied to row (to position it)
  }) => {
    const { renderItem } = props;

    let _style = { ...style };
    let data;
    if (items && items[index]) {
      data = items[index];
    }

    if (data && data.isModified) {
      _style.backgroundColor = '#afbbe01a';
    }
    return (
      <div key={key} style={_style}>
        {data && renderItem(data, isScrolling, index)}
      </div>
    );
  };

  const renderOnScrollContent = () => {
    if (items && isScrolling) {
      return (
        <DefaultScrollRenderer
          items={items}
          startIndex={rangeIndices.startIndex}
          stopIndex={rangeIndices.endIndex}
          scrollRenderer={scrollRenderer}
        />
      );
    }
    return null;
  };

  const getHeight = () => {
    if (height) {
      return height;
    }
    let isMobile = App.utils.isMobile();
    // increase the offset to see more/increase height
    let offset = isMobile ? mobileBottomMargin : desktopBottomMargin;
    return winHeight - winHeight * offset;
  };
  const renderList = () => {
    const { hasMore } = state;

    if (!loaded) {
      return <Loading text={'Loading...'} />;
    }
    if (items.length === 0) {
      return noRowRenderer();
    }
    return (
      <>
        <Virtuoso
          dataKey={dataKey}
          ref={listRef}
          style={{ height: getHeight(), overflowX: 'hidden' }}
          overscan={300}
          endReached={(i) => {
            loadMore(i + 1);
          }}
          rangeChanged={(range) => {
            setRangeIndices(range);
          }}
          isScrolling={(isScrolling) => {
            setIsScrolling(isScrolling);
            if (typeof onScroll === 'function') {
              onScroll();
            }
          }}
          data={items}
          totalCount={remoteRowCount}
          itemContent={(index) => {
            return rowRenderer({
              key: index,
              index,
              isVisible: true,
              style: {},
            });
          }}
          components={{
            Footer: () => {
              return <EndlessScrollFooter hasMore={hasMore} />;
            },
          }}
        />
        {renderOnScrollContent()}
      </>
    );
  };
  return (
    <div
      style={{
        overflow: 'hidden',
      }}>
      {renderList()}
    </div>
  );
});

export default EndlessScroll;

EndlessScroll.propTypes = {
  // required
  getListItems: PropTypes.func,
  // required
  renderItem: PropTypes.func,
  // optional
  noRowRenderer: PropTypes.func,
  onScroll: PropTypes.func,
  // optional, default 9
  limit: PropTypes.number,
  scrollRenderer: PropTypes.func,
  // optional, can be left empty
  remoteRowCount: PropTypes.number,
  // optional, used to refresh a single item
  selectedItemIndex: PropTypes.number,
  // optional, single item to refresh on edit
  getListItem: PropTypes.func,
  //  optional, bottom margin from 0 -> 0.9
  mobileBottomMargin: PropTypes.number,
};
EndlessScroll.defaultProps = {
  getListItems: ({ skip, limit }) => [],
  getListItem: (itemId) => undefined,
  renderItem: (data, isScrolling, index) => {},
  remoteRowCount: 99999,
  limit: 9,
  mobileBottomMargin: 0.1,
  desktopBottomMargin: 0.25,
  noRowRenderer: () => {
    return <NoData message={'No Items Available'} />;
  },
};
