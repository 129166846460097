import React from 'react';
import PropTypes from 'prop-types';

import View from '../views/components/View';

import { Icons } from '../common/Icons';

import Equipments from './Equipments';

const VIEW_ID = 'equipments';

function EquipmentsView(props) {
  const { activeView } = props;

  const [filterValue, setFilterValue] = React.useState();

  return (
    <View
      title={'Equipments'}
      viewId={VIEW_ID}
      activeView={activeView}
      showMenu
      filterProps={{
        onFilter: (v) => {
          setFilterValue(v);
        },
      }}
      icon={<Icons.Equipments size={25} />}>
      <Equipments filterValue={filterValue} />
    </View>
  );
}
EquipmentsView.propTypes = {
  activeView: PropTypes.string,
};

EquipmentsView.defaultProps = {};
export default EquipmentsView;
