import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ReadMore extends Component {
  static propTypes = {
    textContent: PropTypes.object.isRequired,
    textLength: PropTypes.number
  };
  state = {
    showMore: false
  };

  render() {
    const { textContent, textLength } = this.props;
    const { showMore } = this.state;

    return !showMore && textLength && textLength > 400 ? (
      <div>
        <div
          style={{
            overflow: 'hidden',
            position: 'relative',
            height: '6.4em'
          }}>
          {textContent}
        </div>
        <div>
          <a
            style={{
              cursor: 'pointer',
              color: 'rgb(0, 171, 255)',
              fontWeight: 'bold'
            }}
            onClick={() => {
              this.setState({ showMore: true });
            }}>
            See more...
          </a>
        </div>
      </div>
    ) : (
      <div>{textContent}</div>
    );
  }
}
