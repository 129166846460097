import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//TODO: replace with <Icons.X/>
import IList from '@meronex/icons/ios/MdList';

import AddStoreCategoryDialog from './AddStoreCategoryDialog';
import EditStoreCategoryDialog from './EditStoreCategoryDialog';
import StoreCategoriesList from './StoreCategoriesList';

import { storeCategoriesMng } from '../api/storeCategoriesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function StoreCategoriesDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;

  const [openAddStoreCategory, setOpenAddStoreCategory] = React.useState();
  const [openEditStoreCategory, setOpenEditStoreCategory] = React.useState();
  const [selectedStoreCategory, setSelectedStoreCategory] = React.useState();
  const [storeCategories, setStoreCategories] = React.useState();

  const getStoreCategories = async () => {
    console.log('getStoreCategories');
    const storeCategories = await storeCategoriesMng.getStoreCategories();
    setStoreCategories(storeCategories);
  };

  React.useEffect(() => {
    getStoreCategories();
  }, []);

  React.useEffect(() => {
    if (selectedStoreCategory) {
      setOpenEditStoreCategory(true);
    }
  }, [selectedStoreCategory]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IList /> Store Categories
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <StoreCategoriesList
            storeCategories={storeCategories}
            onClick={(storeCategory) => {
              console.log(storeCategory);
            }}
            onEdit={(storeCategory) => {
              console.log(storeCategory);
              setSelectedStoreCategory(storeCategory);
              setOpenEditStoreCategory(true);
            }}
            onDelete={getStoreCategories}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            marginTop: '20px',
          }}>
          <ActionBtn
            onClick={() => {
              setSelectedStoreCategory(false);
              setOpenAddStoreCategory(true);
            }}
            label={<div>Add Store Category</div>}
          />
        </div>
        {openAddStoreCategory && (
          <AddStoreCategoryDialog
            open={openAddStoreCategory}
            onAdd={getStoreCategories}
            onClose={() => {
              setOpenAddStoreCategory(false);
              setSelectedStoreCategory(null);
            }}
          />
        )}
        {openEditStoreCategory && (
          <EditStoreCategoryDialog
            open={openEditStoreCategory}
            storeCategory={selectedStoreCategory}
            onEdit={getStoreCategories}
            onClose={() => {
              setOpenEditStoreCategory(false);
              setSelectedStoreCategory(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

StoreCategoriesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StoreCategoriesDialog;
