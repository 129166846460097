const incidentsUtils = {};

const incidentsValues = {
  hseIncident: 'HSE Incident',
  fireAlarm: 'Fire Alarm',
  propertyDamage: 'Property Damage',
  equipmentMalfunction: 'Equipment Malfunction',
  hygienicComplaints: 'Hygienic Complaints',
  coronaIncident: 'Corona Incident',
  other: 'Other Incidents'
};

const getIncidentsOptions = () => {
  const values = Object.entries(incidentsValues);
  const options = [];
  values.forEach(value => {
    options.push({
      key: value[0],
      value: value[0],
      label: value[1]
    });
  });
  return options;
};
incidentsUtils.incidentsValues = incidentsValues;
incidentsUtils.getIncidentsOptions = getIncidentsOptions;
incidentsUtils.defaultIncident = 'other';

export default incidentsUtils;
