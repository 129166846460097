import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import React from 'react';
import PropTypes from 'prop-types';

export const DrawerClose = (props) => (
  <ListItem
    button
    key={'key'}
    onClick={() => {
      props.onClick(false);
    }}>
    <div
      style={{
        height: '30px',
        paddingTop: '5px',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center',
      }}>
      Close
    </div>
  </ListItem>
);

DrawerClose.propTypes = {
  onClick: PropTypes.func,
};
