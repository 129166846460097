import React from 'react';

import { login } from './login';
import { header } from './header';
import { views } from './views';

export const config = {
  login,
  header,
  views,
};
