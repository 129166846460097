import React from 'react';
import PropTypes from 'prop-types';

import MenuList from './MenuList';
import { useTheme } from '@material-ui/core/styles';

export default function SideMenu(props) {
  const { onClick, value } = props;
  const theme = useTheme();
  return (
    <div
      style={{
        paddingLeft: '50px',
        paddingRight: '50px',
        position: 'sticky',
        top: '0',
        marginTop: '20px',
      }}>
      <div
        style={{
          padding: '10px',
          borderRadius: '10px',
          boxShadow: '0 5px 10px rgba(0,0,0,.05)',
          backgroundColor: theme.palette.background.paper,
        }}>
        <MenuList onClick={onClick} value={value} />
      </div>
    </div>
  );
}

SideMenu.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.number,
};
