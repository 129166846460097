import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListView from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withTheme } from '@material-ui/core/styles';

import { App, AppContext } from '@meronex/app';

import Avatar from '@material-ui/core/Avatar';
import SpeedDial from '@material-ui/lab/SpeedDial/SpeedDial';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  InfiniteLoader,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import BsChevronDoubleUp from '@meronex/icons/bs/BsChevronDoubleUp';
import GiBackwardTime from '@meronex/icons/gi/GiBackwardTime';
import BiCommentError from '@meronex/icons/bi/BiCommentError';

import { notifMng } from '../api';
import { utils } from '../../../base/utils';

const NotificationTypeEnum = {
  COMMENT_ON_LOG: 'commentOnLog',
  MEMBER_APPROVED: 'memberApproved',
  ACCEPT_INVITATION: 'acceptInvitation',
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 50,
});

class NotificationsList extends Component {
  state = {
    remoteRowCount: 10,
    notifications: undefined,
    hasMore: true,
    selectedNotificationIndex: undefined,
    selectedNotification: undefined,
    openCardDialog: false,
    renderData: undefined,
    fastRender: true,
    user: undefined,
    currentlyScrolling: false,
  };

  static propTypes = {
    setUnreadNotificationsCount: PropTypes.func,
    updateNotifications: PropTypes.func,
    setUpdateNotifications: PropTypes.func,
    theme: PropTypes.object,
    unreadNotificationsCount: PropTypes.number,
  };
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }
  componentDidMount() {
    this.init();
    App.eventsManager.on('event-user-loaded', this, () => {
      this.init();
    });
    App.eventsManager.on('event-refresh-feed', this, () => {
      this.refreshNotifications();
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.updateNotifications && this.props.updateNotifications) {
      this.updateNotifications();
      this.props.setUnreadNotificationsCount(0);
      this.props.setUpdateNotifications(false);
    }
  }

  init = () => {
    const { notifications } = this.state;
    window.lastVisitIndexStamp = undefined;
    if (!notifications) {
      this.loadMoreRows({ startIndex: 0, stopIndex: 9 });
    } else {
      this.refreshNotifications();
    }
  };

  handleScroll = () => {
    if (this._timeout) {
      // if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._timeout = null;
      this.setState({
        currentlyScrolling: false,
      });
    }, 500);
    if (!this.state.currentlyScrolling) {
      this.setState({
        currentlyScrolling: true,
      });
    }
  };

  isEmpty = () => {
    const { notifications, fetching } = this.state;
    return (
      Array.isArray(notifications) && notifications.length === 0 && !fetching
    );
  };

  /**
   * Reset the notifications list (back to the top)
   */
  refreshNotifications = () => {
    window.lastVisitIndexStamp = undefined;
    this.loadMoreRows({ startIndex: 0, stopIndex: 10, refresh: true });
    this.scrollToTop();
  };

  /**
   * Update the current range index of the notifications without resetting the list
   * or scrolling.
   */
  updateNotifications = () => {
    const { renderData } = this.state;
    if (renderData) {
      this.loadMoreRows({
        startIndex: renderData.startIndex,
        stopIndex: renderData.stopIndex + 1,
      });
    }
  };

  scrollToTop = () => {
    const list = (this.listRef || {}).current;
    if (list) {
      list.scrollToRow(0);
    }
  };

  setFastRender = (fastRender) => {
    this.setState({
      fastRender,
    });
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
    parent,
  }) => {
    const _style = { ...style };
    const { notifications } = this.state;
    let data;
    if (notifications && notifications[index]) {
      data = notifications[index];
    }

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}>
        {({ measure }) => (
          <div key={key} style={_style}>
            {data && this.renderNotification(data)}
          </div>
        )}
      </CellMeasurer>
    );
  };

  renderNotification = (notification) => {
    if (!notification) {
      return <div />;
    }

    const { actor, type } = notification;

    const actorObj = (actor && actor[0]) || {
      emails: [{ address: 'unknown@email.com' }],
      profile: {
        firstName: 'Unknown',
        lastName: 'Unknown',
      },
    };

    const smartAvatar = (
      <Avatar
        style={{
          width: '40px',
          height: '40px',
        }}
        alt={App.utils.getUserName(actorObj)}
        src={actorObj.profile.avatar}
      />
    );

    let notificationTitle = 'No notification title';
    switch (type) {
      case NotificationTypeEnum.COMMENT_ON_LOG:
        notificationTitle = `${actorObj.profile.firstName} ${actorObj.profile.lastName} has commented on your card!`;
        break;
      case NotificationTypeEnum.MEMBER_APPROVED:
        notificationTitle = `Member (${actorObj.emails[0].address}) has been approved to join your community!`;
        break;
      case NotificationTypeEnum.ACCEPT_INVITATION:
        notificationTitle = `Member (${actorObj.emails[0].address}) has accepted the invitation to join your community!`;
        break;
      default:
        console.log('Notification type not supported');
        break;
    }

    const backgroundColor = !notification.read.includes(App.getUser()._id)
      ? this.props.theme.palette.recentlySeen.backgroundColor
      : '';

    return (
      <ListItem
        alignItems="flex-start"
        style={{ backgroundColor }}
        onClick={() => {
          // Only execute if the notification is marked unread i.e. the backgroundColor is set
          if (backgroundColor) {
            App.server.call('notifications.mark', [notification._id], true);
            this.props.setUnreadNotificationsCount(
              this.props.unreadNotificationsCount - 1
            );
            this.updateNotifications();
          }
        }}>
        <ListItemAvatar>{smartAvatar}</ListItemAvatar>
        <ListItemText
          style={{
            fontSize: '14px',
          }}
          primary={notificationTitle}
          secondary={utils.time.timeAgo(notification.createdAt)}
        />
      </ListItem>
    );
  };

  isRowLoaded = ({ index }) => {
    const { notifications } = this.state;
    return !!notifications[index];
  };

  getLoader = () => {
    const { notifications } = this.state;
    const firstLoad = !notifications || notifications.length === 0;

    return (
      <div
        key={'loader'}
        style={{
          marginRight: '-30px',
          marginTop: firstLoad ? '30px' : '10px',
          marginBottom: '125px',
          textAlign: 'center',
          overflow: 'hidden',
        }}>
        {' '}
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={24}
          thickness={4}
        />
        {firstLoad && <div style={{ color: 'gray' }}>Loading...</div>}
      </div>
    );
  };

  loadMoreRows = async ({ startIndex, stopIndex, refresh = false }) => {
    const { notifications, hasMore } = this.state;

    if (!App.server.connected) {
      console.warn('Waiting for server to connect,');
    } else {
      console.log(`load more ${startIndex} ${stopIndex}`);
      if (hasMore) {
        const fetchedNotifications = await notifMng.fetchNotifications(
          stopIndex - startIndex,
          startIndex
        );
        let _notifications = notifications;
        if (!_notifications || refresh) {
          _notifications = [];
        }
        if (fetchedNotifications.length > 0) {
          // Update the underlying notifications list based on the fetched ranged
          for (let i = startIndex; i < stopIndex; i += 1) {
            // Ignore undefined storeItems
            const item = fetchedNotifications[i - startIndex];
            if (item) {
              _notifications[i] = item;
            }
          }
        }

        let selectedNotification = this.state.selectedNotification;
        if (selectedNotification) {
          console.log(
            `updating selected notification  ${selectedNotification}`
          );
          selectedNotification =
            _notifications[this.state.selectedNotificationIndex];
        }
        if (_notifications.length > 0) {
          this.setState({
            notifications: _notifications,
            remoteRowCount: _notifications.length + 10,
            selectedNotification,
          });
        } else {
          this.setState({
            hasMore: false,
            notifications: _notifications,
            selectedNotification,
          });
        }
        return Promise.resolve(_notifications);
      }
      console.info('has more is false');
    }
  };

  render() {
    const {
      notifications,
      remoteRowCount,
      renderData,
      currentlyScrolling,
    } = this.state;

    const { size, server } = App;

    const parentContainer = document.getElementById('notification-dialog');
    const width = parentContainer ? parentContainer.clientWidth : '600px';
    const loaded = notifications && server.connected && size;

    return (
      <div
        onScroll={this.handleScroll}
        id={'notifications-container'}
        style={{
          marginTop: '0px',
        }}>
        {!loaded && this.getLoader()}
        {loaded && (
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={remoteRowCount}
            style={{
              paddingTop: '0px',
              overflow: 'hidden',
            }}>
            {({ onRowsRendered, registerChild }) => (
              <>
                <ListView
                  ref={registerChild}
                  style={{
                    paddingTop: '0px',
                    overflowX: 'hidden',
                  }}>
                  <List
                    style={{ paddingTop: '0px' }}
                    height={size.height * 0.75}
                    onRowsRendered={(data) => {
                      if (data) {
                        this.setState({
                          renderData: data,
                        });
                        onRowsRendered(data);
                      }
                    }}
                    ref={this.listRef}
                    rowCount={notifications.length}
                    deferredMeasurementCache={cache}
                    rowHeight={cache.rowHeight}
                    rowRenderer={this.rowRenderer}
                    noRowsRenderer={() => {
                      return (
                        <div
                          style={{
                            marginTop: '70px',
                            textAlign: 'center',
                            fontSize: '20px',
                            color: 'rgb(107, 107, 107)',
                          }}>
                          <BiCommentError size={150} />
                          <div>No Notifications</div>
                        </div>
                      );
                    }}
                    // scrollToAlignment={'center'}
                    width={width}
                    containerStyle={{
                      marginBottom: '50px',
                    }}
                  />
                </ListView>
                {notifications && renderData && currentlyScrolling && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '85px',
                      width: '100%',
                      textAlign: 'center',
                    }}>
                    <div
                      style={{
                        width: '240px',
                        margin: 'auto',
                        backgroundColor: 'rgba(25, 25, 25, 0.74)',
                        color: 'white',
                        borderRadius: '10px',
                        padding: '2px',
                        fontSize: '15px',
                      }}>
                      <div
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                          left: '-8px',
                          top: '2px',
                        }}>
                        <GiBackwardTime size={22} />
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                          top: '-5px',
                        }}>
                        {utils.time.timeFullFormat(
                          (notifications[renderData.startIndex] || {}).createdAt
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {renderData && renderData.startIndex > 1 && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '40px',
                      width: '100%',
                      textAlign: 'center',
                    }}>
                    <SpeedDial
                      className={'logs-scroll-top'}
                      style={{
                        transition: 'none',
                        position: 'relative',
                        top: '-40px',
                      }}
                      ariaLabel="SpeedDial openIcon example"
                      onClick={() => {
                        this.scrollToTop();
                      }}
                      icon={<BsChevronDoubleUp size={30} />}
                      onClose={() => {}}
                      onOpen={() => {}}
                      open={false}
                    />
                  </div>
                )}
              </>
            )}
          </InfiniteLoader>
        )}
      </div>
    );
  }
}

export default withTheme(NotificationsList);

NotificationsList.contextType = AppContext;
