import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import IWarn from '@meronex/icons/ai/AiFillWarning';

import { Icons } from '../../../common/Icons';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function StoreArchivesWarningsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, archiveWarnings } = props;

  if (!archiveWarnings) {
    return null;
  }
  const getWarnings = () => {
    let warnings;
    if (
      archiveWarnings.error === 'archiveStore.destinationStore' ||
      archiveWarnings.error === 'archiveStore.productStoreItems'
    ) {
      warnings = (
        <div style={{ marginTop: '10px' }}>
          {archiveWarnings.reason}
          <ul>
            {archiveWarnings.details.map((product) => {
              return (
                <li style={{ fontSize: '12px', marginBottom: '2px' }}>
                  <Icons.Products />
                  &nbsp;{product.name}{' '}
                  {Array.isArray(product.factory) &&
                    product.factory.length > 0 && (
                      <span>
                        {' '}
                        at factory <Icons.Factories /> {product.factory[0].name}
                      </span>
                    )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return <div>{warnings}</div>;
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <IWarn color={'orange'} />
        &nbsp; Archive Warnings
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>Could not archive store.</div>
        <div>{getWarnings()}</div>
        <div style={{ marginTop: '10px' }}></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

StoreArchivesWarningsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StoreArchivesWarningsDialog;
