import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = {};
// Custom app theme stuff
const appTheme = {
  dark: {
    tilesFont: 'rgb(199, 199, 199)',
  },
  light: {
    tilesFont: 'white',
  },
};
theme.dark = createMuiTheme({
  appTheme: appTheme.dark,
  appBar: {
    color: 'white',
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#4c7cd2',
    },
    recentlySeen: {
      backgroundColor: '#716e61',
    },
  },
});
theme.light = createMuiTheme({
  // direction: "rtl",
  appTheme: appTheme.light,
  appBar: {
    color: 'white',
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    recentlySeen: {
      backgroundColor: '#fff5de',
    },
  },
});
export default theme;
