import React from 'react';
import PropTypes from 'prop-types';

import { Form, ImageUploader } from '@meronex/components';

import TextField from '@material-ui/core/TextField';
import StoreCategoriesSelect from './StoreCategoriesSelect';

function StoreCategoryForm(props) {
  const { onChange = () => {}, values = {}, validateOnInit } = props;

  return (
    <div>
      <Form
        validateOnInit={validateOnInit}
        blurDelay={500}
        onUpdate={(_data) => {
          if (_data) {
            const values = _data.values;
            if (values) {
              values.checked = _data.checked;
              values.isValid = _data.isValid;
            }
            onChange(values);
          }
        }}>
        <TextField
          name={'name'}
          label="Name"
          autoFocus={true}
          defaultValue={(values || {}).name}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
        />
      </Form>
    </div>
  );
}
StoreCategoryForm.propTypes = {
  values: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
};

StoreCategoryForm.defaultProps = {
  values: {},
  validateOnInit: false,
  onChange: (values) => console.log(values),
};
export default StoreCategoryForm;
