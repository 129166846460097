import simpleDDP from 'simpleddp'; // ES6
import { simpleDDPLogin } from 'simpleddp-plugin-login';

import ws from 'isomorphic-ws';
import { toast } from 'react-toastify';

let App;

function gracefulSocket(endPoint) {
  try {
    return new ws(endPoint);
  } catch (e) {
    console.error(e);

    return null;
  }
}

let server;
let _opts;
export const initServer = (opts, app) => {
  _opts = {
    endpoint:
      window.location.hostname === 'localhost'
        ? app.metadata.devEndpoint
        : app.metadata.endpoint,
    SocketConstructor: gracefulSocket,
    maxTimeout: 50000,
    reconnectInterval: 500,
  };
  App = app;

  try {
    server = new simpleDDP(_opts, [simpleDDPLogin]);
    // attach events
    server = attachEvents(server);
    // attach helper functions
    server.getUser = async () => {
      return await server.call('getUser', {});
    };
    // attach the server to window
    window.server = server;
    return server;
  } catch (e) {
    console.error(e);
  }
};

const resumeLogin = async () => {
  const token = App.clientSettings.getItem('token');

  if (token) {
    try {
      await server.login({ resume: token });
    } catch (e) {
      // failed auth by token
    }
  }
};
const attachEvents = (server) => {
  server.on('connected', async () => {
    App.clientSettings.setItem('connected', true);
    console.log(`[App/server] Connected to endpoint: ${_opts.endpoint}`);
    await resumeLogin();
    App.eventsManager.emit('event-connected', 'server.js/on/connected');
  });
  server.on('disconnected', () => {
    // for example show alert to user
    App.clientSettings.setItem('connected', false);
    App.eventsManager.emit('event-disconnected', 'server.js/on/disconnected');
  });
  server.on('error', (e) => {
    // global errors from server
    console.error(e);
    if (e.error == 'no-user') {
      console.error(e.reason);
    } else {
      toast.error(
        'Unable to connect to the server, please check with the admin.'
      );
    }
  });
  server.on('ping', (m) => {
    console.log('[App/server] ping');
  });
  server.on('pong', (m) => {
    console.log('[App/server]  pong');
  });
  server.on('login', (m) => {
    console.log('User logged in as', m);
    App.eventsManager.emit('event-resume-login', 'server.js/on/login');
  });

  server.on('logout', () => {
    console.log('[App/server] User logged out');
  });

  server.on('loginSessionLost', async (id) => {
    console.log(
      `[App/server] User {id} lost connection to server, will auto resume by default with token`
    );
    if (window.sessionStorage.getItem('updatingPassword') === 'true') {
      window.sessionStorage.removeItem('updatingPassword');
      App.clientSettings.removeItem('token');
      App.clientSettings.removeItem('userId');
      toast.success('Password updated, please login again to confirm');
      window.location.replace('/login');
    }
  });
  server.on('loginResume', (m) => {
    console.log('[App/server] User resumed (logged in by token)', m);
  });
  server.on('loginResumeFailed', (m) => {
    console.log(
      '[App/server] Failed to resume authorization with token after reconnection ',
      m
    );
  });
  return server;
};
