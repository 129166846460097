import { App } from '@meronex/app';

const { server } = App;

export const sitesMng = {};

const getSites = async () => {
  const sites = await server.call('getSites', {});
  return sites;
};

const getAuthors = async () => {
  const authors = await server.call('getCommunityMembers', {
    filterByCommunity: true
  });
  return authors;
};

sitesMng.getSites = getSites;
sitesMng.getAuthors = getAuthors;
