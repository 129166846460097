import React from 'react';
import Truncate from 'react-truncate';

import { App } from '@meronex/app';
import IStore from '@meronex/icons/mdc/MdcWarehouse';

import Quantity from '../../../../common/Quantity';

const getQuantityColor = (quantity) => {
  if (quantity === 0) {
    return 'gray';
  }
  return quantity <= 0 ? 'red' : 'rgb(85, 108, 214)';
};

const getNumQuantity = (quantity) => {
  return isNaN(quantity) ? 0 : quantity;
};

const getSelectedStoreQuantity = (selectedStore) => {
  let selectedStoreQuantity = selectedStore
    ? getNumQuantity((selectedStore.storeItem || {}).quantity)
    : 0;
  return selectedStoreQuantity;
};

const renderLabel = ({ icon, text, direction }) => {
  return (
    <div
      style={{
        color: 'gray',
        position: 'relative',
        top: '28px',
      }}>
      <div
        style={{
          position: 'absolute',
          top: '-18px',
          whiteSpace: 'nowrap',
          float: 'right',
          width: '100%',
        }}>
        <div>
          <span
            style={{
              position: 'relative',
              right: '4px',
            }}>
            {icon}
          </span>
          <Truncate lines={1} width={120} ellipsis={<span>..</span>}>
            {text}
          </Truncate>
        </div>
      </div>
    </div>
  );
};
const renderStoreQuantity = ({ quantity = 0, unit, color }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'inline-block' }}>
        <Quantity
          style={{
            color: color || 'gray',
          }}
          textAlign
          enableChangeDirAnimation={true}
          enableAutoSizeShrink={true}
          quantity={quantity}
          unitLabel={unit}
          unitLabelStyle={{
            fontSize: '10px',
          }}
          changeDirStyle={{
            textAlign: 'center',
          }}
          quantitySize={'24px'}
        />
      </div>
    </div>
  );
};

const getSourceLabel = (data) => {
  const { selectedStore, item, quantity, type } = data;

  // construct the quantity data
  let _quantity = getNumQuantity(quantity);
  // construct the label data
  let label = renderLabel({
    icon: <IStore />,
    text: item.store.name,
    direction: 'out from:',
  });
  _quantity = item.quantity - _quantity;

  const sourceLabel = (
    <div>
      {renderStoreQuantity({
        quantity: _quantity,
        color: getQuantityColor(_quantity),
        unit: item.type.unit,
        textAlign: 'center',
      })}
      {label}
    </div>
  );
  return sourceLabel;
};
const getTargetLabel = (data) => {
  const { quantity, item, selectedStore } = data;

  let _quantity = getNumQuantity(quantity);
  let selectedStoreQuantity = getSelectedStoreQuantity(selectedStore);
  console.log(selectedStoreQuantity);
  let targetLabel;

  let label;

  if (selectedStore) {
    _quantity = selectedStoreQuantity + _quantity;
    label = renderLabel({
      icon: <IStore />,
      text: selectedStore.name,
    });
  } else {
    label = renderLabel({
      text: 'Target Store ?',
    });
  }
  targetLabel = (
    <div>
      {renderStoreQuantity({
        quantity: _quantity,
        color: getQuantityColor(_quantity),
        unit: item.type.unit,
        label,
      })}
      {label}
    </div>
  );
  return targetLabel;
};
const getEdgeLabel = (data) => {
  const { quantity } = data;
  const _quantity = getNumQuantity(quantity);
  let color = 'gray';
  if (_quantity > 0) {
    color = 'green';
  } else if (_quantity < 0) {
    color = 'red';
  }
  const edgeLabel = (
    <span
      style={{
        color,
      }}>
      {_quantity !== 0 && <span>{_quantity > 0 ? '+' : '-'}</span>}
      {App.utils.number.thousandsSeparator(_quantity)}
    </span>
  );
  return edgeLabel;
};

const getActionData = (data) => {
  return {
    sourceLabel: getSourceLabel(data),
    targetLabel: getTargetLabel(data),
    edgeLabel: getEdgeLabel(data),
  };
};

export const actionHelpers = {
  getActionData,
};
