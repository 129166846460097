import React from 'react';
import PropTypes from 'prop-types';
import MdClose from '@meronex/icons/md/MdClose';

import { useTheme } from '@material-ui/core';

import { App } from '@meronex/app';

export default function CloseBtn(props) {
  const theme = useTheme();

  const { style } = props;

  const mergedStyle = Object.assign(
    {
      fontSize: '25px',
      position: 'absolute',
      float: 'right',
      display: 'inline-block',
      color: props.color || theme.palette.text.primary,
      cursor: 'pointer',
      zIndex: '4',
      right: '0',
      width: '80px',
      height: '50px',
      textAlign: 'center',
      top: '0',
      paddingTop: '15px',
    },
    style
  );
  return (
    <div
      className={'close-btn'}
      style={mergedStyle}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}>
      <MdClose />
    </div>
  );
}

CloseBtn.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  style: PropTypes.object,
};
