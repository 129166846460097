import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CloseBtn } from '@meronex/components';

import { logMng } from '../api';

export default function RemoveCommentDialog(props) {
  const { commentId, open, onClose, onRemove } = props;
  const [userCommentRemoving, setUserCommentRemoving] = React.useState(false);

  const close = () => {
    setUserCommentRemoving(false);
    onClose();
  };

  const onClick = async () => {
    setUserCommentRemoving(true);

    await logMng.removeComment(commentId);
    if (typeof onRemove === 'function') {
      onRemove();
    }
    toast.success('Comment removed');

    close();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        close();
      }}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <CloseBtn
          onClick={() => {
            close();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div style={{ fontSize: '16px', marginTop: '15px' }}>
          Are you sure you want to remove the comment?
        </div>
        <div
          style={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '20px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}>
          <Button
            variant="contained"
            style={{
              color: 'white',
              width: '80px',
              height: '35px',
              fontSize: '12px',
              borderRadius: '20px',
              backgroundColor: '#43c16a',
            }}
            onClick={() => onClick()}
            disabled={userCommentRemoving}>
            {userCommentRemoving ? <CircularProgress size={15} /> : 'Yes'}
          </Button>
          <Button
            variant="contained"
            style={{
              color: 'white',
              width: '80px',
              height: '35px',
              fontSize: '12px',
              borderRadius: '20px',
              backgroundColor: 'rgb(177, 0, 34)',
            }}
            onClick={() => close()}
            disabled={userCommentRemoving}>
            {userCommentRemoving ? <CircularProgress size={15} /> : 'No'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

RemoveCommentDialog.propTypes = {
  commentId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
