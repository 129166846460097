import React from 'react';
import PropTypes from 'prop-types';
import { Icons } from '../common/Icons';

import View from '../views/components/View';
import Fab from '@material-ui/core/Fab';

import Issues from './Issues';

function IssuesView(props) {
  const { activeView } = props;

  return (
    <View
      icon={
        <span>
          <Icons.Issues size={22} />
        </span>
      }
      title={'24 Open Issues'}
      // onTitleDropdownClick={() => {}}
      // filterProps={{
      //   onFilter: (v) => {
      //     setFilterValue(v);
      //   },
      //   enableQrCode: true,
      //   placeholder: 'search store items',
      // }}
      // onRefresh={async (cb) => {
      //   await refresh();
      //   cb();
      // }}
      viewId={'issues'}
      showMenu
      activeView={activeView}>
      <Issues />
    </View>
  );
}
IssuesView.propTypes = {
  activeView: PropTypes.string,
};

IssuesView.defaultProps = {};
export default IssuesView;
