import { App } from '@meronex/app';

const { server } = App;

export const logMng = {};

const getLogs = async (limit, skip, filterOptions) => {
  const logCards = await server.call('getLogsCards', {
    limit,
    skip,
    filterOptions,
  });
  return logCards;
};
// Mark the observation open by the current
// session user.
const markAsOpened = async (_id) => {
  return await server.call('markAsOpened', {
    _id,
  });
};

const fetchOpenedBy = async (_id) => {
  return await server.call('getOpenedBy', {
    _id,
  });
};

const fetchSharedComments = async (subjectId) => {
  return await server.call('getSharedComments', {
    subjectId,
    sortByRecent: true,
  });
};

const addComment = async ({ subjectId, authorId, text }) => {
  return await server.call('saveSharedComment', {
    subjectId,
    authorId,
    text,
  });
};

const updateComment = async (commentId, text) => {
  return await server.call('updateSharedComment', { commentId, text });
};

const removeComment = async (commentId) => {
  return await server.call('removeSharedComment', { commentId });
};

const onAddComment = async (subjectId) => {
  return await server.call('incCommentCount', { _id: subjectId });
};

const onRemoveComment = async (subjectId) => {
  return await server.call('decCommentCount', { _id: subjectId });
};

logMng.getLogs = getLogs;
logMng.markAsOpened = markAsOpened;
logMng.fetchOpenedBy = fetchOpenedBy;

logMng.addComment = addComment;
logMng.updateComment = updateComment;
logMng.removeComment = removeComment;
logMng.onAddComment = onAddComment;
logMng.onRemoveComment = onRemoveComment;
logMng.fetchSharedComments = fetchSharedComments;
