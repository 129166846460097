import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, ActionBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import IWarn from '@meronex/icons/ti/TiWarningOutline';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function FlaggedDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onSubmit } = props;

  const [reason, setReason] = React.useState();

  console.log(reason);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <span
          style={{
            position: 'relative',
            top: '5px',
          }}>
          <IWarn size={22} />{' '}
        </span>
        Flag Work Order
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <TextField
            name={'reason'}
            label="Reason"
            onFocus={function (e) {
              var val = e.target.value;
              e.target.value = '';
              e.target.value = val;
            }}
            autoFocus={true}
            multiline={true}
            variant="outlined"
            value={reason}
            fullWidth
            onChange={(e, v) => {
              setReason(e.currentTarget.value);
            }}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '20px',
          }}>
          <ActionBtn
            disabled={!reason}
            onClick={() => {
              if (onSubmit && reason) {
                onSubmit(reason);
                onClose();
              }
            }}
            label={'Flag Work Order'}
            style={{
              backgroundColor: reason ? 'rgb(211 0 0)' : 'gray',
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

FlaggedDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FlaggedDialog;
