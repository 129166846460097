import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineSharp';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IPeople from '@meronex/icons/md/MdPeopleOutline';

import { storeCategoriesMng } from '../api/storeCategoriesMng';
import PropTypes from 'prop-types';

export default function StoreCategoriesSelect(props) {
  const { onChange, value, label = 'Stores Categories' } = props;

  const [val, setVal] = useState(value);

  // result from the server call

  const [storeCategories, setStoreCategories] = React.useState();

  const fetchCategoriesOption = async () => {
    const result = await storeCategoriesMng.getStoreCategories();
    console.log(result);
    setStoreCategories(result);
  };

  React.useEffect(() => {
    fetchCategoriesOption();
  }, []);

  React.useEffect(() => {
    if (onChange) {
      console.log(val);
      onChange(val);
    }
  }, [val]);
  const getOptions = () => {
    if (!Array.isArray(storeCategories)) {
      return [];
    }
    return storeCategories;
  };

  return (
    <div>
      <Autocomplete
        id="store-category"
        filterSelectedOptions
        options={getOptions()}
        onChange={(e, newValue) => setVal(newValue)}
        getOptionLabel={(option) => option.name}
        renderOption={(v) => {
          return <div>{v.name}</div>;
        }}
        value={val}
        renderTags={() => {}}
        InputLabelProps={{
          shrink: true,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            margin="normal"
            placeholder={!storeCategories ? 'loading' : ''}
            disabled={!storeCategories}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        )}
      />
    </div>
  );
}

StoreCategoriesSelect.propTypes = {
  onChange: PropTypes.func,
};

StoreCategoriesSelect.defaultProps = {
  onChange: (v) => console.log(v),
};
