/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

import SitesAvatar from '../../common/SitesAvatar';
import { equipmentsMng } from '../api/equipmentsMng';

function EquipmentsSelect(props) {
  const { onChange, equipment, factoryId, disabled } = props;
  const [equipments, setEquipments] = React.useState();

  console.log(factoryId);
  const getEquipments = async () => {
    if (!factoryId) {
      setEquipments([]);
    } else {
      const _steps = await equipmentsMng.getFactoryEquipments({ factoryId });
      console.log(_steps);
      setEquipments(_steps);
    }
  };

  React.useEffect(() => {
    getEquipments();
  }, []);

  if (!Array.isArray(equipments)) {
    return <div />;
  }
  return (
    <>
      <Autocomplete
        options={equipments}
        disabled={disabled}
        onChange={(e, v) => {
          console.log(v);
          onChange(v);
        }}
        getOptionLabel={(option) => option.name}
        value={equipment}
        renderInput={(params) => {
          const endAdornment = (
            <>
              {/*<SitesAvatar*/}
              {/*  image={((equipment || {}).image || {}).thumbUrl}*/}
              {/*  name={(equipment || {}).name}*/}
              {/*  size={35}*/}
              {/*/>*/}
              {params.InputProps.endAdornment}
            </>
          );
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment,
              }}
              label="Select Equipment"
              variant={'outlined'}
            />
          );
        }}
        renderOption={(e) => {
          console.log(e);
          return (
            <Grid container>
              <Grid
                item
                xs={3}
                style={{
                  textAlign: 'center',
                }}>
                <SitesAvatar
                  image={e.image && e.image.thumbUrl}
                  size={50}
                  round={10}
                  name={e.name}
                />
              </Grid>
              <Grid item xs={9}>
                <div
                  style={{
                    position: 'relative',
                    top: '20px',
                    fontSize: '16x',
                    verticalAlign: 'middle',
                  }}>
                  {e.name}
                </div>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
}
EquipmentsSelect.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};
EquipmentsSelect.defaultProps = {
  onChange: (v) => console.log(v),
};

export default EquipmentsSelect;
