import React from 'react';
import PropTypes from 'prop-types';
import Scanner from 'react-webcam-qr-scanner';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CloseBtn, NoData, Loading, ActionBtn } from '@meronex/components';
import ICode from '@meronex/icons/fa/FaBarcode';

import SitesAvatar from '../../../../common/SitesAvatar';

function ScanQrCodeDialog(props) {
  const { open, onClose, storeItems, openItem } = props;

  const [data, setData] = React.useState('ink');
  const [selectedItem, setSelectedItem] = React.useState();
  const [loading, setLoading] = React.useState();

  React.useEffect(() => {
    console.log(storeItems);
    if (Array.isArray(storeItems) && data) {
      let found = false;
      console.log(storeItems);
      storeItems.forEach((i) => {
        if (i.type.code === data) {
          console.log(i);
          found = true;
          setSelectedItem(i);
        }
      });
      if (!found) {
        setSelectedItem(false);
      }
    }
  }, [data]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Scan Item QR Code
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            textAlign: 'center',
          }}>
          <Scanner
            className={'item-qr-code-scanner'}
            onDecode={(result) => {
              if (!data || result.data !== data) {
                // setData(result.data);
                setData(result.data);
              }
            }}
            onScannerLoad={() => {
              console.log('loaded');
              setLoading(false);
            }}
            constraints={{
              audio: false,
              video: {
                facingMode: 'environment',
              },
            }}
            captureSize={{ width: 1280, height: 720 }}
          />
        </div>
        <div>
          {loading && <Loading />}
          {!selectedItem && (
            <>
              {data && (
                <NoData
                  type={'warn'}
                  message={`No Item found for data: ${data}`}
                />
              )}
              {!data && <NoData type={'warn'} message={'No Item found'} />}
            </>
          )}
          {selectedItem && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '20px',
              }}>
              <Grid
                container
                style={{
                  border: '1px solid whitesmoke',
                  borderRadius: '10px',
                  padding: '20px',
                }}>
                <Grid item xs={4}>
                  <SitesAvatar
                    name={selectedItem.type.name}
                    image={selectedItem.type.image}
                    size={125}
                    round={10}
                  />
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}>
                    {selectedItem.type.name}
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      color: 'gray',
                      fontSize: '12px',
                    }}>
                    <span
                      style={{
                        position: 'relative',
                        marginRight: '5px',
                        top: '2px',
                      }}>
                      <ICode />
                    </span>
                    {selectedItem.type.code}
                  </div>
                  {selectedItem.location && (
                    <div
                      style={{
                        marginTop: '10px',
                        fontSize: '18px',
                        marginBottom: '-15px',
                      }}>
                      <span
                        style={{
                          marginLeft: '5px',
                        }}>
                        {selectedItem.location.row}.
                        {selectedItem.location.column}.
                        {selectedItem.location.shelf}.
                        {selectedItem.location.level}
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}>
          <ActionBtn
            style={{
              marginTop: '15px',
            }}
            label={'Open'}
            onClick={() => {
              console.log('on click');
              if (openItem) {
                openItem(selectedItem);
              }
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

ScanQrCodeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  openItem: PropTypes.func,
};

export default ScanQrCodeDialog;
