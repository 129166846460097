import React from 'react';
import PropTypes from 'prop-types';

import { App } from '../../base/AppContext';

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Hidden from '@material-ui/core/Hidden';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SpeedDial from '@material-ui/lab/SpeedDial/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SpeedDialIcon';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    position: 'fixed',
    width: '100%',
    bottom: '0px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 8px',
    zIndex: 3,
  },
  btnRoot: {
    transition: 'none',
  },
});

export default function FooterNav(props) {
  const classes = useStyles();

  const { onDrawerOpen, onClick, value } = props;

  if (!App.config.views.showFooter) {
    return null;
  }

  // Drawer options
  const drawer = App.config.views.drawer || {};
  const OpenIcon = drawer.icon;
  const drawerIndex = drawer.index;

  const renderActions = () => {
    const pages = App.config.views.multiPage;
    if (Array.isArray(pages)) {
      const actions = [];
      pages.forEach((page, index) => {
        if (index === drawerIndex) {
          actions.push(
            <div key={'speedDial'}>
              <SpeedDial
                className={classes.btnRoot}
                style={{
                  transition: 'none',
                  position: 'relative',
                  top: '-40px',
                }}
                ariaLabel="SpeedDial openIcon example"
                onClick={() => {
                  onDrawerOpen();
                }}
                icon={<SpeedDialIcon openIcon={OpenIcon} />}
                onClose={() => {}}
                onOpen={() => {}}
                open={false}
              />
            </div>
          );
        }
        actions.push(
          <BottomNavigationAction
            key={index}
            value={index}
            label={page.label}
            icon={page.icon}
          />
        );
      });
      return actions;
    }
  };
  return (
    <Hidden lgUp>
      <BottomNavigation
        className={'footer'}
        value={value}
        onChange={(event, newValue) => {
          onClick(newValue);
        }}
        showLabels
        className={classes.root}>
        {renderActions()}
      </BottomNavigation>
    </Hidden>
  );
}

FooterNav.propTypes = {
  value: PropTypes.number,
  onClick: PropTypes.func,
  onDrawerOpen: PropTypes.func,
};

FooterNav.defaultProps = {
  value: 0,
};
