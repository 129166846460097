import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import IWorkOrder from '@meronex/icons/fa/FaUsersCog';
import IFactory from '@meronex/icons/mdc/MdcFactory';
import { toast } from '@meronex/components';

import WorkOrderStepper from './WorkOrderStepper';
import WorkOrderInfo from './WorkOrderInfo';

import TopBar from '../../../../views/components/TopHeader';
import { workOrdersMng } from '../../../api/WorkOrdersMng';
import Comments from '../../../../common/comments/Comments';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function CreateWorkOrderDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  // draft is passed from all draft factories view
  const {
    open,
    onClose,
    draft,
    product,
    onWorkOrderAdded,
    factory,
    onStart,
  } = props;

  const [workOrder, setWorkOrder] = React.useState(draft || product);
  const [loading, setLoading] = React.useState(false);

  const _onClose = () => {
    onClose();
  };

  if (!product) {
    return null;
  }

  const onCreate = async () => {
    console.log('handle finish');
    setLoading(true);
    try {
      // remove the draft createdAt
      // it will be replaced by a new one at
      // the server
      delete workOrder.createdAt;

      const result = await workOrdersMng.createWorkOrder(workOrder);
      onWorkOrderAdded();
      onClose();
    } catch (e) {
      if (e.error === 'createWorkOrder.storeItemNotFound') {
        toast.error('One or more store items are not found at the store!', {
          autoClose: 5000,
        });
      } else if (e.error === 'updateStoreItemGroup.noProductStore') {
        toast.error(e.reason, {
          autoClose: 5000,
        });
        console.error(e);
      } else {
        toast.error('Ops, something went wrong.');
        console.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const stepperDialog = document.getElementById('stepper-dialog');

  const woSeqNum = workOrder.seqNum ? `#${workOrder.seqNum}` : '';
  return (
    <Dialog
      className={'fullScreenMobile'}
      disableBackdropClick="false"
      open={open}
      onClose={_onClose}>
      <DialogTitle>
        <TopBar
          titleMaxWidth={290}
          title={`Work order ${woSeqNum}`}
          icon={
            <span>
              <IFactory /> <IWorkOrder size={20} />
            </span>
          }
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent
        className={'no-select'}
        id={'stepper-dialog'}
        style={{
          padding: '0px',
        }}>
        <WorkOrderInfo product={product} workOrder={workOrder} />
        <div
          style={{
            height: 'auto',
            overflow: 'hidden',
          }}>
          {open && (
            <WorkOrderStepper
              product={product}
              factory={factory}
              loading={loading}
              draft={draft}
              onStart={onStart}
              onChange={(wo) => {
                setWorkOrder(wo);
              }}
              onNext={() => {
                console.log('on next');
                setTimeout(() => {
                  if (stepperDialog) {
                    stepperDialog.scrollBy({
                      top: 50,
                      behavior: 'smooth',
                    });
                  }
                }, 100);
              }}
              onBack={() => {
                console.log('on back');
                if (stepperDialog) {
                  stepperDialog.scrollBy({
                    top: -50,
                    behavior: 'smooth',
                  });
                }
              }}
              onSummary={() => {
                console.log('onSummary');
                setTimeout(() => {
                  if (stepperDialog) {
                    stepperDialog.scrollBy({
                      top: 500,
                      behavior: 'smooth',
                    });
                  }
                }, 400);
              }}
              onCreate={onCreate}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

CreateWorkOrderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.object,
  onWorkOrderAdded: PropTypes.func,
  factory: PropTypes.object,
  draft: PropTypes.object,
  onStart: PropTypes.func,
};
CreateWorkOrderDialog.defaultProps = {
  onStart: () => console.log('on start'),
};

export default CreateWorkOrderDialog;
