import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@meronex/components';

import { App } from '@meronex/app';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import statsLoading from '../storeItems/analysis/statsLoading.gif';
import SitesAvatar from '../../../common/SitesAvatar';
import Quantity from '../../../common/Quantity';
import AnalyticsDialog from '../storeItems/analysis/AnalyticsDialog';

import { storesMng } from '../../api/storesMng';

const utils = App.utils;

function Stats(props) {
  const { itemsMap } = props;

  const [openAnalyticsDialog, setOpenAnalyticsDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(false);

  console.log(itemsMap);
  console.log(selectedItem);

  if (!itemsMap) {
    return <Loading url={statsLoading} type={'img'} />;
  }

  const stats = [];

  const getTotals = (item) => {
    const totalIn = item.in + item.transferIn;
    const totalOut = item.out + item.transferOut;
    const net = totalIn + totalOut;

    const totals = {
      in: totalIn,
      out: totalOut,
      net,
    };
    return totals;
  };
  const onItemClick = async (item) => {
    console.log(item);
    // call the server to get the actual full doc item,
    // save the state of item and open the item dialog
    const itemDoc = await storesMng.getStoreItem('1234');
    setSelectedItem(itemDoc);
    setOpenAnalyticsDialog();
  };
  const renderItem = (item) => {
    const totals = getTotals(item);
    const type = item.storeItem.type;

    return (
      <div
        onClick={() => {
          onItemClick(item);
        }}
        style={{
          marginBottom: '20px',
        }}>
        <Grid container>
          <Grid
            item
            xs={2}
            style={{
              textAlign: 'center',
            }}>
            <SitesAvatar
              image={type.image}
              name={type.name}
              size={55}
              round={10}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                marginLeft: '10px',
              }}>
              {type.name}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                textAlign: 'right',
                minWidth: '50px',
                marginRight: '20px',
              }}>
              <Quantity
                showPositiveSign
                enableChangeDirAnimation={false}
                unitLabel={type.unit}
                inlineUnit
                quantitySize={16}
                textAlign={'right'}
                unitLabelStyle={{
                  textAlign: 'right',
                }}
                quantity={totals.net}
              />
            </div>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '10px' }} />
      </div>
    );
  };
  for (let [key, value] of itemsMap) {
    stats.push(renderItem(value));
  }
  return (
    <div>
      {stats}

      {selectedItem && (
        <AnalyticsDialog
          item={selectedItem}
          open={openAnalyticsDialog}
          onClose={() => {
            setOpenAnalyticsDialog(true);
          }}
        />
      )}
    </div>
  );
}
Stats.propTypes = {};

Stats.defaultProps = {};
export default Stats;
