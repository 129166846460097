import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import IMenu from '@meronex/icons/mdc/MdcDotsVertical';
import IEdit from '@meronex/icons/bi/BiEdit';
import IArchive from '@meronex/icons/bs/BsArchive';
import IDelete from '@meronex/icons/mdc/MdcDeleteOutline';
import ITime from '@meronex/icons/bi/BiTimeFive';
import IAudit from '@meronex/icons/bi/BiListCheck';
import ISelect from '@meronex/icons/bs/BsCheckCircle';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import SiteAvatar from './SitesAvatar';

import { App } from '@meronex/app';

const useStyles = makeStyles((theme) => ({
  container: {},
  checkStyle: { marginLeft: '10px', color: 'green' },
  itemCount: {
    color: 'gray',
  },
}));

function SitesListItem(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState();

  const {
    item,
    selected,
    loading,
    onSelect,
    onArchive,
    onEdit,
    onDelete,
    onAudit,
    updatedAtLabel,
    height,
    onClose,
    disableDelete,
    disableEdit,
    permissions,
  } = props;

  const toggleMenu = (e) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const getHeight = () => {
    if (!height) {
      return !item.updatedAt ? '100px' : '105px';
    } else {
      return !item.updatedAt ? `${height}px` : `${height + 15}px`;
    }
  };

  const _onSelect = () => {
    if (!selected) {
      onSelect();
    } else {
      onClose();
    }
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        setAnchorEl(null);
      }}>
      <div id={item._id}>
        <div>
          <div
            style={{
              paddingTop: '10px',
              height: getHeight(),
              width: '100%',
              marginRight: '20px',
              paddingBottom: '5px',
            }}>
            <Grid
              container
              style={{
                width: '100%',
              }}>
              <Grid
                item
                xs={4}
                onClick={_onSelect}
                style={{
                  cursor: 'pointer',
                }}>
                <div style={{ position: 'relative', textAlign: 'center' }}>
                  <SiteAvatar name={item.name} size={75} round={5} />

                  {selected && (
                    <div>
                      {!loading && (
                        <div
                          className={'pulse connected'}
                          style={{
                            position: 'relative',
                            top: '-84px',
                            left: '-36px',
                            height: '20px',
                            width: '20px',
                            border: '3px solid white',
                            padding: '5px',
                            borderRadius: '100px',
                            backgroundColor: 'green',
                          }}
                        />
                      )}
                      {loading && (
                        <div
                          style={{
                            display: 'inline-block',
                            position: 'absolute',
                            top: '-8px',
                            left: '-8px',
                          }}>
                          <CircularProgress size={18} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={7}
                onClick={_onSelect}
                style={{
                  cursor: 'pointer',
                }}>
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'left',
                  }}>
                  <div>
                    {item.icon} {item.name}
                  </div>
                </div>

                <div
                  style={{
                    color: 'gray',
                    position: 'relative',
                    top: '5px',
                    marginRight: '10px',
                    fontSize: '12px',
                  }}>
                  {item.count}&nbsp;
                  {item.unit}
                  {item.count > 1 ? 's' : ''}
                </div>
                <div>
                  {item.updatedAt && (
                    <div
                      style={{
                        marginTop: '5px',
                        fontSize: '12px',
                        color: 'gray',
                      }}>
                      <span
                        style={{
                          position: 'relative',
                          top: '3px',
                        }}>
                        <ITime size={14} />
                      </span>{' '}
                      {updatedAtLabel}
                      {App.utils.time.timeAgo(item.updatedAt.date)}
                    </div>
                  )}
                </div>
                <div>
                  {item.additionalDetails && (
                    <div>{item.additionalDetails}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={1}>
                <div
                  className={`${classes.itemCount} edit`}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    position: 'relative',
                    height: '40px',
                  }}>
                  <div
                    style={{
                      position: 'relative',
                      top: '-8px',
                    }}>
                    <IconButton
                      onClick={toggleMenu}
                      style={{
                        display: 'inline-block',
                        position: 'relative',
                        top: '10px',
                      }}
                      aria-label="delete">
                      <IMenu
                        size={20}
                        className={'edit'}
                        color={'gray'}
                        style={{
                          float: 'right',
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>{' '}
        </div>
        {anchorEl && (
          <Fade in={anchorEl}>
            <div
              style={{
                border: '1px solid lightgray',
                borderRadius: '5px',
                marginBottom: '15px',
                marginTop: '10px',
              }}>
              <MenuList>
                {!disableEdit && (
                  <MenuItem
                    key={'index'}
                    onClick={() => {
                      onEdit();
                      setAnchorEl(null);
                    }}>
                    <IEdit size={22} />{' '}
                    <span
                      style={{
                        position: 'relative',
                        left: '10px',
                      }}>
                      &nbsp;Edit
                    </span>
                  </MenuItem>
                )}
                {onAudit && (
                  <div key={'audit'}>
                    <MenuItem
                      disabled={!permissions.auditStore}
                      onClick={() => {
                        onAudit();
                        setAnchorEl(null);
                      }}>
                      <IAudit size={24} />
                      <span
                        style={{
                          position: 'relative',
                        }}>
                        &nbsp; Audit
                      </span>
                    </MenuItem>
                  </div>
                )}
                {onArchive && (
                  <div key={'archive'}>
                    <MenuItem
                      onClick={() => {
                        onArchive();
                        setAnchorEl(null);
                      }}>
                      <IArchive />
                      <span
                        style={{
                          position: 'relative',
                          left: '8px',
                        }}>
                        &nbsp; Archive
                      </span>
                    </MenuItem>
                    <Divider />
                  </div>
                )}
                <MenuItem
                  key={'select'}
                  onClick={() => {
                    _onSelect();
                    setAnchorEl(null);
                  }}>
                  <div>
                    <ISelect size={22} />
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      position: 'relative',
                      top: '-2px',
                      left: '2px',
                    }}>
                    &nbsp; Select
                  </div>
                </MenuItem>
                {!disableDelete && onDelete && (
                  <div key={'divider'}>
                    <Divider />
                    <MenuItem
                      style={{
                        fontSize: 'red',
                      }}
                      onClick={() => {
                        onDelete(item);
                        setAnchorEl(null);
                      }}>
                      <div>
                        <IDelete size={24} color={'red'} />
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                          top: '-2px',
                          left: '2px',
                          color: 'red',
                        }}>
                        &nbsp; Delete
                      </div>
                    </MenuItem>
                  </div>
                )}
              </MenuList>
            </div>
          </Fade>
        )}
        <Divider />
      </div>
    </ClickAwayListener>
  );
}
SitesListItem.propTypes = {
  onSelect: PropTypes.func,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  updatedAtLabel: PropTypes.string,
  height: PropTypes.number,
  additionalDetails: PropTypes.node,
  permissions: PropTypes.object,
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.node,
    count: PropTypes.number,
    unit: PropTypes.string,
    updatedAt: PropTypes.object,
  }),
};

SitesListItem.defaultProps = {
  updatedAtLabel: 'last update:',
  onClose: () => {
    console.log('on close');
  },
};

export default SitesListItem;
