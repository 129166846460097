import React from 'react';
import PropTypes from 'prop-types';
import View from '../../components/View';
import NewsTile from './NewsTile';

function NewsView(props) {
  const { activeView } = props;

  return (
    <View
      viewId={'news'}
      activeView={activeView}
      showMenu
      menuStyle={{
        color: 'white',
      }}
      style={{
        padding: '0px',
        backgroundColor: '#0e0e0e',
        overflow: 'auto',
        height: '100vh',
      }}>
      <NewsTile alwaysExpand />
    </View>
  );
}
NewsView.propTypes = {
  activeView: PropTypes.string,
};

NewsView.defaultProps = {};
export default NewsView;
