import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import IAdd from '@meronex/icons/cg/CgAdd';
import IEdit from '@meronex/icons/bi/BiEdit';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';
import SitesAvatar from '../../../common/SitesAvatar';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ItemTypeActionsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    onAdd,
    onEdit,
    onDelete,
    store,
    enableAddToStore,
    open,
    onClose,
    itemType,
  } = props;

  let gridSize = 12 / (enableAddToStore ? 3 : 2);

  React.useEffect(() => {
    console.log(store);
    console.log(itemType);
  }, [store, itemType]);

  let added = false;
  if (store && Array.isArray(itemType.addedToStores)) {
    added = itemType.addedToStores.includes(store._id);
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <div>
          <div
            style={{
              textAlign: 'center',
              marginBottom: '10px',
              marginTop: '10px',
            }}>
            <SitesAvatar
              name={itemType.name}
              image={itemType.image ? itemType.image.imgUrl : undefined}
              size={90}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            fontSize: '18px',
            fontWeight: '400',
            textAlign: 'center',
          }}>
          {itemType && itemType.name}
        </div>

        <Grid
          container
          style={{
            marginTop: '12px',
            marginBottom: '40px',
            textAlign: 'center',
            borderRadius: '15px',
          }}>
          {enableAddToStore && (
            <Grid
              style={{
                color: !added ? 'green' : 'gray',
                cursor: !added ? 'pointer' : 'none',
              }}
              item
              xs={gridSize}
              onClick={(e) => {
                if (!added) {
                  onAdd(itemType);
                }
              }}>
              <IconButton>
                <IAdd color={!added ? 'green' : 'gray'} />
              </IconButton>
              <div>Add</div>
            </Grid>
          )}
          <Grid
            item
            style={{
              color: 'green',
              cursor: 'pointer',
            }}
            xs={gridSize}
            onClick={() => {
              onEdit(itemType);
            }}>
            <IconButton>
              <IEdit style={{ color: 'rgb(85, 108, 214)' }} />
            </IconButton>
            <div style={{ color: 'rgb(85, 108, 214)' }}>Edit</div>
          </Grid>
          <Grid
            style={{
              color: 'green',
              cursor: 'pointer',
            }}
            item
            xs={gridSize}
            onClick={() => {
              onDelete(itemType);
            }}>
            <IconButton>
              <IDelete style={{ color: 'red' }} />
            </IconButton>
            <div style={{ color: 'red' }}>Delete</div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

ItemTypeActionsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  store: PropTypes.object,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ItemTypeActionsDialog;
