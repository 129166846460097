import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import MdArrowBack from '@meronex/icons/md/MdArrowBack';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import { useHistory } from 'react-router-dom';

import FaBell from '@meronex/icons/fa/FaBell';

import { App } from '../../base/AppContext';

import PulseIndicator from '../../components/core/PulseIndicator';
import NotificationsDialog from '../notifications/NotificationsDialog';
import SettingsMenu from './SettingsMenu';
import { notifMng } from '../notifications/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function HeaderBar(props) {
  const { config } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [themeType, setThemeType] = React.useState(false);
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  ] = React.useState(undefined);
  const [hasUnseenActivities, setHasUnseenActivities] = React.useState(false);

  // TODO Centralize the fetchers because TabsPanel uses it too...
  const fetchNotificationsUnreadCount = async () => {
    console.log('[App/HeaderBar] loading unread notifications count');
    try {
      const unreadNotifs = await notifMng.fetchUnreadNotificationsCount();
      setUnreadNotificationsCount(unreadNotifs);
    } catch (e) {
      console.log(e);
    }
  };

  // const fetchUnseenActivities = async () => {
  //   console.log('[App/HeaderBar] loading unseen activities flag');
  //   try {
  //     const hasUnseenActivities = await notifMng.fetchHasUnseenActivities();
  //     setHasUnseenActivities(hasUnseenActivities);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    if (!App.opts.disableUserAccount) {
      const fetchUserData = () => {
        fetchNotificationsUnreadCount();
        // fetchUnseenActivities();
      };
      App.eventsManager.on('event-user-loaded', HeaderBar, fetchUserData);
    }
  }, []);

  const openNotificationsDialog = () => {
    setOpenNotifications(true);
  };

  const onLogoClick = () => {
    const { eventsManager } = App;
    if (eventsManager.hasListeners('event-open-about')) {
      eventsManager.emit('event-open-about', HeaderBar.name);
    } else {
      window.location.reload(true);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={'app-header'}
        style={{
          background: 'rgb(14, 14, 14)',
          color: theme.appBar.color,
          height: '70px',
          boxShadow: '0 1px 8px rgba(0,0,0,.3)',
        }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <div style={{ fontSize: '30px' }}>
              {props.backUrl && (
                <IconButton
                  onClick={() => {
                    history.push(props.backUrl);
                  }}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu">
                  <MdArrowBack />
                </IconButton>
              )}
              {props.icon && (
                <div
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    top: '8px',
                    position: 'relative',
                  }}>
                  {props.icon}
                </div>
              )}
              <div style={{ fontSize: '20px', display: 'inline-block' }}>
                {props.title}
              </div>
              {!props.title && (
                <span
                  onClick={() => {
                    onLogoClick();
                  }}>
                  {config.title}
                </span>
              )}
            </div>
          </Typography>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              right: '14px',
              top: '15px',
            }}>
            {!App.opts.disablePulseIndicator && <PulseIndicator />}
            {!config.disableNotifications && (
              <div
                style={{
                  marginLeft: '15px',
                  marginRight: '15px',
                  marginTop: '12px',
                }}>
                <Badge
                  badgeContent={
                    unreadNotificationsCount > 0
                      ? unreadNotificationsCount
                      : undefined
                  }
                  variant={'standard'}
                  color="error"
                  onClick={() => {
                    openNotificationsDialog();
                  }}>
                  <FaBell size={24} />
                </Badge>
              </div>
            )}
            <SettingsMenu
              open={openSettings}
              onClose={() => setOpenSettings(false)}
            />
          </div>
        </Toolbar>
      </AppBar>
      <NotificationsDialog
        open={openNotifications}
        onClose={() => {
          setOpenNotifications(false);
        }}
      />
    </div>
  );
}

HeaderBar.propTypes = {
  title: PropTypes.object,
  backUrl: PropTypes.string,
  config: PropTypes.object,
};
