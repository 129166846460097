import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WeatherIcon from 'react-icons-weather';

const callApi = async (url) => {
  const head = {};

  const response = await window.fetch(url, {
    method: 'GET',
    headers: head,
  });
  const respbody = await response.json();

  if (response.status !== 200) throw Error(respbody.message);

  return respbody;
};

// From https://github.com/lopogo59/simple-react-weather/blob/master/src/index.js
export default class Weather extends Component {
  state = {
    temp: undefined,
    city: undefined,
    icon: undefined,
  };
  static propTypes = {
    appid: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    city: PropTypes.string,
  };
  static defaultProps = {};

  componentDidMount() {
    this.callOWM();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.city !== prevProps.city ||
      this.props.lat !== prevProps.lat ||
      this.props.lon !== prevProps.lon
    ) {
      this.callOWM();
    }
  }

  getTemp = (k) => {
    return {
      value: k,
      k: Math.ceil(k),
      c: Math.ceil(k - 273.15),
      f: Math.ceil(((k - 273.15) * 9) / 5 + 32),
    };
  };
  callOWM = () => {
    const { lat, lon, appid, city } = this.props;

    if (lat && lon) {
      callApi(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${appid}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'GET',
        }
      )
        .then((res) => {
          this.setState({
            temp: this.getTemp(res.main.temp),
            city: res.name,
            icon: res.weather[0].id,
          });
        })
        .catch((res) => {
          console.log(res);
        });
    } else if (this.props.city) {
      callApi(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${appid}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'GET',
        }
      )
        .then((res) => {
          this.setState({
            temp: this.getTemp(res.main.temp),
            city: res.name,
            id: res.weather[0].id,
            icon: res.weather[0].icon,
            description: res.weather[0].description,
          });
        })
        .catch((res) => {
          console.log(res);
        });
    }
  };

  render() {
    const { icon, temp, description, city } = this.state;
    if (!icon || !temp) {
      return null;
    }
    return (
      <div
        style={{
          display: 'inline-block',
          height: '0px',
        }}>
        <div
          style={{
            display: 'inline-block',
            marginRight: '3px',
            marginLeft: '3px',
          }}>
          {city}
        </div>{' '}
        |
        <img
          style={{
            width: '40px',
            marginLeft: '-4px',
            top: '14px',
            height: '40px',
            position: 'relative',
          }}
          src={`https://openweathermap.org/img/wn/${icon}@2x.png`}
        />
        <div style={{ display: 'inline-block' }}>
          {temp.c}
          °C
        </div>{' '}
        <div
          style={{
            textTransform: 'capitalize',
            display: 'inline-block',
          }}>
          {description}
        </div>
      </div>
    );
  }
}
