import React from 'react';
import PropTypes from 'prop-types';

import { NoData, Loading } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { toast } from 'react-toastify';

import Product from '../products/Product';
import { Icons } from '../../../common/Icons';

import { productsMng } from '../../api/ProductsMng';
import TopHeader from '../../../views/components/TopHeader';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddStoreProductDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, store, storeItems, onProductAdd } = props;

  const [products, setProducts] = React.useState();

  const [filterValue, setFilterValue] = React.useState('');
  React.useEffect(() => {
    if (!products) {
      fetchAllProducts();
    }
  }, []);
  // const computeAddedField = (products) => {
  //   console.log('compute added field');
  //   console.log(products);
  //   console.log(storeItems);
  //   if (Array.isArray(products) && Array.isArray(storeItems)) {
  //     const storeItemTypeIds = storeItems.map((i) => i.type._id);
  //     console.log(storeItemTypeIds);
  //     products.forEach((p) => {
  //       if (!p.added && storeItemTypeIds.includes(p._id)) {
  //         p.added = true;
  //       }
  //     });
  //   }
  // };

  const fetchAllProducts = async () => {
    const allProducts = await productsMng.getProductsWithFactories();
    setProducts(allProducts);
  };

  const onProductClick = async (p, cb) => {
    try {
      await productsMng.addProductToStore({
        productId: p._id,
        storeId: store._id,
      });
      onProductAdd();
    } catch (e) {
      console.error(e);
      if (e.error === 'addProductToStore.alreadyAdded') {
        toast.error('Product already added to the store', {
          autoClose: 3000,
        });
      } else {
        toast.error('ops, something went wrong', {
          autoClose: 2000,
        });
      }
    } finally {
      if (cb) {
        cb();
      }
    }
  };

  const filterProducts = () => {
    let _filteredProducts = products;

    if (!filterValue) {
      return _filteredProducts;
    }

    _filteredProducts = products.filter((p) => {
      let _name = p.name.toLowerCase();
      const nameIncluded = _name.includes(filterValue.toLowerCase());
      const codeIncluded = p.code.includes(filterValue.toLowerCase());

      return nameIncluded || codeIncluded;
    });
    return _filteredProducts;
  };

  const renderProducts = () => {
    if (!Array.isArray(products)) {
      return <Loading />;
    }

    const filteredProducts = filterProducts(products);

    if (filteredProducts.length === 0) {
      return (
        <NoData
          type={'warn'}
          message={'No products available, create products at the factories.'}
        />
      );
    } else {
      return filteredProducts.map((p) => {
        return (
          <div key={p._id}>
            <Product product={p} showFactory onAddProduct={onProductClick} />
          </div>
        );
      });
    }
  };

  const count = Array.isArray(products) ? products.length : 0;

  console.log(products);
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      scroll={'body'}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopHeader
          filterProps={{
            onFilter: (v) => {
              setFilterValue(v);
            },
          }}
          icon={<Icons.Products />}
          title={<span>All Products</span>}
          itemsUnit={'product'}
          onClose={onClose}
          itemsCount={count}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            maxHeight: '84vh',
          }}>
          {renderProducts()}
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddStoreProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  store: PropTypes.object,
  storeItems: PropTypes.array,
  onProductAdd: PropTypes.func,
};

export default AddStoreProductDialog;
