import React from 'react';
import { App, AppContext } from '@meronex/app';

import MngMenu from './menu/MngMenu';
import View from '../views/components/View';
import { Icons } from '../common/Icons';

export default function Home(props) {
  const { App } = React.useContext(AppContext);

  const { activeView } = props;

  if (activeView !== 'home') {
    return null;
  }
  return (
    <View
      viewId={'home'}
      activeView={activeView}
      showMenu
      title={'Home'}
      icon={<Icons.Home size={25} />}>
      <div className={'home-view'}>
        <div className={'home-tiles'}>
          <MngMenu />
        </div>

        <div>
          <div
            style={{
              color: '#afafaf',
              textAlign: 'center',
              marginTop: '20px',
            }}>
            v{App.metadata.version}-{App.metadata.build}
          </div>
        </div>
      </div>
    </View>
  );
}
