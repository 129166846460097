import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';

import Comment from './Comment';

export default function CommentsList(props) {
  const { comments = undefined, onEditComment, onRemoveComment } = props;

  if (!comments) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={20}
          style={{ color: 'grey' }}
          thickness={4}
        />
      </div>
    );
  }

  const commentsContent = comments.map((comment) => (
    <Comment
      comment={comment}
      onEdit={() => onEditComment()}
      onRemove={() => onRemoveComment()}
    />
  ));

  return <List>{commentsContent}</List>;
}

CommentsList.propTypes = {
  comments: PropTypes.shape({
    _id: PropTypes.string,
  }),
  onEditComment: PropTypes.func,
  onRemoveComment: PropTypes.func,
};
