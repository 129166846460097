import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import IConfig from '@meronex/icons/ri/RiListSettingsLine';
import IChart from '@meronex/icons/hi/HiOutlineChartPie';
import StoreItemDetails from '../StoreItemDetails';

import Transactions from './Transactions';
import AuditDrawer from './AuditDrawer';
import storesMng from '../../../api/storesMng';
import TopBar from '../../../../views/components/TopHeader';

import { Icons } from '../../../../common/Icons';
import BatchesDialog from './BatchesDialog';
import AnalyticsDialog from '../analysis/AnalyticsDialog';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AuditItemDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    open,
    onClose,
    item,
    itemProducts,
    onReverseTransaction,
    onModifyStoreItem,
    onArchiveStoreItem,
    onAdjustUnitRate,
    permissions,
  } = props;

  const transactionRef = React.useRef();
  const [isScrolling, setIsScrolling] = React.useState(false);

  const [openBatchesDialog, setOpenBatchesDialog] = React.useState(false);
  const [openAnalytics, setOpenAnalytics] = React.useState(false);

  const _onClose = () => {
    setIsScrolling(false);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={_onClose}
      className={'fullScreenMobile'}
      scroll={'body'}>
      <DialogTitle>
        <TopBar
          title={`Manage Item`}
          icon={
            <span>
              <Icons.Items />
              <span
                style={{
                  position: 'relative',
                  top: '4px',
                }}>
                <IConfig size={22} />
              </span>
            </span>
          }
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
        }}>
        <div
          style={{
            padding: '10px',
          }}>
          <StoreItemDetails
            item={item}
            products={itemProducts}
            permissions={permissions}
            showDetails
            showBatches
            showFactory
            showUpdated
            onGroupsCountClick={() => {
              setOpenBatchesDialog(true);
            }}
          />
        </div>
        <div>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '15px',
            }}>
            <ActionBtn
              style={{
                width: 300,
              }}
              label={
                <span>
                  <IChart /> Analyze Transactions
                </span>
              }
              onClick={() => {
                setOpenAnalytics(true);
                console.log('analyze transactions');
              }}
            />
          </div>

          <Transactions
            item={item}
            onReverseTransaction={onReverseTransaction}
            fetchTransactions={async ({ limit, skip }) => {
              const transactions = await storesMng.getItemTransactionsCursor(
                item._id,
                limit,
                skip
              );
              return transactions;
            }}
            ref={transactionRef}
            permissions={permissions}
            isScrolling={(v) => {
              setIsScrolling(v);
            }}
            height={window.innerHeight - 285}
            hasExpandedItem={(v) => console.log(v)}
          />
          <AuditDrawer
            isScrolling={isScrolling}
            item={item}
            permissions={permissions}
            products={itemProducts}
            onArchiveStoreItem={() => {
              onArchiveStoreItem();
              _onClose();
            }}
            onAdjustUnitRate={onAdjustUnitRate}
            onModifyStoreItem={() => {
              transactionRef.current.refresh();
              onModifyStoreItem();
            }}
          />
        </div>
      </DialogContent>
      {openBatchesDialog && (
        <BatchesDialog
          open={openBatchesDialog}
          item={item}
          onClose={() => {
            setOpenBatchesDialog(false);
          }}
        />
      )}
      {openAnalytics && (
        <AnalyticsDialog
          open={openAnalytics}
          onClose={() => {
            setOpenAnalytics(false);
          }}
          item={item}
        />
      )}
    </Dialog>
  );
}

AuditItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  item: PropTypes.object,
  itemProducts: PropTypes.array,
  onReverseTransaction: PropTypes.func,
  onArchiveStoreItem: PropTypes.func,
  onModifyStoreItem: PropTypes.func,
  onAdjustUnitRate: PropTypes.func,
};
AuditItemDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onDeleteStoreItem: () => {},
  onArchiveStoreItem: () => {},
  onAdjustUnitRate: () => {},
};

export default AuditItemDialog;
