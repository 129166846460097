import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';

import { useHistory } from 'react-router-dom';

export default function TextTile(props) {
  const history = useHistory();

  const tileContainer = {
    width: '160px',
    height: '120px',
    backgroundColor: props.bgColor,
    borderRadius: '10px',
    padding: '20px',
  };

  return (
    <Paper
      style={tileContainer}
      onClick={() => {
        if (props.onClick && typeof props.onClick === 'function') {
          props.onClick(props.id);
        } else history.push(props.url);
      }}>
      <div />
      <div
        className={'vertical-center-div'}
        style={{
          fontSize: '16px',
          fontWeight: '400',
          textAlign: 'center',
          whiteSpace: 'initial',
          cursor: 'pointer',
        }}>
        {props.icon && (
          <div
            style={{
              fontSize: `${props.iconSize}px`,
              marginBottom: '10px',
              color: `${props.color}`,
            }}>
            {props.icon}
          </div>
        )}
        <div style={{ fontWeight: '500', color: `${props.color}` }}>
          {props.children}
        </div>
      </div>
    </Paper>
  );
}

TextTile.propTypes = {
  id: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
};

TextTile.defaultProps = {
  bgColor: '#9a905c21',
  iconSize: 50,
  color: 'black',
};
