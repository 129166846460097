import React from 'react';
import PropTypes from 'prop-types';

import { Paper, withStyles, Grid, TextField, Button } from '@material-ui/core';
import { AlternateEmail, VpnKeyRounded } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { App } from '../../base/AppContext';
import { PulseIndicator } from '../../components/index';

const styles = (theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },
});

const { server } = App;

const CssTextField = withStyles({
  root: (props) => ({
    '& input': {
      '&:-webkit-autofill': {
        transition:
          'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition:
          'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition:
          'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },

    '& .label.Mui-focused': {
      color: props.color,
    },
    '& .MuiInputLabel-root': {
      color: props.color,
    },
    '$.MuiFormLabel-root.Mui-focused': {
      color: props.color,
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: props.color,
    },
    '& .MuiInput-input': {
      color: props.color,
    },
    '& .MuiOutlinedInput-root': {
      color: props.color,

      '& fieldset': {
        color: props.color,
        borderColor: props.color,
      },
      '&:hover fieldset': {
        borderColor: props.color,
      },
      '&.Mui-focused fieldset': {
        borderColor: props.color,
      },
    },
  }),
})(TextField);

function Login(props) {
  const { classes } = props;

  const [email, setEmail] = React.useState(' ');
  const [password, setPassword] = React.useState(' ');
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const { config } = props;
  const color = config.color;

  React.useEffect(() => {
    // default to english
    if (App.language !== 'en') {
      App.languageManager.changeLanguage('en');
    }
    if (App.isLogged()) {
      console.log('[Login]: user already logged transferring to home');
      history.push('home');
    }
  }, []);
  const onLogin = async () => {
    if (!loading) {
      setLoading(true);
      // you must pass password and at least one of username or email
      try {
        let userAuth = await server.login({
          password,
          user: {
            email,
          },
        });
        const token = server.token;
        const userId = server.userId;

        App.clientSettings.setItem('token', token);
        App.clientSettings.setItem('userId', userId);
        App.clientSettings.setItem('email', email);
        App.eventsManager.emit('event-login', Login.name, {
          token,
          email,
          userId,
          password,
        });
        history.push('home');
      } catch (e) {
        console.log(e);
        if (e.reason) {
          toast.error(e.reason);
        } else {
          toast.error(
            'Unable to connect to the server, please try again later.'
          );
        }
      }
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        height: '100vh',
        paddingRight: '10px',
        paddingLeft: '10px',
        backgroundImage: `url(${config.backgroundImg.src})`,
        backgroundSize: 'cover',
        backgroundPosition:
          config.backgroundImg.backgroundPosition || 'center top',
        textAlign: 'center',
      }}>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          height: '100vh',
        }}>
        <Paper
          style={{
            margin: 'auto',
            top: '50%',
            transform: 'translateY(-50%)',
            position: 'relative',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: config.backgroundColor || 'white',
          }}
          className={classes.padding}>
          <div
            style={{
              textAlign: 'center',
              paddingBottom: '15px',
              fontSize: '35px',
            }}>
            <img src={config.logo.src} style={config.logo.style} />
            {config.title}
          </div>
          <div className={classes.margin}>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item style={{ color: color || '' }}>
                <AlternateEmail />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <CssTextField
                  id="email"
                  label="Email"
                  type="email"
                  color={color}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value);
                  }}
                  fullWidth
                  autoFocus
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item style={{ color: color || '' }}>
                <VpnKeyRounded />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <CssTextField
                  id="password"
                  label="Password"
                  color={color}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.currentTarget.value);
                  }}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              style={{ marginTop: '25px', marginBottom: '35px' }}>
              <Button
                onClick={() => {
                  onLogin();
                }}
                disabled={loading}
                variant="outlined"
                color="primary"
                endIcon={loading ? <CircularProgress size={15} /> : <div />}
                style={{
                  textTransform: 'none',
                  color: color || '',
                }}>
                Login
              </Button>
            </Grid>
            <div style={{ color, fontSize: '12px' }}>
              V{App.metadata.version}-{App.metadata.build}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '5px',
              right: '0px',
            }}>
            <PulseIndicator />
          </div>
        </Paper>
      </div>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
    </div>
  );
}

export default withStyles(styles)(Login);

Login.propTypes = {
  config: PropTypes.object,
};
