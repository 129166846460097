import React from 'react';
import PropTypes from 'prop-types';
import { Form, ImageUploader } from '@meronex/components';
import TextField from '@material-ui/core/TextField';

import IImage from '@meronex/icons/md/MdCameraEnhance';
import ITemp from '@meronex/icons/fa/FaTemperatureLow';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function StepForm(props) {
  const {
    step = {
      type: {},
    },
    onChange,
  } = props;

  console.log(step);
  const previewContainerRef = React.useRef();

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);

  const _onChange = (step) => {
    console.log('on change');
    console.log(step);
    if (step) {
      if (imgUrl && thumbUrl) {
        step.type.image = {
          imgUrl,
          thumbUrl,
        };
      }
      step.uploading = uploading;
    }
    onChange(step);
  };

  React.useEffect(() => {
    console.log('img on change');
    if (imgUrl && thumbUrl) {
      _onChange(step);
    }
  }, [imgUrl, thumbUrl]);

  return (
    <div>
      <Form
        validateOnInit={false}
        blurDelay={500}
        onUpdate={(_data) => {
          console.log('form on change');
          console.log(_data);
          if (_data) {
            const values = _data.values;
            const _updatedStep = Object.assign(step, values);
            _updatedStep.isValid = _data.isValid;
            _updatedStep.type.name = _data.values.name;

            _onChange(_updatedStep);
          }
        }}>
        <TextField
          name={'name'}
          label="Title"
          autoFocus={true}
          defaultValue={step.type.name}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Title is required';
            return true;
          }}
        />
        <TextField
          name={'description'}
          label="Description"
          defaultValue={(step || {}).description}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Description is required';
            return true;
          }}
        />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={step.isTempMeasure}
                onChange={() => {
                  const _step = Object.assign({}, step);
                  _step.isTempMeasure = !_step.isTempMeasure;
                  onChange(_step);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <span>
                <ITemp /> Is temperature measure
              </span>
            }
          />
        </div>
        <div
          style={{
            marginTop: '10px',
          }}>
          <ImageUploader
            btnLabel={
              <span>
                <span
                  style={{
                    position: 'relative',
                    top: '2px',
                    left: '-4px',
                  }}>
                  <IImage size={20} />
                </span>{' '}
                Add Step Image
              </span>
            }
            defaultPreviewUrl={
              step && step.type && step.type.image ? step.type.imgUrl : ''
            }
            previewContainer={previewContainerRef}
            onUploadStart={() => {
              setUploading(true);
            }}
            onUploadSuccess={(imageData) => {
              console.log(imageData);
              setImgUrl(imageData.imgUrl);
              setThumbUrl(imageData.thumbUrl);
              setThumbUrl(imageData.thumbUrl);
              setUploading(false);
            }}
          />
        </div>
      </Form>{' '}
      <div ref={previewContainerRef} style={{ width: '100%' }} />
    </div>
  );
}
StepForm.propTypes = {};

StepForm.defaultProps = {};
export default StepForm;
