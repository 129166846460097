import React from 'react';
import PropTypes from 'prop-types';

import AnimateHeight from 'react-animate-height';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import IEdit from '@meronex/icons/bi/BiEdit';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';

import { rolesMng } from '../api/rolesMng';

export const RoleItem = (props) => {
  const { onClick, onEdit, onDelete, role } = props;
  const [expanded, setExpanded] = React.useState(false);

  const _onClick = () => {
    console.log('onClick');
    setExpanded(!expanded);
    onClick(role);
  };
  const _onEdit = () => {
    onEdit(role);
  };
  const _onDelete = () => {
    onDelete(role);
  };
  return (
    <>
      <ListItem onClick={_onClick}>
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <div>
            <div
              style={{
                fontSize: '16px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}>
              {role.name}
            </div>
          </div>
          <div>
            <AnimateHeight
              duration={200}
              height={expanded ? 90 : 0} // see props documentation below
            >
              <div>
                <Grid
                  container
                  style={{
                    marginTop: '12px',
                    paddingBottom: '14px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}>
                  <Grid
                    item
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    xs={6}
                    onClick={_onEdit}>
                    <IconButton>
                      <IEdit style={{ color: 'rgb(85, 108, 214)' }} />
                    </IconButton>
                    <div style={{ color: 'rgb(85, 108, 214)' }}>Edit</div>
                  </Grid>
                  <Grid
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    item
                    xs={6}
                    onClick={_onDelete}>
                    <IconButton>
                      <IDelete style={{ color: 'red' }} />
                    </IconButton>
                    <div style={{ color: 'red' }}>Delete</div>
                  </Grid>
                </Grid>
              </div>
            </AnimateHeight>
          </div>
        </div>
      </ListItem>
      <Divider />
    </>
  );
};

function RolesList(props) {
  const { roles, onClick, onEdit, onDelete } = props;

  console.log(roles);
  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const _onDelete = (role) => {
    setOpenDeleteAlert(true);
    setSelectedItem(role);
  };

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    try {
      await rolesMng.deleteRole(selectedItem);
      toast.success('Role successfully deleted');
      setDeleting(false);
      onDelete();
    } catch (e) {
      console.log(e);
      toast.error(e.reason, {
        autoClose: 6000,
      });
    } finally {
      setDeleting(false);
    }
  };

  const renderRoles = () => {
    if (!Array.isArray(roles)) {
      return <Loading />;
    } else if (roles.length === 0) {
      return <NoData type={'warn'} />;
    }

    return roles.map((role) => {
      return (
        <RoleItem
          role={role}
          onEdit={onEdit}
          onDelete={_onDelete}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <>
      <List>{renderRoles()}</List>
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
RolesList.propTypes = {
  roles: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

RolesList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default RolesList;
