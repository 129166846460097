import React from 'react';

import { toast } from '@meronex/components';
import Fab from '@material-ui/core/Fab';
import { App } from '@meronex/app';

import EquipmentsList from './components/EquipmentsList';
import AddEquipmentDialog from './components/AddEquipmentDialog';
import EditEquipmentDialog from './components/EditEquipmentDialog';
import EquipmentHomePageDialog from './components/EquipmentHomePageDialog';
import QrCodeScanDialog from '../common/QRCodeScanDialog';
import IQRCode from '@meronex/icons/mdc/MdcQrcodeScan';

import { equipmentsMng } from './api/equipmentsMng';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { Icons } from '../common/Icons';

function Equipments(props) {
  const { factory, filterValue } = props;

  const [openAddEquipment, setOpenAddEquipment] = React.useState();
  const [openEditEquipment, setOpenEditEquipment] = React.useState();
  const [selectedEquipment, setSelectedEquipment] = React.useState();
  const [openDrawer, setOpenDrawer] = React.useState();

  const [
    openEquipmentHomePageDialog,
    setOpenEquipmentHomePageDialog,
  ] = React.useState();

  const [equipments, setEquipments] = React.useState();
  const [openQrCodeScan, setOpenQrCodeScan] = React.useState();
  const [qrCodeData, setQrCodeData] = React.useState();

  const getEquipments = async () => {
    console.log('getEquipments');
    let equipments;
    if (factory) {
      equipments = await equipmentsMng.getFactoryEquipments({
        factoryId: factory._id,
      });
    } else {
      equipments = await equipmentsMng.getAllEquipments({});
    }
    setEquipments(equipments);
  };

  React.useEffect(() => {
    getEquipments();
  }, []);

  React.useEffect(() => {
    if (qrCodeData) {
      onQRCodeScan(qrCodeData);
    }
  }, [qrCodeData]);
  const filterEquipments = () => {
    if (Array.isArray(equipments) && filterValue) {
      return equipments.filter((e) => {
        const _name = e.name.toLowerCase();
        const _uniqid = e.uniqid.toLowerCase();
        const _filterValue = filterValue.toLowerCase();

        if (_name.includes(_filterValue) || _uniqid.includes(_filterValue)) {
          return true;
        }
        return false;
      });
    }
    return equipments;
  };

  const onQRCodeScan = (qrCodeData) => {
    let found = false;
    console.log('on qr code scan:');
    console.log(qrCodeData);
    let uniqid;
    if (typeof qrCodeData === 'string') {
      // check if url and try to get the data
      const isValidUrl = App.utils.isValidUrl(qrCodeData);
      if (isValidUrl) {
        const url = App.utils.getUrlParts(qrCodeData);
        console.log(url);
        if (url.params.id) {
          uniqid = url.params.id;
        }
      }
      if (!uniqid) {
        uniqid = qrCodeData;
      }
      if (Array.isArray(equipments)) {
        let equipment = equipments.find((e) => e.uniqid === uniqid);
        if (equipment) {
          setSelectedEquipment(equipment);
          setOpenEquipmentHomePageDialog(true);
          found = true;
        }
      }
    }
    if (!found) {
      toast.error(`No equipment found for QR data: ${uniqid}`, {
        autoClose: 4000,
      });
    }
  };

  return (
    <div
      style={{
        position: 'relative',
      }}>
      <EquipmentsList
        equipments={filterEquipments(equipments)}
        onEdit={(equipment) => {
          console.log(equipment);

          setSelectedEquipment(equipment);
          setOpenEditEquipment(true);
        }}
        onView={(equipment) => {
          console.log(equipment);
          setOpenEquipmentHomePageDialog(true);
        }}
        onDelete={getEquipments}
        onClick={(equipment) => {
          console.log(equipment);
          setSelectedEquipment(equipment);
        }}
      />

      {openAddEquipment && (
        <AddEquipmentDialog
          open={openAddEquipment}
          onAdd={getEquipments}
          factory={factory}
          onClose={() => {
            setOpenAddEquipment(false);
            setSelectedEquipment(null);
          }}
        />
      )}
      {openEditEquipment && (
        <EditEquipmentDialog
          open={openEditEquipment}
          equipment={selectedEquipment}
          onEdit={getEquipments}
          factory={factory}
          onClose={() => {
            setOpenEditEquipment(false);
            setSelectedEquipment(false);
          }}
        />
      )}
      {openEquipmentHomePageDialog && (
        <EquipmentHomePageDialog
          open={openEquipmentHomePageDialog}
          equipment={selectedEquipment}
          onClose={() => {
            setOpenEquipmentHomePageDialog(false);
            setSelectedEquipment(false);
          }}
        />
      )}
      <div className={'fab'}>
        <Fab
          onClick={() => {
            setOpenDrawer(true);
          }}>
          <Icons.Menu size={25} />
        </Fab>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}>
          <List>
            <ListItem
              button
              key="scanQRCode"
              onClick={() => {
                setOpenQrCodeScan(true);
              }}>
              <ListItemIcon>
                <IQRCode size={25} />
              </ListItemIcon>
              <ListItemText primary={'Scan QR Code'} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="addEquipment"
              onClick={() => {
                setOpenAddEquipment(true);
                setOpenDrawer(false);
              }}>
              <ListItemIcon>
                <Icons.Add size={25} />
              </ListItemIcon>
              <ListItemText primary={'Add Equipment'} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="close"
              onClick={() => {
                setOpenDrawer(false);
              }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '18px',
                }}>
                Close{' '}
              </div>
            </ListItem>
          </List>
        </Drawer>
      </div>
      <QrCodeScanDialog
        open={openQrCodeScan}
        onScan={(data) => {
          setOpenQrCodeScan(false);
          if (!qrCodeData) {
            setQrCodeData(data);
          }
        }}
        onClose={() => {
          setOpenQrCodeScan(false);
        }}
      />
    </div>
  );
}
Equipments.propTypes = {};

Equipments.defaultProps = {};
export default Equipments;
