import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

export default function SitesSelect(props) {
  const { classes, sites, formData, setFormData } = props;

  React.useEffect(() => {
    setSite(getCurrentValue());
  }, []);

  const getCurrentValue = () => {
    let siteId = '';
    const cachedSiteId = App.clientSettings.getItem('siteId');
    if (formData.siteId) {
      siteId = formData.siteId;
    } else if (cachedSiteId) {
      siteId = cachedSiteId;
    } else if (sites.length > 0) {
      siteId = sites[0]._id;
    }
    return siteId;
  };

  const setSite = (siteId, name) => {
    const updatedData = { ...formData };
    updatedData.siteId = siteId;
    updatedData.siteName = name;
    // Store the siteId and re-use it for next entry
    App.clientSettings.setItem('siteId', siteId);
    setFormData(updatedData);
  };
  const handleOnChange = (event) => {
    const { name } = event.currentTarget.dataset;
    const siteId = event.target.value;
    setSite(siteId, name);
  };
  const renderSites = () => {
    if (!sites) {
      return (
        <div
          style={{
            marginTop: '30px',
            textAlign: 'center',
          }}>
          {' '}
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={24}
            thickness={4}
            {...props}
          />
          <div style={{ color: 'gray' }}>Loading...</div>
        </div>
      );
    }
    if (sites.length === 0) {
      return (
        <div
          style={{
            fontSize: '15px',
            color: 'rgb(107, 107, 107)',
          }}>
          No Sites
        </div>
      );
    }
    return sites.map((site) => {
      return (
        <MenuItem key={site._id} value={site._id} data-name={site.name}>
          {site.name}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl
      fullWidth
      className={classes.formControl}
      disabled={sites && sites.length == 0}>
      <InputLabel id="demo-simple-select-label">Site</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={getCurrentValue()}
        fullWidth
        onChange={handleOnChange}>
        {renderSites()}
      </Select>
    </FormControl>
  );
}

SitesSelect.propTypes = {
  classes: PropTypes.object,
  sites: PropTypes.array,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};
