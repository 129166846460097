import React from 'react';
import moment from 'moment';

import { App } from '@meronex/app';

import SatisfactionDetails from '../logDetails/SatisfactionDetails';
const { utils } = App;

const isOpened = (log) => {
  const user = App.getUser();

  let opened = false;
  // If the session is the user, then seen is true
  if (log.authorId === user._id) {
    return true;
  }
  if (Array.isArray(log.openedBy)) {
    log.openedBy.forEach((openStamp) => {
      if (openStamp.userId === user._id) {
        opened = true;
      }
    });
  }
  return opened;
};

const getLastVisitDate = () => {
  const user = App.getUser();

  let lastVisitDate;
  if (
    user &&
    user.settings &&
    user.settings.sites &&
    user.settings.sites.lastLogsVisit
  ) {
    lastVisitDate = user.settings.sites.lastLogsVisit;
  }
  return lastVisitDate || new Date();
};
// Indicate of the log card was
// created before or after last user visit to the feed.
const isSeen = (log) => {
  const user = App.getUser();

  // If the session is the user, then seen is true
  if (log.authorId === user._id) {
    return true;
  }

  const lastVisitDate = getLastVisitDate();
  let lastVisit;
  if (lastVisitDate) {
    lastVisit = moment(lastVisitDate);

    const createdAt = moment(log.createdAt);
    const diff = createdAt.diff(lastVisit, 'seconds');
    // If the creation date is less than the last logged in time
    // (so the log was created before the user's last logged in, then
    // then assume the user has seen it)
    // we're given leeway of 5 seconds in case the user switches
    // views fast.
    if (diff < 5) {
      return true;
    }
  }
  return false;
};

const getDetailsColumn = (log) => {
  const img = getCardImage(log, true);
  return (
    <div style={{ textAlign: 'center' }}>
      {img ? (
        <img
          style={{
            borderRadius: '5px',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: '110px',
            marginTop: '3px',
            float: 'right',
            marginRight: '20%',
          }}
          src={img}
        />
      ) : (
        <div />
      )}
    </div>
  );
};
const getCard = (log) => {
  const card = {};

  let title = '';
  let detailsColumn = getDetailsColumn(log);
  if (log.logType === 'observation') {
    title = log.logDetails.description;
  } else if (log.logType === 'incident') {
    title = log.logDetails.description;
  } else if (log.logType === 'feedback') {
    title = log.logDetails.feedback;
  } else if (log.logType === 'survey') {
    title = log.logDetails.moveOutReason;
    detailsColumn = (
      <SatisfactionDetails
        log={log}
        fontSize={'20px'}
        iconSize={'80px'}
        inline={false}
      />
    );
  }
  card.title = title;
  card.detailsColumn = detailsColumn;
  card.isOpened = isOpened(log);
  card.isSeen = isSeen(log);
  card.isRTL = utils.isRTL(card.title);
  return card;
};

const getCardImage = (log, thumb = false) => {
  const images = log.logDetails.images;
  if (images) {
    if (Array.isArray(images.urls) && images.urls.length > 0) {
      const indexImage = images.urls[0];
      if (typeof indexImage === 'string') {
        return indexImage;
      }
      if (thumb && indexImage.thumbUrl) {
        return indexImage.thumbUrl;
      }
      return indexImage.imageUrl;
    }
  }
  return undefined;
};

export const timeAgo = (timestamp) => {
  return moment(timestamp).fromNow();
};

export const timeFullFormat = (timestamp) => {
  return moment(timestamp).format('L - LTS');
};

export const capitalize = (aString) => {
  return aString.charAt(0).toUpperCase() + aString.slice(1);
};

const logsUtil = {};
logsUtil.getCard = getCard;
logsUtil.getCardImage = getCardImage;
logsUtil.timeAgo = timeAgo;
logsUtil.timeFullFormat = timeFullFormat;
logsUtil.capitalize = capitalize;
logsUtil.isSeen = isSeen;
logsUtil.getLastVisitDate = getLastVisitDate;

export default logsUtil;
