import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import HeaderMenu from './HeaderMenu';

import { App } from '@meronex/app';
import TopHeader from './TopHeader';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function View(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    children,
    viewId,
    activeView,
    showMenu,
    icon,
    style,
    menuStyle,
    title,
    subtitle,
    filterProps,
    itemsCount,
    itemsUnit,
    onTitleDropdownClick,
    onOpen,
    onRefresh,
  } = props;

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (activeView === viewId) {
      setOpen(true);
    } else {
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }
  }, [activeView]);

  const refreshView = (args) => {
    onRefresh(() => {
      console.log('refresh');
      if (args && args.activeView === viewId) {
        toast.success(`App successfully refreshed!`, {
          autoClose: 1200,
        });
      }
    });
  };
  React.useEffect(() => {
    // event-resume-login
    App.eventsManager.on('event-user-loaded', viewId, () => {
      // onRefresh(() => {});
    });
    App.eventEmitter.on('event-item-type-update', () => {
      onRefresh(() => {});
    });
    App.eventsManager.on('event-refresh-views', viewId, refreshView);
    return () => {
      // App.eventsManager.removeListener('event-refresh-views', refreshView);
    };
  }, []);

  React.useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [open]);

  const topHeaderRef = React.useRef();

  if (activeView !== viewId) {
    return null;
  }
  const contentStyle = Object.assign(
    {
      touchAction: 'none',
      overflow: 'hidden',
      position: 'relative',
    },
    style
  );

  return (
    <div className={'view-container'}>
      <div>
        {title && (
          <div
            style={{
              padding: '15px 15px 0 15px',
              position: 'relative',
            }}>
            <TopHeader
              ref={topHeaderRef}
              icon={icon}
              filterProps={filterProps}
              activeView={activeView}
              title={title}
              onTitleDropdownClick={onTitleDropdownClick}
              subtitle={subtitle}
              showMenu={showMenu}
              menuStyle={menuStyle}
              itemsCount={itemsCount}
              itemsUnit={itemsUnit}
              onRefresh={onRefresh}
            />
          </div>
        )}
      </div>
      <div style={contentStyle}>
        {!title && showMenu && (
          <HeaderMenu activeItemId={activeView} style={menuStyle} />
        )}

        {children}
      </div>
    </div>
  );
}

View.propTypes = {
  children: PropTypes.node,
  viewId: PropTypes.string,
  activeView: PropTypes.string,
  showMenu: PropTypes.bool,
  menuStyle: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  itemsCount: PropTypes.number,
  itemsUnit: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
  style: PropTypes.object,
  filterProps: PropTypes.object,
  onOpen: PropTypes.func,
  onTitleDropdownClick: PropTypes.func,
  onRefresh: PropTypes.func,
};

View.defaultProps = {
  onRefresh: () => {},
  onOpen: () => {},
  menuStyle: {
    color: 'black',
  },
};

export default View;
