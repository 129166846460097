import { App } from '@meronex/app';

import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { toast } from 'react-toastify';

import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { v4 as generateRandomID } from 'uuid';
import MobileDetect from 'mobile-detect';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import clone from 'clone';
import rtlCSSJS from 'rtl-css-js';

import DetectScroll from './DetectScroll';
import addToBlobPolyfill from './polyfill';

export const utils = {};

let isMobileObj;

export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
utils.usePrevious = usePrevious;

utils.clone = clone;

utils.object = {};
utils.object.clone = clone;

utils.time = {};
const REFERENCE = moment(); // fixed just for testing, use moment();
const TODAY = REFERENCE.clone().startOf('day');
const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

export function isToday(date) {
  const momentDate = getMomentDate(date);
  return momentDate.isSame(TODAY, 'd');
}

utils.time.isToday = isToday;

export function getMomentDate(date) {
  if (moment.isMoment(date)) {
    return date;
  } else return moment(date);
}
utils.getMomentDate = getMomentDate();

export function isYesterday(date) {
  const momentDate = getMomentDate(date);
  return momentDate.isSame(YESTERDAY, 'd');
}
utils.time.isYesterday = isYesterday;

utils.time.timeAgo = (timestamp) => {
  return moment(timestamp).fromNow();
};

// deprecate
utils.time.timeFullFormat = (timestamp, showHours) => {
  return utils.time.fullFormat(timestamp, showHours);
};

utils.time.fullFormat = (timestamp, time) => {
  if (time) {
    return moment(timestamp).format('DD/MM/YYYY hh:mm:ss A');
  }
  return moment(timestamp).format('DD/MM/YYYY');
};

export function isWithinAWeek(momentDate) {
  return momentDate.isAfter(A_WEEK_OLD);
}
utils.timeisWithinAWeek = isWithinAWeek;
export function isTwoWeeksOrMore(momentDate) {
  return !isWithinAWeek(momentDate);
}
utils.isTwoWeeksOrMore = isTwoWeeksOrMore;
utils.randomId = generateRandomID;
utils.getAuthor = (author, showAvatar = false, style) => {
  if (!author) {
    return '';
  }
  if (typeof author === 'string') {
    return author;
  }
  let profile;

  if (typeof author === 'object' && author.profile) {
    profile = author.profile;
  }
  if (Array.isArray(author) && author.length === 1) {
    const authorDoc = author[0];
    if (authorDoc.profile) {
      profile = authorDoc.profile;
    }
  }
  const mergedStyle = Object.assign(
    {
      marginRight: '10px',
      display: 'inline-block',
      height: '25px',
      width: '25px',
      textAlign: 'center',
    },
    style
  );
  if (profile) {
    if (showAvatar) {
      return (
        <>
          <Avatar
            style={mergedStyle}
            alt={`${author.profile.firstName} ${author.profile.lastName}`}
            src={`${author.profile.avatar ? author.profile.avatar : ''}`}
          />{' '}
          {author.profile.firstName} {author.profile.lastName}
        </>
      );
    }
    return `${profile.firstName} ${profile.lastName}`;
  }
  return '';
};

utils.extractExtension = (filename) => {
  let ext = /(?:\.([^.]+))?$/.exec(filename);
  if (ext != null && ext[0] != null) {
    return ext[0];
  } else {
    return '';
  }
};

/*
 Get the of the file within given folder
 in google storage.
 */
utils.getStorageFileURL = (filename, folder, cb) => {
  const storage = getStorage(App.firebase);

  getDownloadURL(ref(storage, `${folder}/${filename}`)).then((url) => {
    cb(url);
  });
};

/*
 Check when the thumbnail file was generated in
 Google Storage using remote cloud function.
 */
utils.checkThumbnail = (filename, onExist) => {
  const extension = utils.extractExtension(filename);
  const storage = getStorage(App.firebase);
  let fileName;
  if (extension === '.gif' || extension === '.svg') {
    fileName = `${filename.replace(extension, `${extension}_200x200`)}`;
  } else {
    fileName = `${filename.replace(extension, `_200x200${extension}`)}`;
  }

  const fullPath = `thumbs/${fileName}`;
  const imageRef = ref(storage, fullPath);

  if (imageRef) {
    if (onExist) {
      getDownloadURL(imageRef)
        .then((url) => {
          onExist(url);
        })
        .catch((e) => {
          setTimeout(() => {
            utils.checkThumbnail(filename, onExist);
          }, 350);
        });
    }
  }
};

// https://stackoverflow.com/questions/12006095/javascript-how-to-check-if-character-is-rtl
utils.isRTL = (s) => {
  const ltrChars =
    'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
    '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
  const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
  const rtlDirCheck = new RegExp('^[^' + ltrChars + ']*[' + rtlChars + ']');

  return rtlDirCheck.test(s);
};

utils.getUserAvatar = (user) => {
  if (user && user.profile && user.profile.avatar) {
    return user.profile.avatar;
  }
  return '';
};

utils.getUserName = (user) => {
  if (!user || !user.profile) {
    return '';
  }
  const profile = user.profile;
  return `${profile.firstName} ${profile.lastName}`;
};

/**
 *  Capitalize an array of string or string
 * @param value can be an array or string
 * @returns {*}
 */
utils.capitalize = (value) => {
  if (!value) {
    return;
  }
  const makeCapital = (aString) => {
    if (typeof aString !== 'string' || !aString.charAt(0)) {
      return aString;
    }
    return aString.charAt(0).toUpperCase() + aString.slice(1);
  };

  if (Array.isArray(value)) {
    return value.map((aString) => makeCapital(aString));
  }
  return makeCapital(value);
};

/**
 * Remove an element form an array
 * @param array
 * @param item
 * @returns {*}
 */
utils.removeFromArray = (array, item) => {
  if (!Array.isArray(array)) {
    return array;
  }
  const index = array.indexOf(item);
  if (index !== -1) array.splice(index, 1);
  return array;
};

// Image utils helpers
utils.image = {};

utils.image.resizeAndCropImage = (file, w, h) => {
  if (!HTMLCanvasElement.prototype.toBlob) {
    addToBlobPolyfill();
  }
  return new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      // Create image object
      const image = new Image();
      image.onload = (imageEvent) => {
        // Create canvas or use provided canvas
        const canvas = document.createElement('canvas');
        const maxWidth = w || image.width;
        const maxHeight = h || image.height;
        canvas.width = maxWidth;
        canvas.height = maxHeight;
        // Calculate scaling
        const horizontalScale = maxWidth / image.width;
        const verticalScale = maxHeight / image.height;
        const scale = Math.max(horizontalScale, verticalScale);
        // Calculate cropping
        const [width, height] = [scale * image.width, scale * image.height];
        const verticalOffset = Math.min((maxHeight - height) / 2, 0);
        const horizontalOffset = Math.min((maxWidth - width) / 2, 0);
        // Obtain the context for a 2d drawing
        const context = canvas.getContext('2d');
        if (!context) {
          return reject('Could not get the context of the canvas element');
        }
        // Draw the resized and cropped image
        context.drawImage(
          image,
          horizontalOffset,
          verticalOffset,
          width,
          height
        );
        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

utils.image.optimizeImage = (imageFile, maxWidth) => {
  return new Promise((resolve, reject) => {
    const $S = require('scriptjs');

    let backendUrl;

    try {
      backendUrl = App.getServerUrl();
    } catch (err) {
      reject(new Error('Unable to get the server url.'));
    }
    $S(
      [
        `${backendUrl}/js/load-image.all.min.js`,
        `${backendUrl}/js/canvas-to-blob.min.js`,
      ],
      () => {
        // load image comes from  $S(['/js/load-image.all.min.js', '/js/canvas-to-blob.min.js']);
        window.loadImage(
          imageFile,
          (canvas) => {
            if (!imageFile || !imageFile.type) {
              reject("the image file or it's type are not defined");
            }
            if (!canvas || !canvas.toBlob) {
              reject("the image canvas or  it's blob are not defined");
            }
            try {
              canvas.toBlob((blob) => {
                console.log(blob);
                blob.name = `${generateRandomID()}.jpeg`;
                resolve(blob);
              });
            } catch (e) {
              reject(e);
            }
          },
          {
            maxWidth: 1000,
            maxHeight: 1000,
            canvas: true,
            downsamplingRatio: 0.3,
            orientation:
              document.createElement('img').style.imageOrientation ===
              undefined, // temporary disable for issue https://github.com/blueimp/JavaScript-Load-Image/issues/97 ios 13.4
          } // Options
        );
      }
    );
  });
};

const getDataUri = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
      canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

      canvas.getContext('2d').drawImage(this, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };
    image.crossOrigin = 'anonymous';
    image.src = url;
  });
};
utils.image.getDataUri = getDataUri;

utils.isMobile = () => {
  if (isMobileObj) {
    return isMobileObj.mobile();
  }
  isMobileObj = new MobileDetect(window.navigator.userAgent);
  return isMobileObj.mobile();
};
utils.isDesktop = () => {
  return window.matchMedia('(min-width: 992px)').matches;
};
utils.isiPhone = () => {
  let response = false;
  let mobileDetect = new MobileDetect(window.navigator.userAgent);
  if (
    mobileDetect &&
    typeof mobileDetect.os() === 'string' &&
    mobileDetect.os().toLowerCase() === 'ios'
  ) {
    response = true;
  }
  return response;
};
utils.isAndroid = () => {
  let response = false;
  let mobileDetect = new MobileDetect(window.navigator.userAgent);

  if (
    mobileDetect &&
    typeof mobileDetect.os() === 'string' &&
    mobileDetect.os().toLowerCase() === 'ios'
  ) {
    response = true;
  }
  return response;
};

// extend window local storage to automatically handle
// json object
utils.localStorage = {
  setItem: (key, item) => {
    let _item = item;
    if (typeof item === 'object') {
      _item = JSON.stringify(item);
    }
    return window.localStorage.setItem(key, _item);
  },
  removeItem: (key) => {
    return window.localStorage.removeItem(key);
  },
  getItem: (key) => {
    let _item = window.localStorage.getItem(key);
    try {
      _item = JSON.parse(_item);
    } catch (e) {
      return _item;
    }
    return _item;
  },
};

utils.isValidUrl = (str) => {
  const pattern = new RegExp(
    "^(ht|f)tp(s?)\\:\\/\\/(www\\.)?(?:.[a-z]+)*(:(0-9)*)*(\\/?)([a-zA-Z0-9\\-\\.\\?\\,\\'\\=\\/\\\\\\+&amp;%\\$#_]*)?$",
    'gi'
  ); // fragment locator
  return !!pattern.test(str);
};
utils.getUrlParts = (url = window.location) => {
  const a = document.createElement('a');
  a.href = url;

  let fullHost;
  if (typeof url.href === 'string') {
    const arr = url.href.split('/');
    fullHost = arr[0] + '//' + arr[2];
  }

  return {
    href: a.href,
    host: a.host,
    fullHost,
    hostname: a.hostname,
    port: a.port,
    pathname: a.pathname,
    protocol: a.protocol,
    hash: a.hash,
    search: a.search,
    params: Object.fromEntries(new URLSearchParams(a.search)),
  };
};

utils.getObjectName = (entity) => {
  let id = 'unknown';
  try {
    if (typeof entity === 'string') {
      id = entity;
    } else if (typeof entity === 'object') {
      if (entity.name) {
        id = entity.name;
      } else if (entity.constructor && entity.constructor.name) {
        id = entity.constructor.name;
      }
    } else if (typeof entity === 'function') {
      id = entity.name;
    }
  } catch (e) {
    console.log(e);
  }
  return id;
};
utils.number = {};
// min and max included
utils.number.randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

utils.number.countDigits = (num) => {
  return Math.max(Math.floor(Math.log10(Math.abs(num))), 0) + 1;
};

/**
 * @param string string input
 * @return {string|number} a floating point number or '' if
 * not a number
 */
utils.number.parse = (string) => {
  let _num = Number.parseFloat(string);
  return _num;
};
utils.number.countDecimals = (num) => {
  let count;
  let _num = Number(num);
  if (Number.isNaN(_num)) {
    count = 0;
  } else if (Math.floor(_num) === _num) {
    count = 0;
  } else {
    const partitionedNum = _num.toString().split('.');
    if (partitionedNum.length > 0) {
      count = partitionedNum[1].length;
    }
  }
  return count;
};

utils.number.thousandsSeparator = (x) => {
  if (!x) {
    return x;
  }
  return x.toLocaleString(undefined, {
    maximumFractionDigits: 4,
  });
};

utils.number.round = (value, decimals = 2) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

utils.string = {};

utils.string.truncate = (str, num) => {
  if (typeof str !== 'string') {
    return str;
  }
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

utils.string.copyToClipboard = (string, msg) => {
  let _msg = msg;
  if (!_msg) {
    _msg = 'Text copied successfully to clipboard!';
  }
  try {
    navigator.clipboard.writeText(string);
  } catch (e) {
    toast.error('Failed to copy the link to clipboard.');
  }
  console.log(`${string} copied!`);
  toast.success(_msg);
};
/**
 * Check if the object has no properties, i.e. {}
 * @param obj
 * @return {boolean|undefined}
 */
const isObjEmpty = (obj) => {
  if (typeof obj !== 'object') {
    return undefined;
  }
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
utils.isObjEmpty = isObjEmpty;

/**
 * A function to take a string written in dot notation style, and use it to
 * find a nested object property inside of an object.
 *
 * Useful in a plugin or module that accepts a JSON array of objects, but
 * you want to let the user specify where to find various bits of data
 * inside of each custom object instead of forcing a standardized
 * property list.
 *
 * @param String nested A dot notation style parameter reference (ie "urls.small")
 * @param Object object (optional) The object to search
 *
 * @return the value of the property in question
 */
utils.getObjProperty = (object, propertyName) => {
  if (isObjEmpty(object)) {
    return undefined;
  }
  const parts = propertyName.split('.');
  let property = object;

  parts.forEach((part) => {
    property = property[part] || {};
  });

  return property;
};

utils.round = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

/**
 * Creats a PDF document for a URL or an HTML content
 * @param urlOrHtml a string for the url or the html
 * @param filename the file name of the generated pdf
 * @return {Promise<void>}
 */
const htmlToPdf = async (urlOrHtml, filename, header, footer, cb) => {
  const params = {
    apiKey: 'efe85d69b7b4c39f78256362e79583613f6072925e410c7ff2df08d84a0fcaea',
    format: 'A4',
    html: urlOrHtml,
  };

  if (header) {
    params.marginTop = 120;
    params.marginBottom = 40;
    params.headerTemplate = header;
    params.footerTemplate = ' ';
  }

  const data = await fetch('https://api.html2pdf.app/v1/generate', {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(params), // body data type must match "Content-Type" header
  });
  const blob = await new Response(data.body).blob();
  const newBlob = new Blob([blob], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(newBlob);
  if (cb) {
    cb({ name: filename, url: fileURL, blob: newBlob });
  }
};

/**
 * Create a pdf from React component
 * @param component a component such as <Component/>
 * @param pdfFileName the file name of the generated pdf
 * @return {Promise<void>}
 */
const reactToPDF = async ({ body, header, filename, cb }) => {
  const htmlString = renderToStaticMarkup(body);
  let headerString;
  if (header) {
    headerString = renderToStaticMarkup(header);
  }
  htmlToPdf(htmlString, filename, headerString, null, cb);
};

utils.rtl = (style) => {
  if (App && App.languageManager.getDirection() !== 'rtl') {
    return style;
  }
  return rtlCSSJS(style);
};
utils.pdf = {
  htmlToPdf,
  reactToPDF,
};

/**
 *  Attach scroll detection logic to the element
 *
 * @param cb call back function with true/false depending on the scrolling
 * @param e dom element and window
 * @return {DetectScroll}
 *
 * Returns true when scrolling start, false otherwise. Use destroy to clear up
 * listener once the element is about to be unmounted.
 *
 *  Example usage:
 *
 *  React.useEffect(() => {
      const detectScroll = App.utils.isScrolling((v) =>
      console.log(`state : ${v}`)
     );

    return () => detectScroll.destroy();
  }, []);

 */
utils.isScrolling = (cb, delay = 800, e = window) => {
  return new DetectScroll(cb, delay, e);
};
utils.debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

// https://stackoverflow.com/questions/23187013/is-there-a-better-way-to-sanitize-input-with-javascript
utils.sanitize = (str) => {
  str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, '');
  return str.trim();
};

utils.list = {};
