import React from 'react';
import PropTypes from 'prop-types';

import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import { App } from '@meronex/app';
import Avatar from 'react-avatar';
import Truncate from 'react-truncate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: '10px',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  iconStyle: {
    position: 'relative',
    top: '5px',
  },
}));

function WorkOrderStepLabel(props) {
  const classes = useStyles(props);
  const {
    onClick,
    children,
    active,
    error,
    stepIcon,
    text,
    textIcon,
    imgSrc,
  } = props;

  const getLabelColor = () => {
    if (error) {
      return 'red';
    } else if (active) {
      return 'rgb(85, 108, 214)';
    }
    return 'inherit';
  };

  const stepLabelProps = {
    children: props.children,
    completed: props.completed,
    classes: props.classes,
    active: props.active,
    icon: props.icon,
    error: props.error,
  };

  return (
    <StepLabel
      {...stepLabelProps}
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}>
      <span
        style={{
          color: getLabelColor(),
        }}>
        {/*{imgSrc && (*/}
        {/*  <img*/}
        {/*    src={imgSrc}*/}
        {/*    style={{*/}
        {/*      width: '25px',*/}
        {/*      objectFit: 'contain',*/}
        {/*      position: 'relative',*/}
        {/*      top: '5px',*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{!stepIcon && !imgSrc && (*/}
        {/*  <span>*/}
        {/*    <Avatar name={App.utils.sanitize(text)} size={25} round={'5px'} />*/}
        {/*  </span>*/}
        {/*)}*/}
        {stepIcon && <span className={classes.iconStyle}> {stepIcon}</span>}
        <span
          style={{
            marginLeft: '10px',
            fontSize: '16px',
            fontWeight: '400',
          }}>
          {textIcon}{' '}
          <Truncate width={280} lines={1} ellipsis={<span>..</span>}>
            {text}
          </Truncate>
        </span>
      </span>
      <div>{children}</div>
    </StepLabel>
  );
}
WorkOrderStepLabel.propTypes = {
  stepIcon: PropTypes.object,
  text: PropTypes.string,
  textIcon: PropTypes.node,
  active: PropTypes.bool,
  error: PropTypes.bool,
  imgSrc: PropTypes.string,
  children: PropTypes.node,
  completed: PropTypes.bool,
};

WorkOrderStepLabel.defaultProps = {};
export default WorkOrderStepLabel;
