import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { App } from '@meronex/app';

import CommentBox from './components/CommentBox';
import CommentsList from './components/CommentsList';
import { commentsMng } from './api';

function Comments(props) {
  const { subjectId } = props;

  const user = App.getUser();
  const [comments, setComments] = React.useState();
  const theme = useTheme();

  async function fetchSharedComments() {
    if (subjectId) {
      const comments = await commentsMng.fetchSharedComments(subjectId);
      setComments(comments);
    }
  }

  useEffect(() => {
    fetchSharedComments();
  }, [subjectId]);

  if (!subjectId || !user) {
    return null;
  }
  const onAddComment = async () => {
    await commentsMng.onAddComment(subjectId);
    await fetchSharedComments();
  };

  const onRemoveComment = async () => {
    await commentsMng.onRemoveComment(subjectId);
    await fetchSharedComments();
  };

  return (
    <div style={{ marginBottom: '65px' }}>
      <CommentBox
        authorId={(user || {})._id}
        subjectId={subjectId}
        onAddComment={() => onAddComment()}
      />
      <div style={{ marginTop: '-15px' }}>
        <CommentsList
          comments={comments}
          onEditComment={() => fetchSharedComments()}
          onRemoveComment={() => onRemoveComment()}
        />
      </div>
    </div>
  );
}
Comments.propTypes = {
  subjectId: PropTypes.string,
};

Comments.defaultProps = {};
export default Comments;
