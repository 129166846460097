import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, ActionBtn, Form, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import ILocation from '@meronex/icons/en/EnLocation';
import LocationForm from './LocationForm';

import { storesMng } from '../../../api/storesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function LocationDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onUpdate, storeItemId, item } = props;

  const [location, setLocation] = React.useState(
    item ? item.location || {} : null
  );

  const [updating, setUpdating] = React.useState();

  const updateLocation = async () => {
    try {
      setUpdating(true);
      const result = await storesMng.storeItem.actions.modifyStoreItemLocation({
        storeItemId: item._id,
        location,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.log(e);
    } finally {
      toast.success('Item location updated');
      if (onUpdate) {
        onUpdate();
      }
      setUpdating(false);
      onClose();
    }
  };

  if (!location) {
    return null;
  }

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <ILocation
          size={25}
          style={{
            color: 'black',
          }}
        />{' '}
        Set Location
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <LocationForm
            location={location}
            onChange={(v) => {
              setLocation(v);
            }}
          />
        </div>
        <div
          style={{
            width: '100%',
            marginTop: '10px',
            marginBottom: '10px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={updating}
            onClick={() => {
              console.log('save location');
              console.log(location);
              updateLocation();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

LocationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  location: PropTypes.object,
};

export default LocationDialog;
