import React from 'react';
import PropTypes from 'prop-types';

import { AlertDialog, toast, EndlessScroll, NoData } from '@meronex/components';

import List from '@material-ui/core/List';

import IAddFAB from '@meronex/icons/ai/AiOutlinePlus';
import IAdd from '@meronex/icons/cg/CgAdd';

import MemberItem from './MemberItem';
import { membersMng } from '../api/membersMng';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import AddMemberDialog from './AddMemberDialog';
import EditMemberDialog from './EditMemberDialog';

function MembersList(props) {
  const { filters } = props;

  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [openAddMemberDialog, setOpenAddMemberDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [selectedItemIndex, setSelectedItemIndex] = React.useState();
  const [openDrawer, setOpenDrawer] = React.useState();
  const [openEditMember, setOpenEditMember] = React.useState();

  const listRef = React.useRef(null);

  React.useEffect(() => {
    listRef.current.refreshList();
  }, [filters]);

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    try {
      await membersMng.deleteMember(selectedItem);
      toast.success('Member successfully deleted');
      setDeleting(false);
      listRef.current.refreshList();
    } catch (e) {
      toast.error(e.reason);
      setDeleting(false);
    }
  };

  const loadMembers = async ({ limit = 9, skip = 0 }) => {
    console.log(`loadMembers: skip: ${skip}, limit: ${limit}`);
    console.log(filters);
    return await membersMng.getMembersCursor({ skip, limit, filters });
  };

  const _onAdd = async () => {
    listRef.current.refreshList();
  };

  const _onEdit = async () => {
    listRef.current.refreshItems();
  };

  const renderMember = (member, isScrolling, index) => {
    return (
      <MemberItem
        member={member}
        onEditClick={() => {
          setSelectedItem(member);
          setSelectedItemIndex(index);
          setOpenEditMember(true);
        }}
        onDeleteClick={() => {
          setOpenDeleteAlert(true);
          setSelectedItem(member);
        }}
        onClick={() => {
          setSelectedItem(member);
          setSelectedItemIndex(index);
        }}
      />
    );
  };
  return (
    <>
      <EndlessScroll
        ref={listRef}
        getListItems={loadMembers}
        remoteRowCount={999999}
        noRowRenderer={() => {
          return (
            <NoData
              message={'Add new members'}
              onClick={() => {
                setOpenAddMemberDialog(true);
              }}
            />
          );
        }}
        selectedItemIndex={selectedItemIndex}
        renderItem={renderMember}
      />
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
      {openAddMemberDialog && (
        <AddMemberDialog
          open={openAddMemberDialog}
          onAdd={_onAdd}
          onClose={() => {
            setOpenAddMemberDialog(false);
          }}
        />
      )}
      {openEditMember && (
        <EditMemberDialog
          open={openEditMember}
          member={selectedItem}
          onEdit={_onEdit}
          onClose={() => {
            setOpenEditMember(false);
            setSelectedItem(false);
            setSelectedItemIndex(undefined);
          }}
        />
      )}
      <div>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}>
          <List>
            <ListItem
              button
              key="addItemType"
              onClick={() => {
                setOpenDrawer(false);
                setOpenAddMemberDialog(true);
              }}>
              <ListItemIcon>
                <IAdd size={25} />
              </ListItemIcon>
              <ListItemText primary={'Add Member'} />
            </ListItem>
            <Divider />
            <ListItem
              button
              key="close"
              onClick={() => {
                setOpenDrawer(false);
              }}>
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '18px',
                }}>
                Close{' '}
              </div>
            </ListItem>
          </List>
        </Drawer>
      </div>
      <div
        style={{
          position: 'fixed',
          right: '25px',
          bottom: '30px',
        }}>
        <Fab
          color={'primary'}
          onClick={() => {
            setOpenDrawer(true);
            // setOpenAddItemType(true);
          }}>
          <IAddFAB size={30} />
        </Fab>
      </div>
    </>
  );
}
MembersList.propTypes = {
  members: PropTypes.array,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  openAddDialog: PropTypes.func,
};

MembersList.defaultProps = {
  onAdd: () => {
    console.log('on add');
  },
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default MembersList;
