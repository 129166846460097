import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, NoData, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider/Divider';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { App } from '@meronex/app';

import AnimateHeight from 'react-animate-height';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IRemove from '@meronex/icons/ri/RiDeleteBinLine';
import IRestore from '@meronex/icons/mdc/MdcFileRestoreOutline';

import CircularProgress from '@material-ui/core/CircularProgress';

import { storesMng } from '../../api/storesMng';

import { helpers } from '../../../common/helpers';
import SitesUserName from '../../../common/SitesUserName';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ArchivedStoreDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onChange } = props;

  const [archivedStores, setArchivedStores] = React.useState();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedStore, setExpandedStore] = React.useState();
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [restoreLoading, setRestoreLoading] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      fetchArchivedStores();
    }
  }, [open]);

  const fetchArchivedStores = async () => {
    const result = await storesMng.getArchivedStores();
    setArchivedStores(result);
    setExpanded(false);
  };

  const onStoreDelete = async () => {
    setDeleteLoading(true);
    setExpanded(false);

    try {
      const result = await storesMng.deleteStore(expandedStore._id);
      fetchArchivedStores();
      toast.success('Store was deleted successfully', {
        autoClose: 900,
      });
      setExpanded(false);

      onChange();
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setDeleteLoading(false);
    }
  };
  const onStoreRestore = async () => {
    setRestoreLoading(true);
    setExpanded(false);

    try {
      const result = await storesMng.restoreStore(expandedStore._id);
      fetchArchivedStores();
      toast.success('Store was restored successfully', {
        autoClose: 900,
      });

      onChange();
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setRestoreLoading(false);
    }
  };

  const _onClose = () => {
    setDeleteLoading(false);
    setRestoreLoading(false);
    onClose();
  };
  const renderArchivedStores = () => {
    if (Array.isArray(archivedStores)) {
      if (archivedStores.length === 0) {
        return <NoData message={'No Archived Stores'} />;
      }
      return archivedStores.map((store) => {
        return (
          <>
            <div
              onClick={() => {
                if (expandedStore && expandedStore._id === store._id) {
                  setExpandedStore();
                } else {
                  setExpandedStore(store);
                }
              }}
              style={{
                marginBottom: '10px',
                padding: '5px',
              }}>
              <div
                style={{
                  height: '45px',
                }}>
                <div>
                  {store.name}{' '}
                  {expandedStore &&
                    expandedStore._id === store._id &&
                    (deleteLoading || restoreLoading) && (
                      <span style={{ marginLeft: '10px' }}>
                        {' '}
                        <CircularProgress size={20} />
                      </span>
                    )}
                  <span style={{ float: 'right', color: 'gray' }}>
                    {store.itemCount} item{' '}
                  </span>
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    color: 'gray',
                  }}>
                  Archived {App.utils.time.fullFormat(store.archived.date)} by{' '}
                  <SitesUserName profile={store.archived.archivedBy} />
                </div>
              </div>
            </div>
            {expandedStore && (
              <AnimateHeight
                duration={200}
                height={expandedStore._id === store._id ? 80 : 0} // see props documentation below
              >
                <div className={'action-container'}>
                  <Grid container style={{ textAlign: 'center' }}>
                    <Grid
                      item
                      xs={6}
                      onClick={(e) => {
                        onStoreRestore();
                      }}>
                      <IconButton>
                        <IRestore color={'green'} />
                      </IconButton>
                      <div style={{ color: 'green' }}>Restore</div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      onClick={(e) => {
                        onStoreDelete();
                      }}>
                      <IconButton>
                        <IRemove color={'red'} />
                      </IconButton>
                      <div style={{ color: 'red' }}>Delete</div>
                    </Grid>
                  </Grid>
                </div>
              </AnimateHeight>
            )}
            <Divider />
          </>
        );
      });
    }
  };
  if (!archivedStores) {
    return null;
  }
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Archived Stores
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <List>{renderArchivedStores()}</List>
      </DialogContent>
    </Dialog>
  );
}

ArchivedStoreDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ArchivedStoreDialog;
