import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';

import { useHistory } from 'react-router-dom';

import { PublicLayout } from '@meronex/layout';
import { Loading, NoData } from '@meronex/components';

import { equipmentsMng } from '../equipments/api/equipmentsMng';
import EquipmentHomePage from './components/EquipmentHomePage';

import { Icons } from '../common/Icons';

function EquipmentLandingPage(props) {
  const history = useHistory();
  const [equipmentId, setEquipmentId] = React.useState();
  const [equipment, setEquipment] = React.useState();

  const getEquipment = async () => {
    console.log(`get equipment ${equipmentId}`);
    const equipment = await equipmentsMng.getEquipment({
      uniqid: equipmentId,
    });
    console.log(equipment);
    setEquipment(equipment);
  };
  React.useEffect(() => {
    if (equipmentId && !equipment) {
      getEquipment();
    }
  }, [equipmentId]);

  React.useEffect(() => {
    const query = PublicLayout.getQuery();
    console.log(query);
    if (query.id) {
      console.log(query.id);
      setEquipmentId(query.id);
    }
  }, []);

  const renderBody = () => {
    console.log(equipment);
    if (!equipmentId) {
      return <Loading />;
    } else if (equipment) {
      return <EquipmentHomePage equipment={equipment} />;
    }
    return (
      <div>
        <NoData
          type={'warn'}
          message={'No equipment found! please try again.'}
        />
      </div>
    );
  };
  return (
    <PublicLayout emptyPage={true}>
      {renderBody()}
      <div
        style={{
          position: 'absolute',
          right: '30px',
          bottom: '40px',
        }}>
        <Fab
          onClick={() => {
            history.push('/');
          }}>
          <Icons.Home size={28} />
        </Fab>
      </div>
    </PublicLayout>
  );
}
EquipmentLandingPage.propTypes = {};

EquipmentLandingPage.defaultProps = {};
export default EquipmentLandingPage;
