import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from './utils/rotateImage';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import IosCamera from '@meronex/icons/ios/IosCamera';

import CropperDialog from './CropperDialog';

export default function AvatarUploader(props) {
  const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  };

  React.useEffect(() => {
    // added to prevent refresh when visibility change
    // by server checker
    window.DISABLE_RELOAD_ON_VISIBILTY_CHANE = true;
    return () => {
      window.DISABLE_RELOAD_ON_VISIBILTY_CHANE = false;
    };
  }, []);
  const { onUpload, src } = props;
  const [imgProps, setImgProps] = useState();
  const [openCropper, setOpenCropper] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState(src);
  const fileInput = useRef(null);

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }
      setImgProps({
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
      });
      setOpenCropper(true);
    }
  };

  const handleClose = () => {
    fileInput.current.value = '';
    setImgProps(null);
    setOpenCropper(false);
  };

  return (
    <>
      <label htmlFor="avatar-uploader">
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={<IosCamera size={35} color={'gray'} />}>
          <Avatar
            style={{
              width: '85px',
              height: '80px',
              marginTop: '-10px',
            }}
            alt="Travis Howard"
            src={avatarSrc}
          />
        </Badge>
      </label>
      <input
        style={{ display: 'none' }}
        ref={fileInput}
        accept="image/*"
        onChange={onFileChange}
        type="file"
        id="avatar-uploader"
        name="avatar-uploader"
      />
      {imgProps && imgProps.imageSrc && (
        <CropperDialog
          open={openCropper}
          imageSrc={imgProps.imageSrc}
          onUpload={(imgSrc) => {
            setAvatarSrc(imgSrc);
            if (onUpload) {
              onUpload(imgSrc);
            }
          }}
          onClose={() => handleClose()}
        />
      )}
    </>
  );
}

AvatarUploader.propTypes = {
  src: PropTypes.string,
  onUpload: PropTypes.func,
};
