import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, Form, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { factoriesMng } from '../../api/FactoriesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddFactoryDialog(props) {
  const { open, onClose, onAdd } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState();

  const createFactory = async () => {
    setLoading(true);
    console.log(formData);
    const factory = {
      name: formData.values.name,
    };
    console.log(formData);
    try {
      const result = await factoriesMng.addFactory(factory);
      onAdd();
      onClose();
      toast.success('Production site created successfully', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    setFormData({});
    onClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Add New Production Site
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: '-15px',
        }}>
        <Form
          onUpdate={(_formData) => {
            setFormData(_formData);
          }}>
          <TextField
            name={'name'}
            label="Name"
            autoFocus={true}
            defaultValue={''}
            variant="outlined"
            validate={(v) => {
              if (!v || v === '') return 'Name is required';
              return true;
            }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Form>
        <div
          style={{
            marginBottom: '20px',
            marginTop: '10px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || !formData || !formData.isValid}
            label={<span>Create</span>}
            onClick={() => {
              createFactory();
            }}
            onEnter={() => {
              createFactory();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddFactoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
};

export default AddFactoryDialog;
