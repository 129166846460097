import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { App } from '@meronex/app';
import { CloseBtn, ActionBtn, useOnEnterPress } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import ICelsius from '@meronex/icons/mdc/MdcTemperatureCelsius';
import IEquipment from '@meronex/icons/mdc/MdcForklift';

import StoreLink from '../../../../stores/components/stores/StoreLink';
import { helpers } from '../../../../common/helpers';
import { Icons } from '../../../../common/Icons';
import SitesAvatar from '../../../../common/SitesAvatar';
import { workOrderHelpers } from '../helpers';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function WorkOrderSummaryDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, workOrder, onCreate, creatingWorkOrder } = props;
  if (!workOrder) {
    return null;
  }

  const renderProcessStep = (step) => {
    if (step.isTempMeasure) {
      return (
        <span>
          {step.value} <ICelsius color={'rgb(85, 108, 214)'} />
        </span>
      );
    } else {
      const duration = moment.duration(step.timer.state);
      const minutes = duration.minutes();
      const hours = duration.hours();
      const seconds = duration.seconds();
      return (
        <span>
          {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };
  const renderItems = () => {
    if (Array.isArray(workOrder.product.items)) {
      return workOrder.product.items.map((i, index) => {
        let name, image, value, unit, store, duration, minutes, hours, seconds;

        console.log(i);
        if (i.isProcessStep) {
          name = i.name;
          image = i.image;
        } else {
          name = i.type.name;
          image = i.type.image;
          value = i.value;
          unit = i.type.unit;
          store = i.store;
        }
        return (
          <div
            key={i._id}
            style={{
              marginBottom: '14px',
              borderBottom:
                index !== workOrder.product.items.length - 1
                  ? '1px whitesmoke solid'
                  : '',
              paddingBottom: '8px',
            }}>
            <Grid container>
              <Grid item xs={2}>
                <div
                  style={{
                    display: 'inline-block',
                    fontSize: '12px',
                  }}>
                  <SitesAvatar
                    name={name}
                    image={image && image.thumbUrl}
                    size={40}
                    rount={12}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'relative',
                    top: '8px',
                    fontSize: '15px',
                    textOverflow: 'ellipsis',
                  }}>
                  {App.utils.string.truncate(name, 30)}
                  <div
                    style={{
                      color: 'gray',
                      fontSize: '12px',
                    }}>
                    {store && (
                      <div>
                        <Icons.Stores />
                        <span
                          style={{
                            marginLeft: '5px',
                          }}>
                          {store.name}
                        </span>
                      </div>
                    )}
                    {i.equipment && (
                      <div>
                        <IEquipment />
                        <span
                          style={{
                            marginLeft: '5px',
                          }}>
                          {i.equipment.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    float: 'right',
                    color: '#556cd6',
                    fontSize: '14px',
                    position: 'relative',
                    top: '10px',
                  }}>
                  {i.isProcessStep && renderProcessStep(i)}
                  {!i.isProcessStep && (
                    <span>
                      <span>{helpers.formatNumber(value)}</span>{' '}
                      <span>{unit}</span>
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        );
      });
    }
  };
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      scroll={'body'}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Work Order Summary
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginTop: '10px',
            marginBottom: '15px',
            borderRadius: '10px',
            fontSize: '16px',
          }}>
          {renderItems()}
          <Divider style={{ marginTop: '30px' }} />
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            <Grid container>
              <Grid item xs={2}>
                <div
                  style={{
                    display: 'block',
                    position: 'relative',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}>
                  <SitesAvatar
                    name={workOrder.product.name}
                    imgUrl={
                      workOrder.product.image &&
                      workOrder.product.image.thumbUrl
                    }
                    size={50}
                    round={10}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                  {App.utils.string.truncate(workOrder.product.name, 30)}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ float: 'right', marginTop: '10px' }}>
                  <div>
                    <span>
                      {helpers.formatNumber(workOrder.producedQuantity)}
                    </span>
                    &nbsp;
                    {workOrder.product.unit}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: 'gray',
                      float: 'right',
                      marginTop: '4px',
                    }}>
                    {workOrderHelpers.getPackagedQuantity(workOrder)}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Divider
              style={{
                marginTop: '5px',
              }}
            />
            <div
              style={{
                fontSize: '14px',
              }}>
              <div
                style={{
                  display: 'block',
                  marginTop: '35px',
                  marginBottom: '10px',
                }}>
                Product will be stored at:
                {workOrder.destinationStore && (
                  <>
                    <span
                      style={{
                        position: 'relative',
                        color: 'gray',
                        left: '8px',
                        fontWeight: 'bold',
                      }}>
                      <StoreLink store={workOrder.destinationStore} />
                    </span>
                  </>
                )}
              </div>
              {workOrder.batchNum && (
                <div
                  style={{
                    marginTop: '5px',
                  }}>
                  Batch #:{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}>
                    <span
                      style={{
                        position: 'relative',
                        top: '2px',
                      }}>
                      <Icons.Batch />
                    </span>{' '}
                    {workOrder.batchNum}
                  </span>
                </div>
              )}
              {workOrder.expiration && (
                <div style={{ marginTop: '5px' }}>
                  Estimated expiry:{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}>
                    {helpers.computeTimeStr({
                      date: workOrder.expiration.expirationDate,
                      unit: workOrder.expiration.unit,
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '10px',
            textAlign: 'center',
            width: '100%',
            marginTop: '20px',
            marginBottom: '80px',
          }}>
          <ActionBtn
            label={'Create Work Order'}
            loading={creatingWorkOrder}
            onClick={() => {
              onCreate();
            }}
            style={{
              height: '50px',
              width: '220px',
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

WorkOrderSummaryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  workOrder: PropTypes.object,
  onCreate: PropTypes.func,
};

export default WorkOrderSummaryDialog;
