import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Cropper from 'react-easy-crop';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

import { v4 as generateRandomID } from 'uuid';
import LinearProgress from '@material-ui/core/LinearProgress';

import getCroppedImg from './utils/cropImage';
import { utils } from '../../../../base/utils';
import { App } from '../../../../base/AppContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropperDialog(props) {
  const { open, onClose, imageSrc, onUpload } = props;
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const newState = { ...state };
    newState.imageSrc = imageSrc;
    setState(newState);
  }, [imageSrc]); // Only re-run the effect if count changes

  const [state, setState] = useState({
    imageSrc: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    croppedAreaPixels: null,
  });

  const [isCropping, setIsCropping] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [optimizingImg, setOptimizingImg] = useState(false);

  const onCropChange = (crop) => {
    const newState = { ...state };
    newState.crop = crop;
    setState(newState);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const newState = { ...state };
    newState.croppedAreaPixels = croppedAreaPixels;
    setState(newState);
  };

  const onZoomChange = (zoom) => {
    const newState = { ...state };
    newState.zoom = zoom;
    setState(newState);
  };

  const onSave = async () => {
    try {
      const croppedImage = await getCroppedImg(
        state.imageSrc,
        state.croppedAreaPixels
      );
      setCroppedImage(croppedImage);
      setIsCropping(isCropping);
      setOptimizingImg(true);
      const optimizedImg = await utils.image.optimizeImage(croppedImage);
      setOptimizingImg(false);

      uploadImg(optimizedImg);
    } catch (e) {
      console.error(e);
      setIsCropping(false);
    }
  };

  const uploadImg = (file) => {
    if (file) {
      const storage = getStorage(App.firebase);

      const imageRef = ref(storage, `images/${generateRandomID()}`);
      const uploadTask = uploadBytesResumable(imageRef, file);
      setIsUploading(true);
      monitorProgress(uploadTask, (snapshot) => onUploadSuccess(snapshot));
    }
  };

  const onUploadSuccess = (snapshot) => {
    // on upload success
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
      if (onUpload) {
        onUpload(downloadURL);
      }
    });
    console.log('Uploaded a blob or file!');
  };
  const monitorProgress = (uploadTask, onSuccess) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        setProgress(Math.round(progress));
        switch (snapshot.state) {
          case 'paused': // or 'paused'
            console.log('Upload is paused');
            break;
          case 'running': // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setIsUploading(false);
      },
      () => {
        if (onSuccess) {
          console.log('Upload successful');
          onSuccess(uploadTask.snapshot);
          setIsUploading(false);
          onClose();
        }
      }
    );
  };

  const styleText = {
    textAlign: 'center',
    color: 'gray',
    fontSize: '12px',
    marginBottom: '4px',
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle id="alert-dialog-slide-title">
        {'Adjust Profile Image'}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            height: '200px',
            overflow: 'hidden',
            width: '100%',
            position: 'relative',
          }}>
          <Cropper
            image={state.imageSrc}
            crop={state.crop}
            zoom={state.zoom}
            showGrid={false}
            cropShape={'round'}
            maxWidth={'xl'}
            aspect={state.aspect}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </div>

        <div style={{ marginTop: '10px' }}>
          {optimizingImg && <div style={styleText}>Optimizing image...</div>}
          {isUploading && (
            <>
              <div style={styleText}>Uploading {progress}%</div>
              <LinearProgress variant="determinate" value={progress} />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSave();
          }}
          color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CropperDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  imageSrc: PropTypes.string,
  onUpload: PropTypes.func,
};
