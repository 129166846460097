import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, TextField } from '@material-ui/core';

import { itemCategoriesMng } from '../api/itemCategoriesMng';

function ItemCategoriesSelect(props) {
  const { value, onChange } = props;
  const [categories, setCategories] = React.useState();

  const getCategories = async () => {
    const categories = await itemCategoriesMng.getItemCategories();
    categories.unshift({
      _id: 'any',
      name: 'Any',
    });

    setCategories(categories);
  };

  React.useEffect(() => {
    if (!Array.isArray(categories)) {
      getCategories();
    }
  }, []);

  const _onChange = (e) => {
    const category = categories.find((c) => c._id === e.target.value);
    onChange(category);
  };
  const renderItems = () => {
    if (!Array.isArray(categories)) {
      return <MenuItem value={'loading'}>Loading</MenuItem>;
    }
    const menuItems = categories.map((c) => {
      return <MenuItem value={c._id}>{c.name}</MenuItem>;
    });
    return menuItems;
  };

  return (
    <TextField
      variant="standard"
      label="Category"
      value={value ? value._id : undefined}
      onChange={_onChange}
      fullWidth
      margin="normal"
      select>
      {renderItems()}
    </TextField>
  );
}
ItemCategoriesSelect.propTypes = {};

ItemCategoriesSelect.defaultProps = {};
export default ItemCategoriesSelect;
