import { App } from '@meronex/app';
import { workOrderHelpers } from '../components/workorders/helpers';
import { toast } from '@meronex/components';

const { server } = App;

export const workOrdersMng = {
  createWorkOrder: async (workOrder) => {
    const result = await server.call('createWorkOrder', workOrder);
    return result;
  },
  saveWorkOrderDraft: async (workOrder) => {
    const result = await server.call('saveWorkOrderDraft', { workOrder });
    return result;
  },
  deleteWorkOrderDraft: async ({ workOrderDraft }) => {
    const result = await server.call('deleteWorkOrderDraft', {
      workOrderDraft,
    });
    return result;
  },
  getWorkOrderDrafts: async ({ factoryId, productId }) => {
    const result = await server.call('getWorkOrderDrafts', {
      factoryId,
      productId,
    });
    return result;
  },

  getWorkOrders: async (factoryId) => {
    const result = await server.call('getWorkOrders', { factoryId });
    return result;
  },
  getWorkOrdersCursor: async ({ factoryId, skip, limit, filters }) => {
    console.log(`get work order cursor called, skip: ${skip}, limit: ${limit}`);
    const result = await server.call('getWorkOrdersCursor', {
      factoryId,
      skip,
      limit,
      filters,
    });
    return result;
  },
  reverseWorkOrder: async (workOrderId) => {
    const result = await server.call('reverseWorkOrder', { workOrderId });
    return result;
  },
  updateWorkOrder: async ({ _id, note, status, state }) => {
    const result = await server.call('updateWorkOrder', {
      _id,
      note,
      status,
      state,
    });
    return result;
  },
  getProductItemsWithQuantity: async (productId) => {
    const result = await server.call('getProductItemsWithQuantity', {
      productId,
    });
    return result;
  },

  helpers: workOrderHelpers,
};
