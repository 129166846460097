import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, Loading } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IArchive from '@meronex/icons/en/EnArchive';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { NoData } from '@meronex/components';

import { storesMng } from '../../api/storesMng';
import StoreItem from './StoreItem';
import ActionsDialog from './actions/ActionsDialog';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ArchivedStoreItemsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    onClose,
    store,
    permissions,
    onRestoreStoreItem,
    onRemoveStoreItem,
  } = props;

  const [archivedItems, setArchivedItems] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [openArchiveActions, setOpenArchiveActions] = React.useState();

  const fetchStoreArchives = async () => {
    if (store) {
      const result = await storesMng.getStoreArchives(store._id);
      setArchivedItems(result);
    }
  };

  React.useEffect(() => {
    fetchStoreArchives();
  }, [open]);

  if (!store) {
    return null;
  }

  const _onClose = () => {
    setArchivedItems(undefined);
    onClose();
  };
  const renderArchivedItems = () => {
    if (!archivedItems) {
      return <Loading />;
    }
    if (Array.isArray(archivedItems)) {
      if (archivedItems.length === 0) {
        return (
          <NoData key={'no-data'} message={'No Archived Items'} type={'warn'} />
        );
      } else
        return archivedItems.map((i) => {
          return (
            <StoreItem
              key={i.key}
              item={i}
              archived={i.archived}
              onRestoreStoreItem={() => {
                fetchStoreArchives();
                onRestoreStoreItem();
                onClose();
              }}
              onRemoveStoreItem={() => {
                fetchStoreArchives();
                onRemoveStoreItem();
              }}
              onClick={() => {
                setSelectedItem(i);
                setOpenArchiveActions(true);
              }}
            />
          );
        });
    }
  };
  return (
    <>
      <Dialog
        className={'fullScreenMobile'}
        open={open}
        onClose={_onClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle>
          <IArchive size={25} color={'black'} /> Archives
          <CloseBtn
            onClick={() => {
              _onClose();
            }}
          />
        </DialogTitle>
        <DialogContent
          style={{
            padding: '0px',
          }}>
          {renderArchivedItems()}
        </DialogContent>
      </Dialog>
      <ActionsDialog
        item={selectedItem}
        open={openArchiveActions}
        permissions={permissions}
        onItemDelete={() => {
          fetchStoreArchives();
          onRemoveStoreItem();
          _onClose();
        }}
        onItemRestore={() => {
          fetchStoreArchives();
          onRestoreStoreItem();
          _onClose();
        }}
        onClose={() => {
          setOpenArchiveActions(false);
        }}
      />
    </>
  );
}

ArchivedStoreItemsDialog.defaultProps = {
  onChange: () => {},
};
ArchivedStoreItemsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  store: PropTypes.object,
  onRestoreStoreItem: PropTypes.func,
  onRemoveStoreItem: PropTypes.func,
};

export default ArchivedStoreItemsDialog;
