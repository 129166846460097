import React from 'react';
import { App } from '@meronex/app';

const headerSettings = App.settings.header;

export const header = {
  disableNotifications: true,
  title: (
    <>
      <img style={headerSettings.logo.style} src={headerSettings.logo.src} />
    </>
  ),
};
