import Avatar from 'react-avatar';
import { App } from '@meronex/app';
import React from 'react';

import inSound from './sounds/in.mp3';
import outSound from './sounds/out.mp3';
import icq from './sounds/icq.wav';
import netflix from './sounds/netflix.mp3';
import moment from 'moment';
import SitesUserName from './SitesUserName';

let audioIn = new Audio(inSound);
let audioOut = new Audio(outSound);
let audioIcq = new Audio(icq);
let audioNetflix = new Audio(netflix);

const thousandsSeparator = App.utils.number.thousandsSeparator;
const round = App.utils.number.round;

export const helpers = {
  TRANSACTION_TYPES: {
    in: 'in',
    out: 'out',
    transferIn: 'transferIn',
    transferOut: 'transferOut',
    modify: 'modify',
    reverse: 'reverse',
  },
  isTransferTransaction: (type) => {
    if (
      type === helpers.TRANSACTION_TYPES.transferOut ||
      type === helpers.TRANSACTION_TYPES.transferIn
    ) {
      return true;
    }
    return false;
  },

  renderImage: ({
    image,
    name,
    avatarStyle = {
      size: 85,
      round: '15px',
    },
    imgStyle,
  }) => {
    let imgSrc;

    const mergedImgStyle = Object.assign(
      {
        borderRadius: '10px',
        width: '75px',
        height: '75px',
      },
      imgStyle
    );
    if (typeof image === 'string') {
      imgSrc = image;
    } else if (typeof image === 'object' && image.imgUrl) {
      if (image.thumbUrl) {
        imgSrc = image.thumbUrl;
      } else {
        imgSrc = image.imgUrl;
      }
    }
    if (imgSrc) {
      return <img style={mergedImgStyle} src={imgSrc} />;
    }
    return (
      <Avatar
        name={App.utils.sanitize(name)}
        size={avatarStyle.size}
        round={avatarStyle.round}
      />
    );
  },
  computeTimeStr: ({ date, unit }) => {
    let format = 'DD/MM/YY ';
    if (unit === 'seconds') {
      format += 'hh:mm:ss A';
    } else if (unit === 'minutes') {
      format += 'hh:mm A';
    } else if (unit === 'hours') {
      format += 'hh A';
    }
    return <span>{moment(date).format(format).toString()}</span>;
  },
  formatNumber: (num) => {
    return thousandsSeparator(round(num));
  },
  batch: {
    sortBatchesByExpiry: (groups) => {
      return groups.sort((a, b) => {
        if (a.expiration && b.expiration) {
          return a.expiration.expirationDate - b.expiration.expirationDate;
        }
        return 0;
      });
      return groups;
    },
    isBatchedItem: (item) => {
      return (
        item &&
        Array.isArray(item.itemGroups) &&
        item.itemGroups.length > 0 &&
        item.productionInfo &&
        item.productionInfo.product &&
        item.productionInfo.product.groupByBatch
      );
    },
    getSum: (item) => {
      if (!helpers.batch.isBatchedItem(item)) {
        return 0;
      }
      let sum = 0;
      item.itemGroups.forEach((i) => (sum += i.quantity));
      return sum;
    },
  },

  renderUserName: ({ profile, style }) => {
    if (profile) {
      return <SitesUserName profile={profile} style={style} />;
    }
  },

  userSiteSettings: {
    get: async () => {
      let siteSettings = {};
      const userSettings = await App.userSettings.get();
      if (userSettings && userSettings.sites) {
        siteSettings = userSettings.sites;
      } else {
        userSettings.site = siteSettings;
        await App.userSettings.set(userSettings);
      }
      return siteSettings;
    },
    set: async (siteSettings) => {
      const userSettings = App.utils.clone(await App.userSettings.get());
      if (!userSettings.sites) {
        userSettings.sites = {};
      }
      const _mergedSettings = Object.assign(userSettings.site, siteSettings);
      userSettings.site = _mergedSettings;
      await App.userSettings.set(userSettings);
      return siteSettings;
    },
  },
};

export const playSound = (type = 'in', volume) => {
  let audio;
  if (type === 'in') {
    audio = audioIn;
  } else if (type === 'out') {
    audio = audioOut;
  } else if (type === 'icq') {
    audio = audioIcq;
  } else if (type === 'netflix') {
    audio = audioNetflix;
  }
  if (typeof volume === 'number') {
    audio.volume = volume;
  }

  audio.play();
};

playSound.types = {
  IN: 'in',
  OUT: 'OUT',
};
