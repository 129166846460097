import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import NotificationsTabsPanel from './components/NotificationsTabsPanel';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(14, 14, 14)',
    height: '70px',
    color: theme.appBar.color,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationsDialog(props) {
  const classes = useStyles();

  const { open, onClose } = props;

  return (
    <Dialog open={open} fullWidth onClose={() => onClose()}>
      <div>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Notifications
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <div id={'notification-dialog'}>
        <NotificationsTabsPanel />
      </div>
    </Dialog>
  );
}

NotificationsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
