import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, NoData } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IVerify from '@meronex/icons/mdc/MdcMicroscope';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import QualityForm from './QualityForm';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function QualityFormDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IVerify /> Quality Form
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <QualityForm />
      </DialogContent>
    </Dialog>
  );
}

QualityFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default QualityFormDialog;
