import React from 'react';
import PropTypes from 'prop-types';
import { App } from '@meronex/app';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IChart from '@meronex/icons/hi/HiOutlineChartPie';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import IAudit from '@meronex/icons/bi/BiListCheck';
import IStores from '@meronex/icons/mdc/MdcWarehouse';

import Transactions from '../../components/storeItems/actions/Transactions';

import { storesMng } from '../../api/storesMng';
import TopHeader from '../../../views/components/TopHeader';
import { ActionBtn } from '@meronex/components';
import StoreAnalyticsDialog from '../storeAnalytics/StoreAnalyticsDialog';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AuditStoreDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, store } = props;

  const [filters, setFilters] = React.useState();
  const [openAnalytics, setOpenAnalytics] = React.useState();

  const transactionsRef = React.useRef();

  const [fetchValues, setFetchValues] = React.useState({
    skip: 0,
    limit: 9,
  });

  React.useEffect(() => {
    console.log('fetch');
    if (store) {
      fetchStoreTransactions({
        limit: fetchValues.limit,
        skip: fetchValues.skip,
      });
      if (transactionsRef.current) {
        transactionsRef.current.refresh();
      }
    }
  }, [filters]);

  if (!store) {
    return null;
  }

  const fetchStoreTransactions = async ({ limit, skip }) => {
    console.log('fetch!!');
    console.log(store);

    if (!store) {
      return null;
    }
    const fetchValues = {
      storeId: store._id,
      limit,
      skip,
      filters,
    };

    console.log(fetchValues);
    const transactions = await storesMng.getStoreTransactionsCursor(
      fetchValues
    );
    console.log(transactions);
    setFetchValues(fetchValues);

    return transactions;
  };

  const onApplyFilters = (filters) => {
    console.log('apply filter');
    setFilters(filters);
  };
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopHeader
          filterProps={{
            expandable: true,
            filterOnApply: true,
            placeholder: 'search item name',
            enableDateRange: true,
            onReset: () => {
              console.log('on reset');
              setFilters({});
            },
            onApply: (v) => {
              console.log('on apply audit store');
              console.log(v);
              const _filters = Object.assign({ ...filters }, v);
              onApplyFilters(_filters);
            },
          }}
          title={`Audit ${store.name}`}
          icon={
            <span>
              <IStores />
              &nbsp;
              <span
                style={{
                  position: 'relative',
                  top: '2px',
                }}>
                <IAudit size={25} />
              </span>
            </span>
          }
          onClose={onClose}
        />
      </DialogTitle>
      <DialogContent
        style={{
          overflow: ' hidden',
          padding: '0px',
        }}>
        <div>
          <div>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '15px',
              }}>
              <ActionBtn
                style={{
                  width: 300,
                }}
                label={
                  <span>
                    <IChart /> Analyze Transactions
                  </span>
                }
                onClick={() => {
                  setOpenAnalytics(true);
                  console.log('analyze transactions');
                }}
              />
            </div>
          </div>
          <Transactions
            ref={transactionsRef}
            isStoreTransactions
            onReverseTransaction={() => {
              console.log('on reverse transaction');
              onClose();
            }}
            height={window.innerHeight - 100}
            fetchTransactions={fetchStoreTransactions}
          />
        </div>
      </DialogContent>
      {store && openAnalytics && (
        <StoreAnalyticsDialog
          storeId={store._id}
          open={openAnalytics}
          onClose={() => {
            setOpenAnalytics(false);
          }}
        />
      )}
    </Dialog>
  );
}

AuditStoreDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AuditStoreDialog;
