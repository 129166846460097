import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { App } from '@meronex/app';
import { SaveBtn, CloseBtn, ImageUploader, Form } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ItemTypeForm from './ItemTypeForm';
import storesMng from '../../api/storesMng';

const { server } = App;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  uploadBtnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  uploadBtn: {
    borderRadius: '20px',
    width: '100%',
    height: '45px',
    marginTop: '5px',
    marginBottom: '25px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function EditItemTypeDialog(props) {
  const { open, onClose, onUpdate, itemType } = props;

  const classes = useStyles();

  const validatedForm = React.useRef();

  const [formData, setFormData] = React.useState({});
  const [uploading, setUploading] = React.useState(false);

  const onSave = async () => {
    if (formData && formData.values) {
      const item = Object.assign(itemType, formData.values);
      console.log(item);

      delete item.added;
      try {
        const id = await storesMng.updateStoreItemType(item);
        toast.success('Store type was successfully updated!');
      } catch (e) {
        console.log(e);
      }
      if (props.onClose) {
        props.onClose();
      }
      reset();
      onUpdate(item);
    }
  };

  function reset() {
    if (validatedForm.current) {
      validatedForm.current.reset();
    }
  }

  if (!itemType) {
    return null;
  }
  return (
    <div>
      <Dialog
        className={'fullScreenMobile'}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle>
          Update Item Type{' '}
          <CloseBtn
            onClick={() => {
              reset();
              onClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <ItemTypeForm
              itemType={itemType}
              ref={validatedForm}
              validateOnInit={true}
              onUpdate={(formData) => {
                console.log(formData);
                setFormData(formData);
                setUploading(formData.uploading);
              }}
            />
          </div>
          <SaveBtn
            disabled={uploading || !formData.isValid}
            onClick={onSave}
            label={'Save'}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

EditItemTypeDialog.defaultProps = {
  onAdd: () => {},
};
EditItemTypeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  itemType: PropTypes.object,
};
