import React from 'react';
import PropTypes from 'prop-types';

import MdSentimentDissatisfied from '@meronex/icons/md/MdSentimentDissatisfied';
import MdSentimentNeutral from '@meronex/icons/md/MdSentimentNeutral';
import MdSentimentSatisfied from '@meronex/icons/md/MdSentimentSatisfied';

export default function SatisfactionDetails(props) {
  const log = props.log;
  let score = {};
  const ratings = log.logDetails.ratings;
  if (Array.isArray(ratings)) {
    const totalRatings = ratings.length;
    let totalPoints = 0;
    ratings.forEach((v) => {
      if (v.rating === 'dissatisfied') {
        // do nothing
      } else if (v.rating === 'satisfied') {
        totalPoints += 2;
      } else if (v.rating === 'neutral') {
        totalPoints += 1;
      }
    });
    // eslint-disable-next-line no-mixed-operators
    const totalPtsByTotalRatings = totalPoints / (totalRatings * 2);
    const percentage = totalPtsByTotalRatings * 100;
    score = {
      percentage,
      numOfRating: ratings.length,
      totalPoints,
    };
  }

  const roundedPercentage = Math.round(score.percentage);
  let satisfactionDisplay = <div />;
  if (roundedPercentage < 40) {
    satisfactionDisplay = (
      <div
        style={{
          fontSize: props.fontSize,
          color: 'rgb(199, 128, 144)',
        }}>
        <MdSentimentDissatisfied
          style={{
            fontSize: props.iconSize,
            verticalAlign: 'middle',
          }}
        />
        {props.inline ? (
          <span>{roundedPercentage}%</span>
        ) : (
          <div>{roundedPercentage}%</div>
        )}
      </div>
    );
  } else if (roundedPercentage >= 40 && roundedPercentage <= 65) {
    satisfactionDisplay = (
      <div
        style={{
          fontSize: props.fontSize,
          color: 'rgb(234, 217, 130)',
        }}>
        <MdSentimentNeutral
          style={{
            fontSize: props.iconSize,
            verticalAlign: 'middle',
          }}
        />
        {props.inline ? (
          <span>{roundedPercentage}%</span>
        ) : (
          <div>{roundedPercentage}%</div>
        )}
      </div>
    );
  } else if (roundedPercentage > 65) {
    satisfactionDisplay = (
      <div
        style={{
          fontSize: props.fontSize,
          color: 'rgb(121, 171, 91)',
        }}>
        <MdSentimentSatisfied
          style={{
            fontSize: props.iconSize,
            verticalAlign: 'middle',
          }}
        />
        {props.inline ? (
          <span>{roundedPercentage}%</span>
        ) : (
          <div>{roundedPercentage}%</div>
        )}
      </div>
    );
  }

  return satisfactionDisplay;
}

SatisfactionDetails.propTypes = {
  fontSize: PropTypes.string,
  iconSize: PropTypes.string,
  inline: PropTypes.bool,
};

SatisfactionDetails.defaultProps = {
  fontSize: '20px',
  iconSize: '40px',
  inline: true,
  feed: {},
};
