import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';

import { App } from '../../base/AppContext';

export default class FirstTimeUserChecker extends Component {
  state = {
    open: false,
    firstTimeUser: false,
    loginInfo: FirstTimeUserChecker.getLoginInfo(),
    newPassword: '',
    confirmPassword: '',
  };
  static propTypes = {};
  static defaultProps = {};

  static getLoginInfo = () => {
    return App.clientSettings.getItem('loginInfo');
  };

  componentDidMount() {
    window.sessionStorage.setItem('updatingPassword', false);

    App.eventsManager.on('event-login', this, (loginInfo) => {
      console.log(loginInfo);
      if (loginInfo.email) {
        // store the first time login attempt temporary in local storage to protect
        // against refresh before setting the first time password
        App.clientSettings.setItem('loginInfo', loginInfo);
        this.setState(
          {
            loginInfo,
          },
          () => {
            this.checkFirstTimeUser(loginInfo.email);
          }
        );
      }
    });
  }

  handleClose = () => {
    window.sessionStorage.setItem('updatingPassword', false);

    this.setState(
      {
        loginInfo: undefined,
      },
      () => {
        App.clientSettings.removeItem('loginInfo');
      }
    );
  };

  handleUpdate = async () => {
    const { loginInfo, newPassword, confirmPassword } = this.state;

    console.log(loginInfo);
    console.log(newPassword);
    console.log(confirmPassword);
    if (!newPassword) {
      toast.error('Password is required');
    } else if (newPassword != confirmPassword) {
      toast.error('Password does not match');
    } else {
      // call serer method
      try {
        const { server } = App;
        const updated = await server.call('updatePassword', {
          email: loginInfo.email,
          oldPassword: loginInfo.password,
          newPassword,
        });
        console.log('update password returns');
        console.log(updated);
        if (updated) {
          this.handleClose();
        } else {
          window.sessionStorage.setItem('updatingPassword', false);
          App.clientSettings.removeItem('loginInfo');
        }
      } catch (e) {
        toast.error('Something went wrong, please try again later.');
        console.error(e);
        window.sessionStorage.setItem('updatingPassword', false);
        App.clientSettings.removeItem('loginInfo');
      }
    }
  };
  checkFirstTimeUser = async (email) => {
    console.log(`checking for first time user,  email: ${email}`);
    let result;
    try {
      const { server } = App;
      result = await server.call('isFirstTimeUser', {
        email,
      });
      console.log(result);
      if (result) {
        console.log('first time user');
        window.sessionStorage.setItem('updatingPassword', true);
        this.setState({
          open: true,
          firstTimeUser: true,
        });
      } else {
        window.sessionStorage.setItem('updatingPassword', false);
        App.clientSettings.removeItem('loginInfo');
        console.log('returning user');
      }
    } catch (e) {
      console.warn(e);
      App.clientSettings.removeItem('loginInfo');
    }
    return result;
  };
  render() {
    const { loginInfo, firstTimeUser } = this.state;

    const isOpen = () => {
      // Open the dialog when firstTimeUser, the user is logged in and
      // we've the loginInfo
      const token = App.clientSettings.getItem('token');
      return firstTimeUser && loginInfo && token;
    };
    return (
      <Dialog
        open={isOpen()}
        onClose={() => {}}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <div
            style={{
              position: 'relative',
              top: '-15px',
            }}>
            <TextField
              onChange={(e) => {
                this.setState({
                  newPassword: e.target.value,
                });
              }}
              margin="dense"
              id="newPassword"
              label="New Password"
              type="password"
              fullWidth
            />
            <TextField
              onChange={(e) => {
                this.setState({
                  confirmPassword: e.target.value,
                });
              }}
              margin="dense"
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
