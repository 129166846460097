import React from 'react';
import PropTypes, { number } from 'prop-types';
import TextField from '@material-ui/core/TextField';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Grid } from '@material-ui/core';

import IAmount from '@meronex/icons/fa/FaWeight';
import INote from '@meronex/icons/fd/FdClipboardNotes';
import ICode from '@meronex/icons/fa/FaBarcode';
import ILocation from '@meronex/icons/en/EnLocation';

import ActionTransferFlow from './ActionTransferFlow';

import SitesAvatar from '../../../../common/SitesAvatar';
import BatchSelect from '../../../../common/BatchSelect';
import Quantity from '../../../../common/Quantity';
import StoresSelect from '../../../../common/StoresSelect';

import { helpers } from '../../../../common/helpers';
import { Icons } from '../../../../common/Icons';

const useStyles = makeStyles((theme) => ({
  container: {},
  image: {
    margin: '10px',
    marginTop: '5px',
    borderRadius: '15px',
    width: '90px',
    minHeight: '90px',
    maxHeight: '120px',
    objectFit: 'cover',
  },
  title: {
    fontSize: '18px',
    fontWeight: '500',
    position: 'relative',
    marginTop: '-4px',
    marginBottom: '5px',
  },
  unit: {
    marginTop: '10',
    fontSize: '20',
    marginBottom: '10px',
    color: 'gray',
  },
  amountIcon: {
    marginRight: '10px',
    top: '4px',
    position: 'relative',
  },
  inputStyle: { fontSize: 40, textAlign: 'center' },
}));

function ActionInfo(props) {
  const classes = useStyles();
  const theme = useTheme();

  const getInitRate = (item) => {
    if (!item) {
      return '';
    }
    if (
      item.adjustedUnitRate &&
      !isNaN(item.adjustedUnitRate.lastInputtedRate)
    ) {
      return item.adjustedUnitRate.lastInputtedRate;
    }
    if (item.type && !isNaN(item.type.price)) {
      return item.type.price;
    }
    return '';
  };
  const {
    onChange,
    item,
    type,
    hideImage,
    onNoteFocusUpdate,
    onAmountBlur,
    onTargetStoreSelect,
    autoFocusQuantity,
    enableAdjustedUnitRateInput,
    isTransferDialog,
  } = props;

  const [selectedStore, setSelectedStore] = React.useState();

  const [note, setNote] = React.useState('');
  const [amount, setAmount] = React.useState(props.amount);
  const [adjustedUnitRate, setAdjustedUnitRate] = React.useState(
    getInitRate(item)
  );

  const [group, setGroup] = React.useState();

  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    setAdjustedUnitRate(getInitRate(item));
  }, [item]);

  React.useEffect(() => {
    const data = {};
    data.storeItemId = item._id;
    data.selectedStore = selectedStore;
    data.note = note;
    data.amount = amount;
    data.group = group;
    if (!isNaN(adjustedUnitRate)) {
      data.adjustedUnitRate = adjustedUnitRate;
    }

    if (init) {
      onChange(data);
    } else {
      setInit(true);
    }
  }, [note, amount, group, selectedStore, adjustedUnitRate]);

  React.useEffect(() => {
    if (onTargetStoreSelect) {
      onTargetStoreSelect(selectedStore);
    }
  }, [selectedStore]);

  const onAmountChange = (e) => {
    let _amount = parseFloat(e.target.value);
    if (isNaN(_amount)) {
      setAmount('');
    } else {
      setAmount(_amount);
    }
  };

  const onRateChange = (e) => {
    let _unitRate = parseFloat(e.target.value);
    if (isNaN(_unitRate)) {
      setAdjustedUnitRate('');
    } else {
      setAdjustedUnitRate(_unitRate);
    }
  };
  const renderItemInfo = () => {
    return (
      <div
        style={{
          marginTop: '20px',
        }}>
        {!hideImage && (
          <SitesAvatar
            name={item.type.name}
            image={item.type.image}
            size={125}
            round={10}
          />
        )}
        <div
          style={{
            marginBottom: '25px',
          }}>
          <div
            style={{
              marginTop: '18px',
              fontWeight: '400',
              fontSize: '16px',
            }}>
            <span
              style={{
                position: 'relative',
                top: '2px',
                left: '-8px',
              }}>
              <Icons.Items />
            </span>
            {item.productionInfo && (
              <span
                style={{
                  position: 'relative',
                  top: '2px',
                }}>
                <Icons.Products size={16} /> &nbsp;
              </span>
            )}
            {item.type.name}
            <div
              style={{
                marginTop: '10px',
                color: 'gray',
                fontSize: '12px',
              }}>
              <span
                style={{
                  position: 'relative',
                  marginRight: '5px',
                  top: '2px',
                }}>
                <ICode />
              </span>
              {item.type.code}
            </div>{' '}
            {item.location && (
              <div
                style={{
                  marginTop: '10px',
                  fontSize: '18px',
                  marginBottom: '-15px',
                }}>
                <span
                  style={{
                    marginLeft: '5px',
                  }}>
                  {item.location.rack}.{item.location.shelf}.
                  {item.location.level}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const renderAmountFlow = () => {
    const _amount = Number.parseFloat(amount || 0);
    const _quantity = Number.parseFloat(item.quantity || 0);

    if (!helpers.isTransferTransaction(type)) {
      let _itemQuantity;

      if (type === helpers.TRANSACTION_TYPES.out) {
        _itemQuantity = _quantity - _amount;
      } else {
        _itemQuantity = _quantity + _amount;
      }
      return (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: '-15px',
          }}>
          <Quantity
            quantity={_itemQuantity}
            quantitySize={'35px'}
            unitLabel={item.type.unit}
          />
        </div>
      );
    }
    if (helpers.isTransferTransaction(type)) {
      return (
        <div
          style={{
            marginTop: '-45px',
            marginBottom: '-35px',
          }}>
          <ActionTransferFlow
            item={item}
            type={type}
            selectedStore={selectedStore}
            quantity={amount}
          />
        </div>
      );
    }
  };

  const renderBatchSelect = () => {
    return (
      <div
        style={{
          marginTop: '35px',
        }}>
        <BatchSelect
          item={item}
          amount={amount || 0}
          transactionType={type}
          onChange={(group) => {
            setGroup(group);
          }}
        />
      </div>
    );
  };

  const getAutoFocus = () => {
    if (!item.itemGroups) {
      if (autoFocusQuantity) {
        return true;
      }
    }
    if (Array.isArray(item.itemGroups) && item.itemGroups.length === 0) {
      return true;
    }
    return false;
  };
  const renderQuantityField = () => {
    return (
      <div
        style={{
          marginTop: '15px',
        }}>
        <TextField
          fullWidth
          label={
            <div>
              <div
                style={{
                  display: 'inline-block',
                  position: 'relative',
                }}>
                <IAmount size={18} />
              </div>
              &nbsp;{' '}
              <span style={{ position: 'relative', top: '-2px' }}>
                Quantity
              </span>
            </div>
          }
          style={{
            textAlign: 'center',
            marginBottom: '5px',
          }}
          type={'number'}
          autoFocus={getAutoFocus()}
          variant={'outlined'}
          value={amount}
          onBlur={() => {
            if (onAmountBlur) {
              setTimeout(() => {
                onAmountBlur();
              }, 500);
            }
          }}
          onChange={onAmountChange}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="start">
                <span
                  style={{
                    fontSize: '12px',
                    whiteSpace: 'normal',
                  }}>
                  {item.type.unit}
                </span>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };
  const renderAdjustedUnitRate = () => {
    return (
      <div
        style={{
          marginTop: '15px',
        }}>
        <TextField
          fullWidth
          label={
            <div>
              <div
                style={{
                  display: 'inline-block',
                  position: 'relative',
                }}>
                <Icons.Price size={18} />
              </div>
              &nbsp;{' '}
              <span style={{ position: 'relative', top: '-2px' }}>
                Adjusted Unit Rate
              </span>
            </div>
          }
          style={{
            textAlign: 'center',
            marginBottom: '5px',
          }}
          type={'number'}
          variant={'outlined'}
          value={adjustedUnitRate}
          onChange={onRateChange}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="start">
                <span
                  style={{
                    fontSize: '12px',
                    whiteSpace: 'normal',
                  }}>
                  AED/{item.type.unit}
                </span>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };
  const renderNote = () => {
    return (
      <div
        style={{
          position: 'relative',
          marginTop: '10px',
        }}>
        <TextField
          label={
            <span>
              <INote size={22} />{' '}
              <span style={{ position: 'relative', top: '-5px' }}>Note</span>
            </span>
          }
          style={{ textAlign: 'left' }}
          multiline
          maxRows={4}
          onFocus={() => {
            onNoteFocusUpdate(true);
          }}
          onBlur={() => {
            onNoteFocusUpdate(false);
          }}
          fullWidth
          variant={'outlined'}
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
    );
  };

  const renderTargetStore = () => {
    if (!helpers.isTransferTransaction(type)) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: '5px',
        }}>
        <StoresSelect
          typeId={item.type._id}
          includeProductsStore={false}
          storeId={selectedStore ? selectedStore._id : null}
          exclude={[item.store._id]}
          onChange={(store) => {
            setSelectedStore(store);
          }}
        />
      </div>
    );
  };
  return (
    <div
      className={'noselect'}
      style={{
        textAlign: 'center',
      }}>
      {!isTransferDialog && (
        <Grid
          container
          style={{
            marginBottom: '-30px',
          }}>
          <Grid item xs={6}>
            {renderAmountFlow()}
          </Grid>
          <Grid item xs={6}>
            {renderItemInfo()}
          </Grid>
        </Grid>
      )}
      {isTransferDialog && (
        <div>
          {renderItemInfo()}
          {renderAmountFlow()}
        </div>
      )}
      {type !== 'info' && (
        <div>
          {renderBatchSelect()}
          {renderQuantityField()}
          {enableAdjustedUnitRateInput && item.store.enableDynamicPrice && (
            <div>{renderAdjustedUnitRate()}</div>
          )}
          {renderTargetStore()}
          {renderNote()}
        </div>
      )}
    </div>
  );
}

ActionInfo.propTypes = {
  onChange: PropTypes.func,
  item: PropTypes.object,
  type: PropTypes.string,
  onNoteFocusUpdate: PropTypes.func,
  description: PropTypes.object,
  onAmountBlur: PropTypes.func,
  onTargetStoreSelect: PropTypes.func,
  autoFocusQuantity: PropTypes.bool,
  hideImage: PropTypes.bool,
  isTransferDialog: PropTypes.bool,
  enableAdjustedUnitRateInput: PropTypes.bool,
};

ActionInfo.defaultProps = {
  onChange: (v) => {
    console.log(v);
  },
  isTransferDialog: false,
  type: helpers.TRANSACTION_TYPES.in,
  setNoteFocused: (v) => console.log(v),
  onAmountBlur: () => console.log('amount blur'),
  hideImage: false,
  enableAdjustedUnitRateInput: false,
};
export default ActionInfo;
