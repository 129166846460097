import React from 'react';
import PropTypes from 'prop-types';
import IProduct from '@meronex/icons/fi/FiPackage';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { productsMng } from '../../api/ProductsMng';
import ProductForm from './ProductForm';
import TopHeader from '../../../views/components/TopHeader';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddProductDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { factory, open, onClose, onAdd } = props;

  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState();

  if (!factory) {
    return null;
  }

  const createProduct = async () => {
    setLoading(true);

    const _product = { ...product };
    _product.factoryId = factory._id;
    delete _product.isValid;
    console.log(_product);
    try {
      const result = await productsMng.addProduct(_product);
      onAdd();
      _onClose();
      toast.success('Product created successfully', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    onClose();
    setTimeout(() => {
      setProduct({});
    }, 500);
  };

  return (
    <Dialog
      scroll={'body'}
      className={'fullScreenMobile'}
      open={open}
      disableBackdropClick="false"
      onClose={_onClose}>
      <DialogTitle>
        <TopHeader
          icon={<IProduct />}
          title={'Add a Product'}
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <ProductForm onChange={(p) => setProduct(p)} product={product} />
        </div>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            paddingBottom: '20px',
          }}>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
            }}>
            <ActionBtn
              style={{}}
              loading={loading}
              disabled={!product || !product.isValid}
              label={<span>Create Product</span>}
              onClick={() => {
                createProduct();
              }}
              onEnter={() => {
                createProduct();
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddProductDialog.defaultProps = {
  onAdd: () => {},
};
AddProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  factory: PropTypes.object,
};

export default AddProductDialog;
