import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import AnimateHeight from 'react-animate-height';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import IEdit from '@meronex/icons/bi/BiEdit';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';
import { Icons } from '../../../common/Icons';

const ItemCategoryItem = (props) => {
  const { onClick, onEdit, onDelete, itemCategory } = props;
  const [expanded, setExpanded] = React.useState(false);

  const _onClick = () => {
    console.log('onClick');
    setExpanded(!expanded);
    onClick(itemCategory);
  };
  const _onEdit = () => {
    onEdit(itemCategory);
  };
  const _onDelete = () => {
    onDelete(itemCategory);
  };
  return (
    <>
      <ListItem onClick={_onClick}>
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <div>
            <div
              style={{
                fontSize: '16px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}>
              {itemCategory.name}
              {itemCategory.code && (
                <div
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                    color: 'gray',
                  }}>
                  <div>
                    <Icons.Code /> {itemCategory.code}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <AnimateHeight
              duration={200}
              height={expanded ? 90 : 0} // see props documentation below
            >
              <div>
                <Grid
                  container
                  style={{
                    marginTop: '12px',
                    paddingBottom: '14px',
                    textAlign: 'center',
                    borderRadius: '15px',
                  }}>
                  <Grid
                    item
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    xs={6}
                    onClick={_onEdit}>
                    <IconButton>
                      <IEdit style={{ color: 'rgb(85, 108, 214)' }} />
                    </IconButton>
                    <div style={{ color: 'rgb(85, 108, 214)' }}>Edit</div>
                  </Grid>
                  <Grid
                    style={{
                      color: 'green',
                      cursor: 'pointer',
                    }}
                    item
                    xs={6}
                    onClick={_onDelete}>
                    <IconButton>
                      <IDelete style={{ color: 'red' }} />
                    </IconButton>
                    <div style={{ color: 'red' }}>Delete</div>
                  </Grid>
                </Grid>
              </div>
            </AnimateHeight>
          </div>
        </div>
      </ListItem>
      <Divider />
    </>
  );
};

ItemCategoryItem.propTypes = {
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  itemCategory: PropTypes.object,
};

ItemCategoryItem.defaultProps = {
  onClick: () => {
    console.log('on click');
  },
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onChange: (data) => console.log(data),
};
export default ItemCategoryItem;
