import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';
import Avatar from 'react-avatar';
import AnimateHeight from 'react-animate-height';

import { App } from '@meronex/app';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IDelete from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import IStore from '@meronex/icons/mdc/MdcWarehouse';
import ICode from '@meronex/icons/fa/FaBarcode';

import { Icons } from '../../../../common/Icons';
import SitesAvatar from '../../../../common/SitesAvatar';

const DragHandle = SortableHandle(() => (
  <div
    style={{
      height: '50px',
      width: '50px',
      textAlign: 'center',
    }}>
    <DragHandleIcon />
  </div>
));

function MaterialItem(props) {
  const { data } = props;

  const [expanded, setExpanded] = React.useState(false);

  const item = data.item;
  const onRemove = data.onRemove;
  const imgSrc = (item.type.image || {}).thumbUrl;

  console.log(item);
  return (
    <div
      style={{
        position: 'relative',
      }}
      onClick={() => {
        setExpanded(!expanded);
      }}>
      <Grid conatiner style={{ display: 'flex', height: '105px' }}>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <div>
            <SitesAvatar
              name={item.type.name}
              size={75}
              image={imgSrc}
              round={15}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              textAlign: 'left',
              fontSize: '15px',
              fontWeight: '400',
            }}>
            <span
              style={{
                position: 'relative',
                left: '5px',
              }}>
              {item.type.name}
            </span>
          </div>
          <div
            style={{
              fontSize: '12px',
              marginTop: '6px',
            }}>
            <div>
              <ICode /> Code: {item.type.code}
            </div>
            <div>
              <IStore /> Store: {item.store.name}
            </div>
            <div>
              <Icons.Price />{' '}
              {!item.productionInfo && (
                <span>
                  Price: {item.type.price || 0} AED/{item.type.unit}
                </span>
              )}
              {item.productionInfo && <span>Price: weighted average</span>}
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div
            style={{
              padding: '0px',
              float: 'right',
              color: 'gray',
              position: 'relative',
              top: '12px',
            }}>
            <DragHandle />
          </div>
        </Grid>
      </Grid>
      <div className={'action-container'} style={{ textAlign: 'center' }}>
        <AnimateHeight
          duration={200}
          height={expanded ? 80 : 0} // see props documentation below
        >
          <div onClick={onRemove}>
            <IconButton style={{ color: 'red' }}>
              <IDelete color={'red'} />
            </IconButton>
            <div style={{ color: 'red' }}>Remove</div>
          </div>
        </AnimateHeight>
      </div>
      <Divider />
      <div
        style={{
          position: 'relative',
          left: '10px',
          bottom: '30px',
          color: 'gray',
        }}>
        <Icons.Items size={14} />
      </div>
    </div>
  );
}
MaterialItem.propTypes = {};

MaterialItem.defaultProps = {};
export default MaterialItem;
