import { App } from '@meronex/app';
import Papa from 'papaparse';

const { server } = App;

export const storesMng = {
  addStore: async (store) => {
    const result = await server.call('addStore', store);
    return result;
  },
  getStores: async () => {
    console.log('getStores');
    const result = await server.call('getStores', {});
    console.dir(result);
    return result;
  },

  getUserSelectedStore: async (stores) => {
    const siteSettings = await App.userSettings.app.get('sites');
    let selectedStore;
    if (Array.isArray(stores) && stores.length > 0) {
      let selectedStoreId = siteSettings.selectedStoreId;
      if (selectedStoreId) {
        selectedStore = stores.find((s) => s._id === selectedStoreId);
        // this could happen when the admin switches domain
      }
      if (!selectedStore) {
        selectedStore = stores[0];
      }
    }
    console.log(selectedStore);
    return selectedStore;
  },

  setUserSelectedStore: async (storeId) => {
    return await App.userSettings.app.set('sites', {
      selectedStoreId: storeId,
    });
  },

  getStoresWithTypeQuantities: async ({ typeId, includeProductsStore }) => {
    const result = await server.call('getStoresWithTypeQuantities', {
      typeId,
      includeProductsStore,
    });
    return result;
  },
  updateStore: async (store) => {
    const result = await server.call('updateStore', store);
    return result;
  },
  archiveStore: async (storeId) => {
    const result = await server.call('archiveStore', { storeId });
    return result;
  },
  restoreStore: async (storeId) => {
    const result = await server.call('restoreStore', { storeId });
    return result;
  },
  getStoreTransactions: async (storeId) => {
    const result = await server.call('getStoreTransactions', { storeId });
    return result;
  },
  getStoreTransactionsCursor: async ({ storeId, limit, skip, filters }) => {
    const result = await server.call('getStoreTransactionsCursor', {
      storeId,
      limit,
      skip,
      filters,
    });
    return result;
  },

  deleteStore: async (storeId) => {
    const result = await server.call('deleteStore', { _id: storeId });
    return result;
  },
  getArchivedStores: async () => {
    const result = await server.call('getArchivedStores', {});
    return result;
  },
  getArchivedStoresCount: async () => {
    const result = await server.call('getArchivedStoresCount', {});
    return result;
  },

  updateStoreItemType: async (itemType) => {
    const result = await server.call('updateStoreItemType', itemType);
    return result;
  },
  // TODO: rename to getStoreItems
  getStoreItemTypes: async (storeId) => {
    const result = await server.call('getStoreItemTypes', { storeId });
    return result;
  },
  deleteStoreItemType: async (_id) => {
    const result = await server.call('deleteStoreItemType', { _id });
    return result;
  },
  deleteStoreItem: async (_id) => {
    const result = await server.call('deleteStoreItem', { _id });
    return result;
  },
  // mark item as removed
  removeStoreItem: async (itemId) => {
    const result = await server.call('removeStoreItem', { itemId });
    return result;
  },
  // restore archived items
  restoreStoreItem: async (itemId) => {
    const result = await server.call('restoreStoreItem', { itemId });
    return result;
  },
  addStoreItem: async (storeId, itemTypeId) => {
    const result = await server.call('addStoreItem', { storeId, itemTypeId });
    return result;
  },

  getStoreItem: async (itemId) => {
    const result = await server.call('getStoreItem', { itemId });
    return result;
  },
  getStoreItems: async (storeId, filters) => {
    console.log('getStoreItems');
    const result = await server.call('getStoreItems', { storeId, filters });
    return result;
  },
  getStoreItemsCursor: async ({ storeId, skip, limit, filters }) => {
    const result = await server.call('getStoreItemsCursor', {
      storeId,
      skip,
      limit,
      filters,
    });
    return result;
  },
  getAllItemTypes: async () => {
    console.log('getAllItemTypes');
    const result = await server.call('getAllItemTypes', {});
    return result;
  },
  getAllItemTypesCursor: async ({ skip, limit, filters }) => {
    console.log('getAllItemTypesCursor');
    const result = await server.call('getAllItemTypesCursor', {
      skip,
      limit,
      filters,
    });
    return result;
  },
  getStoreArchives: async (storeId) => {
    const result = await server.call('getArchivedStoreItems', { storeId });
    return result;
  },
  archiveStoreItem: async (storeItemId) => {
    const result = await server.call('archiveStoreItem', { storeItemId });
    return result;
  },
  getArchivedStoreItemsCount: async (storeId) => {
    const result = await server.call('getArchivedStoreItemsCount', { storeId });
    return result;
  },
  getStoreItemProducts: async (storeItemId) => {
    const result = await server.call('getStoreItemProducts', {
      storeItemId,
    });
    return result;
  },
  getItemTransactions: async (itemId) => {
    console.log('get transactions');
    const result = await server.call('getItemTransactions', {
      itemId,
    });
    return result;
  },
  getItemTransactionsCursor: async (itemId, limit, skip) => {
    const result = await server.call('getItemTransactionsCursor', {
      itemId,
      limit,
      skip,
    });
    return result;
  },
  reverseTransaction: async (targetTxId) => {
    const result = await server.call('reverseTransaction', {
      targetTxId,
    });
    return result;
  },
  exportToCsv: (storeItems) => {
    console.log('export to csv');
    console.log(storeItems);
    let csv;
    if (!Array.isArray(storeItems)) {
      throw Error('store items is null');
    }
    csv = Papa.unparse(
      storeItems.map((i) => {
        return {
          name: i.type.name,
          code: i.type.code,
          quantity: i.quantity,
          unit: i.type.unit,
        };
      })
    );
    return csv;
  },
  storeItem: {
    actions: {
      inStoreItem: async ({
        storeItemId,
        amount,
        note,
        adjustedUnitRate,
        groupId,
      }) => {
        const result = await server.call('inStoreItem', {
          storeItemId,
          amount,
          note,
          groupId,
          adjustedUnitRate,
        });
        return result;
      },
      outStoreItem: async ({ storeItemId, amount, note, groupId }) => {
        const result = await server.call('outStoreItem', {
          storeItemId,
          amount,
          note,
          groupId,
        });
        return result;
      },
      modifyStoreItem: async ({ storeItemId, amount, note, groupId }) => {
        const result = await server.call('modifyStoreItem', {
          storeItemId,
          amount,
          note,
          groupId,
        });
        return result;
      },
      updateStoreItemAdjustedRate: async ({
        storeItemId,
        adjustedRateValue,
      }) => {
        const result = await server.call('updateStoreItemAdjustedRate', {
          storeItemId,
          adjustedRateValue,
        });
        return result;
      },
      modifyStoreItemLocation: async ({ storeItemId, location }) => {
        const result = await server.call('modifyItemLocation', {
          storeItemId,
          location,
        });
        return result;
      },
      transferStoreItem: async ({
        fromStoreItemId,
        toStoreId,
        amount,
        note,
        groupId,
      }) => {
        const result = await server.call('transferStoreItem', {
          fromStoreItemId,
          toStoreId,
          amount,
          note,
          groupId,
        });
        return result;
      },
    },
  },
};

export default storesMng;
