class DetectScroll {
  constructor(cb = () => {}, delay = 800, e = window) {
    this.e = e;
    this.cb = cb;
    this.delay = delay;
    this.detectScroll();
  }
  detectScroll = () => {
    const e = this.e;

    e.addEventListener('scroll', this.scrollListener);
  };
  scrollListener = () => {
    const e = this.e;
    const cb = this.cb;

    e.clearTimeout(e.isScrollingTimer);
    if (!e.isScrolling) {
      e.isScrolling = true;
      cb(e.isScrolling);
    }
    e.isScrollingTimer = setTimeout(() => {
      e.isScrolling = false;
      cb(e.isScrolling);
    }, this.delay);
  };
  destroy = () => {
    this.e.removeEventListener('scroll', this.scrollListener);
  };
}

export default DetectScroll;
