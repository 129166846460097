import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineSharp';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IPeople from '@meronex/icons/md/MdPeopleOutline';

import { issuesMng } from '../api/issuesMng';
import PropTypes from 'prop-types';

export default function AssignedSelect(props) {
  const { onChange } = props;

  const [val, setVal] = useState([]);

  // result from the server call
  const [assignedOptions, setAssignedOptions] = React.useState();

  const fetchAssignedOptions = async () => {
    const result = await issuesMng.getAssignedOptions();
    setAssignedOptions(result);
  };

  React.useEffect(() => {
    fetchAssignedOptions();
  }, []);

  React.useEffect(() => {
    if (onChange) {
      onChange(val);
    }
  }, [val]);
  const getOptions = () => {
    if (!Array.isArray(assignedOptions)) {
      return [];
    }
    return assignedOptions;
  };

  const valHtml = val.map((option, index) => {
    // This is to handle new options added by the user (allowed by freeSolo prop).
    const label = option.label || option;
    return (
      <Chip
        style={{
          margin: '5px',
        }}
        key={label}
        label={
          <span>
            {option.type === 'team' && (
              <span
                style={{
                  position: 'relative',
                  top: '4px',
                }}>
                <IPeople size={20} />
              </span>
            )}
            <span
              style={{
                marginLeft: '5px',
              }}>
              {label}
            </span>
          </span>
        }
        deleteIcon={<RemoveIcon />}
        onDelete={() => {
          setVal(val.filter((entry) => entry !== option));
        }}
      />
    );
  });

  return (
    <div>
      <Autocomplete
        multiple
        id="assigned-to"
        filterSelectedOptions
        options={getOptions()}
        onChange={(e, newValue) => setVal(newValue)}
        getOptionLabel={(option) => option.label}
        renderOption={(v) => {
          return (
            <div>
              {v.type === 'team' && (
                <span
                  style={{
                    position: 'relative',
                    top: '4px',
                  }}>
                  <IPeople size={20} />
                </span>
              )}
              <span
                style={{
                  marginLeft: '5px',
                }}>
                {v.label}
              </span>
            </div>
          );
        }}
        value={val}
        renderTags={() => {}}
        InputLabelProps={{
          shrink: true,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={'Assigned To'}
            margin="normal"
            placeholder={!assignedOptions ? 'loading' : ''}
            disabled={!assignedOptions}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        )}
      />
      <div className="selectedTags">{valHtml}</div>
    </div>
  );
}

AssignedSelect.propTypes = {
  onChange: PropTypes.func,
};

AssignedSelect.defaultProps = {
  onChange: (v) => console.log(v),
};
