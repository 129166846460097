import React from 'react';
import PropTypes from 'prop-types';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';

import List from '@material-ui/core/List';

import ItemCategoryItem from './ItemCategoryItem';
import { itemCategoriesMng } from '../api/itemCategoriesMng';

function ItemCategoriesList(props) {
  const { itemCategories, onClick, onEdit, onDelete } = props;

  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const _onDelete = (itemCategory) => {
    setOpenDeleteAlert(true);
    setSelectedItem(itemCategory);
  };

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    await itemCategoriesMng.deleteItemCategory(selectedItem);
    toast.success('Item category successfully deleted');
    setDeleting(false);
    onDelete();
  };

  const renderItemCategories = () => {
    if (!Array.isArray(itemCategories)) {
      return <Loading />;
    } else if (itemCategories.length === 0) {
      return <NoData type={'warn'} />;
    }

    return itemCategories.map((itemCategory) => {
      return (
        <ItemCategoryItem
          itemCategory={itemCategory}
          onEdit={onEdit}
          onDelete={_onDelete}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <>
      <List>{renderItemCategories()}</List>
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
ItemCategoriesList.propTypes = {
  itemCategories: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

ItemCategoriesList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default ItemCategoriesList;
