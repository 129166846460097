import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import { CloseBtn, toast } from '@meronex/components';

import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import StoreItemDetails from './StoreItemDetails';

import { storesMng } from '../../api/storesMng';
import { can, p } from '../../../common/roles';

function StoreItem(props) {
  let {
    item,

    onClick,
    onRemoveStoreItem,
    onRestoreStoreItem,
    showItemDetails,
    last,
    itemProducts,
    showBatches,
  } = props;

  //TODO: refactor to remove it
  const permissions = {
    in: can(p.stores.itemIn),
    out: can(p.stores.itemOut),
    transfer: can(p.stores.transferItem),
    auditItem: can(p.stores.auditStore),
    restore: can(p.stores.itemRestore),
    delete: can(p.stores.itemDelete),
    modify: can(p.stores.modifyItemAmount),
    archive: can(p.stores.itemArchive),
    auditStore: can(p.stores.auditItem),
    CRUDItemTypes: can(p.stores.CRUDItemTypes),
    viewPrices: can(p.stores.viewStoresPrices),
  };

  const [openActions, setOpenActions] = React.useState();

  const _onClick = () => {
    setOpenActions(!openActions);
    onClick();
  };

  return (
    <>
      <ListItem
        onClick={() => {
          _onClick();
        }}
        style={{
          display: 'inline-block',
          paddingBottom: '10px',
          cursor: 'pointer',
          minHeight: '120px',
        }}>
        <StoreItemDetails
          item={item}
          permissions={permissions}
          prodcuts={itemProducts}
          showTransaction={false}
          showStore={false}
          showUpdated
          showDetails={showItemDetails}
          showBatches={showBatches}
        />
      </ListItem>
      {!last && (
        <Divider
          style={{
            marginBottom: '0px',
          }}
        />
      )}
    </>
  );
}
StoreItem.propTypes = {
  item: PropTypes.object,
  itemProducts: PropTypes.array,
  onItemIn: PropTypes.func,
  onItemOut: PropTypes.func,
  onItemAudit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  onRemoveStoreItem: PropTypes.func,
  onRestoreStoreItem: PropTypes.func,
  expanded: PropTypes.bool,
  last: PropTypes.bool,
  permissions: PropTypes.object,
};

StoreItem.defaultProps = {
  onDelete: () => {},
  onClick: () => {},
  onItemRestore: () => {},
  onItemRemove: () => {},
  onItemTransfer: () => {},
  onRemoveStoreItem: () => {},
  onRestoreStoreItem: () => {},
};
export default StoreItem;
