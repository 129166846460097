import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'react-shimmer';
import ReactImageCloseup from 'react-image-closeup';

import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SpeedDial from '@material-ui/lab/SpeedDial/SpeedDial';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import { useTheme } from '@material-ui/core';

import { App } from '@meronex/app';
import { CloseBtn } from '@meronex/components';
import { noImage } from '@meronex/assets';
import MdPeopleOutline from '@meronex/icons/md/MdPeopleOutline';
import MdMenu from '@meronex/icons/md/MdMenu';
import FiShare2 from '@meronex/icons/fi/FiShare2';
import FiArrowUpCircle from '@meronex/icons/fi/FiArrowUpCircle';
import FaRegEdit from '@meronex/icons/fa/FaRegEdit';

import AssignDialog from './common/AssignDialog';
import OpenedBy from './common/OpenedBy';
import Comments from './common/Comments';
import logsUtil from './common/logsUtils';
import CommentBox from './common/CommentBox';
import LogDetails from './logDetails/LogDetails';

import { logMng } from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  backdrop: {
    zIndex: 0,
  },
}));

export default function CardDialog(props) {
  const { open, onClose, card, updateLogs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [openImgModal, setImgModal] = React.useState();
  const [openAssignDialog, setOpenAssignDialog] = React.useState();
  const [drawerToggle, setDrawerToggle] = React.useState(false);
  const [comments, setComments] = React.useState([]);

  async function fetchSharedComments() {
    if (card) {
      const comments = await logMng.fetchSharedComments(card._id);
      setComments(comments);
    }
  }

  useEffect(() => {
    fetchSharedComments();
  }, [card]);

  const drawerIconSize = 20;

  if (!card) {
    return null;
  }
  if (open) {
    logMng.markAsOpened(card._id);
  }

  const isObservationOrIncident = () =>
    card.logType === 'observation' || card.logType === 'incident';

  const textContent =
    card.logDetails.description ||
    card.logDetails.feedback ||
    card.logDetails.moveOutReason;

  const logThumb = logsUtil.getCardImage(card, true);
  const logImg = logsUtil.getCardImage(card);

  const onAddComment = async () => {
    await logMng.onAddComment(card._id);
    await fetchSharedComments();
    if (typeof updateLogs === 'function') {
      updateLogs();
    }
  };

  const onRemoveComment = async () => {
    await logMng.onRemoveComment(card._id);
    await fetchSharedComments();
    if (typeof updateLogs === 'function') {
      updateLogs();
    }
  };

  return (
    <Dialog
      fullScreen={App.utils.isMobile()}
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        {(card.logType[0] || '').toUpperCase() + (card.logType || '').substr(1)}
        <CloseBtn
          onClick={() => {
            onClose();
            setComments([]);
          }}
        />
      </DialogTitle>
      <DialogContent style={{ padding: '0px' }}>
        <div
          style={{
            overflow: 'scroll',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}>
          {isObservationOrIncident() && (
            <div
              onClick={() => {
                if (logImg) {
                  setImgModal(true);
                }
              }}
              style={{
                textAlign: 'center',
              }}>
              <Image
                src={logThumb || noImage}
                fallback={
                  <Skeleton variant="rect" width={'100%'} height={210} />
                }
                style={{
                  width: logThumb ? 'inherit' : '100%',
                  borderRadius: '5px',
                  height: '210px',
                }}
              />
            </div>
          )}
          {textContent && (
            <Paper component="form">
              <div
                style={{
                  backgroundColor: theme.palette.background.default,
                  textAlign: 'center',
                  fontSize: '16px',
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  wordBreak: 'break-word',
                }}>
                {textContent}
              </div>
            </Paper>
          )}
          <LogDetails
            log={card}
            style={{
              color: theme.palette.text.primary,
              marginTop: '10px',
              marginBottom: '10px',
            }}
            showExtraSurveyInfo={true}
          />
          <OpenedBy card={card} />
          <div style={{ marginBottom: '65px' }}>
            <div
              style={{
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
              }}>
              Comments:
            </div>
            <CommentBox card={card} onAddComment={() => onAddComment()} />
            <div style={{ marginTop: '-15px' }}>
              <Comments
                comments={comments}
                onEditComment={() => fetchSharedComments()}
                onRemoveComment={() => onRemoveComment()}
              />
            </div>
          </div>
        </div>

        <BottomNavigation
          value={'1'}
          style={{
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0)',
          }}
          onChange={(event, newValue) => {
            // setValue(newValue);
          }}
          showLabels>
          <div>
            <SpeedDial
              className={classes.btnRoot}
              style={{
                transition: 'none',
                position: 'relative',
                top: '-40px',
              }}
              ariaLabel="SpeedDial openIcon example"
              onClick={() => {
                setDrawerToggle(true);
              }}
              icon={<MdMenu size={25} />}
            />
            } onClose=
            {() => {}}
            onOpen=
            {() => {}}
            open=
            {false}
            />
          </div>
        </BottomNavigation>
      </DialogContent>
      <Drawer
        anchor="bottom"
        open={drawerToggle}
        onClose={() => setDrawerToggle(false)}>
        <div
          className={classes.fullList}
          role="presentation"
          onClick={() => setDrawerToggle(false)}
          onKeyDown={() => setDrawerToggle(false)}>
          <List>
            <ListItem button key={'edit'}>
              <ListItemIcon>
                <FaRegEdit size={drawerIconSize} />
              </ListItemIcon>
              <ListItemText primary={'Edit'} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key={'share'}>
              <ListItemIcon>
                <FiShare2 size={drawerIconSize} />
              </ListItemIcon>
              <ListItemText primary={'Share'} />
            </ListItem>
            <ListItem button key={'upvote'}>
              <ListItemIcon>
                <FiArrowUpCircle size={drawerIconSize} />
              </ListItemIcon>
              <ListItemText primary={'Up Vote'} />
            </ListItem>
            <ListItem
              button
              key={'assign'}
              onClick={() => {
                setOpenAssignDialog(true);
              }}>
              <ListItemIcon>
                <MdPeopleOutline size={drawerIconSize} />
              </ListItemIcon>
              <ListItemText primary={'Assign To'} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key={'close'}>
              <div style={{ textAlign: 'center', width: '100%' }}>
                <ListItemText
                  primary={'Close'}
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </div>
            </ListItem>
          </List>
        </div>
      </Drawer>
      <AssignDialog
        open={openAssignDialog}
        card={card}
        onClose={() => {
          setOpenAssignDialog(false);
        }}
      />
      {openImgModal ? (
        <ReactImageCloseup
          closeModalFunc={() => {
            setImgModal(false);
          }}
          imageSrc={logImg}
        />
      ) : null}
    </Dialog>
  );
}

CardDialog.propTypes = {
  open: PropTypes.bool,
  card: PropTypes.object,
  updateLogs: PropTypes.func,
  onClose: PropTypes.func,
};
