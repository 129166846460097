import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { App } from '@meronex/app';

import { helpers } from '../common/helpers';
import Quantity from '../common/Quantity';
import { Icons } from '../common/Icons';

function BatchSelect(props) {
  const [value, setValue] = React.useState('');

  const { item, onChange, amount, transactionType } = props;

  React.useEffect(() => {
    if (value) {
      onChange(value.item);
    } else {
      onChange(null);
    }
  }, [value]);

  if (!item || !Array.isArray(item.itemGroups)) {
    return null;
  }

  if (!helpers.batch.isBatchedItem(item)) {
    return null;
  }
  const getSelectItems = () => {
    const items = helpers.batch
      .sortBatchesByExpiry(item.itemGroups)
      .map((groupItem, index) => {
        // handle legacy data
        if (!groupItem.name) {
          groupItem.name = 'Not Available (old data)';
        }
        return {
          item: groupItem,
          index: index,
        };
      });
    return items;
  };

  const renderGroupItem = (groupItem) => {
    if (!groupItem) {
      return <span />;
    }
    return (
      <div
        value={groupItem._id}
        key={groupItem._id}
        style={{
          position: 'relative',
          width: '100%',
          minHeight: '35px',
        }}>
        <span
          style={{
            position: 'absolute',
            right: '30px',
            top: '0px',
            fontSize: '12px',
            color: 'gray',
            width: '80px',
            whiteSpace: 'pre-line',
            textAlign: 'right',
          }}>
          <div>
            <Quantity
              quantity={groupItem.quantity}
              unitLabel={item.type.unit}
              quantitySize={'16px'}
              unitLabelStyle={{
                position: 'relative',
                top: '-6px',
                float: 'right',
              }}
              enableChangeDirAnimation={false}
            />
          </div>
        </span>
        <span>{groupItem.name}</span>
        {groupItem.expiration && (
          <span style={{ width: '250px', whiteSpace: 'normal' }}>
            <div style={{ fontSize: '12px', marginTop: '2px' }}>
              {helpers.computeTimeStr({
                date: groupItem.expiration.expirationDate,
                unit: groupItem.expiration.unit,
              })}{' '}
              - Expires{' '}
              {App.utils.time.timeAgo(groupItem.expiration.expirationDate)}
            </div>
          </span>
        )}
      </div>
    );
  };

  const getQuantity = () => {
    let _quantity;
    const _amount = Number.parseFloat(amount);
    if (
      transactionType === helpers.TRANSACTION_TYPES.out ||
      transactionType === helpers.TRANSACTION_TYPES.transferOut
    ) {
      console.log('transaction out');
      _quantity = value.item.quantity - _amount;
    } else if (transactionType === helpers.TRANSACTION_TYPES.in) {
      console.log('transaction in');
      _quantity = value.item.quantity + _amount;
    } else if (transactionType === helpers.TRANSACTION_TYPES.modify) {
      console.log('modify');
      _quantity = !Number.isNaN(_amount) ? _amount : value.item.quantity;
    }

    return _quantity;
  };
  return (
    <Autocomplete
      options={getSelectItems()}
      fullWidth
      getOptionLabel={(o) => {
        if (!o || !o.item) {
          return '';
        }
        return o.item.name;
      }}
      value={value}
      renderInput={(params) => {
        let quantity;
        if (value && value.item) {
          quantity = (
            <div
              style={{
                position: 'absolute',
                top: '12px',
                right: '65px',
              }}>
              <Quantity
                quantity={getQuantity()}
                unitLabel={item.type.unit}
                quantitySize={'18px'}
                unitLabelStyle={{
                  position: 'relative',
                  top: '-2px',
                  float: 'right',
                }}
                enableChangeDirAnimation={false}
              />
            </div>
          );
        }
        const endAdornment = (
          <>
            {quantity}
            {params.InputProps.endAdornment}
          </>
        );
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment,
            }}
            label={
              <span>
                <Icons.Batch /> Batch Group
              </span>
            }
            variant="outlined"
          />
        );
      }}
      onChange={(e, v) => {
        console.log(v);
        setValue(v);
      }}
      renderOption={(o) => {
        const groupItem = o.item;
        return renderGroupItem(groupItem);
      }}
    />
  );
}
BatchSelect.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  amount: PropTypes.number,
  modify: PropTypes.bool,
  transactionType: PropTypes.string,
};

BatchSelect.defaultProps = {
  onChange: (v) => console.log(v),
  transactionType: helpers.TRANSACTION_TYPES.in,
};
export default BatchSelect;
