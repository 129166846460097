import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import IEdit from '@meronex/icons/ai/AiTwotoneEdit';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IAmount from '@meronex/icons/fa/FaWeight';
import INote from '@meronex/icons/fd/FdClipboardNotes';
import ICode from '@meronex/icons/fa/FaBarcode';
import IWarn from '@meronex/icons/ti/TiWarning';

import { toast } from 'react-toastify';

import { storesMng } from '../../../api/storesMng';
import { Icons } from '../../../../common/Icons';
import TopBar from '../../../../views/components/TopHeader';
import Quantity from '../../../../common/Quantity';
import SitesAvatar from '../../../../common/SitesAvatar';
import BatchSelect from '../../../../common/BatchSelect';
import { helpers } from '../../../../common/helpers';
import { playSound } from '../../../../common/helpers';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ModifyDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, item, onModifyStoreItem } = props;
  const [amount, setAmount] = React.useState('');
  const [group, setGroup] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [noteFocus, setNoteFocus] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [error, setError] = React.useState('');
  const [isValidValue, setIsValidValue] = React.useState(true);

  const _onClose = () => {
    setLoading(false);
    setAmount('');
    onClose();
  };

  React.useEffect(() => {
    isValid();
  }, [amount]);

  const isGroupByBatch = () => {
    return (
      Array.isArray(item.itemGroups) &&
      item.itemGroups.length > 0 &&
      item.productionInfo &&
      item.productionInfo.product &&
      item.productionInfo.product.groupByBatch
    );
  };
  const isValid = () => {
    if (!amount || isNaN(amount)) {
      setIsValidValue(false);
      setError('');
    } else if (!item.store.allowNegatives && getQuantity() < 0) {
      setIsValidValue(false);
      setError(`${item.store.name} does not allow negative values`);
    } else if (isGroupByBatch() && !group) {
      setIsValidValue(false);
      setError('Item group is required');
    } else {
      setIsValidValue(true);
      setError('');
    }
  };

  const modifyAmount = async () => {
    setLoading(true);
    try {
      const result = await storesMng.storeItem.actions.modifyStoreItem({
        storeItemId: item._id,
        amount: parseFloat(amount || 0),
        groupId: group ? group._id : null,
        note,
      });
      toast.success('Store item amount modified', {
        autoClose: 900,
      });
      if (onModifyStoreItem) {
        onModifyStoreItem();
      }
      playSound('in');
    } catch (e) {
      console.error(e);
    } finally {
      _onClose();
      setLoading(false);
    }
  };

  // get the quantity to be displayed
  const getQuantity = () => {
    const _amount = parseFloat(amount);
    let _quantity = parseFloat(item.quantity || 0);

    if (!Number.isNaN(_amount)) {
      if (isGroupByBatch()) {
        let sum;
        item.itemGroups.forEach((i) => {
          if (!group || i._id !== group._id) {
            sum += parseFloat(i.quantity);
          } else if (!Number.isNaN(_amount)) {
            sum += _amount;
          }
        });
        _quantity = sum;
      } else {
        _quantity = _amount;
      }
    }
    return _quantity;
  };

  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopBar
          icon={
            <div>
              <IEdit />
              <Icons.Items size={18} />
            </div>
          }
          title={'Modify amount'}
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent>
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          <SitesAvatar
            name={item.type.name}
            image={item.type.image}
            round={10}
            size={100}
          />
          <div
            style={{
              marginTop: '18px',
              fontWeight: '400',
              fontSize: '16px',
              marginBottom: '-12px',
            }}>
            <span
              style={{
                position: 'relative',
                top: '2px',
                left: '-8px',
              }}>
              <Icons.Items />
            </span>
            {item.type.name}
            <div
              style={{
                marginTop: '10px',
                color: 'gray',
                fontSize: '12px',
              }}>
              <span
                style={{
                  position: 'relative',
                  marginRight: '5px',
                  top: '2px',
                }}>
                <ICode />
              </span>
              {item.type.code}
            </div>
          </div>

          <div
            style={{
              marginTop: '20px',
            }}>
            <Quantity
              quantity={getQuantity()}
              style={{
                textAlign: 'center',
                float: 'none',
                width: '100%',
              }}
              unitLabel={item.type.unit}
              unitLabelStyle={{
                float: 'none',
                display: 'block',
              }}
            />
          </div>
          <div
            style={{
              marginTop: '14px',
            }}>
            <BatchSelect
              item={item}
              amount={amount}
              transactionType={helpers.TRANSACTION_TYPES.modify}
              onChange={(group) => {
                setAmount('');
                setGroup(group);
              }}
            />
          </div>
          <div
            style={{
              textAlign: 'left',
              position: 'relative',
              top: '14px',
            }}>
            <TextField
              fullWidth
              label={
                <div>
                  <div
                    style={{
                      display: 'inline-block',
                      position: 'relative',
                    }}>
                    <IAmount size={18} />
                  </div>
                  &nbsp;{' '}
                  <span style={{ position: 'relative', top: '-2px' }}>
                    Quantity
                  </span>
                </div>
              }
              style={{
                textAlign: 'center',
                marginBottom: '5px',
              }}
              type={'number'}
              variant={'outlined'}
              value={amount}
              onChange={(v) => {
                setAmount(v.target.value);
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="start">
                    <span
                      style={{
                        fontSize: '12px',
                        whiteSpace: 'normal',
                      }}>
                      {item.type.unit}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              position: 'relative',
              top: '22px',
            }}>
            <TextField
              label={
                <span>
                  <INote size={22} />{' '}
                  <span style={{ position: 'relative', top: '-5px' }}>
                    Note
                  </span>
                </span>
              }
              style={{ textAlign: 'left' }}
              multiline
              maxRows={4}
              onFocus={() => {
                setNoteFocus(true);
              }}
              onBlur={() => {
                setNoteFocus(false);
              }}
              fullWidth
              variant={'outlined'}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          {error && (
            <div
              style={{
                marginBottom: '-20px',
              }}>
              <span style={{ color: 'red' }}>
                <IWarn /> {error}
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            position: 'relative',
            textAlign: 'center',
            width: '100%',
            marginTop: '40px',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || !isValidValue}
            onEnter={() => {
              if (!noteFocus) {
                modifyAmount();
              }
            }}
            onClick={() => {
              modifyAmount();
            }}
            label={
              <span>
                <span
                  style={{
                    marginRight: '10px',
                    top: '4px',
                    position: 'relative',
                  }}>
                  <IEdit />
                </span>
                Update
              </span>
            }
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

ModifyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  item: PropTypes.object,
  onChange: PropTypes.func,
};

export default ModifyDialog;
