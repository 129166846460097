import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { AlertDialog, NoData, toast, Loading } from '@meronex/components';

import { Icons } from '../../../../common/Icons';
import TopBar from '../../../../views/components/TopHeader';

import WorkOrderDraft from './WorkOrderDraft';
import CreateWorkOrderDialog from '../createWorkOrder/CreateWorkOrderDialog';

import { workOrdersMng } from '../../../api/WorkOrdersMng';
import { factoriesMng } from '../../../api/FactoriesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AllDraftsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, factory, onWorkOrderAdded, onDraftDeleted } = props;

  const [filters, setFilters] = React.useState();
  const [drafts, setDrafts] = React.useState();
  const [selectedDraft, setSelectedDraft] = React.useState();
  const [openCreateWODialog, setOpenCreateWODialog] = React.useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const deleteDraft = async () => {
    console.log('delete draft');
    setDeleting(true);
    setOpenDeleteAlert(false);
    const result = await workOrdersMng.deleteWorkOrderDraft({
      workOrderDraft: selectedDraft,
    });
    console.log(result);
    setDeleting(false);
    if (onDraftDeleted) {
      onDraftDeleted();
    }
    toast.success('Draft successfully deleted');
    getFactoryDrafts();
  };

  const _onClose = () => {
    onClose();
  };

  const _onResume = (draft) => {
    console.log('on resume');
    console.log(draft);
    setSelectedDraft(draft);
    setOpenCreateWODialog(true);
    getFactoryDrafts();
  };
  const _onDelete = (draft) => {
    console.log('on delete');
    console.log(draft);
    setSelectedDraft(draft);
    setOpenDeleteAlert(true);
  };
  const getFactoryDrafts = async (_filters = filters) => {
    const drafts = await factoriesMng.getFactoryDrafts({
      factoryId: factory._id,
      filters: _filters,
    });
    console.log(drafts);
    setDrafts(drafts);
  };

  React.useEffect(() => {
    getFactoryDrafts();
  }, []);

  React.useEffect(() => {
    getFactoryDrafts();
  }, [filters]);

  const renderDrafts = () => {
    if (!Array.isArray(drafts)) {
      return <Loading />;
    }
    if (drafts.length === 0) {
      return <NoData type={'warn'} />;
    }
    return drafts.map((d, index) => {
      return (
        <WorkOrderDraft
          workOrder={d}
          index={index}
          onResume={() => {
            _onResume(d);
          }}
          onDelete={() => {
            _onDelete(d);
          }}
        />
      );
    });
  };
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <TopBar
          icon={<Icons.Drafts />}
          title={'All Drafts'}
          onClose={_onClose}
          filterProps={{
            enableDateRange: true,
            expandable: true,
            filterValue: filters,
            filterOnApply: true,
            onReset: () => {
              console.log('on reset');
              setFilters(null);
            },
            onApply: (_filters) => {
              console.log('on apply');
              console.log(_filters);
              setFilters(_filters);
            },
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <div
          style={{
            paddingLeft: '5px',
            paddingRight: '5px',
            marginBottom: '60px',
          }}>
          {renderDrafts()}
        </div>
      </DialogContent>
      {selectedDraft && openCreateWODialog && (
        <CreateWorkOrderDialog
          onClose={() => {
            setOpenCreateWODialog(false);
            setSelectedDraft(null);
            getFactoryDrafts();
          }}
          draft={selectedDraft}
          product={selectedDraft.product}
          open={openCreateWODialog}
          onSave={() => {
            console.log('on save');
          }}
          onWorkOrderAdded={() => {
            setOpenCreateWODialog(false);
            setSelectedDraft();
            onWorkOrderAdded();
          }}
          factory={selectedDraft.factory}
        />
      )}
      {openDeleteAlert && (
        <AlertDialog
          open={openDeleteAlert}
          loading={deleting}
          description={'Are you sure you want to delete draft?'}
          onConfirm={() => {
            deleteDraft();
          }}
          onClose={() => {
            setOpenDeleteAlert(false);
          }}
        />
      )}
    </Dialog>
  );
}

AllDraftsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  factory: PropTypes.object,
  onWorkOrderAdded: PropTypes.func,
  onDraftDeleted: PropTypes.func,
};

export default AllDraftsDialog;
