import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { App } from '@meronex/app';

function SitesAvatar(props) {
  const { image, name, round, size } = props;

  let imgUrl;

  if (typeof image === 'string') {
    imgUrl = image;
  } else if (typeof image === 'object') {
    if (image.thumbUrl) {
      imgUrl = image.thumbUrl;
    } else {
      imgUrl = image.imgUrl;
    }
  }

  if (imgUrl) {
    return (
      <img
        style={{
          borderRadius: `${round}px`,
          width: `${size}px`,
          height: `${size}px`,
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
        src={imgUrl}
      />
    );
  }

  let _name = name ? App.utils.sanitize(name) : name;
  return <Avatar name={_name} size={size} round={`${round}px`} src={imgUrl} />;
}

SitesAvatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  size: PropTypes.number,
  round: PropTypes.number,
};
SitesAvatar.defaultProps = {
  size: 45,
  round: 5,
  name: '',
};

export default SitesAvatar;
