import React from 'react';
import PropTypes from 'prop-types';

function Block(props) {
  const { children } = props;
  return <div className={'block'}>{children}</div>;
}
Block.propTypes = {};

Block.defaultProps = {};
export default Block;
