import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ITag from '@meronex/icons/ai/AiOutlineTag';
import Iid from '@meronex/icons/mdc/MdcIdentifier';

import Avatar from 'react-avatar';

function EquipmentInfo(props) {
  const { equipment } = props;
  const [openQrCodeDialog, setOpenQrCodeDialog] = React.useState();

  const iconStyle = {
    position: 'relative',
    top: '4px',
    marginRight: '5px',
  };
  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={4}
          style={{
            textAlign: 'center',
          }}>
          <Avatar
            src={equipment.image && equipment.image.thumbUrl}
            name={equipment.name}
            round={10}
            size={80}
          />
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              fontSize: '16px',
              paddingBottom: '5px',
            }}>
            {equipment.name}
          </div>
          <div
            style={{
              color: 'gray',
            }}>
            <div>
              <span style={iconStyle}>
                <Iid size={18} />
              </span>{' '}
              {equipment.uniqid}
            </div>
            <div>
              <span style={iconStyle}>
                <ITag />
              </span>{' '}
              {equipment.brand}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
EquipmentInfo.propTypes = {};

EquipmentInfo.defaultProps = {};
export default EquipmentInfo;
