import { App } from '@meronex/app';

const { server } = App;

export const equipmentsMng = {
  addEquipment: async (equipment) => {
    const result = await server.call('addEquipment', { equipment });
    return result;
  },
  updateEquipment: async (equipment) => {
    const result = await server.call('updateEquipment', { equipment });
    return result;
  },
  getEquipment: async ({ uniqid }) => {
    console.log('get equipments');
    console.log(uniqid);
    const result = await server.call('getEquipment', {
      uniqid,
    });
    console.log(result);
    return result;
  },
  getAllEquipments: async () => {
    const result = await server.call('getAllEquipments', {});
    return result;
  },
  getFactoryEquipments: async ({ factoryId }) => {
    const result = await server.call('getFactoryEquipments', { factoryId });
    return result;
  },
  deleteEquipment: async (equipment) => {
    console.log(`delete equipment, id: ${equipment._id}`);
    const result = await server.call('deleteEquipment', { _id: equipment._id });
    return result;
  },
  getEquipmentDrafts: async ({ _id }) => {
    const result = await server.call('getEquipmentDrafts', { _id });
    return result;
  },
};
