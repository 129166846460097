import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, Form, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import StoresSelect from '../../../common/StoresSelect';
import StoreItemSelects from './StoreItemSelects';
import { Icons } from '../../../common/Icons';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddStoreItemDialog(props) {
  const { open, onClose, onAdd, addedItems } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [selectedStoreId, setSelectedStoreId] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();

  const addProductItem = () => {
    if (onAdd) {
      onAdd(selectedItem);
    }
  };

  const _onClose = () => {
    setSelectedItem('');
    setSelectedStoreId(null);
    if (onClose) {
      onClose();
    }
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <Icons.Items /> Add Store Item
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <div
        style={{
          overflow: 'auto',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px',
        }}>
        <div style={{ marginTop: '10px' }}>
          <StoresSelect
            storeId={selectedStoreId}
            includeProductsStore={false}
            onChange={(store) => {
              setSelectedStoreId(store ? store._id : '');
            }}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <StoreItemSelects
            excludedIds={(addedItems || [])
              .filter((i) => i.type)
              .map((i) => i.type._id)}
            storeId={selectedStoreId}
            onChange={(v) => {
              setSelectedItem(v);
            }}
          />
        </div>
        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            disabled={!selectedItem}
            label={<span>Add Store Item</span>}
            onClick={() => {
              addProductItem();
            }}
            onEnter={() => {
              addProductItem();
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
AddStoreItemDialog.defaultProps = {
  onAdd: () => {},
  addedItems: [],
};
AddStoreItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  addedItems: PropTypes.array,
};

export default AddStoreItemDialog;
