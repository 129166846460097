import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { App } from '@meronex/app';

import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepContent from '@material-ui/core/StepContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IconButton } from '@material-ui/core';

import IQuantity from '@meronex/icons/mdc/MdcCartArrowDown';
import IWarehouse from '@meronex/icons/mdc/MdcWarehouse';
import { useOnEnterPress, ActionBtn, toast } from '@meronex/components';
import INext from '@meronex/icons/md/MdNavigateNext';
import IBack from '@meronex/icons/md/MdNavigateBefore';
import IReadOnly from '@meronex/icons/bi/BiBookReader';
import { Icons } from '../../../../common/Icons';

import WorkOrderStep from './WorkOrderStep';
import WorkOrderStepLabel from './WorkOrderStepLabel';
import WorkOrderSummaryDialog from './WorkOrderSummary';

import { workOrdersMng } from '../../../api/WorkOrdersMng';
import StoresSelect from '../../../../common/StoresSelect';
import WorkOrderDrafts from '../draftWorkOrder/WorkOrderDrafts';
import { can, p } from '../../../../common/roles';
import Comments from '../../../../common/comments/Comments';
import { workOrderHelpers } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    marginTop: '10px',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  iconStyle: {
    position: 'relative',
    top: '5px',
  },
}));

export default function WorkOrderStepper(props) {
  const classes = useStyles(props);
  const stepper = React.useRef();

  const {
    loading,
    product,
    onChange,
    onNext,
    onBack,
    onSummary,
    onCreate,
    onStart,
    factory,
    draft,
  } = props;

  // don't assume active step to be zero,
  // because it will override the draft value if any,
  // instead keep it null and wait for the draft work order to load
  // and decide at the stepper component if zero needs to be passed
  const [activeStep, setActiveStep] = React.useState(0);
  const [workOrder, setWorkOrder] = React.useState();
  const [storeItems, setStoreItems] = React.useState();
  const [fetchingItems, setFetchingItems] = React.useState(false);
  const [producedQuantityError, setProducedQuantityError] = React.useState(
    false
  );
  const [defaultStoreError, setDefaultStoreError] = React.useState(false);
  const [steps, setSteps] = React.useState();
  const [isValid, setIsValid] = React.useState({
    workOrder: false,
  });
  const [openWorkOrderSummary, setOpenWorkOrderSummary] = React.useState(false);

  const [drafts, setDrafts] = React.useState();

  const startNewDraft = () => {
    saveWorkOrderDraft(initWorkOrder(), true);
    if (onStart) {
      onStart();
    }
  };
  const saveWorkOrderDraft = async (workOrder, setWorkOrderVal = false) => {
    console.log('save draft');
    console.log(workOrder);
    const _workOrder = App.utils.clone(workOrder);
    if (_workOrder && _workOrder.draft && !window.savingDraft) {
      window.savingDraft = true;

      if (!isNaN(activeStep)) {
        _workOrder.draft.activeStep = activeStep;
      }
      _workOrder.draft.isValid = isValid;
      _workOrder.draft.producedQuantityError = producedQuantityError;
      _workOrder.draft.defaultStoreError = defaultStoreError;

      // when starting a new draft
      // the work order need to be set
      // because a new _id will be generated at the server
      // and it has to be stored at the client state
      // so it can be reused.
      // however when updating the draft,
      // send and forget approach is used
      // no need to wait for the server
      if (setWorkOrderVal) {
        const wo = await workOrdersMng.saveWorkOrderDraft(_workOrder);
        setWorkOrder(assignBatchAndExpiry(wo));
        window.savingDraft = false;
        return wo;
      }
      workOrdersMng.saveWorkOrderDraft(_workOrder);
      window.savingDraft = false;
      return null;
    }
  };
  const getWorkOrderDrafts = async () => {
    if (factory && product) {
      const _drafts = await workOrdersMng.getWorkOrderDrafts({
        factoryId: factory._id,
        productId: product._id,
      });
      setDrafts(_drafts);
    }
  };

  React.useEffect(() => {
    if (draft) {
      onDraftResume(draft);
    }
  }, [draft]);
  React.useEffect(() => {
    getWorkOrderDrafts();
  }, [factory, product]);

  React.useEffect(() => {
    console.log(`on active step change ${activeStep}`);
    saveWorkOrderDraft(workOrder);
  }, [activeStep]);

  useOnEnterPress(() => {
    handleNext();
  });

  const getItemsWithQuantities = async (workOrder) => {
    console.log('getItemsWithQuantities');
    console.log(workOrder);
    window.fetchingItems = true;
    const itemsWithQuantity = await workOrdersMng.getProductItemsWithQuantity(
      product._id
    );
    console.log(itemsWithQuantity);
    setStoreItems(itemsWithQuantity);
    if (workOrder) {
      const _workOrder = { ...workOrder };
      if (!Array.isArray(_workOrder.product.items)) {
        _workOrder.product.items = itemsWithQuantity;
      } else {
        console.log('updating work order quantities');
        // updating the existing quantities
        itemsWithQuantity.forEach((i) => {
          const item = _workOrder.product.items.find(
            (item) => item._id === i._id
          );
          if (item) {
            item.quantity = i.quantity;
          }
        });
      }
      setWorkOrder(_workOrder);
      window.fetchingItems = false;

      return _workOrder;
    }
  };

  const assignBatchAndExpiry = (workOrder) => {
    // compute the most recent bach number
    const product = workOrder.product;
    if (product.batchNumbers) {
      workOrder.batchNum = workOrdersMng.helpers.computeBatchNum(
        new Date(),
        workOrder.seqNum
      );
    }
    if (product.expiration) {
      const { value, unit } = product.expiration;
      const productionDate = new Date();
      workOrder.expiration = {
        expirationDate: moment(productionDate)
          .add(moment.duration(value, unit))
          .toDate(),
        unit,
        value,
        productionDate,
      };
    }
    return workOrder;
  };

  const initWorkOrder = () => {
    console.log('init work order');
    const workOrder = {};
    const _product = App.utils.clone(product);
    // items will be fetched again
    // using getItemsWithQuantities
    // when the work order is loaded
    // and onChange is called

    _product.items = null;
    workOrder.product = _product;
    workOrder.draft = {
      progress: 0,
      created: false,
      uniqid: App.utils.randomId(),
    };
    workOrder.status = 'toBeVerified';
    workOrder.state = {
      dataEntry: null,
      qualityVerified: null,
      flagged: null,
    };
    if (_product.enableDefaultStore) {
      workOrder.enableDefaultStore = _product.enableDefaultStore;
      workOrder.destinationStoreId = _product.destinationStoreId;
      workOrder.destinationStore = _product.destinationStore;
    }

    workOrder.factory = factory;
    console.log(workOrder);

    return workOrder;
  };

  const isReadOnly = () => {
    const user = App.getUser();
    if (
      user &&
      workOrder &&
      workOrder.draft &&
      workOrder.draft.createdBy &&
      workOrder.draft.createdBy._id
    ) {
      if (
        workOrder.draft.createdBy._id !== user._id &&
        !can(p.factories.modifyOthersWorkOrder)
      ) {
        return true;
      }
    }
    return false;
  };

  const disabled = isReadOnly();

  const isWorkOrderValid = (workOrder) => {
    const isValid = {
      steps: {},
      workOrder: false,
      progress: 0,
    };
    if (
      !workOrder ||
      !workOrder.product ||
      !Array.isArray(workOrder.product.items)
    ) {
      return isValid;
    }
    // check the items
    let _index = 0;
    workOrder.product.items.forEach((i) => {
      _index += 1;
      isValid.steps[_index] = Boolean(i.completed);
    });

    if (!workOrder.enableDefaultStore) {
      isValid.steps[_index] = Boolean(workOrder.destinationStore);
      _index += 1;
    }
    if (!workOrder.producedQuantity) {
      isValid.steps[_index] = false;
      _index += 1;
    }
    const stepsState = Object.values(isValid.steps);
    if (Array.isArray(stepsState)) {
      let isWorkOrderValid = true;
      let validStepsCount = 0;
      stepsState.forEach((s) => {
        if (s) {
          validStepsCount += 1;
        } else {
          isWorkOrderValid = false;
        }
      });
      isValid.workOrder = isWorkOrderValid;
      isValid.progress = Math.round(
        (validStepsCount / stepsState.length) * 100
      );
    }
    setIsValid(isValid);

    return isValid;
  };

  const getStepsCount = () => {
    let count = 0;

    const workItemsCount = workOrder.product.items.length - 1;
    count += workItemsCount;

    if (!workOrder.enableDefaultStore) {
      count += 1;
    }
    // quantity
    count += 1;
    return count;
  };

  const onWorkOrderChange = async () => {
    if (workOrder) {
      console.log('work order updated');
      console.log(workOrder);
      if (!window.fetchingItems && !workOrder.product.items) {
        getItemsWithQuantities(workOrder);
      } else {
        setSteps(getStepsArray());
      }
      const isValid = isWorkOrderValid(workOrder);
      workOrder.draft.progress = isValid.progress;
      if (!disabled) {
        saveWorkOrderDraft(workOrder);
      }
      onChange(workOrder);
    }
  };
  React.useEffect(() => {
    onWorkOrderChange();
  }, [workOrder]);

  const onMaterialChange = (e, item, completed) => {
    if (e) {
      try {
        const itemValue = parseFloat(e.target.value);
        const itemName = e.target.name;

        const _workOrder = App.utils.clone(workOrder);
        const updatedItem = _workOrder.product.items.find(
          (i) => i._id === itemName
        );
        updatedItem.value = itemValue;
        updatedItem.completed = completed;
        setWorkOrder(_workOrder);
        if (item) {
          item.quantityUsed = itemValue;
        }
      } catch (e) {
        console.error(e);
      }
      //  process step
    }
  };

  const onProcessStepChange = (item) => {
    const _workOrder = App.utils.clone(workOrder);
    const itemIndex = _workOrder.product.items.findIndex(
      (i) => i._id === item._id
    );
    _workOrder.product.items[itemIndex] = item;
    setWorkOrder(_workOrder);
  };

  const onProcessStepStart = (item) => {
    console.log('on process step start');
    console.log(item);
    console.log(workOrder.product.items);
    workOrder.product.items.forEach((i) => {
      if (i._id !== item._id && i.timer && i.timer.active) {
        console.log(`turning of ${item._id} timer`);
        i.timer = {};
      }
    });
    // iterate over the items
    // stop any item that is in progress
  };
  const onProcessStepEnd = (item) => {
    console.log('on process step end');
  };
  const handleNext = () => {
    const _stepsCounts = getStepsCount();
    console.log(`current step [${activeStep}/${_stepsCounts}]`);
    if (Array.isArray(steps)) {
      if (activeStep < _stepsCounts) {
        onNext(activeStep + 1);
        setActiveStep(activeStep + 1);
      } else if (activeStep === _stepsCounts) {
        if (!loading && isValid.workOrder) {
          setActiveStep(activeStep + 1);
          viewSummary();
        }
      } else if (activeStep > _stepsCounts) {
        if (!loading && isValid.workOrder) {
          setActiveStep(activeStep + 1);

          onCreate();
        }
      }
    }
  };

  const onDraftResume = async (workOrderDraft) => {
    const draftActiveStep = workOrderDraft.draft.activeStep;
    if (draftActiveStep) {
      setActiveStep(draftActiveStep);
    }
    const updatedQuantityWODraft = await getItemsWithQuantities(workOrderDraft);
    setWorkOrder(updatedQuantityWODraft);
    const draft = updatedQuantityWODraft.draft;
    setDefaultStoreError(draft.defaultStoreError);
    setProducedQuantityError(draft.producedQuantityError);
  };

  const viewSummary = () => {
    console.log('view summary');
    setWorkOrder(workOrder);
    setOpenWorkOrderSummary(true);
    if (onSummary) {
      onSummary();
    }
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      onBack(activeStep - 1);
    }
  };

  // render the steps
  const renderItemSteps = () => {
    if (Array.isArray(workOrder.product.items)) {
      console.log(workOrder.product.items);
      return workOrder.product.items.map((item, index) => {
        const _item = workOrder.product.items[index];
        return (
          <WorkOrderStep
            key={_item._id}
            item={_item}
            factory={factory}
            disabled={disabled}
            activeStep={activeStep || 0}
            completed={_item.completed}
            onStepClick={() => {
              setActiveStep(index);
            }}
            onProcessItemChange={onProcessStepChange}
            onMaterialChange={(e, item, completed) => {
              onMaterialChange(e, item, completed);
            }}
            onStepTimerStart={onProcessStepStart}
            onStepTimerStop={onProcessStepEnd}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      });
    }
  };
  const renderNavigationBtn = (value) => {
    if (!Array.isArray(steps)) {
      return null;
    }
    const _stepsCount = getStepsCount();
    const lastStep = _stepsCount === activeStep;
    if (lastStep || disabled) {
      return null;
    }
    return (
      <div className={classes.actionsContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}>
          <IconButton disabled={activeStep === 0} onClick={handleBack}>
            <IBack size={35} />
          </IconButton>
          <IconButton disabled={lastStep} onClick={handleNext}>
            <INext size={35} color={'#556cd6'} />
          </IconButton>
        </div>
      </div>
    );
  };

  const renderStoreSelectStep = (index) => {
    return (
      <Step key={'productStore'}>
        <WorkOrderStepLabel
          completed={workOrder.destinationStore}
          onClick={() => {
            setActiveStep(index);
          }}
          error={defaultStoreError}
          stepIcon={<IWarehouse size={25} />}
          text={'PRODUCT STORE'}
          {...props}
        />
        <StepContent {...props}>
          <div style={{ marginTop: '20px' }}>
            <StoresSelect
              variant={'outlined'}
              label={'Select Product Store'}
              charCount={25}
              disabled={disabled}
              typeId={workOrder.product._id}
              error={defaultStoreError}
              storeId={workOrder.destinationStoreId}
              onChange={(store) => {
                const _updatedWO = { ...workOrder };
                if (store) {
                  _updatedWO.destinationStoreId = store._id;
                  _updatedWO.destinationStore = store;
                  setDefaultStoreError(false);
                } else {
                  _updatedWO.destinationStoreId = '';
                  _updatedWO.destinationStore = null;
                  setDefaultStoreError(true);
                }
                setWorkOrder(_updatedWO);
              }}
              placeholder={'Select store'}
            />
          </div>
          {renderNavigationBtn(workOrder.destinationStoreId)}
        </StepContent>
      </Step>
    );
  };
  const renderProducedQuantityStep = (index) => {
    return (
      <Step key={'producedQuantity'}>
        <WorkOrderStepLabel
          onClick={() => {
            setActiveStep(index);
          }}
          completed={workOrder.producedQuantity}
          error={producedQuantityError}
          stepIcon={<IQuantity size={25} />}
          text={'PRODUCED QUANTITY'}
        />
        <StepContent>
          <TextField
            name={'quantity'}
            fullWidth
            autoFocus
            disabled={disabled}
            error={producedQuantityError}
            value={workOrder.producedQuantity}
            onChange={(e) => {
              const _updatedWO = { ...workOrder };
              let value;

              try {
                value = parseFloat(e.target.value);
                setProducedQuantityError(!value);
                _updatedWO.producedQuantity = value;
                setWorkOrder(_updatedWO);
              } catch (e) {
                console.log(e);
                setProducedQuantityError(true);
              }
            }}
            type={'number'}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {workOrder.product.unit}
                </InputAdornment>
              ),
            }}
          />
          {workOrder.product.packaging && (
            <div
              style={{
                width: '100%',
                minHeight: 20,
              }}>
              <div
                style={{
                  float: 'right',
                  color: '#757575',
                }}>
                {workOrderHelpers.getPackagedQuantity(workOrder)}
              </div>
            </div>
          )}
          <div>{renderNavigationBtn(workOrder.producedQuantity)}</div>
        </StepContent>
      </Step>
    );
  };

  const getStepsArray = () => {
    const stepsArray = [];
    const itemSteps = renderItemSteps();
    let index = itemSteps.length;
    itemSteps.forEach((s) => {
      stepsArray.push(s);
    });
    stepsArray.push(renderProducedQuantityStep(index));
    index += 1;
    if (!workOrder.enableDefaultStore) {
      stepsArray.push(renderStoreSelectStep(index));
    }
    return stepsArray;
  };

  const renderContent = () => {
    if (!workOrder && Array.isArray(drafts)) {
      return (
        <div>
          <WorkOrderDrafts
            drafts={drafts}
            onDraftClick={(workOrderDraft) => {
              console.log('on draft click');
              console.log(workOrderDraft);
            }}
            onDraftResume={onDraftResume}
            onDraftDeleted={() => {
              console.log('on draft deleted');
              getWorkOrderDrafts();
            }}
            onCreateNewWorkOrder={() => {
              startNewDraft();
            }}
          />
        </div>
      );
    }

    if (!workOrder || !Array.isArray(workOrder.product.items)) {
      return (
        <div
          style={{
            textAlign: 'center',
            color: 'gray',
            marginTop: '40px',
          }}>
          Loading
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
              marginLeft: '20px',
              marginRight: '20px',
            }}>
            <LinearProgress />
          </div>
        </div>
      );
    }
    return (
      <>
        {disabled && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
            }}>
            (<IReadOnly /> Read Only)
          </div>
        )}
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          ref={stepper}
          nonLinear
          id={'#stepper'}>
          {getStepsArray()}
        </Stepper>

        {workOrder && workOrder.seqNum && (
          <div
            style={{
              marginLeft: '24px',
              marginRight: '24px',
            }}>
            <div
              className={'label'}
              style={{
                fontSize: '18px',
              }}>
              <Icons.Comments /> Comments
            </div>
            <Comments subjectId={workOrder.seqNum.toString()} />
          </div>
        )}
        {!disabled && (
          <div
            style={{
              textAlign: 'center',
              marginBottom: '100px',
            }}>
            <ActionBtn
              disabled={loading || !isValid.workOrder || disabled}
              loading={loading}
              label={'View Summary'}
              style={{
                width: '200px',
              }}
              onClick={() => {
                viewSummary();
              }}></ActionBtn>
          </div>
        )}
      </>
    );
  };
  return (
    <div className={classes.root} s>
      {renderContent()}
      {openWorkOrderSummary && (
        <WorkOrderSummaryDialog
          workOrder={workOrder}
          open={openWorkOrderSummary}
          onCreate={() => {
            console.log('on create work order');
            setOpenWorkOrderSummary(false);
            onCreate(workOrder);
          }}
          onClose={() => {
            setOpenWorkOrderSummary(false);
          }}
        />
      )}
    </div>
  );
}

WorkOrderStepper.propTypes = {
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onSummary: PropTypes.func,
  onCreate: PropTypes.func,
  draft: PropTypes.object,
  disabled: PropTypes.bool,
};
WorkOrderStepper.defaultProps = {
  onNext: () => {
    console.log('on next');
  },
  onBack: () => {
    console.log('on back');
  },
  onSummary: () => {
    console.log('on summary');
  },
};
