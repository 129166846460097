import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InfiniteLoader, List, WindowScroller } from 'react-virtualized';
import GiBackwardTime from '@meronex/icons/cg/CgTimer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { App, AppContext } from '@meronex/app';
import moment from 'moment';
import { withTheme } from '@material-ui/core/styles';
import NoData from '../NoData';
import Loading from '../Loading';

class InfiniteScroll extends Component {
  state = {
    _remoteRowCount: 10,
    items: undefined,
    hasMore: true,
    selectedItemIndex: undefined,
    selectedItem: undefined,
    renderData: undefined,
    fastRender: true,
    isScrolling: false,
    scrollToIndex: 0,
  };
  static propTypes = {
    getListItems: PropTypes.func,
    renderItem: PropTypes.func,
    noRowRenderer: PropTypes.func,
    itemHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    lastItemHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    height: PropTypes.number,
    onScroll: PropTypes.func,
    limit: PropTypes.number,
    listStyle: PropTypes.object,
    scrollRenderer: PropTypes.func,
    remoteRowCount: PropTypes.number,
  };
  static defaultProps = {
    getListItems: ({ skip, limit }) => [],
    renderItem: (data, isScrolling, index) => {},
    itemHeight: 40,
    height: 450,
    limit: 9,
    noRowRenderer: () => {
      return <NoData message={'No Items Available'} />;
    },
  };

  constructor() {
    super();
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    const { limit } = this.props;
    const { items } = this.state;
    window.lastVisitIndexStamp = undefined;
    if (!items) {
      this.loadMoreRows({ startIndex: 0, stopIndex: limit - 1 });
    } else {
      this.refreshList(true);
    }
  };

  updateLastListVisit = () => {
    const { App } = this.context;

    if (App.server) {
      App.server.call('updateLastLogsVisit', {});
    }
    App.fetchUser();
  };

  isEmpty = () => {
    const { items, fetching } = this.state;
    return Array.isArray(items) && items.length === 0 && !fetching;
  };

  timeFullFormat = (timestamp) => {
    return moment(timestamp).format('L - LTS');
  };

  // slice the array from 0 to the start index, this subarray is unchanged
  // so we've to be careful here if the order is changed and item is
  // moved to the top, then we need to reset the items to 0 so the slice
  // will result in an empty array and the fetched items will be added.
  loadMoreRows = async ({ startIndex, stopIndex, refresh = false }) => {
    const { App } = this.context;
    const { getListItems, limit } = this.props;
    let { items, hasMore } = this.state;

    console.log(startIndex);
    console.log(stopIndex);
    if (!App.server.connected) {
      console.warn('Waiting for server to connect,');
    } else {
      let result;
      console.log(`load more ${startIndex} ${stopIndex}`);

      if (hasMore || refresh) {
        const fetchedItems = await getListItems({
          skip: startIndex,
          limit,
        });
        let _items = items;
        if (!_items) {
          _items = [];
        }

        // slice until the start index to maintain
        // the values until the there
        _items = _items.slice(0, startIndex);

        // then append all the fetched values to that index
        if (Array.isArray(fetchedItems) && fetchedItems.length > 0) {
          // Update the underlying storeItems list based on the fetched ranged
          for (
            let i = startIndex;
            i < startIndex + fetchedItems.length;
            i += 1
          ) {
            // Ignore undefined storeItems
            let item = fetchedItems[i - startIndex];
            if (item) {
              _items[i] = item;
            }
          }
        }

        let selectedItem = this.state.selectedItem;
        if (selectedItem) {
          console.log(`updating selected log  ${selectedItem}`);
          selectedItem = _items[this.state.selectedItemIndex];
        }
        if (_items.length > 0) {
          this.setState({
            items: _items,
            _remoteRowCount: _items.length + limit,
            selectedItem,
          });
        } else {
          this.setState({
            hasMore: false,
            items: _items,
            selectedItem,
          });
        }
        return Promise.resolve(_items.slice(startIndex, stopIndex));
      } else {
        console.info('has more is false');
      }
    }
  };

  /**
   * Reset the storeItems list (back to the top) while applying
   * the current filters.
   *
   * If the order is changed, then better clear and scroll to top, because this
   * ensure the subarray before the window is also updated.
   */
  refreshList = (scrollToTop = true, clearItems = false) => {
    const { limit } = this.props;
    const _state = {
      hasMore: true,
    };
    if (scrollToTop) {
      _state.scrollToIndex = 0;
    }
    if (clearItems) {
      _state.items = null;
    }

    this.setState(_state, () => {
      window.lastVisitIndexStamp = undefined;
      this.loadMoreRows({ startIndex: 0, stopIndex: limit, refresh: true });
    });
  };

  /**
   * Refresh the current range index of the storeItems without resetting the list
   * or scrolling.
   */
  refreshItems = () => {
    const { renderData } = this.state;
    if (renderData) {
      this.loadMoreRows({
        startIndex: renderData.startIndex,
        stopIndex: renderData.stopIndex + 1,
      });
    }
  };

  setFastRender = (fastRender) => {
    this.setState({
      fastRender,
    });
  };

  getLastVisitDate = () => {
    const user = App.getUser();

    let lastVisitDate;
    if (
      user &&
      user.settings &&
      user.settings.sites &&
      user.settings.sites.lastLogsVisit
    ) {
      lastVisitDate = user.settings.sites.lastLogsVisit;
    }
    return lastVisitDate || new Date();
  };
  // Indicate of the log card was
  // created before or after last user visit to the feed.
  isSeen = (item) => {
    const user = App.getUser();

    // If the session is the user, then seen is true
    if (item.authorId === user._id) {
      return true;
    }

    const lastVisitDate = this.getLastVisitDate();
    let lastVisit;
    if (lastVisitDate) {
      lastVisit = moment(lastVisitDate);

      const createdAt = moment(item.createdAt);
      const diff = createdAt.diff(lastVisit, 'seconds');
      // If the creation date is less than the last logged in time
      // (so the log was created before the user's last logged in, then
      // then assume the user has seen it)
      // we're given leeway of 5 seconds in case the user switches
      // views fast.
      if (diff < 5) {
        return true;
      }
    }
    return false;
  };

  isLastVisitIndexStamp = (index) => {
    const { items } = this.state;
    if (items) {
      if (typeof window.lastVisitIndexStamp === 'undefined') {
        const isSeen = this.isSeen(this.state.items[index]);

        if (isSeen) {
          window.lastVisitIndexStamp = index;
        }
      }
      return window.lastVisitIndexStamp === index;
    }
    return false;
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {
    let _style = { ...style };
    const { renderItem } = this.props;
    const { items } = this.state;
    let data;
    if (items && items[index]) {
      data = items[index];
    }

    return (
      <div key={key} style={_style}>
        {data && renderItem(data, isScrolling, index)}
      </div>
    );
  };

  isRowLoaded = ({ index }) => {
    const { items } = this.state;
    return !!items[index];
  };

  getLoader = () => {
    const { items } = this.state;
    const firstLoad = !items || items.length === 0;

    return <Loading text={firstLoad ? 'Loading...' : ''} />;
    return (
      <div
        key={'loader'}
        style={{
          marginRight: '-30px',
          marginTop: firstLoad ? '30px' : '10px',
          marginBottom: '125px',
          textAlign: 'center',
        }}>
        {' '}
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={24}
          thickness={4}
        />
        {firstLoad && <div style={{ color: 'gray' }}>Loading...</div>}
      </div>
    );
  };

  renderScroll = (items, startIndex, stopIndex) => {
    const { scrollRenderer } = this.props;

    if (!Array.isArray(items) || items.length === 0) {
      return <span />;
    }
    const startItem = items[startIndex];

    const getContent = () => {
      if (typeof scrollRenderer === 'function') {
        return scrollRenderer(items, startIndex, stopIndex);
      }

      return (
        <div
          style={{
            width: '240px',
            margin: 'auto',
            backgroundColor: 'rgba(25, 25, 25, 0.74)',
            color: 'white',
            borderRadius: '10px',
            padding: '2px',
            fontSize: '15px',
            zIndex: 99999,
          }}>
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              left: '-8px',
              top: '2px',
            }}>
            <GiBackwardTime size={22} color={'white'} />
          </div>
          <div
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '-5px',
            }}>
            {App.utils.time.timeFullFormat(startItem.createdAt)}
          </div>
        </div>
      );
    };
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '25px',
          width: '100%',
          left: '50%',
          textAlign: 'center',
          transform: 'translateX(-50%)',
          zIndex: 99999,
        }}>
        {getContent()}
      </div>
    );
  };
  render() {
    const { items, _remoteRowCount, renderData, scrollToIndex } = this.state;
    const {
      theme,
      listStyle,
      itemHeight,
      height,
      lastItemHeight = itemHeight + 10,
      noRowRenderer,
      onScroll,
      remoteRowCount,
    } = this.props;
    const { App } = this.context;

    const { size, server } = App;

    const loaded = Boolean(items && server.connected && size);

    const _mergedListStyle = Object.assign(
      {
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
      },
      listStyle
    );

    return (
      <div
        style={{
          overflow: 'hidden',
        }}>
        {!loaded && this.getLoader()}
        {loaded && (
          <InfiniteLoader
            ref={this.listRef}
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={remoteRowCount || _remoteRowCount}>
            {({ onRowsRendered, registerChild }) => (
              <>
                <div
                  onScroll={(e) => {
                    window.clearTimeout(window.isScrolling);
                    if (!this.state.isScrolling) {
                      this.setState(
                        {
                          isScrolling: true,
                          scrollToIndex: onRowsRendered.startIndex,
                        },
                        () => {
                          if (typeof onScroll === 'function') {
                            onScroll(this.state.isScrolling);
                          }
                        }
                      );
                    }
                    window.isScrolling = setTimeout(() => {
                      this.setState(
                        {
                          isScrolling: false,
                        },
                        () => {
                          if (typeof onScroll === 'function') {
                            onScroll(this.state.isScrolling);
                          }
                        }
                      );
                    }, 800);
                  }}>
                  <List
                    ref={registerChild}
                    style={_mergedListStyle}
                    height={height}
                    onRowsRendered={(data) => {
                      if (data) {
                        this.setState({
                          renderData: data,
                        });
                        onRowsRendered(data);
                      }
                    }}
                    noRowsRenderer={noRowRenderer}
                    scrollToAlignment={'center'}
                    scrollToIndex={scrollToIndex}
                    rowCount={items.length}
                    rowHeight={({ index }) => {
                      let _itemHeight = itemHeight;
                      if (typeof _itemHeight === 'function') {
                        _itemHeight = _itemHeight(items[index]);
                      }
                      const lastCard = index === items.length - 1;
                      const isLastVisitMark = this.isLastVisitIndexStamp(index);
                      if (lastCard) {
                        let _lastItemHeight = lastItemHeight;
                        if (typeof _lastItemHeight === 'function') {
                          _lastItemHeight = _lastItemHeight(items[index]);
                        }
                        return _lastItemHeight;
                      } else if (isLastVisitMark) {
                        return _itemHeight;
                      }
                      return _itemHeight;
                    }}
                    rowRenderer={this.rowRenderer}
                    width={size.width}
                  />
                  {items &&
                    renderData &&
                    this.state.isScrolling &&
                    this.renderScroll(
                      items || [],
                      renderData.startIndex,
                      renderData.stopIndex
                    )}
                  {/*{renderData && renderData.startIndex > 1 && (*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      position: 'fixed',*/}
                  {/*      bottom: '40px',*/}
                  {/*      width: '100%',*/}
                  {/*      textAlign: 'center',*/}
                  {/*    }}>*/}
                  {/*    <SpeedDial*/}
                  {/*      className={'logs-scroll-top'}*/}
                  {/*      style={{*/}
                  {/*        transition: 'none',*/}
                  {/*        position: 'relative',*/}
                  {/*        top: '-40px',*/}
                  {/*      }}*/}
                  {/*      ariaLabel="SpeedDial openIcon example"*/}
                  {/*      onClick={() => {*/}
                  {/*        window.scrollTo({*/}
                  {/*          top: 0,*/}
                  {/*        });*/}
                  {/*      }}*/}
                  {/*      icon={<BsChevronDoubleUp size={30} />}*/}
                  {/*      onClose={() => {}}*/}
                  {/*      onOpen={() => {}}*/}
                  {/*      open={false}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              </>
            )}
          </InfiniteLoader>
        )}
      </div>
    );
  }
}

export default withTheme(InfiniteScroll);

InfiniteScroll.contextType = AppContext;
