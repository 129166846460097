import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SmallSpinner extends Component {
  state = {};
  static propTypes = {
    color: PropTypes.string,
  };
  static defaultProps = {
    color: 'lightgray',
  };

  render() {
    return (
      <div className="lds-spinner">
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
        <div style={{ backgroundColor: this.props.color }} />
      </div>
    );
  }
}
