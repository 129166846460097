import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import ItemCategoryForm from './ItemCategoryForm';
import { itemCategoriesMng } from '../api/itemCategoriesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditItemCategoryDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onEdit, itemCategory } = props;

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ isValid: true });

  React.useEffect(() => {
    setFormData({ ...itemCategory });
  }, [itemCategory]);

  const _onClose = () => {
    onClose();
  };

  const editItemCategory = async () => {
    console.log('on edit');
    console.log(itemCategory);

    setLoading(true);

    const _updatedItemCategory = Object.assign(itemCategory, formData);
    delete _updatedItemCategory.isValid;

    try {
      await itemCategoriesMng.updateItemCategory(_updatedItemCategory);
      onEdit();
      onClose();
      toast.success('Item category updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  if (!itemCategory || !open) {
    return null;
  }
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Edit Item Category
        <CloseBtn onClick={_onClose} />
      </DialogTitle>
      <DialogContent>
        <ItemCategoryForm
          validateOnInit={true}
          onChange={(values) => {
            console.log(values);
            setFormData({ ...Object.assign(formData, values) });
          }}
          values={formData}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (formData && !formData.isValid)}
            label={<span>Save Item Category</span>}
            onClick={editItemCategory}
            onEnter={editItemCategory}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditItemCategoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editItemCategory: PropTypes.func,
  itemCategory: PropTypes.object,
  onEdit: PropTypes.func,
};

EditItemCategoryDialog.defaultProps = {
  open: false,
  onClose: () => {},
  itemCategory: {},
};

export default EditItemCategoryDialog;
