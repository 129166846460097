import { App } from '@meronex/app';

const { server } = App;

export const issuesMng = {
  getAssignedOptions: async () => {
    const result = await server.call('getAssignedOptions', {});
    console.log(result);
    return result;
  },
};
