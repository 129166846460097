import React from 'react';
import PropTypes from 'prop-types';
import View from '../components/View';

function CalculatorView(props) {
  const { activeView } = props;
  return (
    <View viewId={'calculator'} activeView={activeView} showMenu>
      <div
        style={{
          height: '80vh',
        }}>
        {/*<Calculator onNewInput={() => {}} onResultChange={() => {}} />*/}
      </div>
    </View>
  );
}
CalculatorView.propTypes = {
  activeView: PropTypes.string,
};

CalculatorView.defaultProps = {};
export default CalculatorView;
