import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Avatar from 'react-avatar';
import NumberFormat from 'react-number-format';

import Divider from '@material-ui/core/Divider';

import { App } from '@meronex/app';
import IOut from '@meronex/icons/bi/BiExport';
import IIn from '@meronex/icons/bi/BiImport';
import IReverse from '@meronex/icons/fa/FaUndo';
import IEdit from '@meronex/icons/ai/AiTwotoneEdit';
import IModifySign from '@meronex/icons/ios/IosArrowRoundForward';
import IFactory from '@meronex/icons/mdc/MdcFactory';
import ITransfer from '@meronex/icons/bs/BsArrowRight';
import INote from '@meronex/icons/fd/FdClipboardNotes';

import { Icons } from '../../../../common/Icons';
import { helpers } from '../../../../common/helpers';
import SitesAvatar from '../../../../common/SitesAvatar';
import SitesUserName from '../../../../common/SitesUserName';

const TRANSACTION_TYPES = helpers.TRANSACTION_TYPES;

function TransactionItem(props) {
  const { item, index, onClick, style, isStoreTransaction, showPrice } = props;

  if (!item) {
    return null;
  }

  const storeItem = item.storeItem;
  const unitLabel = storeItem.type.unit;

  const itemData = {
    icon: undefined,
    color: undefined,
  };
  if (item.transactionType === TRANSACTION_TYPES.out) {
    itemData.icon = <IOut color={'red'} size={18} />;
    itemData.color = 'red';
    itemData.label = 'Out';
  } else if (item.transactionType === TRANSACTION_TYPES.transferOut) {
    if (item.workOrderId) {
      itemData.icon = (
        <span>
          <span
            style={{
              marginRight: '5px',
            }}>
            <IFactory size={19} color={'rgb(31 169 163)'} />
          </span>
          <IOut color={'red'} size={18} />
        </span>
      );
      itemData.color = 'red';
      itemData.label = 'Material out';
    } else {
      itemData.icon = (
        <span>
          <span
            style={{
              position: 'relative',
              top: '2px',
              marginLeft: '4px',
              marginRight: '4px',
            }}>
            <Icons.Transfer size={20} color={'rgb(85, 108, 214)'} />
          </span>
          <IOut color={'red'} size={18} />
        </span>
      );
      itemData.color = 'red';
      itemData.label = 'Transfer out';
    }
  }
  if (item.transactionType === TRANSACTION_TYPES.in) {
    if (item.workOrderId) {
      itemData.icon = (
        <span>
          <span
            style={{
              marginRight: '5px',
            }}>
            <IFactory size={19} color={'rgb(31 169 163)'} />
          </span>
          <IIn color={'green'} size={18} />
        </span>
      );
      itemData.color = 'green';
      itemData.label = 'Product in';
    } else {
      itemData.icon = <IIn color={'green'} size={18} />;
      itemData.color = 'green';
      itemData.label = 'In';
    }
  } else if (item.transactionType === TRANSACTION_TYPES.transferIn) {
    itemData.icon = (
      <span>
        <span
          style={{
            position: 'relative',
            top: '2px',
            marginLeft: '4px',
            marginRight: '4px',
          }}>
          <Icons.Transfer size={20} color={'rgb(85, 108, 214)'} />
        </span>
        <IIn color={'green'} size={18} />
      </span>
    );
    itemData.color = 'green';
    itemData.label = 'Transfer in';
  } else if (item.transactionType === TRANSACTION_TYPES.reverse) {
    itemData.icon = <IReverse color={'gray'} size={15} />;
    itemData.color = 'gray';
    itemData.label = 'Reverse';
  } else if (item.transactionType === TRANSACTION_TYPES.modify) {
    itemData.icon = <IEdit color={'blue'} size={18} />;
    itemData.color = 'blue';
    itemData.label = 'Modify';
  }

  const mergedStyle = Object.assign(
    {
      height: '100%',
      padding: '5px',
      borderRadius: '10px',
      backgroundColor: index % 2 ? 'rgb(247 247 247 / 65%)' : '',
    },
    style
  );

  const imgSrc = ((storeItem.type || {}).image || {}).thumbUrl;

  const thousandsSeparator = App.utils.number.thousandsSeparator;
  const round = App.utils.number.round;

  const formatQuantity = (amount) => {
    return thousandsSeparator(round(amount));
  };

  const renderInfo = () => {
    return (
      <div>
        {isStoreTransaction && (
          <div style={{ display: 'flex' }}>
            <div>
              <div style={{ marginRight: '10px' }}>
                <SitesAvatar
                  image={imgSrc}
                  name={storeItem.type.name}
                  size={25}
                  round={5}
                />
              </div>
            </div>
            <div
              style={{
                marginBottom: '10px',
                fontWeight: '400',
                fontSize: '14px',
              }}>
              {item.storeItem.type.name}
            </div>
          </div>
        )}
        <div>
          <div style={{ display: 'inline-block', marginRight: '5px' }}>
            {itemData.icon} {item.reversed && <span>(R)</span>}
          </div>
          <span
            style={{
              fontSize: '12px',
            }}>
            {App.utils.time.timeFullFormat(item.createdAt, true)}{' '}
          </span>
        </div>
        <div
          style={{
            color: 'gray',
            fontSize: '12px',
            marginTop: '8px',
            minWidth: '285px',
          }}>
          {itemData.label}: {App.utils.time.timeAgo(item.createdAt, true)} by{' '}
          {item.createdBy && <SitesUserName profile={item.createdBy} />}
          {item.note && (
            <span>
              <INote />
            </span>
          )}
          {item.group && Object.keys(item.group).length !== 0 && (
            <div>
              <span
                style={{
                  position: 'relative',
                  top: '2px',
                }}>
                <Icons.Batch />
              </span>
              {'  '}
              <span>
                {item.group.name}{' '}
                <span
                  style={{
                    marginLeft: '10px',
                  }}>
                  {formatQuantity(item.group.quantity)}{' '}
                </span>
              </span>
              <ITransfer /> {formatQuantity(item.group.quantity + item.amount)}{' '}
              {storeItem.type.unit}
            </div>
          )}
          {showPrice &&
            item.adjustedUnitRate &&
            item.adjustedUnitRate.transactionUnitRate && (
              <div>
                <div>
                  Transaction Unit rate:{' '}
                  {item.adjustedUnitRate.transactionUnitRate.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 4,
                    }
                  )}{' '}
                  AED/{storeItem.type.unit}
                </div>
                Transaction Value:{' '}
                {formatQuantity(
                  item.amount * item.adjustedUnitRate.transactionUnitRate
                )}{' '}
                AED
              </div>
            )}
          {showPrice &&
          item.cost && ( // used for factory products
              <div>
                <Icons.Price /> Cost:{' '}
                {item.cost.total.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                })}{' '}
                AED
              </div>
            )}
          {item.from &&
            item.from.type &&
            item.from.store &&
            item.to &&
            item.to.type &&
            item.to.store && (
              <div>
                {(item.from.store || {}).name} (
                {formatQuantity(item.from.quantity)} -{' '}
                {formatQuantity(Math.abs(item.amount))}){' '}
                {(item.from.type || {}).unit}
                <span
                  style={{
                    marginLeft: '5px',
                  }}>
                  <ITransfer />
                </span>
                <span
                  style={{
                    marginLeft: '5px',
                  }}>
                  {item.to.store.name} ({formatQuantity(item.to.quantity)} +{' '}
                  {formatQuantity(Math.abs(item.amount))}) {item.to.type.unit}
                </span>
              </div>
            )}
        </div>
      </div>
    );
  };

  const renderQuantity = () => {
    return (
      <>
        <div
          style={{
            fontSize: '22px',
            color: itemData.color,
            float: 'right',
            display: 'block',
            minWidth: '40px',
            textAlign: 'right',
          }}>
          <div>
            {item.amount > 0 && item.transactionType !== 'modify' && '+'}
            <NumberFormat
              value={item.amount}
              displayType={'text'}
              thousandSeparator={true}
            />
          </div>
          <div
            style={{
              color: 'gray',
              display: 'block',
              position: 'relative',
              float: 'right',
            }}>
            <div
              style={{
                fontSize: '14px',
              }}>
              {unitLabel}
            </div>
            <div style={{ marginTop: '10px', fontSize: '14px' }}>
              {formatQuantity(item.storeItem.quantity)}
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Grid onClick={onClick} container style={mergedStyle}>
        <Grid container>
          <Grid
            item
            xs={8}
            style={{
              marginTop: '5px',
              fontSize: '15px',
            }}>
            {renderInfo()}
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            {renderQuantity()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
TransactionItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  isStoreTransaction: PropTypes.bool,
};

TransactionItem.defaultProps = {
  style: {},
  isStoreTransaction: false,
};
export default TransactionItem;
