import React, { Component } from 'react';
import { App } from '../../base/AppContext';
import IOffline from '@meronex/icons/ri/RiWifiOffLine';

export default class StatusIndicator extends Component {
  state = {
    status: null,
  };
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    window.addEventListener('online', this.updateStatus);
    window.addEventListener('offline', this.updateStatus);
    this.updateStatus();
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.updateStatus);
    window.removeEventListener('offline', this.updateStatus);
  }

  updateStatus = () => {
    let statusMsg;

    const internetCon = navigator.onLine;
    const ddpCon = App.clientSettings.getItem('connected');

    if (!internetCon) {
      statusMsg = (
        <div style={{ paddingTop: '5px' }}>
          <div>
            No Internet Connection
            <div />
            <div>
              <IOffline />
            </div>
          </div>
        </div>
      );
    }
    this.setState({ status: statusMsg });
  };

  render() {
    const statusMsg = this.state.status;
    if (!statusMsg) {
      return null;
    }
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '90px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 999999,
        }}>
        <div
          style={{
            width: '200px',
            textAlign: 'center',
            backgroundColor: 'rgba(86, 86, 86, 0.8)',
            color: 'white',
            borderRadius: '4px',
            minHeight: '40px',
            fontWeight: 'bold',
          }}>
          {statusMsg}
        </div>
      </div>
    );
  }
}
