import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SmartDrawer(props) {
  const classes = useStyles();
  const { open, anchor, onChange, showClose, actions } = props;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    onChange(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List>
        {actions.map((action, index) => (
          <>
            <ListItem
              disabled={action.disabled}
              button
              key={action.text}
              onClick={action.onClick}>
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText
                primary={action.text}
                secondary={action.secondary}
              />
            </ListItem>
            {action.divider && <Divider />}
          </>
        ))}
        {showClose && (
          <>
            <Divider />
            <ListItem
              button
              key={'close'}
              onClick={() => {
                toggleDrawer(false);
              }}>
              <div style={{ textAlign: 'center', width: '100%' }}>
                <ListItemText
                  primary={'Close'}
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </div>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

SmartDrawer.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.string,
  actions: PropTypes.array,
  onChange: PropTypes.func,
  showClose: PropTypes.bool,
};
SmartDrawer.defaultProps = {
  anchor: 'bottom',
  showClose: true,
  onChange: (open) => console.log(open),
  actions: [
    {
      text: 'Open',
      onClick: () => {
        console.log('onClick');
      },
      icon: <div />,
      disabled: false,
      divider: false,
    },
  ],
};
