import React from 'react';
import PropTypes from 'prop-types';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';

import List from '@material-ui/core/List';

import StoreCategoryItem from './StoreCategoryItem';
import { storeCategoriesMng } from '../api/storeCategoriesMng';

function StoreCategoriesList(props) {
  const { storeCategories, onClick, onEdit, onDelete } = props;

  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const _onDelete = (storeCategory) => {
    setOpenDeleteAlert(true);
    setSelectedItem(storeCategory);
  };

  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    await storeCategoriesMng.deleteStoreCategory(selectedItem);
    toast.success('Store category successfully deleted');
    setDeleting(false);
    onDelete();
  };

  const renderStoreCategories = () => {
    if (!Array.isArray(storeCategories)) {
      return <Loading />;
    } else if (storeCategories.length === 0) {
      return <NoData type={'warn'} />;
    }

    return storeCategories.map((storeCategory) => {
      return (
        <StoreCategoryItem
          storeCategory={storeCategory}
          onEdit={onEdit}
          onDelete={_onDelete}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <>
      <List>{renderStoreCategories()}</List>
      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
StoreCategoriesList.propTypes = {
  storeCategories: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

StoreCategoriesList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default StoreCategoriesList;
