import React from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import ItemTypesList from './ItemTypesList';
import TopHeader from '../../../views/components/TopHeader';
import { Icons } from '../../../common/Icons';

const useStyles = makeStyles((theme) => ({
  container: {},
  storeItemImg: {
    width: '95px',
    height: '95px',
    borderRadius: '10px',
    position: 'relative',
  },
  storeItemName: { fontWeight: 'bold', fontSize: '20px' },
  storeItemLineItem: {
    marginTop: '10px',
    fontSize: '12px',
    color: 'gray',
    lineHeight: 1.5,
  },
  addBtn: {
    position: 'relative',
    top: '40px',
    color: 'green',
  },
}));

function ItemTypesDialog(props) {
  const {
    open,
    onClose,
    store,
    onAdd,
    storeItems,
    itemTypes,
    onItemTypeDelete,
    onItemTypeCreate,
    onItemTypeUpdate,
    enableAddToStore,
    permissions,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [filterText, setFilterText] = React.useState('');
  const _onClose = () => {
    onClose();
    setFilterText('');
  };

  const _onAdd = () => {
    onAdd();
  };

  const count = Array.isArray(itemTypes) ? (
    <div
      style={{
        color: 'gray',
        fontSize: '10px',
        position: 'relative',
        left: '-2px',
      }}>
      ({itemTypes.length})
    </div>
  ) : (
    ''
  );
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopHeader
          icon={<Icons.Items />}
          filterProps={{
            onFilter: (v) => {
              setFilterText(v);
            },
          }}
          title={<div>All Item Types {count}</div>}
          onClose={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <div
          style={{
            marginTop: '-20px',
          }}>
          <ItemTypesList
            store={store}
            filterText={filterText}
            onItemTypeDelete={onItemTypeDelete}
            onItemTypeCreate={onItemTypeCreate}
            onItemTypeUpdate={() => {
              onItemTypeUpdate();
            }}
            onAddToStore={_onAdd}
            enableAddToStore={enableAddToStore}
            itemTypes={itemTypes}
            storeItems={storeItems}
            permissions={permissions}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

ItemTypesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  store: PropTypes.object,
  onAdd: PropTypes.func,
  itemTypes: PropTypes.array,
  onItemTypeDelete: PropTypes.func,
  onItemTypeCreate: PropTypes.func,
  onItemTypeUpdate: PropTypes.func,
  enableAddToStore: PropTypes.bool,
};
ItemTypesDialog.defaultProps = {
  enableAddToStore: true,
};

export default ItemTypesDialog;
