import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//TODO: replace with <Icons.X/>
import IList from '@meronex/icons/ios/MdList';

import AddRoleDialog from './AddRoleDialog';
import EditRoleDialog from './EditRoleDialog';
import RolesList from './RolesList';

import { rolesMng } from '../api/rolesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function RolesDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;

  const [openAddRole, setOpenAddRole] = React.useState();
  const [openEditRole, setOpenEditRole] = React.useState();
  const [selectedRole, setSelectedRole] = React.useState();
  const [roles, setRoles] = React.useState();

  const getRoles = async () => {
    console.log('getRoles');
    const roles = await rolesMng.getRoles();
    console.log(roles);
    setRoles(roles);
  };

  React.useEffect(() => {
    getRoles();
  }, []);

  React.useEffect(() => {
    if (selectedRole) {
      setOpenEditRole(true);
    }
  }, [selectedRole]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IList /> Roles
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <RolesList
            roles={roles}
            onClick={(role) => {}}
            onEdit={(role) => {
              console.log(role);
              setSelectedRole(role);
              setOpenEditRole(true);
            }}
            onDelete={getRoles}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            marginTop: '20px',
          }}>
          <ActionBtn
            onClick={() => {
              setSelectedRole(false);
              setOpenAddRole(true);
            }}
            label={<div>Add Role </div>}
          />
        </div>
        {openAddRole && (
          <AddRoleDialog
            open={openAddRole}
            onAdd={getRoles}
            onClose={() => {
              setOpenAddRole(false);
              setSelectedRole(null);
            }}
          />
        )}
        {openEditRole && (
          <EditRoleDialog
            open={openEditRole}
            role={selectedRole}
            onEdit={getRoles}
            onClose={() => {
              setOpenEditRole(false);
              setSelectedRole(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

RolesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RolesDialog;
