import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

function CircularProgressWithLabel(props) {
  const { value } = props;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography
          variant="caption"
          component="div"
          color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {};

CircularProgress.defaultProps = {};
export default CircularProgressWithLabel;
