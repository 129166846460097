import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MdMenu from '@meronex/icons/md/MdMenu';

import IArchive from '@meronex/icons/en/EnArchive';
import IAudit from '@meronex/icons/bi/BiListCheck';
import ICsv from '@meronex/icons/gr/GrDocumentCsv';
import IQRCode from '@meronex/icons/mdc/MdcQrcodeScan';

import { useHistory } from 'react-router-dom';

import { storesMng } from '../../api/storesMng';
import { Icons } from '../../../common/Icons';

function StoreDrawer(props) {
  const {
    selectedStore,
    onAddItem,
    onAddProduct,
    onAuditStore,
    onOpen,
    onExportToCsv,
    onClose,
    onOpenArchives,
    onGenerateLabels,
    permissions,
  } = props;
  const history = useHistory();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [archivedCount, setArchivedCount] = React.useState(0);

  React.useEffect(() => {
    if (selectedStore) {
      fetArchivesCount();
    }
  }, [openDrawer]);
  const _onClose = () => {
    setOpenDrawer(false);
    onClose();
  };

  const fetArchivesCount = async () => {
    const archivedCount = await storesMng.getArchivedStoreItemsCount(
      selectedStore._id
    );
    setArchivedCount(archivedCount);
  };

  return (
    <div>
      <Fab
        onClick={() => {
          setOpenDrawer(true);
          onOpen();
        }}
        aria-label="add"
        style={{
          position: 'fixed',
          right: '25px',
          bottom: '30px',
        }}>
        <MdMenu size={25} />
      </Fab>

      <div>
        <Drawer
          anchor={'bottom'}
          open={openDrawer}
          onClose={() => {
            _onClose();
          }}>
          <List>
            {selectedStore && (
              <>
                <ListItem
                  button
                  key="generateLabels"
                  onClick={() => {
                    onGenerateLabels();
                    _onClose();
                  }}>
                  <ListItemIcon>
                    <IQRCode
                      size={25}
                      style={{
                        color: 'black',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={'Generate Labels'} />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  key="exportToCSV"
                  onClick={() => {
                    onExportToCsv();
                    _onClose();
                  }}>
                  <ListItemIcon>
                    <span style={{}}>
                      <ICsv size={20} />
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={'Export items to CSV'} />
                </ListItem>

                <Divider />
                <ListItem
                  button
                  key="addProduct"
                  disabled={!selectedStore}
                  onClick={() => {
                    onAddProduct();
                    _onClose();
                  }}>
                  <ListItemIcon>
                    <span style={{}}>
                      <Icons.Products size={20} />
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={'Add a Product'} />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  key="addItem"
                  disabled={selectedStore.productsStore}
                  onClick={() => {
                    onAddItem();
                    _onClose();
                  }}>
                  <ListItemIcon>
                    <span style={{}}>
                      <Icons.Items size={20} />
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    primary={'Add an Item'}
                    secondary={
                      selectedStore.productsStore
                        ? 'Items are not allowed in products store'
                        : ''
                    }
                  />
                </ListItem>
                <Divider />

                <ListItem
                  button
                  key="archivedItems"
                  disabled={!selectedStore}
                  onClick={() => {
                    onOpenArchives();
                    _onClose();
                  }}>
                  <ListItemIcon>
                    <span style={{}}>
                      <IArchive size={22} color={'black'} />
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={`Archived Items (${archivedCount})`} />
                </ListItem>
                <Divider />

                <ListItem
                  button
                  key="auditStore"
                  disabled={!permissions.auditStore || !selectedStore}
                  onClick={() => {
                    onAuditStore();
                    _onClose();
                  }}>
                  <ListItemIcon>
                    <span style={{}}>
                      <IAudit size={22} />
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={`Audit Store`} />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  key="close"
                  onClick={() => {
                    _onClose();
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      fontSize: '18px',
                    }}>
                    Close{' '}
                  </div>
                </ListItem>
              </>
            )}
          </List>
          <Divider />
        </Drawer>
      </div>
    </div>
  );
}
StoreDrawer.propTypes = {
  selectedStore: PropTypes.object,
  onAddItem: PropTypes.func,
  onAddProduct: PropTypes.func,
  onOpen: PropTypes.func,
  onOpenArchives: PropTypes.func,
  onAuditStore: PropTypes.func,
  onExportToCsv: PropTypes.func,
  onScanQRCode: PropTypes.func,
  onClose: PropTypes.func,
  hideFab: PropTypes.bool,
  permissions: PropTypes.object,
  onGenerateLabels: PropTypes.func,
};

StoreDrawer.defaultProps = {
  onAddItem: () => {},
  onAuditStore: () => {},
  onExportToCsv: () => {},
  onGenerateLabels: () => {},
  onAddProduct: () => {
    console.log('on add product');
  },
  onOpen: () => {},
  onClose: () => {},
};
export default StoreDrawer;
