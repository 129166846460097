import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { ActionBtn, CloseBtn, toast } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import EquipmentForm from './EquipmentForm';
import { equipmentsMng } from '../api/equipmentsMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditEquipmentDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, onEdit, equipment, factory } = props;

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ isValid: true });

  React.useEffect(() => {
    setFormData({ ...equipment });
  }, [equipment]);

  const _onClose = () => {
    onClose();
  };

  const editEquipment = async () => {
    console.log('on edit');
    console.log(equipment);

    setLoading(true);

    const _updatedEquipment = Object.assign(equipment, formData);
    delete _updatedEquipment.isValid;
    delete _updatedEquipment.uploading;
    try {
      await equipmentsMng.updateEquipment(_updatedEquipment);
      onEdit();
      onClose();
      toast.success('Equipment updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  if (!equipment || !open) {
    return null;
  }
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Edit Equipment
        <CloseBtn onClick={_onClose} />
      </DialogTitle>
      <DialogContent>
        <EquipmentForm
          validateOnInit={true}
          factory={factory}
          onChange={(values) => {
            console.log(values);
            setFormData({ ...Object.assign(formData, values) });
          }}
          values={formData}
        />
        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (formData && !formData.isValid)}
            label={<span>Save Equipment</span>}
            onClick={editEquipment}
            onEnter={editEquipment}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditEquipmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editEquipment: PropTypes.func,
  equipment: PropTypes.object,
  onEdit: PropTypes.func,
};

EditEquipmentDialog.defaultProps = {
  open: false,
  onClose: () => {},
  equipment: {},
};

export default EditEquipmentDialog;
