import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { App } from '../base/AppContext';
import { Login, PrivateRoute } from '../layout/index';

export default function AppRoutes(props) {
  const renderAppRoutes = () => {
    const routes = props.routes || [];

    return routes.map((route, index) => {
      if (route.privateRoute && !App.opts.disableUserAccount) {
        return (
          <PrivateRoute
            key={index}
            path={route.path}
            component={route.component}
          />
        );
      } else {
        return (
          <Route
            key={index}
            path={route.path}
            render={(props) => {
              const Component = route.component;
              return <Component {...props} publicPage={true} />;
            }}
          />
        );
      }
    });
  };
  return (
    <Router history={App.history}>
      <Switch>
        {!App.opts.disableUserAccount && (
          <Route path="/login">
            <Login config={App.config.login} />
          </Route>
        )}
        {renderAppRoutes()}
      </Switch>
    </Router>
  );
}

AppRoutes.propTypes = {
  routes: PropTypes.array,
};
