import React from 'react';
import PropTypes from 'prop-types';
import MdCameraEnhance from '@meronex/icons/md/MdCameraEnhance';
import Button from '@material-ui/core/Button';
import ImageUploading from 'react-images-uploading';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CloseBtn } from '@meronex/components';

import { FilesUpload } from '../api/filesUpload';

function MultiImageUploader(props) {
  const { onUploadCountChange, onChange } = props;

  const [images, setImages] = React.useState([]);
  const [openImgDialog, setOpenImgDialog] = React.useState();
  const [selectedImg, setSelectedImg] = React.useState();
  const [uploadTasksCount, setUploadTasksCount] = React.useState();

  const maxNumber = 69;

  React.useEffect(() => {
    onChange(images);
  }, [images]);
  React.useEffect(() => {
    console.log(uploadTasksCount);
    if (onUploadCountChange) {
      onUploadCountChange(uploadTasksCount);
    }
  }, [uploadTasksCount]);

  const uploader = new FilesUpload({
    onUploadStart: () => {
      console.log('start');
    },
    onProgress: (v) => {
      console.log(`progress :${v}`);
    },
    onTaskSizeChange: (tasks) => {
      setUploadTasksCount(tasks.length);
    },
    onUploadError: (v) => console.log(v),
    onUploadSuccess: (v) => console.log(v),
    folder: 'images',
    randomizeFilename: true,
    optimize: true,
  });

  const onUploadSuccess = (v) => {
    const updatedImages = v.images.map((img) => {
      if (img.data_url === v.img.data_url) {
        img.downloadUrl = v.data.downloadUrl;
      }
      return img;
    });
    setImages(updatedImages);
  };
  const uploadImage = (images, indices) => {
    console.log(`uploading images: ${indices}`);

    indices.forEach((i) => {
      const img = images[i];
      uploader.startUpload({
        file: img.file,
        onUploadError: (err) => {
          console.log(err);
        },
        onProgress: (v) => console.log(v),
        onUploadStart: (v) => console.log(v),
        onUploadSuccess: (data) => {
          onUploadSuccess({ data, img, images });
        },
      });
    });
  };

  const _onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    uploadImage(imageList, addUpdateIndex);
  };

  const removeImage = () => {
    console.log('remove img called');
    console.log(selectedImg);

    setImages(
      images.filter((img) => {
        return img.data_url !== selectedImg.image.data_url;
      })
    );
    setOpenImgDialog(false);
  };

  return (
    <>
      <ImageUploading
        multiple
        value={images}
        onChange={_onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url">
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button
              onClick={onImageUpload}
              color={'primary'}
              style={{
                border: '1px solid #556cd6',
                borderRadius: '20px',
                width: '100%',
                height: '45px',
                marginTop: '5px',
              }}>
              <MdCameraEnhance size={20} />
              &nbsp;Add Images
            </Button>
            &nbsp;
            <div
              style={{
                display: 'flex',
                overflow: 'scroll',
                justifyContent: 'start',
              }}>
              {imageList.map((image, index) => (
                <div
                  onClick={() => {
                    console.log('image clicked');
                    setOpenImgDialog(true);
                    setSelectedImg({
                      image,
                      index,
                    });
                  }}
                  key={index}
                  className="image-item"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}>
                  <img src={image['data_url']} alt="" width="120" />
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
      {openImgDialog && selectedImg && (
        <Dialog
          className={'fullScreenMobile'}
          open={openImgDialog}
          onClose={() => {
            setOpenImgDialog(false);
          }}>
          <DialogTitle
            style={{
              height: '45px',
            }}>
            <CloseBtn
              onClick={() => {
                setOpenImgDialog(false);
              }}
            />
          </DialogTitle>
          <DialogContent>
            <img
              src={selectedImg.image['data_url']}
              style={{
                width: '100%',
              }}
            />
          </DialogContent>
          <DialogActions
            style={{
              textAlign: 'center',
            }}>
            <Button
              onClick={() => removeImage(selectedImg)}
              style={{
                width: '100%',
              }}>
              Remove Image
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
MultiImageUploader.propTypes = {
  onUploadCountChange: PropTypes.func,
  onChange: PropTypes.func,
};

MultiImageUploader.defaultProps = {};
export default MultiImageUploader;
