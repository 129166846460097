import React from 'react';
import EventEmitter from 'eventemitter3';

// new firebase https://github.com/firebase/firebase-js-sdk/issues/4368
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { initServer } from './server';
import history from './history';
import { utils } from './utils';
import * as serviceWorker from './serviceWorker';

const metadata = require('../../metadata.json');

const AppClass = class {
  constructor(opts) {
    // disableUserAccount
    // defaultLanguage
    // disableServer
    // themeType
    this.opts = {
      disableUserAccount: opts.disableUserAccount,
      disableServer: opts.disableServer,
      disablePulseIndicator: opts.disablePulseIndicator,
      themeType: opts.themeType,
      defaultLanguage: opts.defaultLanguage,
      endpoint: opts.disableUserAccount,
      toastPosition: opts.toastPosition,
    };

    this.metadata = metadata;

    this.settings = this.loadDomainSettings();
    // init the server
    if (!opts.disableServer) {
      this.server = initServer(opts, this);
    }
    // attach helpers
    this.utils = utils;

    this.serviceWorker = serviceWorker;
    this.eventEmitter = new EventEmitter();
    this.history = history;
    this.themeType =
      opts.themeType ||
      this.clientSettings.getItem('themeType') ||
      this.themeManager.getSystemTheme();
    this.themeListners = [];
    this.languageListeners = [];
    this.language =
      this.clientSettings.getItem('language') ||
      opts.defaultLanguage ||
      i18n.language;
    this.dir = this.languageManager.getDirection(this.language);
    this.eventsData = {
      eventsMap: new Map(),
      listenersMap: new Map(),
      emittersMap: new Map(),
    };
    this.hostUrl = utils.getUrlParts();
    this.toastPosition = opts.toastPosition || toast.POSITION.BOTTOM_CENTER;
    this.loaded = false;
    if (!opts.disableUserAccount) {
      this.eventsManager.on('event-connected', this, () => {
        this.initUser();
      });
      this.eventsManager.on('event-user-updated', this, () => {
        this.fetchUser();
      });
      // event-resume-login should be private event
      // use event-user-loaded
      this.eventsManager.on('event-resume-login', this, () => {
        this.fetchUser();
      });
    }
    const registerSystemThemeChange = () => {
      const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

      const registerThemeChange = (e) => {
        const newColorScheme = e.matches ? 'dark' : 'light';
        this.themeManager.changeTheme(newColorScheme);
      };
      if (matchMedia.addEventListener) {
        matchMedia.addEventListener('change', (e) => {
          registerThemeChange(e);
        });
      } else if (matchMedia.addListener) {
        matchMedia.addListener((e) => {
          registerThemeChange(e);
        });
      }
    };
    registerSystemThemeChange();

    // Setup firebase
    const firebaseConfig = {
      apiKey: 'AIzaSyADxsGCMFSzpfDMYPDNMY5fs5dtl5Radyw',
      authDomain: 'siteshub-meronex.firebaseapp.com',
      databaseURL: 'https://siteshub-meronex.firebaseio.com',
      projectId: 'siteshub-meronex',
      storageBucket: 'siteshub-meronex.appspot.com',
      messagingSenderId: '640514951222',
      appId: '1:640514951222:web:e8833118e569f34062f097',
      measurementId: 'G-22Y8FC30JZ',
    };
    const firebaseApp = initializeApp(firebaseConfig);
    this.firebase = firebaseApp;

    // Register service service worker
    serviceWorker.register({
      immediate: true,
      onSuccess: () => {},
      onUpdate: () => {},
    });
  }

  getServerUrl = () => {
    try {
      const httpUrl = this.server._opts.endpoint.replace('ws', 'http');
      const httpUrlArray = httpUrl.split('/');
      return `${httpUrlArray[0]}//${httpUrlArray[2]}`;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  setToastPosition = (position) => {
    this.toastPosition = position;
  };
  getToastPosition = () => {
    return this.toastPosition;
  };
  isLocalHost = () => {
    return this.hostUrl.hostname === 'localhost';
  };
  initUser = async () => {
    console.log('[App/AppContext] Initializing user');
    const user = await this.fetchUser();
    console.log(user);
    if (!user) {
      console.warn('App/AppContext] User is undefined');
      if (this.isLogged()) {
        // something is wrong
        // we have user token without user
        console.error('login token without user, logout');
        this.clientSettings.removeItem('token');
        this.clientSettings.removeItem('userId');
        this.clientSettings.removeItem('loginInfo');
        history.push('login');
      }
    } else {
      this.loaded = true;
      this.eventsManager.emit('event-user-loaded', this);
    }
  };
  fetchUser = async (broadcast = true) => {
    console.log('[AppContext]: fetch user');
    const user = await this.server.call('getSessionUser', {});

    this._user = user;
    console.log(user);
    if (user) {
      this.eventsManager.emit('event-user-loaded', this);
    }

    return user;
  };
  getUser = () => {
    return this._user;
  };
  getSessionCommunity = async () => {
    const result = await this.server.call('getSessionCommunity', {});
    return result;
  };

  isLogged = () => {
    const token = this.clientSettings.getItem('token');
    return token != null;
  };

  logout = () => {
    this.server.logout();
    this.clientSettings.removeItem('token');
    this.clientSettings.removeItem('userId');
    this.clientSettings.removeItem('loginInfo');
    history.push('login');
  };
  loadDomainSettings = async () => {
    const appSettingsNode = document.getElementById('app_settings');
    if (appSettingsNode && appSettingsNode.innerText) {
      this.settings = await JSON.parse(appSettingsNode.innerText);
    } else this.settings = undefined;
  };
  // Function for manipulating client settings stored at the
  // browser local storage
  clientSettings = {
    setItem: (key, item) => {
      const clientSettings = utils.localStorage.getItem('clientSettings') || {};
      console.log('[App/appContext] Client settings:');
      console.log(clientSettings);
      clientSettings[key] = item;
      utils.localStorage.setItem('clientSettings', clientSettings);
    },
    getItem: (key) => {
      const clientSettings = utils.localStorage.getItem('clientSettings') || {};
      if (!key) {
        return clientSettings;
      }
      return clientSettings[key];
    },
    removeItem: (key) => {
      const clientSettings = utils.localStorage.getItem('clientSettings') || {};
      delete clientSettings[key];
      return utils.localStorage.setItem('clientSettings', clientSettings);
    },
    getAll: () => {
      return utils.localStorage.getItem('clientSettings');
    },
    removeAll: () => {
      return utils.localStorage.setItem('clientSettings', {});
    },
  };
  userSettings = {
    // get/set general user settings at the server
    set: async (userSettings, cb) => {
      const result = await this.server.call('setUserSettings', {
        userSettings,
      });
      this._user.settings = userSettings;
      if (cb) {
        return cb(userSettings);
      }
      return result;
    },
    get: async (refresh) => {
      let userSettings;
      if (!this._user || refresh) {
        userSettings = await this.server.call('getUserSettings', {});
      } else {
        userSettings = this._user.settings;
      }
      return userSettings;
    },
    // get/set the settings for specific app.
    // if not available then initialize it
    app: {
      get: async (appName) => {
        let settings = {};
        const userSettings = await App.userSettings.get();
        if (userSettings) {
          if (userSettings[appName]) {
            settings = userSettings[appName];
          } else {
            userSettings[appName] = settings;
            await App.userSettings.set(userSettings);
          }
        }

        return settings;
      },
      set: async (appName, settings) => {
        const userSettings = App.utils.clone(await App.userSettings.get());
        if (!userSettings[appName]) {
          userSettings[appName] = {};
        }
        const _mergedSettings = Object.assign(userSettings[appName], settings);
        userSettings[appName] = _mergedSettings;
        await App.userSettings.set(userSettings);
        return settings;
      },
    },
  };
  themeManager = {
    themeType: this.themeType,
    getSystemTheme: () => {
      // Assume light
      let systemTheme = 'light';

      if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
          systemTheme = 'dark';
        }
      } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        systemTheme = 'light';
      }
      return systemTheme;
    },
    addThemeListener: (listener) => {
      this.themeListners.push(listener);
    },
    changeTheme: (themeType) => {
      this.themeType = themeType;
      this.clientSettings.setItem('themeType', themeType);
      this.themeListners.forEach((listener) => {
        listener(themeType);
      });
    },
  };

  languageManager = {
    rtlLanguages: ['ar'],
    language: this.language,
    isRtl: () => {
      return this.languageManager.rtlLanguages.includes(this.language);
    },
    changeLanguage: (language, cb) => {
      this.language = language;
      console.log(`[App/Language] set language to ${language}`);
      this.clientSettings.setItem('language', language);
      i18n.changeLanguage(language, () => {
        console.log('language changed');
        if (cb) {
          cb();
        }
      });

      console.log(this.languageManager.getDirection(language));
      document.body.setAttribute(
        'dir',
        this.languageManager.getDirection(language)
      );

      // this.clientSettings.setItem('themeType', themeType);
      this.languageListeners.forEach((listener) => {
        listener(language);
      });
    },
    addLanguageListener: (listener) => {
      this.languageListeners.push(listener);
    },
    getDirection: (language = this.language) => {
      const dir = this.languageManager.rtlLanguages.includes(language)
        ? 'rtl'
        : 'ltr';
      return dir;
    },
  };
  eventsManager = {
    // the event string, listener object, and callback function
    on: (event, listener, callback) => {
      const listenerId = this.utils.getObjectName(listener);
      this.eventEmitter.on(event, callback);
      // Update the events map
      // these maps are for tracing and debugging
      // to help track event sources in the console
      // and see who registered to what
      let eventSet = this.eventsData.eventsMap.get(event);
      if (!eventSet) {
        eventSet = new Set();
      }
      if (eventSet.has(listenerId)) {
        console.warn(
          `[App/AppContext] attempting to duplicated listener ${listenerId}`
        );
      }
      eventSet.add(listenerId);
      this.eventsData.eventsMap.set(event, eventSet);

      // Update the events data maps
      let listenerSet = this.eventsData.listenersMap.get(listenerId);
      if (!listenerSet) {
        listenerSet = new Set();
      }
      listenerSet.add(event);
      this.eventsData.listenersMap.set(listenerId, listenerSet);
    },
    emit: (event, emitter, eventArgs) => {
      const emitterId = this.utils.getObjectName(emitter);
      const eventsMapSet = this.eventsData.eventsMap.get(event);
      let eventsMapArray = [];
      if (eventsMapSet instanceof Set) {
        eventsMapArray = Array.from(eventsMapSet);
      }
      console.log(
        `[App/AppContext] (${emitterId}) is emitting (${event}) to ${this.eventEmitter.listenerCount(
          event
        )} listeners (${eventsMapArray}).`
      );
      this.eventEmitter.emit(event, eventArgs);
      const emittersMap = this.eventsData.emittersMap;
      let eventEmittersSet = emittersMap.get(event);
      if (!eventEmittersSet) {
        eventEmittersSet = new Set();
      }
      eventEmittersSet.add(emitterId);
      emittersMap.set(event, eventEmittersSet);
    },
    hasListeners: (event) => {
      return this.eventEmitter.listenerCount(event) > 0;
    },
    listenersCount: (event) => {
      return this.eventEmitter.listenerCount(event);
    },
    removeListener: (event, listener, callback) => {
      const listenerId = this.utils.getObjectName(listener);
      this.eventEmitter.removeListener(event, callback);
      let eventSet = this.eventsData.eventsMap.get(event);
      if (eventSet) {
        eventSet.delete(listenerId);
        this.eventsData.eventsMap.set(event, eventSet);
      }
      this.eventsData.listenersMap.delete(listenerId);
    },
    removeAllListeners: () => {
      this.eventEmitter.removeAllListeners();
      this.eventsData.eventsMap = new Map();
      this.eventsData.listenersMap = new Map();
    },
    eventEmitter: this.eventEmitter,
    // the current state of the eventsManager
    // for tracing and debugging
    getState: () => {
      return {
        eventsMap: this.eventsData.eventsMap,
        listenersMap: this.eventsData.listenersMap,
        emittersMap: this.eventsData.emittersMap,
        eventNames: this.eventEmitter.eventNames(),
      };
    },
  };

  forceReload = () => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then(function (reg) {
        if (reg) {
          reg.unregister().then(function () {
            window.location.reload(true);
          });
        } else {
          window.location.reload(true);
        }
      });
      // handle the case where we don't have a service worker
      // being registered.
    } else {
      console.warn(
        '[App/forceReload]: no service worker detected, reloading..'
      );
      window.location.reload(true);
    }
  };
};

const onSettingLoad = (App) =>
  new Promise((resolve) => {
    const isPromise = (obj) => obj instanceof Promise;
    const isValidSettings = () => App.settings && !isPromise(App.settings);

    if (isValidSettings()) {
      resolve(App.settings);
    }
    const loadTestInterval = setInterval(async () => {
      await App.loadDomainSettings();
      if (isValidSettings()) {
        clearInterval(loadTestInterval);
        resolve(App.settings);
      }
    }, 25);
  });

const initI18n = (opts, App) => {
  const clientLanguage = App.clientSettings.getItem('language');

  const activeLanguage = clientLanguage || opts.defaultLanguage || 'en';
  App.language = activeLanguage;
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: opts.translations || {},
      lng: activeLanguage,
      ns: ['prod', 'mock'],
      defaultNS: 'prod',
      keySeparator: '.',
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: function (value, format, lng) {
          if (format === 'number') {
            return Intl.NumberFormat(lng === 'ar' ? 'ar-AE' : 'en', {}).format(
              value
            );
          }
        },
      },
    });
};

export const AppContext = React.createContext(null);
export let App;

export const initApp = async (opts) => {
  // wait for the settings to load
  return new Promise(async (resolve) => {
    App = new AppClass(opts);

    await onSettingLoad(App);
    await initI18n(opts, App);

    //Attach app instance to window
    window.App = App;
    window.app = App;
    resolve(App);
  });
};
