import { App } from '@meronex/app';

const { server } = App;

export const membersMng = {
  addMember: async (member) => {
    const result = await server.call('addSiteMember', { member });
    return result;
  },
  updateMemberRole: async ({ memberId, roleId }) => {
    const result = await server.call('updateMemberRole', { memberId, roleId });
    return result;
  },
  updateMember: async (updateData) => {
    console.log(updateData);
    const result = await server.call('updateSiteMember', { updateData });
    return result;
  },
  getMembers: async () => {
    const result = await server.call('getSiteMembers', {});
    console.log(result);
    return result;
  },
  getMembersCursor: async ({ skip, limit, filters }) => {
    const result = await server.call('getSiteMembersCursor', {
      skip,
      limit,
      filters,
    });
    console.log(result);
    return result;
  },
  deleteMember: async (member) => {
    console.log(`delete member, id: ${member._id}`);
    const result = await server.call('deleteSiteMember', { _id: member._id });

    return result;
  },
};
