import React from 'react';
import PropTypes from 'prop-types';

import IProduct from '@meronex/icons/fi/FiPackage';
import IEdit from '@meronex/icons/bi/BiEdit';
import DialogContent from '@material-ui/core/DialogContent';

import TopHeader from '../../../views/components/TopHeader';
import { ActionBtn, toast, AlertDialog } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import ProductForm from './ProductForm';

import { productsMng } from '../../api/ProductsMng';

import { App } from '@meronex/app';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditProductDialog(props) {
  const { open, onClose, onEdit, onDelete } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState(props.product);
  const [draftsCount, setDraftsCount] = React.useState(0);

  const [openDraftsAlert, setOpenDraftsAlert] = React.useState(false);

  console.log(product);
  const getDraftsCount = async () => {
    const _count = await productsMng.getProductDraftsCount({
      productId: product._id,
    });
    setDraftsCount(_count);
  };

  React.useEffect(() => {
    setProduct(props.product);
  }, [open]);

  React.useEffect(() => {
    if (product) {
      getDraftsCount();
    }
  }, [product]);

  const onEditConfirm = async () => {
    setLoading(true);

    const _updateProduct = { ...product };
    delete _updateProduct.isValid;

    try {
      const result = await productsMng.updateProduct(_updateProduct);
      onEdit(_updateProduct);
      onClose();

      toast.success('Product updated', {
        autoClose: 900,
      });
      // refresh the stores when a product is updated
      // as they might result in batch creation
      App.eventsManager.emit(
        'event-product-updated',
        EditProductDialog.name,
        {}
      );
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const editProduct = () => {
    if (draftsCount > 0) {
      setOpenDraftsAlert(true);
    } else {
      onEditConfirm();
    }
  };

  if (!product) {
    return null;
  }
  return (
    <Dialog
      style={{
        overflow: 'hidden',
        height: '100vh',
      }}
      disableBackdropClick="false"
      scroll={'body'}
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}>
      <DialogTitle>
        <TopHeader
          title={'Edit product'}
          icon={
            <span>
              <IProduct />
              &nbsp;
              <IEdit />
            </span>
          }
          onClose={onClose}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            paddingBottom: '230px',
          }}>
          <div
            style={{
              textAlign: 'right',
            }}>
            <span>
              {' '}
              id: {product._id}{' '}
              <span
                className={'link'}
                onClick={() => {
                  App.utils.string.copyToClipboard(product._id);
                }}>
                (copy)
              </span>
            </span>
          </div>
          <ProductForm
            product={product}
            onChange={(p) => {
              setProduct(p);
            }}
          />
          <div
            style={{
              color: 'red',
              textAlign: 'center',
              marginTop: '40px',
              marginBottom: '30px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onDelete();
            }}>
            {' '}
            Delete Product
          </div>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              addingTop: '10px',
              paddingBottom: '10px',
              marginBottom: '20px',
            }}>
            <ActionBtn
              loading={loading}
              disabled={loading || !product || !product.isValid}
              label={<span>Save Product</span>}
              onClick={() => {
                editProduct();
              }}
              onEnter={() => {
                editProduct();
              }}
            />
          </div>
        </div>
        <AlertDialog
          open={openDraftsAlert}
          onConfirm={() => {
            onEditConfirm();
          }}
          confirmBtn={
            <span style={{ color: 'rgb(67, 193, 106)' }}>Update</span>
          }
          description={
            <div>
              Are you sure you want to update the product?
              <div
                style={{
                  color: 'red',
                }}>
                {' '}
                {draftsCount} existing draft will use the old product data
              </div>
            </div>
          }
          onClose={() => {
            setOpenDraftsAlert(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

EditProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  product: PropTypes.object,
};

export default EditProductDialog;
