import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CloseBtn } from '@meronex/components';
import MdSend from '@meronex/icons/md/MdSend';

import { commentsMng } from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  backdrop: {
    zIndex: 0,
  },
}));

export default function CommentDialog(props) {
  const {
    open,
    onClose,
    subjectId,
    authorId,
    onAdd,
    onEdit,
    existingComment,
    isEditMode,
  } = props;
  const classes = useStyles();
  const [userComment, setUserComment] = React.useState('');
  const [userCommentSaving, setUserCommentSaving] = React.useState(false);

  useEffect(() => {
    // Reason for adding "open === true":
    // We don't want "open === close" to re-trigger the setting of the comment box
    // with the old user comment prior to being edited (even though this would not
    // actually re-save the old comment, this check is only for UX purposes).
    if (existingComment && open === true) {
      // TODO: Instead of having to use RegExp to convert <br/> to \n maybe use:
      // TODO: https://marmelab.com/react-admin/Fields.html#richtextfield
      const regExp = new RegExp(' <br/>', 'g');
      const formattedComment = existingComment.commentText.replace(
        regExp,
        '\n'
      );
      setUserComment(formattedComment);
    }
  }, [open]);

  function isUserCommentEmpty() {
    return !userComment || userComment.length < 1;
  }

  const close = () => {
    setUserComment('');
    setUserCommentSaving(false);
    onClose();
  };

  const onClick = async () => {
    setUserCommentSaving(true);

    if (isEditMode) {
      await commentsMng.updateComment(existingComment.commentId, userComment);
      if (typeof onEdit === 'function') {
        onEdit();
      }
      toast.success('Comment updated');
    } else {
      await commentsMng.addComment({
        subjectId,
        authorId,
        text: userComment,
      });
      if (typeof onAdd === 'function') {
        onAdd({ subjectId, userComment });
      }
      toast.success('Comment added');
    }

    close();
  };

  const submitText = isEditMode ? 'UPDATE COMMENT' : 'POST COMMENT';
  return (
    <Dialog
      open={open}
      className={'fullScreenMobile'}
      onClose={() => {
        close();
      }}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <CloseBtn
          onClick={() => {
            close();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <TextField
          id="filled-full-width"
          className={classes.root}
          style={{
            marginTop: '10px',
            marginBottom: '20px',
          }}
          placeholder="Add a new comment"
          fullWidth
          multiline
          autoFocus={true}
          value={userComment}
          onChange={(event) => {
            setUserComment(event.target.value);
          }}
        />
        <div
          style={{
            width: '100%',
            marginBottom: '20px',
            textAlign: 'center',
          }}>
          <Button
            variant="contained"
            style={{
              color: 'white',
              width: '180px',
              height: '35px',
              fontSize: '12px',
              borderRadius: '20px',
              backgroundColor: !isUserCommentEmpty() && '#43c16a',
            }}
            onClick={() => onClick()}
            disabled={isUserCommentEmpty() || userCommentSaving}>
            {submitText}{' '}
            {userCommentSaving ? <CircularProgress size={15} /> : <MdSend />}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

CommentDialog.propTypes = {
  open: PropTypes.bool,
  cardId: PropTypes.string,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  existingComment: PropTypes.object,
  isEditMode: PropTypes.bool,
};
