import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { App } from '../../base/AppContext';

export default function MenuList(props) {
  const { onClick, value } = props;

  const renderMenuList = () => {
    const pages = App.config.views.multiPage;
    if (Array.isArray(pages)) {
      const menuItems = [];
      pages.forEach((page, index) => {
        const isActive = index === value;

        menuItems.push(
          <ListItem button key={index} onClick={() => onClick(index)}>
            <span style={{ marginRight: '10px' }}>{page.icon}</span>
            <ListItemText
              style={{ color: isActive ? 'rgb(59, 151, 186)' : '' }}
              primary={page.label}
            />
          </ListItem>
        );
      });
      return menuItems;
    }
  };

  return <div role="presentation">{renderMenuList()}</div>;
}

MenuList.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.number,
};
