import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as generateRandomID } from 'uuid';

import { App, AppContext } from '../base/AppContext';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LazyLoader = (props) => {
  const classes = useStyles();
  const { App } = React.useContext(AppContext);

  const [userLoaded, setUserLoaded] = React.useState(false);

  let id = props.id || `${LazyLoader.name}-${generateRandomID()}`;

  React.useEffect(() => {
    App.eventsManager.on('event-user-loaded', id, () => {
      setUserLoaded(true);
    });
  }, []);

  const loading = (
    <Backdrop className={classes.backdrop} open={true}>
      {<CircularProgress id={'circularProgress'} />}
    </Backdrop>
  );
  // wait for user to load before showing content
  if (
    (App.server || {}).connected && // if not connect the circular progress for the server will be shown
    // this line prevent duplicated circular loader with serverChecker
    !App.opts.disableServer &&
    !App.opts.disableUserAccount &&
    !App.getUser() &&
    !userLoaded
  ) {
    return loading;
  }
  return <Suspense fallback={loading}>{props.children}</Suspense>;
};

LazyLoader.protoTypes = {
  id: PropTypes.string,
};
export default LazyLoader;
