import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Form } from '@meronex/components';
import Grid from '@material-ui/core/Grid';

function LocationForm(props) {
  const { location, onChange } = props;

  console.log(location);
  const _onChange = (f, v) => {
    if (f) {
      const _location = Object.assign({}, location);
      _location[f] = v;
      onChange(_location);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          name={'rack'}
          label="Rack"
          autoFocus={true}
          variant="outlined"
          value={location.rack}
          fullWidth
          onChange={(e) => {
            _onChange('rack', e.target.value);
          }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={'shelf'}
          label="shelf"
          variant="outlined"
          fullWidth
          value={location.shelf}
          margin="normal"
          onChange={(e) => {
            _onChange('shelf', e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name={'level'}
          label={'level'}
          variant="outlined"
          fullWidth
          value={location.level}
          onChange={(e) => {
            _onChange('level', e.target.value);
          }}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
LocationForm.propTypes = {
  location: PropTypes.object,
  onChange: PropTypes.func,
};

LocationForm.defaultProps = {};
export default LocationForm;
