import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { App } from '@meronex/app';

import IWarn from '@meronex/icons/ti/TiWarningOutline';
import IVerify from '@meronex/icons/mdc/MdcMicroscope';
import IData from '@meronex/icons/bs/BsClipboardData';
import IReverse from '@meronex/icons/fa/FaUndo';
import IStart from '@meronex/icons/ai/AiOutlineClockCircle';
import IEnd from '@meronex/icons/bs/BsCheckCircle';
import SitesUserName from '../../../../common/SitesUserName';

const useStyles = makeStyles(() => ({
  oppositeContent: {
    // TODO: adjust this value accordingly
    flex: 0.2,
  },
}));

function WOHistory(props) {
  const classes = useStyles();

  const { workOrder } = props;

  if (!workOrder || !workOrder.draft) {
    return null;
  }
  const history = [];

  history.push({
    type: 'start',
    message: 'Draft started',
    date: workOrder.draft.createdAt,
    user: workOrder.draft.createdBy,
    icon: <IStart />,
  });
  history.push({
    type: 'end',
    message: 'Work order submitted',
    date: workOrder.createdAt,
    user: workOrder.createdBy,
    icon: <IEnd />,
  });
  if (workOrder.reversed) {
    history.push({
      type: 'reversed',
      message: 'Work order reversed',
      date: workOrder.reversed.date,
      user: workOrder.reversed.reversedBy,
      icon: <IReverse />,
    });
  }
  if (workOrder.state) {
    if (workOrder.state.dataEntry) {
      history.push({
        type: 'dataEntry',
        message: 'Data entry verified and completed',
        date: workOrder.state.dataEntry.date,
        user: workOrder.state.dataEntry.user.profile,
        icon: <IData />,
      });
    }
    if (workOrder.state.qualityVerified) {
      history.push({
        type: 'qualityVerified',
        message: 'Quality verified',
        date: workOrder.state.qualityVerified.date,
        user: workOrder.state.qualityVerified.user.profile,
        icon: <IVerify />,
      });
    }
    if (workOrder.state.flagged) {
      history.push({
        type: 'flagged',
        message: 'Work order flagged',
        date: workOrder.state.flagged.date,
        user: workOrder.state.flagged.user.profile,
        reason: workOrder.state.flagged.reason,
        icon: <IWarn />,
      });
    }
  }

  const sortedHistory = history.sort((a, b) => a.date - b.date);

  const renderHistory = (h) => {
    const renderMessage = (h) => {
      return (
        <div
          style={{
            color: h.type === 'flagged' ? 'red' : 'inherit',
          }}>
          <Typography>{h.message}</Typography> by{' '}
          <SitesUserName profile={h.user} />
          {h.type === 'flagged' && <div>{h.reason}</div>}
        </div>
      );
    };
    return sortedHistory.map((h) => {
      const color = h.type === 'flagged' ? 'red' : 'inherit';

      return (
        <TimelineItem>
          <TimelineOppositeContent className={classes.oppositeContent}>
            <Typography color="textSecondary">
              <span
                style={{
                  color,
                }}>
                {App.utils.time.fullFormat(h.date, true)}
              </span>
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              style={{
                color,
              }}>
              {h.icon}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>{renderMessage(h)}</TimelineContent>
        </TimelineItem>
      );
    });
  };
  return (
    <React.Fragment>
      <Timeline align={'left'}>{renderHistory(sortedHistory)}</Timeline>
    </React.Fragment>
  );
}
WOHistory.propTypes = {};

WOHistory.defaultProps = {};
export default WOHistory;
