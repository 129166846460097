import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { App } from '@meronex/app';
import FaBell from '@meronex/icons/fa/FaBell';
import GoListUnordered from '@meronex/icons/go/GoListUnordered';
import BsCheckCircle from '@meronex/icons/bs/BsCheckCircle';

import NotificationsList from './NotificationsList';
import ActivitiesList from './ActivitiesList';
import { notifMng } from '../api';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [updateNotifications, setUpdateNotifications] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [hasUnseenActivities, setHasUnseenActivities] = React.useState(false);
  const [
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  ] = React.useState(0);

  // TODO Centralize the fetchers because HeaderBar uses it too...
  const fetchNotificationsUnreadCount = async () => {
    console.log('fetch unread notifications count..');
    try {
      const unreadNotifs = await notifMng.fetchUnreadNotificationsCount();
      setUnreadNotificationsCount(unreadNotifs);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUnseenActivities = async () => {
    console.log('fetch unseen activities flag..');
    try {
      const hasUnseenActivitiesFetch = await notifMng.fetchHasUnseenActivities();
      setHasUnseenActivities(hasUnseenActivitiesFetch);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchNotificationsUnreadCount();
    fetchUnseenActivities();
    // ensure that we call fetch sites when reconnect, this happen
    // on refresh the browser or reconnection while the page is on.
    App.eventsManager.on('event-connected', this, () => {
      fetchNotificationsUnreadCount();
      fetchUnseenActivities();
    });
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <div
          style={{
            position: 'relative',
            marginTop: '10px',
          }}>
          <NotificationsList
            unreadNotificationsCount={unreadNotificationsCount}
            setUnreadNotificationsCount={setUnreadNotificationsCount}
            updateNotifications={updateNotifications}
            setUpdateNotifications={setUpdateNotifications}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '30px',
              width: '100%',
              textAlign: 'center',
            }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                borderRadius: '20px',
              }}
              onClick={() => {
                App.server.call('notifications.mark', 'all', true);
                setUpdateNotifications(true);
              }}>
              <BsCheckCircle size={20} /> &nbsp; Mark All As Read
            </Button>
          </div>
        </div>
      </AppBar>
      {/*  <Tabs*/}
      {/*    value={value}*/}
      {/*    onChange={handleChange}*/}
      {/*    indicatorColor="primary"*/}
      {/*    textColor="primary"*/}
      {/*    variant="fullWidth"*/}
      {/*    aria-label="notifications">*/}
      {/*    <Tab*/}
      {/*      icon={<FaBell size={25} />}*/}
      {/*      label={*/}
      {/*        <span>*/}
      {/*          Notifications{' '}*/}
      {/*          <span style={{ color: '#af2121' }}>*/}
      {/*            {unreadNotificationsCount > 0 &&*/}
      {/*              `(${unreadNotificationsCount})`}*/}
      {/*          </span>*/}
      {/*        </span>*/}
      {/*      }*/}
      {/*      {...a11yProps(0)}*/}
      {/*    />*/}
      {/*    <Tab*/}
      {/*      icon={<GoListUnordered size={25} />}*/}
      {/*      label={*/}
      {/*        <span>*/}
      {/*          Activities{' '}*/}
      {/*          <span style={{ color: '#af2121', fontSize: '12px' }}>*/}
      {/*            {hasUnseenActivities && '(new)'}*/}
      {/*          </span>*/}
      {/*        </span>*/}
      {/*      }*/}
      {/*      {...a11yProps(1)}*/}
      {/*    />*/}
      {/*  </Tabs>*/}
      {/*</AppBar>*/}
      {/*<SwipeableViews*/}
      {/*  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}*/}
      {/*  index={value}*/}
      {/*  onChangeIndex={handleChangeIndex}>*/}
      {/*  <TabPanel*/}
      {/*    style={{ padding: '0px' }}*/}
      {/*    value={value}*/}
      {/*    index={0}*/}
      {/*    dir={theme.direction}>*/}
      {/*    <NotificationsList*/}
      {/*      unreadNotificationsCount={unreadNotificationsCount}*/}
      {/*      setUnreadNotificationsCount={setUnreadNotificationsCount}*/}
      {/*      updateNotifications={updateNotifications}*/}
      {/*      setUpdateNotifications={setUpdateNotifications}*/}
      {/*    />*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        position: 'fixed',*/}
      {/*        bottom: '30px',*/}
      {/*        width: '100%',*/}
      {/*        textAlign: 'center',*/}
      {/*      }}>*/}
      {/*      <Button*/}
      {/*        variant="contained"*/}
      {/*        color="primary"*/}
      {/*        style={{*/}
      {/*          borderRadius: '20px',*/}
      {/*        }}*/}
      {/*        onClick={() => {*/}
      {/*          App.server.call('notifications.mark', 'all', true);*/}
      {/*          setUpdateNotifications(true);*/}
      {/*        }}>*/}
      {/*        <BsCheckCircle size={20} /> &nbsp; Mark All As Read*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  </TabPanel>*/}
      {/*  <TabPanel*/}
      {/*    style={{ padding: '0px' }}*/}
      {/*    value={value}*/}
      {/*    index={1}*/}
      {/*    dir={theme.direction}>*/}
      {/*    <ActivitiesList setHasUnseenActivities={setHasUnseenActivities} />*/}
      {/*  </TabPanel>*/}
      {/*</SwipeableViews>*/}
    </div>
  );
}
