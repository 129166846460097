import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import moment from 'moment';

function Stats(props) {
  const { data, item, filters } = props;

  const round = App.utils.number.round;

  if (!data || !data.totals) {
    return null;
  }

  const getPeriod = () => {
    let months;
    let days;

    if (filters.dateType === 'thisMonth') {
      months = 1;
    } else if (filters.dateType === 'lastThreeMonths') {
      months = 3;
    } else if (filters.dateType === 'lastSixMonths') {
      months = 6;
    } else if (filters.dateType === 'range' && filters.range.length >= 1) {
      const startDate = filters.range[0];
      const stopDate = filters.range[1];

      console.log(startDate);
      console.log(stopDate);

      const a = moment(startDate);
      const b = moment(stopDate);
      days = b.diff(a, 'days'); // =1
      months = days / 30;
    }

    if (!days) {
      days = months * 30;
    }
    const period = {
      months,
      days,
    };

    return period;
  };

  const period = getPeriod();

  console.log(period);

  const itemPadding = '10px';
  const renderInTransactions = () => {
    if (!filters.in && !filters.transferIn) {
      return null;
    }
    return (
      <div>
        <div className={'label'}>In Transactions Analysis</div>
        {filters.in && (
          <div>
            <div>In</div>
            <div
              style={{
                marginTop: '10px',
                border: '1px solid #cbcbcb',
                borderRadius: '9px',
              }}>
              {!isNaN(data.totals.totalIn) && (
                <div
                  className={'bottom-gray-border'}
                  style={{
                    padding: itemPadding,
                  }}>
                  Total In{' '}
                  <span
                    style={{
                      float: 'right',
                    }}>
                    {App.utils.number.thousandsSeparator(data.totals.totalIn)}{' '}
                    {item.type.unit}
                  </span>
                </div>
              )}
              <div
                className={'bottom-gray-border'}
                style={{
                  padding: itemPadding,
                }}>
                Average In / Day
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalIn / period.days
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
              <div
                style={{
                  padding: itemPadding,
                }}>
                Average In/Month
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalIn / period.months
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
            </div>
          </div>
        )}
        {filters.transferIn && (
          <div>
            <div
              style={{
                marginTop: '15px',
              }}>
              Transfer in from other stores
            </div>

            <div
              style={{
                marginTop: '10px',
                border: '1px solid #cbcbcb',
                borderRadius: '9px',
              }}>
              <div
                className={'bottom-gray-border'}
                style={{
                  padding: itemPadding,
                }}>
                Total Transfer In
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferIn
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
              <div
                className={'bottom-gray-border'}
                style={{
                  padding: itemPadding,
                }}>
                Average Transfer In/Day
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferIn / period.days
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
              <div
                style={{
                  padding: itemPadding,
                }}>
                Average Transfer In / Month
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferIn / period.months
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
            </div>
          </div>
        )}
        {Boolean(filters.transferIn) && Boolean(filters.in) && (
          <div>
            <div
              style={{
                marginTop: '15px',
              }}>
              Net In
            </div>
            <div
              style={{
                marginTop: '10px',
                border: '1px solid #cbcbcb',
                borderRadius: '9px',
              }}>
              <div
                style={{
                  padding: itemPadding,
                }}>
                Net In Movement
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferIn + data.totals.totalIn
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderOutTransactions = () => {
    if (!filters.in && !filters.transferIn) {
      return null;
    }
    return (
      <div>
        <div className={'label'}>Out Transactions Analysis</div>
        {Boolean(filters.out) && (
          <div>
            <div>Out</div>
            <div
              style={{
                marginTop: '10px',
                border: '1px solid #cbcbcb',
                borderRadius: '9px',
              }}>
              {!isNaN(data.totals.totalOut) && (
                <div
                  className={'bottom-gray-border'}
                  style={{
                    padding: itemPadding,
                  }}>
                  Total Out
                  <span
                    style={{
                      float: 'right',
                    }}>
                    {App.utils.number.thousandsSeparator(data.totals.totalOut)}{' '}
                    {item.type.unit}
                  </span>
                </div>
              )}
              <div
                className={'bottom-gray-border'}
                style={{
                  padding: itemPadding,
                }}>
                Average Out / Day
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalOut / period.days
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
              <div
                style={{
                  padding: itemPadding,
                }}>
                Average Out / Month
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalOut / period.months
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
            </div>
          </div>
        )}
        {filters.transferOut && (
          <div>
            <div
              style={{
                marginTop: '15px',
              }}>
              Transfer out from other stores
            </div>

            <div
              style={{
                marginTop: '10px',
                border: '1px solid #cbcbcb',
                borderRadius: '9px',
              }}>
              <div
                className={'bottom-gray-border'}
                style={{
                  padding: itemPadding,
                }}>
                Total Transfer Out
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferOut
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
              <div
                className={'bottom-gray-border'}
                style={{
                  padding: itemPadding,
                }}>
                Average Transfer Out / Day
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferOut / period.days
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
              <div
                style={{
                  padding: itemPadding,
                }}>
                Average Transfer Out / Month
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferOut / period.months
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
            </div>
          </div>
        )}
        {Boolean(filters.transferOut) && Boolean(filters.out) && (
          <div>
            <div
              style={{
                marginTop: '15px',
              }}>
              Net Out
            </div>
            <div
              style={{
                marginTop: '10px',
                border: '1px solid #cbcbcb',
                borderRadius: '9px',
              }}>
              <div
                style={{
                  padding: itemPadding,
                }}>
                Net Out Movement
                <span
                  style={{
                    float: 'right',
                  }}>
                  {App.utils.number.thousandsSeparator(
                    data.totals.totalTransferOut + data.totals.totalOut
                  )}{' '}
                  {item.type.unit}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div>{renderInTransactions()}</div>
      <div>{renderOutTransactions()}</div>
    </div>
  );
}
Stats.propTypes = {};

Stats.defaultProps = {};
export default Stats;
