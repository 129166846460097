import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { Loading } from '@meronex/components';

import ITime from '@meronex/icons/bi/BiTimeFive';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import StoresItemsList from '../storeItems/StoreItemsList';

import TopHeader from '../../../views/components/TopHeader';
import { Icons } from '../../../common/Icons';
import SitesAvatar from '../../../common/SitesAvatar';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function StoreDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, store } = props;

  if (!store) {
    return null;
  }

  const renderStoreDetails = () => {
    return (
      <Grid
        container
        style={{
          width: '100%',
          marginBottom: '10px',
        }}>
        <Grid item xs={3}>
          <SitesAvatar name={store.name} size={50} round={5} />
        </Grid>
        <Grid item xs={7}>
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'left',
            }}>
            <div>
              <Icons.Stores /> {App.utils.string.truncate(store.name, 24)}
            </div>
          </div>

          {/*{Array.isArray(storeItems) && (*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      color: 'gray',*/}
          {/*      position: 'relative',*/}
          {/*      top: '5px',*/}
          {/*      marginRight: '10px',*/}
          {/*      fontSize: '12px',*/}
          {/*    }}>*/}
          {/*    <span*/}
          {/*      style={{*/}
          {/*        position: 'relative',*/}
          {/*        top: '3px',*/}
          {/*      }}>*/}
          {/*      <Icons.Items />*/}
          {/*    </span>{' '}*/}
          {/*    {'Items Count:'}*/}
          {/*    &nbsp;{storeItems.length}*/}
          {/*  </div>*/}
          {/*)}*/}

          {store.updatedAt && (
            <div
              style={{
                marginTop: '5px',
                fontSize: '12px',
                color: 'gray',
              }}>
              <span
                style={{
                  position: 'relative',
                  top: '3px',
                }}>
                <ITime size={14} />
              </span>{' '}
              {'Updated:'}
              {App.utils.time.timeAgo(store.updatedAt.date)}
            </div>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopHeader
          title={store.name}
          onClose={onClose}
          icon={<Icons.Stores />}
        />
      </DialogTitle>
      <DialogContent
        style={{
          overflow: 'hidden',
        }}>
        <div>
          <div
            style={{
              border: '1px solid lightgray',
              borderRadius: '10px',
              padding: '10px',
              marginBottom: '5px',
            }}>
            {renderStoreDetails()}
          </div>
        </div>
        <div>
          <StoresItemsList store={store} viewOnly />
        </div>
      </DialogContent>
    </Dialog>
  );
}

StoreDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  store: PropTypes.object,
};

export default StoreDialog;
