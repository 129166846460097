import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, Form, ActionBtn, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import INews from '@meronex/icons/bs/BsNewspaper';
import IPreview from '@meronex/icons/mdc/MdcPresentation';
import NewsSectionForm from './NewsSectionForm';
import NewsSection from './NewsSection';

import { newsMng } from './api';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddNewsSectionDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, refresh } = props;

  const [newsSection, setNewsSection] = React.useState();
  const [previewNewsSection, setPreviewNewsSection] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setNewsSection({
      source: 'topHeadlines',
      language: 'any',
      country: 'ca',
      category: 'general',
      sortBy: 'publishedAt',
    });
    setPreviewNewsSection('');
    setLoading(false);
  }, [open]);

  const onPreviewClick = () => {
    const preview = { ...newsSection };
    preview.modifiedDate = new Date();
    setPreviewNewsSection(preview);
  };
  const onSave = async () => {
    setLoading(true);
    const result = await newsMng.createNewsSection(newsSection);
    onClose();
    setLoading(false);
    toast.success('News section saved!', {
      autoClose: 800,
    });
    refresh();
  };

  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <INews /> Add News Section
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '8px',
        }}>
        <NewsSectionForm
          newsSection={newsSection}
          onChange={(newsSection) => {
            setNewsSection(newsSection);
          }}
        />
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: '30px',
          }}>
          <Button
            onClick={() => {
              onPreviewClick();
            }}>
            <IPreview />
            &nbsp;Preview
          </Button>
        </div>
        <div style={{ marginTop: '10px' }}>
          <NewsSection newsSection={previewNewsSection} />
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}>
          <ActionBtn
            loading={loading}
            label={'Create'}
            onClick={() => onSave()}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
AddNewsSectionDialog.defaultProps = {
  open: false,
};
AddNewsSectionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddNewsSectionDialog;
