import { App } from '@meronex/app';

const { server } = App;

export const commentsMng = {};

const fetchSharedComments = async (subjectId) => {
  return await server.call('getSharedComments', {
    subjectId,
    sortByRecent: true,
  });
};

const addComment = async ({ subjectId, authorId, text }) => {
  return await server.call('saveSharedComment', {
    subjectId,
    authorId,
    text,
  });
};

const updateComment = async (commentId, text) => {
  return await server.call('updateSharedComment', { commentId, text });
};

const removeComment = async (commentId) => {
  return await server.call('removeSharedComment', { commentId });
};

const onAddComment = async (subjectId) => {
  return await server.call('incCommentCount', { _id: subjectId });
};

const onRemoveComment = async (subjectId) => {
  return await server.call('decCommentCount', { _id: subjectId });
};

commentsMng.fetchSharedComments = fetchSharedComments;
commentsMng.addComment = addComment;
commentsMng.updateComment = updateComment;
commentsMng.removeComment = removeComment;
commentsMng.onAddComment = onAddComment;
commentsMng.onRemoveComment = onRemoveComment;
