import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import iso from 'iso-3166-1';
import { countryCodeEmoji } from 'country-code-emoji';

function CountrySelect(props) {
  const { onChange, defaultValue } = props;

  const [selectedCountry, setSelectedCountry] = React.useState(defaultValue);

  let COUNTRIES = {
    AE: 'ae',
    AR: 'ar',
    AT: 'at',
    AU: 'au',
    BE: 'be',
    BG: 'bg',
    BR: 'br',
    CA: 'ca',
    CH: 'ch',
    CN: 'cn',
    CO: 'co',
    CU: 'cu',
    CZ: 'cz',
    DE: 'de',
    EG: 'eg',
    FR: 'fr',
    GB: 'gb',
    GR: 'gr',
    HK: 'hk',
    HU: 'hu',
    ID: 'id',
    IE: 'ie',
    IL: 'il',
    IN: 'in',
    IT: 'it',
    JP: 'jp',
    KR: 'kr',
    LT: 'lt',
    LV: 'lv',
    MA: 'ma',
    MX: 'mx',
    MY: 'my',
    NG: 'ng',
    NL: 'nl',
    NO: 'no',
    NZ: 'nz',
    PH: 'ph',
    PL: 'pl',
    PT: 'pt',
    RO: 'ro',
    RS: 'rs',
    RU: 'ru',
    SA: 'sa',
    SE: 'se',
    SG: 'sg',
    SI: 'si',
    SK: 'sk',
    TH: 'th',
    TR: 'tr',
    TW: 'tw',
    UA: 'ua',
    US: 'us',
    VE: 've',
    ZA: 'za',
  };

  COUNTRIES = Object.values(COUNTRIES).map((v) => {
    const country = iso.whereAlpha2(v);
    country.emoji = countryCodeEmoji(v);
    return country;
  });
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Country</InputLabel>
      <Select
        label={'Country'}
        value={selectedCountry}
        fullWidth
        onChange={(v) => {
          setSelectedCountry(v.target.value);
          onChange(v.target.value);
        }}>
        {COUNTRIES.map((c) => {
          return (
            <MenuItem
              key={c.alpha2.toLowerCase()}
              value={c.alpha2.toLowerCase()}>
              {' '}
              {c.emoji}&nbsp;&nbsp;{c.country}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
CountrySelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

CountrySelect.defaultProps = {
  onChange: (v) => console.log(v),
};
export default CountrySelect;
