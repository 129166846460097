import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';

export default function Loading(props) {
  const {
    type = 'circular',
    count = 5,
    gifStyle,
    containerStyle,
    text,
    onClick,
    url,
    delay,
    isShowRetry,
  } = props;

  const [reloading, setReloading] = React.useState();
  const [showRetry, setShowRetry] = React.useState(isShowRetry);
  const [delayFinished, setDelayFinished] = React.useState(false);
  const mergedContainerStyle = Object.assign(
    {
      textAlign: 'center',
      position: 'relative',
      padding: '50px',
    },
    containerStyle
  );

  const mergedGifStyle = Object.assign(
    {
      width: '280px',
      position: 'relative',
    },
    gifStyle
  );

  React.useEffect(() => {
    if (isShowRetry) {
      window.isLoadingMounted = true;
      window.retryInterval = setInterval(() => {
        if (window.isLoadingMounted) {
          setShowRetry(true);
        }
      }, 1000 * 6);
    }

    return () => {
      window.isLoadingMounted = false;
      window.clearInterval(window.retryInterval);
      window.clearTimeout(window.delayTimeout);
      window.clearTimeout(window.reloadTimeout);
      setDelayFinished(false);
      setShowRetry(false);
    };
  }, []);

  const renderRetry = (color) => {
    if (!showRetry) {
      return <span />;
    }
    return (
      <div>
        <div style={{ marginTop: '10px' }}>If taking long, try reloading</div>

        <Button
          style={{
            marginBottom: '10px',
            marginTop: '10px',
            color,
          }}
          onClick={() => {
            setReloading(true);
            window.reloadTimeout = setTimeout(
              () => window.location.reload(),
              500
            );
          }}
          endIcon={
            reloading ? (
              <CircularProgress size={12} color={color ? color : 'gray'} />
            ) : (
              <div />
            )
          }>
          Reload
        </Button>
      </div>
    );
  };

  if (type === 'serverCall') {
    const content = (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          background: 'rgb(78 78 78 / 89%)',
          color: 'white',
          bottom: '0px',
          position: 'absolute',
          paddingTop: '5px',
        }}>
        <div
          key={'loader'}
          style={{
            marginTop: '30px',
            textAlign: 'center',
            width: '100%',
          }}>
          {' '}
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={24}
            thickness={4}
          />
          <div
            style={{
              paddingBottom: '20px',
            }}>
            Loading...
          </div>
          <div>{renderRetry('white')}</div>
        </div>
      </div>
    );
    if (delay) {
      window.delayTimeout = setTimeout(() => {
        setDelayFinished(true);
      }, delay);
      return <div>{delayFinished && content}</div>;
    } else {
      return content;
    }
  } else if (type === 'img') {
    return (
      <div onClick={onClick} style={mergedContainerStyle}>
        <div>
          {url && (
            <div>
              <img style={mergedGifStyle} src={url} />
            </div>
          )}
          <div
            style={{ fontWeight: '400', marginTop: '40px', fontSize: '16px' }}>
            {text}
          </div>
        </div>
        {renderRetry()}
      </div>
    );
  } else if (type === 'skeletons') {
    const loadingBlock = (
      <div
        className={'shadow'}
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          marginBottom: '15px',
        }}>
        <Skeleton height={'100px'} />
        <Skeleton />
        <Skeleton />
      </div>
    );
    return Array(count)
      .fill()
      .map((x) => loadingBlock);
  }
  return (
    <div
      key={'loader'}
      style={{
        marginTop: '30px',
        marginBottom: '125px',
        textAlign: 'center',
      }}>
      {' '}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={24}
        thickness={4}
      />
      <div style={{ color: 'gray' }}>Loading...</div>
      <div>{renderRetry()}</div>
    </div>
  );
}

Loading.propTypes = {
  type: PropTypes.oneOf(['circular', 'skeletons', 'img']),
  containerStyle: PropTypes.object,
  gifStyle: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  delay: PropTypes.number,
  isShowRetry: PropTypes.bool,
};

Loading.defaultProps = {
  style: {},
  type: 'circular',
  onClick: () => {},
  text: 'Loading...',
};
