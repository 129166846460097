import React from 'react';
import PropTypes from 'prop-types';
import Timer from 'react-compound-timer';
import moment from 'moment';

function ActiveStepInfo(props) {
  const { workOrder } = props;

  if (!workOrder) {
    return <div />;
  }

  let activeStep;
  let activeStepIndex;
  if (
    workOrder &&
    workOrder.product &&
    Array.isArray(workOrder.product.items)
  ) {
    workOrder.product.items.forEach((i, index) => {
      if (i.timer && i.timer.active) {
        activeStep = i;
        activeStepIndex = index;
      }
    });
  }

  if (!activeStep || !activeStep.timer || isNaN(activeStepIndex)) {
    return <div />;
  }
  return (
    <div
      style={{
        fontSize: '14px',
        marginTop: '10px',
      }}>
      <span>
        Step {activeStepIndex + 1}
        {activeStep.equipment && <span> - {activeStep.equipment.name} - </span>}
        <span key={activeStep._id}>
          <Timer
            startImmediately={!!(activeStep.timer || {}).active}
            checkpoints={[
              {
                time: 0,
                callback: () => {},
              },
            ]}
            initialTime={moment(moment()).diff(activeStep.timer.startDate)}
            direction="forward">
            {() => (
              <React.Fragment>
                <span
                  style={{
                    fontSize: '16px',
                    marginTop: '0x',
                    color: (activeStep.timer || {}).active
                      ? 'rgb(2 139 44)'
                      : 'inherit',
                  }}>
                  <Timer.Days />d <Timer.Hours />h <Timer.Minutes />m{' '}
                  <Timer.Seconds />s
                </span>
              </React.Fragment>
            )}
          </Timer>
        </span>
      </span>
    </div>
  );
}
ActiveStepInfo.propTypes = {
  workOrder: PropTypes.object,
};

ActiveStepInfo.defaultProps = {};
export default ActiveStepInfo;
