import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SettingsDialog from '../settings/SettingsDialog';

export default function SettingsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleToggle}
        aria-label="display more actions"
        edge="end"
        color="inherit">
        <MoreIcon />
      </IconButton>
      <SettingsDialog open={open} onClose={() => setOpen(false)} />
      {/*<Menu*/}
      {/*  id="simple-menu"*/}
      {/*  anchorEl={anchorRef.current}*/}
      {/*  // style={{*/}
      {/*  //   position: 'relative',*/}
      {/*  //   marginTop: '-10px',*/}
      {/*  // }}*/}
      {/*  keepMounted*/}
      {/*  open={open}*/}
      {/*  onClose={handleClose}>*/}
      {/*  <MenuItem onClick={handleClose}>Profile</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>My account</MenuItem>*/}
      {/*  <MenuItem onClick={handleClose}>Logout</MenuItem>*/}
      {/*</Menu>*/}
    </>
  );
}

SettingsMenu.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
