import React from 'react';
import PropTypes from 'prop-types';

import { useWindowSize } from '@react-hook/window-size';
import CssBaseline from '@material-ui/core/CssBaseline';
import { create } from 'jss';
import rtl from 'jss-rtl';

import {
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { ErrorBoundary } from 'react-error-boundary';

import {
  FirstTimeUserChecker,
  ServerChecker,
  VersionChecker,
  StatusIndicator,
  ActionBtn,
  NoData,
} from '../components/index';
import { AppContext } from '../base/AppContext';

export default function IndexContainer(props) {
  const { config, theme, App } = props;
  const [width, height] = useWindowSize();
  const size = { height, width };
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const getTheme = (themeType) => {
    return themeType === 'dark' ? theme.dark : theme.light;
  };

  const [activeTheme, setActiveTheme] = React.useState(getTheme(App.themeType));
  const [activeLanguage, setActiveLanguage] = React.useState(
    App.languageManager.language
  );
  App.config = config;
  App.size = size;
  App.theme = theme;

  React.useEffect(() => {
    App.themeManager.addThemeListener((themeType) => {
      const activeTheme = getTheme(themeType);
      setActiveTheme(activeTheme);
    });
    App.languageManager.addLanguageListener((language) => {
      console.log(`[App/indexContainer] setting active language ${language}`);
      setActiveLanguage(language);
    });
  }, []);

  // init the language direction
  const dir = App.languageManager.getDirection(activeLanguage);
  console.log(`[App/indexContainer] setting theme direction to ${dir}`);
  activeTheme.direction = dir;
  document.body.setAttribute('dir', dir);

  console.log('[App/indexContainer] Active theme doc:');
  console.log(activeTheme);

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div
        role="alert"
        style={{
          margin: '0',
          position: 'absolute',
          top: '40%',
          transform: 'translateY(-50%)',
          textAlign: 'center',
          width: '100%',
        }}>
        <NoData type={'warn'} message={'Ops, something went wrong'} />
        <div
          style={{
            width: '100%',
            fontFamily: 'monospace',
          }}>
          {error.message}
        </div>
        <ActionBtn
          label={'Reload the App'}
          style={{
            marginTop: '50px',
          }}
          onClick={resetErrorBoundary}>
          Reload
        </ActionBtn>
      </div>
    );
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={() => {}}
      onReset={() => {
        window.location.reload(true);
        // reset the state of your app so the error doesn't happen again
      }}>
      <ThemeProvider theme={activeTheme}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          <AppContext.Provider value={{ App }}>
            {!App.opts.disableServer && (
              <>
                <VersionChecker showHelperText={false} changelog={true} />
                <ServerChecker />
                <StatusIndicator />
              </>
            )}
            {!App.opts.disableServer && !App.opts.disableUserAccount && (
              <FirstTimeUserChecker />
            )}
            <div>{props.children}</div>
          </AppContext.Provider>
        </StylesProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

IndexContainer.propTypes = {
  theme: PropTypes.object,
  config: PropTypes.object,
  children: PropTypes.element.isRequired,
};
