import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { toast } from 'react-toastify';

import IIn from '@meronex/icons/bi/BiImport';
import IStores from '@meronex/icons/mdc/MdcWarehouse';
import IWarn from '@meronex/icons/ti/TiWarning';

import ActionInfo from './ActionInfo';
import { storesMng } from '../../../api/storesMng';
import { playSound } from '../../../../common/helpers';
import TopBar from '../../../../views/components/TopHeader';

import { helpers } from '../../../../common/helpers';

function ItemInDialog(props) {
  const { open, onClose, item, onItemIn } = props;

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [onNoteFocus, setOnNoteFocus] = React.useState();
  const [error, setError] = React.useState('');
  const [isValidBool, setIsValidBool] = React.useState(false);
  const [amountBlurred, setAmountBlurred] = React.useState(false);

  React.useEffect(() => {
    setIsValidBool(isValid());
  }, [data, amountBlurred]);

  React.useEffect(() => {
    setError('');
  }, [onClose]);

  React.useEffect(() => {
    window.loading = false;
  }, [open]);

  const _onClose = () => {
    setData({});
    onClose();
    window.loading = false;
    setTimeout(() => {
      setAmountBlurred(false);
      setIsValidBool(false);
    }, 500);
  };

  const onSubmit = async () => {
    console.log('on submit');
    if (!window.loading) {
      window.loading = true;
      console.log(data.adjustedUnitRate);

      const inTx = {
        storeItemId: item._id,
        amount: data.amount || 0,
        note: data.note,
        groupId: data.group ? data.group.id : null,
      };

      if (data.adjustedUnitRate) {
        inTx.adjustedUnitRate = data.adjustedUnitRate;
      }
      const result = await storesMng.storeItem.actions.inStoreItem(inTx);

      onItemIn(() => {
        toast.success('Amount successfully added!', {
          autoClose: 900,
        });
        playSound('in');
        window.loading = false;
        _onClose();
      });
    }
  };

  if (!item) {
    return null;
  }

  const isValid = () => {
    const _amountFloat = Number.parseFloat(data.amount);

    // validate blurring on amount
    if (isNaN(_amountFloat)) {
      if (amountBlurred) {
        setError('Amount is required');
      }
      return false;
    }
    // validate negative amount
    if (_amountFloat < 0) {
      setError('Negative amount value is not allowed');
      return false;
    }
    // validate negative store quantity
    const _quantity = item.quantity + (_amountFloat || 0);
    if (!item.store.allowNegatives && _quantity < 0) {
      setError('Negative store quantity is not allowed');
      return false;
    }
    if (item.store.enableDynamicPrice && isNaN(data.adjustedUnitRate)) {
      setError('Adjusted unit rate is required');
      return false;
    }
    // clear error and set valid
    setError('');
    return true;
  };

  return (
    <Dialog
      open={open}
      className={'fullScreenMobile'}
      scoll={'body'}
      onClose={onClose}>
      <DialogTitle>
        <TopBar
          title={`${item.store.name}`}
          icon={
            <span>
              <IStores /> <IIn size={22} color={'green'} />
            </span>
          }
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}>
        <ActionInfo
          autoFocusQuantity
          hideImage
          enableAdjustedUnitRateInput
          onChange={(v) => {
            setData(v);
          }}
          onNoteFocusUpdate={(v) => {
            setOnNoteFocus(v);
          }}
          onAmountBlur={() => {
            setAmountBlurred(true);
          }}
          type={helpers.TRANSACTION_TYPES.in}
          item={item}
        />
        {error && (
          <div
            style={{
              marginTop: '10px',
            }}>
            <span style={{ color: 'red' }}>
              <IWarn /> {error}
            </span>
          </div>
        )}
        <div
          style={{
            position: 'relative',
            textAlign: 'center',
            width: '100%',
          }}>
          <ActionBtn
            style={{
              marginBottom: '40px',
              marginTop: '20px',
            }}
            loading={window.loading}
            onClick={() => {
              if (!window.loading) {
                onSubmit();
              }
            }}
            onEnter={() => {
              // if the note is not focused
              if (!error && !onNoteFocus && !window.loading) {
                onSubmit();
              }
            }}
            disabled={!isValidBool || window.loading || error}
            label={
              <span>
                <span
                  style={{
                    marginRight: '10px',
                    top: '4px',
                    position: 'relative',
                  }}>
                  <IIn size={22} />
                </span>
                INPUT
              </span>
            }
          />
          <div>
            <Button
              onClick={() => {
                _onClose();
              }}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ItemInDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onItemIn: PropTypes.func,
};

export default ItemInDialog;
