import React from 'react';
import PropTypes from 'prop-types';
import ItemTypesDialog from '../../stores/components/itemTypes/ItemTypesDialog';
import StoresMng from '../../stores/api/storesMng';

function SettingsItemTypesDialog(props) {
  const { open, onClose, permissions } = props;

  const [itemTypes, setItemTypes] = React.useState();

  const getAllItemTypes = async () => {
    const itemTypes = await StoresMng.getAllItemTypes();
    setItemTypes(itemTypes);
  };

  React.useEffect(() => {
    getAllItemTypes();
  }, [open]);

  return (
    <div>
      {open && (
        <ItemTypesDialog
          itemTypes={itemTypes}
          permissions={permissions}
          open={open}
          onItemTypeUpdate={(cb) => {
            console.log('on item update');
            getAllItemTypes();
            if (cb) {
              cb();
            }
          }}
          onItemTypeCreate={(cb) => {
            getAllItemTypes();
            if (cb) {
              cb();
            }
            console.log('on item type create');
          }}
          onItemTypeDelete={(cb) => {
            getAllItemTypes();
            if (cb) {
              cb();
            }
          }}
          enableAddToStore={false}
          onClose={onClose}
        />
      )}
    </div>
  );
}
SettingsItemTypesDialog.propTypes = {};
SettingsItemTypesDialog.defaultProps = {};

export default SettingsItemTypesDialog;
