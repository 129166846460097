import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TopHeader from '../../../views/components/TopHeader';
import { productsMng } from '../../api/ProductsMng';
import ProductsList from './ProductsList';
import AddProductDialog from './AddProductDialog';
import Fab from '@material-ui/core/Fab';
import IAdd from '@meronex/icons/md/MdAdd';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function ProductsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    onClose,
    factory,
    onWorkOrderAdded,
    readOnly,
    onDraftCreate,
  } = props;

  const [products, setProducts] = React.useState();
  const [filterValue, setFilterValue] = React.useState();
  const [openAddProduct, setOpenAddProduct] = React.useState();
  const [currentFactory, setCurrentFactory] = React.useState(factory);

  React.useEffect(() => {
    if (factory) {
      if (!products) {
        fetchProducts(factory);
      } else if (currentFactory) {
        if (factory._id !== currentFactory._id) {
          fetchProducts(factory);
        }
      } else {
        fetchProducts(factory);
      }
      setCurrentFactory(factory);
    }
  }, [factory]);

  const fetchProducts = async (_factory = factory) => {
    console.log('fetch products');
    if (_factory) {
      const products = await productsMng.getProducts(_factory._id);
      setProducts(products);
      console.log(products);
    }
  };

  const filterProducts = (products) => {
    let _products = products;

    if (filterValue && Array.isArray(products)) {
      _products = (products || []).filter((p) =>
        p.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return _products;
  };

  const _onClose = () => {
    setFilterValue('');
    onClose();
  };
  return (
    <>
      <Dialog fullScreen open={open} onClose={_onClose}>
        <DialogTitle>
          <TopHeader
            title={'All Products'}
            onClose={_onClose}
            filterProps={{
              onFilter: (v) => setFilterValue(v),
              placeholder: `search ${products ? products.length : ''} products`,
            }}
          />
        </DialogTitle>
        <DialogContent
          style={{
            padding: '5px',
          }}>
          <ProductsList
            products={filterProducts(products)}
            onDelete={() => {
              fetchProducts();
            }}
            readOnly={readOnly}
            showOnlyProductName={filterValue}
            factory={factory}
            onStart={onDraftCreate}
            onEdit={fetchProducts}
            onWorkOrderAdded={onWorkOrderAdded}
            onAdd={() => {
              setOpenAddProduct(true);
            }}
          />
          {!readOnly && (
            <Fab
              onClick={() => {
                setOpenAddProduct(true);
              }}
              style={{
                position: 'fixed',
                right: '25px',
                bottom: '30px',
              }}>
              <IAdd size={25} />
            </Fab>
          )}
        </DialogContent>
      </Dialog>
      {openAddProduct && (
        <AddProductDialog
          open={openAddProduct}
          factory={factory}
          onAdd={() => {
            console.log('on add');
            fetchProducts();
          }}
          onClose={() => {
            setOpenAddProduct(false);
          }}
        />
      )}
    </>
  );
}

ProductsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onProductClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ProductsDialog;
