import React from 'react';
import PropTypes from 'prop-types';

import { Form, ImageUploader } from '@meronex/components';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

import { factoriesMng } from '../../factories/api/FactoriesMng';

function EquipmentForm(props) {
  const { onChange = () => {}, values = {}, validateOnInit, factory } = props;

  const previewContainerRef = React.useRef();

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);

  const [factories, setFactories] = React.useState();

  const getFactories = async () => {
    const factories = await factoriesMng.getFactories();
    setFactories(factories);
  };
  React.useEffect(() => {
    _onChange(values);
  }, [uploading, imgUrl, thumbUrl]);

  React.useEffect(() => {
    if (!factory) {
      getFactories();
    }
  }, []);
  const _onChange = (values) => {
    if (values) {
      if (imgUrl && thumbUrl) {
        values.image = {
          imgUrl,
          thumbUrl,
        };
      }
      values.uploading = uploading;
    }
    onChange(values);
  };

  const renderFactories = () => {
    if (!Array.isArray(factories)) {
      return <MenuItem value={1}>Loading</MenuItem>;
    }
    if (factories.length === 0) {
      return <MenuItem value={'loading'}>Loading</MenuItem>;
    }
    return factories.map((f) => {
      return <MenuItem value={f._id}>{f.name}</MenuItem>;
    });
  };

  return (
    <div>
      <Form
        validateOnInit={validateOnInit}
        blurDelay={500}
        onUpdate={(_data) => {
          console.log(_data);
          if (_data) {
            const values = _data.values;
            if (values) {
              values.checked = _data.checked;
              values.isValid = _data.isValid;
            }

            _onChange(values);
          }
        }}>
        <TextField
          name={'name'}
          label="Name"
          autoFocus={true}
          defaultValue={(values || {}).name}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
        />
        <TextField
          name={'uniqid'}
          label="Id"
          defaultValue={(values || {}).uniqid}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Id is required';
            return true;
          }}
        />
        <TextField
          name={'brand'}
          label="Brand"
          defaultValue={(values || {}).brand}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {!factory && (
          <TextField
            style={{
              marginTop: '5px',
            }}
            variant="outlined"
            label="Factory"
            name={'factoryId'}
            defaultValue={(values || {}).factoryId}
            fullWidth
            select>
            {renderFactories()}
          </TextField>
        )}
        <div
          style={{
            marginTop: '10px',
          }}>
          <ImageUploader
            defaultPreviewUrl={
              values && values.image ? values.image.imgUrl : ''
            }
            previewContainer={previewContainerRef}
            onUploadStart={() => {
              setUploading(true);
            }}
            onUploadSuccess={(imageData) => {
              console.log(imageData);
              setImgUrl(imageData.imgUrl);
              setThumbUrl(imageData.thumbUrl);
              setUploading(false);
            }}
          />
        </div>
      </Form>
      <div ref={previewContainerRef} style={{ width: '100%' }} />
    </div>
  );
}
EquipmentForm.propTypes = {
  values: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
};

EquipmentForm.defaultProps = {
  data: {},
  validateOnInit: false,
  onChange: (data) => console.log(data),
};
export default EquipmentForm;
