import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import WorkOrderStepper from '../createWorkOrder/WorkOrderStepper';
import WorkOrderInfo from '../createWorkOrder/WorkOrderInfo';
import { workOrdersMng } from '../../../api/WorkOrdersMng';
import { App } from '@meronex/app';
import { can, p } from '../../../../common/roles';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function DraftDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, draft, onWorkOrderAdded } = props;

  const [loading, setLoading] = React.useState();
  if (!draft) {
    return <div />;
  }
  const onCreate = async (workOrder) => {
    console.log('handle finish');
    setLoading(true);
    try {
      // remove the draft createdAt
      // it will be replaced by a new one at
      // the server
      delete workOrder.createdAt;
      const result = await workOrdersMng.createWorkOrder(workOrder);
      onWorkOrderAdded();
      onClose();
      toast.success('Work order successfully created!');
    } catch (e) {
      if (e.error === 'createWorkOrder.storeItemNotFound') {
        toast.error('One or more store items are not found at the store!', {
          autoClose: 5000,
        });
      } else if (e.error === 'updateStoreItemGroup.noProductStore') {
        toast.error(e.reason, {
          autoClose: 5000,
        });
        console.error(e);
      } else {
        toast.error('Ops, something went wrong.');
        console.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Draft Dialog
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <WorkOrderInfo workOrder={draft} product={draft.product} />
        <WorkOrderStepper
          product={draft.product}
          factory={draft.factory}
          loading={false}
          draft={draft}
          onChange={(wo) => {
            console.log('on change');
            console.log(wo);
          }}
          onNext={() => {
            console.log('on next');
          }}
          onBack={() => {
            console.log('on back');
          }}
          onSummary={() => {
            console.log('onSummary');
          }}
          onCreate={(workOrder) => {
            console.log('on create');
            onCreate(workOrder);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

DraftDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onWorkOrderAdded: PropTypes.func,
};
DraftDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onWorkOrderAdded: () => {},
};

export default DraftDialog;
