import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { App } from '../base/AppContext';

const isLogged = () => {
  const token = App.clientSettings.getItem('token');
  return token != null;
};

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLogged() === true ? (
          <Component {...props} {...rest} publicPage={false} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
