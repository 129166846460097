import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Section, NoData, toast } from '@meronex/components';
import { noImage2 } from '@meronex/assets';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import IConfigure from '@meronex/icons/go/GoSettings';
import Skeleton from '@material-ui/lab/Skeleton';

import { newsMng } from './api';

import { App } from '@meronex/app';

const number = App.utils.number;

function NewsSection(props) {
  const {
    newsSection,
    titleColor,
    dragHandle,
    onItemClick,
    refresh,
    fullscreen,
  } = props;

  const [loading, setLoading] = React.useState(true);
  let [cards, setCards] = React.useState();
  const [error, setError] = React.useState();
  const [currentNewsSection, setCurrentNewsSection] = React.useState(
    newsSection
  );

  const fetchNewsSection = async () => {
    setError();
    setLoading(true);
    try {
      const result = await newsMng.getNewsSectionFeed(newsSection);
      setCards(result);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (newsSection) {
      if (refresh || loading || !currentNewsSection) {
        fetchNewsSection();
      } else if (
        currentNewsSection &&
        typeof newsSection.modifiedDate === 'object' &&
        typeof currentNewsSection.modifiedDate === 'object' &&
        newsSection.modifiedDate.getTime() !==
          currentNewsSection.modifiedDate.getTime()
      ) {
        fetchNewsSection();
      }
    }
    setCurrentNewsSection(newsSection);
  }, [newsSection, refresh]);

  if (!newsSection) {
    return null;
  }

  const renderCards = (cards = []) => {
    const titles = [];

    if (cards.length === 0) {
      return (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            fontSize: '12px',
            color: 'gray',
          }}>
          <div>No news available, try different filters.</div>
        </div>
      );
    }
    // We need at least one image to render the images row,
    // sometimes the API is broken and no images are shown for all the cards.
    let hasImages = false;
    cards.forEach((card) => {
      if (card.image.url) {
        hasImages = true;
      }
    });
    return cards.map((newsCard, index) => {
      if (titles.includes(newsCard.title)) {
        return null;
      }
      titles.push(newsCard.title);
      return (
        <Section.SectionItem key={index}>
          <div
            onClick={() => {
              window.open(newsCard.url);
            }}>
            {hasImages && (
              <div
                style={{
                  width: '180px',
                  height: '110px',
                  borderRadius: '10px',
                  backgroundImage: `url("${newsCard.image.url || noImage2}")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            )}
            <div
              style={{
                width: '180px',
                whiteSpace: 'pre-wrap',
                fontWeight: 'bold',
                textAlign: 'left',
                fontSize: '14px',
                marginTop: '5px',
                marginBottom: '5px',
              }}>
              {newsCard.title}
            </div>
            <div style={{ textAlign: 'left' }}>
              {moment(newsCard.date).format('L')}.
            </div>
            <div style={{ textAlign: 'left' }}>{newsCard.author}</div>
          </div>
        </Section.SectionItem>
      );
    });
  };
  const renderTitle = (text) => {
    return (
      <div
        style={{
          color: titleColor,
          width: '100%',
          position: 'relative',
          marginBottom: '10px',
          fontWeight: 'bold',
          borderBottom: '1px solid #80808099',
          paddingBottom: '10px',
          textAlign: 'center',
          fontSize: '16px',
        }}>
        {!fullscreen && (
          <div style={{ position: 'absolute', top: '-10px' }}>{dragHandle}</div>
        )}
        {text}
        {!fullscreen && (
          <IconButton
            onClick={() => onItemClick(newsSection)}
            style={{
              position: 'absolute',
              right: '-5px',
              top: '-10px',
            }}>
            <IConfigure size={16} color={titleColor} />
          </IconButton>
        )}
      </div>
    );
  };

  const getNode = () => {
    if (error) {
      console.log(error);
      if (error.error === 'getNewsSectionFeed.tooManyRequests') {
        return (
          <div
            style={{ marginTop: '12px', color: 'gray', paddingBottom: '10px' }}>
            Rate limit exceeded, please try again later
          </div>
        );
      } else
        return (
          <div
            style={{ marginTop: '12px', color: 'gray', paddingBottom: '10px' }}>
            {error.reason}
          </div>
        );
    }
  };

  const renderSkeletons = () => {
    let skeletons = [];

    for (let i = 0; i < 10; i += 1) {
      skeletons.push(
        <Section.Item key={i}>
          <Skeleton
            variant="rectangular"
            width={180}
            height={110}
            style={{
              borderRadius: '10px',
              backgroundColor: 'gray',
            }}
          />
          <Skeleton
            variant="rectangular"
            width={number.randomIntFromInterval(165, 180)}
            height={8}
            style={{
              marginTop: '5px',
              borderRadius: '10px',
              backgroundColor: 'gray',
            }}
          />
          <Skeleton
            variant="rectangular"
            width={number.randomIntFromInterval(165, 180)}
            height={10}
            style={{
              marginTop: '5px',
              borderRadius: '10px',
              backgroundColor: 'gray',
            }}
          />
          <Skeleton
            variant="rectangular"
            width={number.randomIntFromInterval(165, 180)}
            height={10}
            style={{
              marginTop: '5px',
              borderRadius: '10px',
              backgroundColor: 'gray',
            }}
          />
          <Skeleton
            variant="rectangular"
            width={number.randomIntFromInterval(60, 95)}
            height={10}
            style={{
              marginTop: '5px',
              borderRadius: '10px',
              backgroundColor: 'gray',
            }}
          />
          <Skeleton
            variant="rectangular"
            width={number.randomIntFromInterval(60, 95)}
            height={10}
            style={{
              marginTop: '5px',
              borderRadius: '10px',
              backgroundColor: 'gray',
            }}
          />
          {number.randomIntFromInterval(0, 1) === 0 && (
            <Skeleton
              variant="rectangular"
              width={number.randomIntFromInterval(65, 95)}
              height={10}
              style={{
                marginTop: '5px',
                borderRadius: '10px',
                backgroundColor: 'gray',
              }}
            />
          )}
        </Section.Item>
      );
    }
    return skeletons;
  };

  return (
    <div
      style={{
        backgroundColor: 'rgba(14, 14, 14, 0.9)',
        borderRadius: '5px',
        color: 'white',
      }}>
      <Section
        node={getNode()}
        title={renderTitle(newsSection.name)}
        contentClassName={'news-section'}
        onConfigure={() => {
          console.log('configure click');
        }}>
        {!Array.isArray(cards) && renderSkeletons()}
        {Array.isArray(cards) && renderCards(cards)}
      </Section>
    </div>
  );
}
NewsSection.propTypes = {
  titleColor: PropTypes.string,
  newsSection: PropTypes.object,
  onItemClick: PropTypes.func,
  refresh: PropTypes.bool,
};

NewsSection.defaultProps = {
  titleColor: 'white',
};
export default NewsSection;
