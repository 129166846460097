import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import { toast } from 'react-toastify';

import { NoData, InfiniteScroll } from '@meronex/components';
import IWarn from '@meronex/icons/ri/RiErrorWarningLine';

import Divider from '@material-ui/core/Divider/Divider';

import WorkOrderDialog from './WorkOrderDialog';
import WorkOrderDetails from './WorkOrderDetails';
import { workOrdersMng } from '../../../api/WorkOrdersMng';

const { server } = App;

const WorkOrdersList = React.forwardRef((props, ref) => {
  const { fetchWorkOrders, factory, filters } = props;

  const [openWorkOrderDialog, setOpenWorkOrderDialog] = React.useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = React.useState();
  const [isScrolling, setIsScrolling] = React.useState();

  const infiniteScrollRef = React.useRef(null);

  const [workOrderSub, setWorkOrderSub] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    refresh() {
      refresh();
    },
  }));

  React.useEffect(() => {
    if (factory) {
      fetchWorkOrders();
    }
  }, [factory]);

  const onWorkOrderReverse = () => {
    refresh();
    App.eventsManager.emit('event-work-order-reversed', 'WorkOrdersList');
  };
  // if (workOrderSub) {
  //   let workOrdersCursor = server.collection('workOrders');
  //   workOrdersCursor.onChange((newData) => {
  //     console.log('on change');
  //     console.log(newData);
  //     if (newData && newData.added) {
  //       if (!window.lastAdded || window.lastAdded.id !== newData.added.id) {
  //         console.log('refresh');
  //         window.lastAdded = newData.added;
  //         console.log(newData);
  //         refresh();
  //       }
  //     }
  //   });
  // }

  React.useEffect(() => {
    refresh();
  }, [factory, filters]);

  const refresh = (scrollToTop = true) => {
    console.log('refresh list');
    if (
      infiniteScrollRef.current &&
      typeof infiniteScrollRef.current.refreshList === 'function'
    )
      infiniteScrollRef.current.refreshList(scrollToTop);
  };
  const onClick = (e, workOrder) => {
    setSelectedWorkOrder(workOrder);
    setOpenWorkOrderDialog(true);
  };

  const updateWorkOrder = async ({ state, status, note }) => {
    try {
      const result = workOrdersMng.updateWorkOrder({
        _id: selectedWorkOrder._id,
        status,
        state,
        note,
      });
      if (state) {
        const _selectedWorkOrder = { ...selectedWorkOrder };
        _selectedWorkOrder.state = state;
        setSelectedWorkOrder(_selectedWorkOrder);
      }
      refresh(false);
      toast.success('Work order updated', {
        autoClose: 1000,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    }
  };
  const onStatusClick = (status) => {
    console.log('on status change');
    console.log(status);
    updateWorkOrder({ status });
    refresh(false);
  };

  const onStatenChange = (state) => {
    console.log('on state change');
    console.log(state);
    updateWorkOrder({ state });
    refresh(false);
  };

  const getItemHeight = (item) => {
    console.log(item);
    let height = 210;
    if (item.expiration) {
      height += 15;
    }
    if (item.state && item.state.flagged && item.state.flagged.reason) {
      height += 45;
    }
    return height;
  };
  return (
    <div>
      <InfiniteScroll
        ref={infiniteScrollRef}
        height={window.innerHeight - 85}
        listStyle={{
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
        renderItem={(data, isScrolling, index) => {
          return (
            <>
              <div
                onClick={(e) => onClick(e, data)}
                style={{
                  minHeight: getItemHeight(data) - 40,
                }}>
                <WorkOrderDetails
                  workOrder={data}
                  showExpiry={false}
                  showId={false}
                />
              </div>
              <Divider
                style={{
                  marginTop: '15px',
                }}
              />
            </>
          );
        }}
        itemHeight={(item) => {
          return getItemHeight(item);
        }}
        lastItemHeight={(item) => {
          return getItemHeight(item) + 80;
        }}
        onScroll={(v) => setIsScrolling(v)}
        noRowRenderer={() => {
          return (
            <div>
              <NoData
                style={{
                  padding: '50px',
                }}
                icon={<IWarn size={40} color={'#c8c8c8'} />}
                message={'No Work Orders History'}
              />
            </div>
          );
        }}
        getListItems={fetchWorkOrders}
      />
      {openWorkOrderDialog && (
        <div>
          <WorkOrderDialog
            onReverse={onWorkOrderReverse}
            onStatusClick={(status) => {
              onStatusClick(status);
              setOpenWorkOrderDialog(false);
            }}
            onStateChange={onStatenChange}
            workOrder={selectedWorkOrder}
            open={openWorkOrderDialog}
            onClose={() => {
              setOpenWorkOrderDialog(false);
            }}
          />
        </div>
      )}
    </div>
  );
});

WorkOrdersList.propTypes = {
  onScroll: PropTypes.func,
  factory: PropTypes.object,
  fetchWorkOrders: PropTypes.func,
};

WorkOrdersList.defaultProps = {
  onAddWorkOrder: () => {},
  onReverse: () => {},
};
export default WorkOrdersList;
