import React from 'react';
import PropTypes from 'prop-types';

import { NoData, AlertDialog, toast, Loading } from '@meronex/components';

import List from '@material-ui/core/List';

import EquipmentItem from './EquipmentItem';
import { equipmentsMng } from '../api/equipmentsMng';
import EquipmentHomePageDialog from './EquipmentHomePageDialog';

function EquipmentsList(props) {
  const { equipments, onView, onClick, onEdit, onDelete } = props;

  const [
    openEquipmentHomePageDialog,
    setOpenEquipmentHomePageDialog,
  ] = React.useState(false);

  const [deleting, setDeleting] = React.useState();

  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [selectedEquipment, setSelectedEquipment] = React.useState();

  const _onDelete = (equipment) => {
    setOpenDeleteAlert(true);
    setSelectedItem(equipment);
  };

  const _onClick = (equipment) => {
    setSelectedEquipment(equipment);
    onClick(equipment);
  };
  const onConfirmDelete = async () => {
    setDeleting(true);
    setOpenDeleteAlert(false);
    await equipmentsMng.deleteEquipment(selectedItem);
    toast.success('Equipment successfully deleted');
    setDeleting(false);
    onDelete();
  };

  const renderEquipments = () => {
    if (!Array.isArray(equipments)) {
      return <Loading />;
    } else if (equipments.length === 0) {
      return <NoData type={'warn'} message={'No equipments available'} />;
    }

    return equipments.map((equipment, index) => {
      return (
        <EquipmentItem
          equipment={equipment}
          onEdit={onEdit}
          onView={onView}
          onDelete={_onDelete}
          onClick={_onClick}
          last={index === equipments.length - 1}
        />
      );
    });
  };

  return (
    <>
      <List className={'list-scroll'}>{renderEquipments()}</List>
      <EquipmentHomePageDialog
        open={openEquipmentHomePageDialog}
        onClose={() => setOpenEquipmentHomePageDialog(false)}
        equipment={selectedEquipment}
      />

      <AlertDialog
        open={openDeleteAlert}
        loading={deleting}
        onClose={() => {
          setOpenDeleteAlert(false);
        }}
        onConfirm={onConfirmDelete}
      />
    </>
  );
}
EquipmentsList.propTypes = {
  equipments: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

EquipmentsList.defaultProps = {
  onEdit: () => {
    console.log('on edit');
  },
  onDelete: () => {
    console.log('on delete');
  },
  onClick: () => {
    console.log('on click');
  },
};
export default EquipmentsList;
