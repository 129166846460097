import React from 'react';
import PropTypes from 'prop-types';

import { Form, ImageUploader, ActionBtn } from '@meronex/components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import MultiImageUploader from './MultiImageUploader';
import AssignedSelect from './AssignedSelect';

function IssueForm(props) {
  const { onAdd } = props;

  const [uploadTaskCount, setUploadTaskCount] = React.useState();

  const [images, setImages] = React.useState();
  const [title, setTitle] = React.useState();
  const [location, setLocation] = React.useState();
  const [remarks, setRemarks] = React.useState();
  const [assignedTo, setAssignedTo] = React.useState([]);

  const _onAdd = () => {
    const issueData = {
      images,
      assignedTo,
      title,
      location,
      remarks,
    };
    console.log(issueData);
    onAdd(issueData);
  };
  return (
    <div>
      <div>
        <div
          style={{
            textAlign: 'center',
          }}>
          <MultiImageUploader
            onChange={(images) => {
              const issueImages = images.map((i) => {
                return {
                  imgUrl: i.downloadUrl,
                };
              });
              setImages(issueImages);
            }}
            onUploadCountChange={(count) => setUploadTaskCount(count)}
          />
        </div>
      </div>
      <TextField
        name={'title'}
        label="Title"
        multiline
        required
        autoFocus={true}
        value={title}
        variant="outlined"
        fullWidth
        onChange={(event) => {
          setTitle(event.target.value);
        }}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <AssignedSelect
        onChange={(v) => {
          setAssignedTo(v);
        }}
      />
      <TextField
        name={'location'}
        label="Location"
        value={location}
        onChange={(event) => {
          setLocation(event.target.value);
        }}
        variant="outlined"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        name={'remarks'}
        label="Remarks"
        value={remarks}
        onChange={(event) => {
          setRemarks(event.target.value);
        }}
        variant="outlined"
        fullWidth
        multiline
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
        }}>
        {uploadTaskCount > 0 && (
          <div
            style={{
              marginBottom: '60px',
              color: 'gray',
              position: 'relative',
              top: '2px',
            }}>
            <CircularProgress size={15} /> uploading {uploadTaskCount} images
          </div>
        )}
        <ActionBtn
          disabled={uploadTaskCount > 0 || !title}
          label={'Add Issue'}
          onClick={_onAdd}
        />
      </div>
    </div>
  );
}
IssueForm.propTypes = {
  onAdd: PropTypes.func,
};

IssueForm.defaultProps = {
  onAdd: (issue) => console.log(issue),
};
export default IssueForm;
