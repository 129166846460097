import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';

import { App } from '../../../base/AppContext';
import ActionBtn from '../../../components/buttons/ActionBtn';
import CloseBtn from '../../../components/buttons/CloseBtn';
import { useHistory } from 'react-router-dom';

const { server } = App;

export default function PasswordDialog(props) {
  const history = useHistory();

  const { open } = props;
  const [loading, setLoading] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [isValid, setIsValid] = React.useState(false);

  const isFormValid = () => {
    if (confirmPassword && newPassword && confirmPassword === newPassword) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  React.useEffect(() => {
    isFormValid();
  }, [newPassword, confirmPassword]);

  const handleDisconnect = () => {
    console.log('handle disconnect');
    if (open) {
      App.clientSettings.removeItem('token');
      App.clientSettings.removeItem('userId');
      App.clientSettings.removeItem('loginInfo');
      history.push('login');
    }
  };
  React.useEffect(() => {
    if (open) {
      App.eventsManager.on(
        'event-disconnected',
        PasswordDialog.name,
        handleDisconnect
      );
    } else {
      App.eventsManager.removeListener('event-disconnected', handleDisconnect);
    }
  }, [open]);

  const handleUpdate = async () => {
    const user = App.getUser();

    if (!user) {
      return null;
    }

    if (!newPassword) {
      toast.error('Password is required');
    } else if (newPassword != confirmPassword) {
      toast.error('Password does not match');
    } else {
      // call serer method

      setLoading(true);
      try {
        // server will disconnect after
        // so will handle the disconnect
        const updated = await server.call('updatePassword', {
          email: user.emails[0].address,
          oldPassword: '',
          newPassword,
          settingsUpdate: true,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const _onClose = () => {
    const { onClose } = props;
    if (onClose) {
      onClose();
    }
    setTimeout(() => {
      setLoading(false);
      setNewPassword('');
      setConfirmPassword('');
      setIsValid(false);
    }, 500);
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onClose={_onClose}
      aria-labelledby="change-password-dialog">
      <DialogTitle id="change-password-dialog">
        Change Password
        <CloseBtn onClick={() => _onClose()} />
      </DialogTitle>

      <DialogContent>
        <div
          style={{
            position: 'relative',
            top: '-15px',
          }}>
          <TextField
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            margin="dense"
            id="newPassword"
            label="New Password"
            type="password"
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
          />
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: '30px',
            marginBottom: '20px',
          }}>
          <ActionBtn
            disabled={!isValid}
            label={'Update'}
            loading={loading}
            onClick={handleUpdate}
          />
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

PasswordDialog.defaultProps = {
  open: false,
};
