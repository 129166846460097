import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';

const InputField = (props) => {
  console.log(props);
  return (
    <Grid item xs={12}>
      <TextField
        label={props.data.label}
        value={props.data.currentValue}
        name={props.data.name}
        type={'number'}
        onChange={props.handleInput}
        autoFocus={props.autoFocus}
        inputProps={{
          style: {
            textAlign: 'center',
          },
        }}
        InputProps={{
          className: 'units-converter-input',
          style: {
            textAlign: 'center',
            fontSize: '25px',
          },
          endAdornment: (
            <InputAdornment position="end">{props.data.input}</InputAdornment>
          ),
        }}
        style={{ width: '100%' }}
      />
    </Grid>
  );
};

export default InputField;
