import React from 'react';
import PropTypes from 'prop-types';

import IChart from '@meronex/icons/hi/HiOutlineChartPie';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { analyticsMng } from '../../api/analyticsMng';
import Stats from './Stats';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function StoreAnalyticsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, storeId } = props;

  const [filters, setFilters] = React.useState({
    dateType: 'lastThreeMonths',
    range: [null, null],
    out: true,
    transferOut: true,
    in: true,
    transferIn: true,
  });

  const [fetching, setFetching] = React.useState(false);

  const [itemsMap, setItemsMap] = React.useState();

  React.useEffect(() => {
    if (open && storeId) {
      fetchItemTransaction();
    }
  }, [filters, storeId, open]);

  const processTransactions = (transactions) => {
    const _itemsMap = new Map();

    transactions.forEach((tx) => {
      let itemData = _itemsMap.get(tx.storeItem.type._id);

      if (!itemData) {
        itemData = {
          storeItem: tx.storeItem,
          in: 0,
          out: 0,
          transferIn: 0,
          transferOut: 0,
          firstTx: undefined,
          lastTx: undefined,
        };
      }
      const amount = parseFloat(tx.amount) || 0;
      if (tx.transactionType === 'in') {
        itemData.in = amount + itemData.in;
      } else if (tx.transactionType === 'out') {
        itemData.out = amount + itemData.out;
      } else if (tx.transactionType === 'transferOut') {
        itemData.transferOut = amount + itemData.transferOut;
      } else if (tx.transactionType === 'transferIn') {
        itemData.transferIn = amount + itemData.transferIn;
      }

      if (!itemData.firstTx) {
        itemData.firstTx = tx;
      } else {
        itemData.lastTx = tx;
      }
      _itemsMap.set(tx.storeItem.type._id, itemData);
    });
    console.log(_itemsMap);
    setItemsMap(_itemsMap);
  };
  const fetchItemTransaction = async () => {
    console.log('fetch transactions');
    setFetching(true);
    const transactions = await analyticsMng.getAnalyticsTransactions({
      storeId,
      filters,
    });
    processTransactions(transactions);
    setFetching(false);
  };
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IChart /> Store Analysis
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '5px',
        }}>
        <Stats itemsMap={itemsMap} />
      </DialogContent>
    </Dialog>
  );
}

StoreAnalyticsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StoreAnalyticsDialog;
