import React from 'react';
import PropTypes from 'prop-types';

import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageVisibility from 'react-page-visibility';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Backdrop from '@material-ui/core/Backdrop';
import { useTheme } from '@material-ui/core';

import { AppContext } from '../../base/AppContext';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ServerChecker(props) {
  const [showDialog, setShowDialog] = React.useState(true);
  const [showRetry, setShowRetry] = React.useState(false);
  const [reloading, setReloading] = React.useState(false);
  const theme = useTheme();

  const { App } = React.useContext(AppContext);
  const classes = useStyles();

  const { server } = App;

  let reconnectInterval;
  React.useEffect(() => {
    // setShowReloadTimer();
    // setConnectionCheckTimer();
    App.eventsManager.on('event-disconnected', ServerChecker.name, () => {
      setup();
    });
    App.eventsManager.on('event-connected', ServerChecker.name, () => {
      clearInterval(reconnectInterval);
      handleClose();
    });
  }, []);

  React.useEffect(() => {
    if (showDialog) {
      clearTimeout(window.retryTimeout);
      window.clearInterval(reconnectInterval);

      reconnectInterval = setInterval(() => {
        if (server.connected) {
          window.clearInterval(reconnectInterval);
          clearInterval(reconnectInterval);
          handleClose();
        }
      }, 50);

      window.retryTimeout = setTimeout(() => {
        setShowRetry(true);
      }, 8000);
    }
  }, [showDialog]);

  const setup = () => {
    setShowDialog(true);
    setShowRetry(false);
    setReloading(false);
  };

  const handleClose = () => {
    setShowDialog(false);
    setShowRetry(false);
  };

  const handleVisibilityChange = (isVisible) => {
    clearTimeout(window.notVisibleTimeout);
    if (!window.DISABLE_RELOAD_ON_VISIBILTY_CHANE) {
      if (!isVisible) {
        window.notVisibleTimeout = setTimeout(() => {
          server.connected = false;
          server.disconnect();
          clearInterval(reconnectInterval);
          setShowRetry(false);
          window.startTime = new Date();
        }, 500);
      } else {
        reconnectInterval = setInterval(() => {
          if (server.connected) {
            window.clearInterval(reconnectInterval);
            clearInterval(reconnectInterval);
            handleClose();
          }
        }, 50);
        server.connect();
        setShowDialog(true);
      }
    }
  };

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <>
        <Backdrop
          style={{
            zIndex: 999999,
          }}
          className={classes.backdrop}
          open={showDialog}
          onClick={handleClose}>
          <div
            style={{
              zIndex: 999999,
            }}>
            <div
              style={{
                textAlign: 'center',
              }}>
              <CircularProgress id={'circularProgress'} color="inherit" />
              <div
                style={{
                  marginTop: '10px',
                }}>
                Loading
              </div>
            </div>

            {showRetry && (
              <Paper
                style={{
                  textAlign: 'center',
                  color: theme.palette.text.primary,
                  borderRadius: '10px',
                  padding: '10px',
                  marginTop: '19px',
                  zIndex: 999999,
                }}>
                <div style={{ marginTop: '10px' }}>
                  If taking long, check your connection and try reloading
                </div>

                <Button
                  style={{
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}
                  onClick={() => {
                    setReloading(true);
                    setTimeout(() => window.location.reload(), 500);
                  }}
                  endIcon={
                    reloading ? (
                      <CircularProgress size={12} color={'gray'} />
                    ) : (
                      <div />
                    )
                  }>
                  Reload
                </Button>
              </Paper>
            )}
          </div>
        </Backdrop>
      </>
    </PageVisibility>
  );
}
