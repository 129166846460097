import React from 'react';
import PropTypes from 'prop-types';

function SitesUserName(props) {
  const { profile, style } = props;

  if (!profile) {
    return <span />;
  }
  return (
    <span style={style}>
      {profile.firstName} {profile.lastName}
    </span>
  );
}
SitesUserName.propTypes = {
  profile: PropTypes.object,
  style: PropTypes.object,
};

SitesUserName.defaultProps = {};
export default SitesUserName;
