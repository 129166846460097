import React from 'react';
import PropTypes from 'prop-types';

import View from '../views/components/View';
import { Icons } from '../common/Icons';
import { NoData, ConstructionDialog, SmartDrawer } from '@meronex/components';
import ObservationDialog from './components/types/ObservationDialog';
import IAnnounce from '@meronex/icons/zo/ZoAnnouncement';
import Logs from './Logs';
import { can, p } from '../common/roles';
import Fab from '@material-ui/core/Fab';
import IAdd from '@meronex/icons/md/MdAdd';
import IWarn from '@meronex/icons/ios/MdWarning';
function LogsView(props) {
  const { activeView } = props;

  const [openObservationDialog, setOpenObservationDialog] = React.useState();
  const [openDrawer, setOpenDrawer] = React.useState();
  return (
    <View
      title={'Feed'}
      viewId={'feed'}
      showMenu
      icon={<Icons.Feed size={22} />}
      activeView={activeView}>
      <Logs />
      <ObservationDialog
        open={openObservationDialog}
        onClose={() => {
          setOpenObservationDialog(false);
        }}
      />
      <SmartDrawer
        open={openDrawer}
        actions={[
          {
            text: 'Incident',
            onClick: () => {
              setOpenDrawer(false);
            },
            divider: true,
            icon: <IWarn size={22} />,
          },
          {
            text: 'Announcement',
            onClick: () => {
              setOpenDrawer(false);
            },
            divider: true,
            icon: <IAnnounce size={22} />,
          },
          {
            text: 'Observation',
            onClick: () => {
              console.log('observation');
              setOpenObservationDialog(true);
              setOpenDrawer(false);
            },
            divider: false,
            icon: <Icons.Observations size={24} />,
          },
        ]}
        onChange={(open) => {
          setOpenDrawer(open);
        }}
      />
      <div
        style={{
          position: 'fixed',
          right: '25px',
          bottom: '30px',
        }}>
        <Fab
          color={'primary'}
          onClick={() => {
            setOpenDrawer(true);
            // setOpenAddItemType(true);
          }}>
          <IAdd size={30} />
        </Fab>
      </div>
      <ObservationDialog
        open={openObservationDialog}
        onClose={() => {
          setOpenObservationDialog(false);
        }}
      />
    </View>
  );
}
LogsView.propTypes = {};

LogsView.defaultProps = {};
export default LogsView;
