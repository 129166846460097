import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Product from '../../../factories/components/products/Product';
const useStyles = makeStyles((theme) => ({
  container: {},
}));

function StoreItemProductsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, item, products } = props;

  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      scroll={'body'}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Store Item Products
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: '20px', fontSize: '18px' }}>
          {item.type.name} is used in {products.length} products:
        </div>
        <div style={{ display: 'block', height: window.innerHeight - 140 }}>
          <div
            style={{
              marginTop: '10px',
              maxHeight: '40px',
              minWidth: '300px',
            }}>
            {products.map((p) => (
              <div>
                <Product product={p} showFactory />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

StoreItemProductsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  products: PropTypes.array,
  item: PropTypes.object,
};

export default StoreItemProductsDialog;
