import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MembersSelect from '../../members/components/MembersSelect';

function TeamForm(props) {
  const { onChange, values } = props;

  const [members, setMembers] = React.useState();
  const [name, setName] = React.useState(values.name);
  const [nameError, setNameError] = React.useState();

  React.useEffect(() => {
    const formValue = {
      name,
      members,
      isValid: !nameError,
    };
    console.log(formValue);
    onChange(formValue);
  }, [name, members]);

  return (
    <div>
      <TextField
        name={'name'}
        label="Name"
        autoFocus={true}
        value={name}
        onBlur={() => {
          if (!name) {
            setNameError(true);
          }
        }}
        variant="outlined"
        error={nameError}
        helperText={nameError ? 'Name is required' : ''}
        fullWidth
        margin="normal"
        onChange={(event) => {
          const value = event.target.value;

          setName(value);
          setNameError(!Boolean(value));
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div>
        <MembersSelect
          defaultValue={values.members}
          onChange={(v) => {
            console.log(v);
            setMembers(v);
          }}
        />
      </div>
      {/*<div*/}
      {/*  style={{*/}
      {/*    marginTop: '10px',*/}
      {/*    marginBottom: '15px',*/}
      {/*  }}>*/}
      {/*  <List*/}
      {/*    component="nav"*/}
      {/*    aria-label="members"*/}
      {/*    style={{*/}
      {/*      border: '1px solid #d2d2d2',*/}
      {/*      borderRadius: '10px',*/}
      {/*    }}>*/}
      {/*    <ListItem button>Ali</ListItem>*/}
      {/*    <ListItem button>Ali Younger</ListItem>*/}
      {/*    <ListItem*/}
      {/*      button*/}
      {/*      style={{*/}
      {/*        justifyContent: 'center',*/}
      {/*        borderTop: '1px solid #d6d6d6',*/}
      {/*      }}>*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          textAlign: 'center',*/}
      {/*        }}>*/}
      {/*        <div>*/}
      {/*          <IAddMember size={25} />*/}
      {/*        </div>*/}
      {/*        <div>Add Member</div>*/}
      {/*      </div>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</div>*/}
    </div>
  );
}
TeamForm.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
};

TeamForm.defaultProps = {
  values: {},
  onChange: (values) => console.log(values),
};
export default TeamForm;
