import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IDelete from '@meronex/icons/mdc/MdcDeleteEmptyOutline';

import IWarn from '@meronex/icons/ti/TiWarningOutline';
import IInfo from '@meronex/icons/fi/FiInfo';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AlertDialog(props) {
  const {
    open,
    onClose,
    title,
    description,
    onConfirm,
    confirmBtn,
    loading,
    loadingColor,
    type,
  } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {type === 'warn' && (
          <IWarn
            size={25}
            style={{
              position: 'relative',
              top: '5px',
              left: '-8px',
            }}
          />
        )}
        {type === 'info' && (
          <IInfo
            size={25}
            style={{
              position: 'relative',
              top: '5px',
              left: '-8px',
            }}
          />
        )}
        {title}
      </DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {type === 'info' && (
          <Button
            onClick={handleClose}
            style={{
              color: 'gray',
              fontWeight: 400,
            }}>
            Ok
          </Button>
        )}

        {type === 'warn' && (
          <>
            <Button
              onClick={handleClose}
              style={{
                color: 'gray',
                fontWeight: 400,
              }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onConfirm();
              }}
              autoFocus>
              {loading && (
                <CircularProgress
                  style={{ marginRight: '10px', color: loadingColor }}
                  size={20}
                />
              )}
              {confirmBtn}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.defaultProps = {
  title: 'Confirm Action',
  description: 'Are you sure you want to delete?',
  onClose: () => {},
  onConfirm: () => {},
  open: false,
  type: 'warn',
  loadingColor: 'red',
  confirmBtn: (
    <span style={{ color: 'red' }}>
      Delete <IDelete size={20} />
    </span>
  ),
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onConfirm: PropTypes.func,
  confirmBtn: PropTypes.object,
  type: PropTypes.oneOf(['warn', 'info']),
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
};
