import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Switch from '@material-ui/core/Switch';
import AnimateHeight from 'react-animate-height';

import BisRightArrow from '@meronex/icons/bi/BisRightArrow';
import BisDownArrow from '@meronex/icons/bi/BisDownArrow';
import Fade from '@material-ui/core/Fade';

import { Icons } from '../../../common/Icons';

const iconStyle = {
  position: 'relative',
  top: '2px',
  left: '4px',
  display: 'inline-block',
};

export const ExpandIcon = (props) => {
  const { expanded } = props;
  return (
    <div style={iconStyle}>
      {expanded ? <BisDownArrow /> : <BisRightArrow />}
    </div>
  );
};

export const SectionTitle = (props) => {
  const {
    title = 'no title',
    onClick = () => {
      console.log('on click');
    },
    expanded = true,
  } = props;

  return (
    <div
      onClick={onClick}
      style={{
        marginTop: '12px',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '16px',
        cursor: 'pointer',
      }}>
      <div
        style={{
          marginLeft: '5px',
          display: 'inline-block',
        }}>
        {title}
      </div>
      <ExpandIcon expanded={expanded} />
    </div>
  );
};

const RoleItem = (props) => {
  const { label, _id, onChange, checked, category, role, disabled } = props;

  const _onChange = (v) => {
    const checked = v.currentTarget.checked;
    onChange({
      _id,
      category,
      checked,
    });
  };

  const isChecked = () => {
    if (role) {
      if (role[category]) {
        if (role[category][_id]) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <ListItem
      style={{
        paddingBottom: '0px',
        paddingTop: '0px',
        borderBottom: '1px solid #80808026',
      }}>
      <Grid container>
        <Grid
          item
          xs={10}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          {label}
        </Grid>
        <Grid item xs={2}>
          <Switch
            checked={isChecked()}
            onChange={_onChange}
            name={_id}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

function RoleForm(props) {
  const { onChange = () => {} } = props;

  const [role, setRole] = React.useState(props.role || {});

  const [nameError, setNameError] = React.useState(false);
  const [expandGlobal, setExpandGlobal] = React.useState(true);
  const [expandStores, setExpandStores] = React.useState(false);
  const [expandProduction, setExpandProduction] = React.useState(false);

  React.useEffect(() => {
    onChange(role);
  }, [role]);

  const updateName = (nameValue) => {
    role.name = nameValue;
    setRole({ ...role });
  };
  const updateRole = ({ category, _id, checked }) => {
    if (!role[category]) {
      role[category] = {};
    }
    role[category][_id] = checked;
    setRole({ ...role });
  };

  const onStoreChange = ({ category, _id, checked }) => {
    if (!role[category]) {
      role[category] = {};
    }
    role['globals']['accessStores'] = checked;
    if (!checked) {
      setExpandStores(false);
      // when store is unchecked
      // uncheck production
      role['globals']['accessFactories'] = false;
    }
    console.log(role);
    setRole({ ...role });
  };

  const renderGlobalPermissions = () => {
    return (
      <div>
        <SectionTitle
          title={'Global'}
          onClick={() => setExpandGlobal(!expandGlobal)}
          expanded={expandGlobal}
        />
        <AnimateHeight
          duration={200}
          height={expandGlobal ? 190 : 0} // see props documentation below
        >
          <List>
            <RoleItem
              role={role}
              category={'globals'}
              _id={'accessStores'}
              label={
                <span>
                  <Icons.Stores /> Stores
                </span>
              }
              onChange={onStoreChange}
            />
            <RoleItem
              role={role}
              category={'globals'}
              _id={'accessFactories'}
              disabled={!role?.globals?.accessStores}
              label={
                <span
                  style={{
                    color: role?.globals?.accessStores ? 'inherit' : 'darkgray',
                  }}>
                  <Icons.Factories /> Production{' '}
                  {!role?.globals?.accessStores && (
                    <span style={{ fontSize: '10px' }}>
                      (Production requires store access.)
                    </span>
                  )}
                </span>
              }
              onChange={(category, _id, checked) => {
                if (!checked) {
                  setExpandProduction(false);
                }
                updateRole(category, _id, checked);
              }}
            />
            <RoleItem
              role={role}
              category={'globals'}
              _id={'accessEquipments'}
              label={
                <span>
                  <Icons.Equipments /> Equipments
                </span>
              }
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'globals'}
              _id={'viewConfigurations'}
              label={
                <span>
                  <Icons.Settings /> Configurations
                </span>
              }
              onChange={updateRole}
            />
          </List>
        </AnimateHeight>
      </div>
    );
  };
  const renderStoresPermissions = () => {
    return (
      <div>
        <SectionTitle
          title={'Stores'}
          expanded={expandStores}
          onClick={() => setExpandStores(!expandStores)}
        />
        <AnimateHeight
          duration={200}
          height={expandStores ? 480 : 0} // see props documentation below
        >
          <List>
            <RoleItem
              role={role}
              category={'stores'}
              _id={'itemIn'}
              label={'Item in'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'itemOut'}
              label={'Item out'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'transferItem'}
              label={'Transfer'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'auditItem'}
              label={'Audit item'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'modifyItemAmount'}
              label={'Modify item amount'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'itemArchive'}
              label={'Archive item'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'itemRestore'}
              label={'Restore item'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'itemDelete'}
              label={'Delete item'}
              onChange={updateRole}
            />

            <RoleItem
              role={role}
              category={'stores'}
              _id={'auditStore'}
              label={'Audit store'}
              onChange={updateRole}
            />

            <RoleItem
              role={role}
              category={'stores'}
              _id={'reverseTransaction'}
              label={'Reverse transaction'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'CRUDItemTypes'}
              label={'Create, modify or delete item types'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'stores'}
              _id={'viewStoresPrices'}
              label={"View Stores' Prices"}
              onChange={updateRole}
            />
          </List>
        </AnimateHeight>
      </div>
    );
  };
  const renderFactoriesPermissions = () => {
    return (
      <div>
        <SectionTitle
          title={'Production'}
          expanded={expandProduction}
          onClick={() => setExpandProduction(!expandProduction)}
        />
        <AnimateHeight
          duration={200}
          height={expandProduction ? 320 : 0} // see props documentation below
        >
          <List>
            <RoleItem
              role={role}
              category={'factories'}
              _id={'CRUDFactories'}
              label={'Create, modify or delete production sites'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'factories'}
              _id={'CRUDWorkOrders'}
              label={'Create, modify or delete work orders'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'factories'}
              _id={'modifyOthersWorkOrder'}
              label={'Modify others work order'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'factories'}
              _id={'deleteOthersWorkOrder'}
              label={'Delete others work order'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'factories'}
              _id={'updateWorkOrderStatus'}
              label={'Update work order status'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'factories'}
              _id={'reverseWorkOrder'}
              label={'Reverse work order'}
              onChange={updateRole}
            />

            <RoleItem
              role={role}
              category={'factories'}
              _id={'CRUDProducts'}
              label={'Create, modify or delete products'}
              onChange={updateRole}
            />
            <RoleItem
              role={role}
              category={'factories'}
              _id={'viewFactoriesPrices'}
              label={'View Production Prices'}
              onChange={updateRole}
            />
          </List>
        </AnimateHeight>
      </div>
    );
  };

  return (
    <div>
      <TextField
        name={'name'}
        label="Role Name"
        autoFocus={true}
        defaultValue={(role || {}).name}
        variant="outlined"
        onChange={(v) => {
          const nameValue = v.target.value;
          setNameError(!nameValue);
          updateName(nameValue);
        }}
        fullWidth
        error={nameError}
        helperText={nameError ? 'Role name is required' : ''}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {renderGlobalPermissions()}
      <Fade in={role?.globals?.accessStores}>{renderStoresPermissions()}</Fade>
      <Fade in={role?.globals?.accessFactories}>
        {renderFactoriesPermissions()}
      </Fade>
    </div>
  );
}
RoleForm.propTypes = {
  data: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
};

RoleForm.defaultProps = {
  data: {},
  validateOnInit: false,
  onChange: (data) => console.log(data),
};
export default RoleForm;
