import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, Form, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { storesMng } from '../../api/storesMng';
import StoreForm from './StoreForm';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddStoreDialog(props) {
  const { open, onClose, onAdd } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState();

  const createStore = async () => {
    setLoading(true);
    const store = { ...formData };
    delete store.isValid;
    console.log(store);
    try {
      await storesMng.addStore(store);
      onAdd();
      _onClose();
      toast.success('Store created successfully', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    setFormData({});
    onClose();
  };

  if (!open) {
    return null;
  }
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Add New Store
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: '-15px',
        }}>
        {open && (
          <StoreForm
            validateOnInit={false}
            onChange={(data) => {
              console.log('on change');
              console.log(data);
              setFormData({ ...data });
            }}
            data={formData}
          />
        )}
        <div
          style={{
            marginBottom: '20px',
            marginTop: '10px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || !formData || !formData.isValid}
            label={<span>Create Store</span>}
            onClick={() => {
              createStore();
            }}
            onEnter={() => {
              createStore();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

AddStoreDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
};

export default AddStoreDialog;
