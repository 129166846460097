import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Footer = (props) => {
  const { hasMore } = props;
  if (!hasMore) {
    return <div style={{ height: '100px' }}></div>;
  }
  return (
    <div
      key={'loader'}
      style={{
        marginRight: '-30px',
        marginTop: '10px',
        marginBottom: '125px',
        textAlign: 'center',
      }}>
      {' '}
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={24}
        thickness={4}
      />
    </div>
  );
};

export default Footer;
