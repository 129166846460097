import React from 'react';
import moment from 'moment';

import { useTheme } from '@material-ui/core';

import { App } from '@meronex/app';
import GoClock from '@meronex/icons/go/GoClock';
import GoComment from '@meronex/icons/go/GoClock';
import FaRegBuilding from '@meronex/icons/fa/FaRegBuilding';
import FaRegQuestionCircle from '@meronex/icons/fa/FaRegQuestionCircle';
import MdPersonOutline from '@meronex/icons/md/MdPersonOutline';
import MdTimer from '@meronex/icons/md/MdTimer';
import AiOutlineOrderedList from '@meronex/icons/ai/AiOutlineOrderedList';
import GiPerson from '@meronex/icons/gi/GiPerson';
import GiHouseKeys from '@meronex/icons/gi/GiHouseKeys';
import TiPhone from '@meronex/icons/ti/TiPhone';
import TiEyeOutline from '@meronex/icons/ti/TiEyeOutline';
import GoCommentDiscussion from '@meronex/icons/go/GoCommentDiscussion';

import SatisfactionDetails from './SatisfactionDetails';

const { utils } = App;

const renderLineItem = (key, icon, content) => {
  let isDataProvided = true;
  if (typeof content === 'undefined' || content === '') {
    isDataProvided = false;
  }

  return (
    <div key={key}>
      <div style={{ display: 'inline-block' }}>{icon}</div>
      <div
        style={{
          display: 'inline-block',
          marginLeft: '10px',
        }}>
        {isDataProvided ? (
          <div>{content}</div>
        ) : (
          <div style={{ fontStyle: 'italic' }}>N/A</div>
        )}
      </div>
    </div>
  );
};
const renderItems = (items) => {
  return items.map((item, index) =>
    renderLineItem(index, item.icon, item.content)
  );
};
const renderPriority = (log) => {
  // rgba(226, 112, 15, 0.82)
  let bgColor = '#ff0000e3';
  if (log.priority === 'urgent') {
    bgColor = 'rgba(169, 20, 20, 0.89)';
  } else if (log.priority === 'high') {
    bgColor = 'rgba(226, 112, 15, 0.82)';
  } else if (log.priority === 'medium') {
    bgColor = 'rgba(58, 110, 121, 0.82)';
  } else if (log.priority === 'low') {
    bgColor = '#62ab61';
  }

  return (
    <div>
      <AiOutlineOrderedList />
      <div
        style={{
          textTransform: 'capitalize',
          color: bgColor,
          display: 'inline-block',
          marginLeft: '10px',
        }}>
        {log.priority}
      </div>
    </div>
  );
};

export default function LogDetails(props) {
  const { log, showExtraSurveyInfo } = props;
  const theme = useTheme();

  if (!log) {
    return null;
  }
  const style = props.style
    ? props.style
    : {
        width: '200px',
        marginTop: '10px',
        fontSize: '12px',
        color: theme.palette.text.secondary,
      };

  const items = [];

  // Push common log details storeItems
  items.push({
    icon: <FaRegBuilding />,
    content: (log.site || {}).name,
  });
  items.push({
    icon: <MdPersonOutline />,
    content: utils.getAuthor(log.author),
  });
  items.push({
    icon: <GoClock />,
    content: moment(log.createdAt).fromNow(),
  });
  items.push({
    icon: <TiEyeOutline />,
    content: (log.openedBy || []).length,
  });
  items.push({
    icon: <GoCommentDiscussion />,
    content: log.commentsCount || 0,
  });

  const isObservationOrIncident = () =>
    log.logType === 'observation' || log.logType === 'incident';

  const isFeedback = () => log.logType === 'feedback';
  const isSurvey = () => log.logType === 'survey';

  const renderFeedbackDetails = () => {
    const feedbackItems = [];
    feedbackItems.push({
      icon: <GiHouseKeys />,
      content: log.logDetails.unit,
    });
    feedbackItems.push({
      icon: <TiPhone />,
      content: log.logDetails.phone,
    });
    return <div>{renderItems(feedbackItems)}</div>;
  };
  const renderSurveyDetails = () => {
    if (showExtraSurveyInfo) {
      let surveyTypeDisplay = 'Move out';
      if (log.surveyType === 'someOther') {
        surveyTypeDisplay = '';
      }
      const remarks = log.logDetails.remarks;

      const moreSurvey = [];

      moreSurvey.push({
        icon: <MdTimer />,
        content: log.logDetails.tenantDuration,
      });
      moreSurvey.push({
        icon: <TiPhone />,
        content: log.logDetails.tenantPhone,
      });

      moreSurvey.push({
        icon: <FaRegQuestionCircle />,
        content: surveyTypeDisplay,
      });
      moreSurvey.push({
        icon: <GoComment />,
        content: remarks,
      });
      return (
        <div>
          {renderItems(moreSurvey)}
          <div
            style={{
              textAlign: 'center',
              fontSize: '20px',
              marginTop: '10px',
            }}>
            <div>Satisfaction</div>
            <div>
              <SatisfactionDetails log={log} />
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  const renderTenantName = () => {
    return renderLineItem(
      <GiPerson />,
      log.logDetails.tenantName || log.logDetails.name
    );
  };

  return (
    <div style={style}>
      {renderItems(items)}
      {isObservationOrIncident() ? renderPriority(log) : renderTenantName()}
      {isFeedback() && renderFeedbackDetails()}
      {isSurvey() && renderSurveyDetails()}
    </div>
  );
}
