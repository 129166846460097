import { App } from '@meronex/app';

const { server } = App;

export const teamsMng = {
  addTeam: async (team) => {
    const result = await server.call('addTeam', { team });
    return result;
  },
  updateTeam: async (team) => {
    const result = await server.call('updateTeam', { team });
    return result;
  },
  getTeams: async () => {
    const result = await server.call('getTeams', {});
    return result;
  },
  deleteTeam: async (team) => {
    console.log(`delete team, id: ${team._id}`);
    const result = await server.call('deleteTeam', { _id: team._id });
    return result;
  },
};
