import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { App } from '@meronex/app';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import Grid from '@material-ui/core/Grid';
import IProcessStep from '@meronex/icons/mdc/MdcAccountCogOutline';

import { Icons } from '../../../common/Icons';
import AddStoreItemDialog from './AddStoreItemDialog';
import AddProcessStepDialog from './steps/AddStepDialog';
import StepItem from './steps/StepItem';
import MaterialItem from './steps/MaterialItem';
import EditStepDialog from './steps/EditStepDialog';

const SortableItem = SortableElement((data) => {
  console.log(data);
  if (data.item.isProcessStep) {
    return <StepItem data={data} />;
  } else {
    return <MaterialItem data={data} />;
  }
});

const SortableListContainer = SortableContainer(
  ({ items, onRemove, onEdit }) => {
    return (
      <div style={{ width: '100%' }}>
        {items.map((item, index) => {
          return (
            <SortableItem
              key={item._id}
              index={index}
              item={item}
              onRemove={() => onRemove(item)}
              onEdit={() => onEdit(item)}
            />
          );
        })}
      </div>
    );
  }
);

const ProductItems = (props) => {
  const { onChange = () => {}, items = [], product } = props;

  const [
    showAddProductItemDialog,
    setShowAddProductItemDialog,
  ] = React.useState(false);

  const [openProcessStepDialog, setOpenProcessStepDialog] = React.useState();
  const [openEditStepDialog, setOpenEditStepDialog] = React.useState();
  const [selectedStep, setSelectedStep] = React.useState();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const _items = arrayMoveImmutable(items, oldIndex, newIndex);
    onChange(_items);
  };

  const removeStoreItem = (item) => {
    const _items = items.filter((i) => i._id !== item._id);
    onChange(_items);
  };

  const editStoreItem = (item) => {
    console.log('edit store item');
    console.log(item);
    setSelectedStep(item);
    setOpenEditStepDialog(true);
  };
  const addStoreItem = (item) => {
    const _items = [...items];
    if (!_items.find((i) => i._id === item._id)) {
      // the item quantity will need
      // to be fetched again
      // when the work order form is opened
      // to get the latest value
      delete item.quantity;
      _items.push(item);
      setShowAddProductItemDialog(false);
      onChange(_items);
    }
  };

  const addProcessStep = (step) => {
    console.log('add process step');
    console.log(step);
    step._id = App.utils.randomId();
    const _items = [...items];
    step.isProcessStep = true;
    step.factoryId = product.factoryId;
    step.timer = {};
    _items.push({ ...step });
    onChange(_items);
    setOpenProcessStepDialog(false);
  };
  const editProcessStep = (step) => {
    console.log('edit process step');
    console.log(step);
    const _items = [];
    items.forEach((i) => {
      if (i._id === step._id) {
        _items.push({ ...step });
      } else {
        _items.push(i);
      }
    });
    onChange(_items);
    setOpenEditStepDialog(false);
  };

  return (
    <div>
      <div>
        <SortableListContainer
          helperClass={'draggable-item'}
          useDragHandle={true}
          items={items}
          onRemove={removeStoreItem}
          onEdit={editStoreItem}
          onSortEnd={onSortEnd}
          lockAxis="y"
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
        }}>
        <div
          style={{
            height: '60px',
          }}>
          <Grid container>
            <Grid
              style={{
                cursor: 'pointer',
              }}
              item
              xs={6}
              onClick={() => {
                setShowAddProductItemDialog(true);
              }}>
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Icons.Items size={25} color={'rgb(84 84 84)'} />
                <div
                  style={{
                    fontSize: '14px',
                    marginLeft: '10px',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}>
                  Add Store Item
                </div>
              </div>
            </Grid>
            <Grid
              item
              style={{
                cursor: 'pointer',
                borderLeft: 'lightgray 1px solid',
              }}
              xs={6}
              onClick={() => {
                setOpenProcessStepDialog(true);
              }}>
              <div
                style={{
                  textAlign: 'center',
                }}>
                <IProcessStep size={25} color={'rgb(84 84 84)'} />
                <div
                  style={{
                    fontSize: '14px',
                    marginLeft: '10px',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}>
                  Add Process Step
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {showAddProductItemDialog && (
          <AddStoreItemDialog
            onAdd={addStoreItem}
            addedItems={items}
            open={showAddProductItemDialog}
            onClose={() => {
              console.log('on close');
              setShowAddProductItemDialog(false);
            }}
          />
        )}
        {openProcessStepDialog && (
          <AddProcessStepDialog
            open={openProcessStepDialog}
            onAddStep={addProcessStep}
            onClose={() => {
              setOpenProcessStepDialog(false);
            }}
          />
        )}
        {openEditStepDialog && (
          <EditStepDialog
            open={openEditStepDialog && selectedStep}
            step={selectedStep}
            onEditStep={editProcessStep}
            onClose={() => {
              setOpenEditStepDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProductItems;
