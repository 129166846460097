import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IssueForm from './IssueForm';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AddIssueDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Add Issue Dialog
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <IssueForm onAdd={(issue) => console.log(issue)} />
      </DialogContent>
    </Dialog>
  );
}

AddIssueDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddIssueDialog;
