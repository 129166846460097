import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import AnimateHeight from 'react-animate-height';
import { SortableHandle } from 'react-sortable-hoc';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import IDelete from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ITemp from '@meronex/icons/fa/FaTemperatureLow';

import SitesAvatar from '../../../../common/SitesAvatar';
import { Icons } from '../../../../common/Icons';

const DragHandle = SortableHandle(() => (
  <div
    style={{
      height: '50px',
      width: '50px',
      textAlign: 'center',
    }}>
    <DragHandleIcon />
  </div>
));

function StepItem(props) {
  const { data } = props;
  const [expanded, setExpanded] = React.useState(false);

  const step = { ...data.item };
  const onRemove = data.onRemove;
  const onEdit = data.onEdit;

  return (
    <div
      onClick={() => {
        setExpanded(!expanded);
      }}>
      <Grid conatiner style={{ display: 'flex', height: '105px' }}>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <SitesAvatar
            image={(step.type.image || {}).thumbUrl}
            size={75}
            name={step.type.name}
            round={15}
          />
        </Grid>
        <Grid item xs={8}>
          <div
            style={{
              textAlign: 'left',
              fontSize: '15px',
              fontWeight: '400',
            }}>
            <span
              style={{
                position: 'relative',
                left: '4px',
              }}>
              {step.type.name}
            </span>
          </div>

          <div
            style={{
              fontSize: '12px',
              marginTop: '6px',
            }}>
            {!step.isTempMeasure && (
              <div>
                <Icons.Equipments size={18} /> Equipment Usage Step
              </div>
            )}
            {step.isTempMeasure && (
              <div>
                <ITemp /> Temperature Measure Step
              </div>
            )}
            {step.description}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div
            style={{
              padding: '0px',
              float: 'right',
              color: 'gray',
              position: 'relative',
              top: '12px',
            }}>
            <DragHandle />
          </div>
        </Grid>
      </Grid>

      <div className={'action-container'} style={{ textAlign: 'center' }}>
        <AnimateHeight
          duration={200}
          height={expanded ? 80 : 0} // see props documentation below
        >
          <Grid container>
            <Grid item xs={6}>
              <div onClick={onEdit}>
                <IconButton style={{ color: '#556cd6' }}>
                  <Icons.Edit color={'#556cd6'} />
                </IconButton>
                <div style={{ color: '#556cd6' }}>Edit</div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div onClick={onRemove}>
                <IconButton style={{ color: 'red' }}>
                  <IDelete color={'red'} />
                </IconButton>
                <div style={{ color: 'red' }}>Remove</div>
              </div>
            </Grid>
          </Grid>
        </AnimateHeight>
      </div>
      <Divider />
      <div
        style={{
          position: 'relative',
          left: '10px',
          bottom: '30px',
          color: 'gray',
        }}>
        <Icons.ProcessStep size={15} />
      </div>
    </div>
  );
}
StepItem.propTypes = {};

StepItem.defaultProps = {};
export default StepItem;
