import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, QRCodeDialog } from '@meronex/components';
import { QRCode } from 'react-qr-svg';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function QrCodeLabelDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, url, label } = props;

  const ref = React.useRef();

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        Equipment Label
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid
          ref={ref}
          container
          style={{
            border: '1px solid black',
            padding: '15px',
          }}>
          <Grid item xs={7}>
            <QRCode
              className={'qr-code-svg'}
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ width: 320 }}
              value={url}
            />
          </Grid>
          <Grid item xs={5}>
            <div
              style={{
                fontSize: '120px',
                textAlign: 'center',
              }}>
              {label}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

QrCodeLabelDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
  label: PropTypes.string,
};

export default QrCodeLabelDialog;
