import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import { CloseBtn, toast, NoData, Loading } from '@meronex/components';
import IStores from '@meronex/icons/mdc/MdcWarehouse';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import MdMenu from '@meronex/icons/md/MdMenu';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IAdd from '@meronex/icons/gr/GrAddCircle';

import IArchives from '@meronex/icons/en/EnArchive';

import AddStoreDialog from './AddStoreDialog';
import EditStoreDialog from './EditStoreDialog';

import { storesMng } from '../../api/storesMng';
import ArchivedStoreDialog from './ArchivedStoresDialog';
import SitesListItem from '../../../common/SitesListItem';
import TopHeader from '../../../views/components/TopHeader';
import AuditStoreDialog from './AuditStoreDialog';
import StoreArchivesWarningsDialog from './StoreArchivesWarningsDialog';
import { Icons } from '../../../common/Icons';
import StoresFilter from './StoresFilter';

function StoresDialog(props) {
  const {
    open,
    onClose,
    stores,
    onStoreSelect,
    refreshStores,
    selectedStore,
    permissions,
  } = props;

  const [openDrawer, setOpenDrawer] = React.useState();
  const [filterValue, setFilterValue] = React.useState();
  const [categoryId, setCategoryId] = React.useState();

  const [openAddStoreDialog, setOpenAddStoreDialog] = React.useState(false);
  const [openEditStoreDialog, setOpenEditStoreDialog] = React.useState(false);
  const [
    openArchivedStoresDialog,
    setOpenArchivedStoresDialog,
  ] = React.useState(false);
  const [archiveWarnings, setArchiveWarnings] = React.useState();
  const [openAuditDialog, setOpenAuditDialog] = React.useState(false);

  const [archiving, setArchiving] = React.useState(false);
  const [archivedStoresCount, setArchivedStoresCount] = React.useState();
  const [openWarningsDialog, setOpenWarningsDialog] = React.useState();

  const [switchingStore, setSwitchingStore] = React.useState(false);

  const fetchArchivedStoresCount = async () => {
    const count = await storesMng.getArchivedStoresCount();
    setArchivedStoresCount(count);
    return count;
  };

  React.useEffect(() => {
    fetchArchivedStoresCount();
  }, [stores]);

  const onEditClick = (store) => {
    onStoreSelect(store);
    setOpenEditStoreDialog(true);
  };

  const onAuditClick = (store) => {
    onStoreSelect(store);
    setOpenAuditDialog(true);
  };
  const _refresh = () => {
    console.log('refresh');
    // we need to update the selected store
    // some checks but we should not hit this case

    if (Array.isArray(stores) && stores.length > 0) {
      refreshStores((updatedStores) => {
        onStoreSelect(updatedStores[0]);
        fetchArchivedStoresCount();
      });
    }
  };
  const archiveStore = async (selectedStore) => {
    if (stores.length <= 1) {
      toast.error('You need at least one active store.', { autoClose: 900 });
      setOpenDrawer(false);
    } else if (!archiving) {
      setArchiving(true);
      try {
        const result = await storesMng.archiveStore(selectedStore._id);
        _refresh();
        toast.success('Store archived successfully', { autoClose: 900 });
      } catch (e) {
        if (
          e.error === 'archiveStore.destinationStore' ||
          e.error === 'archiveStore.productStoreItems'
        ) {
          console.error(e);
          setOpenWarningsDialog(true);
          setArchiveWarnings(e);
        } else {
          toast.error('Ops, something went wrong');
          console.error(e);
        }
      } finally {
        setArchiving(false);
        setOpenDrawer(false);
      }
    }
  };

  const getFilteredStores = () => {
    let _stores = stores;

    if (filterValue) {
      _stores = _stores.filter((s) =>
        s.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }

    if (categoryId && categoryId !== 'any') {
      console.log('filter');
      _stores = _stores.filter((s) => {
        if (!s.category) {
          return false;
        }
        return s.category._id === categoryId;
      });
    }
    return _stores;
  };
  const renderStores = (_stores) => {
    if (_stores.length === 0) {
      return (
        <NoData
          onClick={() => {
            setOpenAddStoreDialog(true);
          }}
          message={'Add new store'}
          icon={<IStores size={40} color={'gray'} />}
        />
      );
    }

    return _stores.map((store) => {
      const selected = selectedStore && selectedStore._id === store._id;

      const itemStyle = {
        color: 'gray',
        position: 'relative',
        top: '5px',
        marginRight: '10px',
        fontSize: '12px',
      };
      return (
        <SitesListItem
          item={{
            name: App.utils.string.truncate(store.name, 40),
            count: store.itemCount,
            icon: <IStores />,
            _id: store._id,
            unit: 'Item',
            updatedAt: store.updatedAt,
            image: store.image,
            additionalDetails: (
              <div>
                {store.productsStore && (
                  <div style={itemStyle}>
                    <span
                      style={{
                        position: 'relative',
                        top: '2px',
                      }}>
                      <Icons.Products />
                    </span>
                    <span
                      style={{
                        top: '5px',
                        marginLeft: '5px',
                      }}>
                      Products Store
                    </span>
                  </div>
                )}
                {store.category && (
                  <div style={itemStyle}>
                    <span
                      style={{
                        position: 'relative',
                        top: '2px',
                      }}>
                      <Icons.Category />
                    </span>
                    <span
                      style={{
                        top: '5px',
                        marginLeft: '5px',
                      }}>
                      {store.category.name}
                    </span>
                  </div>
                )}
              </div>
            ),
          }}
          height={store.category ? 130 : 110}
          filterValue={filterValue}
          selected={selected}
          loading={switchingStore}
          updatedAtLabel={'Updated: '}
          onArchive={() => archiveStore(store)}
          onEdit={() => onEditClick(store)}
          onAudit={() => {
            onAuditClick(store);
          }}
          onClose={() => {
            onClose();
          }}
          permissions={permissions}
          onSelect={() => {
            setSwitchingStore(true);
            onStoreSelect(store, () => {
              setSwitchingStore(false);
              onClose();
            });
          }}
        />
      );
    });
  };

  const _onClose = () => {
    onClose();
    setTimeout(() => {
      setFilterValue('');
    }, 500);
  };

  const _stores = getFilteredStores();
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle style={{ paddingBottom: '0px' }}>
        <TopHeader
          icon={
            <span>
              <IStores size={20} />
            </span>
          }
          title={`Select Store`}>
          <StoresFilter
            visible={open}
            storesCount={_stores.length}
            onCategoryId={(_id) => {
              setCategoryId(_id);
            }}
            onFilter={(v) => {
              console.log(v);
              setFilterValue(v);
            }}
          />
        </TopHeader>
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginBottom: '80px',
          }}>
          {renderStores(_stores)}
        </div>
        <Fab
          onClick={() => {
            setOpenDrawer(true);
          }}
          aria-label="add"
          style={{
            position: 'fixed',
            right: '25px',
            bottom: '30px',
          }}>
          <MdMenu size={25} />
        </Fab>
        <div>
          <Drawer
            anchor={'bottom'}
            open={openDrawer}
            onClose={() => {
              setOpenDrawer(false);
            }}>
            <List>
              <ListItem
                button
                key="addStore"
                onClick={() => {
                  setOpenAddStoreDialog(true);
                  setOpenDrawer(false);
                }}>
                <ListItemIcon>
                  <IAdd size={25} />
                </ListItemIcon>
                <ListItemText primary={'Add Store'} />
              </ListItem>
              <Divider key={'divider'} />
              <ListItem
                button
                key="viewArchives"
                // disabled={!selectedStore}
                onClick={() => {
                  setOpenArchivedStoresDialog(true);
                  setOpenDrawer(false);
                }}>
                <ListItemIcon>
                  <IArchives size={22} color={'black'} />{' '}
                </ListItemIcon>
                <ListItemText
                  primary={`View Archived Stores (${archivedStoresCount})`}
                />
              </ListItem>
              <Divider key={'divider'} />
              <ListItem
                button
                key="close"
                onClick={() => {
                  setOpenDrawer(false);
                }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    fontSize: '18px',
                  }}>
                  Close{' '}
                </div>
              </ListItem>
            </List>
          </Drawer>
        </div>
      </DialogContent>
      {openAddStoreDialog && (
        <AddStoreDialog
          open={openAddStoreDialog}
          onAdd={refreshStores}
          onClose={() => {
            setOpenAddStoreDialog(false);
          }}
        />
      )}
      {openEditStoreDialog && (
        <EditStoreDialog
          open={openEditStoreDialog}
          onEdit={refreshStores}
          store={selectedStore}
          onClose={() => {
            setOpenEditStoreDialog(false);
          }}
        />
      )}
      {openAuditDialog && (
        <AuditStoreDialog
          store={selectedStore}
          open={openAuditDialog}
          onClose={() => {
            setOpenAuditDialog(false);
          }}
        />
      )}
      {openArchivedStoresDialog && (
        <ArchivedStoreDialog
          onChange={() => _refresh()}
          open={openArchivedStoresDialog}
          onClose={() => {
            setOpenArchivedStoresDialog(false);
          }}
        />
      )}
      {openWarningsDialog && (
        <StoreArchivesWarningsDialog
          archiveWarnings={archiveWarnings}
          open={openWarningsDialog}
          onClose={() => {
            setOpenWarningsDialog(false);
          }}
        />
      )}
      {switchingStore && <Loading type={'serverCall'} delay={1 * 3000} />}
    </Dialog>
  );
}

StoresDialog.defaultProps = {
  onStoreSelect: (store) => {
    console.log(store);
  },
  refreshStores: () => {
    console.log('refresh stores');
  },
  stores: [],
};
StoresDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  stores: PropTypes.array,
  onStoreSelect: PropTypes.func,
  refreshStores: PropTypes.func,
  selectedStore: PropTypes.object,
  permissions: PropTypes.object,
};

export default StoresDialog;
