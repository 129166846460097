import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { toast } from 'react-toastify';

import IStores from '@meronex/icons/mdc/MdcWarehouse';
import IWarn from '@meronex/icons/ti/TiWarning';

import ActionInfo from './ActionInfo';
import { storesMng } from '../../../api/storesMng';
import { playSound } from '../../../../common/helpers';
import TopBar from '../../../../views/components/TopHeader';

import { helpers } from '../../../../common/helpers';
import { Icons } from '../../../../common/Icons';
import Button from '@material-ui/core/Button';

function ItemTransferDialog(props) {
  const { open, onClose, item, onItemTransfer } = props;

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [onNoteFocus, setOnNoteFocus] = React.useState();
  const [error, setError] = React.useState('');
  const [isValidBool, setIsValidBool] = React.useState(false);
  const [amountBlurred, setAmountBlurred] = React.useState(false);

  React.useEffect(() => {
    setIsValidBool(isValid());
  }, [data, amountBlurred]);

  React.useEffect(() => {
    window.loading = false;
  }, [open]);
  const _onClose = () => {
    onClose();
    window.loading = false;
    setTimeout(() => {
      setData({});
      setLoading(false);
      setAmountBlurred(false);
      setIsValidBool(false);
    }, 500);
  };

  React.useEffect(() => {
    setError('');
  }, [onClose]);

  const onSubmit = async () => {
    if (!window.loading) {
      window.loading = true;
      const fromStoreItemId = data.storeItemId;
      const toStoreId = data.selectedStore._id;
      const amount = data.amount;
      const note = data.note;
      const groupId = data.group ? data.group._id : null;

      try {
        await storesMng.storeItem.actions.transferStoreItem({
          fromStoreItemId,
          toStoreId,
          amount,
          note,
          groupId,
        });
        toast.success('Amount successfully transferred in', {
          autoClose: 900,
        });
        playSound('out');

        onItemTransfer();
        _onClose();
      } catch (e) {
        console.error(e);
        toast.error(e.reason, {
          autoClose: 6000,
        });
      } finally {
      }
    }
  };

  if (!item) {
    return null;
  }

  const isValid = () => {
    const _amountFloat = Number.parseFloat(data.amount);

    // validate blurring on amount
    if (isNaN(_amountFloat)) {
      if (amountBlurred) {
        setError('Amount is required');
      }
      return false;
    }
    // validate negative amount
    if (_amountFloat < 0) {
      setError('Negative amount value is not allowed');
      return false;
    }

    // validate batch info
    if (helpers.batch.isBatchedItem(item)) {
      // check if batch is selected
      if (!data.group) {
        setError('Batch group is required');
        return false;
      }
      if (!item.store.allowNegatives) {
        // check if the batch is negative
        if (data.group.quantity - _amountFloat < 0) {
          setError('Negative store batch quantity is not allowed');
          return false;
        }
        // check if the sum is negative
        const sum = helpers.batch.getSum(item);
        if (sum < 0) {
          setError('Negative store quantity is not allowed');
          return false;
        }
      }
    } else {
      // if not batch, check if the total is not negative
      const _sourceQuantity = item.quantity - (_amountFloat || 0);
      if (!item.store.allowNegatives && _sourceQuantity < 0) {
        setError(`${item.store.name} store does not allow negative quantities`);
        return false;
      }
    }

    // b. validate target store
    const selectedStore = data.selectedStore;

    if (!selectedStore) {
      setError(`Target store is required`);
      return false;
    }
    if (!selectedStore.allowNegatives) {
      const _targetQuantity = selectedStore.storeItem
        ? selectedStore.storeItem.quantity + (_amountFloat || 0)
        : 0;

      if (_targetQuantity < 0) {
        setError(
          `${selectedStore.name} store does not allow negative quantities`
        );
        return false;
      }
    }

    // clear error and set valid
    setError('');
    return true;
  };

  const renderActionBtn = () => {
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          width: '100%',
          marginTop: '40px',
        }}>
        <ActionBtn
          style={{
            marginBottom: '40px',
          }}
          loading={window.loading}
          onClick={() => {
            if (!window.loading) {
              onSubmit();
            }
          }}
          onEnter={() => {
            // if the note is not focused
            if (!error && !onNoteFocus && !window.loading) {
              onSubmit();
            }
          }}
          disabled={!isValidBool || loading || error}
          label={
            <span>
              <span
                style={{
                  marginRight: '10px',
                  top: '4px',
                  position: 'relative',
                }}>
                <Icons.Transfer size={22} />
              </span>
              Transfer
            </span>
          }
        />
        <div>
          <Button
            onClick={() => {
              _onClose();
            }}>
            Close
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Dialog
      open={open}
      className={'fullScreenMobile'}
      scoll={'body'}
      onClose={onClose}>
      <DialogTitle>
        <TopBar
          title={`${item.store.name}`}
          icon={
            <span>
              <IStores /> <Icons.Transfer size={22} color={'#5970d7'} />
            </span>
          }
          onClose={_onClose}
        />
      </DialogTitle>
      <DialogContent
        id={'transfer-window'}
        style={{
          paddingLeft: '15px',
          paddingRight: '15px',
        }}>
        {open && (
          <ActionInfo
            autoFocusQuantity
            hideImage
            isTransferDialog
            onChange={(v) => {
              setData(v);
            }}
            onNoteFocusUpdate={(v) => {
              setOnNoteFocus(v);
            }}
            onAmountBlur={() => {
              console.log('amount blurred');
              setAmountBlurred(true);
            }}
            type={helpers.TRANSACTION_TYPES.transferOut}
            item={item}
          />
        )}
        {error && (
          <div
            style={{
              marginTop: '10px',
            }}>
            <span style={{ color: 'red' }}>
              <IWarn /> {error}
            </span>
          </div>
        )}
        {renderActionBtn()}
      </DialogContent>
    </Dialog>
  );
}

ItemTransferDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onItemTransfer: PropTypes.func,
};

export default ItemTransferDialog;
