import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { App, AppContext } from '@meronex/app';
import { noDataImage } from '@meronex/assets';

import Avatar from '@material-ui/core/Avatar';
import SpeedDial from '@material-ui/lab/SpeedDial/SpeedDial';
import CircularProgress from '@material-ui/core/CircularProgress';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { withTheme } from '@material-ui/core/styles';
import {
  InfiniteLoader,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

import moment from 'moment';
import FaRegClock from '@meronex/icons/fa/FaRegClock';
import BsChevronDoubleUp from '@meronex/icons/bs/BsChevronDoubleUp';
import GiBackwardTime from '@meronex/icons/gi/GiBackwardTime';
import { notifMng } from '../api';
import { utils } from '../../../base/utils';

const ActivityTypeEnum = {
  NEW_LOG_CARD: 'newLogCard',
  MEMBER_JOIN: 'memberJoin',
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 50,
});

class ActivitiesList extends Component {
  state = {
    remoteRowCount: 10,
    activities: undefined,
    hasMore: true,
    selectedActivityIndex: undefined,
    selectedActivity: undefined,
    openCardDialog: false,
    renderData: undefined,
    fastRender: true,
    currentlyScrolling: false,
  };
  static propTypes = {
    setHasUnseenActivities: PropTypes.func,
    theme: PropTypes.object,
  };
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  componentDidMount() {
    this.init();
    this.updateLastActivitiesVisit();
    App.eventsManager.on('event-user-loaded', this, () => {
      this.init();
    });
    App.eventsManager.on('event-refresh-feed', this, () => {
      this.refreshActivities();
    });
  }

  componentWillUnmount() {
    this.props.setHasUnseenActivities(false);
  }

  init = () => {
    const { activities } = this.state;
    window.lastVisitIndexStamp = undefined;
    if (!activities) {
      this.loadMoreRows({ startIndex: 0, stopIndex: 9 });
    } else {
      this.refreshActivities();
    }
  };

  handleScroll = () => {
    if (this._timeout) {
      // if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._timeout = null;
      this.setState({
        currentlyScrolling: false,
      });
    }, 500);
    if (!this.state.currentlyScrolling) {
      this.setState({
        currentlyScrolling: true,
      });
    }
  };

  updateLastActivitiesVisit = () => {
    if (App.server) {
      App.server.call('updateLastActivitiesVisit', {});
    }
    App.fetchUser();
  };

  /**
   * Reset the activities list (back to the top)
   */
  refreshActivities = () => {
    window.lastVisitIndexStamp = undefined;
    this.loadMoreRows({ startIndex: 0, stopIndex: 10, refresh: true });
    this.scrollToTop();
  };

  /**
   * Update the current range index of the activities without resetting the list
   * or scrolling.
   */
  updateActivities = () => {
    const { renderData } = this.state;
    if (renderData) {
      this.loadMoreRows({
        startIndex: renderData.startIndex,
        stopIndex: renderData.stopIndex + 1,
      });
    }
  };

  scrollToTop = () => {
    const list = (this.listRef || {}).current;
    if (list) {
      list.scrollToRow(0);
    }
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    style, // Style object to be applied to row (to position it)
    parent,
  }) => {
    const _style = { ...style };
    const { activities } = this.state;
    let data;
    if (activities && activities[index]) {
      data = activities[index];
    }

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}>
        {({ measure }) => (
          <div key={key} style={_style}>
            {data && this.renderTimeline(data, isScrolling, index)}
          </div>
        )}
      </CellMeasurer>
    );
  };

  getLastActivitiesVisitDate = () => {
    const user = App.getUser();

    let lastVisitDate;
    if (user && user.settings && user.settings.lastActivitiesVisit) {
      lastVisitDate = user.settings.lastActivitiesVisit;
    }
    return lastVisitDate || new Date();
  };

  renderTimeline = (activity) => {
    if (!activity) {
      return <div />;
    }

    const { actor } = activity;
    const actorObj = (actor && actor[0]) || {
      emails: [{ address: 'unknown@email.com' }],
      profile: {
        firstName: 'Unknown',
        lastName: 'Unknown',
      },
    };

    const smartAvatar = (
      <Avatar
        style={{
          width: '40px',
          height: '40px',
        }}
        alt={App.utils.getUserName(actorObj)}
        src={actorObj.profile.avatar}
      />
    );

    const lastVisitDate = moment(this.getLastActivitiesVisitDate());
    const activityCreatedAt = moment(activity.createdAt);
    const diff = activityCreatedAt.diff(lastVisitDate, 'seconds');

    let backgroundColor = '';

    if (diff >= 0) {
      this.props.setHasUnseenActivities(true);
      backgroundColor = this.props.theme.palette.recentlySeen.backgroundColor;
    }

    return (
      <TimelineItem style={{ backgroundColor }}>
        <TimelineOppositeContent>
          <span className={'activities-time'}>
            <FaRegClock style={{ marginRight: '5px' }} />
            {utils.time.timeAgo(activity.createdAt)}
          </span>
        </TimelineOppositeContent>
        <TimelineSeparator>
          {activity.meta && activity.meta.icon && activity.icon.src ? (
            <img
              width="40px"
              className="notification-icon"
              src={activity.meta.icon.src}
            />
          ) : (
            smartAvatar
          )}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{this.renderActivityTitle(activity)}</TimelineContent>
      </TimelineItem>
    );
  };

  isRowLoaded = ({ index }) => {
    const { activities } = this.state;
    return !!activities[index];
  };

  getLoader = () => {
    const { activities } = this.state;
    const firstLoad = !activities || activities.length === 0;

    return (
      <div
        key={'loader'}
        style={{
          marginTop: firstLoad ? '30px' : '10px',
          marginBottom: '125px',
          textAlign: 'center',
          overflow: 'hidden',
        }}>
        {' '}
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={24}
          thickness={4}
        />
        {firstLoad && <div style={{ color: 'gray' }}>Loading...</div>}
      </div>
    );
  };

  loadMoreRows = async ({ startIndex, stopIndex, refresh = false }) => {
    const { activities, hasMore } = this.state;

    if (!App.server.connected) {
      console.warn('Waiting for server to connect,');
    } else {
      console.log(`load more ${startIndex} ${stopIndex}`);
      if (hasMore) {
        const fetchedActivities = await notifMng.fetchActivities(
          stopIndex - startIndex,
          startIndex
        );
        let _activities = activities;
        if (!_activities || refresh) {
          _activities = [];
        }
        if (fetchedActivities.length > 0) {
          // Update the underlying activities list based on the fetched ranged
          for (let i = startIndex; i < stopIndex; i += 1) {
            // Ignore undefined storeItems
            const item = fetchedActivities[i - startIndex];

            if (item) {
              _activities[i] = item;
            }
          }
        }

        let selectedActivity = this.state.selectedActivity;
        if (selectedActivity) {
          console.log(`updating selected activity  ${selectedActivity}`);
          selectedActivity = _activities[this.state.selectedActivityIndex];
        }
        if (_activities.length > 0) {
          this.setState({
            activities: _activities,
            remoteRowCount: _activities.length + 10,
            selectedActivity,
          });
        } else {
          this.setState({
            hasMore: false,
            activities: _activities,
            selectedActivity,
          });
        }
        return Promise.resolve(_activities);
      }
      console.info('has more is false');
    }
  };

  renderActivityTitle = (activity) => {
    const { actor, type } = activity;

    const actorObj = (actor && actor[0]) || {
      profile: {
        firstName: 'Unknown',
        lastName: 'Unknown',
      },
    };

    let activityTitle = 'Unknown activity';

    switch (type) {
      case ActivityTypeEnum.NEW_LOG_CARD:
        activityTitle = `${actorObj.profile.firstName} ${actorObj.profile.lastName} has created a card!`;
        break;
      case ActivityTypeEnum.MEMBER_JOIN:
        activityTitle = `${actorObj.profile.firstName} ${actorObj.profile.lastName} has joined the community!"`;
        break;
      default:
        console.log('Activity type not supported for activity title');
        break;
    }

    return (
      <div
        key={activity._id}
        id={activity._id}
        dangerouslySetInnerHTML={{ __html: activityTitle }}
        style={{
          wordWrap: 'anywhere',
        }}
      />
    );
  };

  render() {
    const {
      activities,
      remoteRowCount,
      renderData,
      currentlyScrolling,
    } = this.state;

    const { size, server } = App;
    const parentContainer = document.getElementById('notification-dialog');
    const width = parentContainer ? parentContainer.clientWidth : '600px';
    const loaded = activities && server.connected && size;

    return (
      <div
        onScroll={this.handleScroll}
        id={'activities-container'}
        style={{
          overflowY: 'hidden',
        }}>
        {!loaded && this.getLoader()}
        {loaded && (
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={remoteRowCount}
            style={{
              overflow: 'hidden',
            }}>
            {({ onRowsRendered, registerChild }) => (
              <>
                <Timeline
                  align="alternate"
                  ref={registerChild}
                  style={{
                    margin: '0px',
                    padding: '0px',
                    overflowX: 'hidden',
                  }}>
                  <List
                    style={{
                      padding: '0px',
                      paddingTop: '20px',
                      left: -(size.width / 9),
                    }}
                    height={size.height * 0.75}
                    onRowsRendered={(data) => {
                      if (data) {
                        this.setState({
                          renderData: data,
                        });
                        onRowsRendered(data);
                      }
                    }}
                    ref={this.listRef}
                    rowCount={activities.length}
                    deferredMeasurementCache={cache}
                    rowHeight={cache.rowHeight}
                    rowRenderer={this.rowRenderer}
                    noRowsRenderer={() => {
                      return (
                        <div>
                          <img src={noDataImage} />
                          <div
                            style={{
                              textAlign: 'center',
                              fontSize: '25px',
                              color: 'rgb(107, 107, 107)',
                            }}>
                            No Activities
                          </div>
                        </div>
                      );
                    }}
                    // scrollToAlignment={'center'}
                    width={width}
                  />
                </Timeline>
                {activities && renderData && currentlyScrolling && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '85px',
                      width: '100%',
                      textAlign: 'center',
                    }}>
                    <div
                      style={{
                        width: '240px',
                        margin: 'auto',
                        backgroundColor: 'rgba(25, 25, 25, 0.74)',
                        color: 'white',
                        borderRadius: '10px',
                        padding: '2px',
                        fontSize: '15px',
                      }}>
                      <div
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                          left: '-8px',
                          top: '2px',
                        }}>
                        <GiBackwardTime size={22} />
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                          top: '-5px',
                        }}>
                        {utils.time.timeFullFormat(
                          (activities[renderData.startIndex] || {}).createdAt
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {renderData && renderData.startIndex > 1 && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '40px',
                      width: '100%',
                      textAlign: 'center',
                    }}>
                    <SpeedDial
                      className={'logs-scroll-top'}
                      style={{
                        transition: 'none',
                        position: 'relative',
                        top: '-40px',
                      }}
                      ariaLabel="SpeedDial openIcon example"
                      onClick={() => {
                        this.scrollToTop();
                      }}
                      icon={<BsChevronDoubleUp size={30} />}
                      onClose={() => {}}
                      onOpen={() => {}}
                      open={false}
                    />
                  </div>
                )}
              </>
            )}
          </InfiniteLoader>
        )}
      </div>
    );
  }
}

export default withTheme(ActivitiesList);

ActivitiesList.contextType = AppContext;
