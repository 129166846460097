import React from 'react';
import PropTypes from 'prop-types';

import Quantity from '../../../../../common/Quantity';

function ValueBox(props) {
  const { type, item, newRate } = props;

  const labelStyle = {
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '16px',
  };
  console.log(item);

  const quantity = item.quantity;
  const currentRate = (() => {
    if (item.adjustedUnitRate) {
      return item.adjustedUnitRate.value;
    }
    return '?';
  })();

  const unit = item.type.unit;
  const value = (() => {
    if (!isNaN(newRate)) {
      return newRate * item.quantity;
    }
    if (currentRate) {
      return currentRate;
    }

    return '?';
  })();

  const formattedNum = (num) => {
    if (isNaN(num)) {
      return '?';
    }
    return num.toLocaleString(undefined, {
      maximumFractionDigits: 4,
    });
  };
  return (
    <div
      style={{
        border: '1px solid lightgray',
        borderRadius: '20px',
        padding: '10px',
        textAlign: 'center',
        position: 'relative',
        marginRight: '30px',
        marginLeft: '30px',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}>
        <div
          style={{
            width: '100%',
          }}>
          <div style={labelStyle}>
            {type === 'current' ? 'Current' : 'New'} Rate
          </div>
          <div>
            {formattedNum(newRate || currentRate)} AED/{unit}
          </div>
        </div>
        <div
          style={{
            width: '1px',
            background: 'gray',
          }}></div>
        <div
          style={{
            width: '100%',
          }}>
          <div style={labelStyle}>Quantity</div>
          <div>
            {quantity} {unit}
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: '1px solid lightgray',
          textAlign: 'center',
        }}></div>
      <div
        style={{
          padding: '15px',
        }}>
        <div style={labelStyle}>
          {type === 'current' ? 'Current' : 'New'} Value
        </div>
        <div
          style={{
            fontSize: type === 'new' ? '28px' : 'inherit',
          }}>
          {type === 'current' ? (
            <span>{value}</span>
          ) : (
            <Quantity
              quantitySize={80}
              showUnitLabel={false}
              enableChangeDirAnimation={false}
              inlineUnit
              quantity={value}
              style={{
                fontSize: '40px',
              }}
            />
          )}{' '}
          AED
        </div>
      </div>
    </div>
  );
}
ValueBox.propTypes = {
  type: PropTypes.oneOf(['current', 'new']),
  newRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ValueBox.defaultProps = {
  type: 'current',
};
export default ValueBox;
