import { App } from '@meronex/app';

const { server } = App;

export const storeCategoriesMng = {
  addStoreCategory: async (storeCategory) => {
    const result = await server.call('addStoreCategory', { storeCategory });
    return result;
  },
  updateStoreCategory: async (storeCategory) => {
    const result = await server.call('updateStoreCategory', { storeCategory });
    return result;
  },
  getStoreCategories: async () => {
    const result = await server.call('getStoreCategories', {});
    return result;
  },
  deleteStoreCategory: async (storeCategory) => {
    console.log(`delete store category, id: ${storeCategory._id}`);
    const result = await server.call('deleteStoreCategory', {
      _id: storeCategory._id,
    });
    return result;
  },
};
