import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import clone from 'clone';
import Timer from 'react-compound-timer';
import moment from 'moment';

import StepContent from '@material-ui/core/StepContent';
import Step from '@material-ui/core/Step';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fade from '@material-ui/core/Fade/Fade';
import { IconButton } from '@material-ui/core';

import INext from '@meronex/icons/md/MdNavigateNext';
import IBack from '@meronex/icons/md/MdNavigateBefore';
import IPlay from '@meronex/icons/cg/CgPlayButtonO';
import IPause from '@meronex/icons/fi/FiPauseCircle';
import IReset from '@meronex/icons/md/MdRefresh';
import IFinished from '@meronex/icons/fi/FiCheckCircle';
import IUnFinished from '@meronex/icons/fi/FiCircle';
import ICelsius from '@meronex/icons/mdc/MdcTemperatureCelsius';
import ITemp from '@meronex/icons/fa/FaTemperatureLow';
import StoreLink from '../../../../stores/components/stores/StoreLink';
import SitesAvatar from '../../../../common/SitesAvatar';
import WorkOrderStepLabel from './WorkOrderStepLabel';
import EquipmentsSelect from '../../../../equipments/components/EquipmentsSelect';
import Thermometer from 'react-thermometer-component';

import { helpers } from '../../../../common/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function WorkOrderStep(props) {
  const classes = useStyles();

  const timerRef = React.useRef();

  const {
    onStepClick,
    activeStep,
    handleBack,
    handleNext,
    active,
    showNavigation,
    item,
    onMaterialChange,
    onProcessItemChange,
    onStepTimerStart,
    onStepTimerStop,
    factory,
    disabled,
  } = props;

  const [error, setError] = React.useState(item.error);

  const _item = clone(item);
  if (!item.timer) {
    item.timer = {};
  }
  console.log(_item);

  React.useEffect(() => {
    isValid(item.value);
  }, [item]);

  const isValid = (value) => {
    const _value = Number.parseFloat(value);

    // only validate material step now
    if (!item.isProcessStep) {
      if (Number.isNaN(_value)) {
        setError('');
        item.error = '';
        return false;
      } else if (!item.store.allowNegatives && _item.quantity - _value < 0) {
        const error = `${item.store.name} does not allow negative values`;
        setError(error);
        item.error = error;
        return false;
      }
    }

    item.error = '';
    setError('');
    return true;
  };

  React.useEffect(() => {
    if (timerRef.current) {
      setTimerState();
    }
  }, [timerRef.current]);
  const renderStepName = () => {
    return (
      <WorkOrderStepLabel
        {...props}
        onClick={(e) => {
          onStepClick();
        }}
        error={error}
        active={active}
        text={_item.type.name}
        imgSrc={_item.type.image && _item.type.image.imgUrl}>
        {!active && Boolean(_item.value) && (
          <div style={{ position: 'relative' }}>
            <Fade in={!Number.isNaN(_item.value)}>
              <div
                style={{
                  position: 'absolute',
                  color: 'gray',
                  top: '10px',
                  fontSize: '12px',
                }}>
                {_item.value} {_item.type.unit} from{' '}
                <StoreLink store={_item.store} />
              </div>
            </Fade>
          </div>
        )}
      </WorkOrderStepLabel>
    );
  };

  let placeholderHelp;

  if (_item.quantity) {
    placeholderHelp = `  ${helpers.formatNumber(_item.quantity)} ${
      _item.type.unit
    } at ${_item.store.name}`;
    placeholderHelp = App.utils.string.truncate(placeholderHelp, 30);
  }

  const renderMaterialStep = () => {
    return (
      <TextField
        id={_item._id}
        name={_item._id}
        fullWidth
        style={{
          textAlign: 'center',
          marginBottom: '5px',
        }}
        type={'number'}
        error={error}
        autoFocus
        disabled={disabled}
        placeholder={placeholderHelp}
        value={_item.value}
        onChange={(e) => {
          onMaterialChange(e, _item, isValid(e.target.value));
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">{_item.type.unit}</InputAdornment>
          ),
        }}
      />
    );
  };

  const onToggleStepPlay = (currentDate) => {
    if (!_item.timer.active) {
      // start
      timerRef.current.start();
      if (!_item.timer.startDate) {
        _item.timer.startDate = currentDate;
      }
      _item.timer.active = true;
      _item.completed = false;
      onStepTimerStart(_item);
    } else {
      // stop
      timerRef.current.stop();
      _item.timer.active = false;
      _item.timer.stopDate = currentDate;
      _item.timer.state = timerRef.current.getTime();
      onStepTimerStop(_item);
    }
    onProcessItemChange(_item);
  };

  const onReset = () => {
    const date = new Date();
    window.intialTime = 0;

    if (timerRef.current) {
      timerRef.current.reset();
      timerRef.current.stop();
    }
    _item.timer.active = false;
    _item.timer.startDate = date;
    _item.timer.stopDate = date;
    _item.timer.state = null;
    setTimerState();
    onProcessItemChange(_item);
  };

  const toggleFinish = () => {
    _item.completed = !_item.completed;

    if (_item.completed) {
      _item.timer.active = false;
      _item.timer.stopDate = new Date();
      _item.timer.state = timerRef.current.getTime();
      timerRef.current.stop();
    }
    onProcessItemChange(_item);
  };
  const setTimerState = () => {
    let diff = 0;
    if ((_item.timer || {}).active) {
      diff = moment(moment()).diff(_item.timer.startDate);
      window.intialTime = diff;
    } else if ((_item.timer || {}).state && timerRef.current) {
      timerRef.current.setTime(_item.timer.state);
    }
    return diff;
  };

  const resetBtn = () => {
    return (
      <div
        style={{
          position: 'absolute',
          left: '15px',
          top: '50px',
          width: '80px',
        }}>
        <IconButton onClick={onReset}>
          <IReset size={30} color={'#556cd6'} />
        </IconButton>
        <div
          style={{
            position: 'relative',
            top: '-10px',
          }}>
          Reset
        </div>
      </div>
    );
  };

  const finishedBtn = () => {
    return (
      <div
        style={{
          position: 'absolute',
          right: '15px',
          top: '50px',
          width: '80px',
        }}>
        <IconButton onClick={toggleFinish} disabled={disabled}>
          {item.completed && <IFinished size={24} color={'#556cd6'} />}
          {!_item.completed && <IUnFinished size={25} color={'#556cd6'} />}
        </IconButton>
        <div
          style={{
            position: 'relative',
            top: '-8px',
          }}>
          {_item.completed ? 'Unfinished' : 'Finished'}
        </div>
      </div>
    );
  };

  const renderProcessStep = () => {
    console.log(factory);

    if (item.isTempMeasure) {
      return (
        <div
          style={{
            position: 'relative',
            textAlign: 'center',
          }}>
          {item.description}
          <div
            style={{
              marginTop: '20px',
            }}>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '20px',
              }}>
              <Thermometer
                theme="light"
                value={_item.value || 0}
                max="100"
                steps="3"
                format="°C"
                size="normal"
                height="300"
              />
            </div>

            <TextField
              id={_item._id}
              name={_item._id}
              fullWidth
              variant={'standard'}
              style={{
                textAlign: 'center',
                marginBottom: '5px',
              }}
              type={'number'}
              error={error}
              autoFocus
              disabled={disabled}
              placeholder={placeholderHelp}
              value={_item.value}
              onChange={(e) => {
                onMaterialChange(e, _item, isValid(e.target.value));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ITemp size={25} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ICelsius size={25} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
        }}>
        {item.description}
        <div
          style={{
            marginTop: '20px',
          }}>
          <EquipmentsSelect
            disabled={disabled}
            equipment={(_item || {}).equipment}
            factoryId={factory._id}
            onChange={(v) => {
              console.log(v);
              _item.equipment = v;
              _item.completed = false;
              onReset();
              onProcessItemChange(_item);
            }}
          />
        </div>
        {item.equipment && (
          <>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '40px',
                position: 'relative',
              }}>
              {!disabled && !(_item.timer || {}).active && (
                <div>
                  {resetBtn()}
                  <IconButton
                    onClick={() => {
                      onToggleStepPlay(new Date());
                    }}>
                    <IPlay size={70} color={'rgb(2 139 44)'} />
                  </IconButton>
                  <div>Start</div>
                  {finishedBtn()}
                </div>
              )}
              {!disabled && (_item.timer || {}).active && (
                <div>
                  {resetBtn()}
                  <IconButton
                    onClick={() => {
                      onToggleStepPlay(new Date());
                    }}>
                    <IPause size={70} />
                  </IconButton>
                  <div>Stop</div>
                  {finishedBtn()}
                </div>
              )}
            </div>

            <div
              style={{
                position: 'relative',
              }}>
              <div
                style={{
                  position: 'relative',
                  marginBottom: '-62px',
                  marginTop: '20px',
                }}>
                <div key={((_item || {}).timer || {}).startDate}>
                  <Timer
                    ref={timerRef}
                    startImmediately={!!(_item.timer || {}).active}
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {},
                      },
                    ]}
                    initialTime={setTimerState()}
                    direction="forward">
                    {() => (
                      <React.Fragment>
                        <span
                          style={{
                            fontSize: '16px',
                            marginTop: '0x',
                            color: (_item.timer || {}).active
                              ? 'rgb(2 139 44)'
                              : 'inherit',
                          }}>
                          <Timer.Days />d <Timer.Hours />h <Timer.Minutes />m{' '}
                          <Timer.Seconds />s
                        </span>
                      </React.Fragment>
                    )}
                  </Timer>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
  const renderStepContent = () => {
    const imgSrc = _item.type.image && _item.type.image.imgUrl;
    return (
      <StepContent {...props}>
        <div
          style={{
            marginTop: '20px',
            minHeight: '135px',
          }}>
          {!_item.isTempMeasure && (
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '20px',
              }}>
              <SitesAvatar
                image={imgSrc}
                name={_item.type.name}
                size={90}
                round={10}
              />
            </div>
          )}
          <div
            style={{
              marginBottom: '20px',
            }}>
            {!_item.isProcessStep && renderMaterialStep()}
            {_item.isProcessStep && renderProcessStep()}
          </div>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          {showNavigation && (
            <div className={classes.actionsContainer}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}>
                <IconButton disabled={activeStep === 0} onClick={handleBack}>
                  <IBack size={35} />
                </IconButton>

                <IconButton onClick={handleNext}>
                  <INext size={35} color={'#556cd6'} />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </StepContent>
    );
  };

  return (
    <Step key={item._id} {...props}>
      {renderStepName()}
      {renderStepContent()}
    </Step>
  );
}

WorkOrderStep.propTypes = {
  step: PropTypes.object,
  item: PropTypes.object,
  lastStep: PropTypes.bool,
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  onStepClick: PropTypes.func,
  getLabelColor: PropTypes.func,
  showNavigation: PropTypes.bool,
  value: PropTypes.object,
  onMaterialChange: PropTypes.func,
  onProcessItemChange: PropTypes.func,
  onStepTimerStart: PropTypes.func,
  onStepTimerStop: PropTypes.func,
};
WorkOrderStep.defaultProps = {
  showNavigation: true,
  disabled: false,
  onStepClick: () => {
    console.log('on step click');
  },
};

export default WorkOrderStep;
