import { App } from '@meronex/app';

const { server } = App;

export const itemCategoriesMng = {
  addItemCategory: async (category) => {
    const result = await server.call('addStoreItemCategory', { category });
    return result;
  },
  updateItemCategory: async (category) => {
    const result = await server.call('updateStoreItemCategory', { category });
    return result;
  },
  getItemCategories: async () => {
    const result = await server.call('getStoreItemsCategories', {});
    return result;
  },
  deleteItemCategory: async (itemCategory) => {
    console.log(`delete itemCategory, id: ${itemCategory._id}`);
    const result = await server.call('deleteStoreItemCategory', {
      _id: itemCategory._id,
    });
    return result;
  },
};
