import { App } from '@meronex/app';

const { server } = App;

export const notifMng = {};

const fetchNotifications = async (limit, skip) => {
  return await server.call('fetchNotifications', {
    limit,
    skip,
  });
};

const fetchActivities = async (limit, skip) => {
  return await server.call('fetchActivities', {
    limit,
    skip,
  });
};

const fetchUnreadNotificationsCount = async () => {
  return await server.call('fetchUnreadNotificationsCount', {});
};

const fetchHasUnseenActivities = async () => {
  return await server.call('fetchHasUnseenActivities', {});
};

notifMng.fetchNotifications = fetchNotifications;
notifMng.fetchActivities = fetchActivities;
notifMng.fetchUnreadNotificationsCount = fetchUnreadNotificationsCount;
notifMng.fetchHasUnseenActivities = fetchHasUnseenActivities;
