import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, ActionBtn, AlertDialog, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Icons } from '../../../../../common/Icons';
import { TextField } from '@material-ui/core';
import ValueBox from './ValueBox';

import { storesMng } from '../../../../api/storesMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function AdjustUnitRateDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, item, onAdjustUnitRate } = props;

  const [newRate, setNewRate] = React.useState();
  const [openAlertDialog, setOpenAlertDialog] = React.useState();

  if (!item) {
    return null;
  }

  const _onAdjustRate = async () => {
    console.log(`adjust rate called, new rate ${newRate}`);
    // update the server, wait and toast and close
    const result = await storesMng.storeItem.actions.updateStoreItemAdjustedRate(
      {
        adjustedRateValue: newRate,
        storeItemId: item._id,
      }
    );
    if (onAdjustUnitRate) {
      onAdjustUnitRate();
    }
    toast.success('Item adjusted rate updated');
    onClose();
  };
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <Icons.UnitRate /> Adjust Unit Rate
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <div
          style={{
            minHeight: '600px',
            marginTop: '50px',
            position: 'relative',
          }}>
          <ValueBox item={item} />
          <div
            style={{
              height: '200px',
              top: '-234px',
              position: 'relative',
            }}>
            <div
              style={{
                textAlign: 'center',
                position: 'absolute',
                width: '100%',
                top: '300px',
                backgroundColor: 'white',
                zIndex: 100,
              }}>
              <TextField
                variant={'outlined'}
                autoFocus
                type={'number'}
                onChange={(event) => {
                  console.log(event.target.value);
                  setNewRate(parseFloat(event.target.value));
                }}
                value={newRate}
                placeholder={'new unit rate'}
                style={{
                  borderRadius: '20px',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      AED/{item.type.unit}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    fontSize: '25px',
                    textAlign: 'center',
                  },
                }}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '310px',
                textAlign: 'center',
                width: '100%',
              }}>
              <a className="down_line" href="#">
                <span className="down_line__arrow">
                  <span></span>
                  <span></span>
                </span>
                <span className="down_line__line"></span>
              </a>
            </div>
          </div>
          <div>
            <ValueBox type={'new'} item={item} newRate={newRate} />
          </div>
        </div>
        <div
          style={{
            marginTop: '40px',
            textAlign: 'center',
            marginBottom: '40px',
          }}>
          <ActionBtn
            onClick={() => {
              setOpenAlertDialog(true);
            }}
          />
        </div>
        <AlertDialog
          open={openAlertDialog}
          onClose={() => {
            setOpenAlertDialog(false);
          }}
          onConfirm={() => {
            console.log('confirm btn');
            setOpenAlertDialog(false);
            _onAdjustRate();
          }}
          confirmBtn={
            <span
              style={{
                color: 'green',
              }}>
              Yes, Adjust The Rate
            </span>
          }
          description={
            'Are you sure you want to modify the item adjusted rate?'
          }
        />
      </DialogContent>
    </Dialog>
  );
}

AdjustUnitRateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdjustUnitRate: PropTypes.func,
};

export default AdjustUnitRateDialog;
