import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IRefresh from '@meronex/icons/bi/BiRefresh';

import TopBar from '../../../views/components/TopHeader';
import { Icons } from '../../../common/Icons';
import WorkOrdersList from '../workorders/listWorkOrders/WorkOrdersList';
import { workOrdersMng } from '../../api/WorkOrdersMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function WorkOrdersDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, factory } = props;

  const workOrderListRef = React.useRef();

  const [fetchValues, setFetchValues] = React.useState({
    limit: 4,
    skip: 0,
  });

  const [filters, setFilters] = React.useState({});
  const [fetching, setFetching] = React.useState(false);
  const [lastFetchDate, setLastFetchDate] = React.useState();
  const [isScrolling, setIsScrolling] = React.useState(false);

  const _onClose = () => {
    App.eventsManager.emit('event-reset-filter', WorkOrdersDialog.name);
    onClose();
  };

  React.useEffect(() => {
    console.log(filters);
    if (workOrderListRef.current) {
      workOrderListRef.current.refresh();
    }
  }, [filters]);

  const fetchWorkOrders = async (values = fetchValues) => {
    console.log('fetching work orders');
    console.log(values);
    const _filters = { ...filters };
    setFetching(true);
    const moreWorkOrders = await workOrdersMng.getWorkOrdersCursor({
      factoryId: factory._id,
      limit: values.limit,
      skip: values.skip,
      filters: _filters,
    });
    setFetchValues(values);
    setFetching(false);
    setLastFetchDate(new Date());
    return moreWorkOrders;
  };

  if (!factory) {
    return null;
  }
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      disableBackdropClick="false"
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopBar
          title={'Work Orders'}
          icon={<Icons.WorkOrder />}
          filterProps={{
            placeholder: 'search item name or code',
            expandable: true,
            filterOnApply: true,
            enableDateRange: true,
            onReset: () => {
              setFilters({});
            },
            onApply: (v) => {
              console.log(v);
              const _filters = Object.assign({ ...filters }, v);
              setFilters(_filters);
            },
            onFilter: (v) => {
              const _filters = { ...filters };
              _filters.filterValue = v;
              setFilters(_filters);
            },
          }}
          onClose={() => {
            _onClose();
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '12px',
            right: '65px',
          }}>
          <IconButton
            onClick={() => {
              fetchWorkOrders();
            }}>
            <IRefresh color={'black'} className={fetching ? 'spin' : ''} />
          </IconButton>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '38px',
            right: '26px',
          }}>
          <span
            style={{
              color: '#9b9b9b',
              fontSize: '12px',
              position: 'relative',
            }}>
            updated {lastFetchDate && App.utils.time.timeAgo(lastFetchDate)}
          </span>
        </div>
      </DialogTitle>
      <DialogContent
        className={'no-select'}
        style={{
          overflow: 'hidden',
          padding: '10px',
          marginTop: '-12px',
        }}>
        <div>
          <div>
            <WorkOrdersList
              ref={workOrderListRef}
              onScroll={(v) => {
                setIsScrolling(v);
              }}
              showSelectProduct={false}
              factory={factory}
              fetchWorkOrders={fetchWorkOrders}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

WorkOrdersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  factory: PropTypes.object,
};

export default WorkOrdersDialog;
