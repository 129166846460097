import { App } from '@meronex/app';
import Papa from 'papaparse';

const { server } = App;

const mockData = [
  {
    _id: 'Zu3pXXeHnLCRJysRf',
    createdAt: '2022-01-20T12:26:51.632Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1639.1,
    },
  },
  {
    _id: 'WbqiQbzu6uJH9Hng2',
    createdAt: '2022-01-20T12:39:59.067Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 5503.75,
    },
  },
  {
    _id: 'KjwY7pJumLhERdX95',
    createdAt: '2022-01-20T12:40:21.978Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2393,
    },
  },
  {
    _id: '4gWn6hb7Qe6zLgZ2D',
    createdAt: '2022-01-20T12:40:38.030Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 547,
    },
  },
  {
    _id: 'Z6aB6Fbc2ptsvkrmQ',
    createdAt: '2022-01-20T12:41:00.703Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2135,
    },
  },
  {
    _id: 'Jo5jaF94u7f8LAJGj',
    createdAt: '2022-01-20T12:53:28.175Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT',
        unit: 'Kg',
      },
      quantity: 430,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'AWazECRRQ7tnBPNkK',
    createdAt: '2022-01-20T12:53:28.201Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 3711,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '2qv4aMwsY5WDnnRhN',
    createdAt: '2022-01-20T12:53:28.222Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 365,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'mSCsEA9482YY7KCeA',
    createdAt: '2022-01-21T04:24:52.495Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 231,
    },
  },
  {
    _id: 'DRoJZLLdmh6oMttfi',
    createdAt: '2022-01-21T05:07:10.842Z',
    transactionType: 'transferIn',
    amount: 61.36,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6003.75,
    },
  },
  {
    _id: 'pJRjxomkfzfXwmCP9',
    createdAt: '2022-01-21T06:02:27.195Z',
    transactionType: 'transferIn',
    amount: 15,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 607,
    },
  },
  {
    _id: 'kRkRgtoQdXJdrvAPd',
    createdAt: '2022-01-21T10:37:08.286Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1350,
    },
    transactionType: 'transferOut',
    amount: -172,
  },
  {
    _id: 'uiQGuvtwMT5SQHycb',
    createdAt: '2022-01-21T10:37:08.307Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 3675,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'Tdzj6zDmZjTLLy5mK',
    createdAt: '2022-01-21T10:37:08.324Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 1629,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'cjX9eopS33dA5bYDd',
    createdAt: '2022-01-21T10:37:08.342Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 362,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'BBRXeXjEYHgRszL4f',
    createdAt: '2022-01-21T10:37:40.025Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1178,
    },
    transactionType: 'transferOut',
    amount: -39,
  },
  {
    _id: 'LhchwfrruD8zuydKJ',
    createdAt: '2022-01-21T10:37:40.044Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 3602,
    },
    transactionType: 'transferOut',
    amount: -39,
  },
  {
    _id: '2JnPkStYBM29iEoL2',
    createdAt: '2022-01-21T10:37:40.062Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 356,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'xcy7PFohAcXvHKnrg',
    createdAt: '2022-01-23T05:37:04.817Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 622,
    },
  },
  {
    _id: 'tJjtLtG6CXPseN7Cj',
    createdAt: '2022-01-23T05:37:17.813Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 256,
    },
  },
  {
    _id: 'nj9CJDxRouPNWL7NL',
    createdAt: '2022-01-23T05:38:04.056Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 3563,
    },
  },
  {
    _id: 'P58N7tpokyJjGQd7u',
    createdAt: '2022-01-23T11:32:56.459Z',
    transactionType: 'transferIn',
    amount: 480,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 1627,
    },
  },
  {
    _id: 'n7SMBpfCDw9reiBxD',
    createdAt: '2022-01-23T11:33:16.489Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6065.11,
    },
  },
  {
    _id: 'mnRWLgqms6gSrejWj',
    createdAt: '2022-01-23T11:33:49.471Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2593,
    },
  },
  {
    _id: 'x3muFGrMKhACZBX6Y',
    createdAt: '2022-01-23T11:34:18.165Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1714.1,
    },
  },
  {
    _id: '8X8SiEmvGzYozHmk4',
    createdAt: '2022-01-23T11:35:12.657Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 352,
    },
  },
  {
    _id: 'gxc4a8GJ4nXWNxhS7',
    createdAt: '2022-01-23T11:46:22.466Z',
    transactionType: 'in',
    amount: 2550,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'MILK',
        unit: 'Liter',
      },
      quantity: 15455,
    },
  },
  {
    _id: '5zmo3NWmW4LrLZrpf',
    createdAt: '2022-01-23T11:46:41.285Z',
    transactionType: 'in',
    amount: 800,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1139,
    },
  },
  {
    _id: 'Wip7Y4GkXr9tgPixs',
    createdAt: '2022-01-23T11:56:21.012Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1939,
    },
    transactionType: 'transferOut',
    amount: -243,
  },
  {
    _id: '8L2Z3Jg8dvodPy874',
    createdAt: '2022-01-23T11:56:21.029Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 4203,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'QJFMqfGgsuyyTtKWo',
    createdAt: '2022-01-23T11:56:21.049Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 2107,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'w3JQPpjxQnA2bKT5X',
    createdAt: '2022-01-23T11:56:21.065Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 412,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'pZAkiKF7bpBMffzAk',
    createdAt: '2022-01-23T11:57:29.650Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1696,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'QjjKNrr44y28hJ5Ck',
    createdAt: '2022-01-23T11:57:29.664Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 4103,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'jj2Sc9k9z2iHJrdbt',
    createdAt: '2022-01-23T11:57:29.679Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 403,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'bGwktt23HpkPuZuwf',
    createdAt: '2022-01-23T12:01:30.889Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2713,
    },
  },
  {
    _id: 'h9n59T95n7GjMf8WY',
    createdAt: '2022-01-23T14:13:07.314Z',
    transactionType: 'in',
    amount: 38,
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: 'YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
      },
      quantity: 0,
    },
  },
  {
    _id: 'Ku4gMsPDbvLsGWhnK',
    createdAt: '2022-01-24T04:12:54.232Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'whpyT6mJmKEEeF6DT',
        name: 'D.Milk',
        unit: 'Litter ',
      },
      quantity: 0,
    },
  },
  {
    _id: 'EBAX2hqWMsqBLSAjW',
    createdAt: '2022-01-24T05:06:05.754Z',
    transactionType: 'in',
    amount: 1741,
    storeItem: {
      type: {
        _id: 'DFWrBZWAmHscSd9fB',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 0,
    },
  },
  {
    _id: 'y87Mp6rijeMhSMgt4',
    createdAt: '2022-01-24T07:31:01.713Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D. MILK',
        unit: 'Liter',
      },
      quantity: 18005,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'Q8f87YjEsX3ckuFip',
    createdAt: '2022-01-24T07:31:01.737Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 4613.6,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'TtcPWqFfNf9nkDkBN',
    createdAt: '2022-01-24T07:31:01.772Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 80078,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'uYw4qbuMRdPX8ZJjC',
    createdAt: '2022-01-24T07:31:01.799Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9829.6,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'YJetpifNnSRpo7PDx',
    createdAt: '2022-01-24T07:31:01.818Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 483,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GKvmpo22JJagsjkpW',
    createdAt: '2022-01-24T07:31:01.835Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 481,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aZvJhbZXWDoSGcq2j',
    createdAt: '2022-01-24T07:31:01.857Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'toXnXrraz6cdJM4jb',
    createdAt: '2022-01-24T07:31:01.875Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2753,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'y9ygD9jx5meLibYZT',
    createdAt: '2022-01-24T12:23:58.694Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2703,
    },
  },
  {
    _id: 'J4pRtcXCwrEbyNJeY',
    createdAt: '2022-01-24T12:28:05.633Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1964.1,
    },
  },
  {
    _id: 'iwXo94adYyohomH56',
    createdAt: '2022-01-24T12:28:24.237Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6315.11,
    },
  },
  {
    _id: '9Pvs9MnbFhLFfhZvS',
    createdAt: '2022-01-24T12:28:45.165Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 652,
    },
  },
  {
    _id: 'TaKC9mraB8cX7P9z8',
    createdAt: '2022-01-24T12:29:36.919Z',
    transactionType: 'transferIn',
    amount: 94,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2235,
    },
  },
  {
    _id: 'pCpPF4Lrqz9N3gucp',
    createdAt: '2022-01-24T12:30:00.904Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2978,
    },
  },
  {
    _id: 'BJWKTH34Qvcq4ztXC',
    createdAt: '2022-01-24T12:30:25.757Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 170,
    },
  },
  {
    _id: 'Sd6nH59h9NHBHbk8f',
    createdAt: '2022-01-24T13:02:47.041Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D. MILK',
        unit: 'Liter',
      },
      quantity: 17005,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'stDuHnpArAyH5KxBd',
    createdAt: '2022-01-24T13:02:47.070Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2039.1,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '3Z8waiTca7YKLr9Hh',
    createdAt: '2022-01-24T13:02:47.086Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9769.6,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'S9aorSPGQRbFbXKfT',
    createdAt: '2022-01-24T13:02:47.110Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 79578,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '46D2iwnwHA6dWGhXW',
    createdAt: '2022-01-24T13:02:47.126Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6565.11,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oNaGZEmA2XFtrQHm9',
    createdAt: '2022-01-24T13:02:47.144Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19159,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qip9REWYoBDK6ifzo',
    createdAt: '2022-01-24T13:02:47.158Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19634,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FRarhfLk2rLakRScY',
    createdAt: '2022-01-24T13:02:47.177Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3078,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GQyJLJ6Y8Zt83wozF',
    createdAt: '2022-01-24T13:49:53.776Z',
    transactionType: 'in',
    amount: 1950.26,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 0,
    },
  },
  {
    _id: 'NG796XBX4tBL26uFy',
    createdAt: '2022-01-25T04:33:12.098Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 281,
    },
  },
  {
    _id: 'FyrimpWvQuryrFFj2',
    createdAt: '2022-01-25T05:02:50.862Z',
    transactionType: 'in',
    amount: 1.64425,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16405,
    },
  },
  {
    _id: 'jbaXupLdmHukiTZ2P',
    createdAt: '2022-01-25T05:27:05.402Z',
    transactionType: 'in',
    amount: 1644.25,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 1741,
    },
  },
  {
    _id: 'yjiNBRoRnNfwvQzPR',
    createdAt: '2022-01-25T05:45:03.949Z',
    transactionType: 'out',
    amount: -2060,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16406.64425,
    },
  },
  {
    _id: '3pTxC4gs4cciHNh57',
    createdAt: '2022-01-25T11:40:59.890Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 1950.26,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gqQfyX49tJNndME8x',
    createdAt: '2022-01-25T11:40:59.911Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 3385.25,
    },
    transactionType: 'transferOut',
    amount: -3385.25,
  },
  {
    _id: '4P8sEaXjP4Lm6bZBp',
    createdAt: '2022-01-25T11:40:59.932Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 79538,
    },
    transactionType: 'transferOut',
    amount: -1750,
  },
  {
    _id: 'BEeRuoxDXkfhobHo4',
    createdAt: '2022-01-25T11:40:59.953Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9765.6,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'czQiFEBefoR3R6gbk',
    createdAt: '2022-01-25T13:01:26.919Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2017.1,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: '9wnDC8d4JXybADrDa',
    createdAt: '2022-01-25T13:01:26.939Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 14346.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'fFsEpDoz6vrQuJGki',
    createdAt: '2022-01-25T13:01:26.956Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9555.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'NeFh3q8JL67vomZQD',
    createdAt: '2022-01-25T13:01:26.993Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3078,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WrC6eZJ9jvtXJ2sa4',
    createdAt: '2022-01-25T13:01:27.013Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RPNgJnr6DXRcaniQM',
    createdAt: '2022-01-25T13:01:41.195Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2017.1,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: '2YN9HneZuhRrAsPW3',
    createdAt: '2022-01-25T13:01:41.213Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 14346.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'a3BaMB9KRhcvZwtC7',
    createdAt: '2022-01-25T13:01:41.231Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9555.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ZcasLQMKCe3j2rexd',
    createdAt: '2022-01-25T13:01:41.247Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3078,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wg7xz3pJDFGfvvP22',
    createdAt: '2022-01-25T13:01:41.263Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EPMi7B5XMZT3HWtMc',
    createdAt: '2022-01-25T13:01:53.922Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2017.1,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: 'SR787Q6JqBurK7XpC',
    createdAt: '2022-01-25T13:01:53.940Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 14346.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'JhBZbKPBPt8EohZGS',
    createdAt: '2022-01-25T13:01:53.954Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9555.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'uBNX9yPNDbsBCtRBM',
    createdAt: '2022-01-25T13:01:53.968Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3078,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g4DMMNhWEEgBijr6k',
    createdAt: '2022-01-25T13:01:53.981Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mRBtC2uBF4HKYoGfd',
    createdAt: '2022-01-25T13:02:20.043Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2017.1,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: 'AELJNehKn3vnakpRn',
    createdAt: '2022-01-25T13:02:20.059Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 14346.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'WzxMo7eHZTCXx6ozv',
    createdAt: '2022-01-25T13:02:20.074Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9555.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'HTCugcHofjdHbA3u7',
    createdAt: '2022-01-25T13:02:20.090Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3078,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'HmFE3ZXszNHoYJHty',
    createdAt: '2022-01-25T13:02:20.107Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Z5yzNLxEokM4fcqCH',
    createdAt: '2022-01-25T13:06:01.365Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1949.9,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: '6X9SDL3uMSL3PZiGb',
    createdAt: '2022-01-25T13:06:01.382Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11746.644250000001,
    },
    transactionType: 'transferOut',
    amount: -350,
  },
  {
    _id: 'M88gsiXZqxK99BFT4',
    createdAt: '2022-01-25T13:06:01.397Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9355.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'NDzgMto8Su9EcTx4x',
    createdAt: '2022-01-25T13:06:01.413Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19159,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NSKFeA9RiPFFJwauP',
    createdAt: '2022-01-25T13:06:01.428Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 52,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mHHtZSnaAMNkeYoGk',
    createdAt: '2022-01-25T13:06:01.457Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'Roll',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21863,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ze2agaMXNjsrTxgSc',
    createdAt: '2022-01-25T13:09:06.079Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1933.1000000000001,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: 'eq8pkbMKCMSyvEGxb',
    createdAt: '2022-01-25T13:09:06.096Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11396.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'g98NpGMris3kfN6QK',
    createdAt: '2022-01-25T13:09:06.112Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9305.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'uq4vaaAjCBrkBsLhf',
    createdAt: '2022-01-25T13:09:06.127Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3034,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: '4Fgw6TwaYJTjWgHSX',
    createdAt: '2022-01-25T13:09:06.144Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8CCxkjXEkzaT6eqZE',
    createdAt: '2022-01-25T13:09:15.911Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1933.1000000000001,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: 'SZ8zKoEQ54zA5AQRP',
    createdAt: '2022-01-25T13:09:15.930Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11396.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'TRWYZC6pnco5sNqA4',
    createdAt: '2022-01-25T13:09:15.945Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9305.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '2Xv3a7mZyiWABtuzS',
    createdAt: '2022-01-25T13:09:15.965Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3034,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: '57AuETQ5yBvhmLqBw',
    createdAt: '2022-01-25T13:09:15.984Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cSBMRRhzuTPjv7vof',
    createdAt: '2022-01-25T13:09:33.003Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1933.1000000000001,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: 'cJhqkgB9TfYvbsvs4',
    createdAt: '2022-01-25T13:09:33.022Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11396.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'wP4ssi5ctBEbGzbdr',
    createdAt: '2022-01-25T13:09:33.035Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9305.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'xCaiCE2eu3s7MdFpn',
    createdAt: '2022-01-25T13:09:33.050Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3034,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'ARLLNQjhdfs7gP3hz',
    createdAt: '2022-01-25T13:09:33.064Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'x5aZnmg5wjZDvFT65',
    createdAt: '2022-01-25T13:11:45.333Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 9446.644250000001,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'jqXASydniZA6LHw8e',
    createdAt: '2022-01-25T13:11:45.354Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1882.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -16.8,
  },
  {
    _id: 'E5egfC5RyLccBvsro',
    createdAt: '2022-01-25T13:11:45.371Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9155.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'mtWhvem4oGrxX6Wpb',
    createdAt: '2022-01-25T13:11:45.388Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 77788,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PSQRrasodKWmifurh',
    createdAt: '2022-01-25T13:11:45.419Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6565.11,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g26rDsdYPnDGay5xP',
    createdAt: '2022-01-25T13:11:45.440Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19159,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Jske67TFyQaDwJaA3',
    createdAt: '2022-01-25T13:11:45.455Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19634,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r8ZnE4kvLAzeN8sNA',
    createdAt: '2022-01-25T13:11:45.477Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2902,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'YQTpwcbH2hP4a33fe',
    createdAt: '2022-01-25T13:28:48.056Z',
    transactionType: 'in',
    amount: 1682,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 1950.26,
    },
  },
  {
    _id: 'qArKkDnSiiLYu2mqF',
    createdAt: '2022-01-25T13:29:44.214Z',
    transactionType: 'in',
    amount: 1644.25,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 0,
    },
  },
  {
    _id: 'pvTxF8u4yResncLyQ',
    createdAt: '2022-01-25T13:38:29.289Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 190,
    },
  },
  {
    _id: 'k5rET7zhHfugEdkbD',
    createdAt: '2022-01-25T13:38:46.555Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2858,
    },
  },
  {
    _id: 'vNn8kFdn78s4N7RTf',
    createdAt: '2022-01-25T13:39:04.767Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2329,
    },
  },
  {
    _id: '49djPEYiPNwNTfjy3',
    createdAt: '2022-01-25T13:39:24.001Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 712,
    },
  },
  {
    _id: '5Ptn4eDNLZA42TYJg',
    createdAt: '2022-01-25T13:40:23.621Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6565.11,
    },
  },
  {
    _id: 'dHKsmdZy8ymPvitHv',
    createdAt: '2022-01-25T13:40:42.910Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1865.9000000000003,
    },
  },
  {
    _id: 'cKEs993qtwXnFgraY',
    createdAt: '2022-01-25T13:41:00.016Z',
    transactionType: 'transferIn',
    amount: 80,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2863,
    },
  },
  {
    _id: 'XQA8cR2dxxp5dxetZ',
    createdAt: '2022-01-25T13:41:25.946Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 399,
    },
  },
  {
    _id: 'nSuuu3RkpDgPvipC6',
    createdAt: '2022-01-25T13:42:04.543Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 4060,
    },
  },
  {
    _id: 'ibXJF2TqDZ6JxcTHB',
    createdAt: '2022-01-25T13:42:24.341Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 483,
    },
  },
  {
    _id: 'bc4kgdhZb7FgyuEgK',
    createdAt: '2022-01-25T13:42:49.156Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 113,
    },
  },
  {
    _id: 'd7grjdKA7SEdbZw3t',
    createdAt: '2022-01-25T13:43:13.792Z',
    transactionType: 'transferIn',
    amount: 10,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F234197e3-900e-4d94-a87b-fd9e6365d36b_200x200.jpg?alt=media&token=5c4615e1-dc1f-419a-8483-749e3eef8e0c',
        },
      },
      quantity: 12,
    },
  },
  {
    _id: '6zQQPvJ5vQrWEqEK2',
    createdAt: '2022-01-25T13:43:35.042Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'iASvRoJNWiSfQRNDL',
        name: 'BLEACH CONCENTRALE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2be3571f-f475-4e7a-ade3-41b15edd8a5f_200x200.jpg?alt=media&token=e887dc4b-c094-436e-8c63-4e1d57db4d35',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'WKgxDgkYDEmbSi42k',
    createdAt: '2022-01-25T13:44:31.549Z',
    transactionType: 'transferIn',
    amount: 10.8,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 81.8,
    },
  },
  {
    _id: 'FxMe3MrngLEQze5Jk',
    createdAt: '2022-01-25T13:45:00.048Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 64,
    },
  },
  {
    _id: 'FjSycWned7dfhGgNR',
    createdAt: '2022-01-26T05:22:59.265Z',
    transactionType: 'in',
    amount: 1565,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 1644.25,
    },
  },
  {
    _id: 'qKZi8GS73EAtaZSRW',
    createdAt: '2022-01-26T11:01:05.131Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 306,
    },
  },
  {
    _id: 'dDcCc72MoLvtkFJii',
    createdAt: '2022-01-26T11:01:35.738Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 495,
    },
  },
  {
    _id: '2PoMKtEPzeBP7R3Tx',
    createdAt: '2022-01-26T11:02:11.397Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 77788,
    },
  },
  {
    _id: '3Xp5n3KrpJbvgNvDx',
    createdAt: '2022-01-27T04:33:26.855Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 8796.644250000001,
    },
  },
  {
    _id: 'eAso96zCs8FHyTZqk',
    createdAt: '2022-01-27T07:38:34.056Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11796.644250000001,
    },
    transactionType: 'transferOut',
    amount: -1600,
  },
  {
    _id: 'k6269vWJJT52GCxSr',
    createdAt: '2022-01-27T07:38:34.088Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 3632.26,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R6MPuoh3qyfvojc6N',
    createdAt: '2022-01-27T07:38:34.109Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 3209.25,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AWpfdXk68QeCYwE5P',
    createdAt: '2022-01-27T07:38:34.130Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2040.9000000000003,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'zhXJTFBAGGGpXd3qZ',
    createdAt: '2022-01-27T07:38:34.149Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9105.6,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'zkP22qJonHbywqtGF',
    createdAt: '2022-01-27T07:38:34.179Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 127788,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'GjW7aiBH2FR4WyRa5',
    createdAt: '2022-01-27T07:38:34.206Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 849,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'pumAdTTTFDAeaSucy',
    createdAt: '2022-01-27T07:38:34.227Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6815.11,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'xGLuiYXeSkuGG3dKd',
    createdAt: '2022-01-27T07:38:34.245Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19159,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'uadYhWwTq9EM5Ptvq',
    createdAt: '2022-01-27T07:38:34.267Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19634,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'hvcfpHipD74kmzmif',
    createdAt: '2022-01-27T07:38:34.290Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3058,
    },
    transactionType: 'transferOut',
    amount: -106,
  },
  {
    _id: 'QmKxAWJhwYTZRqtMk',
    createdAt: '2022-01-27T08:10:47.079Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11796.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yXazAzgtMMDftXdHq',
    createdAt: '2022-01-27T08:10:47.102Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 3632.26,
    },
    transactionType: 'transferOut',
    amount: -1600,
  },
  {
    _id: 'X9bCxWWpELBgKZhHm',
    createdAt: '2022-01-27T08:10:47.118Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 3209.25,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Rw3xHETvqLbnB5nxN',
    createdAt: '2022-01-27T08:10:47.138Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2040.9000000000003,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'MwHzwHRxYGyS4XDdb',
    createdAt: '2022-01-27T08:10:47.169Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9105.6,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: '2mXKAjnwjetdg9hJf',
    createdAt: '2022-01-27T08:10:47.191Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 127788,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'zK9AiLWiHwZnYbhTT',
    createdAt: '2022-01-27T08:10:47.209Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 849,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'mY2iHGCCLg5ybX5ki',
    createdAt: '2022-01-27T08:10:47.229Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6815.11,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '7ZZKzwQFNbJD8r8ZJ',
    createdAt: '2022-01-27T08:10:47.250Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19159,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'hi56dm29phe4KL7bh',
    createdAt: '2022-01-27T08:10:47.266Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19634,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'sAxGZWD9sYQRZ5y3W',
    createdAt: '2022-01-27T08:10:47.283Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3058,
    },
    transactionType: 'transferOut',
    amount: -106,
  },
  {
    _id: 'Jm2vm86mNxFr4sWHo',
    createdAt: '2022-01-27T10:04:24.216Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 444,
    },
  },
  {
    _id: 'P8JPCHETTXfzRpaqo',
    createdAt: '2022-01-27T10:04:53.897Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2943,
    },
  },
  {
    _id: 'HiRX4oNoeCNa8cd7J',
    createdAt: '2022-01-27T10:05:22.348Z',
    transactionType: 'transferIn',
    amount: 625,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 6805.11,
    },
  },
  {
    _id: 'NAiSjKvmcqBjguY6Z',
    createdAt: '2022-01-27T10:06:09.511Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2529,
    },
  },
  {
    _id: 'hFFF8ByK7SubBTKjx',
    createdAt: '2022-01-27T10:07:12.202Z',
    transactionType: 'transferIn',
    amount: 164,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 273,
    },
  },
  {
    _id: 'CjwcELi3t2gdhiCGM',
    createdAt: '2022-01-27T10:07:57.604Z',
    transactionType: 'transferIn',
    amount: 520,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 1990.9000000000003,
    },
  },
  {
    _id: 'C9Ffri2SAGAyWvkLD',
    createdAt: '2022-01-27T10:49:44.435Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 462,
    },
  },
  {
    _id: 'MYRJbbThc56dcmoaL',
    createdAt: '2022-01-27T10:50:02.086Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 481,
    },
  },
  {
    _id: '46MNmhPRoREsNESME',
    createdAt: '2022-01-27T10:50:24.230Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 543,
    },
  },
  {
    _id: 'xLMsPihTX7t4oFHB8',
    createdAt: '2022-01-27T11:52:02.728Z',
    transactionType: 'in',
    amount: 1972.5,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 3209.25,
    },
  },
  {
    _id: 'Cn3BzYg6r4s74zHGS',
    createdAt: '2022-01-27T11:53:37.695Z',
    transactionType: 'in',
    amount: 2133.4,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 2032.2600000000002,
    },
  },
  {
    _id: 'qWxH955rxwLmR9zbo',
    createdAt: '2022-01-27T12:57:35.498Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2510.9000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Hf2zdx5p7GpForEHb',
    createdAt: '2022-01-27T12:57:35.517Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11796.644250000001,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'jc8ruDy2PFwu4MX2c',
    createdAt: '2022-01-27T12:57:35.539Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 9015.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'rXjLZEbT9jR3rEteE',
    createdAt: '2022-01-27T12:57:35.561Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19099,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ypsTv9S7QSPPPhTZE',
    createdAt: '2022-01-27T12:57:35.577Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 52,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'YFm5u2umY7u36zhcG',
    createdAt: '2022-01-27T12:57:35.596Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'Roll',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21863,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fLAazX4EmakNphi7B',
    createdAt: '2022-01-28T07:24:15.373Z',
    transactionType: 'in',
    amount: 3500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 11196.644250000001,
    },
  },
  {
    _id: 'ZccMLn2WfNnGPCwdu',
    createdAt: '2022-01-28T10:37:06.970Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 4165.66,
    },
  },
  {
    _id: 'Lr9eRNg5fZqWZAkgH',
    createdAt: '2022-01-28T11:12:37.809Z',
    transactionType: 'in',
    amount: 2133.4,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 5165.66,
    },
  },
  {
    _id: '2E5sX4AHnTYfeTq5D',
    createdAt: '2022-01-28T11:13:17.172Z',
    transactionType: 'in',
    amount: 1972.5,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 5181.75,
    },
  },
  {
    _id: 'Fxzkf8rd5ZDHkaGN3',
    createdAt: '2022-01-28T11:53:36.136Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 4700,
    },
  },
  {
    _id: 'A5FaKMHarJt9BnGS7',
    createdAt: '2022-01-28T13:29:56.109Z',
    transactionType: 'in',
    amount: 1421.29,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 6299.0599999999995,
    },
  },
  {
    _id: 'nnFxDgYXCRo2uFebS',
    createdAt: '2022-01-28T13:37:56.926Z',
    transactionType: 'in',
    amount: 800,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 7720.349999999999,
    },
  },
  {
    _id: 'E76Fri2PudDRupgaF',
    createdAt: '2022-01-28T13:38:35.827Z',
    transactionType: 'in',
    amount: 876.25,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 7154.25,
    },
  },
  {
    _id: 'NWFuYYD6uNysuqyB8',
    createdAt: '2022-01-28T13:41:52.792Z',
    transactionType: 'in',
    amount: 2486.95,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 8520.349999999999,
    },
  },
  {
    _id: 'CbbNempTZS8bu6Ryj',
    createdAt: '2022-01-28T13:47:34.063Z',
    transactionType: 'in',
    amount: 2486.95,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 11007.3,
    },
  },
  {
    _id: 'XKq6Skezi2WCMo8We',
    createdAt: '2022-01-29T04:41:49.839Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 14696.644250000001,
    },
  },
  {
    _id: 'KvkTWsHp5vZBgmRNG',
    createdAt: '2022-01-29T04:51:12.695Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 90.5,
    },
  },
  {
    _id: 'bkdcXdN9Zp9xB5w4t',
    createdAt: '2022-01-29T06:33:50.148Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 13494.25,
    },
    transactionType: 'transferOut',
    amount: -6373,
  },
  {
    _id: 'K3z2SMma4FEqTp9g7',
    createdAt: '2022-01-29T06:33:50.167Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2510.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -186,
  },
  {
    _id: 'SvgoghXyyjLFeN59L',
    createdAt: '2022-01-29T06:33:50.199Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8975.6,
    },
    transactionType: 'transferOut',
    amount: -488,
  },
  {
    _id: 'QRs8iivCkppBqPZdE',
    createdAt: '2022-01-29T06:33:50.228Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 126988,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'Y3hE3Qa8YHmt6DoTg',
    createdAt: '2022-01-29T06:33:50.249Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7430.11,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '8zdryhDxvZ5Zs9voa',
    createdAt: '2022-01-29T06:33:50.268Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3103,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'K6YAvoJqjkZoGB9Xy',
    createdAt: '2022-01-29T06:58:00.608Z',
    transactionType: 'in',
    amount: 1412,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 8030.5,
    },
  },
  {
    _id: 'q8s34dQbpkEAQf8uA',
    createdAt: '2022-01-29T07:08:03.930Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 522,
    },
  },
  {
    _id: 'rAmkzGqgnHexbDxFL',
    createdAt: '2022-01-29T07:08:54.322Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 303,
    },
  },
  {
    _id: 'TJFR2eKB5oHe2guNq',
    createdAt: '2022-01-29T07:29:00.996Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 6080,
    },
  },
  {
    _id: 'DPpCut28qFRcw5BgC',
    createdAt: '2022-01-29T07:29:39.343Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 800,
    },
  },
  {
    _id: 'wQQtg5MSYQc74FFT9',
    createdAt: '2022-01-29T11:28:15.584Z',
    transactionType: 'transferIn',
    amount: 16200,
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 10130,
    },
  },
  {
    _id: '6bvsaH98P4qqBWd9a',
    createdAt: '2022-01-29T11:28:36.418Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7030.11,
    },
  },
  {
    _id: 'EuJR4ng3TT95td6AN',
    createdAt: '2022-01-29T11:28:59.941Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 612,
    },
  },
  {
    _id: 'pmm2K4ByB2Q5npSqN',
    createdAt: '2022-01-29T11:29:20.404Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2952,
    },
  },
  {
    _id: 'qCSCgjXxpp68gLXpn',
    createdAt: '2022-01-29T11:29:38.619Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 489,
    },
  },
  {
    _id: '5FxJnXbYwFnGq6JKq',
    createdAt: '2022-01-29T11:30:31.017Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2629,
    },
  },
  {
    _id: 'Z8WHxkZ3kctJXRTg4',
    createdAt: '2022-01-29T11:31:02.926Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2324.9000000000005,
    },
  },
  {
    _id: 'qF9XXEmFPvan7GJr4',
    createdAt: '2022-01-29T11:31:29.210Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 133,
    },
  },
  {
    _id: 'sTEfTePCNPXFLWa2w',
    createdAt: '2022-01-29T11:32:09.636Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 2921,
    },
  },
  {
    _id: 'dGjskbBmdizxPLEpc',
    createdAt: '2022-01-29T11:56:36.082Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 331,
    },
  },
  {
    _id: 'Rqmaok4nous7mp3s9',
    createdAt: '2022-01-29T12:20:19.789Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 17696.64425,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'ntpBad8ETw5KSfveb',
    createdAt: '2022-01-29T12:20:19.811Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 7121.25,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Z743JqvWTummRcck3',
    createdAt: '2022-01-29T12:20:19.833Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 9442.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xAzqBdYJgjfJ9beCH',
    createdAt: '2022-01-29T12:20:19.853Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2399.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hf6Lc8rWLH8jWyGjA',
    createdAt: '2022-01-29T12:20:19.873Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8487.6,
    },
    transactionType: 'transferOut',
    amount: -6.5,
  },
  {
    _id: '5n4PSzbZwprYK6Pac',
    createdAt: '2022-01-29T12:20:19.912Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 122988,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'v9ewSLdq6tkLNwQE7',
    createdAt: '2022-01-29T12:20:19.949Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cDWyWix2rNJkgzMua',
    createdAt: '2022-01-29T12:20:19.971Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7530.11,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MuNZT6PPvJTmRjDXv',
    createdAt: '2022-01-29T12:20:19.990Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rpJ3SRdejimWMhe7h',
    createdAt: '2022-01-29T12:20:20.010Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19624,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i4RFAFtBjKMnGzrWA',
    createdAt: '2022-01-29T12:20:20.035Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3052,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'cT6MNeFq3hutS7cFd',
    createdAt: '2022-01-29T12:42:43.832Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 9442.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BtGMhv46fBo2ypHyL',
    createdAt: '2022-01-29T12:42:43.854Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 7121.25,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'Z9RF5etYYgGJMnmfQ',
    createdAt: '2022-01-29T12:42:43.874Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16796.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kTZ6nfobYqooG2FJS',
    createdAt: '2022-01-29T12:42:43.895Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2398.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vrHeNJ9mgMmRhsQZr',
    createdAt: '2022-01-29T12:42:43.914Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8481.1,
    },
    transactionType: 'transferOut',
    amount: -5.5,
  },
  {
    _id: 'MgTX52TJfhYqLmoH8',
    createdAt: '2022-01-29T12:42:43.933Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 122488,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'ofHJHHgZiWFTthDPm',
    createdAt: '2022-01-29T12:42:43.952Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oNoGqtDPkFFnGP3gJ',
    createdAt: '2022-01-29T12:42:43.973Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vWCTR6zCp3aBJiuJs',
    createdAt: '2022-01-29T12:42:43.992Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7530.11,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'K7WvZy9Ct7FMvCQuD',
    createdAt: '2022-01-29T12:42:44.013Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2986,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'cER55yEbbbYYRnxrr',
    createdAt: '2022-01-29T13:19:40.656Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 6321.25,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NQA7C3Z7QvvEcvpvg',
    createdAt: '2022-01-29T13:19:40.674Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 9442.5,
    },
    transactionType: 'transferOut',
    amount: -6000,
  },
  {
    _id: '53QPCo7Ma4edjCjHC',
    createdAt: '2022-01-29T13:19:40.691Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 122088,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: '9T6aMnQczeJbJ7sgw',
    createdAt: '2022-01-29T13:19:40.710Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8475.6,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'Gxy6xDm42W7kQChv2',
    createdAt: '2022-01-29T13:44:53.793Z',
    transactionType: 'in',
    amount: 1543.22,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 6321.25,
    },
  },
  {
    _id: 'yaaw6jxbJS9wjZ7ZQ',
    createdAt: '2022-01-31T04:18:32.837Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16796.64425,
    },
  },
  {
    _id: 'eYv7QeLcBn9HqwW7d',
    createdAt: '2022-01-31T04:48:58.213Z',
    transactionType: 'in',
    amount: 267,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 7864.47,
    },
  },
  {
    _id: 'GjBX8XkDxBDhxnq4G',
    createdAt: '2022-01-31T04:49:39.822Z',
    transactionType: 'in',
    amount: 2673,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 7864.47,
    },
  },
  {
    _id: 'RPQ8owkzPuY36xDAu',
    createdAt: '2022-01-31T08:53:41.468Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 688,
    },
  },
  {
    _id: 'Rt4QQYtypfhSjDCrr',
    createdAt: '2022-01-31T10:10:33.572Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3081,
    },
  },
  {
    _id: 'eWGuEXSXsPyKafY3W',
    createdAt: '2022-01-31T10:11:07.763Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2961,
    },
  },
  {
    _id: 'HFaGxnqA9EhreEpCT',
    createdAt: '2022-01-31T10:11:47.699Z',
    transactionType: 'transferIn',
    amount: 65,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 320,
    },
  },
  {
    _id: 'GsHTdJPAmPNEfZEjH',
    createdAt: '2022-01-31T12:11:58.982Z',
    transactionType: 'transferIn',
    amount: 960,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 5340,
    },
  },
  {
    _id: 'zfCeENzChX59aqL3T',
    createdAt: '2022-01-31T12:43:33.178Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19796.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: '5KKs2FZjWFc7wfjXw',
    createdAt: '2022-01-31T12:43:33.198Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8115.6,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'myBycotoisStdkAsj',
    createdAt: '2022-01-31T12:43:33.221Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 119088,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GbEK4CEEHMRx7amcx',
    createdAt: '2022-01-31T12:43:33.239Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7530.11,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9oEeoCiMwmnpKeBgu',
    createdAt: '2022-01-31T12:43:33.257Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3361,
    },
    transactionType: 'transferOut',
    amount: -118,
  },
  {
    _id: 'xT6Fm8hJzXmTCos4M',
    createdAt: '2022-01-31T14:07:46.447Z',
    transactionType: 'in',
    amount: 2336,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 3442.5,
    },
  },
  {
    _id: 'mHDLmDQseR8E239cr',
    createdAt: '2022-02-01T04:46:36.465Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 17796.64425,
    },
  },
  {
    _id: 'hrjJcYEZLTF3bds47',
    createdAt: '2022-02-01T05:32:47.058Z',
    transactionType: 'in',
    amount: 2977,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 10537.470000000001,
    },
  },
  {
    _id: 'WXdJgpnNSqhmm6hKe',
    createdAt: '2022-02-01T05:49:37.129Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 356,
    },
  },
  {
    _id: '6khtbjRYNrpB2GHrx',
    createdAt: '2022-02-01T05:50:08.988Z',
    transactionType: 'transferIn',
    amount: 225,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2397.9000000000005,
    },
  },
  {
    _id: 'KYEh6w3SBNj58JrYk',
    createdAt: '2022-02-01T05:50:31.375Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7530.11,
    },
  },
  {
    _id: 'wHfgA8gqRa8RcJGuD',
    createdAt: '2022-02-01T05:51:06.308Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 564,
    },
  },
  {
    _id: 'raWNTe9T9S5rwSmSS',
    createdAt: '2022-02-01T05:51:42.199Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 6,
    },
  },
  {
    _id: 'vW7pWdArk8gZf9bvw',
    createdAt: '2022-02-01T05:52:18.290Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd1a60168-f2d6-4d88-8e85-4d7ce9852a88_200x200.jpg?alt=media&token=8a719d2c-56aa-4bfc-ba45-aa26fb4989c3',
        },
      },
      quantity: 2,
    },
  },
  {
    _id: 'fsoGfwPdfjmTcHTnC',
    createdAt: '2022-02-01T05:58:38.922Z',
    transactionType: 'transferIn',
    amount: 4,
    storeItem: {
      type: {
        _id: 'xyX3JCjJxkmWbo7EN',
        name: 'GLOVES NITRILE POWDER FREE  - MEDIUM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F35943061-6c89-46e3-b34f-831b47d31ebf_200x200.jpg?alt=media&token=f24bc827-d133-42d3-a329-6d9cfca96721',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'prni8LPCRyi8RXtKb',
    createdAt: '2022-02-01T05:59:38.212Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2ed48cd8-15b1-4e21-ac18-321bba094c67_200x200.jpg?alt=media&token=e0e7419c-f486-4edd-b27b-508e7106435f',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'LyJw8aw9qna9R37LM',
    createdAt: '2022-02-01T08:20:20.533Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20796.64425,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'TbFq2hbvSM3YJaMNN',
    createdAt: '2022-02-01T08:20:20.558Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 5778.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LS8MTBYWwupCbvsY8',
    createdAt: '2022-02-01T08:20:20.576Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2622.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'fJxWrRr76EH3vvEYh',
    createdAt: '2022-02-01T08:20:20.608Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8103.6,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'a4wt3R3zDsFPa2onx',
    createdAt: '2022-02-01T08:20:20.634Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 119087,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '2XTJ5coJEfY2EKWie',
    createdAt: '2022-02-01T08:20:20.655Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'juKc8pexzaDKRZKL3',
    createdAt: '2022-02-01T08:20:20.671Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xr4fm4ZkuxDcRAf3P',
    createdAt: '2022-02-01T08:20:20.688Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3161,
    },
    transactionType: 'transferOut',
    amount: -71,
  },
  {
    _id: 'KiWXJq8Y96Ysw2wZb',
    createdAt: '2022-02-01T08:34:45.883Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19896.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'M8mzp7dvkWGpusG8R',
    createdAt: '2022-02-01T08:34:45.903Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 13514.470000000001,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'hZzX92gyatsFLBXiq',
    createdAt: '2022-02-01T08:34:45.922Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 5778.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rDy4SAjLoFoowyGns',
    createdAt: '2022-02-01T08:34:45.948Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2597.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'WXwsxPN8hfqXzbDBE',
    createdAt: '2022-02-01T08:34:45.971Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8097.6,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'ydWG8nXSbuG8R6wWG',
    createdAt: '2022-02-01T08:34:45.988Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118587,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'vRoBF52j8rMgZF22d',
    createdAt: '2022-02-01T08:34:46.008Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TzcrdAt3YdKqqZoo9',
    createdAt: '2022-02-01T08:34:46.034Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3HAA8ZyMaHw3Nn4oD',
    createdAt: '2022-02-01T08:34:46.052Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8030.11,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'ht8sLjXMWrJJ99TRk',
    createdAt: '2022-02-01T08:34:46.074Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3090,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'q7TRboJfMHKmJ8YHo',
    createdAt: '2022-02-01T10:00:34.861Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 603,
    },
  },
  {
    _id: 'racqKiXKKvoRa2afZ',
    createdAt: '2022-02-01T13:27:20.555Z',
    transactionType: 'in',
    amount: 1844,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 12514.470000000001,
    },
  },
  {
    _id: 'jFY3zswGXi2bKSzSP',
    createdAt: '2022-02-02T05:03:19.128Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19896.64425,
    },
  },
  {
    _id: 'e97dHK9WLSMWs9t72',
    createdAt: '2022-02-02T05:16:09.657Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22896.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'Dudx6aNehp3CmCahs',
    createdAt: '2022-02-02T05:16:09.693Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2572.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'X3PojPGu9uxQ3QH8T',
    createdAt: '2022-02-02T05:16:09.712Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8027.6,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'GmWBgHmoKySK9vLCG',
    createdAt: '2022-02-02T05:16:09.731Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HEPzK28drJ2rnLDez',
    createdAt: '2022-02-02T05:16:09.756Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3020,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'H6EPm54eTDBayshps',
    createdAt: '2022-02-02T05:23:12.048Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8023.6,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'r85gEjxiLeZKnJK7v',
    createdAt: '2022-02-02T05:23:12.066Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2548.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '8iRQjR6EmR3PJXqLX',
    createdAt: '2022-02-02T05:23:12.092Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22296.64425,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'w8J5wdM7C6CzFtStr',
    createdAt: '2022-02-02T05:23:12.114Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sNcWcEPfna4zCsjcZ',
    createdAt: '2022-02-02T05:23:12.133Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2974,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'LiXBnCnQ9XCQG6bPv',
    createdAt: '2022-02-02T10:39:21.023Z',
    transactionType: 'in',
    amount: 2718,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 14358.470000000001,
    },
  },
  {
    _id: 'WcnhJKsNdbDgbS97Y',
    createdAt: '2022-02-02T10:49:31.195Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21896.64425,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'KN2S78X2dXJTt5FHs',
    createdAt: '2022-02-02T10:49:31.218Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2536.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Bf99af2svvL73JNNv',
    createdAt: '2022-02-02T10:49:31.242Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118087,
    },
    transactionType: 'transferOut',
    amount: -2.3,
  },
  {
    _id: 'eHEGf9dewttGXrjFR',
    createdAt: '2022-02-02T10:49:31.261Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8000.11,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'u6pumrdLSWKXhuoHK',
    createdAt: '2022-02-02T10:49:31.280Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 656,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'sTumnC7KbZ4NTG6Hv',
    createdAt: '2022-02-02T10:49:31.300Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 703,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zW47RQDxBJdCuAFvW',
    createdAt: '2022-02-02T10:49:31.324Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2942,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'kGm3tKwJ7ox5m77GC',
    createdAt: '2022-02-02T11:48:11.700Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 84,
    },
  },
  {
    _id: '4YZ9uwmkwbeXBxSaY',
    createdAt: '2022-02-02T11:49:25.434Z',
    transactionType: 'transferIn',
    amount: 21.6,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 92.6,
    },
  },
  {
    _id: 'xxHZWZKxJ9vPPotEi',
    createdAt: '2022-02-02T11:49:42.238Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 2875,
    },
  },
  {
    _id: 'xugm2W79E3w8w29pr',
    createdAt: '2022-02-02T11:52:13.098Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3243,
    },
  },
  {
    _id: 'PdLQNYXQWAMTQ8SHt',
    createdAt: '2022-02-02T11:57:23.992Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21596.64425,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'K3tKSNRRGzdKoRmaj',
    createdAt: '2022-02-02T11:57:24.011Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2486.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '5NxPvDM2qy2srM4tZ',
    createdAt: '2022-02-02T11:57:24.027Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118084.7,
    },
    transactionType: 'transferOut',
    amount: -2.3,
  },
  {
    _id: 'DKmh7d7s2DDuKCykm',
    createdAt: '2022-02-02T11:57:24.044Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7997.11,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'K3C5XLbfTngtFPdzo',
    createdAt: '2022-02-02T11:57:24.061Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 641,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Aw5B8QX5WWmWKqDZY',
    createdAt: '2022-02-02T11:57:24.083Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 703,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9STSmbX8thraAwZ8k',
    createdAt: '2022-02-02T11:57:24.099Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3075,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'udBbhvtFoDReJD3xf',
    createdAt: '2022-02-02T12:42:49.383Z',
    transactionType: 'in',
    amount: 1730.26,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 17076.47,
    },
  },
  {
    _id: 'P8E9q5rQSy2QNgbPc',
    createdAt: '2022-02-03T04:34:37.740Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 210,
    },
  },
  {
    _id: 'sbkSDtvogYuMJyc48',
    createdAt: '2022-02-03T05:18:40.726Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3403,
    },
  },
  {
    _id: 'o6XJ64QGAdM9JDjja',
    createdAt: '2022-02-03T05:19:05.794Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3002,
    },
  },
  {
    _id: 'bR9ZNrKzz6mtP3mw9',
    createdAt: '2022-02-03T05:20:24.958Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7994.11,
    },
  },
  {
    _id: 'Q4wuoT3H3ZEqyqgJx',
    createdAt: '2022-02-03T05:21:02.431Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2436.9000000000005,
    },
  },
  {
    _id: 'fmorWZ4byp775Fn3L',
    createdAt: '2022-02-03T05:26:39.334Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8019.6,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'BZL26s6Rodi3ZrMJ3',
    createdAt: '2022-02-03T05:26:39.352Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2611.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'KaRyLuinFT6SQjAHN',
    createdAt: '2022-02-03T05:26:39.369Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21296.64425,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '4TKkfeX4438Yscowf',
    createdAt: '2022-02-03T05:26:39.395Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gFGseDrTfdoTyMnXc',
    createdAt: '2022-02-03T05:26:39.409Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3202,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'y6dF6WNXdzQRp4owg',
    createdAt: '2022-02-03T05:32:08.311Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21096.64425,
    },
  },
  {
    _id: 'd4zEyYDRJGfEctwsp',
    createdAt: '2022-02-03T05:32:39.583Z',
    transactionType: 'in',
    amount: 3203.4,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 18806.73,
    },
  },
  {
    _id: 'kjq7x3ko6QC4fQeY5',
    createdAt: '2022-02-03T05:33:03.234Z',
    transactionType: 'in',
    amount: 150,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1652,
    },
  },
  {
    _id: 'w7poCqJT5adcYRyy3',
    createdAt: '2022-02-03T12:14:23.985Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24096.64425,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'dPFu7XdpsuP4L7b7R',
    createdAt: '2022-02-03T12:14:24.007Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 22010.13,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MR9pqGkuZ8vvTCFDS',
    createdAt: '2022-02-03T12:14:24.026Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 5778.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n2AisrWCCeHAhLcC6',
    createdAt: '2022-02-03T12:14:24.045Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2603.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'eB9DYPbrd6F9BBohW',
    createdAt: '2022-02-03T12:14:24.066Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8018.1,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'TfQE7NCZpQSMY45YT',
    createdAt: '2022-02-03T12:14:24.093Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118082.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'rACkNTcAbMeFwmLhN',
    createdAt: '2022-02-03T12:14:24.129Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zE9Bdnw4NQzmPSRe4',
    createdAt: '2022-02-03T12:14:24.146Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TgXGMAwQdEhh4GmJ7',
    createdAt: '2022-02-03T12:14:24.161Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8369.11,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gRYQKYkcrAWCi3Q8s',
    createdAt: '2022-02-03T12:14:24.178Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3188,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'YQ6YCsek3XTL9XrL2',
    createdAt: '2022-02-04T04:49:01.066Z',
    transactionType: 'in',
    amount: 1707,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 5778.5,
    },
  },
  {
    _id: 'zveMWkbfuumJ7Z6mk',
    createdAt: '2022-02-04T04:50:12.432Z',
    transactionType: 'in',
    amount: 3182.7,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 22010.13,
    },
  },
  {
    _id: 'CgZWJpui3QRibTfGN',
    createdAt: '2022-02-04T05:20:22.241Z',
    transactionType: 'in',
    amount: 1500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23296.64425,
    },
  },
  {
    _id: 'BRt8zpfuEhic2jNhG',
    createdAt: '2022-02-04T07:07:37.301Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 995,
    },
  },
  {
    _id: 'WCzqepRectceN5fCw',
    createdAt: '2022-02-04T07:08:03.366Z',
    transactionType: 'transferIn',
    amount: 150,
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 965,
    },
  },
  {
    _id: 'cxM4uBRCSuc4o5Pss',
    createdAt: '2022-02-04T07:08:27.905Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 687,
    },
  },
  {
    _id: 'y4zhsDtcqiHqnZmLk',
    createdAt: '2022-02-04T07:08:46.389Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 268,
    },
  },
  {
    _id: 'W3eAhSR7WcrsMNHdt',
    createdAt: '2022-02-04T07:09:06.431Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 381,
    },
  },
  {
    _id: 'n2gKsFfunv8z2aht2',
    createdAt: '2022-02-04T12:00:29.177Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2578.9000000000005,
    },
  },
  {
    _id: 'hxdMdqhc9bcdi6gmT',
    createdAt: '2022-02-04T12:01:04.401Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 117682.4,
    },
  },
  {
    _id: 'uSHMfM8vT2ckYAJPh',
    createdAt: '2022-02-04T12:01:40.449Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'JOHA PZ7 PHOSPHATE 21069099',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe4c48624-b0d4-4aa7-bc1a-204911b25bb7_200x200.jpg?alt=media&token=0d63e8b2-fdb6-48ca-bfeb-9f6ce210fbe7',
        },
      },
      quantity: 15,
    },
  },
  {
    _id: 'se2TLDWvNTT2CWM6L',
    createdAt: '2022-02-04T12:02:06.553Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 703,
    },
  },
  {
    _id: 'vnnfi6BKC5tLs2K8S',
    createdAt: '2022-02-04T12:29:14.562Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3603,
    },
  },
  {
    _id: '56NQ7ZARdahyfNKYe',
    createdAt: '2022-02-04T12:29:44.676Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3118,
    },
  },
  {
    _id: 'sRg5AYzqDJkLMjdzf',
    createdAt: '2022-02-04T12:30:11.427Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 717,
    },
  },
  {
    _id: 'F9wXgSZdEvRw6arzt',
    createdAt: '2022-02-05T04:32:38.019Z',
    transactionType: 'in',
    amount: 400,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24796.64425,
    },
  },
  {
    _id: 'YxQEJbS567p5KHH79',
    createdAt: '2022-02-05T04:33:28.134Z',
    transactionType: 'in',
    amount: 250,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1802,
    },
  },
  {
    _id: 'DsjXwckWmKQYohFD5',
    createdAt: '2022-02-05T07:28:06.693Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 25192.83,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'TPmsvahm9MgucheWG',
    createdAt: '2022-02-05T07:28:06.714Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 7485.5,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'oyxBpkgRvRj6BWppK',
    createdAt: '2022-02-05T07:28:06.736Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167682.4,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '6b5N2MGip2rssf4gB',
    createdAt: '2022-02-05T07:28:06.752Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8013.1,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'BtD6XPFwwpcDzGZLn',
    createdAt: '2022-02-05T07:35:06.109Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 25189.83,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'PABpw2NtwJACxL9St',
    createdAt: '2022-02-05T07:35:06.132Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 7482.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'AZZLgMR3q8YjdRCCv',
    createdAt: '2022-02-05T07:35:06.154Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167679.4,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'KJnJStCg94KKWexHy',
    createdAt: '2022-02-05T07:35:06.171Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8010.1,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'YeSKfsrnbFpSE87K2',
    createdAt: '2022-02-05T08:38:37.868Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25196.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dkvKKnTb29e85pG9e',
    createdAt: '2022-02-05T08:38:37.887Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 25188.83,
    },
    transactionType: 'transferOut',
    amount: -1680,
  },
  {
    _id: 'fLPD8zxCrk4mHayyR',
    createdAt: '2022-02-05T08:38:37.907Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2753.9000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'L9rAo2YpQjgL9Nbad',
    createdAt: '2022-02-05T08:38:37.927Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: 'YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
      },
      quantity: 38,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'E4F7HdF25vfRJmejB',
    createdAt: '2022-02-05T08:38:37.943Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8369.11,
    },
    transactionType: 'transferOut',
    amount: -2.4,
  },
  {
    _id: 'np4BrRWMWKpJmfqu6',
    createdAt: '2022-02-05T08:38:37.961Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19624,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Z6v2fPxxcsmTjRgms',
    createdAt: '2022-02-05T08:38:37.980Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: 'CH-1',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 56,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jyZCuXkzSxujmzYor',
    createdAt: '2022-02-05T08:38:37.996Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3763,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'L44cryLzTpcBPWPio',
    createdAt: '2022-02-05T08:38:38.063Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL',
        unit: 'KG',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 240,
  },
  {
    _id: '95akM5zQRohNdnx26',
    createdAt: '2022-02-05T08:40:05.958Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25196.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7YKT6DdxeCvGFcwfh',
    createdAt: '2022-02-05T08:40:05.979Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 23508.83,
    },
    transactionType: 'transferOut',
    amount: -1750,
  },
  {
    _id: 'patJYm5ThuQS78jwb',
    createdAt: '2022-02-05T08:40:05.997Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2753.9000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RazJNgXCL8TKQToa8',
    createdAt: '2022-02-05T08:40:06.014Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: 'YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
      },
      quantity: 36,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'cHiLfKFzCK3rGcMTk',
    createdAt: '2022-02-05T08:40:06.034Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8366.710000000001,
    },
    transactionType: 'transferOut',
    amount: -2.4,
  },
  {
    _id: 'nZKjeRJWiwLEvbA9i',
    createdAt: '2022-02-05T08:40:06.058Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19612,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'mFjRkARmAwW2s9ajr',
    createdAt: '2022-02-05T08:40:06.094Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: 'CH-1',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 56,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'A8iiKMfE6ySfvfZKv',
    createdAt: '2022-02-05T08:40:06.115Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3739,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'F4GZmvGfpAe6Er888',
    createdAt: '2022-02-05T08:40:06.165Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL',
        unit: 'KG',
      },
      quantity: 240,
    },
    transactionType: 'in',
    amount: 250,
  },
  {
    _id: 'AnPWJ9xHewRonGGQ8',
    createdAt: '2022-02-05T08:43:13.297Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25196.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fQg2oP9P78ri4DjPr',
    createdAt: '2022-02-05T08:43:13.317Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 21758.83,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: '2SRNeXCtZWmnGZ3jK',
    createdAt: '2022-02-05T08:43:13.334Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 7481.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TQQmSqusTnB9AYpKq',
    createdAt: '2022-02-05T08:43:13.350Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2753.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'Nh3jA2WEQCtmR2ozk',
    createdAt: '2022-02-05T08:43:13.366Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 8009.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'oXpWCHBPdnB7ZSqDZ',
    createdAt: '2022-02-05T08:43:13.383Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167678.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'RN6PaLMgWD2TAFLP7',
    createdAt: '2022-02-05T08:43:13.400Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uvjaM7JdRhDpyszJD',
    createdAt: '2022-02-05T08:43:13.420Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g6wNgaFj9YEhEiBjA',
    createdAt: '2022-02-05T08:43:13.436Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8364.310000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wpN7kigGC6DjCx9sp',
    createdAt: '2022-02-05T08:43:13.458Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3218,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'esdq2gC2pSiZEykLr',
    createdAt: '2022-02-05T11:04:11.446Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 762,
    },
  },
  {
    _id: 'ArWRtp6iGvAuqW2vE',
    createdAt: '2022-02-05T11:04:30.633Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3148,
    },
  },
  {
    _id: 'xTMitWBDFHdoXwtrp',
    createdAt: '2022-02-05T11:05:05.716Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3714,
    },
  },
  {
    _id: 'o2JmsqJtmxeWajFQg',
    createdAt: '2022-02-05T11:05:31.442Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 406,
    },
  },
  {
    _id: 'tHRDSFdyYpePrQZw4',
    createdAt: '2022-02-05T12:18:07.056Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25196.64425,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'tY7qmbMeQxf9uuaaY',
    createdAt: '2022-02-05T12:18:07.080Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
      },
      quantity: 20858.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7CgbpLecvtGW7vatM',
    createdAt: '2022-02-05T12:18:07.105Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 7481.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MAobxJ8E5H8XtPynA',
    createdAt: '2022-02-05T12:18:07.133Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2728.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'quJ3Kzh3FBnX7fhmX',
    createdAt: '2022-02-05T12:18:07.151Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7955.1,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'LQBMxynihCmE6L8Ku',
    createdAt: '2022-02-05T12:18:07.167Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167178.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'DNpJsCXZdCWeRzfEk',
    createdAt: '2022-02-05T12:18:07.186Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KeEym3WQTEBMaow6i',
    createdAt: '2022-02-05T12:18:07.210Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MYuJ7nzWkNMsAdmfH',
    createdAt: '2022-02-05T12:18:07.227Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8364.310000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'crt8x7oPyNSyGhKWp',
    createdAt: '2022-02-05T12:18:07.244Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3348,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: '76jTHt5nPu6JoHoTe',
    createdAt: '2022-02-05T12:20:27.720Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24296.64425,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'wpCxypaYrEht5Dqyq',
    createdAt: '2022-02-05T12:20:27.739Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
      },
      quantity: 7481.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HDzKffiB9hqfJdDRq',
    createdAt: '2022-02-05T12:20:27.757Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2727.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '9cSSnAYXqRCtajZ2Q',
    createdAt: '2022-02-05T12:20:27.773Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 110.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9PpxRfeKrxQ3NEzKL',
    createdAt: '2022-02-05T12:20:27.790Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19597,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'QnnwynngWF5ij9atz',
    createdAt: '2022-02-05T12:20:27.806Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2729,
    },
    transactionType: 'transferOut',
    amount: -104,
  },
  {
    _id: 'Grboh4rHg8pfNCp7x',
    createdAt: '2022-02-05T14:15:28.101Z',
    transactionType: 'in',
    amount: 2432,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F09ef82bf-73e9-405d-a5ee-352cd0a6f32f_200x200.jpg?alt=media&token=5a0872fb-2391-4de3-94ce-a8c97b16358c',
        },
      },
      quantity: 20858.83,
    },
  },
  {
    _id: 'eMSbS4KNj88icx2Q2',
    createdAt: '2022-02-05T14:16:33.207Z',
    transactionType: 'in',
    amount: 3270.75,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F09ef82bf-73e9-405d-a5ee-352cd0a6f32f_200x200.jpg?alt=media&token=5a0872fb-2391-4de3-94ce-a8c97b16358c',
        },
      },
      quantity: 23290.83,
    },
  },
  {
    _id: 'Q5nAAqv923LuoqCac',
    createdAt: '2022-02-05T14:17:08.602Z',
    transactionType: 'in',
    amount: 2231,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7481.5,
    },
  },
  {
    _id: 'y56G6pL2quGbMQ85B',
    createdAt: '2022-02-07T04:44:19.439Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23496.64425,
    },
  },
  {
    _id: '5AXxui2WkHjmRt6H2',
    createdAt: '2022-02-07T04:45:45.332Z',
    transactionType: 'in',
    amount: 500,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 2052,
    },
  },
  {
    _id: 'RWWGwCXReD74NPWff',
    createdAt: '2022-02-07T08:09:50.117Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8364.310000000001,
    },
  },
  {
    _id: 'dmoDqGRmR9CG9ocNJ',
    createdAt: '2022-02-07T10:17:55.591Z',
    transactionType: 'in',
    amount: 3577.8,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 26565.58,
    },
  },
  {
    _id: 'A93BhoyWZmmgiuYjw',
    createdAt: '2022-02-07T12:31:25.931Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 0,
    },
  },
  {
    _id: 'dQArrY5D8SMaJFnHy',
    createdAt: '2022-02-07T13:32:51.100Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 807,
    },
  },
  {
    _id: 'Jpg62a5W3HGQ9tYrq',
    createdAt: '2022-02-07T13:33:17.291Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 624,
    },
  },
  {
    _id: 'cXM73F8RhYqn3yWKQ',
    createdAt: '2022-02-07T13:34:10.310Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 51,
    },
  },
  {
    _id: 'tvTHPQZ9TQA5nCFoR',
    createdAt: '2022-02-07T13:34:44.677Z',
    transactionType: 'transferIn',
    amount: 550,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8389.310000000001,
    },
  },
  {
    _id: 'CG9ks63oqwonseZpL',
    createdAt: '2022-02-07T13:35:16.701Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3317,
    },
  },
  {
    _id: 'z2LzjCXypGR5TQetu',
    createdAt: '2022-02-07T13:36:02.857Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2625,
    },
  },
  {
    _id: 'mYQYe6G3YLtbrXt8x',
    createdAt: '2022-02-07T13:37:04.337Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2726.9000000000005,
    },
  },
  {
    _id: 'ocJZp56yGTsWP7jAD',
    createdAt: '2022-02-07T13:37:44.064Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 230,
    },
  },
  {
    _id: '7L8WooEeBKJeeWAMZ',
    createdAt: '2022-02-07T13:38:34.599Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 6300,
    },
  },
  {
    _id: '2BYC3mefwErNwXTRf',
    createdAt: '2022-02-07T21:20:10.110Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 9716.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BKtW7LynqiT4M49wb',
    createdAt: '2022-02-07T21:20:10.146Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 30143.38,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Exv7AFiWwR8GbPDZQ',
    createdAt: '2022-02-07T21:20:10.173Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 26496.64425,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'y84kAEz5ryRtJcd4g',
    createdAt: '2022-02-07T21:20:10.191Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2801.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'HNg2fLHmuaw2RuRKS',
    createdAt: '2022-02-07T21:20:10.212Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7953.1,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'TpraFxvYmT4Jq4JyL',
    createdAt: '2022-02-07T21:20:10.231Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 166682.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'nj9cwxeaqcFPocdSH',
    createdAt: '2022-02-07T21:20:10.249Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wnEepSfE8Z2bZpMgt',
    createdAt: '2022-02-07T21:20:10.265Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nf7vTvxQzwSaruvvS',
    createdAt: '2022-02-07T21:20:10.285Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8939.310000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'C6SYADLKRcGgwxFpf',
    createdAt: '2022-02-07T21:20:10.306Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3517,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'm2TvubA88CRy6eHaz',
    createdAt: '2022-02-08T05:01:57.271Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25696.64425,
    },
  },
  {
    _id: 'pwRYb2Zo8eBfbMqLC',
    createdAt: '2022-02-08T05:07:37.721Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2ed48cd8-15b1-4e21-ac18-321bba094c67_200x200.jpg?alt=media&token=e0e7419c-f486-4edd-b27b-508e7106435f',
        },
      },
      quantity: 1,
    },
  },
  {
    _id: 'Jj94NQ22XCZwStgiD',
    createdAt: '2022-02-08T05:08:07.558Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
      },
      quantity: 0,
    },
  },
  {
    _id: 'QLanA8YW8gJrQAPNf',
    createdAt: '2022-02-08T05:09:30.195Z',
    transactionType: 'transferIn',
    amount: 0.5,
    storeItem: {
      type: {
        _id: 'iASvRoJNWiSfQRNDL',
        name: 'BLEACH CONCENTRALE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2be3571f-f475-4e7a-ade3-41b15edd8a5f_200x200.jpg?alt=media&token=e887dc4b-c094-436e-8c63-4e1d57db4d35',
        },
      },
      quantity: 1,
    },
  },
  {
    _id: 'sSaSnDYNQbCzxZbkj',
    createdAt: '2022-02-08T05:11:58.555Z',
    transactionType: 'in',
    amount: 1878,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 30143.38,
    },
  },
  {
    _id: 'ApMQMWLGG8ZEorCCg',
    createdAt: '2022-02-08T05:12:36.877Z',
    transactionType: 'in',
    amount: 2960,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 32021.38,
    },
  },
  {
    _id: 'RFcnGKiD7soFSKPjr',
    createdAt: '2022-02-08T05:16:08.550Z',
    transactionType: 'transferIn',
    amount: 10,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 104,
    },
  },
  {
    _id: 'xeaCsJEp3WDxCYpL8',
    createdAt: '2022-02-08T05:20:52.964Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 28696.64425,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '9cJsoL9PpZzmBzw8Y',
    createdAt: '2022-02-08T05:20:53.005Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rGmHoobx7xpZrN5Ro',
    createdAt: '2022-02-08T05:20:53.035Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 9716.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g5a6cQByhsQixzLaC',
    createdAt: '2022-02-08T05:20:53.058Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2800.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'qgjNJBNtPpMBj6QqM',
    createdAt: '2022-02-08T05:20:53.082Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7947.1,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'BtzkyxL9w9BiWTkg3',
    createdAt: '2022-02-08T05:20:53.102Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 166282.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'jTnwHt5DZNXd3RKmD',
    createdAt: '2022-02-08T05:20:53.122Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ujNFCx5zLKC6c3YMQ',
    createdAt: '2022-02-08T05:20:53.140Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8939.310000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GzA2oALjY4m6msdHZ',
    createdAt: '2022-02-08T05:20:53.160Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '72j3eiKpQuXhyqNcv',
    createdAt: '2022-02-08T05:20:53.178Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '39rhgrq6tQaQu4Xei',
    createdAt: '2022-02-08T05:20:53.196Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3492,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'jwwdNFRNxxHieNuvn',
    createdAt: '2022-02-08T06:54:44.051Z',
    transactionType: 'transferIn',
    amount: 21.6,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 114.19999999999999,
    },
  },
  {
    _id: 'cGSdiFta48FbxishH',
    createdAt: '2022-02-08T07:08:00.624Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 293,
    },
  },
  {
    _id: 'KzNMzxCZa5RX4rn2k',
    createdAt: '2022-02-08T07:08:43.905Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 110.5,
    },
  },
  {
    _id: 'CWjJmgvD7KN8RaSaN',
    createdAt: '2022-02-08T12:03:10.832Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 803,
    },
  },
  {
    _id: 'rpA93iZHuMJpoMtn4',
    createdAt: '2022-02-08T12:17:12.071Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 27696.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 't6nuEMH7vHBp3bHDq',
    createdAt: '2022-02-08T12:17:12.093Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2775.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'qzDDidRrudtAASqmY',
    createdAt: '2022-02-08T12:17:12.115Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7941.1,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'qr4JuHJjgbCSERvfc',
    createdAt: '2022-02-08T12:17:12.144Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165782.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Pu6rGZcPT39cQhAbw',
    createdAt: '2022-02-08T12:17:12.175Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8939.310000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bFAsgjKgMvTLeirZd',
    createdAt: '2022-02-08T12:17:12.194Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bWFYgwJt6iKZFPqe6',
    createdAt: '2022-02-08T12:17:12.216Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wj98HaNwvyCQd9W2e',
    createdAt: '2022-02-08T12:17:12.236Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3449,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'JtYfpMFn9XRohmLjG',
    createdAt: '2022-02-08T12:25:40.288Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 27096.64425,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'c3tdfMgbLxh75Zi2a',
    createdAt: '2022-02-08T12:25:40.314Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9uhuhJt59sWaEXbKz',
    createdAt: '2022-02-08T12:25:40.337Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 9716.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RaFzZYkKdjuaeDhvg',
    createdAt: '2022-02-08T12:25:40.360Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2753.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'z4963qfEpm8Pmg5Q8',
    createdAt: '2022-02-08T12:25:40.381Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7937.1,
    },
    transactionType: 'transferOut',
    amount: -6.5,
  },
  {
    _id: 'fsW8nDRRRdgptzPNc',
    createdAt: '2022-02-08T12:25:40.415Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165782.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'fJnjijmcyaqDag3Dh',
    createdAt: '2022-02-08T12:25:40.443Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wSsDTngbAiRciPhBf',
    createdAt: '2022-02-08T12:25:40.478Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2So9qgGFFL4otdmcR',
    createdAt: '2022-02-08T12:25:40.503Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8939.310000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7fKhktcfGfXv84zrh',
    createdAt: '2022-02-08T12:25:40.523Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3405,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: '7X477FxJguFEucE5n',
    createdAt: '2022-02-08T12:39:30.244Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 26196.64425,
    },
    transactionType: 'transferOut',
    amount: -1600,
  },
  {
    _id: 'EdxMFNzHfBueBnedT',
    createdAt: '2022-02-08T12:39:30.280Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 9716.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'h7hC8tnNuctntD48u',
    createdAt: '2022-02-08T12:39:30.320Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2752.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'd3KrtxQdbNsEbYHzh',
    createdAt: '2022-02-08T12:39:30.373Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TX9E97ncaLFq57iHw',
    createdAt: '2022-02-08T12:39:30.424Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9oiF4nJFHcZeNxLZm',
    createdAt: '2022-02-08T12:39:30.479Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2825,
    },
    transactionType: 'transferOut',
    amount: -169,
  },
  {
    _id: 'WR4HdsqRit4k2pnwJ',
    createdAt: '2022-02-08T12:46:24.520Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7930.6,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'hsmQuLTRfRsSNu2Aq',
    createdAt: '2022-02-08T12:46:24.556Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2738.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'oHN3kGhhYiLF92DsG',
    createdAt: '2022-02-08T12:46:24.574Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24596.64425,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'XbCAhpQcJMq6xqGBt',
    createdAt: '2022-02-08T12:46:24.593Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WRPkYLEX47b7ssjWB',
    createdAt: '2022-02-08T12:46:24.609Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3362,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: 'hnRpHanDBDu3xHp4r',
    createdAt: '2022-02-09T04:45:16.979Z',
    transactionType: 'in',
    amount: 800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24296.64425,
    },
  },
  {
    _id: 'NYycaCs7gpHWWrbFJ',
    createdAt: '2022-02-09T04:45:39.849Z',
    transactionType: 'in',
    amount: 300,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 2552,
    },
  },
  {
    _id: 'cjyLJ6HocfRvT8hK5',
    createdAt: '2022-02-09T08:37:35.123Z',
    transactionType: 'transferIn',
    amount: 273,
    storeItem: {
      type: {
        _id: 'FhZmc9J37ak4r8fgo',
        name: 'BUCKET 1 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F50ef1676-6804-485a-b5f6-5355bf469bca_200x200.jpg?alt=media&token=48f7bcda-a201-4a13-a9f3-d5442ebdbf66',
        },
      },
      quantity: 193,
    },
  },
  {
    _id: 'fDixHNt5FTTkLaYL6',
    createdAt: '2022-02-09T10:31:43.698Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yr6pwcjQobXGwDrAz',
    createdAt: '2022-02-09T10:31:43.717Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 9716.5,
    },
    transactionType: 'transferOut',
    amount: -960,
  },
  {
    _id: 'bD7tg9Yf24fCbeXFW',
    createdAt: '2022-02-09T10:31:43.734Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165282.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'aQge7qqhL9soSCjGP',
    createdAt: '2022-02-09T10:31:43.756Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7928.6,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'fmfusf4BK4enmmjNQ',
    createdAt: '2022-02-09T11:22:47.699Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WsBzRWqtCr4SETovu',
    createdAt: '2022-02-09T11:22:47.727Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 8756.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: '2CYJFAJDMWiFdouE9',
    createdAt: '2022-02-09T11:22:47.747Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 164782.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: '5mCqsWuJ9JGSkHrjf',
    createdAt: '2022-02-09T11:22:47.762Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7868.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'Xd3SFdgKQFHZyFzpP',
    createdAt: '2022-02-09T11:34:44.939Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8PTwuR8MoRRFq3Ad9',
    createdAt: '2022-02-09T11:34:44.955Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 8792.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: '4ifhYxW4Tafniv4Pd',
    createdAt: '2022-02-09T11:34:44.969Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 164822.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: 'LoeoTPQEB7KM9sPTv',
    createdAt: '2022-02-09T11:34:44.983Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7873.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '6vJ2hur4SyLZoNuKH',
    createdAt: '2022-02-09T11:56:31.846Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kySEJcJxmbNPDFmKz',
    createdAt: '2022-02-09T11:56:31.867Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7868.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: 'wkKRhQQcNmwcH8fL6',
    createdAt: '2022-02-09T11:56:31.882Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 164362.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: 'TPzxrXKLqFaFyr3Lv',
    createdAt: '2022-02-09T11:56:31.898Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7818.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '2D8JdGEqdmq4dn4qK',
    createdAt: '2022-02-09T11:56:47.417Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uGwgmy4MiESaBrHFh',
    createdAt: '2022-02-09T11:56:47.436Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7868.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: 'Ppg4DERNhuycq5dmY',
    createdAt: '2022-02-09T11:56:47.451Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 164362.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: 'RPd9JJhvYrN929KZq',
    createdAt: '2022-02-09T11:56:47.483Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7818.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'Tivb59BniifXq78Wf',
    createdAt: '2022-02-09T12:04:44.810Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '49LSyhL9Ns9iwRxYG',
    createdAt: '2022-02-09T12:04:44.829Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 6020.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: 'BQKTqEaTSDJJ9NHLo',
    createdAt: '2022-02-09T12:04:44.848Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163442.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: 'cFSfGEpfSGo3Qc5t9',
    createdAt: '2022-02-09T12:04:44.865Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7708.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'sdBguSvZ8dtxjDGJq',
    createdAt: '2022-02-09T12:04:55.633Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pAKARN9JurZEcKCi2',
    createdAt: '2022-02-09T12:04:55.654Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 6020.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: 'jPJYGiMp3qoq5qjbG',
    createdAt: '2022-02-09T12:04:55.670Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163442.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: 'GH5G2A9WG7hnkMtyy',
    createdAt: '2022-02-09T12:04:55.687Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7708.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '8epGYfL4oW5vN34qQ',
    createdAt: '2022-02-09T12:05:44.842Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gJGQxMLyf3mMgSiMM',
    createdAt: '2022-02-09T12:05:44.856Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4172.5,
    },
    transactionType: 'transferOut',
    amount: -924,
  },
  {
    _id: 'Dx2hSuigMiedgbHkz',
    createdAt: '2022-02-09T12:05:44.879Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 162522.4,
    },
    transactionType: 'transferOut',
    amount: -460,
  },
  {
    _id: 'gtYGrZjMQNuMiJRxr',
    createdAt: '2022-02-09T12:05:45.055Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7598.6,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'gCtBeyTbzKtJN5Jom',
    createdAt: '2022-02-09T12:05:45.090Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE',
        unit: 'KG',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 84,
  },
  {
    _id: 'XH7mZ8kLxBaZejf2W',
    createdAt: '2022-02-09T12:07:49.874Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE',
        unit: 'KG',
      },
      quantity: 84,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '5pJk6PMbH35vpqayf',
    createdAt: '2022-02-09T12:07:49.895Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 863,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'D4BPDDrZQazEL3HBq',
    createdAt: '2022-02-09T12:07:49.911Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'JOHA PZ7 PHOSPHATE 21069099',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe4c48624-b0d4-4aa7-bc1a-204911b25bb7_200x200.jpg?alt=media&token=0d63e8b2-fdb6-48ca-bfeb-9f6ce210fbe7',
        },
      },
      quantity: 40,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'QqbBJYWinkza2S6oX',
    createdAt: '2022-02-09T12:07:49.936Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 343,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Xsn7xSP3QB6en9eCq',
    createdAt: '2022-02-09T12:07:49.951Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 153,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'gWiKv4D5MxfPKcMn4',
    createdAt: '2022-02-09T12:07:49.966Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 641,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'SCjQv8RjXiAojMM6B',
    createdAt: '2022-02-09T12:07:49.991Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8939.310000000001,
    },
    transactionType: 'transferOut',
    amount: -1.6,
  },
  {
    _id: 'KMNp27HbCt2hinBpA',
    createdAt: '2022-02-09T12:07:50.020Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 897,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'WgXmH2zgPtoeKsgSD',
    createdAt: '2022-02-09T13:21:25.270Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2656,
    },
  },
  {
    _id: 'CKYotRzNEwBqPYTiN',
    createdAt: '2022-02-09T13:22:03.967Z',
    transactionType: 'transferIn',
    amount: 275,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2728.9000000000005,
    },
  },
  {
    _id: '4Zx2JTX8e7vts27LX',
    createdAt: '2022-02-09T13:22:31.471Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3339,
    },
  },
  {
    _id: 'DXH234xqyTgxfeiF5',
    createdAt: '2022-02-09T13:22:54.585Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 152,
    },
  },
  {
    _id: 'YnmpR6o9vXPwN9Nro',
    createdAt: '2022-02-09T13:23:20.085Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 669,
    },
  },
  {
    _id: 'CjMRtGZ725ZMAgvte',
    createdAt: '2022-02-09T13:23:58.137Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3874,
    },
  },
  {
    _id: 'Em8RgSwYNfe46KJN8',
    createdAt: '2022-02-09T13:24:38.672Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 431,
    },
  },
  {
    _id: 'qmZF47T9AM2vtQCSQ',
    createdAt: '2022-02-09T13:30:32.125Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34981.380000000005,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'XhqEeF3Sh5YbzTi2s',
    createdAt: '2022-02-09T13:30:32.162Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'a9rGsZmNjoFQ3za8q',
    createdAt: '2022-02-09T13:30:32.190Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3003.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'nAoDatNNG7gKTgXB8',
    createdAt: '2022-02-09T13:30:32.207Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 162062.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'jTEhP2ddJD7aQBDfR',
    createdAt: '2022-02-09T13:30:32.225Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8937.710000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'oe6SDfi96egoMybR6',
    createdAt: '2022-02-09T13:30:32.239Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 636,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'GDRSiqNxh7NvPnSoX',
    createdAt: '2022-02-09T13:30:32.255Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 858,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'MvWom4GftdcNEqngj',
    createdAt: '2022-02-09T13:30:32.271Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3539,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'YuxQhiB4FDFv4TAs2',
    createdAt: '2022-02-09T13:52:51.707Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 25096.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'Zrj88dWufxbQknCy8',
    createdAt: '2022-02-09T13:52:51.725Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 25,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'AboLkuojcHuuErTzn',
    createdAt: '2022-02-09T13:52:51.741Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159762.4,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'gSBmmCYNveFgrgWYR',
    createdAt: '2022-02-09T13:52:51.761Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7543.6,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: '7aaGFmcHsPKisYnYW',
    createdAt: '2022-02-09T13:52:51.788Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4074,
    },
    transactionType: 'transferOut',
    amount: -96,
  },
  {
    _id: '9JaEfxa5BTKT8mTXp',
    createdAt: '2022-02-09T14:41:12.161Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34731.380000000005,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'RjwuuwHzjRRqazkqz',
    createdAt: '2022-02-09T14:41:12.202Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RphQSiX6ram24PX6k',
    createdAt: '2022-02-09T14:41:12.238Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2950.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'DKSqFnLp4augoc5sW',
    createdAt: '2022-02-09T14:41:12.272Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 621,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'jsBax9zykaWCrzqJF',
    createdAt: '2022-02-09T14:41:12.308Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 175,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'QWtnoGcW8KFpTy3L2',
    createdAt: '2022-02-09T14:41:12.333Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 843,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'LeJLc4jNHX6eS85bS',
    createdAt: '2022-02-09T14:41:12.347Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8934.710000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ZiyT9vtgNbk53QsiX',
    createdAt: '2022-02-09T14:41:12.369Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 158762.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'qbnyHZs7SPMyT5ZvK',
    createdAt: '2022-02-09T14:41:12.383Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 604,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'r2FRvFg7sCJfSB2hd',
    createdAt: '2022-02-09T14:41:12.396Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1220,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'kDgcG4MzZL8JSaqQ8',
    createdAt: '2022-02-09T14:41:12.416Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 887,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'iXAJ3geSJgyeW6m2h',
    createdAt: '2022-02-09T16:52:12.066Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3248.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yZqxZ5BmjA3bhnsQv',
    createdAt: '2022-02-09T16:52:12.082Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34481.380000000005,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'uduKcsxtK5itaGFrP',
    createdAt: '2022-02-09T16:52:12.113Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HYxjEjeD76zWbAj7o',
    createdAt: '2022-02-09T16:52:12.131Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2897.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'agEKBJS6EKxC7hikR',
    createdAt: '2022-02-09T16:52:12.147Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7423.6,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'Kz2af5AqECMjy7qT8',
    createdAt: '2022-02-09T16:52:12.160Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156462.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'bd7L7H7E3nCGePSWz',
    createdAt: '2022-02-09T16:52:12.177Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19079,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7qk8RL59rdCtyTGyZ',
    createdAt: '2022-02-09T16:52:12.191Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 809,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'ZHYLksikGyZQZ4KHo',
    createdAt: '2022-02-09T16:52:12.213Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8931.710000000001,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'JWwSKske5NbmCtEsZ',
    createdAt: '2022-02-09T16:52:12.231Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3479,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'w2PkSERR6Lvvm9KRK',
    createdAt: '2022-02-09T19:40:27.946Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GMJrmdPGAE8Pm8YiX',
    createdAt: '2022-02-09T19:40:27.965Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 33881.380000000005,
    },
    transactionType: 'transferOut',
    amount: -280,
  },
  {
    _id: 'JmiuNhDN6P3MswZKy',
    createdAt: '2022-02-09T19:40:27.983Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3248.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'onEMe5ykPs9mN4q5i',
    createdAt: '2022-02-09T19:40:27.998Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2879.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -8.4,
  },
  {
    _id: 'NNw9ufSJNnSi3jAZG',
    createdAt: '2022-02-09T19:40:28.027Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 785,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'GPSq42RvcFnXaWtn7',
    createdAt: '2022-02-09T19:40:28.044Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19067,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'As3kjfexNiSyL7vax',
    createdAt: '2022-02-09T19:40:28.062Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156162.4,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '7KnrPpssvuPnhjce7',
    createdAt: '2022-02-09T19:40:28.077Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7391.6,
    },
    transactionType: 'transferOut',
    amount: -13.5,
  },
  {
    _id: 'PZiEZWMnrtr86cZYk',
    createdAt: '2022-02-09T19:40:28.111Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8922.710000000001,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'wNQrCjxh4rbgDo682',
    createdAt: '2022-02-09T19:40:28.135Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3428,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: 'JdvN7skuGhjEYznwA',
    createdAt: '2022-02-09T19:56:44.256Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RQqgFQiHjcxDtbBZS',
    createdAt: '2022-02-09T19:56:44.274Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 33601.380000000005,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'HnLEgwnwpiGabvs4o',
    createdAt: '2022-02-09T19:56:44.291Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3248.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zxdaRREG8pcRoRH94',
    createdAt: '2022-02-09T19:56:44.305Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2871.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'XCpYAyjwhuLaQfHJq',
    createdAt: '2022-02-09T19:56:44.320Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 779,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'orfuWRbZX96gmASEk',
    createdAt: '2022-02-09T19:56:44.333Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19055,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FFDQzk43g82tKJQW3',
    createdAt: '2022-02-09T19:56:44.350Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156012.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'HkauLsb7neTfb8uPY',
    createdAt: '2022-02-09T19:56:44.364Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7378.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'SPoSwQc73QWdveEoW',
    createdAt: '2022-02-09T19:56:44.380Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8920.210000000001,
    },
    transactionType: 'transferOut',
    amount: -7.2,
  },
  {
    _id: '7MBLzGNnPqRcGZKGE',
    createdAt: '2022-02-09T19:56:44.393Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3405,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'KwCe9Foa4ihwfvrKz',
    createdAt: '2022-02-09T19:57:05.257Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pEsYrCoX7NXaiMAnB',
    createdAt: '2022-02-09T19:57:05.271Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 32701.380000000005,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'ZYhd6mGMZ6s535iHX',
    createdAt: '2022-02-09T19:57:05.284Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3248.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zBv3x6L2PfpE9nxXQ',
    createdAt: '2022-02-09T19:57:05.299Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2846.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '8dTJ9ACpPSccwtS2a',
    createdAt: '2022-02-09T19:57:05.312Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 779,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'c7dqdkdLvuJ8ywZTe',
    createdAt: '2022-02-09T19:57:05.324Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19055,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qjTYk9TaA5AuYudSv',
    createdAt: '2022-02-09T19:57:05.337Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155512.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'AhgL5NtefNgmoC2xW',
    createdAt: '2022-02-09T19:57:05.351Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7324.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'gnbhkoXtMhH6ChdZb',
    createdAt: '2022-02-09T19:57:05.370Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8913.01,
    },
    transactionType: 'transferOut',
    amount: -7.2,
  },
  {
    _id: 'dbh5NJ9vjiDZGYM6M',
    createdAt: '2022-02-09T19:57:05.383Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3338,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'G87tXe9dZn7mryX6A',
    createdAt: '2022-02-10T10:42:39.493Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 6940,
    },
  },
  {
    _id: 'ThC7THR9PmNgg8yM9',
    createdAt: '2022-02-10T10:43:14.665Z',
    transactionType: 'transferIn',
    amount: 600,
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1200,
    },
  },
  {
    _id: 'Pi9xQwEGKnJcrKM32',
    createdAt: '2022-02-10T10:49:15.215Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23096.64425,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'uCievhi3rQBqo39fG',
    createdAt: '2022-02-10T10:49:15.229Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7270.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'LT7cs9XoLNj5P7LBF',
    createdAt: '2022-02-10T10:49:15.243Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 437,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'bv87HRdwyydX83Xfr',
    createdAt: '2022-02-10T10:54:25.477Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 270,
    },
  },
  {
    _id: 'wFGzxGhnFHGKPvT2A',
    createdAt: '2022-02-10T10:54:52.567Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 427,
    },
  },
  {
    _id: 'pLoqmEzg27Wm9abbL',
    createdAt: '2022-02-10T10:57:06.755Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130.5,
    },
  },
  {
    _id: 'kuiQkTFnuyzD7CcAp',
    createdAt: '2022-02-10T11:01:38.520Z',
    transactionType: 'in',
    amount: 3307.1,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 31801.380000000005,
    },
  },
  {
    _id: 'tsYQ5NxfDjhqjvsgu',
    createdAt: '2022-02-10T11:02:16.931Z',
    transactionType: 'in',
    amount: 3000.3,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35108.48,
    },
  },
  {
    _id: '5A8xkXBm24i7YC86j',
    createdAt: '2022-02-10T11:03:27.349Z',
    transactionType: 'in',
    amount: 5000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 38108.780000000006,
    },
  },
  {
    _id: 'HndQGmmERkWH8ZpbE',
    createdAt: '2022-02-10T11:52:48.282Z',
    transactionType: 'in',
    amount: 30,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
      },
      quantity: 0,
    },
  },
  {
    _id: 'dJ2GuXKDrZnHNyAHs',
    createdAt: '2022-02-10T12:32:11.020Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3271,
    },
  },
  {
    _id: '2LZa8J6YhzavkynrN',
    createdAt: '2022-02-10T12:33:25.640Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2956,
    },
  },
  {
    _id: 'RbJvTsyqfzzmiTEky',
    createdAt: '2022-02-10T12:33:58.840Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2821.5000000000005,
    },
  },
  {
    _id: 'y8iLzrcQvyh2K6DQf',
    createdAt: '2022-02-10T12:48:37.131Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22896.64425,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '7oFMpRz2cWQWbw9jg',
    createdAt: '2022-02-10T12:48:37.150Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2896.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Q2b94Nk44cgeEoLEw',
    createdAt: '2022-02-10T12:48:37.164Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7260.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'wrNE8zQyAwfgnRzuT',
    createdAt: '2022-02-10T12:48:37.183Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19055,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SHNQkGNDg8ZbrWZiP',
    createdAt: '2022-02-10T12:48:37.198Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hgTPDQyhm7xxbacBL',
    createdAt: '2022-02-10T12:48:37.213Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3671,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'zkGmBCc4ZQ8xxFocW',
    createdAt: '2022-02-10T12:50:08.579Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'YH57SY8tLwYWL2zrj',
        name: 'RTS 82 MM (PLAIN WHITE) -JAR CAP',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F37eb8098-650b-44db-943a-dba9c32badc7_200x200.jpg?alt=media&token=71b920ae-7973-4d38-8516-2da53faf51c0',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'YFYuknK8Yv9yBJ934',
    createdAt: '2022-02-10T12:50:42.950Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
      },
      quantity: 0,
    },
  },
  {
    _id: 'mAtx2khnhkZsrXSqe',
    createdAt: '2022-02-10T13:52:04.216Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 43108.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yMFc8dKpevieCXcfL',
    createdAt: '2022-02-10T13:52:04.232Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3248.5,
    },
    transactionType: 'transferOut',
    amount: -860,
  },
  {
    _id: 'uCgk6Nzm6akawo2WY',
    createdAt: '2022-02-10T13:52:04.247Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155012.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '8dTBKSH8p7RPPgLN7',
    createdAt: '2022-02-10T13:52:04.263Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7230.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '5rmYhWAuC3KRhZk7b',
    createdAt: '2022-02-10T13:53:46.602Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 43108.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XqHxX5TDa5HCFZw7Q',
    createdAt: '2022-02-10T13:53:46.619Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2388.5,
    },
    transactionType: 'transferOut',
    amount: -860,
  },
  {
    _id: 'cPSce4HTndf259bKv',
    createdAt: '2022-02-10T13:53:46.632Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 154612.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'nj468Np9gmY5Eg868',
    createdAt: '2022-02-10T13:53:46.649Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7190.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '7miG2izedTx4fNdvn',
    createdAt: '2022-02-10T13:53:46.695Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE',
        unit: 'KG',
      },
      quantity: 34,
    },
    transactionType: 'in',
    amount: 72,
  },
  {
    _id: 'j9CrMYerx6DxduuDZ',
    createdAt: '2022-02-10T14:44:38.132Z',
    transactionType: 'in',
    amount: 800,
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (Salt Water)',
        unit: 'LITER',
      },
      quantity: 0,
    },
  },
  {
    _id: 'zABj74Tv8dKPK2kqs',
    createdAt: '2022-02-10T14:46:32.374Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 606,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Xy4yPtktJgJfzB6pd',
    createdAt: '2022-02-10T14:46:32.391Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 828,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'CNv4aNmLYWWzbGZCs',
    createdAt: '2022-02-10T14:46:32.404Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 174.75,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '4DBozuxRKLs8QY29X',
    createdAt: '2022-02-10T14:46:32.428Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2874.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'tbHxnMu9ATZ3PFcPZ',
    createdAt: '2022-02-10T14:48:05.863Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 591,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'rXKdZLQ7GYmrkfZPW',
    createdAt: '2022-02-10T14:48:05.877Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 813,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'AqQFDEHmf2smpdwfa',
    createdAt: '2022-02-10T14:48:05.891Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 174.5,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'yudZdZMH9pjxb5fEW',
    createdAt: '2022-02-10T14:48:05.906Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2871.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'cgDzaJK3F9uGdmjss',
    createdAt: '2022-02-10T14:48:05.936Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'Kbgmpfnwpr7K65iAG',
    createdAt: '2022-02-10T15:04:54.189Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eEKRhyw89JvNR6Hxf',
    createdAt: '2022-02-10T15:04:54.205Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 43108.780000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'uAT87NaoYjsqT3LDX',
    createdAt: '2022-02-10T15:04:54.466Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ydtGJeAJ3FhkPZ2Z7',
    createdAt: '2022-02-10T15:04:54.502Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2868.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -18.75,
  },
  {
    _id: '96wFuPxiHSrpeueP3',
    createdAt: '2022-02-10T15:04:54.517Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7150.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'EP6837ddiCS2vg5c4',
    createdAt: '2022-02-10T15:04:54.530Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 154212.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'Rzbxiw4EZgsYxKM5X',
    createdAt: '2022-02-10T15:04:54.545Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19055,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'aXTTtsTqLgw3FBKcR',
    createdAt: '2022-02-10T15:04:54.562Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 779,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'emb9mxeY27RhJWbTA',
    createdAt: '2022-02-10T15:04:54.578Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8905.81,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'QGoJ8YrkLh8cho9h8',
    createdAt: '2022-02-10T15:04:54.592Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3630,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'XCsvqfEebMEFtny8E',
    createdAt: '2022-02-10T15:21:25.698Z',
    transactionType: 'in',
    amount: 10000,
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 0,
    },
  },
  {
    _id: 'jr8QYPjjL4wpWxc4v',
    createdAt: '2022-02-10T15:23:42.747Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 10000,
    },
    transactionType: 'transferOut',
    amount: -764,
  },
  {
    _id: '2EkMLajixBHHghxXx',
    createdAt: '2022-02-10T15:23:42.764Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8896.81,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '64DtotZSp9ACJdJ9q',
    createdAt: '2022-02-10T15:23:42.780Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153912.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'arpm2mSL2hqFTtG3w',
    createdAt: '2022-02-10T15:23:42.795Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19585,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'D9LHNgqydHyKhn56H',
    createdAt: '2022-02-10T15:23:42.840Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'nG4oqcsEXA8hTAe6g',
    createdAt: '2022-02-10T15:27:58.243Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sCfMEjce7YPqkH3F3',
    createdAt: '2022-02-10T15:27:58.259Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 42508.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'nXe4u5JvqzS2RHbfi',
    createdAt: '2022-02-10T15:27:58.283Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jpHTB2EEYGWKQpgH5',
    createdAt: '2022-02-10T15:27:58.296Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2849.7500000000005,
    },
    transactionType: 'transferOut',
    amount: -6.25,
  },
  {
    _id: 'q254H5wyPTZzstwDJ',
    createdAt: '2022-02-10T15:27:58.310Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 767,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'eQimcDMA9xX7k4PLn',
    createdAt: '2022-02-10T15:27:58.328Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19031,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'WRfTD7PQixQg8WfME',
    createdAt: '2022-02-10T15:27:58.342Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 151112.4,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'a7yRSo6zM3PCWh5Yn',
    createdAt: '2022-02-10T15:27:58.358Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7123.1,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '9hq7XzHbWj6bwFmEC',
    createdAt: '2022-02-10T15:27:58.373Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8860.81,
    },
    transactionType: 'transferOut',
    amount: -2.8,
  },
  {
    _id: 'asjzi7Ttcuo7LghJh',
    createdAt: '2022-02-10T15:27:58.388Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3580,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'HdtTmdY85HLcATMb5',
    createdAt: '2022-02-10T15:28:28.725Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wREpvX9c8yBTo76Lf',
    createdAt: '2022-02-10T15:28:28.738Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 42308.780000000006,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'HcC6s7Wqjq3gWCzX7',
    createdAt: '2022-02-10T15:28:28.753Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mryskrFq5SzyGvrWi',
    createdAt: '2022-02-10T15:28:28.767Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2843.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'mdoSZredXengKQRDx',
    createdAt: '2022-02-10T15:28:28.781Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 763,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'yv8KckHXTS6BAPmDk',
    createdAt: '2022-02-10T15:28:28.796Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 19023,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'nHDnQYBuLWmX5Acgo',
    createdAt: '2022-02-10T15:28:28.811Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 151012.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'sA7yFnw8TZYh7HGNP',
    createdAt: '2022-02-10T15:28:28.825Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7114.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'NLgvzCGkiQJmbvwhN',
    createdAt: '2022-02-10T15:28:28.840Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8858.01,
    },
    transactionType: 'transferOut',
    amount: -12.6,
  },
  {
    _id: 'e9FxxCMmLwTG9Kn44',
    createdAt: '2022-02-10T15:28:28.852Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3564,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'GSSftHvARBNphCuFn',
    createdAt: '2022-02-10T21:39:44.014Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 9236,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'iG7fCMj8ZMWshzpRg',
    createdAt: '2022-02-10T21:39:44.027Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8845.41,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: '9g53ZKHgjzqoWEemG',
    createdAt: '2022-02-10T21:39:44.040Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 150512.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: '8mbj4am7ovkpe5FNv',
    createdAt: '2022-02-10T21:39:44.059Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19555,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'kMAcZ8P3rR6B9Xae4',
    createdAt: '2022-02-10T21:40:12.070Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 8471,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'SDo9TjsMuhM9TWBRS',
    createdAt: '2022-02-10T21:40:12.090Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8810.41,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: '5o8iEhaXP9e9dfPgG',
    createdAt: '2022-02-10T21:40:12.107Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147712.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'AJb5HJntpeh5Mqxqc',
    createdAt: '2022-02-10T21:40:12.122Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19505,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'PBT22egKB5cAaXMfT',
    createdAt: '2022-02-10T21:55:06.170Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 41408.780000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'LzenFPpA5x82ygJsH',
    createdAt: '2022-02-10T21:55:06.187Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7rPxRNrHRTxwskAhm',
    createdAt: '2022-02-10T21:55:06.200Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RjvwWqFyJPJLxmBhb',
    createdAt: '2022-02-10T21:55:06.219Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2818.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -18.75,
  },
  {
    _id: 'i3mpvryrGhFb3pa3o',
    createdAt: '2022-02-10T21:55:06.238Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7060.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'LWNLs2NtaGqCZzHg9',
    createdAt: '2022-02-10T21:55:06.252Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144912.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'GvCfakxhpuRFzftSW',
    createdAt: '2022-02-10T21:55:06.272Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18987,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'H6aovp9mFew4BpzSo',
    createdAt: '2022-02-10T21:55:06.288Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 745,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ZrXi6XSirEjgJoPA9',
    createdAt: '2022-02-10T21:55:06.304Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'aAJQTHcL3qDNHXPxv',
    createdAt: '2022-02-10T21:55:06.326Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3494,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '8jTXerARmHWAieurP',
    createdAt: '2022-02-10T21:56:43.185Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40808.780000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'WTAqz7cpzJcJqD6sj',
    createdAt: '2022-02-10T21:56:43.203Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n7ZtF3TZQJHMtyqyt',
    createdAt: '2022-02-10T21:56:43.219Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kEgZpG2AfDjwdk2CK',
    createdAt: '2022-02-10T21:56:43.233Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2799.7500000000005,
    },
    transactionType: 'transferOut',
    amount: -18.75,
  },
  {
    _id: 'joq9Zu4zMzWzzfKfn',
    createdAt: '2022-02-10T21:56:43.247Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7033.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'BjuSaoCkc6pJbzZFA',
    createdAt: '2022-02-10T21:56:43.272Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144612.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'bDFxGq2MTSGBFZnny',
    createdAt: '2022-02-10T21:56:43.287Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18963,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '6buPEt9uCjDX83SKy',
    createdAt: '2022-02-10T21:56:43.302Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 733,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'c2Ak4gZLkZZz4xJYF',
    createdAt: '2022-02-10T21:56:43.319Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'E3AAMkGgJzcs486fY',
    createdAt: '2022-02-10T21:56:43.336Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3444,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'bg4m36aWedB2MG82F',
    createdAt: '2022-02-11T05:14:52.204Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2ed48cd8-15b1-4e21-ac18-321bba094c67_200x200.jpg?alt=media&token=e0e7419c-f486-4edd-b27b-508e7106435f',
        },
      },
      quantity: 3,
    },
  },
  {
    _id: 'S4KqG6hjccErnwA5H',
    createdAt: '2022-02-11T05:15:34.253Z',
    transactionType: 'transferIn',
    amount: 3,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
      },
      quantity: 2,
    },
  },
  {
    _id: 'RfFaaGPXoni5sTbbG',
    createdAt: '2022-02-11T05:16:12.471Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'Hdy9QeF8vXbpAy9zx',
        name: 'HAIR NET CAP BLUE',
        unit: 'Carton',
      },
      quantity: 0,
    },
  },
  {
    _id: 'EpkssYAt96evyQ9Qh',
    createdAt: '2022-02-11T05:39:31.050Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 7706,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'p8F9HxheMu27nRAJx',
    createdAt: '2022-02-11T05:39:31.066Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8775.41,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'ynpgCgTsapXTYadw8',
    createdAt: '2022-02-11T05:39:31.079Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144312.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: '8ZN7G26JFuZGbhkhf',
    createdAt: '2022-02-11T05:39:31.092Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19455,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'zLjS3SGfWGX62g7Bt',
    createdAt: '2022-02-11T05:40:48.555Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6941,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'JC6eGaYuQgqP4iLaq',
    createdAt: '2022-02-11T05:40:48.571Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8740.41,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'pqWbtAwiBSpsh78Gm',
    createdAt: '2022-02-11T05:40:48.587Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 141512.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'kCx8ApEqPGvQN6zfH',
    createdAt: '2022-02-11T05:40:48.608Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19405,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'XbuEMJdxZcG6oHcNx',
    createdAt: '2022-02-11T05:42:48.805Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6176,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'xam2cYE9Y5XEGKKnk',
    createdAt: '2022-02-11T05:42:48.821Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8705.41,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'G2SDAnXQHNCZsT3C6',
    createdAt: '2022-02-11T05:42:48.835Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 138712.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'w5G6rbBXSp5nzwsrB',
    createdAt: '2022-02-11T05:42:48.855Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19355,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'zfSibD7k378nY4fmL',
    createdAt: '2022-02-11T05:42:48.889Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 400,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: '4Ya8EHZAAS4HJBnRr',
    createdAt: '2022-02-11T05:47:26.473Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 456,
    },
  },
  {
    _id: 'HkcZSYjoXf72GRPXT',
    createdAt: '2022-02-11T05:47:59.663Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 872,
    },
  },
  {
    _id: 'T8LKdFA7YTamG9TRY',
    createdAt: '2022-02-11T06:00:22.060Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 576,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'X8k7GPaGprkrdpond',
    createdAt: '2022-02-11T06:00:22.083Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 798,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Khk7f7ZoXosuTuFmi',
    createdAt: '2022-02-11T06:00:22.098Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 174.25,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'dNuieDMDBfcY5hWWi',
    createdAt: '2022-02-11T06:00:22.113Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2781.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'sqH5KGyP8gH2yk7PQ',
    createdAt: '2022-02-11T06:00:22.156Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'BhtHWqhh7LFbr38bb',
    createdAt: '2022-02-11T08:05:51.872Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5411,
    },
    transactionType: 'transferOut',
    amount: -764,
  },
  {
    _id: 'khTgaFL49u74CZQKd',
    createdAt: '2022-02-11T08:05:51.889Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8670.41,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'XhuXsEwZr6cRM7Nr4',
    createdAt: '2022-02-11T08:05:51.903Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135912.4,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'TqfmQPJfXEXMtEvXG',
    createdAt: '2022-02-11T08:05:51.918Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19305,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'bL5mxBZ6WQiZM3uQt',
    createdAt: '2022-02-11T08:06:37.310Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5411,
    },
    transactionType: 'transferOut',
    amount: -764,
  },
  {
    _id: 'LeTd2MveAcq2BhFqL',
    createdAt: '2022-02-11T08:06:37.325Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8670.41,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'TrXzcArFXBG5DriWS',
    createdAt: '2022-02-11T08:06:37.339Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135912.4,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'Dp47a4rjrf54SHLJg',
    createdAt: '2022-02-11T08:06:37.355Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19305,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'sNwtkZ3ys7sYoqDSi',
    createdAt: '2022-02-11T08:15:01.470Z',
    transactionType: 'in',
    amount: 10,
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
      },
      quantity: -2.9000000000000004,
    },
  },
  {
    _id: '7fbhWjh7LccM8Baze',
    createdAt: '2022-02-11T08:15:43.532Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40208.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '9HPawv2ADMc9jcbqn',
    createdAt: '2022-02-11T08:15:43.548Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ikuqA8NmW4ncGHEpW',
    createdAt: '2022-02-11T08:15:43.561Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'E8Y93tnc7qfiuzmbr',
    createdAt: '2022-02-11T08:15:43.577Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2778.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4x5nr5ZxpqteXGERB',
    createdAt: '2022-02-11T08:15:43.599Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
      },
      quantity: 7.1,
    },
    transactionType: 'transferOut',
    amount: -7.1,
  },
  {
    _id: 'FoDLcxm78TfBLthfP',
    createdAt: '2022-02-11T08:15:43.616Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19205,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '23cJvsmabnTEGgexj',
    createdAt: '2022-02-11T08:15:43.638Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3056,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bdjELe88AxQZ9WKeZ',
    createdAt: '2022-02-11T08:18:59.864Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22096.64425,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '8CfRTDaaF7P5xzfxS',
    createdAt: '2022-02-11T08:18:59.879Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40208.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '7756SkGiqtPvXnMDr',
    createdAt: '2022-02-11T08:18:59.893Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2778.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'z8LjmAeskTsnoAwto',
    createdAt: '2022-02-11T08:18:59.908Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 33,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'wHSdDqt8a6RFfdMuG',
    createdAt: '2022-02-11T08:18:59.923Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8598.41,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'nAyYchbSPNMn9K7bC',
    createdAt: '2022-02-11T08:18:59.943Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19205,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '2nZnXNWBcEuzMkKXv',
    createdAt: '2022-02-11T08:18:59.965Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 56,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'euN5CrniJdwwS9m4T',
    createdAt: '2022-02-11T08:18:59.988Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3978,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'axz9BXKmy5XQiQJFL',
    createdAt: '2022-02-11T08:20:04.195Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22095.64425,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'jpLujeCfTyL7thFP5',
    createdAt: '2022-02-11T08:20:04.217Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40207.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zAWK3NvityxfGBZRv',
    createdAt: '2022-02-11T08:20:04.234Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2777.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oKQc5DR89LAKgwJRx',
    createdAt: '2022-02-11T08:20:04.247Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 32,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n8eDXcQjwYkHMjuRS',
    createdAt: '2022-02-11T08:20:04.262Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8597.41,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'auF866TH3Z3RQSxXv',
    createdAt: '2022-02-11T08:20:04.279Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19204,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uCQ2ceWioipqvEmro',
    createdAt: '2022-02-11T08:20:04.291Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 55,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ctf9AZq9Ewc2iXZt2',
    createdAt: '2022-02-11T08:20:04.307Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3977,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vRHcabGuiucbkSBxm',
    createdAt: '2022-02-11T08:29:22.667Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22095.64425,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'q2853XkbMqvqZwQy5',
    createdAt: '2022-02-11T08:29:22.687Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40207.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n55vCdZ3GXqMbEqWr',
    createdAt: '2022-02-11T08:29:22.703Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2777.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Qv73gt6ziQ22HotSs',
    createdAt: '2022-02-11T08:29:22.723Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 32,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xaT9YE9DeFHmSHa85',
    createdAt: '2022-02-11T08:29:22.740Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8597.41,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sBokyBB4S3vmdHzFq',
    createdAt: '2022-02-11T08:29:22.759Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19204,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pSDctXeRBZCQ8m8gb',
    createdAt: '2022-02-11T08:29:22.773Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 55,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'm6tZ8dbEb3voffhq5',
    createdAt: '2022-02-11T08:29:22.790Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3977,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NLDaLQreRye6yBRjx',
    createdAt: '2022-02-11T08:33:18.745Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22095.64425,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'g8WKzo9oJeau9Sray',
    createdAt: '2022-02-11T08:33:18.763Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40207.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HKFK4Cz5DcAdxZzBb',
    createdAt: '2022-02-11T08:33:18.779Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2777.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2GYCQhaYLhDE3mbt9',
    createdAt: '2022-02-11T08:33:18.794Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 32,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pJv3dEP8e2QggaPPQ',
    createdAt: '2022-02-11T08:33:18.810Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8597.41,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CAoTEcoqmgiuJW6f8',
    createdAt: '2022-02-11T08:33:18.825Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19204,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Gg45m5eoZPG67dB6B',
    createdAt: '2022-02-11T08:33:18.844Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 55,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8ks4sDArW2JALkzTo',
    createdAt: '2022-02-11T08:33:18.859Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3977,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GaaNyxcudzLy9iMXN',
    createdAt: '2022-02-11T08:33:18.898Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 490,
    },
    transactionType: 'in',
    amount: 1,
  },
  {
    _id: 'KsfofwRWxjxz3tWtv',
    createdAt: '2022-02-11T08:37:22.883Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 491,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'LrYMaFjHCGRzY7FmR',
    createdAt: '2022-02-11T08:37:22.907Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'Roll',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21863,
    },
    transactionType: 'transferOut',
    amount: -0.1,
  },
  {
    _id: 'tAw82TNhneYyFvRqY',
    createdAt: '2022-02-11T08:37:22.925Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 309,
    },
    transactionType: 'transferOut',
    amount: -0.1,
  },
  {
    _id: 'vrP5eXi4Lh8XsZq4A',
    createdAt: '2022-02-11T10:02:47.707Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3393,
    },
  },
  {
    _id: 'uq3j8F6MYcpatqgc7',
    createdAt: '2022-02-11T10:50:09.518Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 40207.780000000006,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'WrW5uQkiSdMoq25x5',
    createdAt: '2022-02-11T10:50:09.536Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'a5NJYenDWRTyWpezP',
    createdAt: '2022-02-11T10:50:09.559Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2777.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'S4KNNyMBKyheST2u5',
    createdAt: '2022-02-11T10:50:09.582Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'p8ow8h5TKwPjxEDaz',
    createdAt: '2022-02-11T10:50:09.603Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135512.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'QrJXMoJ4eWh2dZ936',
    createdAt: '2022-02-11T10:50:09.619Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8597.41,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'FPFZKwfJrQmFQ5xa5',
    createdAt: '2022-02-11T10:50:09.637Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3693,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: '8KBfru5mWXqEk5KJw',
    createdAt: '2022-02-11T11:35:47.543Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3883,
    },
    transactionType: 'transferOut',
    amount: -764,
  },
  {
    _id: '6nevkqZNNCRDK5qmz',
    createdAt: '2022-02-11T11:35:47.557Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8594.41,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '4SrNJe9qJvo8aP7D8',
    createdAt: '2022-02-11T11:35:47.569Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 133212.4,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'vPRZKX2rRXGyuSdNx',
    createdAt: '2022-02-11T11:35:47.586Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19204,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'kuHARCxR7vs8FGw2x',
    createdAt: '2022-02-11T11:36:55.203Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3883,
    },
    transactionType: 'transferOut',
    amount: -764,
  },
  {
    _id: 'og2EWGkfFctT7prge',
    createdAt: '2022-02-11T11:36:55.218Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8594.41,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'usHmJeP7Li23J2mvL',
    createdAt: '2022-02-11T11:36:55.233Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 133212.4,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'en99gfWjKHBjdxqJD',
    createdAt: '2022-02-11T11:36:55.247Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19204,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'XspMXtyNFuCo7WyfZ',
    createdAt: '2022-02-11T15:02:46.941Z',
    transactionType: 'in',
    amount: 15,
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'bnDsQPsSg8QQjnuqq',
    createdAt: '2022-02-11T15:34:50.763Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 39907.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'dXQYnPTDNg3Wxhvuw',
    createdAt: '2022-02-11T15:34:50.780Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dM4DycZXMe27y5Dg3',
    createdAt: '2022-02-11T15:34:50.802Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AE4JRtQgPRg64ewBk',
    createdAt: '2022-02-11T15:34:50.816Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2724.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'DHkD42yZ9RgN6qcc9',
    createdAt: '2022-02-11T15:34:50.834Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 150.5,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'BJEP4DQaikKtHjrmE',
    createdAt: '2022-02-11T15:34:50.851Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19104,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ACx9GJzJL6Y3uRfjZ',
    createdAt: '2022-02-11T15:34:50.869Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'zpnHAP3mxKS2gyKCS',
    createdAt: '2022-02-11T15:34:50.889Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 55,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'yu9q2z4JBnbNAENad',
    createdAt: '2022-02-11T15:34:50.904Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8522.41,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'yx6boiTw97smEPdKA',
    createdAt: '2022-02-11T15:34:50.919Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3977,
    },
    transactionType: 'transferOut',
    amount: -79,
  },
  {
    _id: '9avN87NoQ5SPJch6F',
    createdAt: '2022-02-11T21:34:00.860Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '68H8u6hRvc3WAG6dt',
    createdAt: '2022-02-11T21:34:00.876Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 39107.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'ssY4kfvSZZmYL9eiH',
    createdAt: '2022-02-11T21:34:00.958Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Q69d2FTaMhHaBgj6S',
    createdAt: '2022-02-11T21:34:01.044Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2717.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -6.25,
  },
  {
    _id: 'okfD86dzr5QMr63iG',
    createdAt: '2022-02-11T21:34:01.074Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 721,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'okueK3FBYAuTC9aZa',
    createdAt: '2022-02-11T21:34:01.331Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18939,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'NLqZjBuGhQWgpYCdC',
    createdAt: '2022-02-11T21:34:01.443Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 132812.4,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Pu7Bkcx2ce5jMCiDh',
    createdAt: '2022-02-11T21:34:01.472Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 7006.1,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'haRhYifzupFijz9zD',
    createdAt: '2022-02-11T21:34:01.495Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '27LkFeKAX79TQkrfK',
    createdAt: '2022-02-11T21:34:01.519Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3630,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'yCMjbCopzgTr2Jqjc',
    createdAt: '2022-02-11T21:35:19.630Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4GdRvvan5by3eSZXc',
    createdAt: '2022-02-11T21:35:19.650Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 38907.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'rjyDHpYHGc88krFmi',
    createdAt: '2022-02-11T21:35:19.674Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bTAKKGqzuQ62PPCo6',
    createdAt: '2022-02-11T21:35:19.700Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2710.7500000000005,
    },
    transactionType: 'transferOut',
    amount: -6.25,
  },
  {
    _id: 'h3pS2XNzRm9Bq2bix',
    createdAt: '2022-02-11T21:35:19.719Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 717,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'umy7vDmnyS8qEMfSQ',
    createdAt: '2022-02-11T21:35:19.736Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18931,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '8ykMJB2ZJTvNNKZbT',
    createdAt: '2022-02-11T21:35:19.756Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 132712.4,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'sBQBHkc3iZZCczisY',
    createdAt: '2022-02-11T21:35:19.772Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6997.1,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '4QgPneTEWaHRfQMWQ',
    createdAt: '2022-02-11T21:35:19.789Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 1170,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'MZGjLzRKGExGHuPmJ',
    createdAt: '2022-02-11T21:35:19.811Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3615,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ujpcKBB9yxZ8iweL4',
    createdAt: '2022-02-11T21:38:59.493Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'adB2AW3FX7Dq74LuY',
    createdAt: '2022-02-11T21:38:59.512Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 38707.780000000006,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: '93564abbGCem8jMdz',
    createdAt: '2022-02-11T21:38:59.529Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GpoZPXSRZPvZRPNFD',
    createdAt: '2022-02-11T21:38:59.551Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2704.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '4ERESJobzK3bDhypY',
    createdAt: '2022-02-11T21:38:59.567Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 713,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'JYiySCKhYnkw2pH3x',
    createdAt: '2022-02-11T21:38:59.585Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18923,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'Y7aQ86yx2XHQ8v5mc',
    createdAt: '2022-02-11T21:38:59.601Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 132612.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'CmJCmDq4yAufW3LE6',
    createdAt: '2022-02-11T21:38:59.616Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6988.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: '2jKbBSzvmcZ8nm26e',
    createdAt: '2022-02-11T21:38:59.634Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 1140,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'Q9T2u6mqx4guBj23R',
    createdAt: '2022-02-11T21:38:59.666Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3600,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'w49A5opyRnkqHrCL7',
    createdAt: '2022-02-11T21:40:11.156Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TuAGRGNznvjyNggTH',
    createdAt: '2022-02-11T21:40:11.176Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 37807.780000000006,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'a94EEWBr8az8PHuBd',
    createdAt: '2022-02-11T21:40:11.190Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DtkePtneeMZzD3H4S',
    createdAt: '2022-02-11T21:40:11.215Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2679.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'TH3t8sa2nqWzXyevF',
    createdAt: '2022-02-11T21:40:11.234Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 695,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'H5xRKQqrkpLrDQcEs',
    createdAt: '2022-02-11T21:40:11.250Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18887,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'gPYFM8raqa9YC68Eo',
    createdAt: '2022-02-11T21:40:11.277Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 132112.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'njRoWXWN2W4EWz62k',
    createdAt: '2022-02-11T21:40:11.293Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6934.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'fQJF3DMEthheHshbM',
    createdAt: '2022-02-11T21:40:11.312Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 990,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'G8MM3nB6kZFunHCSH',
    createdAt: '2022-02-11T21:40:11.329Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3532,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'Xmvq5NhjxhKRD7bgt',
    createdAt: '2022-02-11T21:48:41.168Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 36907.780000000006,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'gncHY56fGGdYnmydY',
    createdAt: '2022-02-11T21:48:41.188Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sE6NSo6mJbwoxCrKf',
    createdAt: '2022-02-11T21:48:41.206Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TvLRkdyNBbEEv8Nzv',
    createdAt: '2022-02-11T21:48:41.222Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2654.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -18.7,
  },
  {
    _id: 'PYXrwhP8CjRyq9QLm',
    createdAt: '2022-02-11T21:48:41.249Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6880.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'SC4bQukxiroySZi2K',
    createdAt: '2022-02-11T21:48:41.268Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131612.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'dzGE2YvEd5PrRd3CL',
    createdAt: '2022-02-11T21:48:41.283Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18851,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'Bs8oWeLPtrvk8Kq8t',
    createdAt: '2022-02-11T21:48:41.306Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 677,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'hEKgahmFZ28NYzNJg',
    createdAt: '2022-02-11T21:48:41.326Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 840,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'Mx7QWvTSbPNr5dv6Z',
    createdAt: '2022-02-11T21:48:41.346Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3464,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'n68Bd456qM7wYhy33',
    createdAt: '2022-02-11T22:13:14.129Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EdspWFbssQkD8fdqH',
    createdAt: '2022-02-11T22:13:14.146Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2635.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -4.6,
  },
  {
    _id: 'ZTajR76fTCMZt8mt4',
    createdAt: '2022-02-11T22:13:14.165Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 143.5,
    },
    transactionType: 'transferOut',
    amount: -4.6,
  },
  {
    _id: 'Lf55wcbn5vh5A9CnE',
    createdAt: '2022-02-11T22:13:14.182Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 7080,
    },
    transactionType: 'transferOut',
    amount: -970,
  },
  {
    _id: 'NHQYxzuW6AzazCzy3',
    createdAt: '2022-02-11T22:13:14.198Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 26330,
    },
    transactionType: 'transferOut',
    amount: -970,
  },
  {
    _id: 'j3t2qR6maW9KtXqfY',
    createdAt: '2022-02-11T22:13:14.212Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: '4jBm3DTJzutegYMXB',
    createdAt: '2022-02-11T22:17:35.875Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pGHtHDkGYLSe8xd3j',
    createdAt: '2022-02-11T22:17:35.891Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 36257.780000000006,
    },
    transactionType: 'transferOut',
    amount: -525,
  },
  {
    _id: '9pD742fna6f6gEdDB',
    createdAt: '2022-02-11T22:17:35.905Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ohamkBmxHJ433EEMw',
    createdAt: '2022-02-11T22:17:35.919Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2631.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -4.6,
  },
  {
    _id: 'Qfgkon7voPYkBjRzG',
    createdAt: '2022-02-11T22:17:35.943Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 138.9,
    },
    transactionType: 'transferOut',
    amount: -4.6,
  },
  {
    _id: 'YKAz2dtSui5BGtMPL',
    createdAt: '2022-02-11T22:17:35.958Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19092,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'auxWxqro42WZgNqH7',
    createdAt: '2022-02-11T22:17:35.970Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3056,
    },
    transactionType: 'transferOut',
    amount: -131,
  },
  {
    _id: 'ypF2LWLgMc69u78QB',
    createdAt: '2022-02-12T04:23:01.717Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3414,
    },
  },
  {
    _id: '7Y88sz2u6Z3929pGL',
    createdAt: '2022-02-12T04:23:31.342Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2626.600000000001,
    },
  },
  {
    _id: 'rdWMR6wgf9PAsHTtc',
    createdAt: '2022-02-12T04:24:05.825Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 23,
    },
  },
  {
    _id: '8Tt5KADHTqyc7QHad',
    createdAt: '2022-02-12T04:24:40.312Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 714,
    },
  },
  {
    _id: 'QFAaCh4dWqi32oLyr',
    createdAt: '2022-02-12T05:22:08.224Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 2852,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '9KrWfeYPyFyiEgcxg',
    createdAt: '2022-02-12T05:22:08.237Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7580,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '55cvnpaFzaSEdBre8',
    createdAt: '2022-02-12T05:22:08.251Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 789,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'aLr66LPEmPaQ3wnLD',
    createdAt: '2022-02-12T05:31:37.178Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 2802,
    },
    transactionType: 'transferOut',
    amount: -159,
  },
  {
    _id: '8CoT6yhySqu8yH3nZ',
    createdAt: '2022-02-12T05:31:37.197Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7530,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: 'CWoAJDjHa3TDFJ7bx',
    createdAt: '2022-02-12T05:31:37.212Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 2100,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'HHnQizcineCCMC7FT',
    createdAt: '2022-02-12T05:31:37.233Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 785,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'u7B3dAwvcXgLdzpZu',
    createdAt: '2022-02-12T05:45:27.037Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35732.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Rfi9r7LkF7YYKxvno',
    createdAt: '2022-02-12T05:45:27.056Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'BDLpLxdgPLBr6Wjsx',
    createdAt: '2022-02-12T05:45:27.072Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131312.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'q8GFbywgCgFszT4Hz',
    createdAt: '2022-02-12T05:45:27.086Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6853.1,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'Tg96SEzj7W6mqSS9R',
    createdAt: '2022-02-12T05:45:27.131Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 106,
    },
    transactionType: 'in',
    amount: 60,
  },
  {
    _id: 'knsnNsM2XfA3A6bsu',
    createdAt: '2022-02-12T05:47:54.619Z',
    transactionType: 'in',
    amount: 5000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 728.5,
    },
  },
  {
    _id: 'FiDCEAxHa4zi6M2cH',
    createdAt: '2022-02-12T05:48:45.978Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35732.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WetbtZtSAxmNpTP5D',
    createdAt: '2022-02-12T05:48:45.996Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5728.5,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'gAX8iqPfi97BLQhX2',
    createdAt: '2022-02-12T05:48:46.022Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130912.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'W9a7mekegmxAWGhch',
    createdAt: '2022-02-12T05:48:46.043Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6809.1,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'fZzoBT3L6JbD7eiRi',
    createdAt: '2022-02-12T05:48:46.080Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 166,
    },
    transactionType: 'in',
    amount: 62,
  },
  {
    _id: 'mff5NuubwhDs6DZGe',
    createdAt: '2022-02-12T05:49:19.522Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35732.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3kqRSQAKNWDq7EAdy',
    createdAt: '2022-02-12T05:49:19.539Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4928.5,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'B5qevaNddDrTj7uM5',
    createdAt: '2022-02-12T05:49:19.553Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130512.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'cDxc6FiZT6caz8xpN',
    createdAt: '2022-02-12T05:49:19.565Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6764.1,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'dQA5HgSQG4fL2WA5u',
    createdAt: '2022-02-12T05:49:19.604Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 228,
    },
    transactionType: 'in',
    amount: 65,
  },
  {
    _id: 'CvBJC6o3kAW7oWeZc',
    createdAt: '2022-02-12T05:50:33.423Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35732.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pab49XZGPqK8tcKfi',
    createdAt: '2022-02-12T05:50:33.439Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4128.5,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'v2Lxw8ZLJ7CYQTqW5',
    createdAt: '2022-02-12T05:50:33.467Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130112.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'Sbh92YyRtDdGWmBKT',
    createdAt: '2022-02-12T05:50:33.482Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6720.1,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'qtxEY7LMJ5xiBcPx6',
    createdAt: '2022-02-12T05:50:33.530Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 293,
    },
    transactionType: 'in',
    amount: 61,
  },
  {
    _id: 'ZjTSufZeSrJ4BroHa',
    createdAt: '2022-02-12T05:52:17.127Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35732.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Tc4Xyypn3CzcmWzDS',
    createdAt: '2022-02-12T05:52:17.144Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3328.5,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'uPkcsk6PCSq8Hjha7',
    createdAt: '2022-02-12T05:52:17.161Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 129712.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'Q9SeG84ecJkQKojpi',
    createdAt: '2022-02-12T05:52:17.176Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6676.1,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'sTr9syoaomSnSsyFc',
    createdAt: '2022-02-12T05:52:17.211Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 354,
    },
    transactionType: 'in',
    amount: 69,
  },
  {
    _id: 'S6XgBSc4TM7Brs43k',
    createdAt: '2022-02-12T08:31:22.780Z',
    transactionType: 'in',
    amount: 2200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22094.64425,
    },
  },
  {
    _id: 'ZLxevhEf2JP44s78H',
    createdAt: '2022-02-12T08:32:17.207Z',
    transactionType: 'in',
    amount: 200,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 2643,
    },
  },
  {
    _id: '6nw2akt4ghCcW7Qhh',
    createdAt: '2022-02-12T10:41:14.960Z',
    transactionType: 'in',
    amount: 50,
    storeItem: {
      type: {
        _id: 'BWEYgyyXW9LZb2vaG',
        name: 'WHITE DAILY 40',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1f6ed39d-17eb-4a2f-906e-3abfb2631b6a_200x200.jpg?alt=media&token=f146a98a-a0b7-4a47-bcfe-5077a6e994e3',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'XjzgFSBZ6xES7Bj38',
    createdAt: '2022-02-12T11:06:55.914Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24294.64425,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'stXem5eWDQmyKNmGT',
    createdAt: '2022-02-12T11:06:55.931Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35732.780000000006,
    },
    transactionType: 'transferOut',
    amount: -450,
  },
  {
    _id: 'f5EKskdc3eBhZGnMN',
    createdAt: '2022-02-12T11:06:55.946Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 481,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '6Ddhr6ZS3fE7Yr8Tq',
    createdAt: '2022-02-12T11:06:55.966Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 340,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'foW3GT9ozc77vsGqW',
    createdAt: '2022-02-12T11:06:55.980Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1495,
    },
    transactionType: 'transferOut',
    amount: -252,
  },
  {
    _id: 'Qp2s8FFbzWQrZvECy',
    createdAt: '2022-02-12T11:06:55.994Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 932,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'QjfmqDq3f6LMLK4js',
    createdAt: '2022-02-12T11:31:53.356Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 2843,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: '5aF3d7KskFCWCzi2D',
    createdAt: '2022-02-12T11:31:53.370Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7458,
    },
    transactionType: 'transferOut',
    amount: -85,
  },
  {
    _id: '9MHwk5mkd6GvrY56m',
    createdAt: '2022-02-12T11:31:53.382Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 2098,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'tavXuZTAsxQpmjg27',
    createdAt: '2022-02-12T11:31:53.401Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 779,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '5gGDaYaEXhTGWKTMe',
    createdAt: '2022-02-12T11:34:44.524Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 471.5,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'tsjKt7pB99oqjms9H',
    createdAt: '2022-02-12T11:34:44.539Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7373,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'fQhFNnDfhGRwk4C4H',
    createdAt: '2022-02-12T11:34:44.554Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 772,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '3rD5KB88PNzWghNa8',
    createdAt: '2022-02-12T11:44:48.187Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35282.780000000006,
    },
    transactionType: 'transferOut',
    amount: -225,
  },
  {
    _id: 'PStHQFdTAy9FJcuzJ',
    createdAt: '2022-02-12T11:44:48.208Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24144.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R2Ge4MqFFjwvoZ5Xz',
    createdAt: '2022-02-12T11:44:48.224Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2751.600000000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'YCicwfwz8TJ2D64XB',
    createdAt: '2022-02-12T11:44:48.241Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'rRG6sbZGXWKy8n5b2',
    createdAt: '2022-02-12T11:44:48.260Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7922.41,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'WKzF55HKoCqJ9Mf5y',
    createdAt: '2022-02-12T11:44:48.277Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 129312.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'GJnqjDWWG7Ri6Eejv',
    createdAt: '2022-02-12T11:44:48.291Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 584,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'iCQAFKhgmfTKFTBJ3',
    createdAt: '2022-02-12T11:44:48.306Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1800,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'vHPhzjdzW2niNSMvX',
    createdAt: '2022-02-12T11:44:48.323Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 919,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'xtMYDtZwF6ZSqCRCz',
    createdAt: '2022-02-12T11:48:20.087Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 35057.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'MAwZKbvFe5GxbLkZM',
    createdAt: '2022-02-12T11:48:20.114Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PSXd4jACdDEu52Tht',
    createdAt: '2022-02-12T11:48:20.132Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24144.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7NJch5niXjSGC3yxi',
    createdAt: '2022-02-12T11:48:20.148Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 32,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'SDxrWKQ8EyaAzj5QM',
    createdAt: '2022-02-12T11:48:20.165Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 54.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'PNMqyJ5Fzsrzqsm5b',
    createdAt: '2022-02-12T11:48:20.180Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19080,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'FRyeeuBWtn6G6xLKt',
    createdAt: '2022-02-12T11:48:20.198Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7919.41,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'nP8YgwAtPiiMJuxwT',
    createdAt: '2022-02-12T11:48:20.228Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2925,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'v2224H8DWpmdStczp',
    createdAt: '2022-02-12T11:50:01.314Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE',
        unit: 'KG',
      },
      quantity: 423,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'MmjwjwyMDbcnFXswn',
    createdAt: '2022-02-12T11:50:01.340Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 39.5,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'LKP7LE8pB45aisva5',
    createdAt: '2022-02-12T11:50:01.359Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 335,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'W9TnDMeapkhNjMtuB',
    createdAt: '2022-02-12T11:50:01.396Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 172,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'f6brSEd8mrAxwpgNS',
    createdAt: '2022-02-12T11:50:01.415Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7918.21,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'uEu5qGCcjFBthqfBG',
    createdAt: '2022-02-12T11:50:01.432Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 561,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'q7dPcbmGARzmhB2Z8',
    createdAt: '2022-02-12T11:50:01.453Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 783,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MQpRwA6xiwrwQav7g',
    createdAt: '2022-02-12T11:50:01.473Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 30,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ZjAQFthGzmqQEFh84',
    createdAt: '2022-02-12T11:50:01.495Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 904,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yXjB6ymoqvpEfg3sK',
    createdAt: '2022-02-12T11:51:23.232Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE',
        unit: 'KG',
      },
      quantity: 368,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'jtwTa9DMw2LJQ7GmA',
    createdAt: '2022-02-12T11:51:23.257Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 39.1,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'pTGWMwjMddkXswgoX',
    createdAt: '2022-02-12T11:51:23.274Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 333,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'HTB5bWSTrW8We8hv2',
    createdAt: '2022-02-12T11:51:23.292Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 171.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'BmwuddCoqRDpjbKtP',
    createdAt: '2022-02-12T11:51:23.308Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7917.21,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'g2GPLXFFrxuRK2mCm',
    createdAt: '2022-02-12T11:51:23.324Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 560,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '3GCeWucqrmravZw9H',
    createdAt: '2022-02-12T11:51:23.340Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 783,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'kfYYn9RQJCrzdQRac',
    createdAt: '2022-02-12T11:51:23.360Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'gQnv7ABWataFFSfWs',
    createdAt: '2022-02-12T11:51:23.378Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 904,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'nLa9n5CZQrAbpBsru',
    createdAt: '2022-02-12T12:18:55.540Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2701.600000000001,
    },
  },
  {
    _id: 'sQA8C8Ya2LC7NtqiJ',
    createdAt: '2022-02-12T12:19:31.990Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3898,
    },
  },
  {
    _id: 'KBcPdshsTWTJzGKKm',
    createdAt: '2022-02-12T12:19:53.883Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2871,
    },
  },
  {
    _id: 'PxLWaZ9euXw67Z6zd',
    createdAt: '2022-02-12T12:21:03.008Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7915.71,
    },
  },
  {
    _id: 'cEALedakFwHwFgsnH',
    createdAt: '2022-02-12T12:22:05.388Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 127012.4,
    },
  },
  {
    _id: '85Kp26rXByHGfAvbv',
    createdAt: '2022-02-12T12:38:18.067Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24144.64425,
    },
    transactionType: 'transferOut',
    amount: -260,
  },
  {
    _id: 'WdcARWfz9mo2dH3SS',
    createdAt: '2022-02-12T12:38:18.084Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2876.600000000001,
    },
    transactionType: 'transferOut',
    amount: -7.8,
  },
  {
    _id: 'kxruTXmrAZF67x5rP',
    createdAt: '2022-02-12T12:38:18.100Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6632.1,
    },
    transactionType: 'transferOut',
    amount: -15.6,
  },
  {
    _id: 'wa8PFczC9db9bcar3',
    createdAt: '2022-02-12T12:38:18.135Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18827,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'JKrNgBXaszwkhMqh4',
    createdAt: '2022-02-12T12:38:18.150Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21862.9,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'QxaQyf7EGZybkmNBG',
    createdAt: '2022-02-12T12:38:18.165Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 308.9,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '2WRwSRoYWzDENiJJm',
    createdAt: '2022-02-12T12:55:43.804Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7321,
    },
  },
  {
    _id: 'yocf4nptPEWYkxP3m',
    createdAt: '2022-02-12T12:58:37.255Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23884.64425,
    },
    transactionType: 'transferOut',
    amount: -340,
  },
  {
    _id: 'wbht9iqAjez3TkhY4',
    createdAt: '2022-02-12T12:58:37.272Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2868.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -10.2,
  },
  {
    _id: 'eeuWNgWTqShADNnJN',
    createdAt: '2022-02-12T12:58:37.287Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6616.5,
    },
    transactionType: 'transferOut',
    amount: -20.4,
  },
  {
    _id: '8y6biGFvoQnFubP9e',
    createdAt: '2022-02-12T12:58:37.302Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18815,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yw5hm7zEADXz6skny',
    createdAt: '2022-02-12T12:58:37.316Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 640,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'CGLySCKa3h7Ck4BTz',
    createdAt: '2022-02-12T12:58:37.333Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3514,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'qYPTRtFKovhBt9j5P',
    createdAt: '2022-02-12T13:00:38.975Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 559,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '5264JC5MmtGJqJXS5',
    createdAt: '2022-02-12T13:00:38.988Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 780,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'LQ9v5SSKftdmWCKPo',
    createdAt: '2022-02-12T13:00:39.007Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 174,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'rSbxXsM3R9P2FkW7j',
    createdAt: '2022-02-12T13:00:39.019Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2858.600000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'J9fD82zw2FeHcfton',
    createdAt: '2022-02-12T13:00:39.052Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'Rc5aYx4TrfocH3TQB',
    createdAt: '2022-02-12T13:11:34.164Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qwipkzdKB8fJjX8qH',
    createdAt: '2022-02-12T13:11:34.179Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34257.780000000006,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: '2TiFyCiyEm8ifbNGW',
    createdAt: '2022-02-12T13:11:34.199Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2855.600000000001,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'MTkge3P8ABWJsfSHq',
    createdAt: '2022-02-12T13:11:34.214Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'Q9burWbRqy3kYMnh7',
    createdAt: '2022-02-12T13:11:34.229Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 177012.4,
    },
    transactionType: 'transferOut',
    amount: -1951,
  },
  {
    _id: 'WkWsXXWvdijsseRTE',
    createdAt: '2022-02-12T13:11:34.243Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8215.71,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'DrBGHALFhB3A2oGFu',
    createdAt: '2022-02-12T13:11:34.262Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 96,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'KRE7SZjHfjKc4WQJT',
    createdAt: '2022-02-12T13:11:34.277Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 562,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'ceiLb7Ei3Emy38y3o',
    createdAt: '2022-02-12T13:11:34.291Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1778,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'wFtPTfJgyLS2Jzhga',
    createdAt: '2022-02-12T13:14:10.815Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34047.780000000006,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'fMiJrtAWMA5BBTAES',
    createdAt: '2022-02-12T13:14:10.835Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pJfKPD4N4tq8fg2Ld',
    createdAt: '2022-02-12T13:14:10.851Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2813.600000000001,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'xTSEkjDQSqWDXfbcW',
    createdAt: '2022-02-12T13:14:10.869Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 't7F2Fvi2HXaH78dsa',
    createdAt: '2022-02-12T13:14:10.885Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175061.4,
    },
    transactionType: 'transferOut',
    amount: -348,
  },
  {
    _id: '8RzBoGSXNooReD6mj',
    createdAt: '2022-02-12T13:14:10.900Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8213.21,
    },
    transactionType: 'transferOut',
    amount: -0.45,
  },
  {
    _id: '9xqgwm63perdJMaGv',
    createdAt: '2022-02-12T13:14:10.919Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3492,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'CAG4rzqg5ymknbzbj',
    createdAt: '2022-02-12T13:25:52.074Z',
    transactionType: 'in',
    amount: 500,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2528.5,
    },
  },
  {
    _id: 'kb7dwKjr7Kp5gTd4e',
    createdAt: '2022-02-12T13:27:33.735Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 840,
    },
  },
  {
    _id: 'oLJYGdDqkCcypTjkp',
    createdAt: '2022-02-12T13:28:34.695Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 6110,
    },
  },
  {
    _id: 'Auo4KJgjGJTmd8LTu',
    createdAt: '2022-02-12T13:31:36.462Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34007.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'scBvNMZ7kNoxtrzZM',
    createdAt: '2022-02-12T13:31:36.476Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3028.5,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'rKwi5LoqGLEkNXYCC',
    createdAt: '2022-02-12T13:31:36.490Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174713.4,
    },
    transactionType: 'transferOut',
    amount: -1300,
  },
  {
    _id: 'BsYLen8pRHkPD6NyH',
    createdAt: '2022-02-12T13:31:36.503Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 6596.1,
    },
    transactionType: 'transferOut',
    amount: -1564,
  },
  {
    _id: 'kygvWMZWQA6EDudn8',
    createdAt: '2022-02-12T13:31:36.538Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 318,
    },
    transactionType: 'in',
    amount: 194,
  },
  {
    _id: 'xAZpeP7JsASw2TyJM',
    createdAt: '2022-02-12T21:08:12.944Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 34007.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'pZNybGCuvqxykoyu4',
    createdAt: '2022-02-12T21:08:12.963Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RuRZaSXfqCTvhhn5a',
    createdAt: '2022-02-12T21:08:12.983Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 48,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vDMoLX5R2ChQWeqA6',
    createdAt: '2022-02-12T21:08:12.997Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 173413.4,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'ndDr5Dz83gehyiQJQ',
    createdAt: '2022-02-12T21:08:13.028Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 5032.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 's6y9ZdHDTJxMEfqoX',
    createdAt: '2022-02-12T21:08:13.067Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 512,
    },
    transactionType: 'in',
    amount: 100,
  },
  {
    _id: 'ik3HJc8uLc6jfkg8G',
    createdAt: '2022-02-12T21:08:46.709Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 33007.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'm6h6BAfSL5hA2dCiQ',
    createdAt: '2022-02-12T21:08:46.727Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kYz9GQXvaPrnDw4o2',
    createdAt: '2022-02-12T21:08:46.740Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 47,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'JqxgXwmSErKirEAgY',
    createdAt: '2022-02-12T21:08:46.755Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 172413.4,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'RBqEzmmkR7mHPzMK2',
    createdAt: '2022-02-12T21:08:46.769Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4978.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: '6gyQ3PL4Z8CprQQmj',
    createdAt: '2022-02-12T21:08:46.805Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 612,
    },
    transactionType: 'in',
    amount: 96,
  },
  {
    _id: 'yqMWC24725RsyxJkD',
    createdAt: '2022-02-12T21:17:53.753Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9Ks34u2S9nEJMBjoc',
    createdAt: '2022-02-12T21:17:53.771Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 32007.780000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'MZ3qoLfQA3XiwKZxz',
    createdAt: '2022-02-12T21:17:53.785Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AdAQ842Pw2GxzPMpy',
    createdAt: '2022-02-12T21:17:53.801Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2806.100000000001,
    },
    transactionType: 'transferOut',
    amount: -4.3,
  },
  {
    _id: 'rnQ4kwQogK6bzR33y',
    createdAt: '2022-02-12T21:17:53.815Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134.3,
    },
    transactionType: 'transferOut',
    amount: -4.3,
  },
  {
    _id: '6ncaEN3JZBK4uTwje',
    createdAt: '2022-02-12T21:17:53.832Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19060,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'wLTYK69tSTntCZqdA',
    createdAt: '2022-02-12T21:17:53.850Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2971,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'icCsNFPBuyFGzdGs8',
    createdAt: '2022-02-12T21:20:45.349Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 31507.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'iLkSZTnckXg6KoiPL',
    createdAt: '2022-02-12T21:20:45.363Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DQPxwTnfCm4yWydtE',
    createdAt: '2022-02-12T21:20:45.377Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2801.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'b2McerWc3nNhWzQrN',
    createdAt: '2022-02-12T21:20:45.391Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'i6vpMqbviFWuReEQx',
    createdAt: '2022-02-12T21:20:45.403Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 8110,
    },
    transactionType: 'transferOut',
    amount: -1250,
  },
  {
    _id: 'QReNpxdkZXocaBNEL',
    createdAt: '2022-02-12T21:20:45.416Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 25360,
    },
    transactionType: 'transferOut',
    amount: -1250,
  },
  {
    _id: '3hTt9MbWFxr8EYDKG',
    createdAt: '2022-02-12T21:20:45.429Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1040,
    },
    transactionType: 'transferOut',
    amount: -203,
  },
  {
    _id: '3hNBDom2Z9SXrxwco',
    createdAt: '2022-02-13T00:17:26.888Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 31257.780000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'Bh2LFMk9XHt5FMn76',
    createdAt: '2022-02-13T00:17:26.905Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RwXrkdkhGdYdHDJrn',
    createdAt: '2022-02-13T00:17:26.920Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '74DB3FGpGxeDBtHC4',
    createdAt: '2022-02-13T00:17:26.934Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2799.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -18.75,
  },
  {
    _id: '3ao8TAbkp582zbSd8',
    createdAt: '2022-02-13T00:17:26.947Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4924.1,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'yeDStwpbprosFhRTT',
    createdAt: '2022-02-13T00:17:26.964Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171413.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'zTG8cTucE7i2RDF9s',
    createdAt: '2022-02-13T00:17:26.976Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18805,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'TLTkAwbNMT63j3K6S',
    createdAt: '2022-02-13T00:17:26.997Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 665,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'C88hnk9wmBtQRrjm5',
    createdAt: '2022-02-13T00:17:27.012Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 596,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'JwiFG9xjWLYuW6aLd',
    createdAt: '2022-02-13T00:17:27.032Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3482,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '7srxB4TWNWcTW62z4',
    createdAt: '2022-02-14T02:18:07.708Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GJBNYroFcmGAfY24s',
    createdAt: '2022-02-14T02:18:07.725Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 30657.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'RqHQwDfvK2KBfC2Jw',
    createdAt: '2022-02-14T02:18:07.739Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cEj6SEMJ2Q4H5hyb5',
    createdAt: '2022-02-14T02:18:07.756Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2781.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -6.25,
  },
  {
    _id: '9b9AqyCC9jTN6nGgQ',
    createdAt: '2022-02-14T02:18:07.778Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 641,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '2ExyneoHEYAQq5WvD',
    createdAt: '2022-02-14T02:18:07.794Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18793,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'M5B6EBpbXgeqafZFA',
    createdAt: '2022-02-14T02:18:07.809Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171113.4,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'a9wzLTPWZejMK57uQ',
    createdAt: '2022-02-14T02:18:07.825Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4906.1,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'F6Dd9pGpMJrF8bcyk',
    createdAt: '2022-02-14T02:18:07.848Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 396,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'C6GEXC88Q3rvxXGn4',
    createdAt: '2022-02-14T02:18:07.869Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3432,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'u9xbCQEP7sCERg937',
    createdAt: '2022-02-14T04:57:18.748Z',
    transactionType: 'transferIn',
    amount: 360,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 2096,
    },
  },
  {
    _id: 'ivZfPrqdxzYaNZYMo',
    createdAt: '2022-02-14T04:58:00.043Z',
    transactionType: 'transferIn',
    amount: 63,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 429,
    },
  },
  {
    _id: '45ETgdj5AohLgWE8M',
    createdAt: '2022-02-14T05:28:50.315Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 765,
    },
  },
  {
    _id: 'sZ74TmWGncBMsEgcg',
    createdAt: '2022-02-14T06:47:31.902Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23544.64425,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'oTowmcmizQzM8EFFf',
    createdAt: '2022-02-14T06:47:31.921Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4897.1,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'Hdzg8QGdorYgqvfxc',
    createdAt: '2022-02-14T06:47:31.938Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171013.4,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'tSYqwD7qc3oE9WwfN',
    createdAt: '2022-02-14T06:47:31.954Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18777,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'aAsS5d94yhGRN3nnC',
    createdAt: '2022-02-14T06:47:31.969Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 637,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'z4EogBSmA3N5YTJsy',
    createdAt: '2022-02-14T06:47:31.983Z',
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 527,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '6oJjBmZjaesTqdyek',
    createdAt: '2022-02-14T07:47:17.302Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23344.64425,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'ubAjnbrJfFCg5saFA',
    createdAt: '2022-02-14T07:47:17.317Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4881.1,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'jrFnkWQdtn2Jmnc3A',
    createdAt: '2022-02-14T07:47:17.331Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18769,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '4mKtqzkyqyeJiYhxs',
    createdAt: '2022-02-14T07:47:17.343Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8212.759999999998,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '4pXvpSQtSGSnguB9R',
    createdAt: '2022-02-14T07:47:17.360Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21858.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RvcfRvftDseTKnkxs',
    createdAt: '2022-02-14T07:47:17.376Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 540,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'mvCZSmrrP3qENeKwe',
    createdAt: '2022-02-14T07:47:17.393Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1756,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'YQgESu8CDbjvk4i3N',
    createdAt: '2022-02-14T11:11:03.346Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 2851,
    },
  },
  {
    _id: 'uYaJahbXk5yGasE2f',
    createdAt: '2022-02-14T11:11:55.211Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8201.759999999998,
    },
  },
  {
    _id: 'AudbWySpeRYomu4xH',
    createdAt: '2022-02-14T11:13:48.576Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3412,
    },
  },
  {
    _id: 'v2QLR9ww94SBD9Zpf',
    createdAt: '2022-02-14T11:24:15.102Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2774.8000000000006,
    },
  },
  {
    _id: 'pFNokfcp26J5ja7Ge',
    createdAt: '2022-02-14T11:24:39.469Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 128,
    },
  },
  {
    _id: 'NiWLm6iLNjo74SXFM',
    createdAt: '2022-02-14T11:25:10.173Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 768,
    },
  },
  {
    _id: 'JJvtxStdKKkBLTh8c',
    createdAt: '2022-02-14T11:25:38.611Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 896,
    },
  },
  {
    _id: 'ja9yt6vFZEnn2zXYF',
    createdAt: '2022-02-14T11:26:56.775Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 290,
    },
  },
  {
    _id: 'kKPijdzYqFxTh8Nx7',
    createdAt: '2022-02-14T11:42:49.465Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 419.5,
    },
    transactionType: 'transferOut',
    amount: -243,
  },
  {
    _id: '7ZtQ3TNGtqHaf3Nca',
    createdAt: '2022-02-14T11:42:49.479Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7961,
    },
    transactionType: 'transferOut',
    amount: -102,
  },
  {
    _id: 'YSu7L6iENbrfbMMGf',
    createdAt: '2022-02-14T11:42:49.504Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 2456,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '4rXGjbX2EvKkeduyq',
    createdAt: '2022-02-14T11:42:49.517Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 813,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'GSgpdWy6qeHdEAMC7',
    createdAt: '2022-02-14T11:45:55.594Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 176.5,
    },
    transactionType: 'transferOut',
    amount: -61,
  },
  {
    _id: 'HPgmF9yypjGNHD6yt',
    createdAt: '2022-02-14T11:45:55.630Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7859,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'Bs5h3aAJivGZuGcAK',
    createdAt: '2022-02-14T11:45:55.641Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 804,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'nRNkom7XHgY4c8k9d',
    createdAt: '2022-02-14T11:54:19.242Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 544,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'H6DsHBx8MPaPW6K3J',
    createdAt: '2022-02-14T11:54:19.261Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 865,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'gjwAsTaRCQCwD67ES',
    createdAt: '2022-02-14T11:54:19.287Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 173.75,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'w4fgXWFmaczZtrSns',
    createdAt: '2022-02-14T11:54:19.306Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2949.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '7GYxC4YD27yb63Qkp',
    createdAt: '2022-02-14T11:54:19.346Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 64,
  },
  {
    _id: 'JkcvdKsCHYcNLFGdt',
    createdAt: '2022-02-14T11:55:16.769Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22544.64425,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '497NwcKNrTKHvmDns',
    createdAt: '2022-02-14T11:55:16.786Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 30457.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bmWNqcRmH65nEqtQu',
    createdAt: '2022-02-14T11:55:16.803Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2946.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'jb9cDwaWvSzjv2nFR',
    createdAt: '2022-02-14T11:55:16.818Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'KcGBAfZXRxhEECQfi',
    createdAt: '2022-02-14T11:55:16.835Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 170913.4,
    },
    transactionType: 'transferOut',
    amount: -2.3,
  },
  {
    _id: 'cAnokEQ9DYYzLRLW8',
    createdAt: '2022-02-14T11:55:16.849Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8601.759999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '5BgA2TZ57Wsg5Li8u',
    createdAt: '2022-02-14T11:55:16.869Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 93,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Kw4Bz6bGvbAwy5xLL',
    createdAt: '2022-02-14T11:55:16.883Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 520,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'GTdmbx8ytTc5szdc7',
    createdAt: '2022-02-14T11:55:16.899Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1736,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'uoyNNjJiNgFePPDKA',
    createdAt: '2022-02-14T12:18:17.531Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2df3643e-e9c5-4580-a541-1a0e04694078_200x200.jpg?alt=media&token=a9d98cac-e13c-4892-8d30-f271d846c8c3',
        },
      },
      quantity: 1,
    },
  },
  {
    _id: 'aB8Mm6nrzFHXQAs27',
    createdAt: '2022-02-14T12:51:11.382Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22294.64425,
    },
    transactionType: 'transferOut',
    amount: -700,
  },
  {
    _id: '8982ijQbykva9TuT2',
    createdAt: '2022-02-14T12:51:11.401Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2893.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'taTuoNGJTs2gWvRdB',
    createdAt: '2022-02-14T12:51:11.426Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4849.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'v3ujRgjBCDd4ZwfT2',
    createdAt: '2022-02-14T12:51:11.444Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18729,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '47mN3hCHcMCA8Y2mD',
    createdAt: '2022-02-14T12:51:11.467Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 356,
    },
    transactionType: 'transferOut',
    amount: -106,
  },
  {
    _id: 'WBE7oL4TJvAC9xZ3n',
    createdAt: '2022-02-14T12:51:11.486Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3612,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'w4o58uyZXrS2qPjtb',
    createdAt: '2022-02-14T13:27:23.771Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Tsvsk4kg6NX44Wqe5',
    createdAt: '2022-02-14T13:27:23.785Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 850,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'oRDAtorQQMCcmG46h',
    createdAt: '2022-02-14T13:27:23.797Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 173.5,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'fStzNJpahEW8W2Kho',
    createdAt: '2022-02-14T13:27:23.811Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2874.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'JGYwLsWL8We4Ainar',
    createdAt: '2022-02-14T13:27:23.854Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'sDZRu25dN8DhnwnG5',
    createdAt: '2022-02-14T13:34:55.452Z',
    transactionType: 'out',
    amount: -64,
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 140,
    },
  },
  {
    _id: 'voZ6wzSFv7hFFc5DQ',
    createdAt: '2022-02-14T13:35:40.508Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21844.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Td8kouw8EYtvuR4np',
    createdAt: '2022-02-14T13:35:40.530Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 30457.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Ah7SHrm7Fwm5vpd78',
    createdAt: '2022-02-14T13:35:40.547Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2924.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'eroBdniq3ooDcp7tG',
    createdAt: '2022-02-14T13:35:40.569Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'jcXZ6BwZ6oSS3o6pZ',
    createdAt: '2022-02-14T13:35:40.590Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 170913.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'BvcmXQCR8qcdMNBY9',
    createdAt: '2022-02-14T13:35:40.604Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8601.759999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Cq9sdfWf2NvjWrBb3',
    createdAt: '2022-02-14T13:35:40.619Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 520,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'TthEpohbJ4wCtyRe6',
    createdAt: '2022-02-14T13:35:40.634Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1736,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'mEc2nDsNYqKPNMNhd',
    createdAt: '2022-02-14T13:59:24.257Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 30207.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'h5zbxGYuBWSPPhb8f',
    createdAt: '2022-02-14T13:59:24.275Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pX3zx4KKMhAu34DJL',
    createdAt: '2022-02-14T13:59:24.289Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21844.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xmTMZfwzkRHCP7HZM',
    createdAt: '2022-02-14T13:59:24.303Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2871.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'TKau6Jh3zRDgP6f2X',
    createdAt: '2022-02-14T13:59:24.322Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 148,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'XmEKSS9k2BQeaLLdH',
    createdAt: '2022-02-14T13:59:24.341Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19052.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'H6j2DDx5BvpjhbTpM',
    createdAt: '2022-02-14T13:59:24.358Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 14,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 't5Abxu5anYSub6oRw',
    createdAt: '2022-02-14T13:59:24.373Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 54.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '37siKtiWasQrmnGYZ',
    createdAt: '2022-02-14T13:59:24.390Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8598.759999999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'MSrDXgaGj9NdFMxjF',
    createdAt: '2022-02-14T13:59:24.406Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4018,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'rW9ZdwKjBpKLDMLXG',
    createdAt: '2022-02-14T21:16:48.897Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8598.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'ZY7JPzPM4wjBexjGt',
    createdAt: '2022-02-14T21:16:48.915Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168613.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zadWNFQJrXoDCLezD',
    createdAt: '2022-02-14T21:16:48.926Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19040.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oMn53PCThpjz8mbrY',
    createdAt: '2022-02-14T21:16:58.800Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8598.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '6hs7bX2RXLePB3G7c',
    createdAt: '2022-02-14T21:16:58.815Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168613.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'G6LNZiCydKDvtXBL8',
    createdAt: '2022-02-14T21:16:58.829Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19040.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WQnPbbnHMFarbKvfq',
    createdAt: '2022-02-14T21:27:50.068Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 29407.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'awvSpa67xYGY6Spz6',
    createdAt: '2022-02-14T21:27:50.084Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2864.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 't8pvYohdAteAEg6jg',
    createdAt: '2022-02-14T21:27:50.102Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168613.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'YNbzxnnLTw4kmi9Jm',
    createdAt: '2022-02-14T21:27:50.115Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4809.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'C2wWQEDFnQLSabimE',
    createdAt: '2022-02-14T21:27:50.130Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19040.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'M5JJ9vdsxDwbaBMYb',
    createdAt: '2022-02-14T21:27:50.143Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8298.159999999998,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'M9dLN8qqfhz8x8Rz9',
    createdAt: '2022-02-14T21:27:50.157Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3559,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'ZACYBstqxu96nyf7D',
    createdAt: '2022-02-14T21:27:50.172Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'GKFGgHmen6TvRKaNB',
    createdAt: '2022-02-14T21:28:17.376Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8273.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '8xy3iRcxov4QLRT2n',
    createdAt: '2022-02-14T21:28:17.389Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168113.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '26dr9RbbHGgp3AA5x',
    createdAt: '2022-02-14T21:28:17.401Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19040.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gkgPHNtnsbYhFvXAo',
    createdAt: '2022-02-14T21:29:21.620Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 28407.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'mbS2fbhHySjcreTLs',
    createdAt: '2022-02-14T21:29:21.635Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2839.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'zFZmzDz7DXKv4sA5e',
    createdAt: '2022-02-14T21:29:21.653Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168113.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'iY3TgFKNvunD7RGQ2',
    createdAt: '2022-02-14T21:29:21.666Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4755.1,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'vej6va3M93W8uZFo5',
    createdAt: '2022-02-14T21:29:21.682Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19040.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'od6X6nMMEPH8eFJiM',
    createdAt: '2022-02-14T21:29:21.697Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8123.159999999998,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'JEuAxi6mbxzbamihw',
    createdAt: '2022-02-14T21:29:21.715Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3494,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'MZRDDWYcMociFztwy',
    createdAt: '2022-02-14T21:29:21.734Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 670,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'PfNZqDMepK4SGACjc',
    createdAt: '2022-02-14T21:33:58.728Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 27407.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'ykjq2ATJDMBeJD9kT',
    createdAt: '2022-02-14T21:33:58.746Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 46,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'FN5HcFGGMYRGdPvJB',
    createdAt: '2022-02-14T21:33:58.761Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167613.4,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'x5BN5BD6hjf6KXn2u',
    createdAt: '2022-02-14T21:33:58.774Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4701.1,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'fHHne5ZKppaBbkTb3',
    createdAt: '2022-02-14T21:33:58.810Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 95,
    },
    transactionType: 'in',
    amount: 95,
  },
  {
    _id: 'QdgpABdS2nfwnxdRZ',
    createdAt: '2022-02-15T04:07:10.247Z',
    transactionType: 'in',
    amount: 2200,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
  },
  {
    _id: 'wPgWXzGvTWu2FbfpS',
    createdAt: '2022-02-15T04:09:05.716Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 26607.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oCqBDL2yBjRq6ANfG',
    createdAt: '2022-02-15T04:09:05.734Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2628.5,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'ZkG6m4YxSSSCZpho9',
    createdAt: '2022-02-15T04:09:05.746Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 45.2,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'jjsEBk53F3AFzCLNz',
    createdAt: '2022-02-15T04:09:05.760Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 166813.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'N9bbKj5wM2NoZQ2W8',
    createdAt: '2022-02-15T04:09:05.773Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4665.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'nyq7y33ZCYutcFLJG',
    createdAt: '2022-02-15T04:09:05.808Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 708,
    },
    transactionType: 'in',
    amount: 95,
  },
  {
    _id: 'REfBHb3vpzzi2Eeuo',
    createdAt: '2022-02-15T04:09:36.647Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 26607.780000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7sbz8R4QDgy3yamg7',
    createdAt: '2022-02-15T04:09:36.666Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1528.5,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'ptbHh4rAyzofkqfzv',
    createdAt: '2022-02-15T04:09:36.682Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 44.2,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'TorWib6Fiv4KQKMdB',
    createdAt: '2022-02-15T04:09:36.695Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 166313.4,
    },
    transactionType: 'transferOut',
    amount: -550,
  },
  {
    _id: 'AKCQZMn9mZ6joyypH',
    createdAt: '2022-02-15T04:09:36.711Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4605.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '4ttvoxKbFJqYt4aSh',
    createdAt: '2022-02-15T04:09:36.750Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
      },
      quantity: 803,
    },
    transactionType: 'in',
    amount: 97,
  },
  {
    _id: '7McoX8PWboxmPpCzL',
    createdAt: '2022-02-15T05:13:56.798Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 26607.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'GLPtLaLtg6gexBSYT',
    createdAt: '2022-02-15T05:13:56.813Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xgrLmD4scgsMfPoyG',
    createdAt: '2022-02-15T05:13:56.830Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21844.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hZcyAcuweBPy9Ld3K',
    createdAt: '2022-02-15T05:13:56.847Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 31,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'BrhG3JBzT4xpSKQK9',
    createdAt: '2022-02-15T05:13:56.863Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 53.8,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '73itKPHJ37qky2SrW',
    createdAt: '2022-02-15T05:13:56.877Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19040.5,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'ioBkevmxkBCDCwg7K',
    createdAt: '2022-02-15T05:13:56.894Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8098.159999999998,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WWkxwwbJAHah7a4A2',
    createdAt: '2022-02-15T05:13:56.909Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3251,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'S5XPkAH7cCsDS8YxL',
    createdAt: '2022-02-15T06:31:04.320Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 514,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'KfWbsBT7XM35kEjYK',
    createdAt: '2022-02-15T06:31:04.347Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 835,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'p6zCENFRp4EpcFRSF',
    createdAt: '2022-02-15T06:31:04.365Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 173.25,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'anLPqEayAkfqDLmrG',
    createdAt: '2022-02-15T06:31:04.384Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2814.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'CXAQwv4N7nZMTBuJt',
    createdAt: '2022-02-15T06:31:04.421Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 12,
    },
    transactionType: 'in',
    amount: 64,
  },
  {
    _id: 'Nckg5piMTHzDwEBCB',
    createdAt: '2022-02-15T08:48:10.395Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 499,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '8EjsR3SbeF4XgiQ7H',
    createdAt: '2022-02-15T08:48:10.411Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 820,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '3hGk9HYndPRmdRc8G',
    createdAt: '2022-02-15T08:48:10.423Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 173,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '3Yvb5RKHrapYSMZJr',
    createdAt: '2022-02-15T08:48:10.439Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2811.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'YgDi9Ees2Q9LqCPKg',
    createdAt: '2022-02-15T08:48:10.480Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'in',
    amount: 64,
  },
  {
    _id: 'nfCYb9hgNunhmv9Nc',
    createdAt: '2022-02-15T10:11:15.282Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8098.159999999998,
    },
  },
  {
    _id: 'QgBsx4vThQvzPiWnv',
    createdAt: '2022-02-15T10:11:49.402Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 926,
    },
  },
  {
    _id: 'FkRzyhPduZBtGskP8',
    createdAt: '2022-02-15T10:12:30.799Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 799,
    },
  },
  {
    _id: 'usz8Wup9j7zeDCx6K',
    createdAt: '2022-02-15T10:13:27.517Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2808.8000000000006,
    },
  },
  {
    _id: 'jdHf56dE4RsqsRxQP',
    createdAt: '2022-02-15T10:13:54.179Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3430,
    },
  },
  {
    _id: 'Y8dNf9ZmeoEZGZSkk',
    createdAt: '2022-02-15T10:14:22.066Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3194,
    },
  },
  {
    _id: 'cny7NM5tzgNoiQNXH',
    createdAt: '2022-02-15T10:14:42.757Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 431,
    },
  },
  {
    _id: 'tWFAWKf4rHBjgW2gF',
    createdAt: '2022-02-15T11:01:47.133Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8498.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'hjY8MAzGxPXhNXdWH',
    createdAt: '2022-02-15T11:01:47.157Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165763.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iTfNdbwTfy39GrGYh',
    createdAt: '2022-02-15T11:01:47.175Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4BqGqYhQen64NeBQf',
    createdAt: '2022-02-15T11:31:19.540Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21844.64425,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'GaZLb8Ea3fmWmPeZN',
    createdAt: '2022-02-15T11:31:19.558Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 25507.780000000006,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'iwdCGqHCKSiLBfCbR',
    createdAt: '2022-02-15T11:31:19.576Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 456,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'KSzdF9fWvGoBLLdJA',
    createdAt: '2022-02-15T11:31:19.591Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 331,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'FCq9p7yRDkQWu8Nve',
    createdAt: '2022-02-15T11:31:19.609Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1243,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'Ws7b74PyqoKtfS6YQ',
    createdAt: '2022-02-15T11:31:19.625Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 971,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'eh94TTg2ubnaZJctz',
    createdAt: '2022-02-15T12:25:19.672Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21794.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: '2sNr492uxhjk3X4vY',
    createdAt: '2022-02-15T12:25:19.690Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2933.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'yJu6nqnafHpW2Adti',
    createdAt: '2022-02-15T12:25:19.707Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4545.1,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'ZPWqeGrou9nkNfBMk',
    createdAt: '2022-02-15T12:25:19.724Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165763.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zNyafmReGi5yjEDNn',
    createdAt: '2022-02-15T12:25:19.744Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8348.159999999998,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'o5EDRNXc2JMuMbp5F',
    createdAt: '2022-02-15T12:25:19.764Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18709,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cfxbRvjfw822tKTs5',
    createdAt: '2022-02-15T12:25:19.793Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'G6Rb4ckKscuL8T4kZ',
    createdAt: '2022-02-15T12:25:19.813Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3630,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '8TCLTw97647JpSbo4',
    createdAt: '2022-02-15T12:26:58.895Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 25357.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'TYahty9uw4Pmgpkx6',
    createdAt: '2022-02-15T12:26:58.913Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21194.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BE2C2wKv8pKQ6CBze',
    createdAt: '2022-02-15T12:26:58.934Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2911.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'QZ4wLdHgvpfQSbG6z',
    createdAt: '2022-02-15T12:26:58.954Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'BaomoLtykmuGJjbJq',
    createdAt: '2022-02-15T12:26:58.983Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165763.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'YGMpd3NCAsXt2WBfN',
    createdAt: '2022-02-15T12:26:58.998Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8348.159999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'qSf3qDntFTKB2Pqgv',
    createdAt: '2022-02-15T12:26:59.017Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3580,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'MYFvHxxf7DxPxhpf3',
    createdAt: '2022-02-15T13:29:26.788Z',
    transactionType: 'transferIn',
    amount: 720,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 2454,
    },
  },
  {
    _id: 'xsPJTZfBSpk2uzEHX',
    createdAt: '2022-02-15T13:30:19.609Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7799,
    },
  },
  {
    _id: 'FGJrrhjvRYqoryuef',
    createdAt: '2022-02-15T14:33:24.049Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8345.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'K8n2TtZyK62goHQnS',
    createdAt: '2022-02-15T14:33:24.065Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163463.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rys7nxXpJGA8kvccT',
    createdAt: '2022-02-15T14:33:24.079Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LrQ8uXFgTRzyE3r6s',
    createdAt: '2022-02-15T14:33:31.831Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8345.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'htbvojuaX87rfzhHE',
    createdAt: '2022-02-15T14:33:31.847Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163463.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NZ2D9up7wu3GLFBcT',
    createdAt: '2022-02-15T14:33:31.861Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZQpm6hWatdHHLEzqC',
    createdAt: '2022-02-15T14:36:47.658Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 2355,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'tFjjtwfthriBNBe5t',
    createdAt: '2022-02-15T14:36:47.671Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8045.159999999998,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'GYPvfyHDNExoZiCiA',
    createdAt: '2022-02-15T14:36:47.690Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163463.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Z5J8YqwQzi3EFZXHE',
    createdAt: '2022-02-15T14:36:47.704Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'x3Gn7xEEb8pd9TLbd',
    createdAt: '2022-02-15T14:36:47.746Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 540,
    },
    transactionType: 'in',
    amount: 600,
  },
  {
    _id: 'wBuypJCwuxsPYd7jG',
    createdAt: '2022-02-15T23:08:17.751Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1755,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'WpjmvhqFnBhFZuk6r',
    createdAt: '2022-02-15T23:08:17.765Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7895.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'uL9JwtPoRFFgvzj6o',
    createdAt: '2022-02-15T23:08:17.779Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163463.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'D2vrcc9dQ8WYstFav',
    createdAt: '2022-02-15T23:08:17.792Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'xKu8zsvWdCaDisKjW',
    createdAt: '2022-02-15T23:08:27.393Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1755,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'SZcAXBHuhCPQnd2jZ',
    createdAt: '2022-02-15T23:08:27.407Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7895.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'nRFnXuu8AW3xPF8Lu',
    createdAt: '2022-02-15T23:08:27.433Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163463.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'HSYp7oBEzkbHberSh',
    createdAt: '2022-02-15T23:08:27.446Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Fey5sHXiuCkHZWLKm',
    createdAt: '2022-02-15T23:08:34.833Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1755,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'q4e9zXRDDorEaYFpJ',
    createdAt: '2022-02-15T23:08:34.850Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7895.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'rqiqS9crzTDbNXTnS',
    createdAt: '2022-02-15T23:08:34.866Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163463.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'jbpyYChJSTjs4FzAL',
    createdAt: '2022-02-15T23:08:34.881Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 19005.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'JJLMPGLNLja6PkCrv',
    createdAt: '2022-02-15T23:17:57.881Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 25157.780000000006,
    },
    transactionType: 'transferOut',
    amount: -620,
  },
  {
    _id: 'NWsfTnGBwoug5LGbc',
    createdAt: '2022-02-15T23:17:57.900Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zzKWqCJaZyHht4iHC',
    createdAt: '2022-02-15T23:17:57.917Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21194.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hoDp567CEa9NvzYGx',
    createdAt: '2022-02-15T23:17:57.939Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2861.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -18.75,
  },
  {
    _id: '2a3CnFQ78DeiEn7cv',
    createdAt: '2022-02-15T23:17:57.954Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4513.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'rKu26bdbbHeJtLizL',
    createdAt: '2022-02-15T23:17:57.974Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155063.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'dmkdLDDkxo62DAD3D',
    createdAt: '2022-02-15T23:17:57.989Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18709,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'bHbjpAGijCRNGzEXr',
    createdAt: '2022-02-15T23:17:58.010Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 632,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'v2zXCoRMjfEP3BGTw',
    createdAt: '2022-02-15T23:17:58.027Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'jovBMPcP6befHRSY6',
    createdAt: '2022-02-15T23:17:58.045Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3532,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'gmu3jqG5AcaPWn7n2',
    createdAt: '2022-02-16T05:54:48.103Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 25637.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'vR2d4F6qgW6nhcvL2',
    createdAt: '2022-02-16T05:54:48.120Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nyttPrDNPLdnXW9pB',
    createdAt: '2022-02-16T05:54:48.139Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21194.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NFYZp9fqkLnjP22yC',
    createdAt: '2022-02-16T05:54:48.185Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 31,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hMhRJFgDbf44oKapz',
    createdAt: '2022-02-16T05:54:48.224Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 53.8,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'byHcomYHpzaEyo3Yd',
    createdAt: '2022-02-16T05:54:48.267Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18890.5,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ZbXwTMhz6CLEShsDd',
    createdAt: '2022-02-16T05:54:48.296Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7790.159999999998,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5oE73kZYGJNwbmN6W',
    createdAt: '2022-02-16T05:54:48.328Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3411,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'YjAif4WyhEBkHYC9R',
    createdAt: '2022-02-16T06:20:08.032Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 24537.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'bSQRMBjgZZuxPvxjD',
    createdAt: '2022-02-16T06:20:08.049Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21194.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PDMzmJuJcoQFzYkcz',
    createdAt: '2022-02-16T06:20:08.064Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2843.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '3E43vZbF3ZoCgwyqt',
    createdAt: '2022-02-16T06:20:08.087Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'G2vZaC3e9FuXgWXxe',
    createdAt: '2022-02-16T06:20:08.106Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7790.159999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'kyXb937a2wYKA7mhd',
    createdAt: '2022-02-16T06:20:08.127Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 154763.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'AYJzpWg7fSZ5cz7qK',
    createdAt: '2022-02-16T06:20:08.141Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 505,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'txCMrvWNveuRpEwyT',
    createdAt: '2022-02-16T06:20:08.156Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1721,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '9g9sLznrFk8prXcXf',
    createdAt: '2022-02-16T06:20:08.174Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 966,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'iFjE87nnE5Rx9b45g',
    createdAt: '2022-02-16T10:29:44.066Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 115.5,
    },
  },
  {
    _id: 'Q7ZQr2K7th8sDbKpc',
    createdAt: '2022-02-16T10:30:09.711Z',
    transactionType: 'in',
    amount: 2500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21194.64425,
    },
  },
  {
    _id: 'QbSh6EZkyK852RtxY',
    createdAt: '2022-02-16T10:47:05.219Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 465.5,
    },
    transactionType: 'transferOut',
    amount: -298,
  },
  {
    _id: '8zxnJS6zQs8xh6Mpb',
    createdAt: '2022-02-16T10:47:05.238Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8439,
    },
    transactionType: 'transferOut',
    amount: -149,
  },
  {
    _id: 'segPBjaHeE4YG7oeT',
    createdAt: '2022-02-16T10:47:05.253Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3174,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'J2CS7zz86EuCY33XJ',
    createdAt: '2022-02-16T10:47:05.267Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 844,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KmzGRj5Z9sqpamq26',
    createdAt: '2022-02-16T10:48:37.945Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 167.5,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'BLvSFF7pxEEqS8AiK',
    createdAt: '2022-02-16T10:48:37.959Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8290,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'wzQW8H86tS8h5PT22',
    createdAt: '2022-02-16T10:48:37.985Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 832,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'mHQvP6AFyJpDjtZMZ',
    createdAt: '2022-02-16T11:09:21.980Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 484,
    },
  },
  {
    _id: 'LhXXiaFfCkseqMcGE',
    createdAt: '2022-02-16T11:09:52.319Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 436,
    },
  },
  {
    _id: 'PtNN4fuMdcxG8NG82',
    createdAt: '2022-02-16T12:40:47.205Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 584,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'sYhiFSmR5LkPKmJXu',
    createdAt: '2022-02-16T12:40:47.218Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 805,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'fF7kQuDGe5MPEWfq9',
    createdAt: '2022-02-16T12:40:47.231Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 172.75,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'vQ2RF6qWcGRBZtfXk',
    createdAt: '2022-02-16T12:40:47.244Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2793.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'T4bToFwEom6Q3MQvs',
    createdAt: '2022-02-16T12:40:47.273Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 12,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'KK4DjcMZvHM53efyg',
    createdAt: '2022-02-16T15:59:32.745Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: -540,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'q83sfFe9osAwCdutz',
    createdAt: '2022-02-16T15:59:32.759Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7787.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'Q4R3WqHWMGnnnBQ6W',
    createdAt: '2022-02-16T15:59:32.776Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 152463.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'ksFnjPfTkAdcM73Cg',
    createdAt: '2022-02-16T15:59:32.793Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18875.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'TLHXTGoif7yBtch54',
    createdAt: '2022-02-16T16:01:32.444Z',
    transactionType: 'in',
    amount: 5000,
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: -1305,
    },
  },
  {
    _id: 'r5nZCdhPQWtypqJ77',
    createdAt: '2022-02-16T16:01:52.688Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3695,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'xKSdfiq63QJfWzQgM',
    createdAt: '2022-02-16T16:01:52.702Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7752.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'NyaTDzqGF63c5oZJR',
    createdAt: '2022-02-16T16:01:52.714Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149663.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'ieZCAXHbPNAHCRLYt',
    createdAt: '2022-02-16T16:01:52.726Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18825.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'uztxFBHnXxMnyryqD',
    createdAt: '2022-02-16T16:02:05.022Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3695,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'KzCf7JHDyD3MbtTaw',
    createdAt: '2022-02-16T16:02:05.037Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7752.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'iTqQQXeuznaCC736Q',
    createdAt: '2022-02-16T16:02:05.050Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149663.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'r4wB4Kh378Sdqfrs5',
    createdAt: '2022-02-16T16:02:05.063Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18825.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'q3GbdEXiW7siMsNNa',
    createdAt: '2022-02-16T16:03:06.819Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 2165,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'n2zRxiovoDpQyG9Wd',
    createdAt: '2022-02-16T16:03:06.833Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7682.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'ZzixievQHBfMitKQv',
    createdAt: '2022-02-16T16:03:06.849Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144063.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'qxazgwzbrZE6GaCiW',
    createdAt: '2022-02-16T16:03:06.869Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18725.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'BGivQA3sEvyhrHXCf',
    createdAt: '2022-02-16T16:03:32.622Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3695,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: '8TcF8uowfaoASRhLW',
    createdAt: '2022-02-16T16:03:32.639Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7752.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: '5kq9GifqWXTbxRr9F',
    createdAt: '2022-02-16T16:03:32.656Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149663.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'QMznLqrYLyEQTLheD',
    createdAt: '2022-02-16T16:03:32.687Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18825.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'bCbKaJkghpMts3aoK',
    createdAt: '2022-02-16T16:03:41.074Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 2165,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'GR3hLCnd5K8u9Hfdm',
    createdAt: '2022-02-16T16:03:41.092Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7682.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'Aa6ihWgTerBF3fZqt',
    createdAt: '2022-02-16T16:03:41.107Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144063.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'GHbzCrja4QefpSxMd',
    createdAt: '2022-02-16T16:03:41.123Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18725.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'kLxycpLbdRGCpTzTg',
    createdAt: '2022-02-16T16:04:42.606Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3695,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'tad4zGhEefjnWTbMJ',
    createdAt: '2022-02-16T16:04:42.619Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7752.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'BWe9Np2qQFfGcTdK6',
    createdAt: '2022-02-16T16:04:42.630Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149663.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'D4sYhp2JzFtm8hSGQ',
    createdAt: '2022-02-16T16:04:42.642Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18825.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'BdxFd49N6kqctvqXT',
    createdAt: '2022-02-16T16:06:10.270Z',
    transactionType: 'in',
    amount: 5895,
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: -895,
    },
  },
  {
    _id: 'CrxE6cu26T5drnicP',
    createdAt: '2022-02-16T16:06:47.360Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5000,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'RGe6Jio29fMvm94XQ',
    createdAt: '2022-02-16T16:06:47.378Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7542.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'iADGqoLXffAABord9',
    createdAt: '2022-02-16T16:06:47.393Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 132863.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'Z7cCDtKbgdARhcYb6',
    createdAt: '2022-02-16T16:06:47.413Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18525.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '4jRrXoGrdoFDxmzKF',
    createdAt: '2022-02-16T16:07:32.630Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 4235,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'CAbaq2FAK5vxuQPeN',
    createdAt: '2022-02-16T16:07:32.645Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7507.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'nesPrke9B2fQ7S4wc',
    createdAt: '2022-02-16T16:07:32.661Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130063.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'HFACksBX5BYnZDjFH',
    createdAt: '2022-02-16T16:07:32.679Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18515.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'hqDxZJQJTe7Ldt4wH',
    createdAt: '2022-02-16T16:07:55.794Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3470,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'gRRQpcHDL8SCZ8H8S',
    createdAt: '2022-02-16T16:07:55.808Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7472.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'uJAhiYxsSmXSsr2jp',
    createdAt: '2022-02-16T16:07:55.821Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 127263.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'DZQR5q8L6XRRTia8c',
    createdAt: '2022-02-16T16:07:55.833Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18505.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Pg3BTxoKnooCCoAX3',
    createdAt: '2022-02-16T16:23:31.299Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 2705,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'ZCXfW57xgBv7L3Ehr',
    createdAt: '2022-02-16T16:23:31.325Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7437.159999999998,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'NdP5yNB8ALGpH9R67',
    createdAt: '2022-02-16T16:23:31.341Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 124463.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'ipZKr6kBGTSPM247C',
    createdAt: '2022-02-16T16:23:31.356Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18495.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Dbwop2pgSaTeSCdj6',
    createdAt: '2022-02-16T16:23:31.390Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 50,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'ktWbYGdu4astAmsSd',
    createdAt: '2022-02-17T04:53:51.356Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23694.64425,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'f3kjKttsanZ6Yffnc',
    createdAt: '2022-02-17T04:53:51.440Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2790.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Z4fNj6kiDoHvuEPyH',
    createdAt: '2022-02-17T04:53:51.588Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4486.1,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'RzBNpbYLjossr9g97',
    createdAt: '2022-02-17T04:53:51.618Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18685,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'qgDzpqpihxAzhuJKs',
    createdAt: '2022-02-17T04:53:51.692Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 850,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'oGGQyp7G6WJEuAxLr',
    createdAt: '2022-02-17T04:53:51.851Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3482,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'wrQwi39g8v7WmRhvx',
    createdAt: '2022-02-17T05:02:37.437Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23294.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'LMKLi86NZGaBztNAA',
    createdAt: '2022-02-17T05:02:37.456Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2778.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'pJyozurvuk2Xxfatk',
    createdAt: '2022-02-17T05:02:37.484Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4462.1,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'j5HNuxvfcwKX9gLew',
    createdAt: '2022-02-17T05:02:37.505Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121663.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'x9hdQRsys6op8kyHd',
    createdAt: '2022-02-17T05:02:37.520Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18670,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'q4KZia4BXjXBDYRE4',
    createdAt: '2022-02-17T05:02:37.536Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18445.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'E6x4iw85sLcuJw9CL',
    createdAt: '2022-02-17T05:02:37.555Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 790,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'Bj4b7CekuTo9eNFMs',
    createdAt: '2022-02-17T05:02:37.574Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3456,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'KxzQsGm9g3GDcoZv2',
    createdAt: '2022-02-17T05:04:40.408Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3402,
    },
  },
  {
    _id: 'SWQ9TPBinamDNDBEh',
    createdAt: '2022-02-17T05:05:19.684Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 114,
    },
  },
  {
    _id: 'zjkLAgHF25v2jCMuj',
    createdAt: '2022-02-17T05:05:51.833Z',
    transactionType: 'transferIn',
    amount: 41.6,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 135.79999999999998,
    },
  },
  {
    _id: 's5Y9jpr3znBMpb7LD',
    createdAt: '2022-02-17T05:06:20.159Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 310,
    },
  },
  {
    _id: 'YdPKvd5eJS4Qs56sD',
    createdAt: '2022-02-17T05:06:50.829Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 826,
    },
  },
  {
    _id: 'BbSoRy4dvxoYADQFa',
    createdAt: '2022-02-17T05:07:19.625Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 956,
    },
  },
  {
    _id: 'SDrCR3CRaaXb9sDNb',
    createdAt: '2022-02-17T05:07:57.969Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 93,
    },
  },
  {
    _id: '2mMEa3NkMvueL5ghD',
    createdAt: '2022-02-17T05:37:28.922Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22694.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HWKHZx6ADYooYh4BM',
    createdAt: '2022-02-17T05:37:28.942Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 24337.780000000006,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'MDgpFsDJtDDzyHa4F',
    createdAt: '2022-02-17T05:37:28.957Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'noLRC5t7kYN6E5iga',
    createdAt: '2022-02-17T05:37:28.983Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2760.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'ySgr7emAdBCaef3RB',
    createdAt: '2022-02-17T05:37:28.997Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 620,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'JujjXN89dLJFpdQJT',
    createdAt: '2022-02-17T05:37:29.018Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18655,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '87p3pbZYcKekKRrFK',
    createdAt: '2022-02-17T05:37:29.033Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121663.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'LDcx2vsJMNRMSMjc7',
    createdAt: '2022-02-17T05:37:29.047Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4426.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'kxfeF9NfNLwHTWBLd',
    createdAt: '2022-02-17T05:37:29.062Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 680,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D97n8pwXdnqXfam9q',
    createdAt: '2022-02-17T05:37:29.079Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3702,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Xcn3e4cczkrebFeEx',
    createdAt: '2022-02-17T05:37:29.093Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3944,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: '4m8Qvw3zro9qTNCSF',
    createdAt: '2022-02-17T06:45:21.550Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 23437.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'ZN5Jd4zKkzFR6Bgvx',
    createdAt: '2022-02-17T06:45:21.566Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fwfztvfmFNgNwgbgK',
    createdAt: '2022-02-17T06:45:21.587Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22694.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hLEiN8o4y8dtuGLbn',
    createdAt: '2022-02-17T06:45:21.601Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2735.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'im6pCKhEbugqGedx4',
    createdAt: '2022-02-17T06:45:21.616Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 141,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'G7aPFLxiLFMEsCpjX',
    createdAt: '2022-02-17T06:45:21.633Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18445.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hBGvz45NEhKcm6yjL',
    createdAt: '2022-02-17T06:45:21.648Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 13,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'p5ZEsj7niMdCD6ji4',
    createdAt: '2022-02-17T06:45:21.667Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 53.199999999999996,
    },
    transactionType: 'transferOut',
    amount: -0.7,
  },
  {
    _id: 'uGtXodGz8zbJh76ua',
    createdAt: '2022-02-17T06:45:21.681Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7402.159999999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'EtG2CAujAjPdoWMgb',
    createdAt: '2022-02-17T06:45:21.705Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3917,
    },
    transactionType: 'transferOut',
    amount: -71,
  },
  {
    _id: 'cZoDi6asXGxC9YCgo',
    createdAt: '2022-02-17T08:05:13.652Z',
    transactionType: 'in',
    amount: 250,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 101.5,
    },
  },
  {
    _id: 'z5whEDPS8j8z6DyvF',
    createdAt: '2022-02-17T08:05:58.045Z',
    transactionType: 'in',
    amount: 1100,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22694.64425,
    },
  },
  {
    _id: 'fogP6mjfiF3dyNx6N',
    createdAt: '2022-02-17T08:37:55.232Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 790,
    },
  },
  {
    _id: 'dHs57dGoXc27xyDyD',
    createdAt: '2022-02-17T08:38:39.687Z',
    transactionType: 'transferIn',
    amount: 600,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7401.559999999998,
    },
  },
  {
    _id: 'doJjZZpD98Fb5veF6',
    createdAt: '2022-02-17T08:39:09.241Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 171,
    },
  },
  {
    _id: 'ZK7zz6Ga4v7Q9zokE',
    createdAt: '2022-02-17T08:39:44.767Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3846,
    },
  },
  {
    _id: 'pZwmQPBsRzQHDuFMS',
    createdAt: '2022-02-17T08:40:19.561Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3338,
    },
  },
  {
    _id: 'estFoB5R2uoQD9RWL',
    createdAt: '2022-02-17T08:40:59.374Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2728.0500000000006,
    },
  },
  {
    _id: 'Xedcr3EHSxpcBD7oY',
    createdAt: '2022-02-17T10:22:26.080Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 569,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '5oXvDMMc8G2gbYAhK',
    createdAt: '2022-02-17T10:22:26.095Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 890,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'zsg4Fa4TCfRipKWcY',
    createdAt: '2022-02-17T10:22:26.107Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 172.5,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'kabXtg6yGmp2S5KaT',
    createdAt: '2022-02-17T10:22:26.126Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2928.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'tzxQyB6tJuAHwjhxv',
    createdAt: '2022-02-17T10:22:26.166Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 82,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'GyD5EReYuP9NHELrN',
    createdAt: '2022-02-17T10:25:51.733Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 554,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Nk2D9fdBuDnqhwbFk',
    createdAt: '2022-02-17T10:25:51.749Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 875,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'sse4emdpyeSznJrc5',
    createdAt: '2022-02-17T10:25:51.770Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 172.25,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '6chubXRHEqnFG4AE6',
    createdAt: '2022-02-17T10:25:51.794Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2878.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'aiyyW7endK4J5nvTs',
    createdAt: '2022-02-17T10:25:51.829Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 152,
    },
    transactionType: 'in',
    amount: 64,
  },
  {
    _id: 'RbCncYSDTPq4B3Akn',
    createdAt: '2022-02-17T10:38:16.670Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 22637.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '4ySiyad3JNN6o9CqD',
    createdAt: '2022-02-17T10:38:16.882Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23794.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'N4xGmB34vKGKuGgkC',
    createdAt: '2022-02-17T10:38:16.911Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2925.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ZA65oFGWnaBPhrLZ2',
    createdAt: '2022-02-17T10:38:16.928Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 146,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'RggWYzsdGi24F2MJe',
    createdAt: '2022-02-17T10:38:16.948Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8001.559999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'LuTavbzofjJjb5ctG',
    createdAt: '2022-02-17T10:38:16.975Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121163.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 't3CFmDiXGjrLLEeNz',
    createdAt: '2022-02-17T10:38:16.998Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 485,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'd27yWDtWnkbMvBo9G',
    createdAt: '2022-02-17T10:38:17.022Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1701,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'R9mMvmiwkBAnowwCD',
    createdAt: '2022-02-17T10:38:17.041Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1016,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'TvAxWjKdCuuZzukTP',
    createdAt: '2022-02-17T11:11:58.340Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 554,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'aWzrAnqocHTmbDyix',
    createdAt: '2022-02-17T11:11:58.358Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 875,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'b4hh9nTXEvThCMi9i',
    createdAt: '2022-02-17T11:11:58.371Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 172.25,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'yL3h2RaCuesvhhaF3',
    createdAt: '2022-02-17T11:11:58.385Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2925.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'TjHoaJB5pgcx7xK5o',
    createdAt: '2022-02-17T11:11:58.422Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 64,
  },
  {
    _id: 'Aji7SqWvCb9G2SQNC',
    createdAt: '2022-02-17T11:18:41.269Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 22637.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'SwsLeSdei5SRJ9vAA',
    createdAt: '2022-02-17T11:18:41.286Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23794.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZfEJXg69G63kKKk25',
    createdAt: '2022-02-17T11:18:41.304Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2922.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'cWgHfZwgwukHbL9iJ',
    createdAt: '2022-02-17T11:18:41.319Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'az88ykPcZKkcqRi9a',
    createdAt: '2022-02-17T11:18:41.336Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8001.559999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'qfKKfx8peBXzDWsG3',
    createdAt: '2022-02-17T11:18:41.350Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121163.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'HDJ9vYiXByPKJDoxt',
    createdAt: '2022-02-17T11:18:41.365Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 485,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'x8GemERQMAeHPHFFW',
    createdAt: '2022-02-17T11:18:41.379Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1701,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '7BA38C9hS2LCQy9mJ',
    createdAt: '2022-02-17T11:18:41.394Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1016,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'zyyiNvvW9KAfj8PPC',
    createdAt: '2022-02-17T12:02:16.382Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 329.5,
    },
  },
  {
    _id: 'BSodXe5pBNgkoBxMs',
    createdAt: '2022-02-17T12:02:42.820Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8225,
    },
  },
  {
    _id: 'TnKrDuok9icfqLSKA',
    createdAt: '2022-02-17T12:54:58.572Z',
    transactionType: 'in',
    amount: 5000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 428.5,
    },
  },
  {
    _id: 'RxskedpDD2vbkN24m',
    createdAt: '2022-02-17T12:58:41.189Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 22387.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'NvmFgdvgYYLdZuLJn',
    createdAt: '2022-02-17T12:58:41.204Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SgtnMCoCyjZdFPLzL',
    createdAt: '2022-02-17T12:58:41.217Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 43.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'scTLF6FwRKoLxZmXP',
    createdAt: '2022-02-17T12:58:41.232Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118863.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'vqkcfp5W4xk3k3v3T',
    createdAt: '2022-02-17T12:58:41.245Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4366.1,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'ssAiye9Nwuek5FqQp',
    createdAt: '2022-02-17T12:58:41.277Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 900,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: '8rqESNLAj6XPmgTuL',
    createdAt: '2022-02-17T12:59:08.626Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 21287.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'quXgysHn4C5cnEusP',
    createdAt: '2022-02-17T12:59:08.646Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D8jT9t4p9MZAbiKBe',
    createdAt: '2022-02-17T12:59:08.662Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 42.7,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ceRmRzKz3hZzgqQHr',
    createdAt: '2022-02-17T12:59:08.677Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118363.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'iBmuqTE682xprCaSz',
    createdAt: '2022-02-17T12:59:08.697Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4308.1,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'huqzijKeHXNvRgaov',
    createdAt: '2022-02-17T12:59:08.732Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 980,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'dvk98kosJAEgypniC',
    createdAt: '2022-02-17T15:06:30.038Z',
    transactionType: 'in',
    amount: 79,
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'L4Ezg8a6BkEn2TPnr',
    createdAt: '2022-02-17T15:07:45.612Z',
    transactionType: 'in',
    amount: 200,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'eRrte5TBbq6Wkqydn',
    createdAt: '2022-02-17T22:07:13.296Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 20187.780000000006,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: '5mwnPbK4aTXniKQ8k',
    createdAt: '2022-02-17T22:07:13.315Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3ZuL2pB2JDaKdgqJP',
    createdAt: '2022-02-17T22:07:13.332Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23794.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CgK2XMo8LdwHGaEva',
    createdAt: '2022-02-17T22:07:13.347Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2872.0500000000006,
    },
    transactionType: 'transferOut',
    amount: -18.7,
  },
  {
    _id: 'RH9o97jkBF2k6wBaP',
    createdAt: '2022-02-17T22:07:13.364Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4250.1,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'zQn7XS7LSL9dAmBCq',
    createdAt: '2022-02-17T22:07:13.378Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 117863.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'rTbYqxN6WY3vs6a3W',
    createdAt: '2022-02-17T22:07:13.392Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18655,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'Te9D5y8kZsbtSzuG5',
    createdAt: '2022-02-17T22:07:13.407Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'EfKegRfry5LdEpCjj',
    createdAt: '2022-02-17T22:07:13.422Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 680,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'xZd4XrtbTKoYNf4fP',
    createdAt: '2022-02-17T22:07:13.439Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3702,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'dmDeYvT8GixxiiSvi',
    createdAt: '2022-02-17T22:15:55.388Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23794.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Kesz5DRRB3dBZmEmr',
    createdAt: '2022-02-17T22:15:55.406Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 19537.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '7LjZyDJXLDBXmChP9',
    createdAt: '2022-02-17T22:15:55.422Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WWhDih33vyZNSYnpD',
    createdAt: '2022-02-17T22:15:55.439Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2853.350000000001,
    },
    transactionType: 'transferOut',
    amount: -7.8,
  },
  {
    _id: 'hAmyDgWvnJFGobZSj',
    createdAt: '2022-02-17T22:15:55.459Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4225.1,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'F4BZf6ZFBs6yfp6rj',
    createdAt: '2022-02-17T22:15:55.475Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 117563.4,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: '9rb47xeEknwcjQ642',
    createdAt: '2022-02-17T22:15:55.491Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 588,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'msfA4SMa6PxcHuxJc',
    createdAt: '2022-02-17T22:15:55.511Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18631,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '9ak5fuGA35zXYtEpj',
    createdAt: '2022-02-17T22:15:55.527Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18445.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PnG93TSxeosoLhimy',
    createdAt: '2022-02-17T22:15:55.545Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 480,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '5nMi8Y5PdZTJa4dhQ',
    createdAt: '2022-02-17T22:15:55.578Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3652,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: '4dXhEDuRKFPorypqv',
    createdAt: '2022-02-18T06:57:56.171Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23794.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'SbYw7SzN6L3d7HGKs',
    createdAt: '2022-02-18T06:57:56.188Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2845.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'Ctf2MyZtEN6PP77De',
    createdAt: '2022-02-18T06:57:56.211Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4213.1,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'rgDhi42Gm8zqF3HdL',
    createdAt: '2022-02-18T06:57:56.232Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 117438.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zEZ56P795oB82ybZ4',
    createdAt: '2022-02-18T06:57:56.252Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18621,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9KpSn6Cz2sLFtZQkH',
    createdAt: '2022-02-18T06:57:56.268Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18445.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dv8Aaapxth9gTp4kn',
    createdAt: '2022-02-18T06:57:56.289Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 420,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2DbswoRiykTPB3aBy',
    createdAt: '2022-02-18T06:57:56.306Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3626,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'gqspczw7rtKj3e2La',
    createdAt: '2022-02-18T07:36:49.967Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 351.5,
    },
    transactionType: 'transferOut',
    amount: -191,
  },
  {
    _id: 'z2K8g7pb4pWFy9ypD',
    createdAt: '2022-02-18T07:36:49.981Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8865,
    },
    transactionType: 'transferOut',
    amount: -115,
  },
  {
    _id: 'gBrzyJgzQQw4L3Nq3',
    createdAt: '2022-02-18T07:36:49.994Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3167,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BnfafTzpi9ix9i45r',
    createdAt: '2022-02-18T07:36:50.018Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 871,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'pPjLPPEA9YKpk4q7r',
    createdAt: '2022-02-18T07:38:27.056Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 160.5,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'LmAspmvovGoRtyvto',
    createdAt: '2022-02-18T07:38:27.076Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8750,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'Peedzp6wmM5PLo3zR',
    createdAt: '2022-02-18T07:38:27.090Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 861,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'n5i29gaNnQsuAbJwz',
    createdAt: '2022-02-18T07:40:11.401Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1002,
    },
  },
  {
    _id: 'Qjboqo2Lc2eeJkuJ4',
    createdAt: '2022-02-18T07:40:36.361Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2831.5500000000006,
    },
  },
  {
    _id: '9jo2YiNgz8vuqH6ED',
    createdAt: '2022-02-18T07:42:23.946Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 106.5,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'eubsSLB5tbJxLftB6',
    createdAt: '2022-02-18T07:42:23.962Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8696,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'tEycrQfdxvCmHrKxQ',
    createdAt: '2022-02-18T07:42:23.978Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3167,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oxcnDKSpiz3PD2Twh',
    createdAt: '2022-02-18T07:42:23.993Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 856,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'S6iZiqpva9dRmXYPX',
    createdAt: '2022-02-18T07:45:34.727Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 26.5,
    },
  },
  {
    _id: 'MaGbSKgv6wRhrfaGr',
    createdAt: '2022-02-18T07:46:15.284Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23194.64425,
    },
  },
  {
    _id: 'h5ApDtvL8io5Dfofy',
    createdAt: '2022-02-18T08:11:01.349Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 701.5,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'BcG8geKb7evrWPoNk',
    createdAt: '2022-02-18T08:11:01.367Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8865,
    },
    transactionType: 'transferOut',
    amount: -116,
  },
  {
    _id: 'j7cjNjyETbTQrfsiy',
    createdAt: '2022-02-18T08:11:01.384Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3167,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'PeWeAK9ZdLCP2cF7W',
    createdAt: '2022-02-18T08:11:01.408Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 871,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DJH2sLkzySzgaTmaM',
    createdAt: '2022-02-18T08:13:32.505Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 501.5,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'mooaXKF5cp6LGGRAD',
    createdAt: '2022-02-18T08:13:32.519Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8749,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'P6mxbLmRFXeNwGcX6',
    createdAt: '2022-02-18T08:13:32.537Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 861,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'PBng3CMchyKQQtqoi',
    createdAt: '2022-02-18T08:27:01.563Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 394,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'CKfRKSuzTrZLZ8bw6',
    createdAt: '2022-02-18T08:27:01.579Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8694,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'fMJHmFLkH2tB7qyuM',
    createdAt: '2022-02-18T08:27:01.596Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 856,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'qSPaDwaidFbZmg8e3',
    createdAt: '2022-02-18T08:27:51.920Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 352,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'PNaLgwytcvjGEWNHw',
    createdAt: '2022-02-18T08:27:51.937Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8652,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'Kpju2wte2avK9rx6a',
    createdAt: '2022-02-18T08:27:51.950Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 852,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'pvS698LGLDnYhqYdA',
    createdAt: '2022-02-18T10:43:18.593Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 490.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'NxL8LdwQRGDnQ4Buv',
    createdAt: '2022-02-18T10:43:18.620Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 177.39999999999998,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'mvuPhiewWidL9kpnu',
    createdAt: '2022-02-18T10:43:18.635Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'x8NosCemztQxapq7K',
    createdAt: '2022-02-18T10:43:18.652Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3584,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'CdRi7ebec6BbE2FgG',
    createdAt: '2022-02-18T11:01:02.520Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24194.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6izjYMRfStZRfLYsr',
    createdAt: '2022-02-18T11:01:02.539Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 19287.780000000006,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: '8hJB7xLqmdQj96dYE',
    createdAt: '2022-02-18T11:01:02.554Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2906.5500000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rCYGmxYwmYb77grjD',
    createdAt: '2022-02-18T11:01:02.569Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 30,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'YnsjerJJ36rT2j9X6',
    createdAt: '2022-02-18T11:01:02.585Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7998.559999999998,
    },
    transactionType: 'transferOut',
    amount: -2.4,
  },
  {
    _id: 'JRZT6uBnnoeEYmMWf',
    createdAt: '2022-02-18T11:01:02.602Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18445.5,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ntXQnmuhm8gnjsEGz',
    createdAt: '2022-02-18T11:01:02.638Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 52.49999999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'EMZRQeZZ22CprSoS7',
    createdAt: '2022-02-18T11:01:02.656Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4046,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'GrT4Qtb6GWjX63DCu',
    createdAt: '2022-02-18T11:01:02.699Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 440.5,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: 'RXKtRFHw3NoEh97rW',
    createdAt: '2022-02-18T11:03:22.695Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 790.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'xmvpf8nXGJoKvNhkJ',
    createdAt: '2022-02-18T11:03:22.723Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 177.39999999999998,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'XLg6rBSpkrnaw643T',
    createdAt: '2022-02-18T11:03:22.739Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Wo4uzGnddEAX7Kd5u',
    createdAt: '2022-02-18T11:03:22.755Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3584,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'rLi9ARK6bhYRH9XYv',
    createdAt: '2022-02-18T11:21:24.313Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 17287.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'uj6GdxP8mFzgwtDS7',
    createdAt: '2022-02-18T11:21:24.327Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qcqFY8dgpCByQabXd',
    createdAt: '2022-02-18T11:21:24.340Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24194.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CX6LTHujZ6tSfDhzD',
    createdAt: '2022-02-18T11:21:24.353Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2906.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'YiKC8f4sFspsaEJbu',
    createdAt: '2022-02-18T11:21:24.367Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'qciGCJxKtrgqWshmt',
    createdAt: '2022-02-18T11:21:24.389Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18430.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ysNNedkAPwmBdmYb4',
    createdAt: '2022-02-18T11:21:24.402Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 12,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'TX4F7GRTSEDDA5Y7q',
    createdAt: '2022-02-18T11:21:24.415Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 51.49999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.7,
  },
  {
    _id: '4Qxd3NfseYJynKk32',
    createdAt: '2022-02-18T11:21:24.430Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7996.159999999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'tNMCXodyLQZ7Z7CNk',
    createdAt: '2022-02-18T11:21:24.443Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4021,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'Jd2RYgruQNsegvYpZ',
    createdAt: '2022-02-18T12:23:47.798Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1060,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'vaNLytAq8HeBeGtDK',
    createdAt: '2022-02-18T12:23:47.821Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 38.7,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'aMkQzAvvDX42xx69a',
    createdAt: '2022-02-18T12:23:47.842Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 172,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'XiiEPsm2zJwT2XRFz',
    createdAt: '2022-02-18T12:23:47.861Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 379.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'a7PPFswkWykXHmYxH',
    createdAt: '2022-02-18T12:23:47.878Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 191,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'mzPTzwFcLR2rT9DTE',
    createdAt: '2022-02-18T12:23:47.897Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7995.559999999998,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '2gZTEuSnXZKCq2gn8',
    createdAt: '2022-02-18T12:23:47.916Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RqTNymFJ4TpLcA6Db',
    createdAt: '2022-02-18T12:23:47.933Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 860,
    },
    transactionType: 'transferOut',
    amount: -28,
  },
  {
    _id: 'KCjiN8H9Sr8qt2bCy',
    createdAt: '2022-02-18T12:23:47.951Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'ap9SXQppXuXyaQoB3',
    createdAt: '2022-02-18T12:23:47.977Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AQBKwSfhCjyYzvtov',
    createdAt: '2022-02-18T12:23:47.999Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1077,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: 'zBHrPEyCwmjE4dN5p',
    createdAt: '2022-02-18T13:12:34.932Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 16487.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'drzZHQLEsydbWKNWD',
    createdAt: '2022-02-18T13:12:34.948Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'taT4NnPYbzCLqmfQk',
    createdAt: '2022-02-18T13:12:34.964Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 42.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'CNNuGhoyouahqPkH9',
    createdAt: '2022-02-18T13:12:34.980Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 117438.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '7TbeQLf6iDN5JCZDp',
    createdAt: '2022-02-18T13:12:34.994Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4189.1,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'prpEe7ZzEkhk4M5vk',
    createdAt: '2022-02-18T13:12:35.032Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 860,
    },
    transactionType: 'in',
    amount: 78,
  },
  {
    _id: 'Lbf4ZBpdEYJgiFx5T',
    createdAt: '2022-02-18T13:15:49.136Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 832,
    },
  },
  {
    _id: 'nqMyKwSCQuTJQ7HuL',
    createdAt: '2022-02-18T13:16:09.586Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3560,
    },
  },
  {
    _id: '8T6EpBuYBZwkidWKv',
    createdAt: '2022-02-18T13:21:52.996Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1138,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'uQb3Knt2GfX4ZRLSx',
    createdAt: '2022-02-18T13:21:53.020Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 38.7,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'MBLJnrRkK7MvqFBve',
    createdAt: '2022-02-18T13:21:53.037Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 172,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'v3gRi3eDyidgaNEHR',
    createdAt: '2022-02-18T13:21:53.059Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 379.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'AchnihvxoYCsF3CYA',
    createdAt: '2022-02-18T13:21:53.084Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 191,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Nue5o9SDPHbehvpWm',
    createdAt: '2022-02-18T13:21:53.104Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7995.559999999998,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'vfnJBj3EXi99oBYho',
    createdAt: '2022-02-18T13:21:53.119Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RJ7QEfJXSBuiCmDzG',
    createdAt: '2022-02-18T13:21:53.136Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 960,
    },
    transactionType: 'transferOut',
    amount: -28,
  },
  {
    _id: 'PEBKk6owj6tfMB2KZ',
    createdAt: '2022-02-18T13:21:53.153Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'kC3uWghTk3YH3YozS',
    createdAt: '2022-02-18T13:21:53.171Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9J9xp8P9w2wcDAy2u',
    createdAt: '2022-02-18T13:21:53.191Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1077,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: '8TZBtaTaidD9hTF5i',
    createdAt: '2022-02-18T13:59:02.311Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2df3643e-e9c5-4580-a541-1a0e04694078_200x200.jpg?alt=media&token=a9d98cac-e13c-4892-8d30-f271d846c8c3',
        },
      },
      quantity: 2,
    },
  },
  {
    _id: 'QKxKwcS7ktwttAd69',
    createdAt: '2022-02-19T03:32:14.316Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 24194.64425,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'dqvuihGTN94CvyxZk',
    createdAt: '2022-02-19T03:32:14.335Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 41.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'LErCYFizgQytsxq2J',
    createdAt: '2022-02-19T03:32:14.361Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 116938.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'w6KCdf96GxTxGChZP',
    createdAt: '2022-02-19T03:32:14.380Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4131.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'NKGwcQ2ToCbbkpZYk',
    createdAt: '2022-02-19T03:32:14.410Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1140,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'ReyXpGaQsp3Axfebb',
    createdAt: '2022-02-19T03:32:14.439Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3947,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'ZGnyTjRi48gkSoji4',
    createdAt: '2022-02-19T03:34:39.747Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23194.64425,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'gecAf8SygayYd5fAS',
    createdAt: '2022-02-19T03:34:39.765Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 40.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'uf7L9yWkE2yeXyyEP',
    createdAt: '2022-02-19T03:34:39.785Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 116438.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'oMcjNJo98nLwroSjq',
    createdAt: '2022-02-19T03:34:39.803Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4071.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: '9Dkv65hXyuvQXhZ3j',
    createdAt: '2022-02-19T03:34:39.832Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1008,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'WZPvgibw3Jng7RLPH',
    createdAt: '2022-02-19T03:34:39.858Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3925,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'JRGNKLMgxSqY6FBFA',
    createdAt: '2022-02-19T03:36:27.870Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22194.64425,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'hX3PaXjeiEwZxDWh8',
    createdAt: '2022-02-19T03:36:27.888Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 39.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'h66gLwq5SAaDv7MAy',
    createdAt: '2022-02-19T03:36:27.907Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 115938.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'CuzRKTKAZWprvaErJ',
    createdAt: '2022-02-19T03:36:27.924Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 4023.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'd6uSt3b59EfBbTdCA',
    createdAt: '2022-02-19T03:36:27.944Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 876,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'nMxX7BQGdYg6FTeRY',
    createdAt: '2022-02-19T03:36:27.961Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3904,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Frv22BBczwcFNQ9ee',
    createdAt: '2022-02-19T04:37:00.675Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 492,
    },
  },
  {
    _id: '7qTYrrFZ36W6g9sFi',
    createdAt: '2022-02-19T05:03:08.346Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 522,
    },
  },
  {
    _id: 'hQiLt8EG8RmFKj9mS',
    createdAt: '2022-02-19T05:50:50.146Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 445.5,
    },
  },
  {
    _id: 'beF2gjTduzXRKAHjt',
    createdAt: '2022-02-19T05:52:18.162Z',
    transactionType: 'in',
    amount: 1400,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21194.64425,
    },
  },
  {
    _id: 'YMdA5LhBoda8w5xBA',
    createdAt: '2022-02-19T06:00:23.769Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 461,
    },
  },
  {
    _id: 'vjpZJMqNfT5j8k8AH',
    createdAt: '2022-02-19T06:50:07.576Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22594.64425,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'dTrZexC696neb7R6u',
    createdAt: '2022-02-19T06:50:07.591Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 15387.780000000006,
    },
    transactionType: 'transferOut',
    amount: -450,
  },
  {
    _id: 'jGLDAdCM6D6sBfmFG',
    createdAt: '2022-02-19T06:50:07.615Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 486,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'kN5QrFPpNYnPPzrA6',
    createdAt: '2022-02-19T06:50:07.639Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 371.5,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 't7FE4NLDcarrkFnuJ',
    createdAt: '2022-02-19T06:50:07.655Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1153,
    },
    transactionType: 'transferOut',
    amount: -172,
  },
  {
    _id: 'SmQHN7g9aTttHRF7F',
    createdAt: '2022-02-19T06:50:07.671Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1039,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'n2pHChxancGMN6Sc3',
    createdAt: '2022-02-19T06:55:40.065Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22494.64425,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'TH2ZqQEQscbZHws7G',
    createdAt: '2022-02-19T06:55:40.084Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2899.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KH2D78dQAWCQoZnd2',
    createdAt: '2022-02-19T06:55:40.101Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3963.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'ct9pq9EXZnbMZZcLz',
    createdAt: '2022-02-19T06:55:40.119Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 115438.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iJdFAdEd3NP9Y4asu',
    createdAt: '2022-02-19T06:55:40.135Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18621,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PY4a6AQPFpbEPbNAB',
    createdAt: '2022-02-19T06:55:40.157Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18430.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j67TtnccNuNQzELb7',
    createdAt: '2022-02-19T06:55:40.184Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 420,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8PAaRKYW57JLRb6jK',
    createdAt: '2022-02-19T06:55:40.201Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3660,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'S3rfCNGqQH2F5afRe',
    createdAt: '2022-02-19T07:13:53.123Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21994.64425,
    },
    transactionType: 'transferOut',
    amount: -240,
  },
  {
    _id: 't8r3vzgFi4fn2p6gS',
    createdAt: '2022-02-19T07:13:53.148Z',
    storeItem: {
      type: {
        _id: 'BWEYgyyXW9LZb2vaG',
        name: 'WHITE DAILY 40',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1f6ed39d-17eb-4a2f-906e-3abfb2631b6a_200x200.jpg?alt=media&token=f146a98a-a0b7-4a47-bcfe-5077a6e994e3',
        },
      },
      quantity: 50,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'F74ruXWo4hhAGXdHF',
    createdAt: '2022-02-19T07:13:53.166Z',
    storeItem: {
      type: {
        _id: 'qKCYAS77NQLBFFfji',
        name: 'CT 321',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8232fa90-3584-4984-931b-d22cd2eecdb0_200x200.jpg?alt=media&token=22ad60ce-a49d-4d5f-8ced-0d43d83110e2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Lbi6Rk7Di95Z4Eu7c',
    createdAt: '2022-02-19T07:13:53.183Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3931.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: '744CnGZgdqmAKDEGb',
    createdAt: '2022-02-19T07:13:53.203Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 744,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'qxQ7y8KA5faTmauCa',
    createdAt: '2022-02-19T07:13:53.225Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21858.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'LgL2neSAAKHsAR3m8',
    createdAt: '2022-02-19T07:13:53.248Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1681,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'LxrMZNL62SB9kHjYX',
    createdAt: '2022-02-19T08:48:14.424Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 795.5,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'P2ttbAa8sh8ytivtD',
    createdAt: '2022-02-19T08:48:14.449Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8610,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: '3eYsyzowCYS6x5PT4',
    createdAt: '2022-02-19T08:48:14.465Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 848,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'qikvegwRetzChFr78',
    createdAt: '2022-02-19T08:51:08.710Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 753.5,
    },
    transactionType: 'transferOut',
    amount: -162,
  },
  {
    _id: 'EkLZMXsN3am99QoW9',
    createdAt: '2022-02-19T08:51:08.723Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8569,
    },
    transactionType: 'transferOut',
    amount: -62,
  },
  {
    _id: 'MPdF2hSacnQLvF7p9',
    createdAt: '2022-02-19T08:51:08.739Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3165,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'fHciq3jrohSkkKWNR',
    createdAt: '2022-02-19T08:51:08.754Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 844,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'DoZRzkT85tLLLH4En',
    createdAt: '2022-02-19T09:11:19.732Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 938,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ipA7LWwxGmbhAyqTG',
    createdAt: '2022-02-19T09:11:19.759Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 37.5,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'LxpLn5D8vESPQFhdF',
    createdAt: '2022-02-19T09:11:19.776Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 171.2,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'vmHew8sjtgPm9tT2X',
    createdAt: '2022-02-19T09:11:19.793Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 368.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '3yfcunvfBtEfmh2rL',
    createdAt: '2022-02-19T09:11:19.808Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 187,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ETGGaWu9NuyMQWcFF',
    createdAt: '2022-02-19T09:11:19.825Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7989.559999999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'N8RbhMmstheQYdeAx',
    createdAt: '2022-02-19T09:11:19.840Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sy5r6yH5DjEPTuuCX',
    createdAt: '2022-02-19T09:11:19.857Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 932,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '9Pi7wo8P2T7BPJ9Bu',
    createdAt: '2022-02-19T09:11:19.874Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 176,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Ne4ycPSnvhnrXs8Qs',
    createdAt: '2022-02-19T09:11:19.895Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'c349F35JFMt2SJNCQ',
    createdAt: '2022-02-19T09:11:19.914Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1030,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'DLskqhqWZrXxpTYtM',
    createdAt: '2022-02-19T09:13:45.937Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 888,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ZsoHgNRyEu9RvkGiX',
    createdAt: '2022-02-19T09:13:45.961Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 37.1,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'rXkKAMTaugzvkck7j',
    createdAt: '2022-02-19T09:13:45.981Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 171,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'XgEHXZk75ZsSdD323',
    createdAt: '2022-02-19T09:13:46.018Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 366.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'xy5SrpBJCfZefcRum',
    createdAt: '2022-02-19T09:13:46.038Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 186.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'qpY54jX7PRFuJbAoF',
    createdAt: '2022-02-19T09:13:46.058Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7988.059999999998,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'tzMJKmBJzkF9tk2ti',
    createdAt: '2022-02-19T09:13:46.076Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SCwXSgDK3i6WMDPnW',
    createdAt: '2022-02-19T09:13:46.093Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 924,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'FNa69iAnxWQBFu3JH',
    createdAt: '2022-02-19T09:13:46.112Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 171,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'YLuReGzH95ts8JJHG',
    createdAt: '2022-02-19T09:13:46.131Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'avBKfc5PCTQ7M4vMt',
    createdAt: '2022-02-19T09:13:46.153Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1021,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'xEcWm2QrzLhZGG2qx',
    createdAt: '2022-02-19T09:14:50.291Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 838,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'KFCLPECtWTqkury3g',
    createdAt: '2022-02-19T09:14:50.319Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 36.7,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '3E8f4txpRXX4SFrZt',
    createdAt: '2022-02-19T09:14:50.338Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 170.8,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '7xgDApg46PLnJeacY',
    createdAt: '2022-02-19T09:14:50.357Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 364.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'swBNXAWweaNW6YHoj',
    createdAt: '2022-02-19T09:14:50.375Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 181.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'D93azaLEjipXKz7mE',
    createdAt: '2022-02-19T09:14:50.390Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7973.059999999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'zHkPxNZaH7s8hrecR',
    createdAt: '2022-02-19T09:14:50.409Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'udh5eA7mszkoqAJPB',
    createdAt: '2022-02-19T09:14:50.425Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 916,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'zBiHC6hADeBiSfquS',
    createdAt: '2022-02-19T09:14:50.441Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 166,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'nuCmaNZPWAjyb5Nr4',
    createdAt: '2022-02-19T09:14:50.456Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Fw5A7hdppxaxQYdQE',
    createdAt: '2022-02-19T09:14:50.476Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1011,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'vKsNgqr4MubwtPiyw',
    createdAt: '2022-02-19T09:16:12.190Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 788,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '9coyEASYnjzJtXz34',
    createdAt: '2022-02-19T09:16:12.216Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 36.300000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'Lh4kNkPnHtLaF2hun',
    createdAt: '2022-02-19T09:16:12.235Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 170.60000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'xPAPMoFqbYFg66cuG',
    createdAt: '2022-02-19T09:16:12.254Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 362.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2uMoC9B6gjo2YQAuK',
    createdAt: '2022-02-19T09:16:12.272Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 181,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'rQjJnZsdH4hAFavhJ',
    createdAt: '2022-02-19T09:16:12.288Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7971.559999999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'MxKHgGbzr9jAGPK9o',
    createdAt: '2022-02-19T09:16:12.305Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eQX4eRZX3zeAj2Kxh',
    createdAt: '2022-02-19T09:16:12.326Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 908,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '9xhavmYsucYX6th5L',
    createdAt: '2022-02-19T09:16:12.345Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 161,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'ytduLum4nnSXNFDbW',
    createdAt: '2022-02-19T09:16:12.367Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EpcADdmGBdXdJGrTX',
    createdAt: '2022-02-19T09:16:12.392Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1001,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'W6FThq2ddzxmEj2Dw',
    createdAt: '2022-02-19T09:17:39.818Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 738,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'goe5nHWyzevpeGrw8',
    createdAt: '2022-02-19T09:17:39.842Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 35.900000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'KDFcAkcQKaYpRK7hK',
    createdAt: '2022-02-19T09:17:39.857Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 170.20000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'A3sHQd5ZpTGWgGKsx',
    createdAt: '2022-02-19T09:17:39.873Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 360.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ousRm3wWJFXJSWzZF',
    createdAt: '2022-02-19T09:17:39.890Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 180.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '4WguTxJHMKkK5D8Bw',
    createdAt: '2022-02-19T09:17:39.910Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7970.059999999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'vnuZAQEtGa79XFzo8',
    createdAt: '2022-02-19T09:17:39.927Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LrG62WquYAoeg5Cnd',
    createdAt: '2022-02-19T09:17:39.951Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 900,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'dyumRaLcP8JfDnugR',
    createdAt: '2022-02-19T09:17:39.968Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 156,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'pcDHoEZxNPpCKGRPZ',
    createdAt: '2022-02-19T09:17:39.989Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wCcNPyxjcYE4466MS',
    createdAt: '2022-02-19T09:17:40.026Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 992,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'TT5DanMokhPfGAoDz',
    createdAt: '2022-02-19T09:18:46.749Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 688,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'cq6SvXNPjkcL7yM9K',
    createdAt: '2022-02-19T09:18:46.776Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 35.50000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'Li5pumBMGXxjJbEWx',
    createdAt: '2022-02-19T09:18:46.794Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 170.00000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'y59Zn8gGcpRnhjWmb',
    createdAt: '2022-02-19T09:18:46.826Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 358.5,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 's5vL3j8hNzBgHJJ6u',
    createdAt: '2022-02-19T09:18:46.849Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 180,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'mvpP4yiFK8qPR4JoW',
    createdAt: '2022-02-19T09:18:46.872Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7968.559999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '8YGbeePHAvHMdRZzP',
    createdAt: '2022-02-19T09:18:46.894Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TzMrjC4pYNxf9RazY',
    createdAt: '2022-02-19T09:18:46.925Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 892,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'fC8d9YLApyb9fYApG',
    createdAt: '2022-02-19T09:18:46.948Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 151,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'td5Noo882kESg3EZx',
    createdAt: '2022-02-19T09:18:46.969Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LnLy6z53k4AZigy3w',
    createdAt: '2022-02-19T09:18:47.010Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 983,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '8yxD5bsMMt6SaY2jF',
    createdAt: '2022-02-19T10:20:47.937Z',
    transactionType: 'transferIn',
    amount: 235,
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 304.9,
    },
  },
  {
    _id: 'Db5QS3K675YwXhmFa',
    createdAt: '2022-02-19T10:21:34.708Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1676,
    },
  },
  {
    _id: 'Sv2B86KoBPm5QvGr2',
    createdAt: '2022-02-19T10:27:00.354Z',
    transactionType: 'transferIn',
    amount: 600,
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 465,
    },
  },
  {
    _id: 'Bb3wHw28a2HXMXyTM',
    createdAt: '2022-02-19T11:05:00.696Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 983,
    },
  },
  {
    _id: 'nY6oSbLBip8QgnrW8',
    createdAt: '2022-02-19T11:05:56.585Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2887.5500000000006,
    },
  },
  {
    _id: 'w9E2wTjSxHhYuf7Hb',
    createdAt: '2022-02-19T11:06:25.182Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 839,
    },
  },
  {
    _id: '4giNsvb5cDxJpZeTX',
    createdAt: '2022-02-19T11:06:56.449Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127,
    },
  },
  {
    _id: 'iK5EL6Woxqq6gKg37',
    createdAt: '2022-02-19T11:07:33.821Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 3882,
    },
  },
  {
    _id: '7ovt9afZFT25ZMpZK',
    createdAt: '2022-02-19T11:08:02.663Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Wbdypw9eK7K5BZmeE',
    createdAt: '2022-02-19T11:08:02.688Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 892,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'ycSgAjMx5i56TauAf',
    createdAt: '2022-02-19T11:08:02.707Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 170.00000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'je2iLh2fwvCGPEPqv',
    createdAt: '2022-02-19T11:08:02.735Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3062.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'TyxMNTadsJDKSWF6N',
    createdAt: '2022-02-19T11:08:02.778Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: '6ZgqidZHszMyPSQjE',
    createdAt: '2022-02-19T11:12:49.251Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 14937.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Zn9qcbWmpNE5rtyPE',
    createdAt: '2022-02-19T11:12:49.267Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fcGXpPHrP53Md9GAF',
    createdAt: '2022-02-19T11:12:49.281Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3059.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'cMtd32vf2A2sGJXhT',
    createdAt: '2022-02-19T11:12:49.298Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'AnPYPtrSmzGccEdmj',
    createdAt: '2022-02-19T11:12:49.315Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7968.559999999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'SuDixNftdkJSzY5bM',
    createdAt: '2022-02-19T11:12:49.328Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 115438.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '4DAnngBjxoa5uEu6Q',
    createdAt: '2022-02-19T11:12:49.347Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 1065,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'CLFmpA53b4DQ234Qi',
    createdAt: '2022-02-19T11:12:49.362Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1976,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'v255u7MXAhyYSN5dy',
    createdAt: '2022-02-19T11:12:49.379Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1073,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'kDDeMqJgJpDD26mHr',
    createdAt: '2022-02-19T11:16:41.128Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'R6aGJK2GxFuJe2tfb',
    createdAt: '2022-02-19T11:16:41.149Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 875,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'ugfCkQtggZvd2gDc3',
    createdAt: '2022-02-19T11:16:41.163Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 169.75000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '73PXecHK8fH6BFCaa',
    createdAt: '2022-02-19T11:16:41.175Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3009.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ftYK87DWTn9BB2hpm',
    createdAt: '2022-02-19T11:16:41.210Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'ZvfWbevkStHYPCpjk',
    createdAt: '2022-02-19T12:01:28.782Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 981,
    },
  },
  {
    _id: 'F8HQQkzrCodEnzfqr',
    createdAt: '2022-02-19T13:38:35.383Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 14687.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'udPfjk3iS2hCybMqm',
    createdAt: '2022-02-19T13:38:35.400Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WPbhvhFHbmY3QFtPe',
    createdAt: '2022-02-19T13:38:35.416Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jC6f2WP2GrBoQ3JYb',
    createdAt: '2022-02-19T13:38:35.432Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3006.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'pnFQLjLTyBu6F7SzP',
    createdAt: '2022-02-19T13:38:35.449Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 147,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'JQq5axhgiJZFGjfje',
    createdAt: '2022-02-19T13:38:35.469Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18430.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QuSoN7f4ow5bRtHRN',
    createdAt: '2022-02-19T13:38:35.485Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 11,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'jH8mivL4XfHT9bSra',
    createdAt: '2022-02-19T13:38:35.502Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 50.79999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.7,
  },
  {
    _id: 'quk8pGJZm7dtarHqA',
    createdAt: '2022-02-19T13:38:35.522Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7965.559999999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'xji7uCKG8Tpci8xzd',
    createdAt: '2022-02-19T13:38:35.540Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4162,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'Kb99n3brdMGXCt3qg',
    createdAt: '2022-02-19T16:03:38.857Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 13887.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: '4BskNhgHcxWdZXhKb',
    createdAt: '2022-02-19T16:03:38.875Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MXM9oPQ2FcgAE7rSm',
    createdAt: '2022-02-19T16:03:38.893Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 38.7,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'sch4J52TRtx8J98z2',
    createdAt: '2022-02-19T16:03:38.908Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 113138.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'ALXFfA8Kx4bB6xr7v',
    createdAt: '2022-02-19T16:03:38.923Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3917.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'CWqyKaHivepX8sPvG',
    createdAt: '2022-02-19T16:03:38.960Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 688,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'wAYWHRFTXdkxebenh',
    createdAt: '2022-02-19T16:03:54.011Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 12787.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1050,
  },
  {
    _id: 'gmzw727W4gibFMPMd',
    createdAt: '2022-02-19T16:03:54.031Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gF2zEiXu6uarGyst5',
    createdAt: '2022-02-19T16:03:54.045Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 38.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'SHrsA5id3u4xLgQ4G',
    createdAt: '2022-02-19T16:03:54.059Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 112638.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '6AJX7uYwksAzWzczE',
    createdAt: '2022-02-19T16:03:54.075Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3859.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'eEfYjjnoPGDYY97f3',
    createdAt: '2022-02-19T16:03:54.116Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 768,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'J8S6PCDRWvxAZLj5c',
    createdAt: '2022-02-19T16:05:43.183Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 11737.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'X5Y8xKrFESXsWaYmo',
    createdAt: '2022-02-19T16:05:43.207Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'msQaALJgGtuJziYC2',
    createdAt: '2022-02-19T16:05:43.221Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 37.7,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'emXF43aDnjC4cbv7v',
    createdAt: '2022-02-19T16:05:43.236Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 112138.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'aaNRiHRiZiYeeueB7',
    createdAt: '2022-02-19T16:05:43.249Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3801.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'szRtcremgJidSpSKm',
    createdAt: '2022-02-19T16:05:43.280Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 848,
    },
    transactionType: 'in',
    amount: 77,
  },
  {
    _id: 'QQKWhMoNkiYe3emFA',
    createdAt: '2022-02-19T16:09:44.329Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 10637.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: '2FyNT4SHTTrQW3e2i',
    createdAt: '2022-02-19T16:09:44.348Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i79QD3ZrsaGHmu37a',
    createdAt: '2022-02-19T16:09:44.363Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 37.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'oZiteN68HjHdpdrBQ',
    createdAt: '2022-02-19T16:09:44.376Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 111638.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'Rkxu43czdSxv3s6Ei',
    createdAt: '2022-02-19T16:09:44.394Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3743.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'ujdfxw3KrGkPjin86',
    createdAt: '2022-02-19T16:09:44.430Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 925,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'oiLEDe9RY7485sAun',
    createdAt: '2022-02-19T16:10:35.347Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 9537.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: '7x2LysqT8BywH5KLb',
    createdAt: '2022-02-19T16:10:35.367Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'joYPHt3YBTWsoXesD',
    createdAt: '2022-02-19T16:10:35.385Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 36.7,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'o9RuDnDsxpjmHgdv4',
    createdAt: '2022-02-19T16:10:35.404Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 111138.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'Gwr6wWJv9fvpWeDRa',
    createdAt: '2022-02-19T16:10:35.423Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3685.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'wvELnsmh7stEsq6fN',
    createdAt: '2022-02-19T16:10:35.463Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1005,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'ob67bJBWFMZmwGj2u',
    createdAt: '2022-02-20T14:23:50.327Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 8437.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: '3tKQAYwhAjji3xpAr',
    createdAt: '2022-02-20T14:23:50.342Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wFgnXirDF4uN9JoDR',
    createdAt: '2022-02-20T14:23:50.356Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 36.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'daeay6WdFMsYWDBJr',
    createdAt: '2022-02-20T14:23:50.370Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 110638.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'vXdwrPLSx7SxDrPxb',
    createdAt: '2022-02-20T14:23:50.383Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3627.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: '4k2qjRtCbRkQJt2YB',
    createdAt: '2022-02-20T14:23:50.418Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1085,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'B6w6pookQkkv69WSz',
    createdAt: '2022-02-20T14:26:31.429Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 7337.780000000006,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'gESqerfWe3opvZxAr',
    createdAt: '2022-02-20T14:26:31.446Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SN8yhEXM7Z2QXasn7',
    createdAt: '2022-02-20T14:26:31.462Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Y3FeWcBBBRtbf7gx4',
    createdAt: '2022-02-20T14:26:31.480Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2999.5500000000006,
    },
    transactionType: 'transferOut',
    amount: -18.75,
  },
  {
    _id: 'yEkTuaCsE7kGCAdSJ',
    createdAt: '2022-02-20T14:26:31.505Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3569.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'Gw7um7R2csMzyzLgN',
    createdAt: '2022-02-20T14:26:31.531Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 110138.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'wJcBnCHe6BWWCw3S2',
    createdAt: '2022-02-20T14:26:31.546Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18621,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'eXyX65be8WE28cY7g',
    createdAt: '2022-02-20T14:26:31.566Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 583,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'T8XvK79acKwjayytE',
    createdAt: '2022-02-20T14:26:31.584Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 420,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '3RbbpN2C9wE64p7FY',
    createdAt: '2022-02-20T14:26:31.602Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3620,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '4qFAfAGivga5jRcWp',
    createdAt: '2022-02-20T14:27:04.488Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1940,
    },
    transactionType: 'transferOut',
    amount: -765,
  },
  {
    _id: 'Nj3e7nvjSkmT2aqok',
    createdAt: '2022-02-20T14:27:04.503Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7964.959999999997,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'CedXnzue4Tkvh7ucd',
    createdAt: '2022-02-20T14:27:04.519Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 109838.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: 'Rdx43rTS2HPTHhTF7',
    createdAt: '2022-02-20T14:27:04.539Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18430.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RQsAx56WKN84rsuC7',
    createdAt: '2022-02-20T14:27:04.575Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 220,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'CB5oNP23ybJ6rxZb3',
    createdAt: '2022-02-21T04:47:30.665Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'fNsfCrZSm7gGJX9fS',
    createdAt: '2022-02-21T05:00:14.750Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YHXNtaTNzf2Dw7gew',
    createdAt: '2022-02-21T05:00:14.770Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6687.780000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'HML8Fmo3TaP4KWjBW',
    createdAt: '2022-02-21T05:00:14.788Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gDFCYcaSvk2mL9jA8',
    createdAt: '2022-02-21T05:00:14.804Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2980.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sbEA4Wo3m4nLWWjRK',
    createdAt: '2022-02-21T05:00:14.824Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 79,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'XtM33pZoCkDthg34t',
    createdAt: '2022-02-21T05:00:14.844Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7929.959999999997,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'SDEzCBeJoaMoPegJb',
    createdAt: '2022-02-21T05:00:14.857Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3438,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: 'H4K2iEk4axmWD4RYz',
    createdAt: '2022-02-21T05:02:45.136Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6187.780000000006,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'NHyo3CQbzNimRpTA9',
    createdAt: '2022-02-21T05:02:45.157Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HkGTLNeLk2nRR3jnG',
    createdAt: '2022-02-21T05:02:45.175Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Sf9wToLYR9pfN4uaH',
    createdAt: '2022-02-21T05:02:45.189Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2980.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'LwtxwtHKFsevdFqED',
    createdAt: '2022-02-21T05:02:45.210Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3542.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'y9ARerqMoSgH5LCLE',
    createdAt: '2022-02-21T05:02:45.228Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 107038.4,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: 't89rnbSEkKZ6Cci7p',
    createdAt: '2022-02-21T05:02:45.244Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18597,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'sDioxW7mt3zu9hsmr',
    createdAt: '2022-02-21T05:02:45.258Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 571,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '5TybftMRF52x2YE9t',
    createdAt: '2022-02-21T05:02:45.274Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 1020,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'Eugwi5itrTa6RYk6M',
    createdAt: '2022-02-21T05:02:45.295Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3570,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'JwSvfa23axoS5xkS8',
    createdAt: '2022-02-21T07:45:10.646Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5537.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'hR6AmnazyDRwpb5F5',
    createdAt: '2022-02-21T07:45:10.672Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LSpdhrqvtCibxruW3',
    createdAt: '2022-02-21T07:45:10.715Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2960.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'gfEtJfnZH7zXTHHYp',
    createdAt: '2022-02-21T07:45:10.736Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'mPzMLDJTMeRAX7H9x',
    createdAt: '2022-02-21T07:45:10.795Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7929.459999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'MkgndCz9yZMyfyw5x',
    createdAt: '2022-02-21T07:45:10.818Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 106718.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'rh9EnGtmQjBYYFfHo',
    createdAt: '2022-02-21T07:45:10.845Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 1047,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'Pn2vE7Q3ssxMxDMjT',
    createdAt: '2022-02-21T07:45:10.873Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1958,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'dzkx278od5Fof46kx',
    createdAt: '2022-02-21T07:45:10.898Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1060,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'N2xdCwi33qR769Dos',
    createdAt: '2022-02-21T07:57:20.751Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5287.780000000006,
    },
    transactionType: 'transferOut',
    amount: -420,
  },
  {
    _id: '97ve79yiRjJYo8Ccd',
    createdAt: '2022-02-21T07:57:20.767Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KdFPxoWXN7nmEoybm',
    createdAt: '2022-02-21T07:57:20.782Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4LesQXzyTjPbi2RbJ',
    createdAt: '2022-02-21T07:57:20.797Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' MYSTARTER Y812 100 MU (YOFLEX@EXPRESS 1.0)',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 29,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'eaCYAAxo9fx6m5ZTC',
    createdAt: '2022-02-21T07:57:20.813Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 50.09999999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CX3SqDqrJaFbtrrpF',
    createdAt: '2022-02-21T07:57:20.843Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18430.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'iBdZraNfykAwvtYKj',
    createdAt: '2022-02-21T07:57:20.866Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7926.459999999997,
    },
    transactionType: 'transferOut',
    amount: -0.75,
  },
  {
    _id: 'CFbZ7mQhjkX8yZtYC',
    createdAt: '2022-02-21T07:57:20.882Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3407,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'GNDzk2Q28QnFSyDm4',
    createdAt: '2022-02-21T08:01:04.550Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21754.64425,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'dFYgtYcdnf2LWM9ET',
    createdAt: '2022-02-21T08:01:04.568Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2910.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wY83fR3CbmSeCEGjw',
    createdAt: '2022-02-21T08:01:04.583Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3497.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: '7hTxhwEiTiQZCbA8P',
    createdAt: '2022-02-21T08:01:04.598Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18577,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8AJTBxCYQcpyXKJnG',
    createdAt: '2022-02-21T08:01:04.616Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 910,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'hnaL7PcdDx9TSTHQX',
    createdAt: '2022-02-21T08:01:04.636Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3520,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'BXso7AJiyqNNkkyrq',
    createdAt: '2022-02-21T08:25:41.040Z',
    transactionType: 'transferIn',
    amount: 21.6,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 159.39999999999998,
    },
  },
  {
    _id: 'y6HeCMyn7TEyJnvtC',
    createdAt: '2022-02-21T08:26:43.301Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7925.709999999997,
    },
  },
  {
    _id: 'HLphLyWzESp5e4zaQ',
    createdAt: '2022-02-21T08:27:18.394Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3381,
    },
  },
  {
    _id: 'AZYB3bTX6EYa9s88t',
    createdAt: '2022-02-21T09:58:59.273Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 370,
    },
  },
  {
    _id: 'jKpfXAFcvxvF66pLc',
    createdAt: '2022-02-21T10:34:18.965Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 591.5,
    },
  },
  {
    _id: 'cYnYQphtJnebDYGWH',
    createdAt: '2022-02-21T10:38:35.134Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 941.5,
    },
    transactionType: 'transferOut',
    amount: -229,
  },
  {
    _id: 'xXzWzq4ZQLCH82Kb9',
    createdAt: '2022-02-21T10:38:35.150Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8507,
    },
    transactionType: 'transferOut',
    amount: -93,
  },
  {
    _id: 'eTckbgutQt6ooGoX5',
    createdAt: '2022-02-21T10:38:35.166Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3163,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'FmTcirzXXDPi3ZHW8',
    createdAt: '2022-02-21T10:38:35.188Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 899,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '9hdYTz6etgtujBJN8',
    createdAt: '2022-02-21T10:39:37.763Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 712.5,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'j9cCwdcrJ59E8u2fd',
    createdAt: '2022-02-21T10:39:37.779Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8414,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'XNRBP5TBe64jikPdy',
    createdAt: '2022-02-21T10:39:37.790Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 891,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '4p8qFyfZYFjzxWJ7m',
    createdAt: '2022-02-21T10:44:31.902Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 519,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'KDkWWySkZepQ2vD82',
    createdAt: '2022-02-21T10:44:31.916Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 857,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'R6ZTZ5KrLapvNwRJH',
    createdAt: '2022-02-21T10:44:31.931Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 169.50000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'iodFFPzoEEGKJt8aX',
    createdAt: '2022-02-21T10:44:31.944Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2900.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'phKYKWy5FZQZkGb4L',
    createdAt: '2022-02-21T10:44:31.979Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'RM9PrXYmxM8qoQRvT',
    createdAt: '2022-02-21T11:21:46.182Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 653.5,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'GeKsnwmjCuxdCqh4t',
    createdAt: '2022-02-21T11:21:46.195Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 7.1,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Pb7bBtQHFcmjNYwuM',
    createdAt: '2022-02-21T11:21:46.228Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: 'eNyhjHEytX3yXDMny',
    createdAt: '2022-02-21T11:34:27.464Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 300,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: '9P8ANRJXJ5sm7epoz',
    createdAt: '2022-02-21T11:34:27.488Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8355,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'SRJYyjgva6ocX3y4T',
    createdAt: '2022-02-21T11:34:27.507Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 886,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Gopiji7creoW8KZ3i',
    createdAt: '2022-02-21T11:35:18.809Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 228,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'K3H2EpConHs7M7eGM',
    createdAt: '2022-02-21T11:35:18.827Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8282,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'jP46Y9ictsQzvxZrP',
    createdAt: '2022-02-21T11:35:18.843Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 880,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'tN8d5vSqa2bTuqFb9',
    createdAt: '2022-02-21T11:43:13.301Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 108,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '7Tud7iY2FgyW98YrC',
    createdAt: '2022-02-21T11:43:13.319Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3161,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'Hs8BayznKQtMGpPqP',
    createdAt: '2022-02-21T11:43:13.335Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 870,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Y5AaMTW4e6NGiGrub',
    createdAt: '2022-02-21T11:45:25.429Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 90,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'ugJyyareBpEw6KJX7',
    createdAt: '2022-02-21T11:45:25.445Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3124,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: '2LGat7kiQ4yCMMtda',
    createdAt: '2022-02-21T11:45:25.459Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 867,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'a33ukHBphEHuPD9Ju',
    createdAt: '2022-02-21T13:13:53.328Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 740.5,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'ayhN8h7GF8CM75qzH',
    createdAt: '2022-02-21T13:13:53.352Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 180.99999999999997,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ME8v7TGqqia52hjjc',
    createdAt: '2022-02-21T13:13:53.367Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: -12.5,
  },
  {
    _id: 'CZSu7NATRoLaqkCK7',
    createdAt: '2022-02-21T13:13:53.386Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3500,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: '2kiPjc3zZY5XMRD5Y',
    createdAt: '2022-02-21T13:17:48.859Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2897.8000000000006,
    },
  },
  {
    _id: 'hxW7Fz6TaySuXCWMS',
    createdAt: '2022-02-21T13:18:24.059Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3474,
    },
  },
  {
    _id: 'xLT9YDFcMp2pfo4Kw',
    createdAt: '2022-02-21T14:02:42.947Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4867.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'ibZqZtMEiaX8N2rZo',
    createdAt: '2022-02-21T14:02:42.962Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PbaPRSJs73k8JH22y',
    createdAt: '2022-02-21T14:02:42.977Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 35.7,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'HzrthnmXH42DkfeAu',
    createdAt: '2022-02-21T14:02:42.993Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 104418.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'kSBQmgrmndeK5DuNR',
    createdAt: '2022-02-21T14:02:43.016Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3481.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'af65f46779mqavdB8',
    createdAt: '2022-02-21T14:02:43.054Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1165,
    },
    transactionType: 'in',
    amount: 78,
  },
  {
    _id: 'LveX7m4dh9zsujP29',
    createdAt: '2022-02-21T14:03:57.278Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3767.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '2x9Pzto6vRrPqMfqa',
    createdAt: '2022-02-21T14:03:57.301Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fYTQgL7qTPsrShszX',
    createdAt: '2022-02-21T14:03:57.322Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 35.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'gj6EZx3ycr7qnoFLE',
    createdAt: '2022-02-21T14:03:57.336Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 103918.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'm2B7LxRiMjY8GkB7z',
    createdAt: '2022-02-21T14:03:57.349Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3423.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'qvKPY8qvQtGkzAdJt',
    createdAt: '2022-02-21T14:03:57.384Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1243,
    },
    transactionType: 'in',
    amount: 75,
  },
  {
    _id: 'rRABBGmM3o55apcGT',
    createdAt: '2022-02-22T04:26:17.095Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2767.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Ns89r2P7RGdMWYECr',
    createdAt: '2022-02-22T04:26:17.112Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21454.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zgtgyCvbiLz8DYL6W',
    createdAt: '2022-02-22T04:26:17.129Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3022.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'hLp8W3gbXagTd8vqY',
    createdAt: '2022-02-22T04:26:17.151Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'b6vXAaErc7bWAkxE6',
    createdAt: '2022-02-22T04:26:17.175Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8225.709999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'dC6CtYnXXLn7wRkpz',
    createdAt: '2022-02-22T04:26:17.191Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 103418.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'gih2Qert6EvMgWLRQ',
    createdAt: '2022-02-22T04:26:17.207Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 1029,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'pXN9n95RT7egNR22H',
    createdAt: '2022-02-22T04:26:17.224Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1940,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'X7xGMZeS6Qg2b3uq3',
    createdAt: '2022-02-22T04:26:17.249Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1044,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'fWPruivPLZraJWSsv',
    createdAt: '2022-02-22T04:51:54.507Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 34.7,
    },
  },
  {
    _id: 'qQfX3icBCo2FSr3xz',
    createdAt: '2022-02-22T05:19:17.137Z',
    transactionType: 'transferIn',
    amount: 10,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 109.5,
    },
  },
  {
    _id: 'yiDyHKyM8SmtHph3a',
    createdAt: '2022-02-22T05:19:52.273Z',
    transactionType: 'transferIn',
    amount: 21.6,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 160.99999999999997,
    },
  },
  {
    _id: '5PTAn95xE88hqDsmd',
    createdAt: '2022-02-22T05:34:18.852Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 451,
    },
  },
  {
    _id: 'HBXZGH6z3x7xtYKzv',
    createdAt: '2022-02-22T05:34:55.247Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1481,
    },
  },
  {
    _id: 'BtYghikz3JgidAQJ4',
    createdAt: '2022-02-22T07:06:42.159Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2517.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'apFaS5NrFDH63Week',
    createdAt: '2022-02-22T07:06:42.178Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MC4SgSfWocCMgsjtq',
    createdAt: '2022-02-22T07:06:42.194Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21454.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2qwiz4E9m8Me5M7CE',
    createdAt: '2022-02-22T07:06:42.215Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2972.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9RsCa95PiYvkuxwFb',
    createdAt: '2022-02-22T07:06:42.231Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '74uM4qmDpWB9JtWtQ',
    createdAt: '2022-02-22T07:06:42.257Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18424.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7iwQ6enbyR6ZjJ2sN',
    createdAt: '2022-02-22T07:06:42.272Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'rmKEzdGhwMiPR3L4z',
    createdAt: '2022-02-22T07:06:42.288Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 50.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'kwFyhtZWjohtDa3LZ',
    createdAt: '2022-02-22T07:06:42.306Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 358.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'TX67t3ybj54omE6SA',
    createdAt: '2022-02-22T07:06:42.323Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8222.709999999997,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'wxTaeo5MSxQ3Kejxu',
    createdAt: '2022-02-22T07:06:42.338Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4088,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: '6PPbRR2KrTASGtYE9',
    createdAt: '2022-02-22T10:20:50.292Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 839,
    },
  },
  {
    _id: 'g6sce2syMHicL9m4H',
    createdAt: '2022-02-22T10:22:34.539Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 35.50000000000001,
    },
  },
  {
    _id: 'LZXtwQ4DdBTS3xqZX',
    createdAt: '2022-02-22T10:23:48.196Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1717.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'BaXdrjLe4wuqqT6hj',
    createdAt: '2022-02-22T10:23:48.213Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21454.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5joNvypvWhhAdwozf',
    createdAt: '2022-02-22T10:23:48.229Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2972.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'RqjQvXTPhkuMrDqrp',
    createdAt: '2022-02-22T10:23:48.251Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'sjZZmmfuE59Q2qdaA',
    createdAt: '2022-02-22T10:23:48.279Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 101118.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'MZpDjDX7xa4ZM4uzS',
    createdAt: '2022-02-22T10:23:48.297Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8222.109999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'hrPbQrWFCscS5Gg43',
    createdAt: '2022-02-22T10:23:48.313Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3674,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'SPEn9cSGX9qqK5tEw',
    createdAt: '2022-02-22T11:38:36.371Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 119.5,
    },
  },
  {
    _id: 'RWZFuM5fdLy95PvGq',
    createdAt: '2022-02-22T12:35:21.502Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3610,
    },
  },
  {
    _id: 'ABpbkhmj299ZWo54d',
    createdAt: '2022-02-22T12:36:02.089Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2922.8000000000006,
    },
  },
  {
    _id: 'XvqoQXis4Ci3RLh5o',
    createdAt: '2022-02-22T12:36:37.668Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8219.709999999997,
    },
  },
  {
    _id: '7ky57rG8eCzSgywoq',
    createdAt: '2022-02-22T12:37:11.148Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3481,
    },
  },
  {
    _id: 'B7k7LCnGySiMvinqm',
    createdAt: '2022-02-22T12:38:00.860Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4088,
    },
  },
  {
    _id: 'Pa3ayXT8tYYbTWpoc',
    createdAt: '2022-02-22T14:04:59.730Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21454.64425,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'TvZRfwL57efMTBKgf',
    createdAt: '2022-02-22T14:04:59.751Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3047.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'CNtk66eMgy2HaZ6BL',
    createdAt: '2022-02-22T14:04:59.770Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3369.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'Ra2L6hB5Ecc23zZwy',
    createdAt: '2022-02-22T14:04:59.792Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18577,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'DRHNo7jRSgRyGb4EB',
    createdAt: '2022-02-22T14:04:59.809Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8719.709999999997,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'YHTgtAqdpFeZrNM5r',
    createdAt: '2022-02-22T14:04:59.827Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21853.9,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'MdywBcdCjBLpTf3Fr',
    createdAt: '2022-02-22T14:04:59.857Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 1011,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'uXkzXaBpSGTxtEDrH',
    createdAt: '2022-02-22T14:04:59.884Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1922,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'hYrrJgH33bdE9jfPT',
    createdAt: '2022-02-22T14:06:39.772Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2267.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '5bdyWcCARc6qTvaeX',
    createdAt: '2022-02-22T14:06:39.792Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WCLfCEcfL2dN7QjZY',
    createdAt: '2022-02-22T14:06:39.809Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20554.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KBoniEkqiEjpdCtAh',
    createdAt: '2022-02-22T14:06:39.827Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3022.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'AT7Er9hepBv6W9LcR',
    createdAt: '2022-02-22T14:06:39.852Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'DD6ioa7riW2dT55no',
    createdAt: '2022-02-22T14:06:39.868Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18424.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Nrjf8Q5ZMLordTnFQ',
    createdAt: '2022-02-22T14:06:39.884Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'PPYfts7zCxxCyiBTv',
    createdAt: '2022-02-22T14:06:39.901Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 50.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'q2sdYJfcmoNXJxGN7',
    createdAt: '2022-02-22T14:06:39.919Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 358.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'N8bxzygqjE36v2YcZ',
    createdAt: '2022-02-22T14:06:39.936Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8709.709999999997,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'FCEzouWMmLGF4Ew7q',
    createdAt: '2022-02-22T14:06:39.953Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4288,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'gkXG4ZBg8QZEcAfsw',
    createdAt: '2022-02-22T14:12:35.525Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20554.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fDxEgJyEn9F6pNsyC',
    createdAt: '2022-02-22T14:12:35.543Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1467.780000000006,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: 'KrHb4v2kPGoGNFcqB',
    createdAt: '2022-02-22T14:12:35.563Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'skEGenB4nqjw8gMbd',
    createdAt: '2022-02-22T14:12:35.580Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3015.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'LnGNRTQEgFANEYLci',
    createdAt: '2022-02-22T14:12:35.598Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 133,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'rJyfdWHWNyE5wS4ii',
    createdAt: '2022-02-22T14:12:35.621Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18412.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'hAsJQTGQtHEsf7hvn',
    createdAt: '2022-02-22T14:12:35.645Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3681,
    },
    transactionType: 'transferOut',
    amount: -175,
  },
  {
    _id: 'ka72JbBdWze8m3b2x',
    createdAt: '2022-02-22T14:23:18.717Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20554.64425,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'umPsqqbnogfeRatMc',
    createdAt: '2022-02-22T14:23:18.735Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3008.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -6.4,
  },
  {
    _id: 'HdG9a2bHTKcqrrwTw',
    createdAt: '2022-02-22T14:23:18.769Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3305.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'TnEZdrsCGcfSd5BMy',
    createdAt: '2022-02-22T14:23:18.786Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18557,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'H4smZX6hpWLTcaYTk',
    createdAt: '2022-02-22T14:23:18.809Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 868,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'coDgPfk2LsNkHu6PG',
    createdAt: '2022-02-22T14:23:18.828Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3810,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'p2Q59whXaTX3Ar7pc',
    createdAt: '2022-02-22T14:29:58.790Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20394.64425,
    },
    transactionType: 'transferOut',
    amount: -640,
  },
  {
    _id: 'ozbnbmvYbiNMfrXfZ',
    createdAt: '2022-02-22T14:29:58.807Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3002.4000000000005,
    },
    transactionType: 'transferOut',
    amount: -18.4,
  },
  {
    _id: 'fmqWToDyZPF9TJJEf',
    createdAt: '2022-02-22T14:29:58.826Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3293.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -27.6,
  },
  {
    _id: '4PmNtdJZrehhrkmK5',
    createdAt: '2022-02-22T14:29:58.843Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18552,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '2pDJWhYbpkF3LYY7h',
    createdAt: '2022-02-22T14:29:58.859Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18400.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'c2PKYSCEQkm27oqrB',
    createdAt: '2022-02-22T14:29:58.878Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 844,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'A5oD4Wu5dsBnWpwNo',
    createdAt: '2022-02-22T14:29:58.895Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3799,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'zzRXyFBFz5mjRRk9X',
    createdAt: '2022-02-22T14:29:58.914Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 989,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R6BujAd8TGoqbEf8f',
    createdAt: '2022-02-22T14:29:58.939Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1900,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FtQbDWtM8LNAPv2pk',
    createdAt: '2022-02-22T20:18:58.958Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 717.7800000000061,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'wFpGDuScjtvyZ7Ki4',
    createdAt: '2022-02-22T20:18:58.978Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SWqRAb3CBe8ZvWjst',
    createdAt: '2022-02-22T20:18:58.995Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 59.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '4dgXfEMkcun3eQDdE',
    createdAt: '2022-02-22T20:18:59.013Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 98818.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'DBvRbPhcj4TZWaqSB',
    createdAt: '2022-02-22T20:18:59.028Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3265.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'DBAkPAPbRMPMK422i',
    createdAt: '2022-02-22T20:18:59.064Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1318,
    },
    transactionType: 'in',
    amount: 78,
  },
  {
    _id: 'CJDKWrxWpMLtdXj2K',
    createdAt: '2022-02-22T20:19:16.778Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: -382.2199999999939,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'phgdjSM2FN8Y58xtj',
    createdAt: '2022-02-22T20:19:16.806Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LK3z2k6KeoH3DwXPM',
    createdAt: '2022-02-22T20:19:16.825Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 58.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'EjSxLJ4gBE9nPTm89',
    createdAt: '2022-02-22T20:19:16.847Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 98318.4,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'FmND3F2mCF2NACBt5',
    createdAt: '2022-02-22T20:19:16.862Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3207.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'HHZqrpEKe76Npe4H5',
    createdAt: '2022-02-22T20:19:16.904Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1396,
    },
    transactionType: 'in',
    amount: 78,
  },
  {
    _id: 'wwh6FtFzZd2zjhdZo',
    createdAt: '2022-02-23T05:23:06.459Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19754.64425,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'vhdb7TaETsjq7Y5ZT',
    createdAt: '2022-02-23T05:23:06.481Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 57.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'txmhZnR9gb23Aemwc',
    createdAt: '2022-02-23T05:23:06.505Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 98317.9,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '3e7RBCpjGyu2iTzPA',
    createdAt: '2022-02-23T05:23:06.528Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3149.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'GLFNxWsMJtZdQ5bkZ',
    createdAt: '2022-02-23T05:23:06.551Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8709.109999999997,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'DpHFzfRoAy97jKfu2',
    createdAt: '2022-02-23T05:23:06.585Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 704,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'qh57aNJooTLYNFRwt',
    createdAt: '2022-02-23T05:23:06.609Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4213,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'oDGbBTcB8WXNFgEDM',
    createdAt: '2022-02-23T05:28:50.008Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 18754.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: '3ao8QmnjjHaFELfPw',
    createdAt: '2022-02-23T05:28:50.027Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 56.7,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'v9QWygzMdxpowKcfd',
    createdAt: '2022-02-23T05:28:50.051Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 97817.9,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'W9v33kgrJYpNcBAtP',
    createdAt: '2022-02-23T05:28:50.068Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 3085.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: '3QqNFnac9sysTZaXy',
    createdAt: '2022-02-23T05:28:50.084Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8684.109999999997,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'KRKub83BA283A9Axi',
    createdAt: '2022-02-23T05:28:50.116Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 578,
    },
    transactionType: 'transferOut',
    amount: -230,
  },
  {
    _id: 'CEtdXJvuqyTXKExCt',
    createdAt: '2022-02-23T05:28:50.137Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4192,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'pwZsWLPL8kAfEcEqA',
    createdAt: '2022-02-23T05:34:38.675Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 864,
    },
  },
  {
    _id: 'WPYzRkdjFRPsM4Beu',
    createdAt: '2022-02-23T05:35:32.417Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1028,
    },
  },
  {
    _id: 'RyhcGNLcoMdAFNwoE',
    createdAt: '2022-02-23T05:36:09.784Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8161,
    },
  },
  {
    _id: 'vsg8vrDan4hapFeqM',
    createdAt: '2022-02-23T07:11:14.077Z',
    transactionType: 'in',
    amount: 1900,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: -1482.219999999994,
    },
  },
  {
    _id: 'WN5TfTj7HcK5XvN2s',
    createdAt: '2022-02-23T07:19:27.648Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16754.64425,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'W4eXkqv3J77t4k9Jn',
    createdAt: '2022-02-23T07:19:27.668Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 417.7800000000061,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '3C97CthaijLQh27jz',
    createdAt: '2022-02-23T07:19:27.685Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 476,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'ykkrS4ktJBxvCowcL',
    createdAt: '2022-02-23T07:19:27.702Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 358.5,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '8DYK5QxmdjHt5A3gR',
    createdAt: '2022-02-23T07:19:27.719Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1981,
    },
    transactionType: 'transferOut',
    amount: -205,
  },
  {
    _id: 'FKNcfw6xcBjajCjX3',
    createdAt: '2022-02-23T07:19:27.745Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1103,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'MDKEfbsi2um5f6sgM',
    createdAt: '2022-02-23T08:37:59.833Z',
    transactionType: 'transferIn',
    amount: 4000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 17.780000000006112,
    },
  },
  {
    _id: 'ATNR4qFZY2mhQa7rT',
    createdAt: '2022-02-23T08:38:33.749Z',
    transactionType: 'transferIn',
    amount: 1800,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5428.5,
    },
  },
  {
    _id: 'vG3YEjNY6wsqTLuEM',
    createdAt: '2022-02-23T10:16:29.545Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F234197e3-900e-4d94-a87b-fd9e6365d36b_200x200.jpg?alt=media&token=5c4615e1-dc1f-419a-8483-749e3eef8e0c',
        },
      },
      quantity: 22,
    },
  },
  {
    _id: 'QP9MhGcHxtFwE4uYa',
    createdAt: '2022-02-23T10:19:11.608Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 509,
    },
  },
  {
    _id: 'nL5urZiGJMAEnpDgq',
    createdAt: '2022-02-23T10:19:14.530Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 509,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'A44gqBFs6uZqPwyG3',
    createdAt: '2022-02-23T10:19:14.547Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 939,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'H5viRD5eoe7XuahLe',
    createdAt: '2022-02-23T10:19:14.562Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 169.25000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'kCatJ2PQDduTbBrBc',
    createdAt: '2022-02-23T10:19:14.579Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2984.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'xbpvT3MHu8b8CMedD',
    createdAt: '2022-02-23T10:19:14.623Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'pC6BD59XSjCTw2ZSW',
    createdAt: '2022-02-23T10:19:48.343Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4017.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: '4iWd7GYJXb8AqvsFR',
    createdAt: '2022-02-23T10:19:48.369Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LgJ9ribStToDtoJpg',
    createdAt: '2022-02-23T10:19:48.386Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 54.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'RW2HQ4tTBoGgyxugv',
    createdAt: '2022-02-23T10:19:48.401Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 97816.9,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ftjWFNZdK4zbALXNb',
    createdAt: '2022-02-23T10:19:48.416Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2955.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: '45TXTKPD4Si9MbekY',
    createdAt: '2022-02-23T10:19:48.464Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1474,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'LiSbPfoAB7WaLEPca',
    createdAt: '2022-02-23T10:20:05.308Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 436,
    },
  },
  {
    _id: 'TD68yHrZM4JrZmFuB',
    createdAt: '2022-02-23T10:22:55.207Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 353.5,
    },
  },
  {
    _id: '69W5Mc6mBxtkHnH8b',
    createdAt: '2022-02-23T10:23:18.052Z',
    transactionType: 'in',
    amount: 3550,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16654.64425,
    },
  },
  {
    _id: 'RYMNgrqptuegP2Ky3',
    createdAt: '2022-02-23T10:44:09.360Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2917.780000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'a6fTDKMD4qFZE2LeJ',
    createdAt: '2022-02-23T10:44:09.381Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20204.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WMgsK9QK5zYitktjR',
    createdAt: '2022-02-23T10:44:09.398Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2981.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'EeiuZweTqZostQmna',
    createdAt: '2022-02-23T10:44:09.417Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'KdyRc6ZkNa99LMtDG',
    createdAt: '2022-02-23T10:44:09.437Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 97816.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'b8KKxuearTG7tJ2it',
    createdAt: '2022-02-23T10:44:09.455Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8634.109999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '7KdhTEbkNcMvmmfKw',
    createdAt: '2022-02-23T10:44:09.474Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3767,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: '86c8fomCExE6fjf42',
    createdAt: '2022-02-23T10:45:26.947Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2717.780000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'HWNBefQed5fsSj5YX',
    createdAt: '2022-02-23T10:45:26.979Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QYjTwvSD7psoNGm9M',
    createdAt: '2022-02-23T10:45:27.001Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 53.7,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '2Ym2Eh846Kctsmypc',
    createdAt: '2022-02-23T10:45:27.023Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 95516.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'JzNDt3EE8reKpyFqc',
    createdAt: '2022-02-23T10:45:27.040Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2897.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'JKu3iLBrTmm2Tgm7f',
    createdAt: '2022-02-23T10:45:27.089Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1554,
    },
    transactionType: 'in',
    amount: 48,
  },
  {
    _id: 'N3RXuJaxkvfZQASmX',
    createdAt: '2022-02-23T10:47:14.670Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20204.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Hyrc2fbfJK8oS7Pva',
    createdAt: '2022-02-23T10:47:14.701Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 461,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'wuFt9aA3NrN5XzGYp',
    createdAt: '2022-02-23T10:47:14.717Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 354.5,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: '5HhxLfvwFwu3qeiDS',
    createdAt: '2022-02-23T10:47:14.733Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1776,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '6wWbhQaYsGB7fRTyC',
    createdAt: '2022-02-23T10:47:14.749Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1093,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uRc59peYNnKqhn3va',
    createdAt: '2022-02-23T10:52:11.533Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20204.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'e94uxGmDw4BHs6CTh',
    createdAt: '2022-02-23T10:52:11.588Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 460,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'E79fycAjXbkc3WXnj',
    createdAt: '2022-02-23T10:52:11.605Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 214.5,
    },
    transactionType: 'transferOut',
    amount: -0.14,
  },
  {
    _id: 'yefqPTAJYrNGPv4sn',
    createdAt: '2022-02-23T10:52:11.629Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1772,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'vYN3D2ay7iX7LnDqP',
    createdAt: '2022-02-23T10:52:11.648Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1093,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'L7PAJCZdFSSMApevg',
    createdAt: '2022-02-23T10:56:49.873Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20204.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9fykPvmoYf9HG4iiw',
    createdAt: '2022-02-23T10:56:49.905Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 460,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'DTt4y92LNjhQDHLF6',
    createdAt: '2022-02-23T10:56:49.923Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 354.36,
    },
    transactionType: 'transferOut',
    amount: -0.14,
  },
  {
    _id: 'ZPgshTAp9qp58CrzA',
    createdAt: '2022-02-23T10:56:49.938Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1772,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '4peSmPZfjPX5fXCtL',
    createdAt: '2022-02-23T10:56:49.954Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1093,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Fz4tomaxBhmKoJYWo',
    createdAt: '2022-02-23T10:59:52.405Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20204.64425,
    },
  },
  {
    _id: 'Q6DKSE2BXhAZQqAZh',
    createdAt: '2022-02-23T11:00:28.165Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 703.5,
    },
  },
  {
    _id: '5DCFEBDJPzsrhZ6qm',
    createdAt: '2022-02-23T11:08:47.477Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 1053.5,
    },
    transactionType: 'transferOut',
    amount: -254,
  },
  {
    _id: 'jn33ZSdcYSNFMYguP',
    createdAt: '2022-02-23T11:08:47.494Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8801,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'Cj6cs3vDpxFzEyeTT',
    createdAt: '2022-02-23T11:08:47.523Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3087,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'JHNZt495xBHfKEJJ5',
    createdAt: '2022-02-23T11:08:47.562Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 909,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'Sfw2conmeg2Qvt2Qb',
    createdAt: '2022-02-23T11:10:32.177Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 799.5,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'Sa9bzvyHu67w6muWK',
    createdAt: '2022-02-23T11:10:32.192Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8691,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'HK9Ww5CqwieEkP3bp',
    createdAt: '2022-02-23T11:10:32.208Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 900,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'WWAAnpiH6wabg8Awf',
    createdAt: '2022-02-23T11:14:07.885Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 731.5,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '6jdFKXxRjvtCbyfp5',
    createdAt: '2022-02-23T11:14:07.901Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 6.1,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'REWQ2y726q3mYKWmw',
    createdAt: '2022-02-23T11:14:07.940Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 72,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: 'uvD8njDCKvzDpCGam',
    createdAt: '2022-02-23T11:15:50.268Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 372,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'QSmAfFCKsA4FFuCXL',
    createdAt: '2022-02-23T11:15:50.301Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8623,
    },
    transactionType: 'transferOut',
    amount: -81,
  },
  {
    _id: 'nnc7LS7SEhmJCwi4o',
    createdAt: '2022-02-23T11:15:50.317Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 894,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'uXHnn8SP9QbM776JK',
    createdAt: '2022-02-23T11:19:48.943Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 292,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'dSvpYN4Ng7FADe9he',
    createdAt: '2022-02-23T11:19:48.979Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8542,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'pAcdkjuQCAedcxj7o',
    createdAt: '2022-02-23T11:19:49.015Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 887,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '2k4Eb3JzxXz6Nu2YF',
    createdAt: '2022-02-23T12:48:47.168Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 23204.64425,
    },
    transactionType: 'transferOut',
    amount: -660,
  },
  {
    _id: 'LzNdHETjnpGGqPWkx',
    createdAt: '2022-02-23T12:48:47.203Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2931.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'Wu6sYWfNt2oG5Chmb',
    createdAt: '2022-02-23T12:48:47.223Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2878.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '3sct7DCETLWQhYzab',
    createdAt: '2022-02-23T12:48:47.243Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18532,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'MqMDGgJoyedMGfBa8',
    createdAt: '2022-02-23T12:48:47.268Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18395.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'ch3nMXZpinAuAfhJn',
    createdAt: '2022-02-23T12:48:47.293Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 774,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'WekkBCTj5C3fx693T',
    createdAt: '2022-02-23T12:48:47.315Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3708,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'snJKkqyshYPZzheG3',
    createdAt: '2022-02-23T12:48:47.335Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 989,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '2c9zZBfzmiFqqHfHW',
    createdAt: '2022-02-23T12:48:47.354Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1900,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'waviazQAssaXCucm4',
    createdAt: '2022-02-23T12:56:12.055Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 22544.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'K9aGGirbhRvfTWgGq',
    createdAt: '2022-02-23T12:56:12.079Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2911.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'c5aAwwFdWAjGv9gK6',
    createdAt: '2022-02-23T12:56:12.096Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2838.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'AunYwmY43LwzoxQKk',
    createdAt: '2022-02-23T12:56:12.113Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18507,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'sEdXqYg7CHnrrxYGe',
    createdAt: '2022-02-23T12:56:12.132Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 624,
    },
    transactionType: 'transferOut',
    amount: -88,
  },
  {
    _id: '6xCbpfq26TdfcYHc9',
    createdAt: '2022-02-23T12:56:12.154Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3708,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'pWBfNEvszWnZ7YKDf',
    createdAt: '2022-02-23T12:58:14.946Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 21944.64425,
    },
    transactionType: 'transferOut',
    amount: -1575,
  },
  {
    _id: 'YaAWyyFrvYah4eFbi',
    createdAt: '2022-02-23T12:58:14.965Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 53.1,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'zEZpeo3We3p8kY4d2',
    createdAt: '2022-02-23T12:58:14.983Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 95216.4,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: 'TW9uuSRKQTgkRvsfx',
    createdAt: '2022-02-23T12:58:14.999Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2793.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -95,
  },
  {
    _id: 'CHRTZTTrLrutizZK8',
    createdAt: '2022-02-23T12:58:15.039Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1093,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'XPAGBP6HnzEJp9Qmz',
    createdAt: '2022-02-23T13:14:54.819Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1602,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'jLp2CnuHYQkovmmLt',
    createdAt: '2022-02-23T13:14:54.861Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 60.50000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '6NwRfpZ89L2qACxRA',
    createdAt: '2022-02-23T13:14:54.953Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 169.00000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'ukRFdA7Mt5Wqvwj9m',
    createdAt: '2022-02-23T13:14:54.974Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 354.22,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'yqc33rZawkNMcStAr',
    createdAt: '2022-02-23T13:14:54.991Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 180,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'zrm8za94ZMRH3AbFA',
    createdAt: '2022-02-23T13:14:55.305Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8631.109999999997,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'iuvpvuAwbRcJqu74j',
    createdAt: '2022-02-23T13:14:55.351Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rexX9a9CTxSfiMBs5',
    createdAt: '2022-02-23T13:14:55.468Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 921,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'pyrDotLPfuXa6tb8C',
    createdAt: '2022-02-23T13:14:55.492Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 151,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'L5jHk9EaDuGSDs9M9',
    createdAt: '2022-02-23T13:14:55.795Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r84R78bzEfoumiytn',
    createdAt: '2022-02-23T13:14:56.092Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1071,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Tds4GtYNaMStTD84K',
    createdAt: '2022-02-23T13:16:08.399Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1552,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'GnG9jthKS7cHffRk5',
    createdAt: '2022-02-23T13:16:08.424Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 60.10000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'yceocx8Maac3zeCNw',
    createdAt: '2022-02-23T13:16:08.449Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 168.80000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'Luw7tbbwteobGrfPS',
    createdAt: '2022-02-23T13:16:08.466Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 352.22,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'W82tShgaobRm6BkLf',
    createdAt: '2022-02-23T13:16:08.486Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 179.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '9Pc6s9zYYcogjzgBd',
    createdAt: '2022-02-23T13:16:08.506Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8629.609999999997,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'Z848siT5bKzNGr4gP',
    createdAt: '2022-02-23T13:16:08.528Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3KfERgCfN48a6oKau',
    createdAt: '2022-02-23T13:16:08.545Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 913,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'gCA6aMt3CJkDicwq9',
    createdAt: '2022-02-23T13:16:08.562Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 146,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'czxGZSnb4v7sbr6N7',
    createdAt: '2022-02-23T13:16:08.579Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6Cye7NtbybgkE7vwz',
    createdAt: '2022-02-23T13:16:08.604Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1061,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'piCiRLpT7Cz4584bM',
    createdAt: '2022-02-23T13:17:18.971Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1502,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ebGNsBq6F5CjMsTux',
    createdAt: '2022-02-23T13:17:18.995Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.70000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'W9D8G8PtPePDBpesW',
    createdAt: '2022-02-23T13:17:19.012Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 168.60000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'XgmQD5MzP99WDuTy9',
    createdAt: '2022-02-23T13:17:19.029Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 350.22,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'GfHNAdLdedp9EDH3G',
    createdAt: '2022-02-23T13:17:19.048Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 179,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ct6MWbxEiYmMaYtmH',
    createdAt: '2022-02-23T13:17:19.066Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8628.109999999997,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'sNQsatsBGMkZa89nL',
    createdAt: '2022-02-23T13:17:19.093Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5YYdG83oSxDJmcC8D',
    createdAt: '2022-02-23T13:17:19.111Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 904,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'REqh64BCeX4kd7bte',
    createdAt: '2022-02-23T13:17:19.128Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 146,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'pSGhqMrEmzXkdT2Jt',
    createdAt: '2022-02-23T13:17:19.148Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KYEjEKvJvW3hdq6cb',
    createdAt: '2022-02-23T13:17:19.168Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1052,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'z427womsNQXXrXi8e',
    createdAt: '2022-02-23T13:18:36.882Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1452,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '5yWaKPZcEHWRcLsoN',
    createdAt: '2022-02-23T13:18:36.917Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.30000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'SjSAH2d3v3hqX7ngR',
    createdAt: '2022-02-23T13:18:36.935Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 168.40000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'wmG6w7wwWTPXZ66vB',
    createdAt: '2022-02-23T13:18:36.953Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 348.22,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'oNjxDnvtNTjzL7csr',
    createdAt: '2022-02-23T13:18:36.971Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 178.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'sAo4Z4ZMTKS2vduwS',
    createdAt: '2022-02-23T13:18:36.987Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8626.609999999997,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'gyjDcTcMDhwPg5vqe',
    createdAt: '2022-02-23T13:18:37.004Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Jj5j27694xZiHF89c',
    createdAt: '2022-02-23T13:18:37.021Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 896,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'xkn6Dbf9sZmRMiJnE',
    createdAt: '2022-02-23T13:18:37.040Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 141,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'W7QLXcZ5smrTcqBjY',
    createdAt: '2022-02-23T13:18:37.058Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YwhG5tjNqPXJT3jrf',
    createdAt: '2022-02-23T13:18:37.081Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1042,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'dsAPTDdxHHuR3z9hb',
    createdAt: '2022-02-23T13:19:31.604Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1402,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'EBkzYx3EpnhydeZmE',
    createdAt: '2022-02-23T13:19:31.631Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 58.90000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '5CEPnKMNnA3c47BWg',
    createdAt: '2022-02-23T13:19:31.650Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 168.20000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'wAC9C3vWoYazT6pZJ',
    createdAt: '2022-02-23T13:19:31.668Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 343.22,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'mRh7vbNuXsEvmWnCE',
    createdAt: '2022-02-23T13:19:31.691Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 178,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'oTN2LLZW96TjKw2ZX',
    createdAt: '2022-02-23T13:19:31.716Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8625.109999999997,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'rvzis4pxAAd57eWtD',
    createdAt: '2022-02-23T13:19:31.741Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2ozbg5jHgP3Et6t7E',
    createdAt: '2022-02-23T13:19:31.770Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 887,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'GZvdAT64xyaoDMNdN',
    createdAt: '2022-02-23T13:19:32.048Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 136,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'nahySaWQSiiyNQDZD',
    createdAt: '2022-02-23T13:19:32.117Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wPeMM5s4XjbnpssKZ',
    createdAt: '2022-02-23T13:19:32.174Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1033,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'jq79GFnLiGAsuZqGH',
    createdAt: '2022-02-23T13:39:58.936Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zWkrEuz2y8TSpWpNh',
    createdAt: '2022-02-23T13:39:58.974Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 459,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'xpRFjj4x4QqvB7Ng8',
    createdAt: '2022-02-23T13:39:58.991Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 341.22,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '4hEzi7ywHSDmFJL6p',
    createdAt: '2022-02-23T13:39:59.013Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1768,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: '9r2gKYi5vkKKZN8tz',
    createdAt: '2022-02-23T13:39:59.030Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1024,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WwD9X9TayMYoiznsn',
    createdAt: '2022-02-24T05:44:50.340Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 131,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'qgg4Kys9KYp35GQHj',
    createdAt: '2022-02-24T05:44:50.372Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yxzGjhadJjBCPbzNG',
    createdAt: '2022-02-24T05:44:50.417Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 168.00000000000009,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'gpXLt8RdspfLRCr5d',
    createdAt: '2022-02-24T05:44:50.442Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 878,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'naswwg6i6gDYj3PdH',
    createdAt: '2022-02-24T05:44:50.465Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 549,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'eqcdpM4qeZFjwpe4C',
    createdAt: '2022-02-24T05:44:50.487Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
      },
      quantity: 62,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'D3uC5gjHCo9W2hjih',
    createdAt: '2022-02-24T05:44:50.509Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 58.500000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'k4tM2hmovqxNaMTKi',
    createdAt: '2022-02-24T05:44:50.531Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 339.22,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '3tiJZyz3hH8edotcY',
    createdAt: '2022-02-24T05:44:50.551Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8623.609999999997,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '7cXsSrgAqXTqJBSB7',
    createdAt: '2022-02-24T05:44:50.572Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1352,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'p6QaTdAeMKefpvxQ4',
    createdAt: '2022-02-24T05:44:50.595Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KrEMwpp3GAMfcnvmx',
    createdAt: '2022-02-24T05:44:50.631Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 385,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'Md2ua5s6JcFZiyeyw',
    createdAt: '2022-02-24T05:44:50.654Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 56,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '9eR6eKorJXSNLTEN9',
    createdAt: '2022-02-24T06:27:18.870Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2117.780000000006,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'LcJMteztZRQ8WBBc5',
    createdAt: '2022-02-24T06:27:18.888Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 338.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'sPFiqMXQF44fo3jMg',
    createdAt: '2022-02-24T06:27:18.914Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8623.359999999997,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Q2SvGTmrZxMvkBF2p',
    createdAt: '2022-02-24T06:27:18.955Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18385.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'vkdYeBfrWxJBH8bu5',
    createdAt: '2022-02-24T06:27:18.971Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4150,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'irerB4Q8Hr63rZJAp',
    createdAt: '2022-02-24T06:30:21.097Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2017.780000000006,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'N3XEvvTHdkLXBbETx',
    createdAt: '2022-02-24T06:30:21.118Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 333.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Wb78azcgvnLz9rxFs',
    createdAt: '2022-02-24T06:30:21.136Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8573.359999999997,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'JEGmBGj99rg94uBjT',
    createdAt: '2022-02-24T06:30:21.174Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18383.5,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'xx3w3k5hyAXrNDXP9',
    createdAt: '2022-02-24T06:30:21.189Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4140,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'c5F9Zg5ov8rGSQSer',
    createdAt: '2022-02-24T09:49:13.934Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 877.5,
    },
  },
  {
    _id: '7WrH6oKWJeFvW9rEm',
    createdAt: '2022-02-24T09:49:58.715Z',
    transactionType: 'transferIn',
    amount: 5000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 94466.4,
    },
  },
  {
    _id: 'JrnNFFFhERp8ZC4iF',
    createdAt: '2022-02-24T09:50:47.108Z',
    transactionType: 'transferIn',
    amount: 45000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 99466.4,
    },
  },
  {
    _id: 'CExWYk6oWouL3qgxY',
    createdAt: '2022-02-24T09:51:45.626Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8572.859999999997,
    },
  },
  {
    _id: 'nscwtJ3XhWT8weqmX',
    createdAt: '2022-02-24T09:52:15.125Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3506,
    },
  },
  {
    _id: 'hqAQ6no4WgyFjKebE',
    createdAt: '2022-02-24T09:52:41.378Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3668,
    },
  },
  {
    _id: 'D6wzwDSBz5qXCCnYv',
    createdAt: '2022-02-24T09:53:31.963Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 328.82000000000005,
    },
  },
  {
    _id: 'EvBNoFYecgvR27ruf',
    createdAt: '2022-02-24T09:54:17.327Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2893.0000000000005,
    },
  },
  {
    _id: '9r3bfkeSy7RwtbFSz',
    createdAt: '2022-02-24T09:55:41.109Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1024,
    },
  },
  {
    _id: 'SjeSnBZQ7iALriRrb',
    createdAt: '2022-02-24T09:56:49.401Z',
    transactionType: 'transferIn',
    amount: 440,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4130,
    },
  },
  {
    _id: 'Qfb8s3xvLdFc8NQyR',
    createdAt: '2022-02-24T09:58:49.054Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2df3643e-e9c5-4580-a541-1a0e04694078_200x200.jpg?alt=media&token=a9d98cac-e13c-4892-8d30-f271d846c8c3',
        },
      },
      quantity: 4,
    },
  },
  {
    _id: '68RNGwaT4t25Lc4kP',
    createdAt: '2022-02-24T11:16:48.126Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1337,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'H5pAJomczSaKqKPkw',
    createdAt: '2022-02-24T11:16:48.149Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.600000000000016,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'XXZRqxsjLAAGE2zp5',
    createdAt: '2022-02-24T11:16:48.177Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 167.75000000000009,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'YfY5LPu5d7529t6Ci',
    createdAt: '2022-02-24T11:16:48.204Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 428.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'DbYaj2q77Qd3LRukb',
    createdAt: '2022-02-24T11:16:48.225Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 177.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'sExkhenZCXPpcTJy2',
    createdAt: '2022-02-24T11:16:48.244Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8972.859999999997,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'rZ8nnwwN4Qbah7ZSe',
    createdAt: '2022-02-24T11:16:48.265Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 548,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eefrsgoCo6ZSnBtEa',
    createdAt: '2022-02-24T11:16:48.286Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 977.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '3rhW57dkfZmnD3xa8',
    createdAt: '2022-02-24T11:16:48.310Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zos4e8ySgksJ4ComA',
    createdAt: '2022-02-24T11:16:48.331Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AXW9XioRMYGL2M8Km',
    createdAt: '2022-02-24T11:16:48.369Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1069,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'iCGjCiBgmNLW5cyjr',
    createdAt: '2022-02-24T11:17:40.453Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1287,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'iKGZjfoeWDKMLtkHL',
    createdAt: '2022-02-24T11:17:40.491Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 167.5500000000001,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'RoTogL48m9WYCm2q9',
    createdAt: '2022-02-24T11:17:40.514Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.20000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'wc6rRRg54Lr9eGYBL',
    createdAt: '2022-02-24T11:17:40.540Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 426.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'PbEuuPDov7whzej97',
    createdAt: '2022-02-24T11:17:40.560Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 177,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ALZ4ifFmD7mJ6gJHY',
    createdAt: '2022-02-24T11:17:40.581Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8971.359999999997,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'tMjZ4dRJosxmACaAw',
    createdAt: '2022-02-24T11:17:40.612Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 548,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rWSrhW5kiwCFmz6w8',
    createdAt: '2022-02-24T11:17:40.651Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 968.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'XesCMoWHZp5ph2qoG',
    createdAt: '2022-02-24T11:17:40.692Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7XaAE2BFsALpfYLbp',
    createdAt: '2022-02-24T11:17:40.710Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DhExizFsfy5SQZzaC',
    createdAt: '2022-02-24T11:17:40.739Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1060,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'X8MZ6fEsosibrnmBH',
    createdAt: '2022-02-24T11:19:40.495Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1237,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'gezrFe2kpHRZXZRzj',
    createdAt: '2022-02-24T11:19:40.517Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 167.0500000000001,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'gzLj87QS8XFnbucNm',
    createdAt: '2022-02-24T11:19:40.537Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.000000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'DQaMP64AFH38c2DdB',
    createdAt: '2022-02-24T11:19:40.556Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 424.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'aKGamPvTLSc9MtYrJ',
    createdAt: '2022-02-24T11:19:40.580Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 176.5,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'sjqfrd47iW5E9DNe3',
    createdAt: '2022-02-24T11:19:40.598Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8970.359999999997,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'i8LMCfAv8SbboinKH',
    createdAt: '2022-02-24T11:19:40.620Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 548,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Z45mNHCMX7qPNEmGH',
    createdAt: '2022-02-24T11:19:40.650Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 958.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'SnED2Zwdbn337GfAe',
    createdAt: '2022-02-24T11:19:40.667Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'daZ3vD26SRzmYKNzs',
    createdAt: '2022-02-24T11:19:40.684Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KEYQEeqqBY8nyfp5n',
    createdAt: '2022-02-24T11:19:40.709Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1051,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'emqJBgLjHsKRZLvND',
    createdAt: '2022-02-24T11:37:25.375Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 431.5,
    },
  },
  {
    _id: 't9oHkssKN9NQotuBh',
    createdAt: '2022-02-24T11:43:39.861Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 548,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'KpsSXCAWWrJGiYqoP',
    createdAt: '2022-02-24T11:43:39.876Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 949.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'pqEMpAhvXXQGa6ZRM',
    createdAt: '2022-02-24T11:43:39.890Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 166.5500000000001,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'hWGiFh3vLMYLcnS9J',
    createdAt: '2022-02-24T11:43:39.906Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3018.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'fq52sfLHvDiK2K3u4',
    createdAt: '2022-02-24T11:43:39.950Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'z92ufjEfHS6aWztrK',
    createdAt: '2022-02-24T12:21:22.672Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2117.780000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'mi5Qu86BDSQhXuvRB',
    createdAt: '2022-02-24T12:21:22.694Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fDh7j76xK9TXt9i6K',
    createdAt: '2022-02-24T12:21:22.718Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3015.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'k58GDsiNsiP8eGWKp',
    createdAt: '2022-02-24T12:21:22.737Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'aZAhmTmeF8iE58QTn',
    createdAt: '2022-02-24T12:21:22.769Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9019.859999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'B9xhPJ43kS8fnGReP',
    createdAt: '2022-02-24T12:21:22.787Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144466.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'sDtncn5RRetAgXBuL',
    createdAt: '2022-02-24T12:21:22.806Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 969,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'qZW3EAnLFWy4TvxqK',
    createdAt: '2022-02-24T12:21:22.824Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1880,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '9bPihTcuJktjpRB5Z',
    createdAt: '2022-02-24T12:21:22.845Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1041,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'fRaiCwz3eaDghAApv',
    createdAt: '2022-02-24T12:23:03.784Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 684.5,
    },
    transactionType: 'transferOut',
    amount: -85,
  },
  {
    _id: '5RYw6HetwNwnybd4E',
    createdAt: '2022-02-24T12:23:03.815Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 182.59999999999997,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ABJqw7PrSy9xcDfWN',
    createdAt: '2022-02-24T12:23:03.841Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 139.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r5woPYjkpjwBDX5FA',
    createdAt: '2022-02-24T12:23:03.875Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3868,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zkfdJzJQsQ3FL3Jeb',
    createdAt: '2022-02-24T12:23:03.901Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4590,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'RYRTBjGmkwQfEFPfj',
    createdAt: '2022-02-25T04:47:11.437Z',
    transactionType: 'transferIn',
    amount: 65,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 325,
    },
  },
  {
    _id: 'gP6G6xG8L6jNt35nT',
    createdAt: '2022-02-25T05:14:40.070Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3086,
    },
  },
  {
    _id: 'kXtvMDagJRqtJRLaX',
    createdAt: '2022-02-25T05:47:46.361Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1026,
    },
  },
  {
    _id: 'wMqNssrcAERSs9wbE',
    createdAt: '2022-02-25T05:48:39.869Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0576faaa-f111-47e7-b288-62f0496cfdde_200x200.jpg?alt=media&token=d602135c-9365-4bdb-8ad1-43fe32445a04',
        },
      },
      quantity: 390,
    },
  },
  {
    _id: 'E897Fk4Aj3MLWyfwZ',
    createdAt: '2022-02-25T06:21:29.021Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3868,
    },
  },
  {
    _id: 'AqaSXPYyEfC5JcLZd',
    createdAt: '2022-02-25T06:21:58.347Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3706,
    },
  },
  {
    _id: 'TNRjderworjZAWgFX',
    createdAt: '2022-02-25T06:22:51.960Z',
    transactionType: 'transferIn',
    amount: 150,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2965.0000000000005,
    },
  },
  {
    _id: 'AeMWzoGv9DSjsQwYa',
    createdAt: '2022-02-25T06:23:51.655Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9016.859999999997,
    },
  },
  {
    _id: 'vfWKshrfgiDv8c9uK',
    createdAt: '2022-02-25T06:37:04.587Z',
    transactionType: 'transferIn',
    amount: 1299,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1867.780000000006,
    },
  },
  {
    _id: 'cv2fogW5AmupZzcAe',
    createdAt: '2022-02-25T06:38:30.338Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 781.5,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'LQjSv2meR5nTLf7tG',
    createdAt: '2022-02-25T06:38:30.352Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 5.1,
    },
    transactionType: 'transferOut',
    amount: -1.3,
  },
  {
    _id: '357xQ2TEekEiYYXEi',
    createdAt: '2022-02-25T06:38:30.383Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 172,
    },
    transactionType: 'in',
    amount: 400,
  },
  {
    _id: 'QY6heAgzyg2q9EJre',
    createdAt: '2022-02-25T07:00:54.580Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 877,
    },
  },
  {
    _id: 'JJcxEEtwuu33M6egd',
    createdAt: '2022-02-25T07:01:14.405Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 138,
    },
  },
  {
    _id: '8rBgYgdvaoXsw2gFt',
    createdAt: '2022-02-25T07:34:28.111Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1187,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'fu7vfKDra6Kx3ngWv',
    createdAt: '2022-02-25T07:34:28.147Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 166.3000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'PqsKKpwCNYXnSMP2E',
    createdAt: '2022-02-25T07:34:28.373Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.80000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'gvmS5Ks5FmysfFi9K',
    createdAt: '2022-02-25T07:34:28.395Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 432.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'H97dDXfDotYMgfK3o',
    createdAt: '2022-02-25T07:34:28.421Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 176.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'wRYJX9WJrnwbRZm6L',
    createdAt: '2022-02-25T07:34:28.441Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9516.859999999997,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GwGpeBWmfCE2FLDzu',
    createdAt: '2022-02-25T07:34:28.461Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 538,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xWJxZJWoYMb9LNvHs',
    createdAt: '2022-02-25T07:34:28.479Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 932.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'SjRQPYLWeqmXkGfHQ',
    createdAt: '2022-02-25T07:34:28.504Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8H9wddgBNihJnvBHA',
    createdAt: '2022-02-25T07:34:28.531Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6AmtnAr5T2YJ4PhrQ',
    createdAt: '2022-02-25T07:34:28.555Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1086,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'zadeaMjeZ8jRochke',
    createdAt: '2022-02-25T07:37:18.142Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1137,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'xSkNDrNRWcGryjGZZ',
    createdAt: '2022-02-25T07:37:18.175Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 165.9000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'RMrv34weKXJafojXG',
    createdAt: '2022-02-25T07:37:18.200Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.60000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'pEBH73Eg2bE4ZwomB',
    createdAt: '2022-02-25T07:37:18.223Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 430.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'D64FKePwmoRW9aZ2L',
    createdAt: '2022-02-25T07:37:18.245Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 175.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'A6q2BxgzRhdG7bBqd',
    createdAt: '2022-02-25T07:37:18.265Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9515.859999999997,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'NZYEHoRKraSkxTLYn',
    createdAt: '2022-02-25T07:37:18.286Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 538,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FzGAt5bwsoDXdPq5k',
    createdAt: '2022-02-25T07:37:18.307Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 923.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'gYpdaA2ZXJTYguZNQ',
    createdAt: '2022-02-25T07:37:18.326Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3Y25qrZui8jN6J3z4',
    createdAt: '2022-02-25T07:37:18.344Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SCuxF2YbgRcgor3gD',
    createdAt: '2022-02-25T07:37:18.368Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1077,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'D34MQeRn6ZT2btPPy',
    createdAt: '2022-02-25T07:48:47.968Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tGkwPEWGf3NMYTgcZ',
    createdAt: '2022-02-25T07:48:47.988Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3166.780000000006,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'EAYHKbm6jZthbdC5R',
    createdAt: '2022-02-25T07:48:48.022Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hToRmA5D7byRsnqtW',
    createdAt: '2022-02-25T07:48:48.040Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3115.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'HfLFzcBkik5Ljqw84',
    createdAt: '2022-02-25T07:48:48.057Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 551,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cJw5YKmEDEuq3vgct',
    createdAt: '2022-02-25T07:48:48.073Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hg3vvpNFanb4Ac2Gq',
    createdAt: '2022-02-25T07:48:48.089Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 142166.4,
    },
    transactionType: 'transferOut',
    amount: -450,
  },
  {
    _id: 'BdWXiHK6wSAk6mrX6',
    createdAt: '2022-02-25T07:48:48.111Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2698.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'L958LyrCPQso8LDyL',
    createdAt: '2022-02-25T07:48:48.137Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 536,
    },
    transactionType: 'transferOut',
    amount: -202,
  },
  {
    _id: 'fTgmEmnZgndwzFtyz',
    createdAt: '2022-02-25T07:48:48.155Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Xq6Erm5nDnJCL4tDh',
    createdAt: '2022-02-25T07:48:48.171Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4580,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: 'LF6c6oXnmXKmheaLj',
    createdAt: '2022-02-25T07:54:12.475Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9xFjcmxxgKbBZwkHu',
    createdAt: '2022-02-25T07:54:12.494Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2266.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'nCHfy2bnjD886MWhZ',
    createdAt: '2022-02-25T07:54:12.514Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'w2ds9uhEtypbeTeSZ',
    createdAt: '2022-02-25T07:54:12.537Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3090.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'sKACc78YP6gxrCztu',
    createdAt: '2022-02-25T07:54:12.567Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 551,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TKiy4bAeBPi8FJWgq',
    createdAt: '2022-02-25T07:54:12.605Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3ecaECwdFmDpS5CbG',
    createdAt: '2022-02-25T07:54:12.620Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 141716.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'zEqeZcZjdqRuE7iru',
    createdAt: '2022-02-25T07:54:12.640Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2640.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'JAFT7mJudaz8h5mtq',
    createdAt: '2022-02-25T07:54:12.659Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 334,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'hJm39Mq7EE933SMHE',
    createdAt: '2022-02-25T07:54:12.676Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'st2oNRsPKzLq58jSr',
    createdAt: '2022-02-25T07:54:12.693Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4546,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 's6umybJujd6ioExSu',
    createdAt: '2022-02-25T07:58:55.825Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1175,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '8rsahcfYxehQCFZum',
    createdAt: '2022-02-25T07:58:55.854Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9514.859999999997,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'aZkn9S8PQyGgZT8kK',
    createdAt: '2022-02-25T07:58:55.869Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 141216.4,
    },
    transactionType: 'transferOut',
    amount: -2800,
  },
  {
    _id: '2y8PpovEffdEFR3py',
    createdAt: '2022-02-25T07:58:55.886Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18385.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jPWiF4fDuFPzeKqgB',
    createdAt: '2022-02-25T07:58:55.920Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 134,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'hktdnui9XwisnMnbi',
    createdAt: '2022-02-25T07:59:22.641Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oe853wWNeoQdQekiC',
    createdAt: '2022-02-25T07:59:22.658Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1266.780000000006,
    },
    transactionType: 'transferOut',
    amount: -950,
  },
  {
    _id: 'vHqnZQWibSfrkWRWC',
    createdAt: '2022-02-25T07:59:22.673Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CMqPfqvsEqY5SMqfX',
    createdAt: '2022-02-25T07:59:22.689Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3065.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'TpP2mJqrEnS8kNM6w',
    createdAt: '2022-02-25T07:59:22.705Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 551,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'btZHJvuuBxPJqHdRZ',
    createdAt: '2022-02-25T07:59:22.723Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j43wpzntvyF2LdBnm',
    createdAt: '2022-02-25T07:59:22.740Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 138416.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'AWrX55cnYA6cyoPJK',
    createdAt: '2022-02-25T07:59:22.756Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2586.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: '9D5JCCJCriZov2C87',
    createdAt: '2022-02-25T07:59:22.776Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 934,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'JPY7NFaCt2M6cc6AC',
    createdAt: '2022-02-25T07:59:22.796Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'X9A5Le3t4gKdwu3sj',
    createdAt: '2022-02-25T07:59:22.812Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4506,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'z2XngTSKxAn7btPLd',
    createdAt: '2022-02-25T08:02:33.458Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 316.7800000000061,
    },
  },
  {
    _id: 'ixWyHpzcZLtgRqP8X',
    createdAt: '2022-02-25T08:04:02.218Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5ATQBwso7vRpJ9Y82',
    createdAt: '2022-02-25T08:04:02.242Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1316.780000000006,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'daiQPPTukCdvPwZ7h',
    createdAt: '2022-02-25T08:04:02.264Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YzQCZCByAQD5q5yMM',
    createdAt: '2022-02-25T08:04:02.285Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3040.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'obWKPDHurdo7onHkz',
    createdAt: '2022-02-25T08:04:02.304Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 551,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g76r5eCPpdrajmRMM',
    createdAt: '2022-02-25T08:04:02.328Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5QZ9NiFu5GtAxtYCi',
    createdAt: '2022-02-25T08:04:02.350Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 137916.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'LP9D4aZxvrLnaKr4i',
    createdAt: '2022-02-25T08:04:02.375Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2532.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'F7b3Z57s2aZHnk2Yt',
    createdAt: '2022-02-25T08:04:02.398Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 734,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'nAC7kXD3bootk8SDp',
    createdAt: '2022-02-25T08:04:02.422Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'otoRgRBT9Dp4RnJos',
    createdAt: '2022-02-25T08:04:02.439Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4471,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'M8sHLBtkThj4rxdWr',
    createdAt: '2022-02-25T08:15:50.296Z',
    transactionType: 'in',
    amount: 800,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 316.7800000000061,
    },
  },
  {
    _id: 'FcNnw8pB2ynZrYnBJ',
    createdAt: '2022-02-25T08:17:22.862Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1116.780000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'hEYgLgJqe4GsaukiH',
    createdAt: '2022-02-25T08:17:22.880Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YJdkjvuerDu7oy9cc',
    createdAt: '2022-02-25T08:17:22.895Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BqNK3wKFJbCMfpRxS',
    createdAt: '2022-02-25T08:17:22.908Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3015.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZHgtbx6dsEZHtXS9e',
    createdAt: '2022-02-25T08:17:22.924Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 126,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NWBjKKYuY649WjPiw',
    createdAt: '2022-02-25T08:17:22.938Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18385.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'rg9YneDf2An5x2dnj',
    createdAt: '2022-02-25T08:17:22.953Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 9,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'pbW5M59Aho5rGx7Z6',
    createdAt: '2022-02-25T08:17:22.967Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 49.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'manvENxYRpJsHcDsF',
    createdAt: '2022-02-25T08:17:22.983Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 428.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'TfmwR6uqafMkW9ndi',
    createdAt: '2022-02-25T08:17:22.997Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9479.859999999997,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'fQbNfaxGCMAQXZzzQ',
    createdAt: '2022-02-25T08:17:23.019Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4431,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'yp9FqJECg6fzyo2Mb',
    createdAt: '2022-02-25T10:00:58.906Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1082,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '7oeTpqGyHQt3nhCvw',
    createdAt: '2022-02-25T10:00:58.938Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 165.50000000000009,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'LAQahs3ugNefKaYB9',
    createdAt: '2022-02-25T10:00:58.959Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.400000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'ofrXx2jmMZuQqjDX2',
    createdAt: '2022-02-25T10:00:58.979Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 422.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'pDit52KTqZprRMa5b',
    createdAt: '2022-02-25T10:00:59.009Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 175.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'J886ELuYwurThepfF',
    createdAt: '2022-02-25T10:00:59.032Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9473.859999999997,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'TL9M6DCTYHLTukyMX',
    createdAt: '2022-02-25T10:00:59.052Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 538,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Qks8mxDcZQtZMmvx7',
    createdAt: '2022-02-25T10:00:59.082Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 914.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'XjrRWc5t6ehbPL9Ks',
    createdAt: '2022-02-25T10:00:59.107Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f56d9QDtDhkrp5h3h',
    createdAt: '2022-02-25T10:00:59.131Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ght68RQkmxdPnyrp5',
    createdAt: '2022-02-25T10:00:59.156Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1068,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'dEFct3SGRc7qGLgf8',
    createdAt: '2022-02-25T10:04:27.012Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 20369.64425,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'QdxDTxTvQDagP8XM9',
    createdAt: '2022-02-25T10:04:27.035Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 3015.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'TR5PFWEayuR6QEpGh',
    createdAt: '2022-02-25T10:04:27.054Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2474.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: '2yXqirQrrgy6N62fZ',
    createdAt: '2022-02-25T10:04:27.073Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18487,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'H33iBd6uLEXYjMFab',
    createdAt: '2022-02-25T10:04:27.091Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18373.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '7QumHf2sdsq8HwSc9',
    createdAt: '2022-02-25T10:04:27.111Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 534,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'sEBq7P9tpdM4kTyTX',
    createdAt: '2022-02-25T10:04:27.132Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xMwWypXMaCcGnnD38',
    createdAt: '2022-02-25T10:04:27.150Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 947,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'xqMdHvryNQApj7KJx',
    createdAt: '2022-02-25T10:04:27.169Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1858,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'PRY9RRRsFy9hHyLBg',
    createdAt: '2022-02-25T10:12:35.506Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Febb2c05b-4263-48db-b6e3-348fccc57fa9_200x200.jpg?alt=media&token=67a4f92f-b201-4220-a13d-3b8730cddafa',
        },
      },
      quantity: 538,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'jJ8xh3erTX6iqpChB',
    createdAt: '2022-02-25T10:12:35.522Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 905.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'owDYEM6ASPDS7W8GQ',
    createdAt: '2022-02-25T10:12:35.541Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 165.10000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'PsGvvB8CpLfso9X2N',
    createdAt: '2022-02-25T10:12:35.565Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2990.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'FXrHWHyRwPDtQK3xo',
    createdAt: '2022-02-25T10:12:35.606Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'Bxn7zy8JhdLgodceH',
    createdAt: '2022-02-25T10:22:06.404Z',
    transactionType: 'transferIn',
    amount: 960,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8421,
    },
  },
  {
    _id: 'HzsAbYJvAxr85shgL',
    createdAt: '2022-02-25T10:24:56.538Z',
    transactionType: 'transferIn',
    amount: 63,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 618,
    },
  },
  {
    _id: 'xMoCMuDA9K8ej3j7L',
    createdAt: '2022-02-25T10:41:17.023Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 572,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'ejEkbxL4cTgdcKYrX',
    createdAt: '2022-02-25T10:41:17.042Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9381,
    },
    transactionType: 'transferOut',
    amount: -133,
  },
  {
    _id: 'P7Ccn9MrYc86ahSmn',
    createdAt: '2022-02-25T10:41:17.058Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 952,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'QYZnxhPXLy9wLjQ2y',
    createdAt: '2022-02-25T10:44:41.895Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 440,
    },
    transactionType: 'transferOut',
    amount: -240,
  },
  {
    _id: 'Y7Gf5m7jN65nRwDAd',
    createdAt: '2022-02-25T10:44:41.915Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9248,
    },
    transactionType: 'transferOut',
    amount: -242,
  },
  {
    _id: 'yF8yDgwpKSEswDMHj',
    createdAt: '2022-02-25T10:44:41.933Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 941,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'TLiimgNZAB4KGy2iW',
    createdAt: '2022-02-25T10:49:18.483Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 381.5,
    },
    transactionType: 'transferOut',
    amount: -138,
  },
  {
    _id: 'p7xcb3DDFX2fbRQbg',
    createdAt: '2022-02-25T10:49:18.499Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9006,
    },
    transactionType: 'transferOut',
    amount: -62,
  },
  {
    _id: 'x3ozffC5vZXTb4Wrq',
    createdAt: '2022-02-25T10:49:18.516Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3326,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'zAbjL25Gne2dLy7h6',
    createdAt: '2022-02-25T10:49:18.529Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 921,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'XsJK2njMpwGde6Qqg',
    createdAt: '2022-02-25T10:51:06.671Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
      },
      quantity: 243.5,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'ZRFCrMsD383xPFe8J',
    createdAt: '2022-02-25T10:51:06.689Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8944,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: '5yC94ztcGqYan9y4T',
    createdAt: '2022-02-25T10:51:06.707Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 916,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'T2fhMvSCQSxmxdJ5F',
    createdAt: '2022-02-25T11:05:41.322Z',
    transactionType: 'transferIn',
    amount: 2000.6,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 316.7800000000061,
    },
  },
  {
    _id: 'xyoWxzjnNko4LN7MZ',
    createdAt: '2022-02-25T11:11:29.734Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2317.380000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'yYzdDixSjkF98MPtC',
    createdAt: '2022-02-25T11:11:29.750Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jXW7dHYrRjKPQnaNj',
    createdAt: '2022-02-25T11:11:29.764Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19569.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sMo8KpGvzKx8fsm6w',
    createdAt: '2022-02-25T11:11:29.782Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2987.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'AENJ9ek2mEZBspcyK',
    createdAt: '2022-02-25T11:11:29.796Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 126,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '7v2hzjwC9pgLSDYWy',
    createdAt: '2022-02-25T11:11:29.817Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18363.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'jkKzz2CxJGGEiwyci',
    createdAt: '2022-02-25T11:11:29.832Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'oXysDeTnNxDfKpa6H',
    createdAt: '2022-02-25T11:11:29.849Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 49.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'NtzLra3HZ6jkY2dmk',
    createdAt: '2022-02-25T11:11:29.877Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 420.82000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aib4uK6eayGsmCmdF',
    createdAt: '2022-02-25T11:11:29.894Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9472.859999999997,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'WgzqfaauJ9QS2Hj5t',
    createdAt: '2022-02-25T11:11:29.912Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4357,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'SXXw2R9DX6CAAQ7ur',
    createdAt: '2022-02-25T11:44:09.106Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 51.6,
    },
  },
  {
    _id: '5gzABTx5dokpyLqrD',
    createdAt: '2022-02-25T11:45:42.439Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 119,
    },
  },
  {
    _id: 'tZsaJ3mfR9zE4x5dr',
    createdAt: '2022-02-25T12:36:40.578Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1517.380000000006,
    },
  },
  {
    _id: 'uanz6fqRDrSdNL2W6',
    createdAt: '2022-02-25T12:39:11.878Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 6860,
    },
  },
  {
    _id: 'BfS3fZbuMnENKAcqs',
    createdAt: '2022-02-25T12:39:44.827Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 837,
    },
  },
  {
    _id: 'cP7DanJe8ApzoZpxz',
    createdAt: '2022-02-25T13:18:32.369Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1917.380000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '36c9tdmdvHj7R7JhA',
    createdAt: '2022-02-25T13:18:32.385Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'yCpKGcQ7dMWHJapDY',
    createdAt: '2022-02-25T13:18:32.402Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 76.6,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'fru9aAgNQSFL8kqTa',
    createdAt: '2022-02-25T13:18:32.418Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 137416.4,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'd2EDXPkYhJ6Z47p9G',
    createdAt: '2022-02-25T13:18:32.432Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2429.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'MbZyu9vzDFptfXGAx',
    createdAt: '2022-02-25T13:18:32.468Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1027,
    },
    transactionType: 'in',
    amount: 78,
  },
  {
    _id: '74jiJxPWeJsfGeRPD',
    createdAt: '2022-02-25T13:24:33.323Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19569.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NMfBTdfoJsJZsousm',
    createdAt: '2022-02-25T13:24:33.342Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1917.380000000006,
    },
    transactionType: 'transferOut',
    amount: -207,
  },
  {
    _id: 'H8hk9ET5jv9n8wKjm',
    createdAt: '2022-02-25T13:24:33.363Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2980.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'hvx3x2mK32ga7doBz',
    createdAt: '2022-02-25T13:24:33.381Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'nWKG48HkQCfLZYpTh',
    createdAt: '2022-02-25T13:24:33.401Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 137415.9,
    },
    transactionType: 'transferOut',
    amount: -1910,
  },
  {
    _id: '4tb7AiWPp2r54AdrJ',
    createdAt: '2022-02-25T13:24:33.417Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9472.259999999997,
    },
    transactionType: 'transferOut',
    amount: -2.4,
  },
  {
    _id: 'mgQL9iWTFiiD64XgC',
    createdAt: '2022-02-25T13:24:33.432Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 925,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'oyyxv8Jokhrs2D7kL',
    createdAt: '2022-02-25T13:24:33.450Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1836,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'myC9KBAABCGjjyxYk',
    createdAt: '2022-02-25T13:28:10.009Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1710.380000000006,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'AdQrcK4AmELqQu4N8',
    createdAt: '2022-02-25T13:28:10.027Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 19569.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FNhfQ4FyXPmyDBBY2',
    createdAt: '2022-02-25T13:28:10.047Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2939.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '4mL5965oNBCTbRsbX',
    createdAt: '2022-02-25T13:28:10.069Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 18,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '4EeqrLeLxrEQyryrL',
    createdAt: '2022-02-25T13:28:10.095Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135505.9,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'ByBFcNFAnZ2APgMjH',
    createdAt: '2022-02-25T13:28:10.109Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9469.859999999997,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'pZ7vZRufx8qpf8k8b',
    createdAt: '2022-02-25T13:28:10.125Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4068,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'Apt9E4R7A3gkYbxvg',
    createdAt: '2022-02-26T05:04:09.598Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 19569.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eTrgBJt9qxGBzyBCf',
    createdAt: '2022-02-26T05:04:09.613Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1665.380000000006,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: 'hxHkvMQ8AXYgrYpjB',
    createdAt: '2022-02-26T05:04:09.629Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 6228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'h95anvrQyFdGqMT7J',
    createdAt: '2022-02-26T05:04:09.645Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2930.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'Qnh5vzTtTFEuXDHxg',
    createdAt: '2022-02-26T05:04:09.659Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 139,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'fo7aDiEkTrB9paP9x',
    createdAt: '2022-02-26T05:04:09.676Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18351.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'n2SsyabxYBKnQjNw5',
    createdAt: '2022-02-26T05:04:09.699Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3906,
    },
    transactionType: 'transferOut',
    amount: -175,
  },
  {
    _id: 'nq798dvdyojtdowuj',
    createdAt: '2022-02-26T05:17:57.129Z',
    transactionType: 'transferIn',
    amount: 1600,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 915.380000000006,
    },
  },
  {
    _id: 'PGNoAAdzimLnfX78K',
    createdAt: '2022-02-26T06:02:49.172Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1059,
    },
  },
  {
    _id: 'JMy4efTkqyX4c7xvs',
    createdAt: '2022-02-26T06:52:22.066Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'bzoyAvTee8kAnLC4h',
    createdAt: '2022-02-26T06:52:22.092Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zMYetL88SSq5Xt2tJ',
    createdAt: '2022-02-26T06:52:22.121Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 164.85000000000008,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'TybCTd3KNt2PysN6n',
    createdAt: '2022-02-26T06:52:22.143Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 888.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'uzQcY3xibSJhGByA6',
    createdAt: '2022-02-26T06:52:22.167Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 528,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '5EuRX2XkuE4EwXknK',
    createdAt: '2022-02-26T06:52:22.190Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 61.6,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'nTAwG2Kb6v7PaR9Lw',
    createdAt: '2022-02-26T06:52:22.213Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.2,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'TqJaysDmK77qEJ5tA',
    createdAt: '2022-02-26T06:52:22.245Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 420.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'ToRTMuzgmQDn4u3md',
    createdAt: '2022-02-26T06:52:22.264Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9469.359999999997,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'CZh3vfbegbh8gZwF6',
    createdAt: '2022-02-26T06:52:22.285Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1105,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'p9E3ZqyyicqFKxYiA',
    createdAt: '2022-02-26T06:52:22.312Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'orBfDNuCJb2LhbTst',
    createdAt: '2022-02-26T06:52:22.336Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 390,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'Pu9bi3xvpMJJuLpjd',
    createdAt: '2022-02-26T06:52:22.353Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 51,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'LJe8ZcFDYuf2i2zuv',
    createdAt: '2022-02-26T09:45:19.519Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 19569.64425,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '5YJ3tnHN76tQxqnhC',
    createdAt: '2022-02-26T09:45:19.544Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 75.6,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'QmY723Y4moB983X2o',
    createdAt: '2022-02-26T09:45:19.569Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135105.9,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'NtLNRQhPofwgPxGck',
    createdAt: '2022-02-26T09:45:19.590Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2363.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'PZoBtmtnEMJ3Dx65B',
    createdAt: '2022-02-26T09:45:19.611Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9469.109999999997,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '9jqyi7zxGutRL4Fye',
    createdAt: '2022-02-26T09:45:19.650Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 348,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'KEfpyNLQKowk4ouF8',
    createdAt: '2022-02-26T09:45:19.674Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4283,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'jbxsq3Nc7D6J4yuZa',
    createdAt: '2022-02-26T09:48:26.826Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 18569.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'hKm58v5zZsLvoEbdb',
    createdAt: '2022-02-26T09:48:26.850Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 74.6,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'sLbGnsa9hWaLbyZwo',
    createdAt: '2022-02-26T09:48:26.892Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135105.4,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'TfNRp9kRvSRRzk6br',
    createdAt: '2022-02-26T09:48:26.915Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2299.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -128,
  },
  {
    _id: '8NLesiyyWwNcbotYx',
    createdAt: '2022-02-26T09:48:26.933Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9444.109999999997,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'bXPawJLHvuqRr88NM',
    createdAt: '2022-02-26T09:48:26.961Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 228,
    },
    transactionType: 'transferOut',
    amount: -228,
  },
  {
    _id: 'aRQ2PQN9KDref2CFb',
    createdAt: '2022-02-26T09:48:26.988Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4262,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'my39TqzBZYn32Z4f8',
    createdAt: '2022-02-26T09:51:16.218Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 527,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'HAL7tHv9eL9scA3Gu',
    createdAt: '2022-02-26T09:51:16.235Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 883.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'kmu6sgZWvv25kbPeC',
    createdAt: '2022-02-26T09:51:16.255Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 139.85000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.28,
  },
  {
    _id: 'pMmRka4hsgz2EABtA',
    createdAt: '2022-02-26T09:51:16.276Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2923.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'F7bHXmGmgkzdeszuY',
    createdAt: '2022-02-26T09:51:16.317Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'jA2qCujuujTe6zpM3',
    createdAt: '2022-02-26T09:52:56.361Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2515.380000000006,
    },
    transactionType: 'transferOut',
    amount: -230,
  },
  {
    _id: 'z4D2JynNyRiuKPYQd',
    createdAt: '2022-02-26T09:52:56.401Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16569.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ksYtzJrqyfhkcgtt5',
    createdAt: '2022-02-26T09:52:56.423Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2920.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'XxvtwYnTkAA4mm6RC',
    createdAt: '2022-02-26T09:52:56.441Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 132,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'kxKzWabuM43EQNJx4',
    createdAt: '2022-02-26T09:52:56.455Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 7860,
    },
    transactionType: 'transferOut',
    amount: -1300,
  },
  {
    _id: 'HttKsDHzvTtrd3S2B',
    createdAt: '2022-02-26T09:52:56.474Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 24110,
    },
    transactionType: 'transferOut',
    amount: -1300,
  },
  {
    _id: 'txstXAedNCvkQdZPA',
    createdAt: '2022-02-26T09:52:56.493Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1237,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: 'aa4PJgiHwAREAk94j',
    createdAt: '2022-02-26T09:53:24.494Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2285.380000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'HKqfBiio8zwB2YcCe',
    createdAt: '2022-02-26T09:53:24.522Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 6228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BBwnbSj9rLcPJcSPE',
    createdAt: '2022-02-26T09:53:24.540Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16569.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NTdgC9k8CQ73gzMKw',
    createdAt: '2022-02-26T09:53:24.558Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2918.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'jPzSdWjRynpikQ6pA',
    createdAt: '2022-02-26T09:53:24.579Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'y2Dj7Yy5s2PsatCMq',
    createdAt: '2022-02-26T09:53:24.597Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18339.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Pvi9xb8tj56dyZ5qb',
    createdAt: '2022-02-26T09:53:24.619Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'RCvbKhLXtrkhZQBXh',
    createdAt: '2022-02-26T09:53:24.639Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 48.59999999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EYt8KvvEdTs8Hsr5o',
    createdAt: '2022-02-26T09:53:24.661Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 420.4200000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KYi5yuR4mYYjeh7Yu',
    createdAt: '2022-02-26T09:53:24.678Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9394.109999999997,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'wfqchgYdMmExnBTcg',
    createdAt: '2022-02-26T09:53:24.697Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4219,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'b4ugMsCSRD5MbDqnW',
    createdAt: '2022-02-26T10:28:05.075Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 16569.64425,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'THBBDtMpbaxqkRgJy',
    createdAt: '2022-02-26T10:28:05.095Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2911.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'Q9EDrMWR4evCeqdJD',
    createdAt: '2022-02-26T10:28:05.122Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2171.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'bfQMbGghWasQCeDg4',
    createdAt: '2022-02-26T10:28:05.139Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18467,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '85ARhb49bwCCZX7iS',
    createdAt: '2022-02-26T10:28:05.156Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18327.5,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'sFDwHuMWMPLYzzdES',
    createdAt: '2022-02-26T10:28:05.175Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 394,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'qkrWomegMw7dfarj5',
    createdAt: '2022-02-26T10:28:05.195Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4059,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Jhf8t2SZsf7QLQMwz',
    createdAt: '2022-02-26T10:28:05.213Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 910,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'nqhHZepyyZELsQenZ',
    createdAt: '2022-02-26T10:28:05.228Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1821,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'nbs9KPtZBsFE8cotE',
    createdAt: '2022-02-26T10:40:14.085Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '4gTedWr6c5vT8dTgB',
    createdAt: '2022-02-26T10:40:14.113Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'feamNpdRA43H3EGiW',
    createdAt: '2022-02-26T10:40:14.153Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 164.57000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'qDozNmcTF8Th7tRDX',
    createdAt: '2022-02-26T10:40:14.175Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 871.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'hfXfu9778PhNfwK3w',
    createdAt: '2022-02-26T10:40:14.196Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ooS9FL8vniHKNvAJA',
    createdAt: '2022-02-26T10:40:14.217Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 61.6,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'YPvkXmfiXrtT9gDKb',
    createdAt: '2022-02-26T10:40:14.237Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.2,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'GrY5vf7LTtyyvo6F3',
    createdAt: '2022-02-26T10:40:14.274Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 420.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'eqz98erL8Gc7BkMNt',
    createdAt: '2022-02-26T10:40:14.312Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9393.759999999997,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'MHaZuXYogmBjv7993',
    createdAt: '2022-02-26T10:40:14.349Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1105,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '8QBa7jEk3EukwbGP3',
    createdAt: '2022-02-26T10:40:14.398Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'astHvdGtyjg84dhY4',
    createdAt: '2022-02-26T10:40:14.464Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 390,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'eGaHr5uproLuEsZfK',
    createdAt: '2022-02-26T10:40:14.502Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 51,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Wo53TF6rQ9AMJsKLj',
    createdAt: '2022-02-26T10:44:15.450Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15669.644250000001,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'HKvufZXrFBXNs8ozM',
    createdAt: '2022-02-26T10:44:15.486Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 454,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'jw48SZHNQime8GNfc',
    createdAt: '2022-02-26T10:44:15.503Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 420.82000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'StJwt4a6f4KJXw8jc',
    createdAt: '2022-02-26T10:44:15.518Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1736,
    },
    transactionType: 'transferOut',
    amount: -152,
  },
  {
    _id: 'T8Ne2CQMz3Enknc6M',
    createdAt: '2022-02-26T10:44:15.539Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1089,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'KTXGYCBK3Evwki4nL',
    createdAt: '2022-02-26T11:27:46.980Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 427,
    },
  },
  {
    _id: 'TiEhrjxckhuh4RtYe',
    createdAt: '2022-02-26T11:35:25.649Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 6228.5,
    },
  },
  {
    _id: 'qs3vZ7avc27a5woqz',
    createdAt: '2022-02-26T11:36:06.764Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 111,
    },
  },
  {
    _id: 'jq5fN2aCjDuhEBwrF',
    createdAt: '2022-02-26T11:38:13.541Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 201.5,
    },
    transactionType: 'transferOut',
    amount: -170,
  },
  {
    _id: '5AKHsFh34CkHvbsra',
    createdAt: '2022-02-26T11:38:13.556Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8902,
    },
    transactionType: 'transferOut',
    amount: -62,
  },
  {
    _id: 'aKm5yNaasLdYvmiyB',
    createdAt: '2022-02-26T11:38:13.573Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3324,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'FJimzbTkEehbFkdw2',
    createdAt: '2022-02-26T11:38:13.588Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 912,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'dJomD9onwS7tG6CgX',
    createdAt: '2022-02-26T11:39:50.183Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 31.5,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: '5J487cKKejQJTKubE',
    createdAt: '2022-02-26T11:39:50.201Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8840,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'p6Ht8tBLue3CqnGZJ',
    createdAt: '2022-02-26T11:39:50.218Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 907,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Q4Wkgz9pj7hvY8A5h',
    createdAt: '2022-02-26T12:52:38.409Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15569.644250000001,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: '9LyEbdLpJWKhB7CqP',
    createdAt: '2022-02-26T12:52:38.429Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2886.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'N4ZES9TJAqSBei2de',
    createdAt: '2022-02-26T12:52:38.444Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2104.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'RT4PtoRfNR8sMh9DE',
    createdAt: '2022-02-26T12:52:38.459Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18442,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'AMXa7DaeKa7zPKvoZ',
    createdAt: '2022-02-26T12:52:38.476Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 294,
    },
    transactionType: 'transferOut',
    amount: -85,
  },
  {
    _id: 'mphu98voZTcb9e8rw',
    createdAt: '2022-02-26T12:52:38.495Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4059,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'LyTrF3LdRxwddZDuP',
    createdAt: '2022-02-26T13:26:12.221Z',
    transactionType: 'in',
    amount: 40,
    storeItem: {
      type: {
        _id: 'qKCYAS77NQLBFFfji',
        name: 'CT 321',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8232fa90-3584-4984-931b-d22cd2eecdb0_200x200.jpg?alt=media&token=22ad60ce-a49d-4d5f-8ced-0d43d83110e2',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'SYYpABxw67sn4diGf',
    createdAt: '2022-02-28T05:07:44.152Z',
    transactionType: 'transferIn',
    amount: 2683.8,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1485.380000000006,
    },
  },
  {
    _id: 'NqygNYQBgQCPhWLEG',
    createdAt: '2022-02-28T05:33:44.552Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1093,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Z2ywweTYan8DYhCgo',
    createdAt: '2022-02-28T05:33:44.582Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 164.32000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'ryMsSyfR8FEvXiMky',
    createdAt: '2022-02-28T05:33:44.608Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 55.300000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'Kcgi2F7n6ei8zG3RN',
    createdAt: '2022-02-28T05:33:44.629Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 417.4200000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Mk9RTtCWTeDhZnB23',
    createdAt: '2022-02-28T05:33:44.650Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 174.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'oih5CLG5gH7YMu5gB',
    createdAt: '2022-02-28T05:33:44.670Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9393.509999999997,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'oZ4ZHEYJPiXKt4S7Z',
    createdAt: '2022-02-28T05:33:44.689Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pipHu49AMo7uToeeb',
    createdAt: '2022-02-28T05:33:44.705Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 871,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'btKZ2AgedKubEGXss',
    createdAt: '2022-02-28T05:33:44.723Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 171,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'bzTdtn67w9XPiN5xi',
    createdAt: '2022-02-28T05:33:44.742Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bByssXyubivSGi2xv',
    createdAt: '2022-02-28T05:33:44.772Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1799,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '8xGLwkti7YhThPGgB',
    createdAt: '2022-02-28T05:33:44.796Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 888,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KEKgZYtBLBybaZrub',
    createdAt: '2022-02-28T05:33:44.818Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1081,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'KtpAsFXG29b7TNxPy',
    createdAt: '2022-02-28T06:32:34.591Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1066,
    },
  },
  {
    _id: 'xXf5nunb4rByiLSWd',
    createdAt: '2022-02-28T06:32:58.097Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 905,
    },
  },
  {
    _id: 'ammeAmNQscYED5JKN',
    createdAt: '2022-02-28T06:33:44.300Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9391.509999999997,
    },
  },
  {
    _id: 'rn5Xsep2We6aWRi9F',
    createdAt: '2022-02-28T06:34:56.166Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2868.0000000000005,
    },
  },
  {
    _id: 'RxNWsZ2pmYZRPMDne',
    createdAt: '2022-02-28T06:35:29.816Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 855,
    },
  },
  {
    _id: 'A399NtSQRKYaNyzkT',
    createdAt: '2022-02-28T07:00:59.197Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14969.644250000001,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'xfo5EGm4oKfvgpkAi',
    createdAt: '2022-02-28T07:00:59.219Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3068.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'RtnpegJzrZEY6ER7w',
    createdAt: '2022-02-28T07:00:59.233Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2072.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'mqQkeCYrPqjwh6nbq',
    createdAt: '2022-02-28T07:00:59.250Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18430,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'ZvxQn8TmYvFEPTfMP',
    createdAt: '2022-02-28T07:00:59.267Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21831.9,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'z5kcdgwuopAmA5oTC',
    createdAt: '2022-02-28T07:00:59.283Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 539.9,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'Xch858oobRL98ayYi',
    createdAt: '2022-02-28T07:05:20.121Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 375,
    },
    transactionType: 'transferOut',
    amount: -225,
  },
  {
    _id: '57HXqztGQna5bHn95',
    createdAt: '2022-02-28T07:05:20.139Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9891.509999999997,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'huDYPZabHCuGNX7Yo',
    createdAt: '2022-02-28T07:05:20.156Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134105.4,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'Bn3FfquC8trxfcTTb',
    createdAt: '2022-02-28T07:05:20.175Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18312.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '83uWoERFhy5Lt9863',
    createdAt: '2022-02-28T07:05:20.216Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: '3iC6seeJYFtbHaEsx',
    createdAt: '2022-02-28T07:09:06.676Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4169.180000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'PCPvm359nfckzeL88',
    createdAt: '2022-02-28T07:09:06.697Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8Scf99qYpkvbYMgKa',
    createdAt: '2022-02-28T07:09:06.713Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14669.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kpGeGhAY4HEnpmPjo',
    createdAt: '2022-02-28T07:09:06.732Z',
    storeItem: {
      type: {
        _id: 'qKCYAS77NQLBFFfji',
        name: 'CT 321',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8232fa90-3584-4984-931b-d22cd2eecdb0_200x200.jpg?alt=media&token=22ad60ce-a49d-4d5f-8ced-0d43d83110e2',
        },
      },
      quantity: 40,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Dd2s7jknTPp5MGTsM',
    createdAt: '2022-02-28T07:09:06.748Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 48.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Hkq6nSNjp9fHLAP3n',
    createdAt: '2022-02-28T07:09:06.768Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18312.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'r6oLuNNJuyjJB7ToA',
    createdAt: '2022-02-28T07:09:06.783Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 133805.4,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'MQsTuBy7mv2aQLkWw',
    createdAt: '2022-02-28T07:09:06.802Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2054.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'q6WGdkKC6tXux6o6y',
    createdAt: '2022-02-28T07:09:06.828Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 300,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '5vYNB2RdEEunBuDWY',
    createdAt: '2022-02-28T07:09:06.870Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F87490809-61cf-4c86-8e6c-97be3c8f9d18_200x200.jpeg?alt=media&token=fac38e50-d6ca-49ef-875e-831d15caaa81',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 90,
  },
  {
    _id: 'nJKQJzY6jdDNAooy3',
    createdAt: '2022-02-28T07:17:49.654Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 413.4200000000001,
    },
  },
  {
    _id: 'kKLkcFpdP8y3irxna',
    createdAt: '2022-02-28T07:18:24.950Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4144,
    },
  },
  {
    _id: 'N9MYfYrzfM5F8WbKP',
    createdAt: '2022-02-28T10:17:37.966Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 599.5,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'CZYvadkywXeZRAaZ6',
    createdAt: '2022-02-28T10:17:37.995Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21820.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'bixoyNRZuF292TYKM',
    createdAt: '2022-02-28T10:17:38.024Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 528.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Ag8eTaH2sNijnbkYt',
    createdAt: '2022-02-28T10:19:54.591Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 578.5,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'sEoSQWKzKAQCmvdqt',
    createdAt: '2022-02-28T10:19:54.613Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21814.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '2pGxMwvPAaXWFvvTN',
    createdAt: '2022-02-28T10:19:54.628Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 522.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'zJdY23AWSKiE74wqQ',
    createdAt: '2022-02-28T10:19:54.642Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 168,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'rKzmdB4WASReq44wo',
    createdAt: '2022-02-28T10:21:32.333Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 557.5,
    },
    transactionType: 'transferOut',
    amount: -5.5,
  },
  {
    _id: 'isMRQPKnNFKZShpkN',
    createdAt: '2022-02-28T10:21:32.359Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21809.9,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'i4D7weyoxvQoPr9S7',
    createdAt: '2022-02-28T10:21:32.376Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 517.9,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'KAFnMWYDeDCSCp6KT',
    createdAt: '2022-02-28T10:21:32.393Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 167,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'xhNBMuFyGemMYPmib',
    createdAt: '2022-02-28T10:23:01.645Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 552,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: 'eEvaFYwTnmvwqRndc',
    createdAt: '2022-02-28T10:23:01.672Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21807.9,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '3MX8smSxy4c2chao9',
    createdAt: '2022-02-28T10:23:01.693Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 515.9,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2ESXC3K8SQkFD29FL',
    createdAt: '2022-02-28T10:23:01.713Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 166,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '3aFnyLnevpECmaw2w',
    createdAt: '2022-02-28T11:09:24.571Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7228.5,
    },
  },
  {
    _id: '2PqpagS8kYa4L2QH3',
    createdAt: '2022-02-28T11:28:23.388Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3369.1800000000057,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Dk97xjyRFu7QcgNsm',
    createdAt: '2022-02-28T11:28:23.406Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2000,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'ipDHmHM3RGG2Pkxiq',
    createdAt: '2022-02-28T11:28:23.423Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 72.6,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'M3GrCXA3RNHuQz3AE',
    createdAt: '2022-02-28T11:28:23.438Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 133305.4,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '4dS5PueLQcBK2mFu2',
    createdAt: '2022-02-28T11:28:23.454Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2009.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'KWztCHtxEbFKAT4Ha',
    createdAt: '2022-02-28T11:28:23.498Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 993,
    },
    transactionType: 'in',
    amount: 155,
  },
  {
    _id: 'yBBqmS5pvfsDZjqCd',
    createdAt: '2022-02-28T12:02:10.883Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 4.5,
    },
  },
  {
    _id: '5oK8H4m6CTLci4hDg',
    createdAt: '2022-02-28T12:02:45.638Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 354.5,
    },
  },
  {
    _id: '4MjweQuwSyg36YKTX',
    createdAt: '2022-02-28T12:05:31.217Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 704.5,
    },
    transactionType: 'transferOut',
    amount: -239,
  },
  {
    _id: '2GBL7P3BNdDe4oydj',
    createdAt: '2022-02-28T12:05:31.238Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8813,
    },
    transactionType: 'transferOut',
    amount: -98,
  },
  {
    _id: 'PC9DdPznsEzdqYFuW',
    createdAt: '2022-02-28T12:05:31.253Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3322,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'YD5qRKMimoJ3brMth',
    createdAt: '2022-02-28T12:05:31.265Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 965,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'wAjofiSoN8NCXyBS6',
    createdAt: '2022-02-28T12:07:13.798Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 465.5,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'Xc6hamr55HhYWnD8G',
    createdAt: '2022-02-28T12:07:13.859Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8715,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'DEZthhnpYji48Ki6b',
    createdAt: '2022-02-28T12:07:13.874Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 957,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'b9jyC4L7oH4gTEL8s',
    createdAt: '2022-02-28T12:11:13.277Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 412.5,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'YoiEQKZMRjKLZeWBL',
    createdAt: '2022-02-28T12:11:13.302Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 3.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'E9JbikP2QFoY43jJt',
    createdAt: '2022-02-28T12:11:13.510Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 200,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: 'iysZ7pDKCBY6rPsXi',
    createdAt: '2022-02-28T12:12:49.359Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'vRDmpshwb6PAXtZM4',
    createdAt: '2022-02-28T12:12:49.396Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8662,
    },
    transactionType: 'transferOut',
    amount: -91,
  },
  {
    _id: 'rWAyKSEnSng8s23fx',
    createdAt: '2022-02-28T12:12:49.412Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 952,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'A7yBGAWMwzy4hA3nh',
    createdAt: '2022-02-28T12:14:34.979Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 410,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'mHoLfQomCWD3Whvg4',
    createdAt: '2022-02-28T12:14:35.006Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8571,
    },
    transactionType: 'transferOut',
    amount: -141,
  },
  {
    _id: 'DWXqmux5fbjof3oMq',
    createdAt: '2022-02-28T12:14:35.021Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 944,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'FWSE9GKmkqJf9uYd5',
    createdAt: '2022-02-28T12:29:24.311Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14669.644250000001,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'qjyBmsg7MQ4kksKrb',
    createdAt: '2022-02-28T12:29:24.328Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3059.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'z48ZiTsY984MwSfFR',
    createdAt: '2022-02-28T12:29:24.357Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1883.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'R3cM3gryteAPNqjGs',
    createdAt: '2022-02-28T12:29:24.376Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18420,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'PbnMRZXoisW3vtj9b',
    createdAt: '2022-02-28T12:29:24.397Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 209,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'EiGMsFuqs9JgmGEuh',
    createdAt: '2022-02-28T12:29:24.414Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4018,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'Q4MznHqsRxMndbtnr',
    createdAt: '2022-02-28T12:50:20.754Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3369.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'fSZxMTCq9pdYNL7Sn',
    createdAt: '2022-02-28T12:50:20.778Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iBQGzMeSGEZdp8sHb',
    createdAt: '2022-02-28T12:50:20.797Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3035.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'AmqbcicovRDFED2gc',
    createdAt: '2022-02-28T12:50:20.818Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'adcE28hPgAaY6GTMx',
    createdAt: '2022-02-28T12:50:20.851Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9816.509999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'q7x4Za5bEFMNP9ZAX',
    createdAt: '2022-02-28T12:50:20.876Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 133304.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'D69u5aebBaieEuqXe',
    createdAt: '2022-02-28T12:50:20.896Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 876,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'zsnTXyAuXTYcwvZ97',
    createdAt: '2022-02-28T12:50:20.914Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1787,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'egN5RvxFpnJF5gc72',
    createdAt: '2022-02-28T12:50:20.937Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1126,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'nKRgSFqkXCQNwe7pS',
    createdAt: '2022-02-28T13:12:32.705Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 547.5,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'BZMpA6FW4X5vzPSaF',
    createdAt: '2022-02-28T13:12:32.735Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21805.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'kxKfseTqt9brwZAJk',
    createdAt: '2022-02-28T13:12:32.763Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 513.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'oEA6djbH49XZz4s9n',
    createdAt: '2022-02-28T13:12:32.810Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 165,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Y2qmJA7BzduYKR2rz',
    createdAt: '2022-02-28T13:19:33.309Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GQ6eE2xeLYs6MBxEQ',
    createdAt: '2022-02-28T13:19:33.335Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3119.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'EBpwYs8WoEdKqBENh',
    createdAt: '2022-02-28T13:19:33.354Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '93N7KQha3WHyookk2',
    createdAt: '2022-02-28T13:19:33.379Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2985.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -4.2,
  },
  {
    _id: '3WYinfHAHhxgkGdKt',
    createdAt: '2022-02-28T13:19:33.411Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1839.5000000000005,
    },
    transactionType: 'transferOut',
    amount: -8.4,
  },
  {
    _id: '9jpLxnswqaAMbNgkn',
    createdAt: '2022-02-28T13:19:33.432Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131004.4,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '632rZdsYrMM8m9DC4',
    createdAt: '2022-02-28T13:19:33.453Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18408,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'XhH6kzGQAL5R8NukP',
    createdAt: '2022-02-28T13:19:33.474Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1188,
    },
    transactionType: 'transferOut',
    amount: -61,
  },
  {
    _id: 'YyKTkbhMJe5sdcXTw',
    createdAt: '2022-02-28T13:22:24.183Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2979.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'i8PySha2srG8cpLPR',
    createdAt: '2022-02-28T13:22:24.202Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'L9nJsXgNgWKv6WpDn',
    createdAt: '2022-02-28T13:22:24.219Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LKzAAKq6z5hT7J9A6',
    createdAt: '2022-02-28T13:22:24.235Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2980.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'DwPbGeA67KH4LmSWu',
    createdAt: '2022-02-28T13:22:24.252Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1831.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '49S48PSEtWj25ACiS',
    createdAt: '2022-02-28T13:22:24.286Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130934.4,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'YrBNmZTPgAo5gNhzy',
    createdAt: '2022-02-28T13:22:24.306Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18403,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'Y3ZG9jEwCsfXWwwSZ',
    createdAt: '2022-02-28T13:22:24.324Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 551,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '6Z45njgGFDcSMFXyz',
    createdAt: '2022-02-28T13:22:24.343Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 99,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: 'wih788AqHNvPMPLhH',
    createdAt: '2022-02-28T13:22:24.363Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3966,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'pLdGhMXX5GbayJeCu',
    createdAt: '2022-03-01T05:19:21.247Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 438.4200000000001,
    },
  },
  {
    _id: 'b7EHdphtu69Gz4GDJ',
    createdAt: '2022-03-01T05:19:55.702Z',
    transactionType: 'transferIn',
    amount: 150,
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1115,
    },
  },
  {
    _id: 'zRkf3MZ22yA2F5SMK',
    createdAt: '2022-03-01T05:20:42.846Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 452,
    },
  },
  {
    _id: 'Gp6WBojthbhwYyDFt',
    createdAt: '2022-03-01T05:21:18.051Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1111,
    },
  },
  {
    _id: 'wCTC7pdg6wFQoPfPi',
    createdAt: '2022-03-01T05:21:50.555Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 139.5,
    },
  },
  {
    _id: 'q75WQHSrd5DMyACqD',
    createdAt: '2022-03-01T06:30:17.241Z',
    transactionType: 'transferIn',
    amount: 1678,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2779.1800000000057,
    },
  },
  {
    _id: 'gEWtYtDWxSQWm8Rex',
    createdAt: '2022-03-01T07:02:32.237Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Y8WZ3yTYuzLeym9GW',
    createdAt: '2022-03-01T07:02:32.262Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 955,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'WhNkqjTteCy6nzwzy',
    createdAt: '2022-03-01T07:02:32.276Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 163.92000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'R4FZCs9cZMppB7Wqu',
    createdAt: '2022-03-01T07:02:32.293Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2974.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'gLKzbj5Xg33ErSp6n',
    createdAt: '2022-03-01T07:02:32.347Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'GLBKsNhZNBrAEvBYR',
    createdAt: '2022-03-01T07:12:24.059Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4457.180000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'W6vgp43WkhvMYKYuY',
    createdAt: '2022-03-01T07:12:24.076Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6qceMHXTkbD9hKNCT',
    createdAt: '2022-03-01T07:12:24.094Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2971.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '438TC6fn7qvxA3Cyc',
    createdAt: '2022-03-01T07:12:24.113Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '6voHavQ47SLPH4Js8',
    createdAt: '2022-03-01T07:12:24.135Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9813.509999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '89XdHeya3KBbSoqfW',
    createdAt: '2022-03-01T07:12:24.153Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130834.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'PaxT9W6rqoK4LT5Lv',
    createdAt: '2022-03-01T07:12:24.168Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 854,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '5gJxBmGuvjqpyypPn',
    createdAt: '2022-03-01T07:12:24.195Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1765,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Wo3Q7MDiGFNZMkhYZ',
    createdAt: '2022-03-01T07:12:24.219Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1141,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'xtcTyLLXYQ2LTPrqJ',
    createdAt: '2022-03-01T08:03:02.759Z',
    transactionType: 'transferIn',
    amount: 480,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4384,
    },
  },
  {
    _id: 'gMDEeRM6cnEWweFhL',
    createdAt: '2022-03-01T08:53:29.525Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AtckirXX5BP8mkf2Y',
    createdAt: '2022-03-01T08:53:29.547Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4207.180000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'mPbxBEJRFdJbye8jD',
    createdAt: '2022-03-01T08:53:29.563Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 48.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'q3iXuwX3LEuPccSmf',
    createdAt: '2022-03-01T08:53:29.580Z',
    storeItem: {
      type: {
        _id: 'BWEYgyyXW9LZb2vaG',
        name: 'WHITE DAILY 40',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1f6ed39d-17eb-4a2f-906e-3abfb2631b6a_200x200.jpg?alt=media&token=f146a98a-a0b7-4a47-bcfe-5077a6e994e3',
        },
      },
      quantity: 49.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GiPstFqxhqyNhtR7L',
    createdAt: '2022-03-01T08:53:29.594Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 128534.4,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'mNySJyxmm6ME2bKkJ',
    createdAt: '2022-03-01T08:53:29.612Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1819.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'E2PqgghtSDgqMZ43W',
    createdAt: '2022-03-01T08:53:29.630Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9810.509999999997,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'K87E5f5rXkbj3bFhL',
    createdAt: '2022-03-01T08:53:29.652Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 832,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'quknqJLdt9hf6XaAL',
    createdAt: '2022-03-01T08:53:29.668Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1743,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Sf9qkZNceaM4vEotL',
    createdAt: '2022-03-01T08:53:29.681Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 163,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 't4MGtMNvcyEW5YqBM',
    createdAt: '2022-03-01T08:54:40.444Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 526.5,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'x9bYfZXgs7QHENFPP',
    createdAt: '2022-03-01T08:54:40.468Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 182.59999999999997,
    },
    transactionType: 'transferOut',
    amount: -20.5,
  },
  {
    _id: 'YM5gsE9CuDd5jah3i',
    createdAt: '2022-03-01T08:54:40.484Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 159.5,
    },
    transactionType: 'transferOut',
    amount: -13.5,
  },
  {
    _id: 'z9bcvdQfXCN67Qr3m',
    createdAt: '2022-03-01T08:54:40.501Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3951,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'cXqeQAJvo3NR6Xqmb',
    createdAt: '2022-03-01T08:54:40.520Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4864,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: '99SqApvLRnwqbGxzz',
    createdAt: '2022-03-01T08:56:59.822Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QumbaYQ5YGxq2zmvm',
    createdAt: '2022-03-01T08:56:59.842Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3407.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -850,
  },
  {
    _id: '8NxYbzA4Zdx9BhWk7',
    createdAt: '2022-03-01T08:56:59.858Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hTiSrsinEntCYt3j8',
    createdAt: '2022-03-01T08:56:59.873Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fce5fdb10-574a-4e1d-b066-e5b6405524c9_200x200.jpg?alt=media&token=bda38160-9f70-4c8d-99f9-bbd9a2a1fe4a',
        },
      },
      quantity: 2921.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fnkRvTTuxRyFajRFe',
    createdAt: '2022-03-01T08:56:59.888Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 78.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '6oKGJQzG9THXfF79v',
    createdAt: '2022-03-01T08:56:59.916Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9798.509999999997,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'FefH4scQThaX3d5db',
    createdAt: '2022-03-01T08:56:59.931Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3731,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'raisvC5DizguR7okR',
    createdAt: '2022-03-01T09:01:00.302Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
      },
      quantity: 13869.644250000001,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'yrioJTYZiJhZAmJih',
    createdAt: '2022-03-01T09:01:00.320Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
      },
      quantity: 70.6,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '3y83euWAqaAW2tQSM',
    createdAt: '2022-03-01T09:01:00.362Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 128134.4,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'rg9WnLg3YNCNKTPP3',
    createdAt: '2022-03-01T09:01:00.404Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1774.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -128,
  },
  {
    _id: '7w2FCAvs2joAQB82N',
    createdAt: '2022-03-01T09:01:00.438Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9796.509999999997,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ex2tg5nyFhx8wNHDJ',
    createdAt: '2022-03-01T09:01:00.478Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'i8f557fEjqbN83rws',
    createdAt: '2022-03-01T09:01:00.502Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4837,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'skWz8StqY9fGmBFg4',
    createdAt: '2022-03-01T09:10:56.875Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GmkiPtGv2YaJrhtSL',
    createdAt: '2022-03-01T09:10:56.896Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2557.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'xhAgNov4BGH5DFFkD',
    createdAt: '2022-03-01T09:10:56.916Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bQQi6HbdCv7HMfbSF',
    createdAt: '2022-03-01T09:10:56.935Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2921.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'QLkmShhaQBevuZHzb',
    createdAt: '2022-03-01T09:10:56.953Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1646.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'fBTchPSbP6AN3vg6K',
    createdAt: '2022-03-01T09:10:56.969Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 127134.4,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'itYFgw79kz6tvwhP6',
    createdAt: '2022-03-01T09:10:56.986Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 545,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'F6B4suXysrzQ339Xb',
    createdAt: '2022-03-01T09:10:57.005Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18396,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'DZzwMg87gp6W6NhDv',
    createdAt: '2022-03-01T09:10:57.032Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18300.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'WbLraRs9yHZBLWNB9',
    createdAt: '2022-03-01T09:10:57.052Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 66,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'JJp5EXoXYWYGM9GYi',
    createdAt: '2022-03-01T09:10:57.075Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3924,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '6DirQwiN2Pp9Hioqs',
    createdAt: '2022-03-01T09:44:00.658Z',
    transactionType: 'transferIn',
    amount: 65,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 330,
    },
  },
  {
    _id: 'i4dDZHsWmRNbyyuFR',
    createdAt: '2022-03-01T09:44:44.781Z',
    transactionType: 'transferIn',
    amount: 10,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe357e5dc-766d-4518-9868-05f7651d9abb_200x200.jpg?alt=media&token=2dd741df-52c9-4d15-9d03-5e2aa74ce4dd',
        },
      },
      quantity: 47,
    },
  },
  {
    _id: 'gGSC4zrJqK7rdAiPN',
    createdAt: '2022-03-01T10:24:54.589Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2057.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'PrKWJezL36K3swvmc',
    createdAt: '2022-03-01T10:24:54.608Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'a4dEWe95hncpsvL9o',
    createdAt: '2022-03-01T10:24:54.623Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LWwAohrEe4N3uGJCk',
    createdAt: '2022-03-01T10:24:54.640Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2906.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'Fduo6YgEhhM5vhnEs',
    createdAt: '2022-03-01T10:24:54.656Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 123,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '8aFrhys2rHMoD2j8s',
    createdAt: '2022-03-01T10:24:54.675Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18288.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'RrkhimwWC8BZit3ZW',
    createdAt: '2022-03-01T10:24:54.694Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'M9HLzRiW4PL6zz2hi',
    createdAt: '2022-03-01T10:24:54.711Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 47.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'CjS2mduZ9EHwCuLbQ',
    createdAt: '2022-03-01T10:24:54.731Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 463.4200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'AGZRiyQpkxfnpyPDB',
    createdAt: '2022-03-01T10:24:54.755Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9746.509999999997,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'K74KNNjsoFWZM7aYm',
    createdAt: '2022-03-01T10:24:54.775Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4795,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'hYteYvwmnr8NzPaCC',
    createdAt: '2022-03-01T10:26:44.236Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1257.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'ZfnTrLq8PFF2PsDKC',
    createdAt: '2022-03-01T10:26:44.262Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LzcafY8poDNHfamZX',
    createdAt: '2022-03-01T10:26:44.281Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yZvtLd9TriPytRAgz',
    createdAt: '2022-03-01T10:26:44.295Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2899.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6cCNQoCgbWM8crADx',
    createdAt: '2022-03-01T10:26:44.310Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 116,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SthNgKv2mNdvGmwXn',
    createdAt: '2022-03-01T10:26:44.325Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18276.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'wAbHrmphL9kxve6Yk',
    createdAt: '2022-03-01T10:26:44.342Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '7TB7rco3EEKWWikz7',
    createdAt: '2022-03-01T10:26:44.360Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 47.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'tmKb3CT4t2TjXg6BJ',
    createdAt: '2022-03-01T10:26:44.377Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 461.4200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'dmdm9yu3GDaPToiut',
    createdAt: '2022-03-01T10:26:44.395Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9745.909999999996,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '2XfrjNZtQmzsf7FMM',
    createdAt: '2022-03-01T10:26:44.413Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4721,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'vR4dL7sLKgkGmiZ2A',
    createdAt: '2022-03-01T10:31:24.813Z',
    transactionType: 'in',
    amount: 10000,
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 150,
    },
  },
  {
    _id: 'AiB5NuPuMHDscDxfX',
    createdAt: '2022-03-01T10:31:47.680Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 507,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DCgGubgz8EnRTrWKc',
    createdAt: '2022-03-01T10:31:47.704Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 938,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'WLa7TqGZ3x5E7CKMf',
    createdAt: '2022-03-01T10:31:47.721Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 163.67000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '2ZT746bSpvG7t3saM',
    createdAt: '2022-03-01T10:31:47.737Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2899.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'dHmHHxdXSw2Xr4KrR',
    createdAt: '2022-03-01T10:31:47.770Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'fCNjA7drXhnxFxzqj',
    createdAt: '2022-03-01T10:32:13.538Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 10150,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '9aRaZ3LYHnLAfRKcg',
    createdAt: '2022-03-01T10:32:13.555Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9745.309999999996,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'X7brfwoxP6Fm39W6g',
    createdAt: '2022-03-01T10:32:13.571Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 126884.4,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'MRJkYShQKBhK88qRd',
    createdAt: '2022-03-01T10:32:13.584Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18264.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ArLSrmNf8chFAKqJb',
    createdAt: '2022-03-01T10:32:13.617Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'r6nYqn49rZHsjMQrL',
    createdAt: '2022-03-01T10:34:25.261Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 457.18000000000575,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'uitcoGycDW7v8BMoh',
    createdAt: '2022-03-01T10:34:25.278Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EiPaxuMiL6roi6PuK',
    createdAt: '2022-03-01T10:34:25.297Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2896.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'PhoEpNzizPDjiGDtZ',
    createdAt: '2022-03-01T10:34:25.316Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'HyTuJMYgTpj8fp6pE',
    createdAt: '2022-03-01T10:34:25.340Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 126884.4,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'zoWBDKapJaBuxQAQh',
    createdAt: '2022-03-01T10:34:25.357Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9745.309999999996,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'jWHPREsPHQDsAyuZL',
    createdAt: '2022-03-01T10:34:25.374Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3888,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'npbuXSamRT9ikcGuM',
    createdAt: '2022-03-01T10:38:51.322Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869.644250000001,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'p9AFfP2hqqebAmhRE',
    createdAt: '2022-03-01T10:38:51.339Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 68.6,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '7SGYorLevARyeLcMw',
    createdAt: '2022-03-01T10:38:51.356Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 124334.4,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'zFsze26Z4fmPzggWK',
    createdAt: '2022-03-01T10:38:51.377Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1616.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'pxJckWcP3wnsC4Aoe',
    createdAt: '2022-03-01T10:38:51.396Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9642.309999999996,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'QqYcxphfafKYFHYKP',
    createdAt: '2022-03-01T10:38:51.423Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Hw4Qs4qcJMApy5HPP',
    createdAt: '2022-03-01T10:38:51.441Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4646,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'ie6fE4XuLgY2JAdzh',
    createdAt: '2022-03-01T11:36:57.566Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 9750,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'xkRYRaYAroj8DyeQJ',
    createdAt: '2022-03-01T11:36:57.644Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9542.309999999996,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'KJ6BnkRe5yRtQ48xq',
    createdAt: '2022-03-01T11:36:57.698Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 123334.4,
    },
    transactionType: 'transferOut',
    amount: -2.6,
  },
  {
    _id: 'qxSSFLGRoyMELQC8s',
    createdAt: '2022-03-01T11:36:57.850Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18264.5,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'HCqyPaZDsTQ2nXBgm',
    createdAt: '2022-03-01T11:36:57.887Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'x7ETXuyn8bNimaNhC',
    createdAt: '2022-03-01T11:37:29.319Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9869.644250000001,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'WPvsRPtt7KA5rA9k3',
    createdAt: '2022-03-01T11:37:29.342Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2846.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'gsrZLbgPJSm7c9jZ9',
    createdAt: '2022-03-01T11:37:29.362Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1490.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: 'eT6mPRZjCwcdTHrQb',
    createdAt: '2022-03-01T11:37:29.381Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18376,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'SdF2xFKRFf99qjiQD',
    createdAt: '2022-03-01T11:37:29.400Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -105,
  },
  {
    _id: 'hNLTin9o8cp9Zh7jY',
    createdAt: '2022-03-01T11:37:29.417Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3837,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'buEPDDky6cfyHBsew',
    createdAt: '2022-03-01T11:38:34.672Z',
    transactionType: 'in',
    amount: 593,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 207.18000000000575,
    },
  },
  {
    _id: 'Gh6vm45FL9omzLzGu',
    createdAt: '2022-03-01T12:28:31.193Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 163,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'mrRW2ezecYh5CZtz9',
    createdAt: '2022-03-01T12:28:31.218Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QxtavXBSWu3fTmFJj',
    createdAt: '2022-03-01T12:28:31.243Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 163.42000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'LAJFGQWg4cBT3aBfy',
    createdAt: '2022-03-01T12:28:31.263Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 920,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'EhbWtJ9iRzXqNrbCK',
    createdAt: '2022-03-01T12:28:31.290Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'PC7QWj4SwzgLQJHqn',
    createdAt: '2022-03-01T12:28:31.310Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 61.2,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'cYkLzy6uT5jj3Nh63',
    createdAt: '2022-03-01T12:28:31.338Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 54.50000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: '3amySRg6K5n3AKvAi',
    createdAt: '2022-03-01T12:28:31.359Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 453.4200000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'sgvW7qLgzS9P6RiNx',
    createdAt: '2022-03-01T12:28:31.379Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9506.309999999996,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'rBgszG4FRN9xGTpLe',
    createdAt: '2022-03-01T12:28:31.400Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1148,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'bYAvZDFKY5tfqLQSa',
    createdAt: '2022-03-01T12:28:31.425Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jvcEu5YAc6KsmGmEd',
    createdAt: '2022-03-01T12:28:31.459Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 395,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: '64gD5htknCzKmkzdE',
    createdAt: '2022-03-01T12:28:31.509Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 46,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '9d8io5hAd3Loxa9WD',
    createdAt: '2022-03-01T13:49:01.385Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 919.5,
    },
  },
  {
    _id: 'Me6kbkeeffzEfy73A',
    createdAt: '2022-03-01T17:42:42.897Z',
    transactionType: 'transferIn',
    amount: 1300,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 800.1800000000057,
    },
  },
  {
    _id: 'qGmbDCq69FLbSKroE',
    createdAt: '2022-03-01T17:42:56.568Z',
    transactionType: 'transferIn',
    amount: 1100,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'WF5Ks4vuatur48FSn',
    createdAt: '2022-03-02T05:46:11.699Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 477,
    },
  },
  {
    _id: 'SzwxmqQW65BNxwSZS',
    createdAt: '2022-03-02T05:48:09.412Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1584,
    },
  },
  {
    _id: 'nJsWjj6bpHfGNhXG8',
    createdAt: '2022-03-02T06:38:42.274Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 6560,
    },
  },
  {
    _id: 'WvTwuZaQoGeQPrg3f',
    createdAt: '2022-03-02T06:40:40.615Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2100.1800000000057,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tzrMgiJmtMpCXGZjN',
    createdAt: '2022-03-02T06:40:40.647Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'mrPBo59NMm2ZSqANt',
    createdAt: '2022-03-02T06:40:40.669Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 66.6,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '4GZGZFsnRbs9fdDCE',
    createdAt: '2022-03-02T06:40:40.689Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 123331.79999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '97r7dDMFrkHw5mqgk',
    createdAt: '2022-03-02T06:40:40.713Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1457.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'dP8ozgqixeXSYBY7Y',
    createdAt: '2022-03-02T06:40:40.753Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1136,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'QcZDb7MwRJm7pLpxQ',
    createdAt: '2022-03-02T06:46:49.481Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9269.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4S3EqftEoY86a9vLk',
    createdAt: '2022-03-02T06:46:49.497Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2100.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'i8m4kw9u2gXBpiyg6',
    createdAt: '2022-03-02T06:46:49.515Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mxtXv9FXta6jg9syA',
    createdAt: '2022-03-02T06:46:49.530Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2822.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5tgPNFDaF84Lc3Zc9',
    createdAt: '2022-03-02T06:46:49.545Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 77.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'CmQdh2HM725whKzdz',
    createdAt: '2022-03-02T06:46:49.565Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9506.059999999996,
    },
    transactionType: 'transferOut',
    amount: -1.7,
  },
  {
    _id: 'd9GWfTW4EJzwn5Jun',
    createdAt: '2022-03-02T06:46:49.579Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3681,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'Sv6z3qqkGh4bxfEbd',
    createdAt: '2022-03-02T06:49:49.608Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1216,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'BfxiTSFNL8TgJjoPx',
    createdAt: '2022-03-02T06:49:49.643Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 53.60000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'Yg7XLmGcjugHDKvbN',
    createdAt: '2022-03-02T06:49:49.664Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 163.17000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'YBTqrCJqEQND5dGTt',
    createdAt: '2022-03-02T06:49:49.684Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 453.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'zc4NxTtRNdSnQQzb3',
    createdAt: '2022-03-02T06:49:49.707Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 173.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'tfuFcx5K4iDrDLor4',
    createdAt: '2022-03-02T06:49:49.727Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9504.359999999995,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'qpdW7Y3qww5MD3iHL',
    createdAt: '2022-03-02T06:49:49.750Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 496,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8KDhmv5JBvQvNCwgJ',
    createdAt: '2022-03-02T06:49:49.773Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1019.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'MMpLsqw2HAySCR8Yu',
    createdAt: '2022-03-02T06:49:49.800Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'cXzCxKFfhD8Etmjc2',
    createdAt: '2022-03-02T06:49:49.817Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TxycY2JPjrvrMarZj',
    createdAt: '2022-03-02T06:49:49.841Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1126,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'M28aLMcFf3xTQrdfw',
    createdAt: '2022-03-02T06:51:12.745Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1166,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'R9s352GiGCK8KroZM',
    createdAt: '2022-03-02T06:51:12.772Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 53.20000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'CyBXykEyNLbuthQyS',
    createdAt: '2022-03-02T06:51:12.790Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 162.97000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'f8eEy8zjbXTiyBnoP',
    createdAt: '2022-03-02T06:51:12.821Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 451.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ytWdvze2t7FZ44Bwj',
    createdAt: '2022-03-02T06:51:12.838Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 173.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'HTqQwrCrcxZbuoFa6',
    createdAt: '2022-03-02T06:51:12.855Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9502.859999999995,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'avpKsveWrgvkb97sw',
    createdAt: '2022-03-02T06:51:12.871Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 496,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ege43B4BB7hbJhZcK',
    createdAt: '2022-03-02T06:51:12.887Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1010.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'tfL6SpRt7gkR2TuRT',
    createdAt: '2022-03-02T06:51:12.904Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 148,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'e9TnhpPnCFyNzN8aA',
    createdAt: '2022-03-02T06:51:12.927Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'b98BWsHApDWmhYtwn',
    createdAt: '2022-03-02T06:51:12.954Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1117,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'FoNshgG9WxgzAESxH',
    createdAt: '2022-03-02T06:52:23.255Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1116,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'svXBKycwWaN2bWNM6',
    createdAt: '2022-03-02T06:52:23.277Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 52.80000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'o2rjjuToDrDL4yrCr',
    createdAt: '2022-03-02T06:52:23.294Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 162.7700000000001,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'EnZ3552CcJhpu95N8',
    createdAt: '2022-03-02T06:52:23.320Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 449.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'sK9W68bL6WFy7xxFd',
    createdAt: '2022-03-02T06:52:23.344Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 172.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'mnaZvBLdk9CBMpyQC',
    createdAt: '2022-03-02T06:52:23.365Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9501.359999999995,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '8ALzs2iziZihzLpRP',
    createdAt: '2022-03-02T06:52:23.388Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 496,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rffmLHcH8FTCFKGvM',
    createdAt: '2022-03-02T06:52:23.404Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1002.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'yCetNk7uXYLsNTY3J',
    createdAt: '2022-03-02T06:52:23.423Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 143,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'zDJBhgZ2rr7qdFMGn',
    createdAt: '2022-03-02T06:52:23.445Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QNo8GBwMscyabwxCL',
    createdAt: '2022-03-02T06:52:23.471Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1108,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'kksp9ix5x5CY2TuMY',
    createdAt: '2022-03-02T07:45:15.199Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9269.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '69izq5Lh2q8n9WKvc',
    createdAt: '2022-03-02T07:45:15.222Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1450.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'kMoD3JwyFTq4EgABM',
    createdAt: '2022-03-02T07:45:15.241Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r78Md6iBPBkg7dvAv',
    createdAt: '2022-03-02T07:45:15.268Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2822.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'KixkmnD6HL6Q39wah',
    createdAt: '2022-03-02T07:45:15.288Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1391.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'Aj94MwarnYNCM7H47',
    createdAt: '2022-03-02T07:45:15.305Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 123331.29999999999,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '6bQrrQEoQj8nS2iWq',
    createdAt: '2022-03-02T07:45:15.335Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 535,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'vDhvCJNykc8rsE5Rb',
    createdAt: '2022-03-02T07:45:15.363Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18366,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '38nGFs2Li6ZTzfntG',
    createdAt: '2022-03-02T07:45:15.383Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18234.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'm2Q2CnbcyaLPFiKfc',
    createdAt: '2022-03-02T07:45:15.411Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 695,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'Y4R8mTHv3BvvSTtqH',
    createdAt: '2022-03-02T07:45:15.430Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3795,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'JkKN8itTR5gEQA9iq',
    createdAt: '2022-03-02T07:51:24.740Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 650.1800000000057,
    },
    transactionType: 'transferOut',
    amount: -440,
  },
  {
    _id: 'C2o9cWPTHeiAXdZFv',
    createdAt: '2022-03-02T07:51:24.760Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TifvkjBBBrndbFBqG',
    createdAt: '2022-03-02T07:51:24.774Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9269.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2c6DZCuoMdu4dGE9R',
    createdAt: '2022-03-02T07:51:24.793Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2797.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'HnWjnic8fBAG6pT6k',
    createdAt: '2022-03-02T07:51:24.816Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 116,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'uL3sC29m9BejdjuNb',
    createdAt: '2022-03-02T07:51:24.835Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18224.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '6AB4WSf7DQwJmTkjt',
    createdAt: '2022-03-02T07:51:24.851Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 4,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'TT6mTueujq9Da5d9k',
    createdAt: '2022-03-02T07:51:24.872Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 46.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'M9YYRCgShgnNsrH5Z',
    createdAt: '2022-03-02T07:51:24.903Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 447.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'zsYRieP7Gfqtjcjux',
    createdAt: '2022-03-02T07:51:24.921Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9486.359999999995,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'invWzZKpgxin9AaL3',
    createdAt: '2022-03-02T07:51:24.943Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4603,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'EmfixmQNSqwne9oxi',
    createdAt: '2022-03-02T07:56:49.497Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2793.8000000000006,
    },
  },
  {
    _id: 'H5dSgDcrt7opQy78j',
    createdAt: '2022-03-02T08:06:22.083Z',
    transactionType: 'transferIn',
    amount: 11,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 5,
    },
  },
  {
    _id: 'Pxj3hezXRZcCWdG3g',
    createdAt: '2022-03-02T08:06:48.620Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 430,
    },
  },
  {
    _id: 'Bn2ExwAQns5wTFNzn',
    createdAt: '2022-03-02T08:07:16.199Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8430,
    },
  },
  {
    _id: 'TKEBzHHzsGYsGxYc5',
    createdAt: '2022-03-02T08:26:14.879Z',
    transactionType: 'transferIn',
    amount: 2280.8,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 210.18000000000575,
    },
  },
  {
    _id: 'RmihgaSutfkuQdpp6',
    createdAt: '2022-03-02T09:00:20.424Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 112,
    },
  },
  {
    _id: 'woHu83qojwsrjqXWw',
    createdAt: '2022-03-02T09:00:45.639Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
  },
  {
    _id: 'sPki6FsoTH6wyRRGj',
    createdAt: '2022-03-02T09:11:29.792Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2490.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '36kqiXHpKQqDaoJCX',
    createdAt: '2022-03-02T09:11:29.811Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CC5z4894DYZLFbfzN',
    createdAt: '2022-03-02T09:11:29.828Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9269.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mLQdyfkdMkWNdwuvt',
    createdAt: '2022-03-02T09:11:29.849Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2968.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GvLmrkBJfMnZckXj3',
    createdAt: '2022-03-02T09:11:29.866Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 132,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'F58KsZtwvHytMRGEe',
    createdAt: '2022-03-02T09:11:29.887Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18216.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'p5zxtmoAth3MuMLeH',
    createdAt: '2022-03-02T09:11:29.909Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 3.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'prRxiidbtTPQXYFPA',
    createdAt: '2022-03-02T09:11:29.929Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 46.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'WAdrRsnBSNGpAFJPR',
    createdAt: '2022-03-02T09:11:29.949Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 446.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'CyHRb8RmaDWgdkTF3',
    createdAt: '2022-03-02T09:11:29.965Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9486.059999999996,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'PHpJxFGPF4oSWN74g',
    createdAt: '2022-03-02T09:11:29.978Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4563,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'PoCmsKs2HvqgfPgk9',
    createdAt: '2022-03-02T09:20:17.027Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9269.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6ja8gmhBxdSG4xqWn',
    createdAt: '2022-03-02T09:20:17.044Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1690.980000000006,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'CRhrLzZM7ZyATw5p7',
    createdAt: '2022-03-02T09:20:17.058Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NwM34icJFWzSh56sk',
    createdAt: '2022-03-02T09:20:17.072Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2968.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '6NWEmPLGtfsKhgeYp',
    createdAt: '2022-03-02T09:20:17.091Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 132,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'bYTeXZ7r5uzMRmxFL',
    createdAt: '2022-03-02T09:20:17.107Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 76.5,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'yogecnrv9wy7f2mKT',
    createdAt: '2022-03-02T09:20:17.145Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18204.5,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'cjhhJvnhBS53b59Wp',
    createdAt: '2022-03-02T09:20:17.160Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3637,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: 'nSfDzTuTrB7qJ9R5T',
    createdAt: '2022-03-02T09:23:10.270Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 496,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DM9h9NFiNyNMRMtgQ',
    createdAt: '2022-03-02T09:23:10.285Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 993.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'bf8pSov7uptkgsXCm',
    createdAt: '2022-03-02T09:23:10.301Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 162.5700000000001,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'KAo8od8r3JSwvGmrS',
    createdAt: '2022-03-02T09:23:10.315Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2965.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'kvRtso6ushZnLKis3',
    createdAt: '2022-03-02T09:23:10.359Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'uK9ctdaZ7m9YWgc3t',
    createdAt: '2022-03-02T09:58:39.141Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 438.0200000000001,
    },
  },
  {
    _id: '9HzfjeZHpLM8xNbm2',
    createdAt: '2022-03-02T09:59:45.080Z',
    transactionType: 'transferIn',
    amount: 6,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 1,
    },
  },
  {
    _id: 'ESY2TgQhS233DQFvL',
    createdAt: '2022-03-02T10:05:13.823Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F836955bb-4c46-4d95-aa0d-4cd057c4d7e9_200x200.jpg?alt=media&token=cc75a865-f8ae-400a-a2c5-2249a2829536',
        },
      },
      quantity: 3,
    },
  },
  {
    _id: 'BX6iNNHmGjmg7bRdF',
    createdAt: '2022-03-02T10:39:30.870Z',
    transactionType: 'transferIn',
    amount: 46,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 138,
    },
  },
  {
    _id: 'D2yHW999RCxDuXWCS',
    createdAt: '2022-03-02T11:25:49.595Z',
    transactionType: 'in',
    amount: 2600,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9269.644250000001,
    },
  },
  {
    _id: 'B3obNAgmXE85LJjKc',
    createdAt: '2022-03-02T11:26:14.406Z',
    transactionType: 'in',
    amount: 5000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869.644250000001,
    },
  },
  {
    _id: '4sqh7XTpPYyBJT6Sm',
    createdAt: '2022-03-02T11:26:41.933Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 112.5,
    },
  },
  {
    _id: 'rGsDBSv6qu63yZmnM',
    createdAt: '2022-03-02T11:42:33.413Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 462.5,
    },
    transactionType: 'transferOut',
    amount: -255,
  },
  {
    _id: '7ZF39LTrALZqEmva8',
    createdAt: '2022-03-02T11:42:33.429Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9070,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'GMgHWXWbR5cQYQYSp',
    createdAt: '2022-03-02T11:42:33.445Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3320,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'diQEyMjed2cbKzC5v',
    createdAt: '2022-03-02T11:42:33.460Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 932,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'kNj48uewpz64KdhCS',
    createdAt: '2022-03-02T11:43:43.404Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 207.5,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'ShhM6HLhwYJditcxp',
    createdAt: '2022-03-02T11:43:43.892Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8970,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'Z8PdFie7EfFMipkBg',
    createdAt: '2022-03-02T11:43:43.934Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 923,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'fm6yeWjhwRTLako9o',
    createdAt: '2022-03-02T11:46:51.151Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 154.5,
    },
    transactionType: 'transferOut',
    amount: -154.5,
  },
  {
    _id: 'wyCvvAXg4SAdmh4Tw',
    createdAt: '2022-03-02T11:46:51.166Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 2.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'd62TK9FyhNjxXZFjz',
    createdAt: '2022-03-02T11:46:51.200Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 270,
    },
    transactionType: 'in',
    amount: 154,
  },
  {
    _id: 'iMsETBRnfZMszzeYi',
    createdAt: '2022-03-02T11:50:19.106Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 424,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'qkgPRw2fGwgA9sMAg',
    createdAt: '2022-03-02T11:50:19.122Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8918,
    },
    transactionType: 'transferOut',
    amount: -61,
  },
  {
    _id: 'Hkd5BEeck3pY95G82',
    createdAt: '2022-03-02T11:50:19.141Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 918,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'cAoGxGqK5ogha8SPa',
    createdAt: '2022-03-02T11:52:27.235Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 364,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'AN3PGpZoXhikfPLZC',
    createdAt: '2022-03-02T11:52:27.272Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8857,
    },
    transactionType: 'transferOut',
    amount: -151,
  },
  {
    _id: 'ip4X2h2JyjTZbWCEc',
    createdAt: '2022-03-02T11:52:27.422Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 913,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'SNbnwJoZaGW3bKdKZ',
    createdAt: '2022-03-02T12:14:12.039Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16869.64425,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'bc5LSnEy2r3hmDf86',
    createdAt: '2022-03-02T12:14:12.225Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 527,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: '49CWvaoZtcvt9885z',
    createdAt: '2022-03-02T12:14:12.264Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 463.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'qxrYiaMfP3qfbGi75',
    createdAt: '2022-03-02T12:14:12.306Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2084,
    },
    transactionType: 'transferOut',
    amount: -226,
  },
  {
    _id: 'v2Ldb56EvtfTaGoNs',
    createdAt: '2022-03-02T12:14:12.787Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1099,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'pA6HEgQPgAALxAvnu',
    createdAt: '2022-03-02T12:43:23.138Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4489,
    },
  },
  {
    _id: '96Hyq4P8mrdZCPaZw',
    createdAt: '2022-03-02T12:44:53.799Z',
    transactionType: 'transferIn',
    amount: 140,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3565,
    },
  },
  {
    _id: 'FqRehd2HY3AK8dqRR',
    createdAt: '2022-03-02T12:45:34.036Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3735,
    },
  },
  {
    _id: 'ms7S9R32mwY2eQwRA',
    createdAt: '2022-03-02T12:46:27.728Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1087,
    },
  },
  {
    _id: 'c9aWB4NSevv9fGcbf',
    createdAt: '2022-03-03T04:57:23.443Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 482,
    },
  },
  {
    _id: 'GZB4pKYteFp2tDb4W',
    createdAt: '2022-03-03T04:57:55.645Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'HD6kFyoB86PuxA3ow',
        name: 'HAND SLEEVES ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc98a9b17-9a15-4a0c-844b-63af5c506ee5_200x200.webp?alt=media&token=a218086e-6af4-4827-9f13-f35f9b1d3f9c',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'pKRddQWRjtW7XhtaM',
    createdAt: '2022-03-03T05:53:04.911Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1390.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'hyXXSqfgiqJE35BfL',
    createdAt: '2022-03-03T05:53:04.938Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'v6KAC4uu5jBzmibAw',
    createdAt: '2022-03-03T05:53:04.956Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16719.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'maLGbxkZPvfsdFtzh',
    createdAt: '2022-03-03T05:53:04.982Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2962.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'y3G5E4ewKre8bc329',
    createdAt: '2022-03-03T05:53:04.999Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i5JK2PN34A8rP3afq',
    createdAt: '2022-03-03T05:53:05.019Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18200.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'JYKF9RpB4TbfN8rJd',
    createdAt: '2022-03-03T05:53:05.036Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 2.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'G8f4pJsTmpvdLqS6B',
    createdAt: '2022-03-03T05:53:05.059Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 45.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '9GGRMAX7RTbExP5QB',
    createdAt: '2022-03-03T05:53:05.076Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 459.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'R4RfwRbLmZ4C2aLSL',
    createdAt: '2022-03-03T05:53:05.094Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9485.459999999995,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'HRk3j6o4AD44HddBn',
    createdAt: '2022-03-03T05:53:05.111Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4689,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'Ft3AckWehJKe5wmfx',
    createdAt: '2022-03-03T06:35:45.071Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16719.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'SBxZY5mwFcmwxtD2F',
    createdAt: '2022-03-03T06:35:45.092Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 65.6,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2uZCyMm8XmCgZBPDw',
    createdAt: '2022-03-03T06:35:45.114Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 122931.29999999999,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'dmtEiPw48bEtezToe',
    createdAt: '2022-03-03T06:35:45.128Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1346.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'Lv68Lg3XCpk9kRytC',
    createdAt: '2022-03-03T06:35:45.146Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9484.859999999995,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '5KhKBPcn543Nid2K6',
    createdAt: '2022-03-03T06:35:45.167Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '2JZGajCnfJQj7bs9u',
    createdAt: '2022-03-03T06:35:45.187Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4615,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: '7kowSJk6B98fjAB2y',
    createdAt: '2022-03-03T06:38:54.511Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 8990,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'YkKhdoBE7P2RvKCyC',
    createdAt: '2022-03-03T06:38:54.524Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9434.859999999995,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'sBHGecKBmdXu6eNJd',
    createdAt: '2022-03-03T06:38:54.537Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121931.29999999999,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'G8q2nqiJzmrNmGYC8',
    createdAt: '2022-03-03T06:38:54.554Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18188.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NgL3HoD3ENciNCSH7',
    createdAt: '2022-03-03T06:38:54.587Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'wdTwrDNhz2bLzyrb6',
    createdAt: '2022-03-03T06:40:42.416Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 622,
    },
  },
  {
    _id: 'pMNJvgoEEYxfvxiwb',
    createdAt: '2022-03-03T06:42:48.656Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14719.644250000001,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: '5y4MxLtYHuj2CRzFW',
    createdAt: '2022-03-03T06:42:48.675Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 63.599999999999994,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '4xncQmJr4WLHwdYva',
    createdAt: '2022-03-03T06:42:48.690Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121681.29999999999,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'vXMWQSjmso9b5JMmz',
    createdAt: '2022-03-03T06:42:48.704Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1220.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'HL4ySgxSyBjYWnCYx',
    createdAt: '2022-03-03T06:42:48.717Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9334.859999999995,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'c8ZWtHNXTt4LLJvKh',
    createdAt: '2022-03-03T06:42:48.739Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'rCJm4P62XsQrgcPo2',
    createdAt: '2022-03-03T06:42:48.756Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4572,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'bPLoDMpdEYRntcmhm',
    createdAt: '2022-03-03T06:46:28.065Z',
    transactionType: 'transferIn',
    amount: 2333,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 590.9800000000059,
    },
  },
  {
    _id: 'J6RgCnAKcvriMnyM2',
    createdAt: '2022-03-03T07:01:12.585Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9234.859999999995,
    },
  },
  {
    _id: 'CRbtdyYtdGNBmSvnf',
    createdAt: '2022-03-03T07:01:54.877Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 451.0200000000001,
    },
  },
  {
    _id: 'psXPtG9JSyjHyoGhf',
    createdAt: '2022-03-03T07:02:41.503Z',
    transactionType: 'transferIn',
    amount: 25000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 121581.29999999999,
    },
  },
  {
    _id: '28WK2K5M78ajtWCfr',
    createdAt: '2022-03-03T07:03:23.695Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2962.8000000000006,
    },
  },
  {
    _id: 'FY2satFqxet7TB8dB',
    createdAt: '2022-03-03T07:04:18.937Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1147,
    },
  },
  {
    _id: 'J8G4bSWh5Lj4ihfM5',
    createdAt: '2022-03-03T07:05:10.175Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 900,
    },
  },
  {
    _id: 't4HrjhJTLnwT9C5oS',
    createdAt: '2022-03-03T07:09:11.415Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12719.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3B69swQd82CLKtSmy',
    createdAt: '2022-03-03T07:09:11.435Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2923.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1050,
  },
  {
    _id: 'LSFzBRc2ZTnrP5jtK',
    createdAt: '2022-03-03T07:09:11.460Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'noujSwS4mnQXAkFHQ',
    createdAt: '2022-03-03T07:09:11.489Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3137.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: 'hGM3xNyYWLH89c8kJ',
    createdAt: '2022-03-03T07:09:11.512Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1094.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'MpeJj5tZcmX4DuoPt',
    createdAt: '2022-03-03T07:09:11.534Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 146581.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'YPhxxcuLh7WZomPtk',
    createdAt: '2022-03-03T07:09:11.553Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 519,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'kwPQGWH2o5hDFMaS6',
    createdAt: '2022-03-03T07:09:11.575Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18341,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'k8cTBhGt8sB6yt8pY',
    createdAt: '2022-03-03T07:09:11.594Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18188.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'w65M5urB3yhfWLHxR',
    createdAt: '2022-03-03T07:09:11.611Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 565,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'x4JucMYu8dcQFwgxP',
    createdAt: '2022-03-03T07:09:11.630Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3835,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'NKPbncgMjz579cd32',
    createdAt: '2022-03-03T09:03:15.700Z',
    transactionType: 'transferIn',
    amount: 220,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1873.980000000006,
    },
  },
  {
    _id: 'a3Xz4zXwMbWopaMvH',
    createdAt: '2022-03-03T09:04:38.597Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1100,
    },
  },
  {
    _id: 'bt9DcKHAQZDtdNZiH',
    createdAt: '2022-03-03T10:13:07.028Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2093.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'jgaMwtnYLE6dKcNbY',
    createdAt: '2022-03-03T10:13:07.054Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AkByW9tTvymKWg3Ec',
    createdAt: '2022-03-03T10:13:07.077Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12719.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'A425kXkn5rNgfr6wY',
    createdAt: '2022-03-03T10:13:07.096Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3106.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cSdPNBE2tBp3DibBf',
    createdAt: '2022-03-03T10:13:07.115Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tZ8ANpQWPTzs8aga2',
    createdAt: '2022-03-03T10:13:07.143Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18176.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'QPrafGqj6potEhDNb',
    createdAt: '2022-03-03T10:13:07.159Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 1.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '85cS9dwJ3NauRoc5j',
    createdAt: '2022-03-03T10:13:07.176Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 45.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ZgbaFbXupJ5GawpLv',
    createdAt: '2022-03-03T10:13:07.197Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 476.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'LtzRPE3AidroThMox',
    createdAt: '2022-03-03T10:13:07.212Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9734.859999999995,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'RJdhnCJuoR6XHoMua',
    createdAt: '2022-03-03T10:13:07.232Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4529,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: '3rXaohy7uFx7c9jto',
    createdAt: '2022-03-03T10:26:07.272Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8706,
    },
  },
  {
    _id: 'CTn4gkGfu55cqKZn7',
    createdAt: '2022-03-03T10:26:45.798Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4452,
    },
  },
  {
    _id: 'jyi7ifDgzsxfJFbXj',
    createdAt: '2022-03-03T11:00:04.540Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 469.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'yY3HN87yCDssasTdK',
    createdAt: '2022-03-03T11:00:04.571Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21799.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'mexEJkymdZ6iEGDNR',
    createdAt: '2022-03-03T11:00:04.593Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 507.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'wxCzxazfWpi9XTtNj',
    createdAt: '2022-03-03T11:00:04.614Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 160,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'KutN5F4TNA3fdWR9Q',
    createdAt: '2022-03-03T11:04:40.796Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 447.5,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: 'ANF2qqkywT2AqdqvL',
    createdAt: '2022-03-03T11:04:40.823Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21793.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '3WEfFk3ykJuLCPESh',
    createdAt: '2022-03-03T11:04:40.840Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 501.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'hiLZFbMKfwyQYCdfA',
    createdAt: '2022-03-03T11:04:40.860Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 159,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'M7q9tCpYWFWa4bfsi',
    createdAt: '2022-03-03T11:06:39.404Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 424.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Yuu9rqPwByPsqatRy',
    createdAt: '2022-03-03T11:06:39.436Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21787.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'rAdrN3A9CEKMhscqu',
    createdAt: '2022-03-03T11:06:39.466Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 495.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'rN95ZDobTnRkrLvCw',
    createdAt: '2022-03-03T11:11:29.677Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12719.644250000001,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'tmLyhXXKHF5Lqy5j9',
    createdAt: '2022-03-03T11:11:29.698Z',
    storeItem: {
      type: {
        _id: 'BWEYgyyXW9LZb2vaG',
        name: 'WHITE DAILY 40',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1f6ed39d-17eb-4a2f-906e-3abfb2631b6a_200x200.jpg?alt=media&token=f146a98a-a0b7-4a47-bcfe-5077a6e994e3',
        },
      },
      quantity: 48.5,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'rCBRiDt5xHFrCkBX4',
    createdAt: '2022-03-03T11:11:29.716Z',
    storeItem: {
      type: {
        _id: 'qKCYAS77NQLBFFfji',
        name: 'CT 321',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8232fa90-3584-4984-931b-d22cd2eecdb0_200x200.jpg?alt=media&token=22ad60ce-a49d-4d5f-8ced-0d43d83110e2',
        },
      },
      quantity: 39,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5HSYGXgLhFnZWso4f',
    createdAt: '2022-03-03T11:11:29.738Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1044.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -14.5,
  },
  {
    _id: 'cAnrfyZRryEzGAa8f',
    createdAt: '2022-03-03T11:11:29.761Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'AopqtS9EhAw754YxM',
    createdAt: '2022-03-03T11:11:29.782Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21781.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'f5NkcrAL7o9XFAzou',
    createdAt: '2022-03-03T11:11:29.798Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1733,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'kFGCrnP24sKDTuy6A',
    createdAt: '2022-03-03T11:16:38.092Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1263.980000000006,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'oNwGD8iHWoivSx3Hq',
    createdAt: '2022-03-03T11:16:38.122Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'woqKiZ6KHe4hXLu6y',
    createdAt: '2022-03-03T11:16:38.145Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12469.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eWBfw46pP5djgubwJ',
    createdAt: '2022-03-03T11:16:38.166Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3106.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '78N2MfuPtM2duFPmz',
    createdAt: '2022-03-03T11:16:38.180Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1029.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'SBmjXhWdfkZzfpcRL',
    createdAt: '2022-03-03T11:16:38.195Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 146081.3,
    },
    transactionType: 'transferOut',
    amount: -325,
  },
  {
    _id: 'YzjLsxjxZr2FbmrKh',
    createdAt: '2022-03-03T11:16:38.210Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18311,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '7wko2bhnWJ3kWw5Hb',
    createdAt: '2022-03-03T11:16:38.225Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 494,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ASHBQGopdkfcTTB8Q',
    createdAt: '2022-03-03T11:16:38.241Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 405,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'bbmrycGASttPsMgKY',
    createdAt: '2022-03-03T11:16:38.260Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3760,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'fEQwvE5xcQF427Jvj',
    createdAt: '2022-03-03T11:19:15.531Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1066,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'ooo6EJYpaZX932ysm',
    createdAt: '2022-03-03T11:19:15.557Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 162.3200000000001,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '2irZCPLCZHvwrz8cQ',
    createdAt: '2022-03-03T11:19:15.574Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 52.40000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'jKKNWpP7RCQrcfPNh',
    createdAt: '2022-03-03T11:19:15.593Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 468.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '4mCgrKm5rPmRm3TLi',
    createdAt: '2022-03-03T11:19:15.614Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 172.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '5BNwogrfEuK8oz7Wk',
    createdAt: '2022-03-03T11:19:15.635Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9734.259999999995,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GrjK7RcGvmTuPLmaP',
    createdAt: '2022-03-03T11:19:15.653Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 486,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tHZRbrDZ6hP72utxD',
    createdAt: '2022-03-03T11:19:15.673Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 976.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'TPKgAuKEdsAX6g5aX',
    createdAt: '2022-03-03T11:19:15.698Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 184,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tJwBSRo4hEaBpk8iC',
    createdAt: '2022-03-03T11:19:15.741Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PcLKmbLS5uBDFXzBJ',
    createdAt: '2022-03-03T11:19:15.767Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1177,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'noKtZpWPKoXroe7fH',
    createdAt: '2022-03-03T11:21:11.218Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1011,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'AYpHHLqNPKRSj7s4m',
    createdAt: '2022-03-03T11:21:11.268Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 162.12000000000012,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '8iRtoS84m47RJn8HY',
    createdAt: '2022-03-03T11:21:11.286Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 52.000000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'rsPNtxrgRgosAnAGN',
    createdAt: '2022-03-03T11:21:11.304Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 466.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2apQdybEKbsjWFdA4',
    createdAt: '2022-03-03T11:21:11.322Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 171.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'YLgcaKwQH7BQMe5K8',
    createdAt: '2022-03-03T11:21:11.339Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9733.259999999995,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '2vmGwKr3rugpSZuWr',
    createdAt: '2022-03-03T11:21:11.354Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 486,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2EjQuacQ96YZuwNuz',
    createdAt: '2022-03-03T11:21:11.378Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 967.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'taecTLCRgWF4px9Pc',
    createdAt: '2022-03-03T11:21:11.396Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 184,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tYdizuucxpubfFCJB',
    createdAt: '2022-03-03T11:21:11.414Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YToT2y4QcpMkpmQvG',
    createdAt: '2022-03-03T11:21:11.436Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1168,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'xobAxw9SCAE48YTRr',
    createdAt: '2022-03-03T11:22:25.283Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 961,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'uwNhMamY45MSfGhBR',
    createdAt: '2022-03-03T11:22:25.309Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 161.92000000000013,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '4vGFbqRTetroBafhr',
    createdAt: '2022-03-03T11:22:25.335Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 51.600000000000016,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'YaqpWYCT8wjshiojz',
    createdAt: '2022-03-03T11:22:25.353Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 464.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'DZweTpXqZ5cTkiYsp',
    createdAt: '2022-03-03T11:22:25.369Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 171.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'WgrD5jPq7vNBY69Qy',
    createdAt: '2022-03-03T11:22:25.384Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9732.259999999995,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'kBnvM8AHACAYDuX7X',
    createdAt: '2022-03-03T11:22:25.405Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 486,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oeSWmPZFS8E86FaEy',
    createdAt: '2022-03-03T11:22:25.422Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 958.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'XHxfNyFEzF8EoydHu',
    createdAt: '2022-03-03T11:22:25.440Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 184,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'B6awAM27zdQcr9GKZ',
    createdAt: '2022-03-03T11:22:25.457Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '64vQsMxwfy7H9CCGj',
    createdAt: '2022-03-03T11:22:25.483Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1159,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'e4kdgdEHCZsyoxwLB',
    createdAt: '2022-03-03T11:49:17.231Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 613.9800000000059,
    },
  },
  {
    _id: 'R9EtRGRppej3wsA2E',
    createdAt: '2022-03-03T11:52:09.859Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2613.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'GioWjScH7QL3DZn95',
    createdAt: '2022-03-03T11:52:09.874Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5zWFdmGYTE6XDJXWR',
    createdAt: '2022-03-03T11:52:09.890Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12469.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cgLMmXczc2gRrSJab',
    createdAt: '2022-03-03T11:52:09.905Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3086.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bjnwJGosB7WLd2N7Q',
    createdAt: '2022-03-03T11:52:09.924Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nekH8L6MJJfxr5ccR',
    createdAt: '2022-03-03T11:52:09.942Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18164.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '8XZcP9o898gBq6FbS',
    createdAt: '2022-03-03T11:52:09.974Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'nv5tJGpG2YAphvvKL',
    createdAt: '2022-03-03T11:52:09.993Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 44.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'nKZ9wBru6FCbQc53m',
    createdAt: '2022-03-03T11:52:10.033Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 462.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'ELmm5Hn5e8eeAv259',
    createdAt: '2022-03-03T11:52:10.049Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9731.259999999995,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '3FmdAfwQaQcNRLHQS',
    createdAt: '2022-03-03T11:52:10.065Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4652,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'f7nf9B9rsCqknqfXF',
    createdAt: '2022-03-03T11:58:38.000Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 402.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'MFoPiCjZkbPj9QxBb',
    createdAt: '2022-03-03T11:58:38.172Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
      },
      quantity: 90,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '8KgM74PFucDepFQ5G',
    createdAt: '2022-03-03T11:58:38.226Z',
    storeItem: {
      type: {
        _id: 'j9t7smSeagqHpqf26',
        name: 'PANEER CHEESE VACUUM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F72b43ae2-22ed-4b03-8e77-ca2427556884_200x200.jpg?alt=media&token=f4bd6370-4319-4da9-bf27-3d7f71fbb375',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D6wwxNHBKJj2hgAHp',
    createdAt: '2022-03-03T11:58:38.295Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 61.599999999999994,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'zp4rNNBTg8utyopSQ',
    createdAt: '2022-03-03T11:58:38.336Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9730.659999999994,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'DWK8D3umETsHkXW5b',
    createdAt: '2022-03-03T11:58:38.874Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 822,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'gNCkabBNH9NHAeWaK',
    createdAt: '2022-03-03T11:58:38.905Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1728,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'aZrG3fqkfvnPjEnXp',
    createdAt: '2022-03-03T11:58:39.028Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 158,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'BZtbLQatiQeuSkr5R',
    createdAt: '2022-03-03T11:59:12.409Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3710,
    },
  },
  {
    _id: 'bHgmDoFXYgn5mL6hN',
    createdAt: '2022-03-03T12:15:13.592Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12469.644250000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '3jnCLKCMteHoRCFxx',
    createdAt: '2022-03-03T12:15:13.727Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1783.980000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'bNypPKCGJKq6aCTzi',
    createdAt: '2022-03-03T12:15:13.764Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 507,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'hihTA2MLyGfdmvWko',
    createdAt: '2022-03-03T12:15:14.159Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 454.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'rc642assCX5DoJ39e',
    createdAt: '2022-03-03T12:15:14.177Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1858,
    },
    transactionType: 'transferOut',
    amount: -102,
  },
  {
    _id: 'x3rktJpQRA3NQBv2v',
    createdAt: '2022-03-03T12:15:14.193Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1150,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'ZTELjuqNaW7ipdsB5',
    createdAt: '2022-03-03T12:37:27.128Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1583.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'M7tE2ySFfaNr7dnXw',
    createdAt: '2022-03-03T12:37:27.268Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QYvheRdqDczMpZrkR',
    createdAt: '2022-03-03T12:37:27.306Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12419.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XAdTyPnEK4GF2mnJX',
    createdAt: '2022-03-03T12:37:27.350Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3086.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3yQ99nNjk4Lu634yD',
    createdAt: '2022-03-03T12:37:27.398Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KFWt2vekPsPWwjhr9',
    createdAt: '2022-03-03T12:37:27.439Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18152.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pxtHdW8mfWB3GyS87',
    createdAt: '2022-03-03T12:37:27.471Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8hQADN9fD4PCSEkih',
    createdAt: '2022-03-03T12:37:27.977Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 76.2,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'mYfGHNsJ57eYLKvzK',
    createdAt: '2022-03-03T12:37:28.021Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 43.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'n5S9Ew2sBCWjd3pv7',
    createdAt: '2022-03-03T12:37:28.061Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'iXxSEoS6htSse4LRp',
    createdAt: '2022-03-03T12:37:28.191Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9730.159999999994,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'EL6JfKTFbLFqQTf9N',
    createdAt: '2022-03-03T12:37:28.224Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4575,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'rK5iffo6wZX4T9zFe',
    createdAt: '2022-03-03T12:46:45.299Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 753.9800000000059,
    },
  },
  {
    _id: '65Qe8itZ3uvdswr9e',
    createdAt: '2022-03-03T12:48:29.396Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12419.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iGTAWMBFByEc5KeJw',
    createdAt: '2022-03-03T12:48:29.415Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2753.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'nvCxSkMy2cDK9WwQn',
    createdAt: '2022-03-03T12:48:29.433Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3086.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'W4q6bhRRZLzSqkZis',
    createdAt: '2022-03-03T12:48:29.456Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 28.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ZYMxR89JNSMPoGG5C',
    createdAt: '2022-03-03T12:48:29.480Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9729.559999999994,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'nEdF3QYhgkLT6m2F2',
    createdAt: '2022-03-03T12:48:29.498Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18152.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '6g2tjmb8rM5gFAaEp',
    createdAt: '2022-03-03T12:48:29.524Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 43.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'hw89ogvuLQEigKvrQ',
    createdAt: '2022-03-03T12:48:29.545Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4498,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'KMgZo6sybbpd4v8mS',
    createdAt: '2022-03-03T12:48:29.593Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 382.5,
    },
    transactionType: 'in',
    amount: 110,
  },
  {
    _id: 'BYCFybXonzSCvbRLT',
    createdAt: '2022-03-03T13:04:48.167Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12419.644250000001,
    },
    transactionType: 'transferOut',
    amount: -510,
  },
  {
    _id: 'QYh7JJ69jHwZmN9YZ',
    createdAt: '2022-03-03T13:04:48.202Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3086.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'xZJXuS8QBSfFFjzZQ',
    createdAt: '2022-03-03T13:04:48.224Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 989.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'pZJSqKA99HfASFXwm',
    createdAt: '2022-03-03T13:04:48.240Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18281,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'pA9DQpG78LKhMMbzR',
    createdAt: '2022-03-03T13:04:48.275Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 295,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'RpHa3jC77byuLrWec',
    createdAt: '2022-03-03T13:04:48.303Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3910,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: 'vL2XZDwL9fFo2Xm9X',
    createdAt: '2022-03-03T13:10:19.854Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11909.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QtCwQN2nG9upy4Nee',
    createdAt: '2022-03-03T13:10:19.899Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1953.980000000006,
    },
    transactionType: 'transferOut',
    amount: -222,
  },
  {
    _id: '52amLTvuR2MzEvsLR',
    createdAt: '2022-03-03T13:10:19.933Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3071.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: '7rdtSwN4b8dscrPJH',
    createdAt: '2022-03-03T13:10:19.959Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -62,
  },
  {
    _id: 'o3yPewdFZwC2Ry2KF',
    createdAt: '2022-03-03T13:10:19.975Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 145756.3,
    },
    transactionType: 'transferOut',
    amount: -2049,
  },
  {
    _id: '9MHaxoy8evr48Sq8a',
    createdAt: '2022-03-03T13:10:19.991Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9728.559999999994,
    },
    transactionType: 'transferOut',
    amount: -2.67,
  },
  {
    _id: 'xXB74uGdZMepmwSwm',
    createdAt: '2022-03-03T13:10:20.032Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 814,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'ybt8K5zhReXRuLobg',
    createdAt: '2022-03-03T13:10:20.054Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1720,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'z9KvoyyWnnLAaMKgz',
    createdAt: '2022-03-03T13:12:28.717Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1731.980000000006,
    },
    transactionType: 'transferOut',
    amount: -28,
  },
  {
    _id: '82QorhEBq57dxZB73',
    createdAt: '2022-03-03T13:12:28.732Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11909.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uiMwEcaNvmyw6Sqiw',
    createdAt: '2022-03-03T13:12:28.748Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3027.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -5.6,
  },
  {
    _id: 'ZaWcgEcvGW4Gpgruo',
    createdAt: '2022-03-03T13:12:28.764Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 8,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'qN3XDzxoMzHgXrWLc',
    createdAt: '2022-03-03T13:12:28.791Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 143707.3,
    },
    transactionType: 'transferOut',
    amount: -261,
  },
  {
    _id: 't3oG7tauFZR5Qdj3w',
    createdAt: '2022-03-03T13:12:28.807Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9725.889999999994,
    },
    transactionType: 'transferOut',
    amount: -0.34,
  },
  {
    _id: 'GLH4C5WAsd6HDQYPK',
    createdAt: '2022-03-03T13:12:28.823Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3876,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'RPRtySYFuSAHXpd4r',
    createdAt: '2022-03-03T13:20:01.200Z',
    transactionType: 'in',
    amount: 2900,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11909.644250000001,
    },
  },
  {
    _id: 'jXoaMrewergQKJ4xv',
    createdAt: '2022-03-03T13:20:19.374Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'xigwZBscJQ4Xe2QtR',
    createdAt: '2022-03-03T13:30:06.766Z',
    transactionType: 'out',
    amount: -830,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2533.980000000006,
    },
  },
  {
    _id: '65LAvAaqgSgXgz4Az',
    createdAt: '2022-03-03T14:47:20.494Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1703.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7gFsYoueoz3rYFkFi',
    createdAt: '2022-03-03T14:47:20.514Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'WBTXNzzLWgsBpcD2K',
    createdAt: '2022-03-03T14:47:20.536Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 61.39999999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'TxYZXzxZKSuYgdcGW',
    createdAt: '2022-03-03T14:47:20.554Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 143446.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '4yntY9DEXHv5ym8qy',
    createdAt: '2022-03-03T14:47:20.575Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 959.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'fCxD8kHYYQJYpornY',
    createdAt: '2022-03-03T14:47:20.619Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 911,
    },
    transactionType: 'in',
    amount: 75,
  },
  {
    _id: 'ukjtXPqgE2FLvpedj',
    createdAt: '2022-03-04T07:18:59.196Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 184,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'tYWshu8x6oYhEfcYP',
    createdAt: '2022-03-04T07:18:59.234Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QsARvisyBStuiWrmX',
    createdAt: '2022-03-04T07:18:59.260Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 161.72000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'HiSBg77KjXEzNrJdw',
    createdAt: '2022-03-04T07:18:59.283Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 949.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'W4qHwyCgzqRgYQkZk',
    createdAt: '2022-03-04T07:18:59.302Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 486,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'oBvMr9BBhAjjDYg3r',
    createdAt: '2022-03-04T07:18:59.322Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 60.800000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '2tPKdQJJxWeXKaDF3',
    createdAt: '2022-03-04T07:18:59.342Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 51.20000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'zigo7pB76kao6FhyP',
    createdAt: '2022-03-04T07:18:59.364Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.0200000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '99PTz8Q4hbXKRJDNh',
    createdAt: '2022-03-04T07:18:59.383Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9726.149999999994,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '7XAJKPsh2gPXcTeRQ',
    createdAt: '2022-03-04T07:18:59.403Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 986,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'SECqwBA46aCXnw6Nj',
    createdAt: '2022-03-04T07:18:59.427Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bMSQMHh8hEgNsp3zi',
    createdAt: '2022-03-04T07:18:59.447Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 331,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'Lwft2T673iJYdQSo6',
    createdAt: '2022-03-04T07:18:59.497Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 40,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '8ZbiWEbZQakYXp3c2',
    createdAt: '2022-03-04T08:30:30.685Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14809.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FNtk4kXY8sghacRHt',
    createdAt: '2022-03-04T08:30:31.183Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1703.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1600,
  },
  {
    _id: 'QXvCfhtgXiXdLAG8C',
    createdAt: '2022-03-04T08:30:31.213Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pQjb2qFWbcTHRgujF',
    createdAt: '2022-03-04T08:30:31.319Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3022.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'W6FZ7GCsSLCR9JNEK',
    createdAt: '2022-03-04T08:30:31.341Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 895.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'wANXh6zbtMxv9rPtT',
    createdAt: '2022-03-04T08:30:31.362Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 142946.3,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'DBTgaDACs5CCfodCd',
    createdAt: '2022-03-04T08:30:31.380Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 474,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'AEGtaBEbuZbyqA6Tn',
    createdAt: '2022-03-04T08:30:31.901Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18261,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'wE4HDJzrFTKfi68pj',
    createdAt: '2022-03-04T08:30:31.953Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18154.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'FRT6akB2ATBJTPGuZ',
    createdAt: '2022-03-04T08:30:32.080Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 215,
    },
    transactionType: 'transferOut',
    amount: -215,
  },
  {
    _id: 'hZCs4FBeWEvtZEekw',
    createdAt: '2022-03-04T08:30:32.119Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3871,
    },
    transactionType: 'transferOut',
    amount: -113,
  },
  {
    _id: 'QCBarNovcBTAor3Hm',
    createdAt: '2022-03-04T08:32:18.700Z',
    transactionType: 'in',
    amount: 1500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 103.98000000000593,
    },
  },
  {
    _id: '7e864pnYkBHephHPg',
    createdAt: '2022-03-04T11:19:42.595Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3758,
    },
  },
  {
    _id: 'LLSNG2NtSr262rH65',
    createdAt: '2022-03-04T11:20:05.084Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 450,
    },
  },
  {
    _id: 'vjJ7uRyY7TDv4LoPB',
    createdAt: '2022-03-04T12:33:41.736Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 485,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '5B88R7tvdRCWZDe9F',
    createdAt: '2022-03-04T12:33:41.756Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 949,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'uscDbCoq7ip9RPpkK',
    createdAt: '2022-03-04T12:33:41.773Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 161.47000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'isagvNmvuo7MR9YAv',
    createdAt: '2022-03-04T12:33:41.795Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2974.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'riMg8BRJcNBtdeEDT',
    createdAt: '2022-03-04T12:33:41.833Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'hPuxpMyfDqR62Z7EL',
    createdAt: '2022-03-04T12:36:42.124Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1603.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'zHYatnx2chTdvzx5y',
    createdAt: '2022-03-04T12:36:42.145Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14809.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RfsPqs2tMAqYr2SzF',
    createdAt: '2022-03-04T12:36:42.163Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2971.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'oaCjZqoR9d7JgcYvr',
    createdAt: '2022-03-04T12:36:42.181Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'YMJ3wTHxJnghaZD6v',
    createdAt: '2022-03-04T12:36:42.200Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9725.899999999994,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'zhWguDFnPwe3q8Mp7',
    createdAt: '2022-03-04T12:36:42.218Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 142146.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'u68pZ8DckzR3tuhTt',
    createdAt: '2022-03-04T12:36:42.235Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'c6yy4bMYmHdgtL6W9',
    createdAt: '2022-03-04T12:36:42.251Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1706,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'cP4QmcQk6mpSkmoEm',
    createdAt: '2022-03-04T12:36:42.276Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1145,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'HRCAYSQhCidvPDkW4',
    createdAt: '2022-03-04T12:42:51.217Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14809.644250000001,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'DswNmbpE549vbCZLw',
    createdAt: '2022-03-04T12:42:51.235Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2921.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: '8zQRNzxdBrKxgDbJ3',
    createdAt: '2022-03-04T12:42:51.251Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9722.899999999994,
    },
    transactionType: 'transferOut',
    amount: -0.27,
  },
  {
    _id: 'B7E7g997YPAmygwaE',
    createdAt: '2022-03-04T12:42:51.271Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 475,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '77Y98DasrmqA6xrTz',
    createdAt: '2022-03-04T12:42:51.287Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 932,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'hQgbHt77ARFKQJPYJ',
    createdAt: '2022-03-04T12:42:51.311Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3858,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'HQeCzGmYdz2xif2xT',
    createdAt: '2022-03-04T12:45:59.639Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3852,
    },
  },
  {
    _id: 'RtrsqP9w3SzevaQXh',
    createdAt: '2022-03-04T12:46:21.070Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2916.7000000000007,
    },
  },
  {
    _id: 'LAnX6936MbfHocGgA',
    createdAt: '2022-03-04T12:46:44.965Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 7560,
    },
  },
  {
    _id: 'BNjjKZp7eD6uthmCT',
    createdAt: '2022-03-04T12:47:10.946Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1033,
    },
  },
  {
    _id: 'uTESWz87kdBQ7JWvj',
    createdAt: '2022-03-04T12:47:35.806Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3705,
    },
  },
  {
    _id: 'YEDB5Ffm8xHYBbdbP',
    createdAt: '2022-03-04T12:47:58.218Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4564,
    },
  },
  {
    _id: 'mAmqrCQFWCxSPtC4m',
    createdAt: '2022-03-04T12:48:23.764Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3318,
    },
  },
  {
    _id: 'gbo7D5hXnpnzKcFWa',
    createdAt: '2022-03-04T12:48:56.469Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 681,
    },
  },
  {
    _id: 'GDMee967LjRc4r9Wh',
    createdAt: '2022-03-04T12:58:29.740Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
  },
  {
    _id: 'Dpx8Lc5s655mG5T99',
    createdAt: '2022-03-04T12:59:56.279Z',
    transactionType: 'transferIn',
    amount: 4500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1353.980000000006,
    },
  },
  {
    _id: 'Fm7j6YoNZP3GhnnmH',
    createdAt: '2022-03-04T13:19:34.205Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 474,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'gBLaCaxRdasG9QSyD',
    createdAt: '2022-03-04T13:19:34.221Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 930.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'CGrrTKPhKE6D6KfyZ',
    createdAt: '2022-03-04T13:19:34.236Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 161.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'bS6Z5vQXgpJDEgG8D',
    createdAt: '2022-03-04T13:19:34.253Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2991.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'B5mJ9dCjQrptZd8Gm',
    createdAt: '2022-03-04T13:19:34.300Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: '3G55Dt4mqrKPtEEdg',
    createdAt: '2022-03-04T13:20:24.978Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5853.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'zuMpHqitgwKnpy4PN',
    createdAt: '2022-03-04T13:20:24.995Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14784.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QTsYYJH689gjKh4Bj',
    createdAt: '2022-03-04T13:20:25.023Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2988.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'TFS5NFx6HNpf3PBiS',
    createdAt: '2022-03-04T13:20:25.044Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'qBg5fPdfTPyXGgGKA',
    createdAt: '2022-03-04T13:20:25.064Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9722.629999999994,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'c7itxk2jH7qiXQ9es',
    createdAt: '2022-03-04T13:20:25.082Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 139846.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'HT6BY5JmxzdJQwec6',
    createdAt: '2022-03-04T13:20:25.096Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 778,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Xx67JoJxECxZeBbd2',
    createdAt: '2022-03-04T13:20:25.114Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1684,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'L8LxGq957i6G5nxdp',
    createdAt: '2022-03-04T13:20:25.134Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1130,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'LMGiXxNJpYqhJLKxu',
    createdAt: '2022-03-04T13:24:16.276Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 8590,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: '3kE4Spa3vA5FEKHtE',
    createdAt: '2022-03-04T13:24:16.296Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9719.629999999994,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'rLr2F26zabMkHrG59',
    createdAt: '2022-03-04T13:24:16.313Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 137546.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'jFhJsF4QTbcZtDTGD',
    createdAt: '2022-03-04T13:24:16.330Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18134.5,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'Y6inPGX8KvPYX5jQ7',
    createdAt: '2022-03-04T13:24:16.376Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'XTr4JWGgbiWPa4aEa',
    createdAt: '2022-03-04T13:26:40.523Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14784.644250000001,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'XubQF3FPyJ3NkZj3F',
    createdAt: '2022-03-04T13:26:40.543Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2938.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'mtDrk7sWSKLjq2ccY',
    createdAt: '2022-03-04T13:26:40.567Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 805.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'xk4cyEjeCaFNzoirB',
    createdAt: '2022-03-04T13:26:40.593Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18201,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '4dffdkFLnZfcs8oFm',
    createdAt: '2022-03-04T13:26:40.614Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18104.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'gWo6K7DuYpKWPCQzS',
    createdAt: '2022-03-04T13:26:40.638Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'xKXbLGAnbJuzj7odq',
    createdAt: '2022-03-04T13:26:40.659Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3952,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zQ7XBcMvKpkLgamJa',
    createdAt: '2022-03-04T13:26:40.676Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 756,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'AiTxg87rhX52wvrkK',
    createdAt: '2022-03-04T13:26:40.691Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1662,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'sWricB4sdG7ATjET9',
    createdAt: '2022-03-04T13:35:37.716Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13784.644250000001,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'dCDBGxoBAcSDjZQpb',
    createdAt: '2022-03-04T13:35:37.736Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2908.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'hXXZWENrzrnqgjxiC',
    createdAt: '2022-03-04T13:35:37.751Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 740.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'nZ3gBKv4tb54zNDiy',
    createdAt: '2022-03-04T13:35:37.767Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18171,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '8dWmNBD7RqhXfLhf8',
    createdAt: '2022-03-04T13:35:37.785Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18092.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '8nauXmWJhqvbswFKr',
    createdAt: '2022-03-04T13:35:37.804Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 640,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'eFKrjHnx6rnvA2SEQ',
    createdAt: '2022-03-04T13:35:37.821Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3952,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'zYEKcPXkiJDzsNoY8',
    createdAt: '2022-03-04T13:35:37.839Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 734,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fwrMdRia6xiJfKXnX',
    createdAt: '2022-03-04T13:35:37.856Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1640,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DzKRdPcXNDLBxCu2H',
    createdAt: '2022-03-04T13:40:37.861Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12984.644250000001,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'u3qw2AgMfb76Bfc6f',
    createdAt: '2022-03-04T13:40:37.882Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2883.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'XQrK44rYFoPKQrtnJ',
    createdAt: '2022-03-04T13:40:37.900Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 913.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jtd7DsHGno6kjgiTw',
    createdAt: '2022-03-04T13:40:37.914Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 464,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vK49K4AzFNc57iZXB',
    createdAt: '2022-03-04T13:40:37.931Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21776.9,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'QTQQK89mZLvogwXkD',
    createdAt: '2022-03-04T13:40:37.946Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1640,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'GJTePhtcudhrkcLW7',
    createdAt: '2022-03-04T13:40:37.961Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9679.629999999994,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Nq5J7EcCSKMSGvrXG',
    createdAt: '2022-03-04T13:43:40.237Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5603.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1050,
  },
  {
    _id: 'eeWLXbSjSzBQ9K328',
    createdAt: '2022-03-04T13:43:40.261Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PCrAiFCiAbNH8QjMZ',
    createdAt: '2022-03-04T13:43:40.278Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12884.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'w459gEnAq85ggXSj2',
    createdAt: '2022-03-04T13:43:40.294Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 695.6000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'To9wz8C47yYRxRG9Y',
    createdAt: '2022-03-04T13:43:40.307Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 43.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'k6PHhrdT3xXsypFMa',
    createdAt: '2022-03-04T13:43:40.322Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9678.629999999994,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'NYGTiwQGPuAhWi7mi',
    createdAt: '2022-03-04T13:43:40.336Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18082.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'QRSaTfrLqxMngmGgE',
    createdAt: '2022-03-04T13:43:40.351Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3805,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'DZaLir59DJrZY4rW7',
    createdAt: '2022-03-04T13:45:07.693Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4553.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'qGysj7pX3mCmnWKDt',
    createdAt: '2022-03-04T13:45:07.710Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ya8ygkagJreNwBef5',
    createdAt: '2022-03-04T13:45:07.726Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12884.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CnvEMPXhTorcnqzv2',
    createdAt: '2022-03-04T13:45:07.743Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2873.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3utbAYSMajTErNrmb',
    createdAt: '2022-03-04T13:45:07.759Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zmfgiG4B2aJfPqdnv',
    createdAt: '2022-03-04T13:45:07.776Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18070.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'yETK8YvcTP3SKzrZY',
    createdAt: '2022-03-04T13:45:07.792Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 1,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'KfdvNK9bfjdZwprmR',
    createdAt: '2022-03-04T13:45:07.808Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 75.2,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Y5p7XcRq4ifBmJBpF',
    createdAt: '2022-03-04T13:45:07.825Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 42.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'aLr5K5HorztZ6L6Tk',
    createdAt: '2022-03-04T13:45:07.839Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 451.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'Z5zPww8M7r8eiQpLh',
    createdAt: '2022-03-04T13:45:07.856Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9677.129999999994,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'rDv7ehb59vWuRY5GP',
    createdAt: '2022-03-04T13:45:07.871Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4764,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'Dum28EA8RybeaTjaQ',
    createdAt: '2022-03-04T13:54:30.983Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
    transactionType: 'transferOut',
    amount: -205,
  },
  {
    _id: 'ESRdzxFAjFWpssvBN',
    createdAt: '2022-03-04T13:54:31.004Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9346,
    },
    transactionType: 'transferOut',
    amount: -124,
  },
  {
    _id: '9723Lzkase4mNjoFm',
    createdAt: '2022-03-04T13:54:31.024Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3558,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '5qsum8AwZBsdxJTay',
    createdAt: '2022-03-04T13:54:31.040Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 960,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'SnGpd8SYWd4AsvRuQ',
    createdAt: '2022-03-04T13:55:54.585Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 145,
    },
    transactionType: 'transferOut',
    amount: -61,
  },
  {
    _id: 'iAbcthmdttYiJPShv',
    createdAt: '2022-03-04T13:55:54.604Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9222,
    },
    transactionType: 'transferOut',
    amount: -61,
  },
  {
    _id: 'hK5x5GKy5TqBKkWHG',
    createdAt: '2022-03-04T13:55:54.617Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 947,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'CH2vTSuXee8nstHuy',
    createdAt: '2022-03-04T13:59:56.042Z',
    transactionType: 'in',
    amount: 3200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12884.644250000001,
    },
  },
  {
    _id: 'tY6wPBah2Lnh7QLz2',
    createdAt: '2022-03-04T14:00:12.311Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 84,
    },
  },
  {
    _id: 'dordncptYe9JZM4bi',
    createdAt: '2022-03-04T14:02:19.642Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 434,
    },
    transactionType: 'transferOut',
    amount: -350,
  },
  {
    _id: 'EGwzXGHSrmkE6MaLv',
    createdAt: '2022-03-04T14:02:19.658Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 2.3,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'qBv9Jhhaf7C7oixMw',
    createdAt: '2022-03-04T14:02:19.696Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 214,
    },
    transactionType: 'in',
    amount: 350,
  },
  {
    _id: 'xmXkDarFEz7dQFJvS',
    createdAt: '2022-03-04T14:03:07.379Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 564,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'zT9EWYFcT3BN9n7Jb',
    createdAt: '2022-03-04T14:03:07.400Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9161,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'RqxGKzmfhcx5tiR9C',
    createdAt: '2022-03-04T14:03:07.415Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 942,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'FAQN6QetLHhjisqWP',
    createdAt: '2022-03-04T14:03:39.888Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 444,
    },
    transactionType: 'transferOut',
    amount: -232,
  },
  {
    _id: 'oeHR4GLn72vC7pyzg',
    createdAt: '2022-03-04T14:03:39.907Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9040,
    },
    transactionType: 'transferOut',
    amount: -235,
  },
  {
    _id: 'fJsY3N6hqAfgS5c2G',
    createdAt: '2022-03-04T14:03:39.923Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 932,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'PN2JbuQS5Wp7HK63F',
    createdAt: '2022-03-05T05:03:40.670Z',
    transactionType: 'transferIn',
    amount: 3,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 16,
    },
  },
  {
    _id: 'RuhLPNyTD5r48ucoE',
    createdAt: '2022-03-05T05:51:59.859Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 487,
    },
  },
  {
    _id: 'iZqhyS4uYwp3bt6Ai',
    createdAt: '2022-03-05T05:57:09.445Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16084.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BC5svSFFA7hdqJqCv',
    createdAt: '2022-03-05T05:57:09.466Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3753.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'kgcHLmtmir49Mfxso',
    createdAt: '2022-03-05T05:57:09.487Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 't6fbqrxGezAam9tK7',
    createdAt: '2022-03-05T05:57:09.503Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2873.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '4TF5NYrZfPaqGknYo',
    createdAt: '2022-03-05T05:57:09.526Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'FuFsTcKjfLAkdQcdF',
    createdAt: '2022-03-05T05:57:09.548Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 75.2,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'DLApXWaKWc7vdNH2J',
    createdAt: '2022-03-05T05:57:09.584Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18058.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'g6iKxrCKLn8T2Mo73',
    createdAt: '2022-03-05T05:57:09.604Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3737,
    },
    transactionType: 'transferOut',
    amount: -186,
  },
  {
    _id: 'cjW3koaWMPXviyS3S',
    createdAt: '2022-03-05T06:11:44.331Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2953.980000000006,
    },
    transactionType: 'transferOut',
    amount: -330,
  },
  {
    _id: 'pe5hbudMgrjwj5J6k',
    createdAt: '2022-03-05T06:11:44.350Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ti5PmWtsSxeJq7akq',
    createdAt: '2022-03-05T06:11:44.367Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16084.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'y3MRKxCn84TP8ZPh5',
    createdAt: '2022-03-05T06:11:44.384Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2866.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'EzMWMpn6WtLhxj3Aw',
    createdAt: '2022-03-05T06:11:44.400Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 695.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ZwASJEPxmMWFrgJ58',
    createdAt: '2022-03-05T06:11:44.419Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134946.3,
    },
    transactionType: 'transferOut',
    amount: -155,
  },
  {
    _id: 'W6mDGWXzWyscgnGWd',
    createdAt: '2022-03-05T06:11:44.435Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18151,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 't8ETpEKqRxijH26vL',
    createdAt: '2022-03-05T06:11:44.453Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 434,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'pYcgbHaqxYuJzZAsq',
    createdAt: '2022-03-05T06:11:44.469Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 520,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '8DpfEAsvYwCn9KzuJ',
    createdAt: '2022-03-05T06:11:44.488Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3900,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'isNQkgLXjRLNEh3uZ',
    createdAt: '2022-03-05T06:14:38.396Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16084.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nSbbfqGxAmEKqvSB5',
    createdAt: '2022-03-05T06:14:38.438Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2623.980000000006,
    },
    transactionType: 'transferOut',
    amount: -550,
  },
  {
    _id: 'KZCpPnMB8ycGwXnXP',
    createdAt: '2022-03-05T06:14:38.457Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GZ5gz25FQkBoBo8Gj',
    createdAt: '2022-03-05T06:14:38.476Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2856.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -16.5,
  },
  {
    _id: 'DsnRgraGMrC6qP4xr',
    createdAt: '2022-03-05T06:14:38.497Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 675.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: '4hbWvMd5mmNHLWjm8',
    createdAt: '2022-03-05T06:14:38.517Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134791.3,
    },
    transactionType: 'transferOut',
    amount: -275,
  },
  {
    _id: 'Ba7WvZDxTTXXWBt9Q',
    createdAt: '2022-03-05T06:14:38.535Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 426,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'R3msrLFogjvcnfD2q',
    createdAt: '2022-03-05T06:14:38.556Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18136,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'koWcnz4bm9PZC4eWp',
    createdAt: '2022-03-05T06:14:38.575Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18046.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '6NcZc2vB2dzmseCue',
    createdAt: '2022-03-05T06:14:38.646Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 460,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'yqMukvLuHmhCg3eti',
    createdAt: '2022-03-05T06:14:38.672Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3876,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'W7rTTvDaXzeLFGq6L',
    createdAt: '2022-03-05T10:07:25.612Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'YH57SY8tLwYWL2zrj',
        name: 'RTS 82 MM (PLAIN WHITE) -JAR CAP',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F665dfe2a-8b8b-4ba1-83b7-59c7a5914c4c_200x200.jpg?alt=media&token=5b30fc96-e6ed-43a3-8955-f555e4b0061b',
        },
      },
      quantity: 500,
    },
  },
  {
    _id: 'XXuQP8dXNafiEpPSS',
    createdAt: '2022-03-05T10:07:49.386Z',
    transactionType: 'transferIn',
    amount: 128,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 267,
    },
  },
  {
    _id: 'eQGxGDfbjvxLdwALR',
    createdAt: '2022-03-05T10:10:15.947Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16084.644250000001,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'hDyBcXrmYxmzbx2S4',
    createdAt: '2022-03-05T10:10:15.967Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2840.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'yvJ7ReRjdj5u4p3jC',
    createdAt: '2022-03-05T10:10:15.985Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 642.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'gcd5Soqhu6zmecS3W',
    createdAt: '2022-03-05T10:10:16.007Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18121,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'dWPH65BjTT8wRSTeL',
    createdAt: '2022-03-05T10:10:16.025Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21772.9,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'oN8cJ3ETsyekxb7g7',
    createdAt: '2022-03-05T10:10:16.040Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 489.9,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'LyFWuxsguaZHawKMP',
    createdAt: '2022-03-05T10:13:15.380Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15784.644250000001,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'MisujY6fAZtsfYKbi',
    createdAt: '2022-03-05T10:13:15.402Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 60.39999999999999,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'LnKf9cq5joqq5AFNe',
    createdAt: '2022-03-05T10:13:15.428Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134516.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'k6i2ym6pScYXD5WQo',
    createdAt: '2022-03-05T10:13:15.456Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 624.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'R3eK6GDs6QG56hASt',
    createdAt: '2022-03-05T10:13:15.483Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9676.529999999993,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'WvpN8kKTp3AuaabDo',
    createdAt: '2022-03-05T10:13:15.510Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 230,
    },
    transactionType: 'transferOut',
    amount: -230,
  },
  {
    _id: 'Mn7FneGpeEa4zs5DC',
    createdAt: '2022-03-05T10:13:15.540Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4688,
    },
    transactionType: 'transferOut',
    amount: -39,
  },
  {
    _id: 'r2PbCy8TEv9SPjp9a',
    createdAt: '2022-03-05T10:16:40.941Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 492.5,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'hg3DdNEt5aBNP2k5Z',
    createdAt: '2022-03-05T10:16:40.965Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21763.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'hr9BRnkg8Xd7xkGTv',
    createdAt: '2022-03-05T10:16:40.980Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 480.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'kkEECSQtH9kE8rnuR',
    createdAt: '2022-03-05T10:16:40.996Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 156,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'KiDT8YmMw9Yj4kAT8',
    createdAt: '2022-03-05T10:16:46.548Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 974,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'SHoqjSbCjoN5CPTd6',
    createdAt: '2022-03-05T10:16:46.574Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 50.30000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'ZuyZyLzbSoh6HkBkN',
    createdAt: '2022-03-05T10:16:46.593Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 160.97000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'cyNgEwXsdHnsoZT9u',
    createdAt: '2022-03-05T10:16:46.613Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 443.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ewB9fGZMhHTmpHFyA',
    createdAt: '2022-03-05T10:16:46.633Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 170.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'id8bSsjeWRasL3sW9',
    createdAt: '2022-03-05T10:16:46.661Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9576.529999999993,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '7fs95DgLAuEagoRpW',
    createdAt: '2022-03-05T10:16:46.678Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 464,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kXnmRqJqiHCYGEkWd',
    createdAt: '2022-03-05T10:16:46.697Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 913.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'hPY3Ke6swhwCreqQS',
    createdAt: '2022-03-05T10:16:46.726Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 174,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'rLqfqejaiTRyvHe55',
    createdAt: '2022-03-05T10:16:46.746Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HFXoYbq4Xv6HEzHNT',
    createdAt: '2022-03-05T10:16:46.767Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1115,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'rgdnzHyQMQ5edxLSe',
    createdAt: '2022-03-05T10:17:30.873Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 450.5,
    },
    transactionType: 'transferOut',
    amount: -9.5,
  },
  {
    _id: 'G66DjrgYXkSifiDhp',
    createdAt: '2022-03-05T10:17:30.895Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21753.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'svM8g3JpHChQaLr8g',
    createdAt: '2022-03-05T10:17:30.912Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 470.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'xEFzgKDcZoYtygjTX',
    createdAt: '2022-03-05T10:18:11.350Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 441,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'pibwqZZi9QtRfG7sY',
    createdAt: '2022-03-05T10:18:11.381Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21750.9,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'zsyXf4WCYrKRD4rZx',
    createdAt: '2022-03-05T10:18:11.396Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 467.9,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'S9E2BpXJD7AubxL8e',
    createdAt: '2022-03-05T10:18:11.409Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 153,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'DC8L2yivykyCi7pfX',
    createdAt: '2022-03-05T10:21:03.926Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2073.980000000006,
    },
    transactionType: 'transferOut',
    amount: -890,
  },
  {
    _id: 'zZswDWKS8YFPyadgS',
    createdAt: '2022-03-05T10:21:03.945Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vQKPNCr4i5Ero3gDD',
    createdAt: '2022-03-05T10:21:03.961Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13784.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TChnBDzam54ExqXtG',
    createdAt: '2022-03-05T10:21:03.978Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2831.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iML7vxtemAGTSzN8T',
    createdAt: '2022-03-05T10:21:04.002Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fTYXtYXjB97EC29gj',
    createdAt: '2022-03-05T10:21:04.025Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18041.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'P3BmrbnESxtz942St',
    createdAt: '2022-03-05T10:21:04.042Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'w2Lxmx4ZYDDnhQDr5',
    createdAt: '2022-03-05T10:21:04.058Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 74.2,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'dg8LP3rGcQfs9Hg53',
    createdAt: '2022-03-05T10:21:04.074Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 41.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ZfzxtJSyntRncp99j',
    createdAt: '2022-03-05T10:21:04.090Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 441.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'xsakScoPjaBoZ6z6J',
    createdAt: '2022-03-05T10:21:04.115Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9575.029999999993,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'rNFhQWqLkvNTYN98R',
    createdAt: '2022-03-05T10:21:04.132Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4649,
    },
    transactionType: 'transferOut',
    amount: -81,
  },
  {
    _id: 's3dsMFZQ7vthB2wc4',
    createdAt: '2022-03-05T10:53:47.668Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 464,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'hheAES2Ybcv2zKaAv',
    createdAt: '2022-03-05T10:53:47.687Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 904.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'aPRFwaqDQLQmTvKQs',
    createdAt: '2022-03-05T10:53:47.703Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 160.77000000000015,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'i8hK5KH5D7onE3fL3',
    createdAt: '2022-03-05T10:53:47.720Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2831.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'w4n36qoTf8gTy4ozS',
    createdAt: '2022-03-05T10:53:47.760Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'ARCvr7z2Ep4M5Svgc',
    createdAt: '2022-03-05T10:57:27.435Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1183.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'h7fkPuRgWTb4Pyh23',
    createdAt: '2022-03-05T10:57:27.454Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13784.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NPrwrj5djuz5nvM7n',
    createdAt: '2022-03-05T10:57:27.475Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2828.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'pRofD8DWHDowsrtEN',
    createdAt: '2022-03-05T10:57:27.496Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'SKxcG3v3xcXo6BLDN',
    createdAt: '2022-03-05T10:57:27.523Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134515.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'ix5MAMrwrYTfC38rw',
    createdAt: '2022-03-05T10:57:27.542Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9574.429999999993,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'FbwtBWJmzjwF2qxRQ',
    createdAt: '2022-03-05T10:57:27.557Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3835,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'bBHFqEfrhb64PDG4Z',
    createdAt: '2022-03-05T11:13:26.148Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe087aa06-9bbe-4765-90e8-807e6bd3e88b_200x200.jpg?alt=media&token=07849047-5931-407a-9fca-50e2923b7bbf',
        },
      },
      quantity: 5,
    },
  },
  {
    _id: 'hgYwtc9jpw5gZmzLz',
    createdAt: '2022-03-05T11:35:34.749Z',
    transactionType: 'transferIn',
    amount: 2302,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 933.9800000000059,
    },
  },
  {
    _id: 'kSqgKJk2NiD9KsR3i',
    createdAt: '2022-03-05T11:35:49.379Z',
    transactionType: 'transferIn',
    amount: 1050,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
  },
  {
    _id: 'CXKDckpuRiDjfPMd9',
    createdAt: '2022-03-05T11:39:56.338Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 919,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'DRSTfSiZk9XsMkow5',
    createdAt: '2022-03-05T11:39:56.365Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 49.90000000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '5HQMHvnkKYFxtP5bW',
    createdAt: '2022-03-05T11:39:56.385Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 160.52000000000015,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'cF7cZaKY4tbFknjNR',
    createdAt: '2022-03-05T11:39:56.404Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 433.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'zpjRtamHJ2RBAAFHG',
    createdAt: '2022-03-05T11:39:56.419Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 170.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'gfeCyHnnHB44JYjgX',
    createdAt: '2022-03-05T11:39:56.435Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9571.429999999993,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'ifYWoDGXyDZqSWg4Y',
    createdAt: '2022-03-05T11:39:56.451Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 454,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dXnxFeC3geXa85Mq7',
    createdAt: '2022-03-05T11:39:56.467Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 887.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'LjvtypiP8aGWgpogs',
    createdAt: '2022-03-05T11:39:56.483Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 171,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'eKGjTcqatqTMGjWfm',
    createdAt: '2022-03-05T11:39:56.502Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MzZJrwsoc2f8qw2Sa',
    createdAt: '2022-03-05T11:39:56.524Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1106,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'apbWro7YbXEMyaezi',
    createdAt: '2022-03-05T11:53:35.375Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3771,
    },
  },
  {
    _id: 'MQRSZeTqZxyuCL8nF',
    createdAt: '2022-03-05T11:54:16.194Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9569.929999999993,
    },
  },
  {
    _id: '5Kfx4aqxjtmyzkihH',
    createdAt: '2022-03-05T11:55:48.273Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3551,
    },
  },
  {
    _id: 'Bakpaj9JnWiwxZL2W',
    createdAt: '2022-03-05T11:56:31.642Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 431.6200000000001,
    },
  },
  {
    _id: 'mSGb7fNFQ6z5zXxYq',
    createdAt: '2022-03-05T11:57:19.191Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2778.2000000000007,
    },
  },
  {
    _id: 'sWXXpWj5syhGmkQtj',
    createdAt: '2022-03-05T11:57:57.854Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 58.39999999999999,
    },
  },
  {
    _id: 'pQdv4jaukazhRDcKg',
    createdAt: '2022-03-05T11:58:47.083Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 912,
    },
  },
  {
    _id: 'SeHa2ZzekyngG8mSp',
    createdAt: '2022-03-05T11:59:30.787Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1097,
    },
  },
  {
    _id: 'iGKdnZcLBGmrnAsus',
    createdAt: '2022-03-05T12:00:11.128Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 152,
    },
  },
  {
    _id: 'swfin3hRNLGHeandA',
    createdAt: '2022-03-05T12:00:45.193Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4568,
    },
  },
  {
    _id: 'NxiAKMfiTmn24DNTH',
    createdAt: '2022-03-05T12:30:16.976Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 470,
    },
  },
  {
    _id: 'muY23DS7AEgrETPex',
    createdAt: '2022-03-05T12:42:48.587Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13784.644250000001,
    },
    transactionType: 'transferOut',
    amount: -850,
  },
  {
    _id: 'ZwTKZxKGS9MnfDXva',
    createdAt: '2022-03-05T12:42:48.613Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2903.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'FaBRKrB262d4xiMeD',
    createdAt: '2022-03-05T12:42:48.627Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 498.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'CBpQEz5dQwgLyKgSH',
    createdAt: '2022-03-05T12:42:48.643Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18111,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'LuRaMm7NJHWJJyKgL',
    createdAt: '2022-03-05T12:42:48.658Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 370,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'G3z9Ts4R29kLJhu82',
    createdAt: '2022-03-05T12:42:48.674Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3971,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'MKDFeGEw5KDR42ED8',
    createdAt: '2022-03-05T12:46:49.607Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3235.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2T4tS7AxaauDf93mg',
    createdAt: '2022-03-05T12:46:49.623Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2250,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'Xa6kLpPQsv8AY4iKA',
    createdAt: '2022-03-05T12:46:49.639Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 83.39999999999999,
    },
    transactionType: 'transferOut',
    amount: -2.1,
  },
  {
    _id: 'KWG6B3qgj3thKzzDw',
    createdAt: '2022-03-05T12:46:49.653Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 132215.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'kLtfNewoARx5nz2Sa',
    createdAt: '2022-03-05T12:46:49.668Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 448.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'PNve3C9NhYNpcSgeJ',
    createdAt: '2022-03-05T12:46:49.702Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 864,
    },
    transactionType: 'in',
    amount: 160,
  },
  {
    _id: 'gZyjnzKq45f9Pwwnn',
    createdAt: '2022-03-05T12:48:17.101Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1024,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'sQ2B8mf2ZbSvMzEQu',
    createdAt: '2022-03-05T12:48:17.143Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 45.90000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '73iNEQ8ZzDbZNQjEG',
    createdAt: '2022-03-05T12:48:17.170Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 160.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'rfcTaF4AzwKWqHb7h',
    createdAt: '2022-03-05T12:48:17.197Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 456.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'yJzX6AcrWCGTsBN9o',
    createdAt: '2022-03-05T12:48:17.224Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 169.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '62BLya9PjyPiDDSMc',
    createdAt: '2022-03-05T12:48:17.246Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9869.929999999993,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'uKD8yRrPRNjXBjjgF',
    createdAt: '2022-03-05T12:48:17.272Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 454,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xq568LMPptFypiQAG',
    createdAt: '2022-03-05T12:48:17.290Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 878.5,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'M2HWLJdMFxDesqKJE',
    createdAt: '2022-03-05T12:48:17.308Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 166,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'scn24XRWj2M5woLyX',
    createdAt: '2022-03-05T12:48:17.330Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R98BaezFdbmTCaeNk',
    createdAt: '2022-03-05T12:48:17.357Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1157,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'K3hmMgTxNJAAQiQk7',
    createdAt: '2022-03-05T12:49:58.270Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12934.644250000001,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'hJCJW4Kas4RK4ki6S',
    createdAt: '2022-03-05T12:49:58.286Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3235.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'ZPD6QePJShzHxnXDX',
    createdAt: '2022-03-05T12:49:58.299Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 512,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'qZxHE3jHPyyqmACfX',
    createdAt: '2022-03-05T12:49:58.312Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 456.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '49Ps6MZ9D7TuDdxSp',
    createdAt: '2022-03-05T12:49:58.327Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1756,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'gpJwGhJBF6nwNyniZ',
    createdAt: '2022-03-05T12:49:58.339Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1157,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 't5odLMoTzmtYJR6aD',
    createdAt: '2022-03-05T13:03:58.195Z',
    transactionType: 'in',
    amount: 400,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12834.644250000001,
    },
  },
  {
    _id: 'Rf6HMDQYSde6kaP2S',
    createdAt: '2022-03-05T13:04:39.894Z',
    transactionType: 'in',
    amount: 2900,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 84,
    },
  },
  {
    _id: 'Ae5X7LbhtxcZd5sDR',
    createdAt: '2022-03-05T13:05:08.981Z',
    transactionType: 'out',
    amount: -2900,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 2984,
    },
  },
  {
    _id: 'jSzXemZmmaM7GBFRW',
    createdAt: '2022-03-05T13:05:21.900Z',
    transactionType: 'in',
    amount: 400,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 84,
    },
  },
  {
    _id: 'n4r4fvt8r4Neavm5j',
    createdAt: '2022-03-05T13:06:08.526Z',
    transactionType: 'in',
    amount: 2900,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13234.644250000001,
    },
  },
  {
    _id: 'LWsaR2henLXjbsXr7',
    createdAt: '2022-03-05T13:18:19.496Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 484,
    },
    transactionType: 'transferOut',
    amount: -155,
  },
  {
    _id: 'zeJAMvDABhcGpRHTT',
    createdAt: '2022-03-05T13:18:19.515Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8805,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'vjZZEcCFs74ZMAPTF',
    createdAt: '2022-03-05T13:18:19.531Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3550,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '9wFForDKPA2Qy3oSc',
    createdAt: '2022-03-05T13:18:19.548Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1002,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'EEiJuizEbc7v9QnGw',
    createdAt: '2022-03-05T13:19:25.458Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 329,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'vioBjRgMettAX8Wpt',
    createdAt: '2022-03-05T13:19:25.473Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8748,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'osLp7zKiY4HCFCc5y',
    createdAt: '2022-03-05T13:19:25.487Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'nrjCo8qAw4fhoG7y9',
    createdAt: '2022-03-05T13:26:19.889Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 7830,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'Z3K9um8sjJmgehXQg',
    createdAt: '2022-03-05T13:26:19.908Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9868.429999999993,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '9EwzphJGXFR5vtw9r',
    createdAt: '2022-03-05T13:26:20.458Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131215.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'do9a5zxSRu4CRMGoy',
    createdAt: '2022-03-05T13:26:20.484Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18029.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cPcQDtWtRavzy24DY',
    createdAt: '2022-03-05T13:26:20.554Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'q6gJjAhTRDHMzWqit',
    createdAt: '2022-03-05T13:27:30.146Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 969,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'hAx7wtHWLRH3DL7T8',
    createdAt: '2022-03-05T13:27:30.171Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 45.50000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'gm56L8AXMp7BimLRS',
    createdAt: '2022-03-05T13:27:30.190Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 160.12000000000018,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'm249XyFtM5DoFjSox',
    createdAt: '2022-03-05T13:27:30.224Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'TwXdzSPAjdp3vMef6',
    createdAt: '2022-03-05T13:27:30.242Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 169.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'DhE4u88DhgMuaEuJR',
    createdAt: '2022-03-05T13:27:30.259Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9768.429999999993,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'xgEPYxnQQ5NYqqcvC',
    createdAt: '2022-03-05T13:27:30.275Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 454,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HcptEPjgWZWy7phfC',
    createdAt: '2022-03-05T13:27:30.290Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 869.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'mmrw7FbTSPbYMGMCr',
    createdAt: '2022-03-05T13:27:30.306Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 163,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'HkJa7SLxyjAQJbjPQ',
    createdAt: '2022-03-05T13:27:30.322Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aZFmcNRKSAh2gHByS',
    createdAt: '2022-03-05T13:27:30.341Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1138,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'yX7Xa54eRERM9TP8n',
    createdAt: '2022-03-07T04:58:35.126Z',
    transactionType: 'transferIn',
    amount: 0.5,
    storeItem: {
      type: {
        _id: 'iASvRoJNWiSfQRNDL',
        name: 'BLEACH CONCENTRALE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2be3571f-f475-4e7a-ade3-41b15edd8a5f_200x200.jpg?alt=media&token=e887dc4b-c094-436e-8c63-4e1d57db4d35',
        },
      },
      quantity: 1.5,
    },
  },
  {
    _id: 'q7P3EQvSmJ5Bdpseh',
    createdAt: '2022-03-07T04:59:07.053Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 19,
    },
  },
  {
    _id: 'u3J5bHYoNQ8ma4keB',
    createdAt: '2022-03-07T05:00:24.351Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F836955bb-4c46-4d95-aa0d-4cd057c4d7e9_200x200.jpg?alt=media&token=cc75a865-f8ae-400a-a2c5-2249a2829536',
        },
      },
      quantity: 4,
    },
  },
  {
    _id: 'EZPPaPuvMuKj3f5hz',
    createdAt: '2022-03-07T05:55:32.184Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 158,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'QXgxTSfGjzkhodoT3',
    createdAt: '2022-03-07T05:55:32.220Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'B3k4m6nnktqxGoowG',
    createdAt: '2022-03-07T05:55:32.249Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 159.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'twsMM5QsLbWMLAYwS',
    createdAt: '2022-03-07T05:55:32.271Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 852.5,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'o97uEbpYM65wg2oaC',
    createdAt: '2022-03-07T05:55:32.296Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 454,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'FRcCywSBzg3zmt22C',
    createdAt: '2022-03-07T05:55:32.318Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 60.400000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'biayi3TDCfNycnYwY',
    createdAt: '2022-03-07T05:55:32.339Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 45.10000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'BxiFXEQWRmoqQ3i5u',
    createdAt: '2022-03-07T05:55:32.363Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 448.6200000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'cjdpLFpqrixTZmxEX',
    createdAt: '2022-03-07T05:55:32.382Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9766.929999999993,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'oF6w5FRsv3rPmPoSk',
    createdAt: '2022-03-07T05:55:32.400Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 914,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ewRzHGn67ZxukrSvY',
    createdAt: '2022-03-07T05:55:32.424Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rJA2yHF6bu6iwMeeK',
    createdAt: '2022-03-07T05:55:32.453Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 395,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'P4xKJMjcWrbc82r3Z',
    createdAt: '2022-03-07T05:55:32.492Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 34,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'PMJ3nzovurGNtJADS',
    createdAt: '2022-03-07T07:24:06.892Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 852,
    },
  },
  {
    _id: 'KRX8KLLHm8QH7yRwk',
    createdAt: '2022-03-07T07:24:58.585Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 453,
    },
  },
  {
    _id: 'amjFWui8f2MRKEPD9',
    createdAt: '2022-03-07T08:21:44.289Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8711,
    },
  },
  {
    _id: 'pNHA4y7xkvMNrhuvv',
    createdAt: '2022-03-07T08:42:55.623Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 902,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '8zvafriA9Rbm4dSb5',
    createdAt: '2022-03-07T08:42:55.648Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 44.200000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'CjYS7iWoeNofYNqxE',
    createdAt: '2022-03-07T08:42:55.665Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 159.6700000000002,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'xD2o8TGpEibxzGyNT',
    createdAt: '2022-03-07T08:42:55.690Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 448.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'iPCNRaTkH4xkcmPp6',
    createdAt: '2022-03-07T08:42:55.712Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 168.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '6ftkzwzGqMyyrS5xn',
    createdAt: '2022-03-07T08:42:55.740Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9766.679999999993,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'SSkSdNJLcRr8Au6gt',
    createdAt: '2022-03-07T08:42:55.758Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 553,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vEWiZRyxxeQA9Kose',
    createdAt: '2022-03-07T08:42:55.778Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 952,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'J9eMCHpZLkQJPquiJ',
    createdAt: '2022-03-07T08:42:55.802Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 148,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '7zymvv5GM6YHGBAqs',
    createdAt: '2022-03-07T08:42:55.821Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YEs9TgRsxxnoMXsMB',
    createdAt: '2022-03-07T08:42:55.848Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1129,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '8rKGxofJAK5MpypeT',
    createdAt: '2022-03-07T09:06:37.905Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 7,
    },
  },
  {
    _id: 'GFKNMp25BRm9Pnkkf',
    createdAt: '2022-03-07T09:49:10.876Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2835.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '9nNfn3ntDvDTixfCk',
    createdAt: '2022-03-07T09:49:10.893Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PkzroDTSAggaqruyR',
    createdAt: '2022-03-07T09:49:10.907Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16134.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bBwEjBuyX4ACDwBPG',
    createdAt: '2022-03-07T09:49:10.923Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2879.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fMN7GJM6jRtyRxmSo',
    createdAt: '2022-03-07T09:49:10.939Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XH746h9cvhNn7zqSs',
    createdAt: '2022-03-07T09:49:10.955Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18029.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'm7zmazd2TWqP7AtRw',
    createdAt: '2022-03-07T09:49:10.974Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NGW4DKiF5MeNFQt87',
    createdAt: '2022-03-07T09:49:10.988Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 73.2,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'DhcShXThzARihPGAs',
    createdAt: '2022-03-07T09:49:11.005Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 41.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '3bHqhhHjhnKQW2mvz',
    createdAt: '2022-03-07T09:49:11.032Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 446.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'HvxC3zchRfiCwdvyi',
    createdAt: '2022-03-07T09:49:11.049Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9765.179999999993,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'EES4vujgRYuMfLYxr',
    createdAt: '2022-03-07T09:49:11.072Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4728,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'wWdBKoyT8eFjcmK6m',
    createdAt: '2022-03-07T09:55:09.337Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16134.644250000001,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'Hd2Ls7ZgprL9SSXx5',
    createdAt: '2022-03-07T09:55:09.368Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 81.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'g5guhzzyeKAeza7bd',
    createdAt: '2022-03-07T09:55:09.421Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 130715.29999999999,
    },
    transactionType: 'transferOut',
    amount: -10000,
  },
  {
    _id: 'CXXxr2Je43YJhvq27',
    createdAt: '2022-03-07T09:55:09.442Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 322.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'b8tD2Dow73FjHs27q',
    createdAt: '2022-03-07T09:55:09.461Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9764.579999999993,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'j7m6drPoLYMpF2ZbZ',
    createdAt: '2022-03-07T09:55:09.485Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'potMaHriLjcDsSdsY',
    createdAt: '2022-03-07T09:55:09.507Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4653,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '9PtXrmArzQD8k5fjr',
    createdAt: '2022-03-07T09:57:33.697Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14134.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dZn4pzkyKe6hwwpTi',
    createdAt: '2022-03-07T09:57:33.719Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2035.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'PMNZZ3jr3Hxp8BnkN',
    createdAt: '2022-03-07T09:57:33.739Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZSA64WZYqPwBz66cd',
    createdAt: '2022-03-07T09:57:33.762Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2879.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'E6M774c6p5TFhq7ne',
    createdAt: '2022-03-07T09:57:33.783Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 196.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'mdAejM5HAQguYrtjz',
    createdAt: '2022-03-07T09:57:33.802Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 120715.29999999999,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'biKavs4sWtK7xdnTi',
    createdAt: '2022-03-07T09:57:33.822Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 414,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'iT2skMABnsiDr9j25',
    createdAt: '2022-03-07T09:57:33.840Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18086,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'ACg9cLdzHLj4moug3',
    createdAt: '2022-03-07T09:57:33.858Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18017.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Acuwzv4H5Qh8giaez',
    createdAt: '2022-03-07T09:57:33.877Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 240,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: '4QwEWmcqBuGD43DA2',
    createdAt: '2022-03-07T09:57:33.909Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3916,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'cAbCHLhpDRakFC5da',
    createdAt: '2022-03-07T12:18:47.633Z',
    transactionType: 'transferIn',
    amount: 2340,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1235.980000000006,
    },
  },
  {
    _id: 'vwSGBcyKwr2sJ2AbJ',
    createdAt: '2022-03-07T12:19:04.774Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 250,
    },
  },
  {
    _id: 'xpbkPhoHFkBJNwZWY',
    createdAt: '2022-03-07T12:19:44.423Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14134.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '99CyLfMTyNmFGAZe9',
    createdAt: '2022-03-07T12:19:44.467Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1235.980000000006,
    },
    transactionType: 'transferOut',
    amount: -440,
  },
  {
    _id: 'P6BhkHncxsNk8RFpR',
    createdAt: '2022-03-07T12:19:44.603Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WgGLN2HokcteqqNND',
    createdAt: '2022-03-07T12:19:44.637Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2855.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KyK9rCQRaBNH3XJRj',
    createdAt: '2022-03-07T12:19:44.672Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QJAoJjrLJhYv6iRZX',
    createdAt: '2022-03-07T12:19:44.712Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 438.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '9qhi9C3qwgBLZLe7X',
    createdAt: '2022-03-07T12:19:44.730Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 72.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '4hZKRTPWaXQXgt37w',
    createdAt: '2022-03-07T12:19:45.395Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 18005.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '36gaD78aSTC3akHQP',
    createdAt: '2022-03-07T12:19:45.436Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3851,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'GcjRPQHDvnRrv2bit',
    createdAt: '2022-03-07T12:38:11.566Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4613,
    },
  },
  {
    _id: 'YNB5ETiSHpajDxLcr',
    createdAt: '2022-03-07T12:38:40.795Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3856,
    },
  },
  {
    _id: 'MK6dwFA9MyAYQ7Nai',
    createdAt: '2022-03-07T12:39:12.129Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14134.644250000001,
    },
  },
  {
    _id: 'mgnpZ6dMASAGHJjKm',
    createdAt: '2022-03-07T12:39:37.938Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 292,
    },
  },
  {
    _id: 'tyhbDvoNnbEQ35jLk',
    createdAt: '2022-03-07T12:45:37.144Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 642,
    },
    transactionType: 'transferOut',
    amount: -235,
  },
  {
    _id: 'FnNjG85yvBRH7Z6SE',
    createdAt: '2022-03-07T12:45:37.160Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9351,
    },
    transactionType: 'transferOut',
    amount: -102,
  },
  {
    _id: 'fDvBsiYtuoWx4QuR2',
    createdAt: '2022-03-07T12:45:37.180Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3548,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'fDSQMe5BBJCM7kjxg',
    createdAt: '2022-03-07T12:45:37.197Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 994,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'svykHPdAWyL4BNZRd',
    createdAt: '2022-03-07T12:46:46.731Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 407,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'EtmmoqFRwGGwLcAcB',
    createdAt: '2022-03-07T12:46:46.747Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9249,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'uX9LEvjozsnZsMoAZ',
    createdAt: '2022-03-07T12:46:46.767Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 985,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'TF3poY6ivEEGxTmqr',
    createdAt: '2022-03-07T12:50:02.483Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 212,
    },
    transactionType: 'transferOut',
    amount: -96,
  },
  {
    _id: 'QmSMckmAe4M4ktqnZ',
    createdAt: '2022-03-07T12:50:02.505Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9198,
    },
    transactionType: 'transferOut',
    amount: -97,
  },
  {
    _id: 'Ao8KQukWYqJAnbhHp',
    createdAt: '2022-03-07T12:50:02.525Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 981,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 't7nhktTXWvHchqRKi',
    createdAt: '2022-03-07T12:50:43.657Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 472,
    },
  },
  {
    _id: 'B4Czecp5WBwENsmuB',
    createdAt: '2022-03-07T12:57:46.825Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 356,
    },
    transactionType: 'transferOut',
    amount: -356,
  },
  {
    _id: 'zqbnbWtLY2H2fXSYP',
    createdAt: '2022-03-07T12:57:46.843Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 1.0999999999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'N3XCY6RAHeCRLiGC5',
    createdAt: '2022-03-07T12:57:46.882Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 116,
    },
    transactionType: 'in',
    amount: 356,
  },
  {
    _id: 'dt6GJwBXF8t8fiA37',
    createdAt: '2022-03-07T12:59:52.786Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'HR5rqw5dKANdbMhC8',
    createdAt: '2022-03-07T12:59:52.805Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9101,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'YEc7L5KNseeanwdna',
    createdAt: '2022-03-07T12:59:52.824Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 973,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'LxLL682Eh3ZWiBhH6',
    createdAt: '2022-03-07T13:08:03.161Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3135.980000000006,
    },
  },
  {
    _id: 'dkQRwBiSXiiT5ggfv',
    createdAt: '2022-03-07T13:12:28.922Z',
    transactionType: 'in',
    amount: 2200,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 146.60000000000036,
    },
  },
  {
    _id: 'Ae49FuPqopeKFuAtP',
    createdAt: '2022-03-07T13:13:09.741Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6135.980000000006,
    },
    transactionType: 'transferOut',
    amount: -2500,
  },
  {
    _id: 'QNtQZRKZroLEi6aHS',
    createdAt: '2022-03-07T13:13:09.772Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1250,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bFpNDasGmExrrzhQE',
    createdAt: '2022-03-07T13:13:09.801Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17134.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '594gnGvgnRApo7sNY',
    createdAt: '2022-03-07T13:13:10.436Z',
    storeItem: {
      type: {
        _id: 'qKCYAS77NQLBFFfji',
        name: 'CT 321',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8232fa90-3584-4984-931b-d22cd2eecdb0_200x200.jpg?alt=media&token=22ad60ce-a49d-4d5f-8ced-0d43d83110e2',
        },
      },
      quantity: 39,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '7wzDx5Yh8WvoGstPC',
    createdAt: '2022-03-07T13:13:10.473Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 40.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'pyBeE5c6qA4DBaM3E',
    createdAt: '2022-03-07T13:13:10.511Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17999.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 's4wTptJLcEBbLKy2m',
    createdAt: '2022-03-07T13:13:10.548Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 120315.29999999999,
    },
    transactionType: 'transferOut',
    amount: -1600,
  },
  {
    _id: 'shmzqN5hZzwyZcvCH',
    createdAt: '2022-03-07T13:13:10.584Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 146.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -144,
  },
  {
    _id: 'BzWoExeNrjmdcWEWS',
    createdAt: '2022-03-07T13:13:10.602Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'ccn5azPDWyzBoAS5g',
    createdAt: '2022-03-07T13:13:10.647Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F87490809-61cf-4c86-8e6c-97be3c8f9d18_200x200.jpeg?alt=media&token=fac38e50-d6ca-49ef-875e-831d15caaa81',
        },
      },
      quantity: 60,
    },
    transactionType: 'in',
    amount: 250,
  },
  {
    _id: 'patqvK8rMyvL5qTMk',
    createdAt: '2022-03-07T13:16:23.614Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F87490809-61cf-4c86-8e6c-97be3c8f9d18_200x200.jpeg?alt=media&token=fac38e50-d6ca-49ef-875e-831d15caaa81',
        },
      },
      quantity: 310,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Lb3hP5sQcidWqYD6w',
    createdAt: '2022-03-07T13:16:23.666Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 734,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rQjz3C5DoL7AMzNjT',
    createdAt: '2022-03-07T13:16:23.710Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1636,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gKAj3AfoT5cbaBbDj',
    createdAt: '2022-03-07T13:16:23.749Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 197,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NPbKsaP7gL6iuKRnS',
    createdAt: '2022-03-07T13:16:23.778Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4056,
    },
    transactionType: 'transferOut',
    amount: -99,
  },
  {
    _id: '7av2r6BJjk7gXsr3L',
    createdAt: '2022-03-07T13:17:58.246Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 352,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'TqfrNSEcxgZEpes3H',
    createdAt: '2022-03-07T13:17:58.263Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1265,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'EYPFHkc3zwvjtW6r3',
    createdAt: '2022-03-07T13:17:58.279Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 197,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'eTzX4SymtHj92qXFR',
    createdAt: '2022-03-07T13:25:21.228Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 315,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'DiYx7APNAAtgknaLa',
    createdAt: '2022-03-07T13:25:21.245Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9664.579999999993,
    },
    transactionType: 'transferOut',
    amount: -0.05,
  },
  {
    _id: 'Gv7s2bd5i6MTxnxmC',
    createdAt: '2022-03-07T13:25:21.268Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1220,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'Mzt9ukFkwK8HNCGv7',
    createdAt: '2022-03-07T15:15:12.011Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3635.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4FdsrQivvMbWkEFxA',
    createdAt: '2022-03-07T15:15:12.032Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1250,
    },
    transactionType: 'transferOut',
    amount: -1050,
  },
  {
    _id: 'bX97vfCfPggW7t8Hf',
    createdAt: '2022-03-07T15:15:12.056Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 79.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'cHNHigKnyRoReuSLw',
    createdAt: '2022-03-07T15:15:12.072Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118715.29999999999,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'uvWnbHXWvPSFTLbZQ',
    createdAt: '2022-03-07T15:15:12.088Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2202.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'SooBE7B8shjcSRi3o',
    createdAt: '2022-03-07T15:15:12.129Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 847,
    },
    transactionType: 'in',
    amount: 75,
  },
  {
    _id: 'Ph4KbDcoEMGSqxAMt',
    createdAt: '2022-03-07T15:16:04.852Z',
    transactionType: 'transferIn',
    amount: 1100,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3635.980000000006,
    },
  },
  {
    _id: 'kZao99xBDAsjGrAog',
    createdAt: '2022-03-07T15:18:35.326Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 7430,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'itiDDATyLjTgXnhTi',
    createdAt: '2022-03-07T15:18:35.341Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9664.529999999993,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'G6wfcpRTcbQGjNFhb',
    createdAt: '2022-03-07T15:18:35.357Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 118215.29999999999,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'sX7tXrehkgxzkREw8',
    createdAt: '2022-03-07T15:18:35.372Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17949.5,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'XqqsMALAHpXWnRgY6',
    createdAt: '2022-03-07T15:18:35.409Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 100,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 's46g79z74BjXKPfes',
    createdAt: '2022-03-07T15:19:32.787Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17134.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NZkL8YkZWnfuvtpYN',
    createdAt: '2022-03-07T15:19:32.831Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4735.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'WiBaNto7gYiGNtsoR',
    createdAt: '2022-03-07T15:19:32.869Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LNrHETNXwYEc46pNd',
    createdAt: '2022-03-07T15:19:32.912Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2855.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'mG9ZecayhuwQa2fzg',
    createdAt: '2022-03-07T15:19:32.944Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2138.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'C6yywYvKtw2fteBsa',
    createdAt: '2022-03-07T15:19:32.976Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 115615.29999999999,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'Q99FWJXuRX7sH2KfP',
    createdAt: '2022-03-07T15:19:33.010Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 394,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'Gq5jajimowsmJupCW',
    createdAt: '2022-03-07T15:19:33.037Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18056,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'dpouESPBBc9hrAKra',
    createdAt: '2022-03-07T15:19:33.057Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17919.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '5aarzNSPXw2RBWXfw',
    createdAt: '2022-03-07T15:19:33.077Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 900,
    },
    transactionType: 'transferOut',
    amount: -170,
  },
  {
    _id: '6o3ZT5PokgzYvn3uD',
    createdAt: '2022-03-07T15:19:33.096Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3957,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'chQpdQ6CNbrLDDcPz',
    createdAt: '2022-03-08T04:31:03.069Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3935.980000000006,
    },
    transactionType: 'transferOut',
    amount: -340,
  },
  {
    _id: '5F6xdX3bcS46TJKMN',
    createdAt: '2022-03-08T04:31:03.095Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FrEakcF9GoKphqvgM',
    createdAt: '2022-03-08T04:31:03.117Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17134.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2e4i3krammeyrdy24',
    createdAt: '2022-03-08T04:31:03.139Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2831.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -10.2,
  },
  {
    _id: 'rs4Ghzg2LckeBkGBz',
    createdAt: '2022-03-08T04:31:03.174Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2090.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'cGMgz56gpAXHJKHrq',
    createdAt: '2022-03-08T04:31:03.195Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 115215.29999999999,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'ezaLYZXroYKtKPAH6',
    createdAt: '2022-03-08T04:31:03.217Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'zsbDLwK5PoiY32Hf6',
    createdAt: '2022-03-08T04:31:03.235Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 374,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '7zwB7LmJdBqn8wRBT',
    createdAt: '2022-03-08T04:31:03.255Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 730,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'cNuPNSfxQZRjyW2aJ',
    createdAt: '2022-03-08T04:31:03.278Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3898,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'r5aNMemsWQAtcxq5p',
    createdAt: '2022-03-08T04:33:56.903Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17134.64425,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'WSAwpTXFpE38J78rW',
    createdAt: '2022-03-08T04:33:56.920Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3595.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1361,
  },
  {
    _id: 'ZSHsdohmp7ry5NmNr',
    createdAt: '2022-03-08T04:33:56.938Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rMeFFyFQnXBq7qjGT',
    createdAt: '2022-03-08T04:33:56.960Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2821.000000000001,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: '3ZhkGKekKxnKGjioY',
    createdAt: '2022-03-08T04:33:56.978Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2070.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -82,
  },
  {
    _id: '3EmnfeoKW9A5KwHEi',
    createdAt: '2022-03-08T04:33:56.995Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 115065.29999999999,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'W6RagQrvTXDZpMCm4',
    createdAt: '2022-03-08T04:33:57.016Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 368,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'yrkK84KfMrJFkQEa5',
    createdAt: '2022-03-08T04:33:57.038Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 18016,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'vy3ary4QFKv2K8XPE',
    createdAt: '2022-03-08T04:33:57.057Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17907.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'oJuzyXLJ6puHqgFw5',
    createdAt: '2022-03-08T04:33:57.074Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 675,
    },
    transactionType: 'transferOut',
    amount: -230,
  },
  {
    _id: 'fDJ3py2fz8iX6ThQq',
    createdAt: '2022-03-08T04:33:57.093Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3873,
    },
    transactionType: 'transferOut',
    amount: -99,
  },
  {
    _id: 'BhvwCDW2QcnnKyjHp',
    createdAt: '2022-03-08T04:45:25.886Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 922,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '6k2w74GGjJACvDgHH',
    createdAt: '2022-03-08T04:45:25.908Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 159.4700000000002,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'FRzHsBfvqebtyoA7h',
    createdAt: '2022-03-08T04:45:25.928Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 43.800000000000026,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'M2iNdnBSncu9Bhmc3',
    createdAt: '2022-03-08T04:45:25.947Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 434.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'oLfF8NodXbk9iuH7i',
    createdAt: '2022-03-08T04:45:25.978Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 168.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'iRRkbTC729EKM4nui',
    createdAt: '2022-03-08T04:45:25.999Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9624.529999999993,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hmkzF22deotumuHsP',
    createdAt: '2022-03-08T04:45:26.034Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 553,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Tf2SRiHrhuCBzRata',
    createdAt: '2022-03-08T04:45:26.055Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 943,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'ZnpvFHwZhE58cHYnP',
    createdAt: '2022-03-08T04:45:26.072Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 145,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '2vwS9w4aD37wA8Khr',
    createdAt: '2022-03-08T04:45:26.090Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RKRAoKYRmTnmx9MTo',
    createdAt: '2022-03-08T04:45:26.112Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1636,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'dsooBvek9mBRv4oLe',
    createdAt: '2022-03-08T04:45:26.132Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 734,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'KbDsXbvqWRytGE8br',
    createdAt: '2022-03-08T04:45:26.152Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1120,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'xbFd2a7gDjoLAaiPt',
    createdAt: '2022-03-08T06:45:39.581Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 146,
    },
  },
  {
    _id: 'aPmrm3jgdiEtueFyB',
    createdAt: '2022-03-08T07:18:42.243Z',
    transactionType: 'in',
    amount: 3500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17133.64425,
    },
  },
  {
    _id: 'FvmknJGsLpnAc5a6b',
    createdAt: '2022-03-08T07:18:59.635Z',
    transactionType: 'in',
    amount: 400,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'CkzXkjA5WJHcTbBhA',
    createdAt: '2022-03-08T07:47:11.822Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 426,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'xQJcgydpHGrMeLbe9',
    createdAt: '2022-03-08T07:47:11.844Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 162.09999999999997,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'SSbv7g7JoDrPNX9cJ',
    createdAt: '2022-03-08T07:47:11.863Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 158,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'zGscGxucuAfKbX8EM',
    createdAt: '2022-03-08T07:47:11.881Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3774,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'nhKTk9QNXX5sF2Ann',
    createdAt: '2022-03-08T07:47:11.899Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4773,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '37EjkB6JyM3rp4DSB',
    createdAt: '2022-03-08T07:55:02.146Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6670,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'nm6PYhgA258Whi6u7',
    createdAt: '2022-03-08T07:55:02.166Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9623.529999999993,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'TxG4eSY5G5S9eH7hb',
    createdAt: '2022-03-08T07:55:02.186Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 114415.29999999999,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'qZvhTGhcvidvsuNCN',
    createdAt: '2022-03-08T07:55:02.212Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17895.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'we3vFJkCnRxApQrpu',
    createdAt: '2022-03-08T07:55:02.265Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 125,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: '7uQE7moptYfzfRZFn',
    createdAt: '2022-03-08T07:56:28.440Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 326,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'uPdCiWbHfzeD9jspk',
    createdAt: '2022-03-08T07:56:28.466Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 126.09999999999997,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '378PftXRB85WTYeJi',
    createdAt: '2022-03-08T07:56:28.485Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'NomyimbnvuJYxzZs7',
    createdAt: '2022-03-08T07:56:28.502Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3726,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'kKTgto6d63d3G2fLY',
    createdAt: '2022-03-08T07:56:54.730Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 20633.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'JMMDhGAqHg2SEKnva',
    createdAt: '2022-03-08T07:56:54.749Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 78.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'w3gX7qWZ6QWBpNeP6',
    createdAt: '2022-03-08T07:56:54.874Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 113915.29999999999,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '5QiiH42NnTuvE3Kbc',
    createdAt: '2022-03-08T07:56:54.897Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1988.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'QoprgoJhYG9ZhCdXz',
    createdAt: '2022-03-08T07:56:54.919Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9523.529999999993,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'vMiopjGEkyeLoWtkE',
    createdAt: '2022-03-08T07:56:55.751Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 625,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '3tfT5sKxrL9dvj8Zf',
    createdAt: '2022-03-08T07:56:55.797Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4773,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'AaT3JGurXzjYS8z7Z',
    createdAt: '2022-03-08T07:57:48.659Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 18633.64425,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'sbb3NNZBrYtxcC3Ex',
    createdAt: '2022-03-08T07:57:48.678Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 77.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Yt5C6vcYqkSW9WicH',
    createdAt: '2022-03-08T07:57:48.695Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 112915.29999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'duacnNAu4Qeon9JiB',
    createdAt: '2022-03-08T07:57:48.712Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1862.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'K25me3y9GSKhB9gnd',
    createdAt: '2022-03-08T07:57:48.735Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9423.529999999993,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '92Fbcco8P7sbjCppi',
    createdAt: '2022-03-08T07:57:48.756Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 375,
    },
    transactionType: 'transferOut',
    amount: -255,
  },
  {
    _id: 'Wbp87kquJWGviKwWQ',
    createdAt: '2022-03-08T07:57:48.777Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4731,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'QYeTizBdN2gvjxmLS',
    createdAt: '2022-03-08T08:46:15.413Z',
    transactionType: 'transferIn',
    amount: 30.6,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 117.09999999999997,
    },
  },
  {
    _id: 'uY3ZRTxJyin4HxSfo',
    createdAt: '2022-03-08T08:46:51.009Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1112,
    },
  },
  {
    _id: 'c7jnWWNzQovax2ipF',
    createdAt: '2022-03-08T08:47:49.028Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4689,
    },
  },
  {
    _id: 'FYJZaSW66faBckHsx',
    createdAt: '2022-03-08T08:48:21.485Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3714,
    },
  },
  {
    _id: 'hS3uF5YkPc8wYug3r',
    createdAt: '2022-03-08T08:49:06.940Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2780.000000000001,
    },
  },
  {
    _id: '4yuCfwRD325FKByhA',
    createdAt: '2022-03-08T08:49:53.448Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 112914.29999999999,
    },
  },
  {
    _id: 'QNwAm8hhPjFnPEPFC',
    createdAt: '2022-03-08T08:50:39.616Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 432.22000000000014,
    },
  },
  {
    _id: 'yrAHLejtXcGSDcYnR',
    createdAt: '2022-03-08T08:51:41.891Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9323.529999999993,
    },
  },
  {
    _id: '8Dtt7tbGJHmdmdzve',
    createdAt: '2022-03-08T08:53:31.845Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3546,
    },
  },
  {
    _id: '4LskRY6HajFxAnZXM',
    createdAt: '2022-03-08T10:42:55.689Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 553,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '9kQHjBh2T5rMpZYyh',
    createdAt: '2022-03-08T10:42:55.705Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 934,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'ffBuXhqy9RrMSkfrL',
    createdAt: '2022-03-08T10:42:55.720Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 159.2700000000002,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'zJrECGeDPkGQfgYjs',
    createdAt: '2022-03-08T10:42:55.733Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2955.000000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'pdcuiJSZqxc4Dx4ou',
    createdAt: '2022-03-08T10:42:55.771Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'trWuWD9aJ5KRmP3EN',
    createdAt: '2022-03-08T10:44:12.862Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2234.980000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'dB5WS2KjmjgWcvrbm',
    createdAt: '2022-03-08T10:44:12.888Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16633.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ED3R4HMnCjdkvTGbv',
    createdAt: '2022-03-08T10:44:12.905Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2952.000000000001,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'D49CsFpASJ9qPJtCA',
    createdAt: '2022-03-08T10:44:12.926Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'vcmgXpWuaKhSow2fz',
    createdAt: '2022-03-08T10:44:12.955Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 162914.3,
    },
    transactionType: 'transferOut',
    amount: -1800,
  },
  {
    _id: 'yAYmgPvXPXnPEuMzP',
    createdAt: '2022-03-08T10:44:12.983Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9623.529999999993,
    },
    transactionType: 'transferOut',
    amount: -2.3,
  },
  {
    _id: '2cBgyu3oeRBp7DSK3',
    createdAt: '2022-03-08T10:44:13.004Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3914,
    },
    transactionType: 'transferOut',
    amount: -49,
  },
  {
    _id: 'B5AJbgGJwvLdMBNAM',
    createdAt: '2022-03-08T10:45:16.812Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2034.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'qWRShTES98Ef88Q7n',
    createdAt: '2022-03-08T10:45:16.835Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GvZJhRr3rTt48YZpq',
    createdAt: '2022-03-08T10:45:16.851Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16633.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SbJrNhm2tTC5dYbva',
    createdAt: '2022-03-08T10:45:16.868Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2912.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oZWkmwtp6EhK5tzeZ',
    createdAt: '2022-03-08T10:45:16.884Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xnh69WGzB5FQ2PZTE',
    createdAt: '2022-03-08T10:45:16.911Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17895.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wNgwmoCwJCmejSs4Y',
    createdAt: '2022-03-08T10:45:16.928Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xWr75rdGusnLR4db7',
    createdAt: '2022-03-08T10:45:16.948Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 71.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ERR8WPqXhRPcpdYXp',
    createdAt: '2022-03-08T10:45:16.965Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 38.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'rb4n8jJkw4q3vnFGo',
    createdAt: '2022-03-08T10:45:16.981Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 482.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '5GTbRTQMoxjbS5Lzq',
    createdAt: '2022-03-08T10:45:16.997Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9621.229999999994,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'BmvNJFbzYksAsNHWS',
    createdAt: '2022-03-08T10:45:17.021Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4809,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'JfHshyzJQaia5fbYH',
    createdAt: '2022-03-08T10:46:52.000Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16633.64425,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'F988BM6NCHkusYGqP',
    createdAt: '2022-03-08T10:46:52.018Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2912.000000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'tZ8twLJ9bMdZWYPx5',
    createdAt: '2022-03-08T10:46:52.035Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9621.229999999994,
    },
    transactionType: 'transferOut',
    amount: -1.1,
  },
  {
    _id: 'zc5NXM5b2nThwCNpN',
    createdAt: '2022-03-08T10:46:52.061Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 543,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'S3ympF22pAM2WT7p3',
    createdAt: '2022-03-08T10:46:52.075Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 917,
    },
    transactionType: 'transferOut',
    amount: -6.3,
  },
  {
    _id: '6SAdyyCm4sKELWjqu',
    createdAt: '2022-03-08T10:46:52.088Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3865,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'p6gsYhPejJGAxrnMz',
    createdAt: '2022-03-08T11:56:03.344Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16563.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dZzgKiZ75PXa3wrCZ',
    createdAt: '2022-03-08T11:56:03.365Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1234.980000000006,
    },
    transactionType: 'transferOut',
    amount: -530,
  },
  {
    _id: 'S5PZQjv2yDbqp9Lt4',
    createdAt: '2022-03-08T11:56:03.383Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HZr2DwMna246JEdZQ',
    createdAt: '2022-03-08T11:56:03.398Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2900.000000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '4p9ZukEK5ZKJEbXNb',
    createdAt: '2022-03-08T11:56:03.417Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 341,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cKvrBcde7uBf3LiSj',
    createdAt: '2022-03-08T11:56:03.435Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17966,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WPcSLN3TBNGPdcMjR',
    createdAt: '2022-03-08T11:56:03.451Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 161114.3,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'HpzE6AYNCnQq6vS7J',
    createdAt: '2022-03-08T11:56:03.474Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1736.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'eoSmSPLjBaRYQjc6a',
    createdAt: '2022-03-08T11:56:03.495Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 445,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'ocLbAxuPdEYrj7hvb',
    createdAt: '2022-03-08T11:56:03.515Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3840,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'wMQztxdqqA8ptYqvy',
    createdAt: '2022-03-08T11:56:03.530Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4733,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wjXTsSar92Cczfa9i',
    createdAt: '2022-03-08T12:59:24.164Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16563.64425,
    },
    transactionType: 'transferOut',
    amount: -620,
  },
  {
    _id: '9KasBg5mmi8dw8kYt',
    createdAt: '2022-03-08T12:59:24.179Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2885.000000000001,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'HRzJ33QuhcorWdNN5',
    createdAt: '2022-03-08T12:59:24.197Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1706.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'zCPwWqRTCGBrR55zk',
    createdAt: '2022-03-08T12:59:24.214Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17966,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'xhQJoFxRHDrmvD6MD',
    createdAt: '2022-03-08T12:59:24.233Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 365,
    },
    transactionType: 'transferOut',
    amount: -112,
  },
  {
    _id: '55PmL2E7cecro8nHB',
    createdAt: '2022-03-08T12:59:24.251Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3808,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'cCjvvQDCd8yur98sh',
    createdAt: '2022-03-08T13:11:43.211Z',
    transactionType: 'transferIn',
    amount: 3225,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 704.9800000000059,
    },
  },
  {
    _id: 'Nr4pwQmGxPgs4PKCN',
    createdAt: '2022-03-08T13:12:35.516Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
  },
  {
    _id: 'A4jqsuJu8rmA6pR3A',
    createdAt: '2022-03-08T13:16:19.742Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3929.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BdBc6ybpwzZPqRiKF',
    createdAt: '2022-03-08T13:16:19.764Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2200,
    },
    transactionType: 'transferOut',
    amount: -2100,
  },
  {
    _id: 'YEz9edz8HSZh7AQgY',
    createdAt: '2022-03-08T13:16:19.787Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 75.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'KA9svLirZwjQ4CMAj',
    createdAt: '2022-03-08T13:16:19.804Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160864.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'B35YwmGwjoz4ubpC5',
    createdAt: '2022-03-08T13:16:19.823Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1670.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'H2z5WF8cTFg2PmrC8',
    createdAt: '2022-03-08T13:16:19.865Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 867,
    },
    transactionType: 'in',
    amount: 160,
  },
  {
    _id: 'BPLZswr7oZKrvH5Jc',
    createdAt: '2022-03-08T14:51:52.231Z',
    transactionType: 'transferIn',
    amount: 145,
    storeItem: {
      type: {
        _id: 'tjqw98ZsTsKrbAc9t',
        name: 'NABOLISI CHEESE SUPER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fadd76792-a2f0-43e8-941d-f72aa4fae45e_200x200.jpg?alt=media&token=a6a75ce4-bb9e-4423-8825-c5d94ca32d1b',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'Ba6EyY6tQ9hBwhiNJ',
    createdAt: '2022-03-08T15:09:23.458Z',
    transactionType: 'transferIn',
    amount: 810,
    storeItem: {
      type: {
        _id: 'aDeGN74pbvFRrd67f',
        name: 'YOUGHURT 10 KG BUCKET',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd997e1bb-946a-470f-aa13-70d2d5ac4a21_200x200.jpg?alt=media&token=e88e6639-61d4-4a1f-9374-f472ae1385a3',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'ymkBcbkhnx5P5dupd',
    createdAt: '2022-03-08T15:09:44.342Z',
    transactionType: 'transferIn',
    amount: 81,
    storeItem: {
      type: {
        _id: 'aDeGN74pbvFRrd67f',
        name: 'YOUGHURT 10 KG BUCKET',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd997e1bb-946a-470f-aa13-70d2d5ac4a21_200x200.jpg?alt=media&token=e88e6639-61d4-4a1f-9374-f472ae1385a3',
        },
      },
      quantity: 810,
    },
  },
  {
    _id: 'sMpLEiAzmaRP3yk5T',
    createdAt: '2022-03-08T15:14:27.458Z',
    transactionType: 'transferOut',
    amount: -730,
    storeItem: {
      type: {
        _id: 'aDeGN74pbvFRrd67f',
        name: 'YOUGHURT 10 KG BUCKET',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd997e1bb-946a-470f-aa13-70d2d5ac4a21_200x200.jpg?alt=media&token=e88e6639-61d4-4a1f-9374-f472ae1385a3',
        },
      },
      quantity: 891,
    },
  },
  {
    _id: '6gYjxybGiYZqNYgu5',
    createdAt: '2022-03-08T15:14:43.347Z',
    transactionType: 'out',
    amount: -161,
    storeItem: {
      type: {
        _id: 'aDeGN74pbvFRrd67f',
        name: 'YOUGHURT 10 KG BUCKET',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd997e1bb-946a-470f-aa13-70d2d5ac4a21_200x200.jpg?alt=media&token=e88e6639-61d4-4a1f-9374-f472ae1385a3',
        },
      },
      quantity: 161,
    },
  },
  {
    _id: 'HTPmc8sH7BaCPjfyx',
    createdAt: '2022-03-09T04:49:36.159Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3929.980000000006,
    },
    transactionType: 'transferOut',
    amount: -820,
  },
  {
    _id: 'TZJPLPXdwB5jREby6',
    createdAt: '2022-03-09T04:49:36.178Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EoZhPLpaXxm8k4e7g',
    createdAt: '2022-03-09T04:49:36.192Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15943.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ykw29MsLYNRPjGFW7',
    createdAt: '2022-03-09T04:49:36.209Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2861.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oDB4XRBi4m8EMPwLW',
    createdAt: '2022-03-09T04:49:36.229Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jBHKGX93hwbbYPgeS',
    createdAt: '2022-03-09T04:49:36.248Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17895.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'vaeA47XKEA47gJyTz',
    createdAt: '2022-03-09T04:49:36.265Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BbBCJz6XCJgazZ66j',
    createdAt: '2022-03-09T04:49:36.282Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 70.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Mi55N8TBv2okADMLw',
    createdAt: '2022-03-09T04:49:36.299Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 38.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'PHC665CyxgwrnvnFR',
    createdAt: '2022-03-09T04:49:36.324Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 474.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'BmCG7JLoDuHkQKe5b',
    createdAt: '2022-03-09T04:49:36.337Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9619.529999999993,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'WZfubGZ8nr4AErPnX',
    createdAt: '2022-03-09T04:49:36.354Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4733,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'dedrT48KGWP9Kzaz4',
    createdAt: '2022-03-09T04:52:57.337Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15943.644250000001,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: 'LnWwhRsrSkaPREuT4',
    createdAt: '2022-03-09T04:52:57.357Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2861.000000000001,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '4BLvmkrJiaooo8AGy',
    createdAt: '2022-03-09T04:52:57.377Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1544.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'EnzJ479RKn7rfKxFy',
    createdAt: '2022-03-09T04:52:57.393Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17946,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'bmD7WWRDLjiMq4nQg',
    createdAt: '2022-03-09T04:52:57.412Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 253,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'p99NeWjrWoy4ExTp7',
    createdAt: '2022-03-09T04:52:57.429Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3765,
    },
    transactionType: 'transferOut',
    amount: -49,
  },
  {
    _id: 'aAMHDc4cYrAmGPeeT',
    createdAt: '2022-03-09T04:54:44.726Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3109.980000000006,
    },
    transactionType: 'transferOut',
    amount: -340,
  },
  {
    _id: 'eWriLeEjWm5ugETZk',
    createdAt: '2022-03-09T04:54:44.742Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7SZRExjWFQenHzn5X',
    createdAt: '2022-03-09T04:54:44.756Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15193.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gopNJXzqM2uGNhj6S',
    createdAt: '2022-03-09T04:54:44.772Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1499.6000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mdTssJDh8WhcCRAdR',
    createdAt: '2022-03-09T04:54:44.785Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 37.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'GeJkcARaGW4zRHPHq',
    createdAt: '2022-03-09T04:54:44.807Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9618.929999999993,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XospSoHxM3d29Nb6a',
    createdAt: '2022-03-09T04:54:44.821Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17883.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'egityamMC9t8CcLk7',
    createdAt: '2022-03-09T04:54:44.835Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3751,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: '68fDixQpsrYRpuidK',
    createdAt: '2022-03-09T04:58:23.380Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2769.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'YQ2JheYG4gDcRgouA',
    createdAt: '2022-03-09T04:58:23.402Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15193.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KoF3tvcFZQBL7oHCy',
    createdAt: '2022-03-09T04:58:23.424Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6cpwvkAvpx2JbvQyi',
    createdAt: '2022-03-09T04:58:23.441Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2839.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dcBGiQLeYmqKAoCn7',
    createdAt: '2022-03-09T04:58:23.465Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9618.929999999993,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'e2RcoFxrEEAE3oM89',
    createdAt: '2022-03-09T04:58:23.482Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17877.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'tHsq3DABGFKP5iXaF',
    createdAt: '2022-03-09T04:58:23.499Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3730,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'KrEwGTyXrN7Ps2PXt',
    createdAt: '2022-03-09T05:02:06.867Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1142,
    },
  },
  {
    _id: 'qnhyP8Dnkf4PnzmuY',
    createdAt: '2022-03-09T05:02:52.233Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 963,
    },
  },
  {
    _id: 'K2ZqcEuJamZooSKs2',
    createdAt: '2022-03-09T05:03:28.220Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1574,
    },
  },
  {
    _id: 'DwyMJ2AocJFxabPmJ',
    createdAt: '2022-03-09T05:04:05.671Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 497,
    },
  },
  {
    _id: 'dMuGNtpw64G5H8pFu',
    createdAt: '2022-03-09T05:50:31.902Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 43.40000000000003,
    },
  },
  {
    _id: 'h2TJziGZgQkXJGyYd',
    createdAt: '2022-03-09T05:51:03.446Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3786,
    },
  },
  {
    _id: 'echurS4xNqZrF5oob',
    createdAt: '2022-03-09T05:51:30.990Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 910.7,
    },
  },
  {
    _id: 'FvK9LmaxqFP2LJqBt',
    createdAt: '2022-03-09T07:18:01.608Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8980,
    },
  },
  {
    _id: 'wvc397pxwKpbCk879',
    createdAt: '2022-03-09T07:46:13.263Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'JPaCxdAH35yXi9Fq4',
    createdAt: '2022-03-09T07:46:13.314Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1010.7,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'RqcGzQS4DX5RTwZ58',
    createdAt: '2022-03-09T07:46:13.344Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 159.0200000000002,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'L8ztDqABK7J2T7NeA',
    createdAt: '2022-03-09T07:46:13.368Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2839.000000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'fJH3NHdhNEiATxcZh',
    createdAt: '2022-03-09T07:46:13.410Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: '4PHxxJicihxnESHKu',
    createdAt: '2022-03-09T10:10:27.136Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15193.644250000001,
    },
    transactionType: 'transferOut',
    amount: -230,
  },
  {
    _id: 'ZZtYFzncYJPn5ZDC8',
    createdAt: '2022-03-09T10:10:27.155Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2836.000000000001,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'Q7anaFaqYmdPu9u5i',
    createdAt: '2022-03-09T10:10:27.174Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1499.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: '7cXPKcSXBJFaAoExA',
    createdAt: '2022-03-09T10:10:27.194Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17931,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yLTYi38zbijQLdxTt',
    createdAt: '2022-03-09T10:10:27.214Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 133,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: '23vrd6uBdfALRR9tP',
    createdAt: '2022-03-09T10:10:27.237Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3716,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'FtFp66e7G5wmQskvC',
    createdAt: '2022-03-09T10:14:16.551Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14963.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D8EHytiKfW5xhvgrn',
    createdAt: '2022-03-09T10:14:16.593Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1969.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '5wXLC5JEuLPC2RSgZ',
    createdAt: '2022-03-09T10:14:16.646Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'T5tGbFi4GQZdobdJy',
    createdAt: '2022-03-09T10:14:16.689Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2829.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yZsKm4z6Xf6pckrhn',
    createdAt: '2022-03-09T10:14:16.741Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bJvrn9KmDzLXfiJs9',
    createdAt: '2022-03-09T10:14:16.879Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 466.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'dPYPreTFCaDYxggYX',
    createdAt: '2022-03-09T10:14:16.905Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 69.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'kbsM8q3prEfdEkXm3',
    createdAt: '2022-03-09T10:14:16.964Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17865.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'GuyAjfKK6tz3cXECB',
    createdAt: '2022-03-09T10:14:16.984Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3679,
    },
    transactionType: 'transferOut',
    amount: -185,
  },
  {
    _id: 'yJqdJXiXqpc5EJ5qW',
    createdAt: '2022-03-09T10:58:44.504Z',
    transactionType: 'transferIn',
    amount: 2100,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 100,
    },
  },
  {
    _id: 'LWy9Bit9rwKgsHozT',
    createdAt: '2022-03-09T10:59:49.150Z',
    transactionType: 'transferIn',
    amount: 1700,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1169.980000000006,
    },
  },
  {
    _id: 'Nb9HxrapsmXSKLj5M',
    createdAt: '2022-03-09T11:21:28.901Z',
    transactionType: 'in',
    amount: 2600,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14963.644250000001,
    },
  },
  {
    _id: 'jAxtN8HozxCs8DpEh',
    createdAt: '2022-03-09T11:21:48.186Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 400,
    },
  },
  {
    _id: 'eEgFWd7Se7cQx5SiY',
    createdAt: '2022-03-09T11:25:22.474Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 750,
    },
    transactionType: 'transferOut',
    amount: -246,
  },
  {
    _id: 'Q5LYjwBh23a5HERaQ',
    createdAt: '2022-03-09T11:25:22.492Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9620,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'dWCLp2uzhsjJ2gGLT',
    createdAt: '2022-03-09T11:25:22.515Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3906,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'eGHbaGD6EErfSNnoG',
    createdAt: '2022-03-09T11:25:22.530Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1083,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'mfd9ipg4Wdq4JGJko',
    createdAt: '2022-03-09T11:26:22.720Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 504,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'A3XTqAi4L7xvYNFGY',
    createdAt: '2022-03-09T11:26:22.734Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9520,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'MmJsYiS8A3FfsBteB',
    createdAt: '2022-03-09T11:26:22.747Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1074,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'LCqcug5uHx3SBL7q4',
    createdAt: '2022-03-09T11:28:13.767Z',
    transactionType: 'in',
    amount: 12,
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 0.09999999999999987,
    },
  },
  {
    _id: 'MXTNeBDDrpi3acx9F',
    createdAt: '2022-03-09T11:29:49.985Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 445,
    },
    transactionType: 'transferOut',
    amount: -445,
  },
  {
    _id: 'dakmooYMydEb52KKi',
    createdAt: '2022-03-09T11:29:50.003Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 12.1,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'dgBYnbgL7krZBngof',
    createdAt: '2022-03-09T11:29:50.043Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 240,
    },
    transactionType: 'in',
    amount: 440,
  },
  {
    _id: 'wFtaP8vWjcWY8a4Rs',
    createdAt: '2022-03-09T11:32:14.394Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17563.64425,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '7ByqyzL6bTxb4c3qF',
    createdAt: '2022-03-09T11:32:14.411Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2869.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'erNRTYp4hgL58hSwu',
    createdAt: '2022-03-09T11:32:14.431Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 522,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'oTJSKQqp7So6jmkDc',
    createdAt: '2022-03-09T11:32:14.450Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 458.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'XdNQvWeaNs3KJ4BeM',
    createdAt: '2022-03-09T11:32:14.465Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2074,
    },
    transactionType: 'transferOut',
    amount: -220,
  },
  {
    _id: 'kHtfofuMWpGKpLMDt',
    createdAt: '2022-03-09T11:32:14.483Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1187,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'JAmbDgq5t92HEvqYM',
    createdAt: '2022-03-09T11:33:34.718Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 680,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'sTTRnQHn4TfPuKzh9',
    createdAt: '2022-03-09T11:33:34.739Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9461,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'uEkpBeu6WM6GpBEon',
    createdAt: '2022-03-09T11:33:34.753Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1069,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'nAAG7c3xSKfXu2gct',
    createdAt: '2022-03-09T11:34:07.788Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 610,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'PnAHhgvvDR9CXmDBR',
    createdAt: '2022-03-09T11:34:07.809Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9387,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'DMEW3g82nHYvYSzjH',
    createdAt: '2022-03-09T11:34:07.824Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1063,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'pheQZQhgRodztpp7L',
    createdAt: '2022-03-09T11:37:02.416Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 490,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'Pfp3LEppaZ8f3drGL',
    createdAt: '2022-03-09T11:37:02.436Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3904,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'HipAbpazsTmH5jYsa',
    createdAt: '2022-03-09T11:37:02.462Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1053,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'cNWALYoeBXiKJkuva',
    createdAt: '2022-03-09T11:38:55.786Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 454,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'LSAh9JNKqqPdcnCur',
    createdAt: '2022-03-09T11:38:55.809Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3867,
    },
    transactionType: 'transferOut',
    amount: -81,
  },
  {
    _id: 'QAQMo3wXzp2a7nRyA',
    createdAt: '2022-03-09T11:38:55.827Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1050,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'pco8FHjs9JkNvTz7M',
    createdAt: '2022-03-09T11:40:34.472Z',
    transactionType: 'out',
    amount: -159,
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 374,
    },
  },
  {
    _id: 'C29kNQt4HjwAd9wrz',
    createdAt: '2022-03-09T12:37:16.171Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 490,
    },
  },
  {
    _id: 'wn2wJNxXcXXJpTLuL',
    createdAt: '2022-03-09T12:37:42.880Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3701,
    },
  },
  {
    _id: 'hpko6gThuz7AXrN69',
    createdAt: '2022-03-09T12:38:09.090Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2829.000000000001,
    },
  },
  {
    _id: '82sJTvHmdeaBXxkko',
    createdAt: '2022-03-09T13:07:21.751Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2469.980000000006,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: 'zxgqbyBgRiy44LtFF',
    createdAt: '2022-03-09T13:07:21.805Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HtrpjLXEYwPy4Ca8y',
    createdAt: '2022-03-09T13:07:21.825Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17413.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6GCAggemMC5YB69sN',
    createdAt: '2022-03-09T13:07:21.842Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3004.000000000001,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '2iXRnFxXciJAaKFyv',
    createdAt: '2022-03-09T13:07:21.860Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1485.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'Yveg4qRjSgfDJxtCM',
    createdAt: '2022-03-09T13:07:21.878Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159864.3,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'Gri2tLCjyX42AxLiE',
    createdAt: '2022-03-09T13:07:21.900Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17921,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'dkLBJY3jjchHHNHgd',
    createdAt: '2022-03-09T13:07:21.927Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 341,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'zDasNfnYx2mxWXbzX',
    createdAt: '2022-03-09T13:07:21.945Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 98,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'ErTLmsDPk7XRHZCD5',
    createdAt: '2022-03-09T13:07:21.970Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3901,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'RXwT5mLxaH8FsfoaC',
    createdAt: '2022-03-09T13:09:35.479Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2149.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rXb9j8EngZ42W9Rx8',
    createdAt: '2022-03-09T13:09:35.506Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2200,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'LEv2HXNknJoiMpvso',
    createdAt: '2022-03-09T13:09:35.528Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 73.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'cSyqBqJBbK93zxhze',
    createdAt: '2022-03-09T13:09:35.543Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159714.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'o5z8FG92DMt44qa9x',
    createdAt: '2022-03-09T13:09:35.565Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1467.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: '9mADmnDDeDjNoZANk',
    createdAt: '2022-03-09T13:09:35.606Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1027,
    },
    transactionType: 'in',
    amount: 160,
  },
  {
    _id: 'bXfboEAajH7k5emtj',
    createdAt: '2022-03-09T13:11:02.983Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17413.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RCd7hFmkrJy8chHTC',
    createdAt: '2022-03-09T13:11:03.020Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2149.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '9d6niFdkfW3rCMzSt',
    createdAt: '2022-03-09T13:11:03.046Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2994.000000000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '8S9D8hXpqnh7egXwS',
    createdAt: '2022-03-09T13:11:03.068Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 86,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'yTeXdnuNypbs6jBAk',
    createdAt: '2022-03-09T13:11:03.091Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 158714.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'NiTRmZhHQAbFw8SeM',
    createdAt: '2022-03-09T13:11:03.113Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9617.729999999992,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '2BusuzbJ3MFuF7GTB',
    createdAt: '2022-03-09T13:11:03.152Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 725,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'AWvxGsGNtDd9hGcCp',
    createdAt: '2022-03-09T13:11:03.183Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1627,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'pMFLXE2kEDmiWXARS',
    createdAt: '2022-03-09T13:50:06.524Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1187,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'pBJgx4Fpu5aRuGGf7',
    createdAt: '2022-03-09T13:50:06.568Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 68.40000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'pM4xWsoNntAGgf6bh',
    createdAt: '2022-03-09T13:50:06.588Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 158.7700000000002,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'wYohw8qz6WXrk3wT4',
    createdAt: '2022-03-09T13:50:06.610Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 454.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ct3WcEAK7EeQ2px5e',
    createdAt: '2022-03-09T13:50:06.630Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 167.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'pMx295uqF4GXxH4Ma',
    createdAt: '2022-03-09T13:50:06.658Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9614.729999999992,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'fAT9hPLfmR4R5FmHs',
    createdAt: '2022-03-09T13:50:06.676Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SWJqKQumj7WbgEMyv',
    createdAt: '2022-03-09T13:50:06.692Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 993.7,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'Pm2M3zBarmH6saeGP',
    createdAt: '2022-03-09T13:50:06.709Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 142,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fTiuDpi7jDWAikTgJ',
    createdAt: '2022-03-09T13:50:06.730Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Pef9Ax85uCoYic9Tg',
    createdAt: '2022-03-09T13:50:06.757Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1175,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'sFcpoq7vxbsGQpXtH',
    createdAt: '2022-03-09T13:50:53.238Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1137,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '4DTB5hTSWp2Xtoghu',
    createdAt: '2022-03-09T13:50:53.265Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 68.00000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'XHaEbgmDLNjd4TYcj',
    createdAt: '2022-03-09T13:50:53.287Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 158.57000000000022,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'dkodjHjpFGs8o6PJo',
    createdAt: '2022-03-09T13:50:53.306Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Md3sPkk38MNcZr9tj',
    createdAt: '2022-03-09T13:50:53.323Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 167.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'gx4SkuX64LbPSd4kQ',
    createdAt: '2022-03-09T13:50:53.343Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9613.229999999992,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'WgvRMXApD8sdtR7xS',
    createdAt: '2022-03-09T13:50:53.362Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pmjNHR5YAfFr8cEGT',
    createdAt: '2022-03-09T13:50:53.379Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 984.7,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'qzK3tpLpCgFpeCQrv',
    createdAt: '2022-03-09T13:50:53.397Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 142,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'u3ChhZDbiCvzbMdpQ',
    createdAt: '2022-03-09T13:50:53.428Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3LX3aNQiNBeJB3a3E',
    createdAt: '2022-03-09T13:50:53.461Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1166,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'cGBYR8xfbtA2Dxyfr',
    createdAt: '2022-03-09T13:51:55.716Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1087,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'o3XQoQvx3eepHZpZb',
    createdAt: '2022-03-09T13:51:55.840Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 67.60000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'SCF99KnXM4g7A3rfj',
    createdAt: '2022-03-09T13:51:55.863Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 158.37000000000023,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'pMtj4PrMSQZkM85fj',
    createdAt: '2022-03-09T13:51:55.971Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'sA3TYCWynq6iTaR9E',
    createdAt: '2022-03-09T13:51:56.000Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 166.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Ko39t7z572eAg6gwq',
    createdAt: '2022-03-09T13:51:56.037Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9611.729999999992,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'ByN5huYXnKi3EwTHS',
    createdAt: '2022-03-09T13:51:56.063Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wXFkKQ4QzyAexuCsX',
    createdAt: '2022-03-09T13:51:56.090Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 975.7,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'k9xGr4vF3o9AoKmWL',
    createdAt: '2022-03-09T13:51:56.111Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 142,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9sdfLuYwqggfSYf9G',
    createdAt: '2022-03-09T13:51:56.131Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pfQp6oe6pKzezPwT3',
    createdAt: '2022-03-09T13:51:56.161Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1157,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '8EZuQvx2tazMkTA3E',
    createdAt: '2022-03-10T04:29:54.837Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1899.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'fGNWGjW8xatnoH2BB',
    createdAt: '2022-03-10T04:29:54.856Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LaZPZGSLRygH5DmvB',
    createdAt: '2022-03-10T04:29:54.872Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17413.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6xbgMhrPJTLWJjGw3',
    createdAt: '2022-03-10T04:29:54.890Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2944.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Wubnny2W2mhjRaY4X',
    createdAt: '2022-03-10T04:29:54.916Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WNz25M26uJMP2H5Tc',
    createdAt: '2022-03-10T04:29:54.933Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17853.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ensHEbH56t7DT5dCP',
    createdAt: '2022-03-10T04:29:54.964Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ah9a3DWx5hZkpAfpP',
    createdAt: '2022-03-10T04:29:54.978Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 68.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'SWKMCW4Cm95xzGuQn',
    createdAt: '2022-03-10T04:29:54.991Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 37.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'seTXX6ku667knMyX3',
    createdAt: '2022-03-10T04:29:55.016Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 448.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'xyRXZuPqAuTPYBCao',
    createdAt: '2022-03-10T04:29:55.033Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9610.229999999992,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'BPwHb7RtsbtASqabv',
    createdAt: '2022-03-10T04:29:55.048Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4656,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '8nXcLpXNaSrdfNNKY',
    createdAt: '2022-03-10T04:33:13.601Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6270,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: '2zNrptyMtTMTTRGCS',
    createdAt: '2022-03-10T04:33:13.625Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9604.229999999992,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'WjpfpiaF6LRLrF6Bm',
    createdAt: '2022-03-10T04:33:13.640Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156414.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'xXxjA4EgQYpJMR9nK',
    createdAt: '2022-03-10T04:33:13.654Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17853.5,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'F6BRWuEGG8fGcemAD',
    createdAt: '2022-03-10T04:33:13.689Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 43,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'jZkRJm52LMcwQeax4',
    createdAt: '2022-03-10T04:34:04.428Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17413.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HR3TRESvJtExoeLnH',
    createdAt: '2022-03-10T04:34:04.450Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1099.980000000006,
    },
    transactionType: 'transferOut',
    amount: -640,
  },
  {
    _id: 'KfuReGc5SQ5JJTshw',
    createdAt: '2022-03-10T04:34:04.468Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9LX98v9fqtCG8uFZx',
    createdAt: '2022-03-10T04:34:04.489Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2944.000000000001,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'HB6Xvrcm64sSPMF5q',
    createdAt: '2022-03-10T04:34:04.507Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1341.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'SNm4xKwxdhCfns7uY',
    createdAt: '2022-03-10T04:34:04.524Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153814.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'nvd3p9qRAZZb2hyWk',
    createdAt: '2022-03-10T04:34:04.542Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 335,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '4bfs9RFpaDuvbhcG8',
    createdAt: '2022-03-10T04:34:04.567Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17909,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'gyod24S9rYmTmFq39',
    createdAt: '2022-03-10T04:34:04.583Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17823.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'WWZ5ugSo6cXmjBtpT',
    createdAt: '2022-03-10T04:34:04.604Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 843,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'rLX5MhRYM2F2W7ufD',
    createdAt: '2022-03-10T04:34:04.623Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3877,
    },
    transactionType: 'transferOut',
    amount: -47,
  },
  {
    _id: 'HZHcQTFdLdxor5Eze',
    createdAt: '2022-03-10T10:05:31.395Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 459.98000000000593,
    },
  },
  {
    _id: '8v2LRJCjATDcaHxzf',
    createdAt: '2022-03-10T10:06:19.007Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17413.64425,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'PHhD7rRTG4HnPXbqs',
    createdAt: '2022-03-10T10:06:19.023Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2925.000000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'qidAPsyxAaDzMsbDt',
    createdAt: '2022-03-10T10:06:19.046Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 959.9800000000059,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'pLWL9cbDfT3qwjh63',
    createdAt: '2022-03-10T10:06:19.063Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 440.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'R36bohR2fggq4mKeg',
    createdAt: '2022-03-10T10:06:19.084Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1305.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: 'kfM9Cfui3Mxeqw5Cz',
    createdAt: '2022-03-10T10:06:19.099Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 733,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'bwWHjzCsqSm2hR9p7',
    createdAt: '2022-03-10T10:06:19.117Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153514.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: '4REG6csAXCEZHjWJX',
    createdAt: '2022-03-10T10:06:19.134Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3830,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '95jcwmASTvBMbD2nR',
    createdAt: '2022-03-10T10:48:01.531Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 477,
    },
  },
  {
    _id: '4GXBtXYj4BowdoPuW',
    createdAt: '2022-03-10T10:48:32.908Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2913.000000000001,
    },
  },
  {
    _id: 'msDHKt27KFCwedhyr',
    createdAt: '2022-03-10T10:48:57.491Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9564.229999999992,
    },
  },
  {
    _id: 'nFQNrLPoQfrM6Tuax',
    createdAt: '2022-03-10T10:49:25.473Z',
    transactionType: 'transferIn',
    amount: 64,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 329,
    },
  },
  {
    _id: 'co7soGFo2ob5jK79i',
    createdAt: '2022-03-10T10:50:07.618Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3730,
    },
  },
  {
    _id: 'idQ5qGWEtPSkSazsB',
    createdAt: '2022-03-10T10:51:21.678Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3494,
    },
  },
  {
    _id: 'cGShKfpZGkX5v6P29',
    createdAt: '2022-03-10T10:53:01.387Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4581,
    },
  },
  {
    _id: 'e7CcYaXAAw3PpHz3J',
    createdAt: '2022-03-10T10:53:39.324Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 433.22000000000014,
    },
  },
  {
    _id: 'qpL6d7tZp8FAKdxxt',
    createdAt: '2022-03-10T11:06:10.051Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 200,
    },
  },
  {
    _id: 'nT4sQdKmGBvqECiRh',
    createdAt: '2022-03-10T11:11:45.851Z',
    transactionType: 'transferIn',
    amount: 2327,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 459.98000000000593,
    },
  },
  {
    _id: 'yyivXhWmkMcZsFE5T',
    createdAt: '2022-03-10T11:12:30.695Z',
    transactionType: 'transferIn',
    amount: 923,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2786.980000000006,
    },
  },
  {
    _id: 'XC7eDiFHz54Xum7vg',
    createdAt: '2022-03-10T11:50:11.840Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 142,
    },
  },
  {
    _id: 'QSHkSAN5AuFozNbvm',
    createdAt: '2022-03-10T11:55:27.164Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16913.64425,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'rMtv6b6mDL8DTdzDR',
    createdAt: '2022-03-10T11:55:27.182Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3709.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'n2iP6X46HDcJ98vKD',
    createdAt: '2022-03-10T11:55:27.205Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 502,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'p3if85fZj8SR4DtN8',
    createdAt: '2022-03-10T11:55:27.228Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 458.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'JQ6j5FQLauz7HrQk4',
    createdAt: '2022-03-10T11:55:27.249Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1854,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'dLXGoyLG3mYbJidh4',
    createdAt: '2022-03-10T11:55:27.267Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1148,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Gf873WzyQNCAEjFJk',
    createdAt: '2022-03-10T13:07:24.525Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3959.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'jgCYndMAJzShQYnMJ',
    createdAt: '2022-03-10T13:07:24.542Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BoScqkfjgDewCoiQM',
    createdAt: '2022-03-10T13:07:24.569Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17363.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JJ99EpTwAWGj2a5CT',
    createdAt: '2022-03-10T13:07:24.586Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3000.000000000001,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'uSum2SNWaiDKbuweF',
    createdAt: '2022-03-10T13:07:24.604Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'FxmSZHBfTqmhEzwwk',
    createdAt: '2022-03-10T13:07:24.629Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17811.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '8KtZTsW2yeRwYwpD6',
    createdAt: '2022-03-10T13:07:24.644Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Gt9czxbL8ww97Nb4N',
    createdAt: '2022-03-10T13:07:24.660Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 67.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '4wXonLnn4fmnBaJw3',
    createdAt: '2022-03-10T13:07:24.678Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 36.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'pc5zuo4mvPceXH7SA',
    createdAt: '2022-03-10T13:07:24.693Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 463.22000000000014,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kueaLP7BFBGK35KDw',
    createdAt: '2022-03-10T13:07:24.709Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9864.229999999992,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'bgypffKP6e6hE7TGp',
    createdAt: '2022-03-10T13:07:24.728Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4861,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'kFmi5Es5rGuzC8TeA',
    createdAt: '2022-03-10T13:09:39.851Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3159.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'qpf2hKortq9tdSeve',
    createdAt: '2022-03-10T13:09:39.870Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fwFcHwqDjPD7WKrSm',
    createdAt: '2022-03-10T13:09:39.891Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17363.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RcFDW9jQMN4y5hvy6',
    createdAt: '2022-03-10T13:09:39.911Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2993.000000000001,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'mdAnYLCzXv2dFHZmg',
    createdAt: '2022-03-10T13:09:39.933Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 115,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'ipNsF4hfe32Pm2Z9k',
    createdAt: '2022-03-10T13:09:39.957Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17799.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Jt9gRzjEtBYBBLwGZ',
    createdAt: '2022-03-10T13:09:39.976Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '25zhMuTgcDDHpjpWh',
    createdAt: '2022-03-10T13:09:39.994Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 66.7,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'R5Bg6et7myQ745KAF',
    createdAt: '2022-03-10T13:09:40.026Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 36.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'kQqQhwJLE3BF9ZtLD',
    createdAt: '2022-03-10T13:09:40.040Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 463.22000000000014,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZndonmEyouodtiMFJ',
    createdAt: '2022-03-10T13:09:40.056Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9863.629999999992,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'KE5eRwGAhTaimzZSm',
    createdAt: '2022-03-10T13:09:40.079Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4784,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: '33zAw29sjSwenKEks',
    createdAt: '2022-03-10T13:12:39.780Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 17363.64425,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'o4CHnmPFgLDoFWhfF',
    createdAt: '2022-03-10T13:12:39.797Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2986.000000000001,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '4sov7G2KwTSiL6FMA',
    createdAt: '2022-03-10T13:12:39.821Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1305.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'TmZf7vn5fwQGvghK9',
    createdAt: '2022-03-10T13:12:39.837Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17879,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '4M4eX4iDAeN83955j',
    createdAt: '2022-03-10T13:12:39.855Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 733,
    },
    transactionType: 'transferOut',
    amount: -105,
  },
  {
    _id: 'uh64skXCteRPgBAhg',
    createdAt: '2022-03-10T13:12:39.873Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4130,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'qD5dKFF3ptswngtE3',
    createdAt: '2022-03-10T13:26:38.991Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16763.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ejPSJQ2ea8jNYGLNP',
    createdAt: '2022-03-10T13:26:39.021Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2359.980000000006,
    },
    transactionType: 'transferOut',
    amount: -525,
  },
  {
    _id: 'b2waFi4oWJYYK9afP',
    createdAt: '2022-03-10T13:26:39.047Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8XNYZEfdjHyspYXGm',
    createdAt: '2022-03-10T13:26:39.062Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2968.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'J5iZJ2rT4uJ94XP4w',
    createdAt: '2022-03-10T13:26:39.086Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 65.7,
    },
    transactionType: 'transferOut',
    amount: -0.75,
  },
  {
    _id: 'knSAAy2vBQGqrTmoL',
    createdAt: '2022-03-10T13:26:39.129Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9863.029999999992,
    },
    transactionType: 'transferOut',
    amount: -1.1,
  },
  {
    _id: 'uj29J5PtfoAnAubRR',
    createdAt: '2022-03-10T13:26:39.164Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3694,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'bkwLnX3sFPZCe2r3c',
    createdAt: '2022-03-10T13:30:33.480Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1834.980000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'NxGHGmqNMpSTb8t5r',
    createdAt: '2022-03-10T13:30:33.508Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tgWFhLPKYy9LEcsMs',
    createdAt: '2022-03-10T13:30:33.527Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16763.64425,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sgmcncZ2eRetWeiTp',
    createdAt: '2022-03-10T13:30:33.545Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 27.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'm5gRBqR85WZymacGS',
    createdAt: '2022-03-10T13:30:33.565Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 35.59999999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pYRx46xhzPAzS77ed',
    createdAt: '2022-03-10T13:30:33.581Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17787.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'K7gPyijnoWT75nrDW',
    createdAt: '2022-03-10T13:30:33.596Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9861.929999999991,
    },
    transactionType: 'transferOut',
    amount: -1.4,
  },
  {
    _id: 'tHkoP8zqaePso4bXk',
    createdAt: '2022-03-10T13:30:33.613Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3659,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'taqNNhbFrwEHsDAsG',
    createdAt: '2022-03-10T14:30:24.411Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5510,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'GS3ZjFNcBczjQYwcz',
    createdAt: '2022-03-10T14:30:24.427Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9860.529999999992,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'AX6MLy2v4w5mfBHKd',
    createdAt: '2022-03-10T14:30:24.445Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153514.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'yccsZ6v7Xb4e9ssYX',
    createdAt: '2022-03-10T14:30:24.461Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17775.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qp3bRr8Bdrdu6kwh6',
    createdAt: '2022-03-10T14:30:24.514Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 120,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'A3hPhoPxTRLMgfSSG',
    createdAt: '2022-03-10T14:31:02.039Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 16763.64425,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'dBJpR5A9yXPvnYZBj',
    createdAt: '2022-03-10T14:31:02.073Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 71.3,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'c47BggnH59TvM4BHa',
    createdAt: '2022-03-10T14:31:02.119Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153014.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'bNZc5fqorSBqcjoMn',
    createdAt: '2022-03-10T14:31:02.163Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1273.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'MaAP4p2rytY9p7AjE',
    createdAt: '2022-03-10T14:31:02.203Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9760.529999999992,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'A7cNrcNAd6h3rPPsK',
    createdAt: '2022-03-10T14:31:02.242Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 620,
    },
    transactionType: 'transferOut',
    amount: -410,
  },
  {
    _id: '87WSZoLsb4Si4A6HR',
    createdAt: '2022-03-10T14:31:02.270Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4707,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'aQt9eeNY28aGh4yNr',
    createdAt: '2022-03-10T14:32:58.999Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13763.644250000001,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '5ZHJyFuxXmHt4jpAa',
    createdAt: '2022-03-10T14:32:59.025Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 68.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'b7HhK56GgLmXS5JPn',
    createdAt: '2022-03-10T14:32:59.044Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 151514.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'p8Xe6rPekh3Ltv4LG',
    createdAt: '2022-03-10T14:32:59.063Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1083.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'HKN9gw8k7Kvqg5EGf',
    createdAt: '2022-03-10T14:32:59.081Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9610.529999999992,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '9w3jbDL2NdBvQJgeC',
    createdAt: '2022-03-10T14:32:59.110Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 210,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'cbxG4rHyramt77j7k',
    createdAt: '2022-03-10T14:32:59.131Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4641,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'nzMKELupqLxQ2gABd',
    createdAt: '2022-03-10T14:36:07.778Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12763.644250000001,
    },
    transactionType: 'transferOut',
    amount: -950,
  },
  {
    _id: 'dszXe3qgMR9T8Krkd',
    createdAt: '2022-03-10T14:36:07.799Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1234.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'q4StZJrS4mv9N8SsB',
    createdAt: '2022-03-10T14:36:07.827Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HdtqYxSwpFQEpHpRr',
    createdAt: '2022-03-10T14:36:07.844Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2968.000000000001,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'haae66zt5uW7R8oKQ',
    createdAt: '2022-03-10T14:36:07.859Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1019.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'WPBn6Ryqm8iEaijGL',
    createdAt: '2022-03-10T14:36:07.876Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17775.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'LTdXmJkXNoJufbpwF',
    createdAt: '2022-03-10T14:36:07.891Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17867,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'KbNk8iKj5JB8Dei24',
    createdAt: '2022-03-10T14:36:07.905Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 628,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'EeFeaTpK9opgGQPbt',
    createdAt: '2022-03-10T14:36:07.926Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 709,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'bNz4iEP5AXhQL2evu',
    createdAt: '2022-03-10T14:36:07.942Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1611,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'q8Z2dSJL4C9d2iLNP',
    createdAt: '2022-03-10T14:36:07.958Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 196,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '8WbkjzAYTtpqxLDBC',
    createdAt: '2022-03-10T14:48:07.753Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5110,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'Egdjw9AiYunsnPyYA',
    createdAt: '2022-03-10T14:48:07.777Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9560.529999999992,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Kk6FZgiNGJNpNxhpZ',
    createdAt: '2022-03-10T14:48:07.802Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 151014.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'jW8Ry3CzvmT5RsWeE',
    createdAt: '2022-03-10T14:48:07.822Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17763.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tkHDkQuMnA5shtJmw',
    createdAt: '2022-03-10T14:48:07.857Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 78,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'ptoqzDKCYnztaE9ym',
    createdAt: '2022-03-10T14:49:22.814Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11813.644250000001,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'BBQaB8u6vLEN37LDP',
    createdAt: '2022-03-10T14:49:22.844Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 67.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GbaGWXKsrMekGZnrS',
    createdAt: '2022-03-10T14:49:22.862Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 150514.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'd2zHgmnkfDnzYY6iA',
    createdAt: '2022-03-10T14:49:22.879Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 954.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'ZAn8tQvwYGbaMRJnH',
    createdAt: '2022-03-10T14:49:22.894Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9460.529999999992,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'MMYtbxLKr5JALuctC',
    createdAt: '2022-03-10T14:49:22.913Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 578,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: '95e4RpCEuXYBmtDNp',
    createdAt: '2022-03-10T14:49:22.931Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4619,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'vRybzjoJuAAkDieZR',
    createdAt: '2022-03-10T15:37:23.706Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10813.644250000001,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'zvdkFkGpwM4o9fQgr',
    createdAt: '2022-03-10T15:37:23.732Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2943.000000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'uDwfQTWEeTi9mBA8r',
    createdAt: '2022-03-10T15:37:23.751Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1234.980000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'EWPrx56RegSjSo8Cw',
    createdAt: '2022-03-10T15:37:23.765Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 463.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'LTcN7JG4GftKNWQar',
    createdAt: '2022-03-10T15:37:23.786Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 890.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: '4amDpdG4rdLy93zd8',
    createdAt: '2022-03-10T15:37:23.819Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 468,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'n5XQSJCsRLn5izh5p',
    createdAt: '2022-03-10T15:37:23.840Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 150014.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'FCZE78EJpWjB73EN9',
    createdAt: '2022-03-10T15:37:23.857Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4089,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'zaCm6NSGeQZrZ9cMg',
    createdAt: '2022-03-10T16:10:54.045Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10313.644250000001,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'qyvcM7zyf44fcFLto',
    createdAt: '2022-03-10T16:10:54.077Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2931.000000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7tWQn6xy2u4jmfcDa',
    createdAt: '2022-03-10T16:10:54.106Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 734.9800000000059,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'XiXwtjrea49AXXmqg',
    createdAt: '2022-03-10T16:10:54.129Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 456.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'WinDGuD3nka2prk8D',
    createdAt: '2022-03-10T16:10:54.159Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 827.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: 'sjHiWgyFX8Xdt8rqg',
    createdAt: '2022-03-10T16:10:54.194Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 368,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'kBbnsv6cQxwRofrsT',
    createdAt: '2022-03-10T16:10:54.224Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148514.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: '8hagzPpy3nQAea4FB',
    createdAt: '2022-03-10T16:10:54.247Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3959,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'rRCjjjyo7sXqFAoQt',
    createdAt: '2022-03-11T05:17:18.501Z',
    transactionType: 'in',
    amount: 4000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 234.98000000000593,
    },
  },
  {
    _id: 'MjeWhZBGp2ziAZFXy',
    createdAt: '2022-03-11T05:19:43.793Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9813.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zxyuNJCzFRDRScELh',
    createdAt: '2022-03-11T05:19:43.823Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4234.980000000006,
    },
    transactionType: 'transferOut',
    amount: -630,
  },
  {
    _id: 'iMxnpZvJgiwPxbzMw',
    createdAt: '2022-03-11T05:19:43.849Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CEN2JyrzgtPt4t2ir',
    createdAt: '2022-03-11T05:19:43.878Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2919.000000000001,
    },
    transactionType: 'transferOut',
    amount: -18.9,
  },
  {
    _id: 'BJyRhakd2KQf4mAJB',
    createdAt: '2022-03-11T05:19:43.912Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 764.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '2xcCcruj8c3YXAyk7',
    createdAt: '2022-03-11T05:19:43.942Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147014.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'g6Q9WWAkpw5f4RjaT',
    createdAt: '2022-03-11T05:19:44.134Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 315,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'AcAWhEFEbaKDuytvD',
    createdAt: '2022-03-11T05:19:44.161Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17837,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '84SuwZ2txX425fK2c',
    createdAt: '2022-03-11T05:19:44.181Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17763.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'nQ4nFxSf6ubcnSfsq',
    createdAt: '2022-03-11T05:19:44.204Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 268,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Y4zAQRgW7LzhHKwP5',
    createdAt: '2022-03-11T05:19:44.231Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3907,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'wyfojrT4u5Mdj8sSE',
    createdAt: '2022-03-11T05:21:18.328Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3604.980000000006,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: 'NjZbXBC734PEqCEjn',
    createdAt: '2022-03-11T05:21:18.356Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QWuxB2RHwdJS9T9HS',
    createdAt: '2022-03-11T05:21:18.375Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9813.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AXWR6afsJMa2rNbvC',
    createdAt: '2022-03-11T05:21:18.399Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2900.100000000001,
    },
    transactionType: 'transferOut',
    amount: -9.6,
  },
  {
    _id: 'yzBD3CDCEXHfQQzS4',
    createdAt: '2022-03-11T05:21:18.418Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 746.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '7aJeWunNQunXCWem7',
    createdAt: '2022-03-11T05:21:18.441Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 146714.3,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'CLPkwJKxWE4a7MNNw',
    createdAt: '2022-03-11T05:21:18.464Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17813,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Da3T4A5f97ThGt7m3',
    createdAt: '2022-03-11T05:21:18.497Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 303,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'DmbCtSRC5aE3SKwfP',
    createdAt: '2022-03-11T05:21:18.562Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 168,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'pQm2HiFaRbMDaGC7J',
    createdAt: '2022-03-11T05:21:18.588Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3861,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'XquqQhLW3d9rwtqZg',
    createdAt: '2022-03-11T05:25:38.664Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'WPkYG2Gdz4uKQmDya',
    createdAt: '2022-03-11T05:25:51.070Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'k7bMeduTeGPekqodQ',
    createdAt: '2022-03-11T06:44:06.196Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 172,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DGEMzQpxwkpdudD8y',
    createdAt: '2022-03-11T06:44:06.232Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Nau7eThWnvvKpfTAy',
    createdAt: '2022-03-11T06:44:06.268Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 158.17000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'SP6iMRrutp5hnqYCa',
    createdAt: '2022-03-11T06:44:06.314Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 966.7,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '3BBCeCXnNoamvfNWz',
    createdAt: '2022-03-11T06:44:06.339Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'pxWPTXFmmDoXAQ9iL',
    createdAt: '2022-03-11T06:44:06.367Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 60.00000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'eRB24gDJgo8vDsW7J',
    createdAt: '2022-03-11T06:44:06.400Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 67.20000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'GYFZr2L9xp2tvrK39',
    createdAt: '2022-03-11T06:44:06.426Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 449.22000000000014,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'HoASMwvMP5WrJFf9K',
    createdAt: '2022-03-11T06:44:06.451Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9410.529999999992,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'PhxRAwszBzWQrRxQR',
    createdAt: '2022-03-11T06:44:06.491Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1037,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'E7QYkpNNeKRiNh3aC',
    createdAt: '2022-03-11T06:44:06.522Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JhNozhQZiH3MjrZTs',
    createdAt: '2022-03-11T06:44:06.561Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 393,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: '4TBz3xBrfBN9TMfoP',
    createdAt: '2022-03-11T06:44:06.630Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 28,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'K9rrgf8W9GyfjKXHn',
    createdAt: '2022-03-11T10:24:11.387Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 528,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'YEtqsA2yiueXm6MAm',
    createdAt: '2022-03-11T10:24:11.408Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 966.2,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'LPhWJDBJqhEgkNJwb',
    createdAt: '2022-03-11T10:24:11.426Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 157.92000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'aqzPYQpwuJCx3kYbT',
    createdAt: '2022-03-11T10:24:11.444Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2902.500000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'dgZHNQEWvANSR7oPE',
    createdAt: '2022-03-11T10:24:11.505Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'hAAGcTvFi24KFHier',
    createdAt: '2022-03-11T10:25:38.281Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3784.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'f7DXYkmz73JR8h4ih',
    createdAt: '2022-03-11T10:25:38.310Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10313.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZaQnWtRJtwKqJxJ49',
    createdAt: '2022-03-11T10:25:38.335Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2899.500000000001,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'S6roDXZkpAWjz8FDf',
    createdAt: '2022-03-11T10:25:38.360Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 86,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'Khdtcp9vB77FBkiag',
    createdAt: '2022-03-11T10:25:38.401Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9410.279999999992,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'DHjyMZJsYbJfgThmp',
    createdAt: '2022-03-11T10:25:38.430Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148064.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '7qfdCrmWyoHKLT5FB',
    createdAt: '2022-03-11T10:25:38.458Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 684,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '8TMYYXbd8TcjW6gh4',
    createdAt: '2022-03-11T10:25:38.479Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1586,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'FbPaXu57sA3qemptE',
    createdAt: '2022-03-11T10:25:38.514Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1142,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'nnScRsCSWM4TkP3SZ',
    createdAt: '2022-03-11T10:29:00.790Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1025,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'RGp9fc89HtwMRhBqC',
    createdAt: '2022-03-11T10:29:00.831Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 157.67000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'zYzmiByH8zAnfeHBu',
    createdAt: '2022-03-11T10:29:00.858Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 66.30000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'AT7soEcRgj8upp8NB',
    createdAt: '2022-03-11T10:29:00.884Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 455.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'j5TpRM6QNC5kzuNBW',
    createdAt: '2022-03-11T10:29:00.906Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 166.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'rc4K6dCSbArHZZ3xE',
    createdAt: '2022-03-11T10:29:00.931Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9407.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'BveaLRE24oRjxqMne',
    createdAt: '2022-03-11T10:29:00.958Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Beqs5ifEHiHCknMYx',
    createdAt: '2022-03-11T10:29:00.979Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 949.2,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'RzdTjzGiwreHSJgDR',
    createdAt: '2022-03-11T10:29:01.010Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dmdav6seJfAdBY5tw',
    createdAt: '2022-03-11T10:29:01.039Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wB3mBrJ4iDbjQGmbD',
    createdAt: '2022-03-11T10:29:01.069Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1564,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'x9TMyheFTw644TQyg',
    createdAt: '2022-03-11T10:29:01.095Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 662,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'XQHiq6kEtF7hnJPyd',
    createdAt: '2022-03-11T10:29:01.127Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1125,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: '9yvBJv5yn7y8j5SFc',
    createdAt: '2022-03-11T10:29:28.214Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1025,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'LMiHu5HKWs2rRrq52',
    createdAt: '2022-03-11T10:29:28.242Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 157.67000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'TdpQcxp4PJnPxcSuF',
    createdAt: '2022-03-11T10:29:28.262Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 66.30000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'biNnzY75jerapMKwR',
    createdAt: '2022-03-11T10:29:28.285Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 455.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '67xorNgPCpnfo373D',
    createdAt: '2022-03-11T10:29:28.307Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 166.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'H4NX7ckBQWiSbJAzb',
    createdAt: '2022-03-11T10:29:28.328Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9407.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'oXoEipDBHEvjQArNK',
    createdAt: '2022-03-11T10:29:28.347Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cSd3aqHzuAh8qEmTf',
    createdAt: '2022-03-11T10:29:28.367Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 949.2,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '7mgvCEZGSNxXXpB8K',
    createdAt: '2022-03-11T10:29:28.388Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wwTHvAt3Yjovo7kdf',
    createdAt: '2022-03-11T10:29:28.413Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DJ5E742zw9tAkftg8',
    createdAt: '2022-03-11T10:29:28.459Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1564,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KmsZeyTxWxHxbacxm',
    createdAt: '2022-03-11T10:29:28.486Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 662,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'xGB4t2T48TRqmZ2XC',
    createdAt: '2022-03-11T10:29:28.509Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1125,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'ifh9YQdZ8j3gZ7uo6',
    createdAt: '2022-03-11T10:30:17.732Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1025,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'cqQ59qGAAyHJ5qEtW',
    createdAt: '2022-03-11T10:30:17.763Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 157.67000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'eeNkiaxcodCSeDg9p',
    createdAt: '2022-03-11T10:30:17.788Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 66.30000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'o3rdEE3epB2kmTbLy',
    createdAt: '2022-03-11T10:30:17.816Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 455.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'jXWpb7eS35fNiSkGq',
    createdAt: '2022-03-11T10:30:17.838Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 166.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'L5j48XphAouyhnJvy',
    createdAt: '2022-03-11T10:30:17.858Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9407.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'R9PW62DxNAny7w7BH',
    createdAt: '2022-03-11T10:30:17.891Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yBFgZb8gMBLNAd5tF',
    createdAt: '2022-03-11T10:30:17.912Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 949.2,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '56HkPAgzbR2zSfpXQ',
    createdAt: '2022-03-11T10:30:17.934Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TaQiHqGYY8QhvCJq6',
    createdAt: '2022-03-11T10:30:17.957Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'L4vwb35e5aHR4RCzy',
    createdAt: '2022-03-11T10:30:17.984Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1564,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'CpKixrftaw24xHgrc',
    createdAt: '2022-03-11T10:30:18.024Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 662,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 't69o5dgYkfYhhtcXf',
    createdAt: '2022-03-11T10:30:18.062Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1125,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'MtCubPHvnaE268JH6',
    createdAt: '2022-03-11T10:31:43.191Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 725,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Xho4EMYQCbq3uAaHr',
    createdAt: '2022-03-11T10:31:43.218Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 156.47000000000023,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'm6wLpD4ivRNwbTZqu',
    createdAt: '2022-03-11T10:31:43.240Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 63.90000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'aHaA7SEiYqkesxnqC',
    createdAt: '2022-03-11T10:31:43.263Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 443.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'LxH4AfFbQFHq5f7Ff',
    createdAt: '2022-03-11T10:31:43.298Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 163.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'kkZDvPj4ZZngJPfeC',
    createdAt: '2022-03-11T10:31:43.330Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9401.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'SqP2B2spfLxu7BsQz',
    createdAt: '2022-03-11T10:31:43.373Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3jbC2B2X6hJW7rPpo',
    createdAt: '2022-03-11T10:31:43.396Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 892.2,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '2Ywhu2CDi6kNHkiMk',
    createdAt: '2022-03-11T10:31:43.417Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yFnr732g3KpkqCNFg',
    createdAt: '2022-03-11T10:31:43.441Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qXeoYh7xjFbMttYG7',
    createdAt: '2022-03-11T10:31:43.475Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1528,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'nGCHdpAaGqqMX9t7S',
    createdAt: '2022-03-11T10:31:43.509Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 626,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'nACcdbDdvrmaHwhCW',
    createdAt: '2022-03-11T10:31:43.536Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1083,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: '9aGmPbWisWnNAPdY4',
    createdAt: '2022-03-11T10:33:16.970Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 725,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'o7fZR5XPsNpAFQ9uh',
    createdAt: '2022-03-11T10:33:16.999Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 156.47000000000023,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'andi24S29PRWAA2jG',
    createdAt: '2022-03-11T10:33:17.035Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 63.90000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'dpvQ9xcveJop33tT6',
    createdAt: '2022-03-11T10:33:17.058Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 443.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'dxqpo3QLsYTpK49pG',
    createdAt: '2022-03-11T10:33:17.080Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 163.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'EaxrP2JkdZLcM4qrX',
    createdAt: '2022-03-11T10:33:17.104Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9401.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'uRpKwAQojgzFoEBLR',
    createdAt: '2022-03-11T10:33:17.127Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sspDtf5aCojm8cEBA',
    createdAt: '2022-03-11T10:33:17.149Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 892.2,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'ZocHiNMNRwmhcdpMC',
    createdAt: '2022-03-11T10:33:17.170Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7vKCj6mTknRdNitfH',
    createdAt: '2022-03-11T10:33:17.195Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CaPDFnWCKC6p75v24',
    createdAt: '2022-03-11T10:33:17.224Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1528,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'kYeWpni7ryoxwosPs',
    createdAt: '2022-03-11T10:33:17.250Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 626,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'z7PWepbRtrdy2bcZ5',
    createdAt: '2022-03-11T10:33:17.273Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1083,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'PoNAkgzizPWMXEzr3',
    createdAt: '2022-03-11T10:35:26.173Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 525,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'MWCMoQru6PP2Sax4i',
    createdAt: '2022-03-11T10:35:26.204Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 155.67000000000021,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'um9sfJtskihkC4Cjh',
    createdAt: '2022-03-11T10:35:26.244Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 62.300000000000026,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: '2cW4oe3LoFxGTGwYX',
    createdAt: '2022-03-11T10:35:26.283Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 442.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'erqQjNiKn5iqHS5nj',
    createdAt: '2022-03-11T10:35:26.312Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 161.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hXESd2eiZKsZZmEWy',
    createdAt: '2022-03-11T10:35:26.338Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9397.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Y9akRSukvWztbzWbn',
    createdAt: '2022-03-11T10:35:26.358Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iNzhEwZDjNLysgfnZ',
    createdAt: '2022-03-11T10:35:26.379Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 854.2,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '4ZLuTyb4DTHvkyCeL',
    createdAt: '2022-03-11T10:35:26.400Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'srpBNq4do699ydgA2',
    createdAt: '2022-03-11T10:35:26.424Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oMeTEFNThXNwfQysW',
    createdAt: '2022-03-11T10:35:26.456Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1504,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KuoafJPRRYneC87xA',
    createdAt: '2022-03-11T10:35:26.480Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 602,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'JNvSF6GtdH7RAgWxf',
    createdAt: '2022-03-11T10:35:26.514Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1055,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'Nyoqx4bC3giEmMSu8',
    createdAt: '2022-03-11T10:37:39.302Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 425,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'GARxQTKP28yXoW8Ht',
    createdAt: '2022-03-11T10:37:39.331Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 155.2700000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'df9k84PoyF498SS5T',
    createdAt: '2022-03-11T10:37:39.359Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 61.50000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'esdpwvsFuugoSB3Ja',
    createdAt: '2022-03-11T10:37:39.382Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 438.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'PtY4ha5r6epwQt6Jz',
    createdAt: '2022-03-11T10:37:39.409Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 160.3,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'E2gt6QyAoc3snmX49',
    createdAt: '2022-03-11T10:37:39.432Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9395.279999999992,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'gPmGPjStbbkcJDwyB',
    createdAt: '2022-03-11T10:37:39.455Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j4Dm6edePEQgHvhew',
    createdAt: '2022-03-11T10:37:39.483Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 835.2,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'F3fAobwfyJRngvbWx',
    createdAt: '2022-03-11T10:37:39.508Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'N2GfWkosLuY7mvqBc',
    createdAt: '2022-03-11T10:37:39.533Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3wZ7LBWEhL95iZuzo',
    createdAt: '2022-03-11T10:37:39.561Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1492,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7LPuaw2yHPkPxxaCL',
    createdAt: '2022-03-11T10:37:39.597Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 590,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'zzLEDbXFxT9kwK8kG',
    createdAt: '2022-03-11T10:37:39.621Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1041,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'C7PzDbuaBgP2khJ8d',
    createdAt: '2022-03-11T11:43:06.381Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 434.82000000000016,
    },
  },
  {
    _id: 'CWKBcLq5XMhb2f69v',
    createdAt: '2022-03-11T11:44:07.779Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 477,
    },
  },
  {
    _id: 'LoDE48YWcEhvPMhF6',
    createdAt: '2022-03-11T11:44:41.002Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4598,
    },
  },
  {
    _id: 's2SxCRQAxkyRZty4a',
    createdAt: '2022-03-11T11:45:20.918Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10813.644250000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'i778ZzCzEGttaN2BK',
    createdAt: '2022-03-11T11:45:20.941Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4034.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '39uSvuTDWqrprEsaS',
    createdAt: '2022-03-11T11:45:20.974Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 527,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'XzMi4PDPKNokSTtHD',
    createdAt: '2022-03-11T11:45:20.995Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 459.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'cy7trmrFTiDRLjECM',
    createdAt: '2022-03-11T11:45:21.020Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1732,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: '9xZKwtMawrTLaCGGZ',
    createdAt: '2022-03-11T11:45:21.041Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1027,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'CD7wcjMbAsBSsvyWT',
    createdAt: '2022-03-11T11:45:46.590Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3618,
    },
  },
  {
    _id: '6reN829moKuS64XTY',
    createdAt: '2022-03-11T11:46:37.770Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2858.500000000001,
    },
  },
  {
    _id: 'LkK53KYXjZSErPSaq',
    createdAt: '2022-03-11T11:50:23.805Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -152,
  },
  {
    _id: 'EesEQYJgCrtbog6ff',
    createdAt: '2022-03-11T11:50:23.827Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9266,
    },
    transactionType: 'transferOut',
    amount: -82,
  },
  {
    _id: 'xESXbudge8Y4hPxD3',
    createdAt: '2022-03-11T11:50:23.845Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3786,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'dr75zAHcLNdhSzgmY',
    createdAt: '2022-03-11T11:50:23.863Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1044,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'AsFNJGpB7eLQCJH7e',
    createdAt: '2022-03-11T11:51:25.805Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 548,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'R6d9XYxeiGyXJdac9',
    createdAt: '2022-03-11T11:51:25.824Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9184,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: '8QM4q4x99sXoeLoAF',
    createdAt: '2022-03-11T11:51:25.843Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1037,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'gqDAP9XLGZkyz9iJt',
    createdAt: '2022-03-11T11:53:56.751Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 506,
    },
    transactionType: 'transferOut',
    amount: -506,
  },
  {
    _id: 'w3THc4S9CDQHJZQzg',
    createdAt: '2022-03-11T11:53:56.778Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 10.9,
    },
    transactionType: 'transferOut',
    amount: -1.6,
  },
  {
    _id: 'mbsSjTnocMtvxJniY',
    createdAt: '2022-03-11T11:53:56.825Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 215,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'FxYRKC4xGwcp6t435',
    createdAt: '2022-03-11T11:59:10.274Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 715,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'BvxiqS7iWCQSnS4pR',
    createdAt: '2022-03-11T11:59:10.298Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9142,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'oJbdZHwsA4gLwxXWA',
    createdAt: '2022-03-11T11:59:10.330Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1033,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'kEo2daBRdhNCYNTgD',
    createdAt: '2022-03-11T12:00:10.377Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 594,
    },
    transactionType: 'transferOut',
    amount: -242,
  },
  {
    _id: 'jJd8ZwkkpD79zPjK2',
    createdAt: '2022-03-11T12:00:10.400Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9021,
    },
    transactionType: 'transferOut',
    amount: -242,
  },
  {
    _id: 'LhgqheeHtLSNpfKYr',
    createdAt: '2022-03-11T12:00:10.420Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1023,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'gmsrLYGHvXfSdtimj',
    createdAt: '2022-03-11T12:17:56.872Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 817.2,
    },
  },
  {
    _id: '6PFyefRFLFtX6Bsbz',
    createdAt: '2022-03-11T12:18:29.623Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1021,
    },
  },
  {
    _id: '8w2SGGbSMFvkhMC26',
    createdAt: '2022-03-11T12:38:23.064Z',
    transactionType: 'transferOut',
    amount: -800,
    storeItem: {
      type: {
        _id: 'YH57SY8tLwYWL2zrj',
        name: 'RTS 82 MM (PLAIN WHITE) -JAR CAP',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F665dfe2a-8b8b-4ba1-83b7-59c7a5914c4c_200x200.jpg?alt=media&token=5b30fc96-e6ed-43a3-8955-f555e4b0061b',
        },
      },
      quantity: 1000,
    },
  },
  {
    _id: 'c2FPPxCarbLY9AXxA',
    createdAt: '2022-03-11T12:58:41.792Z',
    transactionType: 'transferIn',
    amount: 3000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3784.980000000006,
    },
  },
  {
    _id: 'pMj3buqsN9J2wDtHn',
    createdAt: '2022-03-11T12:59:13.599Z',
    transactionType: 'transferIn',
    amount: 2100,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1200,
    },
  },
  {
    _id: 'XQzJHmqgrxxMmRHi6',
    createdAt: '2022-03-11T12:59:48.085Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6784.980000000006,
    },
  },
  {
    _id: 'FPRZSJq2rN4qDkpFm',
    createdAt: '2022-03-11T13:09:34.531Z',
    transactionType: 'transferIn',
    amount: 166,
    storeItem: {
      type: {
        _id: 'vApPL8Y74Sbp7C483',
        name: 'HALOOM CHEESE PLAIN VACUUM / KG',
        unit: 'KG',
      },
      quantity: 0,
    },
  },
  {
    _id: 'cHoTkCwy7zXMiisPn',
    createdAt: '2022-03-11T13:10:12.334Z',
    transactionType: 'transferOut',
    amount: -166,
    storeItem: {
      type: {
        _id: 'vApPL8Y74Sbp7C483',
        name: 'HALOOM CHEESE PLAIN VACUUM / KG',
        unit: 'KG',
      },
      quantity: 166,
    },
  },
  {
    _id: 'ptsyi5y7q5LWbGT86',
    createdAt: '2022-03-12T04:47:16.005Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6984.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'bM89BdQR4Bm4fRL53',
    createdAt: '2022-03-12T04:47:16.036Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'woz6bAoBaBW9NzxwM',
    createdAt: '2022-03-12T04:47:16.057Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10763.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iS9zgqC2eCqvjspPf',
    createdAt: '2022-03-12T04:47:16.080Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2983.500000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'P3ZZarRzX5w96P9fz',
    createdAt: '2022-03-12T04:47:16.103Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 108,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JagHNmJybwqsx66hP',
    createdAt: '2022-03-12T04:47:16.127Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17751.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YPhTxAcYyJ2ZAwPBd',
    createdAt: '2022-03-12T04:47:16.147Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uH53kSaR7bkngWt4H',
    createdAt: '2022-03-12T04:47:16.164Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 64.95,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'jespD7HkCiS5TWfCh',
    createdAt: '2022-03-12T04:47:16.186Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 35.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ZeXXhHhZcQJnm2F79',
    createdAt: '2022-03-12T04:47:16.208Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 457.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'YGw8veCiTNQAowWbG',
    createdAt: '2022-03-12T04:47:16.232Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9393.279999999992,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '92LEGJPfzEStjDAzt',
    createdAt: '2022-03-12T04:47:16.253Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4798,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: '4dfmF9dFsBnFmR33J',
    createdAt: '2022-03-12T04:48:25.076Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6184.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'fNvkprdjvL6Fqh8Pw',
    createdAt: '2022-03-12T04:48:25.100Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XnJ8arckHtqkZoQHh',
    createdAt: '2022-03-12T04:48:25.144Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10763.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XhHr2ZseqQbPyHsCA',
    createdAt: '2022-03-12T04:48:25.167Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2983.500000000001,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'hv5gy3dosMNea6SvY',
    createdAt: '2022-03-12T04:48:25.197Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 108,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'iBbfEwC6afRB8QGLY',
    createdAt: '2022-03-12T04:48:25.217Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17751.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'EMbpswpYaRHSQFxMp',
    createdAt: '2022-03-12T04:48:25.239Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '96hKM9Mknm3vEvoN5',
    createdAt: '2022-03-12T04:48:25.261Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 35.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'TesNvHCzYkWcm52zx',
    createdAt: '2022-03-12T04:48:25.286Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9392.679999999991,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '25vZbSJwWL5ZmnStm',
    createdAt: '2022-03-12T04:48:25.304Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4722,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'v2uCRa4LSia9gerKM',
    createdAt: '2022-03-12T04:49:12.320Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5384.980000000006,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: 'gmsNkPhWZdJT84EZh',
    createdAt: '2022-03-12T04:49:12.349Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PebmhchzSDPe4Zmv4',
    createdAt: '2022-03-12T04:49:12.376Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10763.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ikMy6M8Nomw2SPAef',
    createdAt: '2022-03-12T04:49:12.401Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2976.500000000001,
    },
    transactionType: 'transferOut',
    amount: -9.6,
  },
  {
    _id: 'p92xRNnnJiv8NeLXN',
    createdAt: '2022-03-12T04:49:12.423Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 854.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'maa2hWpuS66sGj3W4',
    createdAt: '2022-03-12T04:49:12.445Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147264.3,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'ARuT6JJwoYXsKNrnE',
    createdAt: '2022-03-12T04:49:12.470Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17801,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Ngzu846JLp2bDWcYS',
    createdAt: '2022-03-12T04:49:12.488Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 297,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'tYr6NG647tsAkKwyg',
    createdAt: '2022-03-12T04:49:12.513Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 298,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '6DLgYKQJDMXjZKSLv',
    createdAt: '2022-03-12T04:49:12.535Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4018,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'Zppe9yexFwAmABrYP',
    createdAt: '2022-03-12T04:50:36.746Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10763.644250000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zB27upmK7s7aki6pm',
    createdAt: '2022-03-12T04:50:36.777Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5064.980000000006,
    },
    transactionType: 'transferOut',
    amount: -630,
  },
  {
    _id: 'P47XLnortnHopb282',
    createdAt: '2022-03-12T04:50:36.798Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BK9Q2dQjZRHcEKCCQ',
    createdAt: '2022-03-12T04:50:36.820Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2966.900000000001,
    },
    transactionType: 'transferOut',
    amount: -18.9,
  },
  {
    _id: '6uzdBdjZfjbBagJHx',
    createdAt: '2022-03-12T04:50:36.842Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 836.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'Wp4556YL7Y43hznWM',
    createdAt: '2022-03-12T04:50:36.864Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147114.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'RxCwj2xDQNxvxwFm6',
    createdAt: '2022-03-12T04:50:36.892Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 291,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'LL9bXKAkAEpFKCMAZ',
    createdAt: '2022-03-12T04:50:36.914Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17789,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'fvtTaeBw373gxBvBS',
    createdAt: '2022-03-12T04:50:36.938Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17739.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'b78nSPZaZSqAjJ3gx',
    createdAt: '2022-03-12T04:50:36.971Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 228,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'yqJDZTrADYGg3gqBE',
    createdAt: '2022-03-12T04:50:36.995Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3994,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'MFrB9Q39TAY5eR3jD',
    createdAt: '2022-03-12T05:08:32.289Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10763.644250000001,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'ebdcp64iw5sFPW4mR',
    createdAt: '2022-03-12T05:08:32.314Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 66.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'WuLa9Fo3zjgLkb8h7',
    createdAt: '2022-03-12T05:08:32.339Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 146814.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'qzoihrFD7zDDswT9z',
    createdAt: '2022-03-12T05:08:32.371Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 818.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'AoByeZCGaNxEPRHes',
    createdAt: '2022-03-12T05:08:32.389Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9392.07999999999,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'sAHAAhGFNdSYKebbi',
    createdAt: '2022-03-12T05:08:32.418Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 448,
    },
    transactionType: 'transferOut',
    amount: -240,
  },
  {
    _id: 'ghHeznYBZmTQk36ZH',
    createdAt: '2022-03-12T05:08:32.457Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4647,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'Kq99DZknqGNZuXnaZ',
    createdAt: '2022-03-12T05:08:55.964Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'FhZmc9J37ak4r8fgo',
        name: 'BUCKET 1 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F50ef1676-6804-485a-b5f6-5355bf469bca_200x200.jpg?alt=media&token=48f7bcda-a201-4a13-a9f3-d5442ebdbf66',
        },
      },
      quantity: 466,
    },
  },
  {
    _id: 'w4XFY9KNoJJnYAhtM',
    createdAt: '2022-03-12T05:09:25.137Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 502,
    },
  },
  {
    _id: 'oxWbTPFZ4b4EHRPmz',
    createdAt: '2022-03-12T05:09:56.317Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1610,
    },
  },
  {
    _id: 'XxodE7M5emyyt2Djh',
    createdAt: '2022-03-12T05:10:39.363Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 722,
    },
  },
  {
    _id: '22vYb3vm8NgNLbEf3',
    createdAt: '2022-03-12T05:11:11.248Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 21,
    },
  },
  {
    _id: 'GW4XmjyfRXuaM9iag',
    createdAt: '2022-03-12T05:11:34.610Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F36a083ae-23ac-4407-8771-4336e2246576_200x200.jpg?alt=media&token=1306ff4d-a3a4-4218-85f5-808fbf3dff2f',
        },
      },
      quantity: 5,
    },
  },
  {
    _id: 'dfKaASZnqyvg635Hg',
    createdAt: '2022-03-12T06:31:39.463Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7571,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '8iQQxR6aTDjq2fZDq',
    createdAt: '2022-03-12T06:31:39.480Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2948.000000000001,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'twdYJ8FsLpg45oDoB',
    createdAt: '2022-03-12T06:31:39.497Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 692.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'jwYiucG8pqXysiNuQ',
    createdAt: '2022-03-12T06:31:39.518Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17765,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yeCTaBSpns8b3AoRj',
    createdAt: '2022-03-12T06:31:39.536Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21746.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'PbBQQytyMGX5Z8yXe',
    createdAt: '2022-03-12T06:31:39.554Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 463.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'BXsrPajQhEZPJNJwg',
    createdAt: '2022-03-12T09:15:32.776Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 325,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'TjhCAmvdDKRtmbzPT',
    createdAt: '2022-03-12T09:15:32.823Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 154.8700000000002,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'fYas8Qpo2yfmb5SXh',
    createdAt: '2022-03-12T09:15:32.851Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 60.70000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '48KADr4BnRYxZu9Bb',
    createdAt: '2022-03-12T09:15:32.882Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 449.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'vRnnSZ3dyyNmBBqvM',
    createdAt: '2022-03-12T09:15:32.910Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 159.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Esqfgh2Y5v4JAwKyq',
    createdAt: '2022-03-12T09:15:32.942Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9292.07999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vTv5j29LzoNjHZ8Gu',
    createdAt: '2022-03-12T09:15:32.974Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ftgfi4YLfF54d29qK',
    createdAt: '2022-03-12T09:15:33.000Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 917.2,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '4ybwiGxsbRu38pb3E',
    createdAt: '2022-03-12T09:15:33.049Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3LKcrhGTqm3RWzieB',
    createdAt: '2022-03-12T09:15:33.080Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'G2A422dpFXxodNnNg',
    createdAt: '2022-03-12T09:15:33.122Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1096,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'gwWf2hhBgFGg7ex78',
    createdAt: '2022-03-12T09:16:25.201Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 270,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'ss7S4p69ZcLNtZ6jp',
    createdAt: '2022-03-12T09:16:25.240Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 154.67000000000021,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'wS9cwZNrWkpLnmhH6',
    createdAt: '2022-03-12T09:16:25.260Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 60.30000000000003,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'L32s6ZfsS9hGhHdQd',
    createdAt: '2022-03-12T09:16:25.280Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 447.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'bZCcRJk228RcsfShc',
    createdAt: '2022-03-12T09:16:25.305Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 158.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '5jpdmoKYtjNnZaLpu',
    createdAt: '2022-03-12T09:16:25.326Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9291.07999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'xfvboeaSsgbankhWY',
    createdAt: '2022-03-12T09:16:25.347Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g4jjiKgj4jfcivDJc',
    createdAt: '2022-03-12T09:16:25.367Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 908.2,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'sLgoBz8LC9y9g5hPf',
    createdAt: '2022-03-12T09:16:25.399Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8oDmsMu6mkHFzJJBe',
    createdAt: '2022-03-12T09:16:25.423Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NEoom4CXvjPJAnGGr',
    createdAt: '2022-03-12T09:16:25.450Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1087,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'dLLSeMMuSCz42GNA6',
    createdAt: '2022-03-12T09:16:57.506Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 215,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '6aCPEGebAAT7mLohg',
    createdAt: '2022-03-12T09:16:57.529Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 154.47000000000023,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '4XcMdqBbtjiuAdD7L',
    createdAt: '2022-03-12T09:16:57.549Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.900000000000034,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'EDsKfwXKDNxoPgtiD',
    createdAt: '2022-03-12T09:16:57.567Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 445.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'XzJPE6TE63dnfe9N7',
    createdAt: '2022-03-12T09:16:57.588Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 158.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'KP2YGkmeqHc32Pu4Q',
    createdAt: '2022-03-12T09:16:57.603Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9290.07999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'YinAvX2qhATxrdEqj',
    createdAt: '2022-03-12T09:16:57.621Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DMKzzCZX5YZX2ArNk',
    createdAt: '2022-03-12T09:16:57.640Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 899.2,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'SxFEY7u3dPjaoFEJQ',
    createdAt: '2022-03-12T09:16:57.657Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HfXbmGhp2ehgxLEsh',
    createdAt: '2022-03-12T09:16:57.675Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f7w7c4Cb2H6LNRRgJ',
    createdAt: '2022-03-12T09:16:57.705Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1078,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '7zZDvAh5SBusPTFs2',
    createdAt: '2022-03-12T09:17:35.679Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 160,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Hwbj5ChuGmEt78m8K',
    createdAt: '2022-03-12T09:17:35.710Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.500000000000036,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'akW2ec3J3Yx8x39bt',
    createdAt: '2022-03-12T09:17:35.731Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 154.27000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'sonCLmSBXn859kYEg',
    createdAt: '2022-03-12T09:17:35.761Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 443.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'R4ySBsDBrHHm5FLRS',
    createdAt: '2022-03-12T09:17:35.780Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 157.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'DWR4LvW3JjGR4JQtf',
    createdAt: '2022-03-12T09:17:35.803Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9289.07999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'pvNtbAsF9ZacKsvPX',
    createdAt: '2022-03-12T09:17:35.822Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CpMaakYa9Zd5deb9X',
    createdAt: '2022-03-12T09:17:35.843Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 890.2,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'fxGzbbkeERed9kjDH',
    createdAt: '2022-03-12T09:17:35.861Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '64p8HKrHTnGRgf7Wu',
    createdAt: '2022-03-12T09:17:35.880Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r8gfR3RSfMpXZNpBe',
    createdAt: '2022-03-12T09:17:35.904Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1069,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'r5h9neYkTh2p7Ne8h',
    createdAt: '2022-03-12T09:19:14.711Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 110,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'G7cCiJBQocjjFaJNH',
    createdAt: '2022-03-12T09:19:14.738Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.10000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'jJDFo8irzTedy8MK3',
    createdAt: '2022-03-12T09:19:14.759Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 154.07000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'E2yWwD23jtAiQKuzQ',
    createdAt: '2022-03-12T09:19:14.787Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 441.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '5zWfMCCAsSi25FxhW',
    createdAt: '2022-03-12T09:19:14.808Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 157.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'SHFRh2WxMSyQR4WCx',
    createdAt: '2022-03-12T09:19:14.827Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9287.57999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '6n6KNjZoNosETZKjS',
    createdAt: '2022-03-12T09:19:14.848Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Nvudkqi9zMnZ9fXcQ',
    createdAt: '2022-03-12T09:19:14.874Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 881.2,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '784RPcABX6JwijBLb',
    createdAt: '2022-03-12T09:19:14.896Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'adLbmDQw47SFbkDSY',
    createdAt: '2022-03-12T09:19:14.918Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ycmZRqoDjJfjAWCLS',
    createdAt: '2022-03-12T09:19:14.949Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1060,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'iMtvPYjuWpnohmNDF',
    createdAt: '2022-03-12T09:20:03.789Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 60,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'fihSkZNjqcFP9EwK4',
    createdAt: '2022-03-12T09:20:03.821Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 58.70000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'xSyLProaKpTTMAAd8',
    createdAt: '2022-03-12T09:20:03.847Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 153.67000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'pXbwXqpFjnKmzbYda',
    createdAt: '2022-03-12T09:20:03.871Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 439.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'g4bTtmtsJp7LmiTrk',
    createdAt: '2022-03-12T09:20:03.903Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 156.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'xzFAPymh53eQbEGbu',
    createdAt: '2022-03-12T09:20:03.939Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9286.07999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'EWrF5mR2f4NdBRRP7',
    createdAt: '2022-03-12T09:20:03.965Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qJ325fq9zwF9ds7o5',
    createdAt: '2022-03-12T09:20:03.990Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 872.2,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'GewSsPREQyZxuf5CW',
    createdAt: '2022-03-12T09:20:04.028Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HgQ6ifmuwAosezccw',
    createdAt: '2022-03-12T09:20:04.073Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NMnJdsnk7sqfxDfNv',
    createdAt: '2022-03-12T09:20:04.106Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1051,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'gsgoEfMKdT3bzDG3u',
    createdAt: '2022-03-12T09:21:31.713Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4434.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'u9EixprpCiCzpWCeX',
    createdAt: '2022-03-12T09:21:31.732Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'wAT53CLLHKcwjvfyc',
    createdAt: '2022-03-12T09:21:31.752Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 64.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'wvdYAF7yEL9sGCpTC',
    createdAt: '2022-03-12T09:21:31.769Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 145814.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'YY2XCCCv5sQjT6SqN',
    createdAt: '2022-03-12T09:21:31.785Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 674.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'cjKabADpQRrv9W3nq',
    createdAt: '2022-03-12T09:21:31.827Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 10,
    },
    transactionType: 'in',
    amount: 155,
  },
  {
    _id: 'DzAxQPAGZ6piag3Hi',
    createdAt: '2022-03-12T10:24:04.397Z',
    transactionType: 'in',
    amount: 835,
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 165,
    },
  },
  {
    _id: 'PeQwapdQiAJYJ7zZx',
    createdAt: '2022-03-12T10:24:50.666Z',
    transactionType: 'in',
    amount: 905,
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 95,
    },
  },
  {
    _id: '5WLooeaPQZiYxDtbE',
    createdAt: '2022-03-12T10:25:39.427Z',
    transactionType: 'in',
    amount: 124,
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1000,
    },
  },
  {
    _id: 'maNuYmxtNwZNySWi9',
    createdAt: '2022-03-12T10:30:10.860Z',
    transactionType: 'out',
    amount: -315,
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1000,
    },
  },
  {
    _id: 'zhrqrar82Y8DMiegQ',
    createdAt: '2022-03-12T10:33:03.988Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3948,
    },
  },
  {
    _id: 'j2FgNTkRY2cEZXhRe',
    createdAt: '2022-03-12T10:33:53.521Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3918,
    },
  },
  {
    _id: 'pWMyKgxns8iY7SgcG',
    createdAt: '2022-03-12T10:34:16.833Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 510,
    },
  },
  {
    _id: 'nE7qWPhRrw3keqrev',
    createdAt: '2022-03-12T10:44:13.997Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 437.82000000000016,
    },
  },
  {
    _id: 'LtR7GPpuhQo2TY6bz',
    createdAt: '2022-03-12T10:47:23.456Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 4710,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'S6euxjBsMSYReGw4b',
    createdAt: '2022-03-12T10:47:23.472Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9284.57999999999,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'HJf5HQGbyssrFedGN',
    createdAt: '2022-03-12T10:47:23.486Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144814.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '7oWfRuFvL3fwYGkbE',
    createdAt: '2022-03-12T10:47:23.504Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17727.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qmbFQiQ4DdwSxLKWF',
    createdAt: '2022-03-12T10:47:23.545Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 208,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: '6WbkKqWoJLYeaFbhr',
    createdAt: '2022-03-12T10:49:05.940Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 101,
    },
  },
  {
    _id: 'qPARGWcevkuGn4TyS',
    createdAt: '2022-03-12T10:50:42.615Z',
    transactionType: 'transferIn',
    amount: 600,
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1480,
    },
  },
  {
    _id: 'vZohsiGhAkPtJBstN',
    createdAt: '2022-03-12T10:51:28.019Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7271,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'zbpJAWRveENAaPZfJ',
    createdAt: '2022-03-12T10:51:28.042Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 62.3,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'J4PRRk8rHjBTn8bN5',
    createdAt: '2022-03-12T10:51:28.068Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 144314.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'nWNfAxkPHd42FgC7p',
    createdAt: '2022-03-12T10:51:28.089Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 548.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'WEXuM3jDPJWmnwpCA',
    createdAt: '2022-03-12T10:51:28.116Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9184.57999999999,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'FiGQZWnC297sMaZev',
    createdAt: '2022-03-12T10:51:28.148Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 708,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'YGxoMTSgdJw6haDuT',
    createdAt: '2022-03-12T10:51:28.181Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4606,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'LDZ7jJrMzmvFmfCT6',
    createdAt: '2022-03-12T10:51:40.357Z',
    transactionType: 'transferIn',
    amount: 320,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4546,
    },
  },
  {
    _id: 'Neyc5YSkRfXPZb4Ly',
    createdAt: '2022-03-12T11:37:04.365Z',
    transactionType: 'in',
    amount: 3800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 4271,
    },
  },
  {
    _id: 'N5p7juMFTZQY68nvs',
    createdAt: '2022-03-12T11:37:21.382Z',
    transactionType: 'in',
    amount: 240,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: '2gT3vzWJkdSZPTTrc',
    createdAt: '2022-03-12T11:47:48.076Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 352,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'bhYCngzgg6KYagkRg',
    createdAt: '2022-03-12T11:47:48.099Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3784,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'e5dEiBLANxtSctGiR',
    createdAt: '2022-03-12T11:47:48.120Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ApufSoH4gFWeistmT',
    createdAt: '2022-03-12T11:59:03.204Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 334,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'CkLeXj2JxJbjzHPDT',
    createdAt: '2022-03-12T11:59:03.228Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3747,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'JkMMSvDuC9oLxA4BD',
    createdAt: '2022-03-12T11:59:03.250Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'bWQxwFKnTvKaf4knY',
    createdAt: '2022-03-12T12:05:58.341Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8071,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'th6vXAenfmMpBLhyw',
    createdAt: '2022-03-12T12:05:58.364Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4434.980000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'RaezqHrPSCGtottFJ',
    createdAt: '2022-03-12T12:05:58.379Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 527,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '5Jn9GQKTSZWwhBr4N',
    createdAt: '2022-03-12T12:05:58.398Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 462.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'F5sf5rp9NqjajikCp',
    createdAt: '2022-03-12T12:05:58.418Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2110,
    },
    transactionType: 'transferOut',
    amount: -262,
  },
  {
    _id: 'nbjgt5heijZEou7Ag',
    createdAt: '2022-03-12T12:05:58.440Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1042,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'qbzfofbGa6ePctBTN',
    createdAt: '2022-03-12T12:39:38.348Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 240,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'pquaJCRLW9BjfPbzi',
    createdAt: '2022-03-12T12:39:38.367Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8779,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'QdX627HDHy7pPKXDk',
    createdAt: '2022-03-12T12:39:38.391Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 995,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ybMXJFtWhLgQdAAzp',
    createdAt: '2022-03-12T12:41:08.204Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 208,
    },
    transactionType: 'transferOut',
    amount: -158,
  },
  {
    _id: 'Hh9dLuBfFFfNSZLoq',
    createdAt: '2022-03-12T12:41:08.226Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8747,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'W9qSxN2egFi4EtdwA',
    createdAt: '2022-03-12T12:41:08.245Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3710,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'TBiYkDt7hpxxaN4HZ',
    createdAt: '2022-03-12T12:41:08.263Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 992,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'j2HY4yQWn5kG2RSSo',
    createdAt: '2022-03-12T12:46:10.767Z',
    transactionType: 'transferIn',
    amount: 3000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3934.980000000006,
    },
  },
  {
    _id: 'eZ5aDAh9uQEf4Kwav',
    createdAt: '2022-03-12T12:47:36.619Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2939.000000000001,
    },
  },
  {
    _id: 'Q9n6Xqwr7mkQ3ex7Q',
    createdAt: '2022-03-12T12:47:40.820Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1300,
    },
  },
  {
    _id: 'qa6FscZvcRG4YsQFW',
    createdAt: '2022-03-12T12:48:05.414Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9034.57999999999,
    },
  },
  {
    _id: 'HAcwgDxWukZ6vh5Ba',
    createdAt: '2022-03-12T12:49:45.245Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 987,
    },
  },
  {
    _id: 'nF79WLnng73Pctnf3',
    createdAt: '2022-03-12T12:50:39.289Z',
    transactionType: 'transferIn',
    amount: 3,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 23,
    },
  },
  {
    _id: 'WsY9pKWfZKqEh8xzg',
    createdAt: '2022-03-12T12:51:06.175Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 8,
    },
  },
  {
    _id: '87Duivyx8a7SqzhMn',
    createdAt: '2022-03-12T12:51:24.780Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'Hdy9QeF8vXbpAy9zx',
        name: 'HAIR NET CAP BLUE',
        unit: 'Carton',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc72a8ce4-4c50-43ef-9276-f844c2c4ff5d_200x200.jpeg?alt=media&token=73bf4610-40fd-49db-a30c-c40f9e662718',
        },
      },
      quantity: 1,
    },
  },
  {
    _id: 'jTKKW95ocFdi2BgaR',
    createdAt: '2022-03-12T12:51:50.404Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'HD6kFyoB86PuxA3ow',
        name: 'HAND SLEEVES ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc98a9b17-9a15-4a0c-844b-63af5c506ee5_200x200.webp?alt=media&token=a218086e-6af4-4827-9f13-f35f9b1d3f9c',
        },
      },
      quantity: 1,
    },
  },
  {
    _id: 'HTLAdZgCN8zL43kyp',
    createdAt: '2022-03-12T13:01:44.209Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DZSy95f3u63jDC65j',
    createdAt: '2022-03-12T13:01:44.229Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 863.2,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'K44ZxkcA3E6yXt38d',
    createdAt: '2022-03-12T13:01:44.246Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 153.47000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'MHH4vkqNxfFJYo8c8',
    createdAt: '2022-03-12T13:01:44.263Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3014.000000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'RP28eYrPsnQcC5qJr',
    createdAt: '2022-03-12T13:01:44.313Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'QL4iRizkdWZzAPbd5',
    createdAt: '2022-03-12T13:03:24.302Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6934.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'XNwyTt9gf6simF2mz',
    createdAt: '2022-03-12T13:03:32.027Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7921,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 's5DgQSCtMCmY7pR5g',
    createdAt: '2022-03-12T13:03:36.294Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 3011.000000000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'bM8Moyf99NkzNJSgG',
    createdAt: '2022-03-12T13:03:36.371Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 86,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'BhszNzn888dADoKbo',
    createdAt: '2022-03-12T13:03:36.422Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9284.57999999999,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'GSKiTLGWxppjENkEL',
    createdAt: '2022-03-12T13:03:36.448Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 142814.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '2cdEGBxaa3reLe2TN',
    createdAt: '2022-03-12T13:03:36.497Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 578,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: '7KubzSj8pXMFuBrMd',
    createdAt: '2022-03-12T13:03:36.520Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2080,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: 'ZBa4xjbCpWvrPmMtD',
    createdAt: '2022-03-12T13:03:36.552Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1029,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'waNXHm7ed2Fn46Xzy',
    createdAt: '2022-03-12T13:25:24.020Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6684.980000000006,
    },
  },
  {
    _id: 'ZEvqohwrvgffS6SzA',
    createdAt: '2022-03-12T14:29:16.105Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 7184.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'p6nTHSG62QgDDgqgM',
    createdAt: '2022-03-12T14:29:16.129Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LSrQdoNiJbWHaCg2W',
    createdAt: '2022-03-12T14:29:16.156Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7921,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6PmK8WuMxxB79bCFG',
    createdAt: '2022-03-12T14:29:16.173Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2961.000000000001,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '3kNp5uXxR4jgKovzG',
    createdAt: '2022-03-12T14:29:16.190Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'q2RitNuMjgyG8t4Ku',
    createdAt: '2022-03-12T14:29:16.211Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17727.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'DohBxPJ4jDSiGLXcX',
    createdAt: '2022-03-12T14:29:16.229Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MheeCHpyvx4hruXxK',
    createdAt: '2022-03-12T14:29:16.244Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 33.59999999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'LihrfpmaKo9SPgurg',
    createdAt: '2022-03-12T14:29:16.264Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9281.57999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'aKoLs5AKRyvgSDz86',
    createdAt: '2022-03-12T14:29:16.282Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4866,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'oAm2e7DdLnaJtA43E',
    createdAt: '2022-03-12T14:30:34.439Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 508,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'HHMRufWwWSgSFR86K',
    createdAt: '2022-03-12T14:30:34.460Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 846.2,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'yYifiPBMjMzjEnpQR',
    createdAt: '2022-03-12T14:30:34.484Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 153.22000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'G8oqowi2gWYcH79nA',
    createdAt: '2022-03-12T14:30:34.502Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2954.000000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Hjc7mpN2yvbrpRciP',
    createdAt: '2022-03-12T14:30:34.543Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'JAA9Becikm4aAeSAq',
    createdAt: '2022-03-12T14:30:49.147Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7921,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '62ap9sxQz23cf8p6u',
    createdAt: '2022-03-12T14:30:49.171Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6384.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Js7LqGrrq6RLTmzp2',
    createdAt: '2022-03-12T14:30:49.192Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2951.000000000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'KKiQhvtgxXFru8jYu',
    createdAt: '2022-03-12T14:30:49.215Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 86,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '762xeH56aziC7YWsS',
    createdAt: '2022-03-12T14:30:49.246Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 140514.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'CMhdWRvEdcnk9zhBm',
    createdAt: '2022-03-12T14:30:49.266Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9280.97999999999,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'zA2wMJWbHG3eK8eTB',
    createdAt: '2022-03-12T14:30:49.291Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 555,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'TsSRYfSiSzTKCo3nD',
    createdAt: '2022-03-12T14:30:49.312Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2057,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'Hw3e7AAFb7zzAspQS',
    createdAt: '2022-03-12T14:33:02.733Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7921,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: '8maWuKtBT8m5SpCGz',
    createdAt: '2022-03-12T14:33:02.758Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2901.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WNhgmr5nwkzJWXKTv',
    createdAt: '2022-03-12T14:33:02.777Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 368.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'sm4eB2oNc5ZZYvQys',
    createdAt: '2022-03-12T14:33:02.796Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17755,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'Yh9NS6oeAL3LRAPq4',
    createdAt: '2022-03-12T14:33:02.817Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9277.97999999999,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'ayKx8SBX5ChsR2tqf',
    createdAt: '2022-03-12T14:33:02.840Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21736.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'NEenFdwo6vr5CqowJ',
    createdAt: '2022-03-12T14:33:02.863Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 536,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Bon2z6pd274tGYB7p',
    createdAt: '2022-03-12T14:33:02.884Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2038,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KYMZSnyBx9peiyXxq',
    createdAt: '2022-03-12T14:35:51.159Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7171,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'DANsFkEx8XXwL7zbz',
    createdAt: '2022-03-12T14:35:51.186Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2901.000000000001,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '8Mg4TFF8phXzW63Rr',
    createdAt: '2022-03-12T14:35:51.209Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 328.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'XBmH4cErjhXWGuRnv',
    createdAt: '2022-03-12T14:35:51.230Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17715,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'twLgJeMQnuyG5BkCq',
    createdAt: '2022-03-12T14:35:51.251Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 128,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'rcchFbBpopdW7oZnK',
    createdAt: '2022-03-12T14:35:51.275Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4248,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'aTgc6iMdcHF7PhEoQ',
    createdAt: '2022-03-12T14:37:53.898Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 4310,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'Zw6DTAkW4hEggZm2k',
    createdAt: '2022-03-12T14:37:53.916Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9266.97999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '4iuv8F5tsYQxdRGSE',
    createdAt: '2022-03-12T14:37:53.932Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 138214.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'x8MaPjcrQds8Ny7LK',
    createdAt: '2022-03-12T14:37:53.952Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17715.5,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'v7nT4qGQR6yt5ksyN',
    createdAt: '2022-03-12T14:37:53.997Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 8,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'wuLkwaMvtX3sxX94C',
    createdAt: '2022-03-12T14:38:48.426Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6134.980000000006,
    },
    transactionType: 'transferOut',
    amount: -330,
  },
  {
    _id: 'jigjmSj9ZgoaWJ2Xv',
    createdAt: '2022-03-12T14:38:48.450Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SAJ2HNydwucnAEJp6',
    createdAt: '2022-03-12T14:38:48.469Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6571,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'h4SrczKYCrKb7eb8L',
    createdAt: '2022-03-12T14:38:48.499Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2883.000000000001,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'RqvFDrLcEkmdYTox7',
    createdAt: '2022-03-12T14:38:48.524Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 293.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ntydfqRj9r2JNeccb',
    createdAt: '2022-03-12T14:38:48.554Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135614.3,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'Qz8WC8SXq8QX9dA7h',
    createdAt: '2022-03-12T14:38:48.575Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17695,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KxobXXLBQcy3F9evS',
    createdAt: '2022-03-12T14:38:48.604Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 279,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'PGqJpWd74CmfbcLGD',
    createdAt: '2022-03-12T14:38:48.632Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 808,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: '7LMaLoKtvj3zisrfu',
    createdAt: '2022-03-12T14:38:48.659Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4207,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'BiKXXikW7zWifgdMz',
    createdAt: '2022-03-12T14:40:51.441Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5804.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NRS2GiiwkKn2CHyWy',
    createdAt: '2022-03-12T14:40:51.462Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3300,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'MwoRMtR5SqhGbfKW3',
    createdAt: '2022-03-12T14:40:51.488Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 59.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '9qti68ktkcxeuGJ8E',
    createdAt: '2022-03-12T14:40:51.507Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135464.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '5pAnJ5Gqti9vcHAoe',
    createdAt: '2022-03-12T14:40:51.527Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 273.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -129,
  },
  {
    _id: 'ZzN3SvAaLYANnDLT9',
    createdAt: '2022-03-12T14:40:51.574Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 685,
    },
    transactionType: 'in',
    amount: 150,
  },
  {
    _id: 'ZWmX9MJiWPgkBEKme',
    createdAt: '2022-03-12T14:42:10.780Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6571,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '79YuA6qr7WvqSGqia',
    createdAt: '2022-03-12T14:42:10.799Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5804.980000000006,
    },
    transactionType: 'transferOut',
    amount: -440,
  },
  {
    _id: 'JmfK6Y2nneMcC2xMX',
    createdAt: '2022-03-12T14:42:10.816Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'T9wyf5CLEwi9xjdS7',
    createdAt: '2022-03-12T14:42:10.833Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2873.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vwqG4pZbTJ4x7YXup',
    createdAt: '2022-03-12T14:42:10.851Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HeeavArXfzQshwJmi',
    createdAt: '2022-03-12T14:42:10.877Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 457.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'pBT7h6X7fuxNXq92z',
    createdAt: '2022-03-12T14:42:10.896Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 63.95,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'idkFvTbnMjSBMpGts',
    createdAt: '2022-03-12T14:42:10.933Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17685.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'cog9PEWicxcMHC4wb',
    createdAt: '2022-03-12T14:42:10.954Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 4018,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'atexKv6aRYi6QpKLp',
    createdAt: '2022-03-14T06:53:31.393Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 472,
    },
  },
  {
    _id: 'T6CfzBwxtN2Q2XaTb',
    createdAt: '2022-03-14T06:54:30.495Z',
    transactionType: 'transferIn',
    amount: 65,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 328,
    },
  },
  {
    _id: 'rDmmd6TvSjxNtWwHv',
    createdAt: '2022-03-14T08:05:10.869Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5364.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1275,
  },
  {
    _id: 'LXKeM6WW8awFqb8cJ',
    createdAt: '2022-03-14T08:05:10.892Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6571,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'b5H5ujuJdBifR6MiW',
    createdAt: '2022-03-14T08:05:10.911Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QYiM2MDsN3BJN24fM',
    createdAt: '2022-03-14T08:05:10.931Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2873.000000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MuEPY9wxutx3NhbhL',
    createdAt: '2022-03-14T08:05:10.971Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9226.97999999999,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: '3fnLdJod3aXJYE4uq',
    createdAt: '2022-03-14T08:05:10.993Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17679.5,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'DG2AqY7ks8FhZfvCh',
    createdAt: '2022-03-14T08:05:11.019Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3918,
    },
    transactionType: 'transferOut',
    amount: -85,
  },
  {
    _id: 'fKsXBoWTvEWtCBd59',
    createdAt: '2022-03-14T08:58:58.785Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6571,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KeNfcZX3MM3gMGR55',
    createdAt: '2022-03-14T08:58:58.814Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4089.980000000006,
    },
    transactionType: 'transferOut',
    amount: -577,
  },
  {
    _id: 'eCBRtGraHZv9s875r',
    createdAt: '2022-03-14T08:58:58.847Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n5HHFCn86n6bkFApG',
    createdAt: '2022-03-14T08:58:58.868Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2873.000000000001,
    },
    transactionType: 'transferOut',
    amount: -17.3,
  },
  {
    _id: 'LpTZsiZRfQyjApE7k',
    createdAt: '2022-03-14T08:58:58.895Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 144.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: 'wFBivQgYMsFR9nWB9',
    createdAt: '2022-03-14T08:58:58.916Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134464.3,
    },
    transactionType: 'transferOut',
    amount: -280,
  },
  {
    _id: 'Xdj7aiPcuchJcCZQ9',
    createdAt: '2022-03-14T08:58:58.941Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 273,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'r62NnpXQ9FLNqYb28',
    createdAt: '2022-03-14T08:58:58.962Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17683,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'SfB3J5ZSwFbWdn3e5',
    createdAt: '2022-03-14T08:58:58.982Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17654.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'uyXMa89rauQrhd3qp',
    createdAt: '2022-03-14T08:58:59.013Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 728,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'wYpWSgeXchPdLXSvs',
    createdAt: '2022-03-14T08:58:59.036Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4182,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'Hwzk9ws5RvTY6Zdxk',
    createdAt: '2022-03-14T09:05:41.183Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 498,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'TsWrAwNStiXqwEjEb',
    createdAt: '2022-03-14T09:05:41.203Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 829.2,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'WPNwe3FRJWBeDe9fj',
    createdAt: '2022-03-14T09:05:41.221Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 152.97000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'NNpK9daMs4SstoTiv',
    createdAt: '2022-03-14T09:05:41.239Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2855.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '9ihho6hiyHsJ5YusJ',
    createdAt: '2022-03-14T09:05:41.286Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 16,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'XfniCD4CpLxWJvuYP',
    createdAt: '2022-03-14T11:02:03.576Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6571,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'PTHMk2AucgKJFFimm',
    createdAt: '2022-03-14T11:02:03.601Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3512.980000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'Saf4MucJuGhyKzxm3',
    createdAt: '2022-03-14T11:02:03.623Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 522,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'YPJRyoxwfLh4hwqZ4',
    createdAt: '2022-03-14T11:02:03.645Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 453.82000000000016,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'fPmS3LjxLGdr3Lr8B',
    createdAt: '2022-03-14T11:02:03.667Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1848,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'cXcA427T5q7665kfu',
    createdAt: '2022-03-14T11:02:03.694Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1014,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'RahCk5eLMR8BBtZXs',
    createdAt: '2022-03-14T11:44:23.266Z',
    transactionType: 'in',
    amount: 4600,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6501,
    },
  },
  {
    _id: 'BHndrehfyYJGpDBGP',
    createdAt: '2022-03-14T11:44:42.676Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 50,
    },
  },
  {
    _id: 'BbjZn5b6zB93viow7',
    createdAt: '2022-03-14T12:04:32.056Z',
    transactionType: 'transferIn',
    amount: 2035,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1300,
    },
  },
  {
    _id: 'xbRgPHJewmpjFDpxH',
    createdAt: '2022-03-14T12:05:10.012Z',
    transactionType: 'transferIn',
    amount: 3350,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3312.980000000006,
    },
  },
  {
    _id: '8RD4kA7Cgevpbg6SL',
    createdAt: '2022-03-14T12:05:26.956Z',
    transactionType: 'transferIn',
    amount: 10,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
  },
  {
    _id: 'bRX987JMesZP3nZbC',
    createdAt: '2022-03-14T12:40:18.085Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 451.32000000000016,
    },
  },
  {
    _id: 'nAPowHprJSp8DYi9v',
    createdAt: '2022-03-14T12:40:47.461Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 57.3,
    },
  },
  {
    _id: 'ygY3ZASzqvqSF3YJd',
    createdAt: '2022-03-14T12:41:35.854Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8691,
    },
  },
  {
    _id: 'jW7qmJyL2msRYogno',
    createdAt: '2022-03-14T12:42:30.810Z',
    transactionType: 'transferIn',
    amount: 720,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 3708,
    },
  },
  {
    _id: 'YCWojSTjENFrpF2C3',
    createdAt: '2022-03-14T12:43:01.480Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4791,
    },
  },
  {
    _id: 'gs6uHwrrwjkmTsGNJ',
    createdAt: '2022-03-14T13:02:41.314Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 400,
    },
    transactionType: 'transferOut',
    amount: -218,
  },
  {
    _id: 'aJgvjTAcLr8tGkfJf',
    createdAt: '2022-03-14T13:02:41.336Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9331,
    },
    transactionType: 'transferOut',
    amount: -93,
  },
  {
    _id: 'Re2Lrsg5SdAHLDhck',
    createdAt: '2022-03-14T13:02:41.356Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4428,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '5rPhDhGin6YW9X35p',
    createdAt: '2022-03-14T13:02:41.373Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1032,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'dsmDSYgm2d2LRxxrW',
    createdAt: '2022-03-14T13:05:43.254Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 182,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'AKtRbLzSFw75BQWgZ',
    createdAt: '2022-03-14T13:05:43.270Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9238,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'CTuAtdj96NjGGHduW',
    createdAt: '2022-03-14T13:05:43.289Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1024,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'jRpkK5JgS5HJJRKMC',
    createdAt: '2022-03-14T13:11:34.935Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 316,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'deY9PQknR8aEpi5aH',
    createdAt: '2022-03-14T13:11:34.959Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4426,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'JA296E66bCrF8NKCY',
    createdAt: '2022-03-14T13:11:34.979Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1019,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'nsRB4BjGrQwYhaKfJ',
    createdAt: '2022-03-14T13:12:04.749Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 298,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'aSWYnq4SjtSM9zdHz',
    createdAt: '2022-03-14T13:12:04.772Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4389,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'ZomvJ28udr38AYc3x',
    createdAt: '2022-03-14T13:12:04.794Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1016,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'sgdBQYaLEsbziw8eH',
    createdAt: '2022-03-14T13:13:05.331Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 280,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: '4gDJfgYX6i4eHWtNL',
    createdAt: '2022-03-14T13:13:05.360Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9187,
    },
    transactionType: 'transferOut',
    amount: -91,
  },
  {
    _id: 'jSmsuptBZmp4Q8ZMP',
    createdAt: '2022-03-14T13:13:05.383Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1013,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'qgRawzjyuCfGMJSL9',
    createdAt: '2022-03-14T13:14:18.017Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 190,
    },
    transactionType: 'transferOut',
    amount: -108,
  },
  {
    _id: '2cv4stdo4npYdgtHw',
    createdAt: '2022-03-14T13:14:18.044Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9096,
    },
    transactionType: 'transferOut',
    amount: -109,
  },
  {
    _id: 'RNqTCa2XZYnBooHw4',
    createdAt: '2022-03-14T13:14:18.066Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1005,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'rTxpMRcoDvj8WtJpP',
    createdAt: '2022-03-14T13:16:48.885Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 6662.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'NqtfbEQgdmfLyRPhq',
    createdAt: '2022-03-14T13:16:48.909Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3335,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZKHYFHppPEStaHctA',
    createdAt: '2022-03-14T13:16:48.928Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FRKvM7ECr5nQAyBWQ',
    createdAt: '2022-03-14T13:16:48.947Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2852.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wgkdHHka4KCjyu6Bj',
    createdAt: '2022-03-14T13:16:48.971Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vJCFPidDP63Ar7KZu',
    createdAt: '2022-03-14T13:16:48.991Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17644.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'NzrATwRyZpDhAqCju',
    createdAt: '2022-03-14T13:16:49.013Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'S8cj3W6nxdzvSt679',
    createdAt: '2022-03-14T13:16:49.033Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 63.45,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'x4Tnv7RHEtdbRHFbP',
    createdAt: '2022-03-14T13:16:49.053Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 32.09999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'bS42zxauwxB9ARWn6',
    createdAt: '2022-03-14T13:16:49.072Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 476.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'qic8bXdr6dn43kreb',
    createdAt: '2022-03-14T13:16:49.101Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9224.47999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'avo4NjQPnsXcfQefm',
    createdAt: '2022-03-14T13:16:49.120Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4991,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'mLmHzqpdkR9v2GzzY',
    createdAt: '2022-03-14T13:17:38.832Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5832.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'r4R8W3vet4WvvS9GB',
    createdAt: '2022-03-14T13:17:38.852Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3335,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PNNck5CmWJ94E6wrB',
    createdAt: '2022-03-14T13:17:38.875Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2SkqE8cdpkw7i5f8h',
    createdAt: '2022-03-14T13:17:38.895Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2852.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cbrKcB9xGBzFrDg9X',
    createdAt: '2022-03-14T13:17:38.915Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hye3J9xTrR6Mrk9XT',
    createdAt: '2022-03-14T13:17:38.935Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17632.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'A75Wi3aQ9LW4NNkLn',
    createdAt: '2022-03-14T13:17:38.967Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nXkAXtEExTfaS7s3Y',
    createdAt: '2022-03-14T13:17:38.999Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 62.45,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'k4YCRN7EuSxyR4Pzu',
    createdAt: '2022-03-14T13:17:39.024Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 31.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'eqFxSy7vwLG287jeR',
    createdAt: '2022-03-14T13:17:39.043Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 468.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'HSk9wFQWa6bvi2Ckx',
    createdAt: '2022-03-14T13:17:39.061Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9223.87999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'thai4YtrqkxaZEsyR',
    createdAt: '2022-03-14T13:17:39.086Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4915,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'YsjQDwKHxivugC7PM',
    createdAt: '2022-03-14T13:30:22.286Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 129,
    },
    transactionType: 'transferOut',
    amount: -129,
  },
  {
    _id: 'aimbAqd7m7NprWWWq',
    createdAt: '2022-03-14T13:30:22.309Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 9.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Yax94njHGWzSpcmeF',
    createdAt: '2022-03-14T13:30:22.359Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 82,
    },
    transactionType: 'in',
    amount: 129,
  },
  {
    _id: 'ndvGjrtJ5rmp92cse',
    createdAt: '2022-03-15T05:18:57.553Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5002.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'YaFrRmeYX46GvmMph',
    createdAt: '2022-03-15T05:18:57.587Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3335,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DYGs82k6QeEJn7Q8N',
    createdAt: '2022-03-15T05:18:57.611Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hBYjXx48NtyLrsaDJ',
    createdAt: '2022-03-15T05:18:57.630Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2852.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'h8Q3a3pGB5isKAuYd',
    createdAt: '2022-03-15T05:18:57.651Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'YcTud5espQ2XFfA9s',
    createdAt: '2022-03-15T05:18:57.673Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17620.5,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'kFexN3khW4LN35bsF',
    createdAt: '2022-03-15T05:18:57.696Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aTQhaKeR5sYrfpKkz',
    createdAt: '2022-03-15T05:18:57.717Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 31.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'hFeDLYNAAeEMxpsqa',
    createdAt: '2022-03-15T05:18:57.737Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 61.45,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Py2b5JmrAXMnnedBX',
    createdAt: '2022-03-15T05:18:57.760Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NgdKLakadEeNZtshJ',
    createdAt: '2022-03-15T05:18:57.785Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9223.27999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'acAs7ckfhz2Kwzafd',
    createdAt: '2022-03-15T05:18:57.811Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4839,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: 'YamRME42ts57ZAGQR',
    createdAt: '2022-03-15T05:21:43.651Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4202.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'MK6G7L6m2nLiZhMzw',
    createdAt: '2022-03-15T05:21:43.673Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3335,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4PjtYR4k7SswzJm3o',
    createdAt: '2022-03-15T05:21:43.692Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SAu6TtD5GEG2QoBtk',
    createdAt: '2022-03-15T05:21:43.725Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2846.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'NRTLgwYgj78KvjRkg',
    createdAt: '2022-03-15T05:21:43.745Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 110,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'QNiJea7Z4j765CCi4',
    createdAt: '2022-03-15T05:21:43.763Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17619.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'EGjkSrEBjS3ZkuLuR',
    createdAt: '2022-03-15T05:21:43.782Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kWfukT5t5Ntzvv8Th',
    createdAt: '2022-03-15T05:21:43.801Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 30.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'e2QeBoN9fZMHbkkZj',
    createdAt: '2022-03-15T05:21:43.818Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9222.67999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'o44T98SAj3JMpnD5A',
    createdAt: '2022-03-15T05:21:43.841Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4767,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'h4Lqt7qizRpCMriaY',
    createdAt: '2022-03-15T05:26:12.184Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4vYAH536iQjwMkAgL',
    createdAt: '2022-03-15T05:26:12.209Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3402.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1700,
  },
  {
    _id: '4rTsi5SwBmXbALL5k',
    createdAt: '2022-03-15T05:26:12.230Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2840.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kuvKiC2MbQChmYc4h',
    createdAt: '2022-03-15T05:26:12.249Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZDryPDmYfNwCDfGJp',
    createdAt: '2022-03-15T05:26:12.264Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9222.079999999989,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'axN8mwBLubHhPF88j',
    createdAt: '2022-03-15T05:26:12.283Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17607.9,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'KJQvhjXuKF6PPGs4k',
    createdAt: '2022-03-15T05:26:12.301Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 29.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'onEwC9HK5NmayREZE',
    createdAt: '2022-03-15T05:26:12.319Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4694,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'v5qtZ9bYMFri6hM6u',
    createdAt: '2022-03-15T05:26:12.362Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 301,
    },
    transactionType: 'in',
    amount: 270,
  },
  {
    _id: 'g6orrJ4TATmFRNSvg',
    createdAt: '2022-03-15T06:59:42.864Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4667,
    },
  },
  {
    _id: '93SGoH7kjBei2MArw',
    createdAt: '2022-03-15T07:01:45.600Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 497,
    },
  },
  {
    _id: 'JZw8e9riD4jkbZZhv',
    createdAt: '2022-03-15T07:02:21.670Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 996,
    },
  },
  {
    _id: 'xPpvQwyEgLiJ57N8v',
    createdAt: '2022-03-15T07:02:54.406Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1007,
    },
  },
  {
    _id: 'a3EeEq34bru9kbad8',
    createdAt: '2022-03-15T07:03:30.245Z',
    transactionType: 'transferIn',
    amount: 600,
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1140,
    },
  },
  {
    _id: 'q2dDBpy3hSyLkPQk8',
    createdAt: '2022-03-15T07:09:46.813Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1702.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'bbyGNbDicqxMYbt9x',
    createdAt: '2022-03-15T07:09:46.837Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7bqTKvz2dAzwby58o',
    createdAt: '2022-03-15T07:09:46.857Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2840.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'xvEBYR9ujpx97yaGs',
    createdAt: '2022-03-15T07:09:46.877Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 86,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'QBwnZG96PZxJ4KxM7',
    createdAt: '2022-03-15T07:09:46.918Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9219.079999999989,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'mWJQGnNpmvZGLsgZw',
    createdAt: '2022-03-15T07:09:46.946Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134184.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'HSTi3qXjJWn2mvHt6',
    createdAt: '2022-03-15T07:09:46.970Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 536,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'HXKcycNkmNf6yTn25',
    createdAt: '2022-03-15T07:09:46.993Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2026,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '4tKsgSCPyJ5amvW4u',
    createdAt: '2022-03-15T07:09:47.032Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1097,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'WJC4FNJMbsbSHtWgZ',
    createdAt: '2022-03-15T08:48:15.524Z',
    transactionType: 'transferIn',
    amount: 760,
    storeItem: {
      type: {
        _id: 'aDeGN74pbvFRrd67f',
        name: 'YOUGHURT 10 KG BUCKET',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd997e1bb-946a-470f-aa13-70d2d5ac4a21_200x200.jpg?alt=media&token=e88e6639-61d4-4a1f-9374-f472ae1385a3',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'ufnc5wzQmgs6vDGSZ',
    createdAt: '2022-03-15T08:59:09.461Z',
    transactionType: 'transferOut',
    amount: -760,
    storeItem: {
      type: {
        _id: 'aDeGN74pbvFRrd67f',
        name: 'YOUGHURT 10 KG BUCKET',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fd997e1bb-946a-470f-aa13-70d2d5ac4a21_200x200.jpg?alt=media&token=e88e6639-61d4-4a1f-9374-f472ae1385a3',
        },
      },
      quantity: 760,
    },
  },
  {
    _id: '7zADWjXeFLD7cnfff',
    createdAt: '2022-03-15T10:03:28.952Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 488,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'ua67citHKvyJ3DuNN',
    createdAt: '2022-03-15T10:03:28.980Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 812.2,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'juKJmguauxMMysmup',
    createdAt: '2022-03-15T10:03:29.005Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 152.72000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'fydDixiqpokq6wf7i',
    createdAt: '2022-03-15T10:03:29.026Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2790.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'YBiKpWe64jk4NbdW3',
    createdAt: '2022-03-15T10:03:29.084Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 9,
    },
    transactionType: 'in',
    amount: 67,
  },
  {
    _id: '7MXfee5C5zxjyn3Cb',
    createdAt: '2022-03-15T10:03:55.060Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1452.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'evYxmYaCDFSQTrqC3',
    createdAt: '2022-03-15T10:03:55.084Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'W4pFP2o7jQd9gHnnD',
    createdAt: '2022-03-15T10:03:55.104Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2787.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Lra4uwdM6ww3ReQSk',
    createdAt: '2022-03-15T10:03:55.133Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 76,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'wK7WNEJHpcPQ3sr2b',
    createdAt: '2022-03-15T10:03:55.157Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131884.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'bxHu7Fqmwo4FbHaft',
    createdAt: '2022-03-15T10:03:55.178Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9216.079999999989,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'abyHBWB5PCsGxZg7k',
    createdAt: '2022-03-15T10:03:55.199Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4140,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'Ad88NE4KvHKQgTjDq',
    createdAt: '2022-03-15T10:06:54.752Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 110.60000000000036,
    },
  },
  {
    _id: '3LxRig3Bqgaz8zBCJ',
    createdAt: '2022-03-15T10:08:44.998Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3550,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'gijGbNd6dE4E2qeEy',
    createdAt: '2022-03-15T10:08:45.026Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9213.079999999989,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'wtmot9tZar3aMec7r',
    createdAt: '2022-03-15T10:08:45.045Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 129584.29999999999,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'mXfBmxLdBH7fGbzgZ',
    createdAt: '2022-03-15T10:08:45.064Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17582.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aG9b2CeLZJCvFPsKR',
    createdAt: '2022-03-15T10:08:45.121Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 308,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'hiwNsMossJwzkfEoS',
    createdAt: '2022-03-15T10:10:42.993Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11101,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'FZNxLJ3RSe5MSS8vJ',
    createdAt: '2022-03-15T10:10:43.024Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 82.3,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'wMvrYhYpYNJzbnjhp',
    createdAt: '2022-03-15T10:10:43.044Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 129084.29999999999,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'fgkbsDPZNX3xF5cWB',
    createdAt: '2022-03-15T10:10:43.068Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2110.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: '9Z2yoRMy2qLZJaBw6',
    createdAt: '2022-03-15T10:10:43.086Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9113.079999999989,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'XDaw7vGbGT4vrzbAM',
    createdAt: '2022-03-15T10:10:43.109Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 808,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'uHq62sEwe3ZfMGN7E',
    createdAt: '2022-03-15T10:10:43.133Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4867,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'ajLK4P2ewcCYKPdGN',
    createdAt: '2022-03-15T10:11:50.416Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8101,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'NEM5q7mdexKz99ZC9',
    createdAt: '2022-03-15T10:11:50.441Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 79.3,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'RrLayC38t6ZCTYBmu',
    createdAt: '2022-03-15T10:11:50.469Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 127584.29999999999,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'SKhwkF6SPiT6f8mXh',
    createdAt: '2022-03-15T10:11:50.493Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1920.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -192,
  },
  {
    _id: '5yJpvQRsTeQjtcvz4',
    createdAt: '2022-03-15T10:11:50.515Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8963.079999999989,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'JvM33HALunfSaS7bL',
    createdAt: '2022-03-15T10:11:50.568Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 448,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'tqDiPxAasnxkEHa3Z',
    createdAt: '2022-03-15T10:11:50.590Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4807,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '754rbXbaaynyZEJAg',
    createdAt: '2022-03-15T12:27:49.473Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5101,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'NrrnaFf8c5omkBnRa',
    createdAt: '2022-03-15T12:27:49.499Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1202.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'mjXf9x6KJHsBPif83',
    createdAt: '2022-03-15T12:27:49.537Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 522,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'M8gKiQryaqRKooY4R',
    createdAt: '2022-03-15T12:27:49.561Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 460.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'K6R6pqJqi4gxpL5uh',
    createdAt: '2022-03-15T12:27:49.582Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1716,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'KfDoKcB3SngN2Hnmi',
    createdAt: '2022-03-15T12:27:49.602Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1083,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'v5e5RvGGkFy4TNahb',
    createdAt: '2022-03-15T12:52:43.214Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2737.7000000000007,
    },
  },
  {
    _id: 'Nq7keQmduRus7Joqs',
    createdAt: '2022-03-15T12:53:17.646Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8813.079999999989,
    },
  },
  {
    _id: 'aKnpaQ2mLyFJy5PjD',
    createdAt: '2022-03-15T12:54:08.185Z',
    transactionType: 'transferIn',
    amount: 80,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 795.2,
    },
  },
  {
    _id: '3cSCWMSsTPGHCz7jg',
    createdAt: '2022-03-15T12:54:56.525Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1594,
    },
  },
  {
    _id: 'vpc6ENRTmnh83HNrc',
    createdAt: '2022-03-15T13:24:47.562Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5051,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'LfpPFHJXSo7SDgcq6',
    createdAt: '2022-03-15T13:24:47.586Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2862.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'ittJBHijMm2ji8obx',
    createdAt: '2022-03-15T13:24:47.615Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1728.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'YmLDbGsrggQ3KBf6f',
    createdAt: '2022-03-15T13:24:47.648Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17662,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'MX24ufmj2DdJr3fTb',
    createdAt: '2022-03-15T13:24:47.673Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 628,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'W3wGtADPc9y2ibWFN',
    createdAt: '2022-03-15T13:24:47.722Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4073,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'q8dGJZeZ4L2y5ckL9',
    createdAt: '2022-03-15T13:34:37.662Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 4401,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yu32fvyMMypfBjM9r',
    createdAt: '2022-03-15T13:34:37.694Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 952.9800000000059,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'SyTcYLnBmwG7Sn3yW',
    createdAt: '2022-03-15T13:34:37.717Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3335,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j829T6gBmaRPQ9qXL',
    createdAt: '2022-03-15T13:34:37.744Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2837.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2Qk2Az9xtL3zoua26',
    createdAt: '2022-03-15T13:34:37.767Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 106,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'maLZ6EyPep6q3SDpp',
    createdAt: '2022-03-15T13:34:37.789Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 458.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'A6kKYkueMEmF9Rfqu',
    createdAt: '2022-03-15T13:34:37.810Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 60.45,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '9i6Ykbzk2CfFH7XGD',
    createdAt: '2022-03-15T13:34:37.858Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17582.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ByreAYwy6QgJDBbqN',
    createdAt: '2022-03-15T13:34:37.878Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3833,
    },
    transactionType: 'transferOut',
    amount: -186,
  },
  {
    _id: 'RcQoJDSQsdxJag5iE',
    createdAt: '2022-03-16T05:11:21.833Z',
    transactionType: 'in',
    amount: 2300,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 4401,
    },
  },
  {
    _id: 'kZNnkm6gXs9bMfSJD',
    createdAt: '2022-03-16T05:11:46.071Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'phsPMXYvfJA4kCPAJ',
    createdAt: '2022-03-16T05:12:40.850Z',
    transactionType: 'in',
    amount: 4500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6701,
    },
  },
  {
    _id: 'SHGA65TL5sQKdc3bT',
    createdAt: '2022-03-16T05:12:56.356Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'Qvo2652jxDxteBcW3',
    createdAt: '2022-03-16T05:14:24.480Z',
    transactionType: 'in',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11201,
    },
  },
  {
    _id: 'q8upPuGRibwsEaoEJ',
    createdAt: '2022-03-16T06:27:33.411Z',
    transactionType: 'transferIn',
    amount: 1200,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3335,
    },
  },
  {
    _id: 'gmZ6T8rhcKTEdw2km',
    createdAt: '2022-03-16T06:28:08.470Z',
    transactionType: 'transferIn',
    amount: 3950,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 152.98000000000593,
    },
  },
  {
    _id: 'r52TTpfso6593ZfXy',
    createdAt: '2022-03-16T06:58:00.858Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4102.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'NdxLJAqjvg6Z28nwE',
    createdAt: '2022-03-16T06:58:00.885Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n4f4YtiqJbcdnYRF5',
    createdAt: '2022-03-16T06:58:00.920Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14201,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WABRZb35JvhxcCJoS',
    createdAt: '2022-03-16T06:58:00.943Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2837.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9q2dGWTBiHqHXkKw4',
    createdAt: '2022-03-16T06:58:00.967Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 106,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GYZownPL4GeprTuXh',
    createdAt: '2022-03-16T06:58:00.991Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17570.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7QcQeLSt2XY8uqb9C',
    createdAt: '2022-03-16T06:58:01.016Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hEinvTXJCxc9eanpq',
    createdAt: '2022-03-16T06:58:01.041Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 59.45,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'rTHfp97XmjrvtLhLL',
    createdAt: '2022-03-16T06:58:01.063Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 26.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ws3EuYmNfyBhJR6x9',
    createdAt: '2022-03-16T06:58:01.101Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'nxfqqY6W7C4pF4tZP',
    createdAt: '2022-03-16T06:58:01.143Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9113.079999999989,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'ujGGwGbd7mMSfdfcN',
    createdAt: '2022-03-16T06:58:01.171Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4747,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: '76fmbJhQYcRMwPXWG',
    createdAt: '2022-03-16T06:59:54.897Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3272.980000000006,
    },
    transactionType: 'transferOut',
    amount: -825,
  },
  {
    _id: 'ahBEughgoAYY9gKhQ',
    createdAt: '2022-03-16T06:59:54.926Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Lo7zrWBYjJZAvmMKF',
    createdAt: '2022-03-16T06:59:54.949Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14201,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dRqbsSMj9WEzsnjF3',
    createdAt: '2022-03-16T06:59:54.973Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2837.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'k2rYox3jEpm6nWnp4',
    createdAt: '2022-03-16T06:59:54.997Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 106,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dksPCug9mjrc43uKr',
    createdAt: '2022-03-16T06:59:55.021Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17558.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'mDH5jc6DZPQwi5nqi',
    createdAt: '2022-03-16T06:59:55.039Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'chFPvpRuHXyFBXy3s',
    createdAt: '2022-03-16T06:59:55.058Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 58.45,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vTCpuL38m6mCtQzGp',
    createdAt: '2022-03-16T06:59:55.078Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 26.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'A4snpdM88dGvbi237',
    createdAt: '2022-03-16T06:59:55.097Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 442.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '4RJ5ZQzdybJCJ9fwp',
    createdAt: '2022-03-16T06:59:55.120Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9112.479999999989,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'RyXmCgNidykzXLbqw',
    createdAt: '2022-03-16T06:59:55.143Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4671,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'txJo4bYqxuEFyJxiW',
    createdAt: '2022-03-16T07:04:11.690Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14201,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EFAtxBbDR7F2qRPFm',
    createdAt: '2022-03-16T07:04:11.719Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2447.980000000006,
    },
    transactionType: 'transferOut',
    amount: -510,
  },
  {
    _id: 'FyRwy8kywxbP4m6Ey',
    createdAt: '2022-03-16T07:04:11.743Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wYyTTm4AiSP75CEyb',
    createdAt: '2022-03-16T07:04:11.772Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2837.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ZP6sj3Q7JubrqPTHr',
    createdAt: '2022-03-16T07:04:11.799Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1688.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '7q9RGkB22dvAtWiWe',
    createdAt: '2022-03-16T07:04:11.825Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 126084.29999999999,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'yGyjav9eRLdeWsXMw',
    createdAt: '2022-03-16T07:04:11.856Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 260,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Pu3n276ZDPjfLT5hN',
    createdAt: '2022-03-16T07:04:11.887Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17637,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'w3djrGS8vccmfgLg4',
    createdAt: '2022-03-16T07:04:11.912Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17546.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'TKpnbkk5sZq5ubm7z',
    createdAt: '2022-03-16T07:04:11.943Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 498,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: '4jkyfyDLTov3Axg87',
    createdAt: '2022-03-16T07:04:11.969Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4027,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'p8oafHpyfT9BxdNXc',
    createdAt: '2022-03-16T09:11:57.427Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4596,
    },
  },
  {
    _id: 'BB4cQkXRMZzncKDbD',
    createdAt: '2022-03-16T09:12:33.494Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3990,
    },
  },
  {
    _id: 'N7ucaz9QACNa6at88',
    createdAt: '2022-03-16T09:13:46.464Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3647,
    },
  },
  {
    _id: 'ZFLicCMns92Ljmcru',
    createdAt: '2022-03-16T09:14:28.255Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9111.879999999988,
    },
  },
  {
    _id: 'XBEWPPEwymnp7ECSd',
    createdAt: '2022-03-16T09:14:50.798Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2822.7000000000007,
    },
  },
  {
    _id: 'cEBRAuGFoSnysQ3mf',
    createdAt: '2022-03-16T09:15:29.221Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 125834.29999999999,
    },
  },
  {
    _id: 'HagMWei638EmXML9f',
    createdAt: '2022-03-16T09:16:00.427Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 497,
    },
  },
  {
    _id: 'oA46jL6MARzBcc5aH',
    createdAt: '2022-03-16T09:21:39.118Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 478,
    },
  },
  {
    _id: 'Dt6WbCcPZzfwH4gAT',
    createdAt: '2022-03-16T09:23:05.786Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 528,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'msLskYwETJEmojd5M',
    createdAt: '2022-03-16T09:23:05.805Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 875.2,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'oLA2w8Ds3XQAfQzPw',
    createdAt: '2022-03-16T09:23:05.823Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 152.47000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'M2PLdoTKDRzE8rLeA',
    createdAt: '2022-03-16T09:23:05.841Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2947.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '55RSQnbj2jKiRfPe2',
    createdAt: '2022-03-16T09:23:05.902Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'rBnfKXod9dxKaAbEA',
    createdAt: '2022-03-16T10:20:14.658Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 530,
    },
  },
  {
    _id: 'qjWsBELvShExgJRwE',
    createdAt: '2022-03-16T10:20:46.241Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4796,
    },
  },
  {
    _id: 'uG2jk6popw3sTM7xa',
    createdAt: '2022-03-16T11:02:19.959Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1937.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9jpxtTwEcu7ccKYw5',
    createdAt: '2022-03-16T11:02:19.985Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4535,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'sAs8rJcMWFpqMYyoc',
    createdAt: '2022-03-16T11:02:20.024Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 76.3,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'gnfeugC9NQ6DK9bQG',
    createdAt: '2022-03-16T11:02:20.045Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175834.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'KmHGSfSJ9Qdorizgz',
    createdAt: '2022-03-16T11:02:20.080Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1658.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'kFfQiLyv2nDMoanQP',
    createdAt: '2022-03-16T11:02:20.145Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 835,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: 'voo8BREeEdunbDGX2',
    createdAt: '2022-03-16T11:04:43.899Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14201,
    },
    transactionType: 'transferOut',
    amount: -380,
  },
  {
    _id: 'XvAWvHANuknraadKm',
    createdAt: '2022-03-16T11:04:43.921Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1937.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Tzz29r4WdCupfHpnz',
    createdAt: '2022-03-16T11:04:43.941Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3fmBzueCTioLSwEa6',
    createdAt: '2022-03-16T11:04:43.963Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2944.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -11.5,
  },
  {
    _id: '62NjFpEeLX3D9NkPZ',
    createdAt: '2022-03-16T11:04:43.982Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1408.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'rXBXXPR3aHJ34Sk2j',
    createdAt: '2022-03-16T11:04:44.006Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17617,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'fA3FFrnqXsg6ZwutX',
    createdAt: '2022-03-16T11:04:44.028Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17536.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'GJfeujMiAj5wswGfi',
    createdAt: '2022-03-16T11:04:44.050Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 408,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '6MFcbKuvdwp5RqzRP',
    createdAt: '2022-03-16T11:04:44.073Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4190,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'yLcKJFjEfDbNgcj7o',
    createdAt: '2022-03-16T11:06:27.917Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13821,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'y6y635MDpcNdpyTiT',
    createdAt: '2022-03-16T11:06:27.937Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1937.980000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '8tsnDkXsENjQHq42o',
    createdAt: '2022-03-16T11:06:27.957Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YErQhbLYfAz6ejyzQ',
    createdAt: '2022-03-16T11:06:27.977Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2933.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'APF9QfTXNyMWPN232',
    createdAt: '2022-03-16T11:06:27.996Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1387.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: '35TpEfepiKCX9xJpc',
    createdAt: '2022-03-16T11:06:28.046Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17602,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'GMXmbjNqvArgyBdQk',
    createdAt: '2022-03-16T11:06:28.081Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17531.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'nFSu6wxuN7bKB88Zv',
    createdAt: '2022-03-16T11:06:28.108Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 348,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'ynabxFMdEc2YnaWJK',
    createdAt: '2022-03-16T11:06:28.142Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4164,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: '4C8xM9tW2Nr8t3LrA',
    createdAt: '2022-03-16T11:09:12.503Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13421,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'cCw8QgHGJKfwKesLD',
    createdAt: '2022-03-16T11:09:12.523Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1737.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'EqK7g6YogZ2XA2d2Y',
    createdAt: '2022-03-16T11:09:12.539Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 547,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'xKY6fJaRgsL8JSdch',
    createdAt: '2022-03-16T11:09:12.555Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 434.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Nbg7iJkdP5yiFKLHW',
    createdAt: '2022-03-16T11:09:12.575Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2094,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'XpnaS5Eefoec5o7EX',
    createdAt: '2022-03-16T11:09:12.599Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1077,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'bCxbkhw5FXA7QbJyS',
    createdAt: '2022-03-16T11:18:17.280Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13271,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7punfcHGoiCHHurF9',
    createdAt: '2022-03-16T11:18:17.304Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3SKHaak6nX77YYYkx',
    createdAt: '2022-03-16T11:18:17.330Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1337.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Y5rQ9f2yTcj4rywk3',
    createdAt: '2022-03-16T11:18:17.369Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2933.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'fp4hnx4ystYkXcR2a',
    createdAt: '2022-03-16T11:18:17.520Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 250,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 's8ui6u8HP2B7RXDA6',
    createdAt: '2022-03-16T11:18:17.549Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17577,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'EZvFgsg9RRqiWpf77',
    createdAt: '2022-03-16T11:18:17.587Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175832.3,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: '76nd76a2KM8qQHngz',
    createdAt: '2022-03-16T11:18:17.629Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1342.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'FkhNxRyL9M78hhSZk',
    createdAt: '2022-03-16T11:18:19.117Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4118,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '27MJJWKXEsinR4Lzx',
    createdAt: '2022-03-16T11:34:06.392Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13271,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5GMMB3XtBgsRgstXL',
    createdAt: '2022-03-16T11:34:07.586Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1087.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'edaLWEbtEWieQ6E6L',
    createdAt: '2022-03-16T11:34:07.647Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 535,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DHcAYDBetbMvuWxfm',
    createdAt: '2022-03-16T11:34:08.729Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2925.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QMC5KdZrFpgbm6uh8',
    createdAt: '2022-03-16T11:34:08.882Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 57.45,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'qCtNwabQofapg6pyA',
    createdAt: '2022-03-16T11:34:08.929Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9361.879999999988,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'x7WafQwSrMXZrrR5c',
    createdAt: '2022-03-16T11:34:08.975Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3747,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'i833eJ6SrgavPw727',
    createdAt: '2022-03-16T12:20:15.199Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13271,
    },
    transactionType: 'transferOut',
    amount: -675,
  },
  {
    _id: 'e3hDzNJCcdCt2YDTD',
    createdAt: '2022-03-16T12:20:15.222Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2925.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '8wrusyvnfLyxCoBif',
    createdAt: '2022-03-16T12:20:15.241Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1327.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'Xvv73hPic8QiXysWm',
    createdAt: '2022-03-16T12:20:15.260Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17567,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'L9vnKDS64bEtHTaNn',
    createdAt: '2022-03-16T12:20:15.280Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 223,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: '3ZJSxac3Tz7YvmKxk',
    createdAt: '2022-03-16T12:20:15.300Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4101,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'TGTksAgD8qwQwwG47',
    createdAt: '2022-03-16T12:31:04.742Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -316,
  },
  {
    _id: 'ZvXrcJBfx6x6Kb8AX',
    createdAt: '2022-03-16T12:31:04.765Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8987,
    },
    transactionType: 'transferOut',
    amount: -162,
  },
  {
    _id: 'ghHbwNThfBnMF8d8c',
    createdAt: '2022-03-16T12:31:04.792Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4352,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'KrWD6xyGyLJ4irjYc',
    createdAt: '2022-03-16T12:31:04.814Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1056,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'AACksornNGSdwzfAN',
    createdAt: '2022-03-16T12:32:04.645Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 384,
    },
    transactionType: 'transferOut',
    amount: -79,
  },
  {
    _id: 'Be6wxfPKu3Jpvwosj',
    createdAt: '2022-03-16T12:32:04.667Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8825,
    },
    transactionType: 'transferOut',
    amount: -78,
  },
  {
    _id: 'PCf2kRNKwYZ2mrufB',
    createdAt: '2022-03-16T12:32:04.696Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1042,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'buMs2oLh7duTHRg5g',
    createdAt: '2022-03-16T12:34:03.257Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 305,
    },
    transactionType: 'transferOut',
    amount: -305,
  },
  {
    _id: 'nHetY4iCginaShDAJ',
    createdAt: '2022-03-16T12:34:03.277Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 8.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'mD4AD5RGwuXTRAbBH',
    createdAt: '2022-03-16T12:34:03.318Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 211,
    },
    transactionType: 'in',
    amount: 305,
  },
  {
    _id: 'zkHMcbe3ydvb8t6YX',
    createdAt: '2022-03-16T12:35:42.332Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 516,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: 'cXwQWGhDLYh3d3snr',
    createdAt: '2022-03-16T12:35:42.359Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8747,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'PRS2mK3aaFHEXLTng',
    createdAt: '2022-03-16T12:35:42.377Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1035,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Ka5LpvHCDRPNbfcME',
    createdAt: '2022-03-16T12:36:12.411Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 444,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: '3LGeHTTst56B4SJgW',
    createdAt: '2022-03-16T12:36:12.433Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8674,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'sg9BDi5HddPkR3Ljc',
    createdAt: '2022-03-16T12:36:12.458Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1029,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'cGgFoYL8bwMMFeGbd',
    createdAt: '2022-03-16T12:46:58.013Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 324,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'S9KjTLS257TXZBMgW',
    createdAt: '2022-03-16T12:46:58.039Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9361.079999999989,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'itznJWijHpnWMgkK6',
    createdAt: '2022-03-16T12:46:58.062Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1740,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: '8khmHKfAjFEn5xjpJ',
    createdAt: '2022-03-16T12:48:00.922Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 264,
    },
    transactionType: 'transferOut',
    amount: -47,
  },
  {
    _id: 'k4u6YpLyqbMh23dLK',
    createdAt: '2022-03-16T12:48:00.945Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1668,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'avLePJdTk5bbPavYQ',
    createdAt: '2022-03-16T12:48:00.966Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 192,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'K2JpcYxdronBh4Ycm',
    createdAt: '2022-03-16T13:02:48.414Z',
    transactionType: 'transferIn',
    amount: 4080,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 687.9800000000059,
    },
  },
  {
    _id: 'QqtzGYHhx8ZFbPg29',
    createdAt: '2022-03-16T13:03:12.686Z',
    transactionType: 'transferIn',
    amount: 2050,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 535,
    },
  },
  {
    _id: 'ftFAFKDfRRLmtPPGA',
    createdAt: '2022-03-16T13:29:12.592Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4767.980000000006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'gMWTvkX7gjN4vWdGJ',
    createdAt: '2022-03-16T13:29:12.615Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12596,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nBpdxJ2bXMYhtLb9u',
    createdAt: '2022-03-16T13:29:12.633Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2905.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'v58Y9NFvyLvvnAWwR',
    createdAt: '2022-03-16T13:29:12.658Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '48vBLoAr4oPaWw3pQ',
    createdAt: '2022-03-16T13:29:12.682Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175707.3,
    },
    transactionType: 'transferOut',
    amount: -1832,
  },
  {
    _id: 'cZMmPDTjojioHwaDw',
    createdAt: '2022-03-16T13:29:12.700Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9360.77999999999,
    },
    transactionType: 'transferOut',
    amount: -2.4,
  },
  {
    _id: 'udRKYWRC5iH4imNNh',
    createdAt: '2022-03-16T13:29:12.717Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4056,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: '3dhLmZAhMshgqE47H',
    createdAt: '2022-03-16T13:30:27.620Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4567.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'xmuA9fQqmfnMciuL9',
    createdAt: '2022-03-16T13:30:27.640Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PrqsAHAbp37nCA4KW',
    createdAt: '2022-03-16T13:30:27.660Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12596,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gQFjs5Ri4X528QyR3',
    createdAt: '2022-03-16T13:30:27.681Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2865.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'SqA4psb79NAkyuwxj',
    createdAt: '2022-03-16T13:30:27.698Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 106,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'EPCuSFjYWhpMkGGD4',
    createdAt: '2022-03-16T13:30:27.718Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17521.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'z4nWnZsCkDt2Sakm7',
    createdAt: '2022-03-16T13:30:27.737Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f4fXpNgdPwvZixAGK',
    createdAt: '2022-03-16T13:30:27.756Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 25.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'mFuzby4627N2pQjJ3',
    createdAt: '2022-03-16T13:30:27.774Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 56.95,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hm7jbePEz4AmYB2uf',
    createdAt: '2022-03-16T13:30:27.793Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LvrTXjKvMJpFf6j39',
    createdAt: '2022-03-16T13:30:27.810Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9358.37999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'tEkbdo5wbcR2bdE4c',
    createdAt: '2022-03-16T13:30:27.828Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5036,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'tCruxDchGdJhHLpmT',
    createdAt: '2022-03-16T13:40:30.574Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 172,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Hn4z6FKhC6ASJKsL4',
    createdAt: '2022-03-16T13:40:30.605Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iBHMQNNjsT8MKeMpo',
    createdAt: '2022-03-16T13:40:30.639Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 152.22000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'h8rkqZNFYbTr8ZPaZ',
    createdAt: '2022-03-16T13:40:30.665Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 858.2,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'buC2AnGL2AAEN7jaT',
    createdAt: '2022-03-16T13:40:30.693Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'NCE32fKzMWJLm8Z9H',
    createdAt: '2022-03-16T13:40:30.733Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 59.60000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'sE6jQFimZcYKjeKop',
    createdAt: '2022-03-16T13:40:30.760Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 58.30000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'khkxamKutcJ5eGDhH',
    createdAt: '2022-03-16T13:40:30.790Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 430.32000000000016,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'papCE3B5wcdsq4QRa',
    createdAt: '2022-03-16T13:40:30.816Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9357.77999999999,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'EMYKzbmRmfjhtdZqZ',
    createdAt: '2022-03-16T13:40:30.843Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1135,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'zzhu5AiuAF2Xv2Jp5',
    createdAt: '2022-03-16T13:40:30.878Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1124,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hiSyQvWbtAAZ7j4ru',
    createdAt: '2022-03-16T13:40:30.909Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 393,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: '2TGLDmvNv6wEaa96t',
    createdAt: '2022-03-16T13:40:30.965Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 22,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '9f3LL7mAyD5TyaQ3J',
    createdAt: '2022-03-17T05:56:14.226Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12596,
    },
    transactionType: 'transferOut',
    amount: -950,
  },
  {
    _id: 'wTbFw63QBTr53yT4j',
    createdAt: '2022-03-17T05:56:14.249Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3767.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8fq557cpTHT8vs9js',
    createdAt: '2022-03-17T05:56:14.268Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sPxgxhc8DfD9iTwgC',
    createdAt: '2022-03-17T05:56:14.288Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2858.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '3RjcNdz6HMFLL655f',
    createdAt: '2022-03-17T05:56:14.306Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1287.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'HQsgXzKdopcrZb2bn',
    createdAt: '2022-03-17T05:56:14.326Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17509.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EKgNGud87NXWNBKgf',
    createdAt: '2022-03-17T05:56:14.354Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17542,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'qh6D4Tz2txkoBdHw4',
    createdAt: '2022-03-17T05:56:14.378Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 98,
    },
    transactionType: 'transferOut',
    amount: -98,
  },
  {
    _id: 'zLJuzkyY7Kz5iqj6W',
    createdAt: '2022-03-17T05:56:14.403Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 514,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'NcgckGSxrdpiMPnWy',
    createdAt: '2022-03-17T05:56:14.427Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2004,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'xxWn9HLQLGn2Aroms',
    createdAt: '2022-03-17T05:56:14.447Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 190,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Wp4YKAQEucDbrSneZ',
    createdAt: '2022-03-17T05:57:06.384Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3150,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'wuFHKWgFaCfYtEgJJ',
    createdAt: '2022-03-17T05:57:06.413Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9357.52999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'wEMJRoZjdDzGr43cR',
    createdAt: '2022-03-17T05:57:06.433Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 173875.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'QtPy3WeEW8oH9f42g',
    createdAt: '2022-03-17T05:57:06.449Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17509.9,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'WLG5NLfEZJSnWB8wt',
    createdAt: '2022-03-17T05:57:06.492Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'SiEBrfEvQHgMW8uvK',
    createdAt: '2022-03-17T05:58:44.549Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11646,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ARMKi2kCiXD3xXXMk',
    createdAt: '2022-03-17T05:58:44.569Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3767.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'CRAWy4Fiv8sHb77y2',
    createdAt: '2022-03-17T05:58:44.591Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2585,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aBj7uvWrWK4BYy56D',
    createdAt: '2022-03-17T05:58:44.610Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2833.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'Gdq5pWKjSneG5Q3MT',
    createdAt: '2022-03-17T05:58:44.628Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1223.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'Kt56ZcnCk6iwQkhed',
    createdAt: '2022-03-17T05:58:44.644Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171275.3,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'x8ggyd5XxvAQ6Lgpa',
    createdAt: '2022-03-17T05:58:44.660Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 244,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '5GMktFDjhMXo2LzBc',
    createdAt: '2022-03-17T05:58:44.679Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17502,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'ZNajJYXo3Z9ZpJ4Cw',
    createdAt: '2022-03-17T05:58:44.701Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17479.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'TTjzFfbLkxmjRZfQJ',
    createdAt: '2022-03-17T05:58:44.721Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'soFguW3x4GezTYcwk',
    createdAt: '2022-03-17T05:58:44.744Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4005,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'MvbF4j5XpLBkk94Dq',
    createdAt: '2022-03-17T07:07:04.374Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1123,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'wFiZipSYQjDLrkgcz',
    createdAt: '2022-03-17T07:07:04.407Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.40000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '4TNSk3Na7Gh4kqMP7',
    createdAt: '2022-03-17T07:07:04.437Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 151.97000000000025,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '8zCvw5Pepp9uuCAAn',
    createdAt: '2022-03-17T07:07:04.462Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 429.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'nxA4SzSzfFwBw7AaG',
    createdAt: '2022-03-17T07:07:04.488Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 156.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'fzKwPqGEm7E2Zisno',
    createdAt: '2022-03-17T07:07:04.515Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9317.52999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '76d5vTZeMWCbRs4KT',
    createdAt: '2022-03-17T07:07:04.538Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bvS26jf7d33ASTor7',
    createdAt: '2022-03-17T07:07:04.565Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 857.7,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'BcdnZGXZazndpYCG3',
    createdAt: '2022-03-17T07:07:04.594Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Hwen9HPBG8irizPos',
    createdAt: '2022-03-17T07:07:04.621Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'byF2XdifNcE5KpBPL',
    createdAt: '2022-03-17T07:07:04.649Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1068,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'ZTiK2sCgXuRhiju9z',
    createdAt: '2022-03-17T07:08:03.838Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1073,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ZmyL7Nc3myzy48RBC',
    createdAt: '2022-03-17T07:08:03.868Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.00000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'QZiQKX7c8Eq2fJPe9',
    createdAt: '2022-03-17T07:08:03.900Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 151.77000000000027,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'ZQF8aSvraJvjYGJde',
    createdAt: '2022-03-17T07:08:03.930Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 427.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'gomrgvPpshmYgtEhF',
    createdAt: '2022-03-17T07:08:03.955Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 155.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ZWpT9ZvvqTneRp4MA',
    createdAt: '2022-03-17T07:08:03.983Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9316.02999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'CkpWbYC2QTELc2TQv',
    createdAt: '2022-03-17T07:08:04.012Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kooo5NMHaDYQuxZbC',
    createdAt: '2022-03-17T07:08:04.046Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 848.7,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '2niJpvs44bCK3iNAs',
    createdAt: '2022-03-17T07:08:04.070Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'B4vrKG28PNqh9y5pF',
    createdAt: '2022-03-17T07:08:04.094Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mjNDxQofpJWnuG3aB',
    createdAt: '2022-03-17T07:08:04.125Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1059,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'D6n2y6jQwGZB5eXbR',
    createdAt: '2022-03-17T07:10:18.124Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1023,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'gByW6zMXSEC3pSXfq',
    createdAt: '2022-03-17T07:10:18.164Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.600000000000044,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'dQj2z3Csc5kYCWELL',
    createdAt: '2022-03-17T07:10:18.187Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 151.57000000000028,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'PaMxEKHpy3jraqNKs',
    createdAt: '2022-03-17T07:10:18.214Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 425.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'zq79jfMghbCH3pTwN',
    createdAt: '2022-03-17T07:10:18.235Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 155.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '6SJSjz9juZQwcyavb',
    createdAt: '2022-03-17T07:10:18.257Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9315.42999999999,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ZttEkPBpCWRXPiY8K',
    createdAt: '2022-03-17T07:10:18.280Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'p7gCKQqAP6BXQGEfy',
    createdAt: '2022-03-17T07:10:18.307Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 839.7,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '88X7QMT7Y9zsfqgXe',
    createdAt: '2022-03-17T07:10:18.330Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pnQNSfNAH2h82HuYf',
    createdAt: '2022-03-17T07:10:18.358Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CLZjjtAy5tk67awKu',
    createdAt: '2022-03-17T07:10:18.389Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1050,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'msqPs24LZ5EQekBPE',
    createdAt: '2022-03-17T07:27:14.945Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 2390,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '8pkYkXTRAbFeqvwLg',
    createdAt: '2022-03-17T07:27:15.126Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9314.42999999999,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'ecnWMTko6bcauXM8c',
    createdAt: '2022-03-17T07:27:15.166Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 170875.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'ifAuWHgHBERumY2NF',
    createdAt: '2022-03-17T07:27:15.191Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17469.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KfkFQQ3nyEmLd7fEC',
    createdAt: '2022-03-17T07:27:15.261Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 88,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'DL6PqdiKNTDP4ugSe',
    createdAt: '2022-03-17T07:28:53.818Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11646,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'YvaWL6efLy9k3mHkH',
    createdAt: '2022-03-17T07:28:54.141Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 72.3,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'GWhztBprPW5WHxmp5',
    createdAt: '2022-03-17T07:28:54.217Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 170375.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'Z25FHEce5Ms2XHqQs',
    createdAt: '2022-03-17T07:28:54.257Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1183.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'kB28ETxfhZCqn2HzC',
    createdAt: '2022-03-17T07:28:56.472Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9214.42999999999,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'L6jkrq776hzKSidqN',
    createdAt: '2022-03-17T07:28:56.662Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 588,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'XsRGid2trz3F9fvLC',
    createdAt: '2022-03-17T07:28:56.715Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4961,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'QrK5vANJtR3n7CQhn',
    createdAt: '2022-03-17T07:34:59.144Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2967.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'K4xz27nf7xxPuFdjW',
    createdAt: '2022-03-17T07:34:59.165Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2585,
    },
    transactionType: 'transferOut',
    amount: -2500,
  },
  {
    _id: 'iMd8BfB9xe6Cb38po',
    createdAt: '2022-03-17T07:34:59.186Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 70.3,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'L3XpMqh86rTYZ2C2D',
    createdAt: '2022-03-17T07:34:59.206Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 169375.3,
    },
    transactionType: 'transferOut',
    amount: -1250,
  },
  {
    _id: 'Zs2L6WtMPvkrTF8Q7',
    createdAt: '2022-03-17T07:34:59.224Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1057.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'YM8ueyukNoF2JaGn5',
    createdAt: '2022-03-17T07:34:59.266Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 973,
    },
    transactionType: 'in',
    amount: 200,
  },
  {
    _id: 'HczpRJHbEyMJgpm9Y',
    createdAt: '2022-03-17T09:34:22.338Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9646,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'eBDQ9ipHJSeGcXJYG',
    createdAt: '2022-03-17T09:34:22.360Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2808.7000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9KoMtiiCnQgrocA3F',
    createdAt: '2022-03-17T09:34:22.379Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 907.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'pvYPR3Zd9Y9cmaNrn',
    createdAt: '2022-03-17T09:34:22.398Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17472,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'L36hLRzSKpoiHfSgi',
    createdAt: '2022-03-17T09:34:22.419Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9114.42999999999,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'Nfw5CZkucs3iz2yov',
    createdAt: '2022-03-17T09:34:22.444Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21724.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '2sG3LXfjCBT57RJ7j',
    createdAt: '2022-03-17T09:34:22.463Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 490,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9qm7etk8PtSgLvRnP',
    createdAt: '2022-03-17T09:34:22.481Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1980,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7jkxCx5ctH2GNPAt8',
    createdAt: '2022-03-17T10:58:04.802Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2808.7000000000007,
    },
  },
  {
    _id: '627zMDyC6wA77cSxe',
    createdAt: '2022-03-17T10:59:03.020Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9103.42999999999,
    },
  },
  {
    _id: '8AiWdgw6kRmvqjYPv',
    createdAt: '2022-03-17T10:59:30.545Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1041,
    },
  },
  {
    _id: 'iFBsc6MDax8aMozy2',
    createdAt: '2022-03-17T11:00:22.661Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1019,
    },
  },
  {
    _id: 'aNCTDkM9gTEWHW7Yr',
    createdAt: '2022-03-17T11:04:35.417Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8846,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'EBie7x7fBZzRr9wDu',
    createdAt: '2022-03-17T11:04:35.439Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2908.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'hfmfWJEY5JLmNTn3i',
    createdAt: '2022-03-17T11:04:35.468Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 862.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'kAehhjAbhftJHc3fR',
    createdAt: '2022-03-17T11:04:35.488Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17447,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'YBLBBRxiwhf9Npdzz',
    createdAt: '2022-03-17T11:04:35.508Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 675,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'fbP92iFTsfdb25nwD',
    createdAt: '2022-03-17T11:04:35.527Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3948,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'QixmsiWXJkBSguP6v',
    createdAt: '2022-03-17T11:05:30.591Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2967.980000000006,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'A4PRcaNLMwhHYd3Kn',
    createdAt: '2022-03-17T11:05:30.613Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'K2tLRgGbg6Nfcmqvi',
    createdAt: '2022-03-17T11:05:30.635Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8844,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 't5x5LvY7zfgGMCab2',
    createdAt: '2022-03-17T11:05:30.654Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2258.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'ktu4EjPiXjFL2YaWK',
    createdAt: '2022-03-17T11:05:30.684Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 102,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'J3f2e35tNRpnwhvMJ',
    createdAt: '2022-03-17T11:05:30.704Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17469.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'EvM6KavHdcqxebDnn',
    createdAt: '2022-03-17T11:05:30.725Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9hHK4Nf86To4uLqkX',
    createdAt: '2022-03-17T11:05:30.744Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 25.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'rkEPHCuvZDeDrBp2s',
    createdAt: '2022-03-17T11:05:30.767Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 55.95,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'WQqK2t6sTBZsBXtnr',
    createdAt: '2022-03-17T11:05:30.785Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gd6k5RBu7CjeKuxiW',
    createdAt: '2022-03-17T11:05:30.803Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9478.42999999999,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'Gxyo8eWFRqvfKetmw',
    createdAt: '2022-03-17T11:05:30.825Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4939,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'LmYaWaNeKS4ZCSsWY',
    createdAt: '2022-03-17T11:15:14.535Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8844,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'HqmKGM4FfpZ9nnkCf',
    createdAt: '2022-03-17T11:15:14.567Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2127.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'DcYydbz8boTxHuK73',
    createdAt: '2022-03-17T11:15:14.588Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 502,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'KrSJF2GJfvixBzFyb',
    createdAt: '2022-03-17T11:15:14.608Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 423.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '6HWbiqzbe9eLnycs8',
    createdAt: '2022-03-17T11:15:14.628Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1912,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'Rwtt5qqbRxKFR7qJv',
    createdAt: '2022-03-17T11:15:14.651Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1071,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'JeA7anRFhHNQLADee',
    createdAt: '2022-03-17T11:24:56.826Z',
    transactionType: 'in',
    amount: 5100,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8694,
    },
  },
  {
    _id: 'X48QAEdR6CoaRL88t',
    createdAt: '2022-03-17T11:25:23.367Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'Dgum8gWvyhXfWdmrZ',
    createdAt: '2022-03-17T11:41:19.162Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'Pa7C9av5TY8FTdri5',
    createdAt: '2022-03-17T11:41:19.182Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8553,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'wRjmybEpKjX7kHrqo',
    createdAt: '2022-03-17T11:41:19.201Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4345,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '7RYWPjnhJntpNwYjb',
    createdAt: '2022-03-17T11:41:19.218Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1079,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '6sA9GyMzHXdDve73F',
    createdAt: '2022-03-17T11:42:20.212Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'RBHNgKHJKodcTn3Pk',
    createdAt: '2022-03-17T11:42:20.233Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8421,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'wG3BFNcBDo6CbEjeQ',
    createdAt: '2022-03-17T11:42:20.251Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1068,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'a2KmHYZEi5bpzhXgw',
    createdAt: '2022-03-17T11:57:33.433Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13796,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: '44ubT3BserphLE4D3',
    createdAt: '2022-03-17T11:57:33.462Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2902.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'kdqW5JyoNJkF3iJHW',
    createdAt: '2022-03-17T11:57:33.486Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 862.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'ZQDDTBDN79dBpkyK2',
    createdAt: '2022-03-17T11:57:33.509Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17447,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'bBbouj8frccTH8RmP',
    createdAt: '2022-03-17T11:57:33.539Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 675,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'LbK539CediFd5je5z',
    createdAt: '2022-03-17T11:57:33.571Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3948,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 's7wvACN3G74aGhdTs',
    createdAt: '2022-03-17T12:49:55.732Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2882.7000000000007,
    },
  },
  {
    _id: 'r8KaXYPaHWztQzqLG',
    createdAt: '2022-03-17T12:50:21.773Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3903,
    },
  },
  {
    _id: 'DoLwZmXnXw7SyQS4p',
    createdAt: '2022-03-17T12:50:44.409Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3720,
    },
  },
  {
    _id: 'de7djtYXT2Zv7oMcn',
    createdAt: '2022-03-17T12:51:17.082Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 98,
    },
  },
  {
    _id: 'im9JqAuHoF24nuni6',
    createdAt: '2022-03-17T12:51:40.550Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 830.7,
    },
  },
  {
    _id: '4LwAyFXD3zkSHpHXs',
    createdAt: '2022-03-17T12:52:10.066Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4863,
    },
  },
  {
    _id: 'xMxkBkfZg5qGa6Sa6',
    createdAt: '2022-03-17T12:52:33.783Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 154.8,
    },
  },
  {
    _id: 'hwk8X2T4gN65aGDhz',
    createdAt: '2022-03-17T12:57:42.906Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 550,
    },
  },
  {
    _id: 'XejtwyqFrAwRnioWR',
    createdAt: '2022-03-17T12:58:26.035Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 419.9200000000002,
    },
  },
  {
    _id: 'yWKDncCCrcH2Msg8R',
    createdAt: '2022-03-18T04:52:01.355Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1727.980000000006,
    },
    transactionType: 'transferOut',
    amount: -340,
  },
  {
    _id: 'aNH5T9AofArinPsRW',
    createdAt: '2022-03-18T04:52:01.491Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nouGD3pg3G5mbNb9C',
    createdAt: '2022-03-18T04:52:01.600Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13146,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bGsq2dpZk8FB7QYLa',
    createdAt: '2022-03-18T04:52:01.710Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2932.7000000000007,
    },
    transactionType: 'transferOut',
    amount: -9.6,
  },
  {
    _id: 'KbcdbfkMSFT64JQ5C',
    createdAt: '2022-03-18T04:52:01.824Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 822.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '6i4vsNafGBzke4vcm',
    createdAt: '2022-03-18T04:52:01.931Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168125.3,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'txPxeBCXwJkNpXb2w',
    createdAt: '2022-03-18T04:52:02.041Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17422,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'hTt478SpsPTBX7CMx',
    createdAt: '2022-03-18T04:52:02.150Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 224,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '9KhLuTDQBFzw4hYZf',
    createdAt: '2022-03-18T04:52:02.264Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 550,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'JjFXPMuGyCmRsSwmx',
    createdAt: '2022-03-18T04:52:02.381Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4003,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'NAdz3DRYWhF35T2G7',
    createdAt: '2022-03-18T04:55:32.612Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13146,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4kuEN7HzSSryzy4yE',
    createdAt: '2022-03-18T04:55:32.749Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1387.980000000006,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: '6w7NdNdpmRdq5W2WM',
    createdAt: '2022-03-18T04:55:32.864Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bDSxioqyaH8wSYW6H',
    createdAt: '2022-03-18T04:55:32.979Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2923.100000000001,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'Zck3HcnffQ4TsiizF',
    createdAt: '2022-03-18T04:55:33.101Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 803.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'obFsS3hhLkKnbhYdx',
    createdAt: '2022-03-18T04:55:33.210Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167965.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'hxP3qa9gEBahTRkC8',
    createdAt: '2022-03-18T04:55:33.321Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 217,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7Mv5eKKpbEwRaZsfR',
    createdAt: '2022-03-18T04:55:33.437Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17410,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'mft7TLketZWq3W9Fv',
    createdAt: '2022-03-18T04:55:33.556Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17457.9,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'oyz7i9j8Z5HGsH8wb',
    createdAt: '2022-03-18T04:55:33.668Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 490,
    },
    transactionType: 'transferOut',
    amount: -96,
  },
  {
    _id: '6WF7foMxSgRP3SRRt',
    createdAt: '2022-03-18T04:55:33.830Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3979,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'v8K9xvQSJJZzaEPGm',
    createdAt: '2022-03-18T04:59:18.289Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13146,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WLyEiNx2rpj4PfNcx',
    createdAt: '2022-03-18T04:59:18.425Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dAo2JEWDiqfGtku2c',
    createdAt: '2022-03-18T04:59:18.559Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 787.9800000000059,
    },
    transactionType: 'transferOut',
    amount: -260,
  },
  {
    _id: 'wDNMq5vFaxCTcp4ou',
    createdAt: '2022-03-18T04:59:18.668Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2905.100000000001,
    },
    transactionType: 'transferOut',
    amount: -7.3,
  },
  {
    _id: 'QsPssJMXqYePKTtwD',
    createdAt: '2022-03-18T04:59:18.774Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 205,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'LpAiazwqiWB6HDbJZ',
    createdAt: '2022-03-18T04:59:18.884Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17386,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'RWu2WAzgqYQunk9wy',
    createdAt: '2022-03-18T04:59:18.991Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167665.3,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'BL3y8y8TDNAYKzWLJ',
    createdAt: '2022-03-18T04:59:19.097Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 767.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'HYKg2XEt8e3kTmM7A',
    createdAt: '2022-03-18T04:59:19.206Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3937,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'MYFPCYrPe36sX4bEx',
    createdAt: '2022-03-18T05:19:51.652Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 457,
    },
  },
  {
    _id: 'Kfe9jHGdpzJzTACr8',
    createdAt: '2022-03-18T07:09:33.967Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 527.9800000000059,
    },
  },
  {
    _id: 'MZ8Mx3wZqAx4T7a2p',
    createdAt: '2022-03-18T07:15:51.606Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13146,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qT4QSRYZtoo4xTQCz',
    createdAt: '2022-03-18T07:15:51.733Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1527.980000000006,
    },
    transactionType: 'transferOut',
    amount: -580,
  },
  {
    _id: 'mg3oTEiexvNWtpjwt',
    createdAt: '2022-03-18T07:15:51.839Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jgLwTi2WnbK7kRtsf',
    createdAt: '2022-03-18T07:15:51.950Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2897.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'gmhtHpsNAYYKWcTRu',
    createdAt: '2022-03-18T07:15:52.058Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 752.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'cErdYqRnczx9LCAb8',
    createdAt: '2022-03-18T07:15:52.163Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167545.3,
    },
    transactionType: 'transferOut',
    amount: -280,
  },
  {
    _id: '6S22DwdzijMrG8Hjm',
    createdAt: '2022-03-18T07:15:52.272Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'McWM24qatWTKEziRF',
    createdAt: '2022-03-18T07:15:52.408Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17376,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'JB67L3YbAZQYZuQ5t',
    createdAt: '2022-03-18T07:15:52.556Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17437.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'hG97hqAwxH3YQSYCJ',
    createdAt: '2022-03-18T07:15:52.665Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 394,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'x8GReY3jRcsNaStb7',
    createdAt: '2022-03-18T07:15:52.783Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3919,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'z2KKuvqLpCcEcTMB5',
    createdAt: '2022-03-18T07:17:17.366Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13146,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'G5qLQm87wdfmXaYrF',
    createdAt: '2022-03-18T07:17:17.504Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 67.8,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ifoNYd5cyssZsGdoY',
    createdAt: '2022-03-18T07:17:17.615Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167265.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'o23hqh8Ejvmns7sg5',
    createdAt: '2022-03-18T07:17:17.723Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 717.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'rfcFSptZjdRWRdpHS',
    createdAt: '2022-03-18T07:17:17.829Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9477.829999999989,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'NeaTpZgs4EWQZSM2z',
    createdAt: '2022-03-18T07:17:17.945Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 338,
    },
    transactionType: 'transferOut',
    amount: -338,
  },
  {
    _id: 'ZbDwJiL33RhPgwLYg',
    createdAt: '2022-03-18T07:17:18.058Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4983,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'AmrmqyHhJDGyyJQmA',
    createdAt: '2022-03-18T10:06:49.504Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 947.9800000000059,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: '95PqdK7c9vHzniMTJ',
    createdAt: '2022-03-18T10:06:49.644Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'asDYT9iM4r7HZAoeA',
    createdAt: '2022-03-18T10:06:49.757Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10146,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QBipMphRp4Zs3CHhA',
    createdAt: '2022-03-18T10:06:49.873Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2880.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BbyfxtovxYfTmove5',
    createdAt: '2022-03-18T10:06:49.986Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DxST4grgve48Qf8EF',
    createdAt: '2022-03-18T10:06:50.104Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17425.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'J5KNH3rpAgH8kNyb2',
    createdAt: '2022-03-18T10:06:50.225Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FJB5JdkDtZkoybhXi',
    createdAt: '2022-03-18T10:06:50.335Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 54.95,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'YXcwK8zvihu6dNDtx',
    createdAt: '2022-03-18T10:06:50.471Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 20.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '835CT8FXEafMPS3rY',
    createdAt: '2022-03-18T10:06:50.583Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 469.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'jSy43R3HcmroKc9Lr',
    createdAt: '2022-03-18T10:06:50.695Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9327.829999999989,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'WJZvvFqNRMjMATZAG',
    createdAt: '2022-03-18T10:06:50.807Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4923,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'PJcDzAY6ZvxZ5vkYS',
    createdAt: '2022-03-18T10:12:04.291Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'e94dMeghwmphyNZBt',
    createdAt: '2022-03-18T10:12:04.418Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 890.7,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: 'DpEzKYkqhxHf5QSoD',
    createdAt: '2022-03-18T10:12:04.553Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 151.3700000000003,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'bNQ2pg536YDzHzZ7T',
    createdAt: '2022-03-18T10:12:04.679Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2880.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'qEgF75CF6x75CQk3P',
    createdAt: '2022-03-18T10:12:04.930Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 140,
  },
  {
    _id: 'kfjNLYKruoR5jtS5M',
    createdAt: '2022-03-18T10:56:57.731Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 217,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'CAc7Z3MHdtJHhFjmC',
    createdAt: '2022-03-18T10:56:57.865Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4343,
    },
    transactionType: 'transferOut',
    amount: -49,
  },
  {
    _id: 'eQ9Kg97jkujYcbido',
    createdAt: '2022-03-18T10:56:57.979Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1062,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '2ehMMGAQzShQj9PCZ',
    createdAt: '2022-03-18T10:57:44.462Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 193,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '5pMJpYRc8szcePyTp',
    createdAt: '2022-03-18T10:57:45.243Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4294,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'x2hPfWTTgfZKSBFnM',
    createdAt: '2022-03-18T10:57:45.379Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1058,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ZA4oMLpNrwBEHSCyY',
    createdAt: '2022-03-18T11:03:49.487Z',
    transactionType: 'in',
    amount: 3600,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10146,
    },
  },
  {
    _id: 'ekHx2n4cg99WbgPE2',
    createdAt: '2022-03-18T11:04:22.597Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 66,
    },
  },
  {
    _id: 'CbXu7njszEwWKLiJ2',
    createdAt: '2022-03-18T11:14:32.591Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 416,
    },
    transactionType: 'transferOut',
    amount: -117,
  },
  {
    _id: 'KtroK7nyXvPzN5HgZ',
    createdAt: '2022-03-18T11:14:32.725Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 527.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'u4R3ZXReui6W2NwQi',
    createdAt: '2022-03-18T11:14:32.834Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 490,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'EBtwQtcn9cTTWaMZg',
    createdAt: '2022-03-18T11:14:32.949Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21712.9,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'BnFfL6vWK5jGHRm9A',
    createdAt: '2022-03-18T11:14:33.062Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 186,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'FF9S8S4G8sRpQrkWK',
    createdAt: '2022-03-18T11:17:09.778Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 299,
    },
    transactionType: 'transferOut',
    amount: -299,
  },
  {
    _id: 'vFBXJJeZR3tJCevPq',
    createdAt: '2022-03-18T11:17:10.687Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 7.800000000000001,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'qeeE3Yhavgwqokgjq',
    createdAt: '2022-03-18T11:17:11.020Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 175,
    },
    transactionType: 'in',
    amount: 299,
  },
  {
    _id: 'pxrD39FezWNcmYetH',
    createdAt: '2022-03-18T11:20:06.267Z',
    transactionType: 'in',
    amount: 150,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 117.98000000000593,
    },
  },
  {
    _id: 'ZcRAnCvixJypzzfqb',
    createdAt: '2022-03-18T11:20:21.117Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13746,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '3MRSRymMLmNT6faLT',
    createdAt: '2022-03-18T11:20:21.261Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 267.98000000000593,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'fvofaXeNEzbxMJ2aB',
    createdAt: '2022-03-18T11:20:21.376Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 507,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '7kPp7y9MRwTq6doev',
    createdAt: '2022-03-18T11:20:21.494Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 461.9200000000002,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'FmJLHMsERPwMG9sKe',
    createdAt: '2022-03-18T11:20:21.613Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1730,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'QbgXfKiBpjy8dvX94',
    createdAt: '2022-03-18T11:20:21.751Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1062,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'CgcMgqSnLYwhh3pqs',
    createdAt: '2022-03-18T11:20:26.555Z',
    transactionType: 'transferIn',
    amount: 3104,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 17.98000000000593,
    },
  },
  {
    _id: 'qes2ZAWRfsW3JKKbr',
    createdAt: '2022-03-18T11:22:11.115Z',
    transactionType: 'transferIn',
    amount: 2022,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
  },
  {
    _id: 'bioGLJj79Sb43kKYd',
    createdAt: '2022-03-18T11:22:39.264Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 474,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'y5rtKHCfEZHHmxcMC',
    createdAt: '2022-03-18T11:22:39.405Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8348,
    },
    transactionType: 'transferOut',
    amount: -133,
  },
  {
    _id: 'rssg4AZh9WjGqcz9X',
    createdAt: '2022-03-18T11:22:39.543Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1055,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '3HdKxstiSZ8SA2fNL',
    createdAt: '2022-03-18T11:23:35.271Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 342,
    },
    transactionType: 'transferOut',
    amount: -236,
  },
  {
    _id: 'DLs2MkJz8BHRogDnD',
    createdAt: '2022-03-18T11:23:35.402Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8215,
    },
    transactionType: 'transferOut',
    amount: -238,
  },
  {
    _id: '55pN6p3sSHMQGN68p',
    createdAt: '2022-03-18T11:23:35.525Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1044,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'AhASvxjGY8wLBBhkv',
    createdAt: '2022-03-18T11:33:17.298Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9327.229999999989,
    },
  },
  {
    _id: 't9hwGFywKuaEc255p',
    createdAt: '2022-03-18T11:33:42.089Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2874.8000000000006,
    },
  },
  {
    _id: 'Ry5WW3NxS3rjrdYQ9',
    createdAt: '2022-03-18T11:34:09.103Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3876,
    },
  },
  {
    _id: 'BvSEcs7PRnTyD6n6y',
    createdAt: '2022-03-18T11:34:46.420Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 64.8,
    },
  },
  {
    _id: 'dKjPDfJQ3rkZrDfJA',
    createdAt: '2022-03-18T11:35:11.820Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 856.7,
    },
  },
  {
    _id: 'KGv2YuEifX6wakQqD',
    createdAt: '2022-03-18T11:35:41.383Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 185,
    },
  },
  {
    _id: '3abQE7TXDSjPibhHo',
    createdAt: '2022-03-18T11:36:52.264Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 7977,
    },
  },
  {
    _id: '6An2CjzyS9hgBAPxw',
    createdAt: '2022-03-18T11:37:29.099Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 807,
    },
  },
  {
    _id: 'cJj6XsTgo9Lq4og7k',
    createdAt: '2022-03-18T11:52:55.200Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3121.980000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'oMDHZDKfb5Yiqkzko',
    createdAt: '2022-03-18T11:52:55.346Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13696,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kpPLuLjaRhN5GKp7G',
    createdAt: '2022-03-18T11:52:55.486Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2999.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'TH2tudyDW9DdmK7hS',
    createdAt: '2022-03-18T11:52:55.627Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 155,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'gHLJcQQatJTFv4g6N',
    createdAt: '2022-03-18T11:52:55.769Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9577.229999999989,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '6NKMXZknp76krtBr7',
    createdAt: '2022-03-18T11:52:55.886Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165765.3,
    },
    transactionType: 'transferOut',
    amount: -4600,
  },
  {
    _id: 'tRo4sD7DrqbZdprr3',
    createdAt: '2022-03-18T11:52:56.032Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 487.5,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'Y5p4Rq3nfHYsgjiLJ',
    createdAt: '2022-03-18T11:52:56.152Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1968,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'vwmPX27XuQ9s6i9T2',
    createdAt: '2022-03-18T11:52:56.271Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1056,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'F6qNNiyWaSBThrPKQ',
    createdAt: '2022-03-18T12:57:32.160Z',
    transactionType: 'transferIn',
    amount: 160,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4846,
    },
  },
  {
    _id: '477hzE5NFvTG2NTTR',
    createdAt: '2022-03-18T12:58:01.157Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1608,
    },
  },
  {
    _id: '4LBfYZoRS46z53bhY',
    createdAt: '2022-03-18T12:58:42.111Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 482,
    },
  },
  {
    _id: 'KKbZnFHrpC7jtxXJD',
    createdAt: '2022-03-18T12:59:15.194Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1026,
    },
  },
  {
    _id: '6oh7brbcJWga8cEfR',
    createdAt: '2022-03-18T13:39:53.801Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2107,
    },
  },
  {
    _id: 'fApwCnTPu6xKSZbxL',
    createdAt: '2022-03-18T13:40:47.745Z',
    transactionType: 'transferIn',
    amount: 2410,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2621.980000000006,
    },
  },
  {
    _id: 'qbww6jHHdanWSRtPr',
    createdAt: '2022-03-18T14:04:36.462Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5031.980000000006,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'AHJHL3kWqAP5h7HN2',
    createdAt: '2022-03-18T14:04:36.590Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4107,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tSHi9suD2Kyu6vjr6',
    createdAt: '2022-03-18T14:04:36.694Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13696,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LPkCkALXWs8KdNNkC',
    createdAt: '2022-03-18T14:04:36.799Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2899.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'kL7SLPQKLeiHXDqxa',
    createdAt: '2022-03-18T14:04:36.905Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 521.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'atDvtRuvwYzgJcjqA',
    createdAt: '2022-03-18T14:04:37.009Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 161165.3,
    },
    transactionType: 'transferOut',
    amount: -325,
  },
  {
    _id: 'jCnfj4SP2t9ZMeA6H',
    createdAt: '2022-03-18T14:04:37.118Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17352,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'hyndkn7REhbMcEhyv',
    createdAt: '2022-03-18T14:04:37.224Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 188,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'nMZc853qvmeoS5bxJ',
    createdAt: '2022-03-18T14:04:37.337Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 284,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'iDnuJ6HFuZEjx392L',
    createdAt: '2022-03-18T14:04:37.446Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4176,
    },
    transactionType: 'transferOut',
    amount: -49,
  },
  {
    _id: 'SMc2suyGPCyZktPKw',
    createdAt: '2022-03-18T14:05:28.568Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13696,
    },
    transactionType: 'transferOut',
    amount: -690,
  },
  {
    _id: 'iR3hi3a5qAvABRFcu',
    createdAt: '2022-03-18T14:05:28.701Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2879.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'C43fkuGYYWt36kKCL',
    createdAt: '2022-03-18T14:05:28.837Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 486.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '9jvqaCpBBfZqwimkD',
    createdAt: '2022-03-18T14:05:29.008Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17328,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'PLCBxxKQ2yT36EQhW',
    createdAt: '2022-03-18T14:05:29.138Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 174,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'vzMBu8F6Tajeh8grQ',
    createdAt: '2022-03-18T14:05:29.270Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4127,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: '36xztQd9z6x4vCe3H',
    createdAt: '2022-03-18T14:06:38.575Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 571,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'Nt7BqK6HMHXJGQqQ3',
    createdAt: '2022-03-18T14:06:38.740Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21710.4,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'eprXQSFEiFwCRnZ3T',
    createdAt: '2022-03-18T14:06:38.848Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 453.9,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'cxufp794ob6Acd4fR',
    createdAt: '2022-03-18T14:06:38.956Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 215,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'epuAT9X4DTnWygRpP',
    createdAt: '2022-03-18T14:10:42.919Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 562,
    },
    transactionType: 'transferOut',
    amount: -8.5,
  },
  {
    _id: 'epQo2Q3CTESwJYqjT',
    createdAt: '2022-03-18T14:10:43.085Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21707.9,
    },
    transactionType: 'transferOut',
    amount: -3.5,
  },
  {
    _id: 'metn6YEG23Q4kE75T',
    createdAt: '2022-03-18T14:10:43.194Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 451.4,
    },
    transactionType: 'transferOut',
    amount: -3.5,
  },
  {
    _id: 'x3uiAYuhAE6c9GEyX',
    createdAt: '2022-03-19T04:48:39.923Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4381.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'azBEynbPFeStjgeRd',
    createdAt: '2022-03-19T04:48:40.056Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tv7gNg3utCxwCxfj8',
    createdAt: '2022-03-19T04:48:40.188Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4107,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SnwW8ZjywBvGQxRWP',
    createdAt: '2022-03-19T04:48:40.319Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2859.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Wvc9YuXpdup5Lc534',
    createdAt: '2022-03-19T04:48:40.454Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9571.229999999989,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'kMcBGRBaRwof4eYZ4',
    createdAt: '2022-03-19T04:48:40.560Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17413.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'qvvK9xNiX4RmTjWPW',
    createdAt: '2022-03-19T04:48:40.671Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3820,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'QWoPPTAB5TNZapdBp',
    createdAt: '2022-03-19T04:50:42.403Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3981.980000000006,
    },
    transactionType: 'transferOut',
    amount: -336,
  },
  {
    _id: 'Zvm9tBvjYDbTpQfvE',
    createdAt: '2022-03-19T04:50:42.533Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4107,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Z2wsPffMLshferJhK',
    createdAt: '2022-03-19T04:50:42.663Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'urdR7F8ep8Z4gxFE5',
    createdAt: '2022-03-19T04:50:42.771Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 446.60000000000036,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cHwEQo2dhjYkr4vut',
    createdAt: '2022-03-19T04:50:42.897Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 19.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'SdnsvqrZpwWL2dkaQ',
    createdAt: '2022-03-19T04:50:43.002Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9570.629999999988,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'xPFTHn6EnR8WYGZWq',
    createdAt: '2022-03-19T04:50:43.132Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17403.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'sFYTkAxrFcTrQGHYz',
    createdAt: '2022-03-19T04:50:43.238Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3794,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'fNnrhgdeQ86GjkrKp',
    createdAt: '2022-03-19T04:56:53.201Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NRHwaJiob5PKNnNvj',
    createdAt: '2022-03-19T04:56:53.340Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3645.980000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'mb8JEt6eEGoG5iv3K',
    createdAt: '2022-03-19T04:56:53.447Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4107,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9E3uonozZq9Ex4fk4',
    createdAt: '2022-03-19T04:56:53.556Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2859.8000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SRN9eiGBwnZbnEcoW',
    createdAt: '2022-03-19T04:56:53.665Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XeRxK29Anu4xxBKdq',
    createdAt: '2022-03-19T04:56:53.774Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 459.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'YsNKGxCMwqD2ypgQD',
    createdAt: '2022-03-19T04:56:53.883Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 53.95,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'KgzSdogufoL9CzQuw',
    createdAt: '2022-03-19T04:56:54.096Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17393.9,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'fxQYCAh7fTyznfEdL',
    createdAt: '2022-03-19T04:56:54.203Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3773,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'CrPSyEmZ4wdJKMcoc',
    createdAt: '2022-03-19T05:02:19.537Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1990,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'BchMegX8wH44Jwjic',
    createdAt: '2022-03-19T05:02:19.641Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9570.029999999988,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'cZahHu3pydpaPvrdF',
    createdAt: '2022-03-19T05:02:19.745Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160840.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'tDLHSoAWB3J25qEZ6',
    createdAt: '2022-03-19T05:02:19.851Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17386.9,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'sYJL6uWXKp5wzcp2z',
    createdAt: '2022-03-19T05:02:20.343Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 49,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'XqXn8qkZ7GuqipgKB',
    createdAt: '2022-03-19T05:04:06.305Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1124,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'dwmsLZN7ZGYZhfKWg',
    createdAt: '2022-03-19T05:04:06.460Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1173,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'fEBK577Gh8Sf3LvmA',
    createdAt: '2022-03-19T05:04:06.579Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 150.8700000000003,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'tpmLG9Yyemg63mDnq',
    createdAt: '2022-03-19T05:04:06.695Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.200000000000045,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'wcbky3vmrHXkXfqBw',
    createdAt: '2022-03-19T05:04:06.803Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 454.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'eHPgtkHybBcr9m6ND',
    createdAt: '2022-03-19T05:04:06.911Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 174.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Nc6E2Ey9TiBPksk9w',
    createdAt: '2022-03-19T05:04:07.019Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9530.029999999988,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '52zw7NyjQZ23irSzt',
    createdAt: '2022-03-19T05:04:07.129Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GJtdJARjbbaa7An4K',
    createdAt: '2022-03-19T05:04:07.239Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 916.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'xKbi3yTWFFpREeoP4',
    createdAt: '2022-03-19T05:04:07.349Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HP4Pi7uTJBpTBYJbw',
    createdAt: '2022-03-19T05:04:07.459Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qbAMeqF9K6LwhuX6n',
    createdAt: '2022-03-19T05:04:07.575Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1071,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'cM9jvXqWosumco3Wt',
    createdAt: '2022-03-19T05:04:50.307Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nHf4sHo7CNwSaLJxn',
    createdAt: '2022-03-19T05:04:50.439Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3145.980000000006,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'tjhKREYsg7frnKFHx',
    createdAt: '2022-03-19T05:04:50.549Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4107,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HXzqt39xpSuZ3FwSz',
    createdAt: '2022-03-19T05:04:50.660Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2859.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -10.8,
  },
  {
    _id: 'JNCfGRZPFj7SEzM5H',
    createdAt: '2022-03-19T05:04:50.772Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 446.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'CcYNZR4uHX4s9AMnw',
    createdAt: '2022-03-19T05:04:50.878Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 158240.3,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'SJwnGHqj3md2h2p9H',
    createdAt: '2022-03-19T05:04:50.984Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 173,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'DtARenGgmJDSAHYf2',
    createdAt: '2022-03-19T05:04:51.091Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17303,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'CRk45KqKAJGhTRWmD',
    createdAt: '2022-03-19T05:04:51.198Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17356.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'wdcA9wzJ3bk5a9xrP',
    createdAt: '2022-03-19T05:04:51.305Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 849,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'b8KcZmxW2ygmttt2j',
    createdAt: '2022-03-19T05:04:51.415Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4081,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: '3pLPMgNa3N2BQnzY3',
    createdAt: '2022-03-19T05:05:49.829Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 553.5,
    },
    transactionType: 'transferOut',
    amount: -29,
  },
  {
    _id: '9hjhujZLNaHo3JKLf',
    createdAt: '2022-03-19T05:05:49.989Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21704.4,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'gbCEmvTmwbF7q78QG',
    createdAt: '2022-03-19T05:05:50.096Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 447.9,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'aakJBewdoZhKD79Af',
    createdAt: '2022-03-19T05:05:50.203Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 214,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'r3Y3QTTQJHtudNNm4',
    createdAt: '2022-03-19T05:17:53.824Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2785.980000000006,
    },
    transactionType: 'transferOut',
    amount: -184,
  },
  {
    _id: '5JBwdwsfagD7r3GGm',
    createdAt: '2022-03-19T05:17:53.953Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fso5TdQCwnbsCYExt',
    createdAt: '2022-03-19T05:17:54.056Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2849.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Lzyb98T4bFzba7Q3f',
    createdAt: '2022-03-19T05:17:54.164Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XotZCH3nPdS2buTTQ',
    createdAt: '2022-03-19T05:17:54.277Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 9560,
    },
    transactionType: 'transferOut',
    amount: -1034,
  },
  {
    _id: 'b26QnMQWSSFdLDGr9',
    createdAt: '2022-03-19T05:17:54.485Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 53.45,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'YwLNWp3ePjdozJ8yH',
    createdAt: '2022-03-19T05:17:54.596Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 22810,
    },
    transactionType: 'transferOut',
    amount: -1034,
  },
  {
    _id: 'Ht8JpTuYadpAE4h2J',
    createdAt: '2022-03-19T05:17:54.703Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1233,
    },
    transactionType: 'transferOut',
    amount: -165,
  },
  {
    _id: 'w3NdDWdsknEzXPKTm',
    createdAt: '2022-03-19T08:27:36.331Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 1230,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'ezpnTqTxtdCxFtzvp',
    createdAt: '2022-03-19T08:27:36.437Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9529.029999999988,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'x9YzC2cmCFaCzXffs',
    createdAt: '2022-03-19T08:27:36.557Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 158060.3,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'djSg6mrWSYe7Awtw9',
    createdAt: '2022-03-19T08:27:36.665Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17351.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'joi6Y84SpmBrC82LW',
    createdAt: '2022-03-19T08:27:36.911Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 500,
  },
  {
    _id: 'JvvikrkxLKXDiLMwn',
    createdAt: '2022-03-19T08:29:21.410Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13006,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'LxdW6FswY7hwyurbD',
    createdAt: '2022-03-19T08:29:21.545Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 89.8,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'gGAknrPhvsNCcGN8d',
    createdAt: '2022-03-19T08:29:21.652Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 157810.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: '7DwMgNfyxHzK3DXxG',
    createdAt: '2022-03-19T08:29:21.760Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 428.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'Aj6imCcockLSHRPmB',
    createdAt: '2022-03-19T08:29:21.874Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9429.029999999988,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '4kadADdY6N43oJxMY',
    createdAt: '2022-03-19T08:29:21.989Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'PA2SEwSpC7pYcDrjG',
    createdAt: '2022-03-19T08:29:22.146Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5006,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: '9bPB5B9mAibhFrmxm',
    createdAt: '2022-03-19T08:31:22.569Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 830,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'CnYww66tpBBuWkZQX',
    createdAt: '2022-03-19T08:31:22.684Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9279.029999999988,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'yWKcPCiKFgFJi6Zf4',
    createdAt: '2022-03-19T08:31:22.801Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156310.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'Bo82eS2dRAdcKnHRW',
    createdAt: '2022-03-19T08:31:22.911Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17351.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rr8vk7cnKnP9SpZDv',
    createdAt: '2022-03-19T08:31:23.158Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 100,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'LfpqhZAPrwNgHeDgG',
    createdAt: '2022-03-19T08:32:15.971Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10006,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'hAqcaNNdL9MuiZtvt',
    createdAt: '2022-03-19T08:32:16.106Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 86.8,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'FeYKmLBcZyoebdPqW',
    createdAt: '2022-03-19T08:32:16.218Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155810.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'oaQZC9MhfvcfNqdi2',
    createdAt: '2022-03-19T08:32:16.328Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 228.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '8Buf38EYPkjyd3qxh',
    createdAt: '2022-03-19T08:32:16.439Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9079.029999999988,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '3CHSAPkyw4Mc7Mkqd',
    createdAt: '2022-03-19T08:32:16.555Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '68N9HpuJJpQpDQ4Qa',
    createdAt: '2022-03-19T08:32:16.665Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4943,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: 'ZSyNJCtvwLxHTThnE',
    createdAt: '2022-03-19T10:59:37.655Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28.600000000000364,
    },
  },
  {
    _id: '4i3T5LhG2zm2syxn6',
    createdAt: '2022-03-19T11:11:14.275Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2601.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5zaQAXzWdBz87Huyj',
    createdAt: '2022-03-19T11:11:14.411Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4107,
    },
    transactionType: 'transferOut',
    amount: -4100,
  },
  {
    _id: 'QP3cP2HMikCLeXDCX',
    createdAt: '2022-03-19T11:11:14.547Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 83.8,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '3SQZnxfEzmoWuuAKe',
    createdAt: '2022-03-19T11:11:14.685Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 154310.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'qf4mjEFRDG9ZJ3YJD',
    createdAt: '2022-03-19T11:11:14.826Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1028.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 't3yBQs3oujwKjARj4',
    createdAt: '2022-03-19T11:11:15.080Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1133,
    },
    transactionType: 'in',
    amount: 300,
  },
  {
    _id: 'viFBCFHLPXcJhiWoQ',
    createdAt: '2022-03-19T11:21:32.363Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1104,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'kexnmfZiLHQkRvsB3',
    createdAt: '2022-03-19T11:21:32.546Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1433,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'c45AytpPy7nD57h2X',
    createdAt: '2022-03-19T11:21:32.684Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 150.6700000000003,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'GgXyCbs5BSJPRw7Ls',
    createdAt: '2022-03-19T11:21:32.808Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 55.80000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '7wJZye6HBtX5Ng4r7',
    createdAt: '2022-03-19T11:21:32.933Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'D6oFzqpQZJEzsnQmY',
    createdAt: '2022-03-19T11:21:33.064Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 174.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'e8HMZ8s42oJawdqbY',
    createdAt: '2022-03-19T11:21:33.193Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8929.029999999988,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '7K8B8GQsruLTBzLuP',
    createdAt: '2022-03-19T11:21:33.318Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cdsgmFbA3WSLki5Xn',
    createdAt: '2022-03-19T11:21:33.443Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 905.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'KErCtpECxgEiqH3ah',
    createdAt: '2022-03-19T11:21:33.573Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Cn5gbq5YZ9qeQSgkx',
    createdAt: '2022-03-19T11:21:33.698Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QTebX7F4iyAgCEwtP',
    createdAt: '2022-03-19T11:21:33.841Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1063,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'mTnx9wKtRSnCja7H7',
    createdAt: '2022-03-19T11:24:51.033Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8617,
    },
  },
  {
    _id: 'REwD36WL96JBfcMFn',
    createdAt: '2022-03-19T11:25:13.350Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4257,
    },
  },
  {
    _id: 'aQANmhrAsu6h5BJST',
    createdAt: '2022-03-19T11:25:32.848Z',
    transactionType: 'transferIn',
    amount: 4,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 26,
    },
  },
  {
    _id: '5DJWu457h2aFP7YeP',
    createdAt: '2022-03-19T11:25:50.534Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 10,
    },
  },
  {
    _id: 'bahaR5eAbTZNrqsdJ',
    createdAt: '2022-03-19T11:26:12.553Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F836955bb-4c46-4d95-aa0d-4cd057c4d7e9_200x200.jpg?alt=media&token=cc75a865-f8ae-400a-a2c5-2249a2829536',
        },
      },
      quantity: 5,
    },
  },
  {
    _id: 'kCDr37owfeaazfeTq',
    createdAt: '2022-03-19T11:26:42.008Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'HD6kFyoB86PuxA3ow',
        name: 'HAND SLEEVES ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc98a9b17-9a15-4a0c-844b-63af5c506ee5_200x200.webp?alt=media&token=a218086e-6af4-4827-9f13-f35f9b1d3f9c',
        },
      },
      quantity: 2,
    },
  },
  {
    _id: 'Dhst36tkgjTpYbFFT',
    createdAt: '2022-03-19T11:27:00.636Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'pWYnmfSmxrYpRYXGd',
    createdAt: '2022-03-19T11:27:00.755Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 894.7,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'erXgSwh8icgsEEc5n',
    createdAt: '2022-03-19T11:27:00.874Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 150.4700000000003,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'LaSfpNbCPxF7A338y',
    createdAt: '2022-03-19T11:27:00.989Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2849.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'zeHuvNJSSYpZTgcMP',
    createdAt: '2022-03-19T11:27:01.242Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'bgaXpQrxT7kQsBP38',
    createdAt: '2022-03-19T11:27:49.074Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Q3Lt5EwsNEfnFY4SM',
    createdAt: '2022-03-19T11:27:49.215Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2601.980000000006,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'qzTncc3emmmW7MG9a',
    createdAt: '2022-03-19T11:27:49.331Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2846.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '2WmcPepSufkWJ5yAA',
    createdAt: '2022-03-19T11:27:49.447Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '8A5cYxyaW6rJqSh8C',
    createdAt: '2022-03-19T11:27:49.559Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 152310.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'mxrBNqQiNDAakSujn',
    createdAt: '2022-03-19T11:27:49.669Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8928.029999999988,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'obKrzgqAjaATT3gyZ',
    createdAt: '2022-03-19T11:27:49.793Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 447.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'WGHvjxWov2i98zf2W',
    createdAt: '2022-03-19T11:27:49.931Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1928,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '9GqDFD5Qq9gcCSWTE',
    createdAt: '2022-03-19T11:29:13.393Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2351.980000000006,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'Ccxuccvj52D3ro4TF',
    createdAt: '2022-03-19T11:29:13.537Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NGnzZLWX4XqFtZSea',
    createdAt: '2022-03-19T11:29:13.650Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kvEM9z9TRtM67PtzQ',
    createdAt: '2022-03-19T11:29:13.770Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2796.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AW7GB6oRityqk5YHm',
    createdAt: '2022-03-19T11:29:13.884Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KHZBo9hQt8xDy9fX9',
    createdAt: '2022-03-19T11:29:14.022Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17351.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'vFLFahoCdJqndnqCC',
    createdAt: '2022-03-19T11:29:14.139Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wDKk4zRtRimiex7MC',
    createdAt: '2022-03-19T11:29:14.252Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 53.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '2ykPJMkGRhZpxEi8K',
    createdAt: '2022-03-19T11:29:14.364Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 19.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'fpuZESbP27ExWt2d3',
    createdAt: '2022-03-19T11:29:14.480Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'a7gs26Mxc3usCPFNE',
    createdAt: '2022-03-19T11:29:14.627Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8925.029999999988,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'GsrFQySfyZzGQ6MFv',
    createdAt: '2022-03-19T11:29:14.737Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4880,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'PLNf3ceBgPXYy2Wdp',
    createdAt: '2022-03-19T11:57:18.243Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7006,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'NF94AWiY4xKobePJS',
    createdAt: '2022-03-19T11:57:18.379Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1521.980000000006,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'fREvLKqGw5j5T7vgM',
    createdAt: '2022-03-19T11:57:18.491Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 507,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'XsnPNkDuBKAMwmkw6',
    createdAt: '2022-03-19T11:57:18.603Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 442.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'o37EwkMBJpGqK8dGD',
    createdAt: '2022-03-19T11:57:18.747Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2108,
    },
    transactionType: 'transferOut',
    amount: -222,
  },
  {
    _id: 'KD8bAoxg4FpBqmAaY',
    createdAt: '2022-03-19T11:57:18.859Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1055,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'MtXxsSo5569k6M3TM',
    createdAt: '2022-03-19T12:02:27.968Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'XKyZwupL5bPcokCgw',
    createdAt: '2022-03-19T12:03:03.039Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '6seFazey8kk6PHuKS',
    createdAt: '2022-03-19T12:03:03.150Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9257,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'yzphpFwyrhy5s6EQA',
    createdAt: '2022-03-19T12:03:03.260Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4497,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'fCjdWqC2MShrBJvBY',
    createdAt: '2022-03-19T12:03:03.375Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1024,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'h5bawDuXvBJs2w9ie',
    createdAt: '2022-03-19T12:03:42.713Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'Lc4sJrEPQvPHjS3n2',
    createdAt: '2022-03-19T12:03:42.824Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9200,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '44rRKa36yNmhNxwZy',
    createdAt: '2022-03-19T12:03:43.512Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1019,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'KocgtM3pyoQ6E4sMC',
    createdAt: '2022-03-19T13:07:44.352Z',
    transactionType: 'transferIn',
    amount: 80,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4804,
    },
  },
  {
    _id: 'GN3FkSJKaCxsFfsvy',
    createdAt: '2022-03-19T13:08:06.447Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3663,
    },
  },
  {
    _id: '5ZSCnmfB7r29BpJ9a',
    createdAt: '2022-03-19T13:08:57.648Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4055,
    },
  },
  {
    _id: 'EF8rnYz7mMCb4QZNc',
    createdAt: '2022-03-19T13:09:17.631Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2796.0000000000005,
    },
  },
  {
    _id: 'KB3vYtD7Ts4hwBqGq',
    createdAt: '2022-03-19T13:09:40.215Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1016,
    },
  },
  {
    _id: 'b52mr6nf2bdv6kZuj',
    createdAt: '2022-03-19T13:10:03.060Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1043,
    },
  },
  {
    _id: 'BvMw7ErE2PxJgbtmy',
    createdAt: '2022-03-19T13:21:41.303Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1084,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'zPGsbFMLgdv8FHqgB',
    createdAt: '2022-03-19T13:21:41.482Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1393,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'nigd8WqHrLaHtrzD2',
    createdAt: '2022-03-19T13:21:41.629Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 55.40000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'QH2zzwcC2QSLyE7yf',
    createdAt: '2022-03-19T13:21:41.801Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 150.2200000000003,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '7hWQAsjAH3h593rQg',
    createdAt: '2022-03-19T13:21:41.972Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 437.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'xA3HLwmHx366D5roZ',
    createdAt: '2022-03-19T13:21:42.139Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 173.8,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'pqaYmeZDPSY5badAm',
    createdAt: '2022-03-19T13:21:42.286Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8924.429999999988,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'QSrek6zvE2tNQKyn9',
    createdAt: '2022-03-19T13:21:42.406Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ubpvMCoMdzEAySyGv',
    createdAt: '2022-03-19T13:21:42.522Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 877.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'HffJFiTMvEcBWYsdE',
    createdAt: '2022-03-19T13:21:42.646Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5hMoFTJP75bvZszHy',
    createdAt: '2022-03-19T13:21:42.766Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WsRtAes9QkJy3pLJP',
    createdAt: '2022-03-19T13:21:42.941Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1133,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'jzsfexvTpkWq585cv',
    createdAt: '2022-03-19T13:22:22.868Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1064,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '6WAsPisLQ6zrM636o',
    createdAt: '2022-03-19T13:22:23.039Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1363,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'bEsPjS4noRyr8Zrwn',
    createdAt: '2022-03-19T13:22:23.181Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 55.00000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '7duaeTnxcKPCmafYW',
    createdAt: '2022-03-19T13:22:23.302Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 150.02000000000032,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'hpcRS7mzPXTQ6J38e',
    createdAt: '2022-03-19T13:22:23.418Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 435.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Rb7LtZJuSnkENhHar',
    createdAt: '2022-03-19T13:22:23.534Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 168.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '7qBtnzpXRW2BLAoeL',
    createdAt: '2022-03-19T13:22:23.654Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8922.929999999988,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'yP6ZCjFAJ5nZv8P2g',
    createdAt: '2022-03-19T13:22:23.771Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6GwFS9qkZXEME7vNF',
    createdAt: '2022-03-19T13:22:23.888Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 866.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'K7YMSEwPSwnCFQgBT',
    createdAt: '2022-03-19T13:22:24.002Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dxpqFRC9h3NeSsgeN',
    createdAt: '2022-03-19T13:22:24.120Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tiPDj9J5PptsK6jaF',
    createdAt: '2022-03-19T13:22:24.251Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1124,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'X77ifnMKCYSPcWaKR',
    createdAt: '2022-03-19T13:36:00.278Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6806,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'p3cKf5xy2AkAneADJ',
    createdAt: '2022-03-19T13:36:00.418Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2871.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Mmf9TvHrWq9H4XmaE',
    createdAt: '2022-03-19T13:36:00.536Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 778.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'ytYCfPJBkE96MDLd4',
    createdAt: '2022-03-19T13:36:00.660Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17291,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'mHFnia2GA8GmMbh73',
    createdAt: '2022-03-19T13:36:00.780Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 779,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'i9wQBXBqeoY8ew9ER',
    createdAt: '2022-03-19T13:36:00.897Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4155,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'Pm55d29ADwBi9hJu4',
    createdAt: '2022-03-19T13:37:50.051Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1121.980000000006,
    },
    transactionType: 'transferOut',
    amount: -660,
  },
  {
    _id: 'hgn6QLj3eqwEZX7wJ',
    createdAt: '2022-03-19T13:37:50.195Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AXiav4GcanBCzwo7q',
    createdAt: '2022-03-19T13:37:50.311Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6406,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Mv8rxmXrBWbPw96ho',
    createdAt: '2022-03-19T13:37:50.434Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2859.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'aqX2jJWzxfWaAdAd6',
    createdAt: '2022-03-19T13:37:50.552Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 760.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'rkzE462tC8hMM2FRE',
    createdAt: '2022-03-19T13:37:50.673Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 150010.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'Rbwxq76phJpvrvYD3',
    createdAt: '2022-03-19T13:37:50.791Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17276,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'xPEmqthMLogcPiBNZ',
    createdAt: '2022-03-19T13:37:50.912Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 166,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'iSt5YewARFRhjvqeM',
    createdAt: '2022-03-19T13:37:51.028Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 719,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'jJ3aqqtyxnGW3wkwS',
    createdAt: '2022-03-19T13:37:51.151Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4129,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'h8qoT3LQKtMo5GBoK',
    createdAt: '2022-03-21T04:51:03.523Z',
    transactionType: 'transferIn',
    amount: 3285,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 461.98000000000593,
    },
  },
  {
    _id: 'cAh5j3sEbydx87LPK',
    createdAt: '2022-03-21T04:51:38.417Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7,
    },
  },
  {
    _id: 'jG3XzmpydbB29FD6t',
    createdAt: '2022-03-21T04:53:51.610Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1044,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'xBR3dHbzAFb9YjoDH',
    createdAt: '2022-03-21T04:53:51.787Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1333,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'XLn4c2BEFJvHoYtu2',
    createdAt: '2022-03-21T04:53:51.907Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 149.82000000000033,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'vW4M6zagmPuZEMkLt',
    createdAt: '2022-03-21T04:53:52.043Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 54.60000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'jBq8A8bx9mYJF2LiW',
    createdAt: '2022-03-21T04:53:52.160Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 433.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'EoYPhxdQwBbiMAhtJ',
    createdAt: '2022-03-21T04:53:52.269Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 168.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'pvcCngZMEvihxstj6',
    createdAt: '2022-03-21T04:53:52.384Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8921.429999999988,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ZeHm3vuZ3dJum7vMq',
    createdAt: '2022-03-21T04:53:52.498Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ErT4ntYRZ8mLYSLaG',
    createdAt: '2022-03-21T04:53:52.615Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 855.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'TzTer6miCGmbYkyHW',
    createdAt: '2022-03-21T04:53:52.727Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3oDoALe5SxnemtdRg',
    createdAt: '2022-03-21T04:53:52.889Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YxNYKwgkv6jSGmNTn',
    createdAt: '2022-03-21T04:53:53.028Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1115,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '3MgsdfQqT8Pg5SqgE',
    createdAt: '2022-03-21T04:54:52.733Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1024,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'RkLKs365ejruDzcv8',
    createdAt: '2022-03-21T04:54:52.912Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1293,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'ikGdvC5fbnRDgNKsG',
    createdAt: '2022-03-21T04:54:53.101Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 149.62000000000035,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'ZrPmqiA5Yhx9rArWo',
    createdAt: '2022-03-21T04:54:53.218Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 54.20000000000005,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'EwbHSKi22e5NeoSpP',
    createdAt: '2022-03-21T04:54:53.339Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 431.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '9p7JkLJfFzregMshe',
    createdAt: '2022-03-21T04:54:53.493Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 167.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'iBCL26YkadT9cZFwq',
    createdAt: '2022-03-21T04:54:53.611Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8920.429999999988,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'QtYvrKJMxszrJSete',
    createdAt: '2022-03-21T04:54:53.725Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f6FAWz6t2g4KKhogo',
    createdAt: '2022-03-21T04:54:53.837Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 844.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'ppQK5dmkpxszY87a8',
    createdAt: '2022-03-21T04:54:53.945Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AEBCsj4G8NWJWgfxg',
    createdAt: '2022-03-21T04:54:54.054Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JxruJnDGoeJFj2LRJ',
    createdAt: '2022-03-21T04:54:54.184Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1106,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'TuicyTPPJ4jL8i6TL',
    createdAt: '2022-03-21T04:55:53.656Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1004,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'Ag7TJWqtjYjiDzQqC',
    createdAt: '2022-03-21T04:55:53.838Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1253,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'BY9uieuP5AdfaYuQo',
    createdAt: '2022-03-21T04:55:53.978Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 50.20000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '9xg8jWtsn7vPv8bRZ',
    createdAt: '2022-03-21T04:55:54.094Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 149.42000000000036,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'XJ6uG39mkZXSdBTBr',
    createdAt: '2022-03-21T04:55:54.209Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 429.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'kvn8MXBm6cptvKo4i',
    createdAt: '2022-03-21T04:55:54.321Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 167.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'h2MBAjcDDa6o8rWEq',
    createdAt: '2022-03-21T04:55:54.433Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8919.429999999988,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'jfCZMYoLbvCxt68wP',
    createdAt: '2022-03-21T04:55:54.547Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 487,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vHQ3nBdsFzGhJhuH6',
    createdAt: '2022-03-21T04:55:54.662Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 833.7,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'x5R4zTP9Wu2hqF8GD',
    createdAt: '2022-03-21T04:55:54.771Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'z5S9eQSMYAM5ACoBd',
    createdAt: '2022-03-21T04:55:54.883Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZqXshJ9rDkb65dxsh',
    createdAt: '2022-03-21T04:55:55.045Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1097,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'kGbRHc5e9vkwbfvR2',
    createdAt: '2022-03-21T05:02:03.582Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6406,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '73L5x3WmuHJ7HhZC5',
    createdAt: '2022-03-21T05:02:03.725Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3746.980000000006,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'ATSMQ6dd28oCdiufT',
    createdAt: '2022-03-21T05:02:03.841Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '74zaTytciDnhx33RR',
    createdAt: '2022-03-21T05:02:03.956Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2840.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'AYWr4RQieRJojCsLb',
    createdAt: '2022-03-21T05:02:04.063Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 724.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'C8DAzTmd2kQ7BXXyW',
    createdAt: '2022-03-21T05:02:04.174Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149710.3,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'FBrJ3hbHMpt4jt4G8',
    createdAt: '2022-03-21T05:02:04.287Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 151,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '5G6gaERArWsSLn3pH',
    createdAt: '2022-03-21T05:02:04.420Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17252,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '5koYeWfG7pMgQo6Sa',
    createdAt: '2022-03-21T05:02:04.559Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17339.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7hDgqHCi7nWR4NAw2',
    createdAt: '2022-03-21T05:02:04.673Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 609,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'j6TY8qi3dhxN3aRtg',
    createdAt: '2022-03-21T05:02:04.787Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4079,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'BhLicBJkmZjtAsxXP',
    createdAt: '2022-03-21T05:09:39.558Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6406,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CXMJGtSEj5w83Hxnh',
    createdAt: '2022-03-21T05:09:39.695Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j8qm6HrKTXrTFDxKq',
    createdAt: '2022-03-21T05:09:39.809Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2946.980000000006,
    },
    transactionType: 'transferOut',
    amount: -270,
  },
  {
    _id: 'dQoxtXevPLJ9tmkXQ',
    createdAt: '2022-03-21T05:09:39.920Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2816.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '4CL4DanhB6tJZ3nNT',
    createdAt: '2022-03-21T05:09:40.033Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 131,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'TWRB76zYwcBk9MvbZ',
    createdAt: '2022-03-21T05:09:40.146Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17222,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'bN7TgygauBP28T86J',
    createdAt: '2022-03-21T05:09:40.259Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149310.3,
    },
    transactionType: 'transferOut',
    amount: -135,
  },
  {
    _id: 'a82bBxefmo2J3mibz',
    createdAt: '2022-03-21T05:09:40.368Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 684.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'PYbQ2TiuNPoBcpwhf',
    createdAt: '2022-03-21T05:09:40.476Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4025,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'GTrFvwwb5uQMP768L',
    createdAt: '2022-03-21T05:12:23.228Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2676.980000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vhiFLSQJbsxsTaLYX',
    createdAt: '2022-03-21T05:12:23.370Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2007,
    },
    transactionType: 'transferOut',
    amount: -2007,
  },
  {
    _id: 'jkQgaFgoCDTDp9L2m',
    createdAt: '2022-03-21T05:12:23.489Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 79.8,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'LnpgJ7Nn46v27izKm',
    createdAt: '2022-03-21T05:12:23.602Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 149175.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'SaqWWnqEAnjfjjtge',
    createdAt: '2022-03-21T05:12:23.716Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 669.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'xYqJL7EoNHYsHqiRL',
    createdAt: '2022-03-21T05:12:23.972Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1223,
    },
    transactionType: 'in',
    amount: 160,
  },
  {
    _id: 'k5kTNFmD3KxB5wiSc',
    createdAt: '2022-03-21T08:05:32.656Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6406,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QHY3qYjM3Go8oTq7G',
    createdAt: '2022-03-21T08:05:32.804Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2676.980000000006,
    },
    transactionType: 'transferOut',
    amount: -1200,
  },
  {
    _id: 'qG8XfpvoNpCo87gqP',
    createdAt: '2022-03-21T08:05:32.933Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2808.0000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3k9N4Z6AiutqEocco',
    createdAt: '2022-03-21T08:05:33.046Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rQ6cKN3RhsYPgEb8v',
    createdAt: '2022-03-21T08:05:33.169Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8917.929999999988,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2SmwiZRJXFApfHQPq',
    createdAt: '2022-03-21T08:05:33.285Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17327.9,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'u3hcj7jr2vCaNLWyK',
    createdAt: '2022-03-21T08:05:33.431Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 18.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'nmRqKGmqJ8gogQFqP',
    createdAt: '2022-03-21T08:05:33.560Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4884,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'rcPSwAeKDAqs7Z9fw',
    createdAt: '2022-03-21T08:05:33.910Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 524.5,
    },
    transactionType: 'in',
    amount: 180,
  },
  {
    _id: 'kLCzQEuwSiBFaRijJ',
    createdAt: '2022-03-21T10:46:18.932Z',
    transactionType: 'in',
    amount: 10000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1476.980000000006,
    },
  },
  {
    _id: 'LcZP86RhkKj3EWurQ',
    createdAt: '2022-03-21T10:46:39.036Z',
    transactionType: 'in',
    amount: 600,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 549.6000000000004,
    },
  },
  {
    _id: 'rS33Li9BdAuwu4zKY',
    createdAt: '2022-03-21T10:47:06.905Z',
    transactionType: 'in',
    amount: 5000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148175.3,
    },
  },
  {
    _id: 'a79ofnnLFeLzKstBX',
    createdAt: '2022-03-21T10:49:29.692Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 11476.980000000007,
    },
    transactionType: 'transferOut',
    amount: -10000,
  },
  {
    _id: '8h9LY7EqZtxrnAG8h',
    createdAt: '2022-03-21T10:49:29.820Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 77.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'tfgatd2Dj5NzxoYKY',
    createdAt: '2022-03-21T10:49:29.930Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153175.3,
    },
    transactionType: 'transferOut',
    amount: -5000,
  },
  {
    _id: '6eBsG9XMS6XyKyXsR',
    createdAt: '2022-03-21T10:49:30.030Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1149.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'yydSiYQdME5jd3eQP',
    createdAt: '2022-03-21T10:49:30.275Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'fc7RexXL7CHfGjzDa',
    createdAt: '2022-03-21T11:02:57.698Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6406,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'zC5bGR3JL8cKbiSMq',
    createdAt: '2022-03-21T11:02:57.833Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1476.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'qMsvzCN73sGZrosaR',
    createdAt: '2022-03-21T11:02:57.942Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 457,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'hYmy9cR6qNrdDaJXX',
    createdAt: '2022-03-21T11:02:58.050Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 427.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Neo2bwfYwhLZNcdhZ',
    createdAt: '2022-03-21T11:02:58.159Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1886,
    },
    transactionType: 'transferOut',
    amount: -162,
  },
  {
    _id: 'aSz8zx7zxQmETN7Aj',
    createdAt: '2022-03-21T11:02:58.290Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1089,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'GKcFkLdETfF2ERGNp',
    createdAt: '2022-03-21T11:20:06.095Z',
    transactionType: 'in',
    amount: 5373,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6306,
    },
  },
  {
    _id: 'ZQNySfqXN53ZKPbFR',
    createdAt: '2022-03-21T11:20:29.214Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 164,
    },
  },
  {
    _id: '5WhTGh5FnpNcB99zd',
    createdAt: '2022-03-21T11:24:09.277Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 514,
    },
    transactionType: 'transferOut',
    amount: -211,
  },
  {
    _id: 'jbjBka9gbMa746429',
    createdAt: '2022-03-21T11:24:09.409Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9164,
    },
    transactionType: 'transferOut',
    amount: -89,
  },
  {
    _id: 'kn4mJmG6J2pPgjCeL',
    createdAt: '2022-03-21T11:24:09.516Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4495,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'Ew8L32pyTZbTrksv3',
    createdAt: '2022-03-21T11:24:09.633Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1046,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'vdex4pXBLngnhNxgK',
    createdAt: '2022-03-21T11:25:01.857Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 303,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'aahAesqpX4youRxMZ',
    createdAt: '2022-03-21T11:25:01.979Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9075,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'L7Zz4ScXwtFNbcf5e',
    createdAt: '2022-03-21T11:25:02.145Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1038,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '3rFRgsir5PZNJYv43',
    createdAt: '2022-03-21T11:26:03.249Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 106,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'LyKM4sBdiFGoW5L8j',
    createdAt: '2022-03-21T11:26:03.401Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9025,
    },
    transactionType: 'transferOut',
    amount: -81,
  },
  {
    _id: 'dNbAYDm56gTPi3xHg',
    createdAt: '2022-03-21T11:26:03.509Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1033,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'KWsCYTnCNthzKT2kF',
    createdAt: '2022-03-21T11:28:58.963Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 251,
    },
    transactionType: 'transferOut',
    amount: -251,
  },
  {
    _id: 'EMRqjKPzDkZq7asgC',
    createdAt: '2022-03-21T11:28:59.077Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 6.800000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'E5bSRsy6MNbXfHTpE',
    createdAt: '2022-03-21T11:28:59.341Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 26,
    },
    transactionType: 'in',
    amount: 250,
  },
  {
    _id: 'mPHWRQ8pvSyb92XHJ',
    createdAt: '2022-03-21T11:29:45.124Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 276,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'PbStK8NQwqPLzS4v7',
    createdAt: '2022-03-21T11:29:45.256Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8944,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'xRX7xgCyeKJEHyz2C',
    createdAt: '2022-03-21T11:29:45.386Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'RcpXAaPG9WSRxMJcf',
    createdAt: '2022-03-21T11:51:50.963Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 417,
    },
  },
  {
    _id: 'hGTy4J4d8Po5RhHrk',
    createdAt: '2022-03-21T11:52:14.283Z',
    transactionType: 'transferIn',
    amount: 65,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 328,
    },
  },
  {
    _id: 'CL2goCvNBiNYpAjQw',
    createdAt: '2022-03-21T11:52:37.463Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
  },
  {
    _id: 'b4YavcAHemQwwiPyt',
    createdAt: '2022-03-21T11:53:06.995Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 423.4200000000002,
    },
  },
  {
    _id: 'oSMQHpWCFojhmt3Yw',
    createdAt: '2022-03-21T13:18:44.028Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1076.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'KMN8zdRRG84K6ErpW',
    createdAt: '2022-03-21T13:18:44.156Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kcR6jCvMHQHJ9af9c',
    createdAt: '2022-03-21T13:18:44.264Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sSy6RAuu23nxwSWBe',
    createdAt: '2022-03-21T13:18:44.365Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2808.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'BhWZdkFReDrWdYtDW',
    createdAt: '2022-03-21T13:18:44.500Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 138,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'JaphAjFHi4kSa88GW',
    createdAt: '2022-03-21T13:18:44.616Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17307.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Nh9K9RhRavhFkC2gx',
    createdAt: '2022-03-21T13:18:44.721Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uBkWZ82BdJm2HquJT',
    createdAt: '2022-03-21T13:18:44.827Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 16.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'aQMyLfXgfzSd6tRg8',
    createdAt: '2022-03-21T13:18:44.940Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 52.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'uLEjnS83R7kZT8sp3',
    createdAt: '2022-03-21T13:18:45.054Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oWehZp3JTLkS5hXw7',
    createdAt: '2022-03-21T13:18:45.168Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8915.929999999988,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'dg7WR5BLyJv7iHD34',
    createdAt: '2022-03-21T13:18:45.277Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4866,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'akLHa53jYJEwNTgnP',
    createdAt: '2022-03-21T13:20:35.707Z',
    transactionType: 'transferIn',
    amount: 2400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 246.98000000000684,
    },
  },
  {
    _id: '7EtkFkEs6ER6PjREQ',
    createdAt: '2022-03-21T13:21:39.245Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2646.980000000007,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'Pxa2cAYBTB5mnmqpB',
    createdAt: '2022-03-21T13:21:39.384Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vMFZRJRvkN6SZ57X2',
    createdAt: '2022-03-21T13:21:39.517Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CHsisKxyiahMSPtJQ',
    createdAt: '2022-03-21T13:21:39.625Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2802.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '6h4uC97KuANyKsx7Y',
    createdAt: '2022-03-21T13:21:39.736Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 549.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'mEqfGRLy9BrSNekkn',
    createdAt: '2022-03-21T13:21:39.847Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148175.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'R6QcNmbJ47XhDQP9w',
    createdAt: '2022-03-21T13:21:39.961Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17212,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'DFTj6rMwggTkDk9pF',
    createdAt: '2022-03-21T13:21:40.080Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 125,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'RYgba27jen8uQbrDy',
    createdAt: '2022-03-21T13:21:40.193Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 469,
    },
    transactionType: 'transferOut',
    amount: -115,
  },
  {
    _id: 'nm2K5JvSTLNNXEXew',
    createdAt: '2022-03-21T13:21:40.326Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4006,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: '3CQT9HGoivzHHAtm9',
    createdAt: '2022-03-21T13:23:41.434Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oJbNsHdWBLm6cbKfG',
    createdAt: '2022-03-21T13:23:41.572Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2046.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'rDGLFs2MMH9xjix9n',
    createdAt: '2022-03-21T13:23:41.692Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TWYphecJeThHdCiN8',
    createdAt: '2022-03-21T13:23:41.805Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2784.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -1.8,
  },
  {
    _id: 'aNnB2n5jxWNZNanzL',
    createdAt: '2022-03-21T13:23:41.921Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 519.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'N48o7chJCqE8XzJEq',
    createdAt: '2022-03-21T13:23:42.033Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147875.3,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'QALymMWbnWgTGwG8S',
    createdAt: '2022-03-21T13:23:42.148Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17188,
    },
    transactionType: 'transferOut',
    amount: -2.4,
  },
  {
    _id: '5KYrzWYab745vx4s2',
    createdAt: '2022-03-21T13:23:42.285Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1127,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'FHtaHsG7ssuTxFmf2',
    createdAt: '2022-03-21T13:33:59.710Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1081,
    },
  },
  {
    _id: '4x7SvHY6p2dd98Cjp',
    createdAt: '2022-03-21T13:35:01.145Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1016,
    },
  },
  {
    _id: 'vHG4a2D3qHqy56oM6',
    createdAt: '2022-03-21T13:35:19.392Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 212,
    },
  },
  {
    _id: 'JbJwT927yhxgTPSCc',
    createdAt: '2022-03-21T13:35:46.702Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2782.2000000000003,
    },
  },
  {
    _id: 'TaSC7sr6Xpc9szmqo',
    createdAt: '2022-03-21T13:36:05.022Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8915.329999999987,
    },
  },
  {
    _id: 'DvzeLLMRnC9u2QzQn',
    createdAt: '2022-03-21T13:36:30.122Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 822,
    },
  },
  {
    _id: 'WnNGqtDTYR9WHnzuE',
    createdAt: '2022-03-21T13:37:00.133Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4789,
    },
  },
  {
    _id: 'w8D7ZvY8ABkcqcyeX',
    createdAt: '2022-03-21T13:37:34.611Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 822.7,
    },
  },
  {
    _id: 'LWexr2zmAbggYu3Em',
    createdAt: '2022-03-21T13:38:03.136Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3960,
    },
  },
  {
    _id: 'nh3jc54QphytFSMtR',
    createdAt: '2022-03-21T13:39:09.199Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 487,
    },
  },
  {
    _id: 'LmmyYrMqnCweTTKmn',
    createdAt: '2022-03-21T13:40:38.005Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 590,
    },
  },
  {
    _id: '5EGJpBoHkvEoGbYNZ',
    createdAt: '2022-03-21T13:57:18.605Z',
    transactionType: 'transferIn',
    amount: 2150,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1981.9800000000068,
    },
  },
  {
    _id: 'HGtFe6m9bNSQPtFWj',
    createdAt: '2022-03-21T14:03:25.532Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11679,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'SG72q5uhZ3SbyWPtR',
    createdAt: '2022-03-21T14:03:25.691Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4131.980000000007,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'zqHj38G4y6YSZwPW2',
    createdAt: '2022-03-21T14:03:25.801Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QqSRo5S3Y9f6e4fJG',
    createdAt: '2022-03-21T14:03:25.919Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2957.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -8.4,
  },
  {
    _id: '5uT8KCqEHLgkJDuff',
    createdAt: '2022-03-21T14:03:26.030Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 516.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'CEv7N7Em8BenDPM4m',
    createdAt: '2022-03-21T14:03:26.157Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17185.6,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'XKazCqDjggawrvbGb',
    createdAt: '2022-03-21T14:03:26.267Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17295.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yLwnSjJnpka5iawKj',
    createdAt: '2022-03-21T14:03:26.388Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 354,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'jqGgtu45vNWu7CsJt',
    createdAt: '2022-03-21T14:03:26.501Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4160,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'cumiMZcaMqDAkQvqk',
    createdAt: '2022-03-21T14:37:16.468Z',
    transactionType: 'transferIn',
    amount: 2150,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'f9xvDRto4a2TwPz4J',
    createdAt: '2022-03-21T14:39:07.075Z',
    transactionType: 'out',
    amount: -2150,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3921.980000000007,
    },
  },
  {
    _id: 'EWGjj37m36bBsy9kJ',
    createdAt: '2022-03-21T14:46:50.693Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1771.9800000000068,
    },
  },
  {
    _id: 'Diq8Ps97CRW49WMes',
    createdAt: '2022-03-22T04:41:07.107Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 442,
    },
  },
  {
    _id: 'Ea6WgvrAkcnd6RK37',
    createdAt: '2022-03-22T04:41:51.541Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 448.4200000000002,
    },
  },
  {
    _id: 'vTwfsKepnC5qsEzoq',
    createdAt: '2022-03-22T04:42:14.102Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 128,
    },
  },
  {
    _id: 'pyDewko4aFdukYFEQ',
    createdAt: '2022-03-22T04:42:38.629Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 147.69999999999996,
    },
  },
  {
    _id: 'HZZmE5idsoCsKXWiW',
    createdAt: '2022-03-22T05:12:11.871Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11469,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GboW2Gxh227552mnF',
    createdAt: '2022-03-22T05:12:12.010Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2171.980000000007,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'wuhDzypEAczk58rBb',
    createdAt: '2022-03-22T05:12:12.126Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2150,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ucMWYHuJvsvnJMtiM',
    createdAt: '2022-03-22T05:12:12.723Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2948.8,
    },
    transactionType: 'transferOut',
    amount: -10.8,
  },
  {
    _id: 'xnzdMtrQEkEKxybiw',
    createdAt: '2022-03-22T05:12:12.862Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 492.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'y7qXA5pGMjHyTscf7',
    createdAt: '2022-03-22T05:12:12.970Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147845.3,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'GeeXv8bQqdbLEAgwy',
    createdAt: '2022-03-22T05:12:13.074Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 111,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'LJTaKTKW8yd6NZZE3',
    createdAt: '2022-03-22T05:12:13.180Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17175.6,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'MN6z9rrAXXbSLsoXS',
    createdAt: '2022-03-22T05:12:13.285Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17285.9,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'K8JRvCatuaEN4MctC',
    createdAt: '2022-03-22T05:12:13.390Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 264,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'ej8bCBuWkuL2EMxLF',
    createdAt: '2022-03-22T05:12:13.495Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4125,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'a4MYpPMmHfBB4fFwA',
    createdAt: '2022-03-22T05:42:19.251Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 527,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'gcY674tLPpNCknF7v',
    createdAt: '2022-03-22T05:42:19.387Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 922.7,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '75HJFQFHt7vb4Ft8S',
    createdAt: '2022-03-22T05:42:19.498Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 149.22000000000037,
    },
    transactionType: 'transferOut',
    amount: -0.28,
  },
  {
    _id: 'LyZSPxAWEfGCdqc4K',
    createdAt: '2022-03-22T05:42:19.609Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2938,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'y5Dx2DzjKENGtFkJ4',
    createdAt: '2022-03-22T05:42:19.858Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'cprFTn7J9tfsnjTA9',
    createdAt: '2022-03-22T05:52:20.612Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11469,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'o9u6PRnGAS44XgQvY',
    createdAt: '2022-03-22T05:52:20.746Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1811.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -580,
  },
  {
    _id: 'pJXDsvQmwzEuz8o6k',
    createdAt: '2022-03-22T05:52:21.482Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2150,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RhhJh3EfGpRguZozT',
    createdAt: '2022-03-22T05:52:21.624Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2935,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i9mMC3bKY76FRaBZn',
    createdAt: '2022-03-22T05:52:21.790Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3um4HMSNatu8vqmqy',
    createdAt: '2022-03-22T05:52:21.949Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 473.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'rzqFyTaYTT2gZibnB',
    createdAt: '2022-03-22T05:52:22.087Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 51.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'g69j9BxnkwHHP4tBC',
    createdAt: '2022-03-22T05:52:22.309Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17280.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'iecj25KsaXo9C6At4',
    createdAt: '2022-03-22T05:52:22.429Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3763,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'CABarWK7FRZ8B4AKS',
    createdAt: '2022-03-22T05:53:31.546Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1231.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dEZ2SuDvMng9LFCXi',
    createdAt: '2022-03-22T05:53:31.685Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2150,
    },
    transactionType: 'transferOut',
    amount: -2150,
  },
  {
    _id: 'QDZDbaPot6hAi837i',
    createdAt: '2022-03-22T05:53:31.797Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 76.8,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'nLkXN8cSsJztD3Ehy',
    createdAt: '2022-03-22T05:53:31.915Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 147665.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'sfhomdx97JsmQzykQ',
    createdAt: '2022-03-22T05:53:32.052Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 474.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'j2xQhYmgJBqzoGv5k',
    createdAt: '2022-03-22T05:53:32.317Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1383,
    },
    transactionType: 'in',
    amount: 165,
  },
  {
    _id: 'Dm3XsdDzztpcT7gci',
    createdAt: '2022-03-22T05:55:17.879Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11469,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'AEF9oAMuwpmysNb5A',
    createdAt: '2022-03-22T05:55:18.018Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 74.8,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '4eScNh7WyA9ZfW8AF',
    createdAt: '2022-03-22T05:55:18.130Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 146665.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'cHKwh3JTPejBryftS',
    createdAt: '2022-03-22T05:55:18.243Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 354.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'x3oBS9bTFRrjwHXHc',
    createdAt: '2022-03-22T05:55:18.356Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9040.329999999987,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '324PRNAPALLe97ERi',
    createdAt: '2022-03-22T05:55:18.479Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'vkqEDJvxchK4ua5CD',
    createdAt: '2022-03-22T05:55:18.597Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5069,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'XctrfEjvqrMRx3yJX',
    createdAt: '2022-03-22T07:40:14.247Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1231.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'K7RmgNnR2NNpSc4ZW',
    createdAt: '2022-03-22T07:40:14.382Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9469,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ggSyFt7c7RqEDcENT',
    createdAt: '2022-03-22T07:40:14.491Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2935,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '4aLnfEjC9F3aFbEN2',
    createdAt: '2022-03-22T07:40:14.603Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'benBb7xa3fcwfvsxh',
    createdAt: '2022-03-22T07:40:14.713Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8940.329999999987,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'b2fi2yBHWWdxvH2Th',
    createdAt: '2022-03-22T07:40:14.824Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 145665.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'qhmApfLG4JaNfawvK',
    createdAt: '2022-03-22T07:40:14.932Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 427.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'JqCJCKTDMfmtm73BG',
    createdAt: '2022-03-22T07:40:15.038Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1908,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'sEt4QyA78xsyAWYbT',
    createdAt: '2022-03-22T07:40:15.145Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1141,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'QjuahH8HuPQJkZSt5',
    createdAt: '2022-03-22T07:46:42.441Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 517,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Ns7G3CrQPyZPPBQjn',
    createdAt: '2022-03-22T07:46:42.573Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 905.7,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'czNKYoFGmWhqKhso4',
    createdAt: '2022-03-22T07:46:42.681Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 148.94000000000037,
    },
    transactionType: 'transferOut',
    amount: -0.28,
  },
  {
    _id: 'izGYLBRhB8Jp7sxyg',
    createdAt: '2022-03-22T07:46:42.796Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2885,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'FczHtjviEfkvneMTQ',
    createdAt: '2022-03-22T07:46:43.043Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'XWwpbn2JigLf6sTEN',
    createdAt: '2022-03-22T07:48:19.644Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9469,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'aDyupnoD822oQ9EQX',
    createdAt: '2022-03-22T07:48:19.781Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2882,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '4z7vH5AHXGZ5t8iAX',
    createdAt: '2022-03-22T07:48:19.889Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 228.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'hbADxefB9ahMSzH7X',
    createdAt: '2022-03-22T07:48:19.996Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17163.6,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Gbzk4ZKW8QLJgEovZ',
    createdAt: '2022-03-22T07:48:20.102Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21696.4,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'gicTrmafE4qgCz75q',
    createdAt: '2022-03-22T07:48:20.212Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 439.9,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'QFowMRx8CgZGMjGJ7',
    createdAt: '2022-03-22T07:56:27.018Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9069,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'XSdthFCpq5MxMTicn',
    createdAt: '2022-03-22T07:56:27.207Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 62.8,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'KtyeYijBNqPgfJswZ',
    createdAt: '2022-03-22T07:56:28.196Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 143365.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'cG3w74vCyxPy2hH4Y',
    createdAt: '2022-03-22T07:56:28.520Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 204.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'HyF4zds99NhotYefh',
    createdAt: '2022-03-22T07:56:28.655Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8937.329999999987,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'pBtGu9c9YEiqxGzbX',
    createdAt: '2022-03-22T07:56:28.788Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 450,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'pTEvZTrztsLpstLTb',
    createdAt: '2022-03-22T07:56:28.910Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5027,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'PtKzif3YZvG4fPuNa',
    createdAt: '2022-03-22T08:08:39.321Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 156,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'QMzEs4xPn67WFKaoC',
    createdAt: '2022-03-22T08:08:39.464Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4488,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '3ctmv5wmHTWQmc2WK',
    createdAt: '2022-03-22T08:08:39.578Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1106,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'CZNrrWbKWZKgKvZB6',
    createdAt: '2022-03-22T08:09:39.592Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 151,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'GrT3ywYJwi62Pgab4',
    createdAt: '2022-03-22T08:09:39.737Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4477,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'aBswes4LYdHkApSv4',
    createdAt: '2022-03-22T08:09:39.856Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1105,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ag6HiWghoD9BDGeCP',
    createdAt: '2022-03-22T09:07:17.323Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 146,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'pYu3cE7Kz8uF3pMYb',
    createdAt: '2022-03-22T09:07:17.457Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8823,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: '6DkzsRzJX7Pq3oN6G',
    createdAt: '2022-03-22T09:07:17.562Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1104,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '4uyzdNziEczwfhPwf',
    createdAt: '2022-03-22T11:02:03.594Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 162,
    },
    transactionType: 'transferOut',
    amount: -1.6,
  },
  {
    _id: 'wWxagyANKmvWgMF7u',
    createdAt: '2022-03-22T11:02:03.763Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f8Q7BvDuTESdR87Wq',
    createdAt: '2022-03-22T11:02:03.918Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 148.66000000000037,
    },
    transactionType: 'transferOut',
    amount: -0.04,
  },
  {
    _id: 'ZY4B32Xx5QjCQ2m5Z',
    createdAt: '2022-03-22T11:02:04.052Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 888.7,
    },
    transactionType: 'transferOut',
    amount: -0.08,
  },
  {
    _id: 'kdgdXvxAWy6GngJZn',
    createdAt: '2022-03-22T11:02:04.174Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 507,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'JWCWRq89ZMjngim5y',
    createdAt: '2022-03-22T11:02:04.290Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 59.20000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.06,
  },
  {
    _id: 'uy8kiaMgXndMXrFNf',
    createdAt: '2022-03-22T11:02:04.414Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 49.800000000000054,
    },
    transactionType: 'transferOut',
    amount: -0.14,
  },
  {
    _id: 'p45WWDpaAPDK8EEnC',
    createdAt: '2022-03-22T11:02:04.528Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 467.4200000000002,
    },
    transactionType: 'transferOut',
    amount: -0.06,
  },
  {
    _id: 'E5G95KGNzdXT49CKP',
    createdAt: '2022-03-22T11:02:04.643Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8837.329999999987,
    },
    transactionType: 'transferOut',
    amount: -0.04,
  },
  {
    _id: 'HMzrEgBhYam4HzdH5',
    createdAt: '2022-03-22T11:02:04.757Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1548,
    },
    transactionType: 'transferOut',
    amount: -1.9,
  },
  {
    _id: 'P46mscFCXWCp6HQ53',
    createdAt: '2022-03-22T11:02:04.893Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '39hA7RaKhvENtFyZs',
    createdAt: '2022-03-22T11:02:05.022Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 393,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'XwRt6T8Gp7dQ72CwC',
    createdAt: '2022-03-22T11:02:05.264Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 16,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GrjtFbjTbt277cw6p',
    createdAt: '2022-03-22T11:27:48.617Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7069,
    },
    transactionType: 'transferOut',
    amount: -640,
  },
  {
    _id: 'C7vifNG8Qcpro6NBM',
    createdAt: '2022-03-22T11:27:48.754Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2870,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '6EHjzPMMoLidGQdLj',
    createdAt: '2022-03-22T11:27:48.886Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 78.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: 'j2wsRpzzg947KvaW3',
    createdAt: '2022-03-22T11:27:48.993Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17148.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'L92DcZkBhzuXPDPDw',
    createdAt: '2022-03-22T11:27:49.114Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 194,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'eywDPdmmmXSY9jum3',
    createdAt: '2022-03-22T11:27:49.225Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4099,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'ekp6zccL68JuvCeHx',
    createdAt: '2022-03-22T11:31:40.290Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6429,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'LSB2XS8yK6YBCE6G4',
    createdAt: '2022-03-22T11:31:40.430Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 981.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '826Z6ZBSbE2aGsBE6',
    createdAt: '2022-03-22T11:31:40.554Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 492,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'PWhjMHHmxW7NTBp49',
    createdAt: '2022-03-22T11:31:40.663Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 467.3600000000002,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'eDsRfnAkGQhL76ZfQ',
    createdAt: '2022-03-22T11:31:40.774Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1724,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'zbCEgZL5mCA7Rkiw4',
    createdAt: '2022-03-22T11:31:40.882Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1126,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '39pJDLrMacPEDWQ8d',
    createdAt: '2022-03-22T13:08:40.619Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 704.5,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: 'iry5au3zzPw8dFr7C',
    createdAt: '2022-03-22T13:08:40.775Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 167.69999999999996,
    },
    transactionType: 'transferOut',
    amount: -11.5,
  },
  {
    _id: '2fnRy7ja6ijxND7Xs',
    createdAt: '2022-03-22T13:08:40.904Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'E2LSiu2mahD4kfcAR',
    createdAt: '2022-03-22T13:08:41.020Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4055,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'WqEP7Zy882C3JnBCx',
    createdAt: '2022-03-22T13:08:41.137Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4985,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BNMkwufMdanbfYurj',
    createdAt: '2022-03-22T14:05:15.122Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6329,
    },
    transactionType: 'transferOut',
    amount: -1700,
  },
  {
    _id: 'adL6irc3o5XsDAmW4',
    createdAt: '2022-03-22T14:05:15.264Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 60.8,
    },
    transactionType: 'transferOut',
    amount: -1.7,
  },
  {
    _id: 'TzStWWNNPSdwgYCTv',
    createdAt: '2022-03-22T14:05:15.380Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 142365.3,
    },
    transactionType: 'transferOut',
    amount: -850,
  },
  {
    _id: 'gqHt5NTKfgFzY4hjN',
    createdAt: '2022-03-22T14:05:15.508Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 40.600000000000364,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 's9XfDwv9ohbqpShwa',
    createdAt: '2022-03-22T14:05:15.629Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1119,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'DGseh9Wsepn8ZgMeo',
    createdAt: '2022-03-22T14:07:34.870Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 465,
    },
  },
  {
    _id: 'f8hDfT5Qjkw24cyAC',
    createdAt: '2022-03-22T14:07:47.286Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2846,
    },
  },
  {
    _id: '4WZXLeXCAHN6H3DXp',
    createdAt: '2022-03-22T14:08:08.506Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1095,
    },
  },
  {
    _id: '8AsHreCxTb4yED8Cg',
    createdAt: '2022-03-22T14:08:30.402Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8837.289999999986,
    },
  },
  {
    _id: 'xmFPELzasvENbydMC',
    createdAt: '2022-03-22T14:08:50.622Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4040,
    },
  },
  {
    _id: 'FkLnfmPQ2h4wL7FCD',
    createdAt: '2022-03-22T14:09:13.499Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4985,
    },
  },
  {
    _id: 'vqq4JjDi8bxHio6pT',
    createdAt: '2022-03-22T14:09:36.921Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3631,
    },
  },
  {
    _id: '6GjSz8rsBXuP4xGzP',
    createdAt: '2022-03-22T14:10:44.269Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 382,
    },
  },
  {
    _id: 'yejzxn6YfNEsyTZJF',
    createdAt: '2022-03-22T16:08:05.101Z',
    transactionType: 'in',
    amount: 3400,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 4629,
    },
  },
  {
    _id: 'tGEYjKi6o3fGbrYjP',
    createdAt: '2022-03-22T16:08:47.312Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: '2Fj3mygxmhuPRaSS4',
    createdAt: '2022-03-23T04:55:48.123Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 731.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -336,
  },
  {
    _id: 'Sbx5iY6WrpC6Pgjkf',
    createdAt: '2022-03-23T04:55:48.255Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TmzQpqnF5x4vzjzTK',
    createdAt: '2022-03-23T04:55:48.368Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8029,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BjdAgdWrMscHEZomc',
    createdAt: '2022-03-23T04:55:48.478Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 0.6000000000003638,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RHwb7KnENG6N6kQX8',
    createdAt: '2022-03-23T04:55:48.594Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 16.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'X3TNsCHw6vZAQ5qXF',
    createdAt: '2022-03-23T04:55:48.700Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9012.289999999986,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'eDCH83gbjW3ageBuJ',
    createdAt: '2022-03-23T04:55:48.813Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17268.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'xGktCL92SGwMpYSsZ',
    createdAt: '2022-03-23T04:55:48.919Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3831,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'vQTCutrhdaP28mLD8',
    createdAt: '2022-03-23T05:11:29.039Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 395.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'niqmbphrGoFRCeDJe',
    createdAt: '2022-03-23T05:11:29.176Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8029,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vEJZ6DqD4FSh7gHBE',
    createdAt: '2022-03-23T05:11:29.288Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2921,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '4E4ht6CwRdZXXA2px',
    createdAt: '2022-03-23T05:11:29.424Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'WuCNWZ4bw9ASEo47P',
    createdAt: '2022-03-23T05:11:29.586Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9011.689999999986,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'oumwwhbvbgdnNvfsz',
    createdAt: '2022-03-23T05:11:29.720Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 141515.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'X85tfHTgujSBAam8i',
    createdAt: '2022-03-23T05:11:29.857Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 405.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '7NmzFdx7m6iwnj3QR',
    createdAt: '2022-03-23T05:11:30.000Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1886,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '3zDGcsQavXDKWE4CS',
    createdAt: '2022-03-23T05:11:30.138Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1098,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'oA5Sx7Nk3Mzhhe6sA',
    createdAt: '2022-03-23T05:49:57.497Z',
    transactionType: 'transferIn',
    amount: 6,
    storeItem: {
      type: {
        _id: 'oTP5sR2gNMqNwhWuT',
        name: 'LABNA SUPER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F47c3904c-268b-4c57-9522-1df27334fd21_200x200.jpg?alt=media&token=6441e292-3a15-4f08-bfb1-05766fd88081',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'Sy53yawPtdtgGEJQ6',
    createdAt: '2022-03-23T05:51:01.723Z',
    storeItem: {
      type: {
        _id: 'oTP5sR2gNMqNwhWuT',
        name: 'LABNA SUPER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F47c3904c-268b-4c57-9522-1df27334fd21_200x200.jpg?alt=media&token=6441e292-3a15-4f08-bfb1-05766fd88081',
        },
      },
      quantity: 6,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'GMb5bAMouAirk2HDm',
    createdAt: '2022-03-23T05:51:01.888Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1611,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'inrSaXHuTWnASLtn2',
    createdAt: '2022-03-23T05:51:02.001Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 242,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '9LjnJuYcLit6xHcSs',
    createdAt: '2022-03-23T06:45:45.211Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1596,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'FiJK77j9Fio4N3Qsd',
    createdAt: '2022-03-23T06:45:45.325Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 241,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'NHKbNHmuqotc32d7S',
    createdAt: '2022-03-23T07:38:39.236Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 30,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KqcTdCTCz9sgxzGo7',
    createdAt: '2022-03-23T07:38:39.345Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9008.689999999986,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'eg3Zs7DF3SxbEd43p',
    createdAt: '2022-03-23T07:38:39.454Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 139215.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'ew5nCRqMi865j3BsH',
    createdAt: '2022-03-23T07:38:39.571Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17258.9,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '87qRHtKgXbs7PGFiK',
    createdAt: '2022-03-23T07:38:39.824Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 69,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'SRfs3p4shGTkvSTEv',
    createdAt: '2022-03-23T07:40:36.327Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8029,
    },
    transactionType: 'transferOut',
    amount: -950,
  },
  {
    _id: 'SRckeq2qHSM4JQPqh',
    createdAt: '2022-03-23T07:40:36.576Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 145.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ln2JpnhiGGAMN5LKB',
    createdAt: '2022-03-23T07:40:36.695Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CCLqsWwJvJADJNmY6',
    createdAt: '2022-03-23T07:40:36.823Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2871,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '4e2G2ZKxHG7sQoBcB',
    createdAt: '2022-03-23T07:40:36.950Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 0.6000000000003638,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GSNi3xo8sQ6R7Tfsm',
    createdAt: '2022-03-23T07:40:37.076Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17228.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'QTyTp2Ab8wciDxkqb',
    createdAt: '2022-03-23T07:40:37.191Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17124.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '8dCsw3EaTrxJqenMW',
    createdAt: '2022-03-23T07:40:37.313Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 869,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'P2EWKMN3yaSJLGFtx',
    createdAt: '2022-03-23T07:40:38.159Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 383.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ekgysCKHaooxJ35YD',
    createdAt: '2022-03-23T07:40:38.274Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1864,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'asWxn4xHcpRXPMWc7',
    createdAt: '2022-03-23T07:40:38.403Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 241,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'AbwHo4y94z3MwAujv',
    createdAt: '2022-03-23T07:55:16.494Z',
    transactionType: 'in',
    amount: 200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7079,
    },
  },
  {
    _id: 'ET4ZABCYjvbQPyuMi',
    createdAt: '2022-03-23T07:55:28.373Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'T8JLjK4YnpoDDDgCE',
    createdAt: '2022-03-23T10:28:57.198Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -220,
  },
  {
    _id: 'o4N2PAcFz3GhuiGJw',
    createdAt: '2022-03-23T10:28:57.328Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8786,
    },
    transactionType: 'transferOut',
    amount: -93,
  },
  {
    _id: '7Bg9XSQDP6RpAAdKG',
    createdAt: '2022-03-23T10:28:57.433Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4466,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'fs4ayD5mTBKFAiyJw',
    createdAt: '2022-03-23T10:28:57.543Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1101,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'DeW6se4Xh56Q72tDJ',
    createdAt: '2022-03-23T10:29:59.832Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 480,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'iRyMoc8cXLrtBopYg',
    createdAt: '2022-03-23T10:29:59.954Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8693,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'M8rTGrEKCrX4ptGuq',
    createdAt: '2022-03-23T10:30:00.073Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1093,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'PdPjzQLbLfYpG2nDu',
    createdAt: '2022-03-23T10:32:53.538Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 420,
    },
    transactionType: 'transferOut',
    amount: -420,
  },
  {
    _id: 'KA78isX8faEDajJpz',
    createdAt: '2022-03-23T10:32:53.655Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 6.000000000000001,
    },
    transactionType: 'transferOut',
    amount: -1.4,
  },
  {
    _id: '668D9P9k53Y3Fdnuh',
    createdAt: '2022-03-23T10:32:53.932Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 110,
    },
    transactionType: 'in',
    amount: 420,
  },
  {
    _id: 'ezkf9EEoj7GQpddez',
    createdAt: '2022-03-23T10:34:01.600Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 530,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 't424G2NFQWkczyRss',
    createdAt: '2022-03-23T10:34:01.747Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8633,
    },
    transactionType: 'transferOut',
    amount: -91,
  },
  {
    _id: '3EaeNtDLPeCnGopYo',
    createdAt: '2022-03-23T10:34:01.855Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1088,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'GiRHSeCQRzqWMByQY',
    createdAt: '2022-03-23T10:34:58.002Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 440,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'jATmN6oKTSLCjStMA',
    createdAt: '2022-03-23T10:34:58.147Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8542,
    },
    transactionType: 'transferOut',
    amount: -121,
  },
  {
    _id: 'ksSkJhafXTZCbDdNS',
    createdAt: '2022-03-23T10:34:58.271Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1080,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Z3eLiaiD5EZxtgtRW',
    createdAt: '2022-03-23T10:35:49.177Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 320,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'FzDmYqDtYNGgph5oG',
    createdAt: '2022-03-23T10:35:49.314Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4464,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'tscywRGBavcGdaTbe',
    createdAt: '2022-03-23T10:35:49.427Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1070,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ZfxhcgCfrNELajxys',
    createdAt: '2022-03-23T10:36:09.260Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 302,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'caaD925tzvu4aJjsT',
    createdAt: '2022-03-23T10:36:09.407Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4427,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'FzYJkehJvjSABePZJ',
    createdAt: '2022-03-23T10:36:09.521Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1067,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '9sKZjF3AEbBDhcLAG',
    createdAt: '2022-03-23T11:10:36.870Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 160.4,
    },
    transactionType: 'transferOut',
    amount: -8.5,
  },
  {
    _id: 'TuFX6uZPALdntWHxe',
    createdAt: '2022-03-23T11:10:37.038Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KzkxXJByYqcmhzuLX',
    createdAt: '2022-03-23T11:10:37.208Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 148.62000000000037,
    },
    transactionType: 'transferOut',
    amount: -0.21,
  },
  {
    _id: 'RhnRFTFWgrsqqRws9',
    createdAt: '2022-03-23T11:10:37.333Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 888.62,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'Eh8nGcs2koNWBY24s',
    createdAt: '2022-03-23T11:10:37.468Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 506.8,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'B4YyDKuuzwnsDb7Zx',
    createdAt: '2022-03-23T11:10:37.621Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 59.14000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: '3dMqtHWwLWXo7ZADL',
    createdAt: '2022-03-23T11:10:37.738Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 49.66000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.7,
  },
  {
    _id: 'WBzzkqNNcen5nW8cd',
    createdAt: '2022-03-23T11:10:37.854Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 464.8600000000002,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'KYDAFxfm35iNyPac9',
    createdAt: '2022-03-23T11:10:37.978Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8968.689999999986,
    },
    transactionType: 'transferOut',
    amount: -0.21,
  },
  {
    _id: 'EEy7wQ2f5WrZ25sBh',
    createdAt: '2022-03-23T11:10:38.117Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1546.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'NgTR2s8zfbati2PsN',
    createdAt: '2022-03-23T11:10:38.308Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZxcRohhvRR8fmQgWG',
    createdAt: '2022-03-23T11:10:38.448Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 502,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'Mmb9m9GSkMpxG7P5N',
    createdAt: '2022-03-23T11:10:38.697Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'ddDc4o334EDGZhhoJ',
    createdAt: '2022-03-23T11:38:31.060Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7279,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'cnA3jTNX8mB4PCfJy',
    createdAt: '2022-03-23T11:38:31.200Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 145.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SimPWCSMLNKgLkY8h',
    createdAt: '2022-03-23T11:38:31.313Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9p96esMy3SLb7uSNS',
    createdAt: '2022-03-23T11:38:31.433Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2846,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'mZrt8GesDutDkWJYD',
    createdAt: '2022-03-23T11:38:31.564Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8968.479999999987,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'JhsqAsd7fdtGhXhkd',
    createdAt: '2022-03-23T11:38:31.684Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 136615.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'tEvjnP2ABh775LESK',
    createdAt: '2022-03-23T11:38:31.802Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 59.099999999999994,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'rMpdS9HfRo9vYhwAd',
    createdAt: '2022-03-23T11:38:31.917Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21682.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'uMx8SDra5YWF5pEWs',
    createdAt: '2022-03-23T11:38:32.033Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1844,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'EjKQeQ9xgBSYouXp4',
    createdAt: '2022-03-23T12:50:43.227Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3810,
    },
  },
  {
    _id: 'KoeGmvuxhH6nWc8Wj',
    createdAt: '2022-03-23T12:52:04.450Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4140,
    },
  },
  {
    _id: 'PeH9nyaxeGBzGFvYX',
    createdAt: '2022-03-23T12:54:26.791Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 515,
    },
  },
  {
    _id: 'obveSmSvNd7ef8LFF',
    createdAt: '2022-03-23T12:55:02.734Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1592,
    },
  },
  {
    _id: 'mk2g4WP9LkARQZJBy',
    createdAt: '2022-03-23T12:55:51.009Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1083,
    },
  },
  {
    _id: 'fcaaiG8EzN453HGHP',
    createdAt: '2022-03-23T13:15:52.304Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 0.6000000000003638,
    },
  },
  {
    _id: 'LkpwawGsPuEejn9xf',
    createdAt: '2022-03-23T13:24:08.495Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6979,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'ncaG2p6JDw4tEznKq',
    createdAt: '2022-03-23T13:24:08.655Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 145.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 't6Br2iJFRePDAMnsN',
    createdAt: '2022-03-23T13:24:08.775Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j2CcF2kQ8zSypFCXQ',
    createdAt: '2022-03-23T13:24:08.890Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2841,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: 'Asf4ZTnyi4easHAoX',
    createdAt: '2022-03-23T13:24:09.008Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 2000.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'CaphLtkwxvpdanRwq',
    createdAt: '2022-03-23T13:24:09.123Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17084.6,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'fht9c6p7QE8hF3DDQ',
    createdAt: '2022-03-23T13:24:09.241Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17216.9,
    },
    transactionType: 'transferOut',
    amount: -13.5,
  },
  {
    _id: 'yLATqvmaHoYywsSzb',
    createdAt: '2022-03-23T13:24:09.388Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 749,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'zAC4e8TNmALEGsxwa',
    createdAt: '2022-03-23T13:24:09.532Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4340,
    },
    transactionType: 'transferOut',
    amount: -69,
  },
  {
    _id: 'cJtCYzGQRZoQbMR5j',
    createdAt: '2022-03-23T13:52:54.472Z',
    transactionType: 'in',
    amount: 3200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5979,
    },
  },
  {
    _id: 'WoLgsTkshayZrcTWC',
    createdAt: '2022-03-23T14:09:20.762Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 145.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '5N3Bfgi5R2tmt6wGW',
    createdAt: '2022-03-23T14:09:20.913Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'y4fki4sWQgtCE87BY',
    createdAt: '2022-03-23T14:09:21.053Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9179,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rGG4ANxfqLgJevuBQ',
    createdAt: '2022-03-23T14:09:21.171Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2807,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Fbx4pp44ovT2MJb3c',
    createdAt: '2022-03-23T14:09:21.296Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WfJjuhDY8vmkMyG2J',
    createdAt: '2022-03-23T14:09:21.417Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17203.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'L4Sc5DtzW6ZCLFQ6m',
    createdAt: '2022-03-23T14:09:21.540Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LoA3WP678WnZ4JE22',
    createdAt: '2022-03-23T14:09:21.659Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 50.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'SB7BxWgmfXTeSJeeb',
    createdAt: '2022-03-23T14:09:21.780Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 15.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'TBKdJiz2jBNNAkyQ6',
    createdAt: '2022-03-23T14:09:21.904Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 464.5600000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '9yikShDNWPqPG3zgY',
    createdAt: '2022-03-23T14:09:22.018Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8966.479999999987,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'xTmosvjRMfBmpDdiT',
    createdAt: '2022-03-23T14:09:22.129Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5105,
    },
    transactionType: 'transferOut',
    amount: -78,
  },
  {
    _id: 'm55Y9MNp5k2NaxP2E',
    createdAt: '2022-03-23T14:47:11.434Z',
    transactionType: 'transferIn',
    amount: 3500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: -654.0199999999932,
    },
  },
  {
    _id: 'd6KQ4bn3xBwoFfW27',
    createdAt: '2022-03-23T14:51:12.752Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2845.980000000007,
    },
    transactionType: 'transferOut',
    amount: -660,
  },
  {
    _id: 'Q32TQkMtt3rm6Z24A',
    createdAt: '2022-03-23T14:51:12.896Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QGEttPcsJe3Mxhi2w',
    createdAt: '2022-03-23T14:51:13.019Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9179,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oXWmhniDtQsbW4X7w',
    createdAt: '2022-03-23T14:51:13.137Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2807,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'HmJt2JfYY5ZDFnxB8',
    createdAt: '2022-03-23T14:51:13.265Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1960.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'aj7KdhMLkvZJL5niE',
    createdAt: '2022-03-23T14:51:13.381Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 135115.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '99zSghZvBvXZSqk9z',
    createdAt: '2022-03-23T14:51:13.502Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17057.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '3dDotkP6qHqfGobRK',
    createdAt: '2022-03-23T14:51:13.637Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 104,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ohePPbdJ5gmZfb4tz',
    createdAt: '2022-03-23T14:51:13.776Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 589,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'j54bnZEidSCCfK7Q5',
    createdAt: '2022-03-23T14:51:13.914Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4271,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'zKhxLB8n5DhpTkZy9',
    createdAt: '2022-03-23T15:48:43.853Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9179,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '3bfwgnbmj4R9JJa4y',
    createdAt: '2022-03-23T15:48:43.993Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2185.980000000007,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'egXiScofBhpGsMBjk',
    createdAt: '2022-03-23T15:48:44.114Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 590,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'EjYcHKnPxqaokKuv5',
    createdAt: '2022-03-23T15:48:44.231Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 456.5600000000002,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'bqBnyae8SrjAJtibt',
    createdAt: '2022-03-23T15:48:44.345Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2092,
    },
    transactionType: 'transferOut',
    amount: -282,
  },
  {
    _id: 'u3osYZefMCbP5Ab9b',
    createdAt: '2022-03-23T15:48:44.457Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1113,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '5LRsCGdekc9NSRFKb',
    createdAt: '2022-03-23T21:09:36.229Z',
    transactionType: 'transferIn',
    amount: 4300,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: '9Z53DNuR7kM3zzEFL',
    createdAt: '2022-03-24T06:55:09.524Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: '848o64DfSBx8FgqYu',
    createdAt: '2022-03-24T07:43:00.195Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8965.879999999986,
    },
  },
  {
    _id: 'T5HB7zsM9AtLPEKxa',
    createdAt: '2022-03-24T07:44:34.693Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 933,
    },
  },
  {
    _id: 'CW4nqm8FDXHd9X6Ct',
    createdAt: '2022-03-24T07:45:09.682Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8421,
    },
  },
  {
    _id: 'qvE3xMFXfSnqkprmd',
    createdAt: '2022-03-24T07:58:28.628Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'tfSs32eicmnsJW9BX',
    createdAt: '2022-03-24T07:58:28.739Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1596,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yz6cxFri7LwoW8ibL',
    createdAt: '2022-03-24T07:58:28.851Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 237,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '6R52qsFFq2cPXFPzf',
    createdAt: '2022-03-24T08:03:48.255Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8979,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'enBrbGQ3GmQf88L8y',
    createdAt: '2022-03-24T08:03:48.400Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1685.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GEoXrbNuTJ4n9yyn6',
    createdAt: '2022-03-24T08:03:48.541Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kZQrGaEbFM6oDXEWn',
    createdAt: '2022-03-24T08:03:48.659Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2788,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wcX4TM8yeQw7L4zB3',
    createdAt: '2022-03-24T08:03:48.794Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1924.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'FmKC2dRNjCKYkpHmF',
    createdAt: '2022-03-24T08:03:48.913Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134815.3,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'MfamcNdeWGhqfJgi5',
    createdAt: '2022-03-24T08:03:49.029Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 89,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'zsNPphYogxDX9TAst',
    createdAt: '2022-03-24T08:03:49.149Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17033.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '7KPGv58yGg3gxGGf8',
    createdAt: '2022-03-24T08:03:49.271Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17191.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'au9sxJ6rXWoAAX7gG',
    createdAt: '2022-03-24T08:03:49.389Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 469,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '3hvay2PLnvL4wWXcy',
    createdAt: '2022-03-24T08:03:49.509Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4221,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'iZHThgsnutCTz6Rhr',
    createdAt: '2022-03-24T08:09:07.799Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'A7NCvdKgL4mdWzL6p',
    createdAt: '2022-03-24T08:09:07.936Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1685.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -580,
  },
  {
    _id: 'b4h2WyxEaYeLnojcH',
    createdAt: '2022-03-24T08:09:08.055Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gq7RrvQW2RFSQie9q',
    createdAt: '2022-03-24T08:09:08.167Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2778,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rN5BJjGKeGcecS485',
    createdAt: '2022-03-24T08:09:08.278Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LfvgP6gt5PPQDivtb',
    createdAt: '2022-03-24T08:09:08.393Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.5600000000002,
    },
    transactionType: 'transferOut',
    amount: -5.5,
  },
  {
    _id: '8Gp9ZH9qqtS5TzqYF',
    createdAt: '2022-03-24T08:09:08.513Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 49.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'qZdu96yeuLHCkHuZq',
    createdAt: '2022-03-24T08:09:08.746Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17191.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LEMBqJyxmjt2Apph4',
    createdAt: '2022-03-24T08:09:08.859Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 4010,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: '7YYgbQGWrhGYpPtf2',
    createdAt: '2022-03-24T08:10:58.269Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7mDBWp5Z36RBC3nCk',
    createdAt: '2022-03-24T08:10:58.406Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1105.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'CdpPvWmXj7cLqPoBz',
    createdAt: '2022-03-24T08:10:58.515Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nQfwCGdz9YK2Mo662',
    createdAt: '2022-03-24T08:10:58.634Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2778,
    },
    transactionType: 'transferOut',
    amount: -3.6,
  },
  {
    _id: 'jQnd7x5nCkPXsMEAX',
    createdAt: '2022-03-24T08:10:58.745Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1909.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'azTK7v5bCmLd5tHsS',
    createdAt: '2022-03-24T08:10:58.860Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134665.3,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'v9r5P6HJ8mrqcjEiG',
    createdAt: '2022-03-24T08:10:58.970Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17009.6,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'oWsrH3DnS8xBScM3i',
    createdAt: '2022-03-24T08:10:59.108Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1595,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: '92srWydu9836GB2zs',
    createdAt: '2022-03-24T08:21:06.161Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 151.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'kd3EcD3cppG7RpZmb',
    createdAt: '2022-03-24T08:21:06.319Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dXPEaZYKCPkMCvJzD',
    createdAt: '2022-03-24T08:21:06.462Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 148.41000000000037,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'hCbrrEYvEAAQDTreN',
    createdAt: '2022-03-24T08:21:06.574Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 888.22,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'h5CNrruix8oqEGw8m',
    createdAt: '2022-03-24T08:21:06.690Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 506,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'uis8Fcd3NK9T7QH35',
    createdAt: '2022-03-24T08:21:06.802Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 58.84000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'ovE7xGYn2SJAE7oKQ',
    createdAt: '2022-03-24T08:21:06.932Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 48.96000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'o7enoaykjYWxihpSQ',
    createdAt: '2022-03-24T08:21:07.044Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 445.0600000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'SFwxy5jLDrns5uy7o',
    createdAt: '2022-03-24T08:21:07.155Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9215.879999999986,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '73itTzKJRBW3zgkoc',
    createdAt: '2022-03-24T08:21:07.273Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1536.1,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'diLXRqJpEwTvWnZnA',
    createdAt: '2022-03-24T08:21:07.386Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8DtozsEJw9Dmw4fFP',
    createdAt: '2022-03-24T08:21:07.500Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 447,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'b8uLow2zJLjgJ5uBR',
    createdAt: '2022-03-24T08:21:07.723Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Se7w2gXKNG6jfFGBb',
    createdAt: '2022-03-24T08:24:05.840Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kQ78CheYQzcZLBSP8',
    createdAt: '2022-03-24T08:24:05.975Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 985.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -260,
  },
  {
    _id: 'ETb28Z44HwYEyTgsn',
    createdAt: '2022-03-24T08:24:06.086Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'A9wtXJeCwDycA3jzA',
    createdAt: '2022-03-24T08:24:06.198Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2774.4,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'YwF7ruME8XBXzdXjQ',
    createdAt: '2022-03-24T08:24:06.317Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1903.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'ERjyMPonCD756J6CM',
    createdAt: '2022-03-24T08:24:06.432Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134605.3,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'W6FyNCdST3vJN6GNi',
    createdAt: '2022-03-24T08:24:06.543Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 83,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'L6M92cbMZgaXW37CG',
    createdAt: '2022-03-24T08:24:06.656Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 17004.6,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '6wCyurirFG5GFEmzu',
    createdAt: '2022-03-24T08:24:06.769Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17191.4,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'ckdXwnqAfbTfAWANu',
    createdAt: '2022-03-24T08:24:06.883Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 419,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'HBYrRJB8sCapfq8rE',
    createdAt: '2022-03-24T08:24:06.991Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4196,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'WeRdDqvMskqE2SaXu',
    createdAt: '2022-03-24T08:26:42.557Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 725.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -530,
  },
  {
    _id: 'xQD9gkCiWs6LfANjy',
    createdAt: '2022-03-24T08:26:42.694Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D35RTht7TGwy5iMRH',
    createdAt: '2022-03-24T08:26:42.802Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mReiWBe2yEPcJApdo',
    createdAt: '2022-03-24T08:26:42.915Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2766.9,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'b4odXKsdWktigkFdy',
    createdAt: '2022-03-24T08:26:43.031Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1890.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'G7S2Y66y8YxAXt88y',
    createdAt: '2022-03-24T08:26:43.144Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134475.3,
    },
    transactionType: 'transferOut',
    amount: -260,
  },
  {
    _id: 'tPbr2YtQk7ZsccJK9',
    createdAt: '2022-03-24T08:26:43.258Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16994.6,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'Jbi9HeW9N3Rpz5CAN',
    createdAt: '2022-03-24T08:26:43.370Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 77,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'PHEjFRDiq7jHhHkCq',
    createdAt: '2022-03-24T08:26:43.483Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 375,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'f482Hv53TS8ydXfY3',
    createdAt: '2022-03-24T08:26:43.595Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4177,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'qFj2oteBc4iTxYPzg',
    createdAt: '2022-03-24T08:34:40.911Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 195.98000000000684,
    },
  },
  {
    _id: 'nvdnWBb5F7oTBTtnh',
    createdAt: '2022-03-24T08:37:37.159Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1195.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -810,
  },
  {
    _id: 'Asqv9jLsTCjGzrQxZ',
    createdAt: '2022-03-24T08:37:37.295Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sTT8ycL6fWCEfP5hs',
    createdAt: '2022-03-24T08:37:37.404Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'E5dtozu8eyhXBoZX9',
    createdAt: '2022-03-24T08:37:37.511Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2750.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cqxCxdmKC6As9D22y',
    createdAt: '2022-03-24T08:37:37.624Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6yuvfdFCrDWc6QMXJ',
    createdAt: '2022-03-24T08:37:37.739Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17186.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'amzThqBW42s7ZBx24',
    createdAt: '2022-03-24T08:37:37.878Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Q9sRprF7pejQTjZg8',
    createdAt: '2022-03-24T08:37:37.989Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 48.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vnuv99um5F7xF94q2',
    createdAt: '2022-03-24T08:37:38.103Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 15.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '5AQ9rWhuB6C9GnQky',
    createdAt: '2022-03-24T08:37:38.217Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 444.6600000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '4RFqhMWLguGYRLhq2',
    createdAt: '2022-03-24T08:37:38.333Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9215.629999999986,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'iGC5d5DvJy7ctxQ9Q',
    createdAt: '2022-03-24T08:37:38.445Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5027,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'D2funEZT4A4ZtMaN8',
    createdAt: '2022-03-24T10:40:03.859Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 503,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wf6wu9HnCWWhLSkT4',
    createdAt: '2022-03-24T10:40:04.002Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 887.72,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '8pwctbKhzjcjzsMeq',
    createdAt: '2022-03-24T10:40:04.131Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 148.16000000000037,
    },
    transactionType: 'transferOut',
    amount: -0.28,
  },
  {
    _id: 'iq5CN49QBMB2rA6gx',
    createdAt: '2022-03-24T10:40:04.249Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2750.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'skPeYxTkr2fY2dLBh',
    createdAt: '2022-03-24T10:40:04.539Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'rZJFG5SiBRv693jwu',
    createdAt: '2022-03-24T10:40:24.578Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 385.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'cdcttH3PWd935APny',
    createdAt: '2022-03-24T10:40:24.720Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rSsJTFrmEnTay9bJy',
    createdAt: '2022-03-24T10:40:24.834Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2747.9,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '94o3Xrkt8HErz5PTd',
    createdAt: '2022-03-24T10:40:24.949Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'oaNn3k2SpGs6SSY2w',
    createdAt: '2022-03-24T10:40:25.104Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 134215.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'tsHKZkyEXGbnD5mir',
    createdAt: '2022-03-24T10:40:25.218Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9215.029999999986,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'fnbmXiT2v59xgNAZR',
    createdAt: '2022-03-24T10:40:25.334Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4136,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'SsdoZzmypoqkNighW',
    createdAt: '2022-03-24T11:43:24.115Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 139.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '2hSu7spJXCneekS3X',
    createdAt: '2022-03-24T11:43:24.276Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'k8cuL2X6muZrTbHFd',
    createdAt: '2022-03-24T11:43:24.486Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 147.88000000000036,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'shgi2ej3Pr5KjHR8x',
    createdAt: '2022-03-24T11:43:24.601Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 870.72,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'QgAyhck2MKWjv8PGp',
    createdAt: '2022-03-24T11:43:24.715Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 493,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'nn8uHkvYax8ewXouW',
    createdAt: '2022-03-24T11:43:24.831Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 58.44000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'mWJyBSgzdQPZS9Dkk',
    createdAt: '2022-03-24T11:43:24.948Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 48.06000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'XfLLDhquCtv3ecxdT',
    createdAt: '2022-03-24T11:43:25.061Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 436.6600000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'pNJcqJkcx5aNftJ7k',
    createdAt: '2022-03-24T11:43:25.230Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9212.029999999986,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'xsQ4LNe96t4h4NMqF',
    createdAt: '2022-03-24T11:43:25.356Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1524.1,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'kpZ7Bfy7GiMcgCZQG',
    createdAt: '2022-03-24T11:43:25.477Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ysv5SqBcLbHHbCGJR',
    createdAt: '2022-03-24T11:43:25.596Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 383,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'PSouyMaTBrhx7aYtn',
    createdAt: '2022-03-24T11:43:25.827Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 4,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Qyuhd9DpLCxb3aFFu',
    createdAt: '2022-03-24T11:55:01.926Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 673.5,
    },
    transactionType: 'transferOut',
    amount: -12.5,
  },
  {
    _id: 'HkT5y55aLdFu2uSDS',
    createdAt: '2022-03-24T11:55:02.158Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 156.19999999999996,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: 'ot2AtSGBiy3jgKNYp',
    createdAt: '2022-03-24T11:55:02.293Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 132.5,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Rhm7vHjqtdJmzymB6',
    createdAt: '2022-03-24T11:55:02.438Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4076,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'TxRiM4PWPDbk5jXgy',
    createdAt: '2022-03-24T12:04:49.512Z',
    transactionType: 'transferIn',
    amount: 1500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 135.98000000000684,
    },
  },
  {
    _id: 'mHejaMEyWcsiWJKj7',
    createdAt: '2022-03-24T12:05:22.869Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dBF4fz8eGetzhmEL3',
    createdAt: '2022-03-24T12:05:23.009Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 135.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '9L23QEZWC9kCQnQjy',
    createdAt: '2022-03-24T12:05:23.151Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'K9nCuSucW2j4ukHAE',
    createdAt: '2022-03-24T12:05:23.286Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131915.3,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'qBD6bPF9xkFcus7uL',
    createdAt: '2022-03-24T12:05:23.403Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 58.8,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'vLz6ybfF7BC8CyuTh',
    createdAt: '2022-03-24T12:05:23.517Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1543,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oqbSSgmMqrXifKqMW',
    createdAt: '2022-03-24T12:05:23.628Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1810,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'uzEX4EvgDSD2YqBRd',
    createdAt: '2022-03-24T12:07:53.152Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 180,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: '3Pt454nC4pxdr8oKn',
    createdAt: '2022-03-24T12:07:53.290Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1865.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'uX9AR9twzkWMbLaXt',
    createdAt: '2022-03-24T12:07:53.403Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 363.5,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'JqMoHtsdLewgBn79r',
    createdAt: '2022-03-24T12:07:53.513Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21672.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'puhYJMLFqCmPQ8xsC',
    createdAt: '2022-03-24T12:07:53.623Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1834,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'MrRA5xT3XsH4oDNi8',
    createdAt: '2022-03-24T12:07:53.735Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 236,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'JK3yiThNAKN8Ea3Sb',
    createdAt: '2022-03-24T12:08:34.788Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1560.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'u2z3d929p982uWBgQ',
    createdAt: '2022-03-24T12:08:34.920Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fMgWuCqTpk4Z2ahZR',
    createdAt: '2022-03-24T12:08:35.033Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'a6bF5s62MhwBiTzLp',
    createdAt: '2022-03-24T12:08:35.145Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2697.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HBkpMd5EnYsB4KYnA',
    createdAt: '2022-03-24T12:08:35.257Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9jcpP7EdiWoNYsRwT',
    createdAt: '2022-03-24T12:08:35.369Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17174.4,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ufYfyBiXRJQgoXZkQ',
    createdAt: '2022-03-24T12:08:35.484Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eYz7rxiy4pQZGqneE',
    createdAt: '2022-03-24T12:08:35.602Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 47.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'tdBovwBpoxZzFzuqn',
    createdAt: '2022-03-24T12:08:35.712Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 14.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ypJBJirQyKGvmTEbT',
    createdAt: '2022-03-24T12:08:35.833Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 436.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '2MuQiviFaj4kSq3yt',
    createdAt: '2022-03-24T12:08:35.968Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9211.779999999986,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'vbkAT9HrYhKbKSLhg',
    createdAt: '2022-03-24T12:08:36.098Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4952,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '6x6EPJyYosbY6T6a6',
    createdAt: '2022-03-24T12:09:09.493Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'HYLMsz7ZYk2ESEpQS',
    createdAt: '2022-03-24T12:09:09.623Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 760.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'aaSLbvjWBXr8S9Gpp',
    createdAt: '2022-03-24T12:09:09.759Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 530,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'xSsqKeSFWC4p8TSeR',
    createdAt: '2022-03-24T12:09:09.873Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 428.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'JFFy8TravPZXh484F',
    createdAt: '2022-03-24T12:09:09.981Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1765,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'XKHKMcZZH2rzgL5ZX',
    createdAt: '2022-03-24T12:09:10.090Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1098,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'iiCCzQrkEq76QHxYh',
    createdAt: '2022-03-24T12:11:16.790Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 510.98000000000684,
    },
  },
  {
    _id: 'rRW2nhERv377nAKxh',
    createdAt: '2022-03-24T12:12:38.792Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8629,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aazMQfHC5eTbWosEJ',
    createdAt: '2022-03-24T12:12:38.925Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2510.980000000007,
    },
    transactionType: 'transferOut',
    amount: -530,
  },
  {
    _id: 'obKBfZHifmt2DBMfz',
    createdAt: '2022-03-24T12:12:39.040Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2697.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pXuocTAnk7Es6zrxf',
    createdAt: '2022-03-24T12:12:39.151Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fdc2Ma8pxdzfSapDM',
    createdAt: '2022-03-24T12:12:39.263Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9211.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '94pYRjoWd9tkspW7t',
    createdAt: '2022-03-24T12:12:39.377Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17154.4,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Y5iTc25uSvDEebhCX',
    createdAt: '2022-03-24T12:12:39.485Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 14.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'mizBTjKCk3CQdKSAx',
    createdAt: '2022-03-24T12:12:39.593Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4877,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'LicxnihZd8sm6CYcp',
    createdAt: '2022-03-24T12:12:39.946Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 661,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'JpfZ9uo2bqWiJSLD2',
    createdAt: '2022-03-24T12:16:07.864Z',
    transactionType: 'in',
    amount: 10000,
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 30,
    },
  },
  {
    _id: 'KaaQ74RNBeumm5W8n',
    createdAt: '2022-03-24T12:16:37.368Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 10030,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'DLoBgTbzfmgtPiGcS',
    createdAt: '2022-03-24T12:16:37.476Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9210.179999999986,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'suB4aJa4FLXjd4Hve',
    createdAt: '2022-03-24T12:16:37.597Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131715.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '8jYTSuP4JP5836BJM',
    createdAt: '2022-03-24T12:16:37.711Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17149.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hJtySGhXZD5acX3TR',
    createdAt: '2022-03-24T12:16:37.973Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 200,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'F8wb3GEC3ESjonEqs',
    createdAt: '2022-03-24T12:18:07.435Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8629,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'zgQLGn9XMLtfpDgrX',
    createdAt: '2022-03-24T12:18:07.575Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 8.799999999999997,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Ec9YrqkLiyWDaszTS',
    createdAt: '2022-03-24T12:18:07.687Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 131215.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'DswDJJASEYrcjBPyE',
    createdAt: '2022-03-24T12:18:07.798Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1861.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'EgvGNYegvKBSBL6pi',
    createdAt: '2022-03-24T12:18:07.910Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9010.179999999986,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'ghow2D7nEDPLAfThc',
    createdAt: '2022-03-24T12:18:08.030Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '8HSfYujfneFwWKJF8',
    createdAt: '2022-03-24T12:18:08.143Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4869,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: 'inN9Moa8W9m4E4tvd',
    createdAt: '2022-03-24T12:40:12.887Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5629,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'k3coDgQMAmCHsDtPu',
    createdAt: '2022-03-24T12:40:13.028Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2055.980000000007,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'cqBXh9EgpshcMFEbP',
    createdAt: '2022-03-24T12:40:13.166Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'txRzT6trkwDnDtiY9',
    createdAt: '2022-03-24T12:40:13.309Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 129915.29999999999,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'PPhyfmN5DdjmatYie',
    createdAt: '2022-03-24T12:40:13.452Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 55.8,
    },
    transactionType: 'transferOut',
    amount: -0.05,
  },
  {
    _id: 'ZzuYT2J5tLJbsLTyR',
    createdAt: '2022-03-24T12:40:13.566Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1543,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TP99vgEfSeBBkQwi5',
    createdAt: '2022-03-24T12:40:13.690Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1688,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: '9SYmgiB88jA5Hj73q',
    createdAt: '2022-03-24T12:48:22.246Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1643,
    },
  },
  {
    _id: 'aGinn43rc4LW3otos',
    createdAt: '2022-03-24T12:48:59.016Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2697.9,
    },
  },
  {
    _id: 'sShvNQKtiMkiErwfX',
    createdAt: '2022-03-24T12:49:30.078Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 610,
    },
  },
  {
    _id: 'haeYNMPSmrL7wwipo',
    createdAt: '2022-03-24T12:49:49.795Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 129815.29999999999,
    },
  },
  {
    _id: '6CKEKFwP8WNdTXYN7',
    createdAt: '2022-03-24T12:50:08.529Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 55.75,
    },
  },
  {
    _id: 'JBJC5FKnkGfPoLieA',
    createdAt: '2022-03-24T12:50:39.746Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 425.7600000000002,
    },
  },
  {
    _id: '8onBmPa5sLgQLqdMk',
    createdAt: '2022-03-24T12:51:09.396Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4806,
    },
  },
  {
    _id: 'PavdbehiSp5LaJ3mA',
    createdAt: '2022-03-24T12:51:28.702Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3878,
    },
  },
  {
    _id: 'KYQ576nRbuvQFAmCH',
    createdAt: '2022-03-24T12:52:25.403Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 313,
    },
  },
  {
    _id: 'ifLDk4FtpJe9MeLqd',
    createdAt: '2022-03-24T12:52:59.162Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'KL963CfGtsumaJnw8',
    createdAt: '2022-03-24T12:54:11.590Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 129.5,
    },
  },
  {
    _id: 'RJDBL5GTsZz4gCdWG',
    createdAt: '2022-03-24T12:58:38.318Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 741,
    },
    transactionType: 'transferOut',
    amount: -24.5,
  },
  {
    _id: '2arN6yr5cauXDHMHy',
    createdAt: '2022-03-24T12:58:38.479Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 151.69999999999996,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'myG9sD78AKegTrKRd',
    createdAt: '2022-03-24T12:58:38.595Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 141.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kM439nP92NjMYLd45',
    createdAt: '2022-03-24T12:58:38.707Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4070,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CnCBf6ibEtcN7JHxW',
    createdAt: '2022-03-24T12:58:38.819Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5086,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'cDEupShKpPM88p2mP',
    createdAt: '2022-03-24T17:53:31.605Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1980.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -820,
  },
  {
    _id: '6kLA2tvKM3QfYxEMf',
    createdAt: '2022-03-24T17:53:31.741Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xs4Pyuz7fh4Gz4wn9',
    createdAt: '2022-03-24T17:53:31.852Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5629,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'e8iWWez9wu2sH33rf',
    createdAt: '2022-03-24T17:53:31.962Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2797.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'JdQnYHdfG3Y3jTJEh',
    createdAt: '2022-03-24T17:53:32.076Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '4Y5Qi2aXtw6uwDJR4',
    createdAt: '2022-03-24T17:53:32.190Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17149.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'wqDt9LhRCd8tsLFqv',
    createdAt: '2022-03-24T17:53:32.303Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gvfAft6FMoY9B4Eth',
    createdAt: '2022-03-24T17:53:32.416Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 13.099999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'iAf22jZv7CcenB9F6',
    createdAt: '2022-03-24T17:53:32.529Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 46.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '3EuA3qxjg5ZJ5Xczk',
    createdAt: '2022-03-24T17:53:32.642Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '37TPcLQHu5P3zgwSp',
    createdAt: '2022-03-24T17:53:32.753Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8860.179999999986,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'KK7ZdfiGevm6F9nwR',
    createdAt: '2022-03-24T17:53:32.864Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5083,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'a4bkoD8chLMJa7To5',
    createdAt: '2022-03-25T05:02:16.361Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1160.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -480,
  },
  {
    _id: 'ypumu2BJPwbwkkxTn',
    createdAt: '2022-03-25T05:02:16.488Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JRnF2pqdtEMdH2YeC',
    createdAt: '2022-03-25T05:02:16.613Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5629,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sHAEr4dWjNnJLoXtS',
    createdAt: '2022-03-25T05:02:16.739Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1671.6000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CMheGugJ3dF4cHDR4',
    createdAt: '2022-03-25T05:02:16.867Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 12.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'XzAzXFX5r56GDRxWf',
    createdAt: '2022-03-25T05:02:16.971Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8859.579999999985,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'evFWYMKXwCbEJcqe3',
    createdAt: '2022-03-25T05:02:17.667Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17137.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'tKBLiXcxWSAdqjWSS',
    createdAt: '2022-03-25T05:02:17.788Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3978,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'B4cuWq5Yr8zjzevzu',
    createdAt: '2022-03-25T06:28:13.141Z',
    transactionType: 'in',
    amount: 3071,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5629,
    },
  },
  {
    _id: '5Zt6dugKfnGcxLJut',
    createdAt: '2022-03-25T06:51:02.080Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11700,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'xsYQNaTcXqPTXuytc',
    createdAt: '2022-03-25T06:51:02.223Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 108.75,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'duWC8bZMDTNMBKyvF',
    createdAt: '2022-03-25T06:51:02.343Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 181315.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: '3C9miPdx4aHLJtPdA',
    createdAt: '2022-03-25T06:51:02.456Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1861.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -185,
  },
  {
    _id: 'fY7M8iBXqMoSXPzir',
    createdAt: '2022-03-25T06:51:02.569Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 9008.779999999986,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'Ry6SW4stKw7jpfCCA',
    createdAt: '2022-03-25T06:51:02.713Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1200,
    },
    transactionType: 'transferOut',
    amount: -380,
  },
  {
    _id: 'Xot83uRaDMLpB63dB',
    createdAt: '2022-03-25T06:51:02.833Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5070,
    },
    transactionType: 'transferOut',
    amount: -62,
  },
  {
    _id: 'FDLcAhai7hEe4ZjNM',
    createdAt: '2022-03-25T07:20:39.440Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8700,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'g2psTRzeEhhKuAtZu',
    createdAt: '2022-03-25T07:20:39.584Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 105.75,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '7DBPBeK53XdaH8RkM',
    createdAt: '2022-03-25T07:20:39.708Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 179815.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '3tNTbEDDs2h35aoHy',
    createdAt: '2022-03-25T07:20:39.830Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1676.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -108,
  },
  {
    _id: 'B2gPgbwS9NPBRiNTs',
    createdAt: '2022-03-25T07:20:39.948Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8858.779999999986,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'bokjPQnMY8RjxHWYs',
    createdAt: '2022-03-25T07:20:40.074Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 820,
    },
    transactionType: 'transferOut',
    amount: -260,
  },
  {
    _id: '8FBNntE2mKu45uDQo',
    createdAt: '2022-03-25T07:20:40.221Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5008,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'z5pFYP6RCjcoJujqT',
    createdAt: '2022-03-25T07:22:43.354Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6700,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'RdAbKYzNCqomToGwD',
    createdAt: '2022-03-25T07:22:43.496Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 103.75,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '7RpHMrYpZzvv5ZadP',
    createdAt: '2022-03-25T07:22:43.645Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178815.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'Kmz4wNiXLXiEJosyF',
    createdAt: '2022-03-25T07:22:43.784Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1568.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'GDBvbf3pTTXyNkRnX',
    createdAt: '2022-03-25T07:22:43.923Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8758.779999999986,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '674np3xpe6aFQHhdn',
    createdAt: '2022-03-25T07:22:44.081Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 560,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'WvyydtdcFPhFnEjR7',
    createdAt: '2022-03-25T07:22:44.234Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4966,
    },
    transactionType: 'transferOut',
    amount: -66,
  },
  {
    _id: 'kfnpALZKBKzHeGm2a',
    createdAt: '2022-03-25T08:47:24.004Z',
    transactionType: 'in',
    amount: 3200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3700,
    },
  },
  {
    _id: 'rLCcTKazTgeFG4Tiv',
    createdAt: '2022-03-25T08:48:36.004Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 100,
    },
  },
  {
    _id: 'qLYsJupF8B8hCzt2t',
    createdAt: '2022-03-25T10:41:59.353Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6900,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '53RWixNQpMoFL6PFv',
    createdAt: '2022-03-25T10:41:59.496Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 680.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'MhFiqC6uQK5Ex2mub',
    createdAt: '2022-03-25T10:41:59.626Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 505,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'npaoYtwGbha54NrAT',
    createdAt: '2022-03-25T10:41:59.739Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.7600000000002,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'NQe535Ju2obgteiFo',
    createdAt: '2022-03-25T10:41:59.862Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2143,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'jSPyQYG6XLWoPviRq',
    createdAt: '2022-03-25T10:41:59.979Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1092,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'g4E8riNm8bqxZEpT3',
    createdAt: '2022-03-25T11:13:18.302Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 450,
    },
    transactionType: 'transferOut',
    amount: -165,
  },
  {
    _id: 'htNq5cZATai3kx8eA',
    createdAt: '2022-03-25T11:13:18.438Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9061,
    },
    transactionType: 'transferOut',
    amount: -86,
  },
  {
    _id: 'EmKap2bLEftaR5gHH',
    createdAt: '2022-03-25T11:13:18.579Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4390,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'CF9RgjCbkab2rgheh',
    createdAt: '2022-03-25T11:13:18.699Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1064,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'zDXPBwwt8JZH3azaE',
    createdAt: '2022-03-25T11:14:36.399Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 285,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: 'TPGxn72AYb32FfYQA',
    createdAt: '2022-03-25T11:14:36.512Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8975,
    },
    transactionType: 'transferOut',
    amount: -39,
  },
  {
    _id: '6uKfnqoCMkwjZdqBL',
    createdAt: '2022-03-25T11:14:36.619Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1056,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'sA8KfjFNeSXRZm9nj',
    createdAt: '2022-03-25T11:19:05.220Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 247,
    },
    transactionType: 'transferOut',
    amount: -247,
  },
  {
    _id: 'a3wvHxhTwcycNFTME',
    createdAt: '2022-03-25T11:19:05.335Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 4.600000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'Qk6KAZWBeDutiNssv',
    createdAt: '2022-03-25T11:19:05.591Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 284,
    },
    transactionType: 'in',
    amount: 247,
  },
  {
    _id: 'MmC7pNeSWx4YSXDno',
    createdAt: '2022-03-25T11:21:07.503Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 531,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '4sxfSpSjPEp8wChTd',
    createdAt: '2022-03-25T11:21:07.641Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8936,
    },
    transactionType: 'transferOut',
    amount: -101,
  },
  {
    _id: 'pm9Q24izRvdMwyjNm',
    createdAt: '2022-03-25T11:21:07.759Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1053,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'GgCr6R94QiLvcAsKv',
    createdAt: '2022-03-25T11:22:00.573Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 431,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'kSfMT3rKSKDHjCDXb',
    createdAt: '2022-03-25T11:22:00.715Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8835,
    },
    transactionType: 'transferOut',
    amount: -202,
  },
  {
    _id: 'gTysL525wpEg9W8jQ',
    createdAt: '2022-03-25T11:22:00.845Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1044,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'KZfLrCertK7mWHu3F',
    createdAt: '2022-03-25T11:46:20.141Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 8633,
    },
  },
  {
    _id: 'Kc2th3o9ArQMAmraF',
    createdAt: '2022-03-25T12:40:04.656Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 231,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'E27esBGChcsFzzKoc',
    createdAt: '2022-03-25T12:40:04.806Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8608.779999999986,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'qem4YshuZAHZgFCkx',
    createdAt: '2022-03-25T12:40:04.938Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1586,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 't7nDXzGKkLNvnSkyE',
    createdAt: '2022-03-25T13:17:41.580Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '6jHNb4FgH7oMdhwA9',
    createdAt: '2022-03-25T13:17:41.746Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1512.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'kN6KpPPexm9mNyXPu',
    createdAt: '2022-03-25T13:17:41.866Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 47.16000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '6AKbZsoQkLhvkw3KH',
    createdAt: '2022-03-25T13:17:41.984Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 147.63000000000036,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '7YGEzsL57XNP5ffL2',
    createdAt: '2022-03-25T13:17:42.106Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 448.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'YjGGGoy4NJ8bPobk4',
    createdAt: '2022-03-25T13:17:42.226Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 166.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'rNGyBNaifbkNzvw8k',
    createdAt: '2022-03-25T13:17:42.344Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8608.579999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'JJdqKkQEjCEPwT97e',
    createdAt: '2022-03-25T13:17:42.461Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 492,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XMxhJdrtTLbMSzg2G',
    createdAt: '2022-03-25T13:17:42.588Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 870.22,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'PTPMzPCPzN79o7QjA',
    createdAt: '2022-03-25T13:17:42.708Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JdbjWFNzB9s9vnDE6',
    createdAt: '2022-03-25T13:17:42.837Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'maEtk2xevS99nP6Dy',
    createdAt: '2022-03-25T13:17:42.964Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1086,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'DZPFMtgabg4qreibh',
    createdAt: '2022-03-25T13:20:45.978Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 980,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '4qH5qTQ2Gy9HPsFqN',
    createdAt: '2022-03-25T13:20:46.174Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1482.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'XsfLci33vyzL3adZ4',
    createdAt: '2022-03-25T13:20:46.355Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 46.760000000000055,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'BFPgpyEqhMddGttar',
    createdAt: '2022-03-25T13:20:46.480Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 147.43000000000038,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'JCaFBZMNqcCxgtGae',
    createdAt: '2022-03-25T13:20:46.838Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 446.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'rMKCt8wFn8F3JxsEf',
    createdAt: '2022-03-25T13:20:47.780Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 166.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Xjv6EFPpttcdJSvyJ',
    createdAt: '2022-03-25T13:20:47.925Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8607.979999999985,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'At3so9Mi26HJhu4i4',
    createdAt: '2022-03-25T13:20:48.099Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 492,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eGk53cjh2SBQFXZo2',
    createdAt: '2022-03-25T13:20:48.256Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 859.22,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'KmBxkyRmDBeuFWbHt',
    createdAt: '2022-03-25T13:20:48.374Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f7bxAMyq4HzJDQez5',
    createdAt: '2022-03-25T13:20:48.505Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WmW47auK5uFcePupC',
    createdAt: '2022-03-25T13:20:48.649Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1077,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'ofr87Qf7P6Sk563y2',
    createdAt: '2022-03-25T13:21:29.138Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 960,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'oxu6XFuEh3yx79idT',
    createdAt: '2022-03-25T13:21:29.312Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1452.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'FJcfwEjwh778yJBiP',
    createdAt: '2022-03-25T13:21:29.434Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 46.360000000000056,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'wjFpBvdLPGAHTBwtj',
    createdAt: '2022-03-25T13:21:29.548Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 147.2300000000004,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: '9NPaDsia9LSzWYxdX',
    createdAt: '2022-03-25T13:21:29.663Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 444.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'eMxpGTskyNZLqK2wK',
    createdAt: '2022-03-25T13:21:29.782Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 165.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '2Eb7MgB5M85MwcATL',
    createdAt: '2022-03-25T13:21:29.894Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8606.479999999985,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'p3dE63qraSxuFr47Z',
    createdAt: '2022-03-25T13:21:30.012Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 492,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jduaMyAaF8zrr58Dr',
    createdAt: '2022-03-25T13:21:30.128Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 815.22,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'MN86nB68JNyMo97Zp',
    createdAt: '2022-03-25T13:21:30.256Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8fwwczQ6g9ZNRYniJ',
    createdAt: '2022-03-25T13:21:30.373Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LSto7KebnEgREjzTL',
    createdAt: '2022-03-25T13:21:30.497Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1068,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'CgPGhaKbTQpxuoBNv',
    createdAt: '2022-03-25T13:32:08.876Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 430.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'irRYgH2wQLnDKPSaC',
    createdAt: '2022-03-25T13:32:09.066Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4300,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'MtEqHcXvSW3rtSXT2',
    createdAt: '2022-03-25T13:32:09.206Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2791.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vk8pRpJjzpZrMGXc7',
    createdAt: '2022-03-25T13:32:09.365Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 177315.3,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '9aa6N9BAPnwKfezey',
    createdAt: '2022-03-25T13:32:09.510Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ap24fiWWze2zDFrPJ',
    createdAt: '2022-03-25T13:32:09.660Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8604.979999999985,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9YJk3YdufKdmkFrtb',
    createdAt: '2022-03-25T13:32:09.828Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F87490809-61cf-4c86-8e6c-97be3c8f9d18_200x200.jpeg?alt=media&token=fac38e50-d6ca-49ef-875e-831d15caaa81',
        },
      },
      quantity: 60,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'DNWTBWpTNLZbkdqMf',
    createdAt: '2022-03-25T13:32:10.030Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 716.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '8gsZi5q2JSToJ9fN4',
    createdAt: '2022-03-25T13:32:10.201Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 100.75,
    },
    transactionType: 'transferOut',
    amount: -0.02,
  },
  {
    _id: 'cSENidvFitqgrdazj',
    createdAt: '2022-03-25T13:32:10.341Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17127.4,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'PJMTPdTTYLqC9jeSo',
    createdAt: '2022-03-25T13:32:10.508Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21672.4,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'EnKsp6QvWeKcd8BiM',
    createdAt: '2022-03-25T13:32:10.660Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4900,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GKGD8oJmeDSd8FgsH',
    createdAt: '2022-03-25T13:32:10.800Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1831,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '6RvSvaa4ghcvkLvNu',
    createdAt: '2022-03-25T13:32:10.940Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1059,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'trDPdhjA4kAPs8gf2',
    createdAt: '2022-03-25T13:39:38.541Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 186,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'KvH6wC256fDR63tsm',
    createdAt: '2022-03-25T13:39:38.688Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1536,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'N7Xst4987SgnaLjCa',
    createdAt: '2022-03-25T13:39:38.802Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 232,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hPNBmrpjj5myazkqW',
    createdAt: '2022-03-25T13:40:28.747Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3948,
    },
  },
  {
    _id: 'yQc8ea527r3oRxz75',
    createdAt: '2022-03-25T13:40:45.541Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8604.979999999985,
    },
  },
  {
    _id: 'dovBPHEZAeECHPEat',
    createdAt: '2022-03-25T13:41:02.993Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4070,
    },
  },
  {
    _id: 'Ec7dC5pG7QLPkQEvW',
    createdAt: '2022-03-25T13:41:28.672Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1059,
    },
  },
  {
    _id: '9E3biCfwx37NxTqrr',
    createdAt: '2022-03-25T13:41:50.135Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1026,
    },
  },
  {
    _id: 'Akht69bFSGSq2pYkQ',
    createdAt: '2022-03-25T13:42:27.195Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 848.22,
    },
  },
  {
    _id: 'SP56BgfPGtjgKNMzS',
    createdAt: '2022-03-25T13:44:28.256Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 960,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '5gTLYZmJ9kRT6ZBxu',
    createdAt: '2022-03-25T13:44:28.421Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1452.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'ZFxDnFFGn7FER3Yvt',
    createdAt: '2022-03-25T13:44:28.556Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 46.360000000000056,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'pdw76deT5JpkQpsk9',
    createdAt: '2022-03-25T13:44:28.693Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 147.2300000000004,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'g2Y65syST4zp9FZDw',
    createdAt: '2022-03-25T13:44:28.805Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 444.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'ct6qo2tziZFkqpKhc',
    createdAt: '2022-03-25T13:44:28.921Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 165.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'GDjkZ7TYhhE37aDgS',
    createdAt: '2022-03-25T13:44:29.088Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8781.479999999985,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'A2nKz7PnrwSFCJBsz',
    createdAt: '2022-03-25T13:44:29.227Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 492,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3hBErhTapgmtW5F5b',
    createdAt: '2022-03-25T13:44:29.349Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 908.22,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'cuzmAZGuvvycxwiuA',
    createdAt: '2022-03-25T13:44:29.464Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bQxf6daHHuycSnfQd',
    createdAt: '2022-03-25T13:44:29.584Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MdRsukyZgZWaukrTz',
    createdAt: '2022-03-25T13:44:29.727Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1143,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'jA7bvfpo7wjm5JfKN',
    createdAt: '2022-03-25T16:10:49.443Z',
    transactionType: 'transferIn',
    amount: 2850,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 430.98000000000684,
    },
  },
  {
    _id: 'JY7mFH5bXmy4hdBuM',
    createdAt: '2022-03-25T16:12:46.174Z',
    transactionType: 'transferIn',
    amount: 4800,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4285,
    },
  },
  {
    _id: 'x4xNkAGJxooMczrm9',
    createdAt: '2022-03-26T05:06:24.195Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3280.980000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QignKvJEskkJACMve',
    createdAt: '2022-03-26T05:06:24.325Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 9085,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: '8tDMvZAp6Bc6PXpki',
    createdAt: '2022-03-26T05:06:24.430Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 100.73,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'RHt2Me3bfMGm5XWR5',
    createdAt: '2022-03-26T05:06:24.536Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 177285.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'Seugj4SYxot8RMjxN',
    createdAt: '2022-03-26T05:06:24.639Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1368.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'jdmvZPW7m8hsS4w93',
    createdAt: '2022-03-26T05:06:24.889Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1392.1,
    },
    transactionType: 'in',
    amount: 155,
  },
  {
    _id: 'RK5spFefD9vmjWNxP',
    createdAt: '2022-03-26T05:09:52.437Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3280.980000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9FdZZTTM63eyYhmiY',
    createdAt: '2022-03-26T05:09:52.596Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 7085,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'uLjqwXdgem3z3hDWS',
    createdAt: '2022-03-26T05:09:52.706Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 98.73,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'dSsfNGGpxQdhJGAuk',
    createdAt: '2022-03-26T05:09:52.823Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 176285.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'CYLTWdhFcYCCmikDt',
    createdAt: '2022-03-26T05:09:52.932Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1242.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'jYEdtZca3ctmMKQLo',
    createdAt: '2022-03-26T05:09:53.214Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1547.1,
    },
    transactionType: 'in',
    amount: 160,
  },
  {
    _id: '9FBcjcLCQbRwbs5FL',
    createdAt: '2022-03-26T05:46:42.708Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6850,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QWYXWHtYyJDY4u4zW',
    createdAt: '2022-03-26T05:46:42.861Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3280.980000000007,
    },
    transactionType: 'transferOut',
    amount: -820,
  },
  {
    _id: 'BAnztuL38DsBmupoo',
    createdAt: '2022-03-26T05:46:42.976Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5085,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QccPcrN4rT7T2fLzW',
    createdAt: '2022-03-26T05:46:43.091Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2791.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bxrg3iGZGnq6ugMQ4',
    createdAt: '2022-03-26T05:46:43.205Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YpAABqFjXjjn3P7PD',
    createdAt: '2022-03-26T05:46:43.322Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 440.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'hoJ8453cD2Rqvu8me',
    createdAt: '2022-03-26T05:46:43.850Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 45.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'bxSEZwqZHusPEiGJx',
    createdAt: '2022-03-26T05:46:44.263Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17126.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'h2vorMXj5P45iBrmD',
    createdAt: '2022-03-26T05:46:44.380Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 4148,
    },
    transactionType: 'transferOut',
    amount: -184,
  },
  {
    _id: '5DytjxsErACQYqkWb',
    createdAt: '2022-03-26T05:49:06.925Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6850,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FsunR33q7GrhMNWe3',
    createdAt: '2022-03-26T05:49:07.080Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2460.980000000007,
    },
    transactionType: 'transferOut',
    amount: -700,
  },
  {
    _id: 'YqG5A8du4Y7bk5Gij',
    createdAt: '2022-03-26T05:49:07.225Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5085,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7CxArFAKBq4tfqKea',
    createdAt: '2022-03-26T05:49:07.363Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2791.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dJJ9YhBhYc7KACqbW',
    createdAt: '2022-03-26T05:49:07.504Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3qQkgAnmRWbQxGkYt',
    createdAt: '2022-03-26T05:49:07.645Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 432.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'zv4pYecqX6dwQfvop',
    createdAt: '2022-03-26T05:49:07.788Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 44.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'crvPGvbxsGHKgRvkg',
    createdAt: '2022-03-26T05:49:08.070Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17114.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '27ykvPuAoFLfc3qsF',
    createdAt: '2022-03-26T05:49:08.209Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3964,
    },
    transactionType: 'transferOut',
    amount: -154,
  },
  {
    _id: '9dZbYrfgZcD2yJaw7',
    createdAt: '2022-03-26T05:59:46.433Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1760.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'wnSMDJ4TMrafbFx5b',
    createdAt: '2022-03-26T05:59:46.562Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6850,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mByycY9mdzR58A2wi',
    createdAt: '2022-03-26T05:59:47.143Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5085,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RLBbtsm5CWJkJeJZL',
    createdAt: '2022-03-26T05:59:47.301Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2791.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5EmtcPw4u353KKcRQ',
    createdAt: '2022-03-26T05:59:47.416Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8778.479999999985,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'uwtow6JPgutFT3Tew',
    createdAt: '2022-03-26T05:59:47.521Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17102.4,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'wTh5s7ky72GD3hmYw',
    createdAt: '2022-03-26T05:59:47.629Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3810,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'xDERtE8Y3iZDNrrBc',
    createdAt: '2022-03-26T06:01:43.699Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1360.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nPTRAs6wxjiWeXPWJ',
    createdAt: '2022-03-26T06:01:43.860Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5085,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'uC2pMoRoBPvenseGb',
    createdAt: '2022-03-26T06:01:43.982Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 96.73,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'fbgcTDjpyhe5rLzuW',
    createdAt: '2022-03-26T06:01:44.129Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175285.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '4fPBCGau2A8SnYmdn',
    createdAt: '2022-03-26T06:01:44.250Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1116.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'shn3qBD6gSWCLRGgz',
    createdAt: '2022-03-26T06:01:44.537Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1707.1,
    },
    transactionType: 'in',
    amount: 75,
  },
  {
    _id: 'WiR7iLdcoS5uMFJf8',
    createdAt: '2022-03-26T06:02:54.293Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1360.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vcCr25MrfjobNJaof',
    createdAt: '2022-03-26T06:02:54.437Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3985,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'b72nqmXZwQwq9BDTE',
    createdAt: '2022-03-26T06:02:54.548Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 95.73,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'FFh5LYESGa6eFDLk3',
    createdAt: '2022-03-26T06:02:54.661Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174285.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'KfgNH8yeHpCb2ZXuY',
    createdAt: '2022-03-26T06:02:54.776Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1080.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'WAo4X4Zuw4aNvLchD',
    createdAt: '2022-03-26T06:02:55.033Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1782.1,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: 'ojfnZPctrz9gi8fyK',
    createdAt: '2022-03-26T06:05:48.172Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1360.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TYA7vRrydbWAnx8Y3',
    createdAt: '2022-03-26T06:05:48.331Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2885,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'sCNsTN9eRsXjQgojs',
    createdAt: '2022-03-26T06:05:48.449Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 94.73,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'zhumEcaXn5DGrDk5o',
    createdAt: '2022-03-26T06:05:48.564Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 173285.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '3ET2BC4gdgWkmvhH5',
    createdAt: '2022-03-26T06:05:48.675Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1044.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'fbJ5LxYxJzEn5mjpY',
    createdAt: '2022-03-26T06:05:49.425Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1862.1,
    },
    transactionType: 'in',
    amount: 80,
  },
  {
    _id: '63MrrJHZGbMnvP3P5',
    createdAt: '2022-03-26T06:07:46.352Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1360.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mZbxkiYrPaa7iY7Nm',
    createdAt: '2022-03-26T06:07:46.497Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1785,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'Q2NNc6J9mZdbDZtYE',
    createdAt: '2022-03-26T06:07:46.611Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 93.73,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'SdKWH3Q8ynL5CQPjp',
    createdAt: '2022-03-26T06:07:46.728Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 172285.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'vy7rWKfwC2xLtqxJq',
    createdAt: '2022-03-26T06:07:46.861Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 1008.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'fqXvgzJS2uCbb76ao',
    createdAt: '2022-03-26T06:07:47.113Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1942.1,
    },
    transactionType: 'in',
    amount: 78,
  },
  {
    _id: '7k5TgCi4rgR6xuP9r',
    createdAt: '2022-03-26T06:15:45.817Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6850,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pKC5YrGsYxdbxaRAg',
    createdAt: '2022-03-26T06:15:45.953Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1360.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'kYXjf28SriELQgE2S',
    createdAt: '2022-03-26T06:15:46.065Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 7.599999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'tprhGdAFiSsPbcSyr',
    createdAt: '2022-03-26T06:15:46.173Z',
    storeItem: {
      type: {
        _id: 'BWEYgyyXW9LZb2vaG',
        name: 'WHITE DAILY 40',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1f6ed39d-17eb-4a2f-906e-3abfb2631b6a_200x200.jpg?alt=media&token=f146a98a-a0b7-4a47-bcfe-5077a6e994e3',
        },
      },
      quantity: 48.3,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'M6YsJCEqBjGxax6zq',
    createdAt: '2022-03-26T06:15:46.282Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171285.3,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'mpsEj8MrEhjvqNGNj',
    createdAt: '2022-03-26T06:15:46.395Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 972.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '3GjyfQW3uKs84Fmyz',
    createdAt: '2022-03-26T06:15:46.503Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8777.679999999986,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'wGKMHcoaiAoqq2fKf',
    createdAt: '2022-03-26T06:15:46.614Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 360.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'PFeSLR236kFz6FynA',
    createdAt: '2022-03-26T06:15:46.749Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1829,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '3cJWyk6yh78z32bK8',
    createdAt: '2022-03-26T06:15:46.860Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 232,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '7fRegD4NykFfZ52QH',
    createdAt: '2022-03-26T07:27:47.170Z',
    transactionType: 'in',
    amount: 4480,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6850,
    },
  },
  {
    _id: 'bmQ2vcnq5947czMMR',
    createdAt: '2022-03-26T07:28:09.789Z',
    transactionType: 'in',
    amount: 400,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'afQAkZkKhpszXNRrd',
    createdAt: '2022-03-26T10:47:32.546Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 400,
    },
    transactionType: 'transferOut',
    amount: -147,
  },
  {
    _id: 'bCB2Pxvh22SgJhdEd',
    createdAt: '2022-03-26T10:47:32.679Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9273,
    },
    transactionType: 'transferOut',
    amount: -62,
  },
  {
    _id: 'mayah2TG9qni6BcTy',
    createdAt: '2022-03-26T10:47:32.790Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4381,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'C6D3GvrKNeT8HQ4Cw',
    createdAt: '2022-03-26T10:47:32.900Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1086,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'bF5kFE7XMm6LP7Dwz',
    createdAt: '2022-03-26T10:48:29.891Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 253,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: 'cECkdHTkcxyEmYnzj',
    createdAt: '2022-03-26T10:48:30.001Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9211,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: 'hhKCMCXuMboozYGHZ',
    createdAt: '2022-03-26T10:48:30.107Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1081,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'wcdjKWdmg4xz5cstW',
    createdAt: '2022-03-26T10:49:53.766Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11330,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'mxjjsbnbFBCEwZB4k',
    createdAt: '2022-03-26T10:49:53.897Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 960.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'hnFkXeRfLvYJNyMeA',
    createdAt: '2022-03-26T10:49:54.003Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Feff882eb-cd0f-445d-8ea9-1ccacc3a9140_200x200.jpg?alt=media&token=76d6220e-6b74-4173-b053-288575e96b38',
        },
      },
      quantity: 480,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'WFu5LHvc9GgMuHqeq',
    createdAt: '2022-03-26T10:49:54.110Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 425.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'sm7Rg3Y354wEy5Rph',
    createdAt: '2022-03-26T10:49:54.220Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2021,
    },
    transactionType: 'transferOut',
    amount: -152,
  },
  {
    _id: 'aGFCGHr3h3mpszm9T',
    createdAt: '2022-03-26T10:49:54.327Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1125,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '4DuSj7QfL9qQ88hxY',
    createdAt: '2022-03-26T11:57:44.578Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 492,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'zy6oSHXNCxoGLGCS6',
    createdAt: '2022-03-26T11:57:44.708Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 886.22,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '2wBAgmjuJ7Ssxr5uj',
    createdAt: '2022-03-26T11:57:44.812Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 146.83000000000038,
    },
    transactionType: 'transferOut',
    amount: -0.28,
  },
  {
    _id: 'jh2T4ujd7FtBr67Eg',
    createdAt: '2022-03-26T11:57:44.917Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2791.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'QkLYTfyg3phfSbP8X',
    createdAt: '2022-03-26T11:57:45.158Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'ueLD9FPiu2wrwg8ru',
    createdAt: '2022-03-26T12:19:49.451Z',
    transactionType: 'in',
    amount: 700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11280,
    },
  },
  {
    _id: 'xN9cJ5tDLHw62AFxf',
    createdAt: '2022-03-26T12:58:59.532Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 450,
    },
  },
  {
    _id: 'zqyYY97hGse2dBJCs',
    createdAt: '2022-03-26T12:59:31.937Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8771.679999999986,
    },
  },
  {
    _id: 'T3Gr7PptFBLMkRdo3',
    createdAt: '2022-03-26T12:59:54.445Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2788.9,
    },
  },
  {
    _id: 'QKXRKarvStJC9p8SC',
    createdAt: '2022-03-26T13:00:19.372Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4900,
    },
  },
  {
    _id: 'fZrqtPxDSc7e9axee',
    createdAt: '2022-03-26T13:00:40.863Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3783,
    },
  },
  {
    _id: 'TxY5eZaBSBJ6z9Ncn',
    createdAt: '2022-03-26T13:01:07.108Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 869.22,
    },
  },
  {
    _id: 'ayoTWKyyFf8vj4ZQf',
    createdAt: '2022-03-26T13:01:59.954Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9178,
    },
  },
  {
    _id: '8Pf7vo3eytp8R89of',
    createdAt: '2022-03-26T13:51:07.433Z',
    transactionType: 'transferIn',
    amount: 1900,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 710.9800000000068,
    },
  },
  {
    _id: '3TCZy3Sa5E84aoz6r',
    createdAt: '2022-03-26T13:52:04.857Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2610.980000000007,
    },
    transactionType: 'transferOut',
    amount: -825,
  },
  {
    _id: 'dGfJDEZoEaPTfsjzY',
    createdAt: '2022-03-26T13:52:04.996Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fCJbFweDphQ6ZYTSL',
    createdAt: '2022-03-26T13:52:05.107Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11980,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5zBfLQ8b676wBuCPp',
    createdAt: '2022-03-26T13:52:05.220Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2863.9,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '2iezwZbCfB6HWP5GF',
    createdAt: '2022-03-26T13:52:05.337Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'RYWZeJoEq535oMLP3',
    createdAt: '2022-03-26T13:52:05.449Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17096.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '2QztwNWp5qfZpbR8T',
    createdAt: '2022-03-26T13:52:06.146Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CyXTMhd4PGcCbW444',
    createdAt: '2022-03-26T13:52:06.378Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 7.349999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'LEifcoFYW2SAWiDnb',
    createdAt: '2022-03-26T13:52:06.492Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 43.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'w7tfM5qeEFcFrgJ2Q',
    createdAt: '2022-03-26T13:52:06.619Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TwPXZqGHefWLHY392',
    createdAt: '2022-03-26T13:52:06.733Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8946.679999999986,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'JLqhxaE3nmNBHqeCZ',
    createdAt: '2022-03-26T13:52:06.843Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5180,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '5CbqHS4Xqb9zPsmEs',
    createdAt: '2022-03-26T13:53:12.342Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1785.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -836,
  },
  {
    _id: 'fZTW8omD4CXZAt3cb',
    createdAt: '2022-03-26T13:53:12.475Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f8JBofyvtjPbB9C6j',
    createdAt: '2022-03-26T13:53:12.582Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11980,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kjiwi2BGQp4nE9mWC',
    createdAt: '2022-03-26T13:53:12.692Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2856.9,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'N9PkiE2SyfZkQEK5d',
    createdAt: '2022-03-26T13:53:12.802Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 126,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '5d2XDFgYYZea2JTip',
    createdAt: '2022-03-26T13:53:12.916Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17084.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '7hF4AHjbmpThjjDw9',
    createdAt: '2022-03-26T13:53:13.028Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aZkPGZhn2wQS5MKjv',
    createdAt: '2022-03-26T13:53:13.138Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 6.849999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'cCEZgSh5RWbrFR7Hh',
    createdAt: '2022-03-26T13:53:13.245Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 42.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'zXERjDM2yHkBfNRa3',
    createdAt: '2022-03-26T13:53:13.352Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qdyACbPkxyZg7Hwkh',
    createdAt: '2022-03-26T13:53:13.461Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8946.079999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '5pNvoXQyYFsSmeR5P',
    createdAt: '2022-03-26T13:53:13.569Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5105,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'KyiGHtc84kagfRTw8',
    createdAt: '2022-03-26T14:01:15.154Z',
    transactionType: 'transferIn',
    amount: 3300,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
  },
  {
    _id: 'iQJvnQEkuuFSHCwWb',
    createdAt: '2022-03-27T16:11:11.089Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 9230,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'iwmPNt6EisTi5azi4',
    createdAt: '2022-03-27T16:11:11.201Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8945.479999999985,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'XNpRWA6GGm8hMickD',
    createdAt: '2022-03-27T16:11:11.312Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171085.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'FHRRbHEiKHBp56rgT',
    createdAt: '2022-03-27T16:11:11.421Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17072.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xPfTnbkfwvZqSNWsN',
    createdAt: '2022-03-27T16:11:11.678Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 160,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'r6bzEvbY2kp3C3Tzc',
    createdAt: '2022-03-27T16:15:02.657Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11980,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: '85WZaRE84kp7JPDo6',
    createdAt: '2022-03-27T16:15:02.806Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 92.73,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'yDE9fAPC8abDZrBAk',
    createdAt: '2022-03-27T16:15:02.921Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 170585.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'z3KMKPRmvk7gacNZi',
    createdAt: '2022-03-27T16:15:03.040Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 948.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: '8JSxEQkaA4ZyN9XBm',
    createdAt: '2022-03-27T16:15:03.171Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8745.479999999985,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'CS8HhXQYwe3FbpJyK',
    createdAt: '2022-03-27T16:15:03.988Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1160,
    },
    transactionType: 'transferOut',
    amount: -372,
  },
  {
    _id: 'YCXhKT7k5kcbToSZt',
    createdAt: '2022-03-27T16:15:04.234Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5029,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: 'RKjc5keEfmqH6kqkf',
    createdAt: '2022-03-28T04:46:20.769Z',
    transactionType: 'transferIn',
    amount: 3,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 30,
    },
  },
  {
    _id: 'KqnLvpcXo6hwtueYf',
    createdAt: '2022-03-28T05:10:44.878Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 949.9800000000068,
    },
  },
  {
    _id: '2TGKJqEm3akv6Bnd5',
    createdAt: '2022-03-28T05:16:48.019Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 482,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'gHTwnZeLMMm9HhfyG',
    createdAt: '2022-03-28T05:16:48.157Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 929.22,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'FbrkFpZQkBSnKvgRe',
    createdAt: '2022-03-28T05:16:48.293Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 146.55000000000038,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'HLCACh5tG9DfHE3oY',
    createdAt: '2022-03-28T05:16:48.402Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2850.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'CCKE7NwKBWKJhDSrT',
    createdAt: '2022-03-28T05:16:48.649Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'f6f2oZSqN96gqtdpD',
    createdAt: '2022-03-28T05:19:33.228Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1949.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -1290,
  },
  {
    _id: 'zCKMCALgWzErvgZe5',
    createdAt: '2022-03-28T05:19:33.359Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8980,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wZptbnyFREG4XnpNp',
    createdAt: '2022-03-28T05:19:33.464Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3985,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ecwuM7fv73fuGwZZ2',
    createdAt: '2022-03-28T05:19:33.570Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2847.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EvQDoAmmcod3EPJoA',
    createdAt: '2022-03-28T05:19:33.682Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8595.479999999985,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '6p6GPrmrawdoCcXMi',
    createdAt: '2022-03-28T05:19:33.787Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 17072.4,
    },
    transactionType: 'transferOut',
    amount: -118,
  },
  {
    _id: 'dJ69ByrHtviE9X5pA',
    createdAt: '2022-03-28T05:19:33.893Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 6.349999999999987,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'h77aNLBn8RkM5rScp',
    createdAt: '2022-03-28T05:19:33.996Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 41.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'nP2KjBZZxdD8bXugm',
    createdAt: '2022-03-28T05:19:34.106Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3883,
    },
    transactionType: 'transferOut',
    amount: -86,
  },
  {
    _id: 'YaT7NNFpFHe6qJKEM',
    createdAt: '2022-03-28T05:23:59.339Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 659.9800000000068,
    },
  },
  {
    _id: 'ggwPCjNEpkaMswNys',
    createdAt: '2022-03-28T05:35:37.189Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1659.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -1150,
  },
  {
    _id: 'PE9HN3KRMi5fRKCMi',
    createdAt: '2022-03-28T05:35:37.326Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3985,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pfQLmyCuCCCPWTCuX',
    createdAt: '2022-03-28T05:35:37.437Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8980,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QvRvsE5Jnw6q6dKiA',
    createdAt: '2022-03-28T05:35:37.561Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 768.6000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CSTr6pewRLd5fdd53',
    createdAt: '2022-03-28T05:35:37.671Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 4.849999999999987,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'iQLSwtp6vRRWdnMR3',
    createdAt: '2022-03-28T05:35:37.784Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8593.479999999985,
    },
    transactionType: 'transferOut',
    amount: -1.8,
  },
  {
    _id: 'in8np7bD3r3BxWyNF',
    createdAt: '2022-03-28T05:35:37.901Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16954.4,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'AotZ22tmJ6WdozjBP',
    createdAt: '2022-03-28T05:35:38.014Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3797,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'ykHt27prmJGLXTGxL',
    createdAt: '2022-03-28T07:34:57.455Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 509.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Bnfyh3yAyDEetXn5G',
    createdAt: '2022-03-28T07:34:57.596Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8980,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jLZt29JpcHAbRkycS',
    createdAt: '2022-03-28T07:34:57.710Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2847.9,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'r8mk8Q6hHkAqGPGvP',
    createdAt: '2022-03-28T07:34:57.829Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 155,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '6j8YF7LS2aNdoaPg5',
    createdAt: '2022-03-28T07:34:57.943Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8591.679999999986,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'aRyDAYQwqsaK7HCCN',
    createdAt: '2022-03-28T07:34:58.054Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 169085.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '9c57azfFcjFATjRD3',
    createdAt: '2022-03-28T07:34:58.164Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 352.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'JEbtsybrZps58jE7T',
    createdAt: '2022-03-28T07:34:58.278Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1821,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '25y39ggNQKgJSZPQ2',
    createdAt: '2022-03-28T07:34:58.397Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1117,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'LMWjZ7Nz8nyJf2Srp',
    createdAt: '2022-03-28T07:53:22.038Z',
    transactionType: 'in',
    amount: 4500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8980,
    },
  },
  {
    _id: 'bdtvFCf3qwpY3FTpz',
    createdAt: '2022-03-28T07:53:46.061Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 219,
    },
  },
  {
    _id: 'cw7wwbavPCojJjwSq',
    createdAt: '2022-03-28T08:17:31.094Z',
    transactionType: 'transferIn',
    amount: 2100,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 259.98000000000684,
    },
  },
  {
    _id: 'GHSKgdPMBRsScQzTa',
    createdAt: '2022-03-28T08:18:17.317Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2359.980000000007,
    },
    transactionType: 'transferOut',
    amount: -350,
  },
  {
    _id: 'BA9kAF8DdjfZNdhFJ',
    createdAt: '2022-03-28T08:18:17.453Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13480,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fwmRnk9D9mjctJLZC',
    createdAt: '2022-03-28T08:18:17.583Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2797.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'p4FkSt46cHwhBTwPd',
    createdAt: '2022-03-28T08:18:17.723Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RueMnnQtBdcKSpYJT',
    createdAt: '2022-03-28T08:18:17.836Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 8526,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'pLXmd4fRK2t9nNuJN',
    createdAt: '2022-03-28T08:18:18.063Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 40.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hkicDp7M4DY6cMoXk',
    createdAt: '2022-03-28T08:18:18.166Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 21776,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'AC6PbZTD9w5DNutW7',
    createdAt: '2022-03-28T08:18:18.524Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1068,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'CwNqT2a786LpZ6tNG',
    createdAt: '2022-03-28T08:29:29.523Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 920,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'rLTPiTRCBDXqkt6Yd',
    createdAt: '2022-03-28T08:29:29.695Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2020.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'zg2pxTaZxYTtixSRG',
    createdAt: '2022-03-28T08:29:29.839Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 45.760000000000055,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'k387nH6oGz5ko436C',
    createdAt: '2022-03-28T08:29:29.958Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 146.30000000000038,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'qKo7EATEmWytmxhLa',
    createdAt: '2022-03-28T08:29:30.119Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 422.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'osacc7XNb5mKeqEtR',
    createdAt: '2022-03-28T08:29:30.286Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 164.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '4L6Bj237pnkrmLJKB',
    createdAt: '2022-03-28T08:29:30.416Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8588.679999999986,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'RmSWxzuWh73szAmGv',
    createdAt: '2022-03-28T08:29:30.534Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '544fRk7pDqmjjFoFh',
    createdAt: '2022-03-28T08:29:30.663Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 912.22,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'ArarY7L5YcWciBcTZ',
    createdAt: '2022-03-28T08:29:30.789Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ioK5e5rMH7Nq8N5Nm',
    createdAt: '2022-03-28T08:29:30.905Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BTMixhR837jinQ7hG',
    createdAt: '2022-03-28T08:29:31.033Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1103,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CsSvPCquws6pAQ54H',
    createdAt: '2022-03-28T08:30:45.844Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 900,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'sTWQXNqqaJe2s3aFE',
    createdAt: '2022-03-28T08:30:46.020Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1990.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 're3Hex2Tn9MAhLq4L',
    createdAt: '2022-03-28T08:30:46.149Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 146.1000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'wEsueD7rMf5TtDJvH',
    createdAt: '2022-03-28T08:30:47.042Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 45.46000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'H8mnGizAq8AGeWgx9',
    createdAt: '2022-03-28T08:30:47.158Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 420.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'AE89grEEdMdhSEoxp',
    createdAt: '2022-03-28T08:30:47.290Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 164.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '4E2dQRwDLd89rb92b',
    createdAt: '2022-03-28T08:30:47.413Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8587.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'HpwEYKEjTcNAEX2C8',
    createdAt: '2022-03-28T08:30:47.528Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g3kXHXGwMCNELaWJF',
    createdAt: '2022-03-28T08:30:47.647Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 901.22,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DmDRewmj3rQ8BP5nb',
    createdAt: '2022-03-28T08:30:47.764Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zHP9LLMPu7QhGDHF9',
    createdAt: '2022-03-28T08:30:48.611Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BqdbDxHwMn88m3j87',
    createdAt: '2022-03-28T08:30:48.871Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1103,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DCmBZAna2ZJh2Ag8j',
    createdAt: '2022-03-28T08:32:15.383Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 880,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'JgQLfHDgtRptXLFs5',
    createdAt: '2022-03-28T08:32:15.547Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1960.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '8KFkyPKajbcdW6RhB',
    createdAt: '2022-03-28T08:32:15.684Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 45.16000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '5LGTtbueHxTaDqjfg',
    createdAt: '2022-03-28T08:32:15.797Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 145.9000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'LNKnd4HgR6dLHdEWS',
    createdAt: '2022-03-28T08:32:15.912Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 418.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'q8CJon8AAJvq9eLjS',
    createdAt: '2022-03-28T08:32:16.026Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 163.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'x73m99zhaojKTjiqf',
    createdAt: '2022-03-28T08:32:16.144Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8586.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'tYKhA5NgFPhMd82H6',
    createdAt: '2022-03-28T08:32:16.264Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RjvRdR4nPDQ7BxKeo',
    createdAt: '2022-03-28T08:32:16.382Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 891.22,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'twA9me3SX4Aqw2G3k',
    createdAt: '2022-03-28T08:32:16.496Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'piPfLfdTHqmxjgjYJ',
    createdAt: '2022-03-28T08:32:16.613Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ANvHEDcE9ZpagA7sq',
    createdAt: '2022-03-28T08:32:16.730Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1103,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'v9K8GY3eGxfAGzouu',
    createdAt: '2022-03-28T08:32:46.627Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 860,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'YZRDCzrmYwuFKXZTF',
    createdAt: '2022-03-28T08:32:46.834Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1930.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'WRzJcqfKd5mhAkwFL',
    createdAt: '2022-03-28T08:32:46.977Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 44.76000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'yiTMJSRALKzZH2ErW',
    createdAt: '2022-03-28T08:32:47.102Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 145.70000000000041,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'Kp3NcLiQDXFSBZjWD',
    createdAt: '2022-03-28T08:32:47.217Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 416.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '8J9gdRjzccgwzEkqp',
    createdAt: '2022-03-28T08:32:47.332Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 163.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'wYfpJPi9Eh8gbWzps',
    createdAt: '2022-03-28T08:32:47.446Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8584.679999999986,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '5eqdRc2ewAFAwrPxX',
    createdAt: '2022-03-28T08:32:47.556Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GRu4sucDYsK3rqDpy',
    createdAt: '2022-03-28T08:32:47.678Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 880.22,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'msjR2zDi2XX3ZXR89',
    createdAt: '2022-03-28T08:32:47.802Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TQhwykkc6ppbCjLyG',
    createdAt: '2022-03-28T08:32:47.921Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JZd6Z8E29r6SsRojC',
    createdAt: '2022-03-28T08:32:48.043Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1095,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'TBZA6XjPGdyeTQJ5y',
    createdAt: '2022-03-28T08:33:26.003Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 840,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'hyL7EEKeeJSzRCNqk',
    createdAt: '2022-03-28T08:33:26.179Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1900.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'E8HtCiXqsndpYuRNA',
    createdAt: '2022-03-28T08:33:26.299Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 145.50000000000043,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'fujjR2ZsrZ5B4APz9',
    createdAt: '2022-03-28T08:33:26.416Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 44.460000000000065,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'nEgBvQ5G6id98bT7q',
    createdAt: '2022-03-28T08:33:26.545Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 414.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'CdkHEBNW7FDsrZ4uv',
    createdAt: '2022-03-28T08:33:26.668Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 162.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'HHvwvTWZZhpHbHfzg',
    createdAt: '2022-03-28T08:33:26.786Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8583.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'oW7o6enxmAb5fJ6pm',
    createdAt: '2022-03-28T08:33:26.900Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DfteA8p297XTudmrz',
    createdAt: '2022-03-28T08:33:27.028Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 869.22,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'vvEMhKPKWfW2xi2YG',
    createdAt: '2022-03-28T08:33:27.147Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'H9yrhNd4Wf7axmAo4',
    createdAt: '2022-03-28T08:33:27.259Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2i5SQeSChPoYePGJd',
    createdAt: '2022-03-28T08:33:27.384Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1087,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Sn5jrEg5vKavtDjxF',
    createdAt: '2022-03-28T08:34:09.105Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 820,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '4ptnP2DhreHgBtZQz',
    createdAt: '2022-03-28T08:34:09.272Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1870.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '8okmczRBi6pb26XNR',
    createdAt: '2022-03-28T08:34:09.398Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 145.30000000000044,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'QSu4HKNim5jEbrpAt',
    createdAt: '2022-03-28T08:34:09.517Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 44.16000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'QoABL59F4SxzjuXkT',
    createdAt: '2022-03-28T08:34:09.646Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 412.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'MsDm6rq4grmY2tYzb',
    createdAt: '2022-03-28T08:34:09.769Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 162.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'kt37NaELodnyWdyxt',
    createdAt: '2022-03-28T08:34:09.884Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8582.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'u46pBzr2W2NaYdbLv',
    createdAt: '2022-03-28T08:34:10.004Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rN7MDcNKoxis5MapN',
    createdAt: '2022-03-28T08:34:10.123Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 859.22,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'iGSr6Gt8J5FXFdQBz',
    createdAt: '2022-03-28T08:34:10.235Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ncjmXknMeiEtMa5Fs',
    createdAt: '2022-03-28T08:34:10.349Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NeePRWGnCnhd5sKSq',
    createdAt: '2022-03-28T08:34:10.466Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1087,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'wmNyQvP2WpdTTkxxw',
    createdAt: '2022-03-28T08:45:28.260Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2009.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ckQraS3DbPBRt6a2x',
    createdAt: '2022-03-28T08:45:28.392Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3985,
    },
    transactionType: 'transferOut',
    amount: -3900,
  },
  {
    _id: '3rkdTAFKXnmDFxS33',
    createdAt: '2022-03-28T08:45:28.510Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 89.73,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '2woBwrP8HuuJDQCzN',
    createdAt: '2022-03-28T08:45:28.619Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 166785.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'PqswgCcCPQiMKMDDG',
    createdAt: '2022-03-28T08:45:28.732Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 768.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -240,
  },
  {
    _id: 'rzhABhXbdrPzhiXhJ',
    createdAt: '2022-03-28T08:45:29.718Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1840.1,
    },
    transactionType: 'in',
    amount: 280,
  },
  {
    _id: 'JsyaLuXJSYy6FJ8Qq',
    createdAt: '2022-03-28T08:53:00.300Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'rhS5Rw2h5eM6xWWNf',
    createdAt: '2022-03-28T08:53:00.470Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2120.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'dTGYu2SbXzgqRmmXw',
    createdAt: '2022-03-28T08:53:00.587Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 145.10000000000045,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'aRDoTFJji3ZQ7qZsE',
    createdAt: '2022-03-28T08:53:00.696Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 43.86000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'vsxXhYS53hEbXdH6d',
    createdAt: '2022-03-28T08:53:00.813Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 410.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'o9M3ynoWGZRgxcPJK',
    createdAt: '2022-03-28T08:53:00.928Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 161.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'GqWAAG4BZbHxz7vM5',
    createdAt: '2022-03-28T08:53:01.047Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8581.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Go7sGf5qszwDs5buv',
    createdAt: '2022-03-28T08:53:01.157Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'a73sdmCNyQGWS3H3y',
    createdAt: '2022-03-28T08:53:01.267Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 849.22,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'XGpRuEDPFqyrkdi4F',
    createdAt: '2022-03-28T08:53:01.379Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wpTLXgpJbGqk8mqnv',
    createdAt: '2022-03-28T08:53:01.492Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fcu2pGPFngiKvGhsC',
    createdAt: '2022-03-28T08:53:01.609Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1799,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'qi6Dcvz2eGfxPQJ4c',
    createdAt: '2022-03-28T08:53:01.720Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 330.5,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'hFNELoYrfWopWXHkc',
    createdAt: '2022-03-28T08:53:01.837Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1079,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'hSic9khXojeNTApgF',
    createdAt: '2022-03-28T08:54:02.200Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 780,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ToPWrYZP9tEmNpXCe',
    createdAt: '2022-03-28T08:54:02.367Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2080.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'nkAxXZGaFXGkLKyvg',
    createdAt: '2022-03-28T08:54:02.502Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 144.90000000000046,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'JXScKMWZb8bEbm4R5',
    createdAt: '2022-03-28T08:54:02.619Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 43.46000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'vn5BuipcfE6igPTf9',
    createdAt: '2022-03-28T08:54:02.736Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 406.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '8bN6QHMLoXZ6u9tem',
    createdAt: '2022-03-28T08:54:02.852Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 161.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '68vmQhAXyPGKTzZeB',
    createdAt: '2022-03-28T08:54:02.964Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8580.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'wNF3SvDkxg6vi6k8n',
    createdAt: '2022-03-28T08:54:03.075Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eg4gdzkye8sSG3HEg',
    createdAt: '2022-03-28T08:54:03.185Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 839.22,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'y7quwXdgeR5uXb8X4',
    createdAt: '2022-03-28T08:54:03.297Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PhP54EGEaNHm6N3Xi',
    createdAt: '2022-03-28T08:54:03.416Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zYmuAeFR4JaS9yERD',
    createdAt: '2022-03-28T08:54:03.530Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1792,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'pedrfLBNtqgPJdNow',
    createdAt: '2022-03-28T08:54:03.645Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 323.5,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'GYetdbL4tmFabAHvF',
    createdAt: '2022-03-28T08:54:03.762Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1070,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '2hhGJix9gNoAzd9dR',
    createdAt: '2022-03-28T08:54:39.366Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 760,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'cM9PHY9oDFAFBBsGs',
    createdAt: '2022-03-28T08:54:39.537Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2040.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'rYGrzgfaJLcc87gjC',
    createdAt: '2022-03-28T08:54:39.684Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 43.06000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'EiouHs9EZNbP58oHY',
    createdAt: '2022-03-28T08:54:39.866Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 144.70000000000047,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'f9b5f3PAwGN4FX5L9',
    createdAt: '2022-03-28T08:54:40.045Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 404.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Xj8CdwzQEpFRLk5y7',
    createdAt: '2022-03-28T08:54:40.195Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 160.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'd8pAXBzKiqWCGkW9F',
    createdAt: '2022-03-28T08:54:40.313Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8579.179999999986,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'Euegj7XH3kHSqmQ3i',
    createdAt: '2022-03-28T08:54:40.444Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'C7KwzzAe4Li9AB2ds',
    createdAt: '2022-03-28T08:54:40.571Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 829.22,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'XcHLxAxMZp52KQ2Bd',
    createdAt: '2022-03-28T08:54:40.690Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4RbsttzsPwBq5PgE6',
    createdAt: '2022-03-28T08:54:40.881Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'G8Fd2borGL3fko5JG',
    createdAt: '2022-03-28T08:54:41.068Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1062,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'gwvwjqxX6us6JR9KY',
    createdAt: '2022-03-28T11:11:10.142Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe087aa06-9bbe-4765-90e8-807e6bd3e88b_200x200.jpg?alt=media&token=07849047-5931-407a-9fca-50e2923b7bbf',
        },
      },
      quantity: 6,
    },
  },
  {
    _id: 'xWjZ7vhXCuWCnGPkH',
    createdAt: '2022-03-28T11:39:09.019Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 569,
    },
    transactionType: 'transferOut',
    amount: -195,
  },
  {
    _id: 'fu53iNhY73Qr8WkPH',
    createdAt: '2022-03-28T11:39:09.155Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9818,
    },
    transactionType: 'transferOut',
    amount: -94,
  },
  {
    _id: 'NarhFzTsQgDTKLE5k',
    createdAt: '2022-03-28T11:39:09.271Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4379,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'a7eWeeysXydYcRBPy',
    createdAt: '2022-03-28T11:39:09.388Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1078,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'kqoeZidahwPQD7ip9',
    createdAt: '2022-03-28T11:40:06.179Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 374,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'LTZKQq2ZPqFEzEK4H',
    createdAt: '2022-03-28T11:40:06.292Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9724,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'mKiswFWgSQc3GfXJY',
    createdAt: '2022-03-28T11:40:06.408Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1070,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'GRJbRe5Y2aBLFNmg9',
    createdAt: '2022-03-28T11:49:23.505Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 321,
    },
    transactionType: 'transferOut',
    amount: -321,
  },
  {
    _id: 'CQGbWgA2NKHpFerFe',
    createdAt: '2022-03-28T11:49:23.626Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 3.8000000000000016,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'wRTgfSywoB9tbfFHN',
    createdAt: '2022-03-28T11:49:23.895Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 171,
    },
    transactionType: 'in',
    amount: 320,
  },
  {
    _id: 'EgydC3aam2gCW5iYf',
    createdAt: '2022-03-28T11:50:57.881Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 491,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'jdhySiMeBAw5EJSnn',
    createdAt: '2022-03-28T11:50:58.028Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9671,
    },
    transactionType: 'transferOut',
    amount: -91,
  },
  {
    _id: 'AzoqCFyrDYubJc2AX',
    createdAt: '2022-03-28T11:50:58.139Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1065,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'NtbeSEnHHLTgKDSrm',
    createdAt: '2022-03-28T11:51:46.648Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 401,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: '3E8zDYdbeS3nr9fbe',
    createdAt: '2022-03-28T11:51:46.793Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9580,
    },
    transactionType: 'transferOut',
    amount: -133,
  },
  {
    _id: 'TbnFtWW4WH68YNg7v',
    createdAt: '2022-03-28T11:51:46.915Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1057,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'apzbALEHToN4DeHNr',
    createdAt: '2022-03-28T11:52:14.670Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 269,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '79f2yA8k97m83skhW',
    createdAt: '2022-03-28T11:52:14.815Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4377,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'Rq7LjvGHNTN6S83QR',
    createdAt: '2022-03-28T11:52:14.933Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1046,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'bspuvGuyDE387sEYB',
    createdAt: '2022-03-28T11:52:46.833Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 251,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'eiu43DLMxH3MEKGPb',
    createdAt: '2022-03-28T11:52:46.973Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4340,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: '8kjJoBFarK6WwWHAA',
    createdAt: '2022-03-28T11:52:47.089Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1043,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'WCqgkCDyJoAsnzZRr',
    createdAt: '2022-03-28T11:57:53.979Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 402.2600000000002,
    },
  },
  {
    _id: 'ooDQuhP4n8R8EDEH7',
    createdAt: '2022-03-28T11:58:18.662Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 316.5,
    },
  },
  {
    _id: 'ZD8bJ5X4PHYavypJb',
    createdAt: '2022-03-28T11:58:47.431Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4966,
    },
  },
  {
    _id: 'FqZsHEfkyXERcC6KL',
    createdAt: '2022-03-28T11:59:14.309Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3723,
    },
  },
  {
    _id: 'NM4rHYRhSHdsjMeJf',
    createdAt: '2022-03-28T11:59:40.756Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1040,
    },
  },
  {
    _id: 'gesCPNpLW65Jf4wuH',
    createdAt: '2022-03-28T12:02:51.162Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1053,
    },
  },
  {
    _id: 'NDYaDHGBn2enKZYZk',
    createdAt: '2022-03-28T12:03:23.144Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8577.679999999986,
    },
  },
  {
    _id: 'FWeRN5g5J8AahEXfd',
    createdAt: '2022-03-28T12:03:41.459Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2797.9,
    },
  },
  {
    _id: '7TkMxF6DW8C2oCeYD',
    createdAt: '2022-03-28T12:07:01.515Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.2600000000002,
    },
  },
  {
    _id: 'WG6u7si6dbypmoSjW',
    createdAt: '2022-03-28T12:31:20.416Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9447,
    },
  },
  {
    _id: 'AyQ5uscNRQiSYQiKE',
    createdAt: '2022-03-28T12:31:45.896Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4303,
    },
  },
  {
    _id: '7GnhLtPbPTRN3ZypC',
    createdAt: '2022-03-28T12:33:11.564Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13480,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9xJZhYtbGchmfQ22J',
    createdAt: '2022-03-28T12:33:11.695Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2009.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'cF87HAHap7WPyMfnm',
    createdAt: '2022-03-28T12:33:11.810Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qRnzeTcHBZsbsJ6PT',
    createdAt: '2022-03-28T12:33:11.919Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2872.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PR7cCEyGYoauoaQFZ',
    createdAt: '2022-03-28T12:33:12.025Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3iHdXtc4vmqqtXtgw',
    createdAt: '2022-03-28T12:33:12.130Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 477.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '2A67kMrMMiBoYo7PS',
    createdAt: '2022-03-28T12:33:12.237Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 39.050000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MEQcRiRGK6xQHFT27',
    createdAt: '2022-03-28T12:33:12.348Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 26.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'mJ2ZSSfGZpnAbMd8f',
    createdAt: '2022-03-28T12:33:12.454Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16939.4,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'EHbNDiiHpGHn94ZKj',
    createdAt: '2022-03-28T12:33:12.562Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3923,
    },
    transactionType: 'transferOut',
    amount: -194,
  },
  {
    _id: '8jaj3xGFk3Hvd2zCs',
    createdAt: '2022-03-28T12:36:05.599Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 711.5,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'FxCATpReatZuhBi7w',
    createdAt: '2022-03-28T12:36:05.772Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21670.4,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'qjdHoRtD5ctPvZtop',
    createdAt: '2022-03-28T12:36:05.889Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 425.9,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'swC2cEyBFP2ZXfKeq',
    createdAt: '2022-03-28T12:36:06.026Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 230,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'MrduSsToGGhpc3Mda',
    createdAt: '2022-03-28T13:52:57.067Z',
    transactionType: 'transferIn',
    amount: 2010,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1169.9800000000068,
    },
  },
  {
    _id: '3TZ22nmJjEnaq2rqB',
    createdAt: '2022-03-29T05:51:23.114Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 472,
    },
  },
  {
    _id: '5XPFyspDcduspgduC',
    createdAt: '2022-03-29T05:52:07.602Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 818.22,
    },
  },
  {
    _id: 'TKv3Be9ehBNGPsSdt',
    createdAt: '2022-03-29T06:56:31.928Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13480,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'T5EnBe9RftzkCkRh7',
    createdAt: '2022-03-29T06:56:32.064Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2872.9,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'u4nJ4NiFdd7Cutz3Y',
    createdAt: '2022-03-29T06:56:32.174Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 528.6000000000004,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'XjNTWWna7so7aDn7K',
    createdAt: '2022-03-29T06:56:32.284Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16974.6,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'qsKbioCyTBY4Rgb8h',
    createdAt: '2022-03-29T06:56:32.392Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 275,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'Qek2qrN8hpZ6dEskb',
    createdAt: '2022-03-29T06:56:32.503Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4270,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'hNoKXW3LdfNDA4MZc',
    createdAt: '2022-03-29T06:58:23.706Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3179.980000000007,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'NvsGKqWJhbNmmNSfP',
    createdAt: '2022-03-29T06:58:23.847Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12640,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kZw7RSwZY76egMwJS',
    createdAt: '2022-03-29T06:58:23.959Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2847.9,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '4ZSEnBGPcLHQwkk8x',
    createdAt: '2022-03-29T06:58:24.075Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'w5dNxj9C85LpBmsQQ',
    createdAt: '2022-03-29T06:58:24.188Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8752.679999999986,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '5rWYuCQg8RasEF4wj',
    createdAt: '2022-03-29T06:58:24.302Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 164785.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'vbYvXmWzyZPGyLyCQ',
    createdAt: '2022-03-29T06:58:24.417Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 616.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '56hZkW3SxJAF8a4SS',
    createdAt: '2022-03-29T06:58:24.530Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1785,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'wYsnYmgi7JuFD9KSg',
    createdAt: '2022-03-29T06:58:24.647Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1128,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '2ho4pX6e7ifypvE37',
    createdAt: '2022-03-29T07:00:59.669Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2929.980000000007,
    },
    transactionType: 'transferOut',
    amount: -850,
  },
  {
    _id: 'RowFFGwwsE3vAaxzm',
    createdAt: '2022-03-29T07:00:59.805Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12640,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oSoqwMJv6iZ233T7T',
    createdAt: '2022-03-29T07:00:59.916Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5WRpeF8RRF6qN43Ri',
    createdAt: '2022-03-29T07:01:00.053Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2797.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DdZReqH5864qrQ88R',
    createdAt: '2022-03-29T07:01:00.170Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8749.679999999986,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '5bFL6Pb5z2LTS3Xs7',
    createdAt: '2022-03-29T07:01:00.277Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16919.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ghGa7wYQpfyLJ2eL8',
    createdAt: '2022-03-29T07:01:00.384Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 3.849999999999987,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'A3AJhoEfvHA5YvTLJ',
    createdAt: '2022-03-29T07:01:00.497Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 39.050000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kWnqGbtAxt5i8QpLK',
    createdAt: '2022-03-29T07:01:00.609Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3729,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'vTpY6hfazFmAGPvAY',
    createdAt: '2022-03-29T07:05:39.861Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 532,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'nXi2TMYWTwQu49Lxu',
    createdAt: '2022-03-29T07:05:40.006Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 918.22,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'fTpJvhzHvasGR5QYc',
    createdAt: '2022-03-29T07:05:40.142Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 144.50000000000048,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'MPMcoZBJJNuwACRqo',
    createdAt: '2022-03-29T07:05:40.260Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2797.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '6BL3Y88zLf9qKsayW',
    createdAt: '2022-03-29T07:05:40.515Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 15,
    },
    transactionType: 'in',
    amount: 65,
  },
  {
    _id: 'RddgeNCqotN5j4bkG',
    createdAt: '2022-03-29T11:09:22.991Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2079.980000000007,
    },
    transactionType: 'transferOut',
    amount: -820,
  },
  {
    _id: 'TNXmcoQYueipf7mPi',
    createdAt: '2022-03-29T11:09:23.127Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FLNsSjrKANCBSjyNr',
    createdAt: '2022-03-29T11:09:23.235Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12640,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Cd6y3A2C9ErymCcQW',
    createdAt: '2022-03-29T11:09:23.345Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2794.9,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'CvnefsFPdYWa5ZZSx',
    createdAt: '2022-03-29T11:09:23.453Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'KWpXWQjdWFS4quQiR',
    createdAt: '2022-03-29T11:09:23.559Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16907.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'XingnS99k7yy4gT2Q',
    createdAt: '2022-03-29T11:09:23.677Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XXg9vEtQp6K6mC4dt',
    createdAt: '2022-03-29T11:09:23.789Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 2.849999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'wLg3LbzS5tGp3dJA3',
    createdAt: '2022-03-29T11:09:23.910Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 39.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vzFGH9ovKsxEz24Fw',
    createdAt: '2022-03-29T11:09:24.018Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 25.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'q9XdCbb565MEWYSDJ',
    createdAt: '2022-03-29T11:09:24.135Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8747.679999999986,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'rpSbEQ5cQYSoaPZZE',
    createdAt: '2022-03-29T11:09:24.266Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5166,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'azWHG5RTWaZwx34Wm',
    createdAt: '2022-03-29T11:13:00.628Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1259.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'cuJoSmNFjM9yzRqHS',
    createdAt: '2022-03-29T11:13:00.771Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pBr2x5C9mK7ixrjXd',
    createdAt: '2022-03-29T11:13:00.885Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12640,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LbvRXdsJXwt9RvvF3',
    createdAt: '2022-03-29T11:13:00.996Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2787.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PAp6QyNhCZXNnejaM',
    createdAt: '2022-03-29T11:13:01.113Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JxnAsWFzuHvwX2JTG',
    createdAt: '2022-03-29T11:13:01.225Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16895.4,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'nGMeGhG2AWDzaX3TL',
    createdAt: '2022-03-29T11:13:01.338Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xaDD6mrFbDHeXwfuB',
    createdAt: '2022-03-29T11:13:01.457Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 38.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'HigmtsauehCN2vb35',
    createdAt: '2022-03-29T11:13:01.572Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 2.349999999999987,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'H2CiiCjXL4GKBsT9s',
    createdAt: '2022-03-29T11:13:01.690Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 469.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'iek7xaAGDPD6duHiy',
    createdAt: '2022-03-29T11:13:01.811Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8747.079999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'NZR4RWE9Ztv2p9dwh',
    createdAt: '2022-03-29T11:13:01.934Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5090,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'r6Nt5YLw7uwZWAYxD',
    createdAt: '2022-03-29T11:28:29.342Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12640,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'BHyy9tB6Y8QDjQmmq',
    createdAt: '2022-03-29T11:28:29.492Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 429.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2qhMKFhxvCmsjXm45',
    createdAt: '2022-03-29T11:28:30.157Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ADZAqigMTgYxCjrNL',
    createdAt: '2022-03-29T11:28:30.431Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 162485.3,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'vw6L3HWD8k6J58xBj',
    createdAt: '2022-03-29T11:28:30.567Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 85.73,
    },
    transactionType: 'transferOut',
    amount: -0.005,
  },
  {
    _id: 'yffa9zqJ2wnA84ej2',
    createdAt: '2022-03-29T11:28:30.683Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1543,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'NcZLCH9s3gSxE7kqH',
    createdAt: '2022-03-29T11:28:30.792Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1869,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '87jTPH2pT4L8Bp5Ar',
    createdAt: '2022-03-29T11:31:25.381Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12615,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'BikCy4Z49adrrnxXw',
    createdAt: '2022-03-29T11:31:25.519Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 85.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'QFnpwEwvzoiKnDiju',
    createdAt: '2022-03-29T11:31:25.636Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 162435.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: '8uKp35nBqmu57S5RF',
    createdAt: '2022-03-29T11:31:25.789Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 483.60000000000036,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'u8hByHGBq6qNn82Wa',
    createdAt: '2022-03-29T11:31:25.937Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8746.479999999985,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'JWagyeY23tRJ8syLb',
    createdAt: '2022-03-29T11:31:26.057Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 788,
    },
    transactionType: 'transferOut',
    amount: -380,
  },
  {
    _id: 'cFAhKQuGmfYNeYpi3',
    createdAt: '2022-03-29T11:31:26.169Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5013,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'qLYS2e48vKjNcL48p',
    createdAt: '2022-03-29T11:39:22.761Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 740,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'WCauNM5M6isjA3NN6',
    createdAt: '2022-03-29T11:39:22.945Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2010.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'dPb6g6i2vjiuG4gLJ',
    createdAt: '2022-03-29T11:39:23.108Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 144.25000000000048,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'nif6wTSkLpKQ8upGq',
    createdAt: '2022-03-29T11:39:23.267Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 42.760000000000076,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'ET6tA9RoEvb8bX8do',
    createdAt: '2022-03-29T11:39:23.400Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 461.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '3erkqPB2oJyidcFnt',
    createdAt: '2022-03-29T11:39:23.544Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 160.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'THK77EbkD9n6TktYv',
    createdAt: '2022-03-29T11:39:23.687Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8596.479999999985,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '3hHy2G8Ga2PPkmLes',
    createdAt: '2022-03-29T11:39:23.831Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 522,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jrNJh6XncSeEk3QJP',
    createdAt: '2022-03-29T11:39:23.984Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 901.22,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '8oSqmh7qMHss5h9LK',
    createdAt: '2022-03-29T11:39:24.128Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EAWRkFaYGHXmgzkwa',
    createdAt: '2022-03-29T11:39:24.274Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2LA76iQjzS3vsd9Do',
    createdAt: '2022-03-29T11:39:24.415Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1113,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'LT9YEKZfJip9462EB',
    createdAt: '2022-03-29T11:49:30.266Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 720,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'QM8JHvC8a9ttPMeMg',
    createdAt: '2022-03-29T11:49:30.441Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1970.1,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'W3bcZ89yFc46tQeKq',
    createdAt: '2022-03-29T11:49:30.596Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 42.56000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'hbsufCDhPoq7uJRFM',
    createdAt: '2022-03-29T11:49:30.709Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 144.0500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'bkw9W8dJWT8D7Wdkf',
    createdAt: '2022-03-29T11:49:30.831Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 459.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '6ofETbjMcgW9R8Kpe',
    createdAt: '2022-03-29T11:49:30.948Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 159.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'A8iBZJHSwSXkqxFzL',
    createdAt: '2022-03-29T11:49:31.063Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8595.479999999985,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'GAD2fSLLBuMGcaPWQ',
    createdAt: '2022-03-29T11:49:31.176Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 522,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oxcCLERbsHYxTvqXH',
    createdAt: '2022-03-29T11:49:31.298Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 895.22,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'MsjvkmfLthk6aRCHe',
    createdAt: '2022-03-29T11:49:31.413Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i54Ln6QAkqZtJWYL6',
    createdAt: '2022-03-29T11:49:31.527Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iQRgFYKKYjQ9pkc3o',
    createdAt: '2022-03-29T11:49:31.648Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1104,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'dQKbd5bCTJPvwbFb8',
    createdAt: '2022-03-29T11:59:00.741Z',
    transactionType: 'in',
    amount: 30000,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 293.60000000000036,
    },
  },
  {
    _id: 'cE6ugnfJdS9b8rJy9',
    createdAt: '2022-03-29T12:01:08.264Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 708,
    },
  },
  {
    _id: 'x2HCvgYuW9CL9Bqqo',
    createdAt: '2022-03-29T12:02:14.582Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 650,
    },
  },
  {
    _id: 'RkWvoovw7KX4zpdK8',
    createdAt: '2022-03-29T12:04:22.137Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9615,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '6ayyi9qrcGPkYLnEp',
    createdAt: '2022-03-29T12:04:22.270Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 429.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'adZSyyYPRDPLrcNsX',
    createdAt: '2022-03-29T12:04:22.380Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'WjoMj6CGEd9o3rmay',
    createdAt: '2022-03-29T12:04:22.487Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 455.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'bpgvuDjZn8Gj4ZqS7',
    createdAt: '2022-03-29T12:04:22.593Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1869,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'S8em9KF7afwXbKWam',
    createdAt: '2022-03-29T12:04:22.710Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1086,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'nXrfoyoTLphya7hZ6',
    createdAt: '2022-03-29T12:19:00.743Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9615,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'Y8sb8LsiC5d77jt2L',
    createdAt: '2022-03-29T12:19:00.876Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 429.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'cBwhBNL2YXLNZyBRM',
    createdAt: '2022-03-29T12:19:00.991Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'uM4jQ4AsABtahZzJG',
    createdAt: '2022-03-29T12:19:01.107Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 455.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '2P56r9g9r3fCPBobb',
    createdAt: '2022-03-29T12:19:01.217Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1869,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'Rf8XJMnHRgYE9Wnv6',
    createdAt: '2022-03-29T12:19:01.325Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1086,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'RZybdTv63i5XYBqGC',
    createdAt: '2022-03-29T12:30:21.390Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9565,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'yFTDQeC4diLsRa6Y8',
    createdAt: '2022-03-29T12:30:21.529Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2787.9,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'xCY8J456engD3NsWn',
    createdAt: '2022-03-29T12:30:21.638Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8592.479999999985,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '55cx7xZSa3x6gvYQT',
    createdAt: '2022-03-29T12:30:21.751Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 522,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '5bmLenfMNNS7gvRfP',
    createdAt: '2022-03-29T12:30:21.863Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 894.22,
    },
    transactionType: 'transferOut',
    amount: -2.2,
  },
  {
    _id: 'xSGrGQ8cjuTJ2iwmD',
    createdAt: '2022-03-29T12:30:21.979Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4214,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'qehSJnN7nNgxJbZnm',
    createdAt: '2022-03-29T12:34:08.634Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9515,
    },
    transactionType: 'transferOut',
    amount: -680,
  },
  {
    _id: 'bu2LH3hWxX7te3igN',
    createdAt: '2022-03-29T12:34:08.794Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2778.9,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: '5aApxdZ6fhDCWTxNR',
    createdAt: '2022-03-29T12:34:08.930Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 30293.6,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '9YXtQ2sYhJA22z7Xe',
    createdAt: '2022-03-29T12:34:09.043Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16954.6,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'JLpESe2X4n54d5fG4',
    createdAt: '2022-03-29T12:34:09.166Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 145,
    },
    transactionType: 'transferOut',
    amount: -115,
  },
  {
    _id: 'oBmXemQcr2aX3Kyf3',
    createdAt: '2022-03-29T12:34:09.279Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4205,
    },
    transactionType: 'transferOut',
    amount: -47,
  },
  {
    _id: 'EzJQ8NAG6c6ad64F6',
    createdAt: '2022-03-29T12:36:29.656Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8835,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Tutm64nNJjMQKDX48',
    createdAt: '2022-03-29T12:36:29.786Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 359.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'DLFGR9cyN6Yxra5nf',
    createdAt: '2022-03-29T12:36:30.433Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2751.9,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'y6qJZmrz6DsvixABS',
    createdAt: '2022-03-29T12:36:30.677Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'DAHjpqsAYrbKzoTWm',
    createdAt: '2022-03-29T12:36:30.810Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160935.3,
    },
    transactionType: 'transferOut',
    amount: -1930,
  },
  {
    _id: 'NZ2W3FcwrhGEkpohz',
    createdAt: '2022-03-29T12:36:30.916Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8591.979999999985,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'w2E5orA74r7SurXfN',
    createdAt: '2022-03-29T12:36:31.027Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 594.5,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'FLWQebiBNBuHkR9kc',
    createdAt: '2022-03-29T12:36:31.135Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1763,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'cEEmDW22npzcbsCFP',
    createdAt: '2022-03-29T12:53:43.686Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 8430,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'Xa8HAAr7XotddKoYi',
    createdAt: '2022-03-29T12:53:43.792Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8589.479999999985,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'uAhy2XqdEkzTzKSyy',
    createdAt: '2022-03-29T12:53:43.898Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159005.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'KGFizDJtohwMbSBt3',
    createdAt: '2022-03-29T12:53:44.003Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16875.4,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'TK4MJ4ywKwkzjuPkQ',
    createdAt: '2022-03-29T12:53:44.239Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 30,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'jzr2xrwMKW63KHgi8',
    createdAt: '2022-03-29T12:55:13.486Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8835,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'ET44zEf3gKLB8fpzb',
    createdAt: '2022-03-29T12:55:13.616Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 149.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Rcsokn9kmcsy5DZTm',
    createdAt: '2022-03-29T12:55:13.728Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9Grod7J28rSFjBApb',
    createdAt: '2022-03-29T12:55:13.839Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2710.9,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'hs2xxNwWofdHhrZYE',
    createdAt: '2022-03-29T12:55:13.975Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 30243.6,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'pxCE77k4LtgMCSxXA',
    createdAt: '2022-03-29T12:55:14.088Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16845.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'CqpXH9bBgcqDySDsF',
    createdAt: '2022-03-29T12:55:14.197Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16929.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'nvz2evoEJ7L4iSGQf',
    createdAt: '2022-03-29T12:55:14.311Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 830,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'S9EGA9vfwy83PdXWx',
    createdAt: '2022-03-29T12:55:14.450Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 578.5,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'FdtMkdKmh7xQZN3gR',
    createdAt: '2022-03-29T12:55:14.562Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1747,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'x9ase63Z8BfT6d3Bb',
    createdAt: '2022-03-29T12:55:14.672Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 226,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'xzXb5ey9gpf54BF8j',
    createdAt: '2022-03-29T13:00:08.302Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4953,
    },
  },
  {
    _id: 'Gcdu6krxPABavsDML',
    createdAt: '2022-03-29T13:00:26.256Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3671,
    },
  },
  {
    _id: '57M7gEbdpAtDcaizG',
    createdAt: '2022-03-29T13:00:44.167Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 670,
    },
  },
  {
    _id: 'P7mmdiyEKWBeAirtG',
    createdAt: '2022-03-29T13:01:03.525Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2685.9,
    },
  },
  {
    _id: 'MnLw5Bc8pb6QB4w7k',
    createdAt: '2022-03-29T13:02:33.692Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8549.479999999985,
    },
  },
  {
    _id: 'PgAwnYGDBdN7oCS3s',
    createdAt: '2022-03-29T13:03:20.053Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2510.9,
    },
  },
  {
    _id: '8y3YNeWd6PizMmZEG',
    createdAt: '2022-03-29T13:20:09.841Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 383,
    },
  },
  {
    _id: '664oSjgvyrC8RQE5d',
    createdAt: '2022-03-29T18:24:33.223Z',
    transactionType: 'transferIn',
    amount: 2500,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 85,
    },
  },
  {
    _id: 'SSQQ3zLpJ9iHaZ35d',
    createdAt: '2022-03-29T18:28:02.441Z',
    transactionType: 'transferIn',
    amount: 3085,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 149.98000000000684,
    },
  },
  {
    _id: 'oQBgKT8nw7hs5gf43',
    createdAt: '2022-03-29T18:40:03.315Z',
    transactionType: 'transferIn',
    amount: 3100,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2585,
    },
  },
  {
    _id: 'ZL3b2DdB4NLDKPXCm',
    createdAt: '2022-03-30T04:53:14.892Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3234.980000000007,
    },
    transactionType: 'transferOut',
    amount: -330,
  },
  {
    _id: 'SxfHFagkh4A6N8bj5',
    createdAt: '2022-03-30T04:53:15.031Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CCR6pzS5B6oKNAasH',
    createdAt: '2022-03-30T04:53:15.148Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7835,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QrcB2uoco8j3pa3Mg',
    createdAt: '2022-03-30T04:53:15.258Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2585.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'ZjKYk6TkBezZbXP4u',
    createdAt: '2022-03-30T04:53:15.366Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 30183.6,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'dkyRPTKZQSaYhFiKY',
    createdAt: '2022-03-30T04:53:15.479Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156405.3,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'uajzudPijCxYu644k',
    createdAt: '2022-03-30T04:53:15.591Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16889.6,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'od8MnAR29c6XjCHaB',
    createdAt: '2022-03-30T04:53:15.700Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 65,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '25AwqyTT7ar9KzRnE',
    createdAt: '2022-03-30T04:53:15.812Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 630,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'HGXeoE8bPpL5gRGuE',
    createdAt: '2022-03-30T04:53:15.976Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4158,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'c4HJgQkDjBMkXEPgB',
    createdAt: '2022-03-30T05:02:33.334Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7835,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BLLJqTagyJ9PLZc5Z',
    createdAt: '2022-03-30T05:02:33.477Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2904.980000000007,
    },
    transactionType: 'transferOut',
    amount: -440,
  },
  {
    _id: 'rt43Jd4tv7qQJsN2M',
    createdAt: '2022-03-30T05:02:33.586Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RJG8k3dDSSLdMX446',
    createdAt: '2022-03-30T05:02:33.698Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2575.9,
    },
    transactionType: 'transferOut',
    amount: -13.2,
  },
  {
    _id: 'vyDeKXfvWocgQmcFL',
    createdAt: '2022-03-30T05:02:33.814Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 30164.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '575PRAZPhJt5Cmf5b',
    createdAt: '2022-03-30T05:02:33.922Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156245.3,
    },
    transactionType: 'transferOut',
    amount: -220,
  },
  {
    _id: 's5y8n2cfuXQdjuHA6',
    createdAt: '2022-03-30T05:02:34.031Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 58,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'bGmWWEEC68tkor78N',
    createdAt: '2022-03-30T05:02:34.144Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16877.6,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'yezhzMvwFAoymvgCk',
    createdAt: '2022-03-30T05:02:34.258Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16833.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'etTSbtND3Tmyf6kLv',
    createdAt: '2022-03-30T05:02:34.368Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 570,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'Rfz8voAbSWTfYDTi9',
    createdAt: '2022-03-30T05:02:34.474Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4133,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: '6REj2n2eym9rzeZ94',
    createdAt: '2022-03-30T05:04:33.532Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7835,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'on5YT6CG3zWGBdudz',
    createdAt: '2022-03-30T05:04:33.675Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2464.980000000007,
    },
    transactionType: 'transferOut',
    amount: -850,
  },
  {
    _id: 'pFb7oGnbBMQL8SAuD',
    createdAt: '2022-03-30T05:04:33.795Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'd7LmPh4bMETagAcuB',
    createdAt: '2022-03-30T05:04:33.912Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2562.7000000000003,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tFjyDvtvXR7wXe6gf',
    createdAt: '2022-03-30T05:04:34.051Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 37.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'qAxriYvuK2bSnuZND',
    createdAt: '2022-03-30T05:04:34.169Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8724.479999999985,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '7ypYEkeNN9G7X9cD9',
    createdAt: '2022-03-30T05:04:34.285Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3871,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'HTxAG3Y4KhMFyxA9D',
    createdAt: '2022-03-30T05:11:53.398Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 680,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '5HXmJuNveMqmAky6g',
    createdAt: '2022-03-30T05:11:53.582Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1890.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'PmmBF5qSANNGp6dEL',
    createdAt: '2022-03-30T05:11:53.700Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 143.6500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'Cg285CnF5ZoQpgr2s',
    createdAt: '2022-03-30T05:11:53.820Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 41.760000000000076,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'GHpgoyMzFD2abxHdM',
    createdAt: '2022-03-30T05:11:53.932Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 454.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'xjqjW6HfXC4hXWoD9',
    createdAt: '2022-03-30T05:11:54.051Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 158.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Z7aZB7LELMXtcPS4n',
    createdAt: '2022-03-30T05:11:54.167Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8722.979999999985,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'dLikMu5jtFtqjXLrx',
    createdAt: '2022-03-30T05:11:54.288Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 520.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Zs8524BZxwBCJjD7d',
    createdAt: '2022-03-30T05:11:54.400Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 892.02,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '5XE3a5TnCi6622gGN',
    createdAt: '2022-03-30T05:11:54.532Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r6PtE8qyHTBQEdLgg',
    createdAt: '2022-03-30T05:11:54.661Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BtRJYSzcKwZ4sCFEh',
    createdAt: '2022-03-30T05:11:54.789Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1083,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '6FWXyy6d8TD7tRQP8',
    createdAt: '2022-03-30T05:34:04.447Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 7670,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'KJSXvdCwi5YgqqBGW',
    createdAt: '2022-03-30T05:34:04.558Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8721.979999999985,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'WJkcvHrQmnjxhGeRi',
    createdAt: '2022-03-30T05:34:04.669Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156025.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'Y6toMocdLBpk6jXS5',
    createdAt: '2022-03-30T05:34:04.776Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16823.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nfDJsgbBsLpoFFxc3',
    createdAt: '2022-03-30T05:34:05.020Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 408,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'nfCLXtSik2EPsPFcD',
    createdAt: '2022-03-30T05:34:34.664Z',
    transactionType: 'in',
    amount: 3300,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7835,
    },
  },
  {
    _id: 'YfsK73HHg99TnADnx',
    createdAt: '2022-03-30T05:34:53.935Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'FrfNZ9h8DfEaZFjZp',
    createdAt: '2022-03-30T05:35:14.554Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7835,
    },
    transactionType: 'transferOut',
    amount: -6000,
  },
  {
    _id: 'yyNBrKKcX86Ejg3Fk',
    createdAt: '2022-03-30T05:35:14.690Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 82.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '5xDFbsWhREXzjnWd8',
    createdAt: '2022-03-30T05:35:14.799Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155525.3,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'aLsapi54wG5N4ER3S',
    createdAt: '2022-03-30T05:35:14.909Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 30140.6,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'dz4JFEBHvuW8iejqa',
    createdAt: '2022-03-30T05:35:15.024Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8521.979999999985,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'cMhAjr6QfBZGwdPy4',
    createdAt: '2022-03-30T05:35:15.838Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1408,
    },
    transactionType: 'transferOut',
    amount: -720,
  },
  {
    _id: 'ctqTTNKuaxJ6D25uB',
    createdAt: '2022-03-30T05:35:16.019Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5073,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'sLA79j2xuFvEmuckF',
    createdAt: '2022-03-30T05:56:54.890Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1614.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iZLGddxkfBibs3X39',
    createdAt: '2022-03-30T05:56:55.032Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 5685,
    },
    transactionType: 'transferOut',
    amount: -5000,
  },
  {
    _id: 'dzDEyfZR92XoAmxhq',
    createdAt: '2022-03-30T05:56:55.143Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 76.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'PXjvTDhkMNKjwRrRx',
    createdAt: '2022-03-30T05:56:55.255Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155522.3,
    },
    transactionType: 'transferOut',
    amount: -2500,
  },
  {
    _id: 'QAP658RbRErK9eRX7',
    createdAt: '2022-03-30T05:56:55.368Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29780.6,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'aBSXApx7syATZLKBt',
    createdAt: '2022-03-30T05:56:55.636Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 1850.1,
    },
    transactionType: 'in',
    amount: 350,
  },
  {
    _id: 'WpgLXZ2HQZL3kZ6md',
    createdAt: '2022-03-30T06:05:29.411Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 490,
    },
  },
  {
    _id: 'hH5rdyMvetcqWp22f',
    createdAt: '2022-03-30T06:05:49.695Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.2600000000002,
    },
  },
  {
    _id: 'uprWTYrGLAKoLmhXr',
    createdAt: '2022-03-30T06:06:10.532Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2562.7000000000003,
    },
  },
  {
    _id: 'WYH78KL4p4DLkeCyy',
    createdAt: '2022-03-30T06:06:27.218Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1074,
    },
  },
  {
    _id: 'KQkZZpSu4a7HbjnRR',
    createdAt: '2022-03-30T06:06:44.794Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1825,
    },
  },
  {
    _id: 'EwDzDKhRE32TubnW4',
    createdAt: '2022-03-30T06:07:32.934Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4543,
    },
  },
  {
    _id: 'sfef4FnaHeH4XicpG',
    createdAt: '2022-03-30T07:11:27.429Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 151.69999999999996,
    },
  },
  {
    _id: 'QyCBYCuxFes4KJ5EN',
    createdAt: '2022-03-30T08:42:58.707Z',
    transactionType: 'in',
    amount: 4000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5135,
    },
  },
  {
    _id: 'eLaZ34mP6eKWTpRbf',
    createdAt: '2022-03-30T08:43:21.023Z',
    transactionType: 'in',
    amount: 700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9135,
    },
  },
  {
    _id: 'YmbWJFTviDuumCMLX',
    createdAt: '2022-03-30T08:43:37.247Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'QYpYkb2F7v6Q5nzfC',
    createdAt: '2022-03-30T11:16:25.522Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 453,
    },
  },
  {
    _id: 'nfDe5S8YCYfmvSdgp',
    createdAt: '2022-03-30T12:14:43.145Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -225,
  },
  {
    _id: 'MgiMQdYvikcuGWeyc',
    createdAt: '2022-03-30T12:14:43.280Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10087,
    },
    transactionType: 'transferOut',
    amount: -106,
  },
  {
    _id: 'Hh5DdrLH5KhvqTRtg',
    createdAt: '2022-03-30T12:14:43.393Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4663,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'YFc3gnzrXfe9pLD5b',
    createdAt: '2022-03-30T12:14:43.505Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1115,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'TEcDnGvfzjrRARaHN',
    createdAt: '2022-03-30T12:15:58.206Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 475,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'jXQZfXxkbwySAmAB5',
    createdAt: '2022-03-30T12:15:58.320Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9981,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'LaCTMn4nmXSaB9k7s',
    createdAt: '2022-03-30T12:15:58.447Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1106,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'XZiGxPwG6ucirLvzP',
    createdAt: '2022-03-30T12:18:15.655Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 408,
    },
    transactionType: 'transferOut',
    amount: -408,
  },
  {
    _id: 'DuvkMpuiSNhS2jLn2',
    createdAt: '2022-03-30T12:18:15.767Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 2.8000000000000016,
    },
    transactionType: 'transferOut',
    amount: -1.3,
  },
  {
    _id: 'hwnpxkMLFcoZ5dxei',
    createdAt: '2022-03-30T12:18:16.043Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 233,
    },
    transactionType: 'in',
    amount: 405,
  },
  {
    _id: 'cQCxCitDK8MEgZsY8',
    createdAt: '2022-03-30T12:19:44.115Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 638,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'okKRuzK3oE49zJdmB',
    createdAt: '2022-03-30T12:19:44.253Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9914,
    },
    transactionType: 'transferOut',
    amount: -91,
  },
  {
    _id: 'swLWNYzzMhW9iPNLG',
    createdAt: '2022-03-30T12:19:44.369Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1100,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'nxcAYcXnMmEDsod4d',
    createdAt: '2022-03-30T12:20:52.981Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 548,
    },
    transactionType: 'transferOut',
    amount: -132,
  },
  {
    _id: 'WypkQPpAT3cRgLg4M',
    createdAt: '2022-03-30T12:20:53.121Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9823,
    },
    transactionType: 'transferOut',
    amount: -133,
  },
  {
    _id: 'q5k6cuTreJP4f3b32',
    createdAt: '2022-03-30T12:20:53.237Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1092,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'sCpy9vJZTXeZ2DXS9',
    createdAt: '2022-03-30T12:21:43.041Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 416,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'o79uEtoQvDsWqhcoa',
    createdAt: '2022-03-30T12:21:43.175Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4661,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'h7DmGpgqTHyWwKEDH',
    createdAt: '2022-03-30T12:21:43.285Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1081,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'yvJHXjPX9hxbahviC',
    createdAt: '2022-03-30T12:22:13.700Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 404,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'z7ygu94oP4TZacaiC',
    createdAt: '2022-03-30T12:22:13.844Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4636,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'GmQvJL4N2Hq8XvELi',
    createdAt: '2022-03-30T12:22:13.957Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1079,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'gpsfFNqazKzZw5B3p',
    createdAt: '2022-03-30T12:23:34.220Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 392,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'oi3MevKoZAP3KwJ3Y',
    createdAt: '2022-03-30T12:23:34.365Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4611,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'G5X4x3eTKEqTKbS56',
    createdAt: '2022-03-30T12:23:34.490Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1077,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ss8J7C5PxQmCTmPZC',
    createdAt: '2022-03-30T12:48:07.850Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 660,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'DAFaEZZ3guzdMxcTJ',
    createdAt: '2022-03-30T12:48:08.031Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2200.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'qifzToQbrM8kATpPi',
    createdAt: '2022-03-30T12:48:08.182Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 143.4500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'bPw47EabhsLh9hETX',
    createdAt: '2022-03-30T12:48:08.327Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 41.56000000000007,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '9GcvwYHRRgDfWAHfa',
    createdAt: '2022-03-30T12:48:08.468Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 477.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'yi4J4zzq52hZSpynJ',
    createdAt: '2022-03-30T12:48:08.604Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 158.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '25SdsYYhKHQ9pKkGT',
    createdAt: '2022-03-30T12:48:08.723Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8221.979999999985,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'jdspknRNg69fs2HcX',
    createdAt: '2022-03-30T12:48:08.840Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 520.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6ZJKvDDEt35D72G2f',
    createdAt: '2022-03-30T12:48:08.952Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 886.02,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'BgkBjsrm8uJxKJMRL',
    createdAt: '2022-03-30T12:48:09.064Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LohYd5MJAQobB5K3W',
    createdAt: '2022-03-30T12:48:09.184Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'guas2Luj35398tmAW',
    createdAt: '2022-03-30T12:48:09.356Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1104,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'Q5nToKvGEE7q6HhHu',
    createdAt: '2022-03-30T12:49:41.214Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 640,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'R8HtuyPyqj2vDnrHp',
    createdAt: '2022-03-30T12:49:41.385Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2160.1,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'ebtfExyLBac5WtKa3',
    createdAt: '2022-03-30T12:49:41.522Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 41.160000000000075,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'FEzcRj4WYvjrdYqbH',
    createdAt: '2022-03-30T12:49:41.654Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 143.2500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'baEZShjCm8kyeDjdg',
    createdAt: '2022-03-30T12:49:41.787Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 475.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'eag7RmzXjQpK5fcWe',
    createdAt: '2022-03-30T12:49:41.921Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 157.8,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'visa2YF9H52k7quGC',
    createdAt: '2022-03-30T12:49:42.041Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8220.979999999985,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '24aQ6xtcm3jHYWevo',
    createdAt: '2022-03-30T12:49:42.153Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 520.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D7HPSdQWMqktfhbM5',
    createdAt: '2022-03-30T12:49:42.273Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 875.02,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'mhzpMvr2WjnmfciL8',
    createdAt: '2022-03-30T12:49:42.388Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'c2QttK9FcvRSKMPaT',
    createdAt: '2022-03-30T12:49:42.502Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QyaGubqpDft4LYPfH',
    createdAt: '2022-03-30T12:49:42.645Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1095,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'th9DYsMPEyXyBq9pE',
    createdAt: '2022-03-30T13:02:07.980Z',
    transactionType: 'out',
    amount: -1000,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9835,
    },
  },
  {
    _id: 'AXtBkgEMGoNWT8X6P',
    createdAt: '2022-03-30T13:02:22.120Z',
    transactionType: 'out',
    amount: -700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8835,
    },
  },
  {
    _id: 'h5ebGvgwFwXqfrZ8T',
    createdAt: '2022-03-30T13:02:38.943Z',
    transactionType: 'out',
    amount: -700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8135,
    },
  },
  {
    _id: 'B8rPreyGLwiW4j4Tj',
    createdAt: '2022-03-30T13:03:40.361Z',
    transactionType: 'transferIn',
    amount: 280,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4953,
    },
  },
  {
    _id: 'FSEED9asSECKAKhDW',
    createdAt: '2022-03-30T13:04:06.166Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1077,
    },
  },
  {
    _id: 'CJbwNGNwGvCEE4MuQ',
    createdAt: '2022-03-30T13:04:07.114Z',
    transactionType: 'out',
    amount: -440,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7435,
    },
  },
  {
    _id: 'fzsFKkmqGSgDZ2emC',
    createdAt: '2022-03-30T13:04:23.726Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1077,
    },
  },
  {
    _id: 'vXb3qSSybgLXiCrMb',
    createdAt: '2022-03-30T13:04:46.482Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8217.979999999985,
    },
  },
  {
    _id: 'XFfD65uFuygiiXjP8',
    createdAt: '2022-03-30T13:05:12.853Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4102,
    },
  },
  {
    _id: 'kRfhjinMaSGLMkESL',
    createdAt: '2022-03-30T13:07:40.249Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6995,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'Ypei2an5osEP3uQCs',
    createdAt: '2022-03-30T13:07:40.386Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1614.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -450,
  },
  {
    _id: 'd4rNP8NYWn9hD8bLy',
    createdAt: '2022-03-30T13:07:40.500Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 590,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'KbvP5hG4NymFwfbSR',
    createdAt: '2022-03-30T13:07:40.612Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 471.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'hKMmKLB7X44dWNC9M',
    createdAt: '2022-03-30T13:07:40.748Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2325,
    },
    transactionType: 'transferOut',
    amount: -202,
  },
  {
    _id: 'rauqfrjrrxZn2dnb4',
    createdAt: '2022-03-30T13:07:40.859Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1152,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wEEnqtS977QGgEx9f',
    createdAt: '2022-03-30T13:09:33.368Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1164.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -820,
  },
  {
    _id: 'wwyTtfDCkCG2NZKje',
    createdAt: '2022-03-30T13:09:33.508Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'H8ki3EERpSJpLDj27',
    createdAt: '2022-03-30T13:09:33.632Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6845,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rHMYRaCHTzq3Ayjoe',
    createdAt: '2022-03-30T13:09:33.754Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2662.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '7wrfGmL5i3aXZtpgQ',
    createdAt: '2022-03-30T13:09:33.879Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 118,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'd2RjNWqBmCQyrBnyT',
    createdAt: '2022-03-30T13:09:33.993Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16823.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'iK75DY4KBdeuEjQ9E',
    createdAt: '2022-03-30T13:09:34.117Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sYLuncE37i3tTfoYw',
    createdAt: '2022-03-30T13:09:34.241Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 1.8499999999999872,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '5a8EiZiLSrQDX85uQ',
    createdAt: '2022-03-30T13:09:34.368Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 36.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'zDiXYFWH9WQimX5rW',
    createdAt: '2022-03-30T13:09:34.490Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 25.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3gt8xB67Ge8zhLFon',
    createdAt: '2022-03-30T13:09:34.611Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8392.979999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'ock4nouaM3AdyTq6w',
    createdAt: '2022-03-30T13:09:34.739Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5233,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'FAYyLFupaL6XeT4fX',
    createdAt: '2022-03-30T13:11:36.372Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6845,
    },
    transactionType: 'transferOut',
    amount: -465,
  },
  {
    _id: 'GiveMC9YWnHk9Bhxz',
    createdAt: '2022-03-30T13:11:36.505Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2656.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -13.8,
  },
  {
    _id: 'k8RAopGQsDs95yoiE',
    createdAt: '2022-03-30T13:11:36.621Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29480.6,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'FYt5jkoRyRPcCQg9d',
    createdAt: '2022-03-30T13:11:36.731Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16859.6,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'EY8XuJia2rGiKwjzL',
    createdAt: '2022-03-30T13:11:36.844Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'YJpKB94kRnewMnXJM',
    createdAt: '2022-03-30T13:11:36.958Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4302,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: 'xnrKfya8utHreS4ie',
    createdAt: '2022-03-30T13:14:58.472Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 520.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'dZSwuwHP6c59M8ZQG',
    createdAt: '2022-03-30T13:14:58.612Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 853.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'HFnDQw6fa8D4jwYfu',
    createdAt: '2022-03-30T13:14:58.721Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 142.8500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'WmtSSqEEjcrduCNFz',
    createdAt: '2022-03-30T13:14:58.833Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2642.9,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'S6FzZYwonkad5ncRD',
    createdAt: '2022-03-30T13:14:59.097Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'TG9enajhLSZA9wY4L',
    createdAt: '2022-03-30T13:22:54.367Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 129.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'pWxZ7bWqhWzWFamvA',
    createdAt: '2022-03-30T13:22:54.532Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tCeDKCHtkKaaF9AE2',
    createdAt: '2022-03-30T13:22:54.672Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 142.6000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '7TJDNPeRKJRkqfDpY',
    createdAt: '2022-03-30T13:22:54.788Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 836.02,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '8YK2H5eQG5N2NktLd',
    createdAt: '2022-03-30T13:22:54.915Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 510.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'onLquBc6tSXDuHsP8',
    createdAt: '2022-03-30T13:22:55.030Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 58.04000000000001,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'mNZz2XcHxMJqJsBtt',
    createdAt: '2022-03-30T13:22:55.142Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 40.36000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'izZc3ij8WhCaZgy2X',
    createdAt: '2022-03-30T13:22:55.251Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 466.2600000000002,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'RT8HZfuQPDPeof2rs',
    createdAt: '2022-03-30T13:22:55.363Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8392.379999999985,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'WLT8AScKTYt5aiPrJ',
    createdAt: '2022-03-30T13:22:55.476Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2080.1,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'zBozQPCJZRHfuh4Q6',
    createdAt: '2022-03-30T13:22:55.592Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BZK7KA8Jhy2vwHMLn',
    createdAt: '2022-03-30T13:22:55.710Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 523,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'kwnYX5CfqTzv9vaS3',
    createdAt: '2022-03-30T13:22:55.979Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 30,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '3FYB3ti5frpZZ9wBn',
    createdAt: '2022-03-30T23:41:44.509Z',
    transactionType: 'transferIn',
    amount: 10,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 117.9,
    },
  },
  {
    _id: 'xMb7kw2pdmXmgFzok',
    createdAt: '2022-03-31T04:56:40.445Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe357e5dc-766d-4518-9868-05f7651d9abb_200x200.jpg?alt=media&token=2dd741df-52c9-4d15-9d03-5e2aa74ce4dd',
        },
      },
      quantity: 57,
    },
  },
  {
    _id: '7u8sir4xQcKrcp2Ft',
    createdAt: '2022-03-31T06:01:51.005Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 344.98000000000684,
    },
  },
  {
    _id: 'tfHEYtsqK4kJRAq9C',
    createdAt: '2022-03-31T06:05:00.926Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6380,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KXFEv5z8HXTgLoiLz',
    createdAt: '2022-03-31T06:05:01.081Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2344.980000000007,
    },
    transactionType: 'transferOut',
    amount: -430,
  },
  {
    _id: 'ASQde46ywgBNYRSqg',
    createdAt: '2022-03-31T06:05:01.215Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BMpKa3W4qszbgKpik',
    createdAt: '2022-03-31T06:05:01.350Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2639.9,
    },
    transactionType: 'transferOut',
    amount: -12.5,
  },
  {
    _id: 'R64uqanqmdnrxEu2b',
    createdAt: '2022-03-31T06:05:01.490Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29453.6,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'd6dpR25itonnQwrAa',
    createdAt: '2022-03-31T06:05:01.625Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153022.3,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'XYjMEwGQXjsHAdkRz',
    createdAt: '2022-03-31T06:05:01.757Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 48,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'kBwrWhdQw5pNbDiB7',
    createdAt: '2022-03-31T06:05:01.891Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16844.6,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: '7Ch7ikzjW8gExa5hx',
    createdAt: '2022-03-31T06:05:02.027Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16811.4,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'm7FT8ukELmu3FwKLM',
    createdAt: '2022-03-31T06:05:02.157Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 435,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'qeHHAPPmL6XmYqgRS',
    createdAt: '2022-03-31T06:05:02.291Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4271,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: 'b5SE2k6GEP9oztZug',
    createdAt: '2022-03-31T06:08:30.400Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1914.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -625,
  },
  {
    _id: 'JfLb5DvT6kpoPvxy4',
    createdAt: '2022-03-31T06:08:30.591Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iHtxKXdhQ8jgbrtnj',
    createdAt: '2022-03-31T06:08:30.695Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6380,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8t4q856HewrXXL9WX',
    createdAt: '2022-03-31T06:08:30.798Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2627.4,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'rxwu5axbqDSHRS5Sb',
    createdAt: '2022-03-31T06:08:30.931Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29433.6,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'kKQLhRRrniqDwPput',
    createdAt: '2022-03-31T06:08:31.041Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 152822.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'BMkxDkQKQ2iqimfdP',
    createdAt: '2022-03-31T06:08:31.168Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16828.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'YpSQiDnNWWDbkzwm9',
    createdAt: '2022-03-31T06:08:31.276Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 38,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '9xE8ShcXpyfo4eoiY',
    createdAt: '2022-03-31T06:08:31.408Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 365,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'DghNtAp8jxCGePeky',
    createdAt: '2022-03-31T06:08:31.544Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4240,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'DjGbmgYRfxrbdLtTw',
    createdAt: '2022-03-31T07:23:23.401Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 171.69999999999996,
    },
  },
  {
    _id: 'zzBBus33o5NiWHPiY',
    createdAt: '2022-03-31T07:24:02.734Z',
    transactionType: 'transferIn',
    amount: 18,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 33,
    },
  },
  {
    _id: 'MJjDudcXeEQpHiBdH',
    createdAt: '2022-03-31T07:25:40.383Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 15,
    },
  },
  {
    _id: 'WntG9gME8Z6HjjawK',
    createdAt: '2022-03-31T07:30:20.424Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1289.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -272,
  },
  {
    _id: 'ij4CCZAzApz54qHD2',
    createdAt: '2022-03-31T07:30:20.551Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hYzdvqPwuRrAJcHcc',
    createdAt: '2022-03-31T07:30:20.663Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6380,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'n5tS6Z8TmLue7wmaZ',
    createdAt: '2022-03-31T07:30:20.771Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29403.6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9PcCFNBWmqENJWfS3',
    createdAt: '2022-03-31T07:30:20.884Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 1.3499999999999872,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'TZ77ygKYmFwAmPgCa',
    createdAt: '2022-03-31T07:30:21.000Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8392.129999999985,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'tp4bpB5AzGC8qJADi',
    createdAt: '2022-03-31T07:30:21.117Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16806.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'BGC99ZqLTLKZt9Dwa',
    createdAt: '2022-03-31T07:30:21.232Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3813,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'TpaRmWnonK4frjB5b',
    createdAt: '2022-03-31T07:37:34.127Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 127.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'nKuBb84NMjXwEEGzL',
    createdAt: '2022-03-31T07:37:34.281Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hZcaPenKrBpCHhb9p',
    createdAt: '2022-03-31T07:37:34.413Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 142.3500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'crD3cFYXWxzPJn7iw',
    createdAt: '2022-03-31T07:37:34.567Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 835.52,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'LTwHg8QSrLg9DkvSr',
    createdAt: '2022-03-31T07:37:34.696Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 509.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'pQgLdZCXARjeejo2k',
    createdAt: '2022-03-31T07:37:34.806Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 57.640000000000015,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'pKR9wCLdvtRPE8XLq',
    createdAt: '2022-03-31T07:37:34.919Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 39.46000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: 'xPAuo5p4cfjcSgJCg',
    createdAt: '2022-03-31T07:37:35.025Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 465.86000000000024,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'evZbcrsiymYnSEas8',
    createdAt: '2022-03-31T07:37:35.129Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8391.729999999985,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '2XaPcnxSQbDAao8Ap',
    createdAt: '2022-03-31T07:37:35.235Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2065.1,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'wbmiGGHi5nQyihfeL',
    createdAt: '2022-03-31T07:37:35.373Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '53kuFZcwcYgrA6MA7',
    createdAt: '2022-03-31T07:37:35.484Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 453,
    },
    transactionType: 'transferOut',
    amount: -69,
  },
  {
    _id: 'DtvofsqdA9hPSwNpW',
    createdAt: '2022-03-31T07:37:35.705Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 24,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'yLRaHjbQgzmg4E5bP',
    createdAt: '2022-03-31T07:38:48.260Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 508.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'zgJavX2qpg7RvNJdd',
    createdAt: '2022-03-31T07:38:48.390Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 835.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'KWM6JgT9bBHhYyeiF',
    createdAt: '2022-03-31T07:38:48.503Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 142.1000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'eXCBBAha2Tj8gycFX',
    createdAt: '2022-03-31T07:38:48.611Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2609.4,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Q9SmG9SeiwMTbXcxs',
    createdAt: '2022-03-31T07:38:48.850Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'auJEGiPWTuDA59dZ2',
    createdAt: '2022-03-31T10:30:31.800Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1017.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'CctNrJdow2ydBWK4b',
    createdAt: '2022-03-31T10:30:31.942Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FxeN3aw6p7egYnmcN',
    createdAt: '2022-03-31T10:30:32.054Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6380,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DXBaZJQQrp6mcC2JW',
    createdAt: '2022-03-31T10:30:32.168Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2606.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Yfvgp64gn43H2593M',
    createdAt: '2022-03-31T10:30:32.281Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'J9ymXhAKSEjAt4gtG',
    createdAt: '2022-03-31T10:30:32.394Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16796.4,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'xc6udLyALmB4mRDos',
    createdAt: '2022-03-31T10:30:32.509Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'b8yw6MpxmZGK2gRec',
    createdAt: '2022-03-31T10:30:32.623Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 35.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'r5WuozjEqBYHeJbzc',
    createdAt: '2022-03-31T10:30:32.737Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.8499999999999872,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '7KX3AQTWtsEa2xb73',
    createdAt: '2022-03-31T10:30:32.861Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 465.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 's84CEP4WvRcANmFLB',
    createdAt: '2022-03-31T10:30:32.972Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8391.479999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '7Nhqpbs2mmAXpfmjG',
    createdAt: '2022-03-31T10:30:33.090Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5157,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: '3JWvH9kytCLNNg6md',
    createdAt: '2022-03-31T10:43:39.703Z',
    transactionType: 'in',
    amount: 4800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6380,
    },
  },
  {
    _id: 'wmGbARg5XW3Cf26Av',
    createdAt: '2022-03-31T10:44:38.373Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'a6KtZa3Qpv5yogLRB',
    createdAt: '2022-03-31T10:49:35.854Z',
    transactionType: 'in',
    amount: 500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11180,
    },
  },
  {
    _id: 'p2fSwZkCvMGLcxfvw',
    createdAt: '2022-03-31T10:49:47.235Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
  },
  {
    _id: 'BuBmSgBSRwhm9sWcm',
    createdAt: '2022-03-31T10:57:14.286Z',
    transactionType: 'transferIn',
    amount: 2300,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 187.98000000000684,
    },
  },
  {
    _id: 'HQyvr8e26paQAipYz',
    createdAt: '2022-03-31T11:05:12.920Z',
    transactionType: 'transferIn',
    amount: 2260,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2487.980000000007,
    },
  },
  {
    _id: 'YTead5JEB5pJeY69y',
    createdAt: '2022-03-31T11:49:09.263Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11680,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'tnNPWtHzvLkawHSZ6',
    createdAt: '2022-03-31T11:49:09.401Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4747.980000000007,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '5RHFGSxGf8xy9WsFg',
    createdAt: '2022-03-31T11:49:09.532Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 540,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'enhKrwCjhwsxo6h48',
    createdAt: '2022-03-31T11:49:09.648Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 457.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'agTG88buZx5sxfPaR',
    createdAt: '2022-03-31T11:49:09.772Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2123,
    },
    transactionType: 'transferOut',
    amount: -406,
  },
  {
    _id: 'fiyfpGFcQjCEJtT4e',
    createdAt: '2022-03-31T11:49:09.890Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1142,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'g4ofGN2Y6RgKRXBWF',
    createdAt: '2022-03-31T12:07:46.315Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2606.4,
    },
  },
  {
    _id: 'G73bfRHDenZ43AZfS',
    createdAt: '2022-03-31T12:08:02.343Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 440,
    },
  },
  {
    _id: 'qtARL9GhQYLDgJQxh',
    createdAt: '2022-03-31T12:08:21.995Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4190,
    },
  },
  {
    _id: 'DdS4JjdNm8FG9ruek',
    createdAt: '2022-03-31T12:09:07.777Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1717,
    },
  },
  {
    _id: 'sYgmWBDMB4gq3Zm7D',
    createdAt: '2022-03-31T12:09:26.639Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8390.879999999985,
    },
  },
  {
    _id: 'qnnBuAoRDFprCLQCw',
    createdAt: '2022-03-31T12:09:55.567Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1122,
    },
  },
  {
    _id: 'P8hfwvJkQn6Bf9mfh',
    createdAt: '2022-03-31T12:10:13.059Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 141.5,
    },
  },
  {
    _id: '9ZxtSA4ReLwXMHNQg',
    createdAt: '2022-03-31T12:11:06.372Z',
    transactionType: 'transferIn',
    amount: 3,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F36a083ae-23ac-4407-8771-4336e2246576_200x200.jpg?alt=media&token=1306ff4d-a3a4-4218-85f5-808fbf3dff2f',
        },
      },
      quantity: 7,
    },
  },
  {
    _id: 'eJRwFXrNoQe5ic9Qf',
    createdAt: '2022-03-31T12:11:26.370Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'xyX3JCjJxkmWbo7EN',
        name: 'GLOVES NITRILE POWDER FREE  - MEDIUM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F063d4329-7985-45d3-bac7-1c99ee771a32_200x200.jpg?alt=media&token=0afbc2a7-f8ff-4af5-8022-88319906aa2f',
        },
      },
      quantity: 4,
    },
  },
  {
    _id: 'k63F8hqbPM8un2YLv',
    createdAt: '2022-03-31T12:30:35.547Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10780,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '7Ly9HgduPA87n5YJH',
    createdAt: '2022-03-31T12:30:35.706Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4447.980000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZivQw3TmMuDmSzGYp',
    createdAt: '2022-03-31T12:30:35.839Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZoSLx5enh32e9WpCE',
    createdAt: '2022-03-31T12:30:35.968Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2731.4,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'cwMc4NX8PjcYuZXLs',
    createdAt: '2022-03-31T12:30:36.087Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29403.6,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'vTuGazswP2Jn79r3i',
    createdAt: '2022-03-31T12:30:36.204Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16804.6,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'rh9ujAQEHXejMyGLr',
    createdAt: '2022-03-31T12:30:36.344Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16776.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Z72LoPnZBhJ7EnMfY',
    createdAt: '2022-03-31T12:30:36.472Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 240,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'YyZPsuvh9kbW8tDqb',
    createdAt: '2022-03-31T12:30:36.592Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4390,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'RCcp4kfoXwKHScuGQ',
    createdAt: '2022-03-31T12:53:30.416Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 671.5,
    },
    transactionType: 'transferOut',
    amount: -20.5,
  },
  {
    _id: 'xWHtBCfMFq7EPw3aH',
    createdAt: '2022-03-31T12:53:30.579Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 191.69999999999996,
    },
    transactionType: 'transferOut',
    amount: -7.6,
  },
  {
    _id: 'dP4xpHrZZJxggBpKs',
    createdAt: '2022-03-31T12:53:30.695Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 153.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '3WPyzeqbjsBheQubD',
    createdAt: '2022-03-31T12:53:30.807Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4334,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'xJKk7mot68Ru3QTKt',
    createdAt: '2022-03-31T12:57:19.692Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 651,
    },
    transactionType: 'transferOut',
    amount: -46.5,
  },
  {
    _id: 'CxdcBSjx7og564WiE',
    createdAt: '2022-03-31T12:57:19.866Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 184.09999999999997,
    },
    transactionType: 'transferOut',
    amount: -17.5,
  },
  {
    _id: 'yStSHNGbhDHbNYm7H',
    createdAt: '2022-03-31T12:57:19.982Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 148.5,
    },
    transactionType: 'transferOut',
    amount: -11.5,
  },
  {
    _id: 'dY7a2nx5YLmE5PkLb',
    createdAt: '2022-03-31T12:57:20.096Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4324,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: 'X38HXNzjh5P8XPsRd',
    createdAt: '2022-03-31T12:57:20.223Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5080,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5yxtuBPtYFPL4K6oE',
    createdAt: '2022-03-31T13:00:30.946Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4447.980000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Fv8NtFeNdyvpEmJow',
    createdAt: '2022-03-31T13:00:31.082Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2685,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'WQFHJohfbBRjJGCZf',
    createdAt: '2022-03-31T13:00:31.219Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 71.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'FgZqDHubLMiWsMETZ',
    createdAt: '2022-03-31T13:00:31.328Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 152522.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '7KEXr6MktoByQtjdu',
    createdAt: '2022-03-31T13:00:31.458Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29358.6,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: '2PDD3WMvtuoFbERJE',
    createdAt: '2022-03-31T13:00:31.714Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2050.1,
    },
    transactionType: 'in',
    amount: 160,
  },
  {
    _id: 'ciEFKpBdWq4E9SwtJ',
    createdAt: '2022-03-31T13:02:11.965Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10880,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'MZuYGk6oGbQwAEaCB',
    createdAt: '2022-03-31T13:02:12.100Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4747.980000000007,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'p5pC8AGjeYpM8rsXu',
    createdAt: '2022-03-31T13:02:12.208Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 665,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'HHACgQyyk2BqEBA6y',
    createdAt: '2022-03-31T13:02:12.313Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 457.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'WtMn94ZQsQBnteLBr',
    createdAt: '2022-03-31T13:02:12.418Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2623,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: '888e9SeRpdWxbBdvG',
    createdAt: '2022-03-31T13:02:12.532Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1172,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'g2Ldoyg42fyPuL9AS',
    createdAt: '2022-03-31T13:06:22.712Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10580,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'BbCAWBHfAmYrAzDCG',
    createdAt: '2022-03-31T13:06:22.853Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3847.980000000007,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'WXEurrbiYanRZawxR',
    createdAt: '2022-03-31T13:06:22.998Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 565,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'KRtY5AXxNy7vwMXsa',
    createdAt: '2022-03-31T13:06:23.118Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 447.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '84Fsx2juCEX9CB4Rd',
    createdAt: '2022-03-31T13:06:23.230Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2419,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: 'ErK55gT9zMSJfSAFS',
    createdAt: '2022-03-31T13:06:23.343Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1162,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'S3NmAvboaG6FjJZy4',
    createdAt: '2022-03-31T13:11:44.161Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'YH57SY8tLwYWL2zrj',
        name: 'RTS 82 MM (PLAIN WHITE) -JAR CAP',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F665dfe2a-8b8b-4ba1-83b7-59c7a5914c4c_200x200.jpg?alt=media&token=5b30fc96-e6ed-43a3-8955-f555e4b0061b',
        },
      },
      quantity: 200,
    },
  },
  {
    _id: 'Jiqc8yHRkMWeCG96N',
    createdAt: '2022-03-31T13:16:40.220Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'YH57SY8tLwYWL2zrj',
        name: 'RTS 82 MM (PLAIN WHITE) -JAR CAP',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F665dfe2a-8b8b-4ba1-83b7-59c7a5914c4c_200x200.jpg?alt=media&token=5b30fc96-e6ed-43a3-8955-f555e4b0061b',
        },
      },
      quantity: 270,
    },
  },
  {
    _id: 'NhZNCqbppYuS3zP47',
    createdAt: '2022-03-31T13:23:14.539Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10280,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'KCpX8cpo4bpiuMTPy',
    createdAt: '2022-03-31T13:23:14.674Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2947.980000000007,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'ZiCQiGkqxiCpG2Pxc',
    createdAt: '2022-03-31T13:23:14.785Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 465,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '2iv2iXp9R9yktNDtv',
    createdAt: '2022-03-31T13:23:14.893Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 437.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'ss5shCjBzCC75mgtT',
    createdAt: '2022-03-31T13:23:15.020Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2215,
    },
    transactionType: 'transferOut',
    amount: -404,
  },
  {
    _id: 'qrFvwqw6dkigXXgTa',
    createdAt: '2022-03-31T13:23:15.129Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1152,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'BNLrhnyizCF9ZjFTr',
    createdAt: '2022-03-31T13:32:05.338Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 392,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Spmyyo3y22nhuoMgE',
    createdAt: '2022-03-31T13:32:05.473Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9690,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '8fMN4t5Rd6kR5RTi8',
    createdAt: '2022-03-31T13:32:05.588Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1137,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'jmTM5YsiffYRq6em7',
    createdAt: '2022-04-01T04:44:22.386Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10580,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'x4zGLpXBXkcdCwNxj',
    createdAt: '2022-04-01T04:44:22.514Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3847.980000000007,
    },
    transactionType: 'transferOut',
    amount: -450,
  },
  {
    _id: '8HDY6Tz5Csrpi5J6C',
    createdAt: '2022-04-01T04:44:22.625Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 565,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'qoZLDgb5v5RDvwkjZ',
    createdAt: '2022-04-01T04:44:22.731Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 447.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Fv5jhqCLeftZncMSJ',
    createdAt: '2022-04-01T04:44:22.840Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2219,
    },
    transactionType: 'transferOut',
    amount: -202,
  },
  {
    _id: 'EJtnzc54vNx7AYxgv',
    createdAt: '2022-04-01T04:44:22.946Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1152,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '7ry4iAMmTSPv8rKcb',
    createdAt: '2022-04-01T05:07:16.924Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6870,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'JLLYsEE659A4pShPk',
    createdAt: '2022-04-01T05:07:17.026Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8565.879999999985,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '94xu7tYRdmxFaPjmX',
    createdAt: '2022-04-01T05:07:17.126Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 151522.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'Zmrf49FgFwEMiAoqt',
    createdAt: '2022-04-01T05:07:17.231Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16766.4,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'qACMKFwbqSdwbmp99',
    createdAt: '2022-04-01T05:07:17.468Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 100,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'hM5MsPhCyj4PZL5q9',
    createdAt: '2022-04-01T05:07:53.459Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3397.980000000007,
    },
    transactionType: 'transferOut',
    amount: -620,
  },
  {
    _id: '8EMa9sB2KuuFiLSJx',
    createdAt: '2022-04-01T05:07:53.591Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TGFv7g2KBgZaT4j3P',
    createdAt: '2022-04-01T05:07:53.705Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10430,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R5Axi5nXELbq8QMT4',
    createdAt: '2022-04-01T05:07:53.814Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2707.4,
    },
    transactionType: 'transferOut',
    amount: -18.6,
  },
  {
    _id: 'wD8r2nWLSwWorzipH',
    createdAt: '2022-04-01T05:07:53.924Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29238.6,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'AEygNGo7gApWtRaMi',
    createdAt: '2022-04-01T05:07:54.034Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148922.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '8teaWKvhK7f8ReoTK',
    createdAt: '2022-04-01T05:07:54.144Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16779.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'jXGpdj2YsrSLjPjp4',
    createdAt: '2022-04-01T05:07:54.259Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 26,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '9pTcyCW3g8XAtDLAq',
    createdAt: '2022-04-01T05:07:54.372Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 900,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'zZxi8PHcrJJLXgxWe',
    createdAt: '2022-04-01T05:07:54.485Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4301,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '9wcmxCX3cW7uqeea2',
    createdAt: '2022-04-01T05:21:22.950Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10430,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9CKAyqj9ZdZrdfjQq',
    createdAt: '2022-04-01T05:21:23.086Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2777.980000000007,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '9w94Yfdz6HGH6Kpwf',
    createdAt: '2022-04-01T05:21:23.195Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5uahrRzJfJ4f84AjM',
    createdAt: '2022-04-01T05:21:23.303Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2688.8,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'ncFin6WBek3Wr8BtL',
    createdAt: '2022-04-01T05:21:23.419Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29208.6,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '4FZwwc9ModycGHh2o',
    createdAt: '2022-04-01T05:21:23.529Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148622.3,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'SmjM5RRWBDYctDKnz',
    createdAt: '2022-04-01T05:21:23.644Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 14,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'BmPbgvGKKWD3XFnXk',
    createdAt: '2022-04-01T05:21:23.757Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16755.6,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'PJbLsJvAaefMTwhiF',
    createdAt: '2022-04-01T05:21:23.871Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16736.4,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'PM84NeZzXN5uEL9K2',
    createdAt: '2022-04-01T05:21:24.048Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 775,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'F9qzwEGHbQtHNAJXh',
    createdAt: '2022-04-01T05:21:24.210Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4251,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: '6dEgSD3Zj5op6y82m',
    createdAt: '2022-04-01T06:36:10.374Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 515,
    },
  },
  {
    _id: 'Ng3RXZRSP8NTPKg46',
    createdAt: '2022-04-01T07:23:30.152Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 922,
    },
  },
  {
    _id: '6EeBj9yrMWEM84iWb',
    createdAt: '2022-04-01T07:24:10.481Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4611,
    },
  },
  {
    _id: 'SDyNupR6Eh57Zng5n',
    createdAt: '2022-04-01T07:24:45.826Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9679,
    },
  },
  {
    _id: 'Hqi2EbteM5y6uZKFZ',
    createdAt: '2022-04-01T07:26:14.891Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 442.46000000000026,
    },
  },
  {
    _id: 'srrNR6kbJHgF4vHN4',
    createdAt: '2022-04-01T07:26:37.848Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8525.879999999985,
    },
  },
  {
    _id: 'stykBBycJi8x3ycq3',
    createdAt: '2022-04-01T08:06:01.890Z',
    transactionType: 'transferIn',
    amount: 3,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 17,
    },
  },
  {
    _id: 'qdaXoafCxsshL3CNE',
    createdAt: '2022-04-01T08:06:23.547Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'HD6kFyoB86PuxA3ow',
        name: 'HAND SLEEVES ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc98a9b17-9a15-4a0c-844b-63af5c506ee5_200x200.webp?alt=media&token=a218086e-6af4-4827-9f13-f35f9b1d3f9c',
        },
      },
      quantity: 3,
    },
  },
  {
    _id: '8bEiKoohXjZDXNokD',
    createdAt: '2022-04-01T11:03:20.474Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2527.980000000007,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'HGpWRsipeiXTmDFRZ',
    createdAt: '2022-04-01T11:03:20.605Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jMdpexWSpW87d9FfX',
    createdAt: '2022-04-01T11:03:20.732Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10430,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LuedYhYZhnBu6kuoX',
    createdAt: '2022-04-01T11:03:20.864Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2681.3,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ctBGeX6dZFFwG8ZGz',
    createdAt: '2022-04-01T11:03:20.996Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Hww5i5xbTo8qT4ptb',
    createdAt: '2022-04-01T11:03:21.124Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16731.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kcRD67dGg3XGQjPw9',
    createdAt: '2022-04-01T11:03:21.253Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FxZnJarRkx5vhQFZr',
    createdAt: '2022-04-01T11:03:21.394Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 34.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vr24Kqz2w7Ko6Sdar',
    createdAt: '2022-04-01T11:03:21.525Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.3499999999999872,
    },
    transactionType: 'transferOut',
    amount: -0.31,
  },
  {
    _id: 'CWsfhnXqMCzH5q5vp',
    createdAt: '2022-04-01T11:03:21.658Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 467.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'M2Ej7HZXf9DF7xewK',
    createdAt: '2022-04-01T11:03:21.761Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8825.879999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'L2aQDAKZ72Tpy5Jtk',
    createdAt: '2022-04-01T11:03:21.870Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5080,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'cP5hfemukBXtj6aYX',
    createdAt: '2022-04-01T11:15:17.802Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10430,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'X3PvutQ2fDYNTt6kB',
    createdAt: '2022-04-01T11:15:17.940Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1687.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '33ybKZ7mpYX8ugHtf',
    createdAt: '2022-04-01T11:15:18.051Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 615,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ResmLhLMdYQB4Pap7',
    createdAt: '2022-04-01T11:15:18.187Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 459.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'mPPHn95bjygGmgPZJ',
    createdAt: '2022-04-01T11:15:18.298Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2017,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: '5iBxDoDZng49bexzh',
    createdAt: '2022-04-01T11:15:18.409Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1142,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'td2kHNijLdsALB3tA',
    createdAt: '2022-04-01T11:20:05.681Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
    transactionType: 'transferOut',
    amount: -142,
  },
  {
    _id: 'SW5X3GmrLGtb8tfiu',
    createdAt: '2022-04-01T11:20:05.827Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10319,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: '4TwjaSMvuKzDEjvGR',
    createdAt: '2022-04-01T11:20:05.943Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4851,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'NvLR9eiKqawuQ6voq',
    createdAt: '2022-04-01T11:20:06.059Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1136,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'yhxL8xZGJFPXNdbou',
    createdAt: '2022-04-01T11:20:47.730Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 208,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '3jMmQXSa2deKvj2xq',
    createdAt: '2022-04-01T11:20:47.850Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10242,
    },
    transactionType: 'transferOut',
    amount: -47,
  },
  {
    _id: 'sjbNtBDFHTgcQ9op4',
    createdAt: '2022-04-01T11:20:47.981Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1129,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'skt6xr8JoW2r3Cexy',
    createdAt: '2022-04-01T11:34:26.167Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 158,
    },
  },
  {
    _id: 's6Jm8evRwxEZ3pW2f',
    createdAt: '2022-04-01T11:36:48.848Z',
    transactionType: 'in',
    amount: 500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10230,
    },
  },
  {
    _id: 'naqQQk5EBBDsrRtYu',
    createdAt: '2022-04-01T11:37:15.376Z',
    transactionType: 'in',
    amount: 4800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10730,
    },
  },
  {
    _id: 'fRiCQedNPy9eGWbwb',
    createdAt: '2022-04-01T11:52:48.187Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 382,
    },
    transactionType: 'transferOut',
    amount: -225,
  },
  {
    _id: 'jEALXmqMFdCW5Daf3',
    createdAt: '2022-04-01T11:52:48.336Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10195,
    },
    transactionType: 'transferOut',
    amount: -226,
  },
  {
    _id: 'AZ4dwk2PdHcEk2cxN',
    createdAt: '2022-04-01T11:52:48.451Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1125,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '86qkLbgKRgLYS8mdu',
    createdAt: '2022-04-01T11:53:56.166Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 157,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'bD7xsHGgRrhts3Ygz',
    createdAt: '2022-04-01T11:53:56.306Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9969,
    },
    transactionType: 'transferOut',
    amount: -111,
  },
  {
    _id: '6DFrhZpBxQbyqREc5',
    createdAt: '2022-04-01T11:53:56.422Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1106,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'qZs4yaEQWdZiPf89R',
    createdAt: '2022-04-01T11:56:07.287Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 47,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'KbsSK8KL3tudjESjH',
    createdAt: '2022-04-01T11:56:07.454Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4849,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'ghuaSGeSWvvyLquog',
    createdAt: '2022-04-01T11:56:07.587Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1097,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'BjMixuuZtSyh4XuJg',
    createdAt: '2022-04-01T11:56:27.793Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 42,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'TwroDxxrNkwifJwWr',
    createdAt: '2022-04-01T11:56:27.933Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4838,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'dpbBjq6J6tg3km2h3',
    createdAt: '2022-04-01T11:56:28.048Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1096,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'eoeB7eZAo79FbLz9z',
    createdAt: '2022-04-01T12:09:17.541Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 498.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'sSd6rrMhNesnMxgTj',
    createdAt: '2022-04-01T12:09:17.686Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 818.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'hGGPLAGyajATMQkMg',
    createdAt: '2022-04-01T12:09:17.790Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 141.8500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'iizosLwG2XYH5AK9R',
    createdAt: '2022-04-01T12:09:17.921Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2681.3,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'K3rXY5EhHSCFG3a9Z',
    createdAt: '2022-04-01T12:09:18.174Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 150,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'fmuCY5nmCmiwpSyu5',
    createdAt: '2022-04-01T12:38:13.389Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 508,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'tZ45FCxNmZxgtjard',
    createdAt: '2022-04-01T12:38:13.498Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 1.5000000000000016,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'bhNchmqdtZSgnwCBk',
    createdAt: '2022-04-01T12:38:13.754Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 37,
    },
    transactionType: 'in',
    amount: 400,
  },
  {
    _id: 'QoEpHX8EQjAQ6mfA6',
    createdAt: '2022-04-01T12:42:01.890Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1287.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Rh2t7vJYbtc4vcje5',
    createdAt: '2022-04-01T12:42:02.035Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15530,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DxoG5ne7caFhDqNQw',
    createdAt: '2022-04-01T12:42:02.162Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2678.3,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'th7xvSpLdoW2rx4MT',
    createdAt: '2022-04-01T12:42:02.283Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 220,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'ofFpcrkaQfwgSWsrD',
    createdAt: '2022-04-01T12:42:02.408Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 148497.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '63yDdhwua3vQ7aERo',
    createdAt: '2022-04-01T12:42:02.565Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8825.279999999984,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'RSLM338fYHx222sAc',
    createdAt: '2022-04-01T12:42:02.705Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4230,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'XTyaMotEEComXs3yZ',
    createdAt: '2022-04-01T12:43:47.979Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1037.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Xckaa3T9jknKjG55j',
    createdAt: '2022-04-01T12:43:48.118Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15530,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R8ZvMjB4fBazckRfM',
    createdAt: '2022-04-01T12:43:48.238Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2628.3,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '6xG6oSbHEqHtGy97X',
    createdAt: '2022-04-01T12:43:48.363Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 150,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '9ykLAce5aHFaD8YWY',
    createdAt: '2022-04-01T12:43:48.481Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8822.279999999984,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'WJrXTy59qPEELuzZy',
    createdAt: '2022-04-01T12:43:48.595Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 146197.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'L8JNQc4nBn9FE4Nmq',
    createdAt: '2022-04-01T12:43:48.717Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 553.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'n6BiBRfGQhQQK2Cat',
    createdAt: '2022-04-01T12:43:48.874Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1722,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '3m6d35SnvqJBcD2qx',
    createdAt: '2022-04-01T12:43:48.989Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1132,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'eHZPHnchGJc9mLKA5',
    createdAt: '2022-04-01T12:45:13.557Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15530,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'ghRP3gn3FAaHbtQdv',
    createdAt: '2022-04-01T12:45:13.713Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 69.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '4xsJJgERmkwKaeuKc',
    createdAt: '2022-04-01T12:45:13.862Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 143897.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'G4bYeJyQ72Wmo3e8q',
    createdAt: '2022-04-01T12:45:13.975Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29196.6,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'gWZahHX8J9b7Rkf7K',
    createdAt: '2022-04-01T12:45:14.095Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8819.279999999984,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'vNJmYNGCuMmeki4CC',
    createdAt: '2022-04-01T12:45:14.233Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 688,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'RYhnG65ykuwuQhqha',
    createdAt: '2022-04-01T12:45:14.355Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5004,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'nkDwWwWitd5Wrt2ah',
    createdAt: '2022-04-02T01:29:06.160Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12530,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'xSgCyJiiDG3FTM2Yy',
    createdAt: '2022-04-02T01:29:06.297Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 787.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'TDbBuaYW8HuadTZu7',
    createdAt: '2022-04-02T01:29:06.413Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 565,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'GNsWmLKCD23QQHEsE',
    createdAt: '2022-04-02T01:29:06.525Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 454.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'u8sEkJCkFdZzWKHeG',
    createdAt: '2022-04-02T01:29:06.644Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1813,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: 'kLeh2i6RQGo2KbzhD',
    createdAt: '2022-04-02T01:29:06.755Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1118,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'EAWdZMiErDPb3TASd',
    createdAt: '2022-04-02T03:40:28.546Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8669.279999999984,
    },
  },
  {
    _id: 'WChCiuHETK5eQsXTs',
    createdAt: '2022-04-02T03:40:55.227Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2578.3,
    },
  },
  {
    _id: '8qjYxPhsLEdHicNFL',
    createdAt: '2022-04-02T03:41:36.894Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 142397.3,
    },
  },
  {
    _id: 'Rdchoon7sRYWFFXMX',
    createdAt: '2022-04-02T03:42:05.337Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 66.72500000000001,
    },
  },
  {
    _id: 'aKgGmLcwj6cyMCygs',
    createdAt: '2022-04-02T03:43:31.079Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1108,
    },
  },
  {
    _id: 'YreEedaWs4jHkQRJY',
    createdAt: '2022-04-02T03:43:49.500Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1095,
    },
  },
  {
    _id: 'RHhNiwRn2u3av8Qmx',
    createdAt: '2022-04-02T03:44:15.731Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3796,
    },
  },
  {
    _id: 'WsW77uYMs3Qm4Dszv',
    createdAt: '2022-04-02T03:44:40.085Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4163,
    },
  },
  {
    _id: 'MBLnHKGWZf3hjFT3E',
    createdAt: '2022-04-02T03:45:11.405Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 4944,
    },
  },
  {
    _id: 'D6XintFZWApbjvwxk',
    createdAt: '2022-04-02T03:45:39.947Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'FhZmc9J37ak4r8fgo',
        name: 'BUCKET 1 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F50ef1676-6804-485a-b5f6-5355bf469bca_200x200.jpg?alt=media&token=48f7bcda-a201-4a13-a9f3-d5442ebdbf66',
        },
      },
      quantity: 716,
    },
  },
  {
    _id: 'SHxNvH5Py3yuTB6W5',
    createdAt: '2022-04-02T03:46:06.217Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 690,
    },
  },
  {
    _id: 'H2gxowhCj3tNeJvv9',
    createdAt: '2022-04-02T06:17:09.814Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 449.46000000000026,
    },
  },
  {
    _id: 'vYYkSnFnSKKguQCDz',
    createdAt: '2022-04-02T06:17:35.614Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 515,
    },
  },
  {
    _id: 'Kvq2q8aRe6x3nftZh',
    createdAt: '2022-04-02T06:18:01.545Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1609,
    },
  },
  {
    _id: 'Ee8DsD82mPdbYHMw4',
    createdAt: '2022-04-02T06:18:31.338Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1183,
    },
  },
  {
    _id: 'e8SNgYstfpRNbpRCY',
    createdAt: '2022-04-02T06:19:33.473Z',
    transactionType: 'in',
    amount: 4000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 387.98000000000684,
    },
  },
  {
    _id: 'za8DKHtHT7txjEfEH',
    createdAt: '2022-04-02T06:23:05.019Z',
    transactionType: 'in',
    amount: 1600,
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 9,
    },
  },
  {
    _id: 'PuYWMKxqidX7sKXc9',
    createdAt: '2022-04-02T06:24:23.835Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12330,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HXrv6ctkpRHY5uAyJ',
    createdAt: '2022-04-02T06:24:23.988Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4387.980000000007,
    },
    transactionType: 'transferOut',
    amount: -825,
  },
  {
    _id: 'ZGahvxQYFawWvKGJr',
    createdAt: '2022-04-02T06:24:24.128Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'baaBTy6ra9iqtwsAt',
    createdAt: '2022-04-02T06:24:24.258Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2703.3,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '33QHC2wGYgzDMeWRs',
    createdAt: '2022-04-02T06:24:24.372Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 29016.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'PSWNnciL8wa2DxkcX',
    createdAt: '2022-04-02T06:24:24.493Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 192397.3,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'rqjFKza6cSDMAn5Mm',
    createdAt: '2022-04-02T06:24:24.611Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1609,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'mh23p6BLL45Yv5bFr',
    createdAt: '2022-04-02T06:24:24.730Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16745.6,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'mB8RdpFpLHpdpo2Kc',
    createdAt: '2022-04-02T06:24:24.854Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16731.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'NRqrTt7xXxFqeBCc2',
    createdAt: '2022-04-02T06:24:24.969Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 715,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'qBKeMmkTcpMgf7cH4',
    createdAt: '2022-04-02T06:24:25.091Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4363,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'M5mcyEoqx4ZhS3NPJ',
    createdAt: '2022-04-02T06:26:12.080Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3562.980000000007,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '4dEEA5n359DnLcCbM',
    createdAt: '2022-04-02T06:26:12.216Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12330,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'GZA8L2nwNQcpdLGpp',
    createdAt: '2022-04-02T06:26:12.330Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2679.3,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'QmBx9uG53oF3ax24d',
    createdAt: '2022-04-02T06:26:12.480Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 75,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '6HFitHzBY6HNRFSwm',
    createdAt: '2022-04-02T06:26:12.594Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8844.279999999984,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'hkoiQXyWb9zZSWXEF',
    createdAt: '2022-04-02T06:26:12.709Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 191997.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'nujzuMjGnx343757b',
    createdAt: '2022-04-02T06:26:12.824Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 531.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '8bcTtBYig83wSyJHE',
    createdAt: '2022-04-02T06:26:12.936Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1700,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'kzMGopSL5YjLmDkCp',
    createdAt: '2022-04-02T06:26:13.059Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1228,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'FHznyr5fbdSQ3b3GH',
    createdAt: '2022-04-02T06:28:16.635Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12280,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'RCTgYEoM78Bg9Wmwu',
    createdAt: '2022-04-02T06:28:16.780Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 116.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '4DeiQTDWx26PRi5sr',
    createdAt: '2022-04-02T06:28:16.934Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 189697.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'PGsAxxvaGJEAPhb8D',
    createdAt: '2022-04-02T06:28:17.056Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28976.6,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'QqkziW8wCXngqrhiY',
    createdAt: '2022-04-02T06:28:17.178Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8841.279999999984,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'ZEto2ortgHc8gTrWE',
    createdAt: '2022-04-02T06:28:17.303Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 328,
    },
    transactionType: 'transferOut',
    amount: -328,
  },
  {
    _id: '8sTQbxbh4s2djJhAk',
    createdAt: '2022-04-02T06:28:17.450Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5184,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'mJ4Hbtqj29a95Qw5N',
    createdAt: '2022-04-02T06:56:13.935Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 108,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: 'TXsPRhezn6EzKvBRh',
    createdAt: '2022-04-02T06:56:14.054Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9858,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: '2MkueFToYYLDGfvTZ',
    createdAt: '2022-04-02T06:56:14.167Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1170,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'rTMmAKjkt7nG5iCkF',
    createdAt: '2022-04-02T07:02:45.618Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 437,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: '7EECReYztJc29J8FT',
    createdAt: '2022-04-02T07:02:45.771Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4827,
    },
    transactionType: 'transferOut',
    amount: -111,
  },
  {
    _id: 'pbunMfrqzpuNNNQuh',
    createdAt: '2022-04-02T07:02:45.887Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1167,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'LtpaRD33E4vutQNTW',
    createdAt: '2022-04-02T07:03:33.231Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 382,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'KRQtZwf8gEBZscHCe',
    createdAt: '2022-04-02T07:03:33.375Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4716,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'C6MRT4vsTe2chSHPk',
    createdAt: '2022-04-02T07:03:33.493Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1158,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'JpaB5BxPrhTWPHd4T',
    createdAt: '2022-04-02T11:16:50.314Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9280,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'omx5pk25fobty4gPJ',
    createdAt: '2022-04-02T11:16:50.456Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3312.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'J9Qy6m6t6g4o4Lv7A',
    createdAt: '2022-04-02T11:16:50.563Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 615,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'NvNKtcmmKvFS6Nru4',
    createdAt: '2022-04-02T11:16:50.675Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 474.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '5NZfECJXkyJ6vqQpi',
    createdAt: '2022-04-02T11:16:50.784Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2109,
    },
    transactionType: 'transferOut',
    amount: -205,
  },
  {
    _id: 'Di949HSRjc24ft59Y',
    createdAt: '2022-04-02T11:16:50.892Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1214,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'L4SEAnWGN5raXpsoN',
    createdAt: '2022-04-02T11:23:33.808Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 70,
    },
  },
  {
    _id: '2GLetTxD342sC8nXY',
    createdAt: '2022-04-02T11:24:23.073Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 420,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'C2p6tbpncfcoRF2L8',
    createdAt: '2022-04-02T11:24:23.206Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9821,
    },
    transactionType: 'transferOut',
    amount: -111,
  },
  {
    _id: 'xvwuo2q2WStC3RPZu',
    createdAt: '2022-04-02T11:24:23.318Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4705,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'diZgvZR8p5Ke5cGHQ',
    createdAt: '2022-04-02T11:24:23.428Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1157,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'jsorKGtEwMrBmfgWC',
    createdAt: '2022-04-02T11:25:53.858Z',
    transactionType: 'in',
    amount: 2800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9080,
    },
  },
  {
    _id: 'QATgPZkSThhoQu2ya',
    createdAt: '2022-04-02T15:13:49.482Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 377,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'dvizKiKCQywwXtrD3',
    createdAt: '2022-04-02T15:13:49.633Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9710,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'RAExPoSGa65GhR53c',
    createdAt: '2022-04-02T15:13:49.755Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1147,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'iXRrukypQRCPX4G9G',
    createdAt: '2022-04-02T15:31:41.483Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11880,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'uijJCpwxhoYSW4vvk',
    createdAt: '2022-04-02T15:31:42.222Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4703,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'Nhdy7d5t6Gho3ZbgK',
    createdAt: '2022-04-02T15:31:42.333Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1145,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wf5FzWX9GFNtMDFcs',
    createdAt: '2022-04-02T15:58:41.566Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'raEgjWHRFbR77oW98',
    createdAt: '2022-04-02T15:58:41.708Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2912.980000000007,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'BM7gCmEPj3ZGeCCwN',
    createdAt: '2022-04-02T15:58:41.850Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Nm66N8zdYvE5Qwa5c',
    createdAt: '2022-04-02T15:58:41.990Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2629.3,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'brgxtF7dTaGMeqkNa',
    createdAt: '2022-04-02T15:58:42.111Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AfvgKa22GZ36Ki2mc',
    createdAt: '2022-04-02T15:58:42.225Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 469.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'yWc48NDjKjHc6LLzT',
    createdAt: '2022-04-02T15:58:42.342Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 33.050000000000004,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'm9XRtA4H5Fnj8d6MA',
    createdAt: '2022-04-02T15:58:42.460Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 25.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'azethEjKG8NybAJtf',
    createdAt: '2022-04-02T15:58:42.577Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16721.4,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'BdJrbrAnzHMgy28wb',
    createdAt: '2022-04-02T15:58:42.691Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3996,
    },
    transactionType: 'transferOut',
    amount: -192,
  },
  {
    _id: 'NYdvFDXCGkSSZR9Pf',
    createdAt: '2022-04-02T17:34:28.362Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11869,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'wXeN2fXtuGAenxTF9',
    createdAt: '2022-04-02T17:34:28.498Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2082.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '7LTFhEoxQSxwAAPMM',
    createdAt: '2022-04-02T17:34:29.224Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 565,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'a425FKv6mRLCoMpm8',
    createdAt: '2022-04-02T17:34:29.449Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 461.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'WWrkmwPzMSHFwovZQ',
    createdAt: '2022-04-02T17:34:29.561Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1904,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: 'BmtKZyDioZtPjLRZh',
    createdAt: '2022-04-02T17:34:29.672Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1204,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '8DWfectTtc5C63X82',
    createdAt: '2022-04-03T06:10:12.406Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6110,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'DL2uG83YydqG3LBWQ',
    createdAt: '2022-04-03T06:10:12.511Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8691.279999999984,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'YXEwyjtuiCu9Xjd5n',
    createdAt: '2022-04-03T06:10:12.616Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 188197.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'LdPhYPNas2uGam4hk',
    createdAt: '2022-04-03T06:10:12.720Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16701.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yPD9QNDcHbTarhRhL',
    createdAt: '2022-04-03T06:10:12.956Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: '3pmepj8Xb4Q7m9jmK',
    createdAt: '2022-04-03T06:11:28.858Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11669,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: '9s9xzxtvq2F8B9p7c',
    createdAt: '2022-04-03T06:11:29.005Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 113.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '5MqEbWQNAKWRLM3cG',
    createdAt: '2022-04-03T06:11:29.117Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 187697.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'qu87noq6dXCFCPYAS',
    createdAt: '2022-04-03T06:11:29.227Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28796.6,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'Wud6KkJkyiGWjt2oW',
    createdAt: '2022-04-03T06:11:29.344Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8491.279999999984,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'puM9jAKX428etFshJ',
    createdAt: '2022-04-03T06:11:29.459Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1000,
    },
    transactionType: 'transferOut',
    amount: -360,
  },
  {
    _id: 'EuJseaBSY7okzQfRz',
    createdAt: '2022-04-03T06:11:29.599Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5124,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'uiWkCPhhyzWDqQyZR',
    createdAt: '2022-04-04T01:25:33.129Z',
    transactionType: 'transferIn',
    amount: 150,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 515,
    },
  },
  {
    _id: 'XG5DFL27qnQGQFpe9',
    createdAt: '2022-04-04T01:26:06.173Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1700,
    },
  },
  {
    _id: 'awQxkGkaDsp8BthQq',
    createdAt: '2022-04-04T02:21:31.657Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8669,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '9SpcNQ5voqXYNqKc6',
    createdAt: '2022-04-04T02:21:31.795Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1682.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'KNjo5P3u3tP22CHfZ',
    createdAt: '2022-04-04T02:21:31.909Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 665,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '25HRcLtsevmS6gCzC',
    createdAt: '2022-04-04T02:21:32.021Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 456.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'AhGmDswn3hvEDQkeC',
    createdAt: '2022-04-04T02:21:32.137Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2700,
    },
    transactionType: 'transferOut',
    amount: -410,
  },
  {
    _id: '2EMDA2PZydRwFmetb',
    createdAt: '2022-04-04T02:21:32.251Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1194,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'nuybGka6uhpTjsJ7G',
    createdAt: '2022-04-04T03:25:57.440Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2629.3,
    },
  },
  {
    _id: 'njEgW2X3wHCik43GJ',
    createdAt: '2022-04-04T03:26:46.579Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8341.279999999984,
    },
  },
  {
    _id: 'nFsDYPoZ6DZhNjk79',
    createdAt: '2022-04-04T03:48:33.940Z',
    transactionType: 'transferIn',
    amount: 180,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5064,
    },
  },
  {
    _id: 'LPvrHtpAqkufrboq3',
    createdAt: '2022-04-04T04:20:27.595Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8269,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'NRtpFhnwbaMYSbJWc',
    createdAt: '2022-04-04T04:20:27.724Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2754.3,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vyjGQdvg2or2AW8TX',
    createdAt: '2022-04-04T04:20:27.832Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28606.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '5wLrEfiqPTND22TYm',
    createdAt: '2022-04-04T04:20:27.941Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16713.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'mz59b8MHSDCDFyaXM',
    createdAt: '2022-04-04T04:20:28.047Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8716.279999999984,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'nLLz8tgjdCfLpocpd',
    createdAt: '2022-04-04T04:20:28.153Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21657.4,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'd6pqcDFwwDrT4Jn2j',
    createdAt: '2022-04-04T04:20:28.264Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 509.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FmkCEJPCzjZ3MrTKb',
    createdAt: '2022-04-04T04:20:28.369Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1678,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'cYmuCmicMdk8YGCgM',
    createdAt: '2022-04-04T04:48:09.946Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7469,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'jr5Pqea6n3r52i6Hh',
    createdAt: '2022-04-04T04:48:10.083Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 110.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'rHYMq3zeofCKquQcp',
    createdAt: '2022-04-04T04:48:10.186Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 186197.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'giMFCiWCeKLN8phDQ',
    createdAt: '2022-04-04T04:48:10.294Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28566.6,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'mdvrJTexcrHjbufHf',
    createdAt: '2022-04-04T04:48:10.401Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8705.279999999984,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '7JCmzmEr8HACGbEL9',
    createdAt: '2022-04-04T04:48:10.514Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 640,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'THRmmEKf9Ff66a6ZS',
    createdAt: '2022-04-04T04:48:10.620Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5244,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'Zkja57q4wKMCbTLtu',
    createdAt: '2022-04-04T07:06:06.452Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 353,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'F5tJcagmMuP5LBikZ',
    createdAt: '2022-04-04T07:06:06.603Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9685,
    },
    transactionType: 'transferOut',
    amount: -69,
  },
  {
    _id: 'W5gqsLS926DFTrChF',
    createdAt: '2022-04-04T07:06:06.727Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1145,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'MAbY8CMLwBCm69TRk',
    createdAt: '2022-04-04T07:07:06.991Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 285,
    },
    transactionType: 'transferOut',
    amount: -108,
  },
  {
    _id: 'DK7uqfLFzDMzzcaxH',
    createdAt: '2022-04-04T07:07:07.132Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9616,
    },
    transactionType: 'transferOut',
    amount: -109,
  },
  {
    _id: 'zbd5yKnjEuSitSqgt',
    createdAt: '2022-04-04T07:07:07.250Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1139,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'NADwHhGdn3pvPC6YD',
    createdAt: '2022-04-04T07:07:18.037Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1661,
    },
  },
  {
    _id: 'M3aTTRnnJ9eg36c6H',
    createdAt: '2022-04-04T07:07:42.486Z',
    transactionType: 'transferIn',
    amount: 15,
    storeItem: {
      type: {
        _id: 'bhzF94p2BGEKqYnGu',
        name: 'GIFTS WHITE CARTON 300 X 270 X 250 MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd7c4710-c8ae-43b3-af6b-03d4e44ef40c_200x200.jpg?alt=media&token=075749c7-1a7a-4eb4-9be5-11973afafd1e',
        },
      },
      quantity: 20,
    },
  },
  {
    _id: 's29fHAYHTJsKcRPEy',
    createdAt: '2022-04-04T07:08:12.142Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 220,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'QRHeMyvfE3Y7rz84M',
    createdAt: '2022-04-04T07:08:12.260Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9507,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'kJ22HPxonRXEubizK',
    createdAt: '2022-04-04T07:08:12.379Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1130,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'k8JCEABbBNaLDiDY7',
    createdAt: '2022-04-04T07:08:27.648Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 18,
    },
  },
  {
    _id: 'AhTsdrhPTMv2vLzaW',
    createdAt: '2022-04-04T07:20:21.714Z',
    transactionType: 'in',
    amount: 2500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3469,
    },
  },
  {
    _id: 'PWuattnQPjTLzaznH',
    createdAt: '2022-04-04T07:22:04.452Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 166,
    },
  },
  {
    _id: 'tjgLLbfQbXWYRMK5i',
    createdAt: '2022-04-04T07:22:36.520Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 516,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'pCfcGAmTeMxknRGSK',
    createdAt: '2022-04-04T07:22:36.659Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9456,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: '8EDBF6wHjW5f82tMc',
    createdAt: '2022-04-04T07:22:36.777Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4682,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ohCRLFJEaqT9neqMA',
    createdAt: '2022-04-04T07:22:36.892Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1126,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'HwXKg6Niup2bPgBps',
    createdAt: '2022-04-04T07:22:40.571Z',
    transactionType: 'in',
    amount: 2950,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5969,
    },
  },
  {
    _id: 'cmAnKTLddLTM5Pzny',
    createdAt: '2022-04-04T07:23:51.803Z',
    transactionType: 'in',
    amount: 2800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8919,
    },
  },
  {
    _id: 'Zi99Nqp8SjrJ9uwrX',
    createdAt: '2022-04-04T07:24:20.658Z',
    transactionType: 'in',
    amount: 700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11719,
    },
  },
  {
    _id: 'bM6wWQjDjufkEN6NE',
    createdAt: '2022-04-04T07:29:16.531Z',
    transactionType: 'transferIn',
    amount: 2020,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 882.9800000000068,
    },
  },
  {
    _id: 'JAkJxYKvcQen9cGc6',
    createdAt: '2022-04-04T07:42:37.374Z',
    transactionType: 'out',
    amount: -700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12419,
    },
  },
  {
    _id: 'TrvjL77n6JJ3yqvfZ',
    createdAt: '2022-04-04T08:08:11.186Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9376,
    },
  },
  {
    _id: 'zqhLSpA4HHM4h4gY2',
    createdAt: '2022-04-04T08:08:18.955Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11719,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5g7LNX45Ft9k33xgT',
    createdAt: '2022-04-04T08:08:19.096Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2902.980000000007,
    },
    transactionType: 'transferOut',
    amount: -1340,
  },
  {
    _id: 'a5DCaymPXHbgn24Wy',
    createdAt: '2022-04-04T08:08:19.222Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ECST2NPSWC524dTMo',
    createdAt: '2022-04-04T08:08:19.375Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2754.3,
    },
    transactionType: 'transferOut',
    amount: -39.6,
  },
  {
    _id: 'StmKjnZtxuQwTYwTr',
    createdAt: '2022-04-04T08:08:19.493Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28316.6,
    },
    transactionType: 'transferOut',
    amount: -74,
  },
  {
    _id: 'K5vo5yfphJRPeN7uJ',
    createdAt: '2022-04-04T08:08:19.614Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 184197.3,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: '7jsvLCAMd9RkNku6d',
    createdAt: '2022-04-04T08:08:19.745Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1593,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'zEs56gTdLNbvF2uNi',
    createdAt: '2022-04-04T08:08:19.863Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16673.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'Xe4XpeRLqAQyYQjDF',
    createdAt: '2022-04-04T08:08:19.984Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16701.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'rfmnmGE5dC2v5rS3Z',
    createdAt: '2022-04-04T08:08:20.114Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 555,
    },
    transactionType: 'transferOut',
    amount: -245,
  },
  {
    _id: 'j9jixQokrc5exHfrD',
    createdAt: '2022-04-04T08:08:20.233Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4303,
    },
    transactionType: 'transferOut',
    amount: -98,
  },
  {
    _id: 'C53kvAEeJKTpAxn55',
    createdAt: '2022-04-04T08:08:36.072Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4205,
    },
  },
  {
    _id: '5JAP97egfdePA5X2X',
    createdAt: '2022-04-04T08:22:47.669Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1562.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'PyqadFK4ADjzRPzdh',
    createdAt: '2022-04-04T08:22:47.855Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pQCARD8qneSo9ToN9',
    createdAt: '2022-04-04T08:22:47.984Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2714.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'i7yyBtJ5ssG8HaarT',
    createdAt: '2022-04-04T08:22:48.102Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 183547.3,
    },
    transactionType: 'transferOut',
    amount: -290,
  },
  {
    _id: 'yu9gYn6afv6h3n59Q',
    createdAt: '2022-04-04T08:22:48.230Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '86BTj44dmnhEPY4Zu',
    createdAt: '2022-04-04T08:22:48.346Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8505.279999999984,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'nRNG9X8WyF7xHKuTH',
    createdAt: '2022-04-04T08:22:48.491Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F87490809-61cf-4c86-8e6c-97be3c8f9d18_200x200.jpeg?alt=media&token=fac38e50-d6ca-49ef-875e-831d15caaa81',
        },
      },
      quantity: 55,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'KC7JEAtpzNgqmCLGS',
    createdAt: '2022-04-04T08:22:48.637Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 604.5,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'LjfB9YmKnvzPtAvbX',
    createdAt: '2022-04-04T08:22:48.755Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 106.72500000000001,
    },
    transactionType: 'transferOut',
    amount: -0.05,
  },
  {
    _id: 'DdYDbvajnwu38LEhn',
    createdAt: '2022-04-04T08:22:48.868Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16691.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'PgnwSyNkC5nnSbZej',
    createdAt: '2022-04-04T08:22:48.995Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21643.4,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'x9GTxQcfmoK2SCeyP',
    createdAt: '2022-04-04T08:22:49.128Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5164,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HTxdQkK2Y3G6w8pKp',
    createdAt: '2022-04-04T08:22:49.254Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1961,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'b5gsHSPxY43yRagxb',
    createdAt: '2022-04-04T08:22:49.367Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1174,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'uKfBSFWkWFEQpeQxW',
    createdAt: '2022-04-04T08:26:59.703Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11719,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'zqsyQmSBjqr7Ytx48',
    createdAt: '2022-04-04T08:26:59.842Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1532.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gjtPw86yunoKM3nDQ',
    createdAt: '2022-04-04T08:26:59.952Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HGNhNXp2B7Ah5Dt4G',
    createdAt: '2022-04-04T08:27:00.067Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2707.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'q5qdG3BfnTZAY3LuE',
    createdAt: '2022-04-04T08:27:00.181Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28242.6,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'Rb22dckJFrN5Kkt3d',
    createdAt: '2022-04-04T08:27:00.292Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16681.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'PJpatetCHvkjz38r2',
    createdAt: '2022-04-04T08:27:00.409Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16633.6,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'Hr8oomp4MsS2ozA8m',
    createdAt: '2022-04-04T08:27:00.526Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 310,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'TAQFdBoW5ZeAHBfkj',
    createdAt: '2022-04-04T08:27:00.710Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 509.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'zwvs6gD4Ev9ZBN2hN',
    createdAt: '2022-04-04T08:27:00.841Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1939,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'dZTjC9u74kNewsFir',
    createdAt: '2022-04-04T08:27:00.982Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 222,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'kjAZZdf6BAWKtLjhP',
    createdAt: '2022-04-04T08:31:28.291Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1532.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -825,
  },
  {
    _id: 'D5vehP9bk5ighgkin',
    createdAt: '2022-04-04T08:31:28.427Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10919,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JQ3fhRKoh92D7j9Lz',
    createdAt: '2022-04-04T08:31:28.540Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vGWgt266z3rWuNHXE',
    createdAt: '2022-04-04T08:31:28.652Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2683.7000000000003,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3GmyFGwNJDFxGCLjd',
    createdAt: '2022-04-04T08:31:28.770Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8504.879999999985,
    },
    transactionType: 'transferOut',
    amount: -1.6,
  },
  {
    _id: 'JD2r3MoGn3bqSg8Di',
    createdAt: '2022-04-04T08:31:28.884Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16671.4,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'hRoeJ8ZCZ4QcQPfd5',
    createdAt: '2022-04-04T08:31:28.997Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.03999999999998721,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4fc5ST7LJ7ytFs3qP',
    createdAt: '2022-04-04T08:31:29.113Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 33.050000000000004,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'fcjiqYG6vkmdjpmBP',
    createdAt: '2022-04-04T08:31:29.231Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F070da88e-398d-41a7-8d61-3b67eb4d69e6_200x200.jpg?alt=media&token=54dee2e2-bb77-430f-bcab-5992cf9c03ed',
        },
      },
      quantity: 3804,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'GpdEdxWL365yZW7yT',
    createdAt: '2022-04-04T08:37:30.914Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10919,
    },
    transactionType: 'transferOut',
    amount: -350,
  },
  {
    _id: 'BZRTxvaQzu6DiJHNZ',
    createdAt: '2022-04-04T08:37:31.057Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2683.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'Whyjh3gsddcPjK7gX',
    createdAt: '2022-04-04T08:37:31.173Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28197.6,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'BkxuN5BaSJfmeWhJx',
    createdAt: '2022-04-04T08:37:31.283Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16603.6,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'tDhWxJRguBNySPjac',
    createdAt: '2022-04-04T08:37:31.399Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21621.4,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Hx5MRZHyspEKN7pXi',
    createdAt: '2022-04-04T08:37:31.515Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 412.9,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DendfTE76xZhN6bZX',
    createdAt: '2022-04-04T08:40:31.855Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10569,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'tw56P7F8vRDoTs8pw',
    createdAt: '2022-04-04T08:40:31.992Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2674.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '5YLbb2qgJYvDZGtid',
    createdAt: '2022-04-04T08:40:32.113Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 707.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'ectkoe6AYbtuLevT7',
    createdAt: '2022-04-04T08:40:32.229Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 446.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'nNtjTpGpm84rm8CGh',
    createdAt: '2022-04-04T08:40:32.339Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28179.6,
    },
    transactionType: 'transferOut',
    amount: -63,
  },
  {
    _id: 'EvtvKbinx32rrMMpp',
    createdAt: '2022-04-04T08:40:32.455Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'JHLDvnQiNTPDR5cfy',
    createdAt: '2022-04-04T08:40:32.570Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 183257.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'GT3msLr9urvBQ7JX5',
    createdAt: '2022-04-04T08:40:32.683Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4305,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: 'CzRPqJ2KNtgBc8zoT',
    createdAt: '2022-04-04T08:42:47.784Z',
    transactionType: 'transferIn',
    amount: 2100,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 207.98000000000684,
    },
  },
  {
    _id: 'pbxokAYg8uauKfvSr',
    createdAt: '2022-04-04T08:43:24.007Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3749,
    },
  },
  {
    _id: 'gTpCMBfFmtPpj7qsF',
    createdAt: '2022-04-04T08:44:56.644Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 710,
    },
  },
  {
    _id: 'GC7ShhSDZ5kwa4DQ7',
    createdAt: '2022-04-04T09:15:25.609Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2307.980000000007,
    },
    transactionType: 'transferOut',
    amount: -340,
  },
  {
    _id: '4qjdignGCgLHLzBqu',
    createdAt: '2022-04-04T09:15:25.751Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8izCzDWYesme7XCmK',
    createdAt: '2022-04-04T09:15:25.867Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10069,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jRZZEXF6uC7dL587Y',
    createdAt: '2022-04-04T09:15:25.976Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2662.7000000000003,
    },
    transactionType: 'transferOut',
    amount: -10.5,
  },
  {
    _id: 'qqRWJfx4ZaGv47rnc',
    createdAt: '2022-04-04T09:15:26.093Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28116.6,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'XYbESkgTcSsywKq48',
    createdAt: '2022-04-04T09:15:26.211Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 181757.3,
    },
    transactionType: 'transferOut',
    amount: -175,
  },
  {
    _id: 'LHMRhLY3Fd6geuN7o',
    createdAt: '2022-04-04T09:15:26.324Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16593.6,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '4Eo4fMt3stEicfrET',
    createdAt: '2022-04-04T09:15:26.436Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1567,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '8eA9zoYJCL2Kd4woc',
    createdAt: '2022-04-04T09:15:26.549Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 100,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'nKQHzf65mBRqxsySc',
    createdAt: '2022-04-04T09:15:26.667Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4253,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'b6gnZaHTCSJu6oWxd',
    createdAt: '2022-04-04T09:17:21.405Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1967.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'RJCaE6cxM6dnJBtaN',
    createdAt: '2022-04-04T09:17:21.541Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6oMNWmdwYW85AyzNW',
    createdAt: '2022-04-04T09:17:21.652Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10069,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8JddQQ6atF9ZhxDc6',
    createdAt: '2022-04-04T09:17:21.759Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 24.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'RXKP6cv89dT5bd3Ai',
    createdAt: '2022-04-04T09:17:21.870Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.03999999999998721,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nZPQZ2W9AobqWTQL2',
    createdAt: '2022-04-04T09:17:21.989Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16656.4,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '4yeEva7rxxHn38HG7',
    createdAt: '2022-04-04T09:17:22.101Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8503.279999999984,
    },
    transactionType: 'transferOut',
    amount: -1.8,
  },
  {
    _id: 'XLDz3XhoePNhSJab5',
    createdAt: '2022-04-04T09:17:22.212Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4249,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'tchpiD95jCDg8WE5u',
    createdAt: '2022-04-04T10:29:50.594Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10069,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6veyPhXiNZvNEGZv3',
    createdAt: '2022-04-04T10:29:50.737Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1127.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -820,
  },
  {
    _id: '9RJ5jBP3usFuJDrfm',
    createdAt: '2022-04-04T10:29:50.857Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'R47X3nRwyHwQNm5eY',
    createdAt: '2022-04-04T10:29:50.980Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2652.2000000000003,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nxtxt32nyKsuJpZBb',
    createdAt: '2022-04-04T10:29:51.098Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DCH39LaQ3Xnts7gCf',
    createdAt: '2022-04-04T10:29:51.214Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 439.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'ksGiTuDJ5TLujjawA',
    createdAt: '2022-04-04T10:29:51.334Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 31.550000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hsNsSiPE8cuRv9uGM',
    createdAt: '2022-04-04T10:29:51.458Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xNFCkLtSc8pWedFMm',
    createdAt: '2022-04-04T10:29:51.580Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16641.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'nSwmfhGifmkSf5GJE',
    createdAt: '2022-04-04T10:29:51.696Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4190,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'JZYYWM4Ap2MFBJeSs',
    createdAt: '2022-04-04T10:31:39.258Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5310,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'fFqm4X7PQB8chRAuw',
    createdAt: '2022-04-04T10:31:39.377Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8501.479999999985,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'oxdsbap8SovknARwq',
    createdAt: '2022-04-04T10:31:39.499Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 181582.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'Dh7axbzCxRPfdNukp',
    createdAt: '2022-04-04T10:31:39.614Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16629.4,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'eEWj4LacnnsTKfT49',
    createdAt: '2022-04-04T10:31:39.880Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 35,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: '7kQtG3qHNtTGaWNEY',
    createdAt: '2022-04-04T10:52:27.802Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 307.98000000000684,
    },
  },
  {
    _id: '2vEsMDeKkzx7pS6ce',
    createdAt: '2022-04-04T10:53:03.344Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685,
    },
  },
  {
    _id: 'uBfCeyuk2fFeMwmNn',
    createdAt: '2022-04-04T11:00:24.867Z',
    transactionType: 'transferIn',
    amount: 900,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 807.9800000000068,
    },
  },
  {
    _id: 'RL2aFKsDEg94pyG7t',
    createdAt: '2022-04-04T11:01:08.874Z',
    transactionType: 'transferIn',
    amount: 450,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1707.9800000000068,
    },
  },
  {
    _id: 'jALTAQJRpjnr3dxxR',
    createdAt: '2022-04-04T11:02:04.131Z',
    transactionType: 'transferIn',
    amount: 450,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2157.980000000007,
    },
  },
  {
    _id: 'fYAtzc4Zh2NRDiL2N',
    createdAt: '2022-04-04T11:02:30.615Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2607.980000000007,
    },
  },
  {
    _id: 'wKLmNbmRqc3nwfD7Y',
    createdAt: '2022-04-04T11:03:03.407Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3007.980000000007,
    },
  },
  {
    _id: 'oTrmjkN6TnL5TKvBm',
    createdAt: '2022-04-04T11:55:20.694Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10069,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'hayRJJPDqMRxbpsAp',
    createdAt: '2022-04-04T11:55:20.839Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3407.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'BiT8pxCfNYrtBsxyy',
    createdAt: '2022-04-04T11:55:20.954Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 565,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'BtDgFSrLJ4e9yxkFf',
    createdAt: '2022-04-04T11:55:21.097Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 431.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -3.5,
  },
  {
    _id: 'FfJkuNQvdNbTTeMbr',
    createdAt: '2022-04-04T11:55:21.241Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2290,
    },
    transactionType: 'transferOut',
    amount: -165,
  },
  {
    _id: 'inR2YM23RF5dJZ38M',
    createdAt: '2022-04-04T11:55:21.396Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1171,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'zHje5fqpNkd25TK3S',
    createdAt: '2022-04-04T13:00:16.447Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3007.980000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'HkL4vugRZ66msdK9R',
    createdAt: '2022-04-04T13:00:16.615Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rpPHKLtXvxdD9Dt3P',
    createdAt: '2022-04-04T13:00:16.755Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2652.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'XDMZqSmBGjKZtgDrx',
    createdAt: '2022-04-04T13:00:16.884Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8461.479999999985,
    },
    transactionType: 'transferOut',
    amount: -0.375,
  },
  {
    _id: 'xjmeKpS7NbvkHdnpo',
    createdAt: '2022-04-04T13:00:17.011Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178982.3,
    },
    transactionType: 'transferOut',
    amount: -287,
  },
  {
    _id: 'AB8pCXPyWffYsBPRd',
    createdAt: '2022-04-04T13:00:17.140Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2Rt2oX2jhFaqRmbbE',
    createdAt: '2022-04-04T13:00:17.314Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 579.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '7Raq3uxa7KtTCQwE7',
    createdAt: '2022-04-04T13:00:17.462Z',
    storeItem: {
      type: {
        _id: '7ePhj3YLMmNkm3HEF',
        name: 'FETA CHEESE SEMI FINAL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F87490809-61cf-4c86-8e6c-97be3c8f9d18_200x200.jpeg?alt=media&token=fac38e50-d6ca-49ef-875e-831d15caaa81',
        },
      },
      quantity: 35,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'BejLybKeg3p9rmsnq',
    createdAt: '2022-04-04T13:00:17.604Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 106.67500000000001,
    },
    transactionType: 'transferOut',
    amount: -0.05,
  },
  {
    _id: 'kLDLqkuYPqFnDRubC',
    createdAt: '2022-04-04T13:00:17.720Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1919,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'kfzFZEbjW23F6oJKA',
    createdAt: '2022-04-04T13:00:17.844Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 489.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'MwyLzAoyNywSgzpDT',
    createdAt: '2022-04-04T13:00:17.970Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5164,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'djtapjTEJHZTJibDJ',
    createdAt: '2022-04-04T13:36:14.044Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9919,
    },
    transactionType: 'transferOut',
    amount: -700,
  },
  {
    _id: 'd3FJgqTbgsnuwWZqv',
    createdAt: '2022-04-04T13:36:14.188Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2652.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'BNoSp65XS9hBEk6oT',
    createdAt: '2022-04-04T13:36:14.303Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28101.6,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'iNYibD5yAPrYzMXWp',
    createdAt: '2022-04-04T13:36:14.422Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16581.6,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'ZBkjmsdtEh7sMgeJm',
    createdAt: '2022-04-04T13:36:14.545Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 835,
    },
    transactionType: 'transferOut',
    amount: -112.5,
  },
  {
    _id: 'mMJHBR2YoeMtEgG74',
    createdAt: '2022-04-04T13:36:14.661Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4228,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'AbZDZWv9YrdNAFnQ7',
    createdAt: '2022-04-04T15:06:43.747Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 177,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Fjoey8Qv7kHDdWJnY',
    createdAt: '2022-04-04T15:06:43.905Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4680,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'WBgtJnMToLqZXSHFW',
    createdAt: '2022-04-04T15:06:44.029Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1119,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'gQ3My4DMHbdGC7hWY',
    createdAt: '2022-04-04T15:39:21.634Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 171,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'yKzf4fW8SQGmPtWra',
    createdAt: '2022-04-04T15:39:21.772Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4667,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'sNeX4a6AyBA2MtBTn',
    createdAt: '2022-04-04T15:39:21.888Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1118,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'muEAPfhWdGT4oHAaM',
    createdAt: '2022-04-05T03:54:05.067Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2987.980000000007,
    },
    transactionType: 'transferOut',
    amount: -384,
  },
  {
    _id: 'KnAcfqrkN6MJR5Gox',
    createdAt: '2022-04-05T03:54:05.205Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CweGXWGjB73erCSPy',
    createdAt: '2022-04-05T03:54:05.320Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9219,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7WJiFjp4fFkQjaDtd',
    createdAt: '2022-04-05T03:54:05.432Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28061.6,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jbjFxk2xB2eagmeo8',
    createdAt: '2022-04-05T03:54:05.543Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.03999999999998721,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xCpFCSqsShNGyNGRo',
    createdAt: '2022-04-05T03:54:05.657Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8461.504999999985,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'HWi8jY7WyAiFPeKfM',
    createdAt: '2022-04-05T03:54:05.769Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16609.4,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'HRdNRCe5qDZTBFWZt',
    createdAt: '2022-04-05T03:54:05.877Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4000,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'qqoN8YqZGEeYbXZue',
    createdAt: '2022-04-05T03:57:32.154Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9219,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KYn836hZ4Y5X2Mpob',
    createdAt: '2022-04-05T03:57:32.292Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2603.980000000007,
    },
    transactionType: 'transferOut',
    amount: -1031,
  },
  {
    _id: 'Tzuruu5MTF5i2qtbz',
    createdAt: '2022-04-05T03:57:32.407Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2685,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'a62RqbLXQfR7s8kLh',
    createdAt: '2022-04-05T03:57:32.518Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2631.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: 'QizWGgav9QQ8bTS2t',
    createdAt: '2022-04-05T03:57:32.626Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28061.6,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'PpoqiT3XtyTrf62FQ',
    createdAt: '2022-04-05T03:57:32.745Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178985.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'eNnki7EbzqxH36KDk',
    createdAt: '2022-04-05T03:57:32.856Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1561,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Yird9K3WiJf35FbdP',
    createdAt: '2022-04-05T03:57:32.976Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16556.6,
    },
    transactionType: 'transferOut',
    amount: -44,
  },
  {
    _id: 'rnNa6dn5KACbuLXFP',
    createdAt: '2022-04-05T03:57:33.087Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16603.4,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Hxt8enfseuLoGb9xY',
    createdAt: '2022-04-05T03:57:33.198Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 722.5,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'TvzCkB7CARbEP6rHk',
    createdAt: '2022-04-05T03:57:33.317Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4183,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: '97AEvXSEz9tAxZNCk',
    createdAt: '2022-04-05T03:59:40.184Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 488.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '4L673FzHqGhuMMr6y',
    createdAt: '2022-04-05T03:59:40.312Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 801.02,
    },
    transactionType: 'transferOut',
    amount: -34,
  },
  {
    _id: '3qkHwur3RnDNmv2WR',
    createdAt: '2022-04-05T03:59:40.418Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 141.6000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'YDfCaJydrqBjgqSX2',
    createdAt: '2022-04-05T03:59:40.528Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2600.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'bxrAaneQmnNHtn4Ks',
    createdAt: '2022-04-05T03:59:40.787Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 140,
  },
  {
    _id: '6wzYuRbegvAujhQKd',
    createdAt: '2022-04-05T04:00:54.947Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1572.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'pzhv7JNq76hBw8Kg9',
    createdAt: '2022-04-05T04:00:55.090Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9219,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'WrczRoMyABw2pwac5',
    createdAt: '2022-04-05T04:00:55.206Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2594.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'fZYa9eRd7AhGZ83Fi',
    createdAt: '2022-04-05T04:00:55.323Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'm9YAhfQD2efoMq6FS',
    createdAt: '2022-04-05T04:00:55.439Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8460.904999999984,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'bfCgPe35Ytrd448hf',
    createdAt: '2022-04-05T04:00:55.554Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178485.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'mjgYSfiF2zuSyevoX',
    createdAt: '2022-04-05T04:00:55.691Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 469.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Tn6C7KKLpHDFX5xof',
    createdAt: '2022-04-05T04:00:55.805Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1921,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '2jFCBrp9okc3eS5Wh',
    createdAt: '2022-04-05T04:00:55.930Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1166,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'uLgBfWDCrkYbZP9e8',
    createdAt: '2022-04-05T04:08:00.116Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4108,
    },
  },
  {
    _id: 'LSLqvxXrLqCmP7uBW',
    createdAt: '2022-04-05T04:08:24.182Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2125,
    },
  },
  {
    _id: 'wG2X6zyzyNE4gxepW',
    createdAt: '2022-04-05T04:08:48.688Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8457.904999999984,
    },
  },
  {
    _id: 'uXNgQe2DomKzwi5Gi',
    createdAt: '2022-04-05T04:09:18.889Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2544.2000000000003,
    },
  },
  {
    _id: 'DYwK9p7H5zXgwgnm7',
    createdAt: '2022-04-05T04:09:45.812Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 427.96000000000026,
    },
  },
  {
    _id: 'KYRsuSCdYsCGYXs5J',
    createdAt: '2022-04-05T04:10:11.070Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 114,
    },
  },
  {
    _id: 'FWN4NyBADMJS3sELo',
    createdAt: '2022-04-05T04:10:31.959Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 525,
    },
  },
  {
    _id: 'txXZp6GEqXXpZ9EiS',
    createdAt: '2022-04-05T04:10:56.104Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1152,
    },
  },
  {
    _id: 'gSuC3swrF243eDWXa',
    createdAt: '2022-04-05T04:11:53.276Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 767.02,
    },
  },
  {
    _id: 'xgbEGiEvHTDsYLHMf',
    createdAt: '2022-04-05T04:12:25.912Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 20,
    },
  },
  {
    _id: 'H6sAfannWgQdDAkLk',
    createdAt: '2022-04-05T04:57:42.754Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9169,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'BthJcF39rNQJXcSfm',
    createdAt: '2022-04-05T04:57:42.888Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2669.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'wEFc5wAfJTBWrM23r',
    createdAt: '2022-04-05T04:57:43.025Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 28001.6,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yddkFgTudyBSmue3T',
    createdAt: '2022-04-05T04:57:43.163Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 176185.3,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'hk2whSK3vDymopfXz',
    createdAt: '2022-04-05T04:57:43.275Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16512.6,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'px5TADXcZLA9FCnAR',
    createdAt: '2022-04-05T04:57:43.384Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 141.1000000000005,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'Yo9dhnWQj53KLNo9D',
    createdAt: '2022-04-05T04:57:43.498Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 218,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'gYibuvdGvoEtdj7C5',
    createdAt: '2022-04-05T04:57:43.610Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21633.4,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'vQ7ofqs8oJcjLPPrj',
    createdAt: '2022-04-05T05:08:12.023Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 625,
    },
  },
  {
    _id: '8NJbXTRW7auAr7SSu',
    createdAt: '2022-04-05T05:36:41.724Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 22,
    },
  },
  {
    _id: 'Ma7guzNsPF6sricHx',
    createdAt: '2022-04-05T06:32:39.393Z',
    transactionType: 'in',
    amount: 4,
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.03999999999998721,
    },
  },
  {
    _id: 'FBLGREyokpQp6qQWW',
    createdAt: '2022-04-05T06:40:39.652Z',
    transactionType: 'in',
    amount: 3450,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8969,
    },
  },
  {
    _id: 'nE6WpeyFtTkWHkvtS',
    createdAt: '2022-04-05T06:41:08.235Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 336,
    },
  },
  {
    _id: 'zitryACC7w7un2CuQ',
    createdAt: '2022-04-05T08:41:31.280Z',
    transactionType: 'transferIn',
    amount: 24,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 137,
    },
  },
  {
    _id: 'pGhqPsQ6bYtcFvKsT',
    createdAt: '2022-04-05T08:42:05.323Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 166.59999999999997,
    },
  },
  {
    _id: 'g3wWaa6ZYTadRioWv',
    createdAt: '2022-04-05T08:42:30.064Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 11,
    },
  },
  {
    _id: 'PaccvBLJReC35We5w',
    createdAt: '2022-04-05T09:29:00.157Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12419,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'vkQjcqnWQRZbKrxbe',
    createdAt: '2022-04-05T09:29:00.297Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2663.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'SuHnsAjGxxpKM9C3n',
    createdAt: '2022-04-05T09:29:00.443Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27991.6,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'pwskt8JCQtmbvazuX',
    createdAt: '2022-04-05T09:29:00.588Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16504.6,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'EANu3C8YTGpko2sAs',
    createdAt: '2022-04-05T09:29:00.730Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 522.5,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'nX6Dudgifz97bHiSM',
    createdAt: '2022-04-05T09:29:00.871Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4308,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: 'myFgzNMSbRrwS2Dr5',
    createdAt: '2022-04-05T09:59:15.064Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 468.5,
    },
  },
  {
    _id: '8iZifnaRdzjnnCL4T',
    createdAt: '2022-04-05T10:00:23.041Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 508.5,
    },
  },
  {
    _id: 'txB5itxgp5XsmCXcB',
    createdAt: '2022-04-05T10:27:29.181Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11819,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'FgvBqtJ96vis3Cpou',
    createdAt: '2022-04-05T10:27:29.328Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1322.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '7mAaCzjcX25JqrZ75',
    createdAt: '2022-04-05T10:27:29.443Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 675,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'wMrSpEQRab4yEfPMn',
    createdAt: '2022-04-05T10:27:29.558Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 452.96000000000026,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'pgafhSM5KfNETfTiK',
    createdAt: '2022-04-05T10:27:29.669Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2625,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'WPPixTfYE6qRqfmW3',
    createdAt: '2022-04-05T10:27:29.783Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1197,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'JEJTEjiubuaPRbvif',
    createdAt: '2022-04-05T12:23:28.827Z',
    transactionType: 'transferIn',
    amount: 4688,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1072.9800000000068,
    },
  },
  {
    _id: 'ELfvpFjYTAfRfHJPS',
    createdAt: '2022-04-05T12:34:25.539Z',
    transactionType: 'transferIn',
    amount: 580,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2685,
    },
  },
  {
    _id: 'XxrQnnDf47DkQ9BfB',
    createdAt: '2022-04-05T12:49:03.981Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11769,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'jy7seBX2FpyHGG7uq',
    createdAt: '2022-04-05T12:49:04.115Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5760.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'eqK7BDeJ49TgXKp2Z',
    createdAt: '2022-04-05T12:49:04.249Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 650,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'r7q36sysAMxTWQGvr',
    createdAt: '2022-04-05T12:49:04.358Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 450.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'bE9jg8G9BzZ8iLjSy',
    createdAt: '2022-04-05T12:49:04.468Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2503,
    },
    transactionType: 'transferOut',
    amount: -204,
  },
  {
    _id: '6opmn2zWQtmsjuxfp',
    createdAt: '2022-04-05T12:49:04.575Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1191,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'aJ692deJGJ8yNPWBZ',
    createdAt: '2022-04-05T12:54:04.611Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 5360.980000000007,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'oRPKaCryafuMskWhQ',
    createdAt: '2022-04-05T12:54:04.747Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3265,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cMnH6dJkdy35DW4pM',
    createdAt: '2022-04-05T12:54:04.884Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'g6ofupMv2zyuMRroZ',
    createdAt: '2022-04-05T12:54:05.018Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2639.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'R3ws5FwoQNz4gfhFL',
    createdAt: '2022-04-05T12:54:05.128Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'nhP2LaAZLa67yTjH9',
    createdAt: '2022-04-05T12:54:05.238Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16588.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'GvmZTdK3B5kiJWP5h',
    createdAt: '2022-04-05T12:54:05.348Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3HoqumSedxz2CQiFN',
    createdAt: '2022-04-05T12:54:05.460Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 4.039999999999988,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'G5KhWu4ruxPPd8quQ',
    createdAt: '2022-04-05T12:54:05.576Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 30.550000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'PhabhocoxCBbdsGba',
    createdAt: '2022-04-05T12:54:05.687Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ireGtmgww6zLMZQfs',
    createdAt: '2022-04-05T12:54:05.798Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8832.904999999984,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'vwfGfqEWoMzydBpTd',
    createdAt: '2022-04-05T12:54:05.915Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5164,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'F9rhAQwm7wrEWEv7Z',
    createdAt: '2022-04-05T13:37:53.215Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4530.980000000007,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'LgakWfcmJek6powrW',
    createdAt: '2022-04-05T13:37:53.352Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3265,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'h3tybrPfENFbxuDRP',
    createdAt: '2022-04-05T13:37:53.467Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'A3p3Ka5hN5x8icn7f',
    createdAt: '2022-04-05T13:37:53.583Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2633.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'zJZsWJmfZp4f5cx4b',
    createdAt: '2022-04-05T13:37:53.694Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27955.6,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '4DQo7Sj9yudKkw3C4',
    createdAt: '2022-04-05T13:37:53.810Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 176085.3,
    },
    transactionType: 'transferOut',
    amount: -325,
  },
  {
    _id: 'PGvRELMKbJkrMkZYx',
    createdAt: '2022-04-05T13:37:53.932Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16484.6,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'zC6a7AJm5sReawtuk',
    createdAt: '2022-04-05T13:37:54.059Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1539,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'eArNn2CWt9PQvm6Kg',
    createdAt: '2022-04-05T13:37:54.173Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 422.5,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'GuP4SkQvKreFNKLQN',
    createdAt: '2022-04-05T13:37:54.309Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4270,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'bgfAmxLJHhhKiSQyn',
    createdAt: '2022-04-05T13:45:47.793Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 165,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'kmGNRFJFDnqvnB4E6',
    createdAt: '2022-04-05T13:45:47.951Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10016,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'PGYph2iiYgh3Lckt3',
    createdAt: '2022-04-05T13:45:48.061Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1117,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'pbZmQraCBF8cdgTfL',
    createdAt: '2022-04-05T15:52:10.695Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fToCG9jR9XdNaBjDR',
    createdAt: '2022-04-05T15:52:10.861Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3880.980000000007,
    },
    transactionType: 'transferOut',
    amount: -640,
  },
  {
    _id: 'Qao9K5MbrqRabjGXh',
    createdAt: '2022-04-05T15:52:11.031Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3265,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ofoqEvhYgNkhGpmoY',
    createdAt: '2022-04-05T15:52:11.191Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2613.2000000000003,
    },
    transactionType: 'transferOut',
    amount: -19.2,
  },
  {
    _id: '8MXQKaKRdtYyaoFya',
    createdAt: '2022-04-05T15:52:11.353Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27925.6,
    },
    transactionType: 'transferOut',
    amount: -38,
  },
  {
    _id: 'MGtP45hzTTuEr82kv',
    createdAt: '2022-04-05T15:52:11.494Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175760.3,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: 'sARsh3FW5FGYgQqkC',
    createdAt: '2022-04-05T15:52:11.628Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1527,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'hAH3PjCwxQjq7pDNe',
    createdAt: '2022-04-05T15:52:11.763Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16460.6,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'YG6R2WjFnTvCQ7Hjt',
    createdAt: '2022-04-05T15:52:11.902Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16576.4,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'cDyhJwSD8R6EZdJaJ',
    createdAt: '2022-04-05T15:52:12.048Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 297.5,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'be6NT94ACbgGspcTW',
    createdAt: '2022-04-05T15:52:12.187Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4220,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'aoFhbPDqER3yzdLN4',
    createdAt: '2022-04-05T16:15:19.047Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CZkCq77jxNWhtEff4',
    createdAt: '2022-04-05T16:15:19.186Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3265,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wR7Z6Rnm95u5jQvES',
    createdAt: '2022-04-05T16:15:19.319Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3240.980000000007,
    },
    transactionType: 'transferOut',
    amount: -275,
  },
  {
    _id: 'nmNxRELLMfcjaHYTe',
    createdAt: '2022-04-05T16:15:19.432Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2594.0000000000005,
    },
    transactionType: 'transferOut',
    amount: -8.2,
  },
  {
    _id: 'CxzjqN3aMEryuJFy5',
    createdAt: '2022-04-05T16:15:19.547Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1512,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'HkpXhFyo6oFLMxmod',
    createdAt: '2022-04-05T16:15:19.655Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16435.6,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'M2oN7DNrBGZ3EASvr',
    createdAt: '2022-04-05T16:15:19.769Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175440.3,
    },
    transactionType: 'transferOut',
    amount: -140,
  },
  {
    _id: 'ycftumJoDGQbFpyWz',
    createdAt: '2022-04-05T16:15:19.886Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27887.6,
    },
    transactionType: 'transferOut',
    amount: -16.5,
  },
  {
    _id: 'exBk2rtjdF8mf2z4L',
    createdAt: '2022-04-05T16:15:19.996Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4174,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'wgSzP9xg9gK3ef8u8',
    createdAt: '2022-04-05T16:17:17.222Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 4550,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '4cY79jDbwtECZkd2J',
    createdAt: '2022-04-05T16:17:17.338Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8832.304999999984,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'jburCrFTfqBNtQkdx',
    createdAt: '2022-04-05T16:17:17.448Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175300.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'sMwHNEQgHdNvWCwcH',
    createdAt: '2022-04-05T16:17:17.558Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16561.4,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gcvfXcnK4DYTFHEPj',
    createdAt: '2022-04-05T16:17:17.808Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 140,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'N4T7nx6rF6yFcXT6n',
    createdAt: '2022-04-05T16:18:34.691Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2965.980000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FNsLA6zWN3uAbB8ti',
    createdAt: '2022-04-05T16:18:34.823Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3265,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'daQHcAtR5MEoJubTq',
    createdAt: '2022-04-05T16:18:34.930Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 106.67500000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'mLFMGtamxsGrD9Mk8',
    createdAt: '2022-04-05T16:18:35.042Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174800.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'oijjW88YCZodsw2DL',
    createdAt: '2022-04-05T16:18:35.175Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27871.1,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'RjzTrPLf7GZcxrGYx',
    createdAt: '2022-04-05T16:18:35.429Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2210.1,
    },
    transactionType: 'in',
    amount: 235,
  },
  {
    _id: 'YAQgPjxKMnuNgtd7P',
    createdAt: '2022-04-05T16:20:34.910Z',
    transactionType: 'in',
    amount: 10000,
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 3750,
    },
  },
  {
    _id: 'gbxC6t3dgubuQjqTP',
    createdAt: '2022-04-05T18:46:29.829Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'CRDDcyKxGRZfxiEhR',
    createdAt: '2022-04-05T18:46:29.965Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 103.67500000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'hXZmFivrSHHkyGQFB',
    createdAt: '2022-04-05T18:46:30.078Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 173300.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'LXGW8y3YZzPLsJaXn',
    createdAt: '2022-04-05T18:46:30.188Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27691.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'eX4CqJHtto3ndSi7M',
    createdAt: '2022-04-05T18:46:30.295Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8632.304999999984,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '65ESDTSq5oze5TNYx',
    createdAt: '2022-04-05T18:46:30.413Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1140,
    },
    transactionType: 'transferOut',
    amount: -520,
  },
  {
    _id: 'nwSA24fwEw9sDL2uN',
    createdAt: '2022-04-05T18:46:30.523Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5088,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'yNjqAcgqdBsrBSqww',
    createdAt: '2022-04-05T20:02:50.789Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 13750,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'QA5FAbYynooLH6ztR',
    createdAt: '2022-04-05T20:02:50.902Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8432.304999999984,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'wGHS5QLrqM2XxfBEP',
    createdAt: '2022-04-05T20:02:51.019Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171300.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'P8DBjki5EnaD9h259',
    createdAt: '2022-04-05T20:02:52.085Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16561.4,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'JnykFFCE5u2BAAdib',
    createdAt: '2022-04-05T20:02:52.350Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 177.5,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'EpFBCe7fGk293rbQL',
    createdAt: '2022-04-05T20:03:49.376Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '452CmstGfmJpd4rf3',
    createdAt: '2022-04-05T20:03:49.512Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2965.980000000007,
    },
    transactionType: 'transferOut',
    amount: -1550,
  },
  {
    _id: 'pBuidQZQRHqL7ys5R',
    createdAt: '2022-04-05T20:03:49.633Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 265,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'C96cvH7gpHRacsgRj',
    createdAt: '2022-04-05T20:03:49.749Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2585.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'bRNibrBAx4LJPrKL2',
    createdAt: '2022-04-05T20:03:49.864Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27441.1,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'PezgJDP7CvF7LbMQ9',
    createdAt: '2022-04-05T20:03:49.974Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168700.3,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '7kjdWRcAQTaQWRrWe',
    createdAt: '2022-04-05T20:03:50.089Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1506,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'hJbspesw5bvAnz5gr',
    createdAt: '2022-04-05T20:03:50.206Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16423.6,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'wCynzY6TRSm3LMKG5',
    createdAt: '2022-04-05T20:03:50.318Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16531.4,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'aWzQpPpgzxTAxbjXN',
    createdAt: '2022-04-05T20:03:50.431Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 977.5,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'DTT4CubJDvdaYwWNP',
    createdAt: '2022-04-05T20:03:50.555Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4156,
    },
    transactionType: 'transferOut',
    amount: -114,
  },
  {
    _id: 'sYpQAAvgLPbSsbscJ',
    createdAt: '2022-04-06T02:54:05.635Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe087aa06-9bbe-4765-90e8-807e6bd3e88b_200x200.jpg?alt=media&token=07849047-5931-407a-9fca-50e2923b7bbf',
        },
      },
      quantity: 7,
    },
  },
  {
    _id: 'cHdiHEc4GsCc28gJB',
    createdAt: '2022-04-06T03:59:53.657Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 528.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'gs34gcvCGzsrPHZyE',
    createdAt: '2022-04-06T03:59:53.790Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 827.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'hAwtCQmofM8jSAWWW',
    createdAt: '2022-04-06T03:59:53.898Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 137.1000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'JwniZmnrGetTTAxYM',
    createdAt: '2022-04-06T03:59:54.007Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2537.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'KvSJaf64yLNBJEnME',
    createdAt: '2022-04-06T03:59:54.254Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: '2XR5XA9Gaf5bLqHqs',
    createdAt: '2022-04-06T04:10:02.288Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 686,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'A8HmuzFmR52oPv8fQ',
    createdAt: '2022-04-06T04:10:03.435Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10006,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: '2umXa4GDCCjbYG87e',
    createdAt: '2022-04-06T04:10:03.544Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1116,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '2Qs4CBemdqe9DpNFP',
    createdAt: '2022-04-06T04:11:37.312Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 628,
    },
    transactionType: 'transferOut',
    amount: -221,
  },
  {
    _id: 'nyyaW3pLqdXFGzYLg',
    createdAt: '2022-04-06T04:11:37.452Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9950,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'AbN9RRaDDW7QEANHM',
    createdAt: '2022-04-06T04:11:37.560Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4654,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '23Am3i6d6HAZrS2q6',
    createdAt: '2022-04-06T04:11:37.677Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1111,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'JNGPwxDgs3zBBYe8a',
    createdAt: '2022-04-06T04:42:56.320Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1415.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'M3w4dKPJKxEwbufjX',
    createdAt: '2022-04-06T04:42:56.459Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MTdEpKzaW5KnrAxxX',
    createdAt: '2022-04-06T04:42:56.570Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2534.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'XygyPJvGAhvNG6QBv',
    createdAt: '2022-04-06T04:42:56.686Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: '5L4tJQdYfrZZf4TQT',
    createdAt: '2022-04-06T04:42:56.800Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8392.304999999984,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'iQYiQBzFmpBqoFHwD',
    createdAt: '2022-04-06T04:42:56.913Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167900.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '6ZkSYdDjuSHdx33to',
    createdAt: '2022-04-06T04:42:57.025Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 447.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'PAhAiaACJXMmHhEwt',
    createdAt: '2022-04-06T04:42:57.140Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1899,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'vj2i3ytk2MjG7zkbY',
    createdAt: '2022-04-06T04:42:57.255Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1181,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'DqzTABEMubWBHiaNm',
    createdAt: '2022-04-06T05:02:11.926Z',
    transactionType: 'transferIn',
    amount: 211,
    storeItem: {
      type: {
        _id: 'iBGcmoApdk7FwLi8v',
        name: 'FULL FAT ORGANIC FRESH MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F67aa256c-0e88-412f-82b9-1779322f6dbb_200x200.jpg?alt=media&token=76e33d9e-a949-4f64-91d6-d6919f88b570',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'qpCrtwp2KibJ9G3SC',
    createdAt: '2022-04-06T05:10:02.561Z',
    transactionType: 'in',
    amount: 5,
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 0.3000000000000016,
    },
  },
  {
    _id: 'meq5uD6Ci9YMctbK7',
    createdAt: '2022-04-06T05:13:55.688Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 407,
    },
    transactionType: 'transferOut',
    amount: -407,
  },
  {
    _id: 'GidoRXCZefBz3ierk',
    createdAt: '2022-04-06T05:13:55.798Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 5.300000000000002,
    },
    transactionType: 'transferOut',
    amount: -1.3,
  },
  {
    _id: 'ty45hajXAMNLp5t7o',
    createdAt: '2022-04-06T05:13:56.050Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 154,
    },
    transactionType: 'in',
    amount: 405,
  },
  {
    _id: 'hiwBvyst8XuDAXNWA',
    createdAt: '2022-04-06T05:15:06.587Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 559,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'ayBz925QrhSK9XgHy',
    createdAt: '2022-04-06T05:15:06.727Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9850,
    },
    transactionType: 'transferOut',
    amount: -251,
  },
  {
    _id: 'u8ofiFyEj6sJW7u34',
    createdAt: '2022-04-06T05:15:06.845Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1102,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: '56uHbtRRrGKw8xx5f',
    createdAt: '2022-04-06T05:15:54.287Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 309,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'oP2NbjC9ZmnCM54nw',
    createdAt: '2022-04-06T05:15:54.426Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9599,
    },
    transactionType: 'transferOut',
    amount: -181,
  },
  {
    _id: 'aYTD8AZLoqZiQqtjR',
    createdAt: '2022-04-06T05:15:54.537Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1081,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'DxKZqxpxTdLj4ELsK',
    createdAt: '2022-04-06T06:55:36.983Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2484.8000000000006,
    },
  },
  {
    _id: '4B5Y3c9AvrXBDQtcb',
    createdAt: '2022-04-06T06:56:39.789Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 600,
    },
  },
  {
    _id: 'gGPyyfGKHeuQPg9xD',
    createdAt: '2022-04-06T06:57:12.388Z',
    transactionType: 'transferIn',
    amount: 820,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9418,
    },
  },
  {
    _id: 'PWwgTmNyisjCe7gkv',
    createdAt: '2022-04-06T07:49:47.804Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5008,
    },
  },
  {
    _id: 'QTTwp5KmS75uG3Z66',
    createdAt: '2022-04-06T07:50:07.116Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4042,
    },
  },
  {
    _id: 'ey5u3JigAdbeaM6kJ',
    createdAt: '2022-04-06T07:50:21.899Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 730,
    },
  },
  {
    _id: 'mN86ypPC9xX97iQC5',
    createdAt: '2022-04-06T07:50:45.706Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1108,
    },
  },
  {
    _id: 'q3wArtm88K7yZnTk5',
    createdAt: '2022-04-06T07:51:09.981Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 6226,
    },
  },
  {
    _id: 't4XAR5iRuZn5bbKXw',
    createdAt: '2022-04-06T07:51:25.809Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 810.02,
    },
  },
  {
    _id: 'Y5cEnfPQnEbFpQJ7E',
    createdAt: '2022-04-06T07:51:54.567Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F36a083ae-23ac-4407-8771-4336e2246576_200x200.jpg?alt=media&token=1306ff4d-a3a4-4218-85f5-808fbf3dff2f',
        },
      },
      quantity: 10,
    },
  },
  {
    _id: 'WgpytJncWm64G5N3n',
    createdAt: '2022-04-06T08:16:29.461Z',
    transactionType: 'transferIn',
    amount: 110.6,
    storeItem: {
      type: {
        _id: '4rrmm3JfMLBnXwERi',
        name: 'yoghurt ',
        unit: 'kg',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe381ef71-e198-428b-a614-0f360881c3bc_200x200.jpg?alt=media&token=ee3d6db4-f4d9-4c26-bc3d-ed74a433cad1',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'ne8DSwj7Dn6jrGKYG',
    createdAt: '2022-04-06T08:45:38.754Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 584.5,
    },
    transactionType: 'transferOut',
    amount: -18.5,
  },
  {
    _id: 'LyxXGXDyKetrhjayX',
    createdAt: '2022-04-06T08:45:38.943Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 206.59999999999997,
    },
    transactionType: 'transferOut',
    amount: -6.8,
  },
  {
    _id: 'aDc8rxTR6T3AugKis',
    createdAt: '2022-04-06T08:45:39.059Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 161,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: 'CYjta7y5sJj47kYED',
    createdAt: '2022-04-06T08:45:39.174Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4342,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'ABk9sZzqmzQHPpv9m',
    createdAt: '2022-04-06T08:46:23.470Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 566,
    },
    transactionType: 'transferOut',
    amount: -18.5,
  },
  {
    _id: 'NwEnxZWcCKQNMzzew',
    createdAt: '2022-04-06T08:46:23.651Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 199.79999999999995,
    },
    transactionType: 'transferOut',
    amount: -6.8,
  },
  {
    _id: 'vfX52cL5Sf3tDwZof',
    createdAt: '2022-04-06T08:46:23.807Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 156.5,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: 'Nt6LKcAKQPj52XLMN',
    createdAt: '2022-04-06T08:46:23.926Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4333,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'uerBujji25KkcLsrn',
    createdAt: '2022-04-06T08:47:22.917Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 547.5,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'oDK6RYayjLogsmkBm',
    createdAt: '2022-04-06T08:47:23.097Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 192.99999999999994,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'k8qnr37AHaDwbdcLh',
    createdAt: '2022-04-06T08:47:23.230Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 152,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'vtBWtAbeEn9HftnkX',
    createdAt: '2022-04-06T08:47:23.367Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4324,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'AX5sEdqjXBwaLg66j',
    createdAt: '2022-04-06T08:47:23.503Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5308,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eSgYRqx9esNFxH56f',
    createdAt: '2022-04-06T08:48:51.612Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 8226,
    },
  },
  {
    _id: 'xP5DaWJP7bfphdDcP',
    createdAt: '2022-04-06T08:49:14.001Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4312,
    },
  },
  {
    _id: 'tRjKGBMvhe7bqcWpS',
    createdAt: '2022-04-06T08:53:09.396Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1165.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'JdLZSyjWLtrDWPmNN',
    createdAt: '2022-04-06T08:53:09.529Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 265,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZmpTsiXMJZbw6mM6H',
    createdAt: '2022-04-06T08:53:09.637Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jErwwv9LcwAai5tZ3',
    createdAt: '2022-04-06T08:53:09.754Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2659.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'iDqb5LnGyhEikgeyx',
    createdAt: '2022-04-06T08:53:09.864Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 130,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'f7CcsWbtKw7HT87yf',
    createdAt: '2022-04-06T08:53:09.982Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16506.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'vwKHouqNXWDDkFmxX',
    createdAt: '2022-04-06T08:53:10.091Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'utCL6yrbdxxXD4u93',
    createdAt: '2022-04-06T08:53:10.205Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 3.5399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '5HYEs3CtPZDraSNfb',
    createdAt: '2022-04-06T08:53:10.317Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 29.550000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'enoAedpuaDp9qebSW',
    createdAt: '2022-04-06T08:53:10.427Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QTeDPXnXfhH7YswRL',
    createdAt: '2022-04-06T08:53:10.537Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8389.304999999984,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '63DrX8LWT8ERtXM6k',
    createdAt: '2022-04-06T08:53:10.645Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5308,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'FuJ8pKbT5Jdgbfwdd',
    createdAt: '2022-04-06T08:58:49.439Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7569,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'LZqkHxi2LELPBxRRK',
    createdAt: '2022-04-06T08:58:49.579Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 335.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'SycGGzjrAf46AWr4t',
    createdAt: '2022-04-06T08:58:49.699Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2652.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'fuYkKzXGMHJAgDDYc',
    createdAt: '2022-04-06T08:58:49.827Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'rjztmn4LsqGcMWorF',
    createdAt: '2022-04-06T08:58:49.978Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165600.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: '3krHatbkGMpkas6oF',
    createdAt: '2022-04-06T08:58:50.095Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8388.704999999984,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ADseASQAMer7D2N2Y',
    createdAt: '2022-04-06T08:58:50.214Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 425.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'ktC2xbJrDZr3SYs9H',
    createdAt: '2022-04-06T08:58:50.329Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1877,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'xNdPpKSwBMBTQkxwW',
    createdAt: '2022-04-06T09:01:07.718Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7519,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'MHbsvvp4DZdfRF54m',
    createdAt: '2022-04-06T09:01:07.853Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 99.67500000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '528cPtSLSYaYvfp8g',
    createdAt: '2022-04-06T09:01:07.965Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163300.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'xh94tWfnX6x8gaApv',
    createdAt: '2022-04-06T09:01:08.083Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27351.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'YZbeXxFGZ8TsZsmEZ',
    createdAt: '2022-04-06T09:01:08.190Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8385.704999999984,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'Qgdj46fERPZ8bPuTu',
    createdAt: '2022-04-06T09:01:08.303Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 620,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'YucnMWwdzcxYvbvWk',
    createdAt: '2022-04-06T09:01:08.416Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5231,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'wTkzPdnhnBY3pYSP3',
    createdAt: '2022-04-06T10:42:42.400Z',
    transactionType: 'transferIn',
    amount: 2200,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 85.98000000000684,
    },
  },
  {
    _id: 'pXkH9ZWdFQrzzPWAA',
    createdAt: '2022-04-06T10:43:50.290Z',
    transactionType: 'transferIn',
    amount: 1500,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 265,
    },
  },
  {
    _id: 'SrydCY6eZfQLXuk47',
    createdAt: '2022-04-06T10:47:03.139Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2285.980000000007,
    },
  },
  {
    _id: 'S5fYfYn8b9WAJY7vj',
    createdAt: '2022-04-06T11:10:50.763Z',
    transactionType: 'transferIn',
    amount: 39.2,
    storeItem: {
      type: {
        _id: '4rrmm3JfMLBnXwERi',
        name: 'yoghurt ',
        unit: 'kg',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe381ef71-e198-428b-a614-0f360881c3bc_200x200.jpg?alt=media&token=ee3d6db4-f4d9-4c26-bc3d-ed74a433cad1',
        },
      },
      quantity: 110.6,
    },
  },
  {
    _id: 'PfrpdSNW78XqNDpaY',
    createdAt: '2022-04-06T12:31:59.393Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3519,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'd5zp8Y5xKJm2e6KsN',
    createdAt: '2022-04-06T12:31:59.568Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2685.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'RBBxfGmzBknxSDpBb',
    createdAt: '2022-04-06T12:31:59.684Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 625,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ZtZNqMTFTTj5pmKp2',
    createdAt: '2022-04-06T12:31:59.798Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 445.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'ijsKDqBfrFRpbPa3u',
    createdAt: '2022-04-06T12:31:59.914Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2299,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'k95f45u5Xx3PXPbAd',
    createdAt: '2022-04-06T12:32:00.052Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1167,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'gN8crXH7GXeRrNfgj',
    createdAt: '2022-04-06T13:53:46.621Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2285.980000000007,
    },
    transactionType: 'transferOut',
    amount: -640,
  },
  {
    _id: 'H7ZZnz4KGaLbitfss',
    createdAt: '2022-04-06T13:53:46.771Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1765,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CadJNeerAFf5pXJYt',
    createdAt: '2022-04-06T13:53:46.892Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3319,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qgwSTDAHBWfjuoxK9',
    createdAt: '2022-04-06T13:53:47.008Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2602.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -19.2,
  },
  {
    _id: '79MCZbHNuocc39Gye',
    createdAt: '2022-04-06T13:53:47.131Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27101.1,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'E6N6ZucNkXo2ymwnG',
    createdAt: '2022-04-06T13:53:47.250Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 161300.3,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: 'M9En4Z6KoyuWAukDR',
    createdAt: '2022-04-06T13:53:47.371Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16363.599999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'mGqnaDaDKbZG7345o',
    createdAt: '2022-04-06T13:53:47.490Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1474,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'sSy8drqetWc97vwvZ',
    createdAt: '2022-04-06T13:53:47.606Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 677.5,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'Nju4aM4qXaNj9TSsm',
    createdAt: '2022-04-06T13:53:47.735Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4412,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'D5xA6ZH7WLGwKJ4ou',
    createdAt: '2022-04-06T13:58:00.494Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 522.5,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: '4pPTNgpBabFiWLi6u',
    createdAt: '2022-04-06T13:58:00.669Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 183.99999999999994,
    },
    transactionType: 'transferOut',
    amount: -6.75,
  },
  {
    _id: 'oRjPdDZiCK4zfRRRX',
    createdAt: '2022-04-06T13:58:00.789Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 146,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: '9jXDNSF7hHNmYSpDP',
    createdAt: '2022-04-06T13:58:00.909Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4364,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'ib78BqPdubNn5fgqg',
    createdAt: '2022-04-06T14:58:17.553Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3319,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'eBWn8Y69uqYSthMdw',
    createdAt: '2022-04-06T14:58:17.698Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2583.600000000001,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'Dyx378RuMqaKRB6v9',
    createdAt: '2022-04-06T14:58:17.843Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27066.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'iMeCKoHoBJgpdF4zm',
    createdAt: '2022-04-06T14:58:17.986Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16338.599999999999,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ZoqRnNxPEtpnaPsz8',
    createdAt: '2022-04-06T14:58:18.130Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4355,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'RFYKPBuKAJkCsw2xg',
    createdAt: '2022-04-06T17:14:12.369Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2819,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CykdqWNJFfYFvNBXt',
    createdAt: '2022-04-06T17:14:12.511Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1765,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4Fi8pHBdRCyKMFBv2',
    createdAt: '2022-04-06T17:14:12.623Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1645.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'cYC4xbGczmpkWyorB',
    createdAt: '2022-04-06T17:14:12.742Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2563.600000000001,
    },
    transactionType: 'transferOut',
    amount: -5.4,
  },
  {
    _id: 'ptTAYqqhXXtPLNFyR',
    createdAt: '2022-04-06T17:14:12.857Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1459,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'WHN8p6zxCKSty7tsD',
    createdAt: '2022-04-06T17:14:13.719Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16318.599999999999,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'p8CDM7f9aiNxBezJj',
    createdAt: '2022-04-06T17:14:13.858Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160980.3,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'KYqyMnRiiqmhExNaZ',
    createdAt: '2022-04-06T17:14:13.974Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27036.1,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'tM4yEkC2CDHEBxpxA',
    createdAt: '2022-04-06T17:14:14.818Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4323,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'GfE4SFgwrmTk3AhkP',
    createdAt: '2022-04-06T17:15:46.591Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2819,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'AnBhc2Lc9afnFtuso',
    createdAt: '2022-04-06T17:15:46.733Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1465.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3LrtndkMQMzmndSiQ',
    createdAt: '2022-04-06T17:15:46.861Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1765,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dfacfJQcQA6GGoTZS',
    createdAt: '2022-04-06T17:15:46.981Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2558.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'eYy7pWEwEtMuYDKcf',
    createdAt: '2022-04-06T17:15:47.106Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 27025.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '3Nh69eZ63Sy98uCsd',
    createdAt: '2022-04-06T17:15:47.245Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16310.599999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '9eDDXuWzYrLw3zkYM',
    createdAt: '2022-04-06T17:15:47.365Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16494.4,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '7eLjeCHR4W2nQKPPT',
    createdAt: '2022-04-06T17:15:47.499Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 552.5,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'X8ezgqm9zbzsKRsDW',
    createdAt: '2022-04-06T17:15:47.629Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4311,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'BhTWYdE2MQ5tQY8gf',
    createdAt: '2022-04-06T20:13:01.510Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2019,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BmDGzQhy2SifDt8v4',
    createdAt: '2022-04-06T20:13:01.682Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1465.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -1400,
  },
  {
    _id: '6qcRbMiJD7CKDutqv',
    createdAt: '2022-04-06T20:13:01.799Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1765,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'GBWyBaKBqp75f9Zuh',
    createdAt: '2022-04-06T20:13:01.917Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2534.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'SvjSMbJYZyvTd8HdH',
    createdAt: '2022-04-06T20:13:02.029Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26985.1,
    },
    transactionType: 'transferOut',
    amount: -85,
  },
  {
    _id: 'm3tbsaihygATRrbrX',
    createdAt: '2022-04-06T20:13:02.143Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160890.3,
    },
    transactionType: 'transferOut',
    amount: -850,
  },
  {
    _id: 'jDkDNACDaFWmLsQbi',
    createdAt: '2022-04-06T20:13:02.261Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1455,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: 'ifXha4FKSTJjBaNw8',
    createdAt: '2022-04-06T20:13:02.378Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16285.599999999999,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'jM3sxrDp7uLj7CS3p',
    createdAt: '2022-04-06T20:13:02.489Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16479.4,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'JJdQyawe6yj7nwMv9',
    createdAt: '2022-04-06T20:13:02.602Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 432.5,
    },
    transactionType: 'transferOut',
    amount: -320,
  },
  {
    _id: '9XHm4WmPDDpjQEevW',
    createdAt: '2022-04-06T20:13:02.718Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4266,
    },
    transactionType: 'transferOut',
    amount: -127.5,
  },
  {
    _id: 'xSjPsjSAe65sbKoza',
    createdAt: '2022-04-07T04:12:15.563Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: 'XpQC9WPLvhaWr4nF6',
        name: 'BALADI CHEESE SUPER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbc948a52-0dc0-4b17-bd6d-1e9396a1356b_200x200.jpg?alt=media&token=6a5371df-04e0-4dca-b5ea-527fe3c69069',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: '64mexP2aTNTNBX6ry',
    createdAt: '2022-04-07T04:16:01.497Z',
    transactionType: 'transferIn',
    amount: 538,
    storeItem: {
      type: {
        _id: '6QpNSqHBX753qb9Cr',
        name: 'PANEER CHEESE VACUUMED 250 GM PACK',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb9c7729a-fca4-4b8b-92b7-c179d2024b94_200x200.jpg?alt=media&token=e9a531a9-09df-43ab-ab93-869375de0674',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'vXdae97BAdjDWbdyk',
    createdAt: '2022-04-07T04:32:33.986Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 65.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xy366AFcz72mCZ2hg',
    createdAt: '2022-04-07T04:32:34.120Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1465,
    },
    transactionType: 'transferOut',
    amount: -627,
  },
  {
    _id: '74HXouq8NCPsQBNJX',
    createdAt: '2022-04-07T04:32:34.236Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2019,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'X6bqEYdAPrF5k7GDs',
    createdAt: '2022-04-07T04:32:34.354Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2484.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Fk3nfjTob9EdnLjF5',
    createdAt: '2022-04-07T04:32:34.469Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 126,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'M35pqoRH3QDoqdWEN',
    createdAt: '2022-04-07T04:32:34.591Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16454.4,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '9qeMh4ZwPBYR4M7RE',
    createdAt: '2022-04-07T04:32:34.702Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EZShnPNchkH5Kz4iq',
    createdAt: '2022-04-07T04:32:34.811Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 28.550000000000004,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'fPrgTYHdp98ERmmXz',
    createdAt: '2022-04-07T04:32:34.919Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 3.0399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Lq2cmZiq3mWgyfYHF',
    createdAt: '2022-04-07T04:32:35.033Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 440.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'TQtyGnRR2RrjN7WXw',
    createdAt: '2022-04-07T04:32:35.145Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8185.704999999984,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '5mqtBiPbExf3touhq',
    createdAt: '2022-04-07T04:32:35.255Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5151,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'HAex3CYJJYu9rWDRE',
    createdAt: '2022-04-07T04:35:00.010Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 503.5,
    },
    transactionType: 'transferOut',
    amount: -14.5,
  },
  {
    _id: 'dxKw4B22mnffo6jrd',
    createdAt: '2022-04-07T04:35:00.178Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 177.24999999999994,
    },
    transactionType: 'transferOut',
    amount: -5.25,
  },
  {
    _id: 'NEWQJuWMn4Y39SfcM',
    createdAt: '2022-04-07T04:35:00.294Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 141.5,
    },
    transactionType: 'transferOut',
    amount: -3.5,
  },
  {
    _id: 'dCkNQtkQubvoG4a3m',
    createdAt: '2022-04-07T04:35:00.406Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4138.5,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'NkQZXgdGEycAWhKKE',
    createdAt: '2022-04-07T05:17:46.347Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 9226,
    },
  },
  {
    _id: 'D6K2Ke7kXjNXvCew6',
    createdAt: '2022-04-07T05:18:39.319Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 870.02,
    },
  },
  {
    _id: 'Le2N2tWebwRup6MQt',
    createdAt: '2022-04-07T05:22:44.422Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5094,
    },
  },
  {
    _id: '6C2yNbRmsQoy6fZhZ',
    createdAt: '2022-04-07T05:30:43.258Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 575,
    },
  },
  {
    _id: 'NJwtYAGRahNq769pY',
    createdAt: '2022-04-07T05:41:56.303Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe357e5dc-766d-4518-9868-05f7651d9abb_200x200.jpg?alt=media&token=2dd741df-52c9-4d15-9d03-5e2aa74ce4dd',
        },
      },
      quantity: 87,
    },
  },
  {
    _id: '4zjfGMvb98NEFvNLL',
    createdAt: '2022-04-07T05:43:58.083Z',
    transactionType: 'transferIn',
    amount: 2.5,
    storeItem: {
      type: {
        _id: 'iASvRoJNWiSfQRNDL',
        name: 'BLEACH CONCENTRALE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2be3571f-f475-4e7a-ade3-41b15edd8a5f_200x200.jpg?alt=media&token=e887dc4b-c094-436e-8c63-4e1d57db4d35',
        },
      },
      quantity: 2,
    },
  },
  {
    _id: 'jaQSx45D6vHsEaBkT',
    createdAt: '2022-04-07T05:44:56.545Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F836955bb-4c46-4d95-aa0d-4cd057c4d7e9_200x200.jpg?alt=media&token=cc75a865-f8ae-400a-a2c5-2249a2829536',
        },
      },
      quantity: 6,
    },
  },
  {
    _id: 'siHe9WYQmxCKB87fF',
    createdAt: '2022-04-07T07:44:09.275Z',
    transactionType: 'in',
    amount: 2000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 65.98000000000684,
    },
  },
  {
    _id: 'Gssxjqi7WTJsSyLya',
    createdAt: '2022-04-07T07:48:08.752Z',
    transactionType: 'in',
    amount: 5500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2019,
    },
  },
  {
    _id: 'XoQHkJg7b3ygzxZka',
    createdAt: '2022-04-07T07:49:31.983Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'o9fpTXgKngdmfE25R',
    createdAt: '2022-04-07T07:50:24.333Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'E2cgt3tr2R98Cj8Bo',
    createdAt: '2022-04-07T07:51:05.579Z',
    transactionType: 'in',
    amount: 4050,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7519,
    },
  },
  {
    _id: 'k5n3FYfTumNZdpu8c',
    createdAt: '2022-04-07T08:08:10.158Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1885.9800000000068,
    },
  },
  {
    _id: 'F2QGnXTEEkuzvfgiA',
    createdAt: '2022-04-07T08:23:45.211Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 489,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: '9htuYjz7pKvXJqfEp',
    createdAt: '2022-04-07T08:23:45.376Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21626.4,
    },
    transactionType: 'transferOut',
    amount: -0.1,
  },
  {
    _id: 'T3QcuWY5xjwBXg8tq',
    createdAt: '2022-04-07T08:23:45.497Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 402.9,
    },
    transactionType: 'transferOut',
    amount: -0.1,
  },
  {
    _id: 'RDYb653ZCzeonn58w',
    createdAt: '2022-04-07T08:23:45.620Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 216,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'xHXeZgLXqbcRX9cgB',
    createdAt: '2022-04-07T08:34:46.525Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BJ2rS39ye9qFcfjsC',
    createdAt: '2022-04-07T08:34:46.659Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2285.980000000007,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'tcLNJen2QpHLusMzH',
    createdAt: '2022-04-07T08:34:46.788Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 838,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RcKbiXvFY6QeNnWig',
    createdAt: '2022-04-07T08:34:46.910Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2484.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CwBmrbwS5CnNNoaGY',
    createdAt: '2022-04-07T08:34:47.033Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 27.550000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.01,
  },
  {
    _id: 'xYwJubZBW3WSpPfcL',
    createdAt: '2022-04-07T08:34:47.149Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8185.204999999984,
    },
    transactionType: 'transferOut',
    amount: -0.05,
  },
  {
    _id: 'hbwk8g5SHprZdwgNv',
    createdAt: '2022-04-07T08:34:47.259Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3976,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'u8H837ey8bbiemcyz',
    createdAt: '2022-04-07T09:35:33.578Z',
    transactionType: 'transferIn',
    amount: 2400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2285.980000000007,
    },
  },
  {
    _id: 'rYfEtaSuSPGmesWbd',
    createdAt: '2022-04-07T11:18:33.940Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'FcM6CxF4JT48cFmzG',
    createdAt: '2022-04-07T11:18:34.074Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4685.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '3PXbCzRcDn8pxFrfj',
    createdAt: '2022-04-07T11:18:34.185Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 625,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'imaXxuGANPYH5tQgN',
    createdAt: '2022-04-07T11:18:34.292Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 434.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'J9sX5eL29cSrpLgaL',
    createdAt: '2022-04-07T11:18:34.405Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2119,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'AzF7NHMcYzhBy5fwD',
    createdAt: '2022-04-07T11:18:34.514Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1159,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'NEyvDgbDRFW66r6dn',
    createdAt: '2022-04-07T11:59:13.185Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4285.980000000007,
    },
    transactionType: 'transferOut',
    amount: -340,
  },
  {
    _id: 'CMcy7FkZGjjRcJmgM',
    createdAt: '2022-04-07T11:59:13.319Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11369,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RXaCANvYToTKR6MwD',
    createdAt: '2022-04-07T11:59:13.429Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 838,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WXiwzxpDQZxRtFsnw',
    createdAt: '2022-04-07T11:59:13.536Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2484.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TF8TLaj3hkDBRWAor',
    createdAt: '2022-04-07T11:59:13.657Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8185.204999999984,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'srMJLCTb6urTEjeXw',
    createdAt: '2022-04-07T11:59:13.768Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16446.4,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'TM3E3kbyX5zJy5gxP',
    createdAt: '2022-04-07T11:59:13.878Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 2.5399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'xmdSa4Px9mxidQMRf',
    createdAt: '2022-04-07T11:59:13.988Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 27.550000000000004,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'qkNw3H3WB6QdMK3k6',
    createdAt: '2022-04-07T11:59:14.097Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3976,
    },
    transactionType: 'transferOut',
    amount: -23,
  },
  {
    _id: 'XdRiAj2cSeFiGtHcb',
    createdAt: '2022-04-07T12:47:57.518Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11369,
    },
    transactionType: 'transferOut',
    amount: -1600,
  },
  {
    _id: 'XZAn2ZjsehNnEaHKE',
    createdAt: '2022-04-07T12:47:57.651Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 95.67500000000001,
    },
    transactionType: 'transferOut',
    amount: -1.6,
  },
  {
    _id: 'wFw4ogBETZ2HTZbp2',
    createdAt: '2022-04-07T12:47:57.759Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160040.3,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: 'NmAqsebJuxtiRGwiL',
    createdAt: '2022-04-07T12:47:57.866Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26900.1,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'Re5JCickPDcYhrckG',
    createdAt: '2022-04-07T12:47:57.988Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1150,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'vsa5XhRWECuYS7TNC',
    createdAt: '2022-04-07T12:59:42.770Z',
    transactionType: 'transferIn',
    amount: 2300,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 838,
    },
  },
  {
    _id: 'hnzwi9yDNbq3HHx6v',
    createdAt: '2022-04-07T13:01:21.479Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3945.980000000007,
    },
  },
  {
    _id: 'zqTzXhpDkviGkMJ88',
    createdAt: '2022-04-07T13:02:19.924Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4xA725hASmobtoMEu',
    createdAt: '2022-04-07T13:02:20.062Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3945.980000000007,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'fZ34DZvJED7KQRRiA',
    createdAt: '2022-04-07T13:02:20.290Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3138,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'McwyWNdEPejzDGHv9',
    createdAt: '2022-04-07T13:02:21.046Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2484.2000000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cLgCfG7iEqtAAjm4R',
    createdAt: '2022-04-07T13:02:21.181Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 126,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dTP6MJ3oHcnKySafK',
    createdAt: '2022-04-07T13:02:21.289Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 429.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'fNubsTGdiqYLp8Qhu',
    createdAt: '2022-04-07T13:02:21.394Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 27.350000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'nDCsXcpdDf8nNDjih',
    createdAt: '2022-04-07T13:02:21.506Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oos7gezZYhtnsiqyn',
    createdAt: '2022-04-07T13:02:21.637Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16440.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'xiKPGu8zWmdRvJvZ7',
    createdAt: '2022-04-07T13:02:21.774Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3953,
    },
    transactionType: 'transferOut',
    amount: -190,
  },
  {
    _id: 'Nfs4jj6xHzKPnu3Lk',
    createdAt: '2022-04-07T13:35:21.435Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3138,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6j3KzCNyB7wcZkyWc',
    createdAt: '2022-04-07T13:35:21.572Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3505.980000000007,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'kMFM6moaL8KCg6Qjo',
    createdAt: '2022-04-07T13:35:21.691Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6eRpZdPm2hrNscSW8',
    createdAt: '2022-04-07T13:35:21.813Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2484.2000000000007,
    },
    transactionType: 'transferOut',
    amount: -3.6,
  },
  {
    _id: 'pJr3KanB67zz4Gegp',
    createdAt: '2022-04-07T13:35:21.928Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26810.1,
    },
    transactionType: 'transferOut',
    amount: -6.5,
  },
  {
    _id: 'c6RwWWeiDwFAAr6TD',
    createdAt: '2022-04-07T13:35:22.046Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159290.3,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'FkMC7tDgzp8toEeCA',
    createdAt: '2022-04-07T13:35:22.160Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16220.599999999999,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'DKMZe9wRja4XqLkQB',
    createdAt: '2022-04-07T13:35:22.275Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1524,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'hNTMco6huKyEJJNnv',
    createdAt: '2022-04-07T13:51:11.593Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3385.980000000007,
    },
    transactionType: 'transferOut',
    amount: -240,
  },
  {
    _id: 'AazbfWnyFzoBEnjwW',
    createdAt: '2022-04-07T13:51:11.724Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3138,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2gve2yRKYrCnGByrq',
    createdAt: '2022-04-07T13:51:11.859Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uQfsLfXF4chjmM9Bz',
    createdAt: '2022-04-07T13:51:11.987Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2480.600000000001,
    },
    transactionType: 'transferOut',
    amount: -7.2,
  },
  {
    _id: 'giwYum87LEFSNi4sC',
    createdAt: '2022-04-07T13:51:12.099Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26803.6,
    },
    transactionType: 'transferOut',
    amount: -12.5,
  },
  {
    _id: 'dAKaD45FRjPoArKpn',
    createdAt: '2022-04-07T13:51:12.210Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159230.3,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'WLE5nCyhkEsJWuQ2f',
    createdAt: '2022-04-07T13:51:12.320Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16215.599999999999,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DvWDToe2J7hwHjYth',
    createdAt: '2022-04-07T13:51:12.433Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1420,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'qYEYv9bA7xTeTF7gu',
    createdAt: '2022-04-07T13:51:12.545Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 112.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'dvkaPs2ZHXMeNzfAh',
    createdAt: '2022-04-07T13:51:12.685Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4131.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'rKJ2iNoS73WEe4oJr',
    createdAt: '2022-04-07T13:52:32.041Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3145.980000000007,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'HC3KjWqbEGjo6vMK6',
    createdAt: '2022-04-07T13:52:32.177Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3138,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ebNBx2PPW5m7ZYq5o',
    createdAt: '2022-04-07T13:52:32.287Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EJCzpDXY2HFaZCSPF',
    createdAt: '2022-04-07T13:52:32.396Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2473.400000000001,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'k3ghrzLKq6JdQ9c4h',
    createdAt: '2022-04-07T13:52:32.511Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 126,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'PSNwMtqhcGTZL6hTo',
    createdAt: '2022-04-07T13:52:32.629Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16428.4,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'gqpy8oM7fdNLHuuAc',
    createdAt: '2022-04-07T13:52:32.752Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WQdaZ8DvQQTccJ3dY',
    createdAt: '2022-04-07T13:52:32.871Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 2.0399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Jk5MCK8HcL5EKsPKc',
    createdAt: '2022-04-07T13:52:32.983Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 26.350000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'r26b2TKs4P6CwNkX4',
    createdAt: '2022-04-07T13:52:33.105Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gdcSwcsENA9izJ7m7',
    createdAt: '2022-04-07T13:52:33.226Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8184.604999999983,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'QQZzux94MXmFbmjmf',
    createdAt: '2022-04-07T13:52:33.349Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5134,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'z5rndCrLnmqvEdS8b',
    createdAt: '2022-04-08T02:09:09.766Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2466.400000000001,
    },
  },
  {
    _id: '7YAiaxdL7kDETobM3',
    createdAt: '2022-04-08T02:09:51.296Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8184.004999999983,
    },
  },
  {
    _id: 'errmexDALZ6dkqznS',
    createdAt: '2022-04-08T02:10:47.411Z',
    transactionType: 'transferIn',
    amount: 210,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5057,
    },
  },
  {
    _id: 'rPpgk6juuRYbk5KGF',
    createdAt: '2022-04-08T02:11:06.215Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4114.5,
    },
  },
  {
    _id: 'GoSSkAZghmcHpZznN',
    createdAt: '2022-04-08T02:11:25.419Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3763,
    },
  },
  {
    _id: 'CxkfpyrQBg8FkFTqa',
    createdAt: '2022-04-08T02:14:47.631Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1855,
    },
  },
  {
    _id: 'Ad7SP9ytwzni356PR',
    createdAt: '2022-04-08T02:15:09.800Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 403.5,
    },
  },
  {
    _id: 'TkMQwPKmeK2tYxyTh',
    createdAt: '2022-04-08T03:08:03.050Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4652,
    },
  },
  {
    _id: 'JnDPMKmcyjs3xPJxh',
    createdAt: '2022-04-08T03:08:23.597Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 1059,
    },
  },
  {
    _id: 'zizFrdi3sZvG5MWME',
    createdAt: '2022-04-08T03:42:44.050Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'yL9hmPWNkQWXSnJSA',
    createdAt: '2022-04-08T03:42:44.156Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 4.000000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'gWFXNrTtMscHnkXG4',
    createdAt: '2022-04-08T03:42:44.395Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 129,
    },
    transactionType: 'in',
    amount: 200,
  },
  {
    _id: 'c4Thasre3ZzXev5ZF',
    createdAt: '2022-04-08T03:44:00.482Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 329,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'zzFXbTsJdPRxCAZRy',
    createdAt: '2022-04-08T03:44:00.615Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10238,
    },
    transactionType: 'transferOut',
    amount: -251,
  },
  {
    _id: '6EQvYbBH5npNMNSwx',
    createdAt: '2022-04-08T03:44:01.540Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1066,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'RwEM5uc7EwKLKvYYA',
    createdAt: '2022-04-08T03:57:26.458Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TBtz5jW2Wg4J9dBMm',
    createdAt: '2022-04-08T03:57:26.597Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3138,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'nuovkFdTGWMEA3Q2Q',
    createdAt: '2022-04-08T03:57:26.711Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2315.980000000007,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'gPgA3kYPDPZv7KDQK',
    createdAt: '2022-04-08T03:57:26.825Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2641.400000000001,
    },
    transactionType: 'transferOut',
    amount: -6.3,
  },
  {
    _id: 'LBuckxR4Y6HGAhYC5',
    createdAt: '2022-04-08T03:57:26.935Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1415,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'hiZbfszEdj9Cji9KW',
    createdAt: '2022-04-08T03:57:27.048Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16205.599999999999,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'xjHBYHb6Z58Ps8byQ',
    createdAt: '2022-04-08T03:57:27.159Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159110.3,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'uP4fK7As9ADCJRaFR',
    createdAt: '2022-04-08T03:57:27.267Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26791.1,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '75xRqBdXCFD7afwQz',
    createdAt: '2022-04-08T03:57:27.377Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4314.5,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'pXEvNTyq6QBrSFf2Q',
    createdAt: '2022-04-08T04:02:12.739Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 12990,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'Yzq5XwWRXABzYiqFc',
    createdAt: '2022-04-08T04:02:12.847Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8559.004999999983,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'CsW25mzjXAfFzYB89',
    createdAt: '2022-04-08T04:02:12.955Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 159010.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'R2QJmnx3pADzCFueG',
    createdAt: '2022-04-08T04:02:13.067Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16416.4,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '6hoyLZPYFci2kC2qc',
    createdAt: '2022-04-08T04:02:13.303Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 62.5,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'F4qxW5botjMuSDt3A',
    createdAt: '2022-04-08T04:04:00.622Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oNecv8dv4FE5TxaB5',
    createdAt: '2022-04-08T04:04:00.767Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2105.980000000007,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'qWpeQKwCETqZWHTZP',
    createdAt: '2022-04-08T04:04:00.886Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3138,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'KnrxEYWfP5tx3NBMX',
    createdAt: '2022-04-08T04:04:01.002Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2635.100000000001,
    },
    transactionType: 'transferOut',
    amount: -57,
  },
  {
    _id: 'sJ9f83k3JJboxGzH2',
    createdAt: '2022-04-08T04:04:01.127Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26779.1,
    },
    transactionType: 'transferOut',
    amount: -114,
  },
  {
    _id: 'wRWuKP946cFDHH47A',
    createdAt: '2022-04-08T04:04:01.240Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 156410.3,
    },
    transactionType: 'transferOut',
    amount: -950,
  },
  {
    _id: 'q2x29aeJC7v5tfqXo',
    createdAt: '2022-04-08T04:04:01.360Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1411,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'WhHfXJJD7PFKEAXQE',
    createdAt: '2022-04-08T04:04:01.478Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16197.599999999999,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'JSj5HTgMkaBjriRcQ',
    createdAt: '2022-04-08T04:04:01.595Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16386.4,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'eG2gmcQym99AtmYuP',
    createdAt: '2022-04-08T04:04:01.711Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 862.5,
    },
    transactionType: 'transferOut',
    amount: -276,
  },
  {
    _id: 'dyG5x3NCd4Lu6iHjH',
    createdAt: '2022-04-08T04:04:01.837Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4300.5,
    },
    transactionType: 'transferOut',
    amount: -138,
  },
  {
    _id: '5geHXZNdq6hzz6uMu',
    createdAt: '2022-04-08T04:06:39.659Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1105.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -535,
  },
  {
    _id: 'fw73qEnfDAcFGDzzt',
    createdAt: '2022-04-08T04:06:39.795Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2238,
    },
    transactionType: 'transferOut',
    amount: -265,
  },
  {
    _id: '34FMb9tDh7kaCHWxG',
    createdAt: '2022-04-08T04:06:39.917Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'iDvrfCB2tqJaTRQ9Z',
    createdAt: '2022-04-08T04:06:40.030Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2578.100000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KLqdzvMEkiPPcJy3G',
    createdAt: '2022-04-08T04:06:40.141Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26665.1,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yx7ikFCDwfkH5ZgFA',
    createdAt: '2022-04-08T04:06:40.250Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8519.004999999983,
    },
    transactionType: 'transferOut',
    amount: -1.4,
  },
  {
    _id: 'LPtCMxqg6KcBaikRg',
    createdAt: '2022-04-08T04:06:40.361Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3963,
    },
    transactionType: 'transferOut',
    amount: -47,
  },
  {
    _id: 'bigwDBQAtr4G5Q4AT',
    createdAt: '2022-04-08T04:58:24.506Z',
    transactionType: 'transferIn',
    amount: 2000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 570.9800000000068,
    },
  },
  {
    _id: 'RJE8RG4mArbGNFQh6',
    createdAt: '2022-04-08T06:17:15.786Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'bSQcoeg2tiFwWiLka',
    createdAt: '2022-04-08T06:17:15.919Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 960.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '4fEpsgG9RatAzsBcr',
    createdAt: '2022-04-08T06:17:16.030Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 136.8500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'ZCBFhsTAYh6CRNdtS',
    createdAt: '2022-04-08T06:17:16.144Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2578.100000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ga3aTwDSAJxYZw8Cy',
    createdAt: '2022-04-08T06:17:16.411Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: '7iNqMd7PwQ2AzatTe',
    createdAt: '2022-04-08T06:29:43.372Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2570.980000000007,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '4769Rukqd9NffxKXP',
    createdAt: '2022-04-08T06:29:43.504Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sxygytizBmcb84qLq',
    createdAt: '2022-04-08T06:29:43.610Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2575.100000000001,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'X8zRFPT8YNzBb2jPa',
    createdAt: '2022-04-08T06:29:43.717Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 122,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'TJmBJiRoKbG8jjyBk',
    createdAt: '2022-04-08T06:29:43.824Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 10226,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: '75MjnQ9Kw7APMpjBa',
    createdAt: '2022-04-08T06:29:44.043Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 25.350000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'AadQo9gxWykXtCsby',
    createdAt: '2022-04-08T06:29:44.149Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 19476,
    },
    transactionType: 'transferOut',
    amount: -1100,
  },
  {
    _id: 'F3m978uRmhbtcGhYL',
    createdAt: '2022-04-08T06:29:44.258Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1508,
    },
    transactionType: 'transferOut',
    amount: -177,
  },
  {
    _id: '44Jfi65zX4jLH43yj',
    createdAt: '2022-04-08T06:38:40.874Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2573.100000000001,
    },
  },
  {
    _id: 'BzzPwL3XHDCBaMAoN',
    createdAt: '2022-04-08T06:39:10.192Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2748.100000000001,
    },
  },
  {
    _id: 'ARjGsBeeFSeg64kTT',
    createdAt: '2022-04-08T06:40:03.072Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 421.46000000000026,
    },
  },
  {
    _id: 'ahERgiSWni5Em7gpv',
    createdAt: '2022-04-08T06:40:37.766Z',
    transactionType: 'transferIn',
    amount: 125,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8517.604999999983,
    },
  },
  {
    _id: 'shDYWEcL2g8uxsuxb',
    createdAt: '2022-04-08T06:40:54.751Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 3916,
    },
  },
  {
    _id: 'EzdmavwyLHkJSTYpk',
    createdAt: '2022-04-08T06:41:48.665Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1129,
    },
  },
  {
    _id: 'XhgNZG2SY56teJfHS',
    createdAt: '2022-04-08T06:42:03.966Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1045,
    },
  },
  {
    _id: 'GYM6T2jDafAgMECnn',
    createdAt: '2022-04-08T06:43:15.081Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 943.02,
    },
  },
  {
    _id: 'BQao8NxJyZfKkLwPk',
    createdAt: '2022-04-08T06:43:32.415Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 508.5,
    },
  },
  {
    _id: 'ZDGfPtMj6auKSbPoj',
    createdAt: '2022-04-08T06:44:13.526Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 216,
    },
  },
  {
    _id: 'SvPFfPq9hcerY4SJz',
    createdAt: '2022-04-08T07:13:57.407Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'LNSZHGMEsjAqyLcT6',
    createdAt: '2022-04-08T07:13:57.538Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9987,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: '9Sn2N6dxwjai7oMF4',
    createdAt: '2022-04-08T07:13:57.659Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4772,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'vFMXv2yhzosxdtbK8',
    createdAt: '2022-04-08T07:13:57.787Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1120,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'F7vQsnJucc48LdB42',
    createdAt: '2022-04-08T07:16:18.973Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 370,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'ZTBC976Gcwkvf83Dd',
    createdAt: '2022-04-08T07:16:19.082Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9922,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'MYE5kQx8e7ETdmFHB',
    createdAt: '2022-04-08T07:16:19.196Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1114,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '39udcYCnepwDTKrig',
    createdAt: '2022-04-08T07:18:13.632Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 322,
    },
    transactionType: 'transferOut',
    amount: -122,
  },
  {
    _id: 'MomNJzuNtcjD84zGB',
    createdAt: '2022-04-08T07:18:13.742Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 3.4000000000000017,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'GaaCX7GRfibchpre4',
    createdAt: '2022-04-08T07:18:13.993Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 79,
    },
    transactionType: 'in',
    amount: 120,
  },
  {
    _id: 'AuSD9uCANpQJG8gyq',
    createdAt: '2022-04-08T07:18:43.467Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 199,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'HzqvfTznHmkQseCAE',
    createdAt: '2022-04-08T07:18:43.602Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9876,
    },
    transactionType: 'transferOut',
    amount: -101,
  },
  {
    _id: '54xKwc9eRi3cL3rGc',
    createdAt: '2022-04-08T07:18:43.709Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1110,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '5nEDHgvgkQsq6Peed',
    createdAt: '2022-04-08T08:00:01.106Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5267,
    },
  },
  {
    _id: 'DjScdHTDXhvmFhgWB',
    createdAt: '2022-04-08T08:13:56.872Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 446.46000000000026,
    },
  },
  {
    _id: 'cRxECDWsJnyucmdRf',
    createdAt: '2022-04-08T09:51:30.203Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2370.980000000007,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'oR3PajLmjtxGBFJcJ',
    createdAt: '2022-04-08T09:51:30.340Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1973,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Z62qt3JZxxyruH8rG',
    createdAt: '2022-04-08T09:51:30.451Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FjM9LFS5etYDn3waj',
    createdAt: '2022-04-08T09:51:30.561Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2573.100000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eDj6LJ7LqcALqmY3h',
    createdAt: '2022-04-08T09:51:30.675Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 120.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qsiPNPyamompqrnbu',
    createdAt: '2022-04-08T09:51:30.784Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16356.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'SaN9mbrJBnQg6GtQk',
    createdAt: '2022-04-08T09:51:30.900Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RYvzBw3smFSr8PGSa',
    createdAt: '2022-04-08T09:51:31.012Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 24.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Q3X982K7ucQJaKERR',
    createdAt: '2022-04-08T09:51:31.124Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 1.5399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'cZCPXn6cCqYwKydK2',
    createdAt: '2022-04-08T09:51:31.232Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 496.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '3Zbd2CZx5bH6egW8h',
    createdAt: '2022-04-08T09:51:31.339Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8642.604999999983,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: '65yka45MEPSiwdNyR',
    createdAt: '2022-04-08T09:51:31.451Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5387,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'qD6ZxMs4vGpecZsgQ',
    createdAt: '2022-04-08T09:54:34.252Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'iZ2nwP2iMnbDjCp4J',
    createdAt: '2022-04-08T09:54:34.387Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1570.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '7XSkNkS7MKzLd3ZLE',
    createdAt: '2022-04-08T09:54:34.510Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2573.100000000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'iyuvBfid9Pd3MHM58',
    createdAt: '2022-04-08T09:54:34.624Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'oATgtSFYTvYadSKvq',
    createdAt: '2022-04-08T09:54:34.737Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 155460.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'mBYRK3phqFgGpfGXh',
    createdAt: '2022-04-08T09:54:34.853Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8636.604999999983,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'w2FBAutEEwDnuBM6d',
    createdAt: '2022-04-08T09:54:34.963Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 703.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'teSmfZoncBKbQS86A',
    createdAt: '2022-04-08T09:54:35.076Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2155,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'ayMwRdGconqZ7cBiB',
    createdAt: '2022-04-08T09:57:28.417Z',
    transactionType: 'in',
    amount: 20,
    storeItem: {
      type: {
        _id: 'oTP5sR2gNMqNwhWuT',
        name: 'LABNA SUPER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F47c3904c-268b-4c57-9522-1df27334fd21_200x200.jpg?alt=media&token=6441e292-3a15-4f08-bfb1-05766fd88081',
        },
      },
      quantity: 2,
    },
  },
  {
    _id: 'qMxxKPrbFe7bB88c6',
    createdAt: '2022-04-08T09:58:07.667Z',
    storeItem: {
      type: {
        _id: 'oTP5sR2gNMqNwhWuT',
        name: 'LABNA SUPER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F47c3904c-268b-4c57-9522-1df27334fd21_200x200.jpg?alt=media&token=6441e292-3a15-4f08-bfb1-05766fd88081',
        },
      },
      quantity: 22,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'yq4gxrCcMP9S3HFy4',
    createdAt: '2022-04-08T09:58:07.826Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1516,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'b86KJCsxbTmoA6Fh6',
    createdAt: '2022-04-08T09:58:07.936Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 276,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '3ZW55WYjcM7JK4RPP',
    createdAt: '2022-04-08T10:56:23.977Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9719,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'AjG2WggGbgepqtsaN',
    createdAt: '2022-04-08T10:56:24.114Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2523.100000000001,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'hXKD7bBwaHJg9Pe9J',
    createdAt: '2022-04-08T10:56:24.230Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26665.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'GzLqyBy2kzdPeiBNu',
    createdAt: '2022-04-08T10:56:24.338Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16117.599999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '2CwouCNpbi8zaj3jg',
    createdAt: '2022-04-08T10:56:24.450Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 586.5,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'yX95dXkYpBeY5STSe',
    createdAt: '2022-04-08T10:56:24.567Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4162.5,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'uo58mSPCqX4Cg2Em3',
    createdAt: '2022-04-08T11:39:15.354Z',
    transactionType: 'transferIn',
    amount: 50000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 153160.3,
    },
  },
  {
    _id: 'mzFjCcwqrrTFmreRG',
    createdAt: '2022-04-08T12:04:37.793Z',
    transactionType: 'transferIn',
    amount: 352,
    storeItem: {
      type: {
        _id: '4rrmm3JfMLBnXwERi',
        name: 'yoghurt ',
        unit: 'kg',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe381ef71-e198-428b-a614-0f360881c3bc_200x200.jpg?alt=media&token=ee3d6db4-f4d9-4c26-bc3d-ed74a433cad1',
        },
      },
      quantity: 149.8,
    },
  },
  {
    _id: 'sBWg7xfNQggXaZntS',
    createdAt: '2022-04-08T12:07:37.531Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1973,
    },
  },
  {
    _id: '5Bgza2LhZddvaCf2k',
    createdAt: '2022-04-08T12:08:10.968Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1370.9800000000068,
    },
  },
  {
    _id: 'wB6C94kzdEiaCXayG',
    createdAt: '2022-04-08T14:26:29.880Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8879,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'nFLQLBDeaFb8e6suv',
    createdAt: '2022-04-08T14:26:30.019Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2370.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'qK4AqakCX9EP5qgYv',
    createdAt: '2022-04-08T14:26:30.130Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 575,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'owEKGijP8fnmHaTBi',
    createdAt: '2022-04-08T14:26:30.241Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 488.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'SqAGtyPyYTJoFfsEj',
    createdAt: '2022-04-08T14:26:30.352Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1937,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: '7dbnXJCbgZqiG4i6t',
    createdAt: '2022-04-08T14:26:30.477Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1189,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'oq2jrvkTMwfqztHbM',
    createdAt: '2022-04-08T14:38:13.306Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'wNFBFuqDAuzuFJhH2',
    createdAt: '2022-04-08T14:38:13.408Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 3.0000000000000018,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '6nHmvb6Kuzoodmtb9',
    createdAt: '2022-04-08T14:38:13.638Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 99,
    },
    transactionType: 'in',
    amount: 400,
  },
  {
    _id: 'nYhQecq8B2KK8ibeE',
    createdAt: '2022-04-08T14:39:15.723Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 499,
    },
    transactionType: 'transferOut',
    amount: -183,
  },
  {
    _id: 'PcHFvvmYZZrc6NNGp',
    createdAt: '2022-04-08T14:39:15.850Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9775,
    },
    transactionType: 'transferOut',
    amount: -177,
  },
  {
    _id: 'TQLDbzGtSB2pgGf4W',
    createdAt: '2022-04-08T14:39:15.955Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1101,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '4fWHzMHQsNkWN6mHk',
    createdAt: '2022-04-08T14:41:55.433Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 316,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'y9HSFy9ErqPJuhGYh',
    createdAt: '2022-04-08T14:41:55.568Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4770,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'i87HHYJBfmGCewRiX',
    createdAt: '2022-04-08T14:41:55.678Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1083,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'BX5RTvPnsJRBHdcfk',
    createdAt: '2022-04-09T03:20:26.782Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 525,
    },
  },
  {
    _id: 'GJgd3ZMTedcrzvHX9',
    createdAt: '2022-04-09T03:20:57.066Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1755,
    },
  },
  {
    _id: 'WzAHkigZkPqD6JJoQ',
    createdAt: '2022-04-09T03:22:18.102Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1180,
    },
  },
  {
    _id: 'XiopoW75vQLzqXZX9',
    createdAt: '2022-04-09T03:22:41.455Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 483.46000000000026,
    },
  },
  {
    _id: 'soKPTYu9r3TYfGbep',
    createdAt: '2022-04-09T03:41:42.520Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 548.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'ctTrfE23jv5xBFQfL',
    createdAt: '2022-04-09T03:41:42.657Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1003.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'fNZSn6ypZubkNatyB',
    createdAt: '2022-04-09T03:41:42.766Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 136.6000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'br5aPSJBWk64DgemT',
    createdAt: '2022-04-09T03:41:42.875Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2499.100000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'upiGWCJ9k2bXzCWs2',
    createdAt: '2022-04-09T03:41:43.128Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'XQnfzRhEfKvFHRgzL',
    createdAt: '2022-04-09T03:53:36.288Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NWvdQNXgP6QWD2d92',
    createdAt: '2022-04-09T03:53:36.456Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1970.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -1200,
  },
  {
    _id: 'NNhg6ammp3udeMh68',
    createdAt: '2022-04-09T03:53:36.595Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2973,
    },
    transactionType: 'transferOut',
    amount: -900,
  },
  {
    _id: 'vvF79C473jMwsAs4Q',
    createdAt: '2022-04-09T03:53:36.722Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2496.100000000001,
    },
    transactionType: 'transferOut',
    amount: -72,
  },
  {
    _id: 'YeirKrhQ4oN8aQHmz',
    createdAt: '2022-04-09T03:53:36.836Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'GQ6hR9mw6zureNbQt',
    createdAt: '2022-04-09T03:53:36.974Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26625.1,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'fj8jsSHfyekXrDrmF',
    createdAt: '2022-04-09T03:53:37.089Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 203160.3,
    },
    transactionType: 'transferOut',
    amount: -1300,
  },
  {
    _id: '3Lc2Qf5xumzHzTvGm',
    createdAt: '2022-04-09T03:53:37.201Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1371,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'fmunRTuG9P6J7rWog',
    createdAt: '2022-04-09T03:53:37.324Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16092.599999999999,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'j8tyR6zHroTHHYfRu',
    createdAt: '2022-04-09T03:53:37.449Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16344.400000000001,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'cnjTMY8bWMg5H535r',
    createdAt: '2022-04-09T03:53:37.564Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 466.5,
    },
    transactionType: 'transferOut',
    amount: -466,
  },
  {
    _id: '2SSaDXt8DY5sMsPva',
    createdAt: '2022-04-09T03:53:37.674Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4109.5,
    },
    transactionType: 'transferOut',
    amount: -174,
  },
  {
    _id: '5AGiMGFbs4KxLsbsi',
    createdAt: '2022-04-09T03:56:12.587Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 12230,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'QBAoXaFZEMw9k7Ttt',
    createdAt: '2022-04-09T03:56:12.695Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8633.604999999983,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'vXYSmBdvjkKirFSzt',
    createdAt: '2022-04-09T03:56:12.802Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 201860.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'vEgRANf5gpEZThKnF',
    createdAt: '2022-04-09T03:56:12.907Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16314.400000000001,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'pPXKkNanR6iGmrLya',
    createdAt: '2022-04-09T03:56:13.150Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 0.5,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'HmR58N82CWhhDq2Pc',
    createdAt: '2022-04-09T03:57:18.860Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8679,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '2sGPTEf5Cu5DrsbSu',
    createdAt: '2022-04-09T03:57:18.989Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 770.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9diDEGZFv35fiusxT',
    createdAt: '2022-04-09T03:57:19.128Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2073,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jMqRFKawzztKoAdCX',
    createdAt: '2022-04-09T03:57:19.240Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2424.100000000001,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'xNupb52yEqwnoQdRB',
    createdAt: '2022-04-09T03:57:19.353Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26475.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'xDWeseX6Ln6AnAuAn',
    createdAt: '2022-04-09T03:57:19.459Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16284.400000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '5346WvZXodPAfZQGY',
    createdAt: '2022-04-09T03:57:19.571Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 16002.599999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'axoFrk6XM2fSBn22D',
    createdAt: '2022-04-09T03:57:19.683Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800.5,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'Rtdr44WjP5Ae9Xjti',
    createdAt: '2022-04-09T03:57:19.793Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 681.5,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'HqpQxWzQwDEuLQxrv',
    createdAt: '2022-04-09T03:57:19.898Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2133,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'Mp7Ez5npGBs4h8sGe',
    createdAt: '2022-04-09T03:57:20.010Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 275,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'cMcgg2wTogrsBtfzn',
    createdAt: '2022-04-09T03:59:44.585Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 538.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'RLzhbs7ekhC87zzZg',
    createdAt: '2022-04-09T03:59:44.717Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 986.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'MQZhieoguJ8cTNcNX',
    createdAt: '2022-04-09T03:59:45.277Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 136.3500000000005,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'YhpyS88Gp92deWFX4',
    createdAt: '2022-04-09T03:59:45.550Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2400.100000000001,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'PZqpDzgWFivr2Tnhz',
    createdAt: '2022-04-09T03:59:45.798Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'xrQNyvqD7h29XDgFJ',
    createdAt: '2022-04-09T04:01:36.083Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 770.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'y7KmhEApnBvsrrrYS',
    createdAt: '2022-04-09T04:01:36.226Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7679,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'SWmXA5aRXskhXNyAF',
    createdAt: '2022-04-09T04:01:36.339Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2397.100000000001,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'FrggYXLujPuRQvMqN',
    createdAt: '2022-04-09T04:01:36.452Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 80,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'zPfXxScakQxo2tR6C',
    createdAt: '2022-04-09T04:01:36.565Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8593.604999999983,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'E2nDyTfqspXkSDQMy',
    createdAt: '2022-04-09T04:01:36.673Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 199260.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'wnCzijBscehwaJrf3',
    createdAt: '2022-04-09T04:01:36.790Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 656.5,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'GqFcPqyLwxvzNrQHb',
    createdAt: '2022-04-09T04:01:36.901Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2108,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'sRv4JDNBp8G2773QW',
    createdAt: '2022-04-09T04:01:37.011Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1240,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'b9Ai3Z4saxRiNK2Ec',
    createdAt: '2022-04-09T04:03:33.524Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7629,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gyw8XQaPe649Fcyjt',
    createdAt: '2022-04-09T04:03:33.659Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2073,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '2Roo5ZEG2vZLHMPS4',
    createdAt: '2022-04-09T04:03:33.766Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 570.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'TRKmynZ3TBMfe5FYu',
    createdAt: '2022-04-09T04:03:33.908Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2347.100000000001,
    },
    transactionType: 'transferOut',
    amount: -4.8,
  },
  {
    _id: 'dCGFvaeFkSjpmRtzk',
    createdAt: '2022-04-09T04:03:34.022Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1326,
    },
    transactionType: 'transferOut',
    amount: -3.2,
  },
  {
    _id: 'npLML8qySsuhxWhRW',
    createdAt: '2022-04-09T04:03:34.139Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15962.599999999999,
    },
    transactionType: 'transferOut',
    amount: -6.5,
  },
  {
    _id: 'SupKAygQBzCSt7ak8',
    createdAt: '2022-04-09T04:03:34.249Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 196960.3,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'sYvCom3uNpuMp9sdB',
    createdAt: '2022-04-09T04:03:34.373Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26415.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'nsDoqc5bsXMeLjRzq',
    createdAt: '2022-04-09T04:03:34.489Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3935.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'TRXDDFJuNZ32WEy7h',
    createdAt: '2022-04-09T04:07:04.741Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 11470,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'Ri6kcw2G7qPp22GMB',
    createdAt: '2022-04-09T04:07:04.868Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8590.604999999983,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'ndY2aZmXi2RNYfQLP',
    createdAt: '2022-04-09T04:07:04.979Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 196880.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'xLm8dtQ6fjTSDpKcd',
    createdAt: '2022-04-09T04:07:05.089Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16269.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mrKubCCf5b8Niq7sj',
    createdAt: '2022-04-09T04:07:05.342Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 120,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'nNBQinrmmRWbNYCMy',
    createdAt: '2022-04-09T04:09:05.582Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7629,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'jseN5ojef6d5fFnmR',
    createdAt: '2022-04-09T04:09:05.719Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 94.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2v8ziPgmZCGQk4YaQ',
    createdAt: '2022-04-09T04:09:05.837Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 196380.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'DddFTeHjtCmxHohpn',
    createdAt: '2022-04-09T04:09:05.947Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26405.1,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: 'n77XDzJoYhyHnxncM',
    createdAt: '2022-04-09T04:09:06.613Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8390.604999999983,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'ZeczkxSuMBFLRJsTc',
    createdAt: '2022-04-09T04:09:06.837Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1120,
    },
    transactionType: 'transferOut',
    amount: -260,
  },
  {
    _id: 'xmYNYhJQutxnJHwWX',
    createdAt: '2022-04-09T04:09:06.980Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5312,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'Fxqgvjvz2noS5sCzD',
    createdAt: '2022-04-09T04:11:34.840Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5629,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: '5jP7tRtDYd5on27nB',
    createdAt: '2022-04-09T04:11:34.983Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 92.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ZA5g6SBcs7xDjfPup',
    createdAt: '2022-04-09T04:11:35.096Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 195380.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'kiJWu5WLbiEjyhytn',
    createdAt: '2022-04-09T04:11:35.213Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26279.1,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'B57SyLi7euFFNZYzq',
    createdAt: '2022-04-09T04:11:35.326Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8290.604999999983,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'DuMAxACjpeSoZeB7k',
    createdAt: '2022-04-09T04:11:35.445Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 860,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'fihziPQEwFNiy4dyv',
    createdAt: '2022-04-09T04:11:35.569Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5272,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'BjFKwFzEtL844Gamm',
    createdAt: '2022-04-09T04:13:50.231Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3629,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'x4iBZCDrZBTceGxPG',
    createdAt: '2022-04-09T04:13:50.359Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 90.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'JpdpJASe8gaL6DoLx',
    createdAt: '2022-04-09T04:13:50.466Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 194380.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'EB4aGaDWFTzEBGXgr',
    createdAt: '2022-04-09T04:13:50.584Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26154.1,
    },
    transactionType: 'transferOut',
    amount: -126,
  },
  {
    _id: '7cH8vsqWupr9hbgPe',
    createdAt: '2022-04-09T04:13:50.692Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8190.604999999983,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'iLrSFfjGS4SumRzNN',
    createdAt: '2022-04-09T04:13:50.809Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 610,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Yt9FpYMB5TWNogr24',
    createdAt: '2022-04-09T04:13:50.918Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5232,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'c9CFSLiui6kkipH3v',
    createdAt: '2022-04-09T05:18:21.767Z',
    transactionType: 'in',
    amount: 5250,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 1629,
    },
  },
  {
    _id: 'ZuPXKDwuTLfm2tY2Y',
    createdAt: '2022-04-09T05:19:01.637Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'muYdwkMsDL4xy5xXj',
    createdAt: '2022-04-09T06:06:58.313Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
    transactionType: 'transferOut',
    amount: -195,
  },
  {
    _id: 'T5DCrEcswuxQtcGqB',
    createdAt: '2022-04-09T06:06:58.451Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9598,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'LxLfq5gmP8jQev6SB',
    createdAt: '2022-04-09T06:06:58.567Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4749,
    },
    transactionType: 'transferOut',
    amount: -102,
  },
  {
    _id: 'JkBcBb84nWyiHzacr',
    createdAt: '2022-04-09T06:06:58.681Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1082,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: '5GPhWK8ZygWpX6utC',
    createdAt: '2022-04-09T06:07:12.440Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 155,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: 'QMAgdrj5tps6qu9Zz',
    createdAt: '2022-04-09T06:07:12.551Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9547,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: '88W93DXzbtk2HMtMM',
    createdAt: '2022-04-09T06:07:12.664Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1069,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ZrDGrWZKMKRnQhgqd',
    createdAt: '2022-04-09T06:58:24.289Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1066,
    },
  },
  {
    _id: 'hBdWicRf65jzTcXJz',
    createdAt: '2022-04-09T06:58:41.538Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5192,
    },
  },
  {
    _id: 'Xp74ZrXPxRZgosTXu',
    createdAt: '2022-04-09T06:59:01.242Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8090.604999999983,
    },
  },
  {
    _id: 't6ZRZmgYi3Y6emab3',
    createdAt: '2022-04-09T06:59:17.059Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2342.3000000000006,
    },
  },
  {
    _id: 'T7xSGxTd26HS7Qh8o',
    createdAt: '2022-04-09T06:59:50.235Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 750,
    },
  },
  {
    _id: 'o5E6Mm6kEWkRsoEev',
    createdAt: '2022-04-09T07:56:42.965Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'ZSXfJ6ZNHgFoASWRu',
    createdAt: '2022-04-09T07:56:43.133Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2445.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'ZAHkzmcdRTspg6a44',
    createdAt: '2022-04-09T07:56:43.277Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 136.1000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'XyTzq2wWDXm9q9dGN',
    createdAt: '2022-04-09T07:56:43.387Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 38.56000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Ae4uCwKdXqox8WS9z',
    createdAt: '2022-04-09T07:56:43.497Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 508.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '2WRz95Dab8bPitt7j',
    createdAt: '2022-04-09T07:56:43.609Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 156.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'nSBSqNdzjvnWCxLqm',
    createdAt: '2022-04-09T07:56:43.720Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8340.604999999983,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'EfGiYcK8F9FbJRhx5',
    createdAt: '2022-04-09T07:56:43.831Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 528.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'o3R4fbhsuwp8NFkrJ',
    createdAt: '2022-04-09T07:56:43.984Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 969.02,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'sth4jhXBifcy2PyAc',
    createdAt: '2022-04-09T07:56:44.140Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 115.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NExm45k3WPtjHkX77',
    createdAt: '2022-04-09T07:56:44.286Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9zfwW84ZrW69frc8E',
    createdAt: '2022-04-09T07:56:44.407Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1225,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'QjnfwP7aJGjePNun6',
    createdAt: '2022-04-09T08:25:25.118Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 470.98000000000684,
    },
  },
  {
    _id: 'Xmr23pTnfoL3HMBNf',
    createdAt: '2022-04-09T08:53:55.223Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 3927.5,
    },
  },
  {
    _id: 'ZXGjMzLFn87JEN6pF',
    createdAt: '2022-04-09T08:54:51.349Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 506.46000000000026,
    },
  },
  {
    _id: '2GSNuR3hjqcFcPeXf',
    createdAt: '2022-04-09T09:23:18.204Z',
    transactionType: 'transferIn',
    amount: 2250,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 870.9800000000068,
    },
  },
  {
    _id: 'wLm5JzT3FEjMZHRMY',
    createdAt: '2022-04-09T09:36:05.592Z',
    transactionType: 'transferIn',
    amount: 232.63,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2013,
    },
  },
  {
    _id: 'Q24XS93krGha2wXGp',
    createdAt: '2022-04-09T10:05:54.885Z',
    transactionType: 'transferIn',
    amount: 1368,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3120.980000000007,
    },
  },
  {
    _id: 'a3jpxpKifQSjE3ri8',
    createdAt: '2022-04-09T12:45:04.765Z',
    transactionType: 'transferIn',
    amount: 221,
    storeItem: {
      type: {
        _id: '4rrmm3JfMLBnXwERi',
        name: 'yoghurt ',
        unit: 'kg',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe381ef71-e198-428b-a614-0f360881c3bc_200x200.jpg?alt=media&token=ee3d6db4-f4d9-4c26-bc3d-ed74a433cad1',
        },
      },
      quantity: 501.8,
    },
  },
  {
    _id: 'hwn5LyT4jinqpewqo',
    createdAt: '2022-04-09T17:17:59.839Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6879,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'jHrJPSeLQpzQ6ZsJC',
    createdAt: '2022-04-09T17:17:59.976Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4488.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'uQxdRxQhmnz6C5qLq',
    createdAt: '2022-04-09T17:18:00.116Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 625,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '6bZTmpgFbiNwsjkTv',
    createdAt: '2022-04-09T17:18:00.235Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 556.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'httHnSfRyA69FhqLT',
    createdAt: '2022-04-09T17:18:00.358Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2255,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'dCo2A6NYB8jALaqaq',
    createdAt: '2022-04-09T17:18:00.487Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1216,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'qcH9NixmGiBBLcEbb',
    createdAt: '2022-04-09T17:34:18.359Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9514,
    },
  },
  {
    _id: 'X6TJAQ6d6xqjPYAtd',
    createdAt: '2022-04-10T12:28:13.368Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6679,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'QvskbjT6S3MqLm4Fv',
    createdAt: '2022-04-10T12:28:13.499Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 4088.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'RxbEyYyvcXhJoSFBn',
    createdAt: '2022-04-10T12:28:13.608Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 575,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'XsqsESQcDPosQaEFy',
    createdAt: '2022-04-10T12:28:13.744Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 551.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'C8tpYCPC4DmRpyRv4',
    createdAt: '2022-04-10T12:28:13.856Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2073,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: '6EvRCxDrrCBvNsnTi',
    createdAt: '2022-04-10T12:28:13.969Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1207,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '5pbpkc2wksgbM9mDf',
    createdAt: '2022-04-10T16:19:34.364Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3688.980000000007,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'JGoBE9vGxwq6FWxih',
    createdAt: '2022-04-10T16:19:34.501Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2245.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'CZ9vuf9FxtQJ9KbFG',
    createdAt: '2022-04-10T16:19:34.613Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6479,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'YuyPGfM4WJC2neW72',
    createdAt: '2022-04-10T16:19:34.734Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2517.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vt25wCSoxSRTZyYeA',
    createdAt: '2022-04-10T16:19:34.844Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 120.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'D4NYrjiW4SsHtwqBv',
    createdAt: '2022-04-10T16:19:34.961Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16269.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'r8spTkJAzWWEMWxwE',
    createdAt: '2022-04-10T16:19:35.076Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZErzrCqyZ34KnJKd3',
    createdAt: '2022-04-10T16:19:35.185Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 23.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Rt2ncdD7NnLWBha8A',
    createdAt: '2022-04-10T16:19:35.300Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 1.0399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'ttTXWREFsuGAxzzMQ',
    createdAt: '2022-04-10T16:19:35.413Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 546.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'Dm6qo5bvQzTmuL9YA',
    createdAt: '2022-04-10T16:19:35.528Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8339.604999999983,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'tenhanrC5wFYGcaWL',
    createdAt: '2022-04-10T16:19:35.640Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5282,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'HJdoDYGj7C9QuhFgy',
    createdAt: '2022-04-10T16:25:11.343Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6439,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'jMwxDRQgeqkGqpGTn',
    createdAt: '2022-04-10T16:25:11.506Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2888.980000000007,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'Y8mtuiLBNmBHKR6yK',
    createdAt: '2022-04-10T16:25:11.624Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2245.63,
    },
    transactionType: 'transferOut',
    amount: -450,
  },
  {
    _id: 'B333LaDWKTdCo8S4S',
    createdAt: '2022-04-10T16:25:11.737Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2517.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'DzAXAsTYNbYCQajs5',
    createdAt: '2022-04-10T16:25:11.851Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 10,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wKBAyWA9Jq4Q4equX',
    createdAt: '2022-04-10T16:25:11.963Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 26028.1,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'K5KsvMZjy3pMoyj9W',
    createdAt: '2022-04-10T16:25:12.085Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 193380.3,
    },
    transactionType: 'transferOut',
    amount: -700,
  },
  {
    _id: 'kMnmvbdPBfiREPcNR',
    createdAt: '2022-04-10T16:25:12.197Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1322.8,
    },
    transactionType: 'transferOut',
    amount: -28,
  },
  {
    _id: 'K7724zgwDLM7KC45h',
    createdAt: '2022-04-10T16:25:12.313Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15956.099999999999,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'pjs4qvy2X2yN65N4C',
    createdAt: '2022-04-10T16:25:12.432Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16257.400000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'GBG5ia4q4gzYQWQY9',
    createdAt: '2022-04-10T16:25:12.555Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 680.5,
    },
    transactionType: 'transferOut',
    amount: -270,
  },
  {
    _id: 'wFmMiFQX5q2TaNasi',
    createdAt: '2022-04-10T16:25:12.668Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4227.5,
    },
    transactionType: 'transferOut',
    amount: -105,
  },
  {
    _id: 'jze4EYqfzAKNaS5yz',
    createdAt: '2022-04-10T16:26:46.575Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 528.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '4WiHWmbBhqXGL7sbA',
    createdAt: '2022-04-10T16:26:46.712Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 960.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '3qAKGdwut98fft2hp',
    createdAt: '2022-04-10T16:26:46.833Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 135.90000000000052,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'pNktJBgq73JubsAGC',
    createdAt: '2022-04-10T16:26:46.949Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2467.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'nq7q3uDsjbbbheaLN',
    createdAt: '2022-04-10T16:26:47.234Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: '76KyTiGQzED82YuEk',
    createdAt: '2022-04-10T16:27:17.856Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2088.980000000007,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'X45JzhoKoRFepSdCQ',
    createdAt: '2022-04-10T16:27:17.998Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1795.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9cWF7yiATb4wXCQcB',
    createdAt: '2022-04-10T16:27:18.113Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6289,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'MZHLZaoXgnjve6zJL',
    createdAt: '2022-04-10T16:27:18.238Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2464.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'idZdow3FDH8G6iwST',
    createdAt: '2022-04-10T16:27:18.361Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 120.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EdyxbiqxcTYayXJrt',
    createdAt: '2022-04-10T16:27:18.472Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16242.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2JXbe24jDKWSL4jD7',
    createdAt: '2022-04-10T16:27:18.590Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FM7fYtp6bi6SGuepL',
    createdAt: '2022-04-10T16:27:18.701Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 22.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'BSHeRyJnQSusJHiMT',
    createdAt: '2022-04-10T16:27:18.817Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.5399999999999876,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'zAkX3EaeytcpwEoNu',
    createdAt: '2022-04-10T16:27:18.927Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 538.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 't7ym3P4rJfuK6zDQS',
    createdAt: '2022-04-10T16:27:19.093Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8339.004999999983,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'rPn4MakCjA7AeKAyz',
    createdAt: '2022-04-10T16:27:19.243Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5206,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'AsmQAkEAnWAHqzCNi',
    createdAt: '2022-04-10T16:28:06.819Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 580,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'TkzdK4CdqLd4ovutZ',
    createdAt: '2022-04-10T16:28:06.993Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2405.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'cH2SPWgpqG9mRoZYh',
    createdAt: '2022-04-10T16:28:07.108Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 38.06000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'LfntvKerENKX2Ao28',
    createdAt: '2022-04-10T16:28:07.236Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 135.65000000000052,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'KTzMEPQkS5RpGdy3J',
    createdAt: '2022-04-10T16:28:07.352Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 530.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '6hxLtP6vLtqKHxNHk',
    createdAt: '2022-04-10T16:28:08.130Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 156.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'D2zszCsiYAXzMivnW',
    createdAt: '2022-04-10T16:28:08.488Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8338.404999999982,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'QutgeEJc3RgonvBTc',
    createdAt: '2022-04-10T16:28:08.627Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'N7fN5PefDtsYmnB6J',
    createdAt: '2022-04-10T16:28:08.742Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 943.02,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'LWsBnN7F7aGPGEF7E',
    createdAt: '2022-04-10T16:28:08.867Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 115.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PnXXyixpvGJDXpku4',
    createdAt: '2022-04-10T16:28:08.987Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hYBmPXvtichTorXDo',
    createdAt: '2022-04-10T16:28:09.160Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1198,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '5KtCL8veschJWhCBJ',
    createdAt: '2022-04-10T16:28:49.365Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 560,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'NRbwcJMtT6DcvPuF6',
    createdAt: '2022-04-10T16:28:49.530Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2365.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'jLjnWzY7B6qMj5xsE',
    createdAt: '2022-04-10T16:28:49.677Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 37.56000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'qHn23sR56dv5uGTF2',
    createdAt: '2022-04-10T16:28:49.801Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 135.45000000000053,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'jiDZzm6hAWAYHn7Hu',
    createdAt: '2022-04-10T16:28:49.922Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 528.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ob6NGM5aEX58hLWAL',
    createdAt: '2022-04-10T16:28:50.037Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 155.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'A9dAPynapgaZCKR2R',
    createdAt: '2022-04-10T16:28:50.152Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8336.904999999982,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'rh5m9BEdGXor9nSou',
    createdAt: '2022-04-10T16:28:50.269Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MZ5Nnj7PqSjEwndaw',
    createdAt: '2022-04-10T16:28:50.393Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 932.02,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '5yJNx9GDJDG6EASap',
    createdAt: '2022-04-10T16:28:50.513Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 115.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mCa4wFNZP3ieWo8qF',
    createdAt: '2022-04-10T16:28:50.629Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'B6C28dfBnyMtpG9oj',
    createdAt: '2022-04-10T16:28:50.756Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1189,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'suFB7zYr6v5owcajK',
    createdAt: '2022-04-10T16:29:36.176Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 540,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'EJvcArssyPDRvzLry',
    createdAt: '2022-04-10T16:29:36.345Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2325.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '3qqwM7YNd94nWGxya',
    createdAt: '2022-04-10T16:29:36.491Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 135.25000000000054,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'dTZBjRrit8GqBvrv7',
    createdAt: '2022-04-10T16:29:36.603Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 37.06000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'KKrbEESuDTLirAzC9',
    createdAt: '2022-04-10T16:29:36.721Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 526.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'X55QeEjE9oBHBu5dA',
    createdAt: '2022-04-10T16:29:36.859Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 155.3,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'kdC4ecqi9NTYdA9kZ',
    createdAt: '2022-04-10T16:29:36.973Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8335.404999999982,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'uzhnL2AWJAP2CpPkB',
    createdAt: '2022-04-10T16:29:37.089Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4wxGk7zqjEbq3Dtgh',
    createdAt: '2022-04-10T16:29:37.208Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 921.02,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '36DYyFwxxJcNYupJv',
    createdAt: '2022-04-10T16:29:37.337Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 115.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'd8Lha356YsRpHmGv5',
    createdAt: '2022-04-10T16:29:37.463Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8KwfFDxDvCvKwwMqT',
    createdAt: '2022-04-10T16:29:37.670Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1180,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'r28FawvtCkyAdMubn',
    createdAt: '2022-04-10T17:44:52.644Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 10670,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'tCPjisjrhAzgsk4wy',
    createdAt: '2022-04-10T17:44:52.753Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8334.404999999982,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '2pbjPpP6BAmy5tNiA',
    createdAt: '2022-04-10T17:44:52.865Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 192680.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: '3Z6pn3xMPR7F9HEoQ',
    createdAt: '2022-04-10T17:44:52.972Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16242.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7tqBoFdWKAGcvqbuH',
    createdAt: '2022-04-10T17:44:53.225Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 410.5,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'avY9EfXPeSyMQMZFv',
    createdAt: '2022-04-10T17:46:31.268Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 9870,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'of5QnBHQzYdyXG6MC',
    createdAt: '2022-04-10T17:46:31.376Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8134.4049999999825,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'DegSHf3YuPTv5WozW',
    createdAt: '2022-04-10T17:46:31.485Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 192180.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'JaKTgyLWvRZipj58T',
    createdAt: '2022-04-10T17:46:31.595Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16242.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'opzF5xLKwpPW29TWe',
    createdAt: '2022-04-10T17:46:31.841Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 360,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'ShtnWJNtnb3roya3L',
    createdAt: '2022-04-10T17:47:22.643Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6249,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'FWiJA5WiufJyABRtN',
    createdAt: '2022-04-10T17:47:22.780Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 88.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'DrAMEn7o4wi2zn2io',
    createdAt: '2022-04-10T17:47:22.889Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 191680.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'S33YQJHfkmNDAwqQi',
    createdAt: '2022-04-10T17:47:22.996Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25963.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'CvB2MxujfZBR4DbMn',
    createdAt: '2022-04-10T17:47:23.112Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7934.4049999999825,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'CEAsWtFyGqk9WkQcs',
    createdAt: '2022-04-10T17:47:23.233Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1360,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'KumfFPv3ghJ28v8M8',
    createdAt: '2022-04-10T17:47:23.344Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5130,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'pk8JYzAzcwbA3WJnK',
    createdAt: '2022-04-11T02:11:29.240Z',
    transactionType: 'transferIn',
    amount: 210,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5050,
    },
  },
  {
    _id: 'jt224T5BSsXreesgy',
    createdAt: '2022-04-11T02:15:24.246Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 1022,
    },
  },
  {
    _id: 'jDYNB6YZxBb7uevF4',
    createdAt: '2022-04-11T04:04:34.846Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 525,
    },
  },
  {
    _id: 'pm2hjoqsxBwWyhkF3',
    createdAt: '2022-04-11T04:05:04.011Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10154,
    },
  },
  {
    _id: 'FD2azyRskohcJrpLp',
    createdAt: '2022-04-11T04:05:32.533Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4647,
    },
  },
  {
    _id: 'jtXrrTGEdyjEYbBZD',
    createdAt: '2022-04-11T05:20:59.125Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1288.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'mu5cuutm3zFirSRBH',
    createdAt: '2022-04-11T05:20:59.257Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1795.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ALmkN7zoudWvAexhn',
    createdAt: '2022-04-11T05:20:59.366Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2249,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'b9gYS8HjgwT5ySaFX',
    createdAt: '2022-04-11T05:20:59.472Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2464.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'GNJjEWdKsYy6Z7hPy',
    createdAt: '2022-04-11T05:20:59.581Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 120.5,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'iaNJBn3sgeG2BP8my',
    createdAt: '2022-04-11T05:20:59.690Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16242.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'S9RkhQKxPA9XEqLsp',
    createdAt: '2022-04-11T05:20:59.798Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'G4St5nGpgJkhbhCEe',
    createdAt: '2022-04-11T05:20:59.910Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.0399999999999876,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8wh3HpaES44GXYBfW',
    createdAt: '2022-04-11T05:21:00.024Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 21.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'CKc7YhMYYdrHnZ7nC',
    createdAt: '2022-04-11T05:21:00.133Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'W5eMqrmd7vBC8c8y3',
    createdAt: '2022-04-11T05:21:00.242Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7734.4049999999825,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'oAAJy65ggufBjTaaY',
    createdAt: '2022-04-11T05:21:00.352Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5260,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'TfpB4PyP75vcqMx3q',
    createdAt: '2022-04-11T05:37:46.004Z',
    transactionType: 'in',
    amount: 6150,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2229,
    },
  },
  {
    _id: '6P4jHsRa2gJw7SnAW',
    createdAt: '2022-04-11T05:38:42.584Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 122,
    },
  },
  {
    _id: 'TyDEp2G6rpbPqaD6g',
    createdAt: '2022-04-11T05:50:22.615Z',
    transactionType: 'in',
    amount: 250,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8379,
    },
  },
  {
    _id: '6WjnAFbtBkJkkuWEf',
    createdAt: '2022-04-11T06:13:11.266Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 488.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'ftkrD3sWM8TbKcvtm',
    createdAt: '2022-04-11T06:13:11.411Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2458.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'TxmHA95nfNdLmawj7',
    createdAt: '2022-04-11T06:13:11.527Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25713.1,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'fGXT7dtRm2QYbv6Tw',
    createdAt: '2022-04-11T06:13:11.641Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 190180.3,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'zABhbJhKqyoi9eTke',
    createdAt: '2022-04-11T06:13:11.759Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7933.804999999982,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'tCioqc9sKQC3mGR6F',
    createdAt: '2022-04-11T06:13:11.880Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5187,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'vGda7x36GwkzRoiGY',
    createdAt: '2022-04-11T07:02:39.754Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 305,
    },
    transactionType: 'transferOut',
    amount: -184,
  },
  {
    _id: 'aqXcZmdtmA9ecCWRf',
    createdAt: '2022-04-11T07:02:39.894Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10794,
    },
    transactionType: 'transferOut',
    amount: -185,
  },
  {
    _id: 'hqukaqoniokmxRa9x',
    createdAt: '2022-04-11T07:02:40.013Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1126,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'DJKzaKN8RSEruYKQd',
    createdAt: '2022-04-11T07:04:15.378Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 305,
    },
    transactionType: 'transferOut',
    amount: -184,
  },
  {
    _id: '6Djs4uNWda6J5m63F',
    createdAt: '2022-04-11T07:04:15.516Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10794,
    },
    transactionType: 'transferOut',
    amount: -185,
  },
  {
    _id: 'yQxWFYZaWpvQWKR4r',
    createdAt: '2022-04-11T07:04:15.631Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1126,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'ms3GwHdyqw9Jqikim',
    createdAt: '2022-04-11T07:05:06.070Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 121,
    },
    transactionType: 'transferOut',
    amount: -115,
  },
  {
    _id: 'i6jv8Zk3oB7QAiPKJ',
    createdAt: '2022-04-11T07:05:06.212Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10609,
    },
    transactionType: 'transferOut',
    amount: -116,
  },
  {
    _id: 'QmPwfhfif8DoTQd83',
    createdAt: '2022-04-11T07:05:06.327Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1110,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'TyT9FN6PnNhkZ2WEB',
    createdAt: '2022-04-11T07:05:25.690Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5185,
    },
  },
  {
    _id: 'fFwTLxs4c5mFe9zip',
    createdAt: '2022-04-11T07:05:52.557Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4122.5,
    },
  },
  {
    _id: 'Hsm4sCH7bXe93RwoE',
    createdAt: '2022-04-11T07:06:08.047Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4016,
    },
  },
  {
    _id: 'EgyXwX9W6HJsXWrf5',
    createdAt: '2022-04-11T07:06:23.858Z',
    transactionType: 'transferIn',
    amount: 175,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2458.3000000000006,
    },
  },
  {
    _id: 'mw9rm5bDANPugadBL',
    createdAt: '2022-04-11T07:06:40.383Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7931.804999999982,
    },
  },
  {
    _id: 'F4oW7Qip2TLAsfuZL',
    createdAt: '2022-04-11T07:07:03.195Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1171,
    },
  },
  {
    _id: 'epNaECdrxR7PCG9Ca',
    createdAt: '2022-04-11T07:07:22.992Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1100,
    },
  },
  {
    _id: 'uriWXQZmuz8TTHfMT',
    createdAt: '2022-04-11T07:07:38.083Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 472,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'EtXaNkXfvCkyEZY5P',
    createdAt: '2022-04-11T07:07:38.193Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 2.600000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'GDTfyzB7e4QeKX67E',
    createdAt: '2022-04-11T07:07:38.449Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 200,
  },
  {
    _id: 'aonEC35rHzmuhu687',
    createdAt: '2022-04-11T07:08:22.833Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 206,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'HuS8Za5PGCfSZo9fb',
    createdAt: '2022-04-11T07:08:22.972Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4887,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: '5yKfgggib4T29ozow',
    createdAt: '2022-04-11T07:08:23.088Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1160,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '5KGmhNdaWBgppgk65',
    createdAt: '2022-04-11T07:09:01.654Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 188,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '3wu2DRZcGNATHfq7m',
    createdAt: '2022-04-11T07:09:01.792Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4850,
    },
    transactionType: 'transferOut',
    amount: -37,
  },
  {
    _id: 'k4h9r6Yw99atCnqPe',
    createdAt: '2022-04-11T07:09:01.908Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1157,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'BtAvWMNt32nXkPvWj',
    createdAt: '2022-04-11T07:14:06.565Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 272,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'Jp7BYnAAtWQbjiGYE',
    createdAt: '2022-04-11T07:14:06.684Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10493,
    },
    transactionType: 'transferOut',
    amount: -54,
  },
  {
    _id: 'bFBnzo3gf95wis8ym',
    createdAt: '2022-04-11T07:14:06.794Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1154,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'PRKLMpZ9o4XdTeM35',
    createdAt: '2022-04-11T07:15:05.136Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 214,
    },
    transactionType: 'transferOut',
    amount: -195,
  },
  {
    _id: 'hRAScmcrgnT4eySPA',
    createdAt: '2022-04-11T07:15:05.273Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10439,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'Wj9JmfnLCzhBroeiW',
    createdAt: '2022-04-11T07:15:05.397Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4813,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'PAhXkPzPSQFYtzep4',
    createdAt: '2022-04-11T07:15:05.508Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1149,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'u3GxmDpMxXqzj6EJx',
    createdAt: '2022-04-11T07:48:39.337Z',
    transactionType: 'in',
    amount: 2250,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8629,
    },
  },
  {
    _id: 'EdnbdHt5oLvLuPpDg',
    createdAt: '2022-04-11T07:49:06.185Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 19,
    },
  },
  {
    _id: '7YyBKgefX77QFvddG',
    createdAt: '2022-04-11T07:49:50.709Z',
    transactionType: 'in',
    amount: 1600,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10879,
    },
  },
  {
    _id: '8X9sf5FyJW6ePTkSo',
    createdAt: '2022-04-11T08:01:47.384Z',
    transactionType: 'out',
    amount: -700,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12479,
    },
  },
  {
    _id: 'y2KzQnecd39zfMkDd',
    createdAt: '2022-04-11T08:06:38.469Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4116,
    },
  },
  {
    _id: 'hDhSMR5nyrQK6Fk2f',
    createdAt: '2022-04-11T08:32:44.062Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 388.98000000000684,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Yycbg3EhP373BicrK',
    createdAt: '2022-04-11T08:32:44.208Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11779,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'RApLxHkCcDRo6ikwH',
    createdAt: '2022-04-11T08:32:44.328Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2633.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'rDpAg3NfNrPdTDm25',
    createdAt: '2022-04-11T08:32:44.450Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'njuJgPJDBAxrmfSqS',
    createdAt: '2022-04-11T08:32:44.600Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8181.804999999982,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'BC9YBDvnRfizk2T8k',
    createdAt: '2022-04-11T08:32:44.719Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 190130.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'ePFMhbevNCwBDcZEL',
    createdAt: '2022-04-11T08:32:44.837Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 635.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: '9To8ckPhPRsKf9bC8',
    createdAt: '2022-04-11T08:32:44.950Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2087,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'xNYW4s8a9iJZDvCmn',
    createdAt: '2022-04-11T08:32:45.084Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1216,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'TNG3wWhjaqWXzjpWE',
    createdAt: '2022-04-11T08:37:01.532Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11629,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'A4RFRrxmFcDdyJuWP',
    createdAt: '2022-04-11T08:37:01.675Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 288.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XHNynpii4DteHuXFf',
    createdAt: '2022-04-11T08:37:01.788Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1795.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tHfAADmroFiLNrKbq',
    createdAt: '2022-04-11T08:37:01.908Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2583.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '48Sigfrpogapf3Tbm',
    createdAt: '2022-04-11T08:37:02.030Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25709.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'AwpsFS5BmifP4ZzEL',
    createdAt: '2022-04-11T08:37:02.142Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15896.099999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'xRLb5q7Fb8WZqdRep',
    createdAt: '2022-04-11T08:37:02.254Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16230.400000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'sP89tfBvWwJhysv6n',
    createdAt: '2022-04-11T08:37:02.380Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 410.5,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '3sXZY76K3Jp3kx4aW',
    createdAt: '2022-04-11T08:37:02.501Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4522.5,
    },
    transactionType: 'transferOut',
    amount: -69,
  },
  {
    _id: 'CmWbeTjcfP6yzh6f9',
    createdAt: '2022-04-11T09:05:39.231Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10629,
    },
    transactionType: 'transferOut',
    amount: -1050,
  },
  {
    _id: 'TMGyDeNSgb3k6b9BS',
    createdAt: '2022-04-11T09:05:39.371Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 288.98000000000684,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'hrKqh2dn9AWobyzXB',
    createdAt: '2022-04-11T09:05:39.504Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1795.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WXREaSCBBNNc6souX',
    createdAt: '2022-04-11T09:05:39.656Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2553.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 's6ioucvJFBPWNZgnB',
    createdAt: '2022-04-11T09:05:39.796Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25649.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'rZXMTEhA7kxQrkpox',
    createdAt: '2022-04-11T09:05:39.935Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15856.099999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'QPdTZdxrTpS3aR2su',
    createdAt: '2022-04-11T09:05:40.086Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16215.400000000001,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '8SDFW3hnrFMNKrAwS',
    createdAt: '2022-04-11T09:05:40.227Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 210.5,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'ZbDzLd88KyM94ioKf',
    createdAt: '2022-04-11T09:05:40.372Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4453.5,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'tp8XgPEAgwdK3FuCZ',
    createdAt: '2022-04-11T09:21:30.794Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 518.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'CaaL79JnqJbBYGGTe',
    createdAt: '2022-04-11T09:21:30.942Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 911.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'YrP2wwuqxyXeStHMq',
    createdAt: '2022-04-11T09:21:31.051Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 135.05000000000055,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'xELjCn2qJTJAwvE8A',
    createdAt: '2022-04-11T09:21:31.160Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2523.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'HS2J83mLmb9XQRuwF',
    createdAt: '2022-04-11T09:21:31.415Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'WWHHLRs8QP3Z8CmjH',
    createdAt: '2022-04-11T10:31:19.158Z',
    transactionType: 'transferIn',
    amount: 400,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 288.98000000000684,
    },
  },
  {
    _id: 'Q4vLAzi53qMdCE94P',
    createdAt: '2022-04-11T10:31:52.422Z',
    transactionType: 'transferIn',
    amount: 1660,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 688.9800000000068,
    },
  },
  {
    _id: 'cZjWB37kzZ9jnNzmp',
    createdAt: '2022-04-11T10:40:49.588Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2348.980000000007,
    },
  },
  {
    _id: 't7Y22muDgzLf5PSR2',
    createdAt: '2022-04-11T10:41:18.516Z',
    transactionType: 'transferIn',
    amount: 2400,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1795.63,
    },
  },
  {
    _id: 'Sjvt86Qf4odr5rAJq',
    createdAt: '2022-04-11T12:41:54.594Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4316,
    },
  },
  {
    _id: '95zuBbiEAqvkpRMTe',
    createdAt: '2022-04-11T13:12:26.230Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3348.980000000007,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'CANqrZKuy8zScW5iA',
    createdAt: '2022-04-11T13:12:26.366Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9579,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'EPCC6h24AFpDa24mP',
    createdAt: '2022-04-11T13:12:26.480Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 4195.63,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'xu9Skkjuhv8pXMTK5',
    createdAt: '2022-04-11T13:12:26.594Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2520.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'z4pdy72YuNbryE6Fw',
    createdAt: '2022-04-11T13:12:26.721Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8178.804999999982,
    },
    transactionType: 'transferOut',
    amount: -2.1,
  },
  {
    _id: 'fTLobc5wGS7EJpujP',
    createdAt: '2022-04-11T13:12:26.831Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16190.400000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'qDLeLogF4dYZ7hBFW',
    createdAt: '2022-04-11T13:12:26.943Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.0399999999999876,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'cYhjkpxdzvCKZMJua',
    createdAt: '2022-04-11T13:12:27.054Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 20.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '9Z6rZYqq5jzRwfSht',
    createdAt: '2022-04-11T13:12:27.171Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4616,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'uSvvQfgw4fzn8FSXj',
    createdAt: '2022-04-11T13:38:34.813Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2348.980000000007,
    },
    transactionType: 'transferOut',
    amount: -220,
  },
  {
    _id: 'n9MC7dysSCpPmAbWd',
    createdAt: '2022-04-11T13:38:34.954Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9479,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'f4ChRhuWZE7iTC3e3',
    createdAt: '2022-04-11T13:38:35.070Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2520.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'bbkpfjYvwh8BvTKTB',
    createdAt: '2022-04-11T13:38:35.185Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 116.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'RfPiHQAEn8A5dHeq8',
    createdAt: '2022-04-11T13:38:35.297Z',
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 9126,
    },
    transactionType: 'transferOut',
    amount: -1240,
  },
  {
    _id: 'uGwwMGwgkFaEuPPRG',
    createdAt: '2022-04-11T13:38:35.525Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 19.450000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'obgCLCQy6ZuPtg9LC',
    createdAt: '2022-04-11T13:38:35.639Z',
    storeItem: {
      type: {
        _id: 'FHbrpwSEWkpQmmgqW',
        name: 'TOP ALUMINIUM FOIL FOR YOGHURT CUP 170GM ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F69822c1b-896e-40db-a717-c77619a53173_200x200.jpg?alt=media&token=612014ae-caad-4d53-8219-00907fdef17f',
        },
      },
      quantity: 18376,
    },
    transactionType: 'transferOut',
    amount: -1240,
  },
  {
    _id: 'TmcjKzfzqqPDysbrC',
    createdAt: '2022-04-11T13:38:35.753Z',
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1331,
    },
    transactionType: 'transferOut',
    amount: -206,
  },
  {
    _id: 'DwDSBBxSj53YzXaTp',
    createdAt: '2022-04-11T13:42:27.630Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9479,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'bm4PKhdumhHfrQd3q',
    createdAt: '2022-04-11T13:42:27.787Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2128.980000000007,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'R9REfafAqGRjtCKFT',
    createdAt: '2022-04-11T13:42:27.910Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3945.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Q5zvW39T8oNBYSBik',
    createdAt: '2022-04-11T13:42:28.033Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2517.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '64nNAfiCEMzJnMJw5',
    createdAt: '2022-04-11T13:42:28.151Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 115.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ypBw5WmbPNPwtkf43',
    createdAt: '2022-04-11T13:42:28.303Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 524.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'qKgd8BcNgwakBLxE8',
    createdAt: '2022-04-11T13:42:28.419Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 18.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'SD93nq39yq7xYWtjb',
    createdAt: '2022-04-11T13:42:28.538Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Eon6aZeDpDTSFEyon',
    createdAt: '2022-04-11T13:42:28.655Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16175.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'LJaq8YG96yc84yPXw',
    createdAt: '2022-04-11T13:42:28.773Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4526,
    },
    transactionType: 'transferOut',
    amount: -187,
  },
  {
    _id: 'GAr7vhKF8RSJhpaxv',
    createdAt: '2022-04-11T13:48:56.700Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 9870,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'mZKcdfsaiQNiu4WfY',
    createdAt: '2022-04-11T13:48:56.808Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8176.704999999982,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'tjHe8qbC67veeHGN5',
    createdAt: '2022-04-11T13:48:56.919Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 187830.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'CLGTSjmCiAGXizyH5',
    createdAt: '2022-04-11T13:48:57.042Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16163.400000000001,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '8W39mdGpgDK7TqmdX',
    createdAt: '2022-04-11T13:48:57.298Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 0.5,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: '457hbmjMvDgKZ8EGG',
    createdAt: '2022-04-11T13:50:55.911Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9449,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: '2fHpk98dzJTjzFvqQ',
    createdAt: '2022-04-11T13:50:56.050Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2517.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'NjFckKagsqruLW7Yy',
    createdAt: '2022-04-11T13:50:56.167Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25589.1,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '9frqJPwaW77DzMPY7',
    createdAt: '2022-04-11T13:50:56.284Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15816.099999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'e3iLDNvHJbu42SmT7',
    createdAt: '2022-04-11T13:50:56.399Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 800.5,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'QhwPy2ewatcEzwgSy',
    createdAt: '2022-04-11T13:50:56.516Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4380.5,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'S8nszipkNnHEiCGAb',
    createdAt: '2022-04-11T14:08:03.736Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 170,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'pCkawQN6esRWyavPr',
    createdAt: '2022-04-11T14:08:03.896Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10369,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'LKSNqTRiRmTGoAtyC',
    createdAt: '2022-04-11T14:08:04.028Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1143,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ZGg97iDKz2PN3jtqP',
    createdAt: '2022-04-11T16:40:49.970Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8799,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'uoskvEQbpcN3yK8Q3',
    createdAt: '2022-04-11T16:40:50.108Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1328.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '3Cx9mytZEo2KzJY4E',
    createdAt: '2022-04-11T16:40:50.247Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 625,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '6B3g4nzGarTWNimbw',
    createdAt: '2022-04-11T16:40:50.388Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 516.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '2mYDNn4ZZtHbXpzLc',
    createdAt: '2022-04-11T16:40:50.520Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1891,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'EmY2ceptCZ8hK7Lsn',
    createdAt: '2022-04-11T16:40:50.655Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1201,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'iuPqxcSzSg8Xv8Zbg',
    createdAt: '2022-04-12T05:02:51.129Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8599,
    },
    transactionType: 'transferOut',
    amount: -5000,
  },
  {
    _id: '9ux6C4PQMNXMPC3EB',
    createdAt: '2022-04-12T05:02:51.273Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 84.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'WoS9sYJrCFeZdYj9C',
    createdAt: '2022-04-12T05:02:51.389Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 185230.3,
    },
    transactionType: 'transferOut',
    amount: -2500,
  },
  {
    _id: '7YkHhRFGLAAhkv97a',
    createdAt: '2022-04-12T05:02:51.508Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25553.1,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '5gxTihyKRLH9jvjCo',
    createdAt: '2022-04-12T05:02:51.640Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8136.704999999982,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'uhdvpEfvgX8XdG2AX',
    createdAt: '2022-04-12T05:02:51.764Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 860,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'rGSSPgpEKPGhHWvZ6',
    createdAt: '2022-04-12T05:02:51.881Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5305,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '8GP2cyTreKmv7YcLj',
    createdAt: '2022-04-12T05:10:23.105Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 508.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '2LWPSAyRhfQe2X7oT',
    createdAt: '2022-04-12T05:10:23.248Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 894.02,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'KsXjj4QEqY6poDYBn',
    createdAt: '2022-04-12T05:10:23.357Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 134.80000000000055,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'YMDYGeyQQRpRtksoy',
    createdAt: '2022-04-12T05:10:23.462Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2499.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'wq2WppDEojYJZxmAp',
    createdAt: '2022-04-12T05:10:23.707Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'FJKYtXpPREZXbqpok',
    createdAt: '2022-04-12T05:13:23.180Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 928.9800000000068,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '6s9utRQSMDK9grzPE',
    createdAt: '2022-04-12T05:13:23.320Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3599,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'kKRF8TAqq8XfMAJt4',
    createdAt: '2022-04-12T05:13:23.437Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2496.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'm3rSE2vCBjBpaCos7',
    createdAt: '2022-04-12T05:13:23.554Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 140,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'ZYiNEvcbcitqHFHJW',
    createdAt: '2022-04-12T05:13:23.674Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7886.704999999982,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'fcWdDHYQS6Nn7GdxD',
    createdAt: '2022-04-12T05:13:23.792Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 182730.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'a7EAvh5keko69Jk5p',
    createdAt: '2022-04-12T05:13:23.909Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 613.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'rBDedBtikLArhJG4a',
    createdAt: '2022-04-12T05:13:24.028Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2065,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Toc9cyffKRqJe3nqK',
    createdAt: '2022-04-12T05:13:24.143Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1192,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'XMtCvw52ZicGpPh8G',
    createdAt: '2022-04-12T05:18:34.318Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 158,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'E89FWzrq7ATAYDeW5',
    createdAt: '2022-04-12T05:18:34.458Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10357,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'pDCDQCJqdsDMET6Mn',
    createdAt: '2022-04-12T05:18:34.588Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1142,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'EjjdqexXHZ5DZmA9Y',
    createdAt: '2022-04-12T05:47:43.761Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 928.9800000000068,
    },
  },
  {
    _id: 'ZzMBqmZL7Qkiz9Zyn',
    createdAt: '2022-04-12T05:52:43.399Z',
    transactionType: 'transferIn',
    amount: 2450,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1228.9800000000068,
    },
  },
  {
    _id: 'H9jFu8EBWLDj8AsoW',
    createdAt: '2022-04-12T06:54:16.241Z',
    transactionType: 'transferIn',
    amount: 120,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5205,
    },
  },
  {
    _id: 'm4Z8q7fS4pNxFpueq',
    createdAt: '2022-04-12T06:54:42.249Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 7883.704999999982,
    },
  },
  {
    _id: 'W2QXnfP9qdKfMEnHb',
    createdAt: '2022-04-12T06:55:04.348Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4339.5,
    },
  },
  {
    _id: 'oYyLyL6N8cAEkv9QG',
    createdAt: '2022-04-12T06:55:23.939Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 511.46000000000026,
    },
  },
  {
    _id: 'oCSCHhc3mj3QSYxWC',
    createdAt: '2022-04-12T06:55:41.029Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1709,
    },
  },
  {
    _id: 'GXF9BofXAYyBH3c2Q',
    createdAt: '2022-04-12T06:56:00.528Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 575,
    },
  },
  {
    _id: 'zeisfpZMyt8cTPfA2',
    createdAt: '2022-04-12T06:57:07.864Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F36a083ae-23ac-4407-8771-4336e2246576_200x200.jpg?alt=media&token=1306ff4d-a3a4-4218-85f5-808fbf3dff2f',
        },
      },
      quantity: 12,
    },
  },
  {
    _id: 'CewM9GcdhJ98Si4zp',
    createdAt: '2022-04-12T06:57:18.696Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'xyX3JCjJxkmWbo7EN',
        name: 'GLOVES NITRILE POWDER FREE  - MEDIUM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F063d4329-7985-45d3-bac7-1c99ee771a32_200x200.jpg?alt=media&token=0afbc2a7-f8ff-4af5-8022-88319906aa2f',
        },
      },
      quantity: 5,
    },
  },
  {
    _id: 'hne3qKednfoD3RrNv',
    createdAt: '2022-04-12T06:57:39.346Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 23,
    },
  },
  {
    _id: 'ijCXWg7JYwty6MGJq',
    createdAt: '2022-04-12T06:58:01.218Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 13,
    },
  },
  {
    _id: 'Bppt6JPH2s3S8goSE',
    createdAt: '2022-04-12T06:58:20.642Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe087aa06-9bbe-4765-90e8-807e6bd3e88b_200x200.jpg?alt=media&token=07849047-5931-407a-9fca-50e2923b7bbf',
        },
      },
      quantity: 8,
    },
  },
  {
    _id: 'whSmggskBjLb6EyQh',
    createdAt: '2022-04-12T08:50:59.445Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4811,
    },
  },
  {
    _id: 'Sf8K8Zj36kh68twvy',
    createdAt: '2022-04-12T08:51:22.937Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10345,
    },
  },
  {
    _id: 'ohKkxohKRRPKYyiZv',
    createdAt: '2022-04-12T09:16:40.836Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3678.980000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'BRYe8qnWAusRsAKZG',
    createdAt: '2022-04-12T09:16:41.008Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3945.63,
    },
    transactionType: 'transferOut',
    amount: -3000,
  },
  {
    _id: 'pWDkvqjSmeC77PtYs',
    createdAt: '2022-04-12T09:16:41.133Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 79.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'vbkw8giTv2S626dkQ',
    createdAt: '2022-04-12T09:16:41.248Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 180430.3,
    },
    transactionType: 'transferOut',
    amount: -1500,
  },
  {
    _id: 'u63DgYg7XaX96bTj4',
    createdAt: '2022-04-12T09:16:41.373Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25253.1,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: '33cqnMyGZeZXYJwbb',
    createdAt: '2022-04-12T09:16:41.673Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2285.1,
    },
    transactionType: 'in',
    amount: 225,
  },
  {
    _id: 'kYnxgSfxhLk7Tz7br',
    createdAt: '2022-04-12T09:36:34.593Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3349,
    },
    transactionType: 'transferOut',
    amount: -660,
  },
  {
    _id: 'jPjjr3wGy6aHvzB2q',
    createdAt: '2022-04-12T09:36:34.742Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2446.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'LEMTqokZCpE8HoCLy',
    createdAt: '2022-04-12T09:36:34.886Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25073.1,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '3MH5txW7Tmx7ZJPaF',
    createdAt: '2022-04-12T09:36:34.995Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15791.099999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'XTPP8xJgKcmwQZG3q',
    createdAt: '2022-04-12T09:36:35.108Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 680.5,
    },
    transactionType: 'transferOut',
    amount: -92,
  },
  {
    _id: 'xLbdkxg9zaZJhbj4x',
    createdAt: '2022-04-12T09:36:35.216Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4439.5,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'Lh5xmD7c9tFEshLre',
    createdAt: '2022-04-12T09:54:18.660Z',
    transactionType: 'transferIn',
    amount: 80,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 877.02,
    },
  },
  {
    _id: 'Fnr2d83AFGTc2AEkH',
    createdAt: '2022-04-12T09:54:42.596Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 154.8,
    },
  },
  {
    _id: 'C8PmnznyjFAz2W8zo',
    createdAt: '2022-04-12T09:55:11.031Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 36.56000000000008,
    },
  },
  {
    _id: 'mQocrffRK5h44aszw',
    createdAt: '2022-04-12T10:00:20.874Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25037.1,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'ZqCckvuBhuLutW6Ej',
    createdAt: '2022-04-12T10:04:47.566Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25037.1,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'RqtMg6bwAsoxGkgCh',
    createdAt: '2022-04-12T10:04:48.120Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 360,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'sBtX8MMxgZkWMEmx9',
    createdAt: '2022-04-12T11:15:37.856Z',
    transactionType: 'in',
    amount: 50,
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 310,
    },
  },
  {
    _id: '62Sz73HCvaQ3DFeud',
    createdAt: '2022-04-12T11:15:49.141Z',
    transactionType: 'in',
    amount: 8,
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25029.1,
    },
  },
  {
    _id: 'Kdw6sr6Sf4FLauq5i',
    createdAt: '2022-04-12T13:15:53.007Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 146,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'nmWCygbBRzH2RHeve',
    createdAt: '2022-04-12T13:15:53.149Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10985,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'AC2qLwqw3LWDbvoAa',
    createdAt: '2022-04-12T13:15:53.278Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1141,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'FzKs9JErYuAnCMBtd',
    createdAt: '2022-04-12T13:16:10.209Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 134,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'cKEw8GnjkFafaGREF',
    createdAt: '2022-04-12T13:16:10.343Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10973,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '9mkgfEEyo5GxxGSfe',
    createdAt: '2022-04-12T13:16:10.451Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1140,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'YbhHL44ZCme39oTAt',
    createdAt: '2022-04-12T13:27:08.619Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2689,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '4X57rcYn96yfNYB2u',
    createdAt: '2022-04-12T13:27:08.748Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3678.980000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'KHCoaAQhfsAs2y6iG',
    createdAt: '2022-04-12T13:27:08.854Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'C27k4ofPXKzKx9XgG',
    createdAt: '2022-04-12T13:27:08.962Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 536.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '4A5zhwjvnNd9zd6ZZ',
    createdAt: '2022-04-12T13:27:09.066Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2209,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'eckMYsbsxZk8uKYvE',
    createdAt: '2022-04-12T13:27:09.169Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1176,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'emqTPKhYkKEfSeW8k',
    createdAt: '2022-04-12T13:55:00.494Z',
    transactionType: 'in',
    amount: 96,
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 0.0399999999999876,
    },
  },
  {
    _id: 'JaWWvxe4NtnKc4WhZ',
    createdAt: '2022-04-12T13:57:40.285Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 3278.980000000007,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'k8TkA2iKBhg6eAxak',
    createdAt: '2022-04-12T13:57:40.424Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 945.6300000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'z28yfr7cZc6Hmh28k',
    createdAt: '2022-04-12T13:57:40.532Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2489,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DsNER7zBvuibh3n7d',
    createdAt: '2022-04-12T13:57:40.643Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2422.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9NG84pgp5eFgB7EEG',
    createdAt: '2022-04-12T13:57:40.758Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 115.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'r8TeHtraiaXQYyLtk',
    createdAt: '2022-04-12T13:57:40.875Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16133.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: '85hdNWyu8Pw3xRtGG',
    createdAt: '2022-04-12T13:57:40.984Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Axw9Nsni3S7dTvie6',
    createdAt: '2022-04-12T13:57:41.091Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 17.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '8jfCrSyRyiP54sLkn',
    createdAt: '2022-04-12T13:57:41.204Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 96.03999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'D4AKwJgWAhd2hjbir',
    createdAt: '2022-04-12T13:57:41.315Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 531.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '6f76indgiKXgom9cD',
    createdAt: '2022-04-12T13:57:41.432Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8133.704999999982,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'f4odnhH5vEJe62ZL2',
    createdAt: '2022-04-12T13:57:41.541Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5325,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'XWGdc5ghsAKRRSiuL',
    createdAt: '2022-04-12T15:28:24.717Z',
    transactionType: 'in',
    amount: 2450,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 2489,
    },
  },
  {
    _id: 'arHzoY4tfDTFEakD2',
    createdAt: '2022-04-12T15:28:49.555Z',
    transactionType: 'in',
    amount: 3200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 4939,
    },
  },
  {
    _id: 'uB2YPM4HtuqshCm4x',
    createdAt: '2022-04-12T15:29:56.480Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 369,
    },
  },
  {
    _id: 'eWGXMX27PcbAuZJoW',
    createdAt: '2022-04-12T17:16:47.764Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2448.980000000007,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'S3TyCBP5Renrve7Wq',
    createdAt: '2022-04-12T17:16:47.903Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 945.6300000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vWppMzKDGjTMTaA8h',
    createdAt: '2022-04-12T17:16:48.015Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8139,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'j5GxAsb6qBCQMJPw2',
    createdAt: '2022-04-12T17:16:48.124Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2422.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'Swqg8A5JvTfZH4cha',
    createdAt: '2022-04-12T17:16:48.241Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 115.5,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'ZHjTv46agLqPsJofN',
    createdAt: '2022-04-12T17:16:48.351Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16121.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'WRs5EJphRuqcNLRso',
    createdAt: '2022-04-12T17:16:48.463Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '25RtWJxbFS6w2N77W',
    createdAt: '2022-04-12T17:16:48.580Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 95.53999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'uwobH3tgPSFyjqBar',
    createdAt: '2022-04-12T17:16:48.692Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 16.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'WBef43sxLTuTYqoxk',
    createdAt: '2022-04-12T17:16:48.804Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ADoxW2FKjwwLKcisE',
    createdAt: '2022-04-12T17:16:48.914Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8133.104999999981,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'b6quwK4NqmbBPuBnT',
    createdAt: '2022-04-12T17:16:49.023Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5249,
    },
    transactionType: 'transferOut',
    amount: -73,
  },
  {
    _id: 'MqiikGQ7xZBdvtAi6',
    createdAt: '2022-04-12T17:17:26.030Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1648.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'qMStjLdEHGj2GWkvd',
    createdAt: '2022-04-12T17:17:26.169Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 945.6300000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'XghSnijfiyX7H7Lad',
    createdAt: '2022-04-12T17:17:26.326Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8139,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zScf2gEsnMGzFKnwe',
    createdAt: '2022-04-12T17:17:26.481Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2415.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vyR79nyCkeGQDKn62',
    createdAt: '2022-04-12T17:17:26.641Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 111.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'yiB99erFowdK5dEm8',
    createdAt: '2022-04-12T17:17:26.796Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16109.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'sr5koi88cvwBa6nxN',
    createdAt: '2022-04-12T17:17:26.928Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HgzyzKGNBZcy3G2Ao',
    createdAt: '2022-04-12T17:17:27.063Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 15.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'cxca265zW5aQ2LFgA',
    createdAt: '2022-04-12T17:17:27.202Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 95.03999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'pgwjdtp8fkXu73tXJ',
    createdAt: '2022-04-12T17:17:27.336Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 523.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'LKhaXHNjcBHXrts7z',
    createdAt: '2022-04-12T17:17:27.470Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8132.504999999981,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'L8NDkqPmr4ZAdRZwc',
    createdAt: '2022-04-12T17:17:27.600Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5176,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'D8hNDqndTrGya5S9t',
    createdAt: '2022-04-12T17:22:57.267Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 818.9800000000068,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'jxT8r8Sk5qtvYSZjM',
    createdAt: '2022-04-12T17:22:57.402Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 945.6300000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'vPYcgBERPNGxNW2eq',
    createdAt: '2022-04-12T17:22:57.512Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8139,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '9uFAEbz6a88qGYira',
    createdAt: '2022-04-12T17:22:57.623Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25037.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'aydY23hSt4SwyAad5',
    createdAt: '2022-04-12T17:22:57.738Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 94.53999999999999,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: '4qaK5N2m5L45v9Pg3',
    createdAt: '2022-04-12T17:22:57.849Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8131.904999999981,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'yFAxRP7TE8KNvn33c',
    createdAt: '2022-04-12T17:22:57.960Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16097.400000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'zMRmXgZDaN2RY63Ho',
    createdAt: '2022-04-12T17:22:58.076Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4339,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: '65pq4XknZ487RtRRY',
    createdAt: '2022-04-12T18:24:08.920Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 115.9,
    },
  },
  {
    _id: '77hy3xcN2WNC6k66H',
    createdAt: '2022-04-12T18:28:49.690Z',
    transactionType: 'transferIn',
    amount: 1591.9,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 218.98000000000684,
    },
  },
  {
    _id: 'kwu2ZwACsJ9t5frEp',
    createdAt: '2022-04-12T18:30:20.035Z',
    transactionType: 'transferIn',
    amount: 508,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 945.6300000000001,
    },
  },
  {
    _id: 'whJc8GRbaAGoR4Qvv',
    createdAt: '2022-04-13T03:30:45.358Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 719,
    },
    transactionType: 'transferOut',
    amount: -255,
  },
  {
    _id: 'H25LaEKFdkWuXuJ5p',
    createdAt: '2022-04-13T03:30:45.483Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10961,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'mp5AxafQN44WL8aoz',
    createdAt: '2022-04-13T03:30:45.589Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5051,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'nYQ9RzfZvCu8QmSP4',
    createdAt: '2022-04-13T03:30:45.695Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1128,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'pk2GeMwcCuaxrBcdW',
    createdAt: '2022-04-13T03:31:30.774Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 464,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'BEAdoaAW9aEHpg3Gk',
    createdAt: '2022-04-13T03:31:30.880Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10841,
    },
    transactionType: 'transferOut',
    amount: -68,
  },
  {
    _id: 'MLyrE67dBMe47XSsv',
    createdAt: '2022-04-13T03:31:31.636Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1118,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'DK9whjN6C4BCi3sQq',
    createdAt: '2022-04-13T03:35:25.106Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 394,
    },
    transactionType: 'transferOut',
    amount: -394,
  },
  {
    _id: 'FCzQvvRKrqMKjbQGK',
    createdAt: '2022-04-13T03:35:25.210Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 2.0000000000000018,
    },
    transactionType: 'transferOut',
    amount: -1.1,
  },
  {
    _id: '95psnw6pP5BpQ6rzn',
    createdAt: '2022-04-13T03:35:25.449Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 122,
    },
    transactionType: 'in',
    amount: 394,
  },
  {
    _id: 'XQJZarThpxRj4nBwo',
    createdAt: '2022-04-13T03:36:30.448Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 516,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'rsYvGPPta2JogtMwN',
    createdAt: '2022-04-13T03:36:30.580Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10773,
    },
    transactionType: 'transferOut',
    amount: -101,
  },
  {
    _id: 'Z33X9QRJm6bX3ankS',
    createdAt: '2022-04-13T03:36:30.689Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1112,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '5Jm6pTYBGFEv4CYnR',
    createdAt: '2022-04-13T03:37:08.136Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 416,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '3R7jWRBYvb2r6TJWB',
    createdAt: '2022-04-13T03:37:08.268Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10672,
    },
    transactionType: 'transferOut',
    amount: -151,
  },
  {
    _id: 'QsSLyzWRiLcPFyNMD',
    createdAt: '2022-04-13T03:37:08.380Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1103,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: '58evxSDhQjNvbhGEC',
    createdAt: '2022-04-13T03:56:27.994Z',
    transactionType: 'in',
    amount: 10000,
    storeItem: {
      type: {
        _id: 'Zbzyezv38M6dcg8Lx',
        name: 'Rawahy Glass Bottle',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a42d67b-b5cd-41cb-af32-6618a3d617ac_200x200.jpg?alt=media&token=1d43c43a-4ba3-4185-9eb5-731bbf7ce716',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'psBp3kRoAjvRzqqKu',
    createdAt: '2022-04-13T03:57:07.228Z',
    transactionType: 'in',
    amount: 800,
    storeItem: {
      type: {
        _id: '89q6FnCTPgT4zTw3n',
        name: 'Rawahy Creats',
        unit: 'AES',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F40ee162a-07ae-4769-a38f-d915ef7c2356_200x200.jpg?alt=media&token=f4c439a0-5ae2-479f-8439-a1c3e733774d',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'nGkeLJnPFdsiRGoup',
    createdAt: '2022-04-13T03:57:34.468Z',
    transactionType: 'out',
    amount: -400,
    storeItem: {
      type: {
        _id: 'Zbzyezv38M6dcg8Lx',
        name: 'Rawahy Glass Bottle',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a42d67b-b5cd-41cb-af32-6618a3d617ac_200x200.jpg?alt=media&token=1d43c43a-4ba3-4185-9eb5-731bbf7ce716',
        },
      },
      quantity: 10000,
    },
  },
  {
    _id: 'bJDyjy9QR6EsqmHRP',
    createdAt: '2022-04-13T04:17:00.945Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 488.75,
    },
    transactionType: 'transferOut',
    amount: -20.5,
  },
  {
    _id: 'a2ypHTsz9kSpYozzG',
    createdAt: '2022-04-13T04:17:01.104Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 171.99999999999994,
    },
    transactionType: 'transferOut',
    amount: -7.6,
  },
  {
    _id: 'v6z7o5dW7Dt8j4ojC',
    createdAt: '2022-04-13T04:17:01.213Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 138,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'H32uYZdGkkzsyJxX4',
    createdAt: '2022-04-13T04:17:01.324Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4397.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'DdCRdx9GvNzS6sqSm',
    createdAt: '2022-04-13T04:17:01.437Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5100,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'frzktJdwGcxGWecMH',
    createdAt: '2022-04-13T05:01:02.156Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1810.880000000007,
    },
  },
  {
    _id: 'qLrcje5gM3u5AJ9bd',
    createdAt: '2022-04-13T06:10:33.457Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1811.880000000007,
    },
    transactionType: 'transferOut',
    amount: -620,
  },
  {
    _id: '5eQDsutqTSHeS2Yhj',
    createdAt: '2022-04-13T06:10:33.595Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1453.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Tpf6fgTEB8i9mhwF3',
    createdAt: '2022-04-13T06:10:33.708Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7939,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'gvLmPW9ZrFAfbHxq3',
    createdAt: '2022-04-13T06:10:33.817Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2415.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'xn7xwHkpApbY6fnyg',
    createdAt: '2022-04-13T06:10:33.927Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 25027.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'CFmXkb9Gga3Tkzgdu',
    createdAt: '2022-04-13T06:10:34.036Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178930.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '4PNHLtcbhs4PTrgPQ',
    createdAt: '2022-04-13T06:10:34.145Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15766.099999999999,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'DTtGCew3EAncjydhj',
    createdAt: '2022-04-13T06:10:34.254Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1294.8,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'PMgjuYfB6BRZt9Ja4',
    createdAt: '2022-04-13T06:10:34.366Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 588.5,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'JSp5cijhrjEEb4StS',
    createdAt: '2022-04-13T06:10:34.480Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4387.5,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: '544ppbvtXKtMnLZHc',
    createdAt: '2022-04-13T06:11:53.942Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7939,
    },
    transactionType: 'transferOut',
    amount: -640,
  },
  {
    _id: '2Nf6HpA2aC888WfRE',
    createdAt: '2022-04-13T06:11:54.080Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1191.880000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oK7hohbKoTrcLB69C',
    createdAt: '2022-04-13T06:11:54.199Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1453.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sBCPfTe4WC4xjYQ2m',
    createdAt: '2022-04-13T06:11:54.312Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2397.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: '7WGbmaDM6L2vE5pqp',
    createdAt: '2022-04-13T06:11:54.421Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24997.1,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'gkMCt9RZM8nsG7f5A',
    createdAt: '2022-04-13T06:11:54.533Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15742.099999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '66jzEeBcQXyKyQa9d',
    createdAt: '2022-04-13T06:11:54.652Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16082.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'Eeog9NT5pHg3cEMiN',
    createdAt: '2022-04-13T06:11:54.768Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 488.5,
    },
    transactionType: 'transferOut',
    amount: -112.5,
  },
  {
    _id: 'JQzoDnoKCzzCoMoow',
    createdAt: '2022-04-13T06:11:54.878Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4342.5,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'LF7XpibmMaHDnhfMi',
    createdAt: '2022-04-13T06:13:16.273Z',
    transactionType: 'transferIn',
    amount: 1250,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1453.63,
    },
  },
  {
    _id: 'qYs69d23qHekQ4s7z',
    createdAt: '2022-04-13T06:16:04.245Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7299,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4BHSqeN2dprQZepSc',
    createdAt: '2022-04-13T06:16:04.378Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1191.880000000007,
    },
    transactionType: 'transferOut',
    amount: -580,
  },
  {
    _id: 'qHWs85vdj7q5Q2ayn',
    createdAt: '2022-04-13T06:16:04.484Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2703.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'uYHapfnTmkvM5wHm6',
    createdAt: '2022-04-13T06:16:04.596Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2379.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SvY3LWYeDowNcTqhi',
    createdAt: '2022-04-13T06:16:04.703Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 111.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FSonDeux74biqn6hR',
    createdAt: '2022-04-13T06:16:04.811Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 515.4600000000003,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'iN4mzeuL7Gpx9jSjM',
    createdAt: '2022-04-13T06:16:04.918Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 14.950000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'tfdEFuRT6EDuzHip6',
    createdAt: '2022-04-13T06:16:05.031Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'C7RvFAqRnB6bRogQw',
    createdAt: '2022-04-13T06:16:05.141Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16070.400000000001,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'CDh6gQBg6dNwJZ2km',
    createdAt: '2022-04-13T06:16:05.248Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4293,
    },
    transactionType: 'transferOut',
    amount: -131,
  },
  {
    _id: 'wXLC3LeHWwdtsxSmK',
    createdAt: '2022-04-13T06:24:28.765Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 165.9,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'WnFCGbe4gFJuAXWpe',
    createdAt: '2022-04-13T06:24:28.934Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aHnS7F7G3F5bpF3LL',
    createdAt: '2022-04-13T06:24:29.078Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 134.55000000000055,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'yAQvr8sEoZRbZK3zq',
    createdAt: '2022-04-13T06:24:29.187Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 957.02,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'LRdodtLoL5SsrwZD2',
    createdAt: '2022-04-13T06:24:29.312Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 498.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'J4HHL6rDRFmQzq5CP',
    createdAt: '2022-04-13T06:24:29.437Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 57.240000000000016,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'uZW5c43hkiq9rRbgv',
    createdAt: '2022-04-13T06:24:29.547Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 61.56000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.9,
  },
  {
    _id: '5SNA3BxPoFKWkjovW',
    createdAt: '2022-04-13T06:24:29.654Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 509.46000000000026,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'kRg6vqHhzAdpn55mQ',
    createdAt: '2022-04-13T06:24:29.773Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8130.404999999981,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'RcxkvZwEHSj5N35Nz',
    createdAt: '2022-04-13T06:24:29.913Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2510.1,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'aMAnEBAxPZMgPifDw',
    createdAt: '2022-04-13T06:24:30.027Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 520,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'SY6QzzXYhyyMYfxG4',
    createdAt: '2022-04-13T06:24:30.145Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 384,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'ofWh6MYFbT2nJMW4N',
    createdAt: '2022-04-13T06:24:30.367Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 88,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'NYGBo9RnLm5mhJAxF',
    createdAt: '2022-04-13T06:42:25.715Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 611.8800000000069,
    },
  },
  {
    _id: 'bRzoq9MGP9rypM2fR',
    createdAt: '2022-04-13T06:44:43.603Z',
    transactionType: 'transferIn',
    amount: 480,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 311.88000000000693,
    },
  },
  {
    _id: 'vZhAeCGFspxv8Snwn',
    createdAt: '2022-04-13T08:28:51.536Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5100,
    },
  },
  {
    _id: 'Bn9sSAfSdhNTzwXHo',
    createdAt: '2022-04-13T08:29:20.041Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4297.5,
    },
  },
  {
    _id: 'pnYW8MLmBnT4LqXcD',
    createdAt: '2022-04-13T08:29:40.324Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8130.154999999981,
    },
  },
  {
    _id: 'y86LwLqMXYLgXq8FG',
    createdAt: '2022-04-13T08:30:11.188Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1090,
    },
  },
  {
    _id: 'RbQkufThjrJXZAfdD',
    createdAt: '2022-04-13T08:30:48.016Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2379.3000000000006,
    },
  },
  {
    _id: '85RtmHQJr6bR7mnEH',
    createdAt: '2022-04-13T08:31:39.069Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 790,
    },
  },
  {
    _id: 'rhyD4TqoRfYxa756N',
    createdAt: '2022-04-13T08:32:14.517Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1125,
    },
  },
  {
    _id: 'uaiawZ9xqdcGSGazF',
    createdAt: '2022-04-13T09:18:04.780Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7299,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DNLmWtTZiqW5Ahp9i',
    createdAt: '2022-04-13T09:18:04.952Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 791.8800000000069,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'kZRGm77DmnjTFZikb',
    createdAt: '2022-04-13T09:18:05.063Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2703.63,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'jakGnH9wCp36D479v',
    createdAt: '2022-04-13T09:18:05.171Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2454.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -4.5,
  },
  {
    _id: 'Y6xoLfiYMrryG7CXf',
    createdAt: '2022-04-13T09:18:05.284Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'kJpjWaBNaW9NiDDKB',
    createdAt: '2022-04-13T09:18:05.394Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24961.1,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'G2ALCFJvWLiTShEwf',
    createdAt: '2022-04-13T09:18:05.505Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178630.3,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'ckzaJe4BKCEKQ8YFt',
    createdAt: '2022-04-13T09:18:05.618Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1279.8,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ZY9okBDEcNnSbdQFk',
    createdAt: '2022-04-13T09:18:05.727Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15717.099999999999,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'gZmgYLzAekmgNxBzs',
    createdAt: '2022-04-13T09:18:05.840Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16060.400000000001,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'GqcaeMwvW5Gni3Q37',
    createdAt: '2022-04-13T09:18:05.952Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 376,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'YRM9K3RdbjPi9MtEL',
    createdAt: '2022-04-13T09:18:06.063Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4397.5,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'toQjvSSXypY2xwqaM',
    createdAt: '2022-04-13T12:36:14.140Z',
    transactionType: 'in',
    amount: 2200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7299,
    },
  },
  {
    _id: 'zYynnAR9Q6W6mSMaB',
    createdAt: '2022-04-13T12:36:32.794Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'AjXbtznqfQcRsvZCf',
    createdAt: '2022-04-13T12:41:03.773Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9499,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'fFWppMauaWSaTfbm6',
    createdAt: '2022-04-13T12:41:03.911Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 641.8800000000069,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'NMnokDZswKpNzQbQZ',
    createdAt: '2022-04-13T12:41:04.015Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 550,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'guToaHLvdiHqSwgL7',
    createdAt: '2022-04-13T12:41:04.125Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 509.0600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Xo6YNiQwvWFnnWRPT',
    createdAt: '2022-04-13T12:41:04.235Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2027,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'cfwD9TNGrPL7mmtRo',
    createdAt: '2022-04-13T12:41:04.346Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1167,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'u8yXEiMEe62BvLGpc',
    createdAt: '2022-04-13T13:00:04.005Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F836955bb-4c46-4d95-aa0d-4cd057c4d7e9_200x200.jpg?alt=media&token=cc75a865-f8ae-400a-a2c5-2249a2829536',
        },
      },
      quantity: 7,
    },
  },
  {
    _id: 'DW6a546oW2yYKNyGb',
    createdAt: '2022-04-13T14:37:32.030Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 266,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'oKnRL49f83TPyQ6bs',
    createdAt: '2022-04-13T14:37:32.209Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10521,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '3ws4kmAgmwTa2vsB4',
    createdAt: '2022-04-13T14:37:32.319Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1140,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'EnG9FtZbSHBRyZTZM',
    createdAt: '2022-04-13T14:37:52.140Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 242,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'eZetpYGScRb3dzxWg',
    createdAt: '2022-04-13T14:37:52.298Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10497,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'tnfGWKsmLWoodd4h9',
    createdAt: '2022-04-13T14:37:52.431Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1138,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'C3gzqw4m3DZsecWLg',
    createdAt: '2022-04-13T14:38:10.458Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 218,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'RPje6BxBTeGtvnQjc',
    createdAt: '2022-04-13T14:38:10.603Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5049,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'aAqQM7uCdDg6ANKRQ',
    createdAt: '2022-04-13T14:38:10.716Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1136,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '78qYWNFxASBTKdRqn',
    createdAt: '2022-04-13T17:49:07.191Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 133,
    },
  },
  {
    _id: 'ix9vBwtr9nxYgSvd6',
    createdAt: '2022-04-13T17:49:32.455Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 164.39999999999995,
    },
  },
  {
    _id: '8k2fsPWcs2a2XCZTx',
    createdAt: '2022-04-14T01:50:32.182Z',
    transactionType: 'transferIn',
    amount: 17,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe357e5dc-766d-4518-9868-05f7651d9abb_200x200.jpg?alt=media&token=2dd741df-52c9-4d15-9d03-5e2aa74ce4dd',
        },
      },
      quantity: 99,
    },
  },
  {
    _id: 'fiR5zLdJjAW64wEQc',
    createdAt: '2022-04-14T01:50:57.311Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'HD6kFyoB86PuxA3ow',
        name: 'HAND SLEEVES ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc98a9b17-9a15-4a0c-844b-63af5c506ee5_200x200.webp?alt=media&token=a218086e-6af4-4827-9f13-f35f9b1d3f9c',
        },
      },
      quantity: 4,
    },
  },
  {
    _id: '5ENJJcsTaGeGCAGb9',
    createdAt: '2022-04-14T04:42:26.349Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 520,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'S4d2o7pQgenQh4Gh4',
    createdAt: '2022-04-14T04:42:26.553Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2495.1,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'JZ6upyf4k6xiviGcz',
    createdAt: '2022-04-14T04:42:26.665Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 134.30000000000055,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'fZ99nE8dCc7QejgGs',
    createdAt: '2022-04-14T04:42:26.774Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 60.66000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'g4K7JLkmqpSvKNkuS',
    createdAt: '2022-04-14T04:42:26.883Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 504.0600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'TxrN78J4tMATucgHn',
    createdAt: '2022-04-14T04:42:26.995Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 174.8,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'gjoH3iF4nHiG88j6q',
    createdAt: '2022-04-14T04:42:27.106Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8505.15499999998,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'N5qq3gAKpYi5k4q3M',
    createdAt: '2022-04-14T04:42:27.215Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2a26Lngp4YK8pkv9y',
    createdAt: '2022-04-14T04:42:27.325Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 956.52,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'FEEYXKczgw4HMcumY',
    createdAt: '2022-04-14T04:42:27.440Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5SopN7u86H2zRMRdW',
    createdAt: '2022-04-14T04:42:27.551Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AbdwbsNAM5r2YSKQy',
    createdAt: '2022-04-14T04:42:27.663Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2043,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'WocajWtDxvGd9N4r5',
    createdAt: '2022-04-14T04:42:27.773Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 591.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '7W8NKREpNT6yA2uYp',
    createdAt: '2022-04-14T04:42:27.887Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1158,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '79YfJsbbN7nRcK2YA',
    createdAt: '2022-04-14T04:47:21.347Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9299,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: '5Xr2wnSSgPtjoiKhu',
    createdAt: '2022-04-14T04:47:21.482Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2449.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -5.4,
  },
  {
    _id: 'BsBzQ9WN2gg6hb9wA',
    createdAt: '2022-04-14T04:47:21.592Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24952.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'Sbz2y27dAcNC9PnzS',
    createdAt: '2022-04-14T04:47:21.704Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15711.099999999999,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'RohKg5fPMiBjmsFT8',
    createdAt: '2022-04-14T04:47:21.816Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 351,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'TiBCvAnxa6fusA9HE',
    createdAt: '2022-04-14T04:47:21.929Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4386.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'gsRvd3jhZEi5G2R3f',
    createdAt: '2022-04-14T05:12:34.460Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 468.25,
    },
    transactionType: 'transferOut',
    amount: -12.3,
  },
  {
    _id: 'nJCHHLRgBSWKsAexb',
    createdAt: '2022-04-14T05:12:34.619Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 204.39999999999995,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'vd3ZyRSksxFEzzbWg',
    createdAt: '2022-04-14T05:12:34.745Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 145,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'EezeNB5TbPfSm5tzq',
    createdAt: '2022-04-14T05:12:34.860Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4374.5,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '6jxy7ThL7RoxWZA3E',
    createdAt: '2022-04-14T05:12:34.974Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5340,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bKgtPJ98PifBB9rFg',
    createdAt: '2022-04-14T06:22:56.014Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9299,
    },
    transactionType: 'transferOut',
    amount: -180,
  },
  {
    _id: 'dQ6Rc2ffKCwbMKRwB',
    createdAt: '2022-04-14T06:22:56.148Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2449.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -5.4,
  },
  {
    _id: 'NDZawnhnwjvojiiEA',
    createdAt: '2022-04-14T06:22:56.257Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24952.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'PKBapeGtXbXEjRAf8',
    createdAt: '2022-04-14T06:22:56.368Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15711.099999999999,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'ChcfSt9ujd55scH5Q',
    createdAt: '2022-04-14T06:22:56.501Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 351,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'Jua47eN4Cmg8TRTjy',
    createdAt: '2022-04-14T06:22:56.635Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4378.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ks2PHdgqhtdZLj79f',
    createdAt: '2022-04-14T06:24:53.662Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9119,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'Gi8yjitncNTAKwstX',
    createdAt: '2022-04-14T06:24:53.800Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 76.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'QGEgiSqoRWTdvw4Tt',
    createdAt: '2022-04-14T06:24:53.953Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178555.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'qeiqjRh8QqwR9282G',
    createdAt: '2022-04-14T06:24:54.066Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24942.1,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'dXJoANPcg7zXGxz2b',
    createdAt: '2022-04-14T06:24:54.189Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8504.15499999998,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'i3WDHfed5hT7E7fcA',
    createdAt: '2022-04-14T06:24:54.306Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 360,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'e5eED4fGhE7oSNprr',
    createdAt: '2022-04-14T06:24:55.191Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5340,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '6izuZXs9hAg8ZxJuR',
    createdAt: '2022-04-14T06:30:54.957Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 9110,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'HHZbzGi6hms9C9wXc',
    createdAt: '2022-04-14T06:30:55.070Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8404.15499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'ZfPCCafziznxQzrrT',
    createdAt: '2022-04-14T06:30:55.179Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 177555.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'yngvNAqwRWweCBk5C',
    createdAt: '2022-04-14T06:30:55.296Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16055.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'W2kdgpWNHBsMvnvms',
    createdAt: '2022-04-14T06:30:55.543Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 110,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'oSrJcx5fTbKdG7KW2',
    createdAt: '2022-04-14T06:31:29.051Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 7119,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'B8MZtjBjoMbwsqN62',
    createdAt: '2022-04-14T06:31:29.180Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 74.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '6FizhL77d3bXdQ82i',
    createdAt: '2022-04-14T06:31:29.286Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 177055.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'v8ag7YGs53tMbKAeZ',
    createdAt: '2022-04-14T06:31:29.396Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24817.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'cYnakCLPMG9v7djfp',
    createdAt: '2022-04-14T06:31:29.500Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8204.15499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '36k9QDeYWAuox8Poi',
    createdAt: '2022-04-14T06:31:29.614Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1110,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'etJSRt3nSQWcQuo93',
    createdAt: '2022-04-14T06:31:29.733Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5300,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'PKGGjvcffBikCdZ5P',
    createdAt: '2022-04-14T06:38:37.094Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 455.95,
    },
    transactionType: 'transferOut',
    amount: -26.6,
  },
  {
    _id: 'SzqbamtYa5yJ3bQQn',
    createdAt: '2022-04-14T06:38:37.256Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 198.39999999999995,
    },
    transactionType: 'transferOut',
    amount: -9.75,
  },
  {
    _id: 'weeErakL8fT3ARWue',
    createdAt: '2022-04-14T06:38:37.371Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 141,
    },
    transactionType: 'transferOut',
    amount: -6.5,
  },
  {
    _id: 'jghDFxZSKPDGNCzPG',
    createdAt: '2022-04-14T06:38:37.482Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4366.5,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'sJenzt2MHgbj2HtQX',
    createdAt: '2022-04-14T07:32:51.919Z',
    transactionType: 'in',
    amount: 2800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3119,
    },
  },
  {
    _id: 'w8fxZW2GXmwLPeCtY',
    createdAt: '2022-04-14T07:33:14.664Z',
    transactionType: 'in',
    amount: 2850,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5919,
    },
  },
  {
    _id: 'gbf47W44iJZ9jDvtT',
    createdAt: '2022-04-14T07:33:42.063Z',
    transactionType: 'in',
    amount: 2800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8769,
    },
  },
  {
    _id: '9vaopeJvm6xTWTzZp',
    createdAt: '2022-04-14T07:34:30.461Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'c9omxxKtjpyjWGnf4',
    createdAt: '2022-04-14T08:28:21.069Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 502.0600000000003,
    },
  },
  {
    _id: 'zq64hMGdWETQgRFtL',
    createdAt: '2022-04-14T08:28:48.432Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 500,
    },
  },
  {
    _id: 'ssPSsMQLui7tGF3mH',
    createdAt: '2022-04-14T08:29:14.376Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1845,
    },
  },
  {
    _id: 'NrEjZ6DxDpANuhjGG',
    createdAt: '2022-04-14T08:29:48.220Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1149,
    },
  },
  {
    _id: '4bGCMPyJeaG8Poc8E',
    createdAt: '2022-04-14T08:30:07.707Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8004.154999999981,
    },
  },
  {
    _id: 'T9ct878phNWjXbjM7',
    createdAt: '2022-04-14T08:30:23.185Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4353.5,
    },
  },
  {
    _id: 'K9ix7meFQ3ZWapHyz',
    createdAt: '2022-04-14T08:30:42.358Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4162,
    },
  },
  {
    _id: 'KQsMHakcEMoL237jr',
    createdAt: '2022-04-14T08:31:12.866Z',
    transactionType: 'transferIn',
    amount: 210,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5220,
    },
  },
  {
    _id: 'MGBoh6xAdR5t3zNNr',
    createdAt: '2022-04-14T08:32:51.487Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 25,
    },
  },
  {
    _id: 'JtYRQdbPfbr5QKf2Q',
    createdAt: '2022-04-14T08:33:40.435Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 15,
    },
  },
  {
    _id: 'MfgmzPDFfc6B2vTXH',
    createdAt: '2022-04-14T08:39:02.984Z',
    transactionType: 'transferIn',
    amount: 40,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 946.52,
    },
  },
  {
    _id: 'Q87GYfjGEmQgKzNwx',
    createdAt: '2022-04-14T11:55:44.734Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11569,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'kFfJimXcGMAsx3R7e',
    createdAt: '2022-04-14T11:55:44.863Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2444.4000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'ckbFQxpmZc7r63Zou',
    createdAt: '2022-04-14T11:55:44.972Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 241.88000000000693,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'xZg9HaJ9rTSWRzgP6',
    createdAt: '2022-04-14T11:55:45.086Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 527.0600000000003,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'NmyJJGQFG39FaQh4f',
    createdAt: '2022-04-14T11:55:45.193Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24567.1,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'zGfsmBDrsjdXcmvhP',
    createdAt: '2022-04-14T11:55:45.300Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 321,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'H7H9aEJtEMCPga7Lo',
    createdAt: '2022-04-14T11:55:45.413Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175055.3,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '8Hbs4pCTxxwRbfnB9',
    createdAt: '2022-04-14T11:55:45.521Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4453.5,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'j5tE5rD6BYjzemmX6',
    createdAt: '2022-04-14T12:01:31.028Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11539,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'DSFQg63w792JvH9hF',
    createdAt: '2022-04-14T12:01:31.164Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2443.4000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'yw4yiWeFAhRgLAzHs',
    createdAt: '2022-04-14T12:01:31.299Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 211.88000000000693,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'SntHvZviLGPea99pv',
    createdAt: '2022-04-14T12:01:31.412Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 127.06000000000029,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: 'sTh5AH29WGyupfhwR',
    createdAt: '2022-04-14T12:01:31.526Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24563.1,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'AfidWQtdAjKEYDKtZ',
    createdAt: '2022-04-14T12:01:32.345Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 291,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'JPuZpznhEbTpwwrs9',
    createdAt: '2022-04-14T12:01:32.506Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175015.3,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'GTmLsARgQ7jmTirPH',
    createdAt: '2022-04-14T12:01:32.620Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4446.5,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'GdqjWRatgMJvR8LKD',
    createdAt: '2022-04-14T12:58:22.897Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 212,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'kNiqHFPvSN4KLWgNB',
    createdAt: '2022-04-14T12:58:23.042Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5036,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'qy9tPgeut8ScBAxuB',
    createdAt: '2022-04-14T12:58:23.176Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1135,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'hLg6p8dYSX22s8XKB',
    createdAt: '2022-04-14T13:00:24.924Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Ft2zbhtpqFJP8HM2R',
    createdAt: '2022-04-14T13:00:25.075Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5024,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'iWz8eykWLynA96BJ4',
    createdAt: '2022-04-14T13:00:25.189Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1134,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '2uWHXgQpA2SGDdNQB',
    createdAt: '2022-04-14T13:00:41.711Z',
    transactionType: 'transferIn',
    amount: 2100,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 211.88000000000693,
    },
  },
  {
    _id: 'en3xzPPfcwXATEko8',
    createdAt: '2022-04-14T13:01:10.538Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2311.880000000007,
    },
  },
  {
    _id: 'u9EzTKds63ovgSrC8',
    createdAt: '2022-04-14T13:13:53.905Z',
    transactionType: 'transferIn',
    amount: 712.2,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2703.63,
    },
  },
  {
    _id: 'ZN4bDdSwiXJRLKQTZ',
    createdAt: '2022-04-14T13:15:03.252Z',
    transactionType: 'transferIn',
    amount: 43.63,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2611.880000000007,
    },
  },
  {
    _id: 'sXzTecBkBW2jAnStg',
    createdAt: '2022-04-14T13:32:39.933Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2655.510000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'rKAkXDDkG5r9ESgao',
    createdAt: '2022-04-14T13:32:40.070Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 3415.83,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'vnSMPHQ4SuE46JLv8',
    createdAt: '2022-04-14T13:32:40.206Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LM94PqovMoR4eK7hf',
    createdAt: '2022-04-14T13:32:40.318Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2443.4000000000005,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'ztN7bQiYDjAv7RvzW',
    createdAt: '2022-04-14T13:32:40.441Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 111.5,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'KYmKezZgND4RAwnz9',
    createdAt: '2022-04-14T13:32:40.553Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16055.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'NbEGtxg9unNTtv4zj',
    createdAt: '2022-04-14T13:32:40.673Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'AMCbTCKXW34fqAHZw',
    createdAt: '2022-04-14T13:32:40.817Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 93.03999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'YqjF5bShKnChMW9Kp',
    createdAt: '2022-04-14T13:32:40.941Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 14.150000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'kFk5mguQiYyvHTeev',
    createdAt: '2022-04-14T13:32:41.066Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '58ZuiGEZQpddy92b5',
    createdAt: '2022-04-14T13:32:41.179Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8254.15499999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'snvrEqnWSXjtRsf9Z',
    createdAt: '2022-04-14T13:32:41.304Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5430,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'A8DpB5mZbuxiA5cmn',
    createdAt: '2022-04-14T13:34:22.683Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2655.510000000007,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vH3Tcf2jCikdzn2QH',
    createdAt: '2022-04-14T13:34:22.833Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 2575.83,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'itcbPatcF6mYKWTt7',
    createdAt: '2022-04-14T13:34:22.977Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FRRXqEY8TG6QTKmfo',
    createdAt: '2022-04-14T13:34:23.101Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2437.4000000000005,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FtyKiA5RkHHXkvp6K',
    createdAt: '2022-04-14T13:34:23.215Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 107.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '4XfsprqZpGMDAH9Q7',
    createdAt: '2022-04-14T13:34:23.333Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16043.400000000001,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'B54cHaRwH7LDLQynS',
    createdAt: '2022-04-14T13:34:23.457Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ebRgvovKNScfvRPeG',
    createdAt: '2022-04-14T13:34:23.573Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 13.150000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'W7cprSjpfYcrCoaXp',
    createdAt: '2022-04-14T13:34:23.693Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 92.53999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'qp6itxtYM9SSqDeod',
    createdAt: '2022-04-14T13:34:23.818Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 526.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'JHp8Mo24DbsfWEdPM',
    createdAt: '2022-04-14T13:34:23.933Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8253.55499999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '2JmnQYKGf7s3MqaWi',
    createdAt: '2022-04-14T13:34:24.050Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5354,
    },
    transactionType: 'transferOut',
    amount: -76,
  },
  {
    _id: 'w8vFtui6Aeim8ZZyk',
    createdAt: '2022-04-14T14:32:47.364Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2654.510000000007,
    },
    transactionType: 'transferOut',
    amount: -550,
  },
  {
    _id: 'AQLEvteBMBRMgBxDS',
    createdAt: '2022-04-14T14:32:47.513Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1735.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pFRoQEA9FDzo9kZtT',
    createdAt: '2022-04-14T14:32:47.633Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11539,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'L9w8jXsR9Armik2Wg',
    createdAt: '2022-04-14T14:32:47.753Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2437.4000000000005,
    },
    transactionType: 'transferOut',
    amount: -16.5,
  },
  {
    _id: 'nQ2D9NwjHnWJyZPAL',
    createdAt: '2022-04-14T14:32:47.874Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24563.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'Aepk8ACiSuzySzu8h',
    createdAt: '2022-04-14T14:32:48.674Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 175015.3,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'CfYy79XAafkqWDRrB',
    createdAt: '2022-04-14T14:32:49.037Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15703.099999999999,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'HMwxJMx2dS5JQy6wi',
    createdAt: '2022-04-14T14:32:49.160Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1276.8,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'o6fXsf7BjThEeht7W',
    createdAt: '2022-04-14T14:32:49.281Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 281,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'vyz42ytr3GMq7NTp9',
    createdAt: '2022-04-14T14:32:49.405Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4446.5,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'hpsjg6e3T4pLGM655',
    createdAt: '2022-04-14T19:54:41.491Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11539,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'mda3Q2aocjPsqXbbu',
    createdAt: '2022-04-14T19:54:41.630Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2104.510000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'HcdfZZQuJYvBnwNNy',
    createdAt: '2022-04-14T19:54:41.743Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 600,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'kgGu2tqnaAiRCt93z',
    createdAt: '2022-04-14T19:54:41.870Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 518.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'dH4nxNuDmTCDLXcrZ',
    createdAt: '2022-04-14T19:54:41.987Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2345,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'THGnnmPSbEiS5jWNZ',
    createdAt: '2022-04-14T19:54:42.099Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1179,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'b3WZrcKDYgamtshWQ',
    createdAt: '2022-04-15T03:36:14.112Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: '2Z7wFj4yLidQrBmxF',
    createdAt: '2022-04-15T03:36:14.249Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10473,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'fpSDqXmWw2yLcno6u',
    createdAt: '2022-04-15T03:36:14.383Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5024,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'RGfxoNzGreRcAy4tF',
    createdAt: '2022-04-15T03:36:14.487Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1134,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'MuMKjsDnTpYLxuRqh',
    createdAt: '2022-04-15T03:36:44.286Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 580,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: '64tZYLN27Korqmtt5',
    createdAt: '2022-04-15T03:36:44.395Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10363,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'TkLFAxjnuXqrTpTFC',
    createdAt: '2022-04-15T03:36:44.503Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1124,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'XsvPYGiWP7k8qt2Z7',
    createdAt: '2022-04-15T03:37:12.939Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 520,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'jXDm4nJHvss63ZaPt',
    createdAt: '2022-04-15T03:37:13.084Z',
    storeItem: {
      type: {
        _id: 'Zbzyezv38M6dcg8Lx',
        name: 'Rawahy Glass Bottle',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a42d67b-b5cd-41cb-af32-6618a3d617ac_200x200.jpg?alt=media&token=1d43c43a-4ba3-4185-9eb5-731bbf7ce716',
        },
      },
      quantity: 9600,
    },
    transactionType: 'transferOut',
    amount: -67,
  },
  {
    _id: 'jpnp4tvrWrr6rsYoN',
    createdAt: '2022-04-15T03:37:13.197Z',
    storeItem: {
      type: {
        _id: '89q6FnCTPgT4zTw3n',
        name: 'Rawahy Creats',
        unit: 'AES',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F40ee162a-07ae-4769-a38f-d915ef7c2356_200x200.jpg?alt=media&token=f4c439a0-5ae2-479f-8439-a1c3e733774d',
        },
      },
      quantity: 800,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'sBBAvT7gpCh9sBHn8',
    createdAt: '2022-04-15T04:11:47.853Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 206,
    },
    transactionType: 'transferOut',
    amount: -83,
  },
  {
    _id: 'ZvjunejbQ7BfLrbrq',
    createdAt: '2022-04-15T04:11:47.989Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8252.95499999998,
    },
    transactionType: 'transferOut',
    amount: -0.06,
  },
  {
    _id: 'h3vCcxRJXWXaStmQK',
    createdAt: '2022-04-15T04:11:48.098Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1501,
    },
    transactionType: 'transferOut',
    amount: -88,
  },
  {
    _id: 'bHw86ME7kEhYkNYn5',
    createdAt: '2022-04-15T04:14:00.061Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 123,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'PLm4QqZSKtb48od4C',
    createdAt: '2022-04-15T04:14:00.207Z',
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1413,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'zJSgeKZDFXG8TJg57',
    createdAt: '2022-04-15T04:14:00.343Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 271,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'ck5SRgyAmeQcbkX2m',
    createdAt: '2022-04-15T05:02:26.273Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1735.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qbZqBFKh2txSb9xdZ',
    createdAt: '2022-04-15T05:02:26.410Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1704.510000000007,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'NPtrCSPbjpiduELqD',
    createdAt: '2022-04-15T05:02:26.523Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11339,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RSNNHgzwoGyzuPKS7',
    createdAt: '2022-04-15T05:02:26.636Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2420.9000000000005,
    },
    transactionType: 'transferOut',
    amount: -3.6,
  },
  {
    _id: 'XD3DbtTNuWxHmomDi',
    createdAt: '2022-04-15T05:02:26.745Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24533.1,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'PNbcQY5yoN3M23GZy',
    createdAt: '2022-04-15T05:02:26.859Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174765.3,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'BW2K3stsvJnKcdegt',
    createdAt: '2022-04-15T05:02:26.972Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15683.099999999999,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'woc8XY3AvKPnm2JeQ',
    createdAt: '2022-04-15T05:02:27.086Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1474,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'm2SGfjDLFXLuQciGb',
    createdAt: '2022-04-15T05:04:17.401Z',
    transactionType: 'in',
    amount: 2,
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 0.9000000000000017,
    },
  },
  {
    _id: 'qqFcLzbF9qZ6QXd7k',
    createdAt: '2022-04-15T05:05:21.171Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 450,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'JXA9cNLCoEQXAxnM9',
    createdAt: '2022-04-15T05:05:21.281Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 2.9000000000000017,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'SNXnW5cq3Av4DLf9m',
    createdAt: '2022-04-15T05:05:21.533Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 67,
    },
    transactionType: 'in',
    amount: 400,
  },
  {
    _id: 'LebKM6fbNXZ7inX59',
    createdAt: '2022-04-15T05:07:02.461Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 467,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'h3MPsbkgWgxGEeAr6',
    createdAt: '2022-04-15T05:07:02.597Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10307,
    },
    transactionType: 'transferOut',
    amount: -131,
  },
  {
    _id: 'gu6xqsbxzGZHKDfsN',
    createdAt: '2022-04-15T05:07:02.710Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1119,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'yfFyfkkozYskbNjMg',
    createdAt: '2022-04-15T05:07:39.738Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 337,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'DLMH7QeeF7p2F5jYw',
    createdAt: '2022-04-15T05:07:39.878Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10176,
    },
    transactionType: 'transferOut',
    amount: -251,
  },
  {
    _id: 'gAuANHA6Z7NXbyKmJ',
    createdAt: '2022-04-15T05:07:39.990Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1108,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'cThw6trBLrmAaThac',
    createdAt: '2022-04-15T07:01:35.979Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11339,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'aN7NjbcZoJaaEtsFK',
    createdAt: '2022-04-15T07:01:36.112Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1584.510000000007,
    },
    transactionType: 'transferOut',
    amount: -315,
  },
  {
    _id: 'Xh8Mg8zMWYNxyNQ7z',
    createdAt: '2022-04-15T07:01:36.222Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1735.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'k3afBcJ33eCcGaqrW',
    createdAt: '2022-04-15T07:01:36.329Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2417.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i9tnPcLqNwMs2bRTG',
    createdAt: '2022-04-15T07:01:36.439Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 12.150000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'RK2sXYNixASsHd89t',
    createdAt: '2022-04-15T07:01:36.581Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8252.89499999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '2Mkn9FHGEWqX9nq5D',
    createdAt: '2022-04-15T07:01:36.693Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4262,
    },
    transactionType: 'transferOut',
    amount: -31,
  },
  {
    _id: '8F6FPSW9MCnzn2Lk7',
    createdAt: '2022-04-15T07:02:17.188Z',
    transactionType: 'transferIn',
    amount: 180,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5278,
    },
  },
  {
    _id: 'Kwo82iYhHjAfkpLnH',
    createdAt: '2022-04-15T07:02:40.193Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4406.5,
    },
  },
  {
    _id: 'he4uuqzTYhEeL6Arp',
    createdAt: '2022-04-15T07:03:03.426Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8252.29499999998,
    },
  },
  {
    _id: 'y2Egfdd74swct7ydC',
    createdAt: '2022-04-15T07:03:28.052Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 107.5,
    },
  },
  {
    _id: '9E9uR5mtafkDXNBHh',
    createdAt: '2022-04-15T07:03:48.190Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1170,
    },
  },
  {
    _id: '9MSfbGZoy4DSKJada',
    createdAt: '2022-04-15T07:04:15.806Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1087,
    },
  },
  {
    _id: '4eDRbkb8b7RJyP7fw',
    createdAt: '2022-04-15T07:05:15.161Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 429.34999999999997,
    },
    transactionType: 'transferOut',
    amount: -19,
  },
  {
    _id: 'TZDD4tCq76jnQE2FG',
    createdAt: '2022-04-15T07:05:15.337Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21626.300000000003,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'rmc6nvrRXbREA5g4h',
    createdAt: '2022-04-15T07:05:15.452Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 402.79999999999995,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'Li6xkF8wHuwNNnGew',
    createdAt: '2022-04-15T07:05:15.570Z',
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 268,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '6a7e6QojQoiyJMuBN',
    createdAt: '2022-04-15T07:06:06.454Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 410.34999999999997,
    },
    transactionType: 'transferOut',
    amount: -5.5,
  },
  {
    _id: 'iCzYKiDt6q59HxyLA',
    createdAt: '2022-04-15T07:06:06.624Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21620.300000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Wy98fLiwsddhbWjh6',
    createdAt: '2022-04-15T07:06:06.741Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 396.79999999999995,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'bAPTtYfnRaistZ853',
    createdAt: '2022-04-15T07:11:31.277Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 500,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'biDQg3guWAa5tvrHL',
    createdAt: '2022-04-15T07:11:31.447Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2435.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'PjgGHffDxMaKDkm7i',
    createdAt: '2022-04-15T07:11:31.572Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 134.10000000000056,
    },
    transactionType: 'transferOut',
    amount: -0.15,
  },
  {
    _id: 'YXwErQ7f88eiZ3ico',
    createdAt: '2022-04-15T07:11:31.686Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 60.16000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'QX5WK2H85XNEr7uHc',
    createdAt: '2022-04-15T07:11:31.798Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 513.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'E4n2bC8uNndSxjPby',
    createdAt: '2022-04-15T07:11:31.912Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 174.3,
    },
    transactionType: 'transferOut',
    amount: -0.38,
  },
  {
    _id: '8mPvff5FWcxCSZNqt',
    createdAt: '2022-04-15T07:11:32.027Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8552.29499999998,
    },
    transactionType: 'transferOut',
    amount: -0.65,
  },
  {
    _id: 'iJvLstN79eWdeZSxx',
    createdAt: '2022-04-15T07:11:32.141Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HqSqyPs6fLh5E9Xe8',
    createdAt: '2022-04-15T07:11:32.256Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 986.52,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '7XAhgkx29xvssaqM9',
    createdAt: '2022-04-15T07:11:32.373Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'x5ueNq8iibcgCH9C4',
    createdAt: '2022-04-15T07:11:32.488Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YxR37YKfJnXEsye3J',
    createdAt: '2022-04-15T07:11:32.607Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2035,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'y7FTSawimMc6eruoF',
    createdAt: '2022-04-15T07:11:32.766Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 583.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'QTuZn75T8xt3WrpWL',
    createdAt: '2022-04-15T07:11:32.909Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1245,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '2mmeTS3vvtEuCnGBB',
    createdAt: '2022-04-15T07:16:38.381Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11189,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: '5skjnkEdizvg8XHkR',
    createdAt: '2022-04-15T07:16:38.520Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1269.510000000007,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Zo3Mptw2xJmxeSpm2',
    createdAt: '2022-04-15T07:16:39.542Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2417.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'sNYAebJ9pLgsJB2nM',
    createdAt: '2022-04-15T07:16:39.679Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Bu58tBy8t7Zb4FaXf',
    createdAt: '2022-04-15T07:16:39.790Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8551.64499999998,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'JQxqBvRoBPaFp5KZJ',
    createdAt: '2022-04-15T07:16:39.901Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16039.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'bYjhiiucCgeADn8YX',
    createdAt: '2022-04-15T07:16:40.016Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 92.03999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '84nyWcr2pvsnjw5Gz',
    createdAt: '2022-04-15T07:16:40.131Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5458,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'gwTXvKDrSqoD2uDcA',
    createdAt: '2022-04-15T07:16:40.466Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 404.84999999999997,
    },
    transactionType: 'in',
    amount: 100,
  },
  {
    _id: 'iNosMfRcMwGrfLHc9',
    createdAt: '2022-04-15T07:21:09.472Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11189,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'ak2yHdjpfjHa37YHG',
    createdAt: '2022-04-15T07:21:09.603Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1269.510000000007,
    },
    transactionType: 'transferOut',
    amount: -600,
  },
  {
    _id: 'yegS7r8W3XjMxiNEC',
    createdAt: '2022-04-15T07:21:09.732Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2417.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NRJiE8xRMy4NJdifS',
    createdAt: '2022-04-15T07:21:09.844Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 23.5,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'gNJvTjHNjFq3DMYFL',
    createdAt: '2022-04-15T07:21:09.951Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8551.64499999998,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: '2BfuMh8Avb5pv9gGW',
    createdAt: '2022-04-15T07:21:10.057Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16039.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'sWdTnZJ2pzfBrHhYn',
    createdAt: '2022-04-15T07:21:10.168Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 92.03999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'TSXuM6zAismL2bkJb',
    createdAt: '2022-04-15T07:21:10.288Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5458,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 't4YoFfLvbR9677SEs',
    createdAt: '2022-04-15T07:21:10.605Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 404.84999999999997,
    },
    transactionType: 'in',
    amount: 100,
  },
  {
    _id: '8j3oX6ZE9fiuD5TTo',
    createdAt: '2022-04-15T07:25:50.632Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 485,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '4yzKXHtzA9LwNZrpv',
    createdAt: '2022-04-15T07:25:50.795Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2405.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'iMz6DvKfyyDtyEP6R',
    createdAt: '2022-04-15T07:25:50.946Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 133.95000000000056,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'Ljugxc53FwbSS8MvL',
    createdAt: '2022-04-15T07:25:51.063Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.860000000000085,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'Qgs8BpDfhigMf3pzJ',
    createdAt: '2022-04-15T07:25:51.220Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 512.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'GvSrp62GHFLDox4cM',
    createdAt: '2022-04-15T07:25:51.333Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 173.92000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'YAJtvskGDFCdJdKek',
    createdAt: '2022-04-15T07:25:51.452Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8550.44499999998,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'audaemrN2QEd5ebog',
    createdAt: '2022-04-15T07:25:51.568Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zJiWnQs7MagNj5rQj',
    createdAt: '2022-04-15T07:25:51.684Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 977.52,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'RJzpQobdQW7N2Gsue',
    createdAt: '2022-04-15T07:25:51.798Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pise2n7oskzzDJ7Wm',
    createdAt: '2022-04-15T07:25:51.918Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JZEt8QWazuxCix4fR',
    createdAt: '2022-04-15T07:25:52.049Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1238,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'EKKJXy4THmzsePGsP',
    createdAt: '2022-04-15T07:28:38.704Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 510.34999999999997,
    },
    transactionType: 'transferOut',
    amount: -5.5,
  },
  {
    _id: 'tWfA53JyEGc9Xuch6',
    createdAt: '2022-04-15T07:28:38.895Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21620.300000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'ZzqaEdBXt472Euvgi',
    createdAt: '2022-04-15T07:28:39.036Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 396.79999999999995,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'EGGAWfvKCPuhmdkLu',
    createdAt: '2022-04-15T07:34:45.446Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11089,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8RSKZfyTh3WMrjihu',
    createdAt: '2022-04-15T07:34:45.605Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 669.510000000007,
    },
    transactionType: 'transferOut',
    amount: -233,
  },
  {
    _id: 't4z4f6qGyBqCLY2jf',
    createdAt: '2022-04-15T07:34:45.724Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1735.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i2ZMgS46CCZ2Scvx8',
    createdAt: '2022-04-15T07:34:45.835Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2417.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'DeAQpn9FE67gTyHWF',
    createdAt: '2022-04-15T07:34:45.945Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pJxRR6HEfFoKkwJsS',
    createdAt: '2022-04-15T07:34:46.059Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24527.1,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'fMZmRBSXEGqCFouJw',
    createdAt: '2022-04-15T07:34:46.172Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174700.3,
    },
    transactionType: 'transferOut',
    amount: -128,
  },
  {
    _id: 'Si9ComZY9EBJp9Zdx',
    createdAt: '2022-04-15T07:34:46.284Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1266.8,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Y68QyzwCwqcBcuvm6',
    createdAt: '2022-04-15T07:34:46.394Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15679.099999999999,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'yBJrepDWpHwBhY9FJ',
    createdAt: '2022-04-15T07:34:46.506Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16027.400000000001,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'RGmMoPsnjb6yewPgx',
    createdAt: '2022-04-15T07:34:46.621Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 181,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'DuPig6mNXGc9So8CG',
    createdAt: '2022-04-15T07:34:46.739Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4706.5,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'Qq2my7kwtqkN66pRt',
    createdAt: '2022-04-15T07:51:52.691Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11089,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: '2Da58hrF4FPF5MzZN',
    createdAt: '2022-04-15T07:51:52.828Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2410.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'jNXAKhXrhx4q2NgJx',
    createdAt: '2022-04-15T07:51:52.938Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24516.1,
    },
    transactionType: 'transferOut',
    amount: -42,
  },
  {
    _id: 'yNGj72aaKA4tcEibc',
    createdAt: '2022-04-15T07:51:53.051Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15669.099999999999,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: '6JdDNn45S2hnMTawE',
    createdAt: '2022-04-15T07:51:53.160Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 131,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'ZBJBycmccfYukQxuN',
    createdAt: '2022-04-15T07:51:53.272Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4689.5,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: '43oGqgfrdmDm74wPe',
    createdAt: '2022-04-15T09:28:47.901Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9925,
    },
  },
  {
    _id: 'SJ5Zrvpx5Kao5eksT',
    createdAt: '2022-04-15T09:29:19.180Z',
    transactionType: 'transferIn',
    amount: 63,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 1185,
    },
  },
  {
    _id: 'aZKpdMCzG9u7SqH4d',
    createdAt: '2022-04-15T09:30:22.247Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 319,
    },
  },
  {
    _id: 'DAt5YZR6up5rkkHku',
    createdAt: '2022-04-15T09:31:12.328Z',
    transactionType: 'transferIn',
    amount: 70,
    storeItem: {
      type: {
        _id: 'YH57SY8tLwYWL2zrj',
        name: 'RTS 82 MM (PLAIN WHITE) -JAR CAP',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F665dfe2a-8b8b-4ba1-83b7-59c7a5914c4c_200x200.jpg?alt=media&token=5b30fc96-e6ed-43a3-8955-f555e4b0061b',
        },
      },
      quantity: 300,
    },
  },
  {
    _id: 'rCvvzqgrTDG84eSiK',
    createdAt: '2022-04-15T10:43:50.275Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 497.5,
    },
  },
  {
    _id: 'KQgx4fbNyfevHbqyx',
    createdAt: '2022-04-15T10:44:16.557Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 966.52,
    },
  },
  {
    _id: 'jtzhnBjeJbZXsmiaq',
    createdAt: '2022-04-15T12:19:41.124Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10289,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'CHpWJLEdgX8RX6eyp',
    createdAt: '2022-04-15T12:19:41.267Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 436.51000000000704,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'vZLvHvEYRhuAcjyw7',
    createdAt: '2022-04-15T12:19:41.408Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 550,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'NQgSvKny5g8PbvnEc',
    createdAt: '2022-04-15T12:19:41.528Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 510.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'RXYqSeGCuoEayxBeX',
    createdAt: '2022-04-15T12:19:41.641Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2163,
    },
    transactionType: 'transferOut',
    amount: -183,
  },
  {
    _id: 'pvKkozcuerufTxZ9G',
    createdAt: '2022-04-15T12:19:41.754Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1229,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'L2YAo2zo2eB84G3sp',
    createdAt: '2022-04-15T14:11:18.613Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 87,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '7cpKwz58TP2up5Dtw',
    createdAt: '2022-04-15T14:11:18.749Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10565,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'ExQYjKPLYykGHyBwp',
    createdAt: '2022-04-15T14:11:18.856Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1147,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'vTuSwMjj6Ne6ZtK5D',
    createdAt: '2022-04-15T14:11:44.443Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 87,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'iWrogimZfnouiEteS',
    createdAt: '2022-04-15T14:11:44.581Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10565,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: '65SZBzffREXCaCvm7',
    createdAt: '2022-04-15T14:11:44.689Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1147,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'Jo6SX5z6zeSdrnJJS',
    createdAt: '2022-04-15T14:13:18.011Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 57,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'yGZRjq3jTB4eFipw4',
    createdAt: '2022-04-15T14:13:18.141Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10535,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'RQAnxJea5ugPxgXET',
    createdAt: '2022-04-15T14:13:18.254Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1145,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '7Qbs6vHPF8iiBcnLL',
    createdAt: '2022-04-15T14:13:45.777Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 42,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'LvbzHcSZ7mpHocxnJ',
    createdAt: '2022-04-15T14:13:45.911Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5022,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'ug37yYjipXstM2stt',
    createdAt: '2022-04-15T14:13:46.045Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1144,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'sqmWWF2aZXt4vS53C',
    createdAt: '2022-04-15T14:49:04.422Z',
    transactionType: 'in',
    amount: 2800,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10089,
    },
  },
  {
    _id: 'pugYA98Z9cWFXqP2Q',
    createdAt: '2022-04-15T14:49:21.689Z',
    transactionType: 'in',
    amount: 2450,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12889,
    },
  },
  {
    _id: 'g9cZbsh74aNaMvznH',
    createdAt: '2022-04-15T14:49:34.663Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 50,
    },
  },
  {
    _id: '7fGmbdShKTLzYm23W',
    createdAt: '2022-04-15T17:05:53.739Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 15339,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'v2REWAJfhHMNc6P5r',
    createdAt: '2022-04-15T17:05:53.885Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 70.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'sCTrtTPHMxTmEaNKF',
    createdAt: '2022-04-15T17:05:53.998Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174572.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'yFKGe7SN8EXmbbbaC',
    createdAt: '2022-04-15T17:05:54.135Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24474.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'gstsYZa5mr4gdypm8',
    createdAt: '2022-04-15T17:05:54.246Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8549.44499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'CHX4h9GvMGpXY8G57',
    createdAt: '2022-04-15T17:05:54.364Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 610,
    },
    transactionType: 'transferOut',
    amount: -480,
  },
  {
    _id: 'jLtr53FeJdH2AmxkZ',
    createdAt: '2022-04-15T17:05:54.477Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5448,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'sRuJ9WDMFeyCcLGLt',
    createdAt: '2022-04-15T17:10:22.973Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 465,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'ofjYJZJE5T6eiGfSW',
    createdAt: '2022-04-15T17:10:23.142Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2365.1,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'EbpKD2YzfcmGodHHP',
    createdAt: '2022-04-15T17:10:23.292Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 133.75000000000057,
    },
    transactionType: 'transferOut',
    amount: -0.26,
  },
  {
    _id: '7XPAux7XQcLTrtqPw',
    createdAt: '2022-04-15T17:10:23.405Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 59.360000000000085,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'T4Lhki5vfkuBAhkL6',
    createdAt: '2022-04-15T17:10:23.517Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 505.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'qYBf7omp8JwZvdEQq',
    createdAt: '2022-04-15T17:10:23.631Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 173.42000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.66,
  },
  {
    _id: 'eyLffwHBiRdrywFrn',
    createdAt: '2022-04-15T17:10:23.748Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8349.44499999998,
    },
    transactionType: 'transferOut',
    amount: -1.3,
  },
  {
    _id: 'h6ejjfmBQmbktE6as',
    createdAt: '2022-04-15T17:10:23.864Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 597.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5qrTpQFq8faDrTYDP',
    createdAt: '2022-04-15T17:10:23.979Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1026.52,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'pJ6ex6bAp5JsmRkNH',
    createdAt: '2022-04-15T17:10:24.095Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '3gyPycGmHTD9S7RxN',
    createdAt: '2022-04-15T17:10:24.215Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'NybY8hTXk9DmKTGvL',
    createdAt: '2022-04-15T17:10:24.337Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1220,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'pCDmvTjuojavdaMD8',
    createdAt: '2022-04-15T17:17:26.643Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11339,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'JKfJCfX2SRGHAXouq',
    createdAt: '2022-04-15T17:17:26.784Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 36.51000000000704,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Ebq2peJ9gH3LMWZd3',
    createdAt: '2022-04-15T17:17:26.900Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1735.83,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'oNKyuaj7ZJ6DP8W8E',
    createdAt: '2022-04-15T17:17:27.021Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2386.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FxeEyEfW8RKdK9r6k',
    createdAt: '2022-04-15T17:17:27.131Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 11.650000000000006,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'DFkKvL6X3JKmfDqYo',
    createdAt: '2022-04-15T17:17:27.248Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8348.14499999998,
    },
    transactionType: 'transferOut',
    amount: -1.6,
  },
  {
    _id: 'HPw7Yd4BMxxc36dq3',
    createdAt: '2022-04-15T17:17:27.364Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4231,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'hLTLLHprgmwsqvKLZ',
    createdAt: '2022-04-15T17:21:47.296Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 188.64999999999995,
    },
  },
  {
    _id: 'ZmRR4zM8jEajLtZFu',
    createdAt: '2022-04-15T17:22:23.969Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 134.5,
    },
  },
  {
    _id: 'tmgLPedGsGEDKWfhH',
    createdAt: '2022-04-16T03:08:32.773Z',
    transactionType: 'transferIn',
    amount: 235,
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 394.79999999999995,
    },
  },
  {
    _id: '8qTTnBqC5Lgom8HSB',
    createdAt: '2022-04-16T03:27:36.891Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 479.1600000000003,
    },
  },
  {
    _id: 'Pt2xPr6yaN5Lf9HSa',
    createdAt: '2022-04-16T03:28:00.885Z',
    transactionType: 'transferIn',
    amount: 300,
    storeItem: {
      type: {
        _id: 'XtB5tgYaQA7W8tnKy',
        name: '12O ONZ BLACK CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc34e3a2a-c287-4ef0-b998-cfdaa3d0393d_200x200.jpg?alt=media&token=3e39b108-4f9b-4ee4-ad8a-34d67af1004b',
        },
      },
      quantity: 1348,
    },
  },
  {
    _id: 'swjB26XSGfW72J5mk',
    createdAt: '2022-04-16T03:28:25.636Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 500,
    },
  },
  {
    _id: 'gv23fJ8zaGZ4o6kHA',
    createdAt: '2022-04-16T03:28:50.818Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 1980,
    },
  },
  {
    _id: 'QpngQhrKu4wK2G59o',
    createdAt: '2022-04-16T03:29:41.075Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1208,
    },
  },
  {
    _id: 'CavvjbPX24v8J2yfv',
    createdAt: '2022-04-16T04:05:17.622Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 400,
    },
    transactionType: 'transferOut',
    amount: -65,
  },
  {
    _id: 'rBTntAeYSsJidaeL9',
    createdAt: '2022-04-16T04:05:17.757Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10520,
    },
    transactionType: 'transferOut',
    amount: -60,
  },
  {
    _id: 'eaRd7rPyZomio4ae5',
    createdAt: '2022-04-16T04:05:17.868Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5010,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'HHJ2Hwi9k3tqsTsBq',
    createdAt: '2022-04-16T04:05:17.977Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1143,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: '53FvRFv6Yc5par7xb',
    createdAt: '2022-04-16T04:05:38.300Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 335,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'WJpmRLMfzfiAuH5tX',
    createdAt: '2022-04-16T04:05:38.411Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10460,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'BmygZBy5k9kDos6vt',
    createdAt: '2022-04-16T04:05:38.519Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1138,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'Gv8Goj75i77cYBJvc',
    createdAt: '2022-04-16T04:05:55.001Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 295,
    },
    transactionType: 'transferOut',
    amount: -95,
  },
  {
    _id: 'AZBfzazXufL73zxs2',
    createdAt: '2022-04-16T04:05:55.114Z',
    storeItem: {
      type: {
        _id: 'Zbzyezv38M6dcg8Lx',
        name: 'Rawahy Glass Bottle',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a42d67b-b5cd-41cb-af32-6618a3d617ac_200x200.jpg?alt=media&token=1d43c43a-4ba3-4185-9eb5-731bbf7ce716',
        },
      },
      quantity: 9533,
    },
    transactionType: 'transferOut',
    amount: -91,
  },
  {
    _id: 'TzsKSzxzibsgmwHdg',
    createdAt: '2022-04-16T04:05:55.226Z',
    storeItem: {
      type: {
        _id: '89q6FnCTPgT4zTw3n',
        name: 'Rawahy Creats',
        unit: 'AES',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F40ee162a-07ae-4769-a38f-d915ef7c2356_200x200.jpg?alt=media&token=f4c439a0-5ae2-479f-8439-a1c3e733774d',
        },
      },
      quantity: 794,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'W7DfoAzhcmqcsp8ie',
    createdAt: '2022-04-16T05:10:19.119Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11269,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'WZQNjtb2qTKD22dFA',
    createdAt: '2022-04-16T05:10:19.258Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2386.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9iAsQnm3h8jbfvTff',
    createdAt: '2022-04-16T05:10:19.374Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24224.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'gvWNc7N4Xvt7qFxr4',
    createdAt: '2022-04-16T05:10:19.492Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15639.099999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '8LXZg5TCh4Xso4fm2',
    createdAt: '2022-04-16T05:10:19.604Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8346.54499999998,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'oxjtuZL4hQSmbX3Dt',
    createdAt: '2022-04-16T05:10:19.733Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21618.300000000003,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: '3saExYNu7Nrkiqfiy',
    createdAt: '2022-04-16T05:10:19.857Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 577.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zGzhn2wvfEQ4hzTtN',
    createdAt: '2022-04-16T05:10:19.972Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2029,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'Tb9ydutLrC3EBHpva',
    createdAt: '2022-04-16T05:19:04.375Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 504.84999999999997,
    },
    transactionType: 'transferOut',
    amount: -20.5,
  },
  {
    _id: 'y8vaeqvmJZDHrfQrX',
    createdAt: '2022-04-16T05:19:04.535Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 208.64999999999995,
    },
    transactionType: 'transferOut',
    amount: -7.6,
  },
  {
    _id: 'XkoyzezRPJLLjrMPq',
    createdAt: '2022-04-16T05:19:04.647Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 146.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'eosnpWnJyHApiJozz',
    createdAt: '2022-04-16T05:19:04.759Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4637.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'YtJubcKTabgG9m8qb',
    createdAt: '2022-04-16T05:19:04.874Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5368,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FCPKW2EkTWvX5Jjvt',
    createdAt: '2022-04-16T05:23:46.474Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 484.34999999999997,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'APwnX52NRavttdTCA',
    createdAt: '2022-04-16T05:23:46.635Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 201.04999999999995,
    },
    transactionType: 'transferOut',
    amount: -16.5,
  },
  {
    _id: 'FTXitJtghydqaR22J',
    createdAt: '2022-04-16T05:23:46.744Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 141.5,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '9HMHxfwxgvfybGSwX',
    createdAt: '2022-04-16T05:23:46.853Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4627.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'ZYMSJCgqFceAhy9my',
    createdAt: '2022-04-16T05:24:14.338Z',
    transactionType: 'transferIn',
    amount: 180,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5368,
    },
  },
  {
    _id: 'yMZfSWXNQRXfxaDmP',
    createdAt: '2022-04-16T05:24:32.212Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8335.54499999998,
    },
  },
  {
    _id: 'v4zhZHnG2G78sPRAN',
    createdAt: '2022-04-16T05:24:39.807Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 200,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'AWa64rcMeGXuaETuN',
    createdAt: '2022-04-16T05:24:39.917Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 1.7000000000000017,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'yEtpyDKqtQu4NgK5j',
    createdAt: '2022-04-16T05:24:40.163Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 36,
    },
    transactionType: 'in',
    amount: 200,
  },
  {
    _id: 'wGYdNJatPQWHkrp75',
    createdAt: '2022-04-16T05:24:48.596Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2386.3000000000006,
    },
  },
  {
    _id: 'bxXbGokstbdiubq6q',
    createdAt: '2022-04-16T05:25:32.738Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 68.07500000000002,
    },
  },
  {
    _id: '7DuhyFdXtNK3cSpyN',
    createdAt: '2022-04-16T05:25:56.368Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 810,
    },
  },
  {
    _id: 'uKh6kdCqAAtgESGHr',
    createdAt: '2022-04-16T05:26:16.040Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '7B6cWhTusTZbEeEWt',
        name: 'WHITE CARTON BIG 42X40X33',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8410402f-350a-4815-af41-420dbe7d8d74_200x200.jpg?alt=media&token=8c7f4d33-e801-4bcc-96a2-1d192c541a45',
        },
      },
      quantity: 266,
    },
  },
  {
    _id: 'FP8Jz7fKLedM7QXkT',
    createdAt: '2022-04-16T05:26:55.343Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 236,
    },
    transactionType: 'transferOut',
    amount: -55,
  },
  {
    _id: 'kfRn7xH6238mi4vm8',
    createdAt: '2022-04-16T05:26:55.481Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 5008,
    },
    transactionType: 'transferOut',
    amount: -111,
  },
  {
    _id: 'WeixW75bZurx4war3',
    createdAt: '2022-04-16T05:26:55.591Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1135,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '7jKizcH8LQp3vax5M',
    createdAt: '2022-04-16T05:27:38.581Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 181,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'c3wEjzHuDZFYQKwQu',
    createdAt: '2022-04-16T05:27:38.774Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4897,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'D6aCBTm8pG3fLD2EW',
    createdAt: '2022-04-16T05:27:38.885Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1125,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'PpwDrxK2HndugGM9Q',
    createdAt: '2022-04-16T07:09:12.288Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10469,
    },
    transactionType: 'transferOut',
    amount: -700,
  },
  {
    _id: 'PakN5Kq2qdMnqezd3',
    createdAt: '2022-04-16T07:09:12.430Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2461.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'zF9EHnXsKK2RXh5tA',
    createdAt: '2022-04-16T07:09:12.547Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24184.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'iAbG7Hvv63RMDhmqR',
    createdAt: '2022-04-16T07:09:12.658Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15599.099999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'tBJiWFjKtCQJ9CjuW',
    createdAt: '2022-04-16T07:09:12.771Z',
    storeItem: {
      type: {
        _id: 'xn9i9nRoLubDTGoPp',
        name: 'Thermoforming Top Film 4 & 8 Lane ',
        unit: 'METER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb11dfc13-d445-49ea-bddf-dcdf015b52be_200x200.jpg?alt=media&token=58d29eae-df84-4afe-b35f-f34db167127a',
        },
      },
      quantity: 21604.300000000003,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'LFLNxFDcZTbbsX59T',
    createdAt: '2022-04-16T07:09:12.887Z',
    storeItem: {
      type: {
        _id: 'gJBrYC2seLW4P35xJ',
        name: 'RIGID PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5ac6a61c-626b-46d3-9c2d-61fed42d4023_200x200.jpg?alt=media&token=fc264def-d160-4244-94cb-284107e3d8e3',
        },
      },
      quantity: 629.8,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: '7zmiNHstiofrRFibW',
    createdAt: '2022-04-16T07:59:25.071Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 550,
    },
  },
  {
    _id: 'Th2XrfGvk2J4Cgojq',
    createdAt: '2022-04-16T07:59:40.506Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2440.3000000000006,
    },
  },
  {
    _id: 'YPnee7jDiGTF9sRyB',
    createdAt: '2022-04-16T08:00:01.447Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 504.1600000000003,
    },
  },
  {
    _id: 'atQqtRE9EXvCERggB',
    createdAt: '2022-04-16T08:13:02.537Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9769,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'GKKqgWJKWndNC7mnK',
    createdAt: '2022-04-16T08:13:02.668Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2465.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'a5aSemCXsLqrowHfb',
    createdAt: '2022-04-16T08:13:02.771Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8710.54499999998,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '5MiG2nTvicf8pnzZY',
    createdAt: '2022-04-16T08:13:02.878Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 597.5,
    },
    transactionType: 'transferOut',
    amount: -3.5,
  },
  {
    _id: 'ekc5Y2nKygum5eyu5',
    createdAt: '2022-04-16T08:13:02.984Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1013.52,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: 'sjov44vWkTKgftXuM',
    createdAt: '2022-04-16T08:13:03.091Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4605.5,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'uikLc286AiJqgdZzq',
    createdAt: '2022-04-16T08:30:10.791Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F36a083ae-23ac-4407-8771-4336e2246576_200x200.jpg?alt=media&token=1306ff4d-a3a4-4218-85f5-808fbf3dff2f',
        },
      },
      quantity: 13,
    },
  },
  {
    _id: 'okxc8Hyw76w6qC2q2',
    createdAt: '2022-04-16T08:30:28.882Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'xyX3JCjJxkmWbo7EN',
        name: 'GLOVES NITRILE POWDER FREE  - MEDIUM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F063d4329-7985-45d3-bac7-1c99ee771a32_200x200.jpg?alt=media&token=0afbc2a7-f8ff-4af5-8022-88319906aa2f',
        },
      },
      quantity: 6,
    },
  },
  {
    _id: '9cYWSZnGo2thCSNbs',
    createdAt: '2022-04-16T08:32:49.945Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 36.51000000000704,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: '2iQFjeyDjkEzPo7aW',
    createdAt: '2022-04-16T08:32:50.088Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9669,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'heibFW7smozgmjEnw',
    createdAt: '2022-04-16T08:32:50.829Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2449.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -33,
  },
  {
    _id: 'mJCaWyzmFBQSxAEbu',
    createdAt: '2022-04-16T08:32:51.034Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'TjaKY2WHSiKXNCRfM',
    createdAt: '2022-04-16T08:32:51.177Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 172572.3,
    },
    transactionType: 'transferOut',
    amount: -1533,
  },
  {
    _id: 'mfb2EB8bYKc72Y63c',
    createdAt: '2022-04-16T08:32:51.298Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8709.54499999998,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'pHq3JwrzZQoEiAD2A',
    createdAt: '2022-04-16T08:32:51.445Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4585.5,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'xfmzXY3dSRKkJa3Ju',
    createdAt: '2022-04-16T13:25:48.733Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 175,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KfQzzGTbDpAMgN4T2',
    createdAt: '2022-04-16T13:25:48.874Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10424,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'oTDNKPeZ5EagdSnZv',
    createdAt: '2022-04-16T13:25:48.984Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1124,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'uKpapKKXhEs68ssTz',
    createdAt: '2022-04-16T13:26:37.083Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 163,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'r9uz7HnCotJWsyEAT',
    createdAt: '2022-04-16T13:26:37.739Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10412,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'A6ujNqbxZ99HcoJ3M',
    createdAt: '2022-04-16T13:26:38.023Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1123,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'KMuq8kadwCgqL4gHz',
    createdAt: '2022-04-17T19:19:49.218Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9669,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'KSZ7aqB73tGihsFDa',
    createdAt: '2022-04-17T19:19:49.357Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 0.5100000000070395,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RPnvoRE8KvDpTnoyJ',
    createdAt: '2022-04-17T19:19:49.471Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 935.8299999999999,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'rLnESSTKZYtNp5h8q',
    createdAt: '2022-04-17T19:19:49.584Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2416.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'EBDMi4GEjRLBTnAst',
    createdAt: '2022-04-17T19:19:49.696Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Fof73GTvJcA6gWuoZ',
    createdAt: '2022-04-17T19:19:49.809Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 529.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'v5jMENLqDKk5R6AiL',
    createdAt: '2022-04-17T19:19:49.922Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 10.650000000000006,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'fwqMBh4nf6JJeBtWN',
    createdAt: '2022-04-17T19:19:50.034Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 22.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'PXCGKb4NZQ79CbBSx',
    createdAt: '2022-04-17T19:19:50.155Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16022.400000000001,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'ceqvvzC3fZv5Eh7u5',
    createdAt: '2022-04-17T19:19:50.266Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4173,
    },
    transactionType: 'transferOut',
    amount: -115,
  },
  {
    _id: 'YFNrMvnHozBnuDwCM',
    createdAt: '2022-04-17T19:38:57.629Z',
    transactionType: 'transferIn',
    amount: 976.12,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 0.5100000000070395,
    },
  },
  {
    _id: 'BY6peMN5hYKNzKGyv',
    createdAt: '2022-04-18T00:29:27.594Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9669,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'sjGfw65j39h6pwDHj',
    createdAt: '2022-04-18T00:29:27.736Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 976.630000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '7ZjAqE2ZmEEKkESLR',
    createdAt: '2022-04-18T00:29:27.879Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 575,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'vdud8AxtdnW3Kd5Km',
    createdAt: '2022-04-18T00:29:27.991Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 524.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'bPn7k6Aq2AcLx5Km7',
    createdAt: '2022-04-18T00:29:28.104Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2480,
    },
    transactionType: 'transferOut',
    amount: -183,
  },
  {
    _id: '9x9BvNbsYmz7Ps35u',
    createdAt: '2022-04-18T00:29:28.215Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1238,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '2KvZ24KfarH2rYDrG',
    createdAt: '2022-04-18T05:48:21.451Z',
    transactionType: 'in',
    amount: 1000,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 576.630000000007,
    },
  },
  {
    _id: '5EfYvBZmvCCAuTd9s',
    createdAt: '2022-04-18T05:49:15.405Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: 'CapTSeuKh7ouMxBu7',
    createdAt: '2022-04-18T05:52:17.027Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1576.630000000007,
    },
    transactionType: 'transferOut',
    amount: -720,
  },
  {
    _id: 'fsECmEzCbyiQhD5si',
    createdAt: '2022-04-18T05:52:17.229Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9469,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '7FZ8BQePuN3nmiE4b',
    createdAt: '2022-04-18T05:52:17.338Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 435.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GmzFrH9zarSA66udv',
    createdAt: '2022-04-18T05:52:17.446Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2416.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zHtyj2rohhuudnwDS',
    createdAt: '2022-04-18T05:52:17.678Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8707.54499999998,
    },
    transactionType: 'transferOut',
    amount: -1.4,
  },
  {
    _id: 'D8TKega9KsdEd2y2d',
    createdAt: '2022-04-18T05:52:17.787Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16014.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'YdrEj7JESLR7qj4Pp',
    createdAt: '2022-04-18T05:52:17.897Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 91.53999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'HSAqgDGG46gJFrL4u',
    createdAt: '2022-04-18T05:52:18.008Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 9.850000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'tZHtqp8K7EReE5yLH',
    createdAt: '2022-04-18T05:52:18.121Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4058,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'MxgSFpWMu4BnAKBvY',
    createdAt: '2022-04-18T06:31:58.389Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 438,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'BvKwrKY5hAbMu3njH',
    createdAt: '2022-04-18T06:31:58.559Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2312.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'vHcd5Q446h9hgfdQi',
    createdAt: '2022-04-18T06:31:58.718Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 58.860000000000085,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'JMx587mTRuxhcANdY',
    createdAt: '2022-04-18T06:31:58.835Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 133.49000000000058,
    },
    transactionType: 'transferOut',
    amount: -0.4,
  },
  {
    _id: '83LLMna6oqvcnvRFN',
    createdAt: '2022-04-18T06:31:58.954Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 519.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '5cPAp38HjopJDvw6K',
    createdAt: '2022-04-18T06:31:59.071Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 172.76000000000002,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'NksD3DajNoTgny2PZ',
    createdAt: '2022-04-18T06:31:59.184Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8706.14499999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'r6Lz8xkABDNidt9zn',
    createdAt: '2022-04-18T06:31:59.298Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 594,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'vkt3xmFtjoMtCXHd7',
    createdAt: '2022-04-18T06:31:59.414Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1006.52,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'eLNYdjRFc8Ks9eE3Y',
    createdAt: '2022-04-18T06:31:59.530Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aWuYnwiydiXWtMPKJ',
    createdAt: '2022-04-18T06:31:59.649Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 24,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pFjG5kQak7KWfGcFf',
    createdAt: '2022-04-18T06:31:59.782Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1229,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'wLaPAvHRjhi2tfDEA',
    createdAt: '2022-04-18T06:33:44.211Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 358,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: '8CSGJmza4MzcrgxDT',
    createdAt: '2022-04-18T06:33:44.384Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2272.1,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: '56RxMWB3u9YbdLoSJ',
    createdAt: '2022-04-18T06:33:44.524Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.66000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '4RYs86YwLFvBdvp2P',
    createdAt: '2022-04-18T06:33:44.643Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 133.09000000000057,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'JjDbTxH7NN7umK3dW',
    createdAt: '2022-04-18T06:33:44.761Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 515.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '3uEwvGBmnmjEbegkM',
    createdAt: '2022-04-18T06:33:44.877Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 171.76000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'nzJmAneu2MEqbcdTP',
    createdAt: '2022-04-18T06:33:44.988Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8703.14499999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'GhWqaMqyqFWde5TxN',
    createdAt: '2022-04-18T06:33:45.120Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 592,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'udXLtNddEWeL8s6SA',
    createdAt: '2022-04-18T06:33:45.237Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 984.52,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'd2sKooAj6fs9pX3n9',
    createdAt: '2022-04-18T06:33:45.400Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MBawrrSRp2a4TgE2m',
    createdAt: '2022-04-18T06:33:45.519Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 24,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '8y3u5wtbZBKNcHqan',
    createdAt: '2022-04-18T06:33:45.640Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1209,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'B4HBb57wkksStk3pK',
    createdAt: '2022-04-18T06:36:18.410Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 8310,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'BWgBhitMJjEqxeae8',
    createdAt: '2022-04-18T06:36:18.516Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8701.64499999998,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'msppnvPdo7mL46Zr5',
    createdAt: '2022-04-18T06:36:18.622Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 171039.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'arCtzXZaNMbZ9NKcb',
    createdAt: '2022-04-18T06:36:18.730Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 16002.400000000001,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'Lq6S6S9ZZSqA2TPWo',
    createdAt: '2022-04-18T06:36:18.979Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 1,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: 'gYbSYBYMAKQTAfwLC',
    createdAt: '2022-04-18T06:37:10.191Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9469,
    },
    transactionType: 'transferOut',
    amount: -720,
  },
  {
    _id: 'NXim3758XGvHyp2xZ',
    createdAt: '2022-04-18T06:37:10.330Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2416.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'K39NLvSBv4qX5hiSJ',
    createdAt: '2022-04-18T06:37:10.440Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24144.1,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'MFNhDmtGMqi3R28ea',
    createdAt: '2022-04-18T06:37:10.559Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15574.099999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: '83S4HQHEiWpWCJTbT',
    createdAt: '2022-04-18T06:37:10.677Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 801,
    },
    transactionType: 'transferOut',
    amount: -105,
  },
  {
    _id: 'vMh6eQit5dAAwGqsd',
    createdAt: '2022-04-18T06:37:10.813Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4545.5,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'KrHtZTzrz5S8akdPE',
    createdAt: '2022-04-18T06:39:24.593Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 588,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wmA5yBAh5sPoNPmXS',
    createdAt: '2022-04-18T06:39:24.730Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 973.52,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'n67MsKHvmdnPtF8dg',
    createdAt: '2022-04-18T06:39:24.839Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 132.89000000000058,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'x33NeQZP4c6rPaKKa',
    createdAt: '2022-04-18T06:39:24.952Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2395.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '8F48RHvkdwR3W4YbP',
    createdAt: '2022-04-18T06:39:25.200Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 24,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'ZzPLLNAPMhhQubb8f',
    createdAt: '2022-04-18T06:44:59.793Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 7550,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'ZwhvReg5i69vSxRFW',
    createdAt: '2022-04-18T06:44:59.905Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8661.64499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'CqN6zjhWFfN4RGcSt',
    createdAt: '2022-04-18T06:45:00.026Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 168439.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'rAgsvWgHEWEcFwgua',
    createdAt: '2022-04-18T06:45:00.146Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15972.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'YKNLB7KppNbEc9BB9',
    createdAt: '2022-04-18T06:45:00.418Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 130,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: '5sAuSq9REp4ydyZJn',
    createdAt: '2022-04-18T06:45:55.179Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8749,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'sAX2DeEqdbTwv6XXm',
    createdAt: '2022-04-18T06:45:55.316Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 118.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'q5zaDRRMqebB5AbhT',
    createdAt: '2022-04-18T06:45:55.431Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 167939.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'RwfhEA9N9cqbKnx8Y',
    createdAt: '2022-04-18T06:45:55.544Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 24101.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'JuC8DqJKLjSDvCLYe',
    createdAt: '2022-04-18T06:45:55.685Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8461.64499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'KuMctE55vLS3uYJ9N',
    createdAt: '2022-04-18T06:45:55.805Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1130,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'rfsQuWNitFwnDxR8J',
    createdAt: '2022-04-18T06:45:55.916Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5548,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'QxFxqELLByCFE6SMs',
    createdAt: '2022-04-18T06:46:49.896Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 4749,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'EkkRPuxgPHAg245ZS',
    createdAt: '2022-04-18T06:46:50.029Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 114.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'eEZ7mg2yZDyuMkn5y',
    createdAt: '2022-04-18T06:46:50.139Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 165939.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'TfngTxqSQAfD7Bb9t',
    createdAt: '2022-04-18T06:46:50.248Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23851.1,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'Wn44jdvipAayuSFpt',
    createdAt: '2022-04-18T06:46:50.364Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8261.64499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'bt4KPQ96Lx9HrDgHX',
    createdAt: '2022-04-18T06:46:50.480Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 630,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'TLtAJMWuXbG3Dsu5o',
    createdAt: '2022-04-18T06:46:50.595Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5468,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'Mtd8HPbbP7ZAutj9B',
    createdAt: '2022-04-18T07:13:16.797Z',
    transactionType: 'in',
    amount: 2900,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 749,
    },
  },
  {
    _id: 'xEhZJWYPwty2mRPhJ',
    createdAt: '2022-04-18T07:13:37.861Z',
    transactionType: 'in',
    amount: 2300,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 3649,
    },
  },
  {
    _id: 'Smp4H7Zdt4mnsWob9',
    createdAt: '2022-04-18T07:14:05.670Z',
    transactionType: 'in',
    amount: 250,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 5949,
    },
  },
  {
    _id: 'rJBcDcG4AQZGyqJER',
    createdAt: '2022-04-18T07:14:37.297Z',
    transactionType: 'in',
    amount: 2200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 6199,
    },
  },
  {
    _id: 'xHHtEYnnCieq63HDK',
    createdAt: '2022-04-18T07:14:56.259Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 350,
    },
  },
  {
    _id: 'nChDgEztfv72Y3KkT',
    createdAt: '2022-04-18T07:15:17.415Z',
    transactionType: 'in',
    amount: 500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8399,
    },
  },
  {
    _id: '7aZQHo6SRxfjJR7HP',
    createdAt: '2022-04-18T07:15:36.638Z',
    transactionType: 'in',
    amount: 2250,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8899,
    },
  },
  {
    _id: 'sBLMttqofLDrtRJeX',
    createdAt: '2022-04-18T07:15:50.561Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 700,
    },
  },
  {
    _id: '7LM79gXiRjAtntXrG',
    createdAt: '2022-04-18T07:28:16.564Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11149,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '7MhsHzTocz6CgRm3D',
    createdAt: '2022-04-18T07:28:16.701Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2392.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'JsLTRrNosondcgTHf',
    createdAt: '2022-04-18T07:28:16.819Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 856.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'gS6tvJWLEYkvWwvko',
    createdAt: '2022-04-18T07:28:16.939Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 513.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'SX5fLaPpHfNxh29Dw',
    createdAt: '2022-04-18T07:28:17.056Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23601.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'GWcFzLD2zLSJSm57n',
    createdAt: '2022-04-18T07:28:17.180Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 696,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'DRLMrJcvg4LPv4Lc7',
    createdAt: '2022-04-18T07:28:17.316Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 163939.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '4EhwhwQcQNkDwMrb8',
    createdAt: '2022-04-18T07:28:17.431Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4497.5,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: '2EQFAtedLyhcjovxT',
    createdAt: '2022-04-18T07:28:55.056Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 1050,
    },
    transactionType: 'transferOut',
    amount: -105,
  },
  {
    _id: '3zvEaWmhK89t4CiSD',
    createdAt: '2022-04-18T07:28:55.176Z',
    storeItem: {
      type: {
        _id: 'Zbzyezv38M6dcg8Lx',
        name: 'Rawahy Glass Bottle',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a42d67b-b5cd-41cb-af32-6618a3d617ac_200x200.jpg?alt=media&token=1d43c43a-4ba3-4185-9eb5-731bbf7ce716',
        },
      },
      quantity: 9442,
    },
    transactionType: 'transferOut',
    amount: -102,
  },
  {
    _id: 'dwyxXZhW6325jbmGo',
    createdAt: '2022-04-18T07:28:55.287Z',
    storeItem: {
      type: {
        _id: '89q6FnCTPgT4zTw3n',
        name: 'Rawahy Creats',
        unit: 'AES',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F40ee162a-07ae-4769-a38f-d915ef7c2356_200x200.jpg?alt=media&token=f4c439a0-5ae2-479f-8439-a1c3e733774d',
        },
      },
      quantity: 786,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'hwzHj3nogXZ6kqozS',
    createdAt: '2022-04-18T07:30:25.301Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 945,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'nAhv4ftyZuibG6CjE',
    createdAt: '2022-04-18T07:30:25.443Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10387,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'vczr8dd7Edqhi87DQ',
    createdAt: '2022-04-18T07:30:25.559Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4885,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '6b6rPkE7i6rLZKnFG',
    createdAt: '2022-04-18T07:30:25.673Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1121,
    },
    transactionType: 'transferOut',
    amount: -7,
  },
  {
    _id: '42puMPdaBB2XnQWFu',
    createdAt: '2022-04-18T07:31:21.563Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 855,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'itPyMxmXeS6CAPDj8',
    createdAt: '2022-04-18T07:31:21.723Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10310,
    },
    transactionType: 'transferOut',
    amount: -48,
  },
  {
    _id: 'En6c8xhrgaS4oJATR',
    createdAt: '2022-04-18T07:31:21.840Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1114,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'zWZg96NT3TtmcKjs3',
    createdAt: '2022-04-18T07:32:37.179Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10899,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'uYqkHxvyjtxgpKSEa',
    createdAt: '2022-04-18T07:32:37.314Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2387.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'A3qiHejGQCGrr7sHh',
    createdAt: '2022-04-18T07:32:37.429Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 606.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'TCmqR43PAnTYPWvJN',
    createdAt: '2022-04-18T07:32:37.539Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 263.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'umEzZtGNWztv7DkdS',
    createdAt: '2022-04-18T07:32:37.649Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23574.1,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'shWJE9qFJBhsCk5Z3',
    createdAt: '2022-04-18T07:32:37.763Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 596,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'DsyMWMcwEEu8uPt9S',
    createdAt: '2022-04-18T07:32:37.874Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 162939.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'q5Cx7RvBWXFiTHxYB',
    createdAt: '2022-04-18T07:32:37.983Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4454.5,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'yod4KWus2xsAycWrE',
    createdAt: '2022-04-18T07:35:43.019Z',
    transactionType: 'in',
    amount: 2,
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 1.1000000000000019,
    },
  },
  {
    _id: 'pKLbfKyENQxhP8HwN',
    createdAt: '2022-04-18T07:48:22.697Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 805,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'GFfCT8pRaRpt98Jj8',
    createdAt: '2022-04-18T07:48:22.806Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 3.100000000000002,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'txPx7JL2C4WpecSnF',
    createdAt: '2022-04-18T07:48:23.077Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 139,
    },
    transactionType: 'in',
    amount: 400,
  },
  {
    _id: 'xFd5EBZQj833wWpnz',
    createdAt: '2022-04-18T07:49:04.770Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 539,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'vB4d4RSnrNb7Jp2TY',
    createdAt: '2022-04-18T07:49:04.914Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10262,
    },
    transactionType: 'transferOut',
    amount: -101,
  },
  {
    _id: 'DnTJorM8TyfdaJpFF',
    createdAt: '2022-04-18T07:49:05.037Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1110,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'CdPtszRrzYECzNJ48',
    createdAt: '2022-04-18T07:50:07.629Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 439,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '489ihdkbDomv9ftmP',
    createdAt: '2022-04-18T07:50:07.777Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10161,
    },
    transactionType: 'transferOut',
    amount: -151,
  },
  {
    _id: 'nCaNm5tkpakYRwcer',
    createdAt: '2022-04-18T07:50:07.909Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1101,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'eh8QLzopD8xuLFibq',
    createdAt: '2022-04-18T08:58:59.450Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8061.64499999998,
    },
  },
  {
    _id: 'guB3wkoDGNRdJb7A4',
    createdAt: '2022-04-18T08:59:16.472Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2382.3000000000006,
    },
  },
  {
    _id: '4AW4YdQCwsuZjDBX8',
    createdAt: '2022-04-18T08:59:34.982Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5388,
    },
  },
  {
    _id: 'GtqFp3zEAD8yjYkWe',
    createdAt: '2022-04-18T09:00:03.040Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4010,
    },
  },
  {
    _id: 'hSQPGg9G6Bs8h8AR6',
    createdAt: '2022-04-18T09:00:21.827Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4411.5,
    },
  },
  {
    _id: 'ieTwD5y3ZtEjmFzFB',
    createdAt: '2022-04-18T09:00:37.441Z',
    transactionType: 'transferIn',
    amount: 45,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1199,
    },
  },
  {
    _id: 'F5HATyThF9AfmpoKB',
    createdAt: '2022-04-18T09:00:57.845Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1088,
    },
  },
  {
    _id: 'YSS5T2h25LRtMTmco',
    createdAt: '2022-04-18T09:01:15.437Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 830,
    },
  },
  {
    _id: 'bjJGBTdGcyghffuF4',
    createdAt: '2022-04-18T09:01:47.413Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 956.52,
    },
  },
  {
    _id: '3Hsdi3MRpRKnfSNs8',
    createdAt: '2022-04-18T09:20:03.807Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10649,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'pDvZ7MkfPA8Ncghky',
    createdAt: '2022-04-18T09:20:03.952Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2457.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'aqfkxBiCZAwWytHTx',
    createdAt: '2022-04-18T09:20:04.065Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23538.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'Aus6T9ubGfcAy4D6e',
    createdAt: '2022-04-18T09:20:04.179Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15549.099999999999,
    },
    transactionType: 'transferOut',
    amount: -25,
  },
  {
    _id: 'evewPfXyNygrBNKW3',
    createdAt: '2022-04-18T09:20:04.295Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 496,
    },
    transactionType: 'transferOut',
    amount: -120,
  },
  {
    _id: 'sgNMqftP4tvqFpnwC',
    createdAt: '2022-04-18T09:20:04.413Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4611.5,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'TG5q8HD238XqTs8ej',
    createdAt: '2022-04-18T10:27:05.034Z',
    transactionType: 'transferIn',
    amount: 1000,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 435.8299999999999,
    },
  },
  {
    _id: 'dwnfN5exm2BDuNX5k',
    createdAt: '2022-04-18T10:27:47.275Z',
    transactionType: 'transferIn',
    amount: 2458,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 356.63000000000693,
    },
  },
  {
    _id: 'KBv3jmZNKsuxf9nsL',
    createdAt: '2022-04-18T13:06:30.509Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 289,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'hJ8p8MasKEF8oSJPD',
    createdAt: '2022-04-18T13:06:30.663Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10010,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: '8tvcPPFHgsRs4QEXi',
    createdAt: '2022-04-18T13:06:30.785Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1163,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '4TPtFyQ854rtRorN7',
    createdAt: '2022-04-18T13:07:03.770Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 265,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'w7DxD33p86d2XRggB',
    createdAt: '2022-04-18T13:07:03.907Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9986,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'Wug9FAkZm3YRco9Kb',
    createdAt: '2022-04-18T13:07:04.023Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1161,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'mktqLws8oECw7P4Bm',
    createdAt: '2022-04-18T13:07:59.776Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 241,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'amSpaJsuQXrrMPYnE',
    createdAt: '2022-04-18T13:07:59.907Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4883,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'sz6RHNmHCbDba6y4f',
    createdAt: '2022-04-18T13:08:00.018Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1159,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'pqCiXRXnG6N2NHK8B',
    createdAt: '2022-04-18T13:48:19.620Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9999,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'gkrNs73ikPumAQsBE',
    createdAt: '2022-04-18T13:48:19.754Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2814.630000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'h8CAjF8G6MtmCdSBt',
    createdAt: '2022-04-18T13:48:19.863Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 525,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'ptxNo9P2CjWi2QdrH',
    createdAt: '2022-04-18T13:48:19.971Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 260.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'moXcKyvbXDyKkoLb5',
    createdAt: '2022-04-18T13:48:20.081Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2297,
    },
    transactionType: 'transferOut',
    amount: -182,
  },
  {
    _id: 'X3xsGw3PMR8qPRwns',
    createdAt: '2022-04-18T13:48:20.187Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1244,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'C5GuNAXMmB6XaXS6a',
    createdAt: '2022-04-18T18:58:56.224Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9799,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'DZXHhfeRde8gJHEYE',
    createdAt: '2022-04-18T18:58:56.357Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2433.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'HGAuh3DBbvkz4aahF',
    createdAt: '2022-04-18T18:58:56.468Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2414.630000000007,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'vqXPJ7keiyRdMtj9L',
    createdAt: '2022-04-18T18:58:56.580Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 255.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'JPeWH8ZZCXiHbKssp',
    createdAt: '2022-04-18T18:58:56.686Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23508.1,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'z8P8BLE46ymiCT2ge',
    createdAt: '2022-04-18T18:58:56.799Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 376,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'R3Fr7py67omB9zi35',
    createdAt: '2022-04-18T18:58:56.919Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 161939.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'vp64P7aYfRfJsbun9',
    createdAt: '2022-04-18T18:58:57.056Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4566.5,
    },
    transactionType: 'transferOut',
    amount: -43,
  },
  {
    _id: 'XhWvT2Qupmj9YdyC3',
    createdAt: '2022-04-19T03:18:34.396Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2164.630000000007,
    },
    transactionType: 'transferOut',
    amount: -310,
  },
  {
    _id: 'JMBEKnR5wsDbKQwYg',
    createdAt: '2022-04-19T03:18:34.537Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1435.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wK52WBq4XSzXHojJF',
    createdAt: '2022-04-19T03:18:34.647Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'H9JyJAJjCwwqkJPes',
    createdAt: '2022-04-19T03:18:34.766Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2428.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'oLQX3Dhr88zn5z76D',
    createdAt: '2022-04-19T03:18:34.885Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'syAsDki3GLaFsaRGJ',
    createdAt: '2022-04-19T03:18:34.999Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15972.400000000001,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Y7nHG7yqTbD8Mc5n9',
    createdAt: '2022-04-19T03:18:35.131Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'madoa54N4AD4GbjE2',
    createdAt: '2022-04-19T03:18:35.253Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 8.850000000000005,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'wCreDRSXESdMSnurk',
    createdAt: '2022-04-19T03:18:35.365Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 91.03999999999999,
    },
    transactionType: 'transferOut',
    amount: -0.3,
  },
  {
    _id: 'wnXEYAcPySRBSfYi5',
    createdAt: '2022-04-19T03:18:35.478Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 253.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'CACgky5fyQi6fnsB3',
    createdAt: '2022-04-19T03:18:35.592Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8311.64499999998,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'QK8GFjSYS7uLj7YLH',
    createdAt: '2022-04-19T03:18:35.708Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5478,
    },
    transactionType: 'transferOut',
    amount: -29,
  },
  {
    _id: 'SNew8mAxFZ8vZnTnd',
    createdAt: '2022-04-19T03:21:41.121Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qLSvD4FEkKH3rGvi9',
    createdAt: '2022-04-19T03:21:41.260Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1435.83,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'eGLDke9MynRGqXeRG',
    createdAt: '2022-04-19T03:21:41.371Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1854.630000000007,
    },
    transactionType: 'transferOut',
    amount: -210,
  },
  {
    _id: 'wX2kF6F3zWvhM9Nmj',
    createdAt: '2022-04-19T03:21:41.480Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2428.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'x4caTJiy8TBkXAHFG',
    createdAt: '2022-04-19T03:21:41.587Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1261.8,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'aYHYiN4GT5kvrGjbj',
    createdAt: '2022-04-19T03:21:41.702Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15524.099999999999,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'XzQiTvy8Ws7oD7eip',
    createdAt: '2022-04-19T03:21:41.813Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160939.3,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'stwYytoD5fEMq97p5',
    createdAt: '2022-04-19T03:21:41.921Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23481.1,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'w3WcMcoSgi8JxNuGw',
    createdAt: '2022-04-19T03:21:42.032Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4523.5,
    },
    transactionType: 'transferOut',
    amount: -14,
  },
  {
    _id: 'BvfwuPZBJFHPfaRTz',
    createdAt: '2022-04-19T03:31:19.361Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QRwjRsf4P4prbcCs8',
    createdAt: '2022-04-19T03:31:19.522Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1644.630000000007,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'mMo24yRHBonnphTGR',
    createdAt: '2022-04-19T03:31:19.633Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 1435.83,
    },
    transactionType: 'transferOut',
    amount: -750,
  },
  {
    _id: 'ZrQdcNkWYHzgmQ2D9',
    createdAt: '2022-04-19T03:31:19.742Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2422.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'gfM5yPQwTbJE5JffA',
    createdAt: '2022-04-19T03:31:19.852Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 94,
    },
    transactionType: 'transferOut',
    amount: -35,
  },
  {
    _id: '5y77MofCERu7p8HGe',
    createdAt: '2022-04-19T03:31:19.972Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23471.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'C4ijM3Kz8Qu3JNLCL',
    createdAt: '2022-04-19T03:31:20.084Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160829.3,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'yNb3cpyAThvF3z9Sh',
    createdAt: '2022-04-19T03:31:20.191Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1256.8,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'QpeJ5NPchSZSPSJD8',
    createdAt: '2022-04-19T03:31:20.305Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15515.099999999999,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'nG8AnqZ2myQszEY37',
    createdAt: '2022-04-19T03:31:20.415Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15967.400000000001,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'm62zLkEgyn9LefkcB',
    createdAt: '2022-04-19T03:31:20.528Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 276,
    },
    transactionType: 'transferOut',
    amount: -160,
  },
  {
    _id: 'pSHMAFCzvtCXx5PXA',
    createdAt: '2022-04-19T03:31:20.636Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4509.5,
    },
    transactionType: 'transferOut',
    amount: -61,
  },
  {
    _id: 't8Eqjbovwq4e8LrcN',
    createdAt: '2022-04-19T04:47:07.073Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LXJNnYTcRunkqMvdn',
    createdAt: '2022-04-19T04:47:07.211Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1644.630000000007,
    },
    transactionType: 'transferOut',
    amount: -814,
  },
  {
    _id: 'AYp3npetCodzQFPbd',
    createdAt: '2022-04-19T04:47:07.324Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'MFoJHCQTGi4jPm7JM',
    createdAt: '2022-04-19T04:47:07.440Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2398.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Y8x5S3bHDXtEmmt3e',
    createdAt: '2022-04-19T04:47:07.558Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'F892HcpsfsNHxYyTN',
    createdAt: '2022-04-19T04:47:07.673Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 250.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'BEQh2RXRwG9pmHe3e',
    createdAt: '2022-04-19T04:47:07.782Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 8.250000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'YhM2qhTfywwSAkYTA',
    createdAt: '2022-04-19T04:47:07.902Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 22.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'LgKcLadgLTAKLXna5',
    createdAt: '2022-04-19T04:47:08.028Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15952.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'SijDmiM5RfjFAJwA4',
    createdAt: '2022-04-19T04:47:08.147Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4210,
    },
    transactionType: 'transferOut',
    amount: -185,
  },
  {
    _id: 'tR8ANcZPujQG8D4aw',
    createdAt: '2022-04-19T05:01:29.750Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'sxKWRYfr5xjDGa7P4',
    createdAt: '2022-04-19T05:01:29.892Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 830.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: 'jNAdv8tup9burPJd8',
    createdAt: '2022-04-19T05:01:30.006Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '5FH39WvAmHzPwmAKr',
    createdAt: '2022-04-19T05:01:30.124Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2398.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: '2NM8HFXbZ369BdXAr',
    createdAt: '2022-04-19T05:01:30.237Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23431.1,
    },
    transactionType: 'transferOut',
    amount: -7.8,
  },
  {
    _id: 'Dq3Bu2y6TFwPWBkAz',
    createdAt: '2022-04-19T05:01:30.353Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160429.3,
    },
    transactionType: 'transferOut',
    amount: -75,
  },
  {
    _id: 'mzmhW9D99yguxckkS',
    createdAt: '2022-04-19T05:01:30.465Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15475.099999999999,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'gnsvnme33Qz4Ac4cf',
    createdAt: '2022-04-19T05:01:30.584Z',
    storeItem: {
      type: {
        _id: 'HjGxZjLRDG7DwmP4H',
        name: 'MICROWAVE CONTAINER 525 CC.',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2010c5e5-1fe4-4ec8-a9ce-21ddd82b6edf_200x200.jpg?alt=media&token=afed5539-1309-41ad-9ab9-7ad4cce9cea8',
        },
      },
      quantity: 1424,
    },
    transactionType: 'transferOut',
    amount: -52,
  },
  {
    _id: '98LFuH293p2xrCCa4',
    createdAt: '2022-04-19T05:13:35.981Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 700.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: '527tRMTx6JS8izKA2',
    createdAt: '2022-04-19T05:13:36.118Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 685.8299999999999,
    },
    transactionType: 'transferOut',
    amount: -220,
  },
  {
    _id: 'HaLLNzGPtb7kdCnmC',
    createdAt: '2022-04-19T05:13:36.226Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wu3kwYGjfN9BEQxGp',
    createdAt: '2022-04-19T05:13:36.338Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2394.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -16,
  },
  {
    _id: 'eGjKvgkLmqrpRiSAP',
    createdAt: '2022-04-19T05:13:36.444Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23423.3,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'xHZ6wtdnEnSFdD7gg',
    createdAt: '2022-04-19T05:13:36.558Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160354.3,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'we5Cd2rjT4ZzaBXTC',
    createdAt: '2022-04-19T05:13:36.669Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15470.099999999999,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'G26QwrooeQxnYDD5Y',
    createdAt: '2022-04-19T05:13:36.774Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1236.8,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'dkHXSNFcNnzLtGvci',
    createdAt: '2022-04-19T05:13:36.894Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 116,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'o4FEEAtD3LYQgNHWy',
    createdAt: '2022-04-19T05:13:37.009Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4448.5,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'EtmPkS3sEywFGT9Yi',
    createdAt: '2022-04-19T05:19:36.483Z',
    transactionType: 'in',
    amount: 2900,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9549,
    },
  },
  {
    _id: 'gGKhQoPdxQfgR56CD',
    createdAt: '2022-04-19T05:19:59.915Z',
    transactionType: 'in',
    amount: 1650,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12449,
    },
  },
  {
    _id: 'G9cEKFsaeouBCaW2b',
    createdAt: '2022-04-19T05:20:22.840Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 405,
    },
  },
  {
    _id: 'a4Ywbu2DgtKdpNcKo',
    createdAt: '2022-04-19T06:43:51.174Z',
    transactionType: 'transferIn',
    amount: 1250,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 400.63000000000693,
    },
  },
  {
    _id: 'E3DQHinypw4uFnSe5',
    createdAt: '2022-04-19T06:45:34.915Z',
    transactionType: 'transferIn',
    amount: 380,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1650.630000000007,
    },
  },
  {
    _id: '3fsyYKyPqpxPGgdv3',
    createdAt: '2022-04-19T06:49:40.256Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 6750,
    },
    transactionType: 'transferOut',
    amount: -760,
  },
  {
    _id: 'ShHQfToyN4kNQAWfN',
    createdAt: '2022-04-19T06:49:40.368Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8311.44499999998,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'q9my7HeroyZQzCeLD',
    createdAt: '2022-04-19T06:49:40.486Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 160104.3,
    },
    transactionType: 'transferOut',
    amount: -2600,
  },
  {
    _id: 'MWap2WKS4BxPYecHM',
    createdAt: '2022-04-19T06:49:40.598Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15940.400000000001,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'o35o7QQKdDHQCTeEf',
    createdAt: '2022-04-19T06:49:40.852Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 6,
    },
    transactionType: 'in',
    amount: 800,
  },
  {
    _id: '9gmmqvHJGGD9Ht8bB',
    createdAt: '2022-04-19T06:56:33.147Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 439.34999999999997,
    },
    transactionType: 'transferOut',
    amount: -24.5,
  },
  {
    _id: 'tDWPLGYTuHmgJkcp3',
    createdAt: '2022-04-19T06:56:33.309Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 184.54999999999995,
    },
    transactionType: 'transferOut',
    amount: -9.1,
  },
  {
    _id: '9CSYA596s5vQZGLZa',
    createdAt: '2022-04-19T06:56:33.443Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 130.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'btPdLSnAxqmBr67Lo',
    createdAt: '2022-04-19T06:56:33.581Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4407.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'QofzrdqpKLvh4mf9J',
    createdAt: '2022-04-19T06:56:33.707Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5449,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '2MYXhGCSa994BFWcL',
    createdAt: '2022-04-19T07:18:33.871Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 14099,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'S23cWcs3eFGYAXYga',
    createdAt: '2022-04-19T07:18:34.005Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 2030.630000000007,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'jxrr4pQLPaQaJEnz8',
    createdAt: '2022-04-19T07:18:34.116Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 465.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'N8R28NtJjaMooipAM',
    createdAt: '2022-04-19T07:18:34.228Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2378.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tbz2PetZ9CJs8myxP',
    createdAt: '2022-04-19T07:18:34.340Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1226.8,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Bq9QFR9y63E39mvHF',
    createdAt: '2022-04-19T07:18:34.488Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15450.099999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'Pa2mAm5cACBYTsTpQ',
    createdAt: '2022-04-19T07:18:34.602Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 157504.3,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '7fXjFTSLp22oqeZKb',
    createdAt: '2022-04-19T07:18:34.719Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23393.3,
    },
    transactionType: 'transferOut',
    amount: -36,
  },
  {
    _id: 'iXKMrZ9cvwTQ8Wqfp',
    createdAt: '2022-04-19T07:18:34.833Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 806,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Bjk4D28wxPPjvQsuW',
    createdAt: '2022-04-19T07:18:34.950Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4395.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'HG2wouZNrD9wsbSoy',
    createdAt: '2022-04-19T07:18:35.064Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5449,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'vjvoixnCKQgp6JAux',
    createdAt: '2022-04-19T07:33:21.791Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4395.5,
    },
  },
  {
    _id: 'wDfSf3ftD5uxMq7F8',
    createdAt: '2022-04-19T07:33:49.977Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4025,
    },
  },
  {
    _id: 'EGwQ3yzH3AJ6BqwSm',
    createdAt: '2022-04-19T07:34:09.742Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5423,
    },
  },
  {
    _id: 'PdNebDPqSP9SyJ6gx',
    createdAt: '2022-04-19T07:34:33.057Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8271.44499999998,
    },
  },
  {
    _id: 'h78A2FTzrGk2h3ngn',
    createdAt: '2022-04-19T07:34:55.133Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2378.3000000000006,
    },
  },
  {
    _id: 'EdypBCTeY66dLxGvq',
    createdAt: '2022-04-19T07:35:23.344Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2115,
    },
  },
  {
    _id: 'f8zGgELE2KJE558KQ',
    createdAt: '2022-04-19T07:35:45.693Z',
    transactionType: 'transferIn',
    amount: 25000,
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 157104.3,
    },
  },
  {
    _id: 'uzgi3hGMwnsh7mCua',
    createdAt: '2022-04-19T07:36:01.391Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 475,
    },
  },
  {
    _id: 'Kraj77owkdBaQQ4Se',
    createdAt: '2022-04-19T07:36:24.625Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 124.5,
    },
  },
  {
    _id: '7n8YfK98Nh2jrRv6D',
    createdAt: '2022-04-19T07:36:39.971Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 175.44999999999996,
    },
  },
  {
    _id: 'uczt3sizGReQZ3G7M',
    createdAt: '2022-04-19T07:37:10.002Z',
    transactionType: 'transferIn',
    amount: 4,
    storeItem: {
      type: {
        _id: 'wiLfZFtvW2aKxobKi',
        name: 'FACE MASK',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe357e5dc-766d-4518-9868-05f7651d9abb_200x200.jpg?alt=media&token=2dd741df-52c9-4d15-9d03-5e2aa74ce4dd',
        },
      },
      quantity: 116,
    },
  },
  {
    _id: 'aZq5cACsupk2Xb5SJ',
    createdAt: '2022-04-19T08:35:47.386Z',
    transactionType: 'transferIn',
    amount: 640,
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 9962,
    },
  },
  {
    _id: 't7Ki5rwPFcRpRnkwz',
    createdAt: '2022-04-19T08:37:58.144Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe087aa06-9bbe-4765-90e8-807e6bd3e88b_200x200.jpg?alt=media&token=07849047-5931-407a-9fca-50e2923b7bbf',
        },
      },
      quantity: 9,
    },
  },
  {
    _id: '2wwqBuGpARMgsWpyi',
    createdAt: '2022-04-19T11:56:28.777Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13949,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DGLokEnkyTi7y4YMk',
    createdAt: '2022-04-19T11:56:28.913Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1880.630000000007,
    },
    transactionType: 'transferOut',
    amount: -810,
  },
  {
    _id: 'LhxQoAp7wefs33kEH',
    createdAt: '2022-04-19T11:56:29.031Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 465.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fwuuL6yxpkc4FcGFW',
    createdAt: '2022-04-19T11:56:29.177Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2583.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'dmv7ERvM986FD4TRT',
    createdAt: '2022-04-19T11:56:29.298Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'qS43NW9fmBqHssBsw',
    createdAt: '2022-04-19T11:56:29.425Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 492.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: '5o2FSyfxTCxNMmt7Z',
    createdAt: '2022-04-19T11:56:29.552Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 7.250000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'SrrpDQ6WMbsFTXWKs',
    createdAt: '2022-04-19T11:56:29.669Z',
    storeItem: {
      type: {
        _id: 'irQWZEbi7omnuRruz',
        name: ' YOFLEX@EXPRESS 1.0',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff0e9228b-d89f-46f2-8796-35a9a14a75c5_200x200.jpg?alt=media&token=3bc19ba3-bcf4-4a78-a131-de9b5d206808',
        },
      },
      quantity: 22.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GzcqARAcbKtCi3uES',
    createdAt: '2022-04-19T11:56:29.785Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15910.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'dnYjtTLWZFEKZ3XCw',
    createdAt: '2022-04-19T11:56:29.922Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4225,
    },
    transactionType: 'transferOut',
    amount: -184,
  },
  {
    _id: 'ZaKdbBDsqMGwBu38J',
    createdAt: '2022-04-19T12:01:56.936Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1070.630000000007,
    },
    transactionType: 'transferOut',
    amount: -26,
  },
  {
    _id: 'Szcch2atdfv9gv7K5',
    createdAt: '2022-04-19T12:01:57.089Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13949,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'zMZSAg6XWBxRTJQYX',
    createdAt: '2022-04-19T12:01:57.209Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2583.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '6pDmW3f5qLaCbMq94',
    createdAt: '2022-04-19T12:01:57.334Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 59,
    },
    transactionType: 'transferOut',
    amount: -12.5,
  },
  {
    _id: 'veodbvuiXvKjSp2YZ',
    createdAt: '2022-04-19T12:01:57.463Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 183104.3,
    },
    transactionType: 'transferOut',
    amount: -410,
  },
  {
    _id: 'eRWpBExXsQSBKdypv',
    createdAt: '2022-04-19T12:01:57.587Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8646.44499999998,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'BttrmnPtaAZ2BdKwK',
    createdAt: '2022-04-19T12:01:57.701Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4638.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'f2myuANRdqidct2xA',
    createdAt: '2022-04-19T12:04:45.229Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13919,
    },
    transactionType: 'transferOut',
    amount: -64,
  },
  {
    _id: 'pC9gnCZGpxqsdD9FT',
    createdAt: '2022-04-19T12:04:45.375Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 1044.630000000007,
    },
    transactionType: 'transferOut',
    amount: -96,
  },
  {
    _id: 'ewzZE7TaoDySvN39g',
    createdAt: '2022-04-19T12:04:45.490Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2574.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -32,
  },
  {
    _id: 'vzWPMsC3NqCZD9fLa',
    createdAt: '2022-04-19T12:04:45.613Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 46.5,
    },
    transactionType: 'transferOut',
    amount: -46,
  },
  {
    _id: 'yC36Pj8EEtSvoEgcm',
    createdAt: '2022-04-19T12:04:45.742Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 182694.3,
    },
    transactionType: 'transferOut',
    amount: -1450,
  },
  {
    _id: 'wTjqZZKytzoppuwKn',
    createdAt: '2022-04-19T12:04:45.864Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8645.94499999998,
    },
    transactionType: 'transferOut',
    amount: -1.9,
  },
  {
    _id: 'Q3ZiGi9D5m4iqtRuY',
    createdAt: '2022-04-19T12:04:45.980Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 577.5,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'PSJm7MxeqTwQMEd2e',
    createdAt: '2022-04-19T12:04:46.860Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 2014,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'iomwhno4YZB63c2Jv',
    createdAt: '2022-04-19T12:07:10.086Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 318,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'sisiFpPWPaFt88PZh',
    createdAt: '2022-04-19T12:07:10.261Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2252.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'P5J5XTr3xfu6MFCgh',
    createdAt: '2022-04-19T12:07:10.398Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 57.06000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: '7GWfqwi4zeNorB3re',
    createdAt: '2022-04-19T12:07:10.558Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 132.64000000000058,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'nbJfdPX4CGXk58Lkp',
    createdAt: '2022-04-19T12:07:10.701Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 484.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '4hNv83rM2mym75Tta',
    createdAt: '2022-04-19T12:07:10.817Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 171.26000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'd5C2DT544u5asePzP',
    createdAt: '2022-04-19T12:07:10.943Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8644.04499999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'DndNBwjko9KubWpEG',
    createdAt: '2022-04-19T12:07:11.060Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 578,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'pokJhjCSAFRX7m6BR',
    createdAt: '2022-04-19T12:07:11.177Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1016.52,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'X86GZucsPvgkBNsyg',
    createdAt: '2022-04-19T12:07:11.290Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'fqrJMbTTwBTr3GGTa',
    createdAt: '2022-04-19T12:07:11.404Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'd9qtyThZWSW3zA4Xs',
    createdAt: '2022-04-19T12:07:11.528Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1235,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: '7CoLw4RnX29XamcMr',
    createdAt: '2022-04-19T12:07:42.294Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 298,
    },
    transactionType: 'transferOut',
    amount: -20,
  },
  {
    _id: 'QHE8wt8ids5NsWR5B',
    createdAt: '2022-04-19T12:07:42.454Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2212.1,
    },
    transactionType: 'transferOut',
    amount: -40,
  },
  {
    _id: 'vSAicYLQtpWDrZ5Bf',
    createdAt: '2022-04-19T12:07:42.577Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 56.46000000000008,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'xjk3fBGHRspWZqCCF',
    createdAt: '2022-04-19T12:07:42.695Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 132.14000000000058,
    },
    transactionType: 'transferOut',
    amount: -0.2,
  },
  {
    _id: 'Cvjj4YHJetmdpKX9n',
    createdAt: '2022-04-19T12:07:42.815Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 482.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: '8TmWxWcoW9HDkhMjC',
    createdAt: '2022-04-19T12:07:42.925Z',
    storeItem: {
      type: {
        _id: 'qAYN65FwkwCzhtAJ4',
        name: 'CELLULOSE FIBER PF90   20KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F48e62cf1-013b-44f4-844e-2e13be45e2e7_200x200.jpg?alt=media&token=2182be8c-9a3a-4f9b-ae0c-53d142b868d5',
        },
      },
      quantity: 170.76000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'PWFrT5LrsnELNn8ra',
    createdAt: '2022-04-19T12:07:43.039Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8642.54499999998,
    },
    transactionType: 'transferOut',
    amount: -1.5,
  },
  {
    _id: 'njx4dSikrRnisvHfj',
    createdAt: '2022-04-19T12:07:43.164Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 578,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FRZB7Hr38bTFDCdYX',
    createdAt: '2022-04-19T12:07:43.279Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1005.52,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: '4cKjZZYEEsyuKwwvh',
    createdAt: '2022-04-19T12:07:43.390Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'upxQeqSDqP6foj4oy',
    createdAt: '2022-04-19T12:07:43.504Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'WDncEYs3p3cWTsoFt',
    createdAt: '2022-04-19T12:07:43.647Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1226,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'wMXSmtDvN3xSucKJd',
    createdAt: '2022-04-19T12:09:12.383Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 578,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'bii2xN4g5fk6WxdtM',
    createdAt: '2022-04-19T12:09:12.519Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 994.52,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: '66XFZtASJ25cpLXEP',
    createdAt: '2022-04-19T12:09:12.628Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 131.9400000000006,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'D3LEq7KSzN5PSoWy9',
    createdAt: '2022-04-19T12:09:12.742Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2542.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'xoTEpCukMeTMq8imi',
    createdAt: '2022-04-19T12:09:12.991Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'fkrSieYmem8uxoX2m',
    createdAt: '2022-04-19T12:10:22.918Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 948.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'rKKqNRsARMQ5dXqMp',
    createdAt: '2022-04-19T12:10:23.058Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13855,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '8vPxyCkgPCwdm3FcL',
    createdAt: '2022-04-19T12:10:23.173Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2539.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'PAZ4JKzE3ttkKnDHE',
    createdAt: '2022-04-19T12:10:23.287Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70.5,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'ymeKCHJv8884CkXwb',
    createdAt: '2022-04-19T12:10:23.431Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8641.04499999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '5C3NtKx7WiTDESwpA',
    createdAt: '2022-04-19T12:10:23.544Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 181244.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'stdSxnyAYfev7HtwY',
    createdAt: '2022-04-19T12:10:23.657Z',
    storeItem: {
      type: {
        _id: 'xwdhRuQq3W2mfFwpZ',
        name: 'VACUUM PLASTIC TOP',
        unit: 'MTR.',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4089a0d6-cab6-4bf0-8f41-1593417f015f_200x200.jpg?alt=media&token=1a6f4343-89c6-4fc0-b234-57c4c45ba31d',
        },
      },
      quantity: 562.5,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Nd7qJppzdf2g8kxTb',
    createdAt: '2022-04-19T12:10:23.771Z',
    storeItem: {
      type: {
        _id: 'sbsbynwNA7PpjY2zb',
        name: 'VACUUM PLASTIC BOTTOM',
        unit: 'MTR',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F4aea56c3-b2e8-4a8f-8900-03c9d9d23be3_200x200.jpg?alt=media&token=6ca0e5d4-8266-4703-a95b-ceee2a00aa36',
        },
      },
      quantity: 1999,
    },
    transactionType: 'transferOut',
    amount: -22,
  },
  {
    _id: 'Z2ZecxgGArnHJSpN2',
    createdAt: '2022-04-19T12:10:23.889Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1216,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'roraYJYgs3r3EthRh',
    createdAt: '2022-04-19T12:36:09.426Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13705,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'KNmZQH9AuTYwy5eAT',
    createdAt: '2022-04-19T12:36:09.570Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a43aa1c-8e56-4de5-859e-e255f157d16e_200x200.jpg?alt=media&token=069a0e1a-b453-47f8-9a63-75838710480b',
        },
      },
      quantity: 848.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'qz6mvqJTo2vej5E8n',
    createdAt: '2022-04-19T12:36:09.684Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 525,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'vXoBepqFuwkZaSbgc',
    createdAt: '2022-04-19T12:36:09.795Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 480.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'hEDTp2cHnvCfNniPo',
    createdAt: '2022-04-19T12:36:09.927Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2615,
    },
    transactionType: 'transferOut',
    amount: -183,
  },
  {
    _id: 'jdQqQ3FkuNmNffxhP',
    createdAt: '2022-04-19T12:36:10.052Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1201,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'KKirJFfX5p2xA3C5u',
    createdAt: '2022-04-20T03:30:12.203Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 755,
    },
    transactionType: 'transferOut',
    amount: -130,
  },
  {
    _id: '9Azgrsc7dng8ZPvKj',
    createdAt: '2022-04-20T03:30:12.331Z',
    storeItem: {
      type: {
        _id: 'Zbzyezv38M6dcg8Lx',
        name: 'Rawahy Glass Bottle',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8a42d67b-b5cd-41cb-af32-6618a3d617ac_200x200.jpg?alt=media&token=1d43c43a-4ba3-4185-9eb5-731bbf7ce716',
        },
      },
      quantity: 9340,
    },
    transactionType: 'transferOut',
    amount: -124,
  },
  {
    _id: 'wa3tTCppvSsqWvsMC',
    createdAt: '2022-04-20T03:30:12.504Z',
    storeItem: {
      type: {
        _id: '89q6FnCTPgT4zTw3n',
        name: 'Rawahy Creats',
        unit: 'AES',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F40ee162a-07ae-4769-a38f-d915ef7c2356_200x200.jpg?alt=media&token=f4c439a0-5ae2-479f-8439-a1c3e733774d',
        },
      },
      quantity: 777,
    },
    transactionType: 'transferOut',
    amount: -11,
  },
  {
    _id: 'PFmZ6pCzr9jyAQihX',
    createdAt: '2022-04-20T04:53:26.332Z',
    transactionType: 'transferIn',
    amount: 500,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 448.63000000000693,
    },
  },
  {
    _id: 'zwjj22LAPQupmyNwg',
    createdAt: '2022-04-20T04:57:49.435Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 414.84999999999997,
    },
    transactionType: 'transferOut',
    amount: -20.4,
  },
  {
    _id: '5ZRxJSTSNahA237Gb',
    createdAt: '2022-04-20T04:57:49.606Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 205.44999999999996,
    },
    transactionType: 'transferOut',
    amount: -7.6,
  },
  {
    _id: 'BBdD35sKK7o4qyGFu',
    createdAt: '2022-04-20T04:57:49.731Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 136.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'CKvCHKvcHJjWSKGBt',
    createdAt: '2022-04-20T04:57:49.844Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4628.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'dFCXpLRHEdchQucGQ',
    createdAt: '2022-04-20T04:59:37.990Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 625,
    },
    transactionType: 'transferOut',
    amount: -96,
  },
  {
    _id: 'StqhFoJvcAxHE8Xeh',
    createdAt: '2022-04-20T04:59:38.124Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10602,
    },
    transactionType: 'transferOut',
    amount: -90,
  },
  {
    _id: 'MqR4Thy26pAu43BnW',
    createdAt: '2022-04-20T04:59:38.240Z',
    storeItem: {
      type: {
        _id: 'w4pJvHSKdCQyXDDDw',
        name: 'BOTTLE 0.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5bebf049-e621-4fcd-a2e9-b0fd54e606b1_200x200.jpg?alt=media&token=931b4974-d6a4-4014-9963-8acb14a21661',
        },
      },
      quantity: 4859,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'Sof597TppQaWwnoFs',
    createdAt: '2022-04-20T04:59:38.356Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1157,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'BokYmqoJgfudDPSf8',
    createdAt: '2022-04-20T05:01:24.579Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'RygeijkXK7DaQzLNd',
    createdAt: '2022-04-20T05:01:24.690Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10512,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'pqhxAhHXkberQ6ry9',
    createdAt: '2022-04-20T05:01:24.800Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1149,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'wmNvs6x2nRCNaMWS3',
    createdAt: '2022-04-20T05:03:42.435Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: -56,
  },
  {
    _id: 'DrEHPCX4zzW7mtEWe',
    createdAt: '2022-04-20T05:03:42.543Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10512,
    },
    transactionType: 'transferOut',
    amount: -51,
  },
  {
    _id: 'ZkiGkpLCnccPyPWJ7',
    createdAt: '2022-04-20T05:03:42.663Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1149,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'PrnLkZ5gKeXFe7fW9',
    createdAt: '2022-04-20T05:04:35.314Z',
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 473,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: '2tQbNcLwGZg3Gfztd',
    createdAt: '2022-04-20T05:04:35.430Z',
    storeItem: {
      type: {
        _id: 'e5R2vdqFeidWH5rwL',
        name: 'MYSTARTER Y612 100 MU  ( LYOFAST Y 350 A - 5 UC)',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F8348e523-4b2c-4e1b-bfee-15433585a29d_200x200.jpeg?alt=media&token=18aff4fb-3476-466c-962a-92ef59ac16f4',
        },
      },
      quantity: 1.900000000000002,
    },
    transactionType: 'transferOut',
    amount: -1.2,
  },
  {
    _id: 'Ya6eWK8rdM6KK3EEM',
    createdAt: '2022-04-20T05:04:35.677Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 229,
    },
    transactionType: 'in',
    amount: 400,
  },
  {
    _id: 'MBr259h78z6akbJXm',
    createdAt: '2022-04-20T05:08:01.081Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 629,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'Mok9bGZuzx5Etfd9F',
    createdAt: '2022-04-20T05:08:01.227Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10461,
    },
    transactionType: 'transferOut',
    amount: -101,
  },
  {
    _id: 'D2RX8DZtYLvs5w3c7',
    createdAt: '2022-04-20T05:08:01.350Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1145,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'TsTZai6SFyGyefwKW',
    createdAt: '2022-04-20T05:08:37.239Z',
    storeItem: {
      type: {
        _id: 'TdpDiEsLQtgQKzp5w',
        name: 'ORGANIC YOUGHURT (SEMI FINAL)',
        unit: 'Kg',
      },
      quantity: 529,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: 'Qr6gHiXvFLcrzZuve',
    createdAt: '2022-04-20T05:08:38.228Z',
    storeItem: {
      type: {
        _id: '32fNy5jcLuWWSeN2E',
        name: 'BOTTLE 1 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe14bd1b1-d100-4d46-8962-9b6ddd4cc639_200x200.jpg?alt=media&token=0ab5e490-7606-4815-a613-669e93ae3b94',
        },
      },
      quantity: 10360,
    },
    transactionType: 'transferOut',
    amount: -151,
  },
  {
    _id: 'Zx38jq8YLX4RXSeTr',
    createdAt: '2022-04-20T05:08:38.464Z',
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1136,
    },
    transactionType: 'transferOut',
    amount: -13,
  },
  {
    _id: 'dg5QMA6oY8gsLm6fh',
    createdAt: '2022-04-20T05:28:49.058Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5990,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'AtnT5MJmAnNAqyPu4',
    createdAt: '2022-04-20T05:28:49.180Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8638.04499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: '5iYpzTRjukYP5k5R9',
    createdAt: '2022-04-20T05:28:49.292Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178944.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'xggPSLxTJa3FJFex2',
    createdAt: '2022-04-20T05:28:49.401Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15898.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'C9xqdufGQxrWZA2xw',
    createdAt: '2022-04-20T05:28:49.663Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 130,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'F7FjcPFncAxFXdtvC',
    createdAt: '2022-04-20T05:30:12.534Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13505,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'PBhindrLbtdn4ahLn',
    createdAt: '2022-04-20T05:30:12.675Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 110.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'mkit29pfPX37J99LD',
    createdAt: '2022-04-20T05:30:12.788Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 178444.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'YqixHuSCYmdLfoJit',
    createdAt: '2022-04-20T05:30:12.904Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23384.3,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: '4hYXiK9xrcpmxxkXP',
    createdAt: '2022-04-20T05:30:13.017Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8438.04499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'AEkrmiWsX3bfPcLj7',
    createdAt: '2022-04-20T05:30:13.150Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 1130,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'RJEfmvDtB8FSyGuEo',
    createdAt: '2022-04-20T05:30:13.268Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5513,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'oQHCAoYjjQ4q72EGZ',
    createdAt: '2022-04-20T06:00:43.958Z',
    transactionType: 'in',
    amount: 3100,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 9505,
    },
  },
  {
    _id: 'aaZoY8WeBWkYq3WZc',
    createdAt: '2022-04-20T06:01:07.469Z',
    transactionType: 'in',
    amount: 250,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12605,
    },
  },
  {
    _id: 'hf5MMc4iprv4yj4E6',
    createdAt: '2022-04-20T06:01:27.195Z',
    transactionType: 'in',
    amount: 650,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12855,
    },
  },
  {
    _id: 'SX4az3pmdjv6F8Zsm',
    createdAt: '2022-04-20T06:01:43.158Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 73,
    },
  },
  {
    _id: '4J35T7BgaCnbMzWCg',
    createdAt: '2022-04-20T06:55:20.213Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 568,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'KyuwxxPtpSHZtmmC5',
    createdAt: '2022-04-20T06:55:20.365Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 977.52,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'C9CEKuFhjRs6r4abJ',
    createdAt: '2022-04-20T06:55:20.483Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 131.6900000000006,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'W3xLG4DQb2ie2m5GC',
    createdAt: '2022-04-20T06:55:20.598Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2489.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: '92mm4ivausBfAdqgs',
    createdAt: '2022-04-20T06:55:20.869Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'G3x4FMLvAu6dt6Civ',
    createdAt: '2022-04-20T07:15:54.638Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 948.6300000000069,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'zoJAc5XPojMiRKgEm',
    createdAt: '2022-04-20T07:15:54.779Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13505,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'fxtqCgvkGNNT59iji',
    createdAt: '2022-04-20T07:15:54.892Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2486.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'DLMqdXKvdiWSXerdF',
    createdAt: '2022-04-20T07:15:55.017Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 70.5,
    },
    transactionType: 'transferOut',
    amount: -70,
  },
  {
    _id: 'nTCnF7DttEMyhg7zB',
    createdAt: '2022-04-20T07:15:55.139Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 176444.3,
    },
    transactionType: 'transferOut',
    amount: -2300,
  },
  {
    _id: 'SM2uexX9xTysDssR2',
    createdAt: '2022-04-20T07:15:55.265Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8238.04499999998,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'xgxWoruB9mHWhefG7',
    createdAt: '2022-04-20T07:15:55.384Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4618.5,
    },
    transactionType: 'transferOut',
    amount: -58,
  },
  {
    _id: 'Brox8HrECKbmnL6Jf',
    createdAt: '2022-04-20T08:10:08.051Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4041,
    },
  },
  {
    _id: 'M65h2C3Q759L7Rbz7',
    createdAt: '2022-04-20T08:10:51.555Z',
    transactionType: 'transferIn',
    amount: 90,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5433,
    },
  },
  {
    _id: 'to6CnbDigbmAfyRvt',
    createdAt: '2022-04-20T08:11:07.321Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4560.5,
    },
  },
  {
    _id: 'oiZLnSGigqNfxNWDN',
    createdAt: '2022-04-20T08:11:26.802Z',
    transactionType: 'transferIn',
    amount: 50,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2436.3000000000006,
    },
  },
  {
    _id: 'AszRf48NGryNjNZnH',
    createdAt: '2022-04-20T08:35:45.501Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 475.1600000000003,
    },
  },
  {
    _id: 'MtSvF5H7mMiZSvtf5',
    createdAt: '2022-04-20T08:36:02.535Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1192,
    },
  },
  {
    _id: 'cJPwCXK9NtMEALbqb',
    createdAt: '2022-04-20T08:36:20.756Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: '9neB23h2oHpx9Zxp5',
        name: 'WHITE CARTON MEDIUM 34.5X26X32',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7f907ac3-816d-4253-9e91-8d3c5bc2eb1d_200x200.jpg?alt=media&token=644457a0-d384-4716-8133-0dcd3c279b39',
        },
      },
      quantity: 1123,
    },
  },
  {
    _id: 'rdnpZAxF2BrbcABG9',
    createdAt: '2022-04-20T08:36:58.501Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 960.52,
    },
  },
  {
    _id: '6d2unwyYyMYG4hFrg',
    createdAt: '2022-04-20T08:37:51.941Z',
    transactionType: 'transferIn',
    amount: 2.5,
    storeItem: {
      type: {
        _id: 'qDgyHbqW3hjmN354w',
        name: 'DIVERSEY CLEAN',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F12afe7f1-5542-4a8f-8a1c-b770aa8bfaf8_200x200.jpeg?alt=media&token=316fa827-2692-4a08-be06-87485be1ed66',
        },
      },
      quantity: 16,
    },
  },
  {
    _id: 'naTBJx743CvYF76ca',
    createdAt: '2022-04-20T08:38:21.226Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: '7sdjaQk7M4jBxPRN3',
        name: 'DETERGENTS-WASHING MASHINE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe087aa06-9bbe-4765-90e8-807e6bd3e88b_200x200.jpg?alt=media&token=07849047-5931-407a-9fca-50e2923b7bbf',
        },
      },
      quantity: 10,
    },
  },
  {
    _id: 'yLBhQwqg6cpDostwT',
    createdAt: '2022-04-20T10:47:16.145Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 13255,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'iHGnHNvgySkecGH5d',
    createdAt: '2022-04-20T10:47:16.297Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2486.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'Ju2vCKsZnLeHkXeKy',
    createdAt: '2022-04-20T10:47:16.435Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 948.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'arwa44AbDiauuX8xa',
    createdAt: '2022-04-20T10:47:16.569Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 550.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -2.5,
  },
  {
    _id: 'gNM46unnWry7SWmT2',
    createdAt: '2022-04-20T10:47:16.706Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23134.3,
    },
    transactionType: 'transferOut',
    amount: -27,
  },
  {
    _id: 'evh3ch9h5cAg4LFzM',
    createdAt: '2022-04-20T10:47:16.848Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 806,
    },
    transactionType: 'transferOut',
    amount: -100,
  },
  {
    _id: 'FDcaM7ihQ3jQoiSiy',
    createdAt: '2022-04-20T10:47:16.991Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 174144.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: 'voRjoMfehzAm4vXxk',
    createdAt: '2022-04-20T10:47:17.104Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4660.5,
    },
    transactionType: 'transferOut',
    amount: -41,
  },
  {
    _id: 'd3KLGkeagsnrLEekS',
    createdAt: '2022-04-20T11:19:20.137Z',
    transactionType: 'transferIn',
    amount: 448,
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 465.8299999999999,
    },
  },
  {
    _id: 'njcQfMZErdcvh6oMY',
    createdAt: '2022-04-20T11:22:01.150Z',
    transactionType: 'transferIn',
    amount: 1933,
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 698.6300000000069,
    },
  },
  {
    _id: 'QApAzPiipBFXcZKn7',
    createdAt: '2022-04-20T11:31:49.356Z',
    transactionType: 'transferIn',
    amount: 600,
    storeItem: {
      type: {
        _id: 'Rp9jjQv3GWDQb2PED',
        name: 'S milk full fat (Al Marai)',
        unit: 'Lotr',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F71fce4a1-8ca0-40a2-a2e5-f67ee91ed5ce_200x200.jpeg?alt=media&token=4690e4d9-5bbf-4d64-8418-cb506116baac',
        },
      },
      quantity: 0,
    },
  },
  {
    _id: '9SHtAcPzY526qxieX',
    createdAt: '2022-04-20T12:42:55.447Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 2631.630000000007,
    },
    transactionType: 'transferOut',
    amount: -830,
  },
  {
    _id: 'BFy9bhbHA4XrqmpbM',
    createdAt: '2022-04-20T12:42:55.596Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 913.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ZqpTJyyTFLQzXmSy6',
    createdAt: '2022-04-20T12:42:55.723Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12755,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'ohLwtwsHcsKEZPpRi',
    createdAt: '2022-04-20T12:42:55.854Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2481.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bhXXoAdkqnzxYHRsY',
    createdAt: '2022-04-20T12:42:55.972Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wCYTMWfdmgRnEe3Ne',
    createdAt: '2022-04-20T12:42:56.097Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15898.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'KocGDRMcofRAmJZLM',
    createdAt: '2022-04-20T12:42:56.222Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'm7HZRreEuqA2sT8LQ',
    createdAt: '2022-04-20T12:42:56.347Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 6.250000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'q6PX95DGb3ZJaYxHg',
    createdAt: '2022-04-20T12:42:56.512Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 90.74,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'THYJfMwD3yo6ZMfaH',
    createdAt: '2022-04-20T12:42:56.697Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 547.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'WG6JqjB4NdzsPNQXw',
    createdAt: '2022-04-20T12:42:56.834Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8235.04499999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'TFiF5fE2LqDv6uzZ2',
    createdAt: '2022-04-20T12:42:56.964Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5523,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'Pr9j5CxEjCrBBkenf',
    createdAt: '2022-04-20T12:44:11.181Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 1801.630000000007,
    },
    transactionType: 'transferOut',
    amount: -840,
  },
  {
    _id: 'ceorW9Yj3drgBSH9c',
    createdAt: '2022-04-20T12:44:11.323Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 913.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'GNZvmAxWE6HJbkMmi',
    createdAt: '2022-04-20T12:44:11.449Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12755,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'FdkNm6HKqyiz55MsG',
    createdAt: '2022-04-20T12:44:11.570Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2481.3000000000006,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'P589iHtfavbtudBnt',
    createdAt: '2022-04-20T12:44:11.701Z',
    storeItem: {
      type: {
        _id: 'cTHxZLSGzu643sbNC',
        name: 'FDS 541019',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F292cf300-feb1-4276-9c04-4e115452e08d_200x200.jpg?alt=media&token=35c96b92-9664-4803-95f9-61b26b373e7a',
        },
      },
      quantity: 127.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9vp2rXsi7kMdyCTqt',
    createdAt: '2022-04-20T12:44:11.820Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15886.400000000001,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'gaEoN3MSv6GnR5nTc',
    createdAt: '2022-04-20T12:44:11.941Z',
    storeItem: {
      type: {
        _id: '3pNHNQtgFnrb9DeuA',
        name: 'Y 812 ',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F1235fc08-a67c-4393-8a86-ade7d8863a3a_200x200.jpg?alt=media&token=f026e049-74b0-4422-941b-463bcb5ca916',
        },
      },
      quantity: 0,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aXL6xr7d59xcMvNKT',
    createdAt: '2022-04-20T12:44:12.061Z',
    storeItem: {
      type: {
        _id: 'qjJicRCh69FGtS8NB',
        name: 'MYSTARTER Y551 100MU',
        unit: 'PAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F0e838993-9b8e-43fd-aa97-8d038bf4d929_200x200.jpeg?alt=media&token=512cde13-d97c-4678-ade4-6b2d9a75972e',
        },
      },
      quantity: 5.250000000000005,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: 'exbfGwhHwYRWQKT2v',
    createdAt: '2022-04-20T12:44:12.197Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 90.24,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '2Xxf267ipF3DZ6XKd',
    createdAt: '2022-04-20T12:44:12.316Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 539.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -8,
  },
  {
    _id: 'xis3B8wFFrumLYAX4',
    createdAt: '2022-04-20T12:44:12.465Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8234.44499999998,
    },
    transactionType: 'transferOut',
    amount: -0.6,
  },
  {
    _id: 'zgYX59xhEmxHnFNEC',
    createdAt: '2022-04-20T12:44:12.584Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5446,
    },
    transactionType: 'transferOut',
    amount: -77,
  },
  {
    _id: 'PgY4djgpripfrybX7',
    createdAt: '2022-04-20T13:19:59.945Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 961.6300000000069,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: '9bBjfK8eRB3thZpMe',
    createdAt: '2022-04-20T13:20:00.102Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 913.8299999999999,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'tS9fxjyyBqpxosW4n',
    createdAt: '2022-04-20T13:20:00.360Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12755,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'wNpnQfC2jK4LJKLE3',
    createdAt: '2022-04-20T13:20:00.475Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23107.3,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'bfhKvz2KzhohGiovs',
    createdAt: '2022-04-20T13:20:00.602Z',
    storeItem: {
      type: {
        _id: 'yit3NRkr4hscaexnJ',
        name: ' MYSTARTER Y 431 100 MU ( CH-1 )',
        unit: 'BAC',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F65dc23c2-27de-454c-9a8b-dc87d68df44c_200x200.jpg?alt=media&token=6dc2f83e-dbfc-4909-ab2d-35f11a0f6200',
        },
      },
      quantity: 89.74,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'W6D9xJtkCxFutS7hB',
    createdAt: '2022-04-20T13:20:00.737Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8233.84499999998,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'mx7hCdABcHDQ6iDYB',
    createdAt: '2022-04-20T13:20:00.869Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15874.400000000001,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'aW63gmKrkvigMM4LR',
    createdAt: '2022-04-20T13:20:01.004Z',
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4241,
    },
    transactionType: 'transferOut',
    amount: -59,
  },
  {
    _id: 'YCdp5JPXPwm82r8Ft',
    createdAt: '2022-04-20T15:17:00.926Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12755,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'hvKtfi9jtiT9oDR9G',
    createdAt: '2022-04-20T15:17:01.073Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2481.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'FZtYKBPxToDRi6fF2',
    createdAt: '2022-04-20T15:17:01.185Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 961.6300000000069,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'rZBFkSmfNkXLhMYxh',
    createdAt: '2022-04-20T15:17:01.300Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 531.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'pgP3JpiFLEC8evD8L',
    createdAt: '2022-04-20T15:17:01.440Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23107.3,
    },
    transactionType: 'transferOut',
    amount: -45,
  },
  {
    _id: 'sfpJj8RfxndLsmLG8',
    createdAt: '2022-04-20T15:17:01.568Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 706,
    },
    transactionType: 'transferOut',
    amount: -150,
  },
  {
    _id: '3navsyBhJLkfk2TDf',
    createdAt: '2022-04-20T15:17:01.714Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 173144.3,
    },
    transactionType: 'transferOut',
    amount: -1000,
  },
  {
    _id: '3djsoZGY2nENpjkkn',
    createdAt: '2022-04-20T15:17:01.833Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4619.5,
    },
    transactionType: 'transferOut',
    amount: -53,
  },
  {
    _id: 'E5MbQehWAHrdE5AGc',
    createdAt: '2022-04-20T15:18:42.823Z',
    storeItem: {
      type: {
        _id: 'mYDnaFcHBvsmXj3eC',
        name: 'S Cream',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb31b5649-ecb1-4560-b290-ea1c6969b233_200x200.jpeg?alt=media&token=e1d28613-c362-4e22-a97a-4a9b96440fd2',
        },
      },
      quantity: 153.9,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'DgwPBqkqBfDfPLoMk',
    createdAt: '2022-04-20T15:18:42.993Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'L5vgeoXh6LTaj7aNS',
    createdAt: '2022-04-20T15:18:43.164Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 131.4400000000006,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: '2AkJbTrDBuBYMKQ25',
    createdAt: '2022-04-20T15:18:43.303Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1020.52,
    },
    transactionType: 'transferOut',
    amount: -1,
  },
  {
    _id: '5s6LawJMpadYknsjM',
    createdAt: '2022-04-20T15:18:43.423Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 558,
    },
    transactionType: 'transferOut',
    amount: -2,
  },
  {
    _id: 'pFdsgrXTtfRTwJCRS',
    createdAt: '2022-04-20T15:18:43.541Z',
    storeItem: {
      type: {
        _id: 'oeqdGKemiTveZ7JAF',
        name: 'CHEESE FLAVOR 217813',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F54ecb20c-7123-4123-97a9-3df66d3774fd_200x200.jpg?alt=media&token=c0836f0a-afb5-413e-a7b1-072dff97d2ba',
        },
      },
      quantity: 56.84000000000002,
    },
    transactionType: 'transferOut',
    amount: -0.8,
  },
  {
    _id: 'ELwuHc8qtxSntR2zH',
    createdAt: '2022-04-20T15:18:43.653Z',
    storeItem: {
      type: {
        _id: 'nmupk2ZW8kP8J5hFp',
        name: 'YS 67 EMULSIFING SALT (PZ7)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc6af39b9-ecc8-4fa9-a275-b42cd7567f96_200x200.jpeg?alt=media&token=29a9e369-de9a-4990-8cb8-36a036f43a67',
        },
      },
      quantity: 55.86000000000008,
    },
    transactionType: 'transferOut',
    amount: -1.8,
  },
  {
    _id: 'y2dnZRcRFaTrWcDWA',
    createdAt: '2022-04-20T15:18:43.770Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 531.6600000000003,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'QjBmicYtmXcL5saTc',
    createdAt: '2022-04-20T15:18:43.888Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8231.84499999998,
    },
    transactionType: 'transferOut',
    amount: -0.5,
  },
  {
    _id: 'QzYXBK8ienGr2NL6Y',
    createdAt: '2022-04-20T15:18:44.002Z',
    storeItem: {
      type: {
        _id: 'rd5BFz4o5wFkrAora',
        name: 'CURD CHEESE (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56726bc5-f39f-4ecc-9f63-80a582cd12cf_200x200.jpg?alt=media&token=5bb133f4-00ce-430d-a953-b9d2b0c60480',
        },
      },
      quantity: 2172.1,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'iopvuxG7KY9gjwdLR',
    createdAt: '2022-04-20T15:18:44.166Z',
    storeItem: {
      type: {
        _id: 'XHSvFZY2wAAKdktXZ',
        name: 'CURD CHEESE FULL FAT (SEMI FINAL) ',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F94239466-549e-4a7d-a204-6ab72ae8a310_200x200.jpg?alt=media&token=06a3e97e-495b-4274-b058-a2947445820a',
        },
      },
      quantity: 278,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'aJvDKkJCitYJfYdWM',
    createdAt: '2022-04-20T15:18:44.306Z',
    storeItem: {
      type: {
        _id: 'qaorQKPqpvkz56SPf',
        name: '720 ML JAR  (CHEESE)',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2c525eed-49fc-40b7-86c4-f6a866ccaa2d_200x200.jpg?alt=media&token=b235cc3b-360f-489d-9b13-49e99d1e03ec',
        },
      },
      quantity: 389,
    },
    transactionType: 'transferOut',
    amount: -110,
  },
  {
    _id: 'AemvL68yGjkSfynLF',
    createdAt: '2022-04-20T15:18:44.539Z',
    storeItem: {
      type: {
        _id: 'BfTmwc3jtNhGAA52Y',
        name: '5PLY WHITE TOP BOX DIVIDER 415X314X150MM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F571c19af-5d1c-4bc8-9968-a1510e1b8d87_200x200.jpg?alt=media&token=e3467164-cd9e-46c6-b836-05f3e189de79',
        },
      },
      quantity: 77,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'MzdZSjcXSu9ocazMH',
    createdAt: '2022-04-20T15:21:42.512Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 12505,
    },
    transactionType: 'transferOut',
    amount: -4000,
  },
  {
    _id: 'awzxh5xPeY258ZbFB',
    createdAt: '2022-04-20T15:21:42.647Z',
    storeItem: {
      type: {
        _id: 'nNEcTZpswZmDcZNSY',
        name: 'CITRIC ACID',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe6bea9fe-afdf-4ea9-ae88-476b7f1ca677_200x200.jpg?alt=media&token=6a4e29f1-48c3-4f50-87e3-a91a060bda7e',
        },
      },
      quantity: 106.07500000000002,
    },
    transactionType: 'transferOut',
    amount: -4,
  },
  {
    _id: 'ofLTQxshCQZ9Jorvv',
    createdAt: '2022-04-20T15:21:42.759Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 172144.3,
    },
    transactionType: 'transferOut',
    amount: -2000,
  },
  {
    _id: 'wk6cQQDvk3GNkAzDP',
    createdAt: '2022-04-20T15:21:42.869Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 23062.3,
    },
    transactionType: 'transferOut',
    amount: -250,
  },
  {
    _id: 'egXLwb8nnxxPKkaer',
    createdAt: '2022-04-20T15:21:42.980Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8231.34499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'jNoZz3KxLbtJZcGBC',
    createdAt: '2022-04-20T15:21:43.098Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 630,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'zGrN5Ak3W56nAXSar',
    createdAt: '2022-04-20T15:21:43.213Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5369,
    },
    transactionType: 'transferOut',
    amount: -80,
  },
  {
    _id: 'h2pGJJXkGpvcPmZte',
    createdAt: '2022-04-20T15:39:53.087Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8505,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'mZR5fcdhxjbfBiJpi',
    createdAt: '2022-04-20T15:39:53.231Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 461.63000000000693,
    },
    transactionType: 'transferOut',
    amount: -400,
  },
  {
    _id: 'LL2uJrNgMx92agix9',
    createdAt: '2022-04-20T15:39:53.375Z',
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 475,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: 'tePSAYhCRPjRdThhg',
    createdAt: '2022-04-20T15:39:53.514Z',
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 528.1600000000003,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'wDpG3jf7ocbALQbwK',
    createdAt: '2022-04-20T15:39:53.640Z',
    storeItem: {
      type: {
        _id: 'cjGSe4GTCYRzJHm9G',
        name: 'PLASTIC CONTAINER 500 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe0dbb199-0a10-4552-abd1-372c06a9841a_200x200.jpg?alt=media&token=09749231-e8ec-4bf0-9ad7-0eb2eed90bf3',
        },
      },
      quantity: 2432,
    },
    transactionType: 'transferOut',
    amount: -175,
  },
  {
    _id: '2k9XzukPxgHn5dytY',
    createdAt: '2022-04-20T15:39:53.757Z',
    storeItem: {
      type: {
        _id: 'qED2rDzS5DFNDxqeP',
        name: 'WHITE CARTON SMALL 37X31X21',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b77380c-b395-49d7-9852-f7a2762578d6_200x200.jpg?alt=media&token=9f9eca79-054e-4d8e-8bc1-6595a85a2cf9',
        },
      },
      quantity: 1252,
    },
    transactionType: 'transferOut',
    amount: -9,
  },
  {
    _id: 'sdH3C7gKzFwWLB6NY',
    createdAt: '2022-04-21T03:46:29.542Z',
    transactionType: 'transferIn',
    amount: 75,
    storeItem: {
      type: {
        _id: 'T3NhkuKedHDSrjD8K',
        name: 'MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F5d5dbb83-d88d-4077-9856-3e87923515fe_200x200.jpg?alt=media&token=6d2824e5-d650-4978-a21a-4aa202826ce7',
        },
      },
      quantity: 425,
    },
  },
  {
    _id: 'TX8YunYEdeHXXkjGY',
    createdAt: '2022-04-21T03:47:05.241Z',
    transactionType: 'transferIn',
    amount: 25,
    storeItem: {
      type: {
        _id: 'FeYfJPZRnZjgPA8DC',
        name: 'STARCH 25KG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F56d5768b-2489-4294-84d2-e00d3a211749_200x200.jpg?alt=media&token=36c0475d-435d-4997-bf45-8fd80e455003',
        },
      },
      quantity: 523.1600000000003,
    },
  },
  {
    _id: 'A6e75Zka8Lde8vyvZ',
    createdAt: '2022-04-21T03:47:48.320Z',
    transactionType: 'transferIn',
    amount: 2,
    storeItem: {
      type: {
        _id: 'JJXmuLPZtgr34xgAo',
        name: 'APRON',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F836955bb-4c46-4d95-aa0d-4cd057c4d7e9_200x200.jpg?alt=media&token=cc75a865-f8ae-400a-a2c5-2249a2829536',
        },
      },
      quantity: 8,
    },
  },
  {
    _id: 'PiC9ggy4CucnZo2qx',
    createdAt: '2022-04-21T03:48:20.776Z',
    transactionType: 'transferIn',
    amount: 5,
    storeItem: {
      type: {
        _id: 'Z4kgGNwGh7GMucGHq',
        name: 'MAXI ROLL TISSUE ',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2bd09784-40f3-438b-907f-9931ab66f41a_200x200.jpg?alt=media&token=5634afd6-5928-4c07-ab72-da7de664e7e4',
        },
      },
      quantity: 26,
    },
  },
  {
    _id: 'yYprosRYxehMcQiAk',
    createdAt: '2022-04-21T03:48:56.378Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'xyX3JCjJxkmWbo7EN',
        name: 'GLOVES NITRILE POWDER FREE  - MEDIUM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F063d4329-7985-45d3-bac7-1c99ee771a32_200x200.jpg?alt=media&token=0afbc2a7-f8ff-4af5-8022-88319906aa2f',
        },
      },
      quantity: 7,
    },
  },
  {
    _id: 'HojRkMDv8rPuThqTr',
    createdAt: '2022-04-21T03:49:11.636Z',
    transactionType: 'transferIn',
    amount: 1,
    storeItem: {
      type: {
        _id: 'pgBa2zTtrNA7i3RbB',
        name: 'GLOVES NITRILE POWDER FREE  - LARGE',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F36a083ae-23ac-4407-8771-4336e2246576_200x200.jpg?alt=media&token=1306ff4d-a3a4-4218-85f5-808fbf3dff2f',
        },
      },
      quantity: 14,
    },
  },
  {
    _id: 'CJMdFJLRNZZHMBdP2',
    createdAt: '2022-04-21T03:49:31.914Z',
    transactionType: 'transferIn',
    amount: 12,
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 131.5,
    },
  },
  {
    _id: 'EXYqQ5ZATPzuSHMzT',
    createdAt: '2022-04-21T03:49:50.273Z',
    transactionType: 'transferIn',
    amount: 30,
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 197.84999999999997,
    },
  },
  {
    _id: 'CMnJsonvjHMR2nFxG',
    createdAt: '2022-04-21T03:50:14.259Z',
    transactionType: 'transferIn',
    amount: 20,
    storeItem: {
      type: {
        _id: '7sjZ4SiPXknydj55o',
        name: 'PLASTIC BAG',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fe1c6441e-31bf-427f-a99c-a9aa6f3afb3a_200x200.jpg?alt=media&token=976b174a-0139-4d58-9a10-a55d742bd8ab',
        },
      },
      quantity: 850,
    },
  },
  {
    _id: 'YLntyStv85GLGdwxH',
    createdAt: '2022-04-21T03:51:02.298Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'ZJpy4oqXTiQ3XreZJ',
        name: 'BUCKET 2 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffe11e53c-8436-4222-a71d-4e86a4442531_200x200.jpg?alt=media&token=2d335834-34a7-4670-bc58-763cf4080afe',
        },
      },
      quantity: 1122,
    },
  },
  {
    _id: 'PwBKgazob2cJMsnDQ',
    createdAt: '2022-04-21T04:03:29.440Z',
    transactionType: 'transferIn',
    amount: 375,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8031.344999999979,
    },
  },
  {
    _id: 'xuK3nRMyy4sgM4WyR',
    createdAt: '2022-04-21T04:19:29.917Z',
    transactionType: 'transferIn',
    amount: 126,
    storeItem: {
      type: {
        _id: 'tD2HNG3ZCiLn9hHb5',
        name: 'BOTTLE 1.5 LTR',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F768b3c1f-297c-40e0-be76-70050021b83a_200x200.jpg?alt=media&token=63ea24be-30fb-4902-a658-7583c2d5b0f7',
        },
      },
      quantity: 1248,
    },
  },
  {
    _id: 'LPhDgsGDWAezzKwNE',
    createdAt: '2022-04-21T04:54:06.920Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 61.63000000000693,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'QYwX6A4YrzWYQgSMo',
    createdAt: '2022-04-21T04:54:07.057Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 913.8299999999999,
    },
    transactionType: 'transferOut',
    amount: -650,
  },
  {
    _id: 'MLCgpYoyekwD53qdP',
    createdAt: '2022-04-21T04:54:07.170Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8305,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'JC3ookprDhsHbHHk7',
    createdAt: '2022-04-21T04:54:07.288Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2473.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -18,
  },
  {
    _id: 'rvm2qPJjtd6fm3CAd',
    createdAt: '2022-04-21T04:54:07.400Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 22812.3,
    },
    transactionType: 'transferOut',
    amount: -30,
  },
  {
    _id: 'bB2w6mBRWMqGpxogk',
    createdAt: '2022-04-21T04:54:08.204Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 170144.3,
    },
    transactionType: 'transferOut',
    amount: -300,
  },
  {
    _id: 'DTPYdPAWz8AnzSWtY',
    createdAt: '2022-04-21T04:54:08.325Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15450.099999999999,
    },
    transactionType: 'transferOut',
    amount: -24,
  },
  {
    _id: 'zJcEZozmQh9sdhYMd',
    createdAt: '2022-04-21T04:54:08.441Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1226.8,
    },
    transactionType: 'transferOut',
    amount: -15,
  },
  {
    _id: 'hfna57gBFbnjBaPee',
    createdAt: '2022-04-21T04:54:08.551Z',
    storeItem: {
      type: {
        _id: 'o2LhMEEN7798JNSSN',
        name: 'Brian (Low Salt)',
        unit: 'LITER',
      },
      quantity: 556,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: '4phBdRuQJEKN67Nh7',
    createdAt: '2022-04-21T04:54:08.669Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4566.5,
    },
    transactionType: 'transferOut',
    amount: -50,
  },
  {
    _id: '6TZqFeu5QqEYHjZbf',
    createdAt: '2022-04-21T05:01:51.392Z',
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8305,
    },
    transactionType: 'transferOut',
    amount: -88,
  },
  {
    _id: 'TBbaN3hi5zJzCuXvX',
    createdAt: '2022-04-21T05:01:51.530Z',
    storeItem: {
      type: {
        _id: 'ey6q4svh2bYW5bCZD',
        name: 'S MILK LOW FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7ea0e781-4e30-4ac0-8c09-b83567e19e86_200x200.jpg?alt=media&token=260d9ce1-260e-43c9-bad2-6befb16c38f2',
        },
      },
      quantity: 263.8299999999999,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'rC4noBgHzC5BX3JDf',
    createdAt: '2022-04-21T05:01:51.643Z',
    storeItem: {
      type: {
        _id: '6HHF5mmktkBFs3QGB',
        name: 'S MILK FULL FAT (Marmum)',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7671bb2a-9f8d-4cbf-9a78-3344b8eb0d54_200x200.jpeg?alt=media&token=d4ac3125-a821-47ed-9a24-18be202a9333',
        },
      },
      quantity: 61.63000000000693,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'RgvHauHaPFtd8NbLA',
    createdAt: '2022-04-21T05:01:51.769Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2455.8000000000006,
    },
    transactionType: 'transferOut',
    amount: -7.5,
  },
  {
    _id: 'dHgtnq7GgmRGHvyBM',
    createdAt: '2022-04-21T05:01:51.888Z',
    storeItem: {
      type: {
        _id: 'bb5CYTzKtb7j9WNFX',
        name: 'LYSOZYMC',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fc215f1da-0df8-4529-ae69-e7f172c936cf_200x200.jpg?alt=media&token=395a11b7-18ee-4a2b-9760-00bc9e665e2c',
        },
      },
      quantity: 1211.8,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'is9wACCethct77g8T',
    createdAt: '2022-04-21T05:01:51.998Z',
    storeItem: {
      type: {
        _id: 'GkYymppqADJwbhvfa',
        name: 'WHITE NISIN',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb8cc7260-e17d-4a3e-a0ff-7cc1eab0dc6f_200x200.jpg?alt=media&token=9bb9c541-5af1-4200-9d4d-a846459f9936',
        },
      },
      quantity: 15426.099999999999,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'p7C9fK6yj9RQvsqCR',
    createdAt: '2022-04-21T05:01:52.114Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 169844.3,
    },
    transactionType: 'transferOut',
    amount: -125,
  },
  {
    _id: 'nzGS5CruvdgcM6Smm',
    createdAt: '2022-04-21T05:01:52.229Z',
    storeItem: {
      type: {
        _id: 'CB82wnhjRrCuwW7qw',
        name: 'ALBAMAX',
        unit: 'G',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fdc2a7c6d-8448-4920-8a97-6bfdfc610691_200x200.jpg?alt=media&token=e24eb6c7-114f-4454-bc89-538bc263a9fc',
        },
      },
      quantity: 22782.3,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'tGh37DoADHN9Fwz5K',
    createdAt: '2022-04-21T05:01:52.370Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4516.5,
    },
    transactionType: 'transferOut',
    amount: -21,
  },
  {
    _id: 'P9nazuQp8pGERizWu',
    createdAt: '2022-04-21T05:39:35.528Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 394.45,
    },
    transactionType: 'transferOut',
    amount: -20.3,
  },
  {
    _id: 'qSwsds8vEzAjfJdrf',
    createdAt: '2022-04-21T05:39:35.730Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 227.84999999999997,
    },
    transactionType: 'transferOut',
    amount: -7.6,
  },
  {
    _id: '5cvkvEk4v3C4XbP3s',
    createdAt: '2022-04-21T05:39:35.848Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 143.5,
    },
    transactionType: 'transferOut',
    amount: -5,
  },
  {
    _id: 'HcnpZyp7Fs5riNDfh',
    createdAt: '2022-04-21T05:39:35.972Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4495.5,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: 'c9fi3rpj7cnyELdwC',
    createdAt: '2022-04-21T05:39:36.100Z',
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5289,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'DuadM9FF8XnYvZPf3',
    createdAt: '2022-04-21T05:40:45.140Z',
    storeItem: {
      type: {
        _id: 'ph59aMhdjLbhFAgQx',
        name: 'LABNA BALL (SEMI FINAL)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F14e93c11-d5af-422c-9ea5-7493b33505c9_200x200.jpg?alt=media&token=c286a731-34a4-43a9-9879-0b0c7f3bba8b',
        },
      },
      quantity: 374.15,
    },
    transactionType: 'transferOut',
    amount: -24.4,
  },
  {
    _id: 'nv2YHkMBjRy4fcYKS',
    createdAt: '2022-04-21T05:40:45.308Z',
    storeItem: {
      type: {
        _id: 'wFTEQn354P7vCykqb',
        name: 'SUN FLOWER OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92708da8-3672-4585-9a87-9f5a55533242_200x200.jpg?alt=media&token=9aaeb060-655b-4014-9fef-f45b99bbd836',
        },
      },
      quantity: 220.24999999999997,
    },
    transactionType: 'transferOut',
    amount: -9.1,
  },
  {
    _id: 'DYctnZ5YQTEF7oZPB',
    createdAt: '2022-04-21T05:40:45.431Z',
    storeItem: {
      type: {
        _id: 'FoNqSh2ub5RnbHmKx',
        name: 'OLIVE OIL',
        unit: 'L',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7694bd51-6b23-42db-9756-a233e0df0ba7_200x200.jpg?alt=media&token=e9661901-51ce-42fa-9730-70e08eae1d38',
        },
      },
      quantity: 138.5,
    },
    transactionType: 'transferOut',
    amount: -6,
  },
  {
    _id: 'wAKaqN8Et5STwiTbQ',
    createdAt: '2022-04-21T05:40:45.549Z',
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4485.5,
    },
    transactionType: 'transferOut',
    amount: -12,
  },
  {
    _id: 'q3YtxgHmDuMkubFd3',
    createdAt: '2022-04-21T07:12:37.075Z',
    storeItem: {
      type: {
        _id: 'au4kHLg8wErobJhKA',
        name: 'BUTTER OIL',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6c6da5b6-a6db-488a-8bc8-43ad270eb8fe_200x200.jpeg?alt=media&token=42e84bfe-5737-464e-9b20-525989e9884d',
        },
      },
      quantity: 556,
    },
    transactionType: 'transferOut',
    amount: -10,
  },
  {
    _id: '2fhCpftRFcpX2kCjD',
    createdAt: '2022-04-21T07:12:37.206Z',
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1019.52,
    },
    transactionType: 'transferOut',
    amount: -17,
  },
  {
    _id: 'eptDAX52DSc4SpbEb',
    createdAt: '2022-04-21T07:12:37.341Z',
    storeItem: {
      type: {
        _id: '53bha8tBnrDnetoBd',
        name: 'LYGOMME ABN 622 R (STABILIZERS)',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F11e899d9-ed98-4d09-871a-248d95155a56_200x200.jpg?alt=media&token=e2163484-9127-4201-ac82-83313b14fc29',
        },
      },
      quantity: 130.9400000000006,
    },
    transactionType: 'transferOut',
    amount: -0.25,
  },
  {
    _id: 'Nktkyo3ikwkuXKJ95',
    createdAt: '2022-04-21T07:12:37.449Z',
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2448.3000000000006,
    },
    transactionType: 'transferOut',
    amount: -3,
  },
  {
    _id: 'M2hDymti9jiGnJHpD',
    createdAt: '2022-04-21T07:12:37.691Z',
    storeItem: {
      type: {
        _id: 'xfhZLx9cQgc8h26st',
        name: 'D CREAM',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fb6a2e61e-c971-4fe0-9c91-c5bf0e0e99c9_200x200.jpeg?alt=media&token=f833a2eb-85cd-45bf-a97f-07e37eab4fda',
        },
      },
      quantity: 0.5,
    },
    transactionType: 'in',
    amount: 70,
  },
  {
    _id: 'Eeddc44MEo4BXkswE',
    createdAt: '2022-04-21T07:17:12.824Z',
    storeItem: {
      type: {
        _id: 'f7FRjQ4Y23kgugvQi',
        name: 'Water',
        unit: 'LITER',
      },
      quantity: 5190,
    },
    transactionType: 'transferOut',
    amount: -800,
  },
  {
    _id: 'Tvmm7QnDXxn5QErEo',
    createdAt: '2022-04-21T07:17:12.939Z',
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8406.34499999998,
    },
    transactionType: 'transferOut',
    amount: -200,
  },
  {
    _id: 'zhyPQMzCr2ppb5vXo',
    createdAt: '2022-04-21T07:17:13.053Z',
    storeItem: {
      type: {
        _id: 'rDCrcN8yrfC8irpTb',
        name: 'CALCIUM CHLORIDE',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff2507ea3-3ef1-4bd0-855e-10974f4100af_200x200.jpg?alt=media&token=17d30bef-715c-4b5b-bfda-6c6a7e95e3d0',
        },
      },
      quantity: 169719.3,
    },
    transactionType: 'transferOut',
    amount: -500,
  },
  {
    _id: 'BwQXEYHWMByZsCPkE',
    createdAt: '2022-04-21T07:17:13.168Z',
    storeItem: {
      type: {
        _id: 'RmaMDrgZvagDwhdky',
        name: 'NATACID ',
        unit: 'GRAM',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fac15d6ce-97e0-41e9-bfc7-a577985665ae_200x200.jpg?alt=media&token=992500ac-4a3e-4dc9-af52-b06eee390fd8',
        },
      },
      quantity: 15856.400000000001,
    },
    transactionType: 'transferOut',
    amount: 0,
  },
  {
    _id: 'i2tt85yJbXyqm5jDq',
    createdAt: '2022-04-21T07:17:13.434Z',
    storeItem: {
      type: {
        _id: 'bxuyx8riwMtzB2tQP',
        name: 'Brian (High Salt)',
        unit: 'LITER',
      },
      quantity: 130,
    },
    transactionType: 'in',
    amount: 1000,
  },
  {
    _id: 'eKk2WcSXH3mDvoTTu',
    createdAt: '2022-04-21T08:55:41.397Z',
    transactionType: 'transferIn',
    amount: 250,
    storeItem: {
      type: {
        _id: 'pvQXzxGyFapJcq8fA',
        name: 'Salt',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F444765e2-f4fb-4605-bff0-5d58d4a45ea6_200x200.jpg?alt=media&token=108a4847-5263-4151-bcf8-3dd415d0f865',
        },
      },
      quantity: 8206.34499999998,
    },
  },
  {
    _id: 'QePMEEfxcbbTt8frR',
    createdAt: '2022-04-21T08:55:58.735Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'pXRiMGYXMrfZuZ7DZ',
        name: 'SKIMMED MILK POWDER',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F7a5bff30-dab7-4bbc-888a-767beb83f95d_200x200.jpg?alt=media&token=e4d148e4-9c92-47a9-b9f5-b1b8e346b236',
        },
      },
      quantity: 2445.3000000000006,
    },
  },
  {
    _id: 'aLDG9o6DS7rTmt3D6',
    createdAt: '2022-04-21T08:56:25.489Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'zpmKpxaxs5uPopHGf',
        name: 'SQUARE CHEESE CONTAINER',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F928db258-18bb-4b9c-8136-4c03bc010684_200x200.jpg?alt=media&token=8a0526d3-eb0e-4fb4-a010-ebec11d27672',
        },
      },
      quantity: 4473.5,
    },
  },
  {
    _id: 'YJ6pJNtckraLswqWj',
    createdAt: '2022-04-21T08:56:44.673Z',
    transactionType: 'transferIn',
    amount: 100,
    storeItem: {
      type: {
        _id: 'qwY433jwoYY5JiNBH',
        name: 'BUCKET 4 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ffd845476-ccb4-4997-b282-eab7b5b841a1_200x200.jpg?alt=media&token=ae6aee4b-8ccb-49a8-81b0-0bbeb62b3796',
        },
      },
      quantity: 4182,
    },
  },
  {
    _id: 'XFzfxQi2B2Sa4yJyQ',
    createdAt: '2022-04-21T08:57:11.753Z',
    transactionType: 'transferIn',
    amount: 200,
    storeItem: {
      type: {
        _id: 'pWRYPJdDY4aCMRz85',
        name: 'YOGHURT CUP TRAY',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Ff79ebaa1-fc05-443b-abc1-6da3ab17a3ea_200x200.jpg?alt=media&token=3c1d2207-962a-4951-886e-ffa10eb15214',
        },
      },
      quantity: 1325,
    },
  },
  {
    _id: 'ru2fTSEx2hGAMwRGy',
    createdAt: '2022-04-21T08:57:36.536Z',
    transactionType: 'transferIn',
    amount: 3000,
    storeItem: {
      type: {
        _id: 'yGkD6yz7REfxuWNSm',
        name: 'YOGHURT CUP 170 GM',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F2cc2f6bf-2153-43bd-b62b-abaa1fb78666_200x200.jpg?alt=media&token=2e9a72b3-499f-40d5-b85f-38e61eabc4a5',
        },
      },
      quantity: 7886,
    },
  },
  {
    _id: 'ey9mdnafBvswrw3vA',
    createdAt: '2022-04-21T08:58:35.461Z',
    transactionType: 'transferIn',
    amount: 240,
    storeItem: {
      type: {
        _id: '3zCYDjp22MNaCmdx2',
        name: 'ROUND BUCKET 10 KG',
        unit: 'EA',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F6b9cfdb6-6e5c-4a1b-94fd-ec1f9cb7ac6b_200x200.jpg?alt=media&token=8910c435-a619-4665-a2f5-f76c353393e5',
        },
      },
      quantity: 5289,
    },
  },
  {
    _id: 'nMrRwFh5K6BKhFWyq',
    createdAt: '2022-04-21T08:58:54.565Z',
    transactionType: 'transferIn',
    amount: 60,
    storeItem: {
      type: {
        _id: 'tLd4HWxgGryCAGPjG',
        name: 'SHORTENING ( FATS )',
        unit: 'KG',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2Fbd007530-47be-4dc8-857b-deafa2beaf2c_200x200.jpg?alt=media&token=3c92f29e-5857-4c48-8143-94f666e2768d',
        },
      },
      quantity: 1002.52,
    },
  },
  {
    _id: 'aMCqQ3aTGaHeFq5mb',
    createdAt: '2022-04-21T11:29:11.566Z',
    transactionType: 'in',
    amount: 200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8217,
    },
  },
  {
    _id: '9cMCN7wcAKYprcswe',
    createdAt: '2022-04-21T11:29:29.902Z',
    transactionType: 'in',
    amount: 2500,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 8417,
    },
  },
  {
    _id: '3CCEcKdyQKq8eT4Na',
    createdAt: '2022-04-21T11:29:50.982Z',
    transactionType: 'in',
    amount: 200,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 10917,
    },
  },
  {
    _id: '42zeRSFHaPLFkQt6b',
    createdAt: '2022-04-21T11:30:04.430Z',
    transactionType: 'in',
    amount: 450,
    storeItem: {
      type: {
        _id: 'S758WRs3yJrxDErMj',
        name: 'D MILK FULL FAT',
        unit: 'Liter',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F311b00b0-6f6b-498b-b3f0-a544d86d8652_200x200.jpg?alt=media&token=b6cbcc25-1f92-412f-bf68-71aa7db90bb1',
        },
      },
      quantity: 11117,
    },
  },
  {
    _id: 'H6PuufR7MpTDzjNHe',
    createdAt: '2022-04-21T11:30:31.100Z',
    transactionType: 'in',
    amount: 350,
    storeItem: {
      type: {
        _id: 'xRrcyrvSFsBut9Yqb',
        name: 'D ORGANIC MILK',
        unit: 'LITER',
        image: {
          thumbUrl:
            'https://firebasestorage.googleapis.com/v0/b/siteshub-meronex.appspot.com/o/thumbs%2F92791c5d-7729-4342-88ba-2117120acd8c_200x200.jpg?alt=media&token=cdbfc180-be3e-4c0e-8059-95638755ca52',
        },
      },
      quantity: 423,
    },
  },
];
export const analyticsMng = {
  /**
   * Retrieve transactions for a store or a store item.
   * @param itemId
   * @param storeId
   * @param filters
   * @return {Promise<*>}
   */
  getAnalyticsTransactions: async ({ itemId, storeId, filters }) => {
    const result = await server.call('getAnalyticsTransactions', {
      storeId,
      itemId,
      filters,
    });

    // console.log(result);
    // return mockData;
    return result;
  },
};
