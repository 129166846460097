import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn, NoData } from '@meronex/components';
import IEmpty from '@meronex/icons/gi/GiCardboardBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import { Icons } from '../../../../common/Icons';
import Quantity from '../../../../common/Quantity';

import { App } from '@meronex/app';
import TopBar from '../../../../views/components/TopHeader';
import { helpers } from '../../../../common/helpers';
import Avatar from 'react-avatar';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function BatchesDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, item } = props;
  const [filterValue, setFilterValue] = React.useState('');

  if (!item || !Array.isArray(item.itemGroups)) {
    return false;
  }

  const filterGroups = (groups) => {
    if (!filterValue) {
      return groups;
    }
    return groups.filter((g) =>
      g.name.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  const renderItemGroups = () => {
    const batches = helpers.batch.sortBatchesByExpiry(
      filterGroups(item.itemGroups)
    );

    if (batches.length === 0) {
      return <NoData message={'No Batches Found'} type={'warn'} />;
    }

    return batches.map((i, index) => {
      return (
        <ListItem
          style={{
            borderRadius: '5px',
            backgroundColor:
              index % 2 === 1 ? 'rgba(247, 247, 247, 0.65)' : 'inherit',
          }}>
          <Grid container>
            <Grid item xs={2}>
              <div
                style={{
                  marginTop: '4px',
                  textAlign: 'left',
                }}>
                <Avatar
                  maxInitials={5}
                  size={45}
                  name={i.name}
                  round={10}
                  initials={(i) => {
                    console.log(i);
                    return i;
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <span
                  style={{
                    fontSize: '16px',
                  }}>
                  <span
                    style={{
                      position: 'relative',
                      top: '2px',
                    }}>
                    <span
                      style={{
                        position: 'relative',
                        top: '2px',
                      }}>
                      <Icons.Batch size={14} />
                    </span>
                    <span
                      style={{
                        marginLeft: '4px',
                      }}>
                      {i.name}
                    </span>
                  </span>
                </span>
              </div>
              <div
                style={{
                  marginTop: '10px',
                  fontSize: '12px',
                  color: 'gray',
                }}>
                <div
                  style={{
                    marginBottom: '4px',
                  }}>
                  Created on:
                  {i.createdAt && App.utils.time.fullFormat(i.createdAt, true)}
                </div>
                {i.expiration && (
                  <div>
                    Expires on:{' '}
                    {helpers.computeTimeStr({
                      date: i.expiration.expirationDate,
                      unit: i.expiration.unit,
                    })}{' '}
                    -{' '}
                    <b>{App.utils.time.timeAgo(i.expiration.expirationDate)}</b>
                  </div>
                )}
              </div>
              {i.cost && (
                <div
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                  }}>
                  Cost: {i.cost.total} AED
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <div
                style={{
                  textAlign: 'right',
                }}>
                <Quantity
                  enableChangeDirAnimation={false}
                  quantity={i.quantity}
                  unitLabel={item.type.unit}
                />
              </div>
            </Grid>
          </Grid>
        </ListItem>
      );
    });
  };
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <TopBar
          title={`Batches`}
          icon={<Icons.Batch />}
          onClose={onClose}
          filterProps={{
            placeholder: 'search batches',
            onFilter: (v) => {
              setFilterValue(v);
            },
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          marginTop: '-22px',
        }}>
        <List>{renderItemGroups()}</List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

BatchesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BatchesDialog;
