import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CloseBtn } from '@meronex/components';
import { constructionImage } from '../assets/index';

function ConstructionDialog(props) {
  const { open, onClose, msg = 'This section is under construction.' } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        Coming Soon
        <CloseBtn onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div
            style={{
              fontSize: '15px',
              marginTop: '-10px',
              marginBottom: '10px',
            }}>
            {msg}
          </div>
          <div>
            <img
              style={{
                borderRadius: '10px',
                width: '100%',
                marginTop: '10px',
              }}
              src={constructionImage}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConstructionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  msg: PropTypes.string,
};

export default ConstructionDialog;
