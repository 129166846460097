import { App } from '@meronex/app';

const { server } = App;

export const newsMng = {};

const getNewsSectionFeed = async (newsSection) => {
  const cards = await server.call('getNewsSectionFeed', { newsSection });
  return cards;
};

const createNewsSection = async (newsSection) => {
  const result = await server.call('createNewsSection', { newsSection });
  return result;
};

const getUserNewsSections = async (newsSection) => {
  const result = await server.call('getUserNewsSections', { newsSection });
  return result;
};
const updateNewsSection = async (newsSection) => {
  const result = await server.call('updateNewsSection', { newsSection });
  return result;
};

const saveUpdatedNewsSort = async (updatedList) => {
  const result = await server.call('saveUpdatedNewsSort', { updatedList });
  return result;
};

const deleteNewsSection = async (newsSection) => {
  const result = await server.call('deleteNewsSection', { newsSection });
  return result;
};
newsMng.getNewsSectionFeed = getNewsSectionFeed;
newsMng.getUserNewsSections = getUserNewsSections;
newsMng.createNewsSection = createNewsSection;
newsMng.saveUpdatedNewsSort = saveUpdatedNewsSort;
newsMng.updateNewsSection = updateNewsSection;
newsMng.deleteNewsSection = deleteNewsSection;
