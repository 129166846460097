import React from 'react';

import moment from 'moment';
import { Radixer } from '../../../common/radixer';
import { App } from '@meronex/app';

const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const workOrderHelpers = {
  computeBatchNum: (date, seqNum) => {
    console.log(`compute batch number, seqNum: ${seqNum}`);
    let batchNum = '';

    const momentDateStr = moment(date).format('DDMMYY').toString();
    const dayNum = parseFloat(momentDateStr.substr(0, 2));
    const monthNum = parseFloat(momentDateStr.substr(2, 2));
    const yearStr = momentDateStr.substr(4, 2);

    // day
    if (dayNum < 10) {
      batchNum += `${dayNum % 10}A`;
    } else if (dayNum < 20) {
      batchNum += `${dayNum % 10}B`;
    } else if (dayNum < 30) {
      batchNum += `${dayNum % 10}C`;
    } else if (dayNum < 40) {
      batchNum += `${dayNum % 10}D`;
    }

    switch (monthNum) {
      case 1:
        batchNum += 'J';
        break;
      case 2:
        batchNum += 'K';
        break;
      case 3:
        batchNum += 'L';
        break;
      case 4:
        batchNum += 'M';
        break;
      case 5:
        batchNum += 'N';
        break;
      case 6:
        batchNum += 'O';
        break;
      case 7:
        batchNum += 'P';
        break;
      case 8:
        batchNum += 'Q';
        break;
      case 9:
        batchNum += 'R';
        break;
      case 10:
        batchNum += 'S';
        break;
      case 11:
        batchNum += 'T';
        break;
      case 12:
        batchNum += 'U';
        break;
      default:
        batchNum += '?';
    }

    //year
    batchNum += yearStr.charAt(yearStr.length - 1);

    //wo digit
    const seqDigit = alphabets[seqNum % 26];
    batchNum += seqDigit;

    return batchNum;
  },

  getPackagedQuantity: (workOrder) => {
    if (workOrder && workOrder.product && workOrder.product.packaging) {
      return (
        <span>
          {App.utils.number.thousandsSeparator(
            (workOrder.producedQuantity || 0) / workOrder.product.packaging.rate
          )}{' '}
          {workOrder.product.packaging.unit}
        </span>
      );
    }
    return <span />;
  },
};
