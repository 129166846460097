import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoadingBtn(props) {
  const { loading, onClick, style } = props;
  const classes = useStyles();
  const theme = useTheme();

  const mergedStyle = Object.assign({}, style);
  return (
    <div className={classes.wrapper}>
      <Button
        style={mergedStyle}
        variant="contained"
        color={'primary'}
        disabled={loading}
        onClick={props.onClick}>
        {props.children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}

LoadingBtn.prototypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  loading: PropTypes.loading,
};
