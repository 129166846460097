import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import MdArrowBack from '@meronex/icons/md/MdArrowBack';
import Button from '@material-ui/core/Button';

import { useForm } from 'react-hook-form';

import SaveBtn from '../../../components/buttons/SaveBtn';
import AvatarUploader from './avatarUploader/AvatarUploader';

import { updateProfile } from './api';
import { App } from '../../../base/AppContext';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'rgb(14, 14, 14)',
    height: '60px',
    color: theme.appBar.color,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfileDialog(props) {
  const classes = useStyles();

  const { open, onClose, user } = props;
  const [charRemaining, setCharRemaining] = React.useState(140);

  const [avatar, setAvatar] = useState();
  const [unsavedProfile, setUnsavedProfile] = React.useState(false);

  const [showUnsavedDialog, setShowUnsavedDialog] = React.useState(false);

  const { register, setValue, errors, getValues, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (user && user.profile && user.profile.avatar) {
      setAvatar(user.profile.avatar);
    }
  }, []);
  const handleClose = () => {
    setShowUnsavedDialog(false);
    onClose();
  };

  const onImageUpload = (imgSrc) => {
    setAvatar(imgSrc);
    setUnsavedProfile(true);
  };

  const onSave = async () => {
    //Do some validation here
    const { onUpdate } = props;
    const result = await triggerValidation();
    if (result) {
      const profile = getValues();
      profile.src = avatar;
      await updateProfile(profile);
      toast.success('Profile successfully updated');
      if (onUpdate) {
        onUpdate();
      }
      setUnsavedProfile(false);
      handleClose();
    } else {
      console.log(errors);
    }
  };

  const profile = user.profile;

  return (
    <Dialog
      fullScreen={!!App.utils.isMobile()}
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              if (unsavedProfile) {
                setShowUnsavedDialog(true);
              } else {
                onClose();
              }
            }}
            aria-label="close">
            <MdArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Edit Profile
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper
        style={{
          padding: '10px',
          margin: '10px',
        }}>
        <Typography variant="h6" gutterBottom>
          Profile
        </Typography>
        <Typography paragraph variant="body2">
          Some info might be visible for other members when using the
          application.
        </Typography>
        <Typography variant="overline">PHOTO</Typography>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography paragraph variant="body2">
              A photo helps personalize your account.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <AvatarUploader
              name={'src'}
              ref={register()}
              onUpload={(imgSrc) => onImageUpload(imgSrc)}
              src={profile.avatar}
            />
          </Grid>
        </Grid>
        <Typography variant="overline">Name</Typography>
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          <TextField
            id="first-name"
            name={'firstName'}
            inputRef={register({
              required: 'Must have first name.',
              maxLength: {
                value: 30,
                message: 'Cannot be more than 30 characters.',
              },
              pattern: {
                value: /^[A-Za-z]+$/,
                message:
                  'First name must be alphabetical with no spaces or special characters.',
              },
            })}
            error={errors.firstName}
            helperText={(errors.firstName || {}).message}
            label="First Name"
            fullWidth
            onChange={() => {
              setUnsavedProfile(true);
            }}
            defaultValue={profile.firstName}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="last-name"
            label="Last Name"
            name={'lastName'}
            onChange={() => {
              setUnsavedProfile(true);
            }}
            inputRef={register({
              required: 'Must have last name.',
              maxLength: {
                value: 30,
                message: 'Cannot be more than 30 characters.',
              },
              pattern: {
                value: /^[a-zA-Z\s-]+$/,
                message:
                  'Last name must be alphabetical and accepts spaces/hyphens.',
              },
            })}
            error={errors.lastName}
            helperText={(errors.lastName || {}).message}
            fullWidth
            margin="normal"
            defaultValue={profile.lastName}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </div>
        <Typography variant="overline">About</Typography>
        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            fullWidth
            inputRef={register}
            name={'about'}
            rows={4}
            defaultValue={profile.about}
            onChange={(event) => {
              const value = event.target.value;
              const remainingChar = 140 - value.length;
              if (remainingChar > -1) {
                setCharRemaining(140 - value.length);
              }
              setUnsavedProfile(true);
            }}
            variant="outlined"
          />
          <div
            style={{
              marginTop: '5px',
              color: charRemaining !== 0 ? 'gray' : '#9e0000',
            }}>
            {charRemaining} characters remaining
          </div>
        </div>
        <Typography variant="overline">Email</Typography>
        <div style={{ marginTop: '10px' }}>
          <TextField
            disabled
            fullWidth
            defaultValue={user.emails[0].address}
            name={'email'}
            variant="outlined"
            id="standard-disabled"
          />
        </div>
        <SaveBtn onClick={() => onSave()} />
      </Paper>{' '}
      <Dialog
        open={showUnsavedDialog}
        onClose={() => {
          setShowUnsavedDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Unsaved Profile Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changed, are you sure you want to go back?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowUnsavedDialog(false);
            }}
            color="primary">
            No
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

ProfileDialog.propTypes = {
  user: PropTypes.object,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
