import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import IOut from '@meronex/icons/bi/BiExport';
import IIn from '@meronex/icons/bi/BiImport';
import Fade from '@material-ui/core/Fade/Fade';
import { App } from '@meronex/app';

function Quantity(props) {
  const {
    quantity,
    quantitySize,
    unitLabel,
    unitLabelStyle,
    style,
    inlineUnit,
    textAlign,
    float,
    enableChangeDirAnimation,
    changeDirStyle,
    enableAutoSizeShrink,
    showUnitLabel,
    showPositiveSign,
    decimals,
  } = props;

  const [animating, setAnimating] = React.useState();
  const [currentQuantity, setCurrentQuantity] = React.useState(quantity);
  const [direction, setDirection] = React.useState(); // up or down

  const { countDecimals, countDigits } = App.utils.number;

  const getShrinkSize = (number) => {
    if (!enableAutoSizeShrink) {
      return number;
    }
    const digits = countDigits(number);
    if (digits < 2) {
      return 'shrink shrink-1';
    } else if (digits < 3) {
      return 'shrink shrink-2';
    } else if (digits < 4) {
      return 'shrink shrink-3';
    } else if (digits < 5) {
      return 'shrink shrink-4';
    } else if (digits < 6) {
      return 'shrink shrink-5';
    } else if (digits < 7) {
      return 'shrink shrink-6';
    } else if (digits < 8) {
      return 'shrink shrink-7';
    } else if (digits < 9) {
      return 'shrink shrink-8';
    } else if (digits < 10) {
      return 'shrink shrink-9';
    } else if (digits < 11) {
      return 'shrink shrink-10';
    } else if (digits < 12) {
      return 'shrink shrink-11';
    } else if (digits < 13) {
      return 'shrink shrink-12';
    } else if (digits >= 13) {
      return 'shrink shrink-13';
    }
    return '';
  };

  React.useEffect(() => {
    if (quantity > currentQuantity) {
      setDirection('up');
    } else if (quantity < currentQuantity) {
      setDirection('down');
    } else {
      setDirection('');
    }
    setCurrentQuantity(quantity);
  }, [quantity]);

  const mergedStyle = Object.assign(
    {
      position: 'relative',
      fontSize: quantitySize,
      color: quantity >= 0 ? '#556cd6' : 'red',
      float,
      display: 'inline-block',
      textAlign,
    },
    style
  );

  const mergedUnitLabelStyle = Object.assign(
    {
      color: 'gray',
      display: 'block',
      float: 'none',
      textAlign: 'center',
      fontSize: '12px',
    },
    unitLabelStyle
  );

  const getDecimalsCount = () => {
    let decimalsCount = countDecimals(_quantity);
    if (decimalsCount > 2) {
      return 2;
    }
    return decimalsCount;
  };
  let _quantity = quantity;

  const _decimals =
    typeof decimals === 'number' ? decimals : getDecimalsCount();
  if (_decimals) {
    _quantity = App.utils.number.round(quantity, _decimals);
  }

  const changeDirMergedStyle = Object.assign(
    {
      display: 'inline-block',
      position: 'relative',
      top: '0px',
      left: '0px',
      width: 'inherit',
      textAlign: 'center',
    },
    changeDirStyle
  );

  return (
    <div style={{ position: 'relative' }}>
      {enableChangeDirAnimation && (
        <div>
          <Fade in={animating}>
            <div style={changeDirMergedStyle}>
              {direction === 'down' && <IOut size={20} color={'red'} />}
              {direction === 'up' && <IIn size={20} color={'green'} />}
            </div>
          </Fade>
        </div>
      )}

      <div style={mergedStyle}>
        {showPositiveSign && quantity > 0 ? '+' : ''}
        <div
          className={`quantity ${getShrinkSize(_quantity)}`}
          style={{
            display: inlineUnit ? 'inline-block' : 'block',
            position: 'relative',
          }}>
          <CountUp
            useEasing
            end={_quantity}
            preserveValue
            onUpdate={() => {
              setAnimating(true);
              setTimeout(() => {
                setAnimating(false);
              }, 1200);
            }}
            duration={1}
            separator={','}
            decimals={_decimals}
          />
        </div>
        {showUnitLabel && <div style={mergedUnitLabelStyle}>{unitLabel}</div>}
      </div>
    </div>
  );
}
Quantity.propTypes = {
  quantity: PropTypes.number,
  unitLabel: PropTypes.string,
  showUnitLabel: PropTypes.bool,
  quantitySize: PropTypes.number,
  unitLabelStyle: PropTypes.object,
  inlineUnit: PropTypes.bool,
  enableChangeDirAnimation: PropTypes.bool,
  changeDirStyle: PropTypes.object,
  enableAutoSizeShrink: PropTypes.bool,
  style: PropTypes.object,
  textAlign: PropTypes.string,
  float: PropTypes.string,
  decimals: PropTypes.number,
  showPositiveSign: PropTypes.bool,
};

Quantity.defaultProps = {
  quantitySize: '22px',
  unitLabelStyle: {
    fontSize: '12px',
  },
  textAlign: 'center',
  float: 'none',
  inlineUnit: false,
  enableAutoSizeShrink: false,
  enableChangeDirAnimation: true,
  showUnitLabel: true,
  showPositiveSign: false,
};
export default Quantity;
