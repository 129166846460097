import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

export default function Section(props) {
  const { node, contentClassName } = props;

  return (
    <div style={props.style}>
      <Title
        title={props.title}
        subtitle={props.subtitle}
        style={props.titleStyle}
      />

      <div
        style={{
          height: props.height ? `${props.height}px` : '100%',
        }}>
        {node && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}>
            {node}
          </div>
        )}
        {!node && (
          <div
            className={contentClassName}
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              display: 'flex',
              flex: `0 0 ${props.itemsWidth}px`,
              whiteSpace: 'nowrap',
              padding: props.padding || '12px 1px',
            }}>
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
}
Section.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  height: PropTypes.number,
  itemsWidth: PropTypes.number,
  titleStyle: PropTypes.object,
  style: PropTypes.object,
  loading: PropTypes.bool,
  loadingNode: PropTypes.node,
  contentClassName: PropTypes.string,
};

export const Item = function (props) {
  return (
    <div
      style={{
        display: 'inline-block',
        cursor: 'pointer',
        marginRight: '10px',
      }}>
      {props.children}
    </div>
  );
};

Section.Item = Item;

export const SectionItem = function (props) {
  return (
    <div
      style={{
        display: 'inline-block',
        cursor: 'pointer',
        marginRight: '10px',
      }}>
      {props.children}
    </div>
  );
};

Section.SectionItem = SectionItem;
