import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//TODO: replace with <Icons.X/>

import Equipments from '../Equipments';

import { Icons } from '../../common/Icons';
import TopHeader from '../../views/components/TopHeader';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EquipmentsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, factory } = props;

  const [filterValue, setFilterValue] = React.useState();

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <TopHeader
          title={'Equipments'}
          icon={<Icons.Equipments />}
          onClose={onClose}
          filterProps={{
            onFilter: (v) => {
              setFilterValue(v);
            },
            placeholder: 'search by name or id',
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <div>
          <Equipments filterValue={filterValue} factory={factory} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EquipmentsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EquipmentsDialog;
