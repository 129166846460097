import React from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loading } from '@meronex/components';

import StoreItemsList from './components/storeItems/StoreItemsList';
import StoreDrawer from './components/stores/StoreDrawer';
import ItemTypesDialog from './components/itemTypes/ItemTypesDialog';
import ArchivedStoreItemsDialog from './components/storeItems/ArchivedStoreItemsDialog';

import AddStoreProductDialog from '../factories/components/products/AddStoreProductDialog';

import loadingStoresGif from './components/loadingStores.gif';
import AuditStoreDialog from './components/stores/AuditStoreDialog';
import ScanQrCode from './components/storeItems/actions/ScanQRCode';
import StoresLabelDialog from './components/storeItems/labels/StoreLabelsDialog';

import { storesMng } from './api/storesMng';
import ExportStoreItemsDialog from './components/storeItems/ExportStoreItemsDialog';

function Stores(props) {
  const {
    storeItems,
    fetchStores, // this will result in the item list to update
    permissions,
    selectedStore,
    filters,
    initStores,
  } = props;

  const [itemTypes, setItemTypes] = React.useState();
  const [openAddStoreItem, setOpenAddStoreItem] = React.useState(false);
  const [openAddStoreProduct, setOpenAddStoreProduct] = React.useState(false);
  const [selectedStoreItem, setSelectedStoreItem] = React.useState(undefined);
  const [storeDrawerOpen, setStoreDrawerOpen] = React.useState(false);
  const [openArchives, setOpenArchives] = React.useState(false);
  const [openAuditStoreDialog, setOpenAuditStoreDialog] = React.useState(false);
  const [openScanQrCode, setOpenScanQrCode] = React.useState(false);
  const [openExportStoreItems, setOpenExportStoreItems] = React.useState();
  const [openStoreLabelsDialog, setOpenStoreLabelsDialog] = React.useState();

  const fetchItemTypes = async () => {
    const storeItemTypes = await storesMng.getStoreItemTypes(selectedStore._id);
    setItemTypes(storeItemTypes);
  };
  const deleteStoreItem = async () => {
    if (selectedStoreItem) {
      const result = await storesMng.deleteStoreItem(selectedStoreItem._id);
      fetchStores();
    }
  };

  const renderBody = () => {
    if (!selectedStore) {
      if (initStores) {
        initStores();
      }
      return <Loading type="img" url={loadingStoresGif} />;
    }
    return (
      <div className={'noselect'} style={{ marginBottom: '20px' }}>
        <StoreItemsList
          store={selectedStore}
          filters={filters}
          storeDrawerOpen={storeDrawerOpen}
          onItemClick={(item) => {
            setSelectedStoreItem(item);
          }}
          permissions={permissions}
          onAddStoreItem={() => {
            setOpenAddStoreItem(true);
          }}
          onAddStoreProduct={() => {
            setOpenAddStoreProduct(true);
          }}
          deleteItem={() => {
            deleteStoreItem();
          }}
        />
      </div>
    );
  };

  return (
    <div>
      {renderBody()}
      <ArchivedStoreItemsDialog
        open={openArchives}
        permissions={permissions}
        store={selectedStore}
        onRemoveStoreItem={() => {
          fetchStores();
        }}
        onRestoreStoreItem={() => {
          fetchStores();
        }}
        onClose={() => {
          setOpenArchives(false);
        }}
      />
      {openAuditStoreDialog && (
        <AuditStoreDialog
          store={selectedStore}
          open={openAuditStoreDialog}
          onClose={() => {
            setOpenAuditStoreDialog(false);
          }}
        />
      )}
      {openScanQrCode && (
        <ScanQrCode
          open={openScanQrCode}
          storeItems={storeItems}
          openItem={(item) => {
            setSelectedStoreItem(item);
          }}
          onClose={() => {
            setOpenScanQrCode(false);
          }}
        />
      )}

      <StoreDrawer
        selectedStore={selectedStore}
        onAddItem={() => {
          setOpenAddStoreItem(true);
        }}
        permissions={permissions}
        onAddProduct={() => {
          setOpenAddStoreProduct(true);
        }}
        onAuditStore={() => {
          setOpenAuditStoreDialog(true);
        }}
        onOpen={() => {
          setStoreDrawerOpen(true);
        }}
        onScanQRCode={() => {
          setOpenScanQrCode(true);
        }}
        onClose={() => {
          setStoreDrawerOpen(false);
        }}
        onOpenArchives={() => {
          setOpenArchives(true);
        }}
        onExportToCsv={() => {
          setOpenExportStoreItems(true);
        }}
        onGenerateLabels={() => {
          console.log('generate labels');
          setOpenStoreLabelsDialog(true);
        }}
      />
      {openAddStoreItem && (
        <ItemTypesDialog
          onAdd={async () => {
            fetchStores();
          }}
          permissions={permissions}
          onItemTypeDelete={async (cb) => {
            await fetchItemTypes();
            if (typeof cb === 'function') {
              cb();
            }
            fetchStores();
          }}
          onItemTypeUpdate={async (cb) => {
            await fetchItemTypes();

            if (typeof cb === 'function') {
              cb();
            }
          }}
          onItemTypeCreate={async (cb) => {
            await fetchItemTypes();
            if (typeof cb === 'function') {
              cb();
            }
          }}
          storeItems={storeItems}
          open={openAddStoreItem}
          store={selectedStore}
          onClose={() => {
            setOpenAddStoreItem(false);
          }}
        />
      )}
      {openAddStoreProduct && (
        <AddStoreProductDialog
          open={openAddStoreProduct}
          onProductAdd={async () => {
            toast.success('Product successfully added.');
            setOpenAddStoreProduct(false);

            // this will cause store items to refresh
            // store items list has useEffect on the selected store
            // which will get refresh due to this call
            fetchStores();
          }}
          storeItems={storeItems}
          store={selectedStore}
          onClose={() => {
            setOpenAddStoreProduct(false);
          }}
        />
      )}
      {openExportStoreItems && (
        <ExportStoreItemsDialog
          store={selectedStore}
          open={openExportStoreItems}
          onClose={() => {
            setOpenExportStoreItems(false);
          }}
        />
      )}
      {openStoreLabelsDialog && (
        <StoresLabelDialog
          store={selectedStore}
          open={openStoreLabelsDialog}
          onClose={() => {
            setOpenStoreLabelsDialog(false);
          }}
        />
      )}
    </div>
  );
}
Stores.propTypes = {};

Stores.defaultProps = {};
export default Stores;
