import React from 'react';
import PropTypes from 'prop-types';

import AvatarGroup from '@material-ui/lab/AvatarGroup/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';

import { App } from '@meronex/app';
import { CloseBtn } from '@meronex/components';

import { logMng } from '../../api';
import logsUtil from './logsUtils';

const { utils } = App;

export default function OpenedBy(props) {
  const [open, setOpen] = React.useState(false);
  const [openedBy, setOpenedBy] = React.useState(undefined);
  const [fetching, setFetching] = React.useState();
  const theme = useTheme();

  React.useEffect(() => {
    fetchOpenedBy();
  }, []);
  const fetchOpenedBy = async () => {
    setFetching(true);
    const openedByUsers = await logMng.fetchOpenedBy(props.card._id);
    setOpenedBy(openedByUsers);
    setFetching(false);
  };

  const renderSeenBy = () => {
    if (fetching || !openedBy) {
      return <LinearProgress style={{ marginTop: '10px', width: '200px' }} />;
    } else
      return (
        <AvatarGroup max={3}>
          {openedBy.map((o) => {
            return (
              <Avatar
                key={o.user._id}
                alt={`${o.user.profile.firstName} ${o.user.profile.lastName}`}
                src={`${o.user.profile.avatar ? o.user.profile.avatar : ''}`}
              />
            );
          })}
        </AvatarGroup>
      );
  };
  return (
    <>
      <div
        style={{
          height: '60px',
        }}
        onClick={() => {
          setOpen(true);
        }}>
        <Grid container>
          <Grid item xs={3} sm={3}>
            <div
              style={{
                display: 'inline-block',
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
              }}>
              Opened By:
            </div>
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
              }}
            />
          </Grid>
          <Grid item xs={9} sm={9}>
            <div>{renderSeenBy()}</div>
          </Grid>
        </Grid>
      </div>
      {openedBy && (
        <Dialog
          onClose={() => {
            setOpen(false);
          }}
          fullWidth
          aria-labelledby="simple-dialog-title"
          open={open}>
          <DialogTitle
            id="simple-dialog-title"
            style={{
              padding: '10px',
              marginBottom: '-10px',
            }}>
            Opened By
            <CloseBtn onClick={() => setOpen(false)} />
          </DialogTitle>
          <DialogContent>
            <List>
              {openedBy.map((o) => {
                return (
                  <ListItem key={o.user._id}>
                    <ListItemAvatar>
                      <Avatar
                        alt={utils.getUserName(o.user)}
                        src={utils.getUserAvatar(o.user)}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={utils.getUserName(o.user)}
                      secondary={
                        <div>
                          <div>{logsUtil.timeFullFormat(o.date)}</div>
                          <div>{logsUtil.timeAgo(o.date)}</div>
                        </div>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
OpenedBy.propTypes = {
  card: PropTypes.object,
};
