import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { App } from '@meronex/app';
import { CloseBtn, SaveBtn, ImageUploader } from '@meronex/components';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import SitesSelect from '../common/SitesSelect';
import incidentsUtils from './incidentsUtils';

const { server } = App;
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  uploadBtnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function IncidentsDialog(props) {
  const classes = useStyles();
  const { open, onClose, sites } = props;

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);

  const [uploading, setUploading] = React.useState();
  const [descError, setDescError] = React.useState(false);

  const [formData, setFormData] = React.useState({
    priority: 'urgent',
    type: incidentsUtils.defaultIncident,
    description: '',
  });

  const previewContainerRef = React.useRef();

  const renderIncidentsOptions = () => {
    const incidentOptions = incidentsUtils.getIncidentsOptions();
    return incidentOptions.map((opt, index) => (
      <MenuItem key={index} value={opt.key}>
        {opt.label}
      </MenuItem>
    ));
  };

  const onSave = async () => {
    const noImg = !imgUrl || imgUrl.length < 1;
    const noDescription = !formData || !formData.description;

    if (noImg && noDescription) {
      if (noDescription) {
        setDescError(true);
      }
      toast.error('Either an image or description is required!');
      return;
    }

    formData.imgUrl = imgUrl;
    formData.thumbUrl = thumbUrl;

    try {
      const id = await server.call('addLogCard', {
        logDetails: formData,
        logType: 'incident',
      });

      formData._id = id;
      toast.success('Incidents was successfully added!');
    } catch (e) {
      alert(e);
      console.log(e);
    }
    if (props.onSave) {
      props.onSave();
    }
    if (props.onClose) {
      props.onClose();
    }
    reset();
  };

  const reset = () => {
    setDescError(false);
    setImgUrl(null);
    setThumbUrl(null);

    setFormData({
      priority: 'urgent',
      type: incidentsUtils.defaultIncident,
      description: '',
    });
  };

  return (
    <div>
      <Dialog
        className={'fullScreenMobile'}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle>
          Add Incident
          <CloseBtn
            onClick={() => {
              onClose();
              reset();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <ImageUploader
              previewContainer={previewContainerRef}
              onUploadStart={() => {
                setUploading(true);
              }}
              onUploadSuccess={(imageData) => {
                setImgUrl(imageData.imgUrl);
                setThumbUrl(imageData.thumbUrl);
                setUploading(false);
              }}
            />
            <TextField
              label="Description"
              style={{ margin: 8 }}
              value={formData.description}
              onChange={(e) => {
                const updatedData = { ...formData };
                updatedData.description = e.target.value;
                setFormData(updatedData);
              }}
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={descError}
            />
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Priority</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.priority}
                fullWidth
                onChange={(e) => {
                  const updatedData = { ...formData };
                  updatedData.priority = e.target.value;
                  setFormData(updatedData);
                }}>
                <MenuItem value={'urgent'}>Urgent</MenuItem>
                <MenuItem value={'high'}>High</MenuItem>
                <MenuItem value={'medium'}>Medium</MenuItem>
                <MenuItem value={'low'}>Low</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.type}
                fullWidth
                onChange={(e) => {
                  const updatedData = { ...formData };
                  updatedData.type = e.target.value;
                  setFormData(updatedData);
                }}>
                {renderIncidentsOptions()}
              </Select>
            </FormControl>
            <SitesSelect
              classes={classes}
              formData={formData}
              setFormData={setFormData}
              sites={sites}
            />
          </div>
          <SaveBtn disabled={uploading} onClick={onSave} />
          <div ref={previewContainerRef} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

IncidentsDialog.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

IncidentsDialog.defaultProps = {
  open: false,
  onSave: () => {},
  onClose: () => {},
};
