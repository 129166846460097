import React from 'react';
import { App } from '@meronex/app';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { ConstructionDialog } from '@meronex/components';

import IStores from '@meronex/icons/mdc/MdcWarehouse';

import MenuTile from './MenuTile';
import { sitesMng } from '../../sites/api';
import { Icons } from '../../common/Icons';
import { can, p } from '../../common/roles';

export default function MngMenu(props) {
  const [sites, setSites] = React.useState([]);
  const [userLoaded, setUserLoaded] = React.useState(false);

  const [openConstructionDialog, setOpenConstructionDialog] = React.useState(
    false
  );
  const fetchSites = async () => {
    console.log('fetch sites..');
    try {
      const sites = await sitesMng.getSites();
      console.log(sites);
      setSites(sites);
      App.eventsManager.emit('event-sites-loaded', MngMenu.name, sites);
    } catch (e) {
      console.log(e);
    }
  };

  const onUserLoaded = () => {
    setUserLoaded(true);
  };

  React.useEffect(() => {
    App.eventsManager.on('event-user-loaded', 'MngMneu', onUserLoaded);
    return () => {
      App.eventsManager.removeListener(
        'event-user-loaded',
        'SideMenu',
        onUserLoaded
      );
    };
  }, []);

  React.useEffect(() => {
    // code to run on component mount
    fetchSites();
    // ensure that we call fetch sites when reconnect, this happen
    // on refresh the browser or reconnection while the page is on.
    App.eventsManager.on('event-connected', MngMenu, () => {
      fetchSites();
    });
  }, []);

  const openFormFunc = (openForm) => {
    if (!sites || sites.length < 1) {
      toast.error('No sites available, please ask admin to add one.');
    } else {
      openForm(true);
    }
  };
  const setActiveView = (view) => {
    App.eventsManager.emit('event-active-view', MngMenu.name, {
      activeView: view,
    });
  };

  if (!App.getUser() && !userLoaded) {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={'/images/homeLoading.gif'} style={{ maxWidth: '100%' }} />
      </div>
    );
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
          flexWrap: 'wrap',
          overflow: 'auto',
        }}>
        {can(p.globals.accessStores) && (
          <MenuTile
            title={'Stores'}
            imgUrl={'/images/stores.png'}
            icon={<IStores />}
            onClick={() => {
              setActiveView('stores');
            }}
            bgColor={'rgb(243 37 71)'}
          />
        )}
        {can(p.globals.accessFactories) && (
          <MenuTile
            title={'Production'}
            icon={<Icons.Factories />}
            imgUrl={'/images/factories.png'}
            onClick={() => {
              setActiveView('factories');
            }}
            bgColor={'#17BF5F'}
          />
        )}
        {can(p.globals.accessEquipments) && (
          <MenuTile
            title={'Equipments'}
            icon={<Icons.Equipments />}
            imgUrl={'/images/equipments.png'}
            onClick={() => {
              setActiveView('equipments');
            }}
            bgColor={'#0063AB'}
          />
        )}
        {can(p.globals.viewConfigurations) && (
          <MenuTile
            title={'Configurations'}
            icon={<Icons.Settings />}
            imgUrl={'/images/configurations.png'}
            onClick={() => {
              setActiveView('configurations');
            }}
            bgColor={'#F6D12D'}
          />
        )}
      </div>
      <ConstructionDialog
        open={openConstructionDialog}
        onClose={() => {
          setOpenConstructionDialog(false);
        }}
      />
    </div>
  );
}
