import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { App } from '@meronex/app';
import { SaveBtn, CloseBtn, ImageUploader, Form } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ItemTypeForm from './ItemTypeForm';

const { server } = App;

const useStyles = makeStyles((theme) => ({
  container: {},
  uploadBtnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  uploadBtn: {
    borderRadius: '20px',
    width: '100%',
    height: '45px',
    marginTop: '5px',
    marginBottom: '25px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function AddItemTypeDialog(props) {
  const { open, onClose, onAdd, store } = props;
  const classes = useStyles();
  const itemForm = React.useRef();

  const [formData, setFormData] = React.useState({});
  const [uploading, setUploading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const onSave = async () => {
    setLoading(true);
    if (formData && formData.values) {
      const item = formData.values;
      if (formData.values.image) {
        item.image = {
          imgUrl: formData.values.image.imgUrl,
          thumbUrl: formData.values.image.thumbUrl,
        };
      } else {
        item.image = undefined;
      }

      try {
        console.log(item);
        const id = await server.call('addStoreItemType', item);
        toast.success('Store type was successfully added!', {
          autoClose: 1200,
        });
      } catch (e) {
        console.log(e);
      }

      if (typeof onAdd === 'function') {
        onAdd(() => {
          setLoading(false);

          if (props.onClose) {
            props.onClose();
          }
          reset();
        });
      }
    }
  };

  function reset() {
    if (itemForm.current) {
      itemForm.current.reset();
    }
  }
  return (
    <div>
      <Dialog
        className={'fullScreenMobile'}
        maxWidth={'md'}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle>
          Add New Item Type{' '}
          <CloseBtn
            onClick={() => {
              reset();
              onClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <ItemTypeForm
              ref={itemForm}
              store={store}
              validateOnInit={false}
              onUpdate={(d) => {
                setUploading(d.uploading);
                setFormData(d);
              }}
            />
          </div>
          <SaveBtn
            loading={loading || uploading}
            disabled={loading || uploading || !formData.isValid}
            onClick={onSave}
            label={'Add'}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

AddItemTypeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  store: PropTypes.object,
};
