import React from 'react';
import PropTypes from 'prop-types';
import ReactFlow, { ArrowHeadType } from 'react-flow-renderer';

import { App } from '@meronex/app';

import ActionEdge from './ActionEdge';
import { actionHelpers } from './actionHelpers';

const edgeTypes = {
  actionEdge: ActionEdge,
};

function ActionTransferFlow(props) {
  const { item, selectedStore, type, quantity = 0 } = props;

  const data = {
    quantity,
    item,
    selectedStore,
    type,
  };

  const actionData = actionHelpers.getActionData(data);

  const modal = document.getElementById('transfer-window');

  const elements = [
    {
      id: '1',
      type: 'default', // input node
      selectable: false,
      draggable: false,
      dragHandle: false,
      data: {
        label: actionData.sourceLabel,
      },
      position: { x: 0, y: 25 },
      sourcePosition: 'right',
      style: {
        height: '55px',
        width: '100px',
        top: '5px',
        border: 'none',
        textAlign: 'center',
      },
    },
    // default node
    {
      id: '2',
      type: 'default', // input node
      selectable: false,
      draggable: false,
      // you can also pass a React component as a label
      data: {
        label: actionData.targetLabel,
      },
      position: {
        x: modal ? modal.clientWidth - 135 : 200,
        y: 25,
      },
      targetPosition: 'right',
      style: {
        height: '55px',
        width: '100px',
        top: '5px',
        border: 'none',
        textAlign: 'center',
      },
    },
    // animated edge
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      animated: true,
      type: 'actionEdge',
      data: { edgeLabel: actionData.edgeLabel },
      arrowHeadType: ArrowHeadType.Arrow,
    },
  ];

  return (
    <div
      style={{ height: 180, marginBottom: '10px', marginTop: '10px' }}
      className={'transfer-flow'}>
      <ReactFlow
        zoomOnDoubleClick={false}
        minZoom={1}
        maxZoom={1}
        elements={elements}
        panOnScrollMode={'horizontal'}
        nodesDraggable={false}
        paneMoveable={false}
        edgeTypes={edgeTypes}
        markerEndId={'custom-arrow'}>
        <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <marker
              id="custom-arrow"
              viewBox="0 0 10 10"
              refX="8"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="8"
              markerHeight="8"
              orient="auto">
              <path d="M 0 0 L 10 5 L 0 10 z" fill="#919191" />
            </marker>
          </defs>
        </svg>
      </ReactFlow>
    </div>
  );
}
ActionTransferFlow.propTypes = {
  item: PropTypes.object,
  type: PropTypes.oneOf(['in', 'out']),
  source: PropTypes.object,
  target: PropTypes.object,
  quantity: PropTypes.number,
  selectedStore: PropTypes.object,
};
ActionTransferFlow.defaultProps = {};

export default ActionTransferFlow;
