import React from 'react';
import PropTypes from 'prop-types';

import IProduct from '@meronex/icons/fi/FiPackage';
import { App } from '@meronex/app';
import Grid from '@material-ui/core/Grid';

import Quantity from '../../../common/Quantity';
import SitesAvatar from '../../../common/SitesAvatar';

import StoreItemProducts from './StoreItemProductsDialog';
import StoreItemDetailsFields from './StoreItemDetailsFields';
import Truncate from 'react-truncate';

function StoreItemDetails(props) {
  const {
    item,
    products,
    showDetails,
    onGroupsCountClick,
    showStore,
    showTransaction,
    showBatches,
    permissions,
    showUpdated,
    showFactory,
    showId,
  } = props;

  const [openStoreItemProducts, setOpenStoreItemProducts] = React.useState();

  if (!item || typeof item.type !== 'object') {
    return null;
  }
  const name = item.type.name;
  const imgSrc = (item.type.image || {}).thumbUrl;
  const unitLabel = item.type.unit;
  const quantity = item.quantity;

  const renderBatchInfo = () => {
    if (
      item &&
      item.productionInfo &&
      item.productionInfo.product &&
      item.productionInfo.product.groupByBatch &&
      Array.isArray(item.itemGroups) &&
      item.itemGroups.length > 0
    ) {
      return (
        <div className={'noselect'}>
          <div
            onClick={() => {
              if (typeof onGroupsCountClick === 'function') {
                onGroupsCountClick();
              }
            }}
            style={{
              top: '66px',
              height: '50px',
              fontSize: '12px',
              right: '20px',
              color: onGroupsCountClick ? 'rgb(85, 108, 214)' : 'gray',
              cursor: onGroupsCountClick ? 'pointer' : '',
            }}>
            <span
              style={{
                position: 'relative',
                top: '20px',
              }}>
              {item.itemGroups.length} batches
            </span>
          </div>
        </div>
      );
    }
  };

  const renderPackaging = () => {
    if (
      item.productionInfo &&
      item.productionInfo.product &&
      item.productionInfo.product.packaging
    ) {
      const unit = item.productionInfo.product.packaging.unit;
      const rate = item.productionInfo.product.packaging.rate || 1;

      return (
        <div
          style={{
            color: 'gray',
            fontSize: '12px',
            marginTop: '5px',
          }}>
          {App.utils.number.round(quantity / rate)} {unit}
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <div style={{ textAlign: 'center' }}>
            <SitesAvatar
              image={imgSrc}
              name={item.type.name}
              size={75}
              round={10}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={7}
          style={{
            paddingLeft: '15px',
          }}>
          <div
            style={{
              fontWeight: '400',
              fontSize: '14px',
              marginBottom: '10px',
            }}>
            &nbsp;
            {item.productionInfo && (
              <span
                style={{
                  position: 'relative',
                  top: '2px',
                }}>
                <IProduct size={16} /> &nbsp;
              </span>
            )}
            <Truncate width={250}>{name}</Truncate>
          </div>
          <StoreItemDetailsFields
            item={item}
            showStore={showStore}
            permissions={permissions}
            showDetails={showDetails}
            showUpdated={showUpdated}
            showFactory={showFactory}
            showId={showId}
            showTransaction={showTransaction}
          />
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'CENTER', marginLeft: '-10px' }}>
          <div
            style={{
              position: 'relative',
            }}>
            <Quantity
              quantity={quantity}
              unitLabel={unitLabel}
              quantitySize={'35px'}
              enableChangeDirAnimation
              enableAutoSizeShrink
            />

            {renderPackaging()}
            {/*{renderCost()}*/}
            {showBatches && renderBatchInfo()}
          </div>
        </Grid>
      </Grid>
      {showDetails && Array.isArray(products) && products.length > 0 && (
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <div
              style={{ fontSize: '12px', color: 'gray', marginLeft: '16px' }}>
              <div>
                <IProduct />{' '}
                <span
                  style={{
                    marginLeft: '6px',
                  }}>
                  Used in{' '}
                  <span
                    className={'link'}
                    onClick={() => {
                      setOpenStoreItemProducts(true);
                    }}>
                    {products.length} products
                  </span>
                </span>
              </div>

              <StoreItemProducts
                open={openStoreItemProducts}
                item={item}
                products={products}
                onClose={() => {
                  setOpenStoreItemProducts(false);
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}
StoreItemDetails.propTypes = {
  item: PropTypes.object,
  showDetails: PropTypes.bool,
  products: PropTypes.array,
  onGroupsCountClick: PropTypes.func,
  showStore: PropTypes.bool,
  showTransaction: PropTypes.bool,
  showBatches: PropTypes.bool,
  showUpdated: PropTypes.bool,
  showFactory: PropTypes.bool,
  showId: PropTypes.bool,
};

StoreItemDetails.defaultProps = {
  showDetails: false,
  showStore: true,
  showTransaction: true,
  showBatches: true,
  showUpdated: false,
  permissions: {},
  showFactory: false,
  showId: false,
};
export default StoreItemDetails;
