import React from 'react';
import PropTypes from 'prop-types';
import HamburgerMenu from 'react-hamburger-menu';

import HeaderMenuDialog from './HeaderMenuDialog';

// right side app menu
function HeaderMenu(props) {
  const { activeItemId, style, onRefresh } = props;
  const [openHeaderMenuDialog, setOpenHeaderMenuDialog] = React.useState(false);

  const handleClick = (event) => {
    setOpenHeaderMenuDialog(true);
  };

  return (
    <div
      id={'burger-menu'}
      className={'burger-menu'}
      style={{
        position: 'absolute',
        right: '0px',
        top: '20px',
        zIndex: 10,
      }}>
      <div
        onClick={handleClick}
        style={{
          display: 'inline-block',
          width: '120px',
          height: '56px',
          position: 'relative',
          top: '-20px',
        }}>
        {' '}
        <div
          style={{
            position: 'absolute',
            width: '20px',
            top: '28px',
            right: '10px',
            cursor: 'pointer',
          }}>
          <HamburgerMenu
            isOpen={Boolean(openHeaderMenuDialog)}
            width={18}
            height={15}
            strokeWidth={2}
            menuClicked={() => {}}
            rotate={0}
            color={style.color || 'black'}
            borderRadius={0}
            animationDuration={0.5}
          />
        </div>
      </div>
      <HeaderMenuDialog
        onRefresh={onRefresh}
        activeItemId={activeItemId}
        open={openHeaderMenuDialog}
        onClose={() => {
          setOpenHeaderMenuDialog(false);
        }}
      />
    </div>
  );
}
HeaderMenu.propTypes = {
  activeItem: PropTypes.string,
  style: PropTypes.object,
  onRefresh: PropTypes.func,
};

HeaderMenu.defaultProps = {
  activeItem: '',
  style: {
    color: 'black',
  },
};
export default HeaderMenu;
