import React from 'react';

import { App } from '@meronex/app';

export const login = {
  backgroundImg: App.settings.login.backgroundImg,
  backgroundColor: 'rgba(0, 0, 0, 0.76)',
  color: 'white',
  logo: {
    src: App.settings.login.logo.src,
    style: App.settings.login.logo.style,
  },
};
