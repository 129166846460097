import React from 'react';
import PropTypes from 'prop-types';

function IssuesList(props) {
  return <div></div>;
}
IssuesList.propTypes = {};

IssuesList.defaultProps = {};
export default IssuesList;
