import React from 'react';
import PropTypes from 'prop-types';

import { CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import EquipmentHomePage from './EquipmentHomePage';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EquipmentHomePageDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, equipment } = props;

  if (!equipment) {
    return <div />;
  }

  console.log(equipment);
  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0px',
        }}>
        <EquipmentHomePage
          equipment={equipment}
          onWorkOrderAdded={() => {
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

EquipmentHomePageDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  equipment: PropTypes.object,
};

EquipmentHomePageDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

export default EquipmentHomePageDialog;
