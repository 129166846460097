import React from 'react';
import PropTypes from 'prop-types';

import { Form, ImageUploader } from '@meronex/components';

import TextField from '@material-ui/core/TextField';

function ItemCategoryForm(props) {
  const { onChange = () => {}, values = {}, validateOnInit } = props;

  const previewContainerRef = React.useRef();

  const [imgUrl, setImgUrl] = React.useState(null);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [uploading, setUploading] = React.useState(false);

  console.log(values);
  return (
    <div>
      <Form
        validateOnInit={validateOnInit}
        blurDelay={500}
        onUpdate={(_data) => {
          if (_data) {
            const values = _data.values;
            if (values) {
              values.isValid = _data.isValid;
            }
            onChange(values);
          }
        }}>
        <TextField
          name={'name'}
          label="Name"
          autoFocus={true}
          defaultValue={(values || {}).name}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
        />
        <TextField
          name={'code'}
          label="Code"
          autoFocus={false}
          defaultValue={(values || {}).code}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          validate={(v) => {
            if (!v || v === '') return 'Code is required';
            return true;
          }}
        />
      </Form>
      <div ref={previewContainerRef} style={{ width: '100%' }} />
    </div>
  );
}
ItemCategoryForm.propTypes = {
  values: PropTypes.object,
  validateOnInit: PropTypes.bool,
  onChange: PropTypes.func,
};

ItemCategoryForm.defaultProps = {
  values: {},
  validateOnInit: false,
  onChange: (values) => console.log(values),
};
export default ItemCategoryForm;
