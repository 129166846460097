import React from 'react';
import PropTypes from 'prop-types';

import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

export default function SaveBtn(props) {
  const mergedStyle = Object.assign(
    {
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'center',
      top: '-20px',
      position: 'relative',
    },
    props.style || {}
  );
  return (
    <DialogActions style={mergedStyle}>
      <Button
        onClick={() => {
          if (!props.disabled && props.onClick) {
            props.onClick();
          }
        }}
        disabled={props.disabled || props.loading}
        variant="contained"
        style={{
          color: 'white',
          width: '200px',
          height: '40px',
          borderRadius: '20px',
          backgroundColor:
            props.disabled || props.loading ? 'rgb(158, 158, 158)' : '#43c16a',
        }}>
        {props.loading && (
          <CircularProgress style={{ marginRight: '10px' }} size={20} />
        )}
        {props.label || 'Save'}
      </Button>
    </DialogActions>
  );
}

SaveBtn.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
