import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ICode from '@meronex/icons/fa/FaBarcode';
import IBoxes from '@meronex/icons/fa/FaBoxes';
import IconButton from '@material-ui/core/IconButton';
import { App } from '@meronex/app';

import IEdit from '@meronex/icons/bi/BiEdit';
import ITime from '@meronex/icons/bi/BiTimeFive';
import IProduct from '@meronex/icons/go/GoPackage';

import { helpers } from '../../../common/helpers';

import { Icons } from '../../../common/Icons';
import { p, can } from '../../../common/roles';
import SitesAvatar from '../../../common/SitesAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';

function Product(props) {
  const {
    showOnlyProductName,
    product,
    showFactory,
    onEdit,
    onClick,
    onAddProduct,
    last,
    readOnly,
  } = props;

  const [adding, setAdding] = React.useState();

  if (!product) {
    return null;
  }
  const _onEdit = () => {
    onEdit(product);
  };

  const _onClick = () => {
    if (onAddProduct) {
      setAdding(true);
      onAddProduct(product, () => {
        setAdding(false);
      });
    } else if (onClick) {
      onClick(product);
    }
  };

  const renderFactory = () => {
    if (
      !showFactory ||
      !Array.isArray(product.factory) ||
      product.factory.length < 1
    ) {
      return null;
    }
    return (
      <div style={{ marginTop: '2px' }}>
        <Icons.Factories /> Factory: {product.factory[0].name}
      </div>
    );
  };

  if (showOnlyProductName) {
    return (
      <div
        onClick={(e) => {
          if (!e.target.closest('#factory-btn')) {
            _onClick();
          }
        }}
        style={{
          display: 'inline-block',
          padding: '0px',
          marginTop: '-10px',
          width: '100%',
          fontSize: '14px',
          borderBottom: '1px solid #ebebeb',
          minHeight: '40px',
        }}>
        <Grid
          container
          style={{
            padding: '0px',
          }}>
          <Grid item xs={11}>
            <span
              style={{
                position: 'relative',
                top: '14px',
              }}>
              {product.name}
            </span>
          </Grid>
          {!readOnly && (
            <Grid
              item
              xs={1}
              style={{
                textAlign: 'center',
              }}>
              <IconButton id={'factory-btn'} onClick={_onEdit}>
                <IEdit size={20} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        if (!e.target.closest('#factory-btn')) {
          _onClick();
        }
      }}
      style={{
        display: 'inline-block',
        padding: '0px',
        marginTop: '-10px',
        width: '100%',
      }}>
      <div style={{ marginBottom: '5px', width: '100%' }}>
        <Grid container style={{ width: '100%', marginBottom: '10px' }}>
          <Grid item xs={3}>
            <div
              style={{
                textAlign: 'center',
              }}>
              <SitesAvatar
                name={product.name}
                image={product.image}
                size={75}
                round={15}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={7}
            style={{
              marginTop: '5px',
              paddingLeft: '10px',
            }}>
            <div
              style={{
                fontWeight: '400',
                fontSize: '14px',
                marginBottom: '8px',
                color: product.added ? 'rgb(2 2 2 / 43%)' : 'inherit',
              }}>
              <IProduct size={14} /> {product.name}{' '}
              {product.added ? (
                <span style={{ fontSize: '12px' }}>(added)</span>
              ) : (
                ''
              )}
            </div>
            <div
              style={{
                marginTop: '10px',
                color: 'gray',
                fontSize: '12px',
              }}>
              <div>
                <ICode /> Code: {product.code ? product.code : 'n/a'}
              </div>
              {renderFactory()}
              <div style={{ marginTop: '2px' }}>
                <IBoxes /> Items Count: {(product.items || []).length}
              </div>
              {product.updatedAt && (
                <div style={{ marginTop: '2px' }}>
                  <ITime /> Updated:{' '}
                  {App.utils.time.timeAgo(product.updatedAt.date)}
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={2}>
            {adding && (
              <div
                style={{
                  position: 'relative',
                  top: '5px',
                }}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={24}
                  thickness={4}
                />
              </div>
            )}
            {can(p.factories.CRUDProducts) && !readOnly && onEdit && (
              <div
                style={{
                  height: '80px',
                  position: 'relative',
                  textAlign: 'center',
                }}
                onClick={(e) => {
                  _onEdit();
                }}>
                <IconButton
                  id={'factory-btn'}
                  style={{
                    position: 'relative',
                    right: '-10px',
                    textAlign: 'center',
                  }}>
                  <IEdit size={25} />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      {!last && (
        <Divider
          style={{
            marginBottom: '10px',
          }}
        />
      )}
    </div>
  );
}
Product.propTypes = {
  product: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  onAddProduct: PropTypes.func,
  showFactory: PropTypes.bool,
  last: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Product.defaultProps = {
  onClick: () => console.log('on product click'),
};
export default Product;
