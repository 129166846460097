import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { App } from '@meronex/app';

const { server } = App;

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function AssignDialog(props) {
  const classes = useStyles();
  const { onClose, card, open } = props;

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [members, setMembers] = React.useState(undefined);

  const fetchMembers = async () => {
    const teamMembers = await server.call('getTeamMembers', {});
    setMembers(teamMembers);
  };
  React.useEffect(() => {
    fetchMembers();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClose = () => {
    onClose(card);
  };

  const handleListItemClick = async (value) => {
    const task = {
      referenceId: card.referenceId,
      referenceType: card.type,
      cardId: card._id,
      siteId: card.siteId,
      contributorId: value._id,
    };
    const result = await server.call('createSiteTask', { task });
    console.log(result);
    onClose(value);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'lg'}
      aria-labelledby="simple-dialog-title"
      open={open}>
      {/*<DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>*/}
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example">
            <Tab label="Members" {...a11yProps(0)} />
            <Tab label="Groups" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            {!members && <div>Loading</div>}
            {members && (
              <List>
                {members.map((member) => {
                  if (!member.profile) {
                    return null;
                  } else
                    return (
                      <ListItem
                        button
                        onClick={() => handleListItemClick(member)}
                        key={member._id}>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.avatar}
                            src={member.profile.avatar || ''}>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${member.profile.firstName} ${member.profile.lastName}`}
                        />
                      </ListItem>
                    );
                })}
              </List>
            )}
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div
              style={{
                padding: '20px',
                color: 'gray',
              }}>
              No groups available
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>
    </Dialog>
  );
}

AssignDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  card: PropTypes.object,
};
