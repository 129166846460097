import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import IReverse from '@meronex/icons/fa/FaUndo';
import { toast } from 'react-toastify';
import { App } from '@meronex/app';
import Link from '@material-ui/core/Link/Link';
import INote from '@meronex/icons/fd/FdClipboardNotes';

import storesMng from '../../../api/storesMng';
import TransactionItem from './TransactionItem';
import { helpers } from '../../../../common/helpers';
import SitesAvatar from '../../../../common/SitesAvatar';
import SitesUserName from '../../../../common/SitesUserName';
import { can, p } from '../../../../common/roles';

import WorkOrderDialog from '../../../../factories/components/workorders/listWorkOrders/WorkOrderDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    marginTop: '5px',
  },
}));

const thousandsSeparator = App.utils.number.thousandsSeparator;
const round = App.utils.number.round;

const formatQuantity = (amount) => {
  return thousandsSeparator(round(amount));
};

function TransactionDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    onClose,
    transaction,
    onReverse,
    hasExpandedItem,
    permissions,
  } = props;

  const [reversing, setReversing] = React.useState(false);
  const [openWorkOrder, setOpenWorkOrder] = React.useState(false);

  if (!transaction) {
    return null;
  }

  const storeItem = transaction.storeItem;
  const showPrice = can(p.stores.viewStoresPrices);

  const renderStoreItemInfo = () => {
    if (!storeItem) {
      return null;
    }
    return (
      <div
        style={{
          textAlign: 'center',
          minHeight: '200px',
        }}>
        <SitesAvatar
          image={storeItem.type.image}
          name={storeItem.type.name}
          size={80}
          round={20}
        />
        <div
          style={{
            marginTop: '25px',
            marginBottom: '20px',
            fontSize: '20px',
            fontWeight: '500',
          }}>
          {storeItem.type.name}
        </div>
      </div>
    );
  };
  const copyLink = () => {
    try {
      navigator.clipboard.writeText(transaction.uniqid);
    } catch (e) {
      toast.error('Failed to copy the link to clipboard.', { autoClose: 900 });
    }
    toast.success(`${transaction.uniqid} copied.`);
  };
  const reverseTransaction = async () => {
    if (!reversing) {
      setReversing(true);
      try {
        const result = await storesMng.reverseTransaction(transaction._id);
        toast.success('Transaction reversed successfully', {
          autoClose: 900,
        });
      } catch (e) {
        if (e.error === 'reverseTransaction.alreadyReversed') {
          toast.error(e.reason, {
            autoClose: 3000,
          });
        } else {
          toast.error('Ops, something went wrong');
        }
        console.error(e);
      } finally {
        if (onReverse) {
          onReverse();
        }
        setReversing(false);
        onClose();
      }
    }
  };
  const renderReversedTransaction = () => {
    const reverseTarget = transaction.reverseTarget;

    if (!reverseTarget) {
      return null;
    }

    return (
      <div className={classes.container} style={{ lineHeight: '1.8' }}>
        <IReverse /> Reversing transaction {reverseTarget.uniqid} created by{' '}
        <SitesUserName profile={reverseTarget.createdBy} />
        at {App.utils.time.timeFullFormat(reverseTarget.createdAt, true)} (
        {App.utils.time.timeAgo(reverseTarget.createdAt, true)})
      </div>
    );
  };
  const renderReversedByTransaction = () => {
    if (transaction.reversedBy) {
      return (
        <div className={classes.container} style={{ lineHeight: '1.8' }}>
          <b>(R)</b> Reversed by transaction "{transaction.reversedBy.uniqid}"{' '}
          {transaction.reversedBy.user && (
            <span>
              created by {helpers.renderUserName(transaction.reversedBy.user)}
              at{' '}
            </span>
          )}
          {App.utils.time.timeFullFormat(transaction.reversedBy.createdAt)} (
          {App.utils.time.timeAgo(transaction.reversedBy.createdAt)})
        </div>
      );
    }
  };
  const renderTransactionId = () => {
    if (!transaction.uniqid) {
      return null;
    }
    return (
      <div
        className={classes.container}
        style={{
          borderTop: '1px solid lightgray',
          paddingBottom: '12px',
          borderBottom: '1px solid lightgray',
        }}>
        <div
          style={{
            marginBottom: '4px',
          }}>
          Transaction Id:
        </div>{' '}
        <b>{transaction.uniqid}</b>{' '}
        <Link>
          <span
            onClick={() => {
              copyLink();
            }}
            style={{
              float: 'right',
              cursor: 'pointer',
              top: '-2px',
              position: 'relative',
            }}>
            Copy Id
          </span>
        </Link>
      </div>
    );
  };
  const renderWorkOrderInfo = () => {
    console.log(transaction);
    if (!transaction.to || !transaction.to.toProduct) {
      return <div />;
    }

    let product = transaction.to.product;
    return (
      <div
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          marginTop: '5px',
          paddingTop: '18px',
          paddingBottom: '18px',
          borderTop: '1px solid lightgray',
        }}>
        <div>
          {transaction.amount} {storeItem.type.unit} of {storeItem.type.name}{' '}
          used to create{' '}
        </div>
        <div>
          <b>
            {formatQuantity(product.producedQuantity)} {product.name}
          </b>
        </div>
        <div style={{ float: 'right', position: 'relative', top: '-40px' }}>
          <SitesAvatar
            name={product.image}
            name={product.name}
            size={40}
            round={10}
          />
        </div>
      </div>
    );
  };
  const renderNote = () => {
    if (transaction.note) {
      return (
        <div className={classes.container}>
          <INote
            size={25}
            style={{
              position: 'relative',
              top: '6px',
            }}
          />{' '}
          {transaction.note}
        </div>
      );
    }
  };

  const isReverseDisabled = () => {
    return (
      (transaction.from && transaction.from.isProduct) ||
      transaction.reversed ||
      (transaction.to && transaction.to.toProduct) ||
      transaction.transactionType === 'modify' ||
      !can(p.stores.reverseTransaction)
    );
  };

  return (
    <Dialog
      open={open}
      className={'fullScreenMobile'}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginTop: '-20px',
          marginBottom: '40px',
        }}>
        {renderStoreItemInfo()}
        <div className={classes.container}>
          <TransactionItem item={transaction} showPrice={showPrice} />
        </div>
        {renderWorkOrderInfo()}
        {transaction.workOrder && (
          <div
            style={{
              marginTop: '10px',
              marginBottom: '20px',
              textAlign: 'center',
            }}>
            <Button
              onClick={() => {
                setOpenWorkOrder(true);
              }}>
              Open Work Order
            </Button>
          </div>
        )}

        {renderTransactionId()}
        {renderReversedTransaction()}
        {renderReversedByTransaction()}
        {renderNote()}

        <div style={{ width: '100%', marginTop: '30px', textAlign: 'center' }}>
          <ActionBtn
            onClick={() => {
              reverseTransaction();
            }}
            loading={reversing}
            disabled={isReverseDisabled()}
            label={
              <span>
                <IReverse /> Reverse
              </span>
            }
            backgroundColor={'rgb(218 29 29)'}
          />
        </div>
      </DialogContent>
      {transaction.workOrder && (
        <WorkOrderDialog
          workOrder={transaction.workOrder}
          open={openWorkOrder}
          readOnly={true}
          onClose={() => {
            setOpenWorkOrder(false);
          }}
        />
      )}
    </Dialog>
  );
}

TransactionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  transaction: PropTypes.object,
  onReverse: PropTypes.func,
  permissions: PropTypes.object,
};

export default TransactionDialog;
