import React from 'react';
import PropTypes from 'prop-types';

import {
  CloseBtn,
  Form,
  ActionBtn,
  toast,
  AlertDialog,
} from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import INews from '@meronex/icons/bs/BsNewspaper';
import ITrash from '@meronex/icons/cg/CgTrash';
import IPreview from '@meronex/icons/mdc/MdcPresentation';

import NewsSectionForm from './NewsSectionForm';
import NewsSection from './NewsSection';
import { newsMng } from './api';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditNewsSectionDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose, refresh } = props;

  const [newsSection, setNewsSection] = React.useState(props.newsSection);
  const [previewNewsSection, setPreviewNewsSection] = React.useState();
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setNewsSection(props.newsSection);
  }, [props.newsSection]);

  const onPreviewClick = () => {
    const preview = { ...newsSection };
    preview.modifiedDate = new Date();
    setPreviewNewsSection(preview);
  };

  const onSave = async () => {
    setLoading(true);
    const result = await newsMng.updateNewsSection(newsSection);
    onClose();
    setLoading(false);
    toast.success('News section updated!', {
      autoClose: 800,
    });
    refresh();
  };

  const onDelete = async () => {
    const result = await newsMng.deleteNewsSection(newsSection);
    toast.success('News section deleted', {
      autoClose: 800,
    });
    refresh();
    onClose();
  };
  return (
    <Dialog
      className={'fullScreenMobile'}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        <INews /> Edit News Section
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '8px',
        }}>
        <NewsSectionForm
          newsSection={newsSection}
          onChange={(newsSection) => {
            console.log(newsSection);
            setNewsSection(newsSection);
          }}
        />
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: '30px',
          }}>
          <Button
            onClick={() => {
              onPreviewClick();
            }}>
            <IPreview />
            &nbsp; Preview
          </Button>
        </div>
        <div style={{ marginTop: '10px' }}>
          <NewsSection newsSection={previewNewsSection} />
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
          <Button
            style={{ color: 'red' }}
            onClick={() => {
              setShowAlertDialog(true);
            }}>
            <ITrash /> <span style={{ marginLeft: '5px' }}>Delete</span>
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: '25px',
          }}>
          <ActionBtn
            loading={loading}
            label={'Update'}
            onClick={() => onSave()}
          />
        </div>
      </DialogActions>
      <AlertDialog
        confirmBtn={
          <span style={{ color: 'red' }}>
            {' '}
            <ITrash /> Delete
          </span>
        }
        open={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        description={'Do you want to delete the news section?'}
        onConfirm={() => {
          onDelete();
        }}
      />
    </Dialog>
  );
}

EditNewsSectionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  newsSection: PropTypes.object,
};

export default EditNewsSectionDialog;
