import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn, Form, toast } from '@meronex/components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { storesMng } from '../../api/storesMng';
import { App } from '@meronex/app';
import StoreForm from './StoreForm';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function EditStoreDialog(props) {
  const { open, onClose, onEdit, store } = props;

  console.log(store);
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ isValid: true });

  React.useEffect(() => {
    setFormData({ ...store });
  }, [store]);

  const _onClose = () => {
    onClose();
  };
  const editStore = async () => {
    console.log('on edit');
    setLoading(true);
    console.log(store);
    const _updateStore = Object.assign(store, formData);
    delete _updateStore.isValid;

    console.log(_updateStore);
    try {
      const result = await storesMng.updateStore(_updateStore);
      onEdit();
      onClose();
      App.eventsManager.emit('event-refresh-views', EditStoreDialog.name, {
        activeView: 'stores',
      });
      toast.success('Store updated', {
        autoClose: 900,
      });
    } catch (e) {
      toast.error('Ops, something went wrong');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (!store || !open) {
    return null;
  }
  return (
    <Dialog
      fullWidth
      className={'fullScreenMobile'}
      open={open}
      onClose={_onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle>
        Edit Store
        <CloseBtn
          onClick={() => {
            _onClose();
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: '-15px',
        }}>
        <StoreForm
          validateOnInit
          onChange={(values) => {
            console.log(values);
            setFormData({ ...Object.assign(formData, values) });
          }}
          data={formData}
        />

        <div
          style={{
            marginBottom: '20px',
            marginTop: '20px',
            textAlign: 'center',
          }}>
          <ActionBtn
            loading={loading}
            disabled={loading || (formData && !formData.isValid)}
            label={<span>Save Store</span>}
            onClick={() => {
              editStore();
            }}
            onEnter={() => {
              editStore();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

EditStoreDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default EditStoreDialog;
