import React from 'react';
import PropTypes from 'prop-types';

import { ActionBtn, CloseBtn } from '@meronex/components';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//TODO: replace with <Icons.X/>
import IList from '@meronex/icons/ios/MdList';

import AddTeamDialog from './AddTeamDialog';
import EditTeamDialog from './EditTeamDialog';
import TeamsList from './TeamsList';

import { teamsMng } from '../api/teamsMng';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

function TeamsDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { open, onClose } = props;

  const [openAddTeam, setOpenAddTeam] = React.useState();
  const [openEditTeam, setOpenEditTeam] = React.useState();
  const [selectedTeam, setSelectedTeam] = React.useState();
  const [teams, setTeams] = React.useState();

  const getTeams = async () => {
    console.log('getTeams');
    const teams = await teamsMng.getTeams();
    setTeams(teams);
  };

  React.useEffect(() => {
    getTeams();
  }, []);

  React.useEffect(() => {
    if (selectedTeam) {
      setOpenEditTeam(true);
    }
  }, [selectedTeam]);

  return (
    <Dialog className={'fullScreenMobile'} open={open} onClose={onClose}>
      <DialogTitle>
        <IList /> Teams
        <CloseBtn
          onClick={() => {
            onClose();
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <TeamsList
            teams={teams}
            onClick={(team) => {
              console.log(team);
            }}
            onEdit={(team) => {
              console.log(team);
              setSelectedTeam(team);
              setOpenEditTeam(true);
            }}
            onDelete={getTeams}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            marginTop: '20px',
          }}>
          <ActionBtn
            onClick={() => {
              setSelectedTeam(false);
              setOpenAddTeam(true);
            }}
            label={<div>Add Team</div>}
          />
        </div>
        {openAddTeam && (
          <AddTeamDialog
            open={openAddTeam}
            onAdd={getTeams}
            onClose={() => {
              setOpenAddTeam(false);
              setSelectedTeam(null);
            }}
          />
        )}
        {openEditTeam && (
          <EditTeamDialog
            open={openEditTeam}
            team={selectedTeam}
            onEdit={getTeams}
            onClose={() => {
              setOpenEditTeam(false);
              setSelectedTeam(false);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

TeamsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TeamsDialog;
