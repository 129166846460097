import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FirebaseFileUploader as FileUploader } from '@meronex/components';
import MdCameraEnhance from '@meronex/icons/md/MdCameraEnhance';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { utils } from '../base/utils';
import Annotator from './Annotator';

const useStyles = makeStyles((theme) => ({
  uploadBtnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  uploadBtn: {
    borderRadius: '20px',
    width: '100%',
    height: '45px',
    marginTop: '5px',
    marginBottom: '25px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function ImageUploader(props) {
  const { enableAnnotation, btnColor, btnLabel } = props;

  const [isUploading, setIsUploading] = React.useState();
  const [progress, setProgress] = React.useState(0);
  const [previewUrl, setPreviewUrl] = React.useState(props.defaultPreviewUrl);
  const [checkingThumb, setCheckingThumb] = React.useState(false);
  const [thumbUrl, setThumbUrl] = React.useState(undefined);
  const [imgUrl, setImgUrl] = React.useState(undefined);
  const [fileInfo, setFileInfo] = React.useState();

  const previewContainer = props.previewContainer;

  React.useEffect(() => {
    if (previewContainer && previewContainer.current) {
      ReactDOM.render(renderPreview(), previewContainer.current);
    }
  }, [isUploading, previewUrl, checkingThumb]);

  const handleUploadStart = () => {
    if (props.onUploadStart) {
      props.onUploadStart();
    }
    setIsUploading(true);
  };

  React.useEffect(() => {
    if (fileInfo) {
      checkThumbnail(fileInfo.filename, fileInfo.fileUrl);
    }
  }, [fileInfo]);

  const handleProgress = (progress) => setProgress(progress);

  const handleUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };

  const checkThumbnail = async (filename, imgUrl) => {
    utils.checkThumbnail(filename, (thumbUrl) => {
      if (checkingThumb) {
        setThumbUrl(thumbUrl);
        if (props.onUploadSuccess) {
          props.onUploadSuccess({
            imgUrl,
            thumbUrl,
          });
        }
      }
      setCheckingThumb(false);
      setProgress(0);
    });
  };

  const handleUploadSuccess = async (filename, file) => {
    setProgress(100);
    setIsUploading(false);

    utils.getStorageFileURL(filename, 'images', (fileUrl) => {
      // Use in memory image url, instead of
      // downloading the high resolution image.
      const previewUrl = window.URL.createObjectURL(file);
      setImgUrl(fileUrl);
      setPreviewUrl(previewUrl);
      setCheckingThumb(true);
      setFileInfo({
        filename,
        fileUrl,
        previewUrl,
      });
    });
  };

  const renderPreview = () => {
    return (
      <div style={{ width: '100%' }}>
        {isUploading && (
          <div>
            <div style={{ marginBottom: '10px' }}>
              Uploading Image: {progress}
            </div>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}
        <div style={{ textAlign: 'center', zIndex: 9999 }}>
          <div>
            {checkingThumb && (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  Processing & Optimizing Image...
                </div>
                <CircularProgress />
              </div>
            )}
            {previewUrl && !checkingThumb && !isUploading && (
              <>
                {enableAnnotation && <Annotator src={previewUrl} />}
                {!enableAnnotation && (
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={previewUrl}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const classes = useStyles();

  return (
    <div className={classes.uploadBtnContainer}>
      <label>
        <Button
          className={classes.uploadBtn}
          variant="outlined"
          color={btnColor}
          component="span"
          // className={classes.button}
        >
          {btnLabel}
        </Button>
        <FileUploader
          accept="image/*"
          name="avatar"
          randomizeFilename
          hidden
          folder={'images'}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
        />
      </label>
    </div>
  );
}

ImageUploader.propTypes = {
  previewContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  defaultPreviewUrl: PropTypes.string,
  onUploadStart: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  enableAnnotation: PropTypes.bool,
  btnColor: PropTypes.string,
  btnLabel: PropTypes.string,
};

ImageUploader.defaultProps = {
  enableAnnotation: false,
  btnColor: 'primary',
  btnLabel: (
    <>
      <MdCameraEnhance size={20} />
      &nbsp;Add Image
    </>
  ),
};
