import React from 'react';
import PropTypes from 'prop-types';

import { App } from '@meronex/app';
import ICamera from '@meronex/icons/mdc/MdcCameraEnhanceOutline';
import { Form, ImageUploader, AlertDialog } from '@meronex/components';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import moment from 'moment';

import StoresSelect from '../../../common/StoresSelect';
import ProductItems from './ProductItems';

import InputAdornment from '@material-ui/core/InputAdornment';
import QualityFormDialog from './qualityForm/QualityFormDialog';

function ProductForm(props) {
  const { product = {}, onChange } = props;
  const [uploading, setUploading] = React.useState(false);
  const [items, setItems] = React.useState(product ? product.items : []);
  const [openQualityForm, setOpenQualityForm] = React.useState();

  const [openDisableGroupByBatch, setOpenDisableGroupByBatch] = React.useState(
    false
  );

  const previewContainerRef = React.useRef();
  const _product = product ? App.utils.clone(product) : {};

  if (!product) {
    return null;
  }
  const isValid = (product) => {
    let valid = true;

    if (!product.name) {
      valid = false;
    } else if (!product.code) {
      valid = false;
      valid = false;
    } else if (!product.unit) {
      valid = false;
    } else if (!Array.isArray(product.items) || product.items.length === 0) {
      valid = false;
    } else if (product.enableDefaultStore && !product.destinationStoreId) {
      valid = false;
    } else if (product.expiration && !product.expiration.value) {
      valid = false;
    }

    product.isValid = valid;

    return product;
  };

  const _onChange = (product) => {
    return onChange(isValid(product));
  };
  return (
    <div
      style={{
        overflowX: 'hidden',
      }}>
      <Form
        blurDelay={500}
        onUpdate={(_formData) => {
          _product.name = _formData.values.name;
          _product.code = _formData.values.code;
          _product.unit = _formData.values.unit;
          _product.overheadCost =
            parseFloat(_formData.values.overheadCost) || 0;

          _onChange(_product);
        }}>
        <TextField
          name={'name'}
          label="Name"
          autoFocus={true}
          defaultValue={_product.name}
          variant="outlined"
          validate={(v) => {
            if (!v || v === '') return 'Name is required';
            return true;
          }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name={'unit'}
          label="Unit"
          defaultValue={_product.unit}
          variant="outlined"
          validate={(v) => {
            if (!v || v === '') return 'Unit is required';
            return true;
          }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name={'code'}
          label="Code"
          defaultValue={_product.code}
          variant="outlined"
          validate={(v) => {
            if (!v || v === '') return 'Code is required';
            return true;
          }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name={'overheadCost'}
          label="Overhead Cost"
          defaultValue={_product.overheadCost}
          variant="outlined"
          type={'number'}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                AED/{_product.unit || 'Unit'}
              </InputAdornment>
            ),
          }}
        />
      </Form>
      <div style={{ color: 'gray', marginTop: '20px' }}>Steps & Materials</div>
      <div
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          border: '1px solid rgb(196 196 196)',
          borderRadius: '5px',
          paddingTop: '20px',
        }}>
        <ProductItems
          product={_product}
          onChange={(items) => {
            _product.items = [...items];
            setItems(items);
            _onChange(_product);
          }}
          items={items}
        />
      </div>
      {/*<div style={{ color: 'gray', marginTop: '20px' }}>*/}
      {/*  Quality & Verifications*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  style={{*/}
      {/*    marginTop: '10px',*/}
      {/*  }}>*/}
      {/*  0 Fields in the quality form*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      textAlign: 'center',*/}
      {/*      marginTop: '15px',*/}
      {/*      border: '1px solid #c4c4c4',*/}
      {/*      borderRadius: '30px',*/}
      {/*    }}>*/}
      {/*    <Button*/}
      {/*      style={{ width: '100%' }}*/}
      {/*      onClick={() => {*/}
      {/*        setOpenQualityForm(true);*/}
      {/*      }}>*/}
      {/*      Edit*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div style={{ color: 'gray', marginTop: '20px' }}>Configurations</div>
      <div>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={_product.enableDefaultStore}
                onChange={(v) => {
                  _product.enableDefaultStore = v.target.checked;
                  _onChange(_product);
                }}
                name="defaultStore"
                color="primary"
              />
            }
            label="Default store"></FormControlLabel>
          <FormHelperText>
            The default store where the produced quantities will be stored.
          </FormHelperText>
        </FormControl>
      </div>
      {_product.enableDefaultStore && (
        <div style={{ marginTop: '10px' }}>
          <StoresSelect
            label={'Destination Store'}
            storeId={_product.destinationStoreId}
            onChange={(store) => {
              console.log(store);
              _product.destinationStoreId = store._id;
              _product.destinationStore = store;
              _onChange(_product);
            }}
          />
        </div>
      )}
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={_product.batchNumbers}
              onChange={(v) => {
                _product.batchNumbers = v.target.checked;
                if (!_product.batchNumbers) {
                  _product.groupByBatch = null;
                }
                _onChange(_product);
              }}
              name="batchNumbers"
              color="primary"
            />
          }
          label="Batch numbers"
        />
        <FormHelperText>
          Generate batch numbers based on the creation date.
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!product.batchNumbers}
              checked={_product.groupByBatch}
              onChange={(v) => {
                if (!v.target.checked) {
                  setOpenDisableGroupByBatch(true);
                } else {
                  _product.groupByBatch = v.target.checked;
                  _onChange(_product);
                }
              }}
              name="groupByBatch"
              color="primary"
            />
          }
          label="Group by batch"
        />
        <FormHelperText>
          Automatically group produced quantities by batch numbers, require
          batch numbers to be enabled and a products store.
        </FormHelperText>
      </FormControl>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={_product.expiration}
              onChange={(v) => {
                _product.expiration = v.target.checked
                  ? {
                      value: '',
                      unit: 'weeks',
                    }
                  : null;
                _onChange(_product);
              }}
              name="expiration"
              color="primary"
            />
          }
          label="Expiration"
        />
        <FormHelperText>
          Define the product estimated shelf life. This will used in the work
          order, transactions, batch groups, reports, and alerts.
        </FormHelperText>
      </div>
      {product.expiration && (
        <Grid container>
          <Grid item xs={7}>
            <TextField
              name={'expirationPeriod'}
              label="Estimated Expiration Period"
              defaultValue={_product.expiration.value}
              variant="outlined"
              onChange={(e) => {
                _product.expiration.value = e.target.value;
                const { value, unit } = _product.expiration;
                console.log(
                  moment()
                    .add(moment.duration(value, unit))
                    .format('DD/MM/YY hh:mm:ss A')
                );
                _onChange(_product);
              }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl
              variant="outlined"
              fullWidth
              style={{
                marginTop: '15px',
                marginLeft: '10px',
                paddingRight: '10px',
              }}>
              <InputLabel>Unit</InputLabel>
              <Select
                value={_product.expiration.unit}
                onChange={(e) => {
                  _product.expiration.unit = e.target.value;
                  _onChange(_product);
                }}
                label={'Unit'}>
                <MenuItem value={'seconds'}>Seconds</MenuItem>
                <MenuItem value={'minutes'}>Minutes</MenuItem>
                <MenuItem value={'hours'}>Hours</MenuItem>
                <MenuItem value={'days'}>Days</MenuItem>
                <MenuItem value={'weeks'}>Weeks</MenuItem>
                <MenuItem value={'months'}>Months</MenuItem>
                <MenuItem value={'years'}>Years</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={_product.packaging}
              onChange={(v) => {
                _product.packaging = v.target.checked
                  ? {
                      unit: '',
                      rate: null,
                    }
                  : null;
                _onChange(_product);
              }}
              name="packaging"
              color="primary"
            />
          }
          label="Product Packaging"
        />
        <FormHelperText>
          Define the product packaging unit, for example bags, boxes, containers
          etc.
        </FormHelperText>
      </div>
      {_product.packaging && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name={'unit'}
                label="Unit"
                defaultValue={_product.packaging.unit}
                variant="outlined"
                onChange={(e) => {
                  _product.packaging.unit = e.target.value;
                  _onChange(_product);
                }}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={'rate'}
                label="Rate"
                defaultValue={_product.packaging.rate}
                variant="outlined"
                type={'number'}
                onChange={(e) => {
                  _product.packaging.rate = parseFloat(e.target.value);
                  _onChange(_product);
                }}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {_product.unit}/{_product.packaging.unit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <ImageUploader
          defaultPreviewUrl={
            _product && _product.image ? _product.image.imgUrl : null
          }
          btnColor={'default'}
          btnLabel={
            <>
              <div
                style={{
                  marginRight: '5px',
                  position: 'relative',
                  top: '2px',
                }}>
                <ICamera size={20} />
              </div>{' '}
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  textTransform: 'none',
                }}>
                Add Image{' '}
              </span>
            </>
          }
          previewContainer={previewContainerRef}
          onUploadStart={() => {
            setUploading(true);
          }}
          onUploadSuccess={(imageData) => {
            if (!_product.image) {
              _product.image = {};
            }
            _product.image.imgUrl = imageData.imgUrl;
            _product.image.thumbUrl = imageData.thumbUrl;
            _onChange(_product);
            setUploading(false);
          }}
        />
      </div>
      <div
        ref={previewContainerRef}
        style={{ width: '100%', marginTop: '20px', overflow: 'hidden' }}
      />
      <AlertDialog
        open={openDisableGroupByBatch}
        confirmBtn={'Yes, disable group by batch'}
        description={
          'Disabling group by batch will clear all the existing batch groups, are you sure you want to continue?'
        }
        onConfirm={() => {
          _product.groupByBatch = false;
          _onChange(_product);
          setOpenDisableGroupByBatch(false);
        }}
        onClose={() => {
          setOpenDisableGroupByBatch(false);
        }}
      />
      {openQualityForm && (
        <QualityFormDialog
          open={openQualityForm}
          onClose={() => {
            setOpenQualityForm();
          }}
        />
      )}
    </div>
  );
}
ProductForm.propTypes = {
  product: PropTypes.object,
  onChange: PropTypes.func,
};

ProductForm.defaultProps = {
  product: {},
  onChange: (formData) => console.log(formData),
};
export default ProductForm;
