import React from 'react';
import { Settings } from '@meronex/layout';

import GoLocation from '@meronex/icons/go/GoLocation';

import Home from '../modules/views/ViewsContainer';
import DrawerMenu from '../modules/common/DrawerMenu';
import EditIcon from '@material-ui/core/SvgIcon/SvgIcon';

const iconSize = 20;

export const views = {
  singlePage: undefined,
  multiPage: [
    {
      id: 'home',
      label: 'Home',
      icon: <GoLocation size={iconSize} />,
      component: <Home />,
    },
    // {
    //   id: 'logs',
    //   label: 'Logs',
    //   icon: <GoNote size={iconSize} />,
    //   component: <Logs />,
    // },
    // {
    //   id: 'tasks',
    //   label: 'Tasks',
    //   icon: <FaTasks size={iconSize} />,
    //   component: <Tasks />,
    // },
    // {
    //   id: 'more',
    //   label: 'More',
    //   icon: <FiMoreVertical size={iconSize} />,
    //   component: <Settings />,
    // },
  ],
  showFooter: false,
  drawer: {
    index: 2,
    icon: <EditIcon />,
    component: DrawerMenu,
  },
};
