// note that MainLayout uses views from the settings

const MainLayout = require('@meronex/layout').MainLayout;
const ViewsContainer = require('../modules/views/ViewsContainer').default;
const EquipmentLandingPage = require('../modules/equipments/EquipmentLandingPage')
  .default;
export const routes = [
  {
    path: '/e',
    component: EquipmentLandingPage,
    privateRoute: false,
  },
  {
    path: '/home',
    component: ViewsContainer,
    privateRoute: true,
  },
  {
    path: '/',
    component: ViewsContainer,
    privateRoute: true,
  },
];
